const PlantDailyData=[
  {
    "datetime": "12/17/2023 7:00",
    "plant_id": "Green-tek-A12225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/17/2023 7:15",
    "plant_id": "Green-tek-A12226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/17/2023 7:30",
    "plant_id": "Green-tek-A12227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/17/2023 7:45",
    "plant_id": "Green-tek-A12228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/17/2023 8:00",
    "plant_id": "Green-tek-A12229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "12/17/2023 8:15",
    "plant_id": "Green-tek-A12230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "8:15:00 AM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "12/17/2023 8:30",
    "plant_id": "Green-tek-A12231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "8:30:00 AM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "12/17/2023 8:45",
    "plant_id": "Green-tek-A12232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "8:45:00 AM",
    "power": 0.298,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "12/17/2023 9:00",
    "plant_id": "Green-tek-A12233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "9:00:00 AM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "12/17/2023 9:15",
    "plant_id": "Green-tek-A12234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "9:15:00 AM",
    "power": 0.467,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "12/17/2023 9:30",
    "plant_id": "Green-tek-A12235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "9:30:00 AM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "12/17/2023 9:45",
    "plant_id": "Green-tek-A12236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "9:45:00 AM",
    "power": 0.675,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "12/17/2023 10:00",
    "plant_id": "Green-tek-A12237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "10:00:00 AM",
    "power": 0.787,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "12/17/2023 10:15",
    "plant_id": "Green-tek-A12238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "10:15:00 AM",
    "power": 0.898,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "12/17/2023 10:30",
    "plant_id": "Green-tek-A12239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "10:30:00 AM",
    "power": 1.005,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "12/17/2023 10:45",
    "plant_id": "Green-tek-A12240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "10:45:00 AM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "12/17/2023 11:00",
    "plant_id": "Green-tek-A12241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "11:00:00 AM",
    "power": 1.183,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "12/17/2023 11:15",
    "plant_id": "Green-tek-A12242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "11:15:00 AM",
    "power": 1.245,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "12/17/2023 11:30",
    "plant_id": "Green-tek-A12243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "11:30:00 AM",
    "power": 1.284,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.33048,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "12/17/2023 11:45",
    "plant_id": "Green-tek-A12244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "11:45:00 AM",
    "power": 1.297,
    "Energy": 0.324,
    "output Energy": 0.324,
    "Ac output": 1.324,
    "dc output": 1.35048,
    "AC_R_i": 0.00042261,
    "AC_S_i": 0.000464871,
    "AC_T_i": 0.00051136,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0972003,
    "AC_S_pow": 0.10692033,
    "AC_T_Pow": 0.1176128,
    "AC_R_output": 0.3972,
    "AC_S_output": 0.43692,
    "AC_T_output": 0.480612,
    "dc_current": 0.00238235,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.33048,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000324,
    "carbon offset": 0.405,
    "trees planted": 9,
    "DC_R_output": 0.131076,
    "DC_S_output": 0.1441836,
    "DC_T_output": 0.15860196
  },
  {
    "datetime": "12/17/2023 12:00",
    "plant_id": "Green-tek-A12245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "12:00:00 PM",
    "power": 1.284,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "12/17/2023 12:15",
    "plant_id": "Green-tek-A12246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "12:15:00 PM",
    "power": 1.245,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "12/17/2023 12:30",
    "plant_id": "Green-tek-A12247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "12:30:00 PM",
    "power": 1.183,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "12/17/2023 12:45",
    "plant_id": "Green-tek-A12248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "12:45:00 PM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "12/17/2023 13:00",
    "plant_id": "Green-tek-A12249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "1:00:00 PM",
    "power": 1.005,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "12/17/2023 13:15",
    "plant_id": "Green-tek-A12250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "1:15:00 PM",
    "power": 0.898,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "12/17/2023 13:30",
    "plant_id": "Green-tek-A12251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "1:30:00 PM",
    "power": 0.787,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "12/17/2023 13:45",
    "plant_id": "Green-tek-A12252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "1:45:00 PM",
    "power": 0.675,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "12/17/2023 14:00",
    "plant_id": "Green-tek-A12253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "2:00:00 PM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "12/17/2023 14:15",
    "plant_id": "Green-tek-A12254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "2:15:00 PM",
    "power": 0.467,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "12/17/2023 14:30",
    "plant_id": "Green-tek-A12255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "2:30:00 PM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "12/17/2023 14:45",
    "plant_id": "Green-tek-A12256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "2:45:00 PM",
    "power": 0.298,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "12/17/2023 15:00",
    "plant_id": "Green-tek-A12257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "3:00:00 PM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "12/17/2023 15:15",
    "plant_id": "Green-tek-A12258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "3:15:00 PM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "12/17/2023 15:30",
    "plant_id": "Green-tek-A12259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "12/17/2023 15:45",
    "plant_id": "Green-tek-A12260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/17/2023 16:00",
    "plant_id": "Green-tek-A12261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/17/2023 16:15",
    "plant_id": "Green-tek-A12262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/17/2023 16:30",
    "plant_id": "Green-tek-A12263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/17/2023 16:45",
    "plant_id": "Green-tek-A12264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "12/17/2023 17:00",
    "plant_id": "Green-tek-A12265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/17/2023 17:15",
    "plant_id": "Green-tek-A12266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/17/2023 17:30",
    "plant_id": "Green-tek-A12267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/17/2023",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/18/2023 6:00",
    "plant_id": "Green-tek-A12268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/18/2023 6:15",
    "plant_id": "Green-tek-A12269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/18/2023 6:30",
    "plant_id": "Green-tek-A12270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/18/2023 6:45",
    "plant_id": "Green-tek-A12271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "12/18/2023 7:00",
    "plant_id": "Green-tek-A12272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "12/18/2023 7:15",
    "plant_id": "Green-tek-A12273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "12/18/2023 7:30",
    "plant_id": "Green-tek-A12274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "12/18/2023 7:45",
    "plant_id": "Green-tek-A12275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "7:45:00 AM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "12/18/2023 8:00",
    "plant_id": "Green-tek-A12276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "8:00:00 AM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "12/18/2023 8:15",
    "plant_id": "Green-tek-A12277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "8:15:00 AM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "12/18/2023 8:30",
    "plant_id": "Green-tek-A12278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "8:30:00 AM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "12/18/2023 8:45",
    "plant_id": "Green-tek-A12279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "8:45:00 AM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "12/18/2023 9:00",
    "plant_id": "Green-tek-A12280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "9:00:00 AM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "12/18/2023 9:15",
    "plant_id": "Green-tek-A12281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "9:15:00 AM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "12/18/2023 9:30",
    "plant_id": "Green-tek-A12282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "9:30:00 AM",
    "power": 0.492,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "12/18/2023 9:45",
    "plant_id": "Green-tek-A12283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "9:45:00 AM",
    "power": 0.585,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "12/18/2023 10:00",
    "plant_id": "Green-tek-A12284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "10:00:00 AM",
    "power": 0.682,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "12/18/2023 10:15",
    "plant_id": "Green-tek-A12285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "10:15:00 AM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "12/18/2023 10:30",
    "plant_id": "Green-tek-A12286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "10:30:00 AM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "12/18/2023 10:45",
    "plant_id": "Green-tek-A12287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "10:45:00 AM",
    "power": 0.955,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "12/18/2023 11:00",
    "plant_id": "Green-tek-A12288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "11:00:00 AM",
    "power": 1.026,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "12/18/2023 11:15",
    "plant_id": "Green-tek-A12289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "11:15:00 AM",
    "power": 1.079,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "12/18/2023 11:30",
    "plant_id": "Green-tek-A12290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "11:30:00 AM",
    "power": 1.113,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "12/18/2023 11:45",
    "plant_id": "Green-tek-A12291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "11:45:00 AM",
    "power": 1.124,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "12/18/2023 12:00",
    "plant_id": "Green-tek-A12292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "12:00:00 PM",
    "power": 1.113,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "12/18/2023 12:15",
    "plant_id": "Green-tek-A12293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "12:15:00 PM",
    "power": 1.079,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "12/18/2023 12:30",
    "plant_id": "Green-tek-A12294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "12:30:00 PM",
    "power": 1.026,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "12/18/2023 12:45",
    "plant_id": "Green-tek-A12295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "12:45:00 PM",
    "power": 0.955,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "12/18/2023 13:00",
    "plant_id": "Green-tek-A12296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "1:00:00 PM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "12/18/2023 13:15",
    "plant_id": "Green-tek-A12297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "1:15:00 PM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "12/18/2023 13:30",
    "plant_id": "Green-tek-A12298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "1:30:00 PM",
    "power": 0.682,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "12/18/2023 13:45",
    "plant_id": "Green-tek-A12299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "1:45:00 PM",
    "power": 0.585,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "12/18/2023 14:00",
    "plant_id": "Green-tek-A12300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "2:00:00 PM",
    "power": 0.492,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "12/18/2023 14:15",
    "plant_id": "Green-tek-A12301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "2:15:00 PM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "12/18/2023 14:30",
    "plant_id": "Green-tek-A12302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "2:30:00 PM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "12/18/2023 14:45",
    "plant_id": "Green-tek-A12303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "2:45:00 PM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "12/18/2023 15:00",
    "plant_id": "Green-tek-A12304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "3:00:00 PM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "12/18/2023 15:15",
    "plant_id": "Green-tek-A12305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "3:15:00 PM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "12/18/2023 15:30",
    "plant_id": "Green-tek-A12306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "3:30:00 PM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "12/18/2023 15:45",
    "plant_id": "Green-tek-A12307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "3:45:00 PM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "12/18/2023 16:00",
    "plant_id": "Green-tek-A12308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "12/18/2023 16:15",
    "plant_id": "Green-tek-A12309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "12/18/2023 16:30",
    "plant_id": "Green-tek-A12310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "12/18/2023 16:45",
    "plant_id": "Green-tek-A12311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "12/18/2023 17:00",
    "plant_id": "Green-tek-A12312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/18/2023 17:15",
    "plant_id": "Green-tek-A12313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/18/2023 17:30",
    "plant_id": "Green-tek-A12314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/18/2023",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/19/2023 6:00",
    "plant_id": "Green-tek-A12315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/19/2023 6:15",
    "plant_id": "Green-tek-A12316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/19/2023 6:30",
    "plant_id": "Green-tek-A12317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/19/2023 6:45",
    "plant_id": "Green-tek-A12318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/19/2023 7:00",
    "plant_id": "Green-tek-A12319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/19/2023 7:15",
    "plant_id": "Green-tek-A12320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/19/2023 7:30",
    "plant_id": "Green-tek-A12321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/19/2023 7:45",
    "plant_id": "Green-tek-A12322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "7:45:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/19/2023 8:00",
    "plant_id": "Green-tek-A12323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "8:00:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/19/2023 8:15",
    "plant_id": "Green-tek-A12324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "8:15:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "12/19/2023 8:30",
    "plant_id": "Green-tek-A12325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "8:30:00 AM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "12/19/2023 8:45",
    "plant_id": "Green-tek-A12326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "8:45:00 AM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "12/19/2023 9:00",
    "plant_id": "Green-tek-A12327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "9:00:00 AM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "12/19/2023 9:15",
    "plant_id": "Green-tek-A12328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "9:15:00 AM",
    "power": 0.339,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "12/19/2023 9:30",
    "plant_id": "Green-tek-A12329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "9:30:00 AM",
    "power": 0.411,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "12/19/2023 9:45",
    "plant_id": "Green-tek-A12330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "9:45:00 AM",
    "power": 0.489,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "12/19/2023 10:00",
    "plant_id": "Green-tek-A12331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "10:00:00 AM",
    "power": 0.57,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "12/19/2023 10:15",
    "plant_id": "Green-tek-A12332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "10:15:00 AM",
    "power": 0.651,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "12/19/2023 10:30",
    "plant_id": "Green-tek-A12333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "10:30:00 AM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "12/19/2023 10:45",
    "plant_id": "Green-tek-A12334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "10:45:00 AM",
    "power": 0.798,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "12/19/2023 11:00",
    "plant_id": "Green-tek-A12335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "11:00:00 AM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "12/19/2023 11:15",
    "plant_id": "Green-tek-A12336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "11:15:00 AM",
    "power": 0.902,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "12/19/2023 11:30",
    "plant_id": "Green-tek-A12337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "11:30:00 AM",
    "power": 0.93,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "12/19/2023 11:45",
    "plant_id": "Green-tek-A12338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "11:45:00 AM",
    "power": 0.94,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "12/19/2023 12:00",
    "plant_id": "Green-tek-A12339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "12:00:00 PM",
    "power": 0.93,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "12/19/2023 12:15",
    "plant_id": "Green-tek-A12340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "12:15:00 PM",
    "power": 0.902,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "12/19/2023 12:30",
    "plant_id": "Green-tek-A12341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "12:30:00 PM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "12/19/2023 12:45",
    "plant_id": "Green-tek-A12342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "12:45:00 PM",
    "power": 0.798,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "12/19/2023 13:00",
    "plant_id": "Green-tek-A12343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "1:00:00 PM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "12/19/2023 13:15",
    "plant_id": "Green-tek-A12344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "1:15:00 PM",
    "power": 0.651,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "12/19/2023 13:30",
    "plant_id": "Green-tek-A12345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "1:30:00 PM",
    "power": 0.57,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "12/19/2023 13:45",
    "plant_id": "Green-tek-A12346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "1:45:00 PM",
    "power": 0.489,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "12/19/2023 14:00",
    "plant_id": "Green-tek-A12347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "2:00:00 PM",
    "power": 0.411,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "12/19/2023 14:15",
    "plant_id": "Green-tek-A12348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "2:15:00 PM",
    "power": 0.339,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "12/19/2023 14:30",
    "plant_id": "Green-tek-A12349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "2:30:00 PM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "12/19/2023 14:45",
    "plant_id": "Green-tek-A12350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "2:45:00 PM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "12/19/2023 15:00",
    "plant_id": "Green-tek-A12351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "3:00:00 PM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "12/19/2023 15:15",
    "plant_id": "Green-tek-A12352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "3:15:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "12/19/2023 15:30",
    "plant_id": "Green-tek-A12353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "3:30:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/19/2023 15:45",
    "plant_id": "Green-tek-A12354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "3:45:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/19/2023 16:00",
    "plant_id": "Green-tek-A12355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/19/2023 16:15",
    "plant_id": "Green-tek-A12356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/19/2023 16:30",
    "plant_id": "Green-tek-A12357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/19/2023 16:45",
    "plant_id": "Green-tek-A12358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/19/2023 17:00",
    "plant_id": "Green-tek-A12359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/19/2023 17:15",
    "plant_id": "Green-tek-A12360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/19/2023 17:30",
    "plant_id": "Green-tek-A12361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/19/2023",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/20/2023 6:00",
    "plant_id": "Green-tek-A12362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/20/2023 6:15",
    "plant_id": "Green-tek-A12363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/20/2023 6:30",
    "plant_id": "Green-tek-A12364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/20/2023 6:45",
    "plant_id": "Green-tek-A12365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/20/2023 7:00",
    "plant_id": "Green-tek-A12366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/20/2023 7:15",
    "plant_id": "Green-tek-A12367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/20/2023 7:30",
    "plant_id": "Green-tek-A12368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/20/2023 7:45",
    "plant_id": "Green-tek-A12369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "12/20/2023 8:00",
    "plant_id": "Green-tek-A12370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "8:00:00 AM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/20/2023 8:15",
    "plant_id": "Green-tek-A12371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "12/20/2023 8:30",
    "plant_id": "Green-tek-A12372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "8:30:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "12/20/2023 8:45",
    "plant_id": "Green-tek-A12373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "8:45:00 AM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "12/20/2023 9:00",
    "plant_id": "Green-tek-A12374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "9:00:00 AM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "12/20/2023 9:15",
    "plant_id": "Green-tek-A12375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "9:15:00 AM",
    "power": 0.346,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "12/20/2023 9:30",
    "plant_id": "Green-tek-A12376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "9:30:00 AM",
    "power": 0.42,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "12/20/2023 9:45",
    "plant_id": "Green-tek-A12377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "9:45:00 AM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "12/20/2023 10:00",
    "plant_id": "Green-tek-A12378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "10:00:00 AM",
    "power": 0.582,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "12/20/2023 10:15",
    "plant_id": "Green-tek-A12379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "10:15:00 AM",
    "power": 0.664,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "12/20/2023 10:30",
    "plant_id": "Green-tek-A12380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "10:30:00 AM",
    "power": 0.743,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "12/20/2023 10:45",
    "plant_id": "Green-tek-A12381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "10:45:00 AM",
    "power": 0.814,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "12/20/2023 11:00",
    "plant_id": "Green-tek-A12382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "11:00:00 AM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "12/20/2023 11:15",
    "plant_id": "Green-tek-A12383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "11:15:00 AM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "12/20/2023 11:30",
    "plant_id": "Green-tek-A12384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "11:30:00 AM",
    "power": 0.949,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "12/20/2023 11:45",
    "plant_id": "Green-tek-A12385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "11:45:00 AM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "12/20/2023 12:00",
    "plant_id": "Green-tek-A12386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "12:00:00 PM",
    "power": 0.949,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "12/20/2023 12:15",
    "plant_id": "Green-tek-A12387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "12:15:00 PM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "12/20/2023 12:30",
    "plant_id": "Green-tek-A12388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "12:30:00 PM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "12/20/2023 12:45",
    "plant_id": "Green-tek-A12389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "12:45:00 PM",
    "power": 0.814,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "12/20/2023 13:00",
    "plant_id": "Green-tek-A12390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "1:00:00 PM",
    "power": 0.743,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "12/20/2023 13:15",
    "plant_id": "Green-tek-A12391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "1:15:00 PM",
    "power": 0.664,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "12/20/2023 13:30",
    "plant_id": "Green-tek-A12392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "1:30:00 PM",
    "power": 0.582,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "12/20/2023 13:45",
    "plant_id": "Green-tek-A12393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "1:45:00 PM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "12/20/2023 14:00",
    "plant_id": "Green-tek-A12394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "2:00:00 PM",
    "power": 0.42,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "12/20/2023 14:15",
    "plant_id": "Green-tek-A12395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "2:15:00 PM",
    "power": 0.346,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "12/20/2023 14:30",
    "plant_id": "Green-tek-A12396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "2:30:00 PM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "12/20/2023 14:45",
    "plant_id": "Green-tek-A12397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "2:45:00 PM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "12/20/2023 15:00",
    "plant_id": "Green-tek-A12398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "3:00:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "12/20/2023 15:15",
    "plant_id": "Green-tek-A12399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "12/20/2023 15:30",
    "plant_id": "Green-tek-A12400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "3:30:00 PM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/20/2023 15:45",
    "plant_id": "Green-tek-A12401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "12/20/2023 16:00",
    "plant_id": "Green-tek-A12402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/20/2023 16:15",
    "plant_id": "Green-tek-A12403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/20/2023 16:30",
    "plant_id": "Green-tek-A12404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/20/2023 16:45",
    "plant_id": "Green-tek-A12405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/20/2023 17:00",
    "plant_id": "Green-tek-A12406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/20/2023 17:15",
    "plant_id": "Green-tek-A12407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/20/2023 17:30",
    "plant_id": "Green-tek-A12408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/20/2023",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/21/2023 6:00",
    "plant_id": "Green-tek-A12409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/21/2023 6:15",
    "plant_id": "Green-tek-A12410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/21/2023 6:30",
    "plant_id": "Green-tek-A12411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/21/2023 6:45",
    "plant_id": "Green-tek-A12412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "12/21/2023 7:00",
    "plant_id": "Green-tek-A12413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/21/2023 7:15",
    "plant_id": "Green-tek-A12414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "12/21/2023 7:30",
    "plant_id": "Green-tek-A12415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "7:30:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "12/21/2023 7:45",
    "plant_id": "Green-tek-A12416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "7:45:00 AM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "12/21/2023 8:00",
    "plant_id": "Green-tek-A12417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "8:00:00 AM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "12/21/2023 8:15",
    "plant_id": "Green-tek-A12418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "8:15:00 AM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "12/21/2023 8:30",
    "plant_id": "Green-tek-A12419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "8:30:00 AM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "12/21/2023 8:45",
    "plant_id": "Green-tek-A12420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "8:45:00 AM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "12/21/2023 9:00",
    "plant_id": "Green-tek-A12421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "9:00:00 AM",
    "power": 0.358,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "12/21/2023 9:15",
    "plant_id": "Green-tek-A12422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "9:15:00 AM",
    "power": 0.444,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "12/21/2023 9:30",
    "plant_id": "Green-tek-A12423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "9:30:00 AM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "12/21/2023 9:45",
    "plant_id": "Green-tek-A12424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "9:45:00 AM",
    "power": 0.641,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "12/21/2023 10:00",
    "plant_id": "Green-tek-A12425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "10:00:00 AM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "12/21/2023 10:15",
    "plant_id": "Green-tek-A12426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "10:15:00 AM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "12/21/2023 10:30",
    "plant_id": "Green-tek-A12427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "10:30:00 AM",
    "power": 0.954,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "12/21/2023 10:45",
    "plant_id": "Green-tek-A12428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "10:45:00 AM",
    "power": 1.045,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "12/21/2023 11:00",
    "plant_id": "Green-tek-A12429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "11:00:00 AM",
    "power": 1.123,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "12/21/2023 11:15",
    "plant_id": "Green-tek-A12430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "11:15:00 AM",
    "power": 1.182,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "12/21/2023 11:30",
    "plant_id": "Green-tek-A12431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "11:30:00 AM",
    "power": 1.218,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "12/21/2023 11:45",
    "plant_id": "Green-tek-A12432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "11:45:00 AM",
    "power": 1.231,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "12/21/2023 12:00",
    "plant_id": "Green-tek-A12433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "12:00:00 PM",
    "power": 1.218,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "12/21/2023 12:15",
    "plant_id": "Green-tek-A12434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "12:15:00 PM",
    "power": 1.182,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "12/21/2023 12:30",
    "plant_id": "Green-tek-A12435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "12:30:00 PM",
    "power": 1.123,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "12/21/2023 12:45",
    "plant_id": "Green-tek-A12436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "12:45:00 PM",
    "power": 1.045,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "12/21/2023 13:00",
    "plant_id": "Green-tek-A12437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "1:00:00 PM",
    "power": 0.954,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "12/21/2023 13:15",
    "plant_id": "Green-tek-A12438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "1:15:00 PM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "12/21/2023 13:30",
    "plant_id": "Green-tek-A12439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "1:30:00 PM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "12/21/2023 13:45",
    "plant_id": "Green-tek-A12440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "1:45:00 PM",
    "power": 0.641,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "12/21/2023 14:00",
    "plant_id": "Green-tek-A12441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "2:00:00 PM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "12/21/2023 14:15",
    "plant_id": "Green-tek-A12442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "2:15:00 PM",
    "power": 0.444,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "12/21/2023 14:30",
    "plant_id": "Green-tek-A12443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "2:30:00 PM",
    "power": 0.358,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "12/21/2023 14:45",
    "plant_id": "Green-tek-A12444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "2:45:00 PM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "12/21/2023 15:00",
    "plant_id": "Green-tek-A12445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "3:00:00 PM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "12/21/2023 15:15",
    "plant_id": "Green-tek-A12446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "3:15:00 PM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "12/21/2023 15:30",
    "plant_id": "Green-tek-A12447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "3:30:00 PM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "12/21/2023 15:45",
    "plant_id": "Green-tek-A12448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "3:45:00 PM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "12/21/2023 16:00",
    "plant_id": "Green-tek-A12449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "4:00:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "12/21/2023 16:15",
    "plant_id": "Green-tek-A12450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "12/21/2023 16:30",
    "plant_id": "Green-tek-A12451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/21/2023 16:45",
    "plant_id": "Green-tek-A12452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "12/21/2023 17:00",
    "plant_id": "Green-tek-A12453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/21/2023 17:15",
    "plant_id": "Green-tek-A12454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/21/2023 17:30",
    "plant_id": "Green-tek-A12455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/21/2023",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/22/2023 6:00",
    "plant_id": "Green-tek-A12456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/22/2023 6:15",
    "plant_id": "Green-tek-A12457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/22/2023 6:30",
    "plant_id": "Green-tek-A12458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/22/2023 6:45",
    "plant_id": "Green-tek-A12459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/22/2023 7:00",
    "plant_id": "Green-tek-A12460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/22/2023 7:15",
    "plant_id": "Green-tek-A12461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/22/2023 7:30",
    "plant_id": "Green-tek-A12462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/22/2023 7:45",
    "plant_id": "Green-tek-A12463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "12/22/2023 8:00",
    "plant_id": "Green-tek-A12464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "8:00:00 AM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "12/22/2023 8:15",
    "plant_id": "Green-tek-A12465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "8:15:00 AM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "12/22/2023 8:30",
    "plant_id": "Green-tek-A12466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "8:30:00 AM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "12/22/2023 8:45",
    "plant_id": "Green-tek-A12467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "8:45:00 AM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "12/22/2023 9:00",
    "plant_id": "Green-tek-A12468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "9:00:00 AM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "12/22/2023 9:15",
    "plant_id": "Green-tek-A12469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "9:15:00 AM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "12/22/2023 9:30",
    "plant_id": "Green-tek-A12470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "9:30:00 AM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "12/22/2023 9:45",
    "plant_id": "Green-tek-A12471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "9:45:00 AM",
    "power": 0.524,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "12/22/2023 10:00",
    "plant_id": "Green-tek-A12472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "10:00:00 AM",
    "power": 0.61,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "12/22/2023 10:15",
    "plant_id": "Green-tek-A12473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "10:15:00 AM",
    "power": 0.697,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "12/22/2023 10:30",
    "plant_id": "Green-tek-A12474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "10:30:00 AM",
    "power": 0.78,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "12/22/2023 10:45",
    "plant_id": "Green-tek-A12475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "10:45:00 AM",
    "power": 0.854,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "12/22/2023 11:00",
    "plant_id": "Green-tek-A12476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "11:00:00 AM",
    "power": 0.918,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "12/22/2023 11:15",
    "plant_id": "Green-tek-A12477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "11:15:00 AM",
    "power": 0.966,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "12/22/2023 11:30",
    "plant_id": "Green-tek-A12478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "11:30:00 AM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "12/22/2023 11:45",
    "plant_id": "Green-tek-A12479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "11:45:00 AM",
    "power": 1.006,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "12/22/2023 12:00",
    "plant_id": "Green-tek-A12480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "12:00:00 PM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "12/22/2023 12:15",
    "plant_id": "Green-tek-A12481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "12:15:00 PM",
    "power": 0.966,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "12/22/2023 12:30",
    "plant_id": "Green-tek-A12482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "12:30:00 PM",
    "power": 0.918,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "12/22/2023 12:45",
    "plant_id": "Green-tek-A12483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "12:45:00 PM",
    "power": 0.854,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "12/22/2023 13:00",
    "plant_id": "Green-tek-A12484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "1:00:00 PM",
    "power": 0.78,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "12/22/2023 13:15",
    "plant_id": "Green-tek-A12485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "1:15:00 PM",
    "power": 0.697,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "12/22/2023 13:30",
    "plant_id": "Green-tek-A12486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "1:30:00 PM",
    "power": 0.61,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "12/22/2023 13:45",
    "plant_id": "Green-tek-A12487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "1:45:00 PM",
    "power": 0.524,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "12/22/2023 14:00",
    "plant_id": "Green-tek-A12488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "2:00:00 PM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "12/22/2023 14:15",
    "plant_id": "Green-tek-A12489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "2:15:00 PM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "12/22/2023 14:30",
    "plant_id": "Green-tek-A12490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "2:30:00 PM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "12/22/2023 14:45",
    "plant_id": "Green-tek-A12491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "2:45:00 PM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "12/22/2023 15:00",
    "plant_id": "Green-tek-A12492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "3:00:00 PM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "12/22/2023 15:15",
    "plant_id": "Green-tek-A12493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "3:15:00 PM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "12/22/2023 15:30",
    "plant_id": "Green-tek-A12494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "3:30:00 PM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "12/22/2023 15:45",
    "plant_id": "Green-tek-A12495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "12/22/2023 16:00",
    "plant_id": "Green-tek-A12496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/22/2023 16:15",
    "plant_id": "Green-tek-A12497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/22/2023 16:30",
    "plant_id": "Green-tek-A12498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/22/2023 16:45",
    "plant_id": "Green-tek-A12499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/22/2023 17:00",
    "plant_id": "Green-tek-A12500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/22/2023 17:15",
    "plant_id": "Green-tek-A12501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/22/2023 17:30",
    "plant_id": "Green-tek-A12502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/22/2023",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/23/2023 6:00",
    "plant_id": "Green-tek-A12503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/23/2023 6:15",
    "plant_id": "Green-tek-A12504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/23/2023 6:30",
    "plant_id": "Green-tek-A12505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/23/2023 6:45",
    "plant_id": "Green-tek-A12506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/23/2023 7:00",
    "plant_id": "Green-tek-A12507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/23/2023 7:15",
    "plant_id": "Green-tek-A12508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/23/2023 7:30",
    "plant_id": "Green-tek-A12509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/23/2023 7:45",
    "plant_id": "Green-tek-A12510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "12/23/2023 8:00",
    "plant_id": "Green-tek-A12511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "8:00:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/23/2023 8:15",
    "plant_id": "Green-tek-A12512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "12/23/2023 8:30",
    "plant_id": "Green-tek-A12513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "8:30:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "12/23/2023 8:45",
    "plant_id": "Green-tek-A12514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "8:45:00 AM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "12/23/2023 9:00",
    "plant_id": "Green-tek-A12515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "9:00:00 AM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "12/23/2023 9:15",
    "plant_id": "Green-tek-A12516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "9:15:00 AM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "12/23/2023 9:30",
    "plant_id": "Green-tek-A12517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "9:30:00 AM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "12/23/2023 9:45",
    "plant_id": "Green-tek-A12518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "9:45:00 AM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "12/23/2023 10:00",
    "plant_id": "Green-tek-A12519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "10:00:00 AM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "12/23/2023 10:15",
    "plant_id": "Green-tek-A12520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "10:15:00 AM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "12/23/2023 10:30",
    "plant_id": "Green-tek-A12521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "10:30:00 AM",
    "power": 0.742,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "12/23/2023 10:45",
    "plant_id": "Green-tek-A12522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "10:45:00 AM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "12/23/2023 11:00",
    "plant_id": "Green-tek-A12523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "11:00:00 AM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "12/23/2023 11:15",
    "plant_id": "Green-tek-A12524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "11:15:00 AM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "12/23/2023 11:30",
    "plant_id": "Green-tek-A12525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "11:30:00 AM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "12/23/2023 11:45",
    "plant_id": "Green-tek-A12526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "11:45:00 AM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "12/23/2023 12:00",
    "plant_id": "Green-tek-A12527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "12:00:00 PM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "12/23/2023 12:15",
    "plant_id": "Green-tek-A12528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "12:15:00 PM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "12/23/2023 12:30",
    "plant_id": "Green-tek-A12529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "12:30:00 PM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "12/23/2023 12:45",
    "plant_id": "Green-tek-A12530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "12:45:00 PM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "12/23/2023 13:00",
    "plant_id": "Green-tek-A12531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "1:00:00 PM",
    "power": 0.742,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "12/23/2023 13:15",
    "plant_id": "Green-tek-A12532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "1:15:00 PM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "12/23/2023 13:30",
    "plant_id": "Green-tek-A12533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "1:30:00 PM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "12/23/2023 13:45",
    "plant_id": "Green-tek-A12534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "1:45:00 PM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "12/23/2023 14:00",
    "plant_id": "Green-tek-A12535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "2:00:00 PM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "12/23/2023 14:15",
    "plant_id": "Green-tek-A12536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "2:15:00 PM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "12/23/2023 14:30",
    "plant_id": "Green-tek-A12537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "2:30:00 PM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "12/23/2023 14:45",
    "plant_id": "Green-tek-A12538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "2:45:00 PM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "12/23/2023 15:00",
    "plant_id": "Green-tek-A12539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "3:00:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "12/23/2023 15:15",
    "plant_id": "Green-tek-A12540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "12/23/2023 15:30",
    "plant_id": "Green-tek-A12541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "3:30:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/23/2023 15:45",
    "plant_id": "Green-tek-A12542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "12/23/2023 16:00",
    "plant_id": "Green-tek-A12543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/23/2023 16:15",
    "plant_id": "Green-tek-A12544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/23/2023 16:30",
    "plant_id": "Green-tek-A12545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/23/2023 16:45",
    "plant_id": "Green-tek-A12546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/23/2023 17:00",
    "plant_id": "Green-tek-A12547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/23/2023 17:15",
    "plant_id": "Green-tek-A12548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/23/2023 17:30",
    "plant_id": "Green-tek-A12549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/23/2023",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/24/2023 6:00",
    "plant_id": "Green-tek-A12550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/24/2023 6:15",
    "plant_id": "Green-tek-A12551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/24/2023 6:30",
    "plant_id": "Green-tek-A12552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/24/2023 6:45",
    "plant_id": "Green-tek-A12553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/24/2023 7:00",
    "plant_id": "Green-tek-A12554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/24/2023 7:15",
    "plant_id": "Green-tek-A12555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/24/2023 7:30",
    "plant_id": "Green-tek-A12556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/24/2023 7:45",
    "plant_id": "Green-tek-A12557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "7:45:00 AM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "12/24/2023 8:00",
    "plant_id": "Green-tek-A12558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "8:00:00 AM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "12/24/2023 8:15",
    "plant_id": "Green-tek-A12559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "8:15:00 AM",
    "power": 0.134,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "12/24/2023 8:30",
    "plant_id": "Green-tek-A12560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "8:30:00 AM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "12/24/2023 8:45",
    "plant_id": "Green-tek-A12561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "8:45:00 AM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "12/24/2023 9:00",
    "plant_id": "Green-tek-A12562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "9:00:00 AM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "12/24/2023 9:15",
    "plant_id": "Green-tek-A12563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "9:15:00 AM",
    "power": 0.358,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "12/24/2023 9:30",
    "plant_id": "Green-tek-A12564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "9:30:00 AM",
    "power": 0.434,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "12/24/2023 9:45",
    "plant_id": "Green-tek-A12565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "9:45:00 AM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "12/24/2023 10:00",
    "plant_id": "Green-tek-A12566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "10:00:00 AM",
    "power": 0.602,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "12/24/2023 10:15",
    "plant_id": "Green-tek-A12567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "10:15:00 AM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "12/24/2023 10:30",
    "plant_id": "Green-tek-A12568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "10:30:00 AM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "12/24/2023 10:45",
    "plant_id": "Green-tek-A12569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "10:45:00 AM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "12/24/2023 11:00",
    "plant_id": "Green-tek-A12570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "11:00:00 AM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "12/24/2023 11:15",
    "plant_id": "Green-tek-A12571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "11:15:00 AM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "12/24/2023 11:30",
    "plant_id": "Green-tek-A12572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "11:30:00 AM",
    "power": 0.983,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "12/24/2023 11:45",
    "plant_id": "Green-tek-A12573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "11:45:00 AM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "12/24/2023 12:00",
    "plant_id": "Green-tek-A12574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "12:00:00 PM",
    "power": 0.983,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "12/24/2023 12:15",
    "plant_id": "Green-tek-A12575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "12:15:00 PM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "12/24/2023 12:30",
    "plant_id": "Green-tek-A12576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "12:30:00 PM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "12/24/2023 12:45",
    "plant_id": "Green-tek-A12577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "12:45:00 PM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "12/24/2023 13:00",
    "plant_id": "Green-tek-A12578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "1:00:00 PM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "12/24/2023 13:15",
    "plant_id": "Green-tek-A12579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "1:15:00 PM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "12/24/2023 13:30",
    "plant_id": "Green-tek-A12580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "1:30:00 PM",
    "power": 0.602,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "12/24/2023 13:45",
    "plant_id": "Green-tek-A12581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "1:45:00 PM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "12/24/2023 14:00",
    "plant_id": "Green-tek-A12582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "2:00:00 PM",
    "power": 0.434,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "12/24/2023 14:15",
    "plant_id": "Green-tek-A12583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "2:15:00 PM",
    "power": 0.358,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "12/24/2023 14:30",
    "plant_id": "Green-tek-A12584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "2:30:00 PM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "12/24/2023 14:45",
    "plant_id": "Green-tek-A12585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "2:45:00 PM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "12/24/2023 15:00",
    "plant_id": "Green-tek-A12586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "3:00:00 PM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "12/24/2023 15:15",
    "plant_id": "Green-tek-A12587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "3:15:00 PM",
    "power": 0.134,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "12/24/2023 15:30",
    "plant_id": "Green-tek-A12588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "3:30:00 PM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "12/24/2023 15:45",
    "plant_id": "Green-tek-A12589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "3:45:00 PM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "12/24/2023 16:00",
    "plant_id": "Green-tek-A12590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/24/2023 16:15",
    "plant_id": "Green-tek-A12591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/24/2023 16:30",
    "plant_id": "Green-tek-A12592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/24/2023 16:45",
    "plant_id": "Green-tek-A12593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/24/2023 17:00",
    "plant_id": "Green-tek-A12594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/24/2023 17:15",
    "plant_id": "Green-tek-A12595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/24/2023 17:30",
    "plant_id": "Green-tek-A12596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/24/2023",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/25/2023 6:00",
    "plant_id": "Green-tek-A12597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/25/2023 6:15",
    "plant_id": "Green-tek-A12598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/25/2023 6:30",
    "plant_id": "Green-tek-A12599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/25/2023 6:45",
    "plant_id": "Green-tek-A12600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/25/2023 7:00",
    "plant_id": "Green-tek-A12601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "12/25/2023 7:15",
    "plant_id": "Green-tek-A12602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/25/2023 7:30",
    "plant_id": "Green-tek-A12603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "7:30:00 AM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "12/25/2023 7:45",
    "plant_id": "Green-tek-A12604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "7:45:00 AM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "12/25/2023 8:00",
    "plant_id": "Green-tek-A12605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "8:00:00 AM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "12/25/2023 8:15",
    "plant_id": "Green-tek-A12606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "8:15:00 AM",
    "power": 0.14,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "12/25/2023 8:30",
    "plant_id": "Green-tek-A12607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "8:30:00 AM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "12/25/2023 8:45",
    "plant_id": "Green-tek-A12608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "8:45:00 AM",
    "power": 0.238,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "12/25/2023 9:00",
    "plant_id": "Green-tek-A12609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "9:00:00 AM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "12/25/2023 9:15",
    "plant_id": "Green-tek-A12610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "9:15:00 AM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "12/25/2023 9:30",
    "plant_id": "Green-tek-A12611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "9:30:00 AM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "12/25/2023 9:45",
    "plant_id": "Green-tek-A12612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "9:45:00 AM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "12/25/2023 10:00",
    "plant_id": "Green-tek-A12613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "10:00:00 AM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "12/25/2023 10:15",
    "plant_id": "Green-tek-A12614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "10:15:00 AM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "12/25/2023 10:30",
    "plant_id": "Green-tek-A12615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "10:30:00 AM",
    "power": 0.802,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "12/25/2023 10:45",
    "plant_id": "Green-tek-A12616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "10:45:00 AM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "12/25/2023 11:00",
    "plant_id": "Green-tek-A12617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "11:00:00 AM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "12/25/2023 11:15",
    "plant_id": "Green-tek-A12618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "11:15:00 AM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "12/25/2023 11:30",
    "plant_id": "Green-tek-A12619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "11:30:00 AM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "12/25/2023 11:45",
    "plant_id": "Green-tek-A12620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "11:45:00 AM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "12/25/2023 12:00",
    "plant_id": "Green-tek-A12621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "12:00:00 PM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "12/25/2023 12:15",
    "plant_id": "Green-tek-A12622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "12:15:00 PM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "12/25/2023 12:30",
    "plant_id": "Green-tek-A12623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "12:30:00 PM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "12/25/2023 12:45",
    "plant_id": "Green-tek-A12624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "12:45:00 PM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "12/25/2023 13:00",
    "plant_id": "Green-tek-A12625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "1:00:00 PM",
    "power": 0.802,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "12/25/2023 13:15",
    "plant_id": "Green-tek-A12626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "1:15:00 PM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "12/25/2023 13:30",
    "plant_id": "Green-tek-A12627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "1:30:00 PM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "12/25/2023 13:45",
    "plant_id": "Green-tek-A12628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "1:45:00 PM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "12/25/2023 14:00",
    "plant_id": "Green-tek-A12629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "2:00:00 PM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "12/25/2023 14:15",
    "plant_id": "Green-tek-A12630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "2:15:00 PM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "12/25/2023 14:30",
    "plant_id": "Green-tek-A12631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "2:30:00 PM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "12/25/2023 14:45",
    "plant_id": "Green-tek-A12632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "2:45:00 PM",
    "power": 0.238,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "12/25/2023 15:00",
    "plant_id": "Green-tek-A12633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "3:00:00 PM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "12/25/2023 15:15",
    "plant_id": "Green-tek-A12634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "3:15:00 PM",
    "power": 0.14,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "12/25/2023 15:30",
    "plant_id": "Green-tek-A12635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "3:30:00 PM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "12/25/2023 15:45",
    "plant_id": "Green-tek-A12636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "3:45:00 PM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "12/25/2023 16:00",
    "plant_id": "Green-tek-A12637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "4:00:00 PM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "12/25/2023 16:15",
    "plant_id": "Green-tek-A12638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/25/2023 16:30",
    "plant_id": "Green-tek-A12639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "12/25/2023 16:45",
    "plant_id": "Green-tek-A12640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/25/2023 17:00",
    "plant_id": "Green-tek-A12641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/25/2023 17:15",
    "plant_id": "Green-tek-A12642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/25/2023 17:30",
    "plant_id": "Green-tek-A12643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/25/2023",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/26/2023 6:00",
    "plant_id": "Green-tek-A12644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/26/2023 6:15",
    "plant_id": "Green-tek-A12645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/26/2023 6:30",
    "plant_id": "Green-tek-A12646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/26/2023 6:45",
    "plant_id": "Green-tek-A12647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/26/2023 7:00",
    "plant_id": "Green-tek-A12648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/26/2023 7:15",
    "plant_id": "Green-tek-A12649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/26/2023 7:30",
    "plant_id": "Green-tek-A12650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "7:30:00 AM",
    "power": 0.054,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/26/2023 7:45",
    "plant_id": "Green-tek-A12651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "7:45:00 AM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "12/26/2023 8:00",
    "plant_id": "Green-tek-A12652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "8:00:00 AM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "12/26/2023 8:15",
    "plant_id": "Green-tek-A12653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "8:15:00 AM",
    "power": 0.139,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "12/26/2023 8:30",
    "plant_id": "Green-tek-A12654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "8:30:00 AM",
    "power": 0.183,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "12/26/2023 8:45",
    "plant_id": "Green-tek-A12655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "8:45:00 AM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "12/26/2023 9:00",
    "plant_id": "Green-tek-A12656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "9:00:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "12/26/2023 9:15",
    "plant_id": "Green-tek-A12657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "9:15:00 AM",
    "power": 0.371,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "12/26/2023 9:30",
    "plant_id": "Green-tek-A12658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "9:30:00 AM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "12/26/2023 9:45",
    "plant_id": "Green-tek-A12659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "9:45:00 AM",
    "power": 0.535,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "12/26/2023 10:00",
    "plant_id": "Green-tek-A12660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "10:00:00 AM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "12/26/2023 10:15",
    "plant_id": "Green-tek-A12661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "10:15:00 AM",
    "power": 0.712,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "12/26/2023 10:30",
    "plant_id": "Green-tek-A12662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "10:30:00 AM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "12/26/2023 10:45",
    "plant_id": "Green-tek-A12663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "10:45:00 AM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "12/26/2023 11:00",
    "plant_id": "Green-tek-A12664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "11:00:00 AM",
    "power": 0.938,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "12/26/2023 11:15",
    "plant_id": "Green-tek-A12665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "11:15:00 AM",
    "power": 0.987,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "12/26/2023 11:30",
    "plant_id": "Green-tek-A12666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "11:30:00 AM",
    "power": 1.018,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "12/26/2023 11:45",
    "plant_id": "Green-tek-A12667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "11:45:00 AM",
    "power": 1.028,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "12/26/2023 12:00",
    "plant_id": "Green-tek-A12668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "12:00:00 PM",
    "power": 1.018,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "12/26/2023 12:15",
    "plant_id": "Green-tek-A12669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "12:15:00 PM",
    "power": 0.987,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "12/26/2023 12:30",
    "plant_id": "Green-tek-A12670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "12:30:00 PM",
    "power": 0.938,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "12/26/2023 12:45",
    "plant_id": "Green-tek-A12671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "12:45:00 PM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "12/26/2023 13:00",
    "plant_id": "Green-tek-A12672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "1:00:00 PM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "12/26/2023 13:15",
    "plant_id": "Green-tek-A12673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "1:15:00 PM",
    "power": 0.712,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "12/26/2023 13:30",
    "plant_id": "Green-tek-A12674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "1:30:00 PM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "12/26/2023 13:45",
    "plant_id": "Green-tek-A12675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "1:45:00 PM",
    "power": 0.535,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "12/26/2023 14:00",
    "plant_id": "Green-tek-A12676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "2:00:00 PM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "12/26/2023 14:15",
    "plant_id": "Green-tek-A12677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "2:15:00 PM",
    "power": 0.371,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "12/26/2023 14:30",
    "plant_id": "Green-tek-A12678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "2:30:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "12/26/2023 14:45",
    "plant_id": "Green-tek-A12679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "2:45:00 PM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "12/26/2023 15:00",
    "plant_id": "Green-tek-A12680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "3:00:00 PM",
    "power": 0.183,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "12/26/2023 15:15",
    "plant_id": "Green-tek-A12681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "3:15:00 PM",
    "power": 0.139,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "12/26/2023 15:30",
    "plant_id": "Green-tek-A12682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "3:30:00 PM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "12/26/2023 15:45",
    "plant_id": "Green-tek-A12683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "3:45:00 PM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "12/26/2023 16:00",
    "plant_id": "Green-tek-A12684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "4:00:00 PM",
    "power": 0.054,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "12/26/2023 16:15",
    "plant_id": "Green-tek-A12685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "12/26/2023 16:30",
    "plant_id": "Green-tek-A12686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/26/2023 16:45",
    "plant_id": "Green-tek-A12687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/26/2023 17:00",
    "plant_id": "Green-tek-A12688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/26/2023 17:15",
    "plant_id": "Green-tek-A12689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/26/2023 17:30",
    "plant_id": "Green-tek-A12690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/26/2023",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/27/2023 6:00",
    "plant_id": "Green-tek-A12691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/27/2023 6:15",
    "plant_id": "Green-tek-A12692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/27/2023 6:30",
    "plant_id": "Green-tek-A12693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "6:30:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/27/2023 6:45",
    "plant_id": "Green-tek-A12694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/27/2023 7:00",
    "plant_id": "Green-tek-A12695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/27/2023 7:15",
    "plant_id": "Green-tek-A12696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/27/2023 7:30",
    "plant_id": "Green-tek-A12697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "7:30:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/27/2023 7:45",
    "plant_id": "Green-tek-A12698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "7:45:00 AM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/27/2023 8:00",
    "plant_id": "Green-tek-A12699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "8:00:00 AM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "12/27/2023 8:15",
    "plant_id": "Green-tek-A12700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "8:15:00 AM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "12/27/2023 8:30",
    "plant_id": "Green-tek-A12701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "8:30:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "12/27/2023 8:45",
    "plant_id": "Green-tek-A12702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "8:45:00 AM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "12/27/2023 9:00",
    "plant_id": "Green-tek-A12703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "9:00:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "12/27/2023 9:15",
    "plant_id": "Green-tek-A12704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "9:15:00 AM",
    "power": 0.476,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "12/27/2023 9:30",
    "plant_id": "Green-tek-A12705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "9:30:00 AM",
    "power": 0.578,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "12/27/2023 9:45",
    "plant_id": "Green-tek-A12706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "9:45:00 AM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "12/27/2023 10:00",
    "plant_id": "Green-tek-A12707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "10:00:00 AM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "12/27/2023 10:15",
    "plant_id": "Green-tek-A12708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "10:15:00 AM",
    "power": 0.914,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "12/27/2023 10:30",
    "plant_id": "Green-tek-A12709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "10:30:00 AM",
    "power": 1.023,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "12/27/2023 10:45",
    "plant_id": "Green-tek-A12710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "10:45:00 AM",
    "power": 1.121,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "12/27/2023 11:00",
    "plant_id": "Green-tek-A12711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "11:00:00 AM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "12/27/2023 11:15",
    "plant_id": "Green-tek-A12712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "11:15:00 AM",
    "power": 1.267,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.33354,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "12/27/2023 11:30",
    "plant_id": "Green-tek-A12713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "11:30:00 AM",
    "power": 1.307,
    "Energy": 0.327,
    "output Energy": 0.327,
    "Ac output": 1.327,
    "dc output": 1.35354,
    "AC_R_i": 0.00042652,
    "AC_S_i": 0.000469172,
    "AC_T_i": 0.00051609,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0980996,
    "AC_S_pow": 0.10790956,
    "AC_T_Pow": 0.1187007,
    "AC_R_output": 0.3981,
    "AC_S_output": 0.43791,
    "AC_T_output": 0.481701,
    "dc_current": 0.00240441,
    "dc_voltage": 136,
    "dc_power": 0.3366,
    "dc_output": 0.33354,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000327,
    "carbon offset": 0.40875,
    "trees planted": 9.083333333,
    "DC_R_output": 0.131373,
    "DC_S_output": 0.1445103,
    "DC_T_output": 0.15896133
  },
  {
    "datetime": "12/27/2023 11:45",
    "plant_id": "Green-tek-A12714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "11:45:00 AM",
    "power": 1.32,
    "Energy": 0.33,
    "output Energy": 0.33,
    "Ac output": 1.33,
    "dc output": 1.3566,
    "AC_R_i": 0.00043043,
    "AC_S_i": 0.000473473,
    "AC_T_i": 0.00052082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0989989,
    "AC_S_pow": 0.10889879,
    "AC_T_Pow": 0.1197886,
    "AC_R_output": 0.399,
    "AC_S_output": 0.4389,
    "AC_T_output": 0.48279,
    "dc_current": 0.00242647,
    "dc_voltage": 136,
    "dc_power": 0.33354,
    "dc_output": 0.3366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00033,
    "carbon offset": 0.4125,
    "trees planted": 9.166666667,
    "DC_R_output": 0.13167,
    "DC_S_output": 0.144837,
    "DC_T_output": 0.1593207
  },
  {
    "datetime": "12/27/2023 12:00",
    "plant_id": "Green-tek-A12715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "12:00:00 PM",
    "power": 1.307,
    "Energy": 0.327,
    "output Energy": 0.327,
    "Ac output": 1.327,
    "dc output": 1.35354,
    "AC_R_i": 0.00042652,
    "AC_S_i": 0.000469172,
    "AC_T_i": 0.00051609,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0980996,
    "AC_S_pow": 0.10790956,
    "AC_T_Pow": 0.1187007,
    "AC_R_output": 0.3981,
    "AC_S_output": 0.43791,
    "AC_T_output": 0.481701,
    "dc_current": 0.00240441,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.33354,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000327,
    "carbon offset": 0.40875,
    "trees planted": 9.083333333,
    "DC_R_output": 0.131373,
    "DC_S_output": 0.1445103,
    "DC_T_output": 0.15896133
  },
  {
    "datetime": "12/27/2023 12:15",
    "plant_id": "Green-tek-A12716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "12:15:00 PM",
    "power": 1.267,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "12/27/2023 12:30",
    "plant_id": "Green-tek-A12717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "12:30:00 PM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "12/27/2023 12:45",
    "plant_id": "Green-tek-A12718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "12:45:00 PM",
    "power": 1.121,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "12/27/2023 13:00",
    "plant_id": "Green-tek-A12719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "1:00:00 PM",
    "power": 1.023,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "12/27/2023 13:15",
    "plant_id": "Green-tek-A12720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "1:15:00 PM",
    "power": 0.914,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "12/27/2023 13:30",
    "plant_id": "Green-tek-A12721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "1:30:00 PM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "12/27/2023 13:45",
    "plant_id": "Green-tek-A12722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "1:45:00 PM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "12/27/2023 14:00",
    "plant_id": "Green-tek-A12723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "2:00:00 PM",
    "power": 0.578,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "12/27/2023 14:15",
    "plant_id": "Green-tek-A12724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "2:15:00 PM",
    "power": 0.476,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "12/27/2023 14:30",
    "plant_id": "Green-tek-A12725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "2:30:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "12/27/2023 14:45",
    "plant_id": "Green-tek-A12726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "2:45:00 PM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "12/27/2023 15:00",
    "plant_id": "Green-tek-A12727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "3:00:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "12/27/2023 15:15",
    "plant_id": "Green-tek-A12728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "3:15:00 PM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "12/27/2023 15:30",
    "plant_id": "Green-tek-A12729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "3:30:00 PM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "12/27/2023 15:45",
    "plant_id": "Green-tek-A12730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "3:45:00 PM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "12/27/2023 16:00",
    "plant_id": "Green-tek-A12731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "4:00:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/27/2023 16:15",
    "plant_id": "Green-tek-A12732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/27/2023 16:30",
    "plant_id": "Green-tek-A12733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/27/2023 16:45",
    "plant_id": "Green-tek-A12734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/27/2023 17:00",
    "plant_id": "Green-tek-A12735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "5:00:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/27/2023 17:15",
    "plant_id": "Green-tek-A12736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/27/2023 17:30",
    "plant_id": "Green-tek-A12737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/27/2023",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/28/2023 6:00",
    "plant_id": "Green-tek-A12738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/28/2023 6:15",
    "plant_id": "Green-tek-A12739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/28/2023 6:30",
    "plant_id": "Green-tek-A12740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/28/2023 6:45",
    "plant_id": "Green-tek-A12741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/28/2023 7:00",
    "plant_id": "Green-tek-A12742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/28/2023 7:15",
    "plant_id": "Green-tek-A12743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/28/2023 7:30",
    "plant_id": "Green-tek-A12744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "12/28/2023 7:45",
    "plant_id": "Green-tek-A12745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "12/28/2023 8:00",
    "plant_id": "Green-tek-A12746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "8:00:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "12/28/2023 8:15",
    "plant_id": "Green-tek-A12747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "8:15:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "12/28/2023 8:30",
    "plant_id": "Green-tek-A12748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "8:30:00 AM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "12/28/2023 8:45",
    "plant_id": "Green-tek-A12749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "8:45:00 AM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "12/28/2023 9:00",
    "plant_id": "Green-tek-A12750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "9:00:00 AM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "12/28/2023 9:15",
    "plant_id": "Green-tek-A12751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "9:15:00 AM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "12/28/2023 9:30",
    "plant_id": "Green-tek-A12752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "9:30:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "12/28/2023 9:45",
    "plant_id": "Green-tek-A12753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "9:45:00 AM",
    "power": 0.457,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "12/28/2023 10:00",
    "plant_id": "Green-tek-A12754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "10:00:00 AM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "12/28/2023 10:15",
    "plant_id": "Green-tek-A12755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "10:15:00 AM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "12/28/2023 10:30",
    "plant_id": "Green-tek-A12756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "10:30:00 AM",
    "power": 0.68,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "12/28/2023 10:45",
    "plant_id": "Green-tek-A12757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "10:45:00 AM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "12/28/2023 11:00",
    "plant_id": "Green-tek-A12758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "11:00:00 AM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "12/28/2023 11:15",
    "plant_id": "Green-tek-A12759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "11:15:00 AM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "12/28/2023 11:30",
    "plant_id": "Green-tek-A12760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "11:30:00 AM",
    "power": 0.869,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "12/28/2023 11:45",
    "plant_id": "Green-tek-A12761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "11:45:00 AM",
    "power": 0.878,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "12/28/2023 12:00",
    "plant_id": "Green-tek-A12762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "12:00:00 PM",
    "power": 0.869,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "12/28/2023 12:15",
    "plant_id": "Green-tek-A12763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "12:15:00 PM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "12/28/2023 12:30",
    "plant_id": "Green-tek-A12764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "12:30:00 PM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "12/28/2023 12:45",
    "plant_id": "Green-tek-A12765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "12:45:00 PM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "12/28/2023 13:00",
    "plant_id": "Green-tek-A12766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "1:00:00 PM",
    "power": 0.68,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "12/28/2023 13:15",
    "plant_id": "Green-tek-A12767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "1:15:00 PM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "12/28/2023 13:30",
    "plant_id": "Green-tek-A12768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "1:30:00 PM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "12/28/2023 13:45",
    "plant_id": "Green-tek-A12769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "1:45:00 PM",
    "power": 0.457,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "12/28/2023 14:00",
    "plant_id": "Green-tek-A12770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "2:00:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "12/28/2023 14:15",
    "plant_id": "Green-tek-A12771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "2:15:00 PM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "12/28/2023 14:30",
    "plant_id": "Green-tek-A12772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "2:30:00 PM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "12/28/2023 14:45",
    "plant_id": "Green-tek-A12773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "2:45:00 PM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "12/28/2023 15:00",
    "plant_id": "Green-tek-A12774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "3:00:00 PM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "12/28/2023 15:15",
    "plant_id": "Green-tek-A12775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "3:15:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "12/28/2023 15:30",
    "plant_id": "Green-tek-A12776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "3:30:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "12/28/2023 15:45",
    "plant_id": "Green-tek-A12777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "12/28/2023 16:00",
    "plant_id": "Green-tek-A12778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "12/28/2023 16:15",
    "plant_id": "Green-tek-A12779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/28/2023 16:30",
    "plant_id": "Green-tek-A12780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/28/2023 16:45",
    "plant_id": "Green-tek-A12781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/28/2023 17:00",
    "plant_id": "Green-tek-A12782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/28/2023 17:15",
    "plant_id": "Green-tek-A12783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/28/2023 17:30",
    "plant_id": "Green-tek-A12784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/28/2023",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/29/2023 6:00",
    "plant_id": "Green-tek-A12785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/29/2023 6:15",
    "plant_id": "Green-tek-A12786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/29/2023 6:30",
    "plant_id": "Green-tek-A12787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/29/2023 6:45",
    "plant_id": "Green-tek-A12788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "12/29/2023 7:00",
    "plant_id": "Green-tek-A12789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/29/2023 7:15",
    "plant_id": "Green-tek-A12790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/29/2023 7:30",
    "plant_id": "Green-tek-A12791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/29/2023 7:45",
    "plant_id": "Green-tek-A12792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "12/29/2023 8:00",
    "plant_id": "Green-tek-A12793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "8:00:00 AM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "12/29/2023 8:15",
    "plant_id": "Green-tek-A12794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "8:15:00 AM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "12/29/2023 8:30",
    "plant_id": "Green-tek-A12795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "8:30:00 AM",
    "power": 0.226,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "12/29/2023 8:45",
    "plant_id": "Green-tek-A12796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "8:45:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "12/29/2023 9:00",
    "plant_id": "Green-tek-A12797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "9:00:00 AM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "12/29/2023 9:15",
    "plant_id": "Green-tek-A12798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "9:15:00 AM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "12/29/2023 9:30",
    "plant_id": "Green-tek-A12799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "9:30:00 AM",
    "power": 0.556,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "12/29/2023 9:45",
    "plant_id": "Green-tek-A12800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "9:45:00 AM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "12/29/2023 10:00",
    "plant_id": "Green-tek-A12801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "10:00:00 AM",
    "power": 0.77,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "12/29/2023 10:15",
    "plant_id": "Green-tek-A12802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "10:15:00 AM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "12/29/2023 10:30",
    "plant_id": "Green-tek-A12803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "10:30:00 AM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "12/29/2023 10:45",
    "plant_id": "Green-tek-A12804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "10:45:00 AM",
    "power": 1.079,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "12/29/2023 11:00",
    "plant_id": "Green-tek-A12805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "11:00:00 AM",
    "power": 1.158,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "12/29/2023 11:15",
    "plant_id": "Green-tek-A12806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "11:15:00 AM",
    "power": 1.219,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.32028,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "12/29/2023 11:30",
    "plant_id": "Green-tek-A12807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "11:30:00 AM",
    "power": 1.257,
    "Energy": 0.314,
    "output Energy": 0.314,
    "Ac output": 1.314,
    "dc output": 1.34028,
    "AC_R_i": 0.00040957,
    "AC_S_i": 0.000450527,
    "AC_T_i": 0.00049558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0942011,
    "AC_S_pow": 0.10362121,
    "AC_T_Pow": 0.1139834,
    "AC_R_output": 0.3942,
    "AC_S_output": 0.43362,
    "AC_T_output": 0.476982,
    "dc_current": 0.00230882,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.32028,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000314,
    "carbon offset": 0.3925,
    "trees planted": 8.722222222,
    "DC_R_output": 0.130086,
    "DC_S_output": 0.1430946,
    "DC_T_output": 0.15740406
  },
  {
    "datetime": "12/29/2023 11:45",
    "plant_id": "Green-tek-A12808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "11:45:00 AM",
    "power": 1.27,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.32028,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "12/29/2023 12:00",
    "plant_id": "Green-tek-A12809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "12:00:00 PM",
    "power": 1.257,
    "Energy": 0.314,
    "output Energy": 0.314,
    "Ac output": 1.314,
    "dc output": 1.34028,
    "AC_R_i": 0.00040957,
    "AC_S_i": 0.000450527,
    "AC_T_i": 0.00049558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0942011,
    "AC_S_pow": 0.10362121,
    "AC_T_Pow": 0.1139834,
    "AC_R_output": 0.3942,
    "AC_S_output": 0.43362,
    "AC_T_output": 0.476982,
    "dc_current": 0.00230882,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.32028,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000314,
    "carbon offset": 0.3925,
    "trees planted": 8.722222222,
    "DC_R_output": 0.130086,
    "DC_S_output": 0.1430946,
    "DC_T_output": 0.15740406
  },
  {
    "datetime": "12/29/2023 12:15",
    "plant_id": "Green-tek-A12810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "12:15:00 PM",
    "power": 1.219,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "12/29/2023 12:30",
    "plant_id": "Green-tek-A12811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "12:30:00 PM",
    "power": 1.158,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "12/29/2023 12:45",
    "plant_id": "Green-tek-A12812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "12:45:00 PM",
    "power": 1.079,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "12/29/2023 13:00",
    "plant_id": "Green-tek-A12813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "1:00:00 PM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "12/29/2023 13:15",
    "plant_id": "Green-tek-A12814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "1:15:00 PM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "12/29/2023 13:30",
    "plant_id": "Green-tek-A12815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "1:30:00 PM",
    "power": 0.77,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "12/29/2023 13:45",
    "plant_id": "Green-tek-A12816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "1:45:00 PM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "12/29/2023 14:00",
    "plant_id": "Green-tek-A12817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "2:00:00 PM",
    "power": 0.556,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "12/29/2023 14:15",
    "plant_id": "Green-tek-A12818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "2:15:00 PM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "12/29/2023 14:30",
    "plant_id": "Green-tek-A12819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "2:30:00 PM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "12/29/2023 14:45",
    "plant_id": "Green-tek-A12820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "2:45:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "12/29/2023 15:00",
    "plant_id": "Green-tek-A12821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "3:00:00 PM",
    "power": 0.226,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "12/29/2023 15:15",
    "plant_id": "Green-tek-A12822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "3:15:00 PM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "12/29/2023 15:30",
    "plant_id": "Green-tek-A12823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "3:30:00 PM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "12/29/2023 15:45",
    "plant_id": "Green-tek-A12824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "12/29/2023 16:00",
    "plant_id": "Green-tek-A12825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/29/2023 16:15",
    "plant_id": "Green-tek-A12826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/29/2023 16:30",
    "plant_id": "Green-tek-A12827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/29/2023 16:45",
    "plant_id": "Green-tek-A12828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "12/29/2023 17:00",
    "plant_id": "Green-tek-A12829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/29/2023 17:15",
    "plant_id": "Green-tek-A12830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/29/2023 17:30",
    "plant_id": "Green-tek-A12831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/29/2023",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/30/2023 6:00",
    "plant_id": "Green-tek-A12832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/30/2023 6:15",
    "plant_id": "Green-tek-A12833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/30/2023 6:30",
    "plant_id": "Green-tek-A12834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/30/2023 6:45",
    "plant_id": "Green-tek-A12835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/30/2023 7:00",
    "plant_id": "Green-tek-A12836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "12/30/2023 7:15",
    "plant_id": "Green-tek-A12837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/30/2023 7:30",
    "plant_id": "Green-tek-A12838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "12/30/2023 7:45",
    "plant_id": "Green-tek-A12839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "7:45:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "12/30/2023 8:00",
    "plant_id": "Green-tek-A12840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "8:00:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "12/30/2023 8:15",
    "plant_id": "Green-tek-A12841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "8:15:00 AM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "12/30/2023 8:30",
    "plant_id": "Green-tek-A12842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "8:30:00 AM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "12/30/2023 8:45",
    "plant_id": "Green-tek-A12843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "8:45:00 AM",
    "power": 0.199,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "12/30/2023 9:00",
    "plant_id": "Green-tek-A12844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "9:00:00 AM",
    "power": 0.252,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "12/30/2023 9:15",
    "plant_id": "Green-tek-A12845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "9:15:00 AM",
    "power": 0.312,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "12/30/2023 9:30",
    "plant_id": "Green-tek-A12846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "9:30:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "12/30/2023 9:45",
    "plant_id": "Green-tek-A12847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "9:45:00 AM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "12/30/2023 10:00",
    "plant_id": "Green-tek-A12848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "10:00:00 AM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "12/30/2023 10:15",
    "plant_id": "Green-tek-A12849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "10:15:00 AM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "12/30/2023 10:30",
    "plant_id": "Green-tek-A12850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "10:30:00 AM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "12/30/2023 10:45",
    "plant_id": "Green-tek-A12851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "10:45:00 AM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "12/30/2023 11:00",
    "plant_id": "Green-tek-A12852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "11:00:00 AM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "12/30/2023 11:15",
    "plant_id": "Green-tek-A12853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "11:15:00 AM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "12/30/2023 11:30",
    "plant_id": "Green-tek-A12854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "11:30:00 AM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "12/30/2023 11:45",
    "plant_id": "Green-tek-A12855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "11:45:00 AM",
    "power": 0.865,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "12/30/2023 12:00",
    "plant_id": "Green-tek-A12856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "12:00:00 PM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "12/30/2023 12:15",
    "plant_id": "Green-tek-A12857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "12:15:00 PM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "12/30/2023 12:30",
    "plant_id": "Green-tek-A12858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "12:30:00 PM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "12/30/2023 12:45",
    "plant_id": "Green-tek-A12859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "12:45:00 PM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "12/30/2023 13:00",
    "plant_id": "Green-tek-A12860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "1:00:00 PM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "12/30/2023 13:15",
    "plant_id": "Green-tek-A12861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "1:15:00 PM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "12/30/2023 13:30",
    "plant_id": "Green-tek-A12862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "1:30:00 PM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "12/30/2023 13:45",
    "plant_id": "Green-tek-A12863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "1:45:00 PM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "12/30/2023 14:00",
    "plant_id": "Green-tek-A12864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "2:00:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "12/30/2023 14:15",
    "plant_id": "Green-tek-A12865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "2:15:00 PM",
    "power": 0.312,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "12/30/2023 14:30",
    "plant_id": "Green-tek-A12866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "2:30:00 PM",
    "power": 0.252,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "12/30/2023 14:45",
    "plant_id": "Green-tek-A12867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "2:45:00 PM",
    "power": 0.199,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "12/30/2023 15:00",
    "plant_id": "Green-tek-A12868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "3:00:00 PM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "12/30/2023 15:15",
    "plant_id": "Green-tek-A12869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "3:15:00 PM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "12/30/2023 15:30",
    "plant_id": "Green-tek-A12870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "3:30:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "12/30/2023 15:45",
    "plant_id": "Green-tek-A12871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "3:45:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "12/30/2023 16:00",
    "plant_id": "Green-tek-A12872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "12/30/2023 16:15",
    "plant_id": "Green-tek-A12873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/30/2023 16:30",
    "plant_id": "Green-tek-A12874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "12/30/2023 16:45",
    "plant_id": "Green-tek-A12875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/30/2023 17:00",
    "plant_id": "Green-tek-A12876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/30/2023 17:15",
    "plant_id": "Green-tek-A12877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/30/2023 17:30",
    "plant_id": "Green-tek-A12878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/30/2023",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/31/2023 6:00",
    "plant_id": "Green-tek-A12879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "12/31/2023 6:15",
    "plant_id": "Green-tek-A12880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/31/2023 6:30",
    "plant_id": "Green-tek-A12881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/31/2023 6:45",
    "plant_id": "Green-tek-A12882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/31/2023 7:00",
    "plant_id": "Green-tek-A12883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/31/2023 7:15",
    "plant_id": "Green-tek-A12884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/31/2023 7:30",
    "plant_id": "Green-tek-A12885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/31/2023 7:45",
    "plant_id": "Green-tek-A12886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "7:45:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/31/2023 8:00",
    "plant_id": "Green-tek-A12887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "8:00:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "12/31/2023 8:15",
    "plant_id": "Green-tek-A12888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "8:15:00 AM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "12/31/2023 8:30",
    "plant_id": "Green-tek-A12889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "8:30:00 AM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "12/31/2023 8:45",
    "plant_id": "Green-tek-A12890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "8:45:00 AM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "12/31/2023 9:00",
    "plant_id": "Green-tek-A12891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "9:00:00 AM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "12/31/2023 9:15",
    "plant_id": "Green-tek-A12892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "9:15:00 AM",
    "power": 0.326,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "12/31/2023 9:30",
    "plant_id": "Green-tek-A12893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "9:30:00 AM",
    "power": 0.396,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "12/31/2023 9:45",
    "plant_id": "Green-tek-A12894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "9:45:00 AM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "12/31/2023 10:00",
    "plant_id": "Green-tek-A12895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "10:00:00 AM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "12/31/2023 10:15",
    "plant_id": "Green-tek-A12896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "10:15:00 AM",
    "power": 0.626,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "12/31/2023 10:30",
    "plant_id": "Green-tek-A12897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "10:30:00 AM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "12/31/2023 10:45",
    "plant_id": "Green-tek-A12898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "10:45:00 AM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "12/31/2023 11:00",
    "plant_id": "Green-tek-A12899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "11:00:00 AM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "12/31/2023 11:15",
    "plant_id": "Green-tek-A12900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "11:15:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "12/31/2023 11:30",
    "plant_id": "Green-tek-A12901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "11:30:00 AM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "12/31/2023 11:45",
    "plant_id": "Green-tek-A12902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "11:45:00 AM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "12/31/2023 12:00",
    "plant_id": "Green-tek-A12903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "12:00:00 PM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "12/31/2023 12:15",
    "plant_id": "Green-tek-A12904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "12:15:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "12/31/2023 12:30",
    "plant_id": "Green-tek-A12905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "12:30:00 PM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "12/31/2023 12:45",
    "plant_id": "Green-tek-A12906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "12:45:00 PM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "12/31/2023 13:00",
    "plant_id": "Green-tek-A12907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "1:00:00 PM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "12/31/2023 13:15",
    "plant_id": "Green-tek-A12908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "1:15:00 PM",
    "power": 0.626,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "12/31/2023 13:30",
    "plant_id": "Green-tek-A12909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "1:30:00 PM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "12/31/2023 13:45",
    "plant_id": "Green-tek-A12910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "1:45:00 PM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "12/31/2023 14:00",
    "plant_id": "Green-tek-A12911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "2:00:00 PM",
    "power": 0.396,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "12/31/2023 14:15",
    "plant_id": "Green-tek-A12912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "2:15:00 PM",
    "power": 0.326,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "12/31/2023 14:30",
    "plant_id": "Green-tek-A12913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "2:30:00 PM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "12/31/2023 14:45",
    "plant_id": "Green-tek-A12914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "2:45:00 PM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "12/31/2023 15:00",
    "plant_id": "Green-tek-A12915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "3:00:00 PM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "12/31/2023 15:15",
    "plant_id": "Green-tek-A12916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "3:15:00 PM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "12/31/2023 15:30",
    "plant_id": "Green-tek-A12917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "3:30:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "12/31/2023 15:45",
    "plant_id": "Green-tek-A12918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "3:45:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "12/31/2023 16:00",
    "plant_id": "Green-tek-A12919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "12/31/2023 16:15",
    "plant_id": "Green-tek-A12920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "12/31/2023 16:30",
    "plant_id": "Green-tek-A12921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "12/31/2023 16:45",
    "plant_id": "Green-tek-A12922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "12/31/2023 17:00",
    "plant_id": "Green-tek-A12923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "12/31/2023 17:15",
    "plant_id": "Green-tek-A12924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "12/31/2023 17:30",
    "plant_id": "Green-tek-A12925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "12/31/2023",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/1/2024 6:00",
    "plant_id": "Green-tek-A12926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/1/2024 6:15",
    "plant_id": "Green-tek-A12927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/1/2024 6:30",
    "plant_id": "Green-tek-A12928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/1/2024 6:45",
    "plant_id": "Green-tek-A12929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/1/2024 7:00",
    "plant_id": "Green-tek-A12930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/1/2024 7:15",
    "plant_id": "Green-tek-A12931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/1/2024 7:30",
    "plant_id": "Green-tek-A12932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/1/2024 7:45",
    "plant_id": "Green-tek-A12933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/1/2024 8:00",
    "plant_id": "Green-tek-A12934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "8:00:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/1/2024 8:15",
    "plant_id": "Green-tek-A12935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "8:15:00 AM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/1/2024 8:30",
    "plant_id": "Green-tek-A12936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "8:30:00 AM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "1/1/2024 8:45",
    "plant_id": "Green-tek-A12937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "8:45:00 AM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "1/1/2024 9:00",
    "plant_id": "Green-tek-A12938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "9:00:00 AM",
    "power": 0.254,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "1/1/2024 9:15",
    "plant_id": "Green-tek-A12939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "9:15:00 AM",
    "power": 0.314,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "1/1/2024 9:30",
    "plant_id": "Green-tek-A12940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "9:30:00 AM",
    "power": 0.381,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "1/1/2024 9:45",
    "plant_id": "Green-tek-A12941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "9:45:00 AM",
    "power": 0.454,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "1/1/2024 10:00",
    "plant_id": "Green-tek-A12942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "10:00:00 AM",
    "power": 0.529,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "1/1/2024 10:15",
    "plant_id": "Green-tek-A12943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "10:15:00 AM",
    "power": 0.604,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "1/1/2024 10:30",
    "plant_id": "Green-tek-A12944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "10:30:00 AM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "1/1/2024 10:45",
    "plant_id": "Green-tek-A12945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "10:45:00 AM",
    "power": 0.741,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "1/1/2024 11:00",
    "plant_id": "Green-tek-A12946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "11:00:00 AM",
    "power": 0.795,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "1/1/2024 11:15",
    "plant_id": "Green-tek-A12947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "11:15:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/1/2024 11:30",
    "plant_id": "Green-tek-A12948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "11:30:00 AM",
    "power": 0.863,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "1/1/2024 11:45",
    "plant_id": "Green-tek-A12949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "11:45:00 AM",
    "power": 0.872,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "1/1/2024 12:00",
    "plant_id": "Green-tek-A12950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "12:00:00 PM",
    "power": 0.863,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "1/1/2024 12:15",
    "plant_id": "Green-tek-A12951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "12:15:00 PM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/1/2024 12:30",
    "plant_id": "Green-tek-A12952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "12:30:00 PM",
    "power": 0.795,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "1/1/2024 12:45",
    "plant_id": "Green-tek-A12953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "12:45:00 PM",
    "power": 0.741,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "1/1/2024 13:00",
    "plant_id": "Green-tek-A12954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "1:00:00 PM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "1/1/2024 13:15",
    "plant_id": "Green-tek-A12955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "1:15:00 PM",
    "power": 0.604,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "1/1/2024 13:30",
    "plant_id": "Green-tek-A12956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "1:30:00 PM",
    "power": 0.529,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "1/1/2024 13:45",
    "plant_id": "Green-tek-A12957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "1:45:00 PM",
    "power": 0.454,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "1/1/2024 14:00",
    "plant_id": "Green-tek-A12958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "2:00:00 PM",
    "power": 0.381,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "1/1/2024 14:15",
    "plant_id": "Green-tek-A12959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "2:15:00 PM",
    "power": 0.314,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "1/1/2024 14:30",
    "plant_id": "Green-tek-A12960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "2:30:00 PM",
    "power": 0.254,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "1/1/2024 14:45",
    "plant_id": "Green-tek-A12961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "2:45:00 PM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "1/1/2024 15:00",
    "plant_id": "Green-tek-A12962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "3:00:00 PM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "1/1/2024 15:15",
    "plant_id": "Green-tek-A12963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "3:15:00 PM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/1/2024 15:30",
    "plant_id": "Green-tek-A12964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "3:30:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/1/2024 15:45",
    "plant_id": "Green-tek-A12965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/1/2024 16:00",
    "plant_id": "Green-tek-A12966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/1/2024 16:15",
    "plant_id": "Green-tek-A12967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/1/2024 16:30",
    "plant_id": "Green-tek-A12968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/1/2024 16:45",
    "plant_id": "Green-tek-A12969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/1/2024 17:00",
    "plant_id": "Green-tek-A12970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/1/2024 17:15",
    "plant_id": "Green-tek-A12971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/1/2024 17:30",
    "plant_id": "Green-tek-A12972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/1/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/2/2024 6:00",
    "plant_id": "Green-tek-A12973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/2/2024 6:15",
    "plant_id": "Green-tek-A12974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/2/2024 6:30",
    "plant_id": "Green-tek-A12975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/2/2024 6:45",
    "plant_id": "Green-tek-A12976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/2/2024 7:00",
    "plant_id": "Green-tek-A12977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/2/2024 7:15",
    "plant_id": "Green-tek-A12978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/2/2024 7:30",
    "plant_id": "Green-tek-A12979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/2/2024 7:45",
    "plant_id": "Green-tek-A12980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "7:45:00 AM",
    "power": 0.086,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/2/2024 8:00",
    "plant_id": "Green-tek-A12981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "8:00:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "1/2/2024 8:15",
    "plant_id": "Green-tek-A12982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "8:15:00 AM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "1/2/2024 8:30",
    "plant_id": "Green-tek-A12983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "8:30:00 AM",
    "power": 0.21,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "1/2/2024 8:45",
    "plant_id": "Green-tek-A12984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "8:45:00 AM",
    "power": 0.271,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "1/2/2024 9:00",
    "plant_id": "Green-tek-A12985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "9:00:00 AM",
    "power": 0.343,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "1/2/2024 9:15",
    "plant_id": "Green-tek-A12986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "9:15:00 AM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "1/2/2024 9:30",
    "plant_id": "Green-tek-A12987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "9:30:00 AM",
    "power": 0.515,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "1/2/2024 9:45",
    "plant_id": "Green-tek-A12988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "9:45:00 AM",
    "power": 0.613,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "1/2/2024 10:00",
    "plant_id": "Green-tek-A12989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "10:00:00 AM",
    "power": 0.714,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "1/2/2024 10:15",
    "plant_id": "Green-tek-A12990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "10:15:00 AM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "1/2/2024 10:30",
    "plant_id": "Green-tek-A12991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "10:30:00 AM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "1/2/2024 10:45",
    "plant_id": "Green-tek-A12992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "10:45:00 AM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "1/2/2024 11:00",
    "plant_id": "Green-tek-A12993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "11:00:00 AM",
    "power": 1.074,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "1/2/2024 11:15",
    "plant_id": "Green-tek-A12994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "11:15:00 AM",
    "power": 1.13,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "1/2/2024 11:30",
    "plant_id": "Green-tek-A12995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "11:30:00 AM",
    "power": 1.166,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "1/2/2024 11:45",
    "plant_id": "Green-tek-A12996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "11:45:00 AM",
    "power": 1.178,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "1/2/2024 12:00",
    "plant_id": "Green-tek-A12997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "12:00:00 PM",
    "power": 1.166,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "1/2/2024 12:15",
    "plant_id": "Green-tek-A12998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "12:15:00 PM",
    "power": 1.13,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "1/2/2024 12:30",
    "plant_id": "Green-tek-A12999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "12:30:00 PM",
    "power": 1.074,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "1/2/2024 12:45",
    "plant_id": "Green-tek-A13000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "12:45:00 PM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "1/2/2024 13:00",
    "plant_id": "Green-tek-A13001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "1:00:00 PM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "1/2/2024 13:15",
    "plant_id": "Green-tek-A13002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "1:15:00 PM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "1/2/2024 13:30",
    "plant_id": "Green-tek-A13003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "1:30:00 PM",
    "power": 0.714,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "1/2/2024 13:45",
    "plant_id": "Green-tek-A13004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "1:45:00 PM",
    "power": 0.613,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "1/2/2024 14:00",
    "plant_id": "Green-tek-A13005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "2:00:00 PM",
    "power": 0.515,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "1/2/2024 14:15",
    "plant_id": "Green-tek-A13006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "2:15:00 PM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "1/2/2024 14:30",
    "plant_id": "Green-tek-A13007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "2:30:00 PM",
    "power": 0.343,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "1/2/2024 14:45",
    "plant_id": "Green-tek-A13008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "2:45:00 PM",
    "power": 0.271,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "1/2/2024 15:00",
    "plant_id": "Green-tek-A13009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "3:00:00 PM",
    "power": 0.21,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "1/2/2024 15:15",
    "plant_id": "Green-tek-A13010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "3:15:00 PM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "1/2/2024 15:30",
    "plant_id": "Green-tek-A13011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "3:30:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "1/2/2024 15:45",
    "plant_id": "Green-tek-A13012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "3:45:00 PM",
    "power": 0.086,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/2/2024 16:00",
    "plant_id": "Green-tek-A13013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/2/2024 16:15",
    "plant_id": "Green-tek-A13014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/2/2024 16:30",
    "plant_id": "Green-tek-A13015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/2/2024 16:45",
    "plant_id": "Green-tek-A13016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/2/2024 17:00",
    "plant_id": "Green-tek-A13017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/2/2024 17:15",
    "plant_id": "Green-tek-A13018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/2/2024 17:30",
    "plant_id": "Green-tek-A13019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/2/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/3/2024 6:00",
    "plant_id": "Green-tek-A13020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/3/2024 6:15",
    "plant_id": "Green-tek-A13021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/3/2024 6:30",
    "plant_id": "Green-tek-A13022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/3/2024 6:45",
    "plant_id": "Green-tek-A13023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/3/2024 7:00",
    "plant_id": "Green-tek-A13024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/3/2024 7:15",
    "plant_id": "Green-tek-A13025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/3/2024 7:30",
    "plant_id": "Green-tek-A13026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/3/2024 7:45",
    "plant_id": "Green-tek-A13027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "1/3/2024 8:00",
    "plant_id": "Green-tek-A13028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "8:00:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/3/2024 8:15",
    "plant_id": "Green-tek-A13029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "8:15:00 AM",
    "power": 0.15,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/3/2024 8:30",
    "plant_id": "Green-tek-A13030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "8:30:00 AM",
    "power": 0.198,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/3/2024 8:45",
    "plant_id": "Green-tek-A13031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "8:45:00 AM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "1/3/2024 9:00",
    "plant_id": "Green-tek-A13032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "9:00:00 AM",
    "power": 0.322,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "1/3/2024 9:15",
    "plant_id": "Green-tek-A13033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "9:15:00 AM",
    "power": 0.399,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "1/3/2024 9:30",
    "plant_id": "Green-tek-A13034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "9:30:00 AM",
    "power": 0.485,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "1/3/2024 9:45",
    "plant_id": "Green-tek-A13035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "9:45:00 AM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "1/3/2024 10:00",
    "plant_id": "Green-tek-A13036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "10:00:00 AM",
    "power": 0.672,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "1/3/2024 10:15",
    "plant_id": "Green-tek-A13037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "10:15:00 AM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "1/3/2024 10:30",
    "plant_id": "Green-tek-A13038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "10:30:00 AM",
    "power": 0.859,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "1/3/2024 10:45",
    "plant_id": "Green-tek-A13039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "10:45:00 AM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "1/3/2024 11:00",
    "plant_id": "Green-tek-A13040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "11:00:00 AM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "1/3/2024 11:15",
    "plant_id": "Green-tek-A13041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "11:15:00 AM",
    "power": 1.064,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "1/3/2024 11:30",
    "plant_id": "Green-tek-A13042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "11:30:00 AM",
    "power": 1.097,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "1/3/2024 11:45",
    "plant_id": "Green-tek-A13043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "11:45:00 AM",
    "power": 1.108,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "1/3/2024 12:00",
    "plant_id": "Green-tek-A13044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "12:00:00 PM",
    "power": 1.097,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "1/3/2024 12:15",
    "plant_id": "Green-tek-A13045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "12:15:00 PM",
    "power": 1.064,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "1/3/2024 12:30",
    "plant_id": "Green-tek-A13046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "12:30:00 PM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "1/3/2024 12:45",
    "plant_id": "Green-tek-A13047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "12:45:00 PM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "1/3/2024 13:00",
    "plant_id": "Green-tek-A13048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "1:00:00 PM",
    "power": 0.859,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "1/3/2024 13:15",
    "plant_id": "Green-tek-A13049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "1:15:00 PM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "1/3/2024 13:30",
    "plant_id": "Green-tek-A13050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "1:30:00 PM",
    "power": 0.672,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "1/3/2024 13:45",
    "plant_id": "Green-tek-A13051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "1:45:00 PM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "1/3/2024 14:00",
    "plant_id": "Green-tek-A13052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "2:00:00 PM",
    "power": 0.485,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "1/3/2024 14:15",
    "plant_id": "Green-tek-A13053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "2:15:00 PM",
    "power": 0.399,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "1/3/2024 14:30",
    "plant_id": "Green-tek-A13054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "2:30:00 PM",
    "power": 0.322,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "1/3/2024 14:45",
    "plant_id": "Green-tek-A13055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "2:45:00 PM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "1/3/2024 15:00",
    "plant_id": "Green-tek-A13056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "3:00:00 PM",
    "power": 0.198,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/3/2024 15:15",
    "plant_id": "Green-tek-A13057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "3:15:00 PM",
    "power": 0.15,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/3/2024 15:30",
    "plant_id": "Green-tek-A13058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "3:30:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/3/2024 15:45",
    "plant_id": "Green-tek-A13059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "1/3/2024 16:00",
    "plant_id": "Green-tek-A13060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/3/2024 16:15",
    "plant_id": "Green-tek-A13061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/3/2024 16:30",
    "plant_id": "Green-tek-A13062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/3/2024 16:45",
    "plant_id": "Green-tek-A13063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/3/2024 17:00",
    "plant_id": "Green-tek-A13064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/3/2024 17:15",
    "plant_id": "Green-tek-A13065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/3/2024 17:30",
    "plant_id": "Green-tek-A13066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/3/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/4/2024 6:00",
    "plant_id": "Green-tek-A13067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/4/2024 6:15",
    "plant_id": "Green-tek-A13068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/4/2024 6:30",
    "plant_id": "Green-tek-A13069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/4/2024 6:45",
    "plant_id": "Green-tek-A13070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/4/2024 7:00",
    "plant_id": "Green-tek-A13071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/4/2024 7:15",
    "plant_id": "Green-tek-A13072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/4/2024 7:30",
    "plant_id": "Green-tek-A13073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/4/2024 7:45",
    "plant_id": "Green-tek-A13074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "7:45:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/4/2024 8:00",
    "plant_id": "Green-tek-A13075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "8:00:00 AM",
    "power": 0.114,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/4/2024 8:15",
    "plant_id": "Green-tek-A13076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "8:15:00 AM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/4/2024 8:30",
    "plant_id": "Green-tek-A13077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "8:30:00 AM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "1/4/2024 8:45",
    "plant_id": "Green-tek-A13078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "8:45:00 AM",
    "power": 0.26,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "1/4/2024 9:00",
    "plant_id": "Green-tek-A13079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "9:00:00 AM",
    "power": 0.329,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "1/4/2024 9:15",
    "plant_id": "Green-tek-A13080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "9:15:00 AM",
    "power": 0.407,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "1/4/2024 9:30",
    "plant_id": "Green-tek-A13081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "9:30:00 AM",
    "power": 0.494,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "1/4/2024 9:45",
    "plant_id": "Green-tek-A13082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "9:45:00 AM",
    "power": 0.588,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "1/4/2024 10:00",
    "plant_id": "Green-tek-A13083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "10:00:00 AM",
    "power": 0.685,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "1/4/2024 10:15",
    "plant_id": "Green-tek-A13084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "10:15:00 AM",
    "power": 0.782,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "1/4/2024 10:30",
    "plant_id": "Green-tek-A13085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "10:30:00 AM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "1/4/2024 10:45",
    "plant_id": "Green-tek-A13086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "10:45:00 AM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "1/4/2024 11:00",
    "plant_id": "Green-tek-A13087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "11:00:00 AM",
    "power": 1.03,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "1/4/2024 11:15",
    "plant_id": "Green-tek-A13088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "11:15:00 AM",
    "power": 1.084,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "1/4/2024 11:30",
    "plant_id": "Green-tek-A13089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "11:30:00 AM",
    "power": 1.118,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "1/4/2024 11:45",
    "plant_id": "Green-tek-A13090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "11:45:00 AM",
    "power": 1.129,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "1/4/2024 12:00",
    "plant_id": "Green-tek-A13091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "12:00:00 PM",
    "power": 1.118,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "1/4/2024 12:15",
    "plant_id": "Green-tek-A13092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "12:15:00 PM",
    "power": 1.084,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "1/4/2024 12:30",
    "plant_id": "Green-tek-A13093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "12:30:00 PM",
    "power": 1.03,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "1/4/2024 12:45",
    "plant_id": "Green-tek-A13094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "12:45:00 PM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "1/4/2024 13:00",
    "plant_id": "Green-tek-A13095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "1:00:00 PM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "1/4/2024 13:15",
    "plant_id": "Green-tek-A13096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "1:15:00 PM",
    "power": 0.782,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "1/4/2024 13:30",
    "plant_id": "Green-tek-A13097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "1:30:00 PM",
    "power": 0.685,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "1/4/2024 13:45",
    "plant_id": "Green-tek-A13098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "1:45:00 PM",
    "power": 0.588,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "1/4/2024 14:00",
    "plant_id": "Green-tek-A13099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "2:00:00 PM",
    "power": 0.494,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "1/4/2024 14:15",
    "plant_id": "Green-tek-A13100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "2:15:00 PM",
    "power": 0.407,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "1/4/2024 14:30",
    "plant_id": "Green-tek-A13101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "2:30:00 PM",
    "power": 0.329,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "1/4/2024 14:45",
    "plant_id": "Green-tek-A13102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "2:45:00 PM",
    "power": 0.26,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "1/4/2024 15:00",
    "plant_id": "Green-tek-A13103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "3:00:00 PM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "1/4/2024 15:15",
    "plant_id": "Green-tek-A13104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "3:15:00 PM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/4/2024 15:30",
    "plant_id": "Green-tek-A13105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "3:30:00 PM",
    "power": 0.114,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/4/2024 15:45",
    "plant_id": "Green-tek-A13106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "3:45:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/4/2024 16:00",
    "plant_id": "Green-tek-A13107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/4/2024 16:15",
    "plant_id": "Green-tek-A13108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/4/2024 16:30",
    "plant_id": "Green-tek-A13109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/4/2024 16:45",
    "plant_id": "Green-tek-A13110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/4/2024 17:00",
    "plant_id": "Green-tek-A13111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/4/2024 17:15",
    "plant_id": "Green-tek-A13112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/4/2024 17:30",
    "plant_id": "Green-tek-A13113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/4/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/5/2024 6:00",
    "plant_id": "Green-tek-A13114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/5/2024 6:15",
    "plant_id": "Green-tek-A13115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/5/2024 6:30",
    "plant_id": "Green-tek-A13116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/5/2024 6:45",
    "plant_id": "Green-tek-A13117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/5/2024 7:00",
    "plant_id": "Green-tek-A13118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/5/2024 7:15",
    "plant_id": "Green-tek-A13119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/5/2024 7:30",
    "plant_id": "Green-tek-A13120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/5/2024 7:45",
    "plant_id": "Green-tek-A13121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "7:45:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/5/2024 8:00",
    "plant_id": "Green-tek-A13122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "8:00:00 AM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "1/5/2024 8:15",
    "plant_id": "Green-tek-A13123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "8:15:00 AM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "1/5/2024 8:30",
    "plant_id": "Green-tek-A13124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "8:30:00 AM",
    "power": 0.214,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "1/5/2024 8:45",
    "plant_id": "Green-tek-A13125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "8:45:00 AM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "1/5/2024 9:00",
    "plant_id": "Green-tek-A13126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "9:00:00 AM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "1/5/2024 9:15",
    "plant_id": "Green-tek-A13127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "9:15:00 AM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "1/5/2024 9:30",
    "plant_id": "Green-tek-A13128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "9:30:00 AM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "1/5/2024 9:45",
    "plant_id": "Green-tek-A13129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "9:45:00 AM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "1/5/2024 10:00",
    "plant_id": "Green-tek-A13130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "10:00:00 AM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "1/5/2024 10:15",
    "plant_id": "Green-tek-A13131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "10:15:00 AM",
    "power": 0.83,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "1/5/2024 10:30",
    "plant_id": "Green-tek-A13132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "10:30:00 AM",
    "power": 0.929,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "1/5/2024 10:45",
    "plant_id": "Green-tek-A13133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "10:45:00 AM",
    "power": 1.018,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "1/5/2024 11:00",
    "plant_id": "Green-tek-A13134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "11:00:00 AM",
    "power": 1.094,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "1/5/2024 11:15",
    "plant_id": "Green-tek-A13135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "11:15:00 AM",
    "power": 1.151,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "1/5/2024 11:30",
    "plant_id": "Green-tek-A13136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "11:30:00 AM",
    "power": 1.187,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "1/5/2024 11:45",
    "plant_id": "Green-tek-A13137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "11:45:00 AM",
    "power": 1.199,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "1/5/2024 12:00",
    "plant_id": "Green-tek-A13138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "12:00:00 PM",
    "power": 1.187,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "1/5/2024 12:15",
    "plant_id": "Green-tek-A13139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "12:15:00 PM",
    "power": 1.151,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "1/5/2024 12:30",
    "plant_id": "Green-tek-A13140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "12:30:00 PM",
    "power": 1.094,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "1/5/2024 12:45",
    "plant_id": "Green-tek-A13141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "12:45:00 PM",
    "power": 1.018,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "1/5/2024 13:00",
    "plant_id": "Green-tek-A13142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "1:00:00 PM",
    "power": 0.929,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "1/5/2024 13:15",
    "plant_id": "Green-tek-A13143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "1:15:00 PM",
    "power": 0.83,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "1/5/2024 13:30",
    "plant_id": "Green-tek-A13144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "1:30:00 PM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "1/5/2024 13:45",
    "plant_id": "Green-tek-A13145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "1:45:00 PM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "1/5/2024 14:00",
    "plant_id": "Green-tek-A13146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "2:00:00 PM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "1/5/2024 14:15",
    "plant_id": "Green-tek-A13147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "2:15:00 PM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "1/5/2024 14:30",
    "plant_id": "Green-tek-A13148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "2:30:00 PM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "1/5/2024 14:45",
    "plant_id": "Green-tek-A13149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "2:45:00 PM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "1/5/2024 15:00",
    "plant_id": "Green-tek-A13150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "3:00:00 PM",
    "power": 0.214,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "1/5/2024 15:15",
    "plant_id": "Green-tek-A13151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "3:15:00 PM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "1/5/2024 15:30",
    "plant_id": "Green-tek-A13152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "3:30:00 PM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "1/5/2024 15:45",
    "plant_id": "Green-tek-A13153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "3:45:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/5/2024 16:00",
    "plant_id": "Green-tek-A13154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/5/2024 16:15",
    "plant_id": "Green-tek-A13155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/5/2024 16:30",
    "plant_id": "Green-tek-A13156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/5/2024 16:45",
    "plant_id": "Green-tek-A13157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/5/2024 17:00",
    "plant_id": "Green-tek-A13158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/5/2024 17:15",
    "plant_id": "Green-tek-A13159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/5/2024 17:30",
    "plant_id": "Green-tek-A13160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/5/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/6/2024 6:00",
    "plant_id": "Green-tek-A13161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/6/2024 6:15",
    "plant_id": "Green-tek-A13162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/6/2024 6:30",
    "plant_id": "Green-tek-A13163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/6/2024 6:45",
    "plant_id": "Green-tek-A13164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/6/2024 7:00",
    "plant_id": "Green-tek-A13165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/6/2024 7:15",
    "plant_id": "Green-tek-A13166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/6/2024 7:30",
    "plant_id": "Green-tek-A13167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/6/2024 7:45",
    "plant_id": "Green-tek-A13168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "7:45:00 AM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/6/2024 8:00",
    "plant_id": "Green-tek-A13169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "8:00:00 AM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "1/6/2024 8:15",
    "plant_id": "Green-tek-A13170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "8:15:00 AM",
    "power": 0.134,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/6/2024 8:30",
    "plant_id": "Green-tek-A13171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "8:30:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/6/2024 8:45",
    "plant_id": "Green-tek-A13172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "8:45:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "1/6/2024 9:00",
    "plant_id": "Green-tek-A13173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "9:00:00 AM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "1/6/2024 9:15",
    "plant_id": "Green-tek-A13174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "9:15:00 AM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "1/6/2024 9:30",
    "plant_id": "Green-tek-A13175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "9:30:00 AM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "1/6/2024 9:45",
    "plant_id": "Green-tek-A13176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "9:45:00 AM",
    "power": 0.514,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "1/6/2024 10:00",
    "plant_id": "Green-tek-A13177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "10:00:00 AM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "1/6/2024 10:15",
    "plant_id": "Green-tek-A13178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "10:15:00 AM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "1/6/2024 10:30",
    "plant_id": "Green-tek-A13179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "10:30:00 AM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "1/6/2024 10:45",
    "plant_id": "Green-tek-A13180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "10:45:00 AM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "1/6/2024 11:00",
    "plant_id": "Green-tek-A13181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "11:00:00 AM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/6/2024 11:15",
    "plant_id": "Green-tek-A13182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "11:15:00 AM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "1/6/2024 11:30",
    "plant_id": "Green-tek-A13183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "11:30:00 AM",
    "power": 0.978,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "1/6/2024 11:45",
    "plant_id": "Green-tek-A13184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "11:45:00 AM",
    "power": 0.988,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "1/6/2024 12:00",
    "plant_id": "Green-tek-A13185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "12:00:00 PM",
    "power": 0.978,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "1/6/2024 12:15",
    "plant_id": "Green-tek-A13186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "12:15:00 PM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "1/6/2024 12:30",
    "plant_id": "Green-tek-A13187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "12:30:00 PM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/6/2024 12:45",
    "plant_id": "Green-tek-A13188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "12:45:00 PM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "1/6/2024 13:00",
    "plant_id": "Green-tek-A13189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "1:00:00 PM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "1/6/2024 13:15",
    "plant_id": "Green-tek-A13190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "1:15:00 PM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "1/6/2024 13:30",
    "plant_id": "Green-tek-A13191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "1:30:00 PM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "1/6/2024 13:45",
    "plant_id": "Green-tek-A13192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "1:45:00 PM",
    "power": 0.514,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "1/6/2024 14:00",
    "plant_id": "Green-tek-A13193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "2:00:00 PM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "1/6/2024 14:15",
    "plant_id": "Green-tek-A13194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "2:15:00 PM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "1/6/2024 14:30",
    "plant_id": "Green-tek-A13195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "2:30:00 PM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "1/6/2024 14:45",
    "plant_id": "Green-tek-A13196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "2:45:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "1/6/2024 15:00",
    "plant_id": "Green-tek-A13197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "3:00:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/6/2024 15:15",
    "plant_id": "Green-tek-A13198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "3:15:00 PM",
    "power": 0.134,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/6/2024 15:30",
    "plant_id": "Green-tek-A13199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "3:30:00 PM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "1/6/2024 15:45",
    "plant_id": "Green-tek-A13200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "3:45:00 PM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/6/2024 16:00",
    "plant_id": "Green-tek-A13201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/6/2024 16:15",
    "plant_id": "Green-tek-A13202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/6/2024 16:30",
    "plant_id": "Green-tek-A13203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/6/2024 16:45",
    "plant_id": "Green-tek-A13204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/6/2024 17:00",
    "plant_id": "Green-tek-A13205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/6/2024 17:15",
    "plant_id": "Green-tek-A13206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/6/2024 17:30",
    "plant_id": "Green-tek-A13207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/6/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/7/2024 6:00",
    "plant_id": "Green-tek-A13208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/7/2024 6:15",
    "plant_id": "Green-tek-A13209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/7/2024 6:30",
    "plant_id": "Green-tek-A13210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/7/2024 6:45",
    "plant_id": "Green-tek-A13211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/7/2024 7:00",
    "plant_id": "Green-tek-A13212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/7/2024 7:15",
    "plant_id": "Green-tek-A13213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/7/2024 7:30",
    "plant_id": "Green-tek-A13214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/7/2024 7:45",
    "plant_id": "Green-tek-A13215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "7:45:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/7/2024 8:00",
    "plant_id": "Green-tek-A13216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "8:00:00 AM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/7/2024 8:15",
    "plant_id": "Green-tek-A13217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "8:15:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "1/7/2024 8:30",
    "plant_id": "Green-tek-A13218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "8:30:00 AM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "1/7/2024 8:45",
    "plant_id": "Green-tek-A13219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "8:45:00 AM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "1/7/2024 9:00",
    "plant_id": "Green-tek-A13220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "9:00:00 AM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "1/7/2024 9:15",
    "plant_id": "Green-tek-A13221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "9:15:00 AM",
    "power": 0.338,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "1/7/2024 9:30",
    "plant_id": "Green-tek-A13222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "9:30:00 AM",
    "power": 0.41,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "1/7/2024 9:45",
    "plant_id": "Green-tek-A13223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "9:45:00 AM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "1/7/2024 10:00",
    "plant_id": "Green-tek-A13224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "10:00:00 AM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/7/2024 10:15",
    "plant_id": "Green-tek-A13225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "10:15:00 AM",
    "power": 0.649,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "1/7/2024 10:30",
    "plant_id": "Green-tek-A13226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "10:30:00 AM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "1/7/2024 10:45",
    "plant_id": "Green-tek-A13227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "10:45:00 AM",
    "power": 0.796,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "1/7/2024 11:00",
    "plant_id": "Green-tek-A13228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "11:00:00 AM",
    "power": 0.855,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "1/7/2024 11:15",
    "plant_id": "Green-tek-A13229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "11:15:00 AM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/7/2024 11:30",
    "plant_id": "Green-tek-A13230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "11:30:00 AM",
    "power": 0.928,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "1/7/2024 11:45",
    "plant_id": "Green-tek-A13231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "11:45:00 AM",
    "power": 0.938,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "1/7/2024 12:00",
    "plant_id": "Green-tek-A13232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "12:00:00 PM",
    "power": 0.928,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "1/7/2024 12:15",
    "plant_id": "Green-tek-A13233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "12:15:00 PM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/7/2024 12:30",
    "plant_id": "Green-tek-A13234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "12:30:00 PM",
    "power": 0.855,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "1/7/2024 12:45",
    "plant_id": "Green-tek-A13235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "12:45:00 PM",
    "power": 0.796,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "1/7/2024 13:00",
    "plant_id": "Green-tek-A13236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "1:00:00 PM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "1/7/2024 13:15",
    "plant_id": "Green-tek-A13237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "1:15:00 PM",
    "power": 0.649,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "1/7/2024 13:30",
    "plant_id": "Green-tek-A13238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "1:30:00 PM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/7/2024 13:45",
    "plant_id": "Green-tek-A13239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "1:45:00 PM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "1/7/2024 14:00",
    "plant_id": "Green-tek-A13240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "2:00:00 PM",
    "power": 0.41,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "1/7/2024 14:15",
    "plant_id": "Green-tek-A13241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "2:15:00 PM",
    "power": 0.338,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "1/7/2024 14:30",
    "plant_id": "Green-tek-A13242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "2:30:00 PM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "1/7/2024 14:45",
    "plant_id": "Green-tek-A13243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "2:45:00 PM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "1/7/2024 15:00",
    "plant_id": "Green-tek-A13244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "3:00:00 PM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "1/7/2024 15:15",
    "plant_id": "Green-tek-A13245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "3:15:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "1/7/2024 15:30",
    "plant_id": "Green-tek-A13246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "3:30:00 PM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/7/2024 15:45",
    "plant_id": "Green-tek-A13247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "3:45:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/7/2024 16:00",
    "plant_id": "Green-tek-A13248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/7/2024 16:15",
    "plant_id": "Green-tek-A13249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/7/2024 16:30",
    "plant_id": "Green-tek-A13250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/7/2024 16:45",
    "plant_id": "Green-tek-A13251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/7/2024 17:00",
    "plant_id": "Green-tek-A13252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/7/2024 17:15",
    "plant_id": "Green-tek-A13253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/7/2024 17:30",
    "plant_id": "Green-tek-A13254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/7/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/8/2024 6:00",
    "plant_id": "Green-tek-A13255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/8/2024 6:15",
    "plant_id": "Green-tek-A13256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/8/2024 6:30",
    "plant_id": "Green-tek-A13257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/8/2024 6:45",
    "plant_id": "Green-tek-A13258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/8/2024 7:00",
    "plant_id": "Green-tek-A13259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/8/2024 7:15",
    "plant_id": "Green-tek-A13260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/8/2024 7:30",
    "plant_id": "Green-tek-A13261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "1/8/2024 7:45",
    "plant_id": "Green-tek-A13262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "7:45:00 AM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "1/8/2024 8:00",
    "plant_id": "Green-tek-A13263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "8:00:00 AM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "1/8/2024 8:15",
    "plant_id": "Green-tek-A13264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "8:15:00 AM",
    "power": 0.146,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "1/8/2024 8:30",
    "plant_id": "Green-tek-A13265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "8:30:00 AM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "1/8/2024 8:45",
    "plant_id": "Green-tek-A13266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "8:45:00 AM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "1/8/2024 9:00",
    "plant_id": "Green-tek-A13267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "9:00:00 AM",
    "power": 0.313,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "1/8/2024 9:15",
    "plant_id": "Green-tek-A13268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "9:15:00 AM",
    "power": 0.388,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "1/8/2024 9:30",
    "plant_id": "Green-tek-A13269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "9:30:00 AM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "1/8/2024 9:45",
    "plant_id": "Green-tek-A13270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "9:45:00 AM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "1/8/2024 10:00",
    "plant_id": "Green-tek-A13271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "10:00:00 AM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "1/8/2024 10:15",
    "plant_id": "Green-tek-A13272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "10:15:00 AM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "1/8/2024 10:30",
    "plant_id": "Green-tek-A13273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "10:30:00 AM",
    "power": 0.835,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/8/2024 10:45",
    "plant_id": "Green-tek-A13274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "10:45:00 AM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "1/8/2024 11:00",
    "plant_id": "Green-tek-A13275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "11:00:00 AM",
    "power": 0.983,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "1/8/2024 11:15",
    "plant_id": "Green-tek-A13276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "11:15:00 AM",
    "power": 1.034,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "1/8/2024 11:30",
    "plant_id": "Green-tek-A13277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "11:30:00 AM",
    "power": 1.066,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "1/8/2024 11:45",
    "plant_id": "Green-tek-A13278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "11:45:00 AM",
    "power": 1.077,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "1/8/2024 12:00",
    "plant_id": "Green-tek-A13279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "12:00:00 PM",
    "power": 1.066,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "1/8/2024 12:15",
    "plant_id": "Green-tek-A13280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "12:15:00 PM",
    "power": 1.034,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "1/8/2024 12:30",
    "plant_id": "Green-tek-A13281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "12:30:00 PM",
    "power": 0.983,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "1/8/2024 12:45",
    "plant_id": "Green-tek-A13282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "12:45:00 PM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "1/8/2024 13:00",
    "plant_id": "Green-tek-A13283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "1:00:00 PM",
    "power": 0.835,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/8/2024 13:15",
    "plant_id": "Green-tek-A13284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "1:15:00 PM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "1/8/2024 13:30",
    "plant_id": "Green-tek-A13285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "1:30:00 PM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "1/8/2024 13:45",
    "plant_id": "Green-tek-A13286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "1:45:00 PM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "1/8/2024 14:00",
    "plant_id": "Green-tek-A13287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "2:00:00 PM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "1/8/2024 14:15",
    "plant_id": "Green-tek-A13288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "2:15:00 PM",
    "power": 0.388,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "1/8/2024 14:30",
    "plant_id": "Green-tek-A13289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "2:30:00 PM",
    "power": 0.313,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "1/8/2024 14:45",
    "plant_id": "Green-tek-A13290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "2:45:00 PM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "1/8/2024 15:00",
    "plant_id": "Green-tek-A13291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "3:00:00 PM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "1/8/2024 15:15",
    "plant_id": "Green-tek-A13292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "3:15:00 PM",
    "power": 0.146,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "1/8/2024 15:30",
    "plant_id": "Green-tek-A13293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "3:30:00 PM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "1/8/2024 15:45",
    "plant_id": "Green-tek-A13294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "3:45:00 PM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "1/8/2024 16:00",
    "plant_id": "Green-tek-A13295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "1/8/2024 16:15",
    "plant_id": "Green-tek-A13296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/8/2024 16:30",
    "plant_id": "Green-tek-A13297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/8/2024 16:45",
    "plant_id": "Green-tek-A13298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/8/2024 17:00",
    "plant_id": "Green-tek-A13299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/8/2024 17:15",
    "plant_id": "Green-tek-A13300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/8/2024 17:30",
    "plant_id": "Green-tek-A13301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/8/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/9/2024 6:00",
    "plant_id": "Green-tek-A13302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/9/2024 6:15",
    "plant_id": "Green-tek-A13303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/9/2024 6:30",
    "plant_id": "Green-tek-A13304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/9/2024 6:45",
    "plant_id": "Green-tek-A13305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/9/2024 7:00",
    "plant_id": "Green-tek-A13306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/9/2024 7:15",
    "plant_id": "Green-tek-A13307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/9/2024 7:30",
    "plant_id": "Green-tek-A13308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/9/2024 7:45",
    "plant_id": "Green-tek-A13309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "7:45:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/9/2024 8:00",
    "plant_id": "Green-tek-A13310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "8:00:00 AM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/9/2024 8:15",
    "plant_id": "Green-tek-A13311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "8:15:00 AM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "1/9/2024 8:30",
    "plant_id": "Green-tek-A13312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "8:30:00 AM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "1/9/2024 8:45",
    "plant_id": "Green-tek-A13313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "8:45:00 AM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "1/9/2024 9:00",
    "plant_id": "Green-tek-A13314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "9:00:00 AM",
    "power": 0.338,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "1/9/2024 9:15",
    "plant_id": "Green-tek-A13315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "9:15:00 AM",
    "power": 0.418,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "1/9/2024 9:30",
    "plant_id": "Green-tek-A13316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "9:30:00 AM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "1/9/2024 9:45",
    "plant_id": "Green-tek-A13317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "9:45:00 AM",
    "power": 0.604,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "1/9/2024 10:00",
    "plant_id": "Green-tek-A13318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "10:00:00 AM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "1/9/2024 10:15",
    "plant_id": "Green-tek-A13319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "10:15:00 AM",
    "power": 0.804,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "1/9/2024 10:30",
    "plant_id": "Green-tek-A13320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "10:30:00 AM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/9/2024 10:45",
    "plant_id": "Green-tek-A13321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "10:45:00 AM",
    "power": 0.986,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "1/9/2024 11:00",
    "plant_id": "Green-tek-A13322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "11:00:00 AM",
    "power": 1.059,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "1/9/2024 11:15",
    "plant_id": "Green-tek-A13323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "11:15:00 AM",
    "power": 1.115,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "1/9/2024 11:30",
    "plant_id": "Green-tek-A13324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "11:30:00 AM",
    "power": 1.149,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "1/9/2024 11:45",
    "plant_id": "Green-tek-A13325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "11:45:00 AM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "1/9/2024 12:00",
    "plant_id": "Green-tek-A13326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "12:00:00 PM",
    "power": 1.149,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "1/9/2024 12:15",
    "plant_id": "Green-tek-A13327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "12:15:00 PM",
    "power": 1.115,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "1/9/2024 12:30",
    "plant_id": "Green-tek-A13328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "12:30:00 PM",
    "power": 1.059,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "1/9/2024 12:45",
    "plant_id": "Green-tek-A13329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "12:45:00 PM",
    "power": 0.986,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "1/9/2024 13:00",
    "plant_id": "Green-tek-A13330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "1:00:00 PM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/9/2024 13:15",
    "plant_id": "Green-tek-A13331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "1:15:00 PM",
    "power": 0.804,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "1/9/2024 13:30",
    "plant_id": "Green-tek-A13332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "1:30:00 PM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "1/9/2024 13:45",
    "plant_id": "Green-tek-A13333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "1:45:00 PM",
    "power": 0.604,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "1/9/2024 14:00",
    "plant_id": "Green-tek-A13334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "2:00:00 PM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "1/9/2024 14:15",
    "plant_id": "Green-tek-A13335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "2:15:00 PM",
    "power": 0.418,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "1/9/2024 14:30",
    "plant_id": "Green-tek-A13336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "2:30:00 PM",
    "power": 0.338,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "1/9/2024 14:45",
    "plant_id": "Green-tek-A13337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "2:45:00 PM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "1/9/2024 15:00",
    "plant_id": "Green-tek-A13338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "3:00:00 PM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "1/9/2024 15:15",
    "plant_id": "Green-tek-A13339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "3:15:00 PM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "1/9/2024 15:30",
    "plant_id": "Green-tek-A13340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "3:30:00 PM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/9/2024 15:45",
    "plant_id": "Green-tek-A13341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "3:45:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/9/2024 16:00",
    "plant_id": "Green-tek-A13342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/9/2024 16:15",
    "plant_id": "Green-tek-A13343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/9/2024 16:30",
    "plant_id": "Green-tek-A13344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/9/2024 16:45",
    "plant_id": "Green-tek-A13345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/9/2024 17:00",
    "plant_id": "Green-tek-A13346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/9/2024 17:15",
    "plant_id": "Green-tek-A13347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/9/2024 17:30",
    "plant_id": "Green-tek-A13348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/9/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/10/2024 6:00",
    "plant_id": "Green-tek-A13349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/10/2024 6:15",
    "plant_id": "Green-tek-A13350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/10/2024 6:30",
    "plant_id": "Green-tek-A13351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/10/2024 6:45",
    "plant_id": "Green-tek-A13352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/10/2024 7:00",
    "plant_id": "Green-tek-A13353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/10/2024 7:15",
    "plant_id": "Green-tek-A13354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/10/2024 7:30",
    "plant_id": "Green-tek-A13355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/10/2024 7:45",
    "plant_id": "Green-tek-A13356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/10/2024 8:00",
    "plant_id": "Green-tek-A13357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "8:00:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/10/2024 8:15",
    "plant_id": "Green-tek-A13358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "1/10/2024 8:30",
    "plant_id": "Green-tek-A13359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "8:30:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "1/10/2024 8:45",
    "plant_id": "Green-tek-A13360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "8:45:00 AM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "1/10/2024 9:00",
    "plant_id": "Green-tek-A13361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "9:00:00 AM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "1/10/2024 9:15",
    "plant_id": "Green-tek-A13362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "9:15:00 AM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "1/10/2024 9:30",
    "plant_id": "Green-tek-A13363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "9:30:00 AM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "1/10/2024 9:45",
    "plant_id": "Green-tek-A13364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "9:45:00 AM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "1/10/2024 10:00",
    "plant_id": "Green-tek-A13365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "10:00:00 AM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "1/10/2024 10:15",
    "plant_id": "Green-tek-A13366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "10:15:00 AM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "1/10/2024 10:30",
    "plant_id": "Green-tek-A13367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "10:30:00 AM",
    "power": 0.742,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "1/10/2024 10:45",
    "plant_id": "Green-tek-A13368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "10:45:00 AM",
    "power": 0.814,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "1/10/2024 11:00",
    "plant_id": "Green-tek-A13369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "11:00:00 AM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "1/10/2024 11:15",
    "plant_id": "Green-tek-A13370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "11:15:00 AM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "1/10/2024 11:30",
    "plant_id": "Green-tek-A13371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "11:30:00 AM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "1/10/2024 11:45",
    "plant_id": "Green-tek-A13372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "11:45:00 AM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "1/10/2024 12:00",
    "plant_id": "Green-tek-A13373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "12:00:00 PM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "1/10/2024 12:15",
    "plant_id": "Green-tek-A13374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "12:15:00 PM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "1/10/2024 12:30",
    "plant_id": "Green-tek-A13375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "12:30:00 PM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "1/10/2024 12:45",
    "plant_id": "Green-tek-A13376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "12:45:00 PM",
    "power": 0.814,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "1/10/2024 13:00",
    "plant_id": "Green-tek-A13377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "1:00:00 PM",
    "power": 0.742,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "1/10/2024 13:15",
    "plant_id": "Green-tek-A13378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "1:15:00 PM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "1/10/2024 13:30",
    "plant_id": "Green-tek-A13379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "1:30:00 PM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "1/10/2024 13:45",
    "plant_id": "Green-tek-A13380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "1:45:00 PM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "1/10/2024 14:00",
    "plant_id": "Green-tek-A13381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "2:00:00 PM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "1/10/2024 14:15",
    "plant_id": "Green-tek-A13382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "2:15:00 PM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "1/10/2024 14:30",
    "plant_id": "Green-tek-A13383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "2:30:00 PM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "1/10/2024 14:45",
    "plant_id": "Green-tek-A13384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "2:45:00 PM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "1/10/2024 15:00",
    "plant_id": "Green-tek-A13385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "3:00:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "1/10/2024 15:15",
    "plant_id": "Green-tek-A13386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "1/10/2024 15:30",
    "plant_id": "Green-tek-A13387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "3:30:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/10/2024 15:45",
    "plant_id": "Green-tek-A13388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/10/2024 16:00",
    "plant_id": "Green-tek-A13389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/10/2024 16:15",
    "plant_id": "Green-tek-A13390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/10/2024 16:30",
    "plant_id": "Green-tek-A13391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/10/2024 16:45",
    "plant_id": "Green-tek-A13392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/10/2024 17:00",
    "plant_id": "Green-tek-A13393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/10/2024 17:15",
    "plant_id": "Green-tek-A13394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/10/2024 17:30",
    "plant_id": "Green-tek-A13395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/10/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/11/2024 6:00",
    "plant_id": "Green-tek-A13396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/11/2024 6:15",
    "plant_id": "Green-tek-A13397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/11/2024 6:30",
    "plant_id": "Green-tek-A13398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/11/2024 6:45",
    "plant_id": "Green-tek-A13399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/11/2024 7:00",
    "plant_id": "Green-tek-A13400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/11/2024 7:15",
    "plant_id": "Green-tek-A13401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/11/2024 7:30",
    "plant_id": "Green-tek-A13402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/11/2024 7:45",
    "plant_id": "Green-tek-A13403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "7:45:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/11/2024 8:00",
    "plant_id": "Green-tek-A13404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "8:00:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/11/2024 8:15",
    "plant_id": "Green-tek-A13405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "8:15:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/11/2024 8:30",
    "plant_id": "Green-tek-A13406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "8:30:00 AM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/11/2024 8:45",
    "plant_id": "Green-tek-A13407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "8:45:00 AM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "1/11/2024 9:00",
    "plant_id": "Green-tek-A13408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "9:00:00 AM",
    "power": 0.238,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "1/11/2024 9:15",
    "plant_id": "Green-tek-A13409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "9:15:00 AM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "1/11/2024 9:30",
    "plant_id": "Green-tek-A13410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "9:30:00 AM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "1/11/2024 9:45",
    "plant_id": "Green-tek-A13411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "9:45:00 AM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "1/11/2024 10:00",
    "plant_id": "Green-tek-A13412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "10:00:00 AM",
    "power": 0.497,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "1/11/2024 10:15",
    "plant_id": "Green-tek-A13413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "10:15:00 AM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/11/2024 10:30",
    "plant_id": "Green-tek-A13414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "10:30:00 AM",
    "power": 0.635,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "1/11/2024 10:45",
    "plant_id": "Green-tek-A13415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "10:45:00 AM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "1/11/2024 11:00",
    "plant_id": "Green-tek-A13416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "11:00:00 AM",
    "power": 0.748,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "1/11/2024 11:15",
    "plant_id": "Green-tek-A13417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "11:15:00 AM",
    "power": 0.787,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "1/11/2024 11:30",
    "plant_id": "Green-tek-A13418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "11:30:00 AM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "1/11/2024 11:45",
    "plant_id": "Green-tek-A13419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "11:45:00 AM",
    "power": 0.82,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "1/11/2024 12:00",
    "plant_id": "Green-tek-A13420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "12:00:00 PM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "1/11/2024 12:15",
    "plant_id": "Green-tek-A13421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "12:15:00 PM",
    "power": 0.787,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "1/11/2024 12:30",
    "plant_id": "Green-tek-A13422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "12:30:00 PM",
    "power": 0.748,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "1/11/2024 12:45",
    "plant_id": "Green-tek-A13423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "12:45:00 PM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "1/11/2024 13:00",
    "plant_id": "Green-tek-A13424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "1:00:00 PM",
    "power": 0.635,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "1/11/2024 13:15",
    "plant_id": "Green-tek-A13425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "1:15:00 PM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/11/2024 13:30",
    "plant_id": "Green-tek-A13426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "1:30:00 PM",
    "power": 0.497,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "1/11/2024 13:45",
    "plant_id": "Green-tek-A13427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "1:45:00 PM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "1/11/2024 14:00",
    "plant_id": "Green-tek-A13428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "2:00:00 PM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "1/11/2024 14:15",
    "plant_id": "Green-tek-A13429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "2:15:00 PM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "1/11/2024 14:30",
    "plant_id": "Green-tek-A13430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "2:30:00 PM",
    "power": 0.238,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "1/11/2024 14:45",
    "plant_id": "Green-tek-A13431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "2:45:00 PM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "1/11/2024 15:00",
    "plant_id": "Green-tek-A13432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "3:00:00 PM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/11/2024 15:15",
    "plant_id": "Green-tek-A13433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "3:15:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/11/2024 15:30",
    "plant_id": "Green-tek-A13434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "3:30:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/11/2024 15:45",
    "plant_id": "Green-tek-A13435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "3:45:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/11/2024 16:00",
    "plant_id": "Green-tek-A13436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/11/2024 16:15",
    "plant_id": "Green-tek-A13437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/11/2024 16:30",
    "plant_id": "Green-tek-A13438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/11/2024 16:45",
    "plant_id": "Green-tek-A13439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/11/2024 17:00",
    "plant_id": "Green-tek-A13440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/11/2024 17:15",
    "plant_id": "Green-tek-A13441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/11/2024 17:30",
    "plant_id": "Green-tek-A13442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/11/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/12/2024 6:00",
    "plant_id": "Green-tek-A13443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/12/2024 6:15",
    "plant_id": "Green-tek-A13444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/12/2024 6:30",
    "plant_id": "Green-tek-A13445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/12/2024 6:45",
    "plant_id": "Green-tek-A13446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/12/2024 7:00",
    "plant_id": "Green-tek-A13447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/12/2024 7:15",
    "plant_id": "Green-tek-A13448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/12/2024 7:30",
    "plant_id": "Green-tek-A13449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/12/2024 7:45",
    "plant_id": "Green-tek-A13450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "7:45:00 AM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/12/2024 8:00",
    "plant_id": "Green-tek-A13451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "8:00:00 AM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "1/12/2024 8:15",
    "plant_id": "Green-tek-A13452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "8:15:00 AM",
    "power": 0.134,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "1/12/2024 8:30",
    "plant_id": "Green-tek-A13453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "8:30:00 AM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/12/2024 8:45",
    "plant_id": "Green-tek-A13454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "8:45:00 AM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "1/12/2024 9:00",
    "plant_id": "Green-tek-A13455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "9:00:00 AM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "1/12/2024 9:15",
    "plant_id": "Green-tek-A13456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "9:15:00 AM",
    "power": 0.358,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "1/12/2024 9:30",
    "plant_id": "Green-tek-A13457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "9:30:00 AM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "1/12/2024 9:45",
    "plant_id": "Green-tek-A13458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "9:45:00 AM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "1/12/2024 10:00",
    "plant_id": "Green-tek-A13459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "10:00:00 AM",
    "power": 0.602,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "1/12/2024 10:15",
    "plant_id": "Green-tek-A13460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "10:15:00 AM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "1/12/2024 10:30",
    "plant_id": "Green-tek-A13461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "10:30:00 AM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "1/12/2024 10:45",
    "plant_id": "Green-tek-A13462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "10:45:00 AM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "1/12/2024 11:00",
    "plant_id": "Green-tek-A13463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "11:00:00 AM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "1/12/2024 11:15",
    "plant_id": "Green-tek-A13464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "11:15:00 AM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "1/12/2024 11:30",
    "plant_id": "Green-tek-A13465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "11:30:00 AM",
    "power": 0.983,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "1/12/2024 11:45",
    "plant_id": "Green-tek-A13466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "11:45:00 AM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "1/12/2024 12:00",
    "plant_id": "Green-tek-A13467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "12:00:00 PM",
    "power": 0.983,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "1/12/2024 12:15",
    "plant_id": "Green-tek-A13468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "12:15:00 PM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "1/12/2024 12:30",
    "plant_id": "Green-tek-A13469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "12:30:00 PM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "1/12/2024 12:45",
    "plant_id": "Green-tek-A13470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "12:45:00 PM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "1/12/2024 13:00",
    "plant_id": "Green-tek-A13471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "1:00:00 PM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "1/12/2024 13:15",
    "plant_id": "Green-tek-A13472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "1:15:00 PM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "1/12/2024 13:30",
    "plant_id": "Green-tek-A13473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "1:30:00 PM",
    "power": 0.602,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "1/12/2024 13:45",
    "plant_id": "Green-tek-A13474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "1:45:00 PM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "1/12/2024 14:00",
    "plant_id": "Green-tek-A13475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "2:00:00 PM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "1/12/2024 14:15",
    "plant_id": "Green-tek-A13476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "2:15:00 PM",
    "power": 0.358,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "1/12/2024 14:30",
    "plant_id": "Green-tek-A13477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "2:30:00 PM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "1/12/2024 14:45",
    "plant_id": "Green-tek-A13478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "2:45:00 PM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "1/12/2024 15:00",
    "plant_id": "Green-tek-A13479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "3:00:00 PM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/12/2024 15:15",
    "plant_id": "Green-tek-A13480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "3:15:00 PM",
    "power": 0.134,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "1/12/2024 15:30",
    "plant_id": "Green-tek-A13481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "3:30:00 PM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "1/12/2024 15:45",
    "plant_id": "Green-tek-A13482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "3:45:00 PM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/12/2024 16:00",
    "plant_id": "Green-tek-A13483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/12/2024 16:15",
    "plant_id": "Green-tek-A13484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/12/2024 16:30",
    "plant_id": "Green-tek-A13485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/12/2024 16:45",
    "plant_id": "Green-tek-A13486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/12/2024 17:00",
    "plant_id": "Green-tek-A13487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/12/2024 17:15",
    "plant_id": "Green-tek-A13488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/12/2024 17:30",
    "plant_id": "Green-tek-A13489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/12/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/13/2024 6:00",
    "plant_id": "Green-tek-A13490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/13/2024 6:15",
    "plant_id": "Green-tek-A13491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/13/2024 6:30",
    "plant_id": "Green-tek-A13492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/13/2024 6:45",
    "plant_id": "Green-tek-A13493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/13/2024 7:00",
    "plant_id": "Green-tek-A13494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/13/2024 7:15",
    "plant_id": "Green-tek-A13495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/13/2024 7:30",
    "plant_id": "Green-tek-A13496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/13/2024 7:45",
    "plant_id": "Green-tek-A13497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/13/2024 8:00",
    "plant_id": "Green-tek-A13498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "8:00:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/13/2024 8:15",
    "plant_id": "Green-tek-A13499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "8:15:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/13/2024 8:30",
    "plant_id": "Green-tek-A13500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "8:30:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/13/2024 8:45",
    "plant_id": "Green-tek-A13501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "8:45:00 AM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/13/2024 9:00",
    "plant_id": "Green-tek-A13502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "9:00:00 AM",
    "power": 0.246,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "1/13/2024 9:15",
    "plant_id": "Green-tek-A13503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "9:15:00 AM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "1/13/2024 9:30",
    "plant_id": "Green-tek-A13504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "9:30:00 AM",
    "power": 0.37,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "1/13/2024 9:45",
    "plant_id": "Green-tek-A13505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "9:45:00 AM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "1/13/2024 10:00",
    "plant_id": "Green-tek-A13506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "10:00:00 AM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "1/13/2024 10:15",
    "plant_id": "Green-tek-A13507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "10:15:00 AM",
    "power": 0.586,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "1/13/2024 10:30",
    "plant_id": "Green-tek-A13508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "10:30:00 AM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "1/13/2024 10:45",
    "plant_id": "Green-tek-A13509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "10:45:00 AM",
    "power": 0.719,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "1/13/2024 11:00",
    "plant_id": "Green-tek-A13510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "11:00:00 AM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "1/13/2024 11:15",
    "plant_id": "Green-tek-A13511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "11:15:00 AM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "1/13/2024 11:30",
    "plant_id": "Green-tek-A13512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "11:30:00 AM",
    "power": 0.838,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/13/2024 11:45",
    "plant_id": "Green-tek-A13513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "11:45:00 AM",
    "power": 0.846,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "1/13/2024 12:00",
    "plant_id": "Green-tek-A13514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "12:00:00 PM",
    "power": 0.838,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/13/2024 12:15",
    "plant_id": "Green-tek-A13515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "12:15:00 PM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "1/13/2024 12:30",
    "plant_id": "Green-tek-A13516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "12:30:00 PM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "1/13/2024 12:45",
    "plant_id": "Green-tek-A13517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "12:45:00 PM",
    "power": 0.719,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "1/13/2024 13:00",
    "plant_id": "Green-tek-A13518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "1:00:00 PM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "1/13/2024 13:15",
    "plant_id": "Green-tek-A13519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "1:15:00 PM",
    "power": 0.586,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "1/13/2024 13:30",
    "plant_id": "Green-tek-A13520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "1:30:00 PM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "1/13/2024 13:45",
    "plant_id": "Green-tek-A13521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "1:45:00 PM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "1/13/2024 14:00",
    "plant_id": "Green-tek-A13522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "2:00:00 PM",
    "power": 0.37,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "1/13/2024 14:15",
    "plant_id": "Green-tek-A13523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "2:15:00 PM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "1/13/2024 14:30",
    "plant_id": "Green-tek-A13524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "2:30:00 PM",
    "power": 0.246,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "1/13/2024 14:45",
    "plant_id": "Green-tek-A13525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "2:45:00 PM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/13/2024 15:00",
    "plant_id": "Green-tek-A13526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "3:00:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/13/2024 15:15",
    "plant_id": "Green-tek-A13527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "3:15:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/13/2024 15:30",
    "plant_id": "Green-tek-A13528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "3:30:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/13/2024 15:45",
    "plant_id": "Green-tek-A13529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/13/2024 16:00",
    "plant_id": "Green-tek-A13530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/13/2024 16:15",
    "plant_id": "Green-tek-A13531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/13/2024 16:30",
    "plant_id": "Green-tek-A13532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/13/2024 16:45",
    "plant_id": "Green-tek-A13533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/13/2024 17:00",
    "plant_id": "Green-tek-A13534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/13/2024 17:15",
    "plant_id": "Green-tek-A13535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/13/2024 17:30",
    "plant_id": "Green-tek-A13536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/13/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/14/2024 6:00",
    "plant_id": "Green-tek-A13537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/14/2024 6:15",
    "plant_id": "Green-tek-A13538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/14/2024 6:30",
    "plant_id": "Green-tek-A13539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/14/2024 6:45",
    "plant_id": "Green-tek-A13540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/14/2024 7:00",
    "plant_id": "Green-tek-A13541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/14/2024 7:15",
    "plant_id": "Green-tek-A13542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/14/2024 7:30",
    "plant_id": "Green-tek-A13543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/14/2024 7:45",
    "plant_id": "Green-tek-A13544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/14/2024 8:00",
    "plant_id": "Green-tek-A13545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "8:00:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/14/2024 8:15",
    "plant_id": "Green-tek-A13546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "8:15:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/14/2024 8:30",
    "plant_id": "Green-tek-A13547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "8:30:00 AM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/14/2024 8:45",
    "plant_id": "Green-tek-A13548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "8:45:00 AM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/14/2024 9:00",
    "plant_id": "Green-tek-A13549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "9:00:00 AM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "1/14/2024 9:15",
    "plant_id": "Green-tek-A13550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "9:15:00 AM",
    "power": 0.307,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "1/14/2024 9:30",
    "plant_id": "Green-tek-A13551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "9:30:00 AM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "1/14/2024 9:45",
    "plant_id": "Green-tek-A13552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "9:45:00 AM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "1/14/2024 10:00",
    "plant_id": "Green-tek-A13553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "10:00:00 AM",
    "power": 0.516,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "1/14/2024 10:15",
    "plant_id": "Green-tek-A13554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "10:15:00 AM",
    "power": 0.589,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "1/14/2024 10:30",
    "plant_id": "Green-tek-A13555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "10:30:00 AM",
    "power": 0.659,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "1/14/2024 10:45",
    "plant_id": "Green-tek-A13556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "10:45:00 AM",
    "power": 0.723,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "1/14/2024 11:00",
    "plant_id": "Green-tek-A13557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "11:00:00 AM",
    "power": 0.776,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "1/14/2024 11:15",
    "plant_id": "Green-tek-A13558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "11:15:00 AM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "1/14/2024 11:30",
    "plant_id": "Green-tek-A13559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "11:30:00 AM",
    "power": 0.842,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "1/14/2024 11:45",
    "plant_id": "Green-tek-A13560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "11:45:00 AM",
    "power": 0.851,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "1/14/2024 12:00",
    "plant_id": "Green-tek-A13561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "12:00:00 PM",
    "power": 0.842,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "1/14/2024 12:15",
    "plant_id": "Green-tek-A13562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "12:15:00 PM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "1/14/2024 12:30",
    "plant_id": "Green-tek-A13563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "12:30:00 PM",
    "power": 0.776,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "1/14/2024 12:45",
    "plant_id": "Green-tek-A13564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "12:45:00 PM",
    "power": 0.723,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "1/14/2024 13:00",
    "plant_id": "Green-tek-A13565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "1:00:00 PM",
    "power": 0.659,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "1/14/2024 13:15",
    "plant_id": "Green-tek-A13566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "1:15:00 PM",
    "power": 0.589,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "1/14/2024 13:30",
    "plant_id": "Green-tek-A13567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "1:30:00 PM",
    "power": 0.516,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "1/14/2024 13:45",
    "plant_id": "Green-tek-A13568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "1:45:00 PM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "1/14/2024 14:00",
    "plant_id": "Green-tek-A13569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "2:00:00 PM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "1/14/2024 14:15",
    "plant_id": "Green-tek-A13570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "2:15:00 PM",
    "power": 0.307,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "1/14/2024 14:30",
    "plant_id": "Green-tek-A13571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "2:30:00 PM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "1/14/2024 14:45",
    "plant_id": "Green-tek-A13572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "2:45:00 PM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/14/2024 15:00",
    "plant_id": "Green-tek-A13573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "3:00:00 PM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/14/2024 15:15",
    "plant_id": "Green-tek-A13574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "3:15:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/14/2024 15:30",
    "plant_id": "Green-tek-A13575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "3:30:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/14/2024 15:45",
    "plant_id": "Green-tek-A13576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/14/2024 16:00",
    "plant_id": "Green-tek-A13577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/14/2024 16:15",
    "plant_id": "Green-tek-A13578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/14/2024 16:30",
    "plant_id": "Green-tek-A13579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/14/2024 16:45",
    "plant_id": "Green-tek-A13580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/14/2024 17:00",
    "plant_id": "Green-tek-A13581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/14/2024 17:15",
    "plant_id": "Green-tek-A13582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/14/2024 17:30",
    "plant_id": "Green-tek-A13583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/14/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/15/2024 6:00",
    "plant_id": "Green-tek-A13584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/15/2024 6:15",
    "plant_id": "Green-tek-A13585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/15/2024 6:30",
    "plant_id": "Green-tek-A13586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/15/2024 6:45",
    "plant_id": "Green-tek-A13587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/15/2024 7:00",
    "plant_id": "Green-tek-A13588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/15/2024 7:15",
    "plant_id": "Green-tek-A13589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/15/2024 7:30",
    "plant_id": "Green-tek-A13590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/15/2024 7:45",
    "plant_id": "Green-tek-A13591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "7:45:00 AM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/15/2024 8:00",
    "plant_id": "Green-tek-A13592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "8:00:00 AM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "1/15/2024 8:15",
    "plant_id": "Green-tek-A13593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "8:15:00 AM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/15/2024 8:30",
    "plant_id": "Green-tek-A13594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "8:30:00 AM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/15/2024 8:45",
    "plant_id": "Green-tek-A13595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "8:45:00 AM",
    "power": 0.226,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "1/15/2024 9:00",
    "plant_id": "Green-tek-A13596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "9:00:00 AM",
    "power": 0.286,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "1/15/2024 9:15",
    "plant_id": "Green-tek-A13597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "9:15:00 AM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "1/15/2024 9:30",
    "plant_id": "Green-tek-A13598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "9:30:00 AM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "1/15/2024 9:45",
    "plant_id": "Green-tek-A13599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "9:45:00 AM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "1/15/2024 10:00",
    "plant_id": "Green-tek-A13600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "10:00:00 AM",
    "power": 0.597,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "1/15/2024 10:15",
    "plant_id": "Green-tek-A13601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "10:15:00 AM",
    "power": 0.682,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "1/15/2024 10:30",
    "plant_id": "Green-tek-A13602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "10:30:00 AM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "1/15/2024 10:45",
    "plant_id": "Green-tek-A13603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "10:45:00 AM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/15/2024 11:00",
    "plant_id": "Green-tek-A13604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "11:00:00 AM",
    "power": 0.898,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "1/15/2024 11:15",
    "plant_id": "Green-tek-A13605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "11:15:00 AM",
    "power": 0.945,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "1/15/2024 11:30",
    "plant_id": "Green-tek-A13606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "11:30:00 AM",
    "power": 0.974,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "1/15/2024 11:45",
    "plant_id": "Green-tek-A13607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "11:45:00 AM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "1/15/2024 12:00",
    "plant_id": "Green-tek-A13608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "12:00:00 PM",
    "power": 0.974,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "1/15/2024 12:15",
    "plant_id": "Green-tek-A13609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "12:15:00 PM",
    "power": 0.945,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "1/15/2024 12:30",
    "plant_id": "Green-tek-A13610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "12:30:00 PM",
    "power": 0.898,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "1/15/2024 12:45",
    "plant_id": "Green-tek-A13611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "12:45:00 PM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/15/2024 13:00",
    "plant_id": "Green-tek-A13612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "1:00:00 PM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "1/15/2024 13:15",
    "plant_id": "Green-tek-A13613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "1:15:00 PM",
    "power": 0.682,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "1/15/2024 13:30",
    "plant_id": "Green-tek-A13614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "1:30:00 PM",
    "power": 0.597,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "1/15/2024 13:45",
    "plant_id": "Green-tek-A13615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "1:45:00 PM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "1/15/2024 14:00",
    "plant_id": "Green-tek-A13616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "2:00:00 PM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "1/15/2024 14:15",
    "plant_id": "Green-tek-A13617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "2:15:00 PM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "1/15/2024 14:30",
    "plant_id": "Green-tek-A13618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "2:30:00 PM",
    "power": 0.286,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "1/15/2024 14:45",
    "plant_id": "Green-tek-A13619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "2:45:00 PM",
    "power": 0.226,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "1/15/2024 15:00",
    "plant_id": "Green-tek-A13620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "3:00:00 PM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/15/2024 15:15",
    "plant_id": "Green-tek-A13621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "3:15:00 PM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/15/2024 15:30",
    "plant_id": "Green-tek-A13622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "3:30:00 PM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "1/15/2024 15:45",
    "plant_id": "Green-tek-A13623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "3:45:00 PM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/15/2024 16:00",
    "plant_id": "Green-tek-A13624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/15/2024 16:15",
    "plant_id": "Green-tek-A13625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/15/2024 16:30",
    "plant_id": "Green-tek-A13626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/15/2024 16:45",
    "plant_id": "Green-tek-A13627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/15/2024 17:00",
    "plant_id": "Green-tek-A13628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/15/2024 17:15",
    "plant_id": "Green-tek-A13629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/15/2024 17:30",
    "plant_id": "Green-tek-A13630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/15/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/16/2024 6:00",
    "plant_id": "Green-tek-A13631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/16/2024 6:15",
    "plant_id": "Green-tek-A13632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/16/2024 6:30",
    "plant_id": "Green-tek-A13633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/16/2024 6:45",
    "plant_id": "Green-tek-A13634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/16/2024 7:00",
    "plant_id": "Green-tek-A13635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/16/2024 7:15",
    "plant_id": "Green-tek-A13636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/16/2024 7:30",
    "plant_id": "Green-tek-A13637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/16/2024 7:45",
    "plant_id": "Green-tek-A13638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/16/2024 8:00",
    "plant_id": "Green-tek-A13639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "8:00:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/16/2024 8:15",
    "plant_id": "Green-tek-A13640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "8:15:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/16/2024 8:30",
    "plant_id": "Green-tek-A13641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "8:30:00 AM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/16/2024 8:45",
    "plant_id": "Green-tek-A13642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "8:45:00 AM",
    "power": 0.19,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "1/16/2024 9:00",
    "plant_id": "Green-tek-A13643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "9:00:00 AM",
    "power": 0.24,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "1/16/2024 9:15",
    "plant_id": "Green-tek-A13644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "9:15:00 AM",
    "power": 0.298,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "1/16/2024 9:30",
    "plant_id": "Green-tek-A13645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "9:30:00 AM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "1/16/2024 9:45",
    "plant_id": "Green-tek-A13646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "9:45:00 AM",
    "power": 0.43,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "1/16/2024 10:00",
    "plant_id": "Green-tek-A13647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "10:00:00 AM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "1/16/2024 10:15",
    "plant_id": "Green-tek-A13648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "10:15:00 AM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "1/16/2024 10:30",
    "plant_id": "Green-tek-A13649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "10:30:00 AM",
    "power": 0.64,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "1/16/2024 10:45",
    "plant_id": "Green-tek-A13650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "10:45:00 AM",
    "power": 0.702,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "1/16/2024 11:00",
    "plant_id": "Green-tek-A13651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "11:00:00 AM",
    "power": 0.754,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "1/16/2024 11:15",
    "plant_id": "Green-tek-A13652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "11:15:00 AM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "1/16/2024 11:30",
    "plant_id": "Green-tek-A13653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "11:30:00 AM",
    "power": 0.818,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "1/16/2024 11:45",
    "plant_id": "Green-tek-A13654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "11:45:00 AM",
    "power": 0.826,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "1/16/2024 12:00",
    "plant_id": "Green-tek-A13655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "12:00:00 PM",
    "power": 0.818,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "1/16/2024 12:15",
    "plant_id": "Green-tek-A13656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "12:15:00 PM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "1/16/2024 12:30",
    "plant_id": "Green-tek-A13657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "12:30:00 PM",
    "power": 0.754,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "1/16/2024 12:45",
    "plant_id": "Green-tek-A13658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "12:45:00 PM",
    "power": 0.702,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "1/16/2024 13:00",
    "plant_id": "Green-tek-A13659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "1:00:00 PM",
    "power": 0.64,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "1/16/2024 13:15",
    "plant_id": "Green-tek-A13660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "1:15:00 PM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "1/16/2024 13:30",
    "plant_id": "Green-tek-A13661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "1:30:00 PM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "1/16/2024 13:45",
    "plant_id": "Green-tek-A13662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "1:45:00 PM",
    "power": 0.43,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "1/16/2024 14:00",
    "plant_id": "Green-tek-A13663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "2:00:00 PM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "1/16/2024 14:15",
    "plant_id": "Green-tek-A13664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "2:15:00 PM",
    "power": 0.298,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "1/16/2024 14:30",
    "plant_id": "Green-tek-A13665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "2:30:00 PM",
    "power": 0.24,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "1/16/2024 14:45",
    "plant_id": "Green-tek-A13666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "2:45:00 PM",
    "power": 0.19,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "1/16/2024 15:00",
    "plant_id": "Green-tek-A13667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "3:00:00 PM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/16/2024 15:15",
    "plant_id": "Green-tek-A13668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "3:15:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/16/2024 15:30",
    "plant_id": "Green-tek-A13669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "3:30:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/16/2024 15:45",
    "plant_id": "Green-tek-A13670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/16/2024 16:00",
    "plant_id": "Green-tek-A13671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/16/2024 16:15",
    "plant_id": "Green-tek-A13672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/16/2024 16:30",
    "plant_id": "Green-tek-A13673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/16/2024 16:45",
    "plant_id": "Green-tek-A13674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/16/2024 17:00",
    "plant_id": "Green-tek-A13675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/16/2024 17:15",
    "plant_id": "Green-tek-A13676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/16/2024 17:30",
    "plant_id": "Green-tek-A13677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/16/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/17/2024 6:00",
    "plant_id": "Green-tek-A13678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/17/2024 6:15",
    "plant_id": "Green-tek-A13679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/17/2024 6:30",
    "plant_id": "Green-tek-A13680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/17/2024 6:45",
    "plant_id": "Green-tek-A13681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/17/2024 7:00",
    "plant_id": "Green-tek-A13682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/17/2024 7:15",
    "plant_id": "Green-tek-A13683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/17/2024 7:30",
    "plant_id": "Green-tek-A13684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/17/2024 7:45",
    "plant_id": "Green-tek-A13685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "7:45:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/17/2024 8:00",
    "plant_id": "Green-tek-A13686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "8:00:00 AM",
    "power": 0.114,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/17/2024 8:15",
    "plant_id": "Green-tek-A13687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "8:15:00 AM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/17/2024 8:30",
    "plant_id": "Green-tek-A13688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "8:30:00 AM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "1/17/2024 8:45",
    "plant_id": "Green-tek-A13689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "8:45:00 AM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "1/17/2024 9:00",
    "plant_id": "Green-tek-A13690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "9:00:00 AM",
    "power": 0.328,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "1/17/2024 9:15",
    "plant_id": "Green-tek-A13691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "9:15:00 AM",
    "power": 0.406,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "1/17/2024 9:30",
    "plant_id": "Green-tek-A13692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "9:30:00 AM",
    "power": 0.493,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "1/17/2024 9:45",
    "plant_id": "Green-tek-A13693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "9:45:00 AM",
    "power": 0.587,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "1/17/2024 10:00",
    "plant_id": "Green-tek-A13694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "10:00:00 AM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "1/17/2024 10:15",
    "plant_id": "Green-tek-A13695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "10:15:00 AM",
    "power": 0.781,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "1/17/2024 10:30",
    "plant_id": "Green-tek-A13696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "10:30:00 AM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "1/17/2024 10:45",
    "plant_id": "Green-tek-A13697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "10:45:00 AM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "1/17/2024 11:00",
    "plant_id": "Green-tek-A13698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "11:00:00 AM",
    "power": 1.029,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "1/17/2024 11:15",
    "plant_id": "Green-tek-A13699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "11:15:00 AM",
    "power": 1.082,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "1/17/2024 11:30",
    "plant_id": "Green-tek-A13700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "11:30:00 AM",
    "power": 1.116,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "1/17/2024 11:45",
    "plant_id": "Green-tek-A13701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "11:45:00 AM",
    "power": 1.128,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "1/17/2024 12:00",
    "plant_id": "Green-tek-A13702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "12:00:00 PM",
    "power": 1.116,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "1/17/2024 12:15",
    "plant_id": "Green-tek-A13703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "12:15:00 PM",
    "power": 1.082,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "1/17/2024 12:30",
    "plant_id": "Green-tek-A13704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "12:30:00 PM",
    "power": 1.029,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "1/17/2024 12:45",
    "plant_id": "Green-tek-A13705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "12:45:00 PM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "1/17/2024 13:00",
    "plant_id": "Green-tek-A13706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "1:00:00 PM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "1/17/2024 13:15",
    "plant_id": "Green-tek-A13707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "1:15:00 PM",
    "power": 0.781,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "1/17/2024 13:30",
    "plant_id": "Green-tek-A13708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "1:30:00 PM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "1/17/2024 13:45",
    "plant_id": "Green-tek-A13709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "1:45:00 PM",
    "power": 0.587,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "1/17/2024 14:00",
    "plant_id": "Green-tek-A13710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "2:00:00 PM",
    "power": 0.493,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "1/17/2024 14:15",
    "plant_id": "Green-tek-A13711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "2:15:00 PM",
    "power": 0.406,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "1/17/2024 14:30",
    "plant_id": "Green-tek-A13712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "2:30:00 PM",
    "power": 0.328,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "1/17/2024 14:45",
    "plant_id": "Green-tek-A13713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "2:45:00 PM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "1/17/2024 15:00",
    "plant_id": "Green-tek-A13714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "3:00:00 PM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "1/17/2024 15:15",
    "plant_id": "Green-tek-A13715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "3:15:00 PM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/17/2024 15:30",
    "plant_id": "Green-tek-A13716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "3:30:00 PM",
    "power": 0.114,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/17/2024 15:45",
    "plant_id": "Green-tek-A13717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "3:45:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/17/2024 16:00",
    "plant_id": "Green-tek-A13718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/17/2024 16:15",
    "plant_id": "Green-tek-A13719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/17/2024 16:30",
    "plant_id": "Green-tek-A13720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/17/2024 16:45",
    "plant_id": "Green-tek-A13721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/17/2024 17:00",
    "plant_id": "Green-tek-A13722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/17/2024 17:15",
    "plant_id": "Green-tek-A13723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/17/2024 17:30",
    "plant_id": "Green-tek-A13724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/17/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/18/2024 6:00",
    "plant_id": "Green-tek-A13725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/18/2024 6:15",
    "plant_id": "Green-tek-A13726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/18/2024 6:30",
    "plant_id": "Green-tek-A13727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/18/2024 6:45",
    "plant_id": "Green-tek-A13728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/18/2024 7:00",
    "plant_id": "Green-tek-A13729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/18/2024 7:15",
    "plant_id": "Green-tek-A13730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/18/2024 7:30",
    "plant_id": "Green-tek-A13731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "7:30:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/18/2024 7:45",
    "plant_id": "Green-tek-A13732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/18/2024 8:00",
    "plant_id": "Green-tek-A13733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "8:00:00 AM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "1/18/2024 8:15",
    "plant_id": "Green-tek-A13734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "8:15:00 AM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "1/18/2024 8:30",
    "plant_id": "Green-tek-A13735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "8:30:00 AM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "1/18/2024 8:45",
    "plant_id": "Green-tek-A13736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "8:45:00 AM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "1/18/2024 9:00",
    "plant_id": "Green-tek-A13737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "9:00:00 AM",
    "power": 0.354,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "1/18/2024 9:15",
    "plant_id": "Green-tek-A13738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "9:15:00 AM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "1/18/2024 9:30",
    "plant_id": "Green-tek-A13739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "9:30:00 AM",
    "power": 0.533,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "1/18/2024 9:45",
    "plant_id": "Green-tek-A13740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "9:45:00 AM",
    "power": 0.634,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "1/18/2024 10:00",
    "plant_id": "Green-tek-A13741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "10:00:00 AM",
    "power": 0.738,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "1/18/2024 10:15",
    "plant_id": "Green-tek-A13742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "10:15:00 AM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "1/18/2024 10:30",
    "plant_id": "Green-tek-A13743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "10:30:00 AM",
    "power": 0.943,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "1/18/2024 10:45",
    "plant_id": "Green-tek-A13744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "10:45:00 AM",
    "power": 1.034,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "1/18/2024 11:00",
    "plant_id": "Green-tek-A13745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "11:00:00 AM",
    "power": 1.111,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "1/18/2024 11:15",
    "plant_id": "Green-tek-A13746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "11:15:00 AM",
    "power": 1.169,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "1/18/2024 11:30",
    "plant_id": "Green-tek-A13747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "11:30:00 AM",
    "power": 1.205,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "1/18/2024 11:45",
    "plant_id": "Green-tek-A13748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "11:45:00 AM",
    "power": 1.218,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "1/18/2024 12:00",
    "plant_id": "Green-tek-A13749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "12:00:00 PM",
    "power": 1.205,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "1/18/2024 12:15",
    "plant_id": "Green-tek-A13750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "12:15:00 PM",
    "power": 1.169,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "1/18/2024 12:30",
    "plant_id": "Green-tek-A13751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "12:30:00 PM",
    "power": 1.111,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "1/18/2024 12:45",
    "plant_id": "Green-tek-A13752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "12:45:00 PM",
    "power": 1.034,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "1/18/2024 13:00",
    "plant_id": "Green-tek-A13753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "1:00:00 PM",
    "power": 0.943,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "1/18/2024 13:15",
    "plant_id": "Green-tek-A13754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "1:15:00 PM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "1/18/2024 13:30",
    "plant_id": "Green-tek-A13755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "1:30:00 PM",
    "power": 0.738,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "1/18/2024 13:45",
    "plant_id": "Green-tek-A13756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "1:45:00 PM",
    "power": 0.634,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "1/18/2024 14:00",
    "plant_id": "Green-tek-A13757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "2:00:00 PM",
    "power": 0.533,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "1/18/2024 14:15",
    "plant_id": "Green-tek-A13758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "2:15:00 PM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "1/18/2024 14:30",
    "plant_id": "Green-tek-A13759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "2:30:00 PM",
    "power": 0.354,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "1/18/2024 14:45",
    "plant_id": "Green-tek-A13760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "2:45:00 PM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "1/18/2024 15:00",
    "plant_id": "Green-tek-A13761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "3:00:00 PM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "1/18/2024 15:15",
    "plant_id": "Green-tek-A13762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "3:15:00 PM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "1/18/2024 15:30",
    "plant_id": "Green-tek-A13763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "3:30:00 PM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "1/18/2024 15:45",
    "plant_id": "Green-tek-A13764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/18/2024 16:00",
    "plant_id": "Green-tek-A13765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "4:00:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/18/2024 16:15",
    "plant_id": "Green-tek-A13766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/18/2024 16:30",
    "plant_id": "Green-tek-A13767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/18/2024 16:45",
    "plant_id": "Green-tek-A13768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/18/2024 17:00",
    "plant_id": "Green-tek-A13769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/18/2024 17:15",
    "plant_id": "Green-tek-A13770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/18/2024 17:30",
    "plant_id": "Green-tek-A13771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/18/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/19/2024 6:00",
    "plant_id": "Green-tek-A13772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/19/2024 6:15",
    "plant_id": "Green-tek-A13773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/19/2024 6:30",
    "plant_id": "Green-tek-A13774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/19/2024 6:45",
    "plant_id": "Green-tek-A13775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/19/2024 7:00",
    "plant_id": "Green-tek-A13776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/19/2024 7:15",
    "plant_id": "Green-tek-A13777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/19/2024 7:30",
    "plant_id": "Green-tek-A13778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/19/2024 7:45",
    "plant_id": "Green-tek-A13779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/19/2024 8:00",
    "plant_id": "Green-tek-A13780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "8:00:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/19/2024 8:15",
    "plant_id": "Green-tek-A13781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "8:15:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/19/2024 8:30",
    "plant_id": "Green-tek-A13782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "8:30:00 AM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/19/2024 8:45",
    "plant_id": "Green-tek-A13783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "8:45:00 AM",
    "power": 0.191,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "1/19/2024 9:00",
    "plant_id": "Green-tek-A13784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "9:00:00 AM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "1/19/2024 9:15",
    "plant_id": "Green-tek-A13785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "9:15:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "1/19/2024 9:30",
    "plant_id": "Green-tek-A13786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "9:30:00 AM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "1/19/2024 9:45",
    "plant_id": "Green-tek-A13787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "9:45:00 AM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "1/19/2024 10:00",
    "plant_id": "Green-tek-A13788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "10:00:00 AM",
    "power": 0.504,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "1/19/2024 10:15",
    "plant_id": "Green-tek-A13789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "10:15:00 AM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "1/19/2024 10:30",
    "plant_id": "Green-tek-A13790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "10:30:00 AM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "1/19/2024 10:45",
    "plant_id": "Green-tek-A13791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "10:45:00 AM",
    "power": 0.705,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "1/19/2024 11:00",
    "plant_id": "Green-tek-A13792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "11:00:00 AM",
    "power": 0.757,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "1/19/2024 11:15",
    "plant_id": "Green-tek-A13793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "11:15:00 AM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "1/19/2024 11:30",
    "plant_id": "Green-tek-A13794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "11:30:00 AM",
    "power": 0.822,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "1/19/2024 11:45",
    "plant_id": "Green-tek-A13795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "11:45:00 AM",
    "power": 0.83,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "1/19/2024 12:00",
    "plant_id": "Green-tek-A13796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "12:00:00 PM",
    "power": 0.822,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "1/19/2024 12:15",
    "plant_id": "Green-tek-A13797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "12:15:00 PM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "1/19/2024 12:30",
    "plant_id": "Green-tek-A13798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "12:30:00 PM",
    "power": 0.757,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "1/19/2024 12:45",
    "plant_id": "Green-tek-A13799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "12:45:00 PM",
    "power": 0.705,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "1/19/2024 13:00",
    "plant_id": "Green-tek-A13800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "1:00:00 PM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "1/19/2024 13:15",
    "plant_id": "Green-tek-A13801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "1:15:00 PM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "1/19/2024 13:30",
    "plant_id": "Green-tek-A13802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "1:30:00 PM",
    "power": 0.504,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "1/19/2024 13:45",
    "plant_id": "Green-tek-A13803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "1:45:00 PM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "1/19/2024 14:00",
    "plant_id": "Green-tek-A13804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "2:00:00 PM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "1/19/2024 14:15",
    "plant_id": "Green-tek-A13805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "2:15:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "1/19/2024 14:30",
    "plant_id": "Green-tek-A13806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "2:30:00 PM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "1/19/2024 14:45",
    "plant_id": "Green-tek-A13807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "2:45:00 PM",
    "power": 0.191,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "1/19/2024 15:00",
    "plant_id": "Green-tek-A13808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "3:00:00 PM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/19/2024 15:15",
    "plant_id": "Green-tek-A13809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "3:15:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "1/19/2024 15:30",
    "plant_id": "Green-tek-A13810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "3:30:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/19/2024 15:45",
    "plant_id": "Green-tek-A13811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/19/2024 16:00",
    "plant_id": "Green-tek-A13812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/19/2024 16:15",
    "plant_id": "Green-tek-A13813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/19/2024 16:30",
    "plant_id": "Green-tek-A13814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/19/2024 16:45",
    "plant_id": "Green-tek-A13815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/19/2024 17:00",
    "plant_id": "Green-tek-A13816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/19/2024 17:15",
    "plant_id": "Green-tek-A13817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/19/2024 17:30",
    "plant_id": "Green-tek-A13818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/19/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/20/2024 6:00",
    "plant_id": "Green-tek-A13819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/20/2024 6:15",
    "plant_id": "Green-tek-A13820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/20/2024 6:30",
    "plant_id": "Green-tek-A13821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/20/2024 6:45",
    "plant_id": "Green-tek-A13822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/20/2024 7:00",
    "plant_id": "Green-tek-A13823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/20/2024 7:15",
    "plant_id": "Green-tek-A13824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/20/2024 7:30",
    "plant_id": "Green-tek-A13825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/20/2024 7:45",
    "plant_id": "Green-tek-A13826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "1/20/2024 8:00",
    "plant_id": "Green-tek-A13827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "8:00:00 AM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "1/20/2024 8:15",
    "plant_id": "Green-tek-A13828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "8:15:00 AM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "1/20/2024 8:30",
    "plant_id": "Green-tek-A13829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "8:30:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "1/20/2024 8:45",
    "plant_id": "Green-tek-A13830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "8:45:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "1/20/2024 9:00",
    "plant_id": "Green-tek-A13831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "9:00:00 AM",
    "power": 0.37,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "1/20/2024 9:15",
    "plant_id": "Green-tek-A13832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "9:15:00 AM",
    "power": 0.458,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "1/20/2024 9:30",
    "plant_id": "Green-tek-A13833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "9:30:00 AM",
    "power": 0.556,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "1/20/2024 9:45",
    "plant_id": "Green-tek-A13834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "9:45:00 AM",
    "power": 0.662,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "1/20/2024 10:00",
    "plant_id": "Green-tek-A13835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "10:00:00 AM",
    "power": 0.771,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "1/20/2024 10:15",
    "plant_id": "Green-tek-A13836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "10:15:00 AM",
    "power": 0.88,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "1/20/2024 10:30",
    "plant_id": "Green-tek-A13837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "10:30:00 AM",
    "power": 0.985,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "1/20/2024 10:45",
    "plant_id": "Green-tek-A13838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "10:45:00 AM",
    "power": 1.08,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "1/20/2024 11:00",
    "plant_id": "Green-tek-A13839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "11:00:00 AM",
    "power": 1.16,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "1/20/2024 11:15",
    "plant_id": "Green-tek-A13840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "11:15:00 AM",
    "power": 1.22,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.3213,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "1/20/2024 11:30",
    "plant_id": "Green-tek-A13841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "11:30:00 AM",
    "power": 1.258,
    "Energy": 0.315,
    "output Energy": 0.315,
    "Ac output": 1.315,
    "dc output": 1.3413,
    "AC_R_i": 0.00041087,
    "AC_S_i": 0.000451957,
    "AC_T_i": 0.00049715,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0945001,
    "AC_S_pow": 0.10395011,
    "AC_T_Pow": 0.1143445,
    "AC_R_output": 0.3945,
    "AC_S_output": 0.43395,
    "AC_T_output": 0.477345,
    "dc_current": 0.00231618,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.3213,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000315,
    "carbon offset": 0.39375,
    "trees planted": 8.75,
    "DC_R_output": 0.130185,
    "DC_S_output": 0.1432035,
    "DC_T_output": 0.15752385
  },
  {
    "datetime": "1/20/2024 11:45",
    "plant_id": "Green-tek-A13842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "11:45:00 AM",
    "power": 1.271,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.3213,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "1/20/2024 12:00",
    "plant_id": "Green-tek-A13843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "12:00:00 PM",
    "power": 1.258,
    "Energy": 0.315,
    "output Energy": 0.315,
    "Ac output": 1.315,
    "dc output": 1.3413,
    "AC_R_i": 0.00041087,
    "AC_S_i": 0.000451957,
    "AC_T_i": 0.00049715,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0945001,
    "AC_S_pow": 0.10395011,
    "AC_T_Pow": 0.1143445,
    "AC_R_output": 0.3945,
    "AC_S_output": 0.43395,
    "AC_T_output": 0.477345,
    "dc_current": 0.00231618,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.3213,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000315,
    "carbon offset": 0.39375,
    "trees planted": 8.75,
    "DC_R_output": 0.130185,
    "DC_S_output": 0.1432035,
    "DC_T_output": 0.15752385
  },
  {
    "datetime": "1/20/2024 12:15",
    "plant_id": "Green-tek-A13844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "12:15:00 PM",
    "power": 1.22,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "1/20/2024 12:30",
    "plant_id": "Green-tek-A13845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "12:30:00 PM",
    "power": 1.16,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "1/20/2024 12:45",
    "plant_id": "Green-tek-A13846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "12:45:00 PM",
    "power": 1.08,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "1/20/2024 13:00",
    "plant_id": "Green-tek-A13847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "1:00:00 PM",
    "power": 0.985,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "1/20/2024 13:15",
    "plant_id": "Green-tek-A13848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "1:15:00 PM",
    "power": 0.88,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "1/20/2024 13:30",
    "plant_id": "Green-tek-A13849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "1:30:00 PM",
    "power": 0.771,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "1/20/2024 13:45",
    "plant_id": "Green-tek-A13850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "1:45:00 PM",
    "power": 0.662,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "1/20/2024 14:00",
    "plant_id": "Green-tek-A13851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "2:00:00 PM",
    "power": 0.556,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "1/20/2024 14:15",
    "plant_id": "Green-tek-A13852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "2:15:00 PM",
    "power": 0.458,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "1/20/2024 14:30",
    "plant_id": "Green-tek-A13853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "2:30:00 PM",
    "power": 0.37,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "1/20/2024 14:45",
    "plant_id": "Green-tek-A13854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "2:45:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "1/20/2024 15:00",
    "plant_id": "Green-tek-A13855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "3:00:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "1/20/2024 15:15",
    "plant_id": "Green-tek-A13856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "3:15:00 PM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "1/20/2024 15:30",
    "plant_id": "Green-tek-A13857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "3:30:00 PM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "1/20/2024 15:45",
    "plant_id": "Green-tek-A13858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "1/20/2024 16:00",
    "plant_id": "Green-tek-A13859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/20/2024 16:15",
    "plant_id": "Green-tek-A13860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/20/2024 16:30",
    "plant_id": "Green-tek-A13861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/20/2024 16:45",
    "plant_id": "Green-tek-A13862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/20/2024 17:00",
    "plant_id": "Green-tek-A13863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/20/2024 17:15",
    "plant_id": "Green-tek-A13864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/20/2024 17:30",
    "plant_id": "Green-tek-A13865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/20/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/21/2024 6:00",
    "plant_id": "Green-tek-A13866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/21/2024 6:15",
    "plant_id": "Green-tek-A13867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/21/2024 6:30",
    "plant_id": "Green-tek-A13868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "6:30:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/21/2024 6:45",
    "plant_id": "Green-tek-A13869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/21/2024 7:00",
    "plant_id": "Green-tek-A13870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/21/2024 7:15",
    "plant_id": "Green-tek-A13871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/21/2024 7:30",
    "plant_id": "Green-tek-A13872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "7:30:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/21/2024 7:45",
    "plant_id": "Green-tek-A13873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "7:45:00 AM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/21/2024 8:00",
    "plant_id": "Green-tek-A13874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "8:00:00 AM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/21/2024 8:15",
    "plant_id": "Green-tek-A13875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "8:15:00 AM",
    "power": 0.178,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "1/21/2024 8:30",
    "plant_id": "Green-tek-A13876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "8:30:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "1/21/2024 8:45",
    "plant_id": "Green-tek-A13877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "8:45:00 AM",
    "power": 0.303,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "1/21/2024 9:00",
    "plant_id": "Green-tek-A13878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "9:00:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "1/21/2024 9:15",
    "plant_id": "Green-tek-A13879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "9:15:00 AM",
    "power": 0.475,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "1/21/2024 9:30",
    "plant_id": "Green-tek-A13880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "9:30:00 AM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "1/21/2024 9:45",
    "plant_id": "Green-tek-A13881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "9:45:00 AM",
    "power": 0.686,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "1/21/2024 10:00",
    "plant_id": "Green-tek-A13882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "10:00:00 AM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "1/21/2024 10:15",
    "plant_id": "Green-tek-A13883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "10:15:00 AM",
    "power": 0.913,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "1/21/2024 10:30",
    "plant_id": "Green-tek-A13884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "10:30:00 AM",
    "power": 1.022,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "1/21/2024 10:45",
    "plant_id": "Green-tek-A13885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "10:45:00 AM",
    "power": 1.12,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "1/21/2024 11:00",
    "plant_id": "Green-tek-A13886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "11:00:00 AM",
    "power": 1.203,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "1/21/2024 11:15",
    "plant_id": "Green-tek-A13887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "11:15:00 AM",
    "power": 1.266,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.33252,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "1/21/2024 11:30",
    "plant_id": "Green-tek-A13888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "11:30:00 AM",
    "power": 1.305,
    "Energy": 0.326,
    "output Energy": 0.326,
    "Ac output": 1.326,
    "dc output": 1.35252,
    "AC_R_i": 0.00042522,
    "AC_S_i": 0.000467742,
    "AC_T_i": 0.00051452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0978006,
    "AC_S_pow": 0.10758066,
    "AC_T_Pow": 0.1183396,
    "AC_R_output": 0.3978,
    "AC_S_output": 0.43758,
    "AC_T_output": 0.481338,
    "dc_current": 0.00239706,
    "dc_voltage": 136,
    "dc_power": 0.3366,
    "dc_output": 0.33252,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000326,
    "carbon offset": 0.4075,
    "trees planted": 9.055555556,
    "DC_R_output": 0.131274,
    "DC_S_output": 0.1444014,
    "DC_T_output": 0.15884154
  },
  {
    "datetime": "1/21/2024 11:45",
    "plant_id": "Green-tek-A13889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "11:45:00 AM",
    "power": 1.319,
    "Energy": 0.33,
    "output Energy": 0.33,
    "Ac output": 1.33,
    "dc output": 1.3566,
    "AC_R_i": 0.00043043,
    "AC_S_i": 0.000473473,
    "AC_T_i": 0.00052082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0989989,
    "AC_S_pow": 0.10889879,
    "AC_T_Pow": 0.1197886,
    "AC_R_output": 0.399,
    "AC_S_output": 0.4389,
    "AC_T_output": 0.48279,
    "dc_current": 0.00242647,
    "dc_voltage": 136,
    "dc_power": 0.33252,
    "dc_output": 0.3366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00033,
    "carbon offset": 0.4125,
    "trees planted": 9.166666667,
    "DC_R_output": 0.13167,
    "DC_S_output": 0.144837,
    "DC_T_output": 0.1593207
  },
  {
    "datetime": "1/21/2024 12:00",
    "plant_id": "Green-tek-A13890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "12:00:00 PM",
    "power": 1.305,
    "Energy": 0.326,
    "output Energy": 0.326,
    "Ac output": 1.326,
    "dc output": 1.35252,
    "AC_R_i": 0.00042522,
    "AC_S_i": 0.000467742,
    "AC_T_i": 0.00051452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0978006,
    "AC_S_pow": 0.10758066,
    "AC_T_Pow": 0.1183396,
    "AC_R_output": 0.3978,
    "AC_S_output": 0.43758,
    "AC_T_output": 0.481338,
    "dc_current": 0.00239706,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.33252,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000326,
    "carbon offset": 0.4075,
    "trees planted": 9.055555556,
    "DC_R_output": 0.131274,
    "DC_S_output": 0.1444014,
    "DC_T_output": 0.15884154
  },
  {
    "datetime": "1/21/2024 12:15",
    "plant_id": "Green-tek-A13891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "12:15:00 PM",
    "power": 1.266,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "1/21/2024 12:30",
    "plant_id": "Green-tek-A13892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "12:30:00 PM",
    "power": 1.203,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "1/21/2024 12:45",
    "plant_id": "Green-tek-A13893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "12:45:00 PM",
    "power": 1.12,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "1/21/2024 13:00",
    "plant_id": "Green-tek-A13894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "1:00:00 PM",
    "power": 1.022,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "1/21/2024 13:15",
    "plant_id": "Green-tek-A13895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "1:15:00 PM",
    "power": 0.913,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "1/21/2024 13:30",
    "plant_id": "Green-tek-A13896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "1:30:00 PM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "1/21/2024 13:45",
    "plant_id": "Green-tek-A13897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "1:45:00 PM",
    "power": 0.686,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "1/21/2024 14:00",
    "plant_id": "Green-tek-A13898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "2:00:00 PM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "1/21/2024 14:15",
    "plant_id": "Green-tek-A13899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "2:15:00 PM",
    "power": 0.475,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "1/21/2024 14:30",
    "plant_id": "Green-tek-A13900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "2:30:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "1/21/2024 14:45",
    "plant_id": "Green-tek-A13901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "2:45:00 PM",
    "power": 0.303,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "1/21/2024 15:00",
    "plant_id": "Green-tek-A13902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "3:00:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "1/21/2024 15:15",
    "plant_id": "Green-tek-A13903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "3:15:00 PM",
    "power": 0.178,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "1/21/2024 15:30",
    "plant_id": "Green-tek-A13904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "3:30:00 PM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/21/2024 15:45",
    "plant_id": "Green-tek-A13905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "3:45:00 PM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/21/2024 16:00",
    "plant_id": "Green-tek-A13906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "4:00:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/21/2024 16:15",
    "plant_id": "Green-tek-A13907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/21/2024 16:30",
    "plant_id": "Green-tek-A13908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/21/2024 16:45",
    "plant_id": "Green-tek-A13909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/21/2024 17:00",
    "plant_id": "Green-tek-A13910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "5:00:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/21/2024 17:15",
    "plant_id": "Green-tek-A13911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/21/2024 17:30",
    "plant_id": "Green-tek-A13912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/21/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/22/2024 6:00",
    "plant_id": "Green-tek-A13913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/22/2024 6:15",
    "plant_id": "Green-tek-A13914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/22/2024 6:30",
    "plant_id": "Green-tek-A13915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/22/2024 6:45",
    "plant_id": "Green-tek-A13916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/22/2024 7:00",
    "plant_id": "Green-tek-A13917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/22/2024 7:15",
    "plant_id": "Green-tek-A13918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/22/2024 7:30",
    "plant_id": "Green-tek-A13919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/22/2024 7:45",
    "plant_id": "Green-tek-A13920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "7:45:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/22/2024 8:00",
    "plant_id": "Green-tek-A13921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "8:00:00 AM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/22/2024 8:15",
    "plant_id": "Green-tek-A13922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "8:15:00 AM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "1/22/2024 8:30",
    "plant_id": "Green-tek-A13923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "8:30:00 AM",
    "power": 0.206,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "1/22/2024 8:45",
    "plant_id": "Green-tek-A13924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "8:45:00 AM",
    "power": 0.266,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "1/22/2024 9:00",
    "plant_id": "Green-tek-A13925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "9:00:00 AM",
    "power": 0.336,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "1/22/2024 9:15",
    "plant_id": "Green-tek-A13926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "9:15:00 AM",
    "power": 0.416,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "1/22/2024 9:30",
    "plant_id": "Green-tek-A13927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "9:30:00 AM",
    "power": 0.505,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "1/22/2024 9:45",
    "plant_id": "Green-tek-A13928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "9:45:00 AM",
    "power": 0.601,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "1/22/2024 10:00",
    "plant_id": "Green-tek-A13929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "10:00:00 AM",
    "power": 0.7,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "1/22/2024 10:15",
    "plant_id": "Green-tek-A13930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "10:15:00 AM",
    "power": 0.799,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "1/22/2024 10:30",
    "plant_id": "Green-tek-A13931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "10:30:00 AM",
    "power": 0.894,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "1/22/2024 10:45",
    "plant_id": "Green-tek-A13932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "10:45:00 AM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "1/22/2024 11:00",
    "plant_id": "Green-tek-A13933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "11:00:00 AM",
    "power": 1.053,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "1/22/2024 11:15",
    "plant_id": "Green-tek-A13934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "11:15:00 AM",
    "power": 1.108,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "1/22/2024 11:30",
    "plant_id": "Green-tek-A13935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "11:30:00 AM",
    "power": 1.142,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.29478,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "1/22/2024 11:45",
    "plant_id": "Green-tek-A13936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "11:45:00 AM",
    "power": 1.154,
    "Energy": 0.289,
    "output Energy": 0.289,
    "Ac output": 1.289,
    "dc output": 1.31478,
    "AC_R_i": 0.00037696,
    "AC_S_i": 0.000414656,
    "AC_T_i": 0.00045612,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0867008,
    "AC_S_pow": 0.09537088,
    "AC_T_Pow": 0.1049076,
    "AC_R_output": 0.3867,
    "AC_S_output": 0.42537,
    "AC_T_output": 0.467907,
    "dc_current": 0.002125,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.29478,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000289,
    "carbon offset": 0.36125,
    "trees planted": 8.027777778,
    "DC_R_output": 0.127611,
    "DC_S_output": 0.1403721,
    "DC_T_output": 0.15440931
  },
  {
    "datetime": "1/22/2024 12:00",
    "plant_id": "Green-tek-A13937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "12:00:00 PM",
    "power": 1.142,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "1/22/2024 12:15",
    "plant_id": "Green-tek-A13938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "12:15:00 PM",
    "power": 1.108,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "1/22/2024 12:30",
    "plant_id": "Green-tek-A13939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "12:30:00 PM",
    "power": 1.053,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "1/22/2024 12:45",
    "plant_id": "Green-tek-A13940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "12:45:00 PM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "1/22/2024 13:00",
    "plant_id": "Green-tek-A13941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "1:00:00 PM",
    "power": 0.894,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "1/22/2024 13:15",
    "plant_id": "Green-tek-A13942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "1:15:00 PM",
    "power": 0.799,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "1/22/2024 13:30",
    "plant_id": "Green-tek-A13943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "1:30:00 PM",
    "power": 0.7,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "1/22/2024 13:45",
    "plant_id": "Green-tek-A13944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "1:45:00 PM",
    "power": 0.601,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "1/22/2024 14:00",
    "plant_id": "Green-tek-A13945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "2:00:00 PM",
    "power": 0.505,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "1/22/2024 14:15",
    "plant_id": "Green-tek-A13946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "2:15:00 PM",
    "power": 0.416,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "1/22/2024 14:30",
    "plant_id": "Green-tek-A13947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "2:30:00 PM",
    "power": 0.336,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "1/22/2024 14:45",
    "plant_id": "Green-tek-A13948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "2:45:00 PM",
    "power": 0.266,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "1/22/2024 15:00",
    "plant_id": "Green-tek-A13949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "3:00:00 PM",
    "power": 0.206,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "1/22/2024 15:15",
    "plant_id": "Green-tek-A13950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "3:15:00 PM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "1/22/2024 15:30",
    "plant_id": "Green-tek-A13951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "3:30:00 PM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/22/2024 15:45",
    "plant_id": "Green-tek-A13952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "3:45:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/22/2024 16:00",
    "plant_id": "Green-tek-A13953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/22/2024 16:15",
    "plant_id": "Green-tek-A13954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/22/2024 16:30",
    "plant_id": "Green-tek-A13955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/22/2024 16:45",
    "plant_id": "Green-tek-A13956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/22/2024 17:00",
    "plant_id": "Green-tek-A13957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/22/2024 17:15",
    "plant_id": "Green-tek-A13958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/22/2024 17:30",
    "plant_id": "Green-tek-A13959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/22/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/23/2024 6:00",
    "plant_id": "Green-tek-A13960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/23/2024 6:15",
    "plant_id": "Green-tek-A13961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/23/2024 6:30",
    "plant_id": "Green-tek-A13962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/23/2024 6:45",
    "plant_id": "Green-tek-A13963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/23/2024 7:00",
    "plant_id": "Green-tek-A13964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/23/2024 7:15",
    "plant_id": "Green-tek-A13965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/23/2024 7:30",
    "plant_id": "Green-tek-A13966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/23/2024 7:45",
    "plant_id": "Green-tek-A13967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "7:45:00 AM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/23/2024 8:00",
    "plant_id": "Green-tek-A13968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "8:00:00 AM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "1/23/2024 8:15",
    "plant_id": "Green-tek-A13969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "8:15:00 AM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "1/23/2024 8:30",
    "plant_id": "Green-tek-A13970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "8:30:00 AM",
    "power": 0.178,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "1/23/2024 8:45",
    "plant_id": "Green-tek-A13971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "8:45:00 AM",
    "power": 0.23,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "1/23/2024 9:00",
    "plant_id": "Green-tek-A13972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "9:00:00 AM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "1/23/2024 9:15",
    "plant_id": "Green-tek-A13973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "9:15:00 AM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "1/23/2024 9:30",
    "plant_id": "Green-tek-A13974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "9:30:00 AM",
    "power": 0.438,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "1/23/2024 9:45",
    "plant_id": "Green-tek-A13975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "9:45:00 AM",
    "power": 0.521,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "1/23/2024 10:00",
    "plant_id": "Green-tek-A13976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "10:00:00 AM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "1/23/2024 10:15",
    "plant_id": "Green-tek-A13977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "10:15:00 AM",
    "power": 0.693,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "1/23/2024 10:30",
    "plant_id": "Green-tek-A13978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "10:30:00 AM",
    "power": 0.775,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "1/23/2024 10:45",
    "plant_id": "Green-tek-A13979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "10:45:00 AM",
    "power": 0.849,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "1/23/2024 11:00",
    "plant_id": "Green-tek-A13980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "11:00:00 AM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "1/23/2024 11:15",
    "plant_id": "Green-tek-A13981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "11:15:00 AM",
    "power": 0.96,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "1/23/2024 11:30",
    "plant_id": "Green-tek-A13982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "11:30:00 AM",
    "power": 0.99,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "1/23/2024 11:45",
    "plant_id": "Green-tek-A13983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "11:45:00 AM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "1/23/2024 12:00",
    "plant_id": "Green-tek-A13984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "12:00:00 PM",
    "power": 0.99,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "1/23/2024 12:15",
    "plant_id": "Green-tek-A13985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "12:15:00 PM",
    "power": 0.96,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "1/23/2024 12:30",
    "plant_id": "Green-tek-A13986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "12:30:00 PM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "1/23/2024 12:45",
    "plant_id": "Green-tek-A13987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "12:45:00 PM",
    "power": 0.849,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "1/23/2024 13:00",
    "plant_id": "Green-tek-A13988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "1:00:00 PM",
    "power": 0.775,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "1/23/2024 13:15",
    "plant_id": "Green-tek-A13989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "1:15:00 PM",
    "power": 0.693,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "1/23/2024 13:30",
    "plant_id": "Green-tek-A13990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "1:30:00 PM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "1/23/2024 13:45",
    "plant_id": "Green-tek-A13991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "1:45:00 PM",
    "power": 0.521,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "1/23/2024 14:00",
    "plant_id": "Green-tek-A13992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "2:00:00 PM",
    "power": 0.438,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "1/23/2024 14:15",
    "plant_id": "Green-tek-A13993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "2:15:00 PM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "1/23/2024 14:30",
    "plant_id": "Green-tek-A13994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "2:30:00 PM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "1/23/2024 14:45",
    "plant_id": "Green-tek-A13995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "2:45:00 PM",
    "power": 0.23,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "1/23/2024 15:00",
    "plant_id": "Green-tek-A13996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "3:00:00 PM",
    "power": 0.178,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "1/23/2024 15:15",
    "plant_id": "Green-tek-A13997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "3:15:00 PM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "1/23/2024 15:30",
    "plant_id": "Green-tek-A13998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "3:30:00 PM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "1/23/2024 15:45",
    "plant_id": "Green-tek-A13999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "3:45:00 PM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/23/2024 16:00",
    "plant_id": "Green-tek-A14000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/23/2024 16:15",
    "plant_id": "Green-tek-A14001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/23/2024 16:30",
    "plant_id": "Green-tek-A14002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/23/2024 16:45",
    "plant_id": "Green-tek-A14003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/23/2024 17:00",
    "plant_id": "Green-tek-A14004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/23/2024 17:15",
    "plant_id": "Green-tek-A14005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/23/2024 17:30",
    "plant_id": "Green-tek-A14006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/23/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/24/2024 6:00",
    "plant_id": "Green-tek-A14007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/24/2024 6:15",
    "plant_id": "Green-tek-A14008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/24/2024 6:30",
    "plant_id": "Green-tek-A14009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/24/2024 6:45",
    "plant_id": "Green-tek-A14010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/24/2024 7:00",
    "plant_id": "Green-tek-A14011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/24/2024 7:15",
    "plant_id": "Green-tek-A14012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/24/2024 7:30",
    "plant_id": "Green-tek-A14013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "1/24/2024 7:45",
    "plant_id": "Green-tek-A14014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "7:45:00 AM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "1/24/2024 8:00",
    "plant_id": "Green-tek-A14015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "8:00:00 AM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "1/24/2024 8:15",
    "plant_id": "Green-tek-A14016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "8:15:00 AM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/24/2024 8:30",
    "plant_id": "Green-tek-A14017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "8:30:00 AM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/24/2024 8:45",
    "plant_id": "Green-tek-A14018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "8:45:00 AM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "1/24/2024 9:00",
    "plant_id": "Green-tek-A14019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "9:00:00 AM",
    "power": 0.318,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "1/24/2024 9:15",
    "plant_id": "Green-tek-A14020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "9:15:00 AM",
    "power": 0.394,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "1/24/2024 9:30",
    "plant_id": "Green-tek-A14021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "9:30:00 AM",
    "power": 0.478,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "1/24/2024 9:45",
    "plant_id": "Green-tek-A14022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "9:45:00 AM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/24/2024 10:00",
    "plant_id": "Green-tek-A14023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "10:00:00 AM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "1/24/2024 10:15",
    "plant_id": "Green-tek-A14024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "10:15:00 AM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "1/24/2024 10:30",
    "plant_id": "Green-tek-A14025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "10:30:00 AM",
    "power": 0.846,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "1/24/2024 10:45",
    "plant_id": "Green-tek-A14026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "10:45:00 AM",
    "power": 0.928,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "1/24/2024 11:00",
    "plant_id": "Green-tek-A14027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "11:00:00 AM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "1/24/2024 11:15",
    "plant_id": "Green-tek-A14028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "11:15:00 AM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "1/24/2024 11:30",
    "plant_id": "Green-tek-A14029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "11:30:00 AM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "1/24/2024 11:45",
    "plant_id": "Green-tek-A14030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "11:45:00 AM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "1/24/2024 12:00",
    "plant_id": "Green-tek-A14031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "12:00:00 PM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "1/24/2024 12:15",
    "plant_id": "Green-tek-A14032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "12:15:00 PM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "1/24/2024 12:30",
    "plant_id": "Green-tek-A14033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "12:30:00 PM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "1/24/2024 12:45",
    "plant_id": "Green-tek-A14034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "12:45:00 PM",
    "power": 0.928,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "1/24/2024 13:00",
    "plant_id": "Green-tek-A14035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "1:00:00 PM",
    "power": 0.846,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "1/24/2024 13:15",
    "plant_id": "Green-tek-A14036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "1:15:00 PM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "1/24/2024 13:30",
    "plant_id": "Green-tek-A14037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "1:30:00 PM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "1/24/2024 13:45",
    "plant_id": "Green-tek-A14038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "1:45:00 PM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/24/2024 14:00",
    "plant_id": "Green-tek-A14039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "2:00:00 PM",
    "power": 0.478,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "1/24/2024 14:15",
    "plant_id": "Green-tek-A14040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "2:15:00 PM",
    "power": 0.394,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "1/24/2024 14:30",
    "plant_id": "Green-tek-A14041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "2:30:00 PM",
    "power": 0.318,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "1/24/2024 14:45",
    "plant_id": "Green-tek-A14042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "2:45:00 PM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "1/24/2024 15:00",
    "plant_id": "Green-tek-A14043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "3:00:00 PM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/24/2024 15:15",
    "plant_id": "Green-tek-A14044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "3:15:00 PM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "1/24/2024 15:30",
    "plant_id": "Green-tek-A14045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "3:30:00 PM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "1/24/2024 15:45",
    "plant_id": "Green-tek-A14046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "3:45:00 PM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "1/24/2024 16:00",
    "plant_id": "Green-tek-A14047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "1/24/2024 16:15",
    "plant_id": "Green-tek-A14048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/24/2024 16:30",
    "plant_id": "Green-tek-A14049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/24/2024 16:45",
    "plant_id": "Green-tek-A14050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/24/2024 17:00",
    "plant_id": "Green-tek-A14051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/24/2024 17:15",
    "plant_id": "Green-tek-A14052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/24/2024 17:30",
    "plant_id": "Green-tek-A14053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/24/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/25/2024 6:00",
    "plant_id": "Green-tek-A14054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/25/2024 6:15",
    "plant_id": "Green-tek-A14055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/25/2024 6:30",
    "plant_id": "Green-tek-A14056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/25/2024 6:45",
    "plant_id": "Green-tek-A14057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/25/2024 7:00",
    "plant_id": "Green-tek-A14058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/25/2024 7:15",
    "plant_id": "Green-tek-A14059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/25/2024 7:30",
    "plant_id": "Green-tek-A14060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/25/2024 7:45",
    "plant_id": "Green-tek-A14061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/25/2024 8:00",
    "plant_id": "Green-tek-A14062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "8:00:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/25/2024 8:15",
    "plant_id": "Green-tek-A14063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "8:15:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "1/25/2024 8:30",
    "plant_id": "Green-tek-A14064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "8:30:00 AM",
    "power": 0.17,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "1/25/2024 8:45",
    "plant_id": "Green-tek-A14065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "8:45:00 AM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "1/25/2024 9:00",
    "plant_id": "Green-tek-A14066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "9:00:00 AM",
    "power": 0.278,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "1/25/2024 9:15",
    "plant_id": "Green-tek-A14067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "9:15:00 AM",
    "power": 0.344,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "1/25/2024 9:30",
    "plant_id": "Green-tek-A14068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "9:30:00 AM",
    "power": 0.418,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "1/25/2024 9:45",
    "plant_id": "Green-tek-A14069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "9:45:00 AM",
    "power": 0.497,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "1/25/2024 10:00",
    "plant_id": "Green-tek-A14070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "10:00:00 AM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "1/25/2024 10:15",
    "plant_id": "Green-tek-A14071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "10:15:00 AM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "1/25/2024 10:30",
    "plant_id": "Green-tek-A14072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "10:30:00 AM",
    "power": 0.74,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "1/25/2024 10:45",
    "plant_id": "Green-tek-A14073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "10:45:00 AM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "1/25/2024 11:00",
    "plant_id": "Green-tek-A14074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "11:00:00 AM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "1/25/2024 11:15",
    "plant_id": "Green-tek-A14075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "11:15:00 AM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "1/25/2024 11:30",
    "plant_id": "Green-tek-A14076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "11:30:00 AM",
    "power": 0.945,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "1/25/2024 11:45",
    "plant_id": "Green-tek-A14077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "11:45:00 AM",
    "power": 0.955,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "1/25/2024 12:00",
    "plant_id": "Green-tek-A14078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "12:00:00 PM",
    "power": 0.945,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "1/25/2024 12:15",
    "plant_id": "Green-tek-A14079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "12:15:00 PM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "1/25/2024 12:30",
    "plant_id": "Green-tek-A14080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "12:30:00 PM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "1/25/2024 12:45",
    "plant_id": "Green-tek-A14081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "12:45:00 PM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "1/25/2024 13:00",
    "plant_id": "Green-tek-A14082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "1:00:00 PM",
    "power": 0.74,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "1/25/2024 13:15",
    "plant_id": "Green-tek-A14083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "1:15:00 PM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "1/25/2024 13:30",
    "plant_id": "Green-tek-A14084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "1:30:00 PM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "1/25/2024 13:45",
    "plant_id": "Green-tek-A14085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "1:45:00 PM",
    "power": 0.497,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "1/25/2024 14:00",
    "plant_id": "Green-tek-A14086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "2:00:00 PM",
    "power": 0.418,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "1/25/2024 14:15",
    "plant_id": "Green-tek-A14087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "2:15:00 PM",
    "power": 0.344,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "1/25/2024 14:30",
    "plant_id": "Green-tek-A14088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "2:30:00 PM",
    "power": 0.278,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "1/25/2024 14:45",
    "plant_id": "Green-tek-A14089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "2:45:00 PM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "1/25/2024 15:00",
    "plant_id": "Green-tek-A14090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "3:00:00 PM",
    "power": 0.17,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "1/25/2024 15:15",
    "plant_id": "Green-tek-A14091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "3:15:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "1/25/2024 15:30",
    "plant_id": "Green-tek-A14092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "3:30:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/25/2024 15:45",
    "plant_id": "Green-tek-A14093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "1/25/2024 16:00",
    "plant_id": "Green-tek-A14094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "1/25/2024 16:15",
    "plant_id": "Green-tek-A14095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "1/25/2024 16:30",
    "plant_id": "Green-tek-A14096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "1/25/2024 16:45",
    "plant_id": "Green-tek-A14097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/25/2024 17:00",
    "plant_id": "Green-tek-A14098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/25/2024 17:15",
    "plant_id": "Green-tek-A14099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/25/2024 17:30",
    "plant_id": "Green-tek-A14100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/25/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/26/2024 6:00",
    "plant_id": "Green-tek-A14101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/26/2024 6:15",
    "plant_id": "Green-tek-A14102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/26/2024 6:30",
    "plant_id": "Green-tek-A14103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/26/2024 6:45",
    "plant_id": "Green-tek-A14104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/26/2024 7:00",
    "plant_id": "Green-tek-A14105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/26/2024 7:15",
    "plant_id": "Green-tek-A14106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/26/2024 7:30",
    "plant_id": "Green-tek-A14107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "1/26/2024 7:45",
    "plant_id": "Green-tek-A14108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "7:45:00 AM",
    "power": 0.078,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "1/26/2024 8:00",
    "plant_id": "Green-tek-A14109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "8:00:00 AM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "1/26/2024 8:15",
    "plant_id": "Green-tek-A14110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "8:15:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "1/26/2024 8:30",
    "plant_id": "Green-tek-A14111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "8:30:00 AM",
    "power": 0.19,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "1/26/2024 8:45",
    "plant_id": "Green-tek-A14112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "8:45:00 AM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "1/26/2024 9:00",
    "plant_id": "Green-tek-A14113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "9:00:00 AM",
    "power": 0.31,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "1/26/2024 9:15",
    "plant_id": "Green-tek-A14114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "9:15:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "1/26/2024 9:30",
    "plant_id": "Green-tek-A14115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "9:30:00 AM",
    "power": 0.466,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "1/26/2024 9:45",
    "plant_id": "Green-tek-A14116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "9:45:00 AM",
    "power": 0.554,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "1/26/2024 10:00",
    "plant_id": "Green-tek-A14117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "10:00:00 AM",
    "power": 0.646,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "1/26/2024 10:15",
    "plant_id": "Green-tek-A14118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "10:15:00 AM",
    "power": 0.738,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "1/26/2024 10:30",
    "plant_id": "Green-tek-A14119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "10:30:00 AM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "1/26/2024 10:45",
    "plant_id": "Green-tek-A14120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "10:45:00 AM",
    "power": 0.905,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "1/26/2024 11:00",
    "plant_id": "Green-tek-A14121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "11:00:00 AM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "1/26/2024 11:15",
    "plant_id": "Green-tek-A14122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "11:15:00 AM",
    "power": 1.023,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "1/26/2024 11:30",
    "plant_id": "Green-tek-A14123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "11:30:00 AM",
    "power": 1.054,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "1/26/2024 11:45",
    "plant_id": "Green-tek-A14124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "11:45:00 AM",
    "power": 1.065,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "1/26/2024 12:00",
    "plant_id": "Green-tek-A14125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "12:00:00 PM",
    "power": 1.054,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "1/26/2024 12:15",
    "plant_id": "Green-tek-A14126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "12:15:00 PM",
    "power": 1.023,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "1/26/2024 12:30",
    "plant_id": "Green-tek-A14127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "12:30:00 PM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "1/26/2024 12:45",
    "plant_id": "Green-tek-A14128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "12:45:00 PM",
    "power": 0.905,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "1/26/2024 13:00",
    "plant_id": "Green-tek-A14129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "1:00:00 PM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "1/26/2024 13:15",
    "plant_id": "Green-tek-A14130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "1:15:00 PM",
    "power": 0.738,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "1/26/2024 13:30",
    "plant_id": "Green-tek-A14131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "1:30:00 PM",
    "power": 0.646,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "1/26/2024 13:45",
    "plant_id": "Green-tek-A14132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "1:45:00 PM",
    "power": 0.554,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "1/26/2024 14:00",
    "plant_id": "Green-tek-A14133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "2:00:00 PM",
    "power": 0.466,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "1/26/2024 14:15",
    "plant_id": "Green-tek-A14134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "2:15:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "1/26/2024 14:30",
    "plant_id": "Green-tek-A14135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "2:30:00 PM",
    "power": 0.31,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "1/26/2024 14:45",
    "plant_id": "Green-tek-A14136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "2:45:00 PM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "1/26/2024 15:00",
    "plant_id": "Green-tek-A14137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "3:00:00 PM",
    "power": 0.19,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "1/26/2024 15:15",
    "plant_id": "Green-tek-A14138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "3:15:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "1/26/2024 15:30",
    "plant_id": "Green-tek-A14139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "3:30:00 PM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "1/26/2024 15:45",
    "plant_id": "Green-tek-A14140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "3:45:00 PM",
    "power": 0.078,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "1/26/2024 16:00",
    "plant_id": "Green-tek-A14141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "1/26/2024 16:15",
    "plant_id": "Green-tek-A14142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "1/26/2024 16:30",
    "plant_id": "Green-tek-A14143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "1/26/2024 16:45",
    "plant_id": "Green-tek-A14144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/26/2024 17:00",
    "plant_id": "Green-tek-A14145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/26/2024 17:15",
    "plant_id": "Green-tek-A14146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/26/2024 17:30",
    "plant_id": "Green-tek-A14147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/26/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/27/2024 6:00",
    "plant_id": "Green-tek-A14148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/27/2024 6:15",
    "plant_id": "Green-tek-A14149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/27/2024 6:30",
    "plant_id": "Green-tek-A14150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/27/2024 6:45",
    "plant_id": "Green-tek-A14151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/27/2024 7:00",
    "plant_id": "Green-tek-A14152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/27/2024 7:15",
    "plant_id": "Green-tek-A14153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/27/2024 7:30",
    "plant_id": "Green-tek-A14154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/27/2024 7:45",
    "plant_id": "Green-tek-A14155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/27/2024 8:00",
    "plant_id": "Green-tek-A14156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/27/2024 8:15",
    "plant_id": "Green-tek-A14157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "8:15:00 AM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/27/2024 8:30",
    "plant_id": "Green-tek-A14158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "8:30:00 AM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "1/27/2024 8:45",
    "plant_id": "Green-tek-A14159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "8:45:00 AM",
    "power": 0.298,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "1/27/2024 9:00",
    "plant_id": "Green-tek-A14160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "9:00:00 AM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "1/27/2024 9:15",
    "plant_id": "Green-tek-A14161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "9:15:00 AM",
    "power": 0.467,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "1/27/2024 9:30",
    "plant_id": "Green-tek-A14162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "9:30:00 AM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/27/2024 9:45",
    "plant_id": "Green-tek-A14163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "9:45:00 AM",
    "power": 0.675,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "1/27/2024 10:00",
    "plant_id": "Green-tek-A14164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "10:00:00 AM",
    "power": 0.786,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "1/27/2024 10:15",
    "plant_id": "Green-tek-A14165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "10:15:00 AM",
    "power": 0.898,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/27/2024 10:30",
    "plant_id": "Green-tek-A14166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "10:30:00 AM",
    "power": 1.005,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "1/27/2024 10:45",
    "plant_id": "Green-tek-A14167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "10:45:00 AM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "1/27/2024 11:00",
    "plant_id": "Green-tek-A14168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "11:00:00 AM",
    "power": 1.183,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "1/27/2024 11:15",
    "plant_id": "Green-tek-A14169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "11:15:00 AM",
    "power": 1.245,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "1/27/2024 11:30",
    "plant_id": "Green-tek-A14170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "11:30:00 AM",
    "power": 1.283,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.33048,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "1/27/2024 11:45",
    "plant_id": "Green-tek-A14171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "11:45:00 AM",
    "power": 1.297,
    "Energy": 0.324,
    "output Energy": 0.324,
    "Ac output": 1.324,
    "dc output": 1.35048,
    "AC_R_i": 0.00042261,
    "AC_S_i": 0.000464871,
    "AC_T_i": 0.00051136,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0972003,
    "AC_S_pow": 0.10692033,
    "AC_T_Pow": 0.1176128,
    "AC_R_output": 0.3972,
    "AC_S_output": 0.43692,
    "AC_T_output": 0.480612,
    "dc_current": 0.00238235,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.33048,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000324,
    "carbon offset": 0.405,
    "trees planted": 9,
    "DC_R_output": 0.131076,
    "DC_S_output": 0.1441836,
    "DC_T_output": 0.15860196
  },
  {
    "datetime": "1/27/2024 12:00",
    "plant_id": "Green-tek-A14172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "12:00:00 PM",
    "power": 1.283,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "1/27/2024 12:15",
    "plant_id": "Green-tek-A14173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "12:15:00 PM",
    "power": 1.245,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "1/27/2024 12:30",
    "plant_id": "Green-tek-A14174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "12:30:00 PM",
    "power": 1.183,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "1/27/2024 12:45",
    "plant_id": "Green-tek-A14175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "12:45:00 PM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "1/27/2024 13:00",
    "plant_id": "Green-tek-A14176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "1:00:00 PM",
    "power": 1.005,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "1/27/2024 13:15",
    "plant_id": "Green-tek-A14177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "1:15:00 PM",
    "power": 0.898,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/27/2024 13:30",
    "plant_id": "Green-tek-A14178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "1:30:00 PM",
    "power": 0.786,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "1/27/2024 13:45",
    "plant_id": "Green-tek-A14179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "1:45:00 PM",
    "power": 0.675,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "1/27/2024 14:00",
    "plant_id": "Green-tek-A14180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "2:00:00 PM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/27/2024 14:15",
    "plant_id": "Green-tek-A14181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "2:15:00 PM",
    "power": 0.467,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "1/27/2024 14:30",
    "plant_id": "Green-tek-A14182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "2:30:00 PM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "1/27/2024 14:45",
    "plant_id": "Green-tek-A14183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "2:45:00 PM",
    "power": 0.298,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "1/27/2024 15:00",
    "plant_id": "Green-tek-A14184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "3:00:00 PM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "1/27/2024 15:15",
    "plant_id": "Green-tek-A14185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "3:15:00 PM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/27/2024 15:30",
    "plant_id": "Green-tek-A14186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/27/2024 15:45",
    "plant_id": "Green-tek-A14187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/27/2024 16:00",
    "plant_id": "Green-tek-A14188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/27/2024 16:15",
    "plant_id": "Green-tek-A14189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/27/2024 16:30",
    "plant_id": "Green-tek-A14190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/27/2024 16:45",
    "plant_id": "Green-tek-A14191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/27/2024 17:00",
    "plant_id": "Green-tek-A14192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/27/2024 17:15",
    "plant_id": "Green-tek-A14193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/27/2024 17:30",
    "plant_id": "Green-tek-A14194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/27/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/28/2024 6:00",
    "plant_id": "Green-tek-A14195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/28/2024 6:15",
    "plant_id": "Green-tek-A14196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/28/2024 6:30",
    "plant_id": "Green-tek-A14197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/28/2024 6:45",
    "plant_id": "Green-tek-A14198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/28/2024 7:00",
    "plant_id": "Green-tek-A14199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/28/2024 7:15",
    "plant_id": "Green-tek-A14200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/28/2024 7:30",
    "plant_id": "Green-tek-A14201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "7:30:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/28/2024 7:45",
    "plant_id": "Green-tek-A14202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/28/2024 8:00",
    "plant_id": "Green-tek-A14203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "8:00:00 AM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "1/28/2024 8:15",
    "plant_id": "Green-tek-A14204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "8:15:00 AM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "1/28/2024 8:30",
    "plant_id": "Green-tek-A14205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "8:30:00 AM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "1/28/2024 8:45",
    "plant_id": "Green-tek-A14206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "8:45:00 AM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "1/28/2024 9:00",
    "plant_id": "Green-tek-A14207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "9:00:00 AM",
    "power": 0.354,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "1/28/2024 9:15",
    "plant_id": "Green-tek-A14208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "9:15:00 AM",
    "power": 0.438,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "1/28/2024 9:30",
    "plant_id": "Green-tek-A14209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "9:30:00 AM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "1/28/2024 9:45",
    "plant_id": "Green-tek-A14210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "9:45:00 AM",
    "power": 0.633,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "1/28/2024 10:00",
    "plant_id": "Green-tek-A14211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "10:00:00 AM",
    "power": 0.738,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "1/28/2024 10:15",
    "plant_id": "Green-tek-A14212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "10:15:00 AM",
    "power": 0.842,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "1/28/2024 10:30",
    "plant_id": "Green-tek-A14213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "10:30:00 AM",
    "power": 0.943,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "1/28/2024 10:45",
    "plant_id": "Green-tek-A14214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "10:45:00 AM",
    "power": 1.033,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "1/28/2024 11:00",
    "plant_id": "Green-tek-A14215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "11:00:00 AM",
    "power": 1.11,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "1/28/2024 11:15",
    "plant_id": "Green-tek-A14216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "11:15:00 AM",
    "power": 1.168,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "1/28/2024 11:30",
    "plant_id": "Green-tek-A14217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "11:30:00 AM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "1/28/2024 11:45",
    "plant_id": "Green-tek-A14218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "11:45:00 AM",
    "power": 1.216,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "1/28/2024 12:00",
    "plant_id": "Green-tek-A14219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "12:00:00 PM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "1/28/2024 12:15",
    "plant_id": "Green-tek-A14220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "12:15:00 PM",
    "power": 1.168,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "1/28/2024 12:30",
    "plant_id": "Green-tek-A14221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "12:30:00 PM",
    "power": 1.11,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "1/28/2024 12:45",
    "plant_id": "Green-tek-A14222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "12:45:00 PM",
    "power": 1.033,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "1/28/2024 13:00",
    "plant_id": "Green-tek-A14223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "1:00:00 PM",
    "power": 0.943,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "1/28/2024 13:15",
    "plant_id": "Green-tek-A14224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "1:15:00 PM",
    "power": 0.842,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "1/28/2024 13:30",
    "plant_id": "Green-tek-A14225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "1:30:00 PM",
    "power": 0.738,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "1/28/2024 13:45",
    "plant_id": "Green-tek-A14226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "1:45:00 PM",
    "power": 0.633,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "1/28/2024 14:00",
    "plant_id": "Green-tek-A14227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "2:00:00 PM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "1/28/2024 14:15",
    "plant_id": "Green-tek-A14228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "2:15:00 PM",
    "power": 0.438,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "1/28/2024 14:30",
    "plant_id": "Green-tek-A14229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "2:30:00 PM",
    "power": 0.354,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "1/28/2024 14:45",
    "plant_id": "Green-tek-A14230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "2:45:00 PM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "1/28/2024 15:00",
    "plant_id": "Green-tek-A14231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "3:00:00 PM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "1/28/2024 15:15",
    "plant_id": "Green-tek-A14232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "3:15:00 PM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "1/28/2024 15:30",
    "plant_id": "Green-tek-A14233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "3:30:00 PM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "1/28/2024 15:45",
    "plant_id": "Green-tek-A14234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/28/2024 16:00",
    "plant_id": "Green-tek-A14235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "4:00:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/28/2024 16:15",
    "plant_id": "Green-tek-A14236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/28/2024 16:30",
    "plant_id": "Green-tek-A14237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/28/2024 16:45",
    "plant_id": "Green-tek-A14238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/28/2024 17:00",
    "plant_id": "Green-tek-A14239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "1/28/2024 17:15",
    "plant_id": "Green-tek-A14240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/28/2024 17:30",
    "plant_id": "Green-tek-A14241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/28/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/29/2024 6:00",
    "plant_id": "Green-tek-A14242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/29/2024 6:15",
    "plant_id": "Green-tek-A14243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/29/2024 6:30",
    "plant_id": "Green-tek-A14244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/29/2024 6:45",
    "plant_id": "Green-tek-A14245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/29/2024 7:00",
    "plant_id": "Green-tek-A14246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/29/2024 7:15",
    "plant_id": "Green-tek-A14247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/29/2024 7:30",
    "plant_id": "Green-tek-A14248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/29/2024 7:45",
    "plant_id": "Green-tek-A14249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/29/2024 8:00",
    "plant_id": "Green-tek-A14250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/29/2024 8:15",
    "plant_id": "Green-tek-A14251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "8:15:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/29/2024 8:30",
    "plant_id": "Green-tek-A14252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "8:30:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "1/29/2024 8:45",
    "plant_id": "Green-tek-A14253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "8:45:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "1/29/2024 9:00",
    "plant_id": "Green-tek-A14254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "9:00:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "1/29/2024 9:15",
    "plant_id": "Green-tek-A14255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "9:15:00 AM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "1/29/2024 9:30",
    "plant_id": "Green-tek-A14256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "9:30:00 AM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/29/2024 9:45",
    "plant_id": "Green-tek-A14257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "9:45:00 AM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "1/29/2024 10:00",
    "plant_id": "Green-tek-A14258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "10:00:00 AM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "1/29/2024 10:15",
    "plant_id": "Green-tek-A14259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "10:15:00 AM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/29/2024 10:30",
    "plant_id": "Green-tek-A14260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "10:30:00 AM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "1/29/2024 10:45",
    "plant_id": "Green-tek-A14261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "10:45:00 AM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "1/29/2024 11:00",
    "plant_id": "Green-tek-A14262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "11:00:00 AM",
    "power": 1.187,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "1/29/2024 11:15",
    "plant_id": "Green-tek-A14263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "11:15:00 AM",
    "power": 1.249,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "1/29/2024 11:30",
    "plant_id": "Green-tek-A14264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "11:30:00 AM",
    "power": 1.288,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.3315,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "1/29/2024 11:45",
    "plant_id": "Green-tek-A14265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "11:45:00 AM",
    "power": 1.302,
    "Energy": 0.325,
    "output Energy": 0.325,
    "Ac output": 1.325,
    "dc output": 1.3515,
    "AC_R_i": 0.00042391,
    "AC_S_i": 0.000466301,
    "AC_T_i": 0.00051293,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0974993,
    "AC_S_pow": 0.10724923,
    "AC_T_Pow": 0.1179739,
    "AC_R_output": 0.3975,
    "AC_S_output": 0.43725,
    "AC_T_output": 0.480975,
    "dc_current": 0.00238971,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.3315,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000325,
    "carbon offset": 0.40625,
    "trees planted": 9.027777778,
    "DC_R_output": 0.131175,
    "DC_S_output": 0.1442925,
    "DC_T_output": 0.15872175
  },
  {
    "datetime": "1/29/2024 12:00",
    "plant_id": "Green-tek-A14266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "12:00:00 PM",
    "power": 1.288,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "1/29/2024 12:15",
    "plant_id": "Green-tek-A14267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "12:15:00 PM",
    "power": 1.249,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "1/29/2024 12:30",
    "plant_id": "Green-tek-A14268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "12:30:00 PM",
    "power": 1.187,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "1/29/2024 12:45",
    "plant_id": "Green-tek-A14269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "12:45:00 PM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "1/29/2024 13:00",
    "plant_id": "Green-tek-A14270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "1:00:00 PM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "1/29/2024 13:15",
    "plant_id": "Green-tek-A14271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "1:15:00 PM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "1/29/2024 13:30",
    "plant_id": "Green-tek-A14272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "1:30:00 PM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "1/29/2024 13:45",
    "plant_id": "Green-tek-A14273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "1:45:00 PM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "1/29/2024 14:00",
    "plant_id": "Green-tek-A14274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "2:00:00 PM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "1/29/2024 14:15",
    "plant_id": "Green-tek-A14275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "2:15:00 PM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "1/29/2024 14:30",
    "plant_id": "Green-tek-A14276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "2:30:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "1/29/2024 14:45",
    "plant_id": "Green-tek-A14277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "2:45:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "1/29/2024 15:00",
    "plant_id": "Green-tek-A14278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "3:00:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "1/29/2024 15:15",
    "plant_id": "Green-tek-A14279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "3:15:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "1/29/2024 15:30",
    "plant_id": "Green-tek-A14280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "1/29/2024 15:45",
    "plant_id": "Green-tek-A14281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "1/29/2024 16:00",
    "plant_id": "Green-tek-A14282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "1/29/2024 16:15",
    "plant_id": "Green-tek-A14283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "1/29/2024 16:30",
    "plant_id": "Green-tek-A14284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/29/2024 16:45",
    "plant_id": "Green-tek-A14285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/29/2024 17:00",
    "plant_id": "Green-tek-A14286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/29/2024 17:15",
    "plant_id": "Green-tek-A14287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/29/2024 17:30",
    "plant_id": "Green-tek-A14288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/29/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/30/2024 6:00",
    "plant_id": "Green-tek-A14289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/30/2024 6:15",
    "plant_id": "Green-tek-A14290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/30/2024 6:30",
    "plant_id": "Green-tek-A14291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/30/2024 6:45",
    "plant_id": "Green-tek-A14292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/30/2024 7:00",
    "plant_id": "Green-tek-A14293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/30/2024 7:15",
    "plant_id": "Green-tek-A14294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/30/2024 7:30",
    "plant_id": "Green-tek-A14295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/30/2024 7:45",
    "plant_id": "Green-tek-A14296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "7:45:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/30/2024 8:00",
    "plant_id": "Green-tek-A14297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "8:00:00 AM",
    "power": 0.086,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/30/2024 8:15",
    "plant_id": "Green-tek-A14298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "8:15:00 AM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/30/2024 8:30",
    "plant_id": "Green-tek-A14299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "8:30:00 AM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/30/2024 8:45",
    "plant_id": "Green-tek-A14300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "8:45:00 AM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/30/2024 9:00",
    "plant_id": "Green-tek-A14301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "9:00:00 AM",
    "power": 0.25,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "1/30/2024 9:15",
    "plant_id": "Green-tek-A14302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "9:15:00 AM",
    "power": 0.309,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "1/30/2024 9:30",
    "plant_id": "Green-tek-A14303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "9:30:00 AM",
    "power": 0.375,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "1/30/2024 9:45",
    "plant_id": "Green-tek-A14304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "9:45:00 AM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "1/30/2024 10:00",
    "plant_id": "Green-tek-A14305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "10:00:00 AM",
    "power": 0.52,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "1/30/2024 10:15",
    "plant_id": "Green-tek-A14306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "10:15:00 AM",
    "power": 0.594,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "1/30/2024 10:30",
    "plant_id": "Green-tek-A14307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "10:30:00 AM",
    "power": 0.665,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "1/30/2024 10:45",
    "plant_id": "Green-tek-A14308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "10:45:00 AM",
    "power": 0.729,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "1/30/2024 11:00",
    "plant_id": "Green-tek-A14309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "11:00:00 AM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "1/30/2024 11:15",
    "plant_id": "Green-tek-A14310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "11:15:00 AM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "1/30/2024 11:30",
    "plant_id": "Green-tek-A14311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "11:30:00 AM",
    "power": 0.849,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "1/30/2024 11:45",
    "plant_id": "Green-tek-A14312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "11:45:00 AM",
    "power": 0.858,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "1/30/2024 12:00",
    "plant_id": "Green-tek-A14313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "12:00:00 PM",
    "power": 0.849,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "1/30/2024 12:15",
    "plant_id": "Green-tek-A14314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "12:15:00 PM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "1/30/2024 12:30",
    "plant_id": "Green-tek-A14315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "12:30:00 PM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "1/30/2024 12:45",
    "plant_id": "Green-tek-A14316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "12:45:00 PM",
    "power": 0.729,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "1/30/2024 13:00",
    "plant_id": "Green-tek-A14317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "1:00:00 PM",
    "power": 0.665,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "1/30/2024 13:15",
    "plant_id": "Green-tek-A14318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "1:15:00 PM",
    "power": 0.594,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "1/30/2024 13:30",
    "plant_id": "Green-tek-A14319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "1:30:00 PM",
    "power": 0.52,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "1/30/2024 13:45",
    "plant_id": "Green-tek-A14320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "1:45:00 PM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "1/30/2024 14:00",
    "plant_id": "Green-tek-A14321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "2:00:00 PM",
    "power": 0.375,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "1/30/2024 14:15",
    "plant_id": "Green-tek-A14322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "2:15:00 PM",
    "power": 0.309,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "1/30/2024 14:30",
    "plant_id": "Green-tek-A14323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "2:30:00 PM",
    "power": 0.25,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "1/30/2024 14:45",
    "plant_id": "Green-tek-A14324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "2:45:00 PM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "1/30/2024 15:00",
    "plant_id": "Green-tek-A14325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "3:00:00 PM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/30/2024 15:15",
    "plant_id": "Green-tek-A14326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "3:15:00 PM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/30/2024 15:30",
    "plant_id": "Green-tek-A14327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "3:30:00 PM",
    "power": 0.086,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "1/30/2024 15:45",
    "plant_id": "Green-tek-A14328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "3:45:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "1/30/2024 16:00",
    "plant_id": "Green-tek-A14329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/30/2024 16:15",
    "plant_id": "Green-tek-A14330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/30/2024 16:30",
    "plant_id": "Green-tek-A14331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/30/2024 16:45",
    "plant_id": "Green-tek-A14332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/30/2024 17:00",
    "plant_id": "Green-tek-A14333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/30/2024 17:15",
    "plant_id": "Green-tek-A14334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/30/2024 17:30",
    "plant_id": "Green-tek-A14335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/30/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/31/2024 6:00",
    "plant_id": "Green-tek-A14336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "1/31/2024 6:15",
    "plant_id": "Green-tek-A14337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/31/2024 6:30",
    "plant_id": "Green-tek-A14338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/31/2024 6:45",
    "plant_id": "Green-tek-A14339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/31/2024 7:00",
    "plant_id": "Green-tek-A14340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/31/2024 7:15",
    "plant_id": "Green-tek-A14341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/31/2024 7:30",
    "plant_id": "Green-tek-A14342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/31/2024 7:45",
    "plant_id": "Green-tek-A14343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/31/2024 8:00",
    "plant_id": "Green-tek-A14344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "8:00:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/31/2024 8:15",
    "plant_id": "Green-tek-A14345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "8:15:00 AM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/31/2024 8:30",
    "plant_id": "Green-tek-A14346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "8:30:00 AM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/31/2024 8:45",
    "plant_id": "Green-tek-A14347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "8:45:00 AM",
    "power": 0.194,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "1/31/2024 9:00",
    "plant_id": "Green-tek-A14348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "9:00:00 AM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "1/31/2024 9:15",
    "plant_id": "Green-tek-A14349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "9:15:00 AM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "1/31/2024 9:30",
    "plant_id": "Green-tek-A14350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "9:30:00 AM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "1/31/2024 9:45",
    "plant_id": "Green-tek-A14351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "9:45:00 AM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "1/31/2024 10:00",
    "plant_id": "Green-tek-A14352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "10:00:00 AM",
    "power": 0.511,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "1/31/2024 10:15",
    "plant_id": "Green-tek-A14353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "10:15:00 AM",
    "power": 0.584,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "1/31/2024 10:30",
    "plant_id": "Green-tek-A14354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "10:30:00 AM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "1/31/2024 10:45",
    "plant_id": "Green-tek-A14355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "10:45:00 AM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "1/31/2024 11:00",
    "plant_id": "Green-tek-A14356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "11:00:00 AM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "1/31/2024 11:15",
    "plant_id": "Green-tek-A14357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "11:15:00 AM",
    "power": 0.809,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "1/31/2024 11:30",
    "plant_id": "Green-tek-A14358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "11:30:00 AM",
    "power": 0.834,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/31/2024 11:45",
    "plant_id": "Green-tek-A14359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "11:45:00 AM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "1/31/2024 12:00",
    "plant_id": "Green-tek-A14360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "12:00:00 PM",
    "power": 0.834,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "1/31/2024 12:15",
    "plant_id": "Green-tek-A14361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "12:15:00 PM",
    "power": 0.809,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "1/31/2024 12:30",
    "plant_id": "Green-tek-A14362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "12:30:00 PM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "1/31/2024 12:45",
    "plant_id": "Green-tek-A14363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "12:45:00 PM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "1/31/2024 13:00",
    "plant_id": "Green-tek-A14364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "1:00:00 PM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "1/31/2024 13:15",
    "plant_id": "Green-tek-A14365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "1:15:00 PM",
    "power": 0.584,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "1/31/2024 13:30",
    "plant_id": "Green-tek-A14366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "1:30:00 PM",
    "power": 0.511,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "1/31/2024 13:45",
    "plant_id": "Green-tek-A14367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "1:45:00 PM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "1/31/2024 14:00",
    "plant_id": "Green-tek-A14368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "2:00:00 PM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "1/31/2024 14:15",
    "plant_id": "Green-tek-A14369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "2:15:00 PM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "1/31/2024 14:30",
    "plant_id": "Green-tek-A14370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "2:30:00 PM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "1/31/2024 14:45",
    "plant_id": "Green-tek-A14371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "2:45:00 PM",
    "power": 0.194,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "1/31/2024 15:00",
    "plant_id": "Green-tek-A14372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "3:00:00 PM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "1/31/2024 15:15",
    "plant_id": "Green-tek-A14373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "3:15:00 PM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "1/31/2024 15:30",
    "plant_id": "Green-tek-A14374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "3:30:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "1/31/2024 15:45",
    "plant_id": "Green-tek-A14375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "1/31/2024 16:00",
    "plant_id": "Green-tek-A14376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "1/31/2024 16:15",
    "plant_id": "Green-tek-A14377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "1/31/2024 16:30",
    "plant_id": "Green-tek-A14378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "1/31/2024 16:45",
    "plant_id": "Green-tek-A14379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "1/31/2024 17:00",
    "plant_id": "Green-tek-A14380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/31/2024 17:15",
    "plant_id": "Green-tek-A14381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "1/31/2024 17:30",
    "plant_id": "Green-tek-A14382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "1/31/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/1/2024 6:00",
    "plant_id": "Green-tek-A14383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/1/2024 6:15",
    "plant_id": "Green-tek-A14384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/1/2024 6:30",
    "plant_id": "Green-tek-A14385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/1/2024 6:45",
    "plant_id": "Green-tek-A14386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/1/2024 7:00",
    "plant_id": "Green-tek-A14387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/1/2024 7:15",
    "plant_id": "Green-tek-A14388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/1/2024 7:30",
    "plant_id": "Green-tek-A14389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/1/2024 7:45",
    "plant_id": "Green-tek-A14390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "7:45:00 AM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/1/2024 8:00",
    "plant_id": "Green-tek-A14391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "8:00:00 AM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "2/1/2024 8:15",
    "plant_id": "Green-tek-A14392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "8:15:00 AM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/1/2024 8:30",
    "plant_id": "Green-tek-A14393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "8:30:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/1/2024 8:45",
    "plant_id": "Green-tek-A14394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "8:45:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "2/1/2024 9:00",
    "plant_id": "Green-tek-A14395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "9:00:00 AM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/1/2024 9:15",
    "plant_id": "Green-tek-A14396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "9:15:00 AM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "2/1/2024 9:30",
    "plant_id": "Green-tek-A14397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "9:30:00 AM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "2/1/2024 9:45",
    "plant_id": "Green-tek-A14398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "9:45:00 AM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "2/1/2024 10:00",
    "plant_id": "Green-tek-A14399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "10:00:00 AM",
    "power": 0.598,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "2/1/2024 10:15",
    "plant_id": "Green-tek-A14400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "10:15:00 AM",
    "power": 0.683,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "2/1/2024 10:30",
    "plant_id": "Green-tek-A14401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "10:30:00 AM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "2/1/2024 10:45",
    "plant_id": "Green-tek-A14402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "10:45:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "2/1/2024 11:00",
    "plant_id": "Green-tek-A14403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "11:00:00 AM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "2/1/2024 11:15",
    "plant_id": "Green-tek-A14404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "11:15:00 AM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "2/1/2024 11:30",
    "plant_id": "Green-tek-A14405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "11:30:00 AM",
    "power": 0.976,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "2/1/2024 11:45",
    "plant_id": "Green-tek-A14406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "11:45:00 AM",
    "power": 0.986,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "2/1/2024 12:00",
    "plant_id": "Green-tek-A14407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "12:00:00 PM",
    "power": 0.976,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "2/1/2024 12:15",
    "plant_id": "Green-tek-A14408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "12:15:00 PM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "2/1/2024 12:30",
    "plant_id": "Green-tek-A14409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "12:30:00 PM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "2/1/2024 12:45",
    "plant_id": "Green-tek-A14410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "12:45:00 PM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "2/1/2024 13:00",
    "plant_id": "Green-tek-A14411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "1:00:00 PM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "2/1/2024 13:15",
    "plant_id": "Green-tek-A14412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "1:15:00 PM",
    "power": 0.683,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "2/1/2024 13:30",
    "plant_id": "Green-tek-A14413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "1:30:00 PM",
    "power": 0.598,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "2/1/2024 13:45",
    "plant_id": "Green-tek-A14414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "1:45:00 PM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "2/1/2024 14:00",
    "plant_id": "Green-tek-A14415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "2:00:00 PM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "2/1/2024 14:15",
    "plant_id": "Green-tek-A14416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "2:15:00 PM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "2/1/2024 14:30",
    "plant_id": "Green-tek-A14417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "2:30:00 PM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/1/2024 14:45",
    "plant_id": "Green-tek-A14418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "2:45:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "2/1/2024 15:00",
    "plant_id": "Green-tek-A14419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "3:00:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/1/2024 15:15",
    "plant_id": "Green-tek-A14420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "3:15:00 PM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/1/2024 15:30",
    "plant_id": "Green-tek-A14421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "3:30:00 PM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "2/1/2024 15:45",
    "plant_id": "Green-tek-A14422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "3:45:00 PM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/1/2024 16:00",
    "plant_id": "Green-tek-A14423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/1/2024 16:15",
    "plant_id": "Green-tek-A14424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/1/2024 16:30",
    "plant_id": "Green-tek-A14425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/1/2024 16:45",
    "plant_id": "Green-tek-A14426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/1/2024 17:00",
    "plant_id": "Green-tek-A14427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/1/2024 17:15",
    "plant_id": "Green-tek-A14428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/1/2024 17:30",
    "plant_id": "Green-tek-A14429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/1/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/2/2024 6:00",
    "plant_id": "Green-tek-A14430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/2/2024 6:15",
    "plant_id": "Green-tek-A14431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/2/2024 6:30",
    "plant_id": "Green-tek-A14432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/2/2024 6:45",
    "plant_id": "Green-tek-A14433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/2/2024 7:00",
    "plant_id": "Green-tek-A14434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/2/2024 7:15",
    "plant_id": "Green-tek-A14435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/2/2024 7:30",
    "plant_id": "Green-tek-A14436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/2/2024 7:45",
    "plant_id": "Green-tek-A14437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "7:45:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/2/2024 8:00",
    "plant_id": "Green-tek-A14438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "8:00:00 AM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "2/2/2024 8:15",
    "plant_id": "Green-tek-A14439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "8:15:00 AM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "2/2/2024 8:30",
    "plant_id": "Green-tek-A14440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "8:30:00 AM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "2/2/2024 8:45",
    "plant_id": "Green-tek-A14441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "8:45:00 AM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "2/2/2024 9:00",
    "plant_id": "Green-tek-A14442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "9:00:00 AM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "2/2/2024 9:15",
    "plant_id": "Green-tek-A14443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "9:15:00 AM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "2/2/2024 9:30",
    "plant_id": "Green-tek-A14444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "9:30:00 AM",
    "power": 0.52,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "2/2/2024 9:45",
    "plant_id": "Green-tek-A14445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "9:45:00 AM",
    "power": 0.618,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "2/2/2024 10:00",
    "plant_id": "Green-tek-A14446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "10:00:00 AM",
    "power": 0.72,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "2/2/2024 10:15",
    "plant_id": "Green-tek-A14447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "10:15:00 AM",
    "power": 0.822,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "2/2/2024 10:30",
    "plant_id": "Green-tek-A14448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "10:30:00 AM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "2/2/2024 10:45",
    "plant_id": "Green-tek-A14449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "10:45:00 AM",
    "power": 1.009,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/2/2024 11:00",
    "plant_id": "Green-tek-A14450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "11:00:00 AM",
    "power": 1.083,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "2/2/2024 11:15",
    "plant_id": "Green-tek-A14451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "11:15:00 AM",
    "power": 1.14,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "2/2/2024 11:30",
    "plant_id": "Green-tek-A14452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "11:30:00 AM",
    "power": 1.175,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "2/2/2024 11:45",
    "plant_id": "Green-tek-A14453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "11:45:00 AM",
    "power": 1.188,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "2/2/2024 12:00",
    "plant_id": "Green-tek-A14454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "12:00:00 PM",
    "power": 1.175,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "2/2/2024 12:15",
    "plant_id": "Green-tek-A14455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "12:15:00 PM",
    "power": 1.14,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "2/2/2024 12:30",
    "plant_id": "Green-tek-A14456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "12:30:00 PM",
    "power": 1.083,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "2/2/2024 12:45",
    "plant_id": "Green-tek-A14457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "12:45:00 PM",
    "power": 1.009,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/2/2024 13:00",
    "plant_id": "Green-tek-A14458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "1:00:00 PM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "2/2/2024 13:15",
    "plant_id": "Green-tek-A14459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "1:15:00 PM",
    "power": 0.822,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "2/2/2024 13:30",
    "plant_id": "Green-tek-A14460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "1:30:00 PM",
    "power": 0.72,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "2/2/2024 13:45",
    "plant_id": "Green-tek-A14461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "1:45:00 PM",
    "power": 0.618,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "2/2/2024 14:00",
    "plant_id": "Green-tek-A14462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "2:00:00 PM",
    "power": 0.52,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "2/2/2024 14:15",
    "plant_id": "Green-tek-A14463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "2:15:00 PM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "2/2/2024 14:30",
    "plant_id": "Green-tek-A14464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "2:30:00 PM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "2/2/2024 14:45",
    "plant_id": "Green-tek-A14465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "2:45:00 PM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "2/2/2024 15:00",
    "plant_id": "Green-tek-A14466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "3:00:00 PM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "2/2/2024 15:15",
    "plant_id": "Green-tek-A14467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "3:15:00 PM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "2/2/2024 15:30",
    "plant_id": "Green-tek-A14468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "3:30:00 PM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "2/2/2024 15:45",
    "plant_id": "Green-tek-A14469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "3:45:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/2/2024 16:00",
    "plant_id": "Green-tek-A14470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/2/2024 16:15",
    "plant_id": "Green-tek-A14471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/2/2024 16:30",
    "plant_id": "Green-tek-A14472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/2/2024 16:45",
    "plant_id": "Green-tek-A14473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/2/2024 17:00",
    "plant_id": "Green-tek-A14474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/2/2024 17:15",
    "plant_id": "Green-tek-A14475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/2/2024 17:30",
    "plant_id": "Green-tek-A14476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/2/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/3/2024 6:00",
    "plant_id": "Green-tek-A14477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/3/2024 6:15",
    "plant_id": "Green-tek-A14478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/3/2024 6:30",
    "plant_id": "Green-tek-A14479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/3/2024 6:45",
    "plant_id": "Green-tek-A14480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/3/2024 7:00",
    "plant_id": "Green-tek-A14481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/3/2024 7:15",
    "plant_id": "Green-tek-A14482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/3/2024 7:30",
    "plant_id": "Green-tek-A14483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/3/2024 7:45",
    "plant_id": "Green-tek-A14484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "7:45:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "2/3/2024 8:00",
    "plant_id": "Green-tek-A14485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "8:00:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "2/3/2024 8:15",
    "plant_id": "Green-tek-A14486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "8:15:00 AM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "2/3/2024 8:30",
    "plant_id": "Green-tek-A14487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "8:30:00 AM",
    "power": 0.222,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/3/2024 8:45",
    "plant_id": "Green-tek-A14488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "8:45:00 AM",
    "power": 0.286,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/3/2024 9:00",
    "plant_id": "Green-tek-A14489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "9:00:00 AM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/3/2024 9:15",
    "plant_id": "Green-tek-A14490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "9:15:00 AM",
    "power": 0.448,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "2/3/2024 9:30",
    "plant_id": "Green-tek-A14491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "9:30:00 AM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "2/3/2024 9:45",
    "plant_id": "Green-tek-A14492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "9:45:00 AM",
    "power": 0.648,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "2/3/2024 10:00",
    "plant_id": "Green-tek-A14493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "10:00:00 AM",
    "power": 0.755,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "2/3/2024 10:15",
    "plant_id": "Green-tek-A14494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "10:15:00 AM",
    "power": 0.862,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "2/3/2024 10:30",
    "plant_id": "Green-tek-A14495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "10:30:00 AM",
    "power": 0.964,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "2/3/2024 10:45",
    "plant_id": "Green-tek-A14496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "10:45:00 AM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "2/3/2024 11:00",
    "plant_id": "Green-tek-A14497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "11:00:00 AM",
    "power": 1.135,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "2/3/2024 11:15",
    "plant_id": "Green-tek-A14498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "11:15:00 AM",
    "power": 1.194,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "2/3/2024 11:30",
    "plant_id": "Green-tek-A14499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "11:30:00 AM",
    "power": 1.232,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "2/3/2024 11:45",
    "plant_id": "Green-tek-A14500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "11:45:00 AM",
    "power": 1.244,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "2/3/2024 12:00",
    "plant_id": "Green-tek-A14501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "12:00:00 PM",
    "power": 1.232,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "2/3/2024 12:15",
    "plant_id": "Green-tek-A14502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "12:15:00 PM",
    "power": 1.194,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "2/3/2024 12:30",
    "plant_id": "Green-tek-A14503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "12:30:00 PM",
    "power": 1.135,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "2/3/2024 12:45",
    "plant_id": "Green-tek-A14504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "12:45:00 PM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "2/3/2024 13:00",
    "plant_id": "Green-tek-A14505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "1:00:00 PM",
    "power": 0.964,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "2/3/2024 13:15",
    "plant_id": "Green-tek-A14506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "1:15:00 PM",
    "power": 0.862,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "2/3/2024 13:30",
    "plant_id": "Green-tek-A14507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "1:30:00 PM",
    "power": 0.755,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "2/3/2024 13:45",
    "plant_id": "Green-tek-A14508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "1:45:00 PM",
    "power": 0.648,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "2/3/2024 14:00",
    "plant_id": "Green-tek-A14509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "2:00:00 PM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "2/3/2024 14:15",
    "plant_id": "Green-tek-A14510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "2:15:00 PM",
    "power": 0.448,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "2/3/2024 14:30",
    "plant_id": "Green-tek-A14511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "2:30:00 PM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/3/2024 14:45",
    "plant_id": "Green-tek-A14512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "2:45:00 PM",
    "power": 0.286,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/3/2024 15:00",
    "plant_id": "Green-tek-A14513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "3:00:00 PM",
    "power": 0.222,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/3/2024 15:15",
    "plant_id": "Green-tek-A14514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "3:15:00 PM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "2/3/2024 15:30",
    "plant_id": "Green-tek-A14515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "3:30:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "2/3/2024 15:45",
    "plant_id": "Green-tek-A14516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "3:45:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "2/3/2024 16:00",
    "plant_id": "Green-tek-A14517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/3/2024 16:15",
    "plant_id": "Green-tek-A14518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/3/2024 16:30",
    "plant_id": "Green-tek-A14519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/3/2024 16:45",
    "plant_id": "Green-tek-A14520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/3/2024 17:00",
    "plant_id": "Green-tek-A14521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/3/2024 17:15",
    "plant_id": "Green-tek-A14522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/3/2024 17:30",
    "plant_id": "Green-tek-A14523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/3/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/4/2024 6:00",
    "plant_id": "Green-tek-A14524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/4/2024 6:15",
    "plant_id": "Green-tek-A14525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/4/2024 6:30",
    "plant_id": "Green-tek-A14526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/4/2024 6:45",
    "plant_id": "Green-tek-A14527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/4/2024 7:00",
    "plant_id": "Green-tek-A14528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/4/2024 7:15",
    "plant_id": "Green-tek-A14529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "2/4/2024 7:30",
    "plant_id": "Green-tek-A14530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "2/4/2024 7:45",
    "plant_id": "Green-tek-A14531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/4/2024 8:00",
    "plant_id": "Green-tek-A14532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/4/2024 8:15",
    "plant_id": "Green-tek-A14533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "8:15:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/4/2024 8:30",
    "plant_id": "Green-tek-A14534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "8:30:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/4/2024 8:45",
    "plant_id": "Green-tek-A14535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "8:45:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "2/4/2024 9:00",
    "plant_id": "Green-tek-A14536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "9:00:00 AM",
    "power": 0.378,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "2/4/2024 9:15",
    "plant_id": "Green-tek-A14537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "9:15:00 AM",
    "power": 0.468,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "2/4/2024 9:30",
    "plant_id": "Green-tek-A14538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "9:30:00 AM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "2/4/2024 9:45",
    "plant_id": "Green-tek-A14539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "9:45:00 AM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "2/4/2024 10:00",
    "plant_id": "Green-tek-A14540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "10:00:00 AM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "2/4/2024 10:15",
    "plant_id": "Green-tek-A14541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "10:15:00 AM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "2/4/2024 10:30",
    "plant_id": "Green-tek-A14542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "10:30:00 AM",
    "power": 1.006,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/4/2024 10:45",
    "plant_id": "Green-tek-A14543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "10:45:00 AM",
    "power": 1.103,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "2/4/2024 11:00",
    "plant_id": "Green-tek-A14544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "11:00:00 AM",
    "power": 1.185,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "2/4/2024 11:15",
    "plant_id": "Green-tek-A14545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "11:15:00 AM",
    "power": 1.247,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "2/4/2024 11:30",
    "plant_id": "Green-tek-A14546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "11:30:00 AM",
    "power": 1.286,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.3315,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "2/4/2024 11:45",
    "plant_id": "Green-tek-A14547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "11:45:00 AM",
    "power": 1.299,
    "Energy": 0.325,
    "output Energy": 0.325,
    "Ac output": 1.325,
    "dc output": 1.3515,
    "AC_R_i": 0.00042391,
    "AC_S_i": 0.000466301,
    "AC_T_i": 0.00051293,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0974993,
    "AC_S_pow": 0.10724923,
    "AC_T_Pow": 0.1179739,
    "AC_R_output": 0.3975,
    "AC_S_output": 0.43725,
    "AC_T_output": 0.480975,
    "dc_current": 0.00238971,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.3315,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000325,
    "carbon offset": 0.40625,
    "trees planted": 9.027777778,
    "DC_R_output": 0.131175,
    "DC_S_output": 0.1442925,
    "DC_T_output": 0.15872175
  },
  {
    "datetime": "2/4/2024 12:00",
    "plant_id": "Green-tek-A14548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "12:00:00 PM",
    "power": 1.286,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "2/4/2024 12:15",
    "plant_id": "Green-tek-A14549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "12:15:00 PM",
    "power": 1.247,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "2/4/2024 12:30",
    "plant_id": "Green-tek-A14550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "12:30:00 PM",
    "power": 1.185,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "2/4/2024 12:45",
    "plant_id": "Green-tek-A14551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "12:45:00 PM",
    "power": 1.103,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "2/4/2024 13:00",
    "plant_id": "Green-tek-A14552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "1:00:00 PM",
    "power": 1.006,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/4/2024 13:15",
    "plant_id": "Green-tek-A14553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "1:15:00 PM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "2/4/2024 13:30",
    "plant_id": "Green-tek-A14554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "1:30:00 PM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "2/4/2024 13:45",
    "plant_id": "Green-tek-A14555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "1:45:00 PM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "2/4/2024 14:00",
    "plant_id": "Green-tek-A14556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "2:00:00 PM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "2/4/2024 14:15",
    "plant_id": "Green-tek-A14557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "2:15:00 PM",
    "power": 0.468,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "2/4/2024 14:30",
    "plant_id": "Green-tek-A14558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "2:30:00 PM",
    "power": 0.378,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "2/4/2024 14:45",
    "plant_id": "Green-tek-A14559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "2:45:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "2/4/2024 15:00",
    "plant_id": "Green-tek-A14560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "3:00:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/4/2024 15:15",
    "plant_id": "Green-tek-A14561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "3:15:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/4/2024 15:30",
    "plant_id": "Green-tek-A14562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/4/2024 15:45",
    "plant_id": "Green-tek-A14563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/4/2024 16:00",
    "plant_id": "Green-tek-A14564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "2/4/2024 16:15",
    "plant_id": "Green-tek-A14565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "2/4/2024 16:30",
    "plant_id": "Green-tek-A14566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/4/2024 16:45",
    "plant_id": "Green-tek-A14567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/4/2024 17:00",
    "plant_id": "Green-tek-A14568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/4/2024 17:15",
    "plant_id": "Green-tek-A14569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/4/2024 17:30",
    "plant_id": "Green-tek-A14570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/4/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/5/2024 6:00",
    "plant_id": "Green-tek-A14571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/5/2024 6:15",
    "plant_id": "Green-tek-A14572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/5/2024 6:30",
    "plant_id": "Green-tek-A14573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/5/2024 6:45",
    "plant_id": "Green-tek-A14574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/5/2024 7:00",
    "plant_id": "Green-tek-A14575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/5/2024 7:15",
    "plant_id": "Green-tek-A14576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/5/2024 7:30",
    "plant_id": "Green-tek-A14577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "7:30:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/5/2024 7:45",
    "plant_id": "Green-tek-A14578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "7:45:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/5/2024 8:00",
    "plant_id": "Green-tek-A14579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "8:00:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "2/5/2024 8:15",
    "plant_id": "Green-tek-A14580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "8:15:00 AM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "2/5/2024 8:30",
    "plant_id": "Green-tek-A14581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "8:30:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "2/5/2024 8:45",
    "plant_id": "Green-tek-A14582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "8:45:00 AM",
    "power": 0.185,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "2/5/2024 9:00",
    "plant_id": "Green-tek-A14583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "9:00:00 AM",
    "power": 0.234,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "2/5/2024 9:15",
    "plant_id": "Green-tek-A14584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "9:15:00 AM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "2/5/2024 9:30",
    "plant_id": "Green-tek-A14585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "9:30:00 AM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "2/5/2024 9:45",
    "plant_id": "Green-tek-A14586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "9:45:00 AM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/5/2024 10:00",
    "plant_id": "Green-tek-A14587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "10:00:00 AM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "2/5/2024 10:15",
    "plant_id": "Green-tek-A14588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "10:15:00 AM",
    "power": 0.558,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "2/5/2024 10:30",
    "plant_id": "Green-tek-A14589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "10:30:00 AM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "2/5/2024 10:45",
    "plant_id": "Green-tek-A14590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "10:45:00 AM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "2/5/2024 11:00",
    "plant_id": "Green-tek-A14591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "11:00:00 AM",
    "power": 0.734,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "2/5/2024 11:15",
    "plant_id": "Green-tek-A14592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "11:15:00 AM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "2/5/2024 11:30",
    "plant_id": "Green-tek-A14593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "11:30:00 AM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "2/5/2024 11:45",
    "plant_id": "Green-tek-A14594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "11:45:00 AM",
    "power": 0.805,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "2/5/2024 12:00",
    "plant_id": "Green-tek-A14595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "12:00:00 PM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "2/5/2024 12:15",
    "plant_id": "Green-tek-A14596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "12:15:00 PM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "2/5/2024 12:30",
    "plant_id": "Green-tek-A14597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "12:30:00 PM",
    "power": 0.734,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "2/5/2024 12:45",
    "plant_id": "Green-tek-A14598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "12:45:00 PM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "2/5/2024 13:00",
    "plant_id": "Green-tek-A14599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "1:00:00 PM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "2/5/2024 13:15",
    "plant_id": "Green-tek-A14600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "1:15:00 PM",
    "power": 0.558,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "2/5/2024 13:30",
    "plant_id": "Green-tek-A14601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "1:30:00 PM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "2/5/2024 13:45",
    "plant_id": "Green-tek-A14602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "1:45:00 PM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/5/2024 14:00",
    "plant_id": "Green-tek-A14603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "2:00:00 PM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "2/5/2024 14:15",
    "plant_id": "Green-tek-A14604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "2:15:00 PM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "2/5/2024 14:30",
    "plant_id": "Green-tek-A14605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "2:30:00 PM",
    "power": 0.234,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "2/5/2024 14:45",
    "plant_id": "Green-tek-A14606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "2:45:00 PM",
    "power": 0.185,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "2/5/2024 15:00",
    "plant_id": "Green-tek-A14607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "3:00:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "2/5/2024 15:15",
    "plant_id": "Green-tek-A14608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "3:15:00 PM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "2/5/2024 15:30",
    "plant_id": "Green-tek-A14609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "3:30:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "2/5/2024 15:45",
    "plant_id": "Green-tek-A14610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "3:45:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/5/2024 16:00",
    "plant_id": "Green-tek-A14611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "4:00:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/5/2024 16:15",
    "plant_id": "Green-tek-A14612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/5/2024 16:30",
    "plant_id": "Green-tek-A14613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/5/2024 16:45",
    "plant_id": "Green-tek-A14614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/5/2024 17:00",
    "plant_id": "Green-tek-A14615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/5/2024 17:15",
    "plant_id": "Green-tek-A14616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/5/2024 17:30",
    "plant_id": "Green-tek-A14617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/5/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/6/2024 6:00",
    "plant_id": "Green-tek-A14618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/6/2024 6:15",
    "plant_id": "Green-tek-A14619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/6/2024 6:30",
    "plant_id": "Green-tek-A14620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/6/2024 6:45",
    "plant_id": "Green-tek-A14621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/6/2024 7:00",
    "plant_id": "Green-tek-A14622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/6/2024 7:15",
    "plant_id": "Green-tek-A14623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/6/2024 7:30",
    "plant_id": "Green-tek-A14624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/6/2024 7:45",
    "plant_id": "Green-tek-A14625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "7:45:00 AM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/6/2024 8:00",
    "plant_id": "Green-tek-A14626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "8:00:00 AM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/6/2024 8:15",
    "plant_id": "Green-tek-A14627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "8:15:00 AM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "2/6/2024 8:30",
    "plant_id": "Green-tek-A14628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "8:30:00 AM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "2/6/2024 8:45",
    "plant_id": "Green-tek-A14629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "8:45:00 AM",
    "power": 0.258,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "2/6/2024 9:00",
    "plant_id": "Green-tek-A14630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "9:00:00 AM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "2/6/2024 9:15",
    "plant_id": "Green-tek-A14631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "9:15:00 AM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "2/6/2024 9:30",
    "plant_id": "Green-tek-A14632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "9:30:00 AM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "2/6/2024 9:45",
    "plant_id": "Green-tek-A14633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "9:45:00 AM",
    "power": 0.584,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "2/6/2024 10:00",
    "plant_id": "Green-tek-A14634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "10:00:00 AM",
    "power": 0.681,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/6/2024 10:15",
    "plant_id": "Green-tek-A14635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "10:15:00 AM",
    "power": 0.778,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "2/6/2024 10:30",
    "plant_id": "Green-tek-A14636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "10:30:00 AM",
    "power": 0.87,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "2/6/2024 10:45",
    "plant_id": "Green-tek-A14637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "10:45:00 AM",
    "power": 0.954,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "2/6/2024 11:00",
    "plant_id": "Green-tek-A14638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "11:00:00 AM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "2/6/2024 11:15",
    "plant_id": "Green-tek-A14639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "11:15:00 AM",
    "power": 1.078,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "2/6/2024 11:30",
    "plant_id": "Green-tek-A14640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "11:30:00 AM",
    "power": 1.112,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "2/6/2024 11:45",
    "plant_id": "Green-tek-A14641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "11:45:00 AM",
    "power": 1.123,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "2/6/2024 12:00",
    "plant_id": "Green-tek-A14642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "12:00:00 PM",
    "power": 1.112,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "2/6/2024 12:15",
    "plant_id": "Green-tek-A14643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "12:15:00 PM",
    "power": 1.078,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "2/6/2024 12:30",
    "plant_id": "Green-tek-A14644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "12:30:00 PM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "2/6/2024 12:45",
    "plant_id": "Green-tek-A14645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "12:45:00 PM",
    "power": 0.954,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "2/6/2024 13:00",
    "plant_id": "Green-tek-A14646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "1:00:00 PM",
    "power": 0.87,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "2/6/2024 13:15",
    "plant_id": "Green-tek-A14647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "1:15:00 PM",
    "power": 0.778,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "2/6/2024 13:30",
    "plant_id": "Green-tek-A14648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "1:30:00 PM",
    "power": 0.681,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/6/2024 13:45",
    "plant_id": "Green-tek-A14649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "1:45:00 PM",
    "power": 0.584,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "2/6/2024 14:00",
    "plant_id": "Green-tek-A14650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "2:00:00 PM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "2/6/2024 14:15",
    "plant_id": "Green-tek-A14651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "2:15:00 PM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "2/6/2024 14:30",
    "plant_id": "Green-tek-A14652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "2:30:00 PM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "2/6/2024 14:45",
    "plant_id": "Green-tek-A14653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "2:45:00 PM",
    "power": 0.258,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "2/6/2024 15:00",
    "plant_id": "Green-tek-A14654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "3:00:00 PM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "2/6/2024 15:15",
    "plant_id": "Green-tek-A14655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "3:15:00 PM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "2/6/2024 15:30",
    "plant_id": "Green-tek-A14656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "3:30:00 PM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/6/2024 15:45",
    "plant_id": "Green-tek-A14657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "3:45:00 PM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/6/2024 16:00",
    "plant_id": "Green-tek-A14658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/6/2024 16:15",
    "plant_id": "Green-tek-A14659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/6/2024 16:30",
    "plant_id": "Green-tek-A14660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/6/2024 16:45",
    "plant_id": "Green-tek-A14661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/6/2024 17:00",
    "plant_id": "Green-tek-A14662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/6/2024 17:15",
    "plant_id": "Green-tek-A14663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/6/2024 17:30",
    "plant_id": "Green-tek-A14664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/6/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/7/2024 6:00",
    "plant_id": "Green-tek-A14665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/7/2024 6:15",
    "plant_id": "Green-tek-A14666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/7/2024 6:30",
    "plant_id": "Green-tek-A14667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/7/2024 6:45",
    "plant_id": "Green-tek-A14668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/7/2024 7:00",
    "plant_id": "Green-tek-A14669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/7/2024 7:15",
    "plant_id": "Green-tek-A14670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/7/2024 7:30",
    "plant_id": "Green-tek-A14671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/7/2024 7:45",
    "plant_id": "Green-tek-A14672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "7:45:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "2/7/2024 8:00",
    "plant_id": "Green-tek-A14673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "8:00:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "2/7/2024 8:15",
    "plant_id": "Green-tek-A14674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "8:15:00 AM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "2/7/2024 8:30",
    "plant_id": "Green-tek-A14675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "8:30:00 AM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/7/2024 8:45",
    "plant_id": "Green-tek-A14676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "8:45:00 AM",
    "power": 0.286,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "2/7/2024 9:00",
    "plant_id": "Green-tek-A14677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "9:00:00 AM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/7/2024 9:15",
    "plant_id": "Green-tek-A14678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "9:15:00 AM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "2/7/2024 9:30",
    "plant_id": "Green-tek-A14679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "9:30:00 AM",
    "power": 0.543,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "2/7/2024 9:45",
    "plant_id": "Green-tek-A14680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "9:45:00 AM",
    "power": 0.646,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "2/7/2024 10:00",
    "plant_id": "Green-tek-A14681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "10:00:00 AM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "2/7/2024 10:15",
    "plant_id": "Green-tek-A14682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "10:15:00 AM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "2/7/2024 10:30",
    "plant_id": "Green-tek-A14683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "10:30:00 AM",
    "power": 0.962,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "2/7/2024 10:45",
    "plant_id": "Green-tek-A14684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "10:45:00 AM",
    "power": 1.054,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "2/7/2024 11:00",
    "plant_id": "Green-tek-A14685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "11:00:00 AM",
    "power": 1.132,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "2/7/2024 11:15",
    "plant_id": "Green-tek-A14686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "11:15:00 AM",
    "power": 1.192,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "2/7/2024 11:30",
    "plant_id": "Green-tek-A14687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "11:30:00 AM",
    "power": 1.229,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "2/7/2024 11:45",
    "plant_id": "Green-tek-A14688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "11:45:00 AM",
    "power": 1.241,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "2/7/2024 12:00",
    "plant_id": "Green-tek-A14689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "12:00:00 PM",
    "power": 1.229,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "2/7/2024 12:15",
    "plant_id": "Green-tek-A14690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "12:15:00 PM",
    "power": 1.192,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "2/7/2024 12:30",
    "plant_id": "Green-tek-A14691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "12:30:00 PM",
    "power": 1.132,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "2/7/2024 12:45",
    "plant_id": "Green-tek-A14692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "12:45:00 PM",
    "power": 1.054,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "2/7/2024 13:00",
    "plant_id": "Green-tek-A14693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "1:00:00 PM",
    "power": 0.962,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "2/7/2024 13:15",
    "plant_id": "Green-tek-A14694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "1:15:00 PM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "2/7/2024 13:30",
    "plant_id": "Green-tek-A14695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "1:30:00 PM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "2/7/2024 13:45",
    "plant_id": "Green-tek-A14696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "1:45:00 PM",
    "power": 0.646,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "2/7/2024 14:00",
    "plant_id": "Green-tek-A14697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "2:00:00 PM",
    "power": 0.543,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "2/7/2024 14:15",
    "plant_id": "Green-tek-A14698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "2:15:00 PM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "2/7/2024 14:30",
    "plant_id": "Green-tek-A14699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "2:30:00 PM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/7/2024 14:45",
    "plant_id": "Green-tek-A14700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "2:45:00 PM",
    "power": 0.286,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "2/7/2024 15:00",
    "plant_id": "Green-tek-A14701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "3:00:00 PM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/7/2024 15:15",
    "plant_id": "Green-tek-A14702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "3:15:00 PM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "2/7/2024 15:30",
    "plant_id": "Green-tek-A14703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "3:30:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "2/7/2024 15:45",
    "plant_id": "Green-tek-A14704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "3:45:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "2/7/2024 16:00",
    "plant_id": "Green-tek-A14705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/7/2024 16:15",
    "plant_id": "Green-tek-A14706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/7/2024 16:30",
    "plant_id": "Green-tek-A14707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/7/2024 16:45",
    "plant_id": "Green-tek-A14708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/7/2024 17:00",
    "plant_id": "Green-tek-A14709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/7/2024 17:15",
    "plant_id": "Green-tek-A14710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/7/2024 17:30",
    "plant_id": "Green-tek-A14711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/7/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/8/2024 6:00",
    "plant_id": "Green-tek-A14712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/8/2024 6:15",
    "plant_id": "Green-tek-A14713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/8/2024 6:30",
    "plant_id": "Green-tek-A14714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/8/2024 6:45",
    "plant_id": "Green-tek-A14715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/8/2024 7:00",
    "plant_id": "Green-tek-A14716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/8/2024 7:15",
    "plant_id": "Green-tek-A14717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "2/8/2024 7:30",
    "plant_id": "Green-tek-A14718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "2/8/2024 7:45",
    "plant_id": "Green-tek-A14719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "7:45:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/8/2024 8:00",
    "plant_id": "Green-tek-A14720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/8/2024 8:15",
    "plant_id": "Green-tek-A14721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "8:15:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/8/2024 8:30",
    "plant_id": "Green-tek-A14722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "8:30:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/8/2024 8:45",
    "plant_id": "Green-tek-A14723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "8:45:00 AM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "2/8/2024 9:00",
    "plant_id": "Green-tek-A14724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "9:00:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "2/8/2024 9:15",
    "plant_id": "Green-tek-A14725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "9:15:00 AM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "2/8/2024 9:30",
    "plant_id": "Green-tek-A14726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "9:30:00 AM",
    "power": 0.57,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "2/8/2024 9:45",
    "plant_id": "Green-tek-A14727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "9:45:00 AM",
    "power": 0.678,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/8/2024 10:00",
    "plant_id": "Green-tek-A14728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "10:00:00 AM",
    "power": 0.791,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/8/2024 10:15",
    "plant_id": "Green-tek-A14729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "10:15:00 AM",
    "power": 0.903,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "2/8/2024 10:30",
    "plant_id": "Green-tek-A14730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "10:30:00 AM",
    "power": 1.01,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/8/2024 10:45",
    "plant_id": "Green-tek-A14731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "10:45:00 AM",
    "power": 1.107,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "2/8/2024 11:00",
    "plant_id": "Green-tek-A14732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "11:00:00 AM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "2/8/2024 11:15",
    "plant_id": "Green-tek-A14733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "11:15:00 AM",
    "power": 1.251,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.32946,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "2/8/2024 11:30",
    "plant_id": "Green-tek-A14734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "11:30:00 AM",
    "power": 1.29,
    "Energy": 0.323,
    "output Energy": 0.323,
    "Ac output": 1.323,
    "dc output": 1.34946,
    "AC_R_i": 0.0004213,
    "AC_S_i": 0.00046343,
    "AC_T_i": 0.00050977,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096899,
    "AC_S_pow": 0.1065889,
    "AC_T_Pow": 0.1172471,
    "AC_R_output": 0.3969,
    "AC_S_output": 0.43659,
    "AC_T_output": 0.480249,
    "dc_current": 0.002375,
    "dc_voltage": 136,
    "dc_power": 0.33252,
    "dc_output": 0.32946,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000323,
    "carbon offset": 0.40375,
    "trees planted": 8.972222222,
    "DC_R_output": 0.130977,
    "DC_S_output": 0.1440747,
    "DC_T_output": 0.15848217
  },
  {
    "datetime": "2/8/2024 11:45",
    "plant_id": "Green-tek-A14735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "11:45:00 AM",
    "power": 1.303,
    "Energy": 0.326,
    "output Energy": 0.326,
    "Ac output": 1.326,
    "dc output": 1.35252,
    "AC_R_i": 0.00042522,
    "AC_S_i": 0.000467742,
    "AC_T_i": 0.00051452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0978006,
    "AC_S_pow": 0.10758066,
    "AC_T_Pow": 0.1183396,
    "AC_R_output": 0.3978,
    "AC_S_output": 0.43758,
    "AC_T_output": 0.481338,
    "dc_current": 0.00239706,
    "dc_voltage": 136,
    "dc_power": 0.32946,
    "dc_output": 0.33252,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000326,
    "carbon offset": 0.4075,
    "trees planted": 9.055555556,
    "DC_R_output": 0.131274,
    "DC_S_output": 0.1444014,
    "DC_T_output": 0.15884154
  },
  {
    "datetime": "2/8/2024 12:00",
    "plant_id": "Green-tek-A14736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "12:00:00 PM",
    "power": 1.29,
    "Energy": 0.323,
    "output Energy": 0.323,
    "Ac output": 1.323,
    "dc output": 1.34946,
    "AC_R_i": 0.0004213,
    "AC_S_i": 0.00046343,
    "AC_T_i": 0.00050977,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096899,
    "AC_S_pow": 0.1065889,
    "AC_T_Pow": 0.1172471,
    "AC_R_output": 0.3969,
    "AC_S_output": 0.43659,
    "AC_T_output": 0.480249,
    "dc_current": 0.002375,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.32946,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000323,
    "carbon offset": 0.40375,
    "trees planted": 8.972222222,
    "DC_R_output": 0.130977,
    "DC_S_output": 0.1440747,
    "DC_T_output": 0.15848217
  },
  {
    "datetime": "2/8/2024 12:15",
    "plant_id": "Green-tek-A14737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "12:15:00 PM",
    "power": 1.251,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "2/8/2024 12:30",
    "plant_id": "Green-tek-A14738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "12:30:00 PM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "2/8/2024 12:45",
    "plant_id": "Green-tek-A14739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "12:45:00 PM",
    "power": 1.107,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "2/8/2024 13:00",
    "plant_id": "Green-tek-A14740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "1:00:00 PM",
    "power": 1.01,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/8/2024 13:15",
    "plant_id": "Green-tek-A14741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "1:15:00 PM",
    "power": 0.903,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "2/8/2024 13:30",
    "plant_id": "Green-tek-A14742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "1:30:00 PM",
    "power": 0.791,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/8/2024 13:45",
    "plant_id": "Green-tek-A14743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "1:45:00 PM",
    "power": 0.678,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/8/2024 14:00",
    "plant_id": "Green-tek-A14744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "2:00:00 PM",
    "power": 0.57,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "2/8/2024 14:15",
    "plant_id": "Green-tek-A14745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "2:15:00 PM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "2/8/2024 14:30",
    "plant_id": "Green-tek-A14746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "2:30:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "2/8/2024 14:45",
    "plant_id": "Green-tek-A14747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "2:45:00 PM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "2/8/2024 15:00",
    "plant_id": "Green-tek-A14748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "3:00:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/8/2024 15:15",
    "plant_id": "Green-tek-A14749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "3:15:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/8/2024 15:30",
    "plant_id": "Green-tek-A14750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/8/2024 15:45",
    "plant_id": "Green-tek-A14751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "3:45:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/8/2024 16:00",
    "plant_id": "Green-tek-A14752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "2/8/2024 16:15",
    "plant_id": "Green-tek-A14753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "2/8/2024 16:30",
    "plant_id": "Green-tek-A14754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/8/2024 16:45",
    "plant_id": "Green-tek-A14755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/8/2024 17:00",
    "plant_id": "Green-tek-A14756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/8/2024 17:15",
    "plant_id": "Green-tek-A14757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/8/2024 17:30",
    "plant_id": "Green-tek-A14758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/8/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/9/2024 6:00",
    "plant_id": "Green-tek-A14759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/9/2024 6:15",
    "plant_id": "Green-tek-A14760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/9/2024 6:30",
    "plant_id": "Green-tek-A14761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/9/2024 6:45",
    "plant_id": "Green-tek-A14762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/9/2024 7:00",
    "plant_id": "Green-tek-A14763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/9/2024 7:15",
    "plant_id": "Green-tek-A14764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/9/2024 7:30",
    "plant_id": "Green-tek-A14765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/9/2024 7:45",
    "plant_id": "Green-tek-A14766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/9/2024 8:00",
    "plant_id": "Green-tek-A14767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "8:00:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/9/2024 8:15",
    "plant_id": "Green-tek-A14768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "8:15:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "2/9/2024 8:30",
    "plant_id": "Green-tek-A14769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "8:30:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "2/9/2024 8:45",
    "plant_id": "Green-tek-A14770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "8:45:00 AM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "2/9/2024 9:00",
    "plant_id": "Green-tek-A14771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "9:00:00 AM",
    "power": 0.246,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "2/9/2024 9:15",
    "plant_id": "Green-tek-A14772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "9:15:00 AM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "2/9/2024 9:30",
    "plant_id": "Green-tek-A14773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "9:30:00 AM",
    "power": 0.371,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "2/9/2024 9:45",
    "plant_id": "Green-tek-A14774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "9:45:00 AM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "2/9/2024 10:00",
    "plant_id": "Green-tek-A14775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "10:00:00 AM",
    "power": 0.514,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "2/9/2024 10:15",
    "plant_id": "Green-tek-A14776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "10:15:00 AM",
    "power": 0.587,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "2/9/2024 10:30",
    "plant_id": "Green-tek-A14777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "10:30:00 AM",
    "power": 0.657,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "2/9/2024 10:45",
    "plant_id": "Green-tek-A14778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "10:45:00 AM",
    "power": 0.72,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "2/9/2024 11:00",
    "plant_id": "Green-tek-A14779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "11:00:00 AM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "2/9/2024 11:15",
    "plant_id": "Green-tek-A14780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "11:15:00 AM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "2/9/2024 11:30",
    "plant_id": "Green-tek-A14781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "11:30:00 AM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "2/9/2024 11:45",
    "plant_id": "Green-tek-A14782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "11:45:00 AM",
    "power": 0.847,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "2/9/2024 12:00",
    "plant_id": "Green-tek-A14783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "12:00:00 PM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "2/9/2024 12:15",
    "plant_id": "Green-tek-A14784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "12:15:00 PM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "2/9/2024 12:30",
    "plant_id": "Green-tek-A14785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "12:30:00 PM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "2/9/2024 12:45",
    "plant_id": "Green-tek-A14786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "12:45:00 PM",
    "power": 0.72,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "2/9/2024 13:00",
    "plant_id": "Green-tek-A14787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "1:00:00 PM",
    "power": 0.657,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "2/9/2024 13:15",
    "plant_id": "Green-tek-A14788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "1:15:00 PM",
    "power": 0.587,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "2/9/2024 13:30",
    "plant_id": "Green-tek-A14789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "1:30:00 PM",
    "power": 0.514,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "2/9/2024 13:45",
    "plant_id": "Green-tek-A14790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "1:45:00 PM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "2/9/2024 14:00",
    "plant_id": "Green-tek-A14791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "2:00:00 PM",
    "power": 0.371,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "2/9/2024 14:15",
    "plant_id": "Green-tek-A14792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "2:15:00 PM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "2/9/2024 14:30",
    "plant_id": "Green-tek-A14793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "2:30:00 PM",
    "power": 0.246,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "2/9/2024 14:45",
    "plant_id": "Green-tek-A14794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "2:45:00 PM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "2/9/2024 15:00",
    "plant_id": "Green-tek-A14795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "3:00:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "2/9/2024 15:15",
    "plant_id": "Green-tek-A14796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "3:15:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "2/9/2024 15:30",
    "plant_id": "Green-tek-A14797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "3:30:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/9/2024 15:45",
    "plant_id": "Green-tek-A14798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/9/2024 16:00",
    "plant_id": "Green-tek-A14799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/9/2024 16:15",
    "plant_id": "Green-tek-A14800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/9/2024 16:30",
    "plant_id": "Green-tek-A14801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/9/2024 16:45",
    "plant_id": "Green-tek-A14802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/9/2024 17:00",
    "plant_id": "Green-tek-A14803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/9/2024 17:15",
    "plant_id": "Green-tek-A14804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/9/2024 17:30",
    "plant_id": "Green-tek-A14805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/9/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/10/2024 6:00",
    "plant_id": "Green-tek-A14806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/10/2024 6:15",
    "plant_id": "Green-tek-A14807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/10/2024 6:30",
    "plant_id": "Green-tek-A14808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/10/2024 6:45",
    "plant_id": "Green-tek-A14809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/10/2024 7:00",
    "plant_id": "Green-tek-A14810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/10/2024 7:15",
    "plant_id": "Green-tek-A14811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/10/2024 7:30",
    "plant_id": "Green-tek-A14812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/10/2024 7:45",
    "plant_id": "Green-tek-A14813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/10/2024 8:00",
    "plant_id": "Green-tek-A14814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "8:00:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/10/2024 8:15",
    "plant_id": "Green-tek-A14815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "8:15:00 AM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/10/2024 8:30",
    "plant_id": "Green-tek-A14816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "8:30:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "2/10/2024 8:45",
    "plant_id": "Green-tek-A14817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "8:45:00 AM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "2/10/2024 9:00",
    "plant_id": "Green-tek-A14818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "9:00:00 AM",
    "power": 0.244,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "2/10/2024 9:15",
    "plant_id": "Green-tek-A14819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "9:15:00 AM",
    "power": 0.302,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "2/10/2024 9:30",
    "plant_id": "Green-tek-A14820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "9:30:00 AM",
    "power": 0.367,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "2/10/2024 9:45",
    "plant_id": "Green-tek-A14821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "9:45:00 AM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "2/10/2024 10:00",
    "plant_id": "Green-tek-A14822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "10:00:00 AM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "2/10/2024 10:15",
    "plant_id": "Green-tek-A14823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "10:15:00 AM",
    "power": 0.58,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "2/10/2024 10:30",
    "plant_id": "Green-tek-A14824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "10:30:00 AM",
    "power": 0.649,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "2/10/2024 10:45",
    "plant_id": "Green-tek-A14825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "10:45:00 AM",
    "power": 0.712,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "2/10/2024 11:00",
    "plant_id": "Green-tek-A14826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "11:00:00 AM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "2/10/2024 11:15",
    "plant_id": "Green-tek-A14827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "11:15:00 AM",
    "power": 0.805,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "2/10/2024 11:30",
    "plant_id": "Green-tek-A14828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "11:30:00 AM",
    "power": 0.83,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "2/10/2024 11:45",
    "plant_id": "Green-tek-A14829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "11:45:00 AM",
    "power": 0.838,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "2/10/2024 12:00",
    "plant_id": "Green-tek-A14830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "12:00:00 PM",
    "power": 0.83,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "2/10/2024 12:15",
    "plant_id": "Green-tek-A14831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "12:15:00 PM",
    "power": 0.805,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "2/10/2024 12:30",
    "plant_id": "Green-tek-A14832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "12:30:00 PM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "2/10/2024 12:45",
    "plant_id": "Green-tek-A14833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "12:45:00 PM",
    "power": 0.712,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "2/10/2024 13:00",
    "plant_id": "Green-tek-A14834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "1:00:00 PM",
    "power": 0.649,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "2/10/2024 13:15",
    "plant_id": "Green-tek-A14835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "1:15:00 PM",
    "power": 0.58,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "2/10/2024 13:30",
    "plant_id": "Green-tek-A14836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "1:30:00 PM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "2/10/2024 13:45",
    "plant_id": "Green-tek-A14837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "1:45:00 PM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "2/10/2024 14:00",
    "plant_id": "Green-tek-A14838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "2:00:00 PM",
    "power": 0.367,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "2/10/2024 14:15",
    "plant_id": "Green-tek-A14839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "2:15:00 PM",
    "power": 0.302,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "2/10/2024 14:30",
    "plant_id": "Green-tek-A14840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "2:30:00 PM",
    "power": 0.244,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "2/10/2024 14:45",
    "plant_id": "Green-tek-A14841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "2:45:00 PM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "2/10/2024 15:00",
    "plant_id": "Green-tek-A14842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "3:00:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "2/10/2024 15:15",
    "plant_id": "Green-tek-A14843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "3:15:00 PM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/10/2024 15:30",
    "plant_id": "Green-tek-A14844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "3:30:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/10/2024 15:45",
    "plant_id": "Green-tek-A14845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/10/2024 16:00",
    "plant_id": "Green-tek-A14846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/10/2024 16:15",
    "plant_id": "Green-tek-A14847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/10/2024 16:30",
    "plant_id": "Green-tek-A14848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/10/2024 16:45",
    "plant_id": "Green-tek-A14849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/10/2024 17:00",
    "plant_id": "Green-tek-A14850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/10/2024 17:15",
    "plant_id": "Green-tek-A14851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/10/2024 17:30",
    "plant_id": "Green-tek-A14852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/10/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/11/2024 6:00",
    "plant_id": "Green-tek-A14853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/11/2024 6:15",
    "plant_id": "Green-tek-A14854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/11/2024 6:30",
    "plant_id": "Green-tek-A14855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/11/2024 6:45",
    "plant_id": "Green-tek-A14856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/11/2024 7:00",
    "plant_id": "Green-tek-A14857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/11/2024 7:15",
    "plant_id": "Green-tek-A14858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/11/2024 7:30",
    "plant_id": "Green-tek-A14859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/11/2024 7:45",
    "plant_id": "Green-tek-A14860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/11/2024 8:00",
    "plant_id": "Green-tek-A14861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "8:00:00 AM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "2/11/2024 8:15",
    "plant_id": "Green-tek-A14862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "8:15:00 AM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "2/11/2024 8:30",
    "plant_id": "Green-tek-A14863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "8:30:00 AM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "2/11/2024 8:45",
    "plant_id": "Green-tek-A14864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "8:45:00 AM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "2/11/2024 9:00",
    "plant_id": "Green-tek-A14865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "9:00:00 AM",
    "power": 0.334,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "2/11/2024 9:15",
    "plant_id": "Green-tek-A14866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "9:15:00 AM",
    "power": 0.414,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "2/11/2024 9:30",
    "plant_id": "Green-tek-A14867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "9:30:00 AM",
    "power": 0.502,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "2/11/2024 9:45",
    "plant_id": "Green-tek-A14868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "9:45:00 AM",
    "power": 0.597,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "2/11/2024 10:00",
    "plant_id": "Green-tek-A14869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "10:00:00 AM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "2/11/2024 10:15",
    "plant_id": "Green-tek-A14870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "10:15:00 AM",
    "power": 0.795,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "2/11/2024 10:30",
    "plant_id": "Green-tek-A14871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "10:30:00 AM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "2/11/2024 10:45",
    "plant_id": "Green-tek-A14872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "10:45:00 AM",
    "power": 0.975,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "2/11/2024 11:00",
    "plant_id": "Green-tek-A14873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "11:00:00 AM",
    "power": 1.047,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "2/11/2024 11:15",
    "plant_id": "Green-tek-A14874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "11:15:00 AM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "2/11/2024 11:30",
    "plant_id": "Green-tek-A14875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "11:30:00 AM",
    "power": 1.136,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "2/11/2024 11:45",
    "plant_id": "Green-tek-A14876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "11:45:00 AM",
    "power": 1.147,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "2/11/2024 12:00",
    "plant_id": "Green-tek-A14877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "12:00:00 PM",
    "power": 1.136,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "2/11/2024 12:15",
    "plant_id": "Green-tek-A14878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "12:15:00 PM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "2/11/2024 12:30",
    "plant_id": "Green-tek-A14879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "12:30:00 PM",
    "power": 1.047,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "2/11/2024 12:45",
    "plant_id": "Green-tek-A14880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "12:45:00 PM",
    "power": 0.975,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "2/11/2024 13:00",
    "plant_id": "Green-tek-A14881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "1:00:00 PM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "2/11/2024 13:15",
    "plant_id": "Green-tek-A14882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "1:15:00 PM",
    "power": 0.795,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "2/11/2024 13:30",
    "plant_id": "Green-tek-A14883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "1:30:00 PM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "2/11/2024 13:45",
    "plant_id": "Green-tek-A14884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "1:45:00 PM",
    "power": 0.597,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "2/11/2024 14:00",
    "plant_id": "Green-tek-A14885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "2:00:00 PM",
    "power": 0.502,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "2/11/2024 14:15",
    "plant_id": "Green-tek-A14886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "2:15:00 PM",
    "power": 0.414,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "2/11/2024 14:30",
    "plant_id": "Green-tek-A14887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "2:30:00 PM",
    "power": 0.334,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "2/11/2024 14:45",
    "plant_id": "Green-tek-A14888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "2:45:00 PM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "2/11/2024 15:00",
    "plant_id": "Green-tek-A14889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "3:00:00 PM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "2/11/2024 15:15",
    "plant_id": "Green-tek-A14890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "3:15:00 PM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "2/11/2024 15:30",
    "plant_id": "Green-tek-A14891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "3:30:00 PM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "2/11/2024 15:45",
    "plant_id": "Green-tek-A14892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/11/2024 16:00",
    "plant_id": "Green-tek-A14893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/11/2024 16:15",
    "plant_id": "Green-tek-A14894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/11/2024 16:30",
    "plant_id": "Green-tek-A14895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/11/2024 16:45",
    "plant_id": "Green-tek-A14896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/11/2024 17:00",
    "plant_id": "Green-tek-A14897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/11/2024 17:15",
    "plant_id": "Green-tek-A14898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/11/2024 17:30",
    "plant_id": "Green-tek-A14899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/11/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/12/2024 6:00",
    "plant_id": "Green-tek-A14900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/12/2024 6:15",
    "plant_id": "Green-tek-A14901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/12/2024 6:30",
    "plant_id": "Green-tek-A14902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/12/2024 6:45",
    "plant_id": "Green-tek-A14903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/12/2024 7:00",
    "plant_id": "Green-tek-A14904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/12/2024 7:15",
    "plant_id": "Green-tek-A14905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/12/2024 7:30",
    "plant_id": "Green-tek-A14906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/12/2024 7:45",
    "plant_id": "Green-tek-A14907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "7:45:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/12/2024 8:00",
    "plant_id": "Green-tek-A14908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "8:00:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "2/12/2024 8:15",
    "plant_id": "Green-tek-A14909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "8:15:00 AM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "2/12/2024 8:30",
    "plant_id": "Green-tek-A14910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "8:30:00 AM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "2/12/2024 8:45",
    "plant_id": "Green-tek-A14911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "8:45:00 AM",
    "power": 0.272,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "2/12/2024 9:00",
    "plant_id": "Green-tek-A14912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "9:00:00 AM",
    "power": 0.344,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "2/12/2024 9:15",
    "plant_id": "Green-tek-A14913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "9:15:00 AM",
    "power": 0.426,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "2/12/2024 9:30",
    "plant_id": "Green-tek-A14914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "9:30:00 AM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "2/12/2024 9:45",
    "plant_id": "Green-tek-A14915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "9:45:00 AM",
    "power": 0.615,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "2/12/2024 10:00",
    "plant_id": "Green-tek-A14916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "10:00:00 AM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "2/12/2024 10:15",
    "plant_id": "Green-tek-A14917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "10:15:00 AM",
    "power": 0.818,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "2/12/2024 10:30",
    "plant_id": "Green-tek-A14918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "10:30:00 AM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "2/12/2024 10:45",
    "plant_id": "Green-tek-A14919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "10:45:00 AM",
    "power": 1.003,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "2/12/2024 11:00",
    "plant_id": "Green-tek-A14920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "11:00:00 AM",
    "power": 1.077,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "2/12/2024 11:15",
    "plant_id": "Green-tek-A14921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "11:15:00 AM",
    "power": 1.134,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "2/12/2024 11:30",
    "plant_id": "Green-tek-A14922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "11:30:00 AM",
    "power": 1.169,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "2/12/2024 11:45",
    "plant_id": "Green-tek-A14923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "11:45:00 AM",
    "power": 1.181,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "2/12/2024 12:00",
    "plant_id": "Green-tek-A14924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "12:00:00 PM",
    "power": 1.169,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "2/12/2024 12:15",
    "plant_id": "Green-tek-A14925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "12:15:00 PM",
    "power": 1.134,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "2/12/2024 12:30",
    "plant_id": "Green-tek-A14926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "12:30:00 PM",
    "power": 1.077,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "2/12/2024 12:45",
    "plant_id": "Green-tek-A14927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "12:45:00 PM",
    "power": 1.003,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "2/12/2024 13:00",
    "plant_id": "Green-tek-A14928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "1:00:00 PM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "2/12/2024 13:15",
    "plant_id": "Green-tek-A14929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "1:15:00 PM",
    "power": 0.818,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "2/12/2024 13:30",
    "plant_id": "Green-tek-A14930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "1:30:00 PM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "2/12/2024 13:45",
    "plant_id": "Green-tek-A14931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "1:45:00 PM",
    "power": 0.615,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "2/12/2024 14:00",
    "plant_id": "Green-tek-A14932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "2:00:00 PM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "2/12/2024 14:15",
    "plant_id": "Green-tek-A14933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "2:15:00 PM",
    "power": 0.426,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "2/12/2024 14:30",
    "plant_id": "Green-tek-A14934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "2:30:00 PM",
    "power": 0.344,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "2/12/2024 14:45",
    "plant_id": "Green-tek-A14935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "2:45:00 PM",
    "power": 0.272,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "2/12/2024 15:00",
    "plant_id": "Green-tek-A14936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "3:00:00 PM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "2/12/2024 15:15",
    "plant_id": "Green-tek-A14937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "3:15:00 PM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "2/12/2024 15:30",
    "plant_id": "Green-tek-A14938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "3:30:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "2/12/2024 15:45",
    "plant_id": "Green-tek-A14939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "3:45:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/12/2024 16:00",
    "plant_id": "Green-tek-A14940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/12/2024 16:15",
    "plant_id": "Green-tek-A14941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/12/2024 16:30",
    "plant_id": "Green-tek-A14942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/12/2024 16:45",
    "plant_id": "Green-tek-A14943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/12/2024 17:00",
    "plant_id": "Green-tek-A14944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/12/2024 17:15",
    "plant_id": "Green-tek-A14945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/12/2024 17:30",
    "plant_id": "Green-tek-A14946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/12/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/13/2024 6:00",
    "plant_id": "Green-tek-A14947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/13/2024 6:15",
    "plant_id": "Green-tek-A14948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/13/2024 6:30",
    "plant_id": "Green-tek-A14949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "6:30:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/13/2024 6:45",
    "plant_id": "Green-tek-A14950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/13/2024 7:00",
    "plant_id": "Green-tek-A14951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/13/2024 7:15",
    "plant_id": "Green-tek-A14952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "2/13/2024 7:30",
    "plant_id": "Green-tek-A14953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "7:30:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "2/13/2024 7:45",
    "plant_id": "Green-tek-A14954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "7:45:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/13/2024 8:00",
    "plant_id": "Green-tek-A14955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "8:00:00 AM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/13/2024 8:15",
    "plant_id": "Green-tek-A14956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "8:15:00 AM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/13/2024 8:30",
    "plant_id": "Green-tek-A14957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "8:30:00 AM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/13/2024 8:45",
    "plant_id": "Green-tek-A14958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "8:45:00 AM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "2/13/2024 9:00",
    "plant_id": "Green-tek-A14959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "9:00:00 AM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "2/13/2024 9:15",
    "plant_id": "Green-tek-A14960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "9:15:00 AM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "2/13/2024 9:30",
    "plant_id": "Green-tek-A14961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "9:30:00 AM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "2/13/2024 9:45",
    "plant_id": "Green-tek-A14962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "9:45:00 AM",
    "power": 0.681,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/13/2024 10:00",
    "plant_id": "Green-tek-A14963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "10:00:00 AM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/13/2024 10:15",
    "plant_id": "Green-tek-A14964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "10:15:00 AM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "2/13/2024 10:30",
    "plant_id": "Green-tek-A14965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "10:30:00 AM",
    "power": 1.013,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "2/13/2024 10:45",
    "plant_id": "Green-tek-A14966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "10:45:00 AM",
    "power": 1.111,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "2/13/2024 11:00",
    "plant_id": "Green-tek-A14967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "11:00:00 AM",
    "power": 1.193,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.32028,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "2/13/2024 11:15",
    "plant_id": "Green-tek-A14968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "11:15:00 AM",
    "power": 1.255,
    "Energy": 0.314,
    "output Energy": 0.314,
    "Ac output": 1.314,
    "dc output": 1.34028,
    "AC_R_i": 0.00040957,
    "AC_S_i": 0.000450527,
    "AC_T_i": 0.00049558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0942011,
    "AC_S_pow": 0.10362121,
    "AC_T_Pow": 0.1139834,
    "AC_R_output": 0.3942,
    "AC_S_output": 0.43362,
    "AC_T_output": 0.476982,
    "dc_current": 0.00230882,
    "dc_voltage": 136,
    "dc_power": 0.33048,
    "dc_output": 0.32028,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000314,
    "carbon offset": 0.3925,
    "trees planted": 8.722222222,
    "DC_R_output": 0.130086,
    "DC_S_output": 0.1430946,
    "DC_T_output": 0.15740406
  },
  {
    "datetime": "2/13/2024 11:30",
    "plant_id": "Green-tek-A14969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "11:30:00 AM",
    "power": 1.294,
    "Energy": 0.324,
    "output Energy": 0.324,
    "Ac output": 1.324,
    "dc output": 1.35048,
    "AC_R_i": 0.00042261,
    "AC_S_i": 0.000464871,
    "AC_T_i": 0.00051136,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0972003,
    "AC_S_pow": 0.10692033,
    "AC_T_Pow": 0.1176128,
    "AC_R_output": 0.3972,
    "AC_S_output": 0.43692,
    "AC_T_output": 0.480612,
    "dc_current": 0.00238235,
    "dc_voltage": 136,
    "dc_power": 0.33354,
    "dc_output": 0.33048,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000324,
    "carbon offset": 0.405,
    "trees planted": 9,
    "DC_R_output": 0.131076,
    "DC_S_output": 0.1441836,
    "DC_T_output": 0.15860196
  },
  {
    "datetime": "2/13/2024 11:45",
    "plant_id": "Green-tek-A14970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "11:45:00 AM",
    "power": 1.308,
    "Energy": 0.327,
    "output Energy": 0.327,
    "Ac output": 1.327,
    "dc output": 1.35354,
    "AC_R_i": 0.00042652,
    "AC_S_i": 0.000469172,
    "AC_T_i": 0.00051609,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0980996,
    "AC_S_pow": 0.10790956,
    "AC_T_Pow": 0.1187007,
    "AC_R_output": 0.3981,
    "AC_S_output": 0.43791,
    "AC_T_output": 0.481701,
    "dc_current": 0.00240441,
    "dc_voltage": 136,
    "dc_power": 0.33048,
    "dc_output": 0.33354,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000327,
    "carbon offset": 0.40875,
    "trees planted": 9.083333333,
    "DC_R_output": 0.131373,
    "DC_S_output": 0.1445103,
    "DC_T_output": 0.15896133
  },
  {
    "datetime": "2/13/2024 12:00",
    "plant_id": "Green-tek-A14971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "12:00:00 PM",
    "power": 1.294,
    "Energy": 0.324,
    "output Energy": 0.324,
    "Ac output": 1.324,
    "dc output": 1.35048,
    "AC_R_i": 0.00042261,
    "AC_S_i": 0.000464871,
    "AC_T_i": 0.00051136,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0972003,
    "AC_S_pow": 0.10692033,
    "AC_T_Pow": 0.1176128,
    "AC_R_output": 0.3972,
    "AC_S_output": 0.43692,
    "AC_T_output": 0.480612,
    "dc_current": 0.00238235,
    "dc_voltage": 136,
    "dc_power": 0.32028,
    "dc_output": 0.33048,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000324,
    "carbon offset": 0.405,
    "trees planted": 9,
    "DC_R_output": 0.131076,
    "DC_S_output": 0.1441836,
    "DC_T_output": 0.15860196
  },
  {
    "datetime": "2/13/2024 12:15",
    "plant_id": "Green-tek-A14972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "12:15:00 PM",
    "power": 1.255,
    "Energy": 0.314,
    "output Energy": 0.314,
    "Ac output": 1.314,
    "dc output": 1.34028,
    "AC_R_i": 0.00040957,
    "AC_S_i": 0.000450527,
    "AC_T_i": 0.00049558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0942011,
    "AC_S_pow": 0.10362121,
    "AC_T_Pow": 0.1139834,
    "AC_R_output": 0.3942,
    "AC_S_output": 0.43362,
    "AC_T_output": 0.476982,
    "dc_current": 0.00230882,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.32028,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000314,
    "carbon offset": 0.3925,
    "trees planted": 8.722222222,
    "DC_R_output": 0.130086,
    "DC_S_output": 0.1430946,
    "DC_T_output": 0.15740406
  },
  {
    "datetime": "2/13/2024 12:30",
    "plant_id": "Green-tek-A14973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "12:30:00 PM",
    "power": 1.193,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "2/13/2024 12:45",
    "plant_id": "Green-tek-A14974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "12:45:00 PM",
    "power": 1.111,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "2/13/2024 13:00",
    "plant_id": "Green-tek-A14975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "1:00:00 PM",
    "power": 1.013,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "2/13/2024 13:15",
    "plant_id": "Green-tek-A14976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "1:15:00 PM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "2/13/2024 13:30",
    "plant_id": "Green-tek-A14977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "1:30:00 PM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/13/2024 13:45",
    "plant_id": "Green-tek-A14978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "1:45:00 PM",
    "power": 0.681,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/13/2024 14:00",
    "plant_id": "Green-tek-A14979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "2:00:00 PM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "2/13/2024 14:15",
    "plant_id": "Green-tek-A14980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "2:15:00 PM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "2/13/2024 14:30",
    "plant_id": "Green-tek-A14981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "2:30:00 PM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "2/13/2024 14:45",
    "plant_id": "Green-tek-A14982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "2:45:00 PM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "2/13/2024 15:00",
    "plant_id": "Green-tek-A14983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "3:00:00 PM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/13/2024 15:15",
    "plant_id": "Green-tek-A14984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "3:15:00 PM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/13/2024 15:30",
    "plant_id": "Green-tek-A14985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "3:30:00 PM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/13/2024 15:45",
    "plant_id": "Green-tek-A14986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "3:45:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/13/2024 16:00",
    "plant_id": "Green-tek-A14987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "4:00:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "2/13/2024 16:15",
    "plant_id": "Green-tek-A14988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "2/13/2024 16:30",
    "plant_id": "Green-tek-A14989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/13/2024 16:45",
    "plant_id": "Green-tek-A14990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/13/2024 17:00",
    "plant_id": "Green-tek-A14991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "5:00:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/13/2024 17:15",
    "plant_id": "Green-tek-A14992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/13/2024 17:30",
    "plant_id": "Green-tek-A14993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/13/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/14/2024 6:00",
    "plant_id": "Green-tek-A14994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/14/2024 6:15",
    "plant_id": "Green-tek-A14995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/14/2024 6:30",
    "plant_id": "Green-tek-A14996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/14/2024 6:45",
    "plant_id": "Green-tek-A14997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/14/2024 7:00",
    "plant_id": "Green-tek-A14998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/14/2024 7:15",
    "plant_id": "Green-tek-A14999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/14/2024 7:30",
    "plant_id": "Green-tek-A15000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "7:30:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/14/2024 7:45",
    "plant_id": "Green-tek-A15001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/14/2024 8:00",
    "plant_id": "Green-tek-A15002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "8:00:00 AM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "2/14/2024 8:15",
    "plant_id": "Green-tek-A15003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "8:15:00 AM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "2/14/2024 8:30",
    "plant_id": "Green-tek-A15004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "8:30:00 AM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "2/14/2024 8:45",
    "plant_id": "Green-tek-A15005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "8:45:00 AM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "2/14/2024 9:00",
    "plant_id": "Green-tek-A15006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "9:00:00 AM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "2/14/2024 9:15",
    "plant_id": "Green-tek-A15007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "9:15:00 AM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "2/14/2024 9:30",
    "plant_id": "Green-tek-A15008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "9:30:00 AM",
    "power": 0.533,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "2/14/2024 9:45",
    "plant_id": "Green-tek-A15009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "9:45:00 AM",
    "power": 0.634,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "2/14/2024 10:00",
    "plant_id": "Green-tek-A15010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "10:00:00 AM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "2/14/2024 10:15",
    "plant_id": "Green-tek-A15011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "10:15:00 AM",
    "power": 0.844,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "2/14/2024 10:30",
    "plant_id": "Green-tek-A15012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "10:30:00 AM",
    "power": 0.945,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "2/14/2024 10:45",
    "plant_id": "Green-tek-A15013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "10:45:00 AM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "2/14/2024 11:00",
    "plant_id": "Green-tek-A15014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "11:00:00 AM",
    "power": 1.112,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "2/14/2024 11:15",
    "plant_id": "Green-tek-A15015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "11:15:00 AM",
    "power": 1.17,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "2/14/2024 11:30",
    "plant_id": "Green-tek-A15016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "11:30:00 AM",
    "power": 1.207,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "2/14/2024 11:45",
    "plant_id": "Green-tek-A15017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "11:45:00 AM",
    "power": 1.219,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "2/14/2024 12:00",
    "plant_id": "Green-tek-A15018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "12:00:00 PM",
    "power": 1.207,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "2/14/2024 12:15",
    "plant_id": "Green-tek-A15019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "12:15:00 PM",
    "power": 1.17,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "2/14/2024 12:30",
    "plant_id": "Green-tek-A15020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "12:30:00 PM",
    "power": 1.112,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "2/14/2024 12:45",
    "plant_id": "Green-tek-A15021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "12:45:00 PM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "2/14/2024 13:00",
    "plant_id": "Green-tek-A15022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "1:00:00 PM",
    "power": 0.945,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "2/14/2024 13:15",
    "plant_id": "Green-tek-A15023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "1:15:00 PM",
    "power": 0.844,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "2/14/2024 13:30",
    "plant_id": "Green-tek-A15024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "1:30:00 PM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "2/14/2024 13:45",
    "plant_id": "Green-tek-A15025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "1:45:00 PM",
    "power": 0.634,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "2/14/2024 14:00",
    "plant_id": "Green-tek-A15026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "2:00:00 PM",
    "power": 0.533,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "2/14/2024 14:15",
    "plant_id": "Green-tek-A15027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "2:15:00 PM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "2/14/2024 14:30",
    "plant_id": "Green-tek-A15028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "2:30:00 PM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "2/14/2024 14:45",
    "plant_id": "Green-tek-A15029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "2:45:00 PM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "2/14/2024 15:00",
    "plant_id": "Green-tek-A15030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "3:00:00 PM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "2/14/2024 15:15",
    "plant_id": "Green-tek-A15031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "3:15:00 PM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "2/14/2024 15:30",
    "plant_id": "Green-tek-A15032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "3:30:00 PM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "2/14/2024 15:45",
    "plant_id": "Green-tek-A15033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/14/2024 16:00",
    "plant_id": "Green-tek-A15034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "4:00:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/14/2024 16:15",
    "plant_id": "Green-tek-A15035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/14/2024 16:30",
    "plant_id": "Green-tek-A15036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/14/2024 16:45",
    "plant_id": "Green-tek-A15037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/14/2024 17:00",
    "plant_id": "Green-tek-A15038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/14/2024 17:15",
    "plant_id": "Green-tek-A15039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/14/2024 17:30",
    "plant_id": "Green-tek-A15040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/14/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/15/2024 6:00",
    "plant_id": "Green-tek-A15041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/15/2024 6:15",
    "plant_id": "Green-tek-A15042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/15/2024 6:30",
    "plant_id": "Green-tek-A15043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/15/2024 6:45",
    "plant_id": "Green-tek-A15044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/15/2024 7:00",
    "plant_id": "Green-tek-A15045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/15/2024 7:15",
    "plant_id": "Green-tek-A15046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/15/2024 7:30",
    "plant_id": "Green-tek-A15047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/15/2024 7:45",
    "plant_id": "Green-tek-A15048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "7:45:00 AM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/15/2024 8:00",
    "plant_id": "Green-tek-A15049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "8:00:00 AM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "2/15/2024 8:15",
    "plant_id": "Green-tek-A15050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "8:15:00 AM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "2/15/2024 8:30",
    "plant_id": "Green-tek-A15051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "8:30:00 AM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/15/2024 8:45",
    "plant_id": "Green-tek-A15052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "8:45:00 AM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "2/15/2024 9:00",
    "plant_id": "Green-tek-A15053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "9:00:00 AM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/15/2024 9:15",
    "plant_id": "Green-tek-A15054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "9:15:00 AM",
    "power": 0.358,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/15/2024 9:30",
    "plant_id": "Green-tek-A15055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "9:30:00 AM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "2/15/2024 9:45",
    "plant_id": "Green-tek-A15056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "9:45:00 AM",
    "power": 0.518,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "2/15/2024 10:00",
    "plant_id": "Green-tek-A15057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "10:00:00 AM",
    "power": 0.603,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "2/15/2024 10:15",
    "plant_id": "Green-tek-A15058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "10:15:00 AM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "2/15/2024 10:30",
    "plant_id": "Green-tek-A15059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "10:30:00 AM",
    "power": 0.771,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "2/15/2024 10:45",
    "plant_id": "Green-tek-A15060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "10:45:00 AM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "2/15/2024 11:00",
    "plant_id": "Green-tek-A15061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "11:00:00 AM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "2/15/2024 11:15",
    "plant_id": "Green-tek-A15062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "11:15:00 AM",
    "power": 0.955,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "2/15/2024 11:30",
    "plant_id": "Green-tek-A15063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "11:30:00 AM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "2/15/2024 11:45",
    "plant_id": "Green-tek-A15064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "11:45:00 AM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "2/15/2024 12:00",
    "plant_id": "Green-tek-A15065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "12:00:00 PM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "2/15/2024 12:15",
    "plant_id": "Green-tek-A15066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "12:15:00 PM",
    "power": 0.955,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "2/15/2024 12:30",
    "plant_id": "Green-tek-A15067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "12:30:00 PM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "2/15/2024 12:45",
    "plant_id": "Green-tek-A15068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "12:45:00 PM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "2/15/2024 13:00",
    "plant_id": "Green-tek-A15069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "1:00:00 PM",
    "power": 0.771,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "2/15/2024 13:15",
    "plant_id": "Green-tek-A15070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "1:15:00 PM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "2/15/2024 13:30",
    "plant_id": "Green-tek-A15071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "1:30:00 PM",
    "power": 0.603,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "2/15/2024 13:45",
    "plant_id": "Green-tek-A15072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "1:45:00 PM",
    "power": 0.518,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "2/15/2024 14:00",
    "plant_id": "Green-tek-A15073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "2:00:00 PM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "2/15/2024 14:15",
    "plant_id": "Green-tek-A15074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "2:15:00 PM",
    "power": 0.358,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/15/2024 14:30",
    "plant_id": "Green-tek-A15075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "2:30:00 PM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/15/2024 14:45",
    "plant_id": "Green-tek-A15076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "2:45:00 PM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "2/15/2024 15:00",
    "plant_id": "Green-tek-A15077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "3:00:00 PM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/15/2024 15:15",
    "plant_id": "Green-tek-A15078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "3:15:00 PM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "2/15/2024 15:30",
    "plant_id": "Green-tek-A15079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "3:30:00 PM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "2/15/2024 15:45",
    "plant_id": "Green-tek-A15080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "3:45:00 PM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/15/2024 16:00",
    "plant_id": "Green-tek-A15081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/15/2024 16:15",
    "plant_id": "Green-tek-A15082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/15/2024 16:30",
    "plant_id": "Green-tek-A15083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/15/2024 16:45",
    "plant_id": "Green-tek-A15084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/15/2024 17:00",
    "plant_id": "Green-tek-A15085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/15/2024 17:15",
    "plant_id": "Green-tek-A15086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/15/2024 17:30",
    "plant_id": "Green-tek-A15087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/15/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/16/2024 6:00",
    "plant_id": "Green-tek-A15088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/16/2024 6:15",
    "plant_id": "Green-tek-A15089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/16/2024 6:30",
    "plant_id": "Green-tek-A15090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/16/2024 6:45",
    "plant_id": "Green-tek-A15091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/16/2024 7:00",
    "plant_id": "Green-tek-A15092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/16/2024 7:15",
    "plant_id": "Green-tek-A15093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/16/2024 7:30",
    "plant_id": "Green-tek-A15094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/16/2024 7:45",
    "plant_id": "Green-tek-A15095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "7:45:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/16/2024 8:00",
    "plant_id": "Green-tek-A15096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "8:00:00 AM",
    "power": 0.086,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/16/2024 8:15",
    "plant_id": "Green-tek-A15097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "8:15:00 AM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "2/16/2024 8:30",
    "plant_id": "Green-tek-A15098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "8:30:00 AM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "2/16/2024 8:45",
    "plant_id": "Green-tek-A15099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "8:45:00 AM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "2/16/2024 9:00",
    "plant_id": "Green-tek-A15100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "9:00:00 AM",
    "power": 0.249,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "2/16/2024 9:15",
    "plant_id": "Green-tek-A15101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "9:15:00 AM",
    "power": 0.308,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "2/16/2024 9:30",
    "plant_id": "Green-tek-A15102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "9:30:00 AM",
    "power": 0.374,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "2/16/2024 9:45",
    "plant_id": "Green-tek-A15103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "9:45:00 AM",
    "power": 0.445,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "2/16/2024 10:00",
    "plant_id": "Green-tek-A15104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "10:00:00 AM",
    "power": 0.519,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "2/16/2024 10:15",
    "plant_id": "Green-tek-A15105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "10:15:00 AM",
    "power": 0.593,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "2/16/2024 10:30",
    "plant_id": "Green-tek-A15106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "10:30:00 AM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "2/16/2024 10:45",
    "plant_id": "Green-tek-A15107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "10:45:00 AM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "2/16/2024 11:00",
    "plant_id": "Green-tek-A15108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "11:00:00 AM",
    "power": 0.781,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "2/16/2024 11:15",
    "plant_id": "Green-tek-A15109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "11:15:00 AM",
    "power": 0.822,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "2/16/2024 11:30",
    "plant_id": "Green-tek-A15110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "11:30:00 AM",
    "power": 0.847,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "2/16/2024 11:45",
    "plant_id": "Green-tek-A15111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "11:45:00 AM",
    "power": 0.856,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "2/16/2024 12:00",
    "plant_id": "Green-tek-A15112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "12:00:00 PM",
    "power": 0.847,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "2/16/2024 12:15",
    "plant_id": "Green-tek-A15113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "12:15:00 PM",
    "power": 0.822,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "2/16/2024 12:30",
    "plant_id": "Green-tek-A15114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "12:30:00 PM",
    "power": 0.781,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "2/16/2024 12:45",
    "plant_id": "Green-tek-A15115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "12:45:00 PM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "2/16/2024 13:00",
    "plant_id": "Green-tek-A15116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "1:00:00 PM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "2/16/2024 13:15",
    "plant_id": "Green-tek-A15117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "1:15:00 PM",
    "power": 0.593,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "2/16/2024 13:30",
    "plant_id": "Green-tek-A15118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "1:30:00 PM",
    "power": 0.519,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "2/16/2024 13:45",
    "plant_id": "Green-tek-A15119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "1:45:00 PM",
    "power": 0.445,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "2/16/2024 14:00",
    "plant_id": "Green-tek-A15120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "2:00:00 PM",
    "power": 0.374,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "2/16/2024 14:15",
    "plant_id": "Green-tek-A15121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "2:15:00 PM",
    "power": 0.308,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "2/16/2024 14:30",
    "plant_id": "Green-tek-A15122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "2:30:00 PM",
    "power": 0.249,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "2/16/2024 14:45",
    "plant_id": "Green-tek-A15123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "2:45:00 PM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "2/16/2024 15:00",
    "plant_id": "Green-tek-A15124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "3:00:00 PM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "2/16/2024 15:15",
    "plant_id": "Green-tek-A15125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "3:15:00 PM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "2/16/2024 15:30",
    "plant_id": "Green-tek-A15126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "3:30:00 PM",
    "power": 0.086,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/16/2024 15:45",
    "plant_id": "Green-tek-A15127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "3:45:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/16/2024 16:00",
    "plant_id": "Green-tek-A15128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/16/2024 16:15",
    "plant_id": "Green-tek-A15129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/16/2024 16:30",
    "plant_id": "Green-tek-A15130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/16/2024 16:45",
    "plant_id": "Green-tek-A15131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/16/2024 17:00",
    "plant_id": "Green-tek-A15132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/16/2024 17:15",
    "plant_id": "Green-tek-A15133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/16/2024 17:30",
    "plant_id": "Green-tek-A15134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/16/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/17/2024 6:00",
    "plant_id": "Green-tek-A15135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/17/2024 6:15",
    "plant_id": "Green-tek-A15136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/17/2024 6:30",
    "plant_id": "Green-tek-A15137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/17/2024 6:45",
    "plant_id": "Green-tek-A15138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/17/2024 7:00",
    "plant_id": "Green-tek-A15139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/17/2024 7:15",
    "plant_id": "Green-tek-A15140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "7:15:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/17/2024 7:30",
    "plant_id": "Green-tek-A15141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "7:30:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/17/2024 7:45",
    "plant_id": "Green-tek-A15142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "7:45:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/17/2024 8:00",
    "plant_id": "Green-tek-A15143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "8:00:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "2/17/2024 8:15",
    "plant_id": "Green-tek-A15144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "8:15:00 AM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "2/17/2024 8:30",
    "plant_id": "Green-tek-A15145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "8:30:00 AM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "2/17/2024 8:45",
    "plant_id": "Green-tek-A15146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "8:45:00 AM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "2/17/2024 9:00",
    "plant_id": "Green-tek-A15147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "9:00:00 AM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/17/2024 9:15",
    "plant_id": "Green-tek-A15148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "9:15:00 AM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/17/2024 9:30",
    "plant_id": "Green-tek-A15149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "9:30:00 AM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "2/17/2024 9:45",
    "plant_id": "Green-tek-A15150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "9:45:00 AM",
    "power": 0.417,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "2/17/2024 10:00",
    "plant_id": "Green-tek-A15151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "10:00:00 AM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "2/17/2024 10:15",
    "plant_id": "Green-tek-A15152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "10:15:00 AM",
    "power": 0.555,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "2/17/2024 10:30",
    "plant_id": "Green-tek-A15153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "10:30:00 AM",
    "power": 0.621,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "2/17/2024 10:45",
    "plant_id": "Green-tek-A15154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "10:45:00 AM",
    "power": 0.681,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/17/2024 11:00",
    "plant_id": "Green-tek-A15155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "11:00:00 AM",
    "power": 0.731,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "2/17/2024 11:15",
    "plant_id": "Green-tek-A15156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "11:15:00 AM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "2/17/2024 11:30",
    "plant_id": "Green-tek-A15157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "11:30:00 AM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/17/2024 11:45",
    "plant_id": "Green-tek-A15158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "11:45:00 AM",
    "power": 0.802,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "2/17/2024 12:00",
    "plant_id": "Green-tek-A15159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "12:00:00 PM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/17/2024 12:15",
    "plant_id": "Green-tek-A15160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "12:15:00 PM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "2/17/2024 12:30",
    "plant_id": "Green-tek-A15161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "12:30:00 PM",
    "power": 0.731,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "2/17/2024 12:45",
    "plant_id": "Green-tek-A15162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "12:45:00 PM",
    "power": 0.681,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/17/2024 13:00",
    "plant_id": "Green-tek-A15163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "1:00:00 PM",
    "power": 0.621,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "2/17/2024 13:15",
    "plant_id": "Green-tek-A15164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "1:15:00 PM",
    "power": 0.555,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "2/17/2024 13:30",
    "plant_id": "Green-tek-A15165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "1:30:00 PM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "2/17/2024 13:45",
    "plant_id": "Green-tek-A15166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "1:45:00 PM",
    "power": 0.417,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "2/17/2024 14:00",
    "plant_id": "Green-tek-A15167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "2:00:00 PM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "2/17/2024 14:15",
    "plant_id": "Green-tek-A15168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "2:15:00 PM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/17/2024 14:30",
    "plant_id": "Green-tek-A15169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "2:30:00 PM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/17/2024 14:45",
    "plant_id": "Green-tek-A15170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "2:45:00 PM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "2/17/2024 15:00",
    "plant_id": "Green-tek-A15171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "3:00:00 PM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "2/17/2024 15:15",
    "plant_id": "Green-tek-A15172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "3:15:00 PM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "2/17/2024 15:30",
    "plant_id": "Green-tek-A15173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "3:30:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "2/17/2024 15:45",
    "plant_id": "Green-tek-A15174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "3:45:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/17/2024 16:00",
    "plant_id": "Green-tek-A15175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "4:00:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/17/2024 16:15",
    "plant_id": "Green-tek-A15176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "4:15:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/17/2024 16:30",
    "plant_id": "Green-tek-A15177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/17/2024 16:45",
    "plant_id": "Green-tek-A15178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/17/2024 17:00",
    "plant_id": "Green-tek-A15179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/17/2024 17:15",
    "plant_id": "Green-tek-A15180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/17/2024 17:30",
    "plant_id": "Green-tek-A15181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/17/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/18/2024 6:00",
    "plant_id": "Green-tek-A15182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/18/2024 6:15",
    "plant_id": "Green-tek-A15183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/18/2024 6:30",
    "plant_id": "Green-tek-A15184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/18/2024 6:45",
    "plant_id": "Green-tek-A15185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/18/2024 7:00",
    "plant_id": "Green-tek-A15186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/18/2024 7:15",
    "plant_id": "Green-tek-A15187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/18/2024 7:30",
    "plant_id": "Green-tek-A15188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/18/2024 7:45",
    "plant_id": "Green-tek-A15189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/18/2024 8:00",
    "plant_id": "Green-tek-A15190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "8:00:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/18/2024 8:15",
    "plant_id": "Green-tek-A15191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "2/18/2024 8:30",
    "plant_id": "Green-tek-A15192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "8:30:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "2/18/2024 8:45",
    "plant_id": "Green-tek-A15193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "8:45:00 AM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/18/2024 9:00",
    "plant_id": "Green-tek-A15194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "9:00:00 AM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "2/18/2024 9:15",
    "plant_id": "Green-tek-A15195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "9:15:00 AM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "2/18/2024 9:30",
    "plant_id": "Green-tek-A15196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "9:30:00 AM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/18/2024 9:45",
    "plant_id": "Green-tek-A15197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "9:45:00 AM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "2/18/2024 10:00",
    "plant_id": "Green-tek-A15198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "10:00:00 AM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "2/18/2024 10:15",
    "plant_id": "Green-tek-A15199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "10:15:00 AM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "2/18/2024 10:30",
    "plant_id": "Green-tek-A15200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "10:30:00 AM",
    "power": 0.742,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "2/18/2024 10:45",
    "plant_id": "Green-tek-A15201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "10:45:00 AM",
    "power": 0.814,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "2/18/2024 11:00",
    "plant_id": "Green-tek-A15202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "11:00:00 AM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "2/18/2024 11:15",
    "plant_id": "Green-tek-A15203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "11:15:00 AM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "2/18/2024 11:30",
    "plant_id": "Green-tek-A15204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "11:30:00 AM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "2/18/2024 11:45",
    "plant_id": "Green-tek-A15205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "11:45:00 AM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "2/18/2024 12:00",
    "plant_id": "Green-tek-A15206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "12:00:00 PM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "2/18/2024 12:15",
    "plant_id": "Green-tek-A15207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "12:15:00 PM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "2/18/2024 12:30",
    "plant_id": "Green-tek-A15208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "12:30:00 PM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "2/18/2024 12:45",
    "plant_id": "Green-tek-A15209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "12:45:00 PM",
    "power": 0.814,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "2/18/2024 13:00",
    "plant_id": "Green-tek-A15210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "1:00:00 PM",
    "power": 0.742,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "2/18/2024 13:15",
    "plant_id": "Green-tek-A15211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "1:15:00 PM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "2/18/2024 13:30",
    "plant_id": "Green-tek-A15212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "1:30:00 PM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "2/18/2024 13:45",
    "plant_id": "Green-tek-A15213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "1:45:00 PM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "2/18/2024 14:00",
    "plant_id": "Green-tek-A15214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "2:00:00 PM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/18/2024 14:15",
    "plant_id": "Green-tek-A15215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "2:15:00 PM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "2/18/2024 14:30",
    "plant_id": "Green-tek-A15216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "2:30:00 PM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "2/18/2024 14:45",
    "plant_id": "Green-tek-A15217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "2:45:00 PM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/18/2024 15:00",
    "plant_id": "Green-tek-A15218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "3:00:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "2/18/2024 15:15",
    "plant_id": "Green-tek-A15219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "2/18/2024 15:30",
    "plant_id": "Green-tek-A15220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "3:30:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/18/2024 15:45",
    "plant_id": "Green-tek-A15221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/18/2024 16:00",
    "plant_id": "Green-tek-A15222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/18/2024 16:15",
    "plant_id": "Green-tek-A15223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/18/2024 16:30",
    "plant_id": "Green-tek-A15224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/18/2024 16:45",
    "plant_id": "Green-tek-A15225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/18/2024 17:00",
    "plant_id": "Green-tek-A15226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/18/2024 17:15",
    "plant_id": "Green-tek-A15227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/18/2024 17:30",
    "plant_id": "Green-tek-A15228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/18/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/19/2024 6:00",
    "plant_id": "Green-tek-A15229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/19/2024 6:15",
    "plant_id": "Green-tek-A15230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/19/2024 6:30",
    "plant_id": "Green-tek-A15231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/19/2024 6:45",
    "plant_id": "Green-tek-A15232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/19/2024 7:00",
    "plant_id": "Green-tek-A15233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/19/2024 7:15",
    "plant_id": "Green-tek-A15234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/19/2024 7:30",
    "plant_id": "Green-tek-A15235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/19/2024 7:45",
    "plant_id": "Green-tek-A15236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/19/2024 8:00",
    "plant_id": "Green-tek-A15237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "8:00:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/19/2024 8:15",
    "plant_id": "Green-tek-A15238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "2/19/2024 8:30",
    "plant_id": "Green-tek-A15239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "8:30:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "2/19/2024 8:45",
    "plant_id": "Green-tek-A15240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "8:45:00 AM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/19/2024 9:00",
    "plant_id": "Green-tek-A15241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "9:00:00 AM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "2/19/2024 9:15",
    "plant_id": "Green-tek-A15242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "9:15:00 AM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "2/19/2024 9:30",
    "plant_id": "Green-tek-A15243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "9:30:00 AM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/19/2024 9:45",
    "plant_id": "Green-tek-A15244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "9:45:00 AM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "2/19/2024 10:00",
    "plant_id": "Green-tek-A15245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "10:00:00 AM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "2/19/2024 10:15",
    "plant_id": "Green-tek-A15246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "10:15:00 AM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "2/19/2024 10:30",
    "plant_id": "Green-tek-A15247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "10:30:00 AM",
    "power": 0.742,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "2/19/2024 10:45",
    "plant_id": "Green-tek-A15248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "10:45:00 AM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "2/19/2024 11:00",
    "plant_id": "Green-tek-A15249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "11:00:00 AM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "2/19/2024 11:15",
    "plant_id": "Green-tek-A15250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "11:15:00 AM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "2/19/2024 11:30",
    "plant_id": "Green-tek-A15251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "11:30:00 AM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "2/19/2024 11:45",
    "plant_id": "Green-tek-A15252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "11:45:00 AM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "2/19/2024 12:00",
    "plant_id": "Green-tek-A15253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "12:00:00 PM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "2/19/2024 12:15",
    "plant_id": "Green-tek-A15254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "12:15:00 PM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "2/19/2024 12:30",
    "plant_id": "Green-tek-A15255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "12:30:00 PM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "2/19/2024 12:45",
    "plant_id": "Green-tek-A15256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "12:45:00 PM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "2/19/2024 13:00",
    "plant_id": "Green-tek-A15257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "1:00:00 PM",
    "power": 0.742,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "2/19/2024 13:15",
    "plant_id": "Green-tek-A15258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "1:15:00 PM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "2/19/2024 13:30",
    "plant_id": "Green-tek-A15259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "1:30:00 PM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "2/19/2024 13:45",
    "plant_id": "Green-tek-A15260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "1:45:00 PM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "2/19/2024 14:00",
    "plant_id": "Green-tek-A15261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "2:00:00 PM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/19/2024 14:15",
    "plant_id": "Green-tek-A15262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "2:15:00 PM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "2/19/2024 14:30",
    "plant_id": "Green-tek-A15263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "2:30:00 PM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "2/19/2024 14:45",
    "plant_id": "Green-tek-A15264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "2:45:00 PM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/19/2024 15:00",
    "plant_id": "Green-tek-A15265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "3:00:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "2/19/2024 15:15",
    "plant_id": "Green-tek-A15266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "2/19/2024 15:30",
    "plant_id": "Green-tek-A15267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "3:30:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/19/2024 15:45",
    "plant_id": "Green-tek-A15268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/19/2024 16:00",
    "plant_id": "Green-tek-A15269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/19/2024 16:15",
    "plant_id": "Green-tek-A15270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/19/2024 16:30",
    "plant_id": "Green-tek-A15271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/19/2024 16:45",
    "plant_id": "Green-tek-A15272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/19/2024 17:00",
    "plant_id": "Green-tek-A15273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/19/2024 17:15",
    "plant_id": "Green-tek-A15274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/19/2024 17:30",
    "plant_id": "Green-tek-A15275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/19/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/20/2024 6:00",
    "plant_id": "Green-tek-A15276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/20/2024 6:15",
    "plant_id": "Green-tek-A15277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/20/2024 6:30",
    "plant_id": "Green-tek-A15278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/20/2024 6:45",
    "plant_id": "Green-tek-A15279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/20/2024 7:00",
    "plant_id": "Green-tek-A15280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/20/2024 7:15",
    "plant_id": "Green-tek-A15281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/20/2024 7:30",
    "plant_id": "Green-tek-A15282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/20/2024 7:45",
    "plant_id": "Green-tek-A15283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "7:45:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "2/20/2024 8:00",
    "plant_id": "Green-tek-A15284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "8:00:00 AM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "2/20/2024 8:15",
    "plant_id": "Green-tek-A15285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "8:15:00 AM",
    "power": 0.17,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "2/20/2024 8:30",
    "plant_id": "Green-tek-A15286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "8:30:00 AM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "2/20/2024 8:45",
    "plant_id": "Green-tek-A15287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "8:45:00 AM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/20/2024 9:00",
    "plant_id": "Green-tek-A15288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "9:00:00 AM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "2/20/2024 9:15",
    "plant_id": "Green-tek-A15289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "9:15:00 AM",
    "power": 0.452,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "2/20/2024 9:30",
    "plant_id": "Green-tek-A15290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "9:30:00 AM",
    "power": 0.549,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "2/20/2024 9:45",
    "plant_id": "Green-tek-A15291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "9:45:00 AM",
    "power": 0.652,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "2/20/2024 10:00",
    "plant_id": "Green-tek-A15292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "10:00:00 AM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "2/20/2024 10:15",
    "plant_id": "Green-tek-A15293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "10:15:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "2/20/2024 10:30",
    "plant_id": "Green-tek-A15294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "10:30:00 AM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "2/20/2024 10:45",
    "plant_id": "Green-tek-A15295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "10:45:00 AM",
    "power": 1.065,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "2/20/2024 11:00",
    "plant_id": "Green-tek-A15296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "11:00:00 AM",
    "power": 1.144,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "2/20/2024 11:15",
    "plant_id": "Green-tek-A15297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "11:15:00 AM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "2/20/2024 11:30",
    "plant_id": "Green-tek-A15298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "11:30:00 AM",
    "power": 1.241,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "2/20/2024 11:45",
    "plant_id": "Green-tek-A15299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "11:45:00 AM",
    "power": 1.254,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "2/20/2024 12:00",
    "plant_id": "Green-tek-A15300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "12:00:00 PM",
    "power": 1.241,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "2/20/2024 12:15",
    "plant_id": "Green-tek-A15301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "12:15:00 PM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "2/20/2024 12:30",
    "plant_id": "Green-tek-A15302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "12:30:00 PM",
    "power": 1.144,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "2/20/2024 12:45",
    "plant_id": "Green-tek-A15303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "12:45:00 PM",
    "power": 1.065,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "2/20/2024 13:00",
    "plant_id": "Green-tek-A15304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "1:00:00 PM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "2/20/2024 13:15",
    "plant_id": "Green-tek-A15305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "1:15:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "2/20/2024 13:30",
    "plant_id": "Green-tek-A15306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "1:30:00 PM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "2/20/2024 13:45",
    "plant_id": "Green-tek-A15307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "1:45:00 PM",
    "power": 0.652,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "2/20/2024 14:00",
    "plant_id": "Green-tek-A15308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "2:00:00 PM",
    "power": 0.549,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "2/20/2024 14:15",
    "plant_id": "Green-tek-A15309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "2:15:00 PM",
    "power": 0.452,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "2/20/2024 14:30",
    "plant_id": "Green-tek-A15310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "2:30:00 PM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "2/20/2024 14:45",
    "plant_id": "Green-tek-A15311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "2:45:00 PM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "2/20/2024 15:00",
    "plant_id": "Green-tek-A15312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "3:00:00 PM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "2/20/2024 15:15",
    "plant_id": "Green-tek-A15313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "3:15:00 PM",
    "power": 0.17,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "2/20/2024 15:30",
    "plant_id": "Green-tek-A15314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "3:30:00 PM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "2/20/2024 15:45",
    "plant_id": "Green-tek-A15315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "3:45:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "2/20/2024 16:00",
    "plant_id": "Green-tek-A15316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/20/2024 16:15",
    "plant_id": "Green-tek-A15317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/20/2024 16:30",
    "plant_id": "Green-tek-A15318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/20/2024 16:45",
    "plant_id": "Green-tek-A15319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/20/2024 17:00",
    "plant_id": "Green-tek-A15320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/20/2024 17:15",
    "plant_id": "Green-tek-A15321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/20/2024 17:30",
    "plant_id": "Green-tek-A15322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/20/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/21/2024 6:00",
    "plant_id": "Green-tek-A15323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/21/2024 6:15",
    "plant_id": "Green-tek-A15324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/21/2024 6:30",
    "plant_id": "Green-tek-A15325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/21/2024 6:45",
    "plant_id": "Green-tek-A15326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/21/2024 7:00",
    "plant_id": "Green-tek-A15327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/21/2024 7:15",
    "plant_id": "Green-tek-A15328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/21/2024 7:30",
    "plant_id": "Green-tek-A15329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "2/21/2024 7:45",
    "plant_id": "Green-tek-A15330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "2/21/2024 8:00",
    "plant_id": "Green-tek-A15331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "8:00:00 AM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "2/21/2024 8:15",
    "plant_id": "Green-tek-A15332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "8:15:00 AM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "2/21/2024 8:30",
    "plant_id": "Green-tek-A15333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "8:30:00 AM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "2/21/2024 8:45",
    "plant_id": "Green-tek-A15334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "8:45:00 AM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "2/21/2024 9:00",
    "plant_id": "Green-tek-A15335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "9:00:00 AM",
    "power": 0.306,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "2/21/2024 9:15",
    "plant_id": "Green-tek-A15336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "9:15:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "2/21/2024 9:30",
    "plant_id": "Green-tek-A15337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "9:30:00 AM",
    "power": 0.46,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "2/21/2024 9:45",
    "plant_id": "Green-tek-A15338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "9:45:00 AM",
    "power": 0.547,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "2/21/2024 10:00",
    "plant_id": "Green-tek-A15339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "10:00:00 AM",
    "power": 0.638,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "2/21/2024 10:15",
    "plant_id": "Green-tek-A15340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "10:15:00 AM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "2/21/2024 10:30",
    "plant_id": "Green-tek-A15341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "10:30:00 AM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "2/21/2024 10:45",
    "plant_id": "Green-tek-A15342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "10:45:00 AM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "2/21/2024 11:00",
    "plant_id": "Green-tek-A15343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "11:00:00 AM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "2/21/2024 11:15",
    "plant_id": "Green-tek-A15344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "11:15:00 AM",
    "power": 1.009,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/21/2024 11:30",
    "plant_id": "Green-tek-A15345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "11:30:00 AM",
    "power": 1.041,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "2/21/2024 11:45",
    "plant_id": "Green-tek-A15346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "11:45:00 AM",
    "power": 1.051,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "2/21/2024 12:00",
    "plant_id": "Green-tek-A15347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "12:00:00 PM",
    "power": 1.041,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "2/21/2024 12:15",
    "plant_id": "Green-tek-A15348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "12:15:00 PM",
    "power": 1.009,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/21/2024 12:30",
    "plant_id": "Green-tek-A15349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "12:30:00 PM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "2/21/2024 12:45",
    "plant_id": "Green-tek-A15350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "12:45:00 PM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "2/21/2024 13:00",
    "plant_id": "Green-tek-A15351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "1:00:00 PM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "2/21/2024 13:15",
    "plant_id": "Green-tek-A15352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "1:15:00 PM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "2/21/2024 13:30",
    "plant_id": "Green-tek-A15353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "1:30:00 PM",
    "power": 0.638,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "2/21/2024 13:45",
    "plant_id": "Green-tek-A15354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "1:45:00 PM",
    "power": 0.547,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "2/21/2024 14:00",
    "plant_id": "Green-tek-A15355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "2:00:00 PM",
    "power": 0.46,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "2/21/2024 14:15",
    "plant_id": "Green-tek-A15356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "2:15:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "2/21/2024 14:30",
    "plant_id": "Green-tek-A15357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "2:30:00 PM",
    "power": 0.306,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "2/21/2024 14:45",
    "plant_id": "Green-tek-A15358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "2:45:00 PM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "2/21/2024 15:00",
    "plant_id": "Green-tek-A15359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "3:00:00 PM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "2/21/2024 15:15",
    "plant_id": "Green-tek-A15360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "3:15:00 PM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "2/21/2024 15:30",
    "plant_id": "Green-tek-A15361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "3:30:00 PM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "2/21/2024 15:45",
    "plant_id": "Green-tek-A15362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "2/21/2024 16:00",
    "plant_id": "Green-tek-A15363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "2/21/2024 16:15",
    "plant_id": "Green-tek-A15364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/21/2024 16:30",
    "plant_id": "Green-tek-A15365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/21/2024 16:45",
    "plant_id": "Green-tek-A15366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/21/2024 17:00",
    "plant_id": "Green-tek-A15367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/21/2024 17:15",
    "plant_id": "Green-tek-A15368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/21/2024 17:30",
    "plant_id": "Green-tek-A15369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/21/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/22/2024 6:00",
    "plant_id": "Green-tek-A15370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/22/2024 6:15",
    "plant_id": "Green-tek-A15371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/22/2024 6:30",
    "plant_id": "Green-tek-A15372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/22/2024 6:45",
    "plant_id": "Green-tek-A15373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/22/2024 7:00",
    "plant_id": "Green-tek-A15374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/22/2024 7:15",
    "plant_id": "Green-tek-A15375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/22/2024 7:30",
    "plant_id": "Green-tek-A15376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/22/2024 7:45",
    "plant_id": "Green-tek-A15377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "7:45:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/22/2024 8:00",
    "plant_id": "Green-tek-A15378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "8:00:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/22/2024 8:15",
    "plant_id": "Green-tek-A15379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "8:15:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/22/2024 8:30",
    "plant_id": "Green-tek-A15380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "8:30:00 AM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "2/22/2024 8:45",
    "plant_id": "Green-tek-A15381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "8:45:00 AM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "2/22/2024 9:00",
    "plant_id": "Green-tek-A15382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "9:00:00 AM",
    "power": 0.24,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "2/22/2024 9:15",
    "plant_id": "Green-tek-A15383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "9:15:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "2/22/2024 9:30",
    "plant_id": "Green-tek-A15384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "9:30:00 AM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/22/2024 9:45",
    "plant_id": "Green-tek-A15385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "9:45:00 AM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "2/22/2024 10:00",
    "plant_id": "Green-tek-A15386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "10:00:00 AM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "2/22/2024 10:15",
    "plant_id": "Green-tek-A15387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "10:15:00 AM",
    "power": 0.57,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "2/22/2024 10:30",
    "plant_id": "Green-tek-A15388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "10:30:00 AM",
    "power": 0.638,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "2/22/2024 10:45",
    "plant_id": "Green-tek-A15389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "10:45:00 AM",
    "power": 0.699,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "2/22/2024 11:00",
    "plant_id": "Green-tek-A15390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "11:00:00 AM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "2/22/2024 11:15",
    "plant_id": "Green-tek-A15391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "11:15:00 AM",
    "power": 0.79,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/22/2024 11:30",
    "plant_id": "Green-tek-A15392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "11:30:00 AM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "2/22/2024 11:45",
    "plant_id": "Green-tek-A15393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "11:45:00 AM",
    "power": 0.823,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "2/22/2024 12:00",
    "plant_id": "Green-tek-A15394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "12:00:00 PM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "2/22/2024 12:15",
    "plant_id": "Green-tek-A15395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "12:15:00 PM",
    "power": 0.79,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/22/2024 12:30",
    "plant_id": "Green-tek-A15396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "12:30:00 PM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "2/22/2024 12:45",
    "plant_id": "Green-tek-A15397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "12:45:00 PM",
    "power": 0.699,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "2/22/2024 13:00",
    "plant_id": "Green-tek-A15398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "1:00:00 PM",
    "power": 0.638,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "2/22/2024 13:15",
    "plant_id": "Green-tek-A15399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "1:15:00 PM",
    "power": 0.57,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "2/22/2024 13:30",
    "plant_id": "Green-tek-A15400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "1:30:00 PM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "2/22/2024 13:45",
    "plant_id": "Green-tek-A15401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "1:45:00 PM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "2/22/2024 14:00",
    "plant_id": "Green-tek-A15402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "2:00:00 PM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/22/2024 14:15",
    "plant_id": "Green-tek-A15403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "2:15:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "2/22/2024 14:30",
    "plant_id": "Green-tek-A15404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "2:30:00 PM",
    "power": 0.24,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "2/22/2024 14:45",
    "plant_id": "Green-tek-A15405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "2:45:00 PM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "2/22/2024 15:00",
    "plant_id": "Green-tek-A15406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "3:00:00 PM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "2/22/2024 15:15",
    "plant_id": "Green-tek-A15407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "3:15:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/22/2024 15:30",
    "plant_id": "Green-tek-A15408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "3:30:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/22/2024 15:45",
    "plant_id": "Green-tek-A15409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "3:45:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/22/2024 16:00",
    "plant_id": "Green-tek-A15410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/22/2024 16:15",
    "plant_id": "Green-tek-A15411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/22/2024 16:30",
    "plant_id": "Green-tek-A15412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/22/2024 16:45",
    "plant_id": "Green-tek-A15413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/22/2024 17:00",
    "plant_id": "Green-tek-A15414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/22/2024 17:15",
    "plant_id": "Green-tek-A15415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/22/2024 17:30",
    "plant_id": "Green-tek-A15416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/22/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/23/2024 6:00",
    "plant_id": "Green-tek-A15417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/23/2024 6:15",
    "plant_id": "Green-tek-A15418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/23/2024 6:30",
    "plant_id": "Green-tek-A15419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/23/2024 6:45",
    "plant_id": "Green-tek-A15420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/23/2024 7:00",
    "plant_id": "Green-tek-A15421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/23/2024 7:15",
    "plant_id": "Green-tek-A15422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/23/2024 7:30",
    "plant_id": "Green-tek-A15423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/23/2024 7:45",
    "plant_id": "Green-tek-A15424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "7:45:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/23/2024 8:00",
    "plant_id": "Green-tek-A15425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "8:00:00 AM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/23/2024 8:15",
    "plant_id": "Green-tek-A15426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "8:15:00 AM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "2/23/2024 8:30",
    "plant_id": "Green-tek-A15427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "8:30:00 AM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "2/23/2024 8:45",
    "plant_id": "Green-tek-A15428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "8:45:00 AM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "2/23/2024 9:00",
    "plant_id": "Green-tek-A15429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "9:00:00 AM",
    "power": 0.328,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "2/23/2024 9:15",
    "plant_id": "Green-tek-A15430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "9:15:00 AM",
    "power": 0.406,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "2/23/2024 9:30",
    "plant_id": "Green-tek-A15431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "9:30:00 AM",
    "power": 0.493,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "2/23/2024 9:45",
    "plant_id": "Green-tek-A15432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "9:45:00 AM",
    "power": 0.586,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "2/23/2024 10:00",
    "plant_id": "Green-tek-A15433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "10:00:00 AM",
    "power": 0.683,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "2/23/2024 10:15",
    "plant_id": "Green-tek-A15434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "10:15:00 AM",
    "power": 0.78,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "2/23/2024 10:30",
    "plant_id": "Green-tek-A15435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "10:30:00 AM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "2/23/2024 10:45",
    "plant_id": "Green-tek-A15436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "10:45:00 AM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "2/23/2024 11:00",
    "plant_id": "Green-tek-A15437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "11:00:00 AM",
    "power": 1.027,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "2/23/2024 11:15",
    "plant_id": "Green-tek-A15438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "11:15:00 AM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "2/23/2024 11:30",
    "plant_id": "Green-tek-A15439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "11:30:00 AM",
    "power": 1.115,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "2/23/2024 11:45",
    "plant_id": "Green-tek-A15440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "11:45:00 AM",
    "power": 1.126,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "2/23/2024 12:00",
    "plant_id": "Green-tek-A15441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "12:00:00 PM",
    "power": 1.115,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "2/23/2024 12:15",
    "plant_id": "Green-tek-A15442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "12:15:00 PM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "2/23/2024 12:30",
    "plant_id": "Green-tek-A15443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "12:30:00 PM",
    "power": 1.027,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "2/23/2024 12:45",
    "plant_id": "Green-tek-A15444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "12:45:00 PM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "2/23/2024 13:00",
    "plant_id": "Green-tek-A15445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "1:00:00 PM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "2/23/2024 13:15",
    "plant_id": "Green-tek-A15446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "1:15:00 PM",
    "power": 0.78,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "2/23/2024 13:30",
    "plant_id": "Green-tek-A15447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "1:30:00 PM",
    "power": 0.683,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "2/23/2024 13:45",
    "plant_id": "Green-tek-A15448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "1:45:00 PM",
    "power": 0.586,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "2/23/2024 14:00",
    "plant_id": "Green-tek-A15449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "2:00:00 PM",
    "power": 0.493,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "2/23/2024 14:15",
    "plant_id": "Green-tek-A15450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "2:15:00 PM",
    "power": 0.406,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "2/23/2024 14:30",
    "plant_id": "Green-tek-A15451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "2:30:00 PM",
    "power": 0.328,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "2/23/2024 14:45",
    "plant_id": "Green-tek-A15452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "2:45:00 PM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "2/23/2024 15:00",
    "plant_id": "Green-tek-A15453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "3:00:00 PM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "2/23/2024 15:15",
    "plant_id": "Green-tek-A15454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "3:15:00 PM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "2/23/2024 15:30",
    "plant_id": "Green-tek-A15455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "3:30:00 PM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/23/2024 15:45",
    "plant_id": "Green-tek-A15456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "3:45:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "2/23/2024 16:00",
    "plant_id": "Green-tek-A15457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/23/2024 16:15",
    "plant_id": "Green-tek-A15458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/23/2024 16:30",
    "plant_id": "Green-tek-A15459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/23/2024 16:45",
    "plant_id": "Green-tek-A15460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/23/2024 17:00",
    "plant_id": "Green-tek-A15461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/23/2024 17:15",
    "plant_id": "Green-tek-A15462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/23/2024 17:30",
    "plant_id": "Green-tek-A15463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/23/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/24/2024 6:00",
    "plant_id": "Green-tek-A15464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/24/2024 6:15",
    "plant_id": "Green-tek-A15465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/24/2024 6:30",
    "plant_id": "Green-tek-A15466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/24/2024 6:45",
    "plant_id": "Green-tek-A15467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/24/2024 7:00",
    "plant_id": "Green-tek-A15468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/24/2024 7:15",
    "plant_id": "Green-tek-A15469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/24/2024 7:30",
    "plant_id": "Green-tek-A15470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "2/24/2024 7:45",
    "plant_id": "Green-tek-A15471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "2/24/2024 8:00",
    "plant_id": "Green-tek-A15472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "8:00:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/24/2024 8:15",
    "plant_id": "Green-tek-A15473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "8:15:00 AM",
    "power": 0.15,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "2/24/2024 8:30",
    "plant_id": "Green-tek-A15474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "8:30:00 AM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "2/24/2024 8:45",
    "plant_id": "Green-tek-A15475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "8:45:00 AM",
    "power": 0.254,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "2/24/2024 9:00",
    "plant_id": "Green-tek-A15476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "9:00:00 AM",
    "power": 0.322,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "2/24/2024 9:15",
    "plant_id": "Green-tek-A15477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "9:15:00 AM",
    "power": 0.398,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "2/24/2024 9:30",
    "plant_id": "Green-tek-A15478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "9:30:00 AM",
    "power": 0.484,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "2/24/2024 9:45",
    "plant_id": "Green-tek-A15479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "9:45:00 AM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "2/24/2024 10:00",
    "plant_id": "Green-tek-A15480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "10:00:00 AM",
    "power": 0.67,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "2/24/2024 10:15",
    "plant_id": "Green-tek-A15481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "10:15:00 AM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "2/24/2024 10:30",
    "plant_id": "Green-tek-A15482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "10:30:00 AM",
    "power": 0.856,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "2/24/2024 10:45",
    "plant_id": "Green-tek-A15483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "10:45:00 AM",
    "power": 0.939,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "2/24/2024 11:00",
    "plant_id": "Green-tek-A15484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "11:00:00 AM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/24/2024 11:15",
    "plant_id": "Green-tek-A15485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "11:15:00 AM",
    "power": 1.061,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "2/24/2024 11:30",
    "plant_id": "Green-tek-A15486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "11:30:00 AM",
    "power": 1.094,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "2/24/2024 11:45",
    "plant_id": "Green-tek-A15487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "11:45:00 AM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "2/24/2024 12:00",
    "plant_id": "Green-tek-A15488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "12:00:00 PM",
    "power": 1.094,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "2/24/2024 12:15",
    "plant_id": "Green-tek-A15489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "12:15:00 PM",
    "power": 1.061,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "2/24/2024 12:30",
    "plant_id": "Green-tek-A15490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "12:30:00 PM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "2/24/2024 12:45",
    "plant_id": "Green-tek-A15491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "12:45:00 PM",
    "power": 0.939,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "2/24/2024 13:00",
    "plant_id": "Green-tek-A15492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "1:00:00 PM",
    "power": 0.856,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "2/24/2024 13:15",
    "plant_id": "Green-tek-A15493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "1:15:00 PM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "2/24/2024 13:30",
    "plant_id": "Green-tek-A15494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "1:30:00 PM",
    "power": 0.67,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "2/24/2024 13:45",
    "plant_id": "Green-tek-A15495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "1:45:00 PM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "2/24/2024 14:00",
    "plant_id": "Green-tek-A15496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "2:00:00 PM",
    "power": 0.484,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "2/24/2024 14:15",
    "plant_id": "Green-tek-A15497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "2:15:00 PM",
    "power": 0.398,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "2/24/2024 14:30",
    "plant_id": "Green-tek-A15498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "2:30:00 PM",
    "power": 0.322,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "2/24/2024 14:45",
    "plant_id": "Green-tek-A15499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "2:45:00 PM",
    "power": 0.254,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "2/24/2024 15:00",
    "plant_id": "Green-tek-A15500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "3:00:00 PM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "2/24/2024 15:15",
    "plant_id": "Green-tek-A15501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "3:15:00 PM",
    "power": 0.15,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "2/24/2024 15:30",
    "plant_id": "Green-tek-A15502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "3:30:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "2/24/2024 15:45",
    "plant_id": "Green-tek-A15503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "2/24/2024 16:00",
    "plant_id": "Green-tek-A15504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "2/24/2024 16:15",
    "plant_id": "Green-tek-A15505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "2/24/2024 16:30",
    "plant_id": "Green-tek-A15506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/24/2024 16:45",
    "plant_id": "Green-tek-A15507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/24/2024 17:00",
    "plant_id": "Green-tek-A15508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/24/2024 17:15",
    "plant_id": "Green-tek-A15509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/24/2024 17:30",
    "plant_id": "Green-tek-A15510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/24/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/25/2024 6:00",
    "plant_id": "Green-tek-A15511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/25/2024 6:15",
    "plant_id": "Green-tek-A15512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/25/2024 6:30",
    "plant_id": "Green-tek-A15513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/25/2024 6:45",
    "plant_id": "Green-tek-A15514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/25/2024 7:00",
    "plant_id": "Green-tek-A15515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/25/2024 7:15",
    "plant_id": "Green-tek-A15516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/25/2024 7:30",
    "plant_id": "Green-tek-A15517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/25/2024 7:45",
    "plant_id": "Green-tek-A15518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/25/2024 8:00",
    "plant_id": "Green-tek-A15519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "8:00:00 AM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "2/25/2024 8:15",
    "plant_id": "Green-tek-A15520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "8:15:00 AM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "2/25/2024 8:30",
    "plant_id": "Green-tek-A15521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "8:30:00 AM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "2/25/2024 8:45",
    "plant_id": "Green-tek-A15522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "8:45:00 AM",
    "power": 0.278,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "2/25/2024 9:00",
    "plant_id": "Green-tek-A15523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "9:00:00 AM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "2/25/2024 9:15",
    "plant_id": "Green-tek-A15524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "9:15:00 AM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "2/25/2024 9:30",
    "plant_id": "Green-tek-A15525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "9:30:00 AM",
    "power": 0.528,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "2/25/2024 9:45",
    "plant_id": "Green-tek-A15526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "9:45:00 AM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "2/25/2024 10:00",
    "plant_id": "Green-tek-A15527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "10:00:00 AM",
    "power": 0.732,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "2/25/2024 10:15",
    "plant_id": "Green-tek-A15528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "10:15:00 AM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "2/25/2024 10:30",
    "plant_id": "Green-tek-A15529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "10:30:00 AM",
    "power": 0.935,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "2/25/2024 10:45",
    "plant_id": "Green-tek-A15530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "10:45:00 AM",
    "power": 1.025,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "2/25/2024 11:00",
    "plant_id": "Green-tek-A15531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "11:00:00 AM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "2/25/2024 11:15",
    "plant_id": "Green-tek-A15532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "11:15:00 AM",
    "power": 1.159,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "2/25/2024 11:30",
    "plant_id": "Green-tek-A15533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "11:30:00 AM",
    "power": 1.195,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "2/25/2024 11:45",
    "plant_id": "Green-tek-A15534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "11:45:00 AM",
    "power": 1.207,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "2/25/2024 12:00",
    "plant_id": "Green-tek-A15535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "12:00:00 PM",
    "power": 1.195,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "2/25/2024 12:15",
    "plant_id": "Green-tek-A15536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "12:15:00 PM",
    "power": 1.159,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "2/25/2024 12:30",
    "plant_id": "Green-tek-A15537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "12:30:00 PM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "2/25/2024 12:45",
    "plant_id": "Green-tek-A15538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "12:45:00 PM",
    "power": 1.025,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "2/25/2024 13:00",
    "plant_id": "Green-tek-A15539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "1:00:00 PM",
    "power": 0.935,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "2/25/2024 13:15",
    "plant_id": "Green-tek-A15540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "1:15:00 PM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "2/25/2024 13:30",
    "plant_id": "Green-tek-A15541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "1:30:00 PM",
    "power": 0.732,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "2/25/2024 13:45",
    "plant_id": "Green-tek-A15542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "1:45:00 PM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "2/25/2024 14:00",
    "plant_id": "Green-tek-A15543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "2:00:00 PM",
    "power": 0.528,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "2/25/2024 14:15",
    "plant_id": "Green-tek-A15544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "2:15:00 PM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "2/25/2024 14:30",
    "plant_id": "Green-tek-A15545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "2:30:00 PM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "2/25/2024 14:45",
    "plant_id": "Green-tek-A15546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "2:45:00 PM",
    "power": 0.278,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "2/25/2024 15:00",
    "plant_id": "Green-tek-A15547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "3:00:00 PM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "2/25/2024 15:15",
    "plant_id": "Green-tek-A15548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "3:15:00 PM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "2/25/2024 15:30",
    "plant_id": "Green-tek-A15549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "3:30:00 PM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "2/25/2024 15:45",
    "plant_id": "Green-tek-A15550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "2/25/2024 16:00",
    "plant_id": "Green-tek-A15551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/25/2024 16:15",
    "plant_id": "Green-tek-A15552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/25/2024 16:30",
    "plant_id": "Green-tek-A15553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/25/2024 16:45",
    "plant_id": "Green-tek-A15554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/25/2024 17:00",
    "plant_id": "Green-tek-A15555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/25/2024 17:15",
    "plant_id": "Green-tek-A15556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/25/2024 17:30",
    "plant_id": "Green-tek-A15557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/25/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/26/2024 6:00",
    "plant_id": "Green-tek-A15558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/26/2024 6:15",
    "plant_id": "Green-tek-A15559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/26/2024 6:30",
    "plant_id": "Green-tek-A15560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/26/2024 6:45",
    "plant_id": "Green-tek-A15561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/26/2024 7:00",
    "plant_id": "Green-tek-A15562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/26/2024 7:15",
    "plant_id": "Green-tek-A15563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "2/26/2024 7:30",
    "plant_id": "Green-tek-A15564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "2/26/2024 7:45",
    "plant_id": "Green-tek-A15565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "7:45:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/26/2024 8:00",
    "plant_id": "Green-tek-A15566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/26/2024 8:15",
    "plant_id": "Green-tek-A15567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "8:15:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/26/2024 8:30",
    "plant_id": "Green-tek-A15568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "8:30:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/26/2024 8:45",
    "plant_id": "Green-tek-A15569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "8:45:00 AM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "2/26/2024 9:00",
    "plant_id": "Green-tek-A15570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "9:00:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "2/26/2024 9:15",
    "plant_id": "Green-tek-A15571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "9:15:00 AM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "2/26/2024 9:30",
    "plant_id": "Green-tek-A15572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "9:30:00 AM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "2/26/2024 9:45",
    "plant_id": "Green-tek-A15573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "9:45:00 AM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/26/2024 10:00",
    "plant_id": "Green-tek-A15574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "10:00:00 AM",
    "power": 0.791,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/26/2024 10:15",
    "plant_id": "Green-tek-A15575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "10:15:00 AM",
    "power": 0.903,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "2/26/2024 10:30",
    "plant_id": "Green-tek-A15576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "10:30:00 AM",
    "power": 1.01,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "2/26/2024 10:45",
    "plant_id": "Green-tek-A15577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "10:45:00 AM",
    "power": 1.107,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "2/26/2024 11:00",
    "plant_id": "Green-tek-A15578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "11:00:00 AM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "2/26/2024 11:15",
    "plant_id": "Green-tek-A15579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "11:15:00 AM",
    "power": 1.252,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.32946,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "2/26/2024 11:30",
    "plant_id": "Green-tek-A15580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "11:30:00 AM",
    "power": 1.291,
    "Energy": 0.323,
    "output Energy": 0.323,
    "Ac output": 1.323,
    "dc output": 1.34946,
    "AC_R_i": 0.0004213,
    "AC_S_i": 0.00046343,
    "AC_T_i": 0.00050977,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096899,
    "AC_S_pow": 0.1065889,
    "AC_T_Pow": 0.1172471,
    "AC_R_output": 0.3969,
    "AC_S_output": 0.43659,
    "AC_T_output": 0.480249,
    "dc_current": 0.002375,
    "dc_voltage": 136,
    "dc_power": 0.33252,
    "dc_output": 0.32946,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000323,
    "carbon offset": 0.40375,
    "trees planted": 8.972222222,
    "DC_R_output": 0.130977,
    "DC_S_output": 0.1440747,
    "DC_T_output": 0.15848217
  },
  {
    "datetime": "2/26/2024 11:45",
    "plant_id": "Green-tek-A15581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "11:45:00 AM",
    "power": 1.304,
    "Energy": 0.326,
    "output Energy": 0.326,
    "Ac output": 1.326,
    "dc output": 1.35252,
    "AC_R_i": 0.00042522,
    "AC_S_i": 0.000467742,
    "AC_T_i": 0.00051452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0978006,
    "AC_S_pow": 0.10758066,
    "AC_T_Pow": 0.1183396,
    "AC_R_output": 0.3978,
    "AC_S_output": 0.43758,
    "AC_T_output": 0.481338,
    "dc_current": 0.00239706,
    "dc_voltage": 136,
    "dc_power": 0.32946,
    "dc_output": 0.33252,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000326,
    "carbon offset": 0.4075,
    "trees planted": 9.055555556,
    "DC_R_output": 0.131274,
    "DC_S_output": 0.1444014,
    "DC_T_output": 0.15884154
  },
  {
    "datetime": "2/26/2024 12:00",
    "plant_id": "Green-tek-A15582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "12:00:00 PM",
    "power": 1.291,
    "Energy": 0.323,
    "output Energy": 0.323,
    "Ac output": 1.323,
    "dc output": 1.34946,
    "AC_R_i": 0.0004213,
    "AC_S_i": 0.00046343,
    "AC_T_i": 0.00050977,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096899,
    "AC_S_pow": 0.1065889,
    "AC_T_Pow": 0.1172471,
    "AC_R_output": 0.3969,
    "AC_S_output": 0.43659,
    "AC_T_output": 0.480249,
    "dc_current": 0.002375,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.32946,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000323,
    "carbon offset": 0.40375,
    "trees planted": 8.972222222,
    "DC_R_output": 0.130977,
    "DC_S_output": 0.1440747,
    "DC_T_output": 0.15848217
  },
  {
    "datetime": "2/26/2024 12:15",
    "plant_id": "Green-tek-A15583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "12:15:00 PM",
    "power": 1.252,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "2/26/2024 12:30",
    "plant_id": "Green-tek-A15584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "12:30:00 PM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "2/26/2024 12:45",
    "plant_id": "Green-tek-A15585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "12:45:00 PM",
    "power": 1.107,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "2/26/2024 13:00",
    "plant_id": "Green-tek-A15586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "1:00:00 PM",
    "power": 1.01,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "2/26/2024 13:15",
    "plant_id": "Green-tek-A15587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "1:15:00 PM",
    "power": 0.903,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "2/26/2024 13:30",
    "plant_id": "Green-tek-A15588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "1:30:00 PM",
    "power": 0.791,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "2/26/2024 13:45",
    "plant_id": "Green-tek-A15589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "1:45:00 PM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "2/26/2024 14:00",
    "plant_id": "Green-tek-A15590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "2:00:00 PM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "2/26/2024 14:15",
    "plant_id": "Green-tek-A15591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "2:15:00 PM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "2/26/2024 14:30",
    "plant_id": "Green-tek-A15592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "2:30:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "2/26/2024 14:45",
    "plant_id": "Green-tek-A15593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "2:45:00 PM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "2/26/2024 15:00",
    "plant_id": "Green-tek-A15594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "3:00:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "2/26/2024 15:15",
    "plant_id": "Green-tek-A15595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "3:15:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "2/26/2024 15:30",
    "plant_id": "Green-tek-A15596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/26/2024 15:45",
    "plant_id": "Green-tek-A15597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "3:45:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/26/2024 16:00",
    "plant_id": "Green-tek-A15598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "2/26/2024 16:15",
    "plant_id": "Green-tek-A15599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "2/26/2024 16:30",
    "plant_id": "Green-tek-A15600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/26/2024 16:45",
    "plant_id": "Green-tek-A15601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/26/2024 17:00",
    "plant_id": "Green-tek-A15602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/26/2024 17:15",
    "plant_id": "Green-tek-A15603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/26/2024 17:30",
    "plant_id": "Green-tek-A15604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/26/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/27/2024 6:00",
    "plant_id": "Green-tek-A15605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/27/2024 6:15",
    "plant_id": "Green-tek-A15606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/27/2024 6:30",
    "plant_id": "Green-tek-A15607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/27/2024 6:45",
    "plant_id": "Green-tek-A15608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/27/2024 7:00",
    "plant_id": "Green-tek-A15609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/27/2024 7:15",
    "plant_id": "Green-tek-A15610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/27/2024 7:30",
    "plant_id": "Green-tek-A15611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/27/2024 7:45",
    "plant_id": "Green-tek-A15612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "7:45:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "2/27/2024 8:00",
    "plant_id": "Green-tek-A15613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "8:00:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "2/27/2024 8:15",
    "plant_id": "Green-tek-A15614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "8:15:00 AM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "2/27/2024 8:30",
    "plant_id": "Green-tek-A15615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "8:30:00 AM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/27/2024 8:45",
    "plant_id": "Green-tek-A15616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "8:45:00 AM",
    "power": 0.286,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "2/27/2024 9:00",
    "plant_id": "Green-tek-A15617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "9:00:00 AM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/27/2024 9:15",
    "plant_id": "Green-tek-A15618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "9:15:00 AM",
    "power": 0.448,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "2/27/2024 9:30",
    "plant_id": "Green-tek-A15619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "9:30:00 AM",
    "power": 0.543,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "2/27/2024 9:45",
    "plant_id": "Green-tek-A15620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "9:45:00 AM",
    "power": 0.646,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "2/27/2024 10:00",
    "plant_id": "Green-tek-A15621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "10:00:00 AM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "2/27/2024 10:15",
    "plant_id": "Green-tek-A15622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "10:15:00 AM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "2/27/2024 10:30",
    "plant_id": "Green-tek-A15623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "10:30:00 AM",
    "power": 0.962,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "2/27/2024 10:45",
    "plant_id": "Green-tek-A15624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "10:45:00 AM",
    "power": 1.055,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "2/27/2024 11:00",
    "plant_id": "Green-tek-A15625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "11:00:00 AM",
    "power": 1.133,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "2/27/2024 11:15",
    "plant_id": "Green-tek-A15626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "11:15:00 AM",
    "power": 1.192,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "2/27/2024 11:30",
    "plant_id": "Green-tek-A15627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "11:30:00 AM",
    "power": 1.229,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "2/27/2024 11:45",
    "plant_id": "Green-tek-A15628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "11:45:00 AM",
    "power": 1.242,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "2/27/2024 12:00",
    "plant_id": "Green-tek-A15629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "12:00:00 PM",
    "power": 1.229,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "2/27/2024 12:15",
    "plant_id": "Green-tek-A15630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "12:15:00 PM",
    "power": 1.192,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "2/27/2024 12:30",
    "plant_id": "Green-tek-A15631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "12:30:00 PM",
    "power": 1.133,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "2/27/2024 12:45",
    "plant_id": "Green-tek-A15632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "12:45:00 PM",
    "power": 1.055,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "2/27/2024 13:00",
    "plant_id": "Green-tek-A15633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "1:00:00 PM",
    "power": 0.962,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "2/27/2024 13:15",
    "plant_id": "Green-tek-A15634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "1:15:00 PM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "2/27/2024 13:30",
    "plant_id": "Green-tek-A15635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "1:30:00 PM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "2/27/2024 13:45",
    "plant_id": "Green-tek-A15636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "1:45:00 PM",
    "power": 0.646,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "2/27/2024 14:00",
    "plant_id": "Green-tek-A15637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "2:00:00 PM",
    "power": 0.543,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "2/27/2024 14:15",
    "plant_id": "Green-tek-A15638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "2:15:00 PM",
    "power": 0.448,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "2/27/2024 14:30",
    "plant_id": "Green-tek-A15639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "2:30:00 PM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "2/27/2024 14:45",
    "plant_id": "Green-tek-A15640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "2:45:00 PM",
    "power": 0.286,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "2/27/2024 15:00",
    "plant_id": "Green-tek-A15641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "3:00:00 PM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/27/2024 15:15",
    "plant_id": "Green-tek-A15642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "3:15:00 PM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "2/27/2024 15:30",
    "plant_id": "Green-tek-A15643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "3:30:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "2/27/2024 15:45",
    "plant_id": "Green-tek-A15644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "3:45:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "2/27/2024 16:00",
    "plant_id": "Green-tek-A15645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "2/27/2024 16:15",
    "plant_id": "Green-tek-A15646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/27/2024 16:30",
    "plant_id": "Green-tek-A15647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "2/27/2024 16:45",
    "plant_id": "Green-tek-A15648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/27/2024 17:00",
    "plant_id": "Green-tek-A15649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/27/2024 17:15",
    "plant_id": "Green-tek-A15650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/27/2024 17:30",
    "plant_id": "Green-tek-A15651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/27/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/28/2024 6:00",
    "plant_id": "Green-tek-A15652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/28/2024 6:15",
    "plant_id": "Green-tek-A15653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/28/2024 6:30",
    "plant_id": "Green-tek-A15654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/28/2024 6:45",
    "plant_id": "Green-tek-A15655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/28/2024 7:00",
    "plant_id": "Green-tek-A15656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/28/2024 7:15",
    "plant_id": "Green-tek-A15657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/28/2024 7:30",
    "plant_id": "Green-tek-A15658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/28/2024 7:45",
    "plant_id": "Green-tek-A15659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "7:45:00 AM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/28/2024 8:00",
    "plant_id": "Green-tek-A15660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "8:00:00 AM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/28/2024 8:15",
    "plant_id": "Green-tek-A15661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/28/2024 8:30",
    "plant_id": "Green-tek-A15662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "8:30:00 AM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "2/28/2024 8:45",
    "plant_id": "Green-tek-A15663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "8:45:00 AM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/28/2024 9:00",
    "plant_id": "Green-tek-A15664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "9:00:00 AM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "2/28/2024 9:15",
    "plant_id": "Green-tek-A15665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "9:15:00 AM",
    "power": 0.347,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "2/28/2024 9:30",
    "plant_id": "Green-tek-A15666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "9:30:00 AM",
    "power": 0.421,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/28/2024 9:45",
    "plant_id": "Green-tek-A15667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "9:45:00 AM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "2/28/2024 10:00",
    "plant_id": "Green-tek-A15668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "10:00:00 AM",
    "power": 0.584,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "2/28/2024 10:15",
    "plant_id": "Green-tek-A15669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "10:15:00 AM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "2/28/2024 10:30",
    "plant_id": "Green-tek-A15670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "10:30:00 AM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "2/28/2024 10:45",
    "plant_id": "Green-tek-A15671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "10:45:00 AM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "2/28/2024 11:00",
    "plant_id": "Green-tek-A15672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "11:00:00 AM",
    "power": 0.878,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "2/28/2024 11:15",
    "plant_id": "Green-tek-A15673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "11:15:00 AM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "2/28/2024 11:30",
    "plant_id": "Green-tek-A15674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "11:30:00 AM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "2/28/2024 11:45",
    "plant_id": "Green-tek-A15675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "11:45:00 AM",
    "power": 0.962,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "2/28/2024 12:00",
    "plant_id": "Green-tek-A15676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "12:00:00 PM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "2/28/2024 12:15",
    "plant_id": "Green-tek-A15677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "12:15:00 PM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "2/28/2024 12:30",
    "plant_id": "Green-tek-A15678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "12:30:00 PM",
    "power": 0.878,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "2/28/2024 12:45",
    "plant_id": "Green-tek-A15679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "12:45:00 PM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "2/28/2024 13:00",
    "plant_id": "Green-tek-A15680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "1:00:00 PM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "2/28/2024 13:15",
    "plant_id": "Green-tek-A15681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "1:15:00 PM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "2/28/2024 13:30",
    "plant_id": "Green-tek-A15682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "1:30:00 PM",
    "power": 0.584,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "2/28/2024 13:45",
    "plant_id": "Green-tek-A15683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "1:45:00 PM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "2/28/2024 14:00",
    "plant_id": "Green-tek-A15684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "2:00:00 PM",
    "power": 0.421,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/28/2024 14:15",
    "plant_id": "Green-tek-A15685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "2:15:00 PM",
    "power": 0.347,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "2/28/2024 14:30",
    "plant_id": "Green-tek-A15686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "2:30:00 PM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "2/28/2024 14:45",
    "plant_id": "Green-tek-A15687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "2:45:00 PM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "2/28/2024 15:00",
    "plant_id": "Green-tek-A15688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "3:00:00 PM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "2/28/2024 15:15",
    "plant_id": "Green-tek-A15689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "2/28/2024 15:30",
    "plant_id": "Green-tek-A15690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "3:30:00 PM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "2/28/2024 15:45",
    "plant_id": "Green-tek-A15691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "3:45:00 PM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "2/28/2024 16:00",
    "plant_id": "Green-tek-A15692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "2/28/2024 16:15",
    "plant_id": "Green-tek-A15693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "2/28/2024 16:30",
    "plant_id": "Green-tek-A15694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "2/28/2024 16:45",
    "plant_id": "Green-tek-A15695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "2/28/2024 17:00",
    "plant_id": "Green-tek-A15696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/28/2024 17:15",
    "plant_id": "Green-tek-A15697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/28/2024 17:30",
    "plant_id": "Green-tek-A15698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/28/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/29/2024 6:00",
    "plant_id": "Green-tek-A15699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/29/2024 6:15",
    "plant_id": "Green-tek-A15700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/29/2024 6:30",
    "plant_id": "Green-tek-A15701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/29/2024 6:45",
    "plant_id": "Green-tek-A15702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/29/2024 7:00",
    "plant_id": "Green-tek-A15703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/29/2024 7:15",
    "plant_id": "Green-tek-A15704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/29/2024 7:30",
    "plant_id": "Green-tek-A15705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "7:30:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/29/2024 7:45",
    "plant_id": "Green-tek-A15706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "7:45:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/29/2024 8:00",
    "plant_id": "Green-tek-A15707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "8:00:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "2/29/2024 8:15",
    "plant_id": "Green-tek-A15708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "8:15:00 AM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "2/29/2024 8:30",
    "plant_id": "Green-tek-A15709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "8:30:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "2/29/2024 8:45",
    "plant_id": "Green-tek-A15710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "8:45:00 AM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "2/29/2024 9:00",
    "plant_id": "Green-tek-A15711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "9:00:00 AM",
    "power": 0.236,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "2/29/2024 9:15",
    "plant_id": "Green-tek-A15712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "9:15:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "2/29/2024 9:30",
    "plant_id": "Green-tek-A15713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "9:30:00 AM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "2/29/2024 9:45",
    "plant_id": "Green-tek-A15714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "9:45:00 AM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/29/2024 10:00",
    "plant_id": "Green-tek-A15715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "10:00:00 AM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "2/29/2024 10:15",
    "plant_id": "Green-tek-A15716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "10:15:00 AM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "2/29/2024 10:30",
    "plant_id": "Green-tek-A15717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "10:30:00 AM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "2/29/2024 10:45",
    "plant_id": "Green-tek-A15718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "10:45:00 AM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "2/29/2024 11:00",
    "plant_id": "Green-tek-A15719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "11:00:00 AM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "2/29/2024 11:15",
    "plant_id": "Green-tek-A15720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "11:15:00 AM",
    "power": 0.778,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "2/29/2024 11:30",
    "plant_id": "Green-tek-A15721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "11:30:00 AM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "2/29/2024 11:45",
    "plant_id": "Green-tek-A15722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "11:45:00 AM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "2/29/2024 12:00",
    "plant_id": "Green-tek-A15723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "12:00:00 PM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "2/29/2024 12:15",
    "plant_id": "Green-tek-A15724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "12:15:00 PM",
    "power": 0.778,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "2/29/2024 12:30",
    "plant_id": "Green-tek-A15725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "12:30:00 PM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "2/29/2024 12:45",
    "plant_id": "Green-tek-A15726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "12:45:00 PM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "2/29/2024 13:00",
    "plant_id": "Green-tek-A15727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "1:00:00 PM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "2/29/2024 13:15",
    "plant_id": "Green-tek-A15728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "1:15:00 PM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "2/29/2024 13:30",
    "plant_id": "Green-tek-A15729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "1:30:00 PM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "2/29/2024 13:45",
    "plant_id": "Green-tek-A15730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "1:45:00 PM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "2/29/2024 14:00",
    "plant_id": "Green-tek-A15731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "2:00:00 PM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "2/29/2024 14:15",
    "plant_id": "Green-tek-A15732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "2:15:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "2/29/2024 14:30",
    "plant_id": "Green-tek-A15733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "2:30:00 PM",
    "power": 0.236,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "2/29/2024 14:45",
    "plant_id": "Green-tek-A15734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "2:45:00 PM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "2/29/2024 15:00",
    "plant_id": "Green-tek-A15735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "3:00:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "2/29/2024 15:15",
    "plant_id": "Green-tek-A15736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "3:15:00 PM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "2/29/2024 15:30",
    "plant_id": "Green-tek-A15737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "3:30:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "2/29/2024 15:45",
    "plant_id": "Green-tek-A15738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "3:45:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "2/29/2024 16:00",
    "plant_id": "Green-tek-A15739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "4:00:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "2/29/2024 16:15",
    "plant_id": "Green-tek-A15740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "2/29/2024 16:30",
    "plant_id": "Green-tek-A15741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "2/29/2024 16:45",
    "plant_id": "Green-tek-A15742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "2/29/2024 17:00",
    "plant_id": "Green-tek-A15743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "2/29/2024 17:15",
    "plant_id": "Green-tek-A15744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "2/29/2024 17:30",
    "plant_id": "Green-tek-A15745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "2/29/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/1/2024 6:00",
    "plant_id": "Green-tek-A15746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/1/2024 6:15",
    "plant_id": "Green-tek-A15747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/1/2024 6:30",
    "plant_id": "Green-tek-A15748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/1/2024 6:45",
    "plant_id": "Green-tek-A15749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/1/2024 7:00",
    "plant_id": "Green-tek-A15750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/1/2024 7:15",
    "plant_id": "Green-tek-A15751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "3/1/2024 7:30",
    "plant_id": "Green-tek-A15752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/1/2024 7:45",
    "plant_id": "Green-tek-A15753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/1/2024 8:00",
    "plant_id": "Green-tek-A15754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "8:00:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "3/1/2024 8:15",
    "plant_id": "Green-tek-A15755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "8:15:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "3/1/2024 8:30",
    "plant_id": "Green-tek-A15756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "8:30:00 AM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "3/1/2024 8:45",
    "plant_id": "Green-tek-A15757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "8:45:00 AM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "3/1/2024 9:00",
    "plant_id": "Green-tek-A15758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "9:00:00 AM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "3/1/2024 9:15",
    "plant_id": "Green-tek-A15759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "9:15:00 AM",
    "power": 0.343,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "3/1/2024 9:30",
    "plant_id": "Green-tek-A15760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "9:30:00 AM",
    "power": 0.416,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "3/1/2024 9:45",
    "plant_id": "Green-tek-A15761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "9:45:00 AM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "3/1/2024 10:00",
    "plant_id": "Green-tek-A15762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "10:00:00 AM",
    "power": 0.576,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "3/1/2024 10:15",
    "plant_id": "Green-tek-A15763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "10:15:00 AM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "3/1/2024 10:30",
    "plant_id": "Green-tek-A15764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "10:30:00 AM",
    "power": 0.736,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "3/1/2024 10:45",
    "plant_id": "Green-tek-A15765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "10:45:00 AM",
    "power": 0.807,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "3/1/2024 11:00",
    "plant_id": "Green-tek-A15766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "11:00:00 AM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/1/2024 11:15",
    "plant_id": "Green-tek-A15767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "11:15:00 AM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "3/1/2024 11:30",
    "plant_id": "Green-tek-A15768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "11:30:00 AM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "3/1/2024 11:45",
    "plant_id": "Green-tek-A15769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "11:45:00 AM",
    "power": 0.95,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "3/1/2024 12:00",
    "plant_id": "Green-tek-A15770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "12:00:00 PM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "3/1/2024 12:15",
    "plant_id": "Green-tek-A15771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "12:15:00 PM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "3/1/2024 12:30",
    "plant_id": "Green-tek-A15772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "12:30:00 PM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/1/2024 12:45",
    "plant_id": "Green-tek-A15773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "12:45:00 PM",
    "power": 0.807,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "3/1/2024 13:00",
    "plant_id": "Green-tek-A15774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "1:00:00 PM",
    "power": 0.736,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "3/1/2024 13:15",
    "plant_id": "Green-tek-A15775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "1:15:00 PM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "3/1/2024 13:30",
    "plant_id": "Green-tek-A15776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "1:30:00 PM",
    "power": 0.576,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "3/1/2024 13:45",
    "plant_id": "Green-tek-A15777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "1:45:00 PM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "3/1/2024 14:00",
    "plant_id": "Green-tek-A15778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "2:00:00 PM",
    "power": 0.416,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "3/1/2024 14:15",
    "plant_id": "Green-tek-A15779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "2:15:00 PM",
    "power": 0.343,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "3/1/2024 14:30",
    "plant_id": "Green-tek-A15780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "2:30:00 PM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "3/1/2024 14:45",
    "plant_id": "Green-tek-A15781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "2:45:00 PM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "3/1/2024 15:00",
    "plant_id": "Green-tek-A15782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "3:00:00 PM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "3/1/2024 15:15",
    "plant_id": "Green-tek-A15783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "3:15:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "3/1/2024 15:30",
    "plant_id": "Green-tek-A15784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "3:30:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "3/1/2024 15:45",
    "plant_id": "Green-tek-A15785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/1/2024 16:00",
    "plant_id": "Green-tek-A15786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/1/2024 16:15",
    "plant_id": "Green-tek-A15787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "3/1/2024 16:30",
    "plant_id": "Green-tek-A15788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/1/2024 16:45",
    "plant_id": "Green-tek-A15789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/1/2024 17:00",
    "plant_id": "Green-tek-A15790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/1/2024 17:15",
    "plant_id": "Green-tek-A15791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/1/2024 17:30",
    "plant_id": "Green-tek-A15792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/1/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/2/2024 6:00",
    "plant_id": "Green-tek-A15793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/2/2024 6:15",
    "plant_id": "Green-tek-A15794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/2/2024 6:30",
    "plant_id": "Green-tek-A15795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/2/2024 6:45",
    "plant_id": "Green-tek-A15796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/2/2024 7:00",
    "plant_id": "Green-tek-A15797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/2/2024 7:15",
    "plant_id": "Green-tek-A15798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/2/2024 7:30",
    "plant_id": "Green-tek-A15799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/2/2024 7:45",
    "plant_id": "Green-tek-A15800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/2/2024 8:00",
    "plant_id": "Green-tek-A15801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "8:00:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/2/2024 8:15",
    "plant_id": "Green-tek-A15802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "8:15:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/2/2024 8:30",
    "plant_id": "Green-tek-A15803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "8:30:00 AM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "3/2/2024 8:45",
    "plant_id": "Green-tek-A15804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "8:45:00 AM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/2/2024 9:00",
    "plant_id": "Green-tek-A15805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "9:00:00 AM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "3/2/2024 9:15",
    "plant_id": "Green-tek-A15806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "9:15:00 AM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "3/2/2024 9:30",
    "plant_id": "Green-tek-A15807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "9:30:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "3/2/2024 9:45",
    "plant_id": "Green-tek-A15808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "9:45:00 AM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "3/2/2024 10:00",
    "plant_id": "Green-tek-A15809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "10:00:00 AM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "3/2/2024 10:15",
    "plant_id": "Green-tek-A15810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "10:15:00 AM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "3/2/2024 10:30",
    "plant_id": "Green-tek-A15811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "10:30:00 AM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "3/2/2024 10:45",
    "plant_id": "Green-tek-A15812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "10:45:00 AM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "3/2/2024 11:00",
    "plant_id": "Green-tek-A15813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "11:00:00 AM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "3/2/2024 11:15",
    "plant_id": "Green-tek-A15814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "11:15:00 AM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "3/2/2024 11:30",
    "plant_id": "Green-tek-A15815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "11:30:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/2/2024 11:45",
    "plant_id": "Green-tek-A15816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "11:45:00 AM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "3/2/2024 12:00",
    "plant_id": "Green-tek-A15817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "12:00:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/2/2024 12:15",
    "plant_id": "Green-tek-A15818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "12:15:00 PM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "3/2/2024 12:30",
    "plant_id": "Green-tek-A15819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "12:30:00 PM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "3/2/2024 12:45",
    "plant_id": "Green-tek-A15820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "12:45:00 PM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "3/2/2024 13:00",
    "plant_id": "Green-tek-A15821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "1:00:00 PM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "3/2/2024 13:15",
    "plant_id": "Green-tek-A15822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "1:15:00 PM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "3/2/2024 13:30",
    "plant_id": "Green-tek-A15823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "1:30:00 PM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "3/2/2024 13:45",
    "plant_id": "Green-tek-A15824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "1:45:00 PM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "3/2/2024 14:00",
    "plant_id": "Green-tek-A15825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "2:00:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "3/2/2024 14:15",
    "plant_id": "Green-tek-A15826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "2:15:00 PM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "3/2/2024 14:30",
    "plant_id": "Green-tek-A15827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "2:30:00 PM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "3/2/2024 14:45",
    "plant_id": "Green-tek-A15828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "2:45:00 PM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/2/2024 15:00",
    "plant_id": "Green-tek-A15829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "3:00:00 PM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "3/2/2024 15:15",
    "plant_id": "Green-tek-A15830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "3:15:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/2/2024 15:30",
    "plant_id": "Green-tek-A15831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "3:30:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/2/2024 15:45",
    "plant_id": "Green-tek-A15832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/2/2024 16:00",
    "plant_id": "Green-tek-A15833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/2/2024 16:15",
    "plant_id": "Green-tek-A15834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/2/2024 16:30",
    "plant_id": "Green-tek-A15835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/2/2024 16:45",
    "plant_id": "Green-tek-A15836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/2/2024 17:00",
    "plant_id": "Green-tek-A15837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/2/2024 17:15",
    "plant_id": "Green-tek-A15838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/2/2024 17:30",
    "plant_id": "Green-tek-A15839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/2/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/3/2024 6:00",
    "plant_id": "Green-tek-A15840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/3/2024 6:15",
    "plant_id": "Green-tek-A15841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/3/2024 6:30",
    "plant_id": "Green-tek-A15842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/3/2024 6:45",
    "plant_id": "Green-tek-A15843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/3/2024 7:00",
    "plant_id": "Green-tek-A15844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/3/2024 7:15",
    "plant_id": "Green-tek-A15845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/3/2024 7:30",
    "plant_id": "Green-tek-A15846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/3/2024 7:45",
    "plant_id": "Green-tek-A15847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "7:45:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/3/2024 8:00",
    "plant_id": "Green-tek-A15848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "8:00:00 AM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/3/2024 8:15",
    "plant_id": "Green-tek-A15849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "8:15:00 AM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "3/3/2024 8:30",
    "plant_id": "Green-tek-A15850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "8:30:00 AM",
    "power": 0.214,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "3/3/2024 8:45",
    "plant_id": "Green-tek-A15851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "8:45:00 AM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "3/3/2024 9:00",
    "plant_id": "Green-tek-A15852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "9:00:00 AM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "3/3/2024 9:15",
    "plant_id": "Green-tek-A15853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "9:15:00 AM",
    "power": 0.433,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "3/3/2024 9:30",
    "plant_id": "Green-tek-A15854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "9:30:00 AM",
    "power": 0.526,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "3/3/2024 9:45",
    "plant_id": "Green-tek-A15855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "9:45:00 AM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "3/3/2024 10:00",
    "plant_id": "Green-tek-A15856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "10:00:00 AM",
    "power": 0.729,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "3/3/2024 10:15",
    "plant_id": "Green-tek-A15857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "10:15:00 AM",
    "power": 0.832,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "3/3/2024 10:30",
    "plant_id": "Green-tek-A15858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "10:30:00 AM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "3/3/2024 10:45",
    "plant_id": "Green-tek-A15859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "10:45:00 AM",
    "power": 1.02,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "3/3/2024 11:00",
    "plant_id": "Green-tek-A15860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "11:00:00 AM",
    "power": 1.096,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "3/3/2024 11:15",
    "plant_id": "Green-tek-A15861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "11:15:00 AM",
    "power": 1.153,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "3/3/2024 11:30",
    "plant_id": "Green-tek-A15862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "11:30:00 AM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "3/3/2024 11:45",
    "plant_id": "Green-tek-A15863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "11:45:00 AM",
    "power": 1.201,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "3/3/2024 12:00",
    "plant_id": "Green-tek-A15864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "12:00:00 PM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "3/3/2024 12:15",
    "plant_id": "Green-tek-A15865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "12:15:00 PM",
    "power": 1.153,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "3/3/2024 12:30",
    "plant_id": "Green-tek-A15866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "12:30:00 PM",
    "power": 1.096,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "3/3/2024 12:45",
    "plant_id": "Green-tek-A15867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "12:45:00 PM",
    "power": 1.02,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "3/3/2024 13:00",
    "plant_id": "Green-tek-A15868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "1:00:00 PM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "3/3/2024 13:15",
    "plant_id": "Green-tek-A15869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "1:15:00 PM",
    "power": 0.832,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "3/3/2024 13:30",
    "plant_id": "Green-tek-A15870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "1:30:00 PM",
    "power": 0.729,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "3/3/2024 13:45",
    "plant_id": "Green-tek-A15871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "1:45:00 PM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "3/3/2024 14:00",
    "plant_id": "Green-tek-A15872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "2:00:00 PM",
    "power": 0.526,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "3/3/2024 14:15",
    "plant_id": "Green-tek-A15873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "2:15:00 PM",
    "power": 0.433,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "3/3/2024 14:30",
    "plant_id": "Green-tek-A15874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "2:30:00 PM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "3/3/2024 14:45",
    "plant_id": "Green-tek-A15875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "2:45:00 PM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "3/3/2024 15:00",
    "plant_id": "Green-tek-A15876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "3:00:00 PM",
    "power": 0.214,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "3/3/2024 15:15",
    "plant_id": "Green-tek-A15877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "3:15:00 PM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "3/3/2024 15:30",
    "plant_id": "Green-tek-A15878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "3:30:00 PM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/3/2024 15:45",
    "plant_id": "Green-tek-A15879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "3:45:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/3/2024 16:00",
    "plant_id": "Green-tek-A15880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/3/2024 16:15",
    "plant_id": "Green-tek-A15881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/3/2024 16:30",
    "plant_id": "Green-tek-A15882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/3/2024 16:45",
    "plant_id": "Green-tek-A15883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/3/2024 17:00",
    "plant_id": "Green-tek-A15884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/3/2024 17:15",
    "plant_id": "Green-tek-A15885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/3/2024 17:30",
    "plant_id": "Green-tek-A15886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/3/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/4/2024 6:00",
    "plant_id": "Green-tek-A15887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/4/2024 6:15",
    "plant_id": "Green-tek-A15888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/4/2024 6:30",
    "plant_id": "Green-tek-A15889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/4/2024 6:45",
    "plant_id": "Green-tek-A15890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/4/2024 7:00",
    "plant_id": "Green-tek-A15891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/4/2024 7:15",
    "plant_id": "Green-tek-A15892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "3/4/2024 7:30",
    "plant_id": "Green-tek-A15893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "7:30:00 AM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "3/4/2024 7:45",
    "plant_id": "Green-tek-A15894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "7:45:00 AM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "3/4/2024 8:00",
    "plant_id": "Green-tek-A15895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "8:00:00 AM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "3/4/2024 8:15",
    "plant_id": "Green-tek-A15896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "8:15:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "3/4/2024 8:30",
    "plant_id": "Green-tek-A15897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "8:30:00 AM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "3/4/2024 8:45",
    "plant_id": "Green-tek-A15898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "8:45:00 AM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "3/4/2024 9:00",
    "plant_id": "Green-tek-A15899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "9:00:00 AM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "3/4/2024 9:15",
    "plant_id": "Green-tek-A15900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "9:15:00 AM",
    "power": 0.35,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "3/4/2024 9:30",
    "plant_id": "Green-tek-A15901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "9:30:00 AM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "3/4/2024 9:45",
    "plant_id": "Green-tek-A15902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "9:45:00 AM",
    "power": 0.506,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "3/4/2024 10:00",
    "plant_id": "Green-tek-A15903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "10:00:00 AM",
    "power": 0.589,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "3/4/2024 10:15",
    "plant_id": "Green-tek-A15904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "10:15:00 AM",
    "power": 0.673,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "3/4/2024 10:30",
    "plant_id": "Green-tek-A15905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "10:30:00 AM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "3/4/2024 10:45",
    "plant_id": "Green-tek-A15906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "10:45:00 AM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "3/4/2024 11:00",
    "plant_id": "Green-tek-A15907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "11:00:00 AM",
    "power": 0.886,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "3/4/2024 11:15",
    "plant_id": "Green-tek-A15908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "11:15:00 AM",
    "power": 0.933,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "3/4/2024 11:30",
    "plant_id": "Green-tek-A15909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "11:30:00 AM",
    "power": 0.962,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "3/4/2024 11:45",
    "plant_id": "Green-tek-A15910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "11:45:00 AM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "3/4/2024 12:00",
    "plant_id": "Green-tek-A15911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "12:00:00 PM",
    "power": 0.962,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "3/4/2024 12:15",
    "plant_id": "Green-tek-A15912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "12:15:00 PM",
    "power": 0.933,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "3/4/2024 12:30",
    "plant_id": "Green-tek-A15913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "12:30:00 PM",
    "power": 0.886,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "3/4/2024 12:45",
    "plant_id": "Green-tek-A15914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "12:45:00 PM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "3/4/2024 13:00",
    "plant_id": "Green-tek-A15915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "1:00:00 PM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "3/4/2024 13:15",
    "plant_id": "Green-tek-A15916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "1:15:00 PM",
    "power": 0.673,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "3/4/2024 13:30",
    "plant_id": "Green-tek-A15917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "1:30:00 PM",
    "power": 0.589,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "3/4/2024 13:45",
    "plant_id": "Green-tek-A15918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "1:45:00 PM",
    "power": 0.506,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "3/4/2024 14:00",
    "plant_id": "Green-tek-A15919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "2:00:00 PM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "3/4/2024 14:15",
    "plant_id": "Green-tek-A15920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "2:15:00 PM",
    "power": 0.35,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "3/4/2024 14:30",
    "plant_id": "Green-tek-A15921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "2:30:00 PM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "3/4/2024 14:45",
    "plant_id": "Green-tek-A15922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "2:45:00 PM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "3/4/2024 15:00",
    "plant_id": "Green-tek-A15923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "3:00:00 PM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "3/4/2024 15:15",
    "plant_id": "Green-tek-A15924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "3:15:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "3/4/2024 15:30",
    "plant_id": "Green-tek-A15925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "3:30:00 PM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "3/4/2024 15:45",
    "plant_id": "Green-tek-A15926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "3:45:00 PM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "3/4/2024 16:00",
    "plant_id": "Green-tek-A15927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "4:00:00 PM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "3/4/2024 16:15",
    "plant_id": "Green-tek-A15928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "3/4/2024 16:30",
    "plant_id": "Green-tek-A15929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/4/2024 16:45",
    "plant_id": "Green-tek-A15930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/4/2024 17:00",
    "plant_id": "Green-tek-A15931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/4/2024 17:15",
    "plant_id": "Green-tek-A15932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/4/2024 17:30",
    "plant_id": "Green-tek-A15933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/4/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/5/2024 6:00",
    "plant_id": "Green-tek-A15934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/5/2024 6:15",
    "plant_id": "Green-tek-A15935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/5/2024 6:30",
    "plant_id": "Green-tek-A15936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/5/2024 6:45",
    "plant_id": "Green-tek-A15937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/5/2024 7:00",
    "plant_id": "Green-tek-A15938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/5/2024 7:15",
    "plant_id": "Green-tek-A15939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/5/2024 7:30",
    "plant_id": "Green-tek-A15940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/5/2024 7:45",
    "plant_id": "Green-tek-A15941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "3/5/2024 8:00",
    "plant_id": "Green-tek-A15942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "8:00:00 AM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "3/5/2024 8:15",
    "plant_id": "Green-tek-A15943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "8:15:00 AM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "3/5/2024 8:30",
    "plant_id": "Green-tek-A15944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "8:30:00 AM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "3/5/2024 8:45",
    "plant_id": "Green-tek-A15945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "8:45:00 AM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "3/5/2024 9:00",
    "plant_id": "Green-tek-A15946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "9:00:00 AM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "3/5/2024 9:15",
    "plant_id": "Green-tek-A15947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "9:15:00 AM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "3/5/2024 9:30",
    "plant_id": "Green-tek-A15948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "9:30:00 AM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "3/5/2024 9:45",
    "plant_id": "Green-tek-A15949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "9:45:00 AM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/5/2024 10:00",
    "plant_id": "Green-tek-A15950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "10:00:00 AM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "3/5/2024 10:15",
    "plant_id": "Green-tek-A15951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "10:15:00 AM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "3/5/2024 10:30",
    "plant_id": "Green-tek-A15952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "10:30:00 AM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "3/5/2024 10:45",
    "plant_id": "Green-tek-A15953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "10:45:00 AM",
    "power": 0.891,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "3/5/2024 11:00",
    "plant_id": "Green-tek-A15954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "11:00:00 AM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "3/5/2024 11:15",
    "plant_id": "Green-tek-A15955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "11:15:00 AM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "3/5/2024 11:30",
    "plant_id": "Green-tek-A15956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "11:30:00 AM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "3/5/2024 11:45",
    "plant_id": "Green-tek-A15957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "11:45:00 AM",
    "power": 1.05,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "3/5/2024 12:00",
    "plant_id": "Green-tek-A15958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "12:00:00 PM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "3/5/2024 12:15",
    "plant_id": "Green-tek-A15959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "12:15:00 PM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "3/5/2024 12:30",
    "plant_id": "Green-tek-A15960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "12:30:00 PM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "3/5/2024 12:45",
    "plant_id": "Green-tek-A15961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "12:45:00 PM",
    "power": 0.891,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "3/5/2024 13:00",
    "plant_id": "Green-tek-A15962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "1:00:00 PM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "3/5/2024 13:15",
    "plant_id": "Green-tek-A15963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "1:15:00 PM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "3/5/2024 13:30",
    "plant_id": "Green-tek-A15964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "1:30:00 PM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "3/5/2024 13:45",
    "plant_id": "Green-tek-A15965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "1:45:00 PM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/5/2024 14:00",
    "plant_id": "Green-tek-A15966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "2:00:00 PM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "3/5/2024 14:15",
    "plant_id": "Green-tek-A15967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "2:15:00 PM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "3/5/2024 14:30",
    "plant_id": "Green-tek-A15968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "2:30:00 PM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "3/5/2024 14:45",
    "plant_id": "Green-tek-A15969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "2:45:00 PM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "3/5/2024 15:00",
    "plant_id": "Green-tek-A15970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "3:00:00 PM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "3/5/2024 15:15",
    "plant_id": "Green-tek-A15971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "3:15:00 PM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "3/5/2024 15:30",
    "plant_id": "Green-tek-A15972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "3:30:00 PM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "3/5/2024 15:45",
    "plant_id": "Green-tek-A15973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "3/5/2024 16:00",
    "plant_id": "Green-tek-A15974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/5/2024 16:15",
    "plant_id": "Green-tek-A15975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/5/2024 16:30",
    "plant_id": "Green-tek-A15976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/5/2024 16:45",
    "plant_id": "Green-tek-A15977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/5/2024 17:00",
    "plant_id": "Green-tek-A15978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/5/2024 17:15",
    "plant_id": "Green-tek-A15979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/5/2024 17:30",
    "plant_id": "Green-tek-A15980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/5/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/6/2024 6:00",
    "plant_id": "Green-tek-A15981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/6/2024 6:15",
    "plant_id": "Green-tek-A15982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/6/2024 6:30",
    "plant_id": "Green-tek-A15983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/6/2024 6:45",
    "plant_id": "Green-tek-A15984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/6/2024 7:00",
    "plant_id": "Green-tek-A15985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/6/2024 7:15",
    "plant_id": "Green-tek-A15986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/6/2024 7:30",
    "plant_id": "Green-tek-A15987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "7:30:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/6/2024 7:45",
    "plant_id": "Green-tek-A15988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "7:45:00 AM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/6/2024 8:00",
    "plant_id": "Green-tek-A15989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "8:00:00 AM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/6/2024 8:15",
    "plant_id": "Green-tek-A15990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "8:15:00 AM",
    "power": 0.166,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "3/6/2024 8:30",
    "plant_id": "Green-tek-A15991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "8:30:00 AM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "3/6/2024 8:45",
    "plant_id": "Green-tek-A15992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "8:45:00 AM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "3/6/2024 9:00",
    "plant_id": "Green-tek-A15993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "9:00:00 AM",
    "power": 0.358,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "3/6/2024 9:15",
    "plant_id": "Green-tek-A15994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "9:15:00 AM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "3/6/2024 9:30",
    "plant_id": "Green-tek-A15995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "9:30:00 AM",
    "power": 0.538,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "3/6/2024 9:45",
    "plant_id": "Green-tek-A15996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "9:45:00 AM",
    "power": 0.64,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "3/6/2024 10:00",
    "plant_id": "Green-tek-A15997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "10:00:00 AM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "3/6/2024 10:15",
    "plant_id": "Green-tek-A15998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "10:15:00 AM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "3/6/2024 10:30",
    "plant_id": "Green-tek-A15999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "10:30:00 AM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "3/6/2024 10:45",
    "plant_id": "Green-tek-A16000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "10:45:00 AM",
    "power": 1.044,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "3/6/2024 11:00",
    "plant_id": "Green-tek-A16001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "11:00:00 AM",
    "power": 1.122,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "3/6/2024 11:15",
    "plant_id": "Green-tek-A16002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "11:15:00 AM",
    "power": 1.18,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "3/6/2024 11:30",
    "plant_id": "Green-tek-A16003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "11:30:00 AM",
    "power": 1.217,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "3/6/2024 11:45",
    "plant_id": "Green-tek-A16004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "11:45:00 AM",
    "power": 1.23,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "3/6/2024 12:00",
    "plant_id": "Green-tek-A16005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "12:00:00 PM",
    "power": 1.217,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "3/6/2024 12:15",
    "plant_id": "Green-tek-A16006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "12:15:00 PM",
    "power": 1.18,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "3/6/2024 12:30",
    "plant_id": "Green-tek-A16007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "12:30:00 PM",
    "power": 1.122,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "3/6/2024 12:45",
    "plant_id": "Green-tek-A16008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "12:45:00 PM",
    "power": 1.044,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "3/6/2024 13:00",
    "plant_id": "Green-tek-A16009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "1:00:00 PM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "3/6/2024 13:15",
    "plant_id": "Green-tek-A16010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "1:15:00 PM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "3/6/2024 13:30",
    "plant_id": "Green-tek-A16011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "1:30:00 PM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "3/6/2024 13:45",
    "plant_id": "Green-tek-A16012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "1:45:00 PM",
    "power": 0.64,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "3/6/2024 14:00",
    "plant_id": "Green-tek-A16013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "2:00:00 PM",
    "power": 0.538,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "3/6/2024 14:15",
    "plant_id": "Green-tek-A16014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "2:15:00 PM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "3/6/2024 14:30",
    "plant_id": "Green-tek-A16015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "2:30:00 PM",
    "power": 0.358,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "3/6/2024 14:45",
    "plant_id": "Green-tek-A16016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "2:45:00 PM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "3/6/2024 15:00",
    "plant_id": "Green-tek-A16017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "3:00:00 PM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "3/6/2024 15:15",
    "plant_id": "Green-tek-A16018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "3:15:00 PM",
    "power": 0.166,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "3/6/2024 15:30",
    "plant_id": "Green-tek-A16019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "3:30:00 PM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/6/2024 15:45",
    "plant_id": "Green-tek-A16020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "3:45:00 PM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/6/2024 16:00",
    "plant_id": "Green-tek-A16021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "4:00:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/6/2024 16:15",
    "plant_id": "Green-tek-A16022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/6/2024 16:30",
    "plant_id": "Green-tek-A16023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/6/2024 16:45",
    "plant_id": "Green-tek-A16024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/6/2024 17:00",
    "plant_id": "Green-tek-A16025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/6/2024 17:15",
    "plant_id": "Green-tek-A16026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/6/2024 17:30",
    "plant_id": "Green-tek-A16027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/6/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/7/2024 6:00",
    "plant_id": "Green-tek-A16028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/7/2024 6:15",
    "plant_id": "Green-tek-A16029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/7/2024 6:30",
    "plant_id": "Green-tek-A16030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/7/2024 6:45",
    "plant_id": "Green-tek-A16031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/7/2024 7:00",
    "plant_id": "Green-tek-A16032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/7/2024 7:15",
    "plant_id": "Green-tek-A16033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/7/2024 7:30",
    "plant_id": "Green-tek-A16034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/7/2024 7:45",
    "plant_id": "Green-tek-A16035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "7:45:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/7/2024 8:00",
    "plant_id": "Green-tek-A16036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "8:00:00 AM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/7/2024 8:15",
    "plant_id": "Green-tek-A16037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "8:15:00 AM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/7/2024 8:30",
    "plant_id": "Green-tek-A16038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "8:30:00 AM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/7/2024 8:45",
    "plant_id": "Green-tek-A16039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "8:45:00 AM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/7/2024 9:00",
    "plant_id": "Green-tek-A16040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "9:00:00 AM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "3/7/2024 9:15",
    "plant_id": "Green-tek-A16041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "9:15:00 AM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "3/7/2024 9:30",
    "plant_id": "Green-tek-A16042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "9:30:00 AM",
    "power": 0.393,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "3/7/2024 9:45",
    "plant_id": "Green-tek-A16043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "9:45:00 AM",
    "power": 0.468,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "3/7/2024 10:00",
    "plant_id": "Green-tek-A16044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "10:00:00 AM",
    "power": 0.545,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "3/7/2024 10:15",
    "plant_id": "Green-tek-A16045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "10:15:00 AM",
    "power": 0.622,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "3/7/2024 10:30",
    "plant_id": "Green-tek-A16046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "10:30:00 AM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "3/7/2024 10:45",
    "plant_id": "Green-tek-A16047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "10:45:00 AM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "3/7/2024 11:00",
    "plant_id": "Green-tek-A16048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "11:00:00 AM",
    "power": 0.82,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "3/7/2024 11:15",
    "plant_id": "Green-tek-A16049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "11:15:00 AM",
    "power": 0.863,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "3/7/2024 11:30",
    "plant_id": "Green-tek-A16050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "11:30:00 AM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "3/7/2024 11:45",
    "plant_id": "Green-tek-A16051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "11:45:00 AM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "3/7/2024 12:00",
    "plant_id": "Green-tek-A16052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "12:00:00 PM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "3/7/2024 12:15",
    "plant_id": "Green-tek-A16053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "12:15:00 PM",
    "power": 0.863,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "3/7/2024 12:30",
    "plant_id": "Green-tek-A16054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "12:30:00 PM",
    "power": 0.82,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "3/7/2024 12:45",
    "plant_id": "Green-tek-A16055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "12:45:00 PM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "3/7/2024 13:00",
    "plant_id": "Green-tek-A16056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "1:00:00 PM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "3/7/2024 13:15",
    "plant_id": "Green-tek-A16057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "1:15:00 PM",
    "power": 0.622,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "3/7/2024 13:30",
    "plant_id": "Green-tek-A16058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "1:30:00 PM",
    "power": 0.545,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "3/7/2024 13:45",
    "plant_id": "Green-tek-A16059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "1:45:00 PM",
    "power": 0.468,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "3/7/2024 14:00",
    "plant_id": "Green-tek-A16060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "2:00:00 PM",
    "power": 0.393,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "3/7/2024 14:15",
    "plant_id": "Green-tek-A16061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "2:15:00 PM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "3/7/2024 14:30",
    "plant_id": "Green-tek-A16062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "2:30:00 PM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "3/7/2024 14:45",
    "plant_id": "Green-tek-A16063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "2:45:00 PM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/7/2024 15:00",
    "plant_id": "Green-tek-A16064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "3:00:00 PM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/7/2024 15:15",
    "plant_id": "Green-tek-A16065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "3:15:00 PM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/7/2024 15:30",
    "plant_id": "Green-tek-A16066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "3:30:00 PM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/7/2024 15:45",
    "plant_id": "Green-tek-A16067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "3:45:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/7/2024 16:00",
    "plant_id": "Green-tek-A16068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/7/2024 16:15",
    "plant_id": "Green-tek-A16069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/7/2024 16:30",
    "plant_id": "Green-tek-A16070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/7/2024 16:45",
    "plant_id": "Green-tek-A16071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/7/2024 17:00",
    "plant_id": "Green-tek-A16072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/7/2024 17:15",
    "plant_id": "Green-tek-A16073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/7/2024 17:30",
    "plant_id": "Green-tek-A16074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/7/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/8/2024 6:00",
    "plant_id": "Green-tek-A16075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/8/2024 6:15",
    "plant_id": "Green-tek-A16076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/8/2024 6:30",
    "plant_id": "Green-tek-A16077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/8/2024 6:45",
    "plant_id": "Green-tek-A16078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/8/2024 7:00",
    "plant_id": "Green-tek-A16079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/8/2024 7:15",
    "plant_id": "Green-tek-A16080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/8/2024 7:30",
    "plant_id": "Green-tek-A16081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/8/2024 7:45",
    "plant_id": "Green-tek-A16082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/8/2024 8:00",
    "plant_id": "Green-tek-A16083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "8:00:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/8/2024 8:15",
    "plant_id": "Green-tek-A16084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "8:15:00 AM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/8/2024 8:30",
    "plant_id": "Green-tek-A16085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "8:30:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/8/2024 8:45",
    "plant_id": "Green-tek-A16086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "8:45:00 AM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "3/8/2024 9:00",
    "plant_id": "Green-tek-A16087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "9:00:00 AM",
    "power": 0.244,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "3/8/2024 9:15",
    "plant_id": "Green-tek-A16088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "9:15:00 AM",
    "power": 0.302,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "3/8/2024 9:30",
    "plant_id": "Green-tek-A16089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "9:30:00 AM",
    "power": 0.366,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "3/8/2024 9:45",
    "plant_id": "Green-tek-A16090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "9:45:00 AM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "3/8/2024 10:00",
    "plant_id": "Green-tek-A16091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "10:00:00 AM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "3/8/2024 10:15",
    "plant_id": "Green-tek-A16092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "10:15:00 AM",
    "power": 0.58,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "3/8/2024 10:30",
    "plant_id": "Green-tek-A16093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "10:30:00 AM",
    "power": 0.649,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "3/8/2024 10:45",
    "plant_id": "Green-tek-A16094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "10:45:00 AM",
    "power": 0.711,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "3/8/2024 11:00",
    "plant_id": "Green-tek-A16095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "11:00:00 AM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "3/8/2024 11:15",
    "plant_id": "Green-tek-A16096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "11:15:00 AM",
    "power": 0.804,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "3/8/2024 11:30",
    "plant_id": "Green-tek-A16097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "11:30:00 AM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "3/8/2024 11:45",
    "plant_id": "Green-tek-A16098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "11:45:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "3/8/2024 12:00",
    "plant_id": "Green-tek-A16099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "12:00:00 PM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "3/8/2024 12:15",
    "plant_id": "Green-tek-A16100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "12:15:00 PM",
    "power": 0.804,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "3/8/2024 12:30",
    "plant_id": "Green-tek-A16101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "12:30:00 PM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "3/8/2024 12:45",
    "plant_id": "Green-tek-A16102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "12:45:00 PM",
    "power": 0.711,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "3/8/2024 13:00",
    "plant_id": "Green-tek-A16103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "1:00:00 PM",
    "power": 0.649,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "3/8/2024 13:15",
    "plant_id": "Green-tek-A16104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "1:15:00 PM",
    "power": 0.58,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "3/8/2024 13:30",
    "plant_id": "Green-tek-A16105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "1:30:00 PM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "3/8/2024 13:45",
    "plant_id": "Green-tek-A16106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "1:45:00 PM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "3/8/2024 14:00",
    "plant_id": "Green-tek-A16107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "2:00:00 PM",
    "power": 0.366,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "3/8/2024 14:15",
    "plant_id": "Green-tek-A16108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "2:15:00 PM",
    "power": 0.302,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "3/8/2024 14:30",
    "plant_id": "Green-tek-A16109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "2:30:00 PM",
    "power": 0.244,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "3/8/2024 14:45",
    "plant_id": "Green-tek-A16110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "2:45:00 PM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "3/8/2024 15:00",
    "plant_id": "Green-tek-A16111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "3:00:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/8/2024 15:15",
    "plant_id": "Green-tek-A16112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "3:15:00 PM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/8/2024 15:30",
    "plant_id": "Green-tek-A16113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "3:30:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/8/2024 15:45",
    "plant_id": "Green-tek-A16114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/8/2024 16:00",
    "plant_id": "Green-tek-A16115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/8/2024 16:15",
    "plant_id": "Green-tek-A16116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/8/2024 16:30",
    "plant_id": "Green-tek-A16117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/8/2024 16:45",
    "plant_id": "Green-tek-A16118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/8/2024 17:00",
    "plant_id": "Green-tek-A16119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/8/2024 17:15",
    "plant_id": "Green-tek-A16120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/8/2024 17:30",
    "plant_id": "Green-tek-A16121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/8/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/9/2024 6:00",
    "plant_id": "Green-tek-A16122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/9/2024 6:15",
    "plant_id": "Green-tek-A16123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/9/2024 6:30",
    "plant_id": "Green-tek-A16124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/9/2024 6:45",
    "plant_id": "Green-tek-A16125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/9/2024 7:00",
    "plant_id": "Green-tek-A16126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/9/2024 7:15",
    "plant_id": "Green-tek-A16127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/9/2024 7:30",
    "plant_id": "Green-tek-A16128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "7:30:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/9/2024 7:45",
    "plant_id": "Green-tek-A16129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "7:45:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/9/2024 8:00",
    "plant_id": "Green-tek-A16130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "8:00:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/9/2024 8:15",
    "plant_id": "Green-tek-A16131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "8:15:00 AM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/9/2024 8:30",
    "plant_id": "Green-tek-A16132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "8:30:00 AM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "3/9/2024 8:45",
    "plant_id": "Green-tek-A16133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "8:45:00 AM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "3/9/2024 9:00",
    "plant_id": "Green-tek-A16134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "9:00:00 AM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "3/9/2024 9:15",
    "plant_id": "Green-tek-A16135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "9:15:00 AM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "3/9/2024 9:30",
    "plant_id": "Green-tek-A16136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "9:30:00 AM",
    "power": 0.402,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "3/9/2024 9:45",
    "plant_id": "Green-tek-A16137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "9:45:00 AM",
    "power": 0.478,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "3/9/2024 10:00",
    "plant_id": "Green-tek-A16138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "10:00:00 AM",
    "power": 0.557,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "3/9/2024 10:15",
    "plant_id": "Green-tek-A16139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "10:15:00 AM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "3/9/2024 10:30",
    "plant_id": "Green-tek-A16140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "10:30:00 AM",
    "power": 0.711,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "3/9/2024 10:45",
    "plant_id": "Green-tek-A16141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "10:45:00 AM",
    "power": 0.78,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "3/9/2024 11:00",
    "plant_id": "Green-tek-A16142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "11:00:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "3/9/2024 11:15",
    "plant_id": "Green-tek-A16143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "11:15:00 AM",
    "power": 0.881,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "3/9/2024 11:30",
    "plant_id": "Green-tek-A16144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "11:30:00 AM",
    "power": 0.908,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "3/9/2024 11:45",
    "plant_id": "Green-tek-A16145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "11:45:00 AM",
    "power": 0.918,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "3/9/2024 12:00",
    "plant_id": "Green-tek-A16146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "12:00:00 PM",
    "power": 0.908,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "3/9/2024 12:15",
    "plant_id": "Green-tek-A16147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "12:15:00 PM",
    "power": 0.881,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "3/9/2024 12:30",
    "plant_id": "Green-tek-A16148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "12:30:00 PM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "3/9/2024 12:45",
    "plant_id": "Green-tek-A16149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "12:45:00 PM",
    "power": 0.78,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "3/9/2024 13:00",
    "plant_id": "Green-tek-A16150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "1:00:00 PM",
    "power": 0.711,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "3/9/2024 13:15",
    "plant_id": "Green-tek-A16151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "1:15:00 PM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "3/9/2024 13:30",
    "plant_id": "Green-tek-A16152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "1:30:00 PM",
    "power": 0.557,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "3/9/2024 13:45",
    "plant_id": "Green-tek-A16153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "1:45:00 PM",
    "power": 0.478,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "3/9/2024 14:00",
    "plant_id": "Green-tek-A16154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "2:00:00 PM",
    "power": 0.402,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "3/9/2024 14:15",
    "plant_id": "Green-tek-A16155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "2:15:00 PM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "3/9/2024 14:30",
    "plant_id": "Green-tek-A16156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "2:30:00 PM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "3/9/2024 14:45",
    "plant_id": "Green-tek-A16157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "2:45:00 PM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "3/9/2024 15:00",
    "plant_id": "Green-tek-A16158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "3:00:00 PM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "3/9/2024 15:15",
    "plant_id": "Green-tek-A16159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "3:15:00 PM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/9/2024 15:30",
    "plant_id": "Green-tek-A16160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "3:30:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/9/2024 15:45",
    "plant_id": "Green-tek-A16161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "3:45:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/9/2024 16:00",
    "plant_id": "Green-tek-A16162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "4:00:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/9/2024 16:15",
    "plant_id": "Green-tek-A16163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/9/2024 16:30",
    "plant_id": "Green-tek-A16164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/9/2024 16:45",
    "plant_id": "Green-tek-A16165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/9/2024 17:00",
    "plant_id": "Green-tek-A16166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/9/2024 17:15",
    "plant_id": "Green-tek-A16167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/9/2024 17:30",
    "plant_id": "Green-tek-A16168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/9/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/10/2024 6:00",
    "plant_id": "Green-tek-A16169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/10/2024 6:15",
    "plant_id": "Green-tek-A16170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/10/2024 6:30",
    "plant_id": "Green-tek-A16171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/10/2024 6:45",
    "plant_id": "Green-tek-A16172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/10/2024 7:00",
    "plant_id": "Green-tek-A16173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/10/2024 7:15",
    "plant_id": "Green-tek-A16174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/10/2024 7:30",
    "plant_id": "Green-tek-A16175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/10/2024 7:45",
    "plant_id": "Green-tek-A16176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "7:45:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/10/2024 8:00",
    "plant_id": "Green-tek-A16177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "8:00:00 AM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "3/10/2024 8:15",
    "plant_id": "Green-tek-A16178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "8:15:00 AM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/10/2024 8:30",
    "plant_id": "Green-tek-A16179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "8:30:00 AM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/10/2024 8:45",
    "plant_id": "Green-tek-A16180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "8:45:00 AM",
    "power": 0.27,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "3/10/2024 9:00",
    "plant_id": "Green-tek-A16181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "9:00:00 AM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "3/10/2024 9:15",
    "plant_id": "Green-tek-A16182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "9:15:00 AM",
    "power": 0.422,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "3/10/2024 9:30",
    "plant_id": "Green-tek-A16183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "9:30:00 AM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "3/10/2024 9:45",
    "plant_id": "Green-tek-A16184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "9:45:00 AM",
    "power": 0.61,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "3/10/2024 10:00",
    "plant_id": "Green-tek-A16185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "10:00:00 AM",
    "power": 0.711,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "3/10/2024 10:15",
    "plant_id": "Green-tek-A16186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "10:15:00 AM",
    "power": 0.812,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "3/10/2024 10:30",
    "plant_id": "Green-tek-A16187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "10:30:00 AM",
    "power": 0.908,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "3/10/2024 10:45",
    "plant_id": "Green-tek-A16188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "10:45:00 AM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "3/10/2024 11:00",
    "plant_id": "Green-tek-A16189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "11:00:00 AM",
    "power": 1.069,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "3/10/2024 11:15",
    "plant_id": "Green-tek-A16190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "11:15:00 AM",
    "power": 1.125,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "3/10/2024 11:30",
    "plant_id": "Green-tek-A16191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "11:30:00 AM",
    "power": 1.16,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "3/10/2024 11:45",
    "plant_id": "Green-tek-A16192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "11:45:00 AM",
    "power": 1.172,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "3/10/2024 12:00",
    "plant_id": "Green-tek-A16193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "12:00:00 PM",
    "power": 1.16,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "3/10/2024 12:15",
    "plant_id": "Green-tek-A16194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "12:15:00 PM",
    "power": 1.125,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "3/10/2024 12:30",
    "plant_id": "Green-tek-A16195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "12:30:00 PM",
    "power": 1.069,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "3/10/2024 12:45",
    "plant_id": "Green-tek-A16196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "12:45:00 PM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "3/10/2024 13:00",
    "plant_id": "Green-tek-A16197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "1:00:00 PM",
    "power": 0.908,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "3/10/2024 13:15",
    "plant_id": "Green-tek-A16198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "1:15:00 PM",
    "power": 0.812,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "3/10/2024 13:30",
    "plant_id": "Green-tek-A16199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "1:30:00 PM",
    "power": 0.711,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "3/10/2024 13:45",
    "plant_id": "Green-tek-A16200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "1:45:00 PM",
    "power": 0.61,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "3/10/2024 14:00",
    "plant_id": "Green-tek-A16201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "2:00:00 PM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "3/10/2024 14:15",
    "plant_id": "Green-tek-A16202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "2:15:00 PM",
    "power": 0.422,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "3/10/2024 14:30",
    "plant_id": "Green-tek-A16203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "2:30:00 PM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "3/10/2024 14:45",
    "plant_id": "Green-tek-A16204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "2:45:00 PM",
    "power": 0.27,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "3/10/2024 15:00",
    "plant_id": "Green-tek-A16205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "3:00:00 PM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/10/2024 15:15",
    "plant_id": "Green-tek-A16206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "3:15:00 PM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/10/2024 15:30",
    "plant_id": "Green-tek-A16207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "3:30:00 PM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "3/10/2024 15:45",
    "plant_id": "Green-tek-A16208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "3:45:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/10/2024 16:00",
    "plant_id": "Green-tek-A16209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/10/2024 16:15",
    "plant_id": "Green-tek-A16210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/10/2024 16:30",
    "plant_id": "Green-tek-A16211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/10/2024 16:45",
    "plant_id": "Green-tek-A16212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/10/2024 17:00",
    "plant_id": "Green-tek-A16213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/10/2024 17:15",
    "plant_id": "Green-tek-A16214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/10/2024 17:30",
    "plant_id": "Green-tek-A16215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/10/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/11/2024 6:00",
    "plant_id": "Green-tek-A16216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/11/2024 6:15",
    "plant_id": "Green-tek-A16217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/11/2024 6:30",
    "plant_id": "Green-tek-A16218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/11/2024 6:45",
    "plant_id": "Green-tek-A16219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/11/2024 7:00",
    "plant_id": "Green-tek-A16220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/11/2024 7:15",
    "plant_id": "Green-tek-A16221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/11/2024 7:30",
    "plant_id": "Green-tek-A16222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/11/2024 7:45",
    "plant_id": "Green-tek-A16223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/11/2024 8:00",
    "plant_id": "Green-tek-A16224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "8:00:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/11/2024 8:15",
    "plant_id": "Green-tek-A16225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "8:15:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/11/2024 8:30",
    "plant_id": "Green-tek-A16226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "8:30:00 AM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "3/11/2024 8:45",
    "plant_id": "Green-tek-A16227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "8:45:00 AM",
    "power": 0.254,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "3/11/2024 9:00",
    "plant_id": "Green-tek-A16228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "9:00:00 AM",
    "power": 0.321,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "3/11/2024 9:15",
    "plant_id": "Green-tek-A16229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "9:15:00 AM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/11/2024 9:30",
    "plant_id": "Green-tek-A16230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "9:30:00 AM",
    "power": 0.483,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "3/11/2024 9:45",
    "plant_id": "Green-tek-A16231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "9:45:00 AM",
    "power": 0.574,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "3/11/2024 10:00",
    "plant_id": "Green-tek-A16232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "10:00:00 AM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "3/11/2024 10:15",
    "plant_id": "Green-tek-A16233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "10:15:00 AM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "3/11/2024 10:30",
    "plant_id": "Green-tek-A16234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "10:30:00 AM",
    "power": 0.854,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "3/11/2024 10:45",
    "plant_id": "Green-tek-A16235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "10:45:00 AM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "3/11/2024 11:00",
    "plant_id": "Green-tek-A16236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "11:00:00 AM",
    "power": 1.006,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "3/11/2024 11:15",
    "plant_id": "Green-tek-A16237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "11:15:00 AM",
    "power": 1.059,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "3/11/2024 11:30",
    "plant_id": "Green-tek-A16238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "11:30:00 AM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "3/11/2024 11:45",
    "plant_id": "Green-tek-A16239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "11:45:00 AM",
    "power": 1.103,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "3/11/2024 12:00",
    "plant_id": "Green-tek-A16240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "12:00:00 PM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "3/11/2024 12:15",
    "plant_id": "Green-tek-A16241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "12:15:00 PM",
    "power": 1.059,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "3/11/2024 12:30",
    "plant_id": "Green-tek-A16242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "12:30:00 PM",
    "power": 1.006,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "3/11/2024 12:45",
    "plant_id": "Green-tek-A16243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "12:45:00 PM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "3/11/2024 13:00",
    "plant_id": "Green-tek-A16244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "1:00:00 PM",
    "power": 0.854,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "3/11/2024 13:15",
    "plant_id": "Green-tek-A16245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "1:15:00 PM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "3/11/2024 13:30",
    "plant_id": "Green-tek-A16246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "1:30:00 PM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "3/11/2024 13:45",
    "plant_id": "Green-tek-A16247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "1:45:00 PM",
    "power": 0.574,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "3/11/2024 14:00",
    "plant_id": "Green-tek-A16248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "2:00:00 PM",
    "power": 0.483,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "3/11/2024 14:15",
    "plant_id": "Green-tek-A16249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "2:15:00 PM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/11/2024 14:30",
    "plant_id": "Green-tek-A16250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "2:30:00 PM",
    "power": 0.321,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "3/11/2024 14:45",
    "plant_id": "Green-tek-A16251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "2:45:00 PM",
    "power": 0.254,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "3/11/2024 15:00",
    "plant_id": "Green-tek-A16252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "3:00:00 PM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "3/11/2024 15:15",
    "plant_id": "Green-tek-A16253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "3:15:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/11/2024 15:30",
    "plant_id": "Green-tek-A16254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "3:30:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/11/2024 15:45",
    "plant_id": "Green-tek-A16255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/11/2024 16:00",
    "plant_id": "Green-tek-A16256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/11/2024 16:15",
    "plant_id": "Green-tek-A16257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/11/2024 16:30",
    "plant_id": "Green-tek-A16258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/11/2024 16:45",
    "plant_id": "Green-tek-A16259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/11/2024 17:00",
    "plant_id": "Green-tek-A16260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/11/2024 17:15",
    "plant_id": "Green-tek-A16261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/11/2024 17:30",
    "plant_id": "Green-tek-A16262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/11/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/12/2024 6:00",
    "plant_id": "Green-tek-A16263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/12/2024 6:15",
    "plant_id": "Green-tek-A16264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/12/2024 6:30",
    "plant_id": "Green-tek-A16265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/12/2024 6:45",
    "plant_id": "Green-tek-A16266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/12/2024 7:00",
    "plant_id": "Green-tek-A16267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/12/2024 7:15",
    "plant_id": "Green-tek-A16268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/12/2024 7:30",
    "plant_id": "Green-tek-A16269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/12/2024 7:45",
    "plant_id": "Green-tek-A16270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "7:45:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/12/2024 8:00",
    "plant_id": "Green-tek-A16271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "8:00:00 AM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/12/2024 8:15",
    "plant_id": "Green-tek-A16272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "8:15:00 AM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/12/2024 8:30",
    "plant_id": "Green-tek-A16273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "8:30:00 AM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "3/12/2024 8:45",
    "plant_id": "Green-tek-A16274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "8:45:00 AM",
    "power": 0.274,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "3/12/2024 9:00",
    "plant_id": "Green-tek-A16275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "9:00:00 AM",
    "power": 0.346,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "3/12/2024 9:15",
    "plant_id": "Green-tek-A16276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "9:15:00 AM",
    "power": 0.429,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "3/12/2024 9:30",
    "plant_id": "Green-tek-A16277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "9:30:00 AM",
    "power": 0.521,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "3/12/2024 9:45",
    "plant_id": "Green-tek-A16278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "9:45:00 AM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "3/12/2024 10:00",
    "plant_id": "Green-tek-A16279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "10:00:00 AM",
    "power": 0.722,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "3/12/2024 10:15",
    "plant_id": "Green-tek-A16280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "10:15:00 AM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "3/12/2024 10:30",
    "plant_id": "Green-tek-A16281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "10:30:00 AM",
    "power": 0.922,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "3/12/2024 10:45",
    "plant_id": "Green-tek-A16282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "10:45:00 AM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "3/12/2024 11:00",
    "plant_id": "Green-tek-A16283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "11:00:00 AM",
    "power": 1.086,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "3/12/2024 11:15",
    "plant_id": "Green-tek-A16284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "11:15:00 AM",
    "power": 1.143,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "3/12/2024 11:30",
    "plant_id": "Green-tek-A16285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "11:30:00 AM",
    "power": 1.178,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "3/12/2024 11:45",
    "plant_id": "Green-tek-A16286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "11:45:00 AM",
    "power": 1.19,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "3/12/2024 12:00",
    "plant_id": "Green-tek-A16287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "12:00:00 PM",
    "power": 1.178,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "3/12/2024 12:15",
    "plant_id": "Green-tek-A16288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "12:15:00 PM",
    "power": 1.143,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "3/12/2024 12:30",
    "plant_id": "Green-tek-A16289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "12:30:00 PM",
    "power": 1.086,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "3/12/2024 12:45",
    "plant_id": "Green-tek-A16290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "12:45:00 PM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "3/12/2024 13:00",
    "plant_id": "Green-tek-A16291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "1:00:00 PM",
    "power": 0.922,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "3/12/2024 13:15",
    "plant_id": "Green-tek-A16292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "1:15:00 PM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "3/12/2024 13:30",
    "plant_id": "Green-tek-A16293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "1:30:00 PM",
    "power": 0.722,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "3/12/2024 13:45",
    "plant_id": "Green-tek-A16294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "1:45:00 PM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "3/12/2024 14:00",
    "plant_id": "Green-tek-A16295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "2:00:00 PM",
    "power": 0.521,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "3/12/2024 14:15",
    "plant_id": "Green-tek-A16296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "2:15:00 PM",
    "power": 0.429,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "3/12/2024 14:30",
    "plant_id": "Green-tek-A16297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "2:30:00 PM",
    "power": 0.346,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "3/12/2024 14:45",
    "plant_id": "Green-tek-A16298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "2:45:00 PM",
    "power": 0.274,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "3/12/2024 15:00",
    "plant_id": "Green-tek-A16299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "3:00:00 PM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "3/12/2024 15:15",
    "plant_id": "Green-tek-A16300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "3:15:00 PM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/12/2024 15:30",
    "plant_id": "Green-tek-A16301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "3:30:00 PM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/12/2024 15:45",
    "plant_id": "Green-tek-A16302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "3:45:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/12/2024 16:00",
    "plant_id": "Green-tek-A16303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/12/2024 16:15",
    "plant_id": "Green-tek-A16304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/12/2024 16:30",
    "plant_id": "Green-tek-A16305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/12/2024 16:45",
    "plant_id": "Green-tek-A16306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/12/2024 17:00",
    "plant_id": "Green-tek-A16307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/12/2024 17:15",
    "plant_id": "Green-tek-A16308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/12/2024 17:30",
    "plant_id": "Green-tek-A16309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/12/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/13/2024 6:00",
    "plant_id": "Green-tek-A16310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/13/2024 6:15",
    "plant_id": "Green-tek-A16311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/13/2024 6:30",
    "plant_id": "Green-tek-A16312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/13/2024 6:45",
    "plant_id": "Green-tek-A16313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/13/2024 7:00",
    "plant_id": "Green-tek-A16314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/13/2024 7:15",
    "plant_id": "Green-tek-A16315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/13/2024 7:30",
    "plant_id": "Green-tek-A16316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/13/2024 7:45",
    "plant_id": "Green-tek-A16317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "3/13/2024 8:00",
    "plant_id": "Green-tek-A16318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "8:00:00 AM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "3/13/2024 8:15",
    "plant_id": "Green-tek-A16319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "8:15:00 AM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "3/13/2024 8:30",
    "plant_id": "Green-tek-A16320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "8:30:00 AM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "3/13/2024 8:45",
    "plant_id": "Green-tek-A16321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "8:45:00 AM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "3/13/2024 9:00",
    "plant_id": "Green-tek-A16322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "9:00:00 AM",
    "power": 0.306,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "3/13/2024 9:15",
    "plant_id": "Green-tek-A16323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "9:15:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "3/13/2024 9:30",
    "plant_id": "Green-tek-A16324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "9:30:00 AM",
    "power": 0.46,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "3/13/2024 9:45",
    "plant_id": "Green-tek-A16325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "9:45:00 AM",
    "power": 0.547,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/13/2024 10:00",
    "plant_id": "Green-tek-A16326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "10:00:00 AM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "3/13/2024 10:15",
    "plant_id": "Green-tek-A16327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "10:15:00 AM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "3/13/2024 10:30",
    "plant_id": "Green-tek-A16328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "10:30:00 AM",
    "power": 0.814,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "3/13/2024 10:45",
    "plant_id": "Green-tek-A16329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "10:45:00 AM",
    "power": 0.892,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "3/13/2024 11:00",
    "plant_id": "Green-tek-A16330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "11:00:00 AM",
    "power": 0.958,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "3/13/2024 11:15",
    "plant_id": "Green-tek-A16331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "11:15:00 AM",
    "power": 1.009,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "3/13/2024 11:30",
    "plant_id": "Green-tek-A16332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "11:30:00 AM",
    "power": 1.04,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "3/13/2024 11:45",
    "plant_id": "Green-tek-A16333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "11:45:00 AM",
    "power": 1.051,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "3/13/2024 12:00",
    "plant_id": "Green-tek-A16334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "12:00:00 PM",
    "power": 1.04,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "3/13/2024 12:15",
    "plant_id": "Green-tek-A16335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "12:15:00 PM",
    "power": 1.009,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "3/13/2024 12:30",
    "plant_id": "Green-tek-A16336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "12:30:00 PM",
    "power": 0.958,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "3/13/2024 12:45",
    "plant_id": "Green-tek-A16337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "12:45:00 PM",
    "power": 0.892,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "3/13/2024 13:00",
    "plant_id": "Green-tek-A16338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "1:00:00 PM",
    "power": 0.814,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "3/13/2024 13:15",
    "plant_id": "Green-tek-A16339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "1:15:00 PM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "3/13/2024 13:30",
    "plant_id": "Green-tek-A16340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "1:30:00 PM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "3/13/2024 13:45",
    "plant_id": "Green-tek-A16341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "1:45:00 PM",
    "power": 0.547,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/13/2024 14:00",
    "plant_id": "Green-tek-A16342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "2:00:00 PM",
    "power": 0.46,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "3/13/2024 14:15",
    "plant_id": "Green-tek-A16343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "2:15:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "3/13/2024 14:30",
    "plant_id": "Green-tek-A16344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "2:30:00 PM",
    "power": 0.306,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "3/13/2024 14:45",
    "plant_id": "Green-tek-A16345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "2:45:00 PM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "3/13/2024 15:00",
    "plant_id": "Green-tek-A16346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "3:00:00 PM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "3/13/2024 15:15",
    "plant_id": "Green-tek-A16347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "3:15:00 PM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "3/13/2024 15:30",
    "plant_id": "Green-tek-A16348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "3:30:00 PM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "3/13/2024 15:45",
    "plant_id": "Green-tek-A16349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "3/13/2024 16:00",
    "plant_id": "Green-tek-A16350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/13/2024 16:15",
    "plant_id": "Green-tek-A16351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/13/2024 16:30",
    "plant_id": "Green-tek-A16352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/13/2024 16:45",
    "plant_id": "Green-tek-A16353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/13/2024 17:00",
    "plant_id": "Green-tek-A16354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/13/2024 17:15",
    "plant_id": "Green-tek-A16355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/13/2024 17:30",
    "plant_id": "Green-tek-A16356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/13/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/14/2024 6:00",
    "plant_id": "Green-tek-A16357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/14/2024 6:15",
    "plant_id": "Green-tek-A16358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/14/2024 6:30",
    "plant_id": "Green-tek-A16359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/14/2024 6:45",
    "plant_id": "Green-tek-A16360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/14/2024 7:00",
    "plant_id": "Green-tek-A16361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/14/2024 7:15",
    "plant_id": "Green-tek-A16362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/14/2024 7:30",
    "plant_id": "Green-tek-A16363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "7:30:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/14/2024 7:45",
    "plant_id": "Green-tek-A16364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "7:45:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/14/2024 8:00",
    "plant_id": "Green-tek-A16365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "8:00:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/14/2024 8:15",
    "plant_id": "Green-tek-A16366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "8:15:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/14/2024 8:30",
    "plant_id": "Green-tek-A16367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "8:30:00 AM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "3/14/2024 8:45",
    "plant_id": "Green-tek-A16368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "8:45:00 AM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "3/14/2024 9:00",
    "plant_id": "Green-tek-A16369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "9:00:00 AM",
    "power": 0.268,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "3/14/2024 9:15",
    "plant_id": "Green-tek-A16370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "9:15:00 AM",
    "power": 0.332,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "3/14/2024 9:30",
    "plant_id": "Green-tek-A16371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "9:30:00 AM",
    "power": 0.403,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "3/14/2024 9:45",
    "plant_id": "Green-tek-A16372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "9:45:00 AM",
    "power": 0.479,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "3/14/2024 10:00",
    "plant_id": "Green-tek-A16373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "10:00:00 AM",
    "power": 0.558,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "3/14/2024 10:15",
    "plant_id": "Green-tek-A16374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "10:15:00 AM",
    "power": 0.638,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "3/14/2024 10:30",
    "plant_id": "Green-tek-A16375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "10:30:00 AM",
    "power": 0.713,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "3/14/2024 10:45",
    "plant_id": "Green-tek-A16376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "10:45:00 AM",
    "power": 0.782,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "3/14/2024 11:00",
    "plant_id": "Green-tek-A16377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "11:00:00 AM",
    "power": 0.84,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "3/14/2024 11:15",
    "plant_id": "Green-tek-A16378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "11:15:00 AM",
    "power": 0.884,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "3/14/2024 11:30",
    "plant_id": "Green-tek-A16379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "11:30:00 AM",
    "power": 0.911,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "3/14/2024 11:45",
    "plant_id": "Green-tek-A16380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "11:45:00 AM",
    "power": 0.921,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "3/14/2024 12:00",
    "plant_id": "Green-tek-A16381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "12:00:00 PM",
    "power": 0.911,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "3/14/2024 12:15",
    "plant_id": "Green-tek-A16382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "12:15:00 PM",
    "power": 0.884,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "3/14/2024 12:30",
    "plant_id": "Green-tek-A16383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "12:30:00 PM",
    "power": 0.84,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "3/14/2024 12:45",
    "plant_id": "Green-tek-A16384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "12:45:00 PM",
    "power": 0.782,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "3/14/2024 13:00",
    "plant_id": "Green-tek-A16385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "1:00:00 PM",
    "power": 0.713,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "3/14/2024 13:15",
    "plant_id": "Green-tek-A16386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "1:15:00 PM",
    "power": 0.638,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "3/14/2024 13:30",
    "plant_id": "Green-tek-A16387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "1:30:00 PM",
    "power": 0.558,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "3/14/2024 13:45",
    "plant_id": "Green-tek-A16388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "1:45:00 PM",
    "power": 0.479,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "3/14/2024 14:00",
    "plant_id": "Green-tek-A16389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "2:00:00 PM",
    "power": 0.403,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "3/14/2024 14:15",
    "plant_id": "Green-tek-A16390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "2:15:00 PM",
    "power": 0.332,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "3/14/2024 14:30",
    "plant_id": "Green-tek-A16391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "2:30:00 PM",
    "power": 0.268,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "3/14/2024 14:45",
    "plant_id": "Green-tek-A16392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "2:45:00 PM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "3/14/2024 15:00",
    "plant_id": "Green-tek-A16393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "3:00:00 PM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "3/14/2024 15:15",
    "plant_id": "Green-tek-A16394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "3:15:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/14/2024 15:30",
    "plant_id": "Green-tek-A16395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "3:30:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/14/2024 15:45",
    "plant_id": "Green-tek-A16396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "3:45:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/14/2024 16:00",
    "plant_id": "Green-tek-A16397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "4:00:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/14/2024 16:15",
    "plant_id": "Green-tek-A16398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/14/2024 16:30",
    "plant_id": "Green-tek-A16399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/14/2024 16:45",
    "plant_id": "Green-tek-A16400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/14/2024 17:00",
    "plant_id": "Green-tek-A16401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/14/2024 17:15",
    "plant_id": "Green-tek-A16402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/14/2024 17:30",
    "plant_id": "Green-tek-A16403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/14/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/15/2024 6:00",
    "plant_id": "Green-tek-A16404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/15/2024 6:15",
    "plant_id": "Green-tek-A16405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/15/2024 6:30",
    "plant_id": "Green-tek-A16406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/15/2024 6:45",
    "plant_id": "Green-tek-A16407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/15/2024 7:00",
    "plant_id": "Green-tek-A16408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/15/2024 7:15",
    "plant_id": "Green-tek-A16409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/15/2024 7:30",
    "plant_id": "Green-tek-A16410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/15/2024 7:45",
    "plant_id": "Green-tek-A16411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/15/2024 8:00",
    "plant_id": "Green-tek-A16412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "8:00:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/15/2024 8:15",
    "plant_id": "Green-tek-A16413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "8:15:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/15/2024 8:30",
    "plant_id": "Green-tek-A16414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "8:30:00 AM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/15/2024 8:45",
    "plant_id": "Green-tek-A16415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "8:45:00 AM",
    "power": 0.191,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "3/15/2024 9:00",
    "plant_id": "Green-tek-A16416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "9:00:00 AM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "3/15/2024 9:15",
    "plant_id": "Green-tek-A16417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "9:15:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "3/15/2024 9:30",
    "plant_id": "Green-tek-A16418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "9:30:00 AM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "3/15/2024 9:45",
    "plant_id": "Green-tek-A16419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "9:45:00 AM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "3/15/2024 10:00",
    "plant_id": "Green-tek-A16420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "10:00:00 AM",
    "power": 0.503,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "3/15/2024 10:15",
    "plant_id": "Green-tek-A16421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "10:15:00 AM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "3/15/2024 10:30",
    "plant_id": "Green-tek-A16422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "10:30:00 AM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "3/15/2024 10:45",
    "plant_id": "Green-tek-A16423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "10:45:00 AM",
    "power": 0.705,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "3/15/2024 11:00",
    "plant_id": "Green-tek-A16424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "11:00:00 AM",
    "power": 0.757,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "3/15/2024 11:15",
    "plant_id": "Green-tek-A16425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "11:15:00 AM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "3/15/2024 11:30",
    "plant_id": "Green-tek-A16426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "11:30:00 AM",
    "power": 0.822,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "3/15/2024 11:45",
    "plant_id": "Green-tek-A16427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "11:45:00 AM",
    "power": 0.83,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "3/15/2024 12:00",
    "plant_id": "Green-tek-A16428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "12:00:00 PM",
    "power": 0.822,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "3/15/2024 12:15",
    "plant_id": "Green-tek-A16429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "12:15:00 PM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "3/15/2024 12:30",
    "plant_id": "Green-tek-A16430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "12:30:00 PM",
    "power": 0.757,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "3/15/2024 12:45",
    "plant_id": "Green-tek-A16431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "12:45:00 PM",
    "power": 0.705,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "3/15/2024 13:00",
    "plant_id": "Green-tek-A16432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "1:00:00 PM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "3/15/2024 13:15",
    "plant_id": "Green-tek-A16433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "1:15:00 PM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "3/15/2024 13:30",
    "plant_id": "Green-tek-A16434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "1:30:00 PM",
    "power": 0.503,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "3/15/2024 13:45",
    "plant_id": "Green-tek-A16435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "1:45:00 PM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "3/15/2024 14:00",
    "plant_id": "Green-tek-A16436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "2:00:00 PM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "3/15/2024 14:15",
    "plant_id": "Green-tek-A16437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "2:15:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "3/15/2024 14:30",
    "plant_id": "Green-tek-A16438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "2:30:00 PM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "3/15/2024 14:45",
    "plant_id": "Green-tek-A16439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "2:45:00 PM",
    "power": 0.191,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "3/15/2024 15:00",
    "plant_id": "Green-tek-A16440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "3:00:00 PM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/15/2024 15:15",
    "plant_id": "Green-tek-A16441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "3:15:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/15/2024 15:30",
    "plant_id": "Green-tek-A16442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "3:30:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/15/2024 15:45",
    "plant_id": "Green-tek-A16443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/15/2024 16:00",
    "plant_id": "Green-tek-A16444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/15/2024 16:15",
    "plant_id": "Green-tek-A16445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/15/2024 16:30",
    "plant_id": "Green-tek-A16446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/15/2024 16:45",
    "plant_id": "Green-tek-A16447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/15/2024 17:00",
    "plant_id": "Green-tek-A16448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/15/2024 17:15",
    "plant_id": "Green-tek-A16449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/15/2024 17:30",
    "plant_id": "Green-tek-A16450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/15/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/16/2024 6:00",
    "plant_id": "Green-tek-A16451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/16/2024 6:15",
    "plant_id": "Green-tek-A16452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/16/2024 6:30",
    "plant_id": "Green-tek-A16453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/16/2024 6:45",
    "plant_id": "Green-tek-A16454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/16/2024 7:00",
    "plant_id": "Green-tek-A16455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/16/2024 7:15",
    "plant_id": "Green-tek-A16456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/16/2024 7:30",
    "plant_id": "Green-tek-A16457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/16/2024 7:45",
    "plant_id": "Green-tek-A16458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "7:45:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/16/2024 8:00",
    "plant_id": "Green-tek-A16459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "8:00:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/16/2024 8:15",
    "plant_id": "Green-tek-A16460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "8:15:00 AM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/16/2024 8:30",
    "plant_id": "Green-tek-A16461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "8:30:00 AM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/16/2024 8:45",
    "plant_id": "Green-tek-A16462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "8:45:00 AM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/16/2024 9:00",
    "plant_id": "Green-tek-A16463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "9:00:00 AM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "3/16/2024 9:15",
    "plant_id": "Green-tek-A16464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "9:15:00 AM",
    "power": 0.326,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "3/16/2024 9:30",
    "plant_id": "Green-tek-A16465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "9:30:00 AM",
    "power": 0.395,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/16/2024 9:45",
    "plant_id": "Green-tek-A16466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "9:45:00 AM",
    "power": 0.47,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "3/16/2024 10:00",
    "plant_id": "Green-tek-A16467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "10:00:00 AM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/16/2024 10:15",
    "plant_id": "Green-tek-A16468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "10:15:00 AM",
    "power": 0.626,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "3/16/2024 10:30",
    "plant_id": "Green-tek-A16469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "10:30:00 AM",
    "power": 0.7,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "3/16/2024 10:45",
    "plant_id": "Green-tek-A16470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "10:45:00 AM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "3/16/2024 11:00",
    "plant_id": "Green-tek-A16471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "11:00:00 AM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "3/16/2024 11:15",
    "plant_id": "Green-tek-A16472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "11:15:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/16/2024 11:30",
    "plant_id": "Green-tek-A16473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "11:30:00 AM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "3/16/2024 11:45",
    "plant_id": "Green-tek-A16474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "11:45:00 AM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "3/16/2024 12:00",
    "plant_id": "Green-tek-A16475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "12:00:00 PM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "3/16/2024 12:15",
    "plant_id": "Green-tek-A16476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "12:15:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/16/2024 12:30",
    "plant_id": "Green-tek-A16477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "12:30:00 PM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "3/16/2024 12:45",
    "plant_id": "Green-tek-A16478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "12:45:00 PM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "3/16/2024 13:00",
    "plant_id": "Green-tek-A16479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "1:00:00 PM",
    "power": 0.7,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "3/16/2024 13:15",
    "plant_id": "Green-tek-A16480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "1:15:00 PM",
    "power": 0.626,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "3/16/2024 13:30",
    "plant_id": "Green-tek-A16481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "1:30:00 PM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/16/2024 13:45",
    "plant_id": "Green-tek-A16482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "1:45:00 PM",
    "power": 0.47,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "3/16/2024 14:00",
    "plant_id": "Green-tek-A16483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "2:00:00 PM",
    "power": 0.395,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/16/2024 14:15",
    "plant_id": "Green-tek-A16484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "2:15:00 PM",
    "power": 0.326,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "3/16/2024 14:30",
    "plant_id": "Green-tek-A16485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "2:30:00 PM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "3/16/2024 14:45",
    "plant_id": "Green-tek-A16486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "2:45:00 PM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/16/2024 15:00",
    "plant_id": "Green-tek-A16487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "3:00:00 PM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/16/2024 15:15",
    "plant_id": "Green-tek-A16488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "3:15:00 PM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/16/2024 15:30",
    "plant_id": "Green-tek-A16489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "3:30:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/16/2024 15:45",
    "plant_id": "Green-tek-A16490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "3:45:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/16/2024 16:00",
    "plant_id": "Green-tek-A16491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/16/2024 16:15",
    "plant_id": "Green-tek-A16492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/16/2024 16:30",
    "plant_id": "Green-tek-A16493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/16/2024 16:45",
    "plant_id": "Green-tek-A16494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/16/2024 17:00",
    "plant_id": "Green-tek-A16495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/16/2024 17:15",
    "plant_id": "Green-tek-A16496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/16/2024 17:30",
    "plant_id": "Green-tek-A16497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/16/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/17/2024 6:00",
    "plant_id": "Green-tek-A16498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/17/2024 6:15",
    "plant_id": "Green-tek-A16499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/17/2024 6:30",
    "plant_id": "Green-tek-A16500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/17/2024 6:45",
    "plant_id": "Green-tek-A16501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/17/2024 7:00",
    "plant_id": "Green-tek-A16502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/17/2024 7:15",
    "plant_id": "Green-tek-A16503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/17/2024 7:30",
    "plant_id": "Green-tek-A16504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "7:30:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/17/2024 7:45",
    "plant_id": "Green-tek-A16505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "7:45:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/17/2024 8:00",
    "plant_id": "Green-tek-A16506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "8:00:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/17/2024 8:15",
    "plant_id": "Green-tek-A16507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "8:15:00 AM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "3/17/2024 8:30",
    "plant_id": "Green-tek-A16508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "8:30:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "3/17/2024 8:45",
    "plant_id": "Green-tek-A16509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "8:45:00 AM",
    "power": 0.185,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "3/17/2024 9:00",
    "plant_id": "Green-tek-A16510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "9:00:00 AM",
    "power": 0.234,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "3/17/2024 9:15",
    "plant_id": "Green-tek-A16511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "9:15:00 AM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "3/17/2024 9:30",
    "plant_id": "Green-tek-A16512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "9:30:00 AM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "3/17/2024 9:45",
    "plant_id": "Green-tek-A16513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "9:45:00 AM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "3/17/2024 10:00",
    "plant_id": "Green-tek-A16514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "10:00:00 AM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "3/17/2024 10:15",
    "plant_id": "Green-tek-A16515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "10:15:00 AM",
    "power": 0.558,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "3/17/2024 10:30",
    "plant_id": "Green-tek-A16516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "10:30:00 AM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "3/17/2024 10:45",
    "plant_id": "Green-tek-A16517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "10:45:00 AM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "3/17/2024 11:00",
    "plant_id": "Green-tek-A16518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "11:00:00 AM",
    "power": 0.734,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "3/17/2024 11:15",
    "plant_id": "Green-tek-A16519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "11:15:00 AM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/17/2024 11:30",
    "plant_id": "Green-tek-A16520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "11:30:00 AM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "3/17/2024 11:45",
    "plant_id": "Green-tek-A16521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "11:45:00 AM",
    "power": 0.805,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "3/17/2024 12:00",
    "plant_id": "Green-tek-A16522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "12:00:00 PM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "3/17/2024 12:15",
    "plant_id": "Green-tek-A16523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "12:15:00 PM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/17/2024 12:30",
    "plant_id": "Green-tek-A16524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "12:30:00 PM",
    "power": 0.734,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "3/17/2024 12:45",
    "plant_id": "Green-tek-A16525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "12:45:00 PM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "3/17/2024 13:00",
    "plant_id": "Green-tek-A16526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "1:00:00 PM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "3/17/2024 13:15",
    "plant_id": "Green-tek-A16527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "1:15:00 PM",
    "power": 0.558,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "3/17/2024 13:30",
    "plant_id": "Green-tek-A16528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "1:30:00 PM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "3/17/2024 13:45",
    "plant_id": "Green-tek-A16529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "1:45:00 PM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "3/17/2024 14:00",
    "plant_id": "Green-tek-A16530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "2:00:00 PM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "3/17/2024 14:15",
    "plant_id": "Green-tek-A16531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "2:15:00 PM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "3/17/2024 14:30",
    "plant_id": "Green-tek-A16532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "2:30:00 PM",
    "power": 0.234,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "3/17/2024 14:45",
    "plant_id": "Green-tek-A16533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "2:45:00 PM",
    "power": 0.185,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "3/17/2024 15:00",
    "plant_id": "Green-tek-A16534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "3:00:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "3/17/2024 15:15",
    "plant_id": "Green-tek-A16535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "3:15:00 PM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "3/17/2024 15:30",
    "plant_id": "Green-tek-A16536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "3:30:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/17/2024 15:45",
    "plant_id": "Green-tek-A16537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "3:45:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/17/2024 16:00",
    "plant_id": "Green-tek-A16538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "4:00:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/17/2024 16:15",
    "plant_id": "Green-tek-A16539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/17/2024 16:30",
    "plant_id": "Green-tek-A16540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/17/2024 16:45",
    "plant_id": "Green-tek-A16541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/17/2024 17:00",
    "plant_id": "Green-tek-A16542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/17/2024 17:15",
    "plant_id": "Green-tek-A16543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/17/2024 17:30",
    "plant_id": "Green-tek-A16544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/17/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/18/2024 6:00",
    "plant_id": "Green-tek-A16545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/18/2024 6:15",
    "plant_id": "Green-tek-A16546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/18/2024 6:30",
    "plant_id": "Green-tek-A16547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/18/2024 6:45",
    "plant_id": "Green-tek-A16548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/18/2024 7:00",
    "plant_id": "Green-tek-A16549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/18/2024 7:15",
    "plant_id": "Green-tek-A16550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/18/2024 7:30",
    "plant_id": "Green-tek-A16551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/18/2024 7:45",
    "plant_id": "Green-tek-A16552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "7:45:00 AM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/18/2024 8:00",
    "plant_id": "Green-tek-A16553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "8:00:00 AM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "3/18/2024 8:15",
    "plant_id": "Green-tek-A16554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "8:15:00 AM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/18/2024 8:30",
    "plant_id": "Green-tek-A16555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "8:30:00 AM",
    "power": 0.194,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "3/18/2024 8:45",
    "plant_id": "Green-tek-A16556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "8:45:00 AM",
    "power": 0.25,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "3/18/2024 9:00",
    "plant_id": "Green-tek-A16557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "9:00:00 AM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "3/18/2024 9:15",
    "plant_id": "Green-tek-A16558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "9:15:00 AM",
    "power": 0.392,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "3/18/2024 9:30",
    "plant_id": "Green-tek-A16559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "9:30:00 AM",
    "power": 0.476,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "3/18/2024 9:45",
    "plant_id": "Green-tek-A16560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "9:45:00 AM",
    "power": 0.566,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "3/18/2024 10:00",
    "plant_id": "Green-tek-A16561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "10:00:00 AM",
    "power": 0.66,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "3/18/2024 10:15",
    "plant_id": "Green-tek-A16562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "10:15:00 AM",
    "power": 0.754,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "3/18/2024 10:30",
    "plant_id": "Green-tek-A16563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "10:30:00 AM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "3/18/2024 10:45",
    "plant_id": "Green-tek-A16564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "10:45:00 AM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "3/18/2024 11:00",
    "plant_id": "Green-tek-A16565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "11:00:00 AM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "3/18/2024 11:15",
    "plant_id": "Green-tek-A16566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "11:15:00 AM",
    "power": 1.045,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "3/18/2024 11:30",
    "plant_id": "Green-tek-A16567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "11:30:00 AM",
    "power": 1.077,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "3/18/2024 11:45",
    "plant_id": "Green-tek-A16568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "11:45:00 AM",
    "power": 1.088,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "3/18/2024 12:00",
    "plant_id": "Green-tek-A16569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "12:00:00 PM",
    "power": 1.077,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "3/18/2024 12:15",
    "plant_id": "Green-tek-A16570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "12:15:00 PM",
    "power": 1.045,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "3/18/2024 12:30",
    "plant_id": "Green-tek-A16571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "12:30:00 PM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "3/18/2024 12:45",
    "plant_id": "Green-tek-A16572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "12:45:00 PM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "3/18/2024 13:00",
    "plant_id": "Green-tek-A16573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "1:00:00 PM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "3/18/2024 13:15",
    "plant_id": "Green-tek-A16574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "1:15:00 PM",
    "power": 0.754,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "3/18/2024 13:30",
    "plant_id": "Green-tek-A16575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "1:30:00 PM",
    "power": 0.66,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "3/18/2024 13:45",
    "plant_id": "Green-tek-A16576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "1:45:00 PM",
    "power": 0.566,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "3/18/2024 14:00",
    "plant_id": "Green-tek-A16577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "2:00:00 PM",
    "power": 0.476,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "3/18/2024 14:15",
    "plant_id": "Green-tek-A16578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "2:15:00 PM",
    "power": 0.392,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "3/18/2024 14:30",
    "plant_id": "Green-tek-A16579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "2:30:00 PM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "3/18/2024 14:45",
    "plant_id": "Green-tek-A16580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "2:45:00 PM",
    "power": 0.25,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "3/18/2024 15:00",
    "plant_id": "Green-tek-A16581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "3:00:00 PM",
    "power": 0.194,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "3/18/2024 15:15",
    "plant_id": "Green-tek-A16582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "3:15:00 PM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/18/2024 15:30",
    "plant_id": "Green-tek-A16583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "3:30:00 PM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "3/18/2024 15:45",
    "plant_id": "Green-tek-A16584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "3:45:00 PM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/18/2024 16:00",
    "plant_id": "Green-tek-A16585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/18/2024 16:15",
    "plant_id": "Green-tek-A16586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/18/2024 16:30",
    "plant_id": "Green-tek-A16587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/18/2024 16:45",
    "plant_id": "Green-tek-A16588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/18/2024 17:00",
    "plant_id": "Green-tek-A16589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/18/2024 17:15",
    "plant_id": "Green-tek-A16590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/18/2024 17:30",
    "plant_id": "Green-tek-A16591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/18/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/19/2024 6:00",
    "plant_id": "Green-tek-A16592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/19/2024 6:15",
    "plant_id": "Green-tek-A16593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/19/2024 6:30",
    "plant_id": "Green-tek-A16594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/19/2024 6:45",
    "plant_id": "Green-tek-A16595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/19/2024 7:00",
    "plant_id": "Green-tek-A16596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/19/2024 7:15",
    "plant_id": "Green-tek-A16597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/19/2024 7:30",
    "plant_id": "Green-tek-A16598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/19/2024 7:45",
    "plant_id": "Green-tek-A16599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/19/2024 8:00",
    "plant_id": "Green-tek-A16600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "8:00:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/19/2024 8:15",
    "plant_id": "Green-tek-A16601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "8:15:00 AM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "3/19/2024 8:30",
    "plant_id": "Green-tek-A16602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "8:30:00 AM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "3/19/2024 8:45",
    "plant_id": "Green-tek-A16603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "8:45:00 AM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/19/2024 9:00",
    "plant_id": "Green-tek-A16604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "9:00:00 AM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "3/19/2024 9:15",
    "plant_id": "Green-tek-A16605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "9:15:00 AM",
    "power": 0.314,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "3/19/2024 9:30",
    "plant_id": "Green-tek-A16606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "9:30:00 AM",
    "power": 0.381,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "3/19/2024 9:45",
    "plant_id": "Green-tek-A16607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "9:45:00 AM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "3/19/2024 10:00",
    "plant_id": "Green-tek-A16608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "10:00:00 AM",
    "power": 0.528,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "3/19/2024 10:15",
    "plant_id": "Green-tek-A16609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "10:15:00 AM",
    "power": 0.603,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "3/19/2024 10:30",
    "plant_id": "Green-tek-A16610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "10:30:00 AM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "3/19/2024 10:45",
    "plant_id": "Green-tek-A16611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "10:45:00 AM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "3/19/2024 11:00",
    "plant_id": "Green-tek-A16612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "11:00:00 AM",
    "power": 0.794,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "3/19/2024 11:15",
    "plant_id": "Green-tek-A16613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "11:15:00 AM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "3/19/2024 11:30",
    "plant_id": "Green-tek-A16614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "11:30:00 AM",
    "power": 0.862,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "3/19/2024 11:45",
    "plant_id": "Green-tek-A16615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "11:45:00 AM",
    "power": 0.87,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "3/19/2024 12:00",
    "plant_id": "Green-tek-A16616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "12:00:00 PM",
    "power": 0.862,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "3/19/2024 12:15",
    "plant_id": "Green-tek-A16617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "12:15:00 PM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "3/19/2024 12:30",
    "plant_id": "Green-tek-A16618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "12:30:00 PM",
    "power": 0.794,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "3/19/2024 12:45",
    "plant_id": "Green-tek-A16619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "12:45:00 PM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "3/19/2024 13:00",
    "plant_id": "Green-tek-A16620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "1:00:00 PM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "3/19/2024 13:15",
    "plant_id": "Green-tek-A16621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "1:15:00 PM",
    "power": 0.603,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "3/19/2024 13:30",
    "plant_id": "Green-tek-A16622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "1:30:00 PM",
    "power": 0.528,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "3/19/2024 13:45",
    "plant_id": "Green-tek-A16623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "1:45:00 PM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "3/19/2024 14:00",
    "plant_id": "Green-tek-A16624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "2:00:00 PM",
    "power": 0.381,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "3/19/2024 14:15",
    "plant_id": "Green-tek-A16625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "2:15:00 PM",
    "power": 0.314,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "3/19/2024 14:30",
    "plant_id": "Green-tek-A16626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "2:30:00 PM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "3/19/2024 14:45",
    "plant_id": "Green-tek-A16627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "2:45:00 PM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/19/2024 15:00",
    "plant_id": "Green-tek-A16628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "3:00:00 PM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "3/19/2024 15:15",
    "plant_id": "Green-tek-A16629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "3:15:00 PM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "3/19/2024 15:30",
    "plant_id": "Green-tek-A16630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "3:30:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/19/2024 15:45",
    "plant_id": "Green-tek-A16631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/19/2024 16:00",
    "plant_id": "Green-tek-A16632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/19/2024 16:15",
    "plant_id": "Green-tek-A16633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/19/2024 16:30",
    "plant_id": "Green-tek-A16634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/19/2024 16:45",
    "plant_id": "Green-tek-A16635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/19/2024 17:00",
    "plant_id": "Green-tek-A16636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/19/2024 17:15",
    "plant_id": "Green-tek-A16637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/19/2024 17:30",
    "plant_id": "Green-tek-A16638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/19/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/20/2024 6:00",
    "plant_id": "Green-tek-A16639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/20/2024 6:15",
    "plant_id": "Green-tek-A16640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/20/2024 6:30",
    "plant_id": "Green-tek-A16641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/20/2024 6:45",
    "plant_id": "Green-tek-A16642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/20/2024 7:00",
    "plant_id": "Green-tek-A16643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/20/2024 7:15",
    "plant_id": "Green-tek-A16644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/20/2024 7:30",
    "plant_id": "Green-tek-A16645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "7:30:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/20/2024 7:45",
    "plant_id": "Green-tek-A16646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "7:45:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/20/2024 8:00",
    "plant_id": "Green-tek-A16647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "8:00:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/20/2024 8:15",
    "plant_id": "Green-tek-A16648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "8:15:00 AM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/20/2024 8:30",
    "plant_id": "Green-tek-A16649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "8:30:00 AM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "3/20/2024 8:45",
    "plant_id": "Green-tek-A16650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "8:45:00 AM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/20/2024 9:00",
    "plant_id": "Green-tek-A16651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "9:00:00 AM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "3/20/2024 9:15",
    "plant_id": "Green-tek-A16652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "9:15:00 AM",
    "power": 0.328,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "3/20/2024 9:30",
    "plant_id": "Green-tek-A16653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "9:30:00 AM",
    "power": 0.398,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/20/2024 9:45",
    "plant_id": "Green-tek-A16654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "9:45:00 AM",
    "power": 0.473,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "3/20/2024 10:00",
    "plant_id": "Green-tek-A16655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "10:00:00 AM",
    "power": 0.551,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "3/20/2024 10:15",
    "plant_id": "Green-tek-A16656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "10:15:00 AM",
    "power": 0.63,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "3/20/2024 10:30",
    "plant_id": "Green-tek-A16657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "10:30:00 AM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "3/20/2024 10:45",
    "plant_id": "Green-tek-A16658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "10:45:00 AM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/20/2024 11:00",
    "plant_id": "Green-tek-A16659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "11:00:00 AM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "3/20/2024 11:15",
    "plant_id": "Green-tek-A16660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "11:15:00 AM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "3/20/2024 11:30",
    "plant_id": "Green-tek-A16661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "11:30:00 AM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "3/20/2024 11:45",
    "plant_id": "Green-tek-A16662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "11:45:00 AM",
    "power": 0.909,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "3/20/2024 12:00",
    "plant_id": "Green-tek-A16663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "12:00:00 PM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "3/20/2024 12:15",
    "plant_id": "Green-tek-A16664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "12:15:00 PM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "3/20/2024 12:30",
    "plant_id": "Green-tek-A16665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "12:30:00 PM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "3/20/2024 12:45",
    "plant_id": "Green-tek-A16666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "12:45:00 PM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/20/2024 13:00",
    "plant_id": "Green-tek-A16667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "1:00:00 PM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "3/20/2024 13:15",
    "plant_id": "Green-tek-A16668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "1:15:00 PM",
    "power": 0.63,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "3/20/2024 13:30",
    "plant_id": "Green-tek-A16669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "1:30:00 PM",
    "power": 0.551,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "3/20/2024 13:45",
    "plant_id": "Green-tek-A16670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "1:45:00 PM",
    "power": 0.473,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "3/20/2024 14:00",
    "plant_id": "Green-tek-A16671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "2:00:00 PM",
    "power": 0.398,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/20/2024 14:15",
    "plant_id": "Green-tek-A16672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "2:15:00 PM",
    "power": 0.328,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "3/20/2024 14:30",
    "plant_id": "Green-tek-A16673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "2:30:00 PM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "3/20/2024 14:45",
    "plant_id": "Green-tek-A16674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "2:45:00 PM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/20/2024 15:00",
    "plant_id": "Green-tek-A16675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "3:00:00 PM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "3/20/2024 15:15",
    "plant_id": "Green-tek-A16676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "3:15:00 PM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/20/2024 15:30",
    "plant_id": "Green-tek-A16677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "3:30:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/20/2024 15:45",
    "plant_id": "Green-tek-A16678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "3:45:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/20/2024 16:00",
    "plant_id": "Green-tek-A16679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "4:00:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/20/2024 16:15",
    "plant_id": "Green-tek-A16680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/20/2024 16:30",
    "plant_id": "Green-tek-A16681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/20/2024 16:45",
    "plant_id": "Green-tek-A16682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/20/2024 17:00",
    "plant_id": "Green-tek-A16683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/20/2024 17:15",
    "plant_id": "Green-tek-A16684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/20/2024 17:30",
    "plant_id": "Green-tek-A16685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/20/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/21/2024 6:00",
    "plant_id": "Green-tek-A16686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/21/2024 6:15",
    "plant_id": "Green-tek-A16687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/21/2024 6:30",
    "plant_id": "Green-tek-A16688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/21/2024 6:45",
    "plant_id": "Green-tek-A16689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/21/2024 7:00",
    "plant_id": "Green-tek-A16690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/21/2024 7:15",
    "plant_id": "Green-tek-A16691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/21/2024 7:30",
    "plant_id": "Green-tek-A16692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/21/2024 7:45",
    "plant_id": "Green-tek-A16693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "7:45:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/21/2024 8:00",
    "plant_id": "Green-tek-A16694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "8:00:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/21/2024 8:15",
    "plant_id": "Green-tek-A16695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "8:15:00 AM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/21/2024 8:30",
    "plant_id": "Green-tek-A16696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "8:30:00 AM",
    "power": 0.21,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "3/21/2024 8:45",
    "plant_id": "Green-tek-A16697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "8:45:00 AM",
    "power": 0.272,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "3/21/2024 9:00",
    "plant_id": "Green-tek-A16698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "9:00:00 AM",
    "power": 0.343,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "3/21/2024 9:15",
    "plant_id": "Green-tek-A16699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "9:15:00 AM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "3/21/2024 9:30",
    "plant_id": "Green-tek-A16700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "9:30:00 AM",
    "power": 0.516,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "3/21/2024 9:45",
    "plant_id": "Green-tek-A16701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "9:45:00 AM",
    "power": 0.614,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "3/21/2024 10:00",
    "plant_id": "Green-tek-A16702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "10:00:00 AM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "3/21/2024 10:15",
    "plant_id": "Green-tek-A16703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "10:15:00 AM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "3/21/2024 10:30",
    "plant_id": "Green-tek-A16704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "10:30:00 AM",
    "power": 0.914,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "3/21/2024 10:45",
    "plant_id": "Green-tek-A16705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "10:45:00 AM",
    "power": 1.002,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "3/21/2024 11:00",
    "plant_id": "Green-tek-A16706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "11:00:00 AM",
    "power": 1.077,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "3/21/2024 11:15",
    "plant_id": "Green-tek-A16707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "11:15:00 AM",
    "power": 1.133,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "3/21/2024 11:30",
    "plant_id": "Green-tek-A16708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "11:30:00 AM",
    "power": 1.168,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "3/21/2024 11:45",
    "plant_id": "Green-tek-A16709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "11:45:00 AM",
    "power": 1.18,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "3/21/2024 12:00",
    "plant_id": "Green-tek-A16710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "12:00:00 PM",
    "power": 1.168,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "3/21/2024 12:15",
    "plant_id": "Green-tek-A16711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "12:15:00 PM",
    "power": 1.133,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "3/21/2024 12:30",
    "plant_id": "Green-tek-A16712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "12:30:00 PM",
    "power": 1.077,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "3/21/2024 12:45",
    "plant_id": "Green-tek-A16713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "12:45:00 PM",
    "power": 1.002,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "3/21/2024 13:00",
    "plant_id": "Green-tek-A16714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "1:00:00 PM",
    "power": 0.914,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "3/21/2024 13:15",
    "plant_id": "Green-tek-A16715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "1:15:00 PM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "3/21/2024 13:30",
    "plant_id": "Green-tek-A16716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "1:30:00 PM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "3/21/2024 13:45",
    "plant_id": "Green-tek-A16717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "1:45:00 PM",
    "power": 0.614,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "3/21/2024 14:00",
    "plant_id": "Green-tek-A16718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "2:00:00 PM",
    "power": 0.516,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "3/21/2024 14:15",
    "plant_id": "Green-tek-A16719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "2:15:00 PM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "3/21/2024 14:30",
    "plant_id": "Green-tek-A16720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "2:30:00 PM",
    "power": 0.343,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "3/21/2024 14:45",
    "plant_id": "Green-tek-A16721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "2:45:00 PM",
    "power": 0.272,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "3/21/2024 15:00",
    "plant_id": "Green-tek-A16722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "3:00:00 PM",
    "power": 0.21,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "3/21/2024 15:15",
    "plant_id": "Green-tek-A16723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "3:15:00 PM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/21/2024 15:30",
    "plant_id": "Green-tek-A16724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "3:30:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/21/2024 15:45",
    "plant_id": "Green-tek-A16725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "3:45:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/21/2024 16:00",
    "plant_id": "Green-tek-A16726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/21/2024 16:15",
    "plant_id": "Green-tek-A16727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/21/2024 16:30",
    "plant_id": "Green-tek-A16728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/21/2024 16:45",
    "plant_id": "Green-tek-A16729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/21/2024 17:00",
    "plant_id": "Green-tek-A16730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/21/2024 17:15",
    "plant_id": "Green-tek-A16731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/21/2024 17:30",
    "plant_id": "Green-tek-A16732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/21/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/22/2024 6:00",
    "plant_id": "Green-tek-A16733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/22/2024 6:15",
    "plant_id": "Green-tek-A16734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/22/2024 6:30",
    "plant_id": "Green-tek-A16735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/22/2024 6:45",
    "plant_id": "Green-tek-A16736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/22/2024 7:00",
    "plant_id": "Green-tek-A16737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/22/2024 7:15",
    "plant_id": "Green-tek-A16738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/22/2024 7:30",
    "plant_id": "Green-tek-A16739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/22/2024 7:45",
    "plant_id": "Green-tek-A16740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/22/2024 8:00",
    "plant_id": "Green-tek-A16741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "8:00:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/22/2024 8:15",
    "plant_id": "Green-tek-A16742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "8:15:00 AM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/22/2024 8:30",
    "plant_id": "Green-tek-A16743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "8:30:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/22/2024 8:45",
    "plant_id": "Green-tek-A16744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "8:45:00 AM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "3/22/2024 9:00",
    "plant_id": "Green-tek-A16745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "9:00:00 AM",
    "power": 0.242,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "3/22/2024 9:15",
    "plant_id": "Green-tek-A16746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "9:15:00 AM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "3/22/2024 9:30",
    "plant_id": "Green-tek-A16747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "9:30:00 AM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "3/22/2024 9:45",
    "plant_id": "Green-tek-A16748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "9:45:00 AM",
    "power": 0.434,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "3/22/2024 10:00",
    "plant_id": "Green-tek-A16749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "10:00:00 AM",
    "power": 0.505,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "3/22/2024 10:15",
    "plant_id": "Green-tek-A16750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "10:15:00 AM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "3/22/2024 10:30",
    "plant_id": "Green-tek-A16751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "10:30:00 AM",
    "power": 0.646,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "3/22/2024 10:45",
    "plant_id": "Green-tek-A16752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "10:45:00 AM",
    "power": 0.708,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "3/22/2024 11:00",
    "plant_id": "Green-tek-A16753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "11:00:00 AM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "3/22/2024 11:15",
    "plant_id": "Green-tek-A16754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "11:15:00 AM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "3/22/2024 11:30",
    "plant_id": "Green-tek-A16755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "11:30:00 AM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "3/22/2024 11:45",
    "plant_id": "Green-tek-A16756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "11:45:00 AM",
    "power": 0.833,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "3/22/2024 12:00",
    "plant_id": "Green-tek-A16757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "12:00:00 PM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "3/22/2024 12:15",
    "plant_id": "Green-tek-A16758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "12:15:00 PM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "3/22/2024 12:30",
    "plant_id": "Green-tek-A16759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "12:30:00 PM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "3/22/2024 12:45",
    "plant_id": "Green-tek-A16760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "12:45:00 PM",
    "power": 0.708,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "3/22/2024 13:00",
    "plant_id": "Green-tek-A16761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "1:00:00 PM",
    "power": 0.646,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "3/22/2024 13:15",
    "plant_id": "Green-tek-A16762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "1:15:00 PM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "3/22/2024 13:30",
    "plant_id": "Green-tek-A16763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "1:30:00 PM",
    "power": 0.505,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "3/22/2024 13:45",
    "plant_id": "Green-tek-A16764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "1:45:00 PM",
    "power": 0.434,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "3/22/2024 14:00",
    "plant_id": "Green-tek-A16765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "2:00:00 PM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "3/22/2024 14:15",
    "plant_id": "Green-tek-A16766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "2:15:00 PM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "3/22/2024 14:30",
    "plant_id": "Green-tek-A16767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "2:30:00 PM",
    "power": 0.242,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "3/22/2024 14:45",
    "plant_id": "Green-tek-A16768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "2:45:00 PM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "3/22/2024 15:00",
    "plant_id": "Green-tek-A16769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "3:00:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/22/2024 15:15",
    "plant_id": "Green-tek-A16770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "3:15:00 PM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/22/2024 15:30",
    "plant_id": "Green-tek-A16771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "3:30:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/22/2024 15:45",
    "plant_id": "Green-tek-A16772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/22/2024 16:00",
    "plant_id": "Green-tek-A16773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/22/2024 16:15",
    "plant_id": "Green-tek-A16774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/22/2024 16:30",
    "plant_id": "Green-tek-A16775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/22/2024 16:45",
    "plant_id": "Green-tek-A16776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/22/2024 17:00",
    "plant_id": "Green-tek-A16777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/22/2024 17:15",
    "plant_id": "Green-tek-A16778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/22/2024 17:30",
    "plant_id": "Green-tek-A16779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/22/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/23/2024 6:00",
    "plant_id": "Green-tek-A16780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/23/2024 6:15",
    "plant_id": "Green-tek-A16781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/23/2024 6:30",
    "plant_id": "Green-tek-A16782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/23/2024 6:45",
    "plant_id": "Green-tek-A16783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/23/2024 7:00",
    "plant_id": "Green-tek-A16784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/23/2024 7:15",
    "plant_id": "Green-tek-A16785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/23/2024 7:30",
    "plant_id": "Green-tek-A16786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/23/2024 7:45",
    "plant_id": "Green-tek-A16787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "7:45:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/23/2024 8:00",
    "plant_id": "Green-tek-A16788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "8:00:00 AM",
    "power": 0.126,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/23/2024 8:15",
    "plant_id": "Green-tek-A16789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "8:15:00 AM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "3/23/2024 8:30",
    "plant_id": "Green-tek-A16790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "8:30:00 AM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "3/23/2024 8:45",
    "plant_id": "Green-tek-A16791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "8:45:00 AM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "3/23/2024 9:00",
    "plant_id": "Green-tek-A16792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "9:00:00 AM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "3/23/2024 9:15",
    "plant_id": "Green-tek-A16793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "9:15:00 AM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "3/23/2024 9:30",
    "plant_id": "Green-tek-A16794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "9:30:00 AM",
    "power": 0.547,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/23/2024 9:45",
    "plant_id": "Green-tek-A16795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "9:45:00 AM",
    "power": 0.65,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "3/23/2024 10:00",
    "plant_id": "Green-tek-A16796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "10:00:00 AM",
    "power": 0.758,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "3/23/2024 10:15",
    "plant_id": "Green-tek-A16797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "10:15:00 AM",
    "power": 0.865,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "3/23/2024 10:30",
    "plant_id": "Green-tek-A16798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "10:30:00 AM",
    "power": 0.968,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "3/23/2024 10:45",
    "plant_id": "Green-tek-A16799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "10:45:00 AM",
    "power": 1.061,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "3/23/2024 11:00",
    "plant_id": "Green-tek-A16800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "11:00:00 AM",
    "power": 1.14,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "3/23/2024 11:15",
    "plant_id": "Green-tek-A16801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "11:15:00 AM",
    "power": 1.199,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "3/23/2024 11:30",
    "plant_id": "Green-tek-A16802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "11:30:00 AM",
    "power": 1.237,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "3/23/2024 11:45",
    "plant_id": "Green-tek-A16803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "11:45:00 AM",
    "power": 1.249,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "3/23/2024 12:00",
    "plant_id": "Green-tek-A16804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "12:00:00 PM",
    "power": 1.237,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "3/23/2024 12:15",
    "plant_id": "Green-tek-A16805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "12:15:00 PM",
    "power": 1.199,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "3/23/2024 12:30",
    "plant_id": "Green-tek-A16806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "12:30:00 PM",
    "power": 1.14,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "3/23/2024 12:45",
    "plant_id": "Green-tek-A16807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "12:45:00 PM",
    "power": 1.061,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "3/23/2024 13:00",
    "plant_id": "Green-tek-A16808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "1:00:00 PM",
    "power": 0.968,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "3/23/2024 13:15",
    "plant_id": "Green-tek-A16809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "1:15:00 PM",
    "power": 0.865,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "3/23/2024 13:30",
    "plant_id": "Green-tek-A16810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "1:30:00 PM",
    "power": 0.758,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "3/23/2024 13:45",
    "plant_id": "Green-tek-A16811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "1:45:00 PM",
    "power": 0.65,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "3/23/2024 14:00",
    "plant_id": "Green-tek-A16812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "2:00:00 PM",
    "power": 0.547,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/23/2024 14:15",
    "plant_id": "Green-tek-A16813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "2:15:00 PM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "3/23/2024 14:30",
    "plant_id": "Green-tek-A16814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "2:30:00 PM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "3/23/2024 14:45",
    "plant_id": "Green-tek-A16815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "2:45:00 PM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "3/23/2024 15:00",
    "plant_id": "Green-tek-A16816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "3:00:00 PM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "3/23/2024 15:15",
    "plant_id": "Green-tek-A16817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "3:15:00 PM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "3/23/2024 15:30",
    "plant_id": "Green-tek-A16818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "3:30:00 PM",
    "power": 0.126,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/23/2024 15:45",
    "plant_id": "Green-tek-A16819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "3:45:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/23/2024 16:00",
    "plant_id": "Green-tek-A16820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/23/2024 16:15",
    "plant_id": "Green-tek-A16821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/23/2024 16:30",
    "plant_id": "Green-tek-A16822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/23/2024 16:45",
    "plant_id": "Green-tek-A16823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/23/2024 17:00",
    "plant_id": "Green-tek-A16824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/23/2024 17:15",
    "plant_id": "Green-tek-A16825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/23/2024 17:30",
    "plant_id": "Green-tek-A16826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/23/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/24/2024 6:00",
    "plant_id": "Green-tek-A16827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/24/2024 6:15",
    "plant_id": "Green-tek-A16828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/24/2024 6:30",
    "plant_id": "Green-tek-A16829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/24/2024 6:45",
    "plant_id": "Green-tek-A16830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/24/2024 7:00",
    "plant_id": "Green-tek-A16831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/24/2024 7:15",
    "plant_id": "Green-tek-A16832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/24/2024 7:30",
    "plant_id": "Green-tek-A16833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/24/2024 7:45",
    "plant_id": "Green-tek-A16834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "7:45:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/24/2024 8:00",
    "plant_id": "Green-tek-A16835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "8:00:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/24/2024 8:15",
    "plant_id": "Green-tek-A16836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "8:15:00 AM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/24/2024 8:30",
    "plant_id": "Green-tek-A16837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "8:30:00 AM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/24/2024 8:45",
    "plant_id": "Green-tek-A16838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "8:45:00 AM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "3/24/2024 9:00",
    "plant_id": "Green-tek-A16839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "9:00:00 AM",
    "power": 0.258,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "3/24/2024 9:15",
    "plant_id": "Green-tek-A16840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "9:15:00 AM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "3/24/2024 9:30",
    "plant_id": "Green-tek-A16841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "9:30:00 AM",
    "power": 0.388,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "3/24/2024 9:45",
    "plant_id": "Green-tek-A16842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "9:45:00 AM",
    "power": 0.462,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "3/24/2024 10:00",
    "plant_id": "Green-tek-A16843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "10:00:00 AM",
    "power": 0.538,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "3/24/2024 10:15",
    "plant_id": "Green-tek-A16844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "10:15:00 AM",
    "power": 0.615,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "3/24/2024 10:30",
    "plant_id": "Green-tek-A16845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "10:30:00 AM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "3/24/2024 10:45",
    "plant_id": "Green-tek-A16846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "10:45:00 AM",
    "power": 0.754,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "3/24/2024 11:00",
    "plant_id": "Green-tek-A16847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "11:00:00 AM",
    "power": 0.809,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "3/24/2024 11:15",
    "plant_id": "Green-tek-A16848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "11:15:00 AM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "3/24/2024 11:30",
    "plant_id": "Green-tek-A16849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "11:30:00 AM",
    "power": 0.878,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "3/24/2024 11:45",
    "plant_id": "Green-tek-A16850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "11:45:00 AM",
    "power": 0.887,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "3/24/2024 12:00",
    "plant_id": "Green-tek-A16851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "12:00:00 PM",
    "power": 0.878,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "3/24/2024 12:15",
    "plant_id": "Green-tek-A16852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "12:15:00 PM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "3/24/2024 12:30",
    "plant_id": "Green-tek-A16853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "12:30:00 PM",
    "power": 0.809,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "3/24/2024 12:45",
    "plant_id": "Green-tek-A16854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "12:45:00 PM",
    "power": 0.754,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "3/24/2024 13:00",
    "plant_id": "Green-tek-A16855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "1:00:00 PM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "3/24/2024 13:15",
    "plant_id": "Green-tek-A16856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "1:15:00 PM",
    "power": 0.615,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "3/24/2024 13:30",
    "plant_id": "Green-tek-A16857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "1:30:00 PM",
    "power": 0.538,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "3/24/2024 13:45",
    "plant_id": "Green-tek-A16858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "1:45:00 PM",
    "power": 0.462,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "3/24/2024 14:00",
    "plant_id": "Green-tek-A16859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "2:00:00 PM",
    "power": 0.388,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "3/24/2024 14:15",
    "plant_id": "Green-tek-A16860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "2:15:00 PM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "3/24/2024 14:30",
    "plant_id": "Green-tek-A16861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "2:30:00 PM",
    "power": 0.258,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "3/24/2024 14:45",
    "plant_id": "Green-tek-A16862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "2:45:00 PM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "3/24/2024 15:00",
    "plant_id": "Green-tek-A16863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "3:00:00 PM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/24/2024 15:15",
    "plant_id": "Green-tek-A16864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "3:15:00 PM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/24/2024 15:30",
    "plant_id": "Green-tek-A16865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "3:30:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/24/2024 15:45",
    "plant_id": "Green-tek-A16866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "3:45:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/24/2024 16:00",
    "plant_id": "Green-tek-A16867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/24/2024 16:15",
    "plant_id": "Green-tek-A16868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/24/2024 16:30",
    "plant_id": "Green-tek-A16869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/24/2024 16:45",
    "plant_id": "Green-tek-A16870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/24/2024 17:00",
    "plant_id": "Green-tek-A16871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/24/2024 17:15",
    "plant_id": "Green-tek-A16872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/24/2024 17:30",
    "plant_id": "Green-tek-A16873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/24/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/25/2024 6:00",
    "plant_id": "Green-tek-A16874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/25/2024 6:15",
    "plant_id": "Green-tek-A16875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/25/2024 6:30",
    "plant_id": "Green-tek-A16876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/25/2024 6:45",
    "plant_id": "Green-tek-A16877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/25/2024 7:00",
    "plant_id": "Green-tek-A16878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/25/2024 7:15",
    "plant_id": "Green-tek-A16879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/25/2024 7:30",
    "plant_id": "Green-tek-A16880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/25/2024 7:45",
    "plant_id": "Green-tek-A16881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/25/2024 8:00",
    "plant_id": "Green-tek-A16882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "8:00:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/25/2024 8:15",
    "plant_id": "Green-tek-A16883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "8:15:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "3/25/2024 8:30",
    "plant_id": "Green-tek-A16884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "8:30:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "3/25/2024 8:45",
    "plant_id": "Green-tek-A16885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "8:45:00 AM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "3/25/2024 9:00",
    "plant_id": "Green-tek-A16886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "9:00:00 AM",
    "power": 0.246,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "3/25/2024 9:15",
    "plant_id": "Green-tek-A16887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "9:15:00 AM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "3/25/2024 9:30",
    "plant_id": "Green-tek-A16888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "9:30:00 AM",
    "power": 0.37,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "3/25/2024 9:45",
    "plant_id": "Green-tek-A16889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "9:45:00 AM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "3/25/2024 10:00",
    "plant_id": "Green-tek-A16890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "10:00:00 AM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "3/25/2024 10:15",
    "plant_id": "Green-tek-A16891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "10:15:00 AM",
    "power": 0.586,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "3/25/2024 10:30",
    "plant_id": "Green-tek-A16892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "10:30:00 AM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "3/25/2024 10:45",
    "plant_id": "Green-tek-A16893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "10:45:00 AM",
    "power": 0.719,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "3/25/2024 11:00",
    "plant_id": "Green-tek-A16894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "11:00:00 AM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/25/2024 11:15",
    "plant_id": "Green-tek-A16895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "11:15:00 AM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "3/25/2024 11:30",
    "plant_id": "Green-tek-A16896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "11:30:00 AM",
    "power": 0.838,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "3/25/2024 11:45",
    "plant_id": "Green-tek-A16897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "11:45:00 AM",
    "power": 0.846,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "3/25/2024 12:00",
    "plant_id": "Green-tek-A16898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "12:00:00 PM",
    "power": 0.838,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "3/25/2024 12:15",
    "plant_id": "Green-tek-A16899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "12:15:00 PM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "3/25/2024 12:30",
    "plant_id": "Green-tek-A16900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "12:30:00 PM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/25/2024 12:45",
    "plant_id": "Green-tek-A16901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "12:45:00 PM",
    "power": 0.719,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "3/25/2024 13:00",
    "plant_id": "Green-tek-A16902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "1:00:00 PM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "3/25/2024 13:15",
    "plant_id": "Green-tek-A16903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "1:15:00 PM",
    "power": 0.586,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "3/25/2024 13:30",
    "plant_id": "Green-tek-A16904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "1:30:00 PM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "3/25/2024 13:45",
    "plant_id": "Green-tek-A16905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "1:45:00 PM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "3/25/2024 14:00",
    "plant_id": "Green-tek-A16906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "2:00:00 PM",
    "power": 0.37,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "3/25/2024 14:15",
    "plant_id": "Green-tek-A16907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "2:15:00 PM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "3/25/2024 14:30",
    "plant_id": "Green-tek-A16908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "2:30:00 PM",
    "power": 0.246,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "3/25/2024 14:45",
    "plant_id": "Green-tek-A16909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "2:45:00 PM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "3/25/2024 15:00",
    "plant_id": "Green-tek-A16910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "3:00:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "3/25/2024 15:15",
    "plant_id": "Green-tek-A16911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "3:15:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "3/25/2024 15:30",
    "plant_id": "Green-tek-A16912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "3:30:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "3/25/2024 15:45",
    "plant_id": "Green-tek-A16913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/25/2024 16:00",
    "plant_id": "Green-tek-A16914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/25/2024 16:15",
    "plant_id": "Green-tek-A16915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/25/2024 16:30",
    "plant_id": "Green-tek-A16916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/25/2024 16:45",
    "plant_id": "Green-tek-A16917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/25/2024 17:00",
    "plant_id": "Green-tek-A16918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/25/2024 17:15",
    "plant_id": "Green-tek-A16919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/25/2024 17:30",
    "plant_id": "Green-tek-A16920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/25/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/26/2024 6:00",
    "plant_id": "Green-tek-A16921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/26/2024 6:15",
    "plant_id": "Green-tek-A16922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/26/2024 6:30",
    "plant_id": "Green-tek-A16923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/26/2024 6:45",
    "plant_id": "Green-tek-A16924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/26/2024 7:00",
    "plant_id": "Green-tek-A16925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/26/2024 7:15",
    "plant_id": "Green-tek-A16926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/26/2024 7:30",
    "plant_id": "Green-tek-A16927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/26/2024 7:45",
    "plant_id": "Green-tek-A16928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/26/2024 8:00",
    "plant_id": "Green-tek-A16929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "8:00:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/26/2024 8:15",
    "plant_id": "Green-tek-A16930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "8:15:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/26/2024 8:30",
    "plant_id": "Green-tek-A16931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "8:30:00 AM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "3/26/2024 8:45",
    "plant_id": "Green-tek-A16932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "8:45:00 AM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/26/2024 9:00",
    "plant_id": "Green-tek-A16933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "9:00:00 AM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "3/26/2024 9:15",
    "plant_id": "Green-tek-A16934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "9:15:00 AM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "3/26/2024 9:30",
    "plant_id": "Green-tek-A16935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "9:30:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "3/26/2024 9:45",
    "plant_id": "Green-tek-A16936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "9:45:00 AM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "3/26/2024 10:00",
    "plant_id": "Green-tek-A16937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "10:00:00 AM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "3/26/2024 10:15",
    "plant_id": "Green-tek-A16938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "10:15:00 AM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "3/26/2024 10:30",
    "plant_id": "Green-tek-A16939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "10:30:00 AM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "3/26/2024 10:45",
    "plant_id": "Green-tek-A16940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "10:45:00 AM",
    "power": 0.745,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "3/26/2024 11:00",
    "plant_id": "Green-tek-A16941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "11:00:00 AM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "3/26/2024 11:15",
    "plant_id": "Green-tek-A16942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "11:15:00 AM",
    "power": 0.842,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "3/26/2024 11:30",
    "plant_id": "Green-tek-A16943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "11:30:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/26/2024 11:45",
    "plant_id": "Green-tek-A16944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "11:45:00 AM",
    "power": 0.877,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "3/26/2024 12:00",
    "plant_id": "Green-tek-A16945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "12:00:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/26/2024 12:15",
    "plant_id": "Green-tek-A16946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "12:15:00 PM",
    "power": 0.842,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "3/26/2024 12:30",
    "plant_id": "Green-tek-A16947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "12:30:00 PM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "3/26/2024 12:45",
    "plant_id": "Green-tek-A16948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "12:45:00 PM",
    "power": 0.745,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "3/26/2024 13:00",
    "plant_id": "Green-tek-A16949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "1:00:00 PM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "3/26/2024 13:15",
    "plant_id": "Green-tek-A16950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "1:15:00 PM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "3/26/2024 13:30",
    "plant_id": "Green-tek-A16951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "1:30:00 PM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "3/26/2024 13:45",
    "plant_id": "Green-tek-A16952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "1:45:00 PM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "3/26/2024 14:00",
    "plant_id": "Green-tek-A16953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "2:00:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "3/26/2024 14:15",
    "plant_id": "Green-tek-A16954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "2:15:00 PM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "3/26/2024 14:30",
    "plant_id": "Green-tek-A16955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "2:30:00 PM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "3/26/2024 14:45",
    "plant_id": "Green-tek-A16956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "2:45:00 PM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/26/2024 15:00",
    "plant_id": "Green-tek-A16957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "3:00:00 PM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "3/26/2024 15:15",
    "plant_id": "Green-tek-A16958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "3:15:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/26/2024 15:30",
    "plant_id": "Green-tek-A16959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "3:30:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/26/2024 15:45",
    "plant_id": "Green-tek-A16960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/26/2024 16:00",
    "plant_id": "Green-tek-A16961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/26/2024 16:15",
    "plant_id": "Green-tek-A16962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/26/2024 16:30",
    "plant_id": "Green-tek-A16963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/26/2024 16:45",
    "plant_id": "Green-tek-A16964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/26/2024 17:00",
    "plant_id": "Green-tek-A16965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/26/2024 17:15",
    "plant_id": "Green-tek-A16966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/26/2024 17:30",
    "plant_id": "Green-tek-A16967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/26/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/27/2024 6:00",
    "plant_id": "Green-tek-A16968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/27/2024 6:15",
    "plant_id": "Green-tek-A16969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/27/2024 6:30",
    "plant_id": "Green-tek-A16970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/27/2024 6:45",
    "plant_id": "Green-tek-A16971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/27/2024 7:00",
    "plant_id": "Green-tek-A16972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/27/2024 7:15",
    "plant_id": "Green-tek-A16973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "3/27/2024 7:30",
    "plant_id": "Green-tek-A16974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "3/27/2024 7:45",
    "plant_id": "Green-tek-A16975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "7:45:00 AM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "3/27/2024 8:00",
    "plant_id": "Green-tek-A16976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "8:00:00 AM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "3/27/2024 8:15",
    "plant_id": "Green-tek-A16977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "8:15:00 AM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "3/27/2024 8:30",
    "plant_id": "Green-tek-A16978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "8:30:00 AM",
    "power": 0.178,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "3/27/2024 8:45",
    "plant_id": "Green-tek-A16979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "8:45:00 AM",
    "power": 0.23,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "3/27/2024 9:00",
    "plant_id": "Green-tek-A16980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "9:00:00 AM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "3/27/2024 9:15",
    "plant_id": "Green-tek-A16981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "9:15:00 AM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "3/27/2024 9:30",
    "plant_id": "Green-tek-A16982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "9:30:00 AM",
    "power": 0.437,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "3/27/2024 9:45",
    "plant_id": "Green-tek-A16983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "9:45:00 AM",
    "power": 0.52,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "3/27/2024 10:00",
    "plant_id": "Green-tek-A16984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "10:00:00 AM",
    "power": 0.606,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "3/27/2024 10:15",
    "plant_id": "Green-tek-A16985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "10:15:00 AM",
    "power": 0.692,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "3/27/2024 10:30",
    "plant_id": "Green-tek-A16986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "10:30:00 AM",
    "power": 0.774,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/27/2024 10:45",
    "plant_id": "Green-tek-A16987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "10:45:00 AM",
    "power": 0.848,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "3/27/2024 11:00",
    "plant_id": "Green-tek-A16988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "11:00:00 AM",
    "power": 0.911,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "3/27/2024 11:15",
    "plant_id": "Green-tek-A16989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "11:15:00 AM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "3/27/2024 11:30",
    "plant_id": "Green-tek-A16990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "11:30:00 AM",
    "power": 0.989,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "3/27/2024 11:45",
    "plant_id": "Green-tek-A16991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "11:45:00 AM",
    "power": 0.999,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "3/27/2024 12:00",
    "plant_id": "Green-tek-A16992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "12:00:00 PM",
    "power": 0.989,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "3/27/2024 12:15",
    "plant_id": "Green-tek-A16993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "12:15:00 PM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "3/27/2024 12:30",
    "plant_id": "Green-tek-A16994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "12:30:00 PM",
    "power": 0.911,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "3/27/2024 12:45",
    "plant_id": "Green-tek-A16995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "12:45:00 PM",
    "power": 0.848,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "3/27/2024 13:00",
    "plant_id": "Green-tek-A16996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "1:00:00 PM",
    "power": 0.774,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/27/2024 13:15",
    "plant_id": "Green-tek-A16997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "1:15:00 PM",
    "power": 0.692,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "3/27/2024 13:30",
    "plant_id": "Green-tek-A16998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "1:30:00 PM",
    "power": 0.606,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "3/27/2024 13:45",
    "plant_id": "Green-tek-A16999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "1:45:00 PM",
    "power": 0.52,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "3/27/2024 14:00",
    "plant_id": "Green-tek-A17000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "2:00:00 PM",
    "power": 0.437,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "3/27/2024 14:15",
    "plant_id": "Green-tek-A17001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "2:15:00 PM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "3/27/2024 14:30",
    "plant_id": "Green-tek-A17002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "2:30:00 PM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "3/27/2024 14:45",
    "plant_id": "Green-tek-A17003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "2:45:00 PM",
    "power": 0.23,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "3/27/2024 15:00",
    "plant_id": "Green-tek-A17004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "3:00:00 PM",
    "power": 0.178,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "3/27/2024 15:15",
    "plant_id": "Green-tek-A17005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "3:15:00 PM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "3/27/2024 15:30",
    "plant_id": "Green-tek-A17006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "3:30:00 PM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "3/27/2024 15:45",
    "plant_id": "Green-tek-A17007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "3:45:00 PM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "3/27/2024 16:00",
    "plant_id": "Green-tek-A17008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "3/27/2024 16:15",
    "plant_id": "Green-tek-A17009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "3/27/2024 16:30",
    "plant_id": "Green-tek-A17010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/27/2024 16:45",
    "plant_id": "Green-tek-A17011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/27/2024 17:00",
    "plant_id": "Green-tek-A17012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/27/2024 17:15",
    "plant_id": "Green-tek-A17013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/27/2024 17:30",
    "plant_id": "Green-tek-A17014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/27/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/28/2024 6:00",
    "plant_id": "Green-tek-A17015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/28/2024 6:15",
    "plant_id": "Green-tek-A17016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/28/2024 6:30",
    "plant_id": "Green-tek-A17017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/28/2024 6:45",
    "plant_id": "Green-tek-A17018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/28/2024 7:00",
    "plant_id": "Green-tek-A17019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/28/2024 7:15",
    "plant_id": "Green-tek-A17020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/28/2024 7:30",
    "plant_id": "Green-tek-A17021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/28/2024 7:45",
    "plant_id": "Green-tek-A17022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/28/2024 8:00",
    "plant_id": "Green-tek-A17023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "8:00:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/28/2024 8:15",
    "plant_id": "Green-tek-A17024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "8:15:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/28/2024 8:30",
    "plant_id": "Green-tek-A17025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "8:30:00 AM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "3/28/2024 8:45",
    "plant_id": "Green-tek-A17026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "8:45:00 AM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/28/2024 9:00",
    "plant_id": "Green-tek-A17027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "9:00:00 AM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "3/28/2024 9:15",
    "plant_id": "Green-tek-A17028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "9:15:00 AM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "3/28/2024 9:30",
    "plant_id": "Green-tek-A17029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "9:30:00 AM",
    "power": 0.383,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "3/28/2024 9:45",
    "plant_id": "Green-tek-A17030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "9:45:00 AM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "3/28/2024 10:00",
    "plant_id": "Green-tek-A17031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "10:00:00 AM",
    "power": 0.531,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "3/28/2024 10:15",
    "plant_id": "Green-tek-A17032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "10:15:00 AM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "3/28/2024 10:30",
    "plant_id": "Green-tek-A17033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "10:30:00 AM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "3/28/2024 10:45",
    "plant_id": "Green-tek-A17034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "10:45:00 AM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "3/28/2024 11:00",
    "plant_id": "Green-tek-A17035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "11:00:00 AM",
    "power": 0.799,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "3/28/2024 11:15",
    "plant_id": "Green-tek-A17036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "11:15:00 AM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "3/28/2024 11:30",
    "plant_id": "Green-tek-A17037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "11:30:00 AM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/28/2024 11:45",
    "plant_id": "Green-tek-A17038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "11:45:00 AM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "3/28/2024 12:00",
    "plant_id": "Green-tek-A17039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "12:00:00 PM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/28/2024 12:15",
    "plant_id": "Green-tek-A17040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "12:15:00 PM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "3/28/2024 12:30",
    "plant_id": "Green-tek-A17041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "12:30:00 PM",
    "power": 0.799,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "3/28/2024 12:45",
    "plant_id": "Green-tek-A17042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "12:45:00 PM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "3/28/2024 13:00",
    "plant_id": "Green-tek-A17043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "1:00:00 PM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "3/28/2024 13:15",
    "plant_id": "Green-tek-A17044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "1:15:00 PM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "3/28/2024 13:30",
    "plant_id": "Green-tek-A17045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "1:30:00 PM",
    "power": 0.531,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "3/28/2024 13:45",
    "plant_id": "Green-tek-A17046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "1:45:00 PM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "3/28/2024 14:00",
    "plant_id": "Green-tek-A17047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "2:00:00 PM",
    "power": 0.383,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "3/28/2024 14:15",
    "plant_id": "Green-tek-A17048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "2:15:00 PM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "3/28/2024 14:30",
    "plant_id": "Green-tek-A17049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "2:30:00 PM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "3/28/2024 14:45",
    "plant_id": "Green-tek-A17050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "2:45:00 PM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/28/2024 15:00",
    "plant_id": "Green-tek-A17051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "3:00:00 PM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "3/28/2024 15:15",
    "plant_id": "Green-tek-A17052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "3:15:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "3/28/2024 15:30",
    "plant_id": "Green-tek-A17053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "3:30:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "3/28/2024 15:45",
    "plant_id": "Green-tek-A17054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "3/28/2024 16:00",
    "plant_id": "Green-tek-A17055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "3/28/2024 16:15",
    "plant_id": "Green-tek-A17056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/28/2024 16:30",
    "plant_id": "Green-tek-A17057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/28/2024 16:45",
    "plant_id": "Green-tek-A17058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/28/2024 17:00",
    "plant_id": "Green-tek-A17059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/28/2024 17:15",
    "plant_id": "Green-tek-A17060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/28/2024 17:30",
    "plant_id": "Green-tek-A17061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/28/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/29/2024 6:00",
    "plant_id": "Green-tek-A17062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/29/2024 6:15",
    "plant_id": "Green-tek-A17063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/29/2024 6:30",
    "plant_id": "Green-tek-A17064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/29/2024 6:45",
    "plant_id": "Green-tek-A17065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/29/2024 7:00",
    "plant_id": "Green-tek-A17066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/29/2024 7:15",
    "plant_id": "Green-tek-A17067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/29/2024 7:30",
    "plant_id": "Green-tek-A17068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/29/2024 7:45",
    "plant_id": "Green-tek-A17069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "7:45:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/29/2024 8:00",
    "plant_id": "Green-tek-A17070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "8:00:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/29/2024 8:15",
    "plant_id": "Green-tek-A17071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "8:15:00 AM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/29/2024 8:30",
    "plant_id": "Green-tek-A17072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "8:30:00 AM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/29/2024 8:45",
    "plant_id": "Green-tek-A17073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "8:45:00 AM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/29/2024 9:00",
    "plant_id": "Green-tek-A17074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "9:00:00 AM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "3/29/2024 9:15",
    "plant_id": "Green-tek-A17075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "9:15:00 AM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "3/29/2024 9:30",
    "plant_id": "Green-tek-A17076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "9:30:00 AM",
    "power": 0.396,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/29/2024 9:45",
    "plant_id": "Green-tek-A17077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "9:45:00 AM",
    "power": 0.472,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "3/29/2024 10:00",
    "plant_id": "Green-tek-A17078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "10:00:00 AM",
    "power": 0.549,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/29/2024 10:15",
    "plant_id": "Green-tek-A17079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "10:15:00 AM",
    "power": 0.627,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "3/29/2024 10:30",
    "plant_id": "Green-tek-A17080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "10:30:00 AM",
    "power": 0.702,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "3/29/2024 10:45",
    "plant_id": "Green-tek-A17081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "10:45:00 AM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "3/29/2024 11:00",
    "plant_id": "Green-tek-A17082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "11:00:00 AM",
    "power": 0.826,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "3/29/2024 11:15",
    "plant_id": "Green-tek-A17083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "11:15:00 AM",
    "power": 0.87,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/29/2024 11:30",
    "plant_id": "Green-tek-A17084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "11:30:00 AM",
    "power": 0.897,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "3/29/2024 11:45",
    "plant_id": "Green-tek-A17085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "11:45:00 AM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "3/29/2024 12:00",
    "plant_id": "Green-tek-A17086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "12:00:00 PM",
    "power": 0.897,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "3/29/2024 12:15",
    "plant_id": "Green-tek-A17087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "12:15:00 PM",
    "power": 0.87,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "3/29/2024 12:30",
    "plant_id": "Green-tek-A17088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "12:30:00 PM",
    "power": 0.826,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "3/29/2024 12:45",
    "plant_id": "Green-tek-A17089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "12:45:00 PM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "3/29/2024 13:00",
    "plant_id": "Green-tek-A17090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "1:00:00 PM",
    "power": 0.702,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "3/29/2024 13:15",
    "plant_id": "Green-tek-A17091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "1:15:00 PM",
    "power": 0.627,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "3/29/2024 13:30",
    "plant_id": "Green-tek-A17092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "1:30:00 PM",
    "power": 0.549,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "3/29/2024 13:45",
    "plant_id": "Green-tek-A17093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "1:45:00 PM",
    "power": 0.472,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "3/29/2024 14:00",
    "plant_id": "Green-tek-A17094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "2:00:00 PM",
    "power": 0.396,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/29/2024 14:15",
    "plant_id": "Green-tek-A17095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "2:15:00 PM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "3/29/2024 14:30",
    "plant_id": "Green-tek-A17096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "2:30:00 PM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "3/29/2024 14:45",
    "plant_id": "Green-tek-A17097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "2:45:00 PM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "3/29/2024 15:00",
    "plant_id": "Green-tek-A17098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "3:00:00 PM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "3/29/2024 15:15",
    "plant_id": "Green-tek-A17099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "3:15:00 PM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "3/29/2024 15:30",
    "plant_id": "Green-tek-A17100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "3:30:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "3/29/2024 15:45",
    "plant_id": "Green-tek-A17101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "3:45:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "3/29/2024 16:00",
    "plant_id": "Green-tek-A17102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "3/29/2024 16:15",
    "plant_id": "Green-tek-A17103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "3/29/2024 16:30",
    "plant_id": "Green-tek-A17104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "3/29/2024 16:45",
    "plant_id": "Green-tek-A17105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "3/29/2024 17:00",
    "plant_id": "Green-tek-A17106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/29/2024 17:15",
    "plant_id": "Green-tek-A17107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/29/2024 17:30",
    "plant_id": "Green-tek-A17108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/29/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/30/2024 6:00",
    "plant_id": "Green-tek-A17109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/30/2024 6:15",
    "plant_id": "Green-tek-A17110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/30/2024 6:30",
    "plant_id": "Green-tek-A17111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/30/2024 6:45",
    "plant_id": "Green-tek-A17112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/30/2024 7:00",
    "plant_id": "Green-tek-A17113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/30/2024 7:15",
    "plant_id": "Green-tek-A17114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/30/2024 7:30",
    "plant_id": "Green-tek-A17115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/30/2024 7:45",
    "plant_id": "Green-tek-A17116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "7:45:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/30/2024 8:00",
    "plant_id": "Green-tek-A17117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "8:00:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/30/2024 8:15",
    "plant_id": "Green-tek-A17118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "8:15:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "3/30/2024 8:30",
    "plant_id": "Green-tek-A17119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "8:30:00 AM",
    "power": 0.199,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/30/2024 8:45",
    "plant_id": "Green-tek-A17120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "8:45:00 AM",
    "power": 0.257,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "3/30/2024 9:00",
    "plant_id": "Green-tek-A17121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "9:00:00 AM",
    "power": 0.325,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "3/30/2024 9:15",
    "plant_id": "Green-tek-A17122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "9:15:00 AM",
    "power": 0.402,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "3/30/2024 9:30",
    "plant_id": "Green-tek-A17123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "9:30:00 AM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "3/30/2024 9:45",
    "plant_id": "Green-tek-A17124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "9:45:00 AM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "3/30/2024 10:00",
    "plant_id": "Green-tek-A17125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "10:00:00 AM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "3/30/2024 10:15",
    "plant_id": "Green-tek-A17126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "10:15:00 AM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/30/2024 10:30",
    "plant_id": "Green-tek-A17127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "10:30:00 AM",
    "power": 0.864,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "3/30/2024 10:45",
    "plant_id": "Green-tek-A17128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "10:45:00 AM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "3/30/2024 11:00",
    "plant_id": "Green-tek-A17129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "11:00:00 AM",
    "power": 1.018,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "3/30/2024 11:15",
    "plant_id": "Green-tek-A17130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "11:15:00 AM",
    "power": 1.071,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "3/30/2024 11:30",
    "plant_id": "Green-tek-A17131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "11:30:00 AM",
    "power": 1.104,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "3/30/2024 11:45",
    "plant_id": "Green-tek-A17132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "11:45:00 AM",
    "power": 1.116,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "3/30/2024 12:00",
    "plant_id": "Green-tek-A17133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "12:00:00 PM",
    "power": 1.104,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "3/30/2024 12:15",
    "plant_id": "Green-tek-A17134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "12:15:00 PM",
    "power": 1.071,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "3/30/2024 12:30",
    "plant_id": "Green-tek-A17135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "12:30:00 PM",
    "power": 1.018,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "3/30/2024 12:45",
    "plant_id": "Green-tek-A17136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "12:45:00 PM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "3/30/2024 13:00",
    "plant_id": "Green-tek-A17137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "1:00:00 PM",
    "power": 0.864,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "3/30/2024 13:15",
    "plant_id": "Green-tek-A17138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "1:15:00 PM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "3/30/2024 13:30",
    "plant_id": "Green-tek-A17139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "1:30:00 PM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "3/30/2024 13:45",
    "plant_id": "Green-tek-A17140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "1:45:00 PM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "3/30/2024 14:00",
    "plant_id": "Green-tek-A17141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "2:00:00 PM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "3/30/2024 14:15",
    "plant_id": "Green-tek-A17142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "2:15:00 PM",
    "power": 0.402,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "3/30/2024 14:30",
    "plant_id": "Green-tek-A17143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "2:30:00 PM",
    "power": 0.325,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "3/30/2024 14:45",
    "plant_id": "Green-tek-A17144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "2:45:00 PM",
    "power": 0.257,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "3/30/2024 15:00",
    "plant_id": "Green-tek-A17145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "3:00:00 PM",
    "power": 0.199,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "3/30/2024 15:15",
    "plant_id": "Green-tek-A17146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "3:15:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "3/30/2024 15:30",
    "plant_id": "Green-tek-A17147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "3:30:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/30/2024 15:45",
    "plant_id": "Green-tek-A17148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "3:45:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/30/2024 16:00",
    "plant_id": "Green-tek-A17149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "3/30/2024 16:15",
    "plant_id": "Green-tek-A17150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/30/2024 16:30",
    "plant_id": "Green-tek-A17151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/30/2024 16:45",
    "plant_id": "Green-tek-A17152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/30/2024 17:00",
    "plant_id": "Green-tek-A17153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/30/2024 17:15",
    "plant_id": "Green-tek-A17154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/30/2024 17:30",
    "plant_id": "Green-tek-A17155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/30/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/31/2024 6:00",
    "plant_id": "Green-tek-A17156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "3/31/2024 6:15",
    "plant_id": "Green-tek-A17157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/31/2024 6:30",
    "plant_id": "Green-tek-A17158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/31/2024 6:45",
    "plant_id": "Green-tek-A17159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/31/2024 7:00",
    "plant_id": "Green-tek-A17160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/31/2024 7:15",
    "plant_id": "Green-tek-A17161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/31/2024 7:30",
    "plant_id": "Green-tek-A17162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/31/2024 7:45",
    "plant_id": "Green-tek-A17163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "7:45:00 AM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/31/2024 8:00",
    "plant_id": "Green-tek-A17164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "8:00:00 AM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/31/2024 8:15",
    "plant_id": "Green-tek-A17165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "8:15:00 AM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/31/2024 8:30",
    "plant_id": "Green-tek-A17166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "8:30:00 AM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "3/31/2024 8:45",
    "plant_id": "Green-tek-A17167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "8:45:00 AM",
    "power": 0.252,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "3/31/2024 9:00",
    "plant_id": "Green-tek-A17168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "9:00:00 AM",
    "power": 0.319,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "3/31/2024 9:15",
    "plant_id": "Green-tek-A17169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "9:15:00 AM",
    "power": 0.395,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/31/2024 9:30",
    "plant_id": "Green-tek-A17170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "9:30:00 AM",
    "power": 0.48,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "3/31/2024 9:45",
    "plant_id": "Green-tek-A17171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "9:45:00 AM",
    "power": 0.57,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "3/31/2024 10:00",
    "plant_id": "Green-tek-A17172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "10:00:00 AM",
    "power": 0.665,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "3/31/2024 10:15",
    "plant_id": "Green-tek-A17173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "10:15:00 AM",
    "power": 0.759,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "3/31/2024 10:30",
    "plant_id": "Green-tek-A17174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "10:30:00 AM",
    "power": 0.849,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "3/31/2024 10:45",
    "plant_id": "Green-tek-A17175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "10:45:00 AM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "3/31/2024 11:00",
    "plant_id": "Green-tek-A17176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "11:00:00 AM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "3/31/2024 11:15",
    "plant_id": "Green-tek-A17177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "11:15:00 AM",
    "power": 1.052,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "3/31/2024 11:30",
    "plant_id": "Green-tek-A17178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "11:30:00 AM",
    "power": 1.085,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "3/31/2024 11:45",
    "plant_id": "Green-tek-A17179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "11:45:00 AM",
    "power": 1.096,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "3/31/2024 12:00",
    "plant_id": "Green-tek-A17180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "12:00:00 PM",
    "power": 1.085,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "3/31/2024 12:15",
    "plant_id": "Green-tek-A17181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "12:15:00 PM",
    "power": 1.052,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "3/31/2024 12:30",
    "plant_id": "Green-tek-A17182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "12:30:00 PM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "3/31/2024 12:45",
    "plant_id": "Green-tek-A17183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "12:45:00 PM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "3/31/2024 13:00",
    "plant_id": "Green-tek-A17184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "1:00:00 PM",
    "power": 0.849,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "3/31/2024 13:15",
    "plant_id": "Green-tek-A17185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "1:15:00 PM",
    "power": 0.759,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "3/31/2024 13:30",
    "plant_id": "Green-tek-A17186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "1:30:00 PM",
    "power": 0.665,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "3/31/2024 13:45",
    "plant_id": "Green-tek-A17187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "1:45:00 PM",
    "power": 0.57,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "3/31/2024 14:00",
    "plant_id": "Green-tek-A17188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "2:00:00 PM",
    "power": 0.48,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "3/31/2024 14:15",
    "plant_id": "Green-tek-A17189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "2:15:00 PM",
    "power": 0.395,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "3/31/2024 14:30",
    "plant_id": "Green-tek-A17190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "2:30:00 PM",
    "power": 0.319,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "3/31/2024 14:45",
    "plant_id": "Green-tek-A17191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "2:45:00 PM",
    "power": 0.252,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "3/31/2024 15:00",
    "plant_id": "Green-tek-A17192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "3:00:00 PM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "3/31/2024 15:15",
    "plant_id": "Green-tek-A17193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "3:15:00 PM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "3/31/2024 15:30",
    "plant_id": "Green-tek-A17194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "3:30:00 PM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "3/31/2024 15:45",
    "plant_id": "Green-tek-A17195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "3:45:00 PM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "3/31/2024 16:00",
    "plant_id": "Green-tek-A17196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "3/31/2024 16:15",
    "plant_id": "Green-tek-A17197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "3/31/2024 16:30",
    "plant_id": "Green-tek-A17198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "3/31/2024 16:45",
    "plant_id": "Green-tek-A17199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "3/31/2024 17:00",
    "plant_id": "Green-tek-A17200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "3/31/2024 17:15",
    "plant_id": "Green-tek-A17201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "3/31/2024 17:30",
    "plant_id": "Green-tek-A17202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "3/31/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/1/2024 6:00",
    "plant_id": "Green-tek-A17203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/1/2024 6:15",
    "plant_id": "Green-tek-A17204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/1/2024 6:30",
    "plant_id": "Green-tek-A17205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/1/2024 6:45",
    "plant_id": "Green-tek-A17206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/1/2024 7:00",
    "plant_id": "Green-tek-A17207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/1/2024 7:15",
    "plant_id": "Green-tek-A17208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/1/2024 7:30",
    "plant_id": "Green-tek-A17209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/1/2024 7:45",
    "plant_id": "Green-tek-A17210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "7:45:00 AM",
    "power": 0.078,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/1/2024 8:00",
    "plant_id": "Green-tek-A17211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "8:00:00 AM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/1/2024 8:15",
    "plant_id": "Green-tek-A17212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "8:15:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/1/2024 8:30",
    "plant_id": "Green-tek-A17213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "8:30:00 AM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/1/2024 8:45",
    "plant_id": "Green-tek-A17214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "8:45:00 AM",
    "power": 0.244,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "4/1/2024 9:00",
    "plant_id": "Green-tek-A17215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "9:00:00 AM",
    "power": 0.309,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "4/1/2024 9:15",
    "plant_id": "Green-tek-A17216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "9:15:00 AM",
    "power": 0.383,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "4/1/2024 9:30",
    "plant_id": "Green-tek-A17217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "9:30:00 AM",
    "power": 0.465,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "4/1/2024 9:45",
    "plant_id": "Green-tek-A17218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "9:45:00 AM",
    "power": 0.553,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "4/1/2024 10:00",
    "plant_id": "Green-tek-A17219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "10:00:00 AM",
    "power": 0.644,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "4/1/2024 10:15",
    "plant_id": "Green-tek-A17220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "10:15:00 AM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "4/1/2024 10:30",
    "plant_id": "Green-tek-A17221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "10:30:00 AM",
    "power": 0.823,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "4/1/2024 10:45",
    "plant_id": "Green-tek-A17222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "10:45:00 AM",
    "power": 0.902,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "4/1/2024 11:00",
    "plant_id": "Green-tek-A17223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "11:00:00 AM",
    "power": 0.969,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "4/1/2024 11:15",
    "plant_id": "Green-tek-A17224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "11:15:00 AM",
    "power": 1.019,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "4/1/2024 11:30",
    "plant_id": "Green-tek-A17225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "11:30:00 AM",
    "power": 1.051,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "4/1/2024 11:45",
    "plant_id": "Green-tek-A17226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "11:45:00 AM",
    "power": 1.062,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "4/1/2024 12:00",
    "plant_id": "Green-tek-A17227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "12:00:00 PM",
    "power": 1.051,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "4/1/2024 12:15",
    "plant_id": "Green-tek-A17228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "12:15:00 PM",
    "power": 1.019,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "4/1/2024 12:30",
    "plant_id": "Green-tek-A17229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "12:30:00 PM",
    "power": 0.969,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "4/1/2024 12:45",
    "plant_id": "Green-tek-A17230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "12:45:00 PM",
    "power": 0.902,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "4/1/2024 13:00",
    "plant_id": "Green-tek-A17231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "1:00:00 PM",
    "power": 0.823,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "4/1/2024 13:15",
    "plant_id": "Green-tek-A17232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "1:15:00 PM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "4/1/2024 13:30",
    "plant_id": "Green-tek-A17233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "1:30:00 PM",
    "power": 0.644,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "4/1/2024 13:45",
    "plant_id": "Green-tek-A17234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "1:45:00 PM",
    "power": 0.553,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "4/1/2024 14:00",
    "plant_id": "Green-tek-A17235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "2:00:00 PM",
    "power": 0.465,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "4/1/2024 14:15",
    "plant_id": "Green-tek-A17236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "2:15:00 PM",
    "power": 0.383,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "4/1/2024 14:30",
    "plant_id": "Green-tek-A17237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "2:30:00 PM",
    "power": 0.309,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "4/1/2024 14:45",
    "plant_id": "Green-tek-A17238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "2:45:00 PM",
    "power": 0.244,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "4/1/2024 15:00",
    "plant_id": "Green-tek-A17239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "3:00:00 PM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/1/2024 15:15",
    "plant_id": "Green-tek-A17240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "3:15:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/1/2024 15:30",
    "plant_id": "Green-tek-A17241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "3:30:00 PM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/1/2024 15:45",
    "plant_id": "Green-tek-A17242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "3:45:00 PM",
    "power": 0.078,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/1/2024 16:00",
    "plant_id": "Green-tek-A17243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/1/2024 16:15",
    "plant_id": "Green-tek-A17244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/1/2024 16:30",
    "plant_id": "Green-tek-A17245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/1/2024 16:45",
    "plant_id": "Green-tek-A17246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/1/2024 17:00",
    "plant_id": "Green-tek-A17247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/1/2024 17:15",
    "plant_id": "Green-tek-A17248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/1/2024 17:30",
    "plant_id": "Green-tek-A17249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/1/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/2/2024 6:00",
    "plant_id": "Green-tek-A17250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/2/2024 6:15",
    "plant_id": "Green-tek-A17251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/2/2024 6:30",
    "plant_id": "Green-tek-A17252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/2/2024 6:45",
    "plant_id": "Green-tek-A17253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/2/2024 7:00",
    "plant_id": "Green-tek-A17254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/2/2024 7:15",
    "plant_id": "Green-tek-A17255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/2/2024 7:30",
    "plant_id": "Green-tek-A17256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/2/2024 7:45",
    "plant_id": "Green-tek-A17257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/2/2024 8:00",
    "plant_id": "Green-tek-A17258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "8:00:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "4/2/2024 8:15",
    "plant_id": "Green-tek-A17259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "8:15:00 AM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "4/2/2024 8:30",
    "plant_id": "Green-tek-A17260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "8:30:00 AM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "4/2/2024 8:45",
    "plant_id": "Green-tek-A17261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "8:45:00 AM",
    "power": 0.262,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "4/2/2024 9:00",
    "plant_id": "Green-tek-A17262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "9:00:00 AM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "4/2/2024 9:15",
    "plant_id": "Green-tek-A17263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "9:15:00 AM",
    "power": 0.41,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "4/2/2024 9:30",
    "plant_id": "Green-tek-A17264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "9:30:00 AM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/2/2024 9:45",
    "plant_id": "Green-tek-A17265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "9:45:00 AM",
    "power": 0.593,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "4/2/2024 10:00",
    "plant_id": "Green-tek-A17266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "10:00:00 AM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "4/2/2024 10:15",
    "plant_id": "Green-tek-A17267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "10:15:00 AM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "4/2/2024 10:30",
    "plant_id": "Green-tek-A17268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "10:30:00 AM",
    "power": 0.882,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "4/2/2024 10:45",
    "plant_id": "Green-tek-A17269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "10:45:00 AM",
    "power": 0.967,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "4/2/2024 11:00",
    "plant_id": "Green-tek-A17270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "11:00:00 AM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "4/2/2024 11:15",
    "plant_id": "Green-tek-A17271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "11:15:00 AM",
    "power": 1.093,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "4/2/2024 11:30",
    "plant_id": "Green-tek-A17272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "11:30:00 AM",
    "power": 1.127,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "4/2/2024 11:45",
    "plant_id": "Green-tek-A17273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "11:45:00 AM",
    "power": 1.139,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "4/2/2024 12:00",
    "plant_id": "Green-tek-A17274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "12:00:00 PM",
    "power": 1.127,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "4/2/2024 12:15",
    "plant_id": "Green-tek-A17275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "12:15:00 PM",
    "power": 1.093,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "4/2/2024 12:30",
    "plant_id": "Green-tek-A17276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "12:30:00 PM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "4/2/2024 12:45",
    "plant_id": "Green-tek-A17277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "12:45:00 PM",
    "power": 0.967,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "4/2/2024 13:00",
    "plant_id": "Green-tek-A17278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "1:00:00 PM",
    "power": 0.882,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "4/2/2024 13:15",
    "plant_id": "Green-tek-A17279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "1:15:00 PM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "4/2/2024 13:30",
    "plant_id": "Green-tek-A17280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "1:30:00 PM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "4/2/2024 13:45",
    "plant_id": "Green-tek-A17281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "1:45:00 PM",
    "power": 0.593,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "4/2/2024 14:00",
    "plant_id": "Green-tek-A17282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "2:00:00 PM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/2/2024 14:15",
    "plant_id": "Green-tek-A17283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "2:15:00 PM",
    "power": 0.41,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "4/2/2024 14:30",
    "plant_id": "Green-tek-A17284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "2:30:00 PM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "4/2/2024 14:45",
    "plant_id": "Green-tek-A17285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "2:45:00 PM",
    "power": 0.262,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "4/2/2024 15:00",
    "plant_id": "Green-tek-A17286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "3:00:00 PM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "4/2/2024 15:15",
    "plant_id": "Green-tek-A17287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "3:15:00 PM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "4/2/2024 15:30",
    "plant_id": "Green-tek-A17288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "3:30:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "4/2/2024 15:45",
    "plant_id": "Green-tek-A17289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/2/2024 16:00",
    "plant_id": "Green-tek-A17290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/2/2024 16:15",
    "plant_id": "Green-tek-A17291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/2/2024 16:30",
    "plant_id": "Green-tek-A17292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/2/2024 16:45",
    "plant_id": "Green-tek-A17293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/2/2024 17:00",
    "plant_id": "Green-tek-A17294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/2/2024 17:15",
    "plant_id": "Green-tek-A17295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/2/2024 17:30",
    "plant_id": "Green-tek-A17296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/2/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/3/2024 6:00",
    "plant_id": "Green-tek-A17297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/3/2024 6:15",
    "plant_id": "Green-tek-A17298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/3/2024 6:30",
    "plant_id": "Green-tek-A17299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/3/2024 6:45",
    "plant_id": "Green-tek-A17300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/3/2024 7:00",
    "plant_id": "Green-tek-A17301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/3/2024 7:15",
    "plant_id": "Green-tek-A17302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/3/2024 7:30",
    "plant_id": "Green-tek-A17303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/3/2024 7:45",
    "plant_id": "Green-tek-A17304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "4/3/2024 8:00",
    "plant_id": "Green-tek-A17305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "4/3/2024 8:15",
    "plant_id": "Green-tek-A17306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "8:15:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "4/3/2024 8:30",
    "plant_id": "Green-tek-A17307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "8:30:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "4/3/2024 8:45",
    "plant_id": "Green-tek-A17308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "8:45:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "4/3/2024 9:00",
    "plant_id": "Green-tek-A17309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "9:00:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "4/3/2024 9:15",
    "plant_id": "Green-tek-A17310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "9:15:00 AM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "4/3/2024 9:30",
    "plant_id": "Green-tek-A17311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "9:30:00 AM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "4/3/2024 9:45",
    "plant_id": "Green-tek-A17312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "9:45:00 AM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "4/3/2024 10:00",
    "plant_id": "Green-tek-A17313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "10:00:00 AM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "4/3/2024 10:15",
    "plant_id": "Green-tek-A17314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "10:15:00 AM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "4/3/2024 10:30",
    "plant_id": "Green-tek-A17315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "10:30:00 AM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "4/3/2024 10:45",
    "plant_id": "Green-tek-A17316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "10:45:00 AM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "4/3/2024 11:00",
    "plant_id": "Green-tek-A17317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "11:00:00 AM",
    "power": 1.187,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "4/3/2024 11:15",
    "plant_id": "Green-tek-A17318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "11:15:00 AM",
    "power": 1.249,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "4/3/2024 11:30",
    "plant_id": "Green-tek-A17319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "11:30:00 AM",
    "power": 1.288,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.3315,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "4/3/2024 11:45",
    "plant_id": "Green-tek-A17320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "11:45:00 AM",
    "power": 1.301,
    "Energy": 0.325,
    "output Energy": 0.325,
    "Ac output": 1.325,
    "dc output": 1.3515,
    "AC_R_i": 0.00042391,
    "AC_S_i": 0.000466301,
    "AC_T_i": 0.00051293,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0974993,
    "AC_S_pow": 0.10724923,
    "AC_T_Pow": 0.1179739,
    "AC_R_output": 0.3975,
    "AC_S_output": 0.43725,
    "AC_T_output": 0.480975,
    "dc_current": 0.00238971,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.3315,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000325,
    "carbon offset": 0.40625,
    "trees planted": 9.027777778,
    "DC_R_output": 0.131175,
    "DC_S_output": 0.1442925,
    "DC_T_output": 0.15872175
  },
  {
    "datetime": "4/3/2024 12:00",
    "plant_id": "Green-tek-A17321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "12:00:00 PM",
    "power": 1.288,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "4/3/2024 12:15",
    "plant_id": "Green-tek-A17322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "12:15:00 PM",
    "power": 1.249,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "4/3/2024 12:30",
    "plant_id": "Green-tek-A17323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "12:30:00 PM",
    "power": 1.187,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "4/3/2024 12:45",
    "plant_id": "Green-tek-A17324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "12:45:00 PM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "4/3/2024 13:00",
    "plant_id": "Green-tek-A17325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "1:00:00 PM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "4/3/2024 13:15",
    "plant_id": "Green-tek-A17326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "1:15:00 PM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "4/3/2024 13:30",
    "plant_id": "Green-tek-A17327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "1:30:00 PM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "4/3/2024 13:45",
    "plant_id": "Green-tek-A17328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "1:45:00 PM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "4/3/2024 14:00",
    "plant_id": "Green-tek-A17329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "2:00:00 PM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "4/3/2024 14:15",
    "plant_id": "Green-tek-A17330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "2:15:00 PM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "4/3/2024 14:30",
    "plant_id": "Green-tek-A17331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "2:30:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "4/3/2024 14:45",
    "plant_id": "Green-tek-A17332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "2:45:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "4/3/2024 15:00",
    "plant_id": "Green-tek-A17333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "3:00:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "4/3/2024 15:15",
    "plant_id": "Green-tek-A17334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "3:15:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "4/3/2024 15:30",
    "plant_id": "Green-tek-A17335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "4/3/2024 15:45",
    "plant_id": "Green-tek-A17336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "4/3/2024 16:00",
    "plant_id": "Green-tek-A17337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/3/2024 16:15",
    "plant_id": "Green-tek-A17338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/3/2024 16:30",
    "plant_id": "Green-tek-A17339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/3/2024 16:45",
    "plant_id": "Green-tek-A17340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/3/2024 17:00",
    "plant_id": "Green-tek-A17341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/3/2024 17:15",
    "plant_id": "Green-tek-A17342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/3/2024 17:30",
    "plant_id": "Green-tek-A17343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/3/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/4/2024 6:00",
    "plant_id": "Green-tek-A17344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/4/2024 6:15",
    "plant_id": "Green-tek-A17345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/4/2024 6:30",
    "plant_id": "Green-tek-A17346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/4/2024 6:45",
    "plant_id": "Green-tek-A17347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/4/2024 7:00",
    "plant_id": "Green-tek-A17348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/4/2024 7:15",
    "plant_id": "Green-tek-A17349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/4/2024 7:30",
    "plant_id": "Green-tek-A17350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/4/2024 7:45",
    "plant_id": "Green-tek-A17351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/4/2024 8:00",
    "plant_id": "Green-tek-A17352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "8:00:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/4/2024 8:15",
    "plant_id": "Green-tek-A17353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "8:15:00 AM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/4/2024 8:30",
    "plant_id": "Green-tek-A17354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "8:30:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/4/2024 8:45",
    "plant_id": "Green-tek-A17355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "8:45:00 AM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "4/4/2024 9:00",
    "plant_id": "Green-tek-A17356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "9:00:00 AM",
    "power": 0.243,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "4/4/2024 9:15",
    "plant_id": "Green-tek-A17357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "9:15:00 AM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "4/4/2024 9:30",
    "plant_id": "Green-tek-A17358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "9:30:00 AM",
    "power": 0.366,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "4/4/2024 9:45",
    "plant_id": "Green-tek-A17359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "9:45:00 AM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "4/4/2024 10:00",
    "plant_id": "Green-tek-A17360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "10:00:00 AM",
    "power": 0.507,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "4/4/2024 10:15",
    "plant_id": "Green-tek-A17361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "10:15:00 AM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "4/4/2024 10:30",
    "plant_id": "Green-tek-A17362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "10:30:00 AM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "4/4/2024 10:45",
    "plant_id": "Green-tek-A17363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "10:45:00 AM",
    "power": 0.71,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "4/4/2024 11:00",
    "plant_id": "Green-tek-A17364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "11:00:00 AM",
    "power": 0.762,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "4/4/2024 11:15",
    "plant_id": "Green-tek-A17365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "11:15:00 AM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "4/4/2024 11:30",
    "plant_id": "Green-tek-A17366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "11:30:00 AM",
    "power": 0.827,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "4/4/2024 11:45",
    "plant_id": "Green-tek-A17367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "11:45:00 AM",
    "power": 0.835,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "4/4/2024 12:00",
    "plant_id": "Green-tek-A17368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "12:00:00 PM",
    "power": 0.827,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "4/4/2024 12:15",
    "plant_id": "Green-tek-A17369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "12:15:00 PM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "4/4/2024 12:30",
    "plant_id": "Green-tek-A17370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "12:30:00 PM",
    "power": 0.762,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "4/4/2024 12:45",
    "plant_id": "Green-tek-A17371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "12:45:00 PM",
    "power": 0.71,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "4/4/2024 13:00",
    "plant_id": "Green-tek-A17372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "1:00:00 PM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "4/4/2024 13:15",
    "plant_id": "Green-tek-A17373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "1:15:00 PM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "4/4/2024 13:30",
    "plant_id": "Green-tek-A17374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "1:30:00 PM",
    "power": 0.507,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "4/4/2024 13:45",
    "plant_id": "Green-tek-A17375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "1:45:00 PM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "4/4/2024 14:00",
    "plant_id": "Green-tek-A17376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "2:00:00 PM",
    "power": 0.366,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "4/4/2024 14:15",
    "plant_id": "Green-tek-A17377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "2:15:00 PM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "4/4/2024 14:30",
    "plant_id": "Green-tek-A17378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "2:30:00 PM",
    "power": 0.243,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "4/4/2024 14:45",
    "plant_id": "Green-tek-A17379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "2:45:00 PM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "4/4/2024 15:00",
    "plant_id": "Green-tek-A17380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "3:00:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/4/2024 15:15",
    "plant_id": "Green-tek-A17381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "3:15:00 PM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/4/2024 15:30",
    "plant_id": "Green-tek-A17382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "3:30:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/4/2024 15:45",
    "plant_id": "Green-tek-A17383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/4/2024 16:00",
    "plant_id": "Green-tek-A17384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/4/2024 16:15",
    "plant_id": "Green-tek-A17385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/4/2024 16:30",
    "plant_id": "Green-tek-A17386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/4/2024 16:45",
    "plant_id": "Green-tek-A17387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/4/2024 17:00",
    "plant_id": "Green-tek-A17388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/4/2024 17:15",
    "plant_id": "Green-tek-A17389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/4/2024 17:30",
    "plant_id": "Green-tek-A17390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/4/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/5/2024 6:00",
    "plant_id": "Green-tek-A17391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/5/2024 6:15",
    "plant_id": "Green-tek-A17392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/5/2024 6:30",
    "plant_id": "Green-tek-A17393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/5/2024 6:45",
    "plant_id": "Green-tek-A17394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/5/2024 7:00",
    "plant_id": "Green-tek-A17395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/5/2024 7:15",
    "plant_id": "Green-tek-A17396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/5/2024 7:30",
    "plant_id": "Green-tek-A17397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/5/2024 7:45",
    "plant_id": "Green-tek-A17398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "7:45:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/5/2024 8:00",
    "plant_id": "Green-tek-A17399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "8:00:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/5/2024 8:15",
    "plant_id": "Green-tek-A17400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "8:15:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/5/2024 8:30",
    "plant_id": "Green-tek-A17401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "8:30:00 AM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/5/2024 8:45",
    "plant_id": "Green-tek-A17402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "8:45:00 AM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/5/2024 9:00",
    "plant_id": "Green-tek-A17403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "9:00:00 AM",
    "power": 0.239,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/5/2024 9:15",
    "plant_id": "Green-tek-A17404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "9:15:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/5/2024 9:30",
    "plant_id": "Green-tek-A17405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "9:30:00 AM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "4/5/2024 9:45",
    "plant_id": "Green-tek-A17406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "9:45:00 AM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "4/5/2024 10:00",
    "plant_id": "Green-tek-A17407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "10:00:00 AM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/5/2024 10:15",
    "plant_id": "Green-tek-A17408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "10:15:00 AM",
    "power": 0.57,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "4/5/2024 10:30",
    "plant_id": "Green-tek-A17409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "10:30:00 AM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "4/5/2024 10:45",
    "plant_id": "Green-tek-A17410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "10:45:00 AM",
    "power": 0.699,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "4/5/2024 11:00",
    "plant_id": "Green-tek-A17411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "11:00:00 AM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "4/5/2024 11:15",
    "plant_id": "Green-tek-A17412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "11:15:00 AM",
    "power": 0.79,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "4/5/2024 11:30",
    "plant_id": "Green-tek-A17413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "11:30:00 AM",
    "power": 0.814,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "4/5/2024 11:45",
    "plant_id": "Green-tek-A17414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "11:45:00 AM",
    "power": 0.823,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "4/5/2024 12:00",
    "plant_id": "Green-tek-A17415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "12:00:00 PM",
    "power": 0.814,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "4/5/2024 12:15",
    "plant_id": "Green-tek-A17416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "12:15:00 PM",
    "power": 0.79,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "4/5/2024 12:30",
    "plant_id": "Green-tek-A17417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "12:30:00 PM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "4/5/2024 12:45",
    "plant_id": "Green-tek-A17418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "12:45:00 PM",
    "power": 0.699,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "4/5/2024 13:00",
    "plant_id": "Green-tek-A17419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "1:00:00 PM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "4/5/2024 13:15",
    "plant_id": "Green-tek-A17420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "1:15:00 PM",
    "power": 0.57,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "4/5/2024 13:30",
    "plant_id": "Green-tek-A17421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "1:30:00 PM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/5/2024 13:45",
    "plant_id": "Green-tek-A17422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "1:45:00 PM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "4/5/2024 14:00",
    "plant_id": "Green-tek-A17423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "2:00:00 PM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "4/5/2024 14:15",
    "plant_id": "Green-tek-A17424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "2:15:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/5/2024 14:30",
    "plant_id": "Green-tek-A17425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "2:30:00 PM",
    "power": 0.239,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/5/2024 14:45",
    "plant_id": "Green-tek-A17426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "2:45:00 PM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/5/2024 15:00",
    "plant_id": "Green-tek-A17427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "3:00:00 PM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/5/2024 15:15",
    "plant_id": "Green-tek-A17428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "3:15:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/5/2024 15:30",
    "plant_id": "Green-tek-A17429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "3:30:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/5/2024 15:45",
    "plant_id": "Green-tek-A17430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "3:45:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/5/2024 16:00",
    "plant_id": "Green-tek-A17431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/5/2024 16:15",
    "plant_id": "Green-tek-A17432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/5/2024 16:30",
    "plant_id": "Green-tek-A17433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/5/2024 16:45",
    "plant_id": "Green-tek-A17434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/5/2024 17:00",
    "plant_id": "Green-tek-A17435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/5/2024 17:15",
    "plant_id": "Green-tek-A17436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/5/2024 17:30",
    "plant_id": "Green-tek-A17437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/5/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/6/2024 6:00",
    "plant_id": "Green-tek-A17438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/6/2024 6:15",
    "plant_id": "Green-tek-A17439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/6/2024 6:30",
    "plant_id": "Green-tek-A17440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/6/2024 6:45",
    "plant_id": "Green-tek-A17441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/6/2024 7:00",
    "plant_id": "Green-tek-A17442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/6/2024 7:15",
    "plant_id": "Green-tek-A17443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/6/2024 7:30",
    "plant_id": "Green-tek-A17444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "4/6/2024 7:45",
    "plant_id": "Green-tek-A17445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/6/2024 8:00",
    "plant_id": "Green-tek-A17446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "8:00:00 AM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "4/6/2024 8:15",
    "plant_id": "Green-tek-A17447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "8:15:00 AM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "4/6/2024 8:30",
    "plant_id": "Green-tek-A17448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "8:30:00 AM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "4/6/2024 8:45",
    "plant_id": "Green-tek-A17449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "8:45:00 AM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "4/6/2024 9:00",
    "plant_id": "Green-tek-A17450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "9:00:00 AM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/6/2024 9:15",
    "plant_id": "Green-tek-A17451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "9:15:00 AM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "4/6/2024 9:30",
    "plant_id": "Green-tek-A17452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "9:30:00 AM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "4/6/2024 9:45",
    "plant_id": "Green-tek-A17453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "9:45:00 AM",
    "power": 0.527,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "4/6/2024 10:00",
    "plant_id": "Green-tek-A17454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "10:00:00 AM",
    "power": 0.614,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "4/6/2024 10:15",
    "plant_id": "Green-tek-A17455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "10:15:00 AM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "4/6/2024 10:30",
    "plant_id": "Green-tek-A17456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "10:30:00 AM",
    "power": 0.784,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "4/6/2024 10:45",
    "plant_id": "Green-tek-A17457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "10:45:00 AM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "4/6/2024 11:00",
    "plant_id": "Green-tek-A17458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "11:00:00 AM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "4/6/2024 11:15",
    "plant_id": "Green-tek-A17459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "11:15:00 AM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "4/6/2024 11:30",
    "plant_id": "Green-tek-A17460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "11:30:00 AM",
    "power": 1.002,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "4/6/2024 11:45",
    "plant_id": "Green-tek-A17461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "11:45:00 AM",
    "power": 1.012,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "4/6/2024 12:00",
    "plant_id": "Green-tek-A17462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "12:00:00 PM",
    "power": 1.002,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "4/6/2024 12:15",
    "plant_id": "Green-tek-A17463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "12:15:00 PM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "4/6/2024 12:30",
    "plant_id": "Green-tek-A17464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "12:30:00 PM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "4/6/2024 12:45",
    "plant_id": "Green-tek-A17465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "12:45:00 PM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "4/6/2024 13:00",
    "plant_id": "Green-tek-A17466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "1:00:00 PM",
    "power": 0.784,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "4/6/2024 13:15",
    "plant_id": "Green-tek-A17467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "1:15:00 PM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "4/6/2024 13:30",
    "plant_id": "Green-tek-A17468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "1:30:00 PM",
    "power": 0.614,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "4/6/2024 13:45",
    "plant_id": "Green-tek-A17469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "1:45:00 PM",
    "power": 0.527,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "4/6/2024 14:00",
    "plant_id": "Green-tek-A17470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "2:00:00 PM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "4/6/2024 14:15",
    "plant_id": "Green-tek-A17471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "2:15:00 PM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "4/6/2024 14:30",
    "plant_id": "Green-tek-A17472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "2:30:00 PM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/6/2024 14:45",
    "plant_id": "Green-tek-A17473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "2:45:00 PM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "4/6/2024 15:00",
    "plant_id": "Green-tek-A17474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "3:00:00 PM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "4/6/2024 15:15",
    "plant_id": "Green-tek-A17475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "3:15:00 PM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "4/6/2024 15:30",
    "plant_id": "Green-tek-A17476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "3:30:00 PM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "4/6/2024 15:45",
    "plant_id": "Green-tek-A17477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/6/2024 16:00",
    "plant_id": "Green-tek-A17478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "4/6/2024 16:15",
    "plant_id": "Green-tek-A17479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/6/2024 16:30",
    "plant_id": "Green-tek-A17480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/6/2024 16:45",
    "plant_id": "Green-tek-A17481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/6/2024 17:00",
    "plant_id": "Green-tek-A17482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/6/2024 17:15",
    "plant_id": "Green-tek-A17483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/6/2024 17:30",
    "plant_id": "Green-tek-A17484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/6/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/7/2024 6:00",
    "plant_id": "Green-tek-A17485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/7/2024 6:15",
    "plant_id": "Green-tek-A17486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/7/2024 6:30",
    "plant_id": "Green-tek-A17487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/7/2024 6:45",
    "plant_id": "Green-tek-A17488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/7/2024 7:00",
    "plant_id": "Green-tek-A17489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/7/2024 7:15",
    "plant_id": "Green-tek-A17490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/7/2024 7:30",
    "plant_id": "Green-tek-A17491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/7/2024 7:45",
    "plant_id": "Green-tek-A17492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "4/7/2024 8:00",
    "plant_id": "Green-tek-A17493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "8:00:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "4/7/2024 8:15",
    "plant_id": "Green-tek-A17494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "8:15:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "4/7/2024 8:30",
    "plant_id": "Green-tek-A17495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "8:30:00 AM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "4/7/2024 8:45",
    "plant_id": "Green-tek-A17496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "8:45:00 AM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "4/7/2024 9:00",
    "plant_id": "Green-tek-A17497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "9:00:00 AM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "4/7/2024 9:15",
    "plant_id": "Green-tek-A17498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "9:15:00 AM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "4/7/2024 9:30",
    "plant_id": "Green-tek-A17499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "9:30:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "4/7/2024 9:45",
    "plant_id": "Green-tek-A17500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "9:45:00 AM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "4/7/2024 10:00",
    "plant_id": "Green-tek-A17501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "10:00:00 AM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "4/7/2024 10:15",
    "plant_id": "Green-tek-A17502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "10:15:00 AM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "4/7/2024 10:30",
    "plant_id": "Green-tek-A17503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "10:30:00 AM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "4/7/2024 10:45",
    "plant_id": "Green-tek-A17504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "10:45:00 AM",
    "power": 0.745,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "4/7/2024 11:00",
    "plant_id": "Green-tek-A17505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "11:00:00 AM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "4/7/2024 11:15",
    "plant_id": "Green-tek-A17506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "11:15:00 AM",
    "power": 0.842,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "4/7/2024 11:30",
    "plant_id": "Green-tek-A17507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "11:30:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "4/7/2024 11:45",
    "plant_id": "Green-tek-A17508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "11:45:00 AM",
    "power": 0.877,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "4/7/2024 12:00",
    "plant_id": "Green-tek-A17509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "12:00:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "4/7/2024 12:15",
    "plant_id": "Green-tek-A17510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "12:15:00 PM",
    "power": 0.842,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "4/7/2024 12:30",
    "plant_id": "Green-tek-A17511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "12:30:00 PM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "4/7/2024 12:45",
    "plant_id": "Green-tek-A17512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "12:45:00 PM",
    "power": 0.745,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "4/7/2024 13:00",
    "plant_id": "Green-tek-A17513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "1:00:00 PM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "4/7/2024 13:15",
    "plant_id": "Green-tek-A17514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "1:15:00 PM",
    "power": 0.607,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "4/7/2024 13:30",
    "plant_id": "Green-tek-A17515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "1:30:00 PM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "4/7/2024 13:45",
    "plant_id": "Green-tek-A17516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "1:45:00 PM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "4/7/2024 14:00",
    "plant_id": "Green-tek-A17517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "2:00:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "4/7/2024 14:15",
    "plant_id": "Green-tek-A17518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "2:15:00 PM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "4/7/2024 14:30",
    "plant_id": "Green-tek-A17519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "2:30:00 PM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "4/7/2024 14:45",
    "plant_id": "Green-tek-A17520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "2:45:00 PM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "4/7/2024 15:00",
    "plant_id": "Green-tek-A17521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "3:00:00 PM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "4/7/2024 15:15",
    "plant_id": "Green-tek-A17522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "3:15:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "4/7/2024 15:30",
    "plant_id": "Green-tek-A17523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "3:30:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "4/7/2024 15:45",
    "plant_id": "Green-tek-A17524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "4/7/2024 16:00",
    "plant_id": "Green-tek-A17525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/7/2024 16:15",
    "plant_id": "Green-tek-A17526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/7/2024 16:30",
    "plant_id": "Green-tek-A17527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/7/2024 16:45",
    "plant_id": "Green-tek-A17528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/7/2024 17:00",
    "plant_id": "Green-tek-A17529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/7/2024 17:15",
    "plant_id": "Green-tek-A17530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/7/2024 17:30",
    "plant_id": "Green-tek-A17531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/7/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/8/2024 6:00",
    "plant_id": "Green-tek-A17532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/8/2024 6:15",
    "plant_id": "Green-tek-A17533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/8/2024 6:30",
    "plant_id": "Green-tek-A17534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/8/2024 6:45",
    "plant_id": "Green-tek-A17535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/8/2024 7:00",
    "plant_id": "Green-tek-A17536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/8/2024 7:15",
    "plant_id": "Green-tek-A17537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/8/2024 7:30",
    "plant_id": "Green-tek-A17538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/8/2024 7:45",
    "plant_id": "Green-tek-A17539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/8/2024 8:00",
    "plant_id": "Green-tek-A17540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "8:00:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/8/2024 8:15",
    "plant_id": "Green-tek-A17541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "8:15:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/8/2024 8:30",
    "plant_id": "Green-tek-A17542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "8:30:00 AM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/8/2024 8:45",
    "plant_id": "Green-tek-A17543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "8:45:00 AM",
    "power": 0.191,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "4/8/2024 9:00",
    "plant_id": "Green-tek-A17544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "9:00:00 AM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/8/2024 9:15",
    "plant_id": "Green-tek-A17545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "9:15:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "4/8/2024 9:30",
    "plant_id": "Green-tek-A17546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "9:30:00 AM",
    "power": 0.362,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "4/8/2024 9:45",
    "plant_id": "Green-tek-A17547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "9:45:00 AM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "4/8/2024 10:00",
    "plant_id": "Green-tek-A17548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "10:00:00 AM",
    "power": 0.502,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "4/8/2024 10:15",
    "plant_id": "Green-tek-A17549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "10:15:00 AM",
    "power": 0.574,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "4/8/2024 10:30",
    "plant_id": "Green-tek-A17550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "10:30:00 AM",
    "power": 0.642,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "4/8/2024 10:45",
    "plant_id": "Green-tek-A17551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "10:45:00 AM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "4/8/2024 11:00",
    "plant_id": "Green-tek-A17552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "11:00:00 AM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "4/8/2024 11:15",
    "plant_id": "Green-tek-A17553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "11:15:00 AM",
    "power": 0.795,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "4/8/2024 11:30",
    "plant_id": "Green-tek-A17554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "11:30:00 AM",
    "power": 0.82,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "4/8/2024 11:45",
    "plant_id": "Green-tek-A17555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "11:45:00 AM",
    "power": 0.828,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "4/8/2024 12:00",
    "plant_id": "Green-tek-A17556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "12:00:00 PM",
    "power": 0.82,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "4/8/2024 12:15",
    "plant_id": "Green-tek-A17557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "12:15:00 PM",
    "power": 0.795,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "4/8/2024 12:30",
    "plant_id": "Green-tek-A17558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "12:30:00 PM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "4/8/2024 12:45",
    "plant_id": "Green-tek-A17559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "12:45:00 PM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "4/8/2024 13:00",
    "plant_id": "Green-tek-A17560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "1:00:00 PM",
    "power": 0.642,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "4/8/2024 13:15",
    "plant_id": "Green-tek-A17561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "1:15:00 PM",
    "power": 0.574,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "4/8/2024 13:30",
    "plant_id": "Green-tek-A17562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "1:30:00 PM",
    "power": 0.502,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "4/8/2024 13:45",
    "plant_id": "Green-tek-A17563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "1:45:00 PM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "4/8/2024 14:00",
    "plant_id": "Green-tek-A17564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "2:00:00 PM",
    "power": 0.362,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "4/8/2024 14:15",
    "plant_id": "Green-tek-A17565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "2:15:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "4/8/2024 14:30",
    "plant_id": "Green-tek-A17566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "2:30:00 PM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/8/2024 14:45",
    "plant_id": "Green-tek-A17567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "2:45:00 PM",
    "power": 0.191,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "4/8/2024 15:00",
    "plant_id": "Green-tek-A17568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "3:00:00 PM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/8/2024 15:15",
    "plant_id": "Green-tek-A17569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "3:15:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/8/2024 15:30",
    "plant_id": "Green-tek-A17570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "3:30:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/8/2024 15:45",
    "plant_id": "Green-tek-A17571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/8/2024 16:00",
    "plant_id": "Green-tek-A17572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/8/2024 16:15",
    "plant_id": "Green-tek-A17573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/8/2024 16:30",
    "plant_id": "Green-tek-A17574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/8/2024 16:45",
    "plant_id": "Green-tek-A17575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/8/2024 17:00",
    "plant_id": "Green-tek-A17576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/8/2024 17:15",
    "plant_id": "Green-tek-A17577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/8/2024 17:30",
    "plant_id": "Green-tek-A17578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/8/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/9/2024 6:00",
    "plant_id": "Green-tek-A17579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/9/2024 6:15",
    "plant_id": "Green-tek-A17580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/9/2024 6:30",
    "plant_id": "Green-tek-A17581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/9/2024 6:45",
    "plant_id": "Green-tek-A17582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/9/2024 7:00",
    "plant_id": "Green-tek-A17583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/9/2024 7:15",
    "plant_id": "Green-tek-A17584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/9/2024 7:30",
    "plant_id": "Green-tek-A17585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/9/2024 7:45",
    "plant_id": "Green-tek-A17586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "7:45:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/9/2024 8:00",
    "plant_id": "Green-tek-A17587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "8:00:00 AM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "4/9/2024 8:15",
    "plant_id": "Green-tek-A17588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "8:15:00 AM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/9/2024 8:30",
    "plant_id": "Green-tek-A17589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "8:30:00 AM",
    "power": 0.166,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "4/9/2024 8:45",
    "plant_id": "Green-tek-A17590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "8:45:00 AM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "4/9/2024 9:00",
    "plant_id": "Green-tek-A17591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "9:00:00 AM",
    "power": 0.271,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "4/9/2024 9:15",
    "plant_id": "Green-tek-A17592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "9:15:00 AM",
    "power": 0.336,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "4/9/2024 9:30",
    "plant_id": "Green-tek-A17593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "9:30:00 AM",
    "power": 0.408,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "4/9/2024 9:45",
    "plant_id": "Green-tek-A17594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "9:45:00 AM",
    "power": 0.485,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "4/9/2024 10:00",
    "plant_id": "Green-tek-A17595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "10:00:00 AM",
    "power": 0.566,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "4/9/2024 10:15",
    "plant_id": "Green-tek-A17596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "10:15:00 AM",
    "power": 0.646,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "4/9/2024 10:30",
    "plant_id": "Green-tek-A17597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "10:30:00 AM",
    "power": 0.723,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "4/9/2024 10:45",
    "plant_id": "Green-tek-A17598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "10:45:00 AM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "4/9/2024 11:00",
    "plant_id": "Green-tek-A17599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "11:00:00 AM",
    "power": 0.851,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "4/9/2024 11:15",
    "plant_id": "Green-tek-A17600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "11:15:00 AM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "4/9/2024 11:30",
    "plant_id": "Green-tek-A17601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "11:30:00 AM",
    "power": 0.923,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "4/9/2024 11:45",
    "plant_id": "Green-tek-A17602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "11:45:00 AM",
    "power": 0.932,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "4/9/2024 12:00",
    "plant_id": "Green-tek-A17603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "12:00:00 PM",
    "power": 0.923,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "4/9/2024 12:15",
    "plant_id": "Green-tek-A17604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "12:15:00 PM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "4/9/2024 12:30",
    "plant_id": "Green-tek-A17605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "12:30:00 PM",
    "power": 0.851,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "4/9/2024 12:45",
    "plant_id": "Green-tek-A17606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "12:45:00 PM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "4/9/2024 13:00",
    "plant_id": "Green-tek-A17607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "1:00:00 PM",
    "power": 0.723,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "4/9/2024 13:15",
    "plant_id": "Green-tek-A17608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "1:15:00 PM",
    "power": 0.646,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "4/9/2024 13:30",
    "plant_id": "Green-tek-A17609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "1:30:00 PM",
    "power": 0.566,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "4/9/2024 13:45",
    "plant_id": "Green-tek-A17610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "1:45:00 PM",
    "power": 0.485,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "4/9/2024 14:00",
    "plant_id": "Green-tek-A17611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "2:00:00 PM",
    "power": 0.408,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "4/9/2024 14:15",
    "plant_id": "Green-tek-A17612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "2:15:00 PM",
    "power": 0.336,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "4/9/2024 14:30",
    "plant_id": "Green-tek-A17613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "2:30:00 PM",
    "power": 0.271,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "4/9/2024 14:45",
    "plant_id": "Green-tek-A17614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "2:45:00 PM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "4/9/2024 15:00",
    "plant_id": "Green-tek-A17615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "3:00:00 PM",
    "power": 0.166,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "4/9/2024 15:15",
    "plant_id": "Green-tek-A17616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "3:15:00 PM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/9/2024 15:30",
    "plant_id": "Green-tek-A17617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "3:30:00 PM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "4/9/2024 15:45",
    "plant_id": "Green-tek-A17618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "3:45:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/9/2024 16:00",
    "plant_id": "Green-tek-A17619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/9/2024 16:15",
    "plant_id": "Green-tek-A17620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/9/2024 16:30",
    "plant_id": "Green-tek-A17621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/9/2024 16:45",
    "plant_id": "Green-tek-A17622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/9/2024 17:00",
    "plant_id": "Green-tek-A17623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/9/2024 17:15",
    "plant_id": "Green-tek-A17624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/9/2024 17:30",
    "plant_id": "Green-tek-A17625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/9/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/10/2024 6:00",
    "plant_id": "Green-tek-A17626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/10/2024 6:15",
    "plant_id": "Green-tek-A17627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/10/2024 6:30",
    "plant_id": "Green-tek-A17628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/10/2024 6:45",
    "plant_id": "Green-tek-A17629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/10/2024 7:00",
    "plant_id": "Green-tek-A17630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/10/2024 7:15",
    "plant_id": "Green-tek-A17631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/10/2024 7:30",
    "plant_id": "Green-tek-A17632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "7:30:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/10/2024 7:45",
    "plant_id": "Green-tek-A17633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "7:45:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/10/2024 8:00",
    "plant_id": "Green-tek-A17634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "8:00:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/10/2024 8:15",
    "plant_id": "Green-tek-A17635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "8:15:00 AM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/10/2024 8:30",
    "plant_id": "Green-tek-A17636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "8:30:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/10/2024 8:45",
    "plant_id": "Green-tek-A17637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "8:45:00 AM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/10/2024 9:00",
    "plant_id": "Green-tek-A17638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "9:00:00 AM",
    "power": 0.236,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "4/10/2024 9:15",
    "plant_id": "Green-tek-A17639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "9:15:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "4/10/2024 9:30",
    "plant_id": "Green-tek-A17640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "9:30:00 AM",
    "power": 0.354,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "4/10/2024 9:45",
    "plant_id": "Green-tek-A17641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "9:45:00 AM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "4/10/2024 10:00",
    "plant_id": "Green-tek-A17642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "10:00:00 AM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "4/10/2024 10:15",
    "plant_id": "Green-tek-A17643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "10:15:00 AM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "4/10/2024 10:30",
    "plant_id": "Green-tek-A17644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "10:30:00 AM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "4/10/2024 10:45",
    "plant_id": "Green-tek-A17645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "10:45:00 AM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "4/10/2024 11:00",
    "plant_id": "Green-tek-A17646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "11:00:00 AM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "4/10/2024 11:15",
    "plant_id": "Green-tek-A17647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "11:15:00 AM",
    "power": 0.778,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "4/10/2024 11:30",
    "plant_id": "Green-tek-A17648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "11:30:00 AM",
    "power": 0.802,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "4/10/2024 11:45",
    "plant_id": "Green-tek-A17649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "11:45:00 AM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "4/10/2024 12:00",
    "plant_id": "Green-tek-A17650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "12:00:00 PM",
    "power": 0.802,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "4/10/2024 12:15",
    "plant_id": "Green-tek-A17651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "12:15:00 PM",
    "power": 0.778,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "4/10/2024 12:30",
    "plant_id": "Green-tek-A17652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "12:30:00 PM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "4/10/2024 12:45",
    "plant_id": "Green-tek-A17653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "12:45:00 PM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "4/10/2024 13:00",
    "plant_id": "Green-tek-A17654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "1:00:00 PM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "4/10/2024 13:15",
    "plant_id": "Green-tek-A17655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "1:15:00 PM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "4/10/2024 13:30",
    "plant_id": "Green-tek-A17656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "1:30:00 PM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "4/10/2024 13:45",
    "plant_id": "Green-tek-A17657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "1:45:00 PM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "4/10/2024 14:00",
    "plant_id": "Green-tek-A17658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "2:00:00 PM",
    "power": 0.354,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "4/10/2024 14:15",
    "plant_id": "Green-tek-A17659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "2:15:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "4/10/2024 14:30",
    "plant_id": "Green-tek-A17660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "2:30:00 PM",
    "power": 0.236,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "4/10/2024 14:45",
    "plant_id": "Green-tek-A17661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "2:45:00 PM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/10/2024 15:00",
    "plant_id": "Green-tek-A17662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "3:00:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/10/2024 15:15",
    "plant_id": "Green-tek-A17663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "3:15:00 PM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/10/2024 15:30",
    "plant_id": "Green-tek-A17664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "3:30:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/10/2024 15:45",
    "plant_id": "Green-tek-A17665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "3:45:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/10/2024 16:00",
    "plant_id": "Green-tek-A17666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "4:00:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/10/2024 16:15",
    "plant_id": "Green-tek-A17667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/10/2024 16:30",
    "plant_id": "Green-tek-A17668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/10/2024 16:45",
    "plant_id": "Green-tek-A17669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/10/2024 17:00",
    "plant_id": "Green-tek-A17670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/10/2024 17:15",
    "plant_id": "Green-tek-A17671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/10/2024 17:30",
    "plant_id": "Green-tek-A17672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/10/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/11/2024 6:00",
    "plant_id": "Green-tek-A17673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/11/2024 6:15",
    "plant_id": "Green-tek-A17674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/11/2024 6:30",
    "plant_id": "Green-tek-A17675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/11/2024 6:45",
    "plant_id": "Green-tek-A17676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/11/2024 7:00",
    "plant_id": "Green-tek-A17677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/11/2024 7:15",
    "plant_id": "Green-tek-A17678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/11/2024 7:30",
    "plant_id": "Green-tek-A17679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/11/2024 7:45",
    "plant_id": "Green-tek-A17680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "7:45:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/11/2024 8:00",
    "plant_id": "Green-tek-A17681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "8:00:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/11/2024 8:15",
    "plant_id": "Green-tek-A17682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "8:15:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "4/11/2024 8:30",
    "plant_id": "Green-tek-A17683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "8:30:00 AM",
    "power": 0.199,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "4/11/2024 8:45",
    "plant_id": "Green-tek-A17684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "8:45:00 AM",
    "power": 0.257,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "4/11/2024 9:00",
    "plant_id": "Green-tek-A17685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "9:00:00 AM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "4/11/2024 9:15",
    "plant_id": "Green-tek-A17686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "9:15:00 AM",
    "power": 0.402,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "4/11/2024 9:30",
    "plant_id": "Green-tek-A17687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "9:30:00 AM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "4/11/2024 9:45",
    "plant_id": "Green-tek-A17688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "9:45:00 AM",
    "power": 0.58,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "4/11/2024 10:00",
    "plant_id": "Green-tek-A17689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "10:00:00 AM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "4/11/2024 10:15",
    "plant_id": "Green-tek-A17690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "10:15:00 AM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "4/11/2024 10:30",
    "plant_id": "Green-tek-A17691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "10:30:00 AM",
    "power": 0.864,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "4/11/2024 10:45",
    "plant_id": "Green-tek-A17692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "10:45:00 AM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "4/11/2024 11:00",
    "plant_id": "Green-tek-A17693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "11:00:00 AM",
    "power": 1.017,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "4/11/2024 11:15",
    "plant_id": "Green-tek-A17694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "11:15:00 AM",
    "power": 1.071,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "4/11/2024 11:30",
    "plant_id": "Green-tek-A17695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "11:30:00 AM",
    "power": 1.104,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "4/11/2024 11:45",
    "plant_id": "Green-tek-A17696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "11:45:00 AM",
    "power": 1.115,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "4/11/2024 12:00",
    "plant_id": "Green-tek-A17697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "12:00:00 PM",
    "power": 1.104,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "4/11/2024 12:15",
    "plant_id": "Green-tek-A17698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "12:15:00 PM",
    "power": 1.071,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "4/11/2024 12:30",
    "plant_id": "Green-tek-A17699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "12:30:00 PM",
    "power": 1.017,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "4/11/2024 12:45",
    "plant_id": "Green-tek-A17700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "12:45:00 PM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "4/11/2024 13:00",
    "plant_id": "Green-tek-A17701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "1:00:00 PM",
    "power": 0.864,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "4/11/2024 13:15",
    "plant_id": "Green-tek-A17702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "1:15:00 PM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "4/11/2024 13:30",
    "plant_id": "Green-tek-A17703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "1:30:00 PM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "4/11/2024 13:45",
    "plant_id": "Green-tek-A17704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "1:45:00 PM",
    "power": 0.58,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "4/11/2024 14:00",
    "plant_id": "Green-tek-A17705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "2:00:00 PM",
    "power": 0.488,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "4/11/2024 14:15",
    "plant_id": "Green-tek-A17706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "2:15:00 PM",
    "power": 0.402,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "4/11/2024 14:30",
    "plant_id": "Green-tek-A17707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "2:30:00 PM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "4/11/2024 14:45",
    "plant_id": "Green-tek-A17708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "2:45:00 PM",
    "power": 0.257,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "4/11/2024 15:00",
    "plant_id": "Green-tek-A17709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "3:00:00 PM",
    "power": 0.199,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "4/11/2024 15:15",
    "plant_id": "Green-tek-A17710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "3:15:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "4/11/2024 15:30",
    "plant_id": "Green-tek-A17711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "3:30:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/11/2024 15:45",
    "plant_id": "Green-tek-A17712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "3:45:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/11/2024 16:00",
    "plant_id": "Green-tek-A17713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/11/2024 16:15",
    "plant_id": "Green-tek-A17714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/11/2024 16:30",
    "plant_id": "Green-tek-A17715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/11/2024 16:45",
    "plant_id": "Green-tek-A17716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/11/2024 17:00",
    "plant_id": "Green-tek-A17717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/11/2024 17:15",
    "plant_id": "Green-tek-A17718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/11/2024 17:30",
    "plant_id": "Green-tek-A17719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/11/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/12/2024 6:00",
    "plant_id": "Green-tek-A17720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/12/2024 6:15",
    "plant_id": "Green-tek-A17721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/12/2024 6:30",
    "plant_id": "Green-tek-A17722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/12/2024 6:45",
    "plant_id": "Green-tek-A17723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/12/2024 7:00",
    "plant_id": "Green-tek-A17724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/12/2024 7:15",
    "plant_id": "Green-tek-A17725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/12/2024 7:30",
    "plant_id": "Green-tek-A17726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/12/2024 7:45",
    "plant_id": "Green-tek-A17727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/12/2024 8:00",
    "plant_id": "Green-tek-A17728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "8:00:00 AM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "4/12/2024 8:15",
    "plant_id": "Green-tek-A17729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "8:15:00 AM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/12/2024 8:30",
    "plant_id": "Green-tek-A17730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "8:30:00 AM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/12/2024 8:45",
    "plant_id": "Green-tek-A17731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "8:45:00 AM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/12/2024 9:00",
    "plant_id": "Green-tek-A17732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "9:00:00 AM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "4/12/2024 9:15",
    "plant_id": "Green-tek-A17733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "9:15:00 AM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "4/12/2024 9:30",
    "plant_id": "Green-tek-A17734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "9:30:00 AM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "4/12/2024 9:45",
    "plant_id": "Green-tek-A17735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "9:45:00 AM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "4/12/2024 10:00",
    "plant_id": "Green-tek-A17736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "10:00:00 AM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "4/12/2024 10:15",
    "plant_id": "Green-tek-A17737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "10:15:00 AM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "4/12/2024 10:30",
    "plant_id": "Green-tek-A17738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "10:30:00 AM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "4/12/2024 10:45",
    "plant_id": "Green-tek-A17739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "10:45:00 AM",
    "power": 0.892,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "4/12/2024 11:00",
    "plant_id": "Green-tek-A17740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "11:00:00 AM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "4/12/2024 11:15",
    "plant_id": "Green-tek-A17741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "11:15:00 AM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "4/12/2024 11:30",
    "plant_id": "Green-tek-A17742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "11:30:00 AM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "4/12/2024 11:45",
    "plant_id": "Green-tek-A17743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "11:45:00 AM",
    "power": 1.05,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "4/12/2024 12:00",
    "plant_id": "Green-tek-A17744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "12:00:00 PM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "4/12/2024 12:15",
    "plant_id": "Green-tek-A17745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "12:15:00 PM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "4/12/2024 12:30",
    "plant_id": "Green-tek-A17746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "12:30:00 PM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "4/12/2024 12:45",
    "plant_id": "Green-tek-A17747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "12:45:00 PM",
    "power": 0.892,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "4/12/2024 13:00",
    "plant_id": "Green-tek-A17748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "1:00:00 PM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "4/12/2024 13:15",
    "plant_id": "Green-tek-A17749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "1:15:00 PM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "4/12/2024 13:30",
    "plant_id": "Green-tek-A17750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "1:30:00 PM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "4/12/2024 13:45",
    "plant_id": "Green-tek-A17751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "1:45:00 PM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "4/12/2024 14:00",
    "plant_id": "Green-tek-A17752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "2:00:00 PM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "4/12/2024 14:15",
    "plant_id": "Green-tek-A17753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "2:15:00 PM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "4/12/2024 14:30",
    "plant_id": "Green-tek-A17754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "2:30:00 PM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "4/12/2024 14:45",
    "plant_id": "Green-tek-A17755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "2:45:00 PM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/12/2024 15:00",
    "plant_id": "Green-tek-A17756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "3:00:00 PM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/12/2024 15:15",
    "plant_id": "Green-tek-A17757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "3:15:00 PM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/12/2024 15:30",
    "plant_id": "Green-tek-A17758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "3:30:00 PM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "4/12/2024 15:45",
    "plant_id": "Green-tek-A17759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/12/2024 16:00",
    "plant_id": "Green-tek-A17760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/12/2024 16:15",
    "plant_id": "Green-tek-A17761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/12/2024 16:30",
    "plant_id": "Green-tek-A17762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/12/2024 16:45",
    "plant_id": "Green-tek-A17763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/12/2024 17:00",
    "plant_id": "Green-tek-A17764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/12/2024 17:15",
    "plant_id": "Green-tek-A17765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/12/2024 17:30",
    "plant_id": "Green-tek-A17766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/12/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/13/2024 6:00",
    "plant_id": "Green-tek-A17767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/13/2024 6:15",
    "plant_id": "Green-tek-A17768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/13/2024 6:30",
    "plant_id": "Green-tek-A17769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/13/2024 6:45",
    "plant_id": "Green-tek-A17770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/13/2024 7:00",
    "plant_id": "Green-tek-A17771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/13/2024 7:15",
    "plant_id": "Green-tek-A17772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/13/2024 7:30",
    "plant_id": "Green-tek-A17773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "4/13/2024 7:45",
    "plant_id": "Green-tek-A17774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "4/13/2024 8:00",
    "plant_id": "Green-tek-A17775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "8:00:00 AM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "4/13/2024 8:15",
    "plant_id": "Green-tek-A17776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/13/2024 8:30",
    "plant_id": "Green-tek-A17777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "8:30:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "4/13/2024 8:45",
    "plant_id": "Green-tek-A17778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "8:45:00 AM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "4/13/2024 9:00",
    "plant_id": "Green-tek-A17779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "9:00:00 AM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "4/13/2024 9:15",
    "plant_id": "Green-tek-A17780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "9:15:00 AM",
    "power": 0.346,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "4/13/2024 9:30",
    "plant_id": "Green-tek-A17781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "9:30:00 AM",
    "power": 0.42,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "4/13/2024 9:45",
    "plant_id": "Green-tek-A17782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "9:45:00 AM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/13/2024 10:00",
    "plant_id": "Green-tek-A17783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "10:00:00 AM",
    "power": 0.582,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "4/13/2024 10:15",
    "plant_id": "Green-tek-A17784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "10:15:00 AM",
    "power": 0.664,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "4/13/2024 10:30",
    "plant_id": "Green-tek-A17785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "10:30:00 AM",
    "power": 0.743,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "4/13/2024 10:45",
    "plant_id": "Green-tek-A17786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "10:45:00 AM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "4/13/2024 11:00",
    "plant_id": "Green-tek-A17787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "11:00:00 AM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "4/13/2024 11:15",
    "plant_id": "Green-tek-A17788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "11:15:00 AM",
    "power": 0.921,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "4/13/2024 11:30",
    "plant_id": "Green-tek-A17789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "11:30:00 AM",
    "power": 0.949,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "4/13/2024 11:45",
    "plant_id": "Green-tek-A17790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "11:45:00 AM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "4/13/2024 12:00",
    "plant_id": "Green-tek-A17791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "12:00:00 PM",
    "power": 0.949,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "4/13/2024 12:15",
    "plant_id": "Green-tek-A17792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "12:15:00 PM",
    "power": 0.921,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "4/13/2024 12:30",
    "plant_id": "Green-tek-A17793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "12:30:00 PM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "4/13/2024 12:45",
    "plant_id": "Green-tek-A17794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "12:45:00 PM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "4/13/2024 13:00",
    "plant_id": "Green-tek-A17795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "1:00:00 PM",
    "power": 0.743,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "4/13/2024 13:15",
    "plant_id": "Green-tek-A17796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "1:15:00 PM",
    "power": 0.664,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "4/13/2024 13:30",
    "plant_id": "Green-tek-A17797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "1:30:00 PM",
    "power": 0.582,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "4/13/2024 13:45",
    "plant_id": "Green-tek-A17798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "1:45:00 PM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/13/2024 14:00",
    "plant_id": "Green-tek-A17799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "2:00:00 PM",
    "power": 0.42,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "4/13/2024 14:15",
    "plant_id": "Green-tek-A17800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "2:15:00 PM",
    "power": 0.346,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "4/13/2024 14:30",
    "plant_id": "Green-tek-A17801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "2:30:00 PM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "4/13/2024 14:45",
    "plant_id": "Green-tek-A17802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "2:45:00 PM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "4/13/2024 15:00",
    "plant_id": "Green-tek-A17803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "3:00:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "4/13/2024 15:15",
    "plant_id": "Green-tek-A17804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/13/2024 15:30",
    "plant_id": "Green-tek-A17805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "3:30:00 PM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "4/13/2024 15:45",
    "plant_id": "Green-tek-A17806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "4/13/2024 16:00",
    "plant_id": "Green-tek-A17807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "4/13/2024 16:15",
    "plant_id": "Green-tek-A17808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/13/2024 16:30",
    "plant_id": "Green-tek-A17809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/13/2024 16:45",
    "plant_id": "Green-tek-A17810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/13/2024 17:00",
    "plant_id": "Green-tek-A17811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/13/2024 17:15",
    "plant_id": "Green-tek-A17812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/13/2024 17:30",
    "plant_id": "Green-tek-A17813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/13/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/14/2024 6:00",
    "plant_id": "Green-tek-A17814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/14/2024 6:15",
    "plant_id": "Green-tek-A17815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/14/2024 6:30",
    "plant_id": "Green-tek-A17816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/14/2024 6:45",
    "plant_id": "Green-tek-A17817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/14/2024 7:00",
    "plant_id": "Green-tek-A17818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/14/2024 7:15",
    "plant_id": "Green-tek-A17819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/14/2024 7:30",
    "plant_id": "Green-tek-A17820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/14/2024 7:45",
    "plant_id": "Green-tek-A17821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "7:45:00 AM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/14/2024 8:00",
    "plant_id": "Green-tek-A17822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "8:00:00 AM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/14/2024 8:15",
    "plant_id": "Green-tek-A17823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "8:15:00 AM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/14/2024 8:30",
    "plant_id": "Green-tek-A17824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "8:30:00 AM",
    "power": 0.194,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "4/14/2024 8:45",
    "plant_id": "Green-tek-A17825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "8:45:00 AM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "4/14/2024 9:00",
    "plant_id": "Green-tek-A17826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "9:00:00 AM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "4/14/2024 9:15",
    "plant_id": "Green-tek-A17827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "9:15:00 AM",
    "power": 0.393,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "4/14/2024 9:30",
    "plant_id": "Green-tek-A17828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "9:30:00 AM",
    "power": 0.477,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "4/14/2024 9:45",
    "plant_id": "Green-tek-A17829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "9:45:00 AM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "4/14/2024 10:00",
    "plant_id": "Green-tek-A17830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "10:00:00 AM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "4/14/2024 10:15",
    "plant_id": "Green-tek-A17831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "10:15:00 AM",
    "power": 0.755,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "4/14/2024 10:30",
    "plant_id": "Green-tek-A17832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "10:30:00 AM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "4/14/2024 10:45",
    "plant_id": "Green-tek-A17833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "10:45:00 AM",
    "power": 0.926,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "4/14/2024 11:00",
    "plant_id": "Green-tek-A17834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "11:00:00 AM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "4/14/2024 11:15",
    "plant_id": "Green-tek-A17835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "11:15:00 AM",
    "power": 1.047,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "4/14/2024 11:30",
    "plant_id": "Green-tek-A17836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "11:30:00 AM",
    "power": 1.079,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "4/14/2024 11:45",
    "plant_id": "Green-tek-A17837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "11:45:00 AM",
    "power": 1.09,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "4/14/2024 12:00",
    "plant_id": "Green-tek-A17838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "12:00:00 PM",
    "power": 1.079,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "4/14/2024 12:15",
    "plant_id": "Green-tek-A17839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "12:15:00 PM",
    "power": 1.047,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "4/14/2024 12:30",
    "plant_id": "Green-tek-A17840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "12:30:00 PM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "4/14/2024 12:45",
    "plant_id": "Green-tek-A17841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "12:45:00 PM",
    "power": 0.926,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "4/14/2024 13:00",
    "plant_id": "Green-tek-A17842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "1:00:00 PM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "4/14/2024 13:15",
    "plant_id": "Green-tek-A17843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "1:15:00 PM",
    "power": 0.755,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "4/14/2024 13:30",
    "plant_id": "Green-tek-A17844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "1:30:00 PM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "4/14/2024 13:45",
    "plant_id": "Green-tek-A17845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "1:45:00 PM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "4/14/2024 14:00",
    "plant_id": "Green-tek-A17846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "2:00:00 PM",
    "power": 0.477,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "4/14/2024 14:15",
    "plant_id": "Green-tek-A17847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "2:15:00 PM",
    "power": 0.393,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "4/14/2024 14:30",
    "plant_id": "Green-tek-A17848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "2:30:00 PM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "4/14/2024 14:45",
    "plant_id": "Green-tek-A17849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "2:45:00 PM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "4/14/2024 15:00",
    "plant_id": "Green-tek-A17850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "3:00:00 PM",
    "power": 0.194,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "4/14/2024 15:15",
    "plant_id": "Green-tek-A17851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "3:15:00 PM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/14/2024 15:30",
    "plant_id": "Green-tek-A17852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "3:30:00 PM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/14/2024 15:45",
    "plant_id": "Green-tek-A17853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "3:45:00 PM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/14/2024 16:00",
    "plant_id": "Green-tek-A17854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/14/2024 16:15",
    "plant_id": "Green-tek-A17855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/14/2024 16:30",
    "plant_id": "Green-tek-A17856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/14/2024 16:45",
    "plant_id": "Green-tek-A17857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/14/2024 17:00",
    "plant_id": "Green-tek-A17858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/14/2024 17:15",
    "plant_id": "Green-tek-A17859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/14/2024 17:30",
    "plant_id": "Green-tek-A17860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/14/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/15/2024 6:00",
    "plant_id": "Green-tek-A17861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/15/2024 6:15",
    "plant_id": "Green-tek-A17862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/15/2024 6:30",
    "plant_id": "Green-tek-A17863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/15/2024 6:45",
    "plant_id": "Green-tek-A17864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/15/2024 7:00",
    "plant_id": "Green-tek-A17865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/15/2024 7:15",
    "plant_id": "Green-tek-A17866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/15/2024 7:30",
    "plant_id": "Green-tek-A17867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "7:30:00 AM",
    "power": 0.054,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "4/15/2024 7:45",
    "plant_id": "Green-tek-A17868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "7:45:00 AM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/15/2024 8:00",
    "plant_id": "Green-tek-A17869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "8:00:00 AM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "4/15/2024 8:15",
    "plant_id": "Green-tek-A17870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "8:15:00 AM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "4/15/2024 8:30",
    "plant_id": "Green-tek-A17871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "8:30:00 AM",
    "power": 0.182,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "4/15/2024 8:45",
    "plant_id": "Green-tek-A17872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "8:45:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "4/15/2024 9:00",
    "plant_id": "Green-tek-A17873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "9:00:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/15/2024 9:15",
    "plant_id": "Green-tek-A17874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "9:15:00 AM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "4/15/2024 9:30",
    "plant_id": "Green-tek-A17875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "9:30:00 AM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "4/15/2024 9:45",
    "plant_id": "Green-tek-A17876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "9:45:00 AM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "4/15/2024 10:00",
    "plant_id": "Green-tek-A17877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "10:00:00 AM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "4/15/2024 10:15",
    "plant_id": "Green-tek-A17878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "10:15:00 AM",
    "power": 0.708,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "4/15/2024 10:30",
    "plant_id": "Green-tek-A17879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "10:30:00 AM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "4/15/2024 10:45",
    "plant_id": "Green-tek-A17880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "10:45:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "4/15/2024 11:00",
    "plant_id": "Green-tek-A17881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "11:00:00 AM",
    "power": 0.932,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "4/15/2024 11:15",
    "plant_id": "Green-tek-A17882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "11:15:00 AM",
    "power": 0.981,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "4/15/2024 11:30",
    "plant_id": "Green-tek-A17883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "11:30:00 AM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "4/15/2024 11:45",
    "plant_id": "Green-tek-A17884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "11:45:00 AM",
    "power": 1.022,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "4/15/2024 12:00",
    "plant_id": "Green-tek-A17885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "12:00:00 PM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "4/15/2024 12:15",
    "plant_id": "Green-tek-A17886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "12:15:00 PM",
    "power": 0.981,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "4/15/2024 12:30",
    "plant_id": "Green-tek-A17887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "12:30:00 PM",
    "power": 0.932,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "4/15/2024 12:45",
    "plant_id": "Green-tek-A17888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "12:45:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "4/15/2024 13:00",
    "plant_id": "Green-tek-A17889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "1:00:00 PM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "4/15/2024 13:15",
    "plant_id": "Green-tek-A17890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "1:15:00 PM",
    "power": 0.708,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "4/15/2024 13:30",
    "plant_id": "Green-tek-A17891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "1:30:00 PM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "4/15/2024 13:45",
    "plant_id": "Green-tek-A17892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "1:45:00 PM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "4/15/2024 14:00",
    "plant_id": "Green-tek-A17893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "2:00:00 PM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "4/15/2024 14:15",
    "plant_id": "Green-tek-A17894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "2:15:00 PM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "4/15/2024 14:30",
    "plant_id": "Green-tek-A17895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "2:30:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/15/2024 14:45",
    "plant_id": "Green-tek-A17896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "2:45:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "4/15/2024 15:00",
    "plant_id": "Green-tek-A17897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "3:00:00 PM",
    "power": 0.182,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "4/15/2024 15:15",
    "plant_id": "Green-tek-A17898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "3:15:00 PM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "4/15/2024 15:30",
    "plant_id": "Green-tek-A17899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "3:30:00 PM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "4/15/2024 15:45",
    "plant_id": "Green-tek-A17900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "3:45:00 PM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/15/2024 16:00",
    "plant_id": "Green-tek-A17901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "4:00:00 PM",
    "power": 0.054,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "4/15/2024 16:15",
    "plant_id": "Green-tek-A17902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/15/2024 16:30",
    "plant_id": "Green-tek-A17903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/15/2024 16:45",
    "plant_id": "Green-tek-A17904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/15/2024 17:00",
    "plant_id": "Green-tek-A17905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/15/2024 17:15",
    "plant_id": "Green-tek-A17906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/15/2024 17:30",
    "plant_id": "Green-tek-A17907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/15/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/16/2024 6:00",
    "plant_id": "Green-tek-A17908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/16/2024 6:15",
    "plant_id": "Green-tek-A17909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/16/2024 6:30",
    "plant_id": "Green-tek-A17910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/16/2024 6:45",
    "plant_id": "Green-tek-A17911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/16/2024 7:00",
    "plant_id": "Green-tek-A17912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/16/2024 7:15",
    "plant_id": "Green-tek-A17913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/16/2024 7:30",
    "plant_id": "Green-tek-A17914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/16/2024 7:45",
    "plant_id": "Green-tek-A17915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "4/16/2024 8:00",
    "plant_id": "Green-tek-A17916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "8:00:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/16/2024 8:15",
    "plant_id": "Green-tek-A17917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "8:15:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "4/16/2024 8:30",
    "plant_id": "Green-tek-A17918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "8:30:00 AM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "4/16/2024 8:45",
    "plant_id": "Green-tek-A17919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "8:45:00 AM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "4/16/2024 9:00",
    "plant_id": "Green-tek-A17920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "9:00:00 AM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "4/16/2024 9:15",
    "plant_id": "Green-tek-A17921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "9:15:00 AM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "4/16/2024 9:30",
    "plant_id": "Green-tek-A17922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "9:30:00 AM",
    "power": 0.553,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "4/16/2024 9:45",
    "plant_id": "Green-tek-A17923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "9:45:00 AM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "4/16/2024 10:00",
    "plant_id": "Green-tek-A17924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "10:00:00 AM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "4/16/2024 10:15",
    "plant_id": "Green-tek-A17925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "10:15:00 AM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "4/16/2024 10:30",
    "plant_id": "Green-tek-A17926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "10:30:00 AM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "4/16/2024 10:45",
    "plant_id": "Green-tek-A17927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "10:45:00 AM",
    "power": 1.074,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "4/16/2024 11:00",
    "plant_id": "Green-tek-A17928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "11:00:00 AM",
    "power": 1.154,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "4/16/2024 11:15",
    "plant_id": "Green-tek-A17929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "11:15:00 AM",
    "power": 1.214,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "4/16/2024 11:30",
    "plant_id": "Green-tek-A17930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "11:30:00 AM",
    "power": 1.252,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "4/16/2024 11:45",
    "plant_id": "Green-tek-A17931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "11:45:00 AM",
    "power": 1.265,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "4/16/2024 12:00",
    "plant_id": "Green-tek-A17932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "12:00:00 PM",
    "power": 1.252,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "4/16/2024 12:15",
    "plant_id": "Green-tek-A17933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "12:15:00 PM",
    "power": 1.214,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "4/16/2024 12:30",
    "plant_id": "Green-tek-A17934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "12:30:00 PM",
    "power": 1.154,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "4/16/2024 12:45",
    "plant_id": "Green-tek-A17935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "12:45:00 PM",
    "power": 1.074,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "4/16/2024 13:00",
    "plant_id": "Green-tek-A17936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "1:00:00 PM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "4/16/2024 13:15",
    "plant_id": "Green-tek-A17937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "1:15:00 PM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "4/16/2024 13:30",
    "plant_id": "Green-tek-A17938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "1:30:00 PM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "4/16/2024 13:45",
    "plant_id": "Green-tek-A17939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "1:45:00 PM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "4/16/2024 14:00",
    "plant_id": "Green-tek-A17940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "2:00:00 PM",
    "power": 0.553,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "4/16/2024 14:15",
    "plant_id": "Green-tek-A17941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "2:15:00 PM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "4/16/2024 14:30",
    "plant_id": "Green-tek-A17942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "2:30:00 PM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "4/16/2024 14:45",
    "plant_id": "Green-tek-A17943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "2:45:00 PM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "4/16/2024 15:00",
    "plant_id": "Green-tek-A17944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "3:00:00 PM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "4/16/2024 15:15",
    "plant_id": "Green-tek-A17945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "3:15:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "4/16/2024 15:30",
    "plant_id": "Green-tek-A17946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "3:30:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/16/2024 15:45",
    "plant_id": "Green-tek-A17947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "4/16/2024 16:00",
    "plant_id": "Green-tek-A17948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/16/2024 16:15",
    "plant_id": "Green-tek-A17949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/16/2024 16:30",
    "plant_id": "Green-tek-A17950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/16/2024 16:45",
    "plant_id": "Green-tek-A17951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/16/2024 17:00",
    "plant_id": "Green-tek-A17952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/16/2024 17:15",
    "plant_id": "Green-tek-A17953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/16/2024 17:30",
    "plant_id": "Green-tek-A17954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/16/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/17/2024 6:00",
    "plant_id": "Green-tek-A17955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/17/2024 6:15",
    "plant_id": "Green-tek-A17956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/17/2024 6:30",
    "plant_id": "Green-tek-A17957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/17/2024 6:45",
    "plant_id": "Green-tek-A17958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/17/2024 7:00",
    "plant_id": "Green-tek-A17959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/17/2024 7:15",
    "plant_id": "Green-tek-A17960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/17/2024 7:30",
    "plant_id": "Green-tek-A17961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/17/2024 7:45",
    "plant_id": "Green-tek-A17962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "7:45:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "4/17/2024 8:00",
    "plant_id": "Green-tek-A17963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "8:00:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "4/17/2024 8:15",
    "plant_id": "Green-tek-A17964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "8:15:00 AM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "4/17/2024 8:30",
    "plant_id": "Green-tek-A17965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "8:30:00 AM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "4/17/2024 8:45",
    "plant_id": "Green-tek-A17966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "8:45:00 AM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "4/17/2024 9:00",
    "plant_id": "Green-tek-A17967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "9:00:00 AM",
    "power": 0.258,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "4/17/2024 9:15",
    "plant_id": "Green-tek-A17968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "9:15:00 AM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "4/17/2024 9:30",
    "plant_id": "Green-tek-A17969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "9:30:00 AM",
    "power": 0.389,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "4/17/2024 9:45",
    "plant_id": "Green-tek-A17970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "9:45:00 AM",
    "power": 0.462,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "4/17/2024 10:00",
    "plant_id": "Green-tek-A17971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "10:00:00 AM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "4/17/2024 10:15",
    "plant_id": "Green-tek-A17972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "10:15:00 AM",
    "power": 0.615,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "4/17/2024 10:30",
    "plant_id": "Green-tek-A17973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "10:30:00 AM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "4/17/2024 10:45",
    "plant_id": "Green-tek-A17974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "10:45:00 AM",
    "power": 0.754,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "4/17/2024 11:00",
    "plant_id": "Green-tek-A17975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "11:00:00 AM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "4/17/2024 11:15",
    "plant_id": "Green-tek-A17976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "11:15:00 AM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "4/17/2024 11:30",
    "plant_id": "Green-tek-A17977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "11:30:00 AM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "4/17/2024 11:45",
    "plant_id": "Green-tek-A17978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "11:45:00 AM",
    "power": 0.888,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "4/17/2024 12:00",
    "plant_id": "Green-tek-A17979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "12:00:00 PM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "4/17/2024 12:15",
    "plant_id": "Green-tek-A17980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "12:15:00 PM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "4/17/2024 12:30",
    "plant_id": "Green-tek-A17981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "12:30:00 PM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "4/17/2024 12:45",
    "plant_id": "Green-tek-A17982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "12:45:00 PM",
    "power": 0.754,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "4/17/2024 13:00",
    "plant_id": "Green-tek-A17983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "1:00:00 PM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "4/17/2024 13:15",
    "plant_id": "Green-tek-A17984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "1:15:00 PM",
    "power": 0.615,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "4/17/2024 13:30",
    "plant_id": "Green-tek-A17985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "1:30:00 PM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "4/17/2024 13:45",
    "plant_id": "Green-tek-A17986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "1:45:00 PM",
    "power": 0.462,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "4/17/2024 14:00",
    "plant_id": "Green-tek-A17987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "2:00:00 PM",
    "power": 0.389,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "4/17/2024 14:15",
    "plant_id": "Green-tek-A17988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "2:15:00 PM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "4/17/2024 14:30",
    "plant_id": "Green-tek-A17989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "2:30:00 PM",
    "power": 0.258,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "4/17/2024 14:45",
    "plant_id": "Green-tek-A17990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "2:45:00 PM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "4/17/2024 15:00",
    "plant_id": "Green-tek-A17991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "3:00:00 PM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "4/17/2024 15:15",
    "plant_id": "Green-tek-A17992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "3:15:00 PM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "4/17/2024 15:30",
    "plant_id": "Green-tek-A17993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "3:30:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "4/17/2024 15:45",
    "plant_id": "Green-tek-A17994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "3:45:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "4/17/2024 16:00",
    "plant_id": "Green-tek-A17995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/17/2024 16:15",
    "plant_id": "Green-tek-A17996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/17/2024 16:30",
    "plant_id": "Green-tek-A17997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/17/2024 16:45",
    "plant_id": "Green-tek-A17998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/17/2024 17:00",
    "plant_id": "Green-tek-A17999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/17/2024 17:15",
    "plant_id": "Green-tek-A18000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/17/2024 17:30",
    "plant_id": "Green-tek-A18001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/17/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/18/2024 6:00",
    "plant_id": "Green-tek-A18002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/18/2024 6:15",
    "plant_id": "Green-tek-A18003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/18/2024 6:30",
    "plant_id": "Green-tek-A18004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/18/2024 6:45",
    "plant_id": "Green-tek-A18005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/18/2024 7:00",
    "plant_id": "Green-tek-A18006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/18/2024 7:15",
    "plant_id": "Green-tek-A18007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/18/2024 7:30",
    "plant_id": "Green-tek-A18008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/18/2024 7:45",
    "plant_id": "Green-tek-A18009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "7:45:00 AM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/18/2024 8:00",
    "plant_id": "Green-tek-A18010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "8:00:00 AM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/18/2024 8:15",
    "plant_id": "Green-tek-A18011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "8:15:00 AM",
    "power": 0.146,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/18/2024 8:30",
    "plant_id": "Green-tek-A18012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "8:30:00 AM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "4/18/2024 8:45",
    "plant_id": "Green-tek-A18013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "8:45:00 AM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "4/18/2024 9:00",
    "plant_id": "Green-tek-A18014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "9:00:00 AM",
    "power": 0.314,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "4/18/2024 9:15",
    "plant_id": "Green-tek-A18015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "9:15:00 AM",
    "power": 0.389,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "4/18/2024 9:30",
    "plant_id": "Green-tek-A18016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "9:30:00 AM",
    "power": 0.472,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "4/18/2024 9:45",
    "plant_id": "Green-tek-A18017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "9:45:00 AM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "4/18/2024 10:00",
    "plant_id": "Green-tek-A18018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "10:00:00 AM",
    "power": 0.654,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "4/18/2024 10:15",
    "plant_id": "Green-tek-A18019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "10:15:00 AM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "4/18/2024 10:30",
    "plant_id": "Green-tek-A18020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "10:30:00 AM",
    "power": 0.835,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "4/18/2024 10:45",
    "plant_id": "Green-tek-A18021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "10:45:00 AM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "4/18/2024 11:00",
    "plant_id": "Green-tek-A18022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "11:00:00 AM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "4/18/2024 11:15",
    "plant_id": "Green-tek-A18023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "11:15:00 AM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "4/18/2024 11:30",
    "plant_id": "Green-tek-A18024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "11:30:00 AM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "4/18/2024 11:45",
    "plant_id": "Green-tek-A18025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "11:45:00 AM",
    "power": 1.078,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "4/18/2024 12:00",
    "plant_id": "Green-tek-A18026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "12:00:00 PM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "4/18/2024 12:15",
    "plant_id": "Green-tek-A18027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "12:15:00 PM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "4/18/2024 12:30",
    "plant_id": "Green-tek-A18028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "12:30:00 PM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "4/18/2024 12:45",
    "plant_id": "Green-tek-A18029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "12:45:00 PM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "4/18/2024 13:00",
    "plant_id": "Green-tek-A18030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "1:00:00 PM",
    "power": 0.835,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "4/18/2024 13:15",
    "plant_id": "Green-tek-A18031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "1:15:00 PM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "4/18/2024 13:30",
    "plant_id": "Green-tek-A18032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "1:30:00 PM",
    "power": 0.654,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "4/18/2024 13:45",
    "plant_id": "Green-tek-A18033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "1:45:00 PM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "4/18/2024 14:00",
    "plant_id": "Green-tek-A18034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "2:00:00 PM",
    "power": 0.472,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "4/18/2024 14:15",
    "plant_id": "Green-tek-A18035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "2:15:00 PM",
    "power": 0.389,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "4/18/2024 14:30",
    "plant_id": "Green-tek-A18036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "2:30:00 PM",
    "power": 0.314,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "4/18/2024 14:45",
    "plant_id": "Green-tek-A18037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "2:45:00 PM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "4/18/2024 15:00",
    "plant_id": "Green-tek-A18038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "3:00:00 PM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "4/18/2024 15:15",
    "plant_id": "Green-tek-A18039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "3:15:00 PM",
    "power": 0.146,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/18/2024 15:30",
    "plant_id": "Green-tek-A18040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "3:30:00 PM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/18/2024 15:45",
    "plant_id": "Green-tek-A18041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "3:45:00 PM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/18/2024 16:00",
    "plant_id": "Green-tek-A18042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/18/2024 16:15",
    "plant_id": "Green-tek-A18043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/18/2024 16:30",
    "plant_id": "Green-tek-A18044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/18/2024 16:45",
    "plant_id": "Green-tek-A18045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/18/2024 17:00",
    "plant_id": "Green-tek-A18046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/18/2024 17:15",
    "plant_id": "Green-tek-A18047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/18/2024 17:30",
    "plant_id": "Green-tek-A18048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/18/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/19/2024 6:00",
    "plant_id": "Green-tek-A18049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/19/2024 6:15",
    "plant_id": "Green-tek-A18050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/19/2024 6:30",
    "plant_id": "Green-tek-A18051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/19/2024 6:45",
    "plant_id": "Green-tek-A18052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/19/2024 7:00",
    "plant_id": "Green-tek-A18053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/19/2024 7:15",
    "plant_id": "Green-tek-A18054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/19/2024 7:30",
    "plant_id": "Green-tek-A18055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/19/2024 7:45",
    "plant_id": "Green-tek-A18056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "4/19/2024 8:00",
    "plant_id": "Green-tek-A18057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "8:00:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/19/2024 8:15",
    "plant_id": "Green-tek-A18058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "8:15:00 AM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "4/19/2024 8:30",
    "plant_id": "Green-tek-A18059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "8:30:00 AM",
    "power": 0.23,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "4/19/2024 8:45",
    "plant_id": "Green-tek-A18060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "8:45:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/19/2024 9:00",
    "plant_id": "Green-tek-A18061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "9:00:00 AM",
    "power": 0.375,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "4/19/2024 9:15",
    "plant_id": "Green-tek-A18062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "9:15:00 AM",
    "power": 0.465,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "4/19/2024 9:30",
    "plant_id": "Green-tek-A18063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "9:30:00 AM",
    "power": 0.564,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "4/19/2024 9:45",
    "plant_id": "Green-tek-A18064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "9:45:00 AM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "4/19/2024 10:00",
    "plant_id": "Green-tek-A18065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "10:00:00 AM",
    "power": 0.782,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "4/19/2024 10:15",
    "plant_id": "Green-tek-A18066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "10:15:00 AM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "4/19/2024 10:30",
    "plant_id": "Green-tek-A18067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "10:30:00 AM",
    "power": 0.999,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "4/19/2024 10:45",
    "plant_id": "Green-tek-A18068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "10:45:00 AM",
    "power": 1.095,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "4/19/2024 11:00",
    "plant_id": "Green-tek-A18069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "11:00:00 AM",
    "power": 1.176,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "4/19/2024 11:15",
    "plant_id": "Green-tek-A18070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "11:15:00 AM",
    "power": 1.238,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.32538,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "4/19/2024 11:30",
    "plant_id": "Green-tek-A18071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "11:30:00 AM",
    "power": 1.277,
    "Energy": 0.319,
    "output Energy": 0.319,
    "Ac output": 1.319,
    "dc output": 1.34538,
    "AC_R_i": 0.00041609,
    "AC_S_i": 0.000457699,
    "AC_T_i": 0.00050347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0957007,
    "AC_S_pow": 0.10527077,
    "AC_T_Pow": 0.1157981,
    "AC_R_output": 0.3957,
    "AC_S_output": 0.43527,
    "AC_T_output": 0.478797,
    "dc_current": 0.00234559,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.32538,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000319,
    "carbon offset": 0.39875,
    "trees planted": 8.861111111,
    "DC_R_output": 0.130581,
    "DC_S_output": 0.1436391,
    "DC_T_output": 0.15800301
  },
  {
    "datetime": "4/19/2024 11:45",
    "plant_id": "Green-tek-A18072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "11:45:00 AM",
    "power": 1.29,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.32538,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "4/19/2024 12:00",
    "plant_id": "Green-tek-A18073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "12:00:00 PM",
    "power": 1.277,
    "Energy": 0.319,
    "output Energy": 0.319,
    "Ac output": 1.319,
    "dc output": 1.34538,
    "AC_R_i": 0.00041609,
    "AC_S_i": 0.000457699,
    "AC_T_i": 0.00050347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0957007,
    "AC_S_pow": 0.10527077,
    "AC_T_Pow": 0.1157981,
    "AC_R_output": 0.3957,
    "AC_S_output": 0.43527,
    "AC_T_output": 0.478797,
    "dc_current": 0.00234559,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.32538,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000319,
    "carbon offset": 0.39875,
    "trees planted": 8.861111111,
    "DC_R_output": 0.130581,
    "DC_S_output": 0.1436391,
    "DC_T_output": 0.15800301
  },
  {
    "datetime": "4/19/2024 12:15",
    "plant_id": "Green-tek-A18074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "12:15:00 PM",
    "power": 1.238,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "4/19/2024 12:30",
    "plant_id": "Green-tek-A18075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "12:30:00 PM",
    "power": 1.176,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "4/19/2024 12:45",
    "plant_id": "Green-tek-A18076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "12:45:00 PM",
    "power": 1.095,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "4/19/2024 13:00",
    "plant_id": "Green-tek-A18077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "1:00:00 PM",
    "power": 0.999,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "4/19/2024 13:15",
    "plant_id": "Green-tek-A18078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "1:15:00 PM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "4/19/2024 13:30",
    "plant_id": "Green-tek-A18079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "1:30:00 PM",
    "power": 0.782,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "4/19/2024 13:45",
    "plant_id": "Green-tek-A18080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "1:45:00 PM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "4/19/2024 14:00",
    "plant_id": "Green-tek-A18081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "2:00:00 PM",
    "power": 0.564,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "4/19/2024 14:15",
    "plant_id": "Green-tek-A18082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "2:15:00 PM",
    "power": 0.465,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "4/19/2024 14:30",
    "plant_id": "Green-tek-A18083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "2:30:00 PM",
    "power": 0.375,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "4/19/2024 14:45",
    "plant_id": "Green-tek-A18084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "2:45:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/19/2024 15:00",
    "plant_id": "Green-tek-A18085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "3:00:00 PM",
    "power": 0.23,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "4/19/2024 15:15",
    "plant_id": "Green-tek-A18086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "3:15:00 PM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "4/19/2024 15:30",
    "plant_id": "Green-tek-A18087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "3:30:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/19/2024 15:45",
    "plant_id": "Green-tek-A18088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "4/19/2024 16:00",
    "plant_id": "Green-tek-A18089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/19/2024 16:15",
    "plant_id": "Green-tek-A18090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/19/2024 16:30",
    "plant_id": "Green-tek-A18091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/19/2024 16:45",
    "plant_id": "Green-tek-A18092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/19/2024 17:00",
    "plant_id": "Green-tek-A18093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/19/2024 17:15",
    "plant_id": "Green-tek-A18094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/19/2024 17:30",
    "plant_id": "Green-tek-A18095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/19/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/20/2024 6:00",
    "plant_id": "Green-tek-A18096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/20/2024 6:15",
    "plant_id": "Green-tek-A18097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/20/2024 6:30",
    "plant_id": "Green-tek-A18098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/20/2024 6:45",
    "plant_id": "Green-tek-A18099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/20/2024 7:00",
    "plant_id": "Green-tek-A18100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/20/2024 7:15",
    "plant_id": "Green-tek-A18101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/20/2024 7:30",
    "plant_id": "Green-tek-A18102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/20/2024 7:45",
    "plant_id": "Green-tek-A18103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/20/2024 8:00",
    "plant_id": "Green-tek-A18104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "8:00:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/20/2024 8:15",
    "plant_id": "Green-tek-A18105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "8:15:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/20/2024 8:30",
    "plant_id": "Green-tek-A18106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "8:30:00 AM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "4/20/2024 8:45",
    "plant_id": "Green-tek-A18107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "8:45:00 AM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "4/20/2024 9:00",
    "plant_id": "Green-tek-A18108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "9:00:00 AM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "4/20/2024 9:15",
    "plant_id": "Green-tek-A18109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "9:15:00 AM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "4/20/2024 9:30",
    "plant_id": "Green-tek-A18110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "9:30:00 AM",
    "power": 0.482,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "4/20/2024 9:45",
    "plant_id": "Green-tek-A18111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "9:45:00 AM",
    "power": 0.573,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "4/20/2024 10:00",
    "plant_id": "Green-tek-A18112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "10:00:00 AM",
    "power": 0.668,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "4/20/2024 10:15",
    "plant_id": "Green-tek-A18113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "10:15:00 AM",
    "power": 0.762,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "4/20/2024 10:30",
    "plant_id": "Green-tek-A18114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "10:30:00 AM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "4/20/2024 10:45",
    "plant_id": "Green-tek-A18115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "10:45:00 AM",
    "power": 0.935,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "4/20/2024 11:00",
    "plant_id": "Green-tek-A18116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "11:00:00 AM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "4/20/2024 11:15",
    "plant_id": "Green-tek-A18117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "11:15:00 AM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "4/20/2024 11:30",
    "plant_id": "Green-tek-A18118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "11:30:00 AM",
    "power": 1.09,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "4/20/2024 11:45",
    "plant_id": "Green-tek-A18119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "11:45:00 AM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "4/20/2024 12:00",
    "plant_id": "Green-tek-A18120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "12:00:00 PM",
    "power": 1.09,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "4/20/2024 12:15",
    "plant_id": "Green-tek-A18121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "12:15:00 PM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "4/20/2024 12:30",
    "plant_id": "Green-tek-A18122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "12:30:00 PM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "4/20/2024 12:45",
    "plant_id": "Green-tek-A18123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "12:45:00 PM",
    "power": 0.935,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "4/20/2024 13:00",
    "plant_id": "Green-tek-A18124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "1:00:00 PM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "4/20/2024 13:15",
    "plant_id": "Green-tek-A18125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "1:15:00 PM",
    "power": 0.762,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "4/20/2024 13:30",
    "plant_id": "Green-tek-A18126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "1:30:00 PM",
    "power": 0.668,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "4/20/2024 13:45",
    "plant_id": "Green-tek-A18127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "1:45:00 PM",
    "power": 0.573,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "4/20/2024 14:00",
    "plant_id": "Green-tek-A18128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "2:00:00 PM",
    "power": 0.482,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "4/20/2024 14:15",
    "plant_id": "Green-tek-A18129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "2:15:00 PM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "4/20/2024 14:30",
    "plant_id": "Green-tek-A18130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "2:30:00 PM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "4/20/2024 14:45",
    "plant_id": "Green-tek-A18131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "2:45:00 PM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "4/20/2024 15:00",
    "plant_id": "Green-tek-A18132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "3:00:00 PM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "4/20/2024 15:15",
    "plant_id": "Green-tek-A18133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "3:15:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/20/2024 15:30",
    "plant_id": "Green-tek-A18134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "3:30:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/20/2024 15:45",
    "plant_id": "Green-tek-A18135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/20/2024 16:00",
    "plant_id": "Green-tek-A18136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/20/2024 16:15",
    "plant_id": "Green-tek-A18137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/20/2024 16:30",
    "plant_id": "Green-tek-A18138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/20/2024 16:45",
    "plant_id": "Green-tek-A18139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/20/2024 17:00",
    "plant_id": "Green-tek-A18140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/20/2024 17:15",
    "plant_id": "Green-tek-A18141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/20/2024 17:30",
    "plant_id": "Green-tek-A18142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/20/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/21/2024 6:00",
    "plant_id": "Green-tek-A18143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/21/2024 6:15",
    "plant_id": "Green-tek-A18144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/21/2024 6:30",
    "plant_id": "Green-tek-A18145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/21/2024 6:45",
    "plant_id": "Green-tek-A18146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/21/2024 7:00",
    "plant_id": "Green-tek-A18147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/21/2024 7:15",
    "plant_id": "Green-tek-A18148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/21/2024 7:30",
    "plant_id": "Green-tek-A18149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/21/2024 7:45",
    "plant_id": "Green-tek-A18150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/21/2024 8:00",
    "plant_id": "Green-tek-A18151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "8:00:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/21/2024 8:15",
    "plant_id": "Green-tek-A18152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "8:15:00 AM",
    "power": 0.15,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/21/2024 8:30",
    "plant_id": "Green-tek-A18153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "8:30:00 AM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "4/21/2024 8:45",
    "plant_id": "Green-tek-A18154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "8:45:00 AM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "4/21/2024 9:00",
    "plant_id": "Green-tek-A18155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "9:00:00 AM",
    "power": 0.322,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "4/21/2024 9:15",
    "plant_id": "Green-tek-A18156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "9:15:00 AM",
    "power": 0.399,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "4/21/2024 9:30",
    "plant_id": "Green-tek-A18157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "9:30:00 AM",
    "power": 0.484,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "4/21/2024 9:45",
    "plant_id": "Green-tek-A18158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "9:45:00 AM",
    "power": 0.576,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "4/21/2024 10:00",
    "plant_id": "Green-tek-A18159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "10:00:00 AM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "4/21/2024 10:15",
    "plant_id": "Green-tek-A18160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "10:15:00 AM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "4/21/2024 10:30",
    "plant_id": "Green-tek-A18161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "10:30:00 AM",
    "power": 0.858,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "4/21/2024 10:45",
    "plant_id": "Green-tek-A18162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "10:45:00 AM",
    "power": 0.94,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "4/21/2024 11:00",
    "plant_id": "Green-tek-A18163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "11:00:00 AM",
    "power": 1.01,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "4/21/2024 11:15",
    "plant_id": "Green-tek-A18164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "11:15:00 AM",
    "power": 1.063,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "4/21/2024 11:30",
    "plant_id": "Green-tek-A18165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "11:30:00 AM",
    "power": 1.096,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "4/21/2024 11:45",
    "plant_id": "Green-tek-A18166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "11:45:00 AM",
    "power": 1.107,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "4/21/2024 12:00",
    "plant_id": "Green-tek-A18167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "12:00:00 PM",
    "power": 1.096,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "4/21/2024 12:15",
    "plant_id": "Green-tek-A18168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "12:15:00 PM",
    "power": 1.063,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "4/21/2024 12:30",
    "plant_id": "Green-tek-A18169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "12:30:00 PM",
    "power": 1.01,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "4/21/2024 12:45",
    "plant_id": "Green-tek-A18170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "12:45:00 PM",
    "power": 0.94,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "4/21/2024 13:00",
    "plant_id": "Green-tek-A18171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "1:00:00 PM",
    "power": 0.858,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "4/21/2024 13:15",
    "plant_id": "Green-tek-A18172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "1:15:00 PM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "4/21/2024 13:30",
    "plant_id": "Green-tek-A18173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "1:30:00 PM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "4/21/2024 13:45",
    "plant_id": "Green-tek-A18174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "1:45:00 PM",
    "power": 0.576,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "4/21/2024 14:00",
    "plant_id": "Green-tek-A18175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "2:00:00 PM",
    "power": 0.484,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "4/21/2024 14:15",
    "plant_id": "Green-tek-A18176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "2:15:00 PM",
    "power": 0.399,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "4/21/2024 14:30",
    "plant_id": "Green-tek-A18177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "2:30:00 PM",
    "power": 0.322,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "4/21/2024 14:45",
    "plant_id": "Green-tek-A18178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "2:45:00 PM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "4/21/2024 15:00",
    "plant_id": "Green-tek-A18179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "3:00:00 PM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "4/21/2024 15:15",
    "plant_id": "Green-tek-A18180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "3:15:00 PM",
    "power": 0.15,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/21/2024 15:30",
    "plant_id": "Green-tek-A18181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "3:30:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/21/2024 15:45",
    "plant_id": "Green-tek-A18182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/21/2024 16:00",
    "plant_id": "Green-tek-A18183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/21/2024 16:15",
    "plant_id": "Green-tek-A18184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/21/2024 16:30",
    "plant_id": "Green-tek-A18185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/21/2024 16:45",
    "plant_id": "Green-tek-A18186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/21/2024 17:00",
    "plant_id": "Green-tek-A18187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/21/2024 17:15",
    "plant_id": "Green-tek-A18188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/21/2024 17:30",
    "plant_id": "Green-tek-A18189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/21/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/22/2024 6:00",
    "plant_id": "Green-tek-A18190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/22/2024 6:15",
    "plant_id": "Green-tek-A18191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/22/2024 6:30",
    "plant_id": "Green-tek-A18192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/22/2024 6:45",
    "plant_id": "Green-tek-A18193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/22/2024 7:00",
    "plant_id": "Green-tek-A18194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/22/2024 7:15",
    "plant_id": "Green-tek-A18195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/22/2024 7:30",
    "plant_id": "Green-tek-A18196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "4/22/2024 7:45",
    "plant_id": "Green-tek-A18197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "7:45:00 AM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "4/22/2024 8:00",
    "plant_id": "Green-tek-A18198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "8:00:00 AM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "4/22/2024 8:15",
    "plant_id": "Green-tek-A18199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "8:15:00 AM",
    "power": 0.134,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "4/22/2024 8:30",
    "plant_id": "Green-tek-A18200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "8:30:00 AM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "4/22/2024 8:45",
    "plant_id": "Green-tek-A18201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "8:45:00 AM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "4/22/2024 9:00",
    "plant_id": "Green-tek-A18202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "9:00:00 AM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "4/22/2024 9:15",
    "plant_id": "Green-tek-A18203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "9:15:00 AM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "4/22/2024 9:30",
    "plant_id": "Green-tek-A18204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "9:30:00 AM",
    "power": 0.434,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "4/22/2024 9:45",
    "plant_id": "Green-tek-A18205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "9:45:00 AM",
    "power": 0.516,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "4/22/2024 10:00",
    "plant_id": "Green-tek-A18206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "10:00:00 AM",
    "power": 0.601,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "4/22/2024 10:15",
    "plant_id": "Green-tek-A18207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "10:15:00 AM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "4/22/2024 10:30",
    "plant_id": "Green-tek-A18208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "10:30:00 AM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "4/22/2024 10:45",
    "plant_id": "Green-tek-A18209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "10:45:00 AM",
    "power": 0.842,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "4/22/2024 11:00",
    "plant_id": "Green-tek-A18210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "11:00:00 AM",
    "power": 0.905,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "4/22/2024 11:15",
    "plant_id": "Green-tek-A18211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "11:15:00 AM",
    "power": 0.952,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "4/22/2024 11:30",
    "plant_id": "Green-tek-A18212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "11:30:00 AM",
    "power": 0.981,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "4/22/2024 11:45",
    "plant_id": "Green-tek-A18213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "11:45:00 AM",
    "power": 0.992,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "4/22/2024 12:00",
    "plant_id": "Green-tek-A18214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "12:00:00 PM",
    "power": 0.981,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "4/22/2024 12:15",
    "plant_id": "Green-tek-A18215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "12:15:00 PM",
    "power": 0.952,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "4/22/2024 12:30",
    "plant_id": "Green-tek-A18216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "12:30:00 PM",
    "power": 0.905,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "4/22/2024 12:45",
    "plant_id": "Green-tek-A18217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "12:45:00 PM",
    "power": 0.842,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "4/22/2024 13:00",
    "plant_id": "Green-tek-A18218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "1:00:00 PM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "4/22/2024 13:15",
    "plant_id": "Green-tek-A18219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "1:15:00 PM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "4/22/2024 13:30",
    "plant_id": "Green-tek-A18220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "1:30:00 PM",
    "power": 0.601,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "4/22/2024 13:45",
    "plant_id": "Green-tek-A18221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "1:45:00 PM",
    "power": 0.516,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "4/22/2024 14:00",
    "plant_id": "Green-tek-A18222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "2:00:00 PM",
    "power": 0.434,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "4/22/2024 14:15",
    "plant_id": "Green-tek-A18223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "2:15:00 PM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "4/22/2024 14:30",
    "plant_id": "Green-tek-A18224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "2:30:00 PM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "4/22/2024 14:45",
    "plant_id": "Green-tek-A18225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "2:45:00 PM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "4/22/2024 15:00",
    "plant_id": "Green-tek-A18226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "3:00:00 PM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "4/22/2024 15:15",
    "plant_id": "Green-tek-A18227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "3:15:00 PM",
    "power": 0.134,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "4/22/2024 15:30",
    "plant_id": "Green-tek-A18228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "3:30:00 PM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "4/22/2024 15:45",
    "plant_id": "Green-tek-A18229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "3:45:00 PM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "4/22/2024 16:00",
    "plant_id": "Green-tek-A18230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "4/22/2024 16:15",
    "plant_id": "Green-tek-A18231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "4/22/2024 16:30",
    "plant_id": "Green-tek-A18232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/22/2024 16:45",
    "plant_id": "Green-tek-A18233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/22/2024 17:00",
    "plant_id": "Green-tek-A18234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/22/2024 17:15",
    "plant_id": "Green-tek-A18235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/22/2024 17:30",
    "plant_id": "Green-tek-A18236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/22/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/23/2024 6:00",
    "plant_id": "Green-tek-A18237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/23/2024 6:15",
    "plant_id": "Green-tek-A18238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/23/2024 6:30",
    "plant_id": "Green-tek-A18239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/23/2024 6:45",
    "plant_id": "Green-tek-A18240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/23/2024 7:00",
    "plant_id": "Green-tek-A18241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/23/2024 7:15",
    "plant_id": "Green-tek-A18242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/23/2024 7:30",
    "plant_id": "Green-tek-A18243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/23/2024 7:45",
    "plant_id": "Green-tek-A18244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "7:45:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/23/2024 8:00",
    "plant_id": "Green-tek-A18245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "8:00:00 AM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/23/2024 8:15",
    "plant_id": "Green-tek-A18246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "8:15:00 AM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/23/2024 8:30",
    "plant_id": "Green-tek-A18247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "8:30:00 AM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/23/2024 8:45",
    "plant_id": "Green-tek-A18248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "8:45:00 AM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/23/2024 9:00",
    "plant_id": "Green-tek-A18249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "9:00:00 AM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "4/23/2024 9:15",
    "plant_id": "Green-tek-A18250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "9:15:00 AM",
    "power": 0.294,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "4/23/2024 9:30",
    "plant_id": "Green-tek-A18251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "9:30:00 AM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "4/23/2024 9:45",
    "plant_id": "Green-tek-A18252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "9:45:00 AM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "4/23/2024 10:00",
    "plant_id": "Green-tek-A18253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "10:00:00 AM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "4/23/2024 10:15",
    "plant_id": "Green-tek-A18254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "10:15:00 AM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "4/23/2024 10:30",
    "plant_id": "Green-tek-A18255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "10:30:00 AM",
    "power": 0.632,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "4/23/2024 10:45",
    "plant_id": "Green-tek-A18256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "10:45:00 AM",
    "power": 0.693,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "4/23/2024 11:00",
    "plant_id": "Green-tek-A18257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "11:00:00 AM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "4/23/2024 11:15",
    "plant_id": "Green-tek-A18258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "11:15:00 AM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "4/23/2024 11:30",
    "plant_id": "Green-tek-A18259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "11:30:00 AM",
    "power": 0.807,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "4/23/2024 11:45",
    "plant_id": "Green-tek-A18260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "11:45:00 AM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "4/23/2024 12:00",
    "plant_id": "Green-tek-A18261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "12:00:00 PM",
    "power": 0.807,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "4/23/2024 12:15",
    "plant_id": "Green-tek-A18262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "12:15:00 PM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "4/23/2024 12:30",
    "plant_id": "Green-tek-A18263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "12:30:00 PM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "4/23/2024 12:45",
    "plant_id": "Green-tek-A18264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "12:45:00 PM",
    "power": 0.693,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "4/23/2024 13:00",
    "plant_id": "Green-tek-A18265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "1:00:00 PM",
    "power": 0.632,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "4/23/2024 13:15",
    "plant_id": "Green-tek-A18266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "1:15:00 PM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "4/23/2024 13:30",
    "plant_id": "Green-tek-A18267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "1:30:00 PM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "4/23/2024 13:45",
    "plant_id": "Green-tek-A18268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "1:45:00 PM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "4/23/2024 14:00",
    "plant_id": "Green-tek-A18269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "2:00:00 PM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "4/23/2024 14:15",
    "plant_id": "Green-tek-A18270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "2:15:00 PM",
    "power": 0.294,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "4/23/2024 14:30",
    "plant_id": "Green-tek-A18271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "2:30:00 PM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "4/23/2024 14:45",
    "plant_id": "Green-tek-A18272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "2:45:00 PM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/23/2024 15:00",
    "plant_id": "Green-tek-A18273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "3:00:00 PM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/23/2024 15:15",
    "plant_id": "Green-tek-A18274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "3:15:00 PM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/23/2024 15:30",
    "plant_id": "Green-tek-A18275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "3:30:00 PM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/23/2024 15:45",
    "plant_id": "Green-tek-A18276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "3:45:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/23/2024 16:00",
    "plant_id": "Green-tek-A18277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/23/2024 16:15",
    "plant_id": "Green-tek-A18278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/23/2024 16:30",
    "plant_id": "Green-tek-A18279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/23/2024 16:45",
    "plant_id": "Green-tek-A18280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/23/2024 17:00",
    "plant_id": "Green-tek-A18281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/23/2024 17:15",
    "plant_id": "Green-tek-A18282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/23/2024 17:30",
    "plant_id": "Green-tek-A18283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/23/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/24/2024 6:00",
    "plant_id": "Green-tek-A18284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/24/2024 6:15",
    "plant_id": "Green-tek-A18285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/24/2024 6:30",
    "plant_id": "Green-tek-A18286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/24/2024 6:45",
    "plant_id": "Green-tek-A18287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/24/2024 7:00",
    "plant_id": "Green-tek-A18288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/24/2024 7:15",
    "plant_id": "Green-tek-A18289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/24/2024 7:30",
    "plant_id": "Green-tek-A18290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "7:30:00 AM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/24/2024 7:45",
    "plant_id": "Green-tek-A18291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "7:45:00 AM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/24/2024 8:00",
    "plant_id": "Green-tek-A18292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "8:00:00 AM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "4/24/2024 8:15",
    "plant_id": "Green-tek-A18293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "8:15:00 AM",
    "power": 0.141,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "4/24/2024 8:30",
    "plant_id": "Green-tek-A18294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "8:30:00 AM",
    "power": 0.185,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "4/24/2024 8:45",
    "plant_id": "Green-tek-A18295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "8:45:00 AM",
    "power": 0.239,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/24/2024 9:00",
    "plant_id": "Green-tek-A18296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "9:00:00 AM",
    "power": 0.303,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "4/24/2024 9:15",
    "plant_id": "Green-tek-A18297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "9:15:00 AM",
    "power": 0.375,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "4/24/2024 9:30",
    "plant_id": "Green-tek-A18298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "9:30:00 AM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "4/24/2024 9:45",
    "plant_id": "Green-tek-A18299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "9:45:00 AM",
    "power": 0.541,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "4/24/2024 10:00",
    "plant_id": "Green-tek-A18300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "10:00:00 AM",
    "power": 0.631,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "4/24/2024 10:15",
    "plant_id": "Green-tek-A18301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "10:15:00 AM",
    "power": 0.72,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "4/24/2024 10:30",
    "plant_id": "Green-tek-A18302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "10:30:00 AM",
    "power": 0.806,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "4/24/2024 10:45",
    "plant_id": "Green-tek-A18303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "10:45:00 AM",
    "power": 0.883,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "4/24/2024 11:00",
    "plant_id": "Green-tek-A18304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "11:00:00 AM",
    "power": 0.949,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "4/24/2024 11:15",
    "plant_id": "Green-tek-A18305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "11:15:00 AM",
    "power": 0.999,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "4/24/2024 11:30",
    "plant_id": "Green-tek-A18306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "11:30:00 AM",
    "power": 1.03,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "4/24/2024 11:45",
    "plant_id": "Green-tek-A18307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "11:45:00 AM",
    "power": 1.04,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "4/24/2024 12:00",
    "plant_id": "Green-tek-A18308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "12:00:00 PM",
    "power": 1.03,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "4/24/2024 12:15",
    "plant_id": "Green-tek-A18309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "12:15:00 PM",
    "power": 0.999,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "4/24/2024 12:30",
    "plant_id": "Green-tek-A18310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "12:30:00 PM",
    "power": 0.949,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "4/24/2024 12:45",
    "plant_id": "Green-tek-A18311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "12:45:00 PM",
    "power": 0.883,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "4/24/2024 13:00",
    "plant_id": "Green-tek-A18312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "1:00:00 PM",
    "power": 0.806,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "4/24/2024 13:15",
    "plant_id": "Green-tek-A18313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "1:15:00 PM",
    "power": 0.72,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "4/24/2024 13:30",
    "plant_id": "Green-tek-A18314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "1:30:00 PM",
    "power": 0.631,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "4/24/2024 13:45",
    "plant_id": "Green-tek-A18315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "1:45:00 PM",
    "power": 0.541,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "4/24/2024 14:00",
    "plant_id": "Green-tek-A18316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "2:00:00 PM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "4/24/2024 14:15",
    "plant_id": "Green-tek-A18317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "2:15:00 PM",
    "power": 0.375,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "4/24/2024 14:30",
    "plant_id": "Green-tek-A18318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "2:30:00 PM",
    "power": 0.303,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "4/24/2024 14:45",
    "plant_id": "Green-tek-A18319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "2:45:00 PM",
    "power": 0.239,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/24/2024 15:00",
    "plant_id": "Green-tek-A18320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "3:00:00 PM",
    "power": 0.185,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "4/24/2024 15:15",
    "plant_id": "Green-tek-A18321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "3:15:00 PM",
    "power": 0.141,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "4/24/2024 15:30",
    "plant_id": "Green-tek-A18322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "3:30:00 PM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "4/24/2024 15:45",
    "plant_id": "Green-tek-A18323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "3:45:00 PM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "4/24/2024 16:00",
    "plant_id": "Green-tek-A18324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "4:00:00 PM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/24/2024 16:15",
    "plant_id": "Green-tek-A18325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/24/2024 16:30",
    "plant_id": "Green-tek-A18326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/24/2024 16:45",
    "plant_id": "Green-tek-A18327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/24/2024 17:00",
    "plant_id": "Green-tek-A18328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/24/2024 17:15",
    "plant_id": "Green-tek-A18329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/24/2024 17:30",
    "plant_id": "Green-tek-A18330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/24/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/25/2024 6:00",
    "plant_id": "Green-tek-A18331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/25/2024 6:15",
    "plant_id": "Green-tek-A18332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/25/2024 6:30",
    "plant_id": "Green-tek-A18333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/25/2024 6:45",
    "plant_id": "Green-tek-A18334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/25/2024 7:00",
    "plant_id": "Green-tek-A18335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/25/2024 7:15",
    "plant_id": "Green-tek-A18336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/25/2024 7:30",
    "plant_id": "Green-tek-A18337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/25/2024 7:45",
    "plant_id": "Green-tek-A18338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/25/2024 8:00",
    "plant_id": "Green-tek-A18339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "8:00:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/25/2024 8:15",
    "plant_id": "Green-tek-A18340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "8:15:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/25/2024 8:30",
    "plant_id": "Green-tek-A18341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "8:30:00 AM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/25/2024 8:45",
    "plant_id": "Green-tek-A18342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "8:45:00 AM",
    "power": 0.19,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/25/2024 9:00",
    "plant_id": "Green-tek-A18343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "9:00:00 AM",
    "power": 0.24,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/25/2024 9:15",
    "plant_id": "Green-tek-A18344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "9:15:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/25/2024 9:30",
    "plant_id": "Green-tek-A18345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "9:30:00 AM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "4/25/2024 9:45",
    "plant_id": "Green-tek-A18346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "9:45:00 AM",
    "power": 0.429,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "4/25/2024 10:00",
    "plant_id": "Green-tek-A18347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "10:00:00 AM",
    "power": 0.5,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/25/2024 10:15",
    "plant_id": "Green-tek-A18348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "10:15:00 AM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "4/25/2024 10:30",
    "plant_id": "Green-tek-A18349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "10:30:00 AM",
    "power": 0.639,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "4/25/2024 10:45",
    "plant_id": "Green-tek-A18350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "10:45:00 AM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "4/25/2024 11:00",
    "plant_id": "Green-tek-A18351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "11:00:00 AM",
    "power": 0.752,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "4/25/2024 11:15",
    "plant_id": "Green-tek-A18352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "11:15:00 AM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "4/25/2024 11:30",
    "plant_id": "Green-tek-A18353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "11:30:00 AM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "4/25/2024 11:45",
    "plant_id": "Green-tek-A18354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "11:45:00 AM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "4/25/2024 12:00",
    "plant_id": "Green-tek-A18355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "12:00:00 PM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "4/25/2024 12:15",
    "plant_id": "Green-tek-A18356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "12:15:00 PM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "4/25/2024 12:30",
    "plant_id": "Green-tek-A18357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "12:30:00 PM",
    "power": 0.752,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "4/25/2024 12:45",
    "plant_id": "Green-tek-A18358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "12:45:00 PM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "4/25/2024 13:00",
    "plant_id": "Green-tek-A18359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "1:00:00 PM",
    "power": 0.639,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "4/25/2024 13:15",
    "plant_id": "Green-tek-A18360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "1:15:00 PM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "4/25/2024 13:30",
    "plant_id": "Green-tek-A18361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "1:30:00 PM",
    "power": 0.5,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/25/2024 13:45",
    "plant_id": "Green-tek-A18362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "1:45:00 PM",
    "power": 0.429,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "4/25/2024 14:00",
    "plant_id": "Green-tek-A18363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "2:00:00 PM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "4/25/2024 14:15",
    "plant_id": "Green-tek-A18364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "2:15:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/25/2024 14:30",
    "plant_id": "Green-tek-A18365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "2:30:00 PM",
    "power": 0.24,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "4/25/2024 14:45",
    "plant_id": "Green-tek-A18366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "2:45:00 PM",
    "power": 0.19,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/25/2024 15:00",
    "plant_id": "Green-tek-A18367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "3:00:00 PM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/25/2024 15:15",
    "plant_id": "Green-tek-A18368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "3:15:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/25/2024 15:30",
    "plant_id": "Green-tek-A18369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "3:30:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/25/2024 15:45",
    "plant_id": "Green-tek-A18370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/25/2024 16:00",
    "plant_id": "Green-tek-A18371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/25/2024 16:15",
    "plant_id": "Green-tek-A18372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/25/2024 16:30",
    "plant_id": "Green-tek-A18373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/25/2024 16:45",
    "plant_id": "Green-tek-A18374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/25/2024 17:00",
    "plant_id": "Green-tek-A18375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/25/2024 17:15",
    "plant_id": "Green-tek-A18376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/25/2024 17:30",
    "plant_id": "Green-tek-A18377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/25/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/26/2024 6:00",
    "plant_id": "Green-tek-A18378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/26/2024 6:15",
    "plant_id": "Green-tek-A18379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/26/2024 6:30",
    "plant_id": "Green-tek-A18380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/26/2024 6:45",
    "plant_id": "Green-tek-A18381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/26/2024 7:00",
    "plant_id": "Green-tek-A18382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/26/2024 7:15",
    "plant_id": "Green-tek-A18383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/26/2024 7:30",
    "plant_id": "Green-tek-A18384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/26/2024 7:45",
    "plant_id": "Green-tek-A18385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "7:45:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/26/2024 8:00",
    "plant_id": "Green-tek-A18386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "8:00:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "4/26/2024 8:15",
    "plant_id": "Green-tek-A18387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "8:15:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "4/26/2024 8:30",
    "plant_id": "Green-tek-A18388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "8:30:00 AM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "4/26/2024 8:45",
    "plant_id": "Green-tek-A18389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "8:45:00 AM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "4/26/2024 9:00",
    "plant_id": "Green-tek-A18390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "9:00:00 AM",
    "power": 0.27,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "4/26/2024 9:15",
    "plant_id": "Green-tek-A18391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "9:15:00 AM",
    "power": 0.334,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "4/26/2024 9:30",
    "plant_id": "Green-tek-A18392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "9:30:00 AM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "4/26/2024 9:45",
    "plant_id": "Green-tek-A18393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "9:45:00 AM",
    "power": 0.482,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "4/26/2024 10:00",
    "plant_id": "Green-tek-A18394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "10:00:00 AM",
    "power": 0.562,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "4/26/2024 10:15",
    "plant_id": "Green-tek-A18395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "10:15:00 AM",
    "power": 0.642,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "4/26/2024 10:30",
    "plant_id": "Green-tek-A18396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "10:30:00 AM",
    "power": 0.718,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "4/26/2024 10:45",
    "plant_id": "Green-tek-A18397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "10:45:00 AM",
    "power": 0.787,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "4/26/2024 11:00",
    "plant_id": "Green-tek-A18398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "11:00:00 AM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "4/26/2024 11:15",
    "plant_id": "Green-tek-A18399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "11:15:00 AM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "4/26/2024 11:30",
    "plant_id": "Green-tek-A18400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "11:30:00 AM",
    "power": 0.917,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "4/26/2024 11:45",
    "plant_id": "Green-tek-A18401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "11:45:00 AM",
    "power": 0.926,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "4/26/2024 12:00",
    "plant_id": "Green-tek-A18402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "12:00:00 PM",
    "power": 0.917,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "4/26/2024 12:15",
    "plant_id": "Green-tek-A18403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "12:15:00 PM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "4/26/2024 12:30",
    "plant_id": "Green-tek-A18404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "12:30:00 PM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "4/26/2024 12:45",
    "plant_id": "Green-tek-A18405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "12:45:00 PM",
    "power": 0.787,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "4/26/2024 13:00",
    "plant_id": "Green-tek-A18406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "1:00:00 PM",
    "power": 0.718,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "4/26/2024 13:15",
    "plant_id": "Green-tek-A18407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "1:15:00 PM",
    "power": 0.642,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "4/26/2024 13:30",
    "plant_id": "Green-tek-A18408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "1:30:00 PM",
    "power": 0.562,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "4/26/2024 13:45",
    "plant_id": "Green-tek-A18409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "1:45:00 PM",
    "power": 0.482,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "4/26/2024 14:00",
    "plant_id": "Green-tek-A18410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "2:00:00 PM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "4/26/2024 14:15",
    "plant_id": "Green-tek-A18411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "2:15:00 PM",
    "power": 0.334,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "4/26/2024 14:30",
    "plant_id": "Green-tek-A18412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "2:30:00 PM",
    "power": 0.27,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "4/26/2024 14:45",
    "plant_id": "Green-tek-A18413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "2:45:00 PM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "4/26/2024 15:00",
    "plant_id": "Green-tek-A18414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "3:00:00 PM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "4/26/2024 15:15",
    "plant_id": "Green-tek-A18415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "3:15:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "4/26/2024 15:30",
    "plant_id": "Green-tek-A18416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "3:30:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "4/26/2024 15:45",
    "plant_id": "Green-tek-A18417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "3:45:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/26/2024 16:00",
    "plant_id": "Green-tek-A18418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/26/2024 16:15",
    "plant_id": "Green-tek-A18419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/26/2024 16:30",
    "plant_id": "Green-tek-A18420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "4/26/2024 16:45",
    "plant_id": "Green-tek-A18421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/26/2024 17:00",
    "plant_id": "Green-tek-A18422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/26/2024 17:15",
    "plant_id": "Green-tek-A18423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/26/2024 17:30",
    "plant_id": "Green-tek-A18424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/26/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/27/2024 6:00",
    "plant_id": "Green-tek-A18425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/27/2024 6:15",
    "plant_id": "Green-tek-A18426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/27/2024 6:30",
    "plant_id": "Green-tek-A18427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/27/2024 6:45",
    "plant_id": "Green-tek-A18428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/27/2024 7:00",
    "plant_id": "Green-tek-A18429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/27/2024 7:15",
    "plant_id": "Green-tek-A18430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/27/2024 7:30",
    "plant_id": "Green-tek-A18431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/27/2024 7:45",
    "plant_id": "Green-tek-A18432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "7:45:00 AM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/27/2024 8:00",
    "plant_id": "Green-tek-A18433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "8:00:00 AM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/27/2024 8:15",
    "plant_id": "Green-tek-A18434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "8:15:00 AM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/27/2024 8:30",
    "plant_id": "Green-tek-A18435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "8:30:00 AM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "4/27/2024 8:45",
    "plant_id": "Green-tek-A18436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "8:45:00 AM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "4/27/2024 9:00",
    "plant_id": "Green-tek-A18437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "9:00:00 AM",
    "power": 0.314,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "4/27/2024 9:15",
    "plant_id": "Green-tek-A18438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "9:15:00 AM",
    "power": 0.389,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "4/27/2024 9:30",
    "plant_id": "Green-tek-A18439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "9:30:00 AM",
    "power": 0.472,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "4/27/2024 9:45",
    "plant_id": "Green-tek-A18440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "9:45:00 AM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "4/27/2024 10:00",
    "plant_id": "Green-tek-A18441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "10:00:00 AM",
    "power": 0.654,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "4/27/2024 10:15",
    "plant_id": "Green-tek-A18442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "10:15:00 AM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "4/27/2024 10:30",
    "plant_id": "Green-tek-A18443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "10:30:00 AM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "4/27/2024 10:45",
    "plant_id": "Green-tek-A18444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "10:45:00 AM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "4/27/2024 11:00",
    "plant_id": "Green-tek-A18445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "11:00:00 AM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "4/27/2024 11:15",
    "plant_id": "Green-tek-A18446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "11:15:00 AM",
    "power": 1.036,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "4/27/2024 11:30",
    "plant_id": "Green-tek-A18447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "11:30:00 AM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "4/27/2024 11:45",
    "plant_id": "Green-tek-A18448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "11:45:00 AM",
    "power": 1.079,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "4/27/2024 12:00",
    "plant_id": "Green-tek-A18449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "12:00:00 PM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "4/27/2024 12:15",
    "plant_id": "Green-tek-A18450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "12:15:00 PM",
    "power": 1.036,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "4/27/2024 12:30",
    "plant_id": "Green-tek-A18451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "12:30:00 PM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "4/27/2024 12:45",
    "plant_id": "Green-tek-A18452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "12:45:00 PM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "4/27/2024 13:00",
    "plant_id": "Green-tek-A18453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "1:00:00 PM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "4/27/2024 13:15",
    "plant_id": "Green-tek-A18454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "1:15:00 PM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "4/27/2024 13:30",
    "plant_id": "Green-tek-A18455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "1:30:00 PM",
    "power": 0.654,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "4/27/2024 13:45",
    "plant_id": "Green-tek-A18456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "1:45:00 PM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "4/27/2024 14:00",
    "plant_id": "Green-tek-A18457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "2:00:00 PM",
    "power": 0.472,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "4/27/2024 14:15",
    "plant_id": "Green-tek-A18458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "2:15:00 PM",
    "power": 0.389,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "4/27/2024 14:30",
    "plant_id": "Green-tek-A18459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "2:30:00 PM",
    "power": 0.314,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "4/27/2024 14:45",
    "plant_id": "Green-tek-A18460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "2:45:00 PM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "4/27/2024 15:00",
    "plant_id": "Green-tek-A18461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "3:00:00 PM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "4/27/2024 15:15",
    "plant_id": "Green-tek-A18462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "3:15:00 PM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "4/27/2024 15:30",
    "plant_id": "Green-tek-A18463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "3:30:00 PM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "4/27/2024 15:45",
    "plant_id": "Green-tek-A18464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "3:45:00 PM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "4/27/2024 16:00",
    "plant_id": "Green-tek-A18465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "4/27/2024 16:15",
    "plant_id": "Green-tek-A18466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/27/2024 16:30",
    "plant_id": "Green-tek-A18467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/27/2024 16:45",
    "plant_id": "Green-tek-A18468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/27/2024 17:00",
    "plant_id": "Green-tek-A18469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/27/2024 17:15",
    "plant_id": "Green-tek-A18470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/27/2024 17:30",
    "plant_id": "Green-tek-A18471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/27/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/28/2024 6:00",
    "plant_id": "Green-tek-A18472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/28/2024 6:15",
    "plant_id": "Green-tek-A18473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/28/2024 6:30",
    "plant_id": "Green-tek-A18474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/28/2024 6:45",
    "plant_id": "Green-tek-A18475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/28/2024 7:00",
    "plant_id": "Green-tek-A18476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/28/2024 7:15",
    "plant_id": "Green-tek-A18477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/28/2024 7:30",
    "plant_id": "Green-tek-A18478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/28/2024 7:45",
    "plant_id": "Green-tek-A18479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/28/2024 8:00",
    "plant_id": "Green-tek-A18480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "8:00:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "4/28/2024 8:15",
    "plant_id": "Green-tek-A18481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "8:15:00 AM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "4/28/2024 8:30",
    "plant_id": "Green-tek-A18482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "8:30:00 AM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "4/28/2024 8:45",
    "plant_id": "Green-tek-A18483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "8:45:00 AM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "4/28/2024 9:00",
    "plant_id": "Green-tek-A18484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "9:00:00 AM",
    "power": 0.333,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "4/28/2024 9:15",
    "plant_id": "Green-tek-A18485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "9:15:00 AM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "4/28/2024 9:30",
    "plant_id": "Green-tek-A18486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "9:30:00 AM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/28/2024 9:45",
    "plant_id": "Green-tek-A18487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "9:45:00 AM",
    "power": 0.595,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "4/28/2024 10:00",
    "plant_id": "Green-tek-A18488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "10:00:00 AM",
    "power": 0.694,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "4/28/2024 10:15",
    "plant_id": "Green-tek-A18489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "10:15:00 AM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "4/28/2024 10:30",
    "plant_id": "Green-tek-A18490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "10:30:00 AM",
    "power": 0.886,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "4/28/2024 10:45",
    "plant_id": "Green-tek-A18491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "10:45:00 AM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "4/28/2024 11:00",
    "plant_id": "Green-tek-A18492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "11:00:00 AM",
    "power": 1.044,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "4/28/2024 11:15",
    "plant_id": "Green-tek-A18493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "11:15:00 AM",
    "power": 1.098,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "4/28/2024 11:30",
    "plant_id": "Green-tek-A18494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "11:30:00 AM",
    "power": 1.132,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "4/28/2024 11:45",
    "plant_id": "Green-tek-A18495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "11:45:00 AM",
    "power": 1.144,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "4/28/2024 12:00",
    "plant_id": "Green-tek-A18496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "12:00:00 PM",
    "power": 1.132,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "4/28/2024 12:15",
    "plant_id": "Green-tek-A18497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "12:15:00 PM",
    "power": 1.098,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "4/28/2024 12:30",
    "plant_id": "Green-tek-A18498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "12:30:00 PM",
    "power": 1.044,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "4/28/2024 12:45",
    "plant_id": "Green-tek-A18499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "12:45:00 PM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "4/28/2024 13:00",
    "plant_id": "Green-tek-A18500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "1:00:00 PM",
    "power": 0.886,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "4/28/2024 13:15",
    "plant_id": "Green-tek-A18501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "1:15:00 PM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "4/28/2024 13:30",
    "plant_id": "Green-tek-A18502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "1:30:00 PM",
    "power": 0.694,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "4/28/2024 13:45",
    "plant_id": "Green-tek-A18503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "1:45:00 PM",
    "power": 0.595,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "4/28/2024 14:00",
    "plant_id": "Green-tek-A18504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "2:00:00 PM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "4/28/2024 14:15",
    "plant_id": "Green-tek-A18505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "2:15:00 PM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "4/28/2024 14:30",
    "plant_id": "Green-tek-A18506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "2:30:00 PM",
    "power": 0.333,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "4/28/2024 14:45",
    "plant_id": "Green-tek-A18507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "2:45:00 PM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "4/28/2024 15:00",
    "plant_id": "Green-tek-A18508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "3:00:00 PM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "4/28/2024 15:15",
    "plant_id": "Green-tek-A18509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "3:15:00 PM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "4/28/2024 15:30",
    "plant_id": "Green-tek-A18510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "3:30:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "4/28/2024 15:45",
    "plant_id": "Green-tek-A18511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/28/2024 16:00",
    "plant_id": "Green-tek-A18512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/28/2024 16:15",
    "plant_id": "Green-tek-A18513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "4/28/2024 16:30",
    "plant_id": "Green-tek-A18514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/28/2024 16:45",
    "plant_id": "Green-tek-A18515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/28/2024 17:00",
    "plant_id": "Green-tek-A18516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/28/2024 17:15",
    "plant_id": "Green-tek-A18517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/28/2024 17:30",
    "plant_id": "Green-tek-A18518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/28/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/29/2024 6:00",
    "plant_id": "Green-tek-A18519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/29/2024 6:15",
    "plant_id": "Green-tek-A18520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/29/2024 6:30",
    "plant_id": "Green-tek-A18521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/29/2024 6:45",
    "plant_id": "Green-tek-A18522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/29/2024 7:00",
    "plant_id": "Green-tek-A18523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/29/2024 7:15",
    "plant_id": "Green-tek-A18524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/29/2024 7:30",
    "plant_id": "Green-tek-A18525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/29/2024 7:45",
    "plant_id": "Green-tek-A18526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "4/29/2024 8:00",
    "plant_id": "Green-tek-A18527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "8:00:00 AM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/29/2024 8:15",
    "plant_id": "Green-tek-A18528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "8:15:00 AM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "4/29/2024 8:30",
    "plant_id": "Green-tek-A18529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "8:30:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "4/29/2024 8:45",
    "plant_id": "Green-tek-A18530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "8:45:00 AM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "4/29/2024 9:00",
    "plant_id": "Green-tek-A18531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "9:00:00 AM",
    "power": 0.37,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "4/29/2024 9:15",
    "plant_id": "Green-tek-A18532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "9:15:00 AM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "4/29/2024 9:30",
    "plant_id": "Green-tek-A18533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "9:30:00 AM",
    "power": 0.557,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "4/29/2024 9:45",
    "plant_id": "Green-tek-A18534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "9:45:00 AM",
    "power": 0.662,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "4/29/2024 10:00",
    "plant_id": "Green-tek-A18535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "10:00:00 AM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "4/29/2024 10:15",
    "plant_id": "Green-tek-A18536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "10:15:00 AM",
    "power": 0.881,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "4/29/2024 10:30",
    "plant_id": "Green-tek-A18537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "10:30:00 AM",
    "power": 0.986,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "4/29/2024 10:45",
    "plant_id": "Green-tek-A18538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "10:45:00 AM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "4/29/2024 11:00",
    "plant_id": "Green-tek-A18539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "11:00:00 AM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "4/29/2024 11:15",
    "plant_id": "Green-tek-A18540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "11:15:00 AM",
    "power": 1.222,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.3213,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "4/29/2024 11:30",
    "plant_id": "Green-tek-A18541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "11:30:00 AM",
    "power": 1.26,
    "Energy": 0.315,
    "output Energy": 0.315,
    "Ac output": 1.315,
    "dc output": 1.3413,
    "AC_R_i": 0.00041087,
    "AC_S_i": 0.000451957,
    "AC_T_i": 0.00049715,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0945001,
    "AC_S_pow": 0.10395011,
    "AC_T_Pow": 0.1143445,
    "AC_R_output": 0.3945,
    "AC_S_output": 0.43395,
    "AC_T_output": 0.477345,
    "dc_current": 0.00231618,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.3213,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000315,
    "carbon offset": 0.39375,
    "trees planted": 8.75,
    "DC_R_output": 0.130185,
    "DC_S_output": 0.1432035,
    "DC_T_output": 0.15752385
  },
  {
    "datetime": "4/29/2024 11:45",
    "plant_id": "Green-tek-A18542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "11:45:00 AM",
    "power": 1.273,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.3213,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "4/29/2024 12:00",
    "plant_id": "Green-tek-A18543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "12:00:00 PM",
    "power": 1.26,
    "Energy": 0.315,
    "output Energy": 0.315,
    "Ac output": 1.315,
    "dc output": 1.3413,
    "AC_R_i": 0.00041087,
    "AC_S_i": 0.000451957,
    "AC_T_i": 0.00049715,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0945001,
    "AC_S_pow": 0.10395011,
    "AC_T_Pow": 0.1143445,
    "AC_R_output": 0.3945,
    "AC_S_output": 0.43395,
    "AC_T_output": 0.477345,
    "dc_current": 0.00231618,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.3213,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000315,
    "carbon offset": 0.39375,
    "trees planted": 8.75,
    "DC_R_output": 0.130185,
    "DC_S_output": 0.1432035,
    "DC_T_output": 0.15752385
  },
  {
    "datetime": "4/29/2024 12:15",
    "plant_id": "Green-tek-A18544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "12:15:00 PM",
    "power": 1.222,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "4/29/2024 12:30",
    "plant_id": "Green-tek-A18545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "12:30:00 PM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "4/29/2024 12:45",
    "plant_id": "Green-tek-A18546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "12:45:00 PM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "4/29/2024 13:00",
    "plant_id": "Green-tek-A18547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "1:00:00 PM",
    "power": 0.986,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "4/29/2024 13:15",
    "plant_id": "Green-tek-A18548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "1:15:00 PM",
    "power": 0.881,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "4/29/2024 13:30",
    "plant_id": "Green-tek-A18549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "1:30:00 PM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "4/29/2024 13:45",
    "plant_id": "Green-tek-A18550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "1:45:00 PM",
    "power": 0.662,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "4/29/2024 14:00",
    "plant_id": "Green-tek-A18551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "2:00:00 PM",
    "power": 0.557,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "4/29/2024 14:15",
    "plant_id": "Green-tek-A18552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "2:15:00 PM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "4/29/2024 14:30",
    "plant_id": "Green-tek-A18553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "2:30:00 PM",
    "power": 0.37,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "4/29/2024 14:45",
    "plant_id": "Green-tek-A18554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "2:45:00 PM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "4/29/2024 15:00",
    "plant_id": "Green-tek-A18555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "3:00:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "4/29/2024 15:15",
    "plant_id": "Green-tek-A18556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "3:15:00 PM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "4/29/2024 15:30",
    "plant_id": "Green-tek-A18557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "3:30:00 PM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "4/29/2024 15:45",
    "plant_id": "Green-tek-A18558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "4/29/2024 16:00",
    "plant_id": "Green-tek-A18559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "4/29/2024 16:15",
    "plant_id": "Green-tek-A18560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "4/29/2024 16:30",
    "plant_id": "Green-tek-A18561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "4/29/2024 16:45",
    "plant_id": "Green-tek-A18562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/29/2024 17:00",
    "plant_id": "Green-tek-A18563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "4/29/2024 17:15",
    "plant_id": "Green-tek-A18564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/29/2024 17:30",
    "plant_id": "Green-tek-A18565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/29/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/30/2024 6:00",
    "plant_id": "Green-tek-A18566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/30/2024 6:15",
    "plant_id": "Green-tek-A18567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/30/2024 6:30",
    "plant_id": "Green-tek-A18568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/30/2024 6:45",
    "plant_id": "Green-tek-A18569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/30/2024 7:00",
    "plant_id": "Green-tek-A18570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/30/2024 7:15",
    "plant_id": "Green-tek-A18571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/30/2024 7:30",
    "plant_id": "Green-tek-A18572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/30/2024 7:45",
    "plant_id": "Green-tek-A18573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "7:45:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/30/2024 8:00",
    "plant_id": "Green-tek-A18574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "8:00:00 AM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/30/2024 8:15",
    "plant_id": "Green-tek-A18575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "8:15:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/30/2024 8:30",
    "plant_id": "Green-tek-A18576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "8:30:00 AM",
    "power": 0.146,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/30/2024 8:45",
    "plant_id": "Green-tek-A18577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "8:45:00 AM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/30/2024 9:00",
    "plant_id": "Green-tek-A18578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "9:00:00 AM",
    "power": 0.238,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "4/30/2024 9:15",
    "plant_id": "Green-tek-A18579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "9:15:00 AM",
    "power": 0.294,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/30/2024 9:30",
    "plant_id": "Green-tek-A18580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "9:30:00 AM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "4/30/2024 9:45",
    "plant_id": "Green-tek-A18581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "9:45:00 AM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "4/30/2024 10:00",
    "plant_id": "Green-tek-A18582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "10:00:00 AM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "4/30/2024 10:15",
    "plant_id": "Green-tek-A18583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "10:15:00 AM",
    "power": 0.566,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "4/30/2024 10:30",
    "plant_id": "Green-tek-A18584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "10:30:00 AM",
    "power": 0.633,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "4/30/2024 10:45",
    "plant_id": "Green-tek-A18585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "10:45:00 AM",
    "power": 0.694,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "4/30/2024 11:00",
    "plant_id": "Green-tek-A18586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "11:00:00 AM",
    "power": 0.745,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "4/30/2024 11:15",
    "plant_id": "Green-tek-A18587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "11:15:00 AM",
    "power": 0.784,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "4/30/2024 11:30",
    "plant_id": "Green-tek-A18588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "11:30:00 AM",
    "power": 0.808,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "4/30/2024 11:45",
    "plant_id": "Green-tek-A18589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "11:45:00 AM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "4/30/2024 12:00",
    "plant_id": "Green-tek-A18590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "12:00:00 PM",
    "power": 0.808,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "4/30/2024 12:15",
    "plant_id": "Green-tek-A18591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "12:15:00 PM",
    "power": 0.784,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "4/30/2024 12:30",
    "plant_id": "Green-tek-A18592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "12:30:00 PM",
    "power": 0.745,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "4/30/2024 12:45",
    "plant_id": "Green-tek-A18593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "12:45:00 PM",
    "power": 0.694,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "4/30/2024 13:00",
    "plant_id": "Green-tek-A18594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "1:00:00 PM",
    "power": 0.633,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "4/30/2024 13:15",
    "plant_id": "Green-tek-A18595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "1:15:00 PM",
    "power": 0.566,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "4/30/2024 13:30",
    "plant_id": "Green-tek-A18596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "1:30:00 PM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "4/30/2024 13:45",
    "plant_id": "Green-tek-A18597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "1:45:00 PM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "4/30/2024 14:00",
    "plant_id": "Green-tek-A18598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "2:00:00 PM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "4/30/2024 14:15",
    "plant_id": "Green-tek-A18599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "2:15:00 PM",
    "power": 0.294,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "4/30/2024 14:30",
    "plant_id": "Green-tek-A18600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "2:30:00 PM",
    "power": 0.238,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "4/30/2024 14:45",
    "plant_id": "Green-tek-A18601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "2:45:00 PM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "4/30/2024 15:00",
    "plant_id": "Green-tek-A18602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "3:00:00 PM",
    "power": 0.146,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "4/30/2024 15:15",
    "plant_id": "Green-tek-A18603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "3:15:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "4/30/2024 15:30",
    "plant_id": "Green-tek-A18604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "3:30:00 PM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "4/30/2024 15:45",
    "plant_id": "Green-tek-A18605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "3:45:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "4/30/2024 16:00",
    "plant_id": "Green-tek-A18606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "4/30/2024 16:15",
    "plant_id": "Green-tek-A18607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "4/30/2024 16:30",
    "plant_id": "Green-tek-A18608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "4/30/2024 16:45",
    "plant_id": "Green-tek-A18609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "4/30/2024 17:00",
    "plant_id": "Green-tek-A18610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "4/30/2024 17:15",
    "plant_id": "Green-tek-A18611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "4/30/2024 17:30",
    "plant_id": "Green-tek-A18612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "4/30/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/1/2024 6:00",
    "plant_id": "Green-tek-A18613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/1/2024 6:15",
    "plant_id": "Green-tek-A18614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/1/2024 6:30",
    "plant_id": "Green-tek-A18615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/1/2024 6:45",
    "plant_id": "Green-tek-A18616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/1/2024 7:00",
    "plant_id": "Green-tek-A18617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/1/2024 7:15",
    "plant_id": "Green-tek-A18618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/1/2024 7:30",
    "plant_id": "Green-tek-A18619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/1/2024 7:45",
    "plant_id": "Green-tek-A18620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/1/2024 8:00",
    "plant_id": "Green-tek-A18621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "8:00:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/1/2024 8:15",
    "plant_id": "Green-tek-A18622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "8:15:00 AM",
    "power": 0.118,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/1/2024 8:30",
    "plant_id": "Green-tek-A18623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "8:30:00 AM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "5/1/2024 8:45",
    "plant_id": "Green-tek-A18624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "8:45:00 AM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "5/1/2024 9:00",
    "plant_id": "Green-tek-A18625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "9:00:00 AM",
    "power": 0.254,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "5/1/2024 9:15",
    "plant_id": "Green-tek-A18626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "9:15:00 AM",
    "power": 0.315,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "5/1/2024 9:30",
    "plant_id": "Green-tek-A18627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "9:30:00 AM",
    "power": 0.382,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "5/1/2024 9:45",
    "plant_id": "Green-tek-A18628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "9:45:00 AM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "5/1/2024 10:00",
    "plant_id": "Green-tek-A18629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "10:00:00 AM",
    "power": 0.53,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "5/1/2024 10:15",
    "plant_id": "Green-tek-A18630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "10:15:00 AM",
    "power": 0.605,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "5/1/2024 10:30",
    "plant_id": "Green-tek-A18631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "10:30:00 AM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "5/1/2024 10:45",
    "plant_id": "Green-tek-A18632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "10:45:00 AM",
    "power": 0.742,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "5/1/2024 11:00",
    "plant_id": "Green-tek-A18633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "11:00:00 AM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "5/1/2024 11:15",
    "plant_id": "Green-tek-A18634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "11:15:00 AM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/1/2024 11:30",
    "plant_id": "Green-tek-A18635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "11:30:00 AM",
    "power": 0.865,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "5/1/2024 11:45",
    "plant_id": "Green-tek-A18636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "11:45:00 AM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "5/1/2024 12:00",
    "plant_id": "Green-tek-A18637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "12:00:00 PM",
    "power": 0.865,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "5/1/2024 12:15",
    "plant_id": "Green-tek-A18638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "12:15:00 PM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/1/2024 12:30",
    "plant_id": "Green-tek-A18639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "12:30:00 PM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "5/1/2024 12:45",
    "plant_id": "Green-tek-A18640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "12:45:00 PM",
    "power": 0.742,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "5/1/2024 13:00",
    "plant_id": "Green-tek-A18641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "1:00:00 PM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "5/1/2024 13:15",
    "plant_id": "Green-tek-A18642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "1:15:00 PM",
    "power": 0.605,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "5/1/2024 13:30",
    "plant_id": "Green-tek-A18643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "1:30:00 PM",
    "power": 0.53,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "5/1/2024 13:45",
    "plant_id": "Green-tek-A18644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "1:45:00 PM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "5/1/2024 14:00",
    "plant_id": "Green-tek-A18645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "2:00:00 PM",
    "power": 0.382,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "5/1/2024 14:15",
    "plant_id": "Green-tek-A18646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "2:15:00 PM",
    "power": 0.315,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "5/1/2024 14:30",
    "plant_id": "Green-tek-A18647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "2:30:00 PM",
    "power": 0.254,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "5/1/2024 14:45",
    "plant_id": "Green-tek-A18648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "2:45:00 PM",
    "power": 0.201,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "5/1/2024 15:00",
    "plant_id": "Green-tek-A18649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "3:00:00 PM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "5/1/2024 15:15",
    "plant_id": "Green-tek-A18650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "3:15:00 PM",
    "power": 0.118,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/1/2024 15:30",
    "plant_id": "Green-tek-A18651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "3:30:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/1/2024 15:45",
    "plant_id": "Green-tek-A18652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/1/2024 16:00",
    "plant_id": "Green-tek-A18653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/1/2024 16:15",
    "plant_id": "Green-tek-A18654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/1/2024 16:30",
    "plant_id": "Green-tek-A18655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/1/2024 16:45",
    "plant_id": "Green-tek-A18656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/1/2024 17:00",
    "plant_id": "Green-tek-A18657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/1/2024 17:15",
    "plant_id": "Green-tek-A18658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/1/2024 17:30",
    "plant_id": "Green-tek-A18659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/1/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/2/2024 6:00",
    "plant_id": "Green-tek-A18660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/2/2024 6:15",
    "plant_id": "Green-tek-A18661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/2/2024 6:30",
    "plant_id": "Green-tek-A18662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/2/2024 6:45",
    "plant_id": "Green-tek-A18663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/2/2024 7:00",
    "plant_id": "Green-tek-A18664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/2/2024 7:15",
    "plant_id": "Green-tek-A18665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/2/2024 7:30",
    "plant_id": "Green-tek-A18666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "7:30:00 AM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "5/2/2024 7:45",
    "plant_id": "Green-tek-A18667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "7:45:00 AM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "5/2/2024 8:00",
    "plant_id": "Green-tek-A18668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "8:00:00 AM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "5/2/2024 8:15",
    "plant_id": "Green-tek-A18669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "8:15:00 AM",
    "power": 0.14,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "5/2/2024 8:30",
    "plant_id": "Green-tek-A18670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "8:30:00 AM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "5/2/2024 8:45",
    "plant_id": "Green-tek-A18671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "8:45:00 AM",
    "power": 0.238,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "5/2/2024 9:00",
    "plant_id": "Green-tek-A18672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "9:00:00 AM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "5/2/2024 9:15",
    "plant_id": "Green-tek-A18673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "9:15:00 AM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "5/2/2024 9:30",
    "plant_id": "Green-tek-A18674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "9:30:00 AM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "5/2/2024 9:45",
    "plant_id": "Green-tek-A18675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "9:45:00 AM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "5/2/2024 10:00",
    "plant_id": "Green-tek-A18676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "10:00:00 AM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "5/2/2024 10:15",
    "plant_id": "Green-tek-A18677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "10:15:00 AM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "5/2/2024 10:30",
    "plant_id": "Green-tek-A18678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "10:30:00 AM",
    "power": 0.802,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "5/2/2024 10:45",
    "plant_id": "Green-tek-A18679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "10:45:00 AM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "5/2/2024 11:00",
    "plant_id": "Green-tek-A18680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "11:00:00 AM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "5/2/2024 11:15",
    "plant_id": "Green-tek-A18681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "11:15:00 AM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "5/2/2024 11:30",
    "plant_id": "Green-tek-A18682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "11:30:00 AM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "5/2/2024 11:45",
    "plant_id": "Green-tek-A18683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "11:45:00 AM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "5/2/2024 12:00",
    "plant_id": "Green-tek-A18684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "12:00:00 PM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "5/2/2024 12:15",
    "plant_id": "Green-tek-A18685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "12:15:00 PM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "5/2/2024 12:30",
    "plant_id": "Green-tek-A18686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "12:30:00 PM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "5/2/2024 12:45",
    "plant_id": "Green-tek-A18687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "12:45:00 PM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "5/2/2024 13:00",
    "plant_id": "Green-tek-A18688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "1:00:00 PM",
    "power": 0.802,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "5/2/2024 13:15",
    "plant_id": "Green-tek-A18689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "1:15:00 PM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "5/2/2024 13:30",
    "plant_id": "Green-tek-A18690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "1:30:00 PM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "5/2/2024 13:45",
    "plant_id": "Green-tek-A18691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "1:45:00 PM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "5/2/2024 14:00",
    "plant_id": "Green-tek-A18692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "2:00:00 PM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "5/2/2024 14:15",
    "plant_id": "Green-tek-A18693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "2:15:00 PM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "5/2/2024 14:30",
    "plant_id": "Green-tek-A18694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "2:30:00 PM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "5/2/2024 14:45",
    "plant_id": "Green-tek-A18695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "2:45:00 PM",
    "power": 0.238,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "5/2/2024 15:00",
    "plant_id": "Green-tek-A18696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "3:00:00 PM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "5/2/2024 15:15",
    "plant_id": "Green-tek-A18697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "3:15:00 PM",
    "power": 0.14,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "5/2/2024 15:30",
    "plant_id": "Green-tek-A18698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "3:30:00 PM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "5/2/2024 15:45",
    "plant_id": "Green-tek-A18699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "3:45:00 PM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "5/2/2024 16:00",
    "plant_id": "Green-tek-A18700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "4:00:00 PM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "5/2/2024 16:15",
    "plant_id": "Green-tek-A18701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/2/2024 16:30",
    "plant_id": "Green-tek-A18702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/2/2024 16:45",
    "plant_id": "Green-tek-A18703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/2/2024 17:00",
    "plant_id": "Green-tek-A18704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/2/2024 17:15",
    "plant_id": "Green-tek-A18705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/2/2024 17:30",
    "plant_id": "Green-tek-A18706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/2/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/3/2024 6:00",
    "plant_id": "Green-tek-A18707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/3/2024 6:15",
    "plant_id": "Green-tek-A18708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/3/2024 6:30",
    "plant_id": "Green-tek-A18709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/3/2024 6:45",
    "plant_id": "Green-tek-A18710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/3/2024 7:00",
    "plant_id": "Green-tek-A18711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/3/2024 7:15",
    "plant_id": "Green-tek-A18712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/3/2024 7:30",
    "plant_id": "Green-tek-A18713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/3/2024 7:45",
    "plant_id": "Green-tek-A18714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "7:45:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/3/2024 8:00",
    "plant_id": "Green-tek-A18715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "8:00:00 AM",
    "power": 0.09,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/3/2024 8:15",
    "plant_id": "Green-tek-A18716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "8:15:00 AM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/3/2024 8:30",
    "plant_id": "Green-tek-A18717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "8:30:00 AM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "5/3/2024 8:45",
    "plant_id": "Green-tek-A18718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "8:45:00 AM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "5/3/2024 9:00",
    "plant_id": "Green-tek-A18719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "9:00:00 AM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "5/3/2024 9:15",
    "plant_id": "Green-tek-A18720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "9:15:00 AM",
    "power": 0.321,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "5/3/2024 9:30",
    "plant_id": "Green-tek-A18721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "9:30:00 AM",
    "power": 0.389,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "5/3/2024 9:45",
    "plant_id": "Green-tek-A18722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "9:45:00 AM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "5/3/2024 10:00",
    "plant_id": "Green-tek-A18723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "10:00:00 AM",
    "power": 0.54,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "5/3/2024 10:15",
    "plant_id": "Green-tek-A18724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "10:15:00 AM",
    "power": 0.616,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "5/3/2024 10:30",
    "plant_id": "Green-tek-A18725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "10:30:00 AM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "5/3/2024 10:45",
    "plant_id": "Green-tek-A18726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "10:45:00 AM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "5/3/2024 11:00",
    "plant_id": "Green-tek-A18727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "11:00:00 AM",
    "power": 0.812,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "5/3/2024 11:15",
    "plant_id": "Green-tek-A18728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "11:15:00 AM",
    "power": 0.854,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "5/3/2024 11:30",
    "plant_id": "Green-tek-A18729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "11:30:00 AM",
    "power": 0.881,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "5/3/2024 11:45",
    "plant_id": "Green-tek-A18730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "11:45:00 AM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/3/2024 12:00",
    "plant_id": "Green-tek-A18731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "12:00:00 PM",
    "power": 0.881,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "5/3/2024 12:15",
    "plant_id": "Green-tek-A18732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "12:15:00 PM",
    "power": 0.854,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "5/3/2024 12:30",
    "plant_id": "Green-tek-A18733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "12:30:00 PM",
    "power": 0.812,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "5/3/2024 12:45",
    "plant_id": "Green-tek-A18734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "12:45:00 PM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "5/3/2024 13:00",
    "plant_id": "Green-tek-A18735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "1:00:00 PM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "5/3/2024 13:15",
    "plant_id": "Green-tek-A18736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "1:15:00 PM",
    "power": 0.616,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "5/3/2024 13:30",
    "plant_id": "Green-tek-A18737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "1:30:00 PM",
    "power": 0.54,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "5/3/2024 13:45",
    "plant_id": "Green-tek-A18738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "1:45:00 PM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "5/3/2024 14:00",
    "plant_id": "Green-tek-A18739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "2:00:00 PM",
    "power": 0.389,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "5/3/2024 14:15",
    "plant_id": "Green-tek-A18740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "2:15:00 PM",
    "power": 0.321,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "5/3/2024 14:30",
    "plant_id": "Green-tek-A18741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "2:30:00 PM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "5/3/2024 14:45",
    "plant_id": "Green-tek-A18742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "2:45:00 PM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "5/3/2024 15:00",
    "plant_id": "Green-tek-A18743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "3:00:00 PM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "5/3/2024 15:15",
    "plant_id": "Green-tek-A18744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "3:15:00 PM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/3/2024 15:30",
    "plant_id": "Green-tek-A18745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "3:30:00 PM",
    "power": 0.09,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/3/2024 15:45",
    "plant_id": "Green-tek-A18746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "3:45:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/3/2024 16:00",
    "plant_id": "Green-tek-A18747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/3/2024 16:15",
    "plant_id": "Green-tek-A18748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/3/2024 16:30",
    "plant_id": "Green-tek-A18749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/3/2024 16:45",
    "plant_id": "Green-tek-A18750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/3/2024 17:00",
    "plant_id": "Green-tek-A18751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/3/2024 17:15",
    "plant_id": "Green-tek-A18752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/3/2024 17:30",
    "plant_id": "Green-tek-A18753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/3/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/4/2024 6:00",
    "plant_id": "Green-tek-A18754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/4/2024 6:15",
    "plant_id": "Green-tek-A18755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/4/2024 6:30",
    "plant_id": "Green-tek-A18756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/4/2024 6:45",
    "plant_id": "Green-tek-A18757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/4/2024 7:00",
    "plant_id": "Green-tek-A18758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/4/2024 7:15",
    "plant_id": "Green-tek-A18759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/4/2024 7:30",
    "plant_id": "Green-tek-A18760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/4/2024 7:45",
    "plant_id": "Green-tek-A18761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "7:45:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/4/2024 8:00",
    "plant_id": "Green-tek-A18762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "8:00:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "5/4/2024 8:15",
    "plant_id": "Green-tek-A18763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "8:15:00 AM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "5/4/2024 8:30",
    "plant_id": "Green-tek-A18764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "8:30:00 AM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "5/4/2024 8:45",
    "plant_id": "Green-tek-A18765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "8:45:00 AM",
    "power": 0.285,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "5/4/2024 9:00",
    "plant_id": "Green-tek-A18766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "9:00:00 AM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "5/4/2024 9:15",
    "plant_id": "Green-tek-A18767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "9:15:00 AM",
    "power": 0.446,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "5/4/2024 9:30",
    "plant_id": "Green-tek-A18768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "9:30:00 AM",
    "power": 0.542,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "5/4/2024 9:45",
    "plant_id": "Green-tek-A18769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "9:45:00 AM",
    "power": 0.645,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "5/4/2024 10:00",
    "plant_id": "Green-tek-A18770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "10:00:00 AM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "5/4/2024 10:15",
    "plant_id": "Green-tek-A18771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "10:15:00 AM",
    "power": 0.858,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "5/4/2024 10:30",
    "plant_id": "Green-tek-A18772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "10:30:00 AM",
    "power": 0.96,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "5/4/2024 10:45",
    "plant_id": "Green-tek-A18773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "10:45:00 AM",
    "power": 1.052,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "5/4/2024 11:00",
    "plant_id": "Green-tek-A18774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "11:00:00 AM",
    "power": 1.13,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "5/4/2024 11:15",
    "plant_id": "Green-tek-A18775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "11:15:00 AM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "5/4/2024 11:30",
    "plant_id": "Green-tek-A18776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "11:30:00 AM",
    "power": 1.226,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "5/4/2024 11:45",
    "plant_id": "Green-tek-A18777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "11:45:00 AM",
    "power": 1.239,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "5/4/2024 12:00",
    "plant_id": "Green-tek-A18778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "12:00:00 PM",
    "power": 1.226,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "5/4/2024 12:15",
    "plant_id": "Green-tek-A18779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "12:15:00 PM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "5/4/2024 12:30",
    "plant_id": "Green-tek-A18780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "12:30:00 PM",
    "power": 1.13,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "5/4/2024 12:45",
    "plant_id": "Green-tek-A18781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "12:45:00 PM",
    "power": 1.052,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "5/4/2024 13:00",
    "plant_id": "Green-tek-A18782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "1:00:00 PM",
    "power": 0.96,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "5/4/2024 13:15",
    "plant_id": "Green-tek-A18783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "1:15:00 PM",
    "power": 0.858,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "5/4/2024 13:30",
    "plant_id": "Green-tek-A18784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "1:30:00 PM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "5/4/2024 13:45",
    "plant_id": "Green-tek-A18785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "1:45:00 PM",
    "power": 0.645,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "5/4/2024 14:00",
    "plant_id": "Green-tek-A18786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "2:00:00 PM",
    "power": 0.542,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "5/4/2024 14:15",
    "plant_id": "Green-tek-A18787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "2:15:00 PM",
    "power": 0.446,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "5/4/2024 14:30",
    "plant_id": "Green-tek-A18788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "2:30:00 PM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "5/4/2024 14:45",
    "plant_id": "Green-tek-A18789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "2:45:00 PM",
    "power": 0.285,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "5/4/2024 15:00",
    "plant_id": "Green-tek-A18790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "3:00:00 PM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "5/4/2024 15:15",
    "plant_id": "Green-tek-A18791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "3:15:00 PM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "5/4/2024 15:30",
    "plant_id": "Green-tek-A18792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "3:30:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "5/4/2024 15:45",
    "plant_id": "Green-tek-A18793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "3:45:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/4/2024 16:00",
    "plant_id": "Green-tek-A18794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/4/2024 16:15",
    "plant_id": "Green-tek-A18795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/4/2024 16:30",
    "plant_id": "Green-tek-A18796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/4/2024 16:45",
    "plant_id": "Green-tek-A18797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/4/2024 17:00",
    "plant_id": "Green-tek-A18798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/4/2024 17:15",
    "plant_id": "Green-tek-A18799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/4/2024 17:30",
    "plant_id": "Green-tek-A18800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/4/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/5/2024 6:00",
    "plant_id": "Green-tek-A18801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/5/2024 6:15",
    "plant_id": "Green-tek-A18802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/5/2024 6:30",
    "plant_id": "Green-tek-A18803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/5/2024 6:45",
    "plant_id": "Green-tek-A18804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/5/2024 7:00",
    "plant_id": "Green-tek-A18805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/5/2024 7:15",
    "plant_id": "Green-tek-A18806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/5/2024 7:30",
    "plant_id": "Green-tek-A18807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/5/2024 7:45",
    "plant_id": "Green-tek-A18808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "7:45:00 AM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/5/2024 8:00",
    "plant_id": "Green-tek-A18809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "8:00:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/5/2024 8:15",
    "plant_id": "Green-tek-A18810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "8:15:00 AM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/5/2024 8:30",
    "plant_id": "Green-tek-A18811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "8:30:00 AM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "5/5/2024 8:45",
    "plant_id": "Green-tek-A18812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "8:45:00 AM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/5/2024 9:00",
    "plant_id": "Green-tek-A18813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "9:00:00 AM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "5/5/2024 9:15",
    "plant_id": "Green-tek-A18814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "9:15:00 AM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "5/5/2024 9:30",
    "plant_id": "Green-tek-A18815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "9:30:00 AM",
    "power": 0.562,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "5/5/2024 9:45",
    "plant_id": "Green-tek-A18816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "9:45:00 AM",
    "power": 0.668,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "5/5/2024 10:00",
    "plant_id": "Green-tek-A18817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "10:00:00 AM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "5/5/2024 10:15",
    "plant_id": "Green-tek-A18818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "10:15:00 AM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/5/2024 10:30",
    "plant_id": "Green-tek-A18819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "10:30:00 AM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "5/5/2024 10:45",
    "plant_id": "Green-tek-A18820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "10:45:00 AM",
    "power": 1.091,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "5/5/2024 11:00",
    "plant_id": "Green-tek-A18821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "11:00:00 AM",
    "power": 1.172,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "5/5/2024 11:15",
    "plant_id": "Green-tek-A18822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "11:15:00 AM",
    "power": 1.233,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "5/5/2024 11:30",
    "plant_id": "Green-tek-A18823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "11:30:00 AM",
    "power": 1.271,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "5/5/2024 11:45",
    "plant_id": "Green-tek-A18824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "11:45:00 AM",
    "power": 1.284,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "5/5/2024 12:00",
    "plant_id": "Green-tek-A18825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "12:00:00 PM",
    "power": 1.271,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "5/5/2024 12:15",
    "plant_id": "Green-tek-A18826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "12:15:00 PM",
    "power": 1.233,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "5/5/2024 12:30",
    "plant_id": "Green-tek-A18827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "12:30:00 PM",
    "power": 1.172,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "5/5/2024 12:45",
    "plant_id": "Green-tek-A18828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "12:45:00 PM",
    "power": 1.091,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "5/5/2024 13:00",
    "plant_id": "Green-tek-A18829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "1:00:00 PM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "5/5/2024 13:15",
    "plant_id": "Green-tek-A18830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "1:15:00 PM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/5/2024 13:30",
    "plant_id": "Green-tek-A18831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "1:30:00 PM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "5/5/2024 13:45",
    "plant_id": "Green-tek-A18832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "1:45:00 PM",
    "power": 0.668,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "5/5/2024 14:00",
    "plant_id": "Green-tek-A18833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "2:00:00 PM",
    "power": 0.562,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "5/5/2024 14:15",
    "plant_id": "Green-tek-A18834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "2:15:00 PM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "5/5/2024 14:30",
    "plant_id": "Green-tek-A18835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "2:30:00 PM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "5/5/2024 14:45",
    "plant_id": "Green-tek-A18836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "2:45:00 PM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/5/2024 15:00",
    "plant_id": "Green-tek-A18837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "3:00:00 PM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "5/5/2024 15:15",
    "plant_id": "Green-tek-A18838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "3:15:00 PM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/5/2024 15:30",
    "plant_id": "Green-tek-A18839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "3:30:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/5/2024 15:45",
    "plant_id": "Green-tek-A18840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "3:45:00 PM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/5/2024 16:00",
    "plant_id": "Green-tek-A18841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/5/2024 16:15",
    "plant_id": "Green-tek-A18842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/5/2024 16:30",
    "plant_id": "Green-tek-A18843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/5/2024 16:45",
    "plant_id": "Green-tek-A18844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/5/2024 17:00",
    "plant_id": "Green-tek-A18845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/5/2024 17:15",
    "plant_id": "Green-tek-A18846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/5/2024 17:30",
    "plant_id": "Green-tek-A18847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/5/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/6/2024 6:00",
    "plant_id": "Green-tek-A18848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/6/2024 6:15",
    "plant_id": "Green-tek-A18849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/6/2024 6:30",
    "plant_id": "Green-tek-A18850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "6:30:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/6/2024 6:45",
    "plant_id": "Green-tek-A18851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/6/2024 7:00",
    "plant_id": "Green-tek-A18852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/6/2024 7:15",
    "plant_id": "Green-tek-A18853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/6/2024 7:30",
    "plant_id": "Green-tek-A18854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "7:30:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/6/2024 7:45",
    "plant_id": "Green-tek-A18855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "7:45:00 AM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/6/2024 8:00",
    "plant_id": "Green-tek-A18856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "8:00:00 AM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/6/2024 8:15",
    "plant_id": "Green-tek-A18857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "8:15:00 AM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/6/2024 8:30",
    "plant_id": "Green-tek-A18858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "8:30:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "5/6/2024 8:45",
    "plant_id": "Green-tek-A18859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "8:45:00 AM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "5/6/2024 9:00",
    "plant_id": "Green-tek-A18860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "9:00:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "5/6/2024 9:15",
    "plant_id": "Green-tek-A18861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "9:15:00 AM",
    "power": 0.476,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "5/6/2024 9:30",
    "plant_id": "Green-tek-A18862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "9:30:00 AM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "5/6/2024 9:45",
    "plant_id": "Green-tek-A18863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "9:45:00 AM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "5/6/2024 10:00",
    "plant_id": "Green-tek-A18864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "10:00:00 AM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "5/6/2024 10:15",
    "plant_id": "Green-tek-A18865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "10:15:00 AM",
    "power": 0.914,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "5/6/2024 10:30",
    "plant_id": "Green-tek-A18866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "10:30:00 AM",
    "power": 1.022,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "5/6/2024 10:45",
    "plant_id": "Green-tek-A18867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "10:45:00 AM",
    "power": 1.121,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "5/6/2024 11:00",
    "plant_id": "Green-tek-A18868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "11:00:00 AM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "5/6/2024 11:15",
    "plant_id": "Green-tek-A18869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "11:15:00 AM",
    "power": 1.267,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.33354,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "5/6/2024 11:30",
    "plant_id": "Green-tek-A18870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "11:30:00 AM",
    "power": 1.306,
    "Energy": 0.327,
    "output Energy": 0.327,
    "Ac output": 1.327,
    "dc output": 1.35354,
    "AC_R_i": 0.00042652,
    "AC_S_i": 0.000469172,
    "AC_T_i": 0.00051609,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0980996,
    "AC_S_pow": 0.10790956,
    "AC_T_Pow": 0.1187007,
    "AC_R_output": 0.3981,
    "AC_S_output": 0.43791,
    "AC_T_output": 0.481701,
    "dc_current": 0.00240441,
    "dc_voltage": 136,
    "dc_power": 0.3366,
    "dc_output": 0.33354,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000327,
    "carbon offset": 0.40875,
    "trees planted": 9.083333333,
    "DC_R_output": 0.131373,
    "DC_S_output": 0.1445103,
    "DC_T_output": 0.15896133
  },
  {
    "datetime": "5/6/2024 11:45",
    "plant_id": "Green-tek-A18871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "11:45:00 AM",
    "power": 1.32,
    "Energy": 0.33,
    "output Energy": 0.33,
    "Ac output": 1.33,
    "dc output": 1.3566,
    "AC_R_i": 0.00043043,
    "AC_S_i": 0.000473473,
    "AC_T_i": 0.00052082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0989989,
    "AC_S_pow": 0.10889879,
    "AC_T_Pow": 0.1197886,
    "AC_R_output": 0.399,
    "AC_S_output": 0.4389,
    "AC_T_output": 0.48279,
    "dc_current": 0.00242647,
    "dc_voltage": 136,
    "dc_power": 0.33354,
    "dc_output": 0.3366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00033,
    "carbon offset": 0.4125,
    "trees planted": 9.166666667,
    "DC_R_output": 0.13167,
    "DC_S_output": 0.144837,
    "DC_T_output": 0.1593207
  },
  {
    "datetime": "5/6/2024 12:00",
    "plant_id": "Green-tek-A18872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "12:00:00 PM",
    "power": 1.306,
    "Energy": 0.327,
    "output Energy": 0.327,
    "Ac output": 1.327,
    "dc output": 1.35354,
    "AC_R_i": 0.00042652,
    "AC_S_i": 0.000469172,
    "AC_T_i": 0.00051609,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0980996,
    "AC_S_pow": 0.10790956,
    "AC_T_Pow": 0.1187007,
    "AC_R_output": 0.3981,
    "AC_S_output": 0.43791,
    "AC_T_output": 0.481701,
    "dc_current": 0.00240441,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.33354,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000327,
    "carbon offset": 0.40875,
    "trees planted": 9.083333333,
    "DC_R_output": 0.131373,
    "DC_S_output": 0.1445103,
    "DC_T_output": 0.15896133
  },
  {
    "datetime": "5/6/2024 12:15",
    "plant_id": "Green-tek-A18873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "12:15:00 PM",
    "power": 1.267,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "5/6/2024 12:30",
    "plant_id": "Green-tek-A18874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "12:30:00 PM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "5/6/2024 12:45",
    "plant_id": "Green-tek-A18875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "12:45:00 PM",
    "power": 1.121,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "5/6/2024 13:00",
    "plant_id": "Green-tek-A18876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "1:00:00 PM",
    "power": 1.022,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "5/6/2024 13:15",
    "plant_id": "Green-tek-A18877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "1:15:00 PM",
    "power": 0.914,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "5/6/2024 13:30",
    "plant_id": "Green-tek-A18878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "1:30:00 PM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "5/6/2024 13:45",
    "plant_id": "Green-tek-A18879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "1:45:00 PM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "5/6/2024 14:00",
    "plant_id": "Green-tek-A18880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "2:00:00 PM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "5/6/2024 14:15",
    "plant_id": "Green-tek-A18881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "2:15:00 PM",
    "power": 0.476,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "5/6/2024 14:30",
    "plant_id": "Green-tek-A18882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "2:30:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "5/6/2024 14:45",
    "plant_id": "Green-tek-A18883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "2:45:00 PM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "5/6/2024 15:00",
    "plant_id": "Green-tek-A18884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "3:00:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "5/6/2024 15:15",
    "plant_id": "Green-tek-A18885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "3:15:00 PM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/6/2024 15:30",
    "plant_id": "Green-tek-A18886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "3:30:00 PM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/6/2024 15:45",
    "plant_id": "Green-tek-A18887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "3:45:00 PM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/6/2024 16:00",
    "plant_id": "Green-tek-A18888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "4:00:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/6/2024 16:15",
    "plant_id": "Green-tek-A18889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/6/2024 16:30",
    "plant_id": "Green-tek-A18890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/6/2024 16:45",
    "plant_id": "Green-tek-A18891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/6/2024 17:00",
    "plant_id": "Green-tek-A18892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "5:00:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/6/2024 17:15",
    "plant_id": "Green-tek-A18893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/6/2024 17:30",
    "plant_id": "Green-tek-A18894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/6/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/7/2024 6:00",
    "plant_id": "Green-tek-A18895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/7/2024 6:15",
    "plant_id": "Green-tek-A18896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/7/2024 6:30",
    "plant_id": "Green-tek-A18897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/7/2024 6:45",
    "plant_id": "Green-tek-A18898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/7/2024 7:00",
    "plant_id": "Green-tek-A18899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/7/2024 7:15",
    "plant_id": "Green-tek-A18900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/7/2024 7:30",
    "plant_id": "Green-tek-A18901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/7/2024 7:45",
    "plant_id": "Green-tek-A18902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "7:45:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/7/2024 8:00",
    "plant_id": "Green-tek-A18903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "8:00:00 AM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/7/2024 8:15",
    "plant_id": "Green-tek-A18904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "8:15:00 AM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/7/2024 8:30",
    "plant_id": "Green-tek-A18905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "8:30:00 AM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "5/7/2024 8:45",
    "plant_id": "Green-tek-A18906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "8:45:00 AM",
    "power": 0.206,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "5/7/2024 9:00",
    "plant_id": "Green-tek-A18907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "9:00:00 AM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "5/7/2024 9:15",
    "plant_id": "Green-tek-A18908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "9:15:00 AM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "5/7/2024 9:30",
    "plant_id": "Green-tek-A18909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "9:30:00 AM",
    "power": 0.392,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "5/7/2024 9:45",
    "plant_id": "Green-tek-A18910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "9:45:00 AM",
    "power": 0.466,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "5/7/2024 10:00",
    "plant_id": "Green-tek-A18911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "10:00:00 AM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "5/7/2024 10:15",
    "plant_id": "Green-tek-A18912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "10:15:00 AM",
    "power": 0.621,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "5/7/2024 10:30",
    "plant_id": "Green-tek-A18913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "10:30:00 AM",
    "power": 0.695,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "5/7/2024 10:45",
    "plant_id": "Green-tek-A18914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "10:45:00 AM",
    "power": 0.761,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "5/7/2024 11:00",
    "plant_id": "Green-tek-A18915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "11:00:00 AM",
    "power": 0.818,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "5/7/2024 11:15",
    "plant_id": "Green-tek-A18916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "11:15:00 AM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "5/7/2024 11:30",
    "plant_id": "Green-tek-A18917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "11:30:00 AM",
    "power": 0.887,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/7/2024 11:45",
    "plant_id": "Green-tek-A18918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "11:45:00 AM",
    "power": 0.896,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "5/7/2024 12:00",
    "plant_id": "Green-tek-A18919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "12:00:00 PM",
    "power": 0.887,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/7/2024 12:15",
    "plant_id": "Green-tek-A18920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "12:15:00 PM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "5/7/2024 12:30",
    "plant_id": "Green-tek-A18921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "12:30:00 PM",
    "power": 0.818,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "5/7/2024 12:45",
    "plant_id": "Green-tek-A18922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "12:45:00 PM",
    "power": 0.761,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "5/7/2024 13:00",
    "plant_id": "Green-tek-A18923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "1:00:00 PM",
    "power": 0.695,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "5/7/2024 13:15",
    "plant_id": "Green-tek-A18924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "1:15:00 PM",
    "power": 0.621,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "5/7/2024 13:30",
    "plant_id": "Green-tek-A18925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "1:30:00 PM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "5/7/2024 13:45",
    "plant_id": "Green-tek-A18926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "1:45:00 PM",
    "power": 0.466,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "5/7/2024 14:00",
    "plant_id": "Green-tek-A18927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "2:00:00 PM",
    "power": 0.392,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "5/7/2024 14:15",
    "plant_id": "Green-tek-A18928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "2:15:00 PM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "5/7/2024 14:30",
    "plant_id": "Green-tek-A18929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "2:30:00 PM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "5/7/2024 14:45",
    "plant_id": "Green-tek-A18930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "2:45:00 PM",
    "power": 0.206,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "5/7/2024 15:00",
    "plant_id": "Green-tek-A18931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "3:00:00 PM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "5/7/2024 15:15",
    "plant_id": "Green-tek-A18932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "3:15:00 PM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/7/2024 15:30",
    "plant_id": "Green-tek-A18933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "3:30:00 PM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/7/2024 15:45",
    "plant_id": "Green-tek-A18934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "3:45:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/7/2024 16:00",
    "plant_id": "Green-tek-A18935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/7/2024 16:15",
    "plant_id": "Green-tek-A18936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/7/2024 16:30",
    "plant_id": "Green-tek-A18937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/7/2024 16:45",
    "plant_id": "Green-tek-A18938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/7/2024 17:00",
    "plant_id": "Green-tek-A18939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/7/2024 17:15",
    "plant_id": "Green-tek-A18940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/7/2024 17:30",
    "plant_id": "Green-tek-A18941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/7/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/8/2024 6:00",
    "plant_id": "Green-tek-A18942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/8/2024 6:15",
    "plant_id": "Green-tek-A18943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/8/2024 6:30",
    "plant_id": "Green-tek-A18944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/8/2024 6:45",
    "plant_id": "Green-tek-A18945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/8/2024 7:00",
    "plant_id": "Green-tek-A18946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/8/2024 7:15",
    "plant_id": "Green-tek-A18947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/8/2024 7:30",
    "plant_id": "Green-tek-A18948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/8/2024 7:45",
    "plant_id": "Green-tek-A18949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/8/2024 8:00",
    "plant_id": "Green-tek-A18950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "8:00:00 AM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/8/2024 8:15",
    "plant_id": "Green-tek-A18951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "8:15:00 AM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "5/8/2024 8:30",
    "plant_id": "Green-tek-A18952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "8:30:00 AM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "5/8/2024 8:45",
    "plant_id": "Green-tek-A18953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "8:45:00 AM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "5/8/2024 9:00",
    "plant_id": "Green-tek-A18954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "9:00:00 AM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "5/8/2024 9:15",
    "plant_id": "Green-tek-A18955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "9:15:00 AM",
    "power": 0.437,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "5/8/2024 9:30",
    "plant_id": "Green-tek-A18956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "9:30:00 AM",
    "power": 0.53,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "5/8/2024 9:45",
    "plant_id": "Green-tek-A18957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "9:45:00 AM",
    "power": 0.631,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "5/8/2024 10:00",
    "plant_id": "Green-tek-A18958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "10:00:00 AM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "5/8/2024 10:15",
    "plant_id": "Green-tek-A18959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "10:15:00 AM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/8/2024 10:30",
    "plant_id": "Green-tek-A18960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "10:30:00 AM",
    "power": 0.939,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "5/8/2024 10:45",
    "plant_id": "Green-tek-A18961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "10:45:00 AM",
    "power": 1.029,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "5/8/2024 11:00",
    "plant_id": "Green-tek-A18962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "11:00:00 AM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "5/8/2024 11:15",
    "plant_id": "Green-tek-A18963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "11:15:00 AM",
    "power": 1.163,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "5/8/2024 11:30",
    "plant_id": "Green-tek-A18964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "11:30:00 AM",
    "power": 1.199,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "5/8/2024 11:45",
    "plant_id": "Green-tek-A18965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "11:45:00 AM",
    "power": 1.211,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "5/8/2024 12:00",
    "plant_id": "Green-tek-A18966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "12:00:00 PM",
    "power": 1.199,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "5/8/2024 12:15",
    "plant_id": "Green-tek-A18967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "12:15:00 PM",
    "power": 1.163,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "5/8/2024 12:30",
    "plant_id": "Green-tek-A18968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "12:30:00 PM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "5/8/2024 12:45",
    "plant_id": "Green-tek-A18969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "12:45:00 PM",
    "power": 1.029,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "5/8/2024 13:00",
    "plant_id": "Green-tek-A18970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "1:00:00 PM",
    "power": 0.939,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "5/8/2024 13:15",
    "plant_id": "Green-tek-A18971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "1:15:00 PM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/8/2024 13:30",
    "plant_id": "Green-tek-A18972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "1:30:00 PM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "5/8/2024 13:45",
    "plant_id": "Green-tek-A18973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "1:45:00 PM",
    "power": 0.631,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "5/8/2024 14:00",
    "plant_id": "Green-tek-A18974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "2:00:00 PM",
    "power": 0.53,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "5/8/2024 14:15",
    "plant_id": "Green-tek-A18975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "2:15:00 PM",
    "power": 0.437,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "5/8/2024 14:30",
    "plant_id": "Green-tek-A18976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "2:30:00 PM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "5/8/2024 14:45",
    "plant_id": "Green-tek-A18977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "2:45:00 PM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "5/8/2024 15:00",
    "plant_id": "Green-tek-A18978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "3:00:00 PM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "5/8/2024 15:15",
    "plant_id": "Green-tek-A18979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "3:15:00 PM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "5/8/2024 15:30",
    "plant_id": "Green-tek-A18980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "3:30:00 PM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/8/2024 15:45",
    "plant_id": "Green-tek-A18981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/8/2024 16:00",
    "plant_id": "Green-tek-A18982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/8/2024 16:15",
    "plant_id": "Green-tek-A18983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/8/2024 16:30",
    "plant_id": "Green-tek-A18984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/8/2024 16:45",
    "plant_id": "Green-tek-A18985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/8/2024 17:00",
    "plant_id": "Green-tek-A18986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/8/2024 17:15",
    "plant_id": "Green-tek-A18987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/8/2024 17:30",
    "plant_id": "Green-tek-A18988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/8/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/9/2024 6:00",
    "plant_id": "Green-tek-A18989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/9/2024 6:15",
    "plant_id": "Green-tek-A18990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/9/2024 6:30",
    "plant_id": "Green-tek-A18991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/9/2024 6:45",
    "plant_id": "Green-tek-A18992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/9/2024 7:00",
    "plant_id": "Green-tek-A18993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/9/2024 7:15",
    "plant_id": "Green-tek-A18994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "5/9/2024 7:30",
    "plant_id": "Green-tek-A18995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "5/9/2024 7:45",
    "plant_id": "Green-tek-A18996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "7:45:00 AM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "5/9/2024 8:00",
    "plant_id": "Green-tek-A18997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "8:00:00 AM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "5/9/2024 8:15",
    "plant_id": "Green-tek-A18998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "8:15:00 AM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "5/9/2024 8:30",
    "plant_id": "Green-tek-A18999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "8:30:00 AM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "5/9/2024 8:45",
    "plant_id": "Green-tek-A19000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "8:45:00 AM",
    "power": 0.249,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "5/9/2024 9:00",
    "plant_id": "Green-tek-A19001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "9:00:00 AM",
    "power": 0.314,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "5/9/2024 9:15",
    "plant_id": "Green-tek-A19002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "9:15:00 AM",
    "power": 0.39,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "5/9/2024 9:30",
    "plant_id": "Green-tek-A19003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "9:30:00 AM",
    "power": 0.473,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "5/9/2024 9:45",
    "plant_id": "Green-tek-A19004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "9:45:00 AM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "5/9/2024 10:00",
    "plant_id": "Green-tek-A19005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "10:00:00 AM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "5/9/2024 10:15",
    "plant_id": "Green-tek-A19006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "10:15:00 AM",
    "power": 0.748,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "5/9/2024 10:30",
    "plant_id": "Green-tek-A19007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "10:30:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "5/9/2024 10:45",
    "plant_id": "Green-tek-A19008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "10:45:00 AM",
    "power": 0.918,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "5/9/2024 11:00",
    "plant_id": "Green-tek-A19009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "11:00:00 AM",
    "power": 0.986,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "5/9/2024 11:15",
    "plant_id": "Green-tek-A19010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "11:15:00 AM",
    "power": 1.038,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "5/9/2024 11:30",
    "plant_id": "Green-tek-A19011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "11:30:00 AM",
    "power": 1.07,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "5/9/2024 11:45",
    "plant_id": "Green-tek-A19012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "11:45:00 AM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "5/9/2024 12:00",
    "plant_id": "Green-tek-A19013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "12:00:00 PM",
    "power": 1.07,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "5/9/2024 12:15",
    "plant_id": "Green-tek-A19014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "12:15:00 PM",
    "power": 1.038,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "5/9/2024 12:30",
    "plant_id": "Green-tek-A19015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "12:30:00 PM",
    "power": 0.986,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "5/9/2024 12:45",
    "plant_id": "Green-tek-A19016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "12:45:00 PM",
    "power": 0.918,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "5/9/2024 13:00",
    "plant_id": "Green-tek-A19017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "1:00:00 PM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "5/9/2024 13:15",
    "plant_id": "Green-tek-A19018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "1:15:00 PM",
    "power": 0.748,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "5/9/2024 13:30",
    "plant_id": "Green-tek-A19019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "1:30:00 PM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "5/9/2024 13:45",
    "plant_id": "Green-tek-A19020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "1:45:00 PM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "5/9/2024 14:00",
    "plant_id": "Green-tek-A19021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "2:00:00 PM",
    "power": 0.473,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "5/9/2024 14:15",
    "plant_id": "Green-tek-A19022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "2:15:00 PM",
    "power": 0.39,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "5/9/2024 14:30",
    "plant_id": "Green-tek-A19023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "2:30:00 PM",
    "power": 0.314,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "5/9/2024 14:45",
    "plant_id": "Green-tek-A19024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "2:45:00 PM",
    "power": 0.249,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "5/9/2024 15:00",
    "plant_id": "Green-tek-A19025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "3:00:00 PM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "5/9/2024 15:15",
    "plant_id": "Green-tek-A19026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "3:15:00 PM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "5/9/2024 15:30",
    "plant_id": "Green-tek-A19027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "3:30:00 PM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "5/9/2024 15:45",
    "plant_id": "Green-tek-A19028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "3:45:00 PM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "5/9/2024 16:00",
    "plant_id": "Green-tek-A19029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "5/9/2024 16:15",
    "plant_id": "Green-tek-A19030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "5/9/2024 16:30",
    "plant_id": "Green-tek-A19031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/9/2024 16:45",
    "plant_id": "Green-tek-A19032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/9/2024 17:00",
    "plant_id": "Green-tek-A19033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/9/2024 17:15",
    "plant_id": "Green-tek-A19034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/9/2024 17:30",
    "plant_id": "Green-tek-A19035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/9/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/10/2024 6:00",
    "plant_id": "Green-tek-A19036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/10/2024 6:15",
    "plant_id": "Green-tek-A19037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/10/2024 6:30",
    "plant_id": "Green-tek-A19038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/10/2024 6:45",
    "plant_id": "Green-tek-A19039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/10/2024 7:00",
    "plant_id": "Green-tek-A19040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/10/2024 7:15",
    "plant_id": "Green-tek-A19041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/10/2024 7:30",
    "plant_id": "Green-tek-A19042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/10/2024 7:45",
    "plant_id": "Green-tek-A19043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/10/2024 8:00",
    "plant_id": "Green-tek-A19044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "8:00:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/10/2024 8:15",
    "plant_id": "Green-tek-A19045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/10/2024 8:30",
    "plant_id": "Green-tek-A19046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "8:30:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/10/2024 8:45",
    "plant_id": "Green-tek-A19047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "8:45:00 AM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "5/10/2024 9:00",
    "plant_id": "Green-tek-A19048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "9:00:00 AM",
    "power": 0.278,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "5/10/2024 9:15",
    "plant_id": "Green-tek-A19049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "9:15:00 AM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "5/10/2024 9:30",
    "plant_id": "Green-tek-A19050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "9:30:00 AM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "5/10/2024 9:45",
    "plant_id": "Green-tek-A19051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "9:45:00 AM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "5/10/2024 10:00",
    "plant_id": "Green-tek-A19052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "10:00:00 AM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "5/10/2024 10:15",
    "plant_id": "Green-tek-A19053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "10:15:00 AM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "5/10/2024 10:30",
    "plant_id": "Green-tek-A19054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "10:30:00 AM",
    "power": 0.742,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "5/10/2024 10:45",
    "plant_id": "Green-tek-A19055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "10:45:00 AM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "5/10/2024 11:00",
    "plant_id": "Green-tek-A19056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "11:00:00 AM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "5/10/2024 11:15",
    "plant_id": "Green-tek-A19057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "11:15:00 AM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "5/10/2024 11:30",
    "plant_id": "Green-tek-A19058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "11:30:00 AM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "5/10/2024 11:45",
    "plant_id": "Green-tek-A19059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "11:45:00 AM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "5/10/2024 12:00",
    "plant_id": "Green-tek-A19060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "12:00:00 PM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "5/10/2024 12:15",
    "plant_id": "Green-tek-A19061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "12:15:00 PM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "5/10/2024 12:30",
    "plant_id": "Green-tek-A19062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "12:30:00 PM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "5/10/2024 12:45",
    "plant_id": "Green-tek-A19063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "12:45:00 PM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "5/10/2024 13:00",
    "plant_id": "Green-tek-A19064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "1:00:00 PM",
    "power": 0.742,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "5/10/2024 13:15",
    "plant_id": "Green-tek-A19065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "1:15:00 PM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "5/10/2024 13:30",
    "plant_id": "Green-tek-A19066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "1:30:00 PM",
    "power": 0.581,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "5/10/2024 13:45",
    "plant_id": "Green-tek-A19067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "1:45:00 PM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "5/10/2024 14:00",
    "plant_id": "Green-tek-A19068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "2:00:00 PM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "5/10/2024 14:15",
    "plant_id": "Green-tek-A19069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "2:15:00 PM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "5/10/2024 14:30",
    "plant_id": "Green-tek-A19070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "2:30:00 PM",
    "power": 0.278,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "5/10/2024 14:45",
    "plant_id": "Green-tek-A19071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "2:45:00 PM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "5/10/2024 15:00",
    "plant_id": "Green-tek-A19072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "3:00:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/10/2024 15:15",
    "plant_id": "Green-tek-A19073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/10/2024 15:30",
    "plant_id": "Green-tek-A19074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "3:30:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/10/2024 15:45",
    "plant_id": "Green-tek-A19075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/10/2024 16:00",
    "plant_id": "Green-tek-A19076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/10/2024 16:15",
    "plant_id": "Green-tek-A19077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/10/2024 16:30",
    "plant_id": "Green-tek-A19078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/10/2024 16:45",
    "plant_id": "Green-tek-A19079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/10/2024 17:00",
    "plant_id": "Green-tek-A19080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/10/2024 17:15",
    "plant_id": "Green-tek-A19081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/10/2024 17:30",
    "plant_id": "Green-tek-A19082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/10/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/11/2024 6:00",
    "plant_id": "Green-tek-A19083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/11/2024 6:15",
    "plant_id": "Green-tek-A19084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/11/2024 6:30",
    "plant_id": "Green-tek-A19085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/11/2024 6:45",
    "plant_id": "Green-tek-A19086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/11/2024 7:00",
    "plant_id": "Green-tek-A19087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/11/2024 7:15",
    "plant_id": "Green-tek-A19088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/11/2024 7:30",
    "plant_id": "Green-tek-A19089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/11/2024 7:45",
    "plant_id": "Green-tek-A19090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/11/2024 8:00",
    "plant_id": "Green-tek-A19091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "8:00:00 AM",
    "power": 0.13,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/11/2024 8:15",
    "plant_id": "Green-tek-A19092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "8:15:00 AM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "5/11/2024 8:30",
    "plant_id": "Green-tek-A19093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "8:30:00 AM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "5/11/2024 8:45",
    "plant_id": "Green-tek-A19094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "8:45:00 AM",
    "power": 0.298,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/11/2024 9:00",
    "plant_id": "Green-tek-A19095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "9:00:00 AM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "5/11/2024 9:15",
    "plant_id": "Green-tek-A19096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "9:15:00 AM",
    "power": 0.467,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "5/11/2024 9:30",
    "plant_id": "Green-tek-A19097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "9:30:00 AM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "5/11/2024 9:45",
    "plant_id": "Green-tek-A19098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "9:45:00 AM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "5/11/2024 10:00",
    "plant_id": "Green-tek-A19099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "10:00:00 AM",
    "power": 0.786,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "5/11/2024 10:15",
    "plant_id": "Green-tek-A19100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "10:15:00 AM",
    "power": 0.897,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "5/11/2024 10:30",
    "plant_id": "Green-tek-A19101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "10:30:00 AM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "5/11/2024 10:45",
    "plant_id": "Green-tek-A19102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "10:45:00 AM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "5/11/2024 11:00",
    "plant_id": "Green-tek-A19103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "11:00:00 AM",
    "power": 1.181,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "5/11/2024 11:15",
    "plant_id": "Green-tek-A19104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "11:15:00 AM",
    "power": 1.243,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.3264,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "5/11/2024 11:30",
    "plant_id": "Green-tek-A19105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "11:30:00 AM",
    "power": 1.282,
    "Energy": 0.32,
    "output Energy": 0.32,
    "Ac output": 1.32,
    "dc output": 1.3464,
    "AC_R_i": 0.00041739,
    "AC_S_i": 0.000459129,
    "AC_T_i": 0.00050504,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0959997,
    "AC_S_pow": 0.10559967,
    "AC_T_Pow": 0.1161592,
    "AC_R_output": 0.396,
    "AC_S_output": 0.4356,
    "AC_T_output": 0.47916,
    "dc_current": 0.00235294,
    "dc_voltage": 136,
    "dc_power": 0.33048,
    "dc_output": 0.3264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00032,
    "carbon offset": 0.4,
    "trees planted": 8.888888889,
    "DC_R_output": 0.13068,
    "DC_S_output": 0.143748,
    "DC_T_output": 0.1581228
  },
  {
    "datetime": "5/11/2024 11:45",
    "plant_id": "Green-tek-A19106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "11:45:00 AM",
    "power": 1.295,
    "Energy": 0.324,
    "output Energy": 0.324,
    "Ac output": 1.324,
    "dc output": 1.35048,
    "AC_R_i": 0.00042261,
    "AC_S_i": 0.000464871,
    "AC_T_i": 0.00051136,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0972003,
    "AC_S_pow": 0.10692033,
    "AC_T_Pow": 0.1176128,
    "AC_R_output": 0.3972,
    "AC_S_output": 0.43692,
    "AC_T_output": 0.480612,
    "dc_current": 0.00238235,
    "dc_voltage": 136,
    "dc_power": 0.3264,
    "dc_output": 0.33048,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000324,
    "carbon offset": 0.405,
    "trees planted": 9,
    "DC_R_output": 0.131076,
    "DC_S_output": 0.1441836,
    "DC_T_output": 0.15860196
  },
  {
    "datetime": "5/11/2024 12:00",
    "plant_id": "Green-tek-A19107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "12:00:00 PM",
    "power": 1.282,
    "Energy": 0.32,
    "output Energy": 0.32,
    "Ac output": 1.32,
    "dc output": 1.3464,
    "AC_R_i": 0.00041739,
    "AC_S_i": 0.000459129,
    "AC_T_i": 0.00050504,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0959997,
    "AC_S_pow": 0.10559967,
    "AC_T_Pow": 0.1161592,
    "AC_R_output": 0.396,
    "AC_S_output": 0.4356,
    "AC_T_output": 0.47916,
    "dc_current": 0.00235294,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.3264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00032,
    "carbon offset": 0.4,
    "trees planted": 8.888888889,
    "DC_R_output": 0.13068,
    "DC_S_output": 0.143748,
    "DC_T_output": 0.1581228
  },
  {
    "datetime": "5/11/2024 12:15",
    "plant_id": "Green-tek-A19108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "12:15:00 PM",
    "power": 1.243,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "5/11/2024 12:30",
    "plant_id": "Green-tek-A19109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "12:30:00 PM",
    "power": 1.181,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "5/11/2024 12:45",
    "plant_id": "Green-tek-A19110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "12:45:00 PM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "5/11/2024 13:00",
    "plant_id": "Green-tek-A19111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "1:00:00 PM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "5/11/2024 13:15",
    "plant_id": "Green-tek-A19112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "1:15:00 PM",
    "power": 0.897,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "5/11/2024 13:30",
    "plant_id": "Green-tek-A19113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "1:30:00 PM",
    "power": 0.786,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "5/11/2024 13:45",
    "plant_id": "Green-tek-A19114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "1:45:00 PM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "5/11/2024 14:00",
    "plant_id": "Green-tek-A19115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "2:00:00 PM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "5/11/2024 14:15",
    "plant_id": "Green-tek-A19116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "2:15:00 PM",
    "power": 0.467,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "5/11/2024 14:30",
    "plant_id": "Green-tek-A19117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "2:30:00 PM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "5/11/2024 14:45",
    "plant_id": "Green-tek-A19118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "2:45:00 PM",
    "power": 0.298,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/11/2024 15:00",
    "plant_id": "Green-tek-A19119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "3:00:00 PM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "5/11/2024 15:15",
    "plant_id": "Green-tek-A19120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "3:15:00 PM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "5/11/2024 15:30",
    "plant_id": "Green-tek-A19121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "3:30:00 PM",
    "power": 0.13,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/11/2024 15:45",
    "plant_id": "Green-tek-A19122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/11/2024 16:00",
    "plant_id": "Green-tek-A19123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/11/2024 16:15",
    "plant_id": "Green-tek-A19124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/11/2024 16:30",
    "plant_id": "Green-tek-A19125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/11/2024 16:45",
    "plant_id": "Green-tek-A19126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/11/2024 17:00",
    "plant_id": "Green-tek-A19127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/11/2024 17:15",
    "plant_id": "Green-tek-A19128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/11/2024 17:30",
    "plant_id": "Green-tek-A19129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/11/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/12/2024 6:00",
    "plant_id": "Green-tek-A19130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/12/2024 6:15",
    "plant_id": "Green-tek-A19131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/12/2024 6:30",
    "plant_id": "Green-tek-A19132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/12/2024 6:45",
    "plant_id": "Green-tek-A19133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/12/2024 7:00",
    "plant_id": "Green-tek-A19134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/12/2024 7:15",
    "plant_id": "Green-tek-A19135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/12/2024 7:30",
    "plant_id": "Green-tek-A19136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/12/2024 7:45",
    "plant_id": "Green-tek-A19137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "7:45:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/12/2024 8:00",
    "plant_id": "Green-tek-A19138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "8:00:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/12/2024 8:15",
    "plant_id": "Green-tek-A19139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "8:15:00 AM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/12/2024 8:30",
    "plant_id": "Green-tek-A19140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "8:30:00 AM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "5/12/2024 8:45",
    "plant_id": "Green-tek-A19141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "8:45:00 AM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "5/12/2024 9:00",
    "plant_id": "Green-tek-A19142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "9:00:00 AM",
    "power": 0.275,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "5/12/2024 9:15",
    "plant_id": "Green-tek-A19143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "9:15:00 AM",
    "power": 0.34,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "5/12/2024 9:30",
    "plant_id": "Green-tek-A19144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "9:30:00 AM",
    "power": 0.413,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "5/12/2024 9:45",
    "plant_id": "Green-tek-A19145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "9:45:00 AM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "5/12/2024 10:00",
    "plant_id": "Green-tek-A19146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "10:00:00 AM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "5/12/2024 10:15",
    "plant_id": "Green-tek-A19147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "10:15:00 AM",
    "power": 0.654,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "5/12/2024 10:30",
    "plant_id": "Green-tek-A19148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "10:30:00 AM",
    "power": 0.731,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "5/12/2024 10:45",
    "plant_id": "Green-tek-A19149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "10:45:00 AM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "5/12/2024 11:00",
    "plant_id": "Green-tek-A19150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "11:00:00 AM",
    "power": 0.861,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "5/12/2024 11:15",
    "plant_id": "Green-tek-A19151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "11:15:00 AM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "5/12/2024 11:30",
    "plant_id": "Green-tek-A19152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "11:30:00 AM",
    "power": 0.934,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "5/12/2024 11:45",
    "plant_id": "Green-tek-A19153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "11:45:00 AM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "5/12/2024 12:00",
    "plant_id": "Green-tek-A19154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "12:00:00 PM",
    "power": 0.934,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "5/12/2024 12:15",
    "plant_id": "Green-tek-A19155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "12:15:00 PM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "5/12/2024 12:30",
    "plant_id": "Green-tek-A19156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "12:30:00 PM",
    "power": 0.861,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "5/12/2024 12:45",
    "plant_id": "Green-tek-A19157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "12:45:00 PM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "5/12/2024 13:00",
    "plant_id": "Green-tek-A19158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "1:00:00 PM",
    "power": 0.731,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "5/12/2024 13:15",
    "plant_id": "Green-tek-A19159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "1:15:00 PM",
    "power": 0.654,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "5/12/2024 13:30",
    "plant_id": "Green-tek-A19160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "1:30:00 PM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "5/12/2024 13:45",
    "plant_id": "Green-tek-A19161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "1:45:00 PM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "5/12/2024 14:00",
    "plant_id": "Green-tek-A19162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "2:00:00 PM",
    "power": 0.413,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "5/12/2024 14:15",
    "plant_id": "Green-tek-A19163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "2:15:00 PM",
    "power": 0.34,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "5/12/2024 14:30",
    "plant_id": "Green-tek-A19164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "2:30:00 PM",
    "power": 0.275,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "5/12/2024 14:45",
    "plant_id": "Green-tek-A19165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "2:45:00 PM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "5/12/2024 15:00",
    "plant_id": "Green-tek-A19166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "3:00:00 PM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "5/12/2024 15:15",
    "plant_id": "Green-tek-A19167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "3:15:00 PM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/12/2024 15:30",
    "plant_id": "Green-tek-A19168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "3:30:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/12/2024 15:45",
    "plant_id": "Green-tek-A19169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "3:45:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/12/2024 16:00",
    "plant_id": "Green-tek-A19170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/12/2024 16:15",
    "plant_id": "Green-tek-A19171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/12/2024 16:30",
    "plant_id": "Green-tek-A19172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/12/2024 16:45",
    "plant_id": "Green-tek-A19173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/12/2024 17:00",
    "plant_id": "Green-tek-A19174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/12/2024 17:15",
    "plant_id": "Green-tek-A19175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/12/2024 17:30",
    "plant_id": "Green-tek-A19176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/12/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/13/2024 6:00",
    "plant_id": "Green-tek-A19177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/13/2024 6:15",
    "plant_id": "Green-tek-A19178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/13/2024 6:30",
    "plant_id": "Green-tek-A19179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/13/2024 6:45",
    "plant_id": "Green-tek-A19180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/13/2024 7:00",
    "plant_id": "Green-tek-A19181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/13/2024 7:15",
    "plant_id": "Green-tek-A19182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/13/2024 7:30",
    "plant_id": "Green-tek-A19183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/13/2024 7:45",
    "plant_id": "Green-tek-A19184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/13/2024 8:00",
    "plant_id": "Green-tek-A19185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "8:00:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/13/2024 8:15",
    "plant_id": "Green-tek-A19186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "8:15:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/13/2024 8:30",
    "plant_id": "Green-tek-A19187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "8:30:00 AM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "5/13/2024 8:45",
    "plant_id": "Green-tek-A19188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "8:45:00 AM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "5/13/2024 9:00",
    "plant_id": "Green-tek-A19189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "9:00:00 AM",
    "power": 0.367,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "5/13/2024 9:15",
    "plant_id": "Green-tek-A19190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "9:15:00 AM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "5/13/2024 9:30",
    "plant_id": "Green-tek-A19191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "9:30:00 AM",
    "power": 0.552,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "5/13/2024 9:45",
    "plant_id": "Green-tek-A19192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "9:45:00 AM",
    "power": 0.657,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "5/13/2024 10:00",
    "plant_id": "Green-tek-A19193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "10:00:00 AM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "5/13/2024 10:15",
    "plant_id": "Green-tek-A19194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "10:15:00 AM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "5/13/2024 10:30",
    "plant_id": "Green-tek-A19195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "10:30:00 AM",
    "power": 0.978,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "5/13/2024 10:45",
    "plant_id": "Green-tek-A19196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "10:45:00 AM",
    "power": 1.072,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "5/13/2024 11:00",
    "plant_id": "Green-tek-A19197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "11:00:00 AM",
    "power": 1.151,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "5/13/2024 11:15",
    "plant_id": "Green-tek-A19198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "11:15:00 AM",
    "power": 1.211,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "5/13/2024 11:30",
    "plant_id": "Green-tek-A19199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "11:30:00 AM",
    "power": 1.249,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.3213,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "5/13/2024 11:45",
    "plant_id": "Green-tek-A19200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "11:45:00 AM",
    "power": 1.262,
    "Energy": 0.315,
    "output Energy": 0.315,
    "Ac output": 1.315,
    "dc output": 1.3413,
    "AC_R_i": 0.00041087,
    "AC_S_i": 0.000451957,
    "AC_T_i": 0.00049715,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0945001,
    "AC_S_pow": 0.10395011,
    "AC_T_Pow": 0.1143445,
    "AC_R_output": 0.3945,
    "AC_S_output": 0.43395,
    "AC_T_output": 0.477345,
    "dc_current": 0.00231618,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.3213,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000315,
    "carbon offset": 0.39375,
    "trees planted": 8.75,
    "DC_R_output": 0.130185,
    "DC_S_output": 0.1432035,
    "DC_T_output": 0.15752385
  },
  {
    "datetime": "5/13/2024 12:00",
    "plant_id": "Green-tek-A19201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "12:00:00 PM",
    "power": 1.249,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "5/13/2024 12:15",
    "plant_id": "Green-tek-A19202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "12:15:00 PM",
    "power": 1.211,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "5/13/2024 12:30",
    "plant_id": "Green-tek-A19203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "12:30:00 PM",
    "power": 1.151,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "5/13/2024 12:45",
    "plant_id": "Green-tek-A19204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "12:45:00 PM",
    "power": 1.072,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "5/13/2024 13:00",
    "plant_id": "Green-tek-A19205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "1:00:00 PM",
    "power": 0.978,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "5/13/2024 13:15",
    "plant_id": "Green-tek-A19206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "1:15:00 PM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "5/13/2024 13:30",
    "plant_id": "Green-tek-A19207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "1:30:00 PM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "5/13/2024 13:45",
    "plant_id": "Green-tek-A19208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "1:45:00 PM",
    "power": 0.657,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "5/13/2024 14:00",
    "plant_id": "Green-tek-A19209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "2:00:00 PM",
    "power": 0.552,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "5/13/2024 14:15",
    "plant_id": "Green-tek-A19210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "2:15:00 PM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "5/13/2024 14:30",
    "plant_id": "Green-tek-A19211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "2:30:00 PM",
    "power": 0.367,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "5/13/2024 14:45",
    "plant_id": "Green-tek-A19212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "2:45:00 PM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "5/13/2024 15:00",
    "plant_id": "Green-tek-A19213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "3:00:00 PM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "5/13/2024 15:15",
    "plant_id": "Green-tek-A19214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "3:15:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/13/2024 15:30",
    "plant_id": "Green-tek-A19215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "3:30:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/13/2024 15:45",
    "plant_id": "Green-tek-A19216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/13/2024 16:00",
    "plant_id": "Green-tek-A19217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/13/2024 16:15",
    "plant_id": "Green-tek-A19218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/13/2024 16:30",
    "plant_id": "Green-tek-A19219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/13/2024 16:45",
    "plant_id": "Green-tek-A19220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/13/2024 17:00",
    "plant_id": "Green-tek-A19221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/13/2024 17:15",
    "plant_id": "Green-tek-A19222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/13/2024 17:30",
    "plant_id": "Green-tek-A19223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/13/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/14/2024 6:00",
    "plant_id": "Green-tek-A19224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/14/2024 6:15",
    "plant_id": "Green-tek-A19225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/14/2024 6:30",
    "plant_id": "Green-tek-A19226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/14/2024 6:45",
    "plant_id": "Green-tek-A19227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/14/2024 7:00",
    "plant_id": "Green-tek-A19228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/14/2024 7:15",
    "plant_id": "Green-tek-A19229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/14/2024 7:30",
    "plant_id": "Green-tek-A19230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/14/2024 7:45",
    "plant_id": "Green-tek-A19231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "7:45:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/14/2024 8:00",
    "plant_id": "Green-tek-A19232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "8:00:00 AM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/14/2024 8:15",
    "plant_id": "Green-tek-A19233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "8:15:00 AM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "5/14/2024 8:30",
    "plant_id": "Green-tek-A19234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "8:30:00 AM",
    "power": 0.214,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "5/14/2024 8:45",
    "plant_id": "Green-tek-A19235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "8:45:00 AM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "5/14/2024 9:00",
    "plant_id": "Green-tek-A19236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "9:00:00 AM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "5/14/2024 9:15",
    "plant_id": "Green-tek-A19237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "9:15:00 AM",
    "power": 0.433,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "5/14/2024 9:30",
    "plant_id": "Green-tek-A19238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "9:30:00 AM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "5/14/2024 9:45",
    "plant_id": "Green-tek-A19239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "9:45:00 AM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "5/14/2024 10:00",
    "plant_id": "Green-tek-A19240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "10:00:00 AM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "5/14/2024 10:15",
    "plant_id": "Green-tek-A19241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "10:15:00 AM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "5/14/2024 10:30",
    "plant_id": "Green-tek-A19242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "10:30:00 AM",
    "power": 0.93,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "5/14/2024 10:45",
    "plant_id": "Green-tek-A19243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "10:45:00 AM",
    "power": 1.019,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "5/14/2024 11:00",
    "plant_id": "Green-tek-A19244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "11:00:00 AM",
    "power": 1.095,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "5/14/2024 11:15",
    "plant_id": "Green-tek-A19245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "11:15:00 AM",
    "power": 1.152,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "5/14/2024 11:30",
    "plant_id": "Green-tek-A19246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "11:30:00 AM",
    "power": 1.188,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "5/14/2024 11:45",
    "plant_id": "Green-tek-A19247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "11:45:00 AM",
    "power": 1.2,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "5/14/2024 12:00",
    "plant_id": "Green-tek-A19248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "12:00:00 PM",
    "power": 1.188,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "5/14/2024 12:15",
    "plant_id": "Green-tek-A19249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "12:15:00 PM",
    "power": 1.152,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "5/14/2024 12:30",
    "plant_id": "Green-tek-A19250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "12:30:00 PM",
    "power": 1.095,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "5/14/2024 12:45",
    "plant_id": "Green-tek-A19251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "12:45:00 PM",
    "power": 1.019,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "5/14/2024 13:00",
    "plant_id": "Green-tek-A19252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "1:00:00 PM",
    "power": 0.93,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "5/14/2024 13:15",
    "plant_id": "Green-tek-A19253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "1:15:00 PM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "5/14/2024 13:30",
    "plant_id": "Green-tek-A19254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "1:30:00 PM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "5/14/2024 13:45",
    "plant_id": "Green-tek-A19255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "1:45:00 PM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "5/14/2024 14:00",
    "plant_id": "Green-tek-A19256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "2:00:00 PM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "5/14/2024 14:15",
    "plant_id": "Green-tek-A19257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "2:15:00 PM",
    "power": 0.433,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "5/14/2024 14:30",
    "plant_id": "Green-tek-A19258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "2:30:00 PM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "5/14/2024 14:45",
    "plant_id": "Green-tek-A19259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "2:45:00 PM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "5/14/2024 15:00",
    "plant_id": "Green-tek-A19260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "3:00:00 PM",
    "power": 0.214,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "5/14/2024 15:15",
    "plant_id": "Green-tek-A19261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "3:15:00 PM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "5/14/2024 15:30",
    "plant_id": "Green-tek-A19262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "3:30:00 PM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/14/2024 15:45",
    "plant_id": "Green-tek-A19263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "3:45:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/14/2024 16:00",
    "plant_id": "Green-tek-A19264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/14/2024 16:15",
    "plant_id": "Green-tek-A19265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/14/2024 16:30",
    "plant_id": "Green-tek-A19266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/14/2024 16:45",
    "plant_id": "Green-tek-A19267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/14/2024 17:00",
    "plant_id": "Green-tek-A19268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/14/2024 17:15",
    "plant_id": "Green-tek-A19269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/14/2024 17:30",
    "plant_id": "Green-tek-A19270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/14/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/15/2024 6:00",
    "plant_id": "Green-tek-A19271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/15/2024 6:15",
    "plant_id": "Green-tek-A19272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/15/2024 6:30",
    "plant_id": "Green-tek-A19273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/15/2024 6:45",
    "plant_id": "Green-tek-A19274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/15/2024 7:00",
    "plant_id": "Green-tek-A19275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/15/2024 7:15",
    "plant_id": "Green-tek-A19276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/15/2024 7:30",
    "plant_id": "Green-tek-A19277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "5/15/2024 7:45",
    "plant_id": "Green-tek-A19278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "7:45:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "5/15/2024 8:00",
    "plant_id": "Green-tek-A19279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "8:00:00 AM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "5/15/2024 8:15",
    "plant_id": "Green-tek-A19280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "8:15:00 AM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "5/15/2024 8:30",
    "plant_id": "Green-tek-A19281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "8:30:00 AM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "5/15/2024 8:45",
    "plant_id": "Green-tek-A19282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "8:45:00 AM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "5/15/2024 9:00",
    "plant_id": "Green-tek-A19283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "9:00:00 AM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "5/15/2024 9:15",
    "plant_id": "Green-tek-A19284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "9:15:00 AM",
    "power": 0.422,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "5/15/2024 9:30",
    "plant_id": "Green-tek-A19285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "9:30:00 AM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "5/15/2024 9:45",
    "plant_id": "Green-tek-A19286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "9:45:00 AM",
    "power": 0.61,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "5/15/2024 10:00",
    "plant_id": "Green-tek-A19287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "10:00:00 AM",
    "power": 0.71,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "5/15/2024 10:15",
    "plant_id": "Green-tek-A19288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "10:15:00 AM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "5/15/2024 10:30",
    "plant_id": "Green-tek-A19289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "10:30:00 AM",
    "power": 0.908,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "5/15/2024 10:45",
    "plant_id": "Green-tek-A19290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "10:45:00 AM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "5/15/2024 11:00",
    "plant_id": "Green-tek-A19291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "11:00:00 AM",
    "power": 1.069,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "5/15/2024 11:15",
    "plant_id": "Green-tek-A19292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "11:15:00 AM",
    "power": 1.125,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "5/15/2024 11:30",
    "plant_id": "Green-tek-A19293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "11:30:00 AM",
    "power": 1.159,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "5/15/2024 11:45",
    "plant_id": "Green-tek-A19294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "11:45:00 AM",
    "power": 1.171,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "5/15/2024 12:00",
    "plant_id": "Green-tek-A19295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "12:00:00 PM",
    "power": 1.159,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "5/15/2024 12:15",
    "plant_id": "Green-tek-A19296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "12:15:00 PM",
    "power": 1.125,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "5/15/2024 12:30",
    "plant_id": "Green-tek-A19297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "12:30:00 PM",
    "power": 1.069,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "5/15/2024 12:45",
    "plant_id": "Green-tek-A19298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "12:45:00 PM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "5/15/2024 13:00",
    "plant_id": "Green-tek-A19299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "1:00:00 PM",
    "power": 0.908,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "5/15/2024 13:15",
    "plant_id": "Green-tek-A19300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "1:15:00 PM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "5/15/2024 13:30",
    "plant_id": "Green-tek-A19301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "1:30:00 PM",
    "power": 0.71,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "5/15/2024 13:45",
    "plant_id": "Green-tek-A19302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "1:45:00 PM",
    "power": 0.61,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "5/15/2024 14:00",
    "plant_id": "Green-tek-A19303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "2:00:00 PM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "5/15/2024 14:15",
    "plant_id": "Green-tek-A19304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "2:15:00 PM",
    "power": 0.422,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "5/15/2024 14:30",
    "plant_id": "Green-tek-A19305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "2:30:00 PM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "5/15/2024 14:45",
    "plant_id": "Green-tek-A19306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "2:45:00 PM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "5/15/2024 15:00",
    "plant_id": "Green-tek-A19307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "3:00:00 PM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "5/15/2024 15:15",
    "plant_id": "Green-tek-A19308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "3:15:00 PM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "5/15/2024 15:30",
    "plant_id": "Green-tek-A19309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "3:30:00 PM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "5/15/2024 15:45",
    "plant_id": "Green-tek-A19310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "3:45:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "5/15/2024 16:00",
    "plant_id": "Green-tek-A19311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "5/15/2024 16:15",
    "plant_id": "Green-tek-A19312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/15/2024 16:30",
    "plant_id": "Green-tek-A19313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/15/2024 16:45",
    "plant_id": "Green-tek-A19314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/15/2024 17:00",
    "plant_id": "Green-tek-A19315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/15/2024 17:15",
    "plant_id": "Green-tek-A19316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/15/2024 17:30",
    "plant_id": "Green-tek-A19317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/15/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/16/2024 6:00",
    "plant_id": "Green-tek-A19318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/16/2024 6:15",
    "plant_id": "Green-tek-A19319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/16/2024 6:30",
    "plant_id": "Green-tek-A19320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/16/2024 6:45",
    "plant_id": "Green-tek-A19321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/16/2024 7:00",
    "plant_id": "Green-tek-A19322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/16/2024 7:15",
    "plant_id": "Green-tek-A19323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "5/16/2024 7:30",
    "plant_id": "Green-tek-A19324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "5/16/2024 7:45",
    "plant_id": "Green-tek-A19325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "7:45:00 AM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "5/16/2024 8:00",
    "plant_id": "Green-tek-A19326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "8:00:00 AM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "5/16/2024 8:15",
    "plant_id": "Green-tek-A19327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "8:15:00 AM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "5/16/2024 8:30",
    "plant_id": "Green-tek-A19328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "8:30:00 AM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "5/16/2024 8:45",
    "plant_id": "Green-tek-A19329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "8:45:00 AM",
    "power": 0.25,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "5/16/2024 9:00",
    "plant_id": "Green-tek-A19330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "9:00:00 AM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "5/16/2024 9:15",
    "plant_id": "Green-tek-A19331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "9:15:00 AM",
    "power": 0.391,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "5/16/2024 9:30",
    "plant_id": "Green-tek-A19332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "9:30:00 AM",
    "power": 0.475,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "5/16/2024 9:45",
    "plant_id": "Green-tek-A19333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "9:45:00 AM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "5/16/2024 10:00",
    "plant_id": "Green-tek-A19334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "10:00:00 AM",
    "power": 0.658,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "5/16/2024 10:15",
    "plant_id": "Green-tek-A19335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "10:15:00 AM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "5/16/2024 10:30",
    "plant_id": "Green-tek-A19336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "10:30:00 AM",
    "power": 0.84,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/16/2024 10:45",
    "plant_id": "Green-tek-A19337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "10:45:00 AM",
    "power": 0.921,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "5/16/2024 11:00",
    "plant_id": "Green-tek-A19338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "11:00:00 AM",
    "power": 0.99,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "5/16/2024 11:15",
    "plant_id": "Green-tek-A19339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "11:15:00 AM",
    "power": 1.041,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "5/16/2024 11:30",
    "plant_id": "Green-tek-A19340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "11:30:00 AM",
    "power": 1.074,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "5/16/2024 11:45",
    "plant_id": "Green-tek-A19341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "11:45:00 AM",
    "power": 1.085,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "5/16/2024 12:00",
    "plant_id": "Green-tek-A19342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "12:00:00 PM",
    "power": 1.074,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "5/16/2024 12:15",
    "plant_id": "Green-tek-A19343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "12:15:00 PM",
    "power": 1.041,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "5/16/2024 12:30",
    "plant_id": "Green-tek-A19344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "12:30:00 PM",
    "power": 0.99,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "5/16/2024 12:45",
    "plant_id": "Green-tek-A19345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "12:45:00 PM",
    "power": 0.921,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "5/16/2024 13:00",
    "plant_id": "Green-tek-A19346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "1:00:00 PM",
    "power": 0.84,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/16/2024 13:15",
    "plant_id": "Green-tek-A19347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "1:15:00 PM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "5/16/2024 13:30",
    "plant_id": "Green-tek-A19348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "1:30:00 PM",
    "power": 0.658,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "5/16/2024 13:45",
    "plant_id": "Green-tek-A19349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "1:45:00 PM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "5/16/2024 14:00",
    "plant_id": "Green-tek-A19350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "2:00:00 PM",
    "power": 0.475,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "5/16/2024 14:15",
    "plant_id": "Green-tek-A19351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "2:15:00 PM",
    "power": 0.391,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "5/16/2024 14:30",
    "plant_id": "Green-tek-A19352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "2:30:00 PM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "5/16/2024 14:45",
    "plant_id": "Green-tek-A19353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "2:45:00 PM",
    "power": 0.25,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "5/16/2024 15:00",
    "plant_id": "Green-tek-A19354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "3:00:00 PM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "5/16/2024 15:15",
    "plant_id": "Green-tek-A19355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "3:15:00 PM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "5/16/2024 15:30",
    "plant_id": "Green-tek-A19356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "3:30:00 PM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "5/16/2024 15:45",
    "plant_id": "Green-tek-A19357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "3:45:00 PM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "5/16/2024 16:00",
    "plant_id": "Green-tek-A19358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "5/16/2024 16:15",
    "plant_id": "Green-tek-A19359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "5/16/2024 16:30",
    "plant_id": "Green-tek-A19360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/16/2024 16:45",
    "plant_id": "Green-tek-A19361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/16/2024 17:00",
    "plant_id": "Green-tek-A19362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/16/2024 17:15",
    "plant_id": "Green-tek-A19363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/16/2024 17:30",
    "plant_id": "Green-tek-A19364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/16/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/17/2024 6:00",
    "plant_id": "Green-tek-A19365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/17/2024 6:15",
    "plant_id": "Green-tek-A19366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/17/2024 6:30",
    "plant_id": "Green-tek-A19367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/17/2024 6:45",
    "plant_id": "Green-tek-A19368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/17/2024 7:00",
    "plant_id": "Green-tek-A19369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/17/2024 7:15",
    "plant_id": "Green-tek-A19370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/17/2024 7:30",
    "plant_id": "Green-tek-A19371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "7:30:00 AM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/17/2024 7:45",
    "plant_id": "Green-tek-A19372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "7:45:00 AM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/17/2024 8:00",
    "plant_id": "Green-tek-A19373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "8:00:00 AM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/17/2024 8:15",
    "plant_id": "Green-tek-A19374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "8:15:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/17/2024 8:30",
    "plant_id": "Green-tek-A19375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "8:30:00 AM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/17/2024 8:45",
    "plant_id": "Green-tek-A19376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "8:45:00 AM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "5/17/2024 9:00",
    "plant_id": "Green-tek-A19377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "9:00:00 AM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "5/17/2024 9:15",
    "plant_id": "Green-tek-A19378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "9:15:00 AM",
    "power": 0.35,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "5/17/2024 9:30",
    "plant_id": "Green-tek-A19379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "9:30:00 AM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "5/17/2024 9:45",
    "plant_id": "Green-tek-A19380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "9:45:00 AM",
    "power": 0.506,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "5/17/2024 10:00",
    "plant_id": "Green-tek-A19381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "10:00:00 AM",
    "power": 0.589,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "5/17/2024 10:15",
    "plant_id": "Green-tek-A19382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "10:15:00 AM",
    "power": 0.673,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "5/17/2024 10:30",
    "plant_id": "Green-tek-A19383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "10:30:00 AM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "5/17/2024 10:45",
    "plant_id": "Green-tek-A19384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "10:45:00 AM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "5/17/2024 11:00",
    "plant_id": "Green-tek-A19385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "11:00:00 AM",
    "power": 0.886,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/17/2024 11:15",
    "plant_id": "Green-tek-A19386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "11:15:00 AM",
    "power": 0.932,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "5/17/2024 11:30",
    "plant_id": "Green-tek-A19387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "11:30:00 AM",
    "power": 0.961,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "5/17/2024 11:45",
    "plant_id": "Green-tek-A19388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "11:45:00 AM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "5/17/2024 12:00",
    "plant_id": "Green-tek-A19389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "12:00:00 PM",
    "power": 0.961,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "5/17/2024 12:15",
    "plant_id": "Green-tek-A19390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "12:15:00 PM",
    "power": 0.932,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "5/17/2024 12:30",
    "plant_id": "Green-tek-A19391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "12:30:00 PM",
    "power": 0.886,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/17/2024 12:45",
    "plant_id": "Green-tek-A19392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "12:45:00 PM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "5/17/2024 13:00",
    "plant_id": "Green-tek-A19393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "1:00:00 PM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "5/17/2024 13:15",
    "plant_id": "Green-tek-A19394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "1:15:00 PM",
    "power": 0.673,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "5/17/2024 13:30",
    "plant_id": "Green-tek-A19395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "1:30:00 PM",
    "power": 0.589,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "5/17/2024 13:45",
    "plant_id": "Green-tek-A19396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "1:45:00 PM",
    "power": 0.506,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "5/17/2024 14:00",
    "plant_id": "Green-tek-A19397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "2:00:00 PM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "5/17/2024 14:15",
    "plant_id": "Green-tek-A19398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "2:15:00 PM",
    "power": 0.35,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "5/17/2024 14:30",
    "plant_id": "Green-tek-A19399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "2:30:00 PM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "5/17/2024 14:45",
    "plant_id": "Green-tek-A19400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "2:45:00 PM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "5/17/2024 15:00",
    "plant_id": "Green-tek-A19401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "3:00:00 PM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/17/2024 15:15",
    "plant_id": "Green-tek-A19402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "3:15:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/17/2024 15:30",
    "plant_id": "Green-tek-A19403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "3:30:00 PM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/17/2024 15:45",
    "plant_id": "Green-tek-A19404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "3:45:00 PM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/17/2024 16:00",
    "plant_id": "Green-tek-A19405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "4:00:00 PM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/17/2024 16:15",
    "plant_id": "Green-tek-A19406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/17/2024 16:30",
    "plant_id": "Green-tek-A19407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/17/2024 16:45",
    "plant_id": "Green-tek-A19408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/17/2024 17:00",
    "plant_id": "Green-tek-A19409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/17/2024 17:15",
    "plant_id": "Green-tek-A19410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/17/2024 17:30",
    "plant_id": "Green-tek-A19411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/17/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/18/2024 6:00",
    "plant_id": "Green-tek-A19412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/18/2024 6:15",
    "plant_id": "Green-tek-A19413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/18/2024 6:30",
    "plant_id": "Green-tek-A19414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/18/2024 6:45",
    "plant_id": "Green-tek-A19415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/18/2024 7:00",
    "plant_id": "Green-tek-A19416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/18/2024 7:15",
    "plant_id": "Green-tek-A19417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/18/2024 7:30",
    "plant_id": "Green-tek-A19418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/18/2024 7:45",
    "plant_id": "Green-tek-A19419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "7:45:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/18/2024 8:00",
    "plant_id": "Green-tek-A19420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "8:00:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/18/2024 8:15",
    "plant_id": "Green-tek-A19421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "8:15:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "5/18/2024 8:30",
    "plant_id": "Green-tek-A19422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "8:30:00 AM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "5/18/2024 8:45",
    "plant_id": "Green-tek-A19423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "8:45:00 AM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "5/18/2024 9:00",
    "plant_id": "Green-tek-A19424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "9:00:00 AM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "5/18/2024 9:15",
    "plant_id": "Green-tek-A19425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "9:15:00 AM",
    "power": 0.334,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "5/18/2024 9:30",
    "plant_id": "Green-tek-A19426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "9:30:00 AM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "5/18/2024 9:45",
    "plant_id": "Green-tek-A19427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "9:45:00 AM",
    "power": 0.482,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "5/18/2024 10:00",
    "plant_id": "Green-tek-A19428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "10:00:00 AM",
    "power": 0.562,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "5/18/2024 10:15",
    "plant_id": "Green-tek-A19429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "10:15:00 AM",
    "power": 0.641,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "5/18/2024 10:30",
    "plant_id": "Green-tek-A19430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "10:30:00 AM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "5/18/2024 10:45",
    "plant_id": "Green-tek-A19431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "10:45:00 AM",
    "power": 0.786,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "5/18/2024 11:00",
    "plant_id": "Green-tek-A19432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "11:00:00 AM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "5/18/2024 11:15",
    "plant_id": "Green-tek-A19433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "11:15:00 AM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/18/2024 11:30",
    "plant_id": "Green-tek-A19434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "11:30:00 AM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "5/18/2024 11:45",
    "plant_id": "Green-tek-A19435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "11:45:00 AM",
    "power": 0.926,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "5/18/2024 12:00",
    "plant_id": "Green-tek-A19436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "12:00:00 PM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "5/18/2024 12:15",
    "plant_id": "Green-tek-A19437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "12:15:00 PM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/18/2024 12:30",
    "plant_id": "Green-tek-A19438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "12:30:00 PM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "5/18/2024 12:45",
    "plant_id": "Green-tek-A19439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "12:45:00 PM",
    "power": 0.786,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "5/18/2024 13:00",
    "plant_id": "Green-tek-A19440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "1:00:00 PM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "5/18/2024 13:15",
    "plant_id": "Green-tek-A19441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "1:15:00 PM",
    "power": 0.641,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "5/18/2024 13:30",
    "plant_id": "Green-tek-A19442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "1:30:00 PM",
    "power": 0.562,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "5/18/2024 13:45",
    "plant_id": "Green-tek-A19443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "1:45:00 PM",
    "power": 0.482,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "5/18/2024 14:00",
    "plant_id": "Green-tek-A19444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "2:00:00 PM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "5/18/2024 14:15",
    "plant_id": "Green-tek-A19445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "2:15:00 PM",
    "power": 0.334,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "5/18/2024 14:30",
    "plant_id": "Green-tek-A19446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "2:30:00 PM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "5/18/2024 14:45",
    "plant_id": "Green-tek-A19447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "2:45:00 PM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "5/18/2024 15:00",
    "plant_id": "Green-tek-A19448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "3:00:00 PM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "5/18/2024 15:15",
    "plant_id": "Green-tek-A19449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "3:15:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "5/18/2024 15:30",
    "plant_id": "Green-tek-A19450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "3:30:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/18/2024 15:45",
    "plant_id": "Green-tek-A19451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "3:45:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/18/2024 16:00",
    "plant_id": "Green-tek-A19452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/18/2024 16:15",
    "plant_id": "Green-tek-A19453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/18/2024 16:30",
    "plant_id": "Green-tek-A19454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/18/2024 16:45",
    "plant_id": "Green-tek-A19455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/18/2024 17:00",
    "plant_id": "Green-tek-A19456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/18/2024 17:15",
    "plant_id": "Green-tek-A19457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/18/2024 17:30",
    "plant_id": "Green-tek-A19458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/18/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/19/2024 6:00",
    "plant_id": "Green-tek-A19459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/19/2024 6:15",
    "plant_id": "Green-tek-A19460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/19/2024 6:30",
    "plant_id": "Green-tek-A19461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/19/2024 6:45",
    "plant_id": "Green-tek-A19462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/19/2024 7:00",
    "plant_id": "Green-tek-A19463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/19/2024 7:15",
    "plant_id": "Green-tek-A19464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/19/2024 7:30",
    "plant_id": "Green-tek-A19465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/19/2024 7:45",
    "plant_id": "Green-tek-A19466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "7:45:00 AM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "5/19/2024 8:00",
    "plant_id": "Green-tek-A19467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "8:00:00 AM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "5/19/2024 8:15",
    "plant_id": "Green-tek-A19468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "8:15:00 AM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "5/19/2024 8:30",
    "plant_id": "Green-tek-A19469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "8:30:00 AM",
    "power": 0.182,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/19/2024 8:45",
    "plant_id": "Green-tek-A19470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "8:45:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "5/19/2024 9:00",
    "plant_id": "Green-tek-A19471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "9:00:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/19/2024 9:15",
    "plant_id": "Green-tek-A19472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "9:15:00 AM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "5/19/2024 9:30",
    "plant_id": "Green-tek-A19473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "9:30:00 AM",
    "power": 0.446,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "5/19/2024 9:45",
    "plant_id": "Green-tek-A19474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "9:45:00 AM",
    "power": 0.531,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "5/19/2024 10:00",
    "plant_id": "Green-tek-A19475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "10:00:00 AM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "5/19/2024 10:15",
    "plant_id": "Green-tek-A19476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "10:15:00 AM",
    "power": 0.706,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "5/19/2024 10:30",
    "plant_id": "Green-tek-A19477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "10:30:00 AM",
    "power": 0.79,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "5/19/2024 10:45",
    "plant_id": "Green-tek-A19478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "10:45:00 AM",
    "power": 0.866,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "5/19/2024 11:00",
    "plant_id": "Green-tek-A19479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "11:00:00 AM",
    "power": 0.93,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "5/19/2024 11:15",
    "plant_id": "Green-tek-A19480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "11:15:00 AM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "5/19/2024 11:30",
    "plant_id": "Green-tek-A19481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "11:30:00 AM",
    "power": 1.01,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "5/19/2024 11:45",
    "plant_id": "Green-tek-A19482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "11:45:00 AM",
    "power": 1.02,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "5/19/2024 12:00",
    "plant_id": "Green-tek-A19483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "12:00:00 PM",
    "power": 1.01,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "5/19/2024 12:15",
    "plant_id": "Green-tek-A19484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "12:15:00 PM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "5/19/2024 12:30",
    "plant_id": "Green-tek-A19485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "12:30:00 PM",
    "power": 0.93,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "5/19/2024 12:45",
    "plant_id": "Green-tek-A19486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "12:45:00 PM",
    "power": 0.866,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "5/19/2024 13:00",
    "plant_id": "Green-tek-A19487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "1:00:00 PM",
    "power": 0.79,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "5/19/2024 13:15",
    "plant_id": "Green-tek-A19488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "1:15:00 PM",
    "power": 0.706,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "5/19/2024 13:30",
    "plant_id": "Green-tek-A19489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "1:30:00 PM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "5/19/2024 13:45",
    "plant_id": "Green-tek-A19490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "1:45:00 PM",
    "power": 0.531,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "5/19/2024 14:00",
    "plant_id": "Green-tek-A19491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "2:00:00 PM",
    "power": 0.446,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "5/19/2024 14:15",
    "plant_id": "Green-tek-A19492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "2:15:00 PM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "5/19/2024 14:30",
    "plant_id": "Green-tek-A19493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "2:30:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/19/2024 14:45",
    "plant_id": "Green-tek-A19494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "2:45:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "5/19/2024 15:00",
    "plant_id": "Green-tek-A19495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "3:00:00 PM",
    "power": 0.182,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/19/2024 15:15",
    "plant_id": "Green-tek-A19496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "3:15:00 PM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "5/19/2024 15:30",
    "plant_id": "Green-tek-A19497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "3:30:00 PM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "5/19/2024 15:45",
    "plant_id": "Green-tek-A19498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "3:45:00 PM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "5/19/2024 16:00",
    "plant_id": "Green-tek-A19499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/19/2024 16:15",
    "plant_id": "Green-tek-A19500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/19/2024 16:30",
    "plant_id": "Green-tek-A19501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/19/2024 16:45",
    "plant_id": "Green-tek-A19502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/19/2024 17:00",
    "plant_id": "Green-tek-A19503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/19/2024 17:15",
    "plant_id": "Green-tek-A19504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/19/2024 17:30",
    "plant_id": "Green-tek-A19505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/19/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/20/2024 6:00",
    "plant_id": "Green-tek-A19506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/20/2024 6:15",
    "plant_id": "Green-tek-A19507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/20/2024 6:30",
    "plant_id": "Green-tek-A19508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/20/2024 6:45",
    "plant_id": "Green-tek-A19509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/20/2024 7:00",
    "plant_id": "Green-tek-A19510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/20/2024 7:15",
    "plant_id": "Green-tek-A19511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/20/2024 7:30",
    "plant_id": "Green-tek-A19512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/20/2024 7:45",
    "plant_id": "Green-tek-A19513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "7:45:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "5/20/2024 8:00",
    "plant_id": "Green-tek-A19514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "8:00:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "5/20/2024 8:15",
    "plant_id": "Green-tek-A19515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "8:15:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "5/20/2024 8:30",
    "plant_id": "Green-tek-A19516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "8:30:00 AM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "5/20/2024 8:45",
    "plant_id": "Green-tek-A19517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "8:45:00 AM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "5/20/2024 9:00",
    "plant_id": "Green-tek-A19518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "9:00:00 AM",
    "power": 0.239,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "5/20/2024 9:15",
    "plant_id": "Green-tek-A19519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "9:15:00 AM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/20/2024 9:30",
    "plant_id": "Green-tek-A19520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "9:30:00 AM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "5/20/2024 9:45",
    "plant_id": "Green-tek-A19521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "9:45:00 AM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "5/20/2024 10:00",
    "plant_id": "Green-tek-A19522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "10:00:00 AM",
    "power": 0.498,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "5/20/2024 10:15",
    "plant_id": "Green-tek-A19523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "10:15:00 AM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "5/20/2024 10:30",
    "plant_id": "Green-tek-A19524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "10:30:00 AM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "5/20/2024 10:45",
    "plant_id": "Green-tek-A19525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "10:45:00 AM",
    "power": 0.697,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "5/20/2024 11:00",
    "plant_id": "Green-tek-A19526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "11:00:00 AM",
    "power": 0.748,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "5/20/2024 11:15",
    "plant_id": "Green-tek-A19527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "11:15:00 AM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "5/20/2024 11:30",
    "plant_id": "Green-tek-A19528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "11:30:00 AM",
    "power": 0.812,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "5/20/2024 11:45",
    "plant_id": "Green-tek-A19529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "11:45:00 AM",
    "power": 0.82,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "5/20/2024 12:00",
    "plant_id": "Green-tek-A19530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "12:00:00 PM",
    "power": 0.812,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "5/20/2024 12:15",
    "plant_id": "Green-tek-A19531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "12:15:00 PM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "5/20/2024 12:30",
    "plant_id": "Green-tek-A19532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "12:30:00 PM",
    "power": 0.748,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "5/20/2024 12:45",
    "plant_id": "Green-tek-A19533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "12:45:00 PM",
    "power": 0.697,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "5/20/2024 13:00",
    "plant_id": "Green-tek-A19534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "1:00:00 PM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "5/20/2024 13:15",
    "plant_id": "Green-tek-A19535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "1:15:00 PM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "5/20/2024 13:30",
    "plant_id": "Green-tek-A19536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "1:30:00 PM",
    "power": 0.498,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "5/20/2024 13:45",
    "plant_id": "Green-tek-A19537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "1:45:00 PM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "5/20/2024 14:00",
    "plant_id": "Green-tek-A19538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "2:00:00 PM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "5/20/2024 14:15",
    "plant_id": "Green-tek-A19539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "2:15:00 PM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/20/2024 14:30",
    "plant_id": "Green-tek-A19540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "2:30:00 PM",
    "power": 0.239,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "5/20/2024 14:45",
    "plant_id": "Green-tek-A19541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "2:45:00 PM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "5/20/2024 15:00",
    "plant_id": "Green-tek-A19542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "3:00:00 PM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "5/20/2024 15:15",
    "plant_id": "Green-tek-A19543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "3:15:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "5/20/2024 15:30",
    "plant_id": "Green-tek-A19544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "3:30:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "5/20/2024 15:45",
    "plant_id": "Green-tek-A19545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "3:45:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "5/20/2024 16:00",
    "plant_id": "Green-tek-A19546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/20/2024 16:15",
    "plant_id": "Green-tek-A19547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/20/2024 16:30",
    "plant_id": "Green-tek-A19548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/20/2024 16:45",
    "plant_id": "Green-tek-A19549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/20/2024 17:00",
    "plant_id": "Green-tek-A19550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/20/2024 17:15",
    "plant_id": "Green-tek-A19551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/20/2024 17:30",
    "plant_id": "Green-tek-A19552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/20/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/21/2024 6:00",
    "plant_id": "Green-tek-A19553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/21/2024 6:15",
    "plant_id": "Green-tek-A19554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/21/2024 6:30",
    "plant_id": "Green-tek-A19555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/21/2024 6:45",
    "plant_id": "Green-tek-A19556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/21/2024 7:00",
    "plant_id": "Green-tek-A19557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/21/2024 7:15",
    "plant_id": "Green-tek-A19558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "5/21/2024 7:30",
    "plant_id": "Green-tek-A19559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "5/21/2024 7:45",
    "plant_id": "Green-tek-A19560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "7:45:00 AM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "5/21/2024 8:00",
    "plant_id": "Green-tek-A19561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "8:00:00 AM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "5/21/2024 8:15",
    "plant_id": "Green-tek-A19562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "8:15:00 AM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "5/21/2024 8:30",
    "plant_id": "Green-tek-A19563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "8:30:00 AM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "5/21/2024 8:45",
    "plant_id": "Green-tek-A19564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "8:45:00 AM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "5/21/2024 9:00",
    "plant_id": "Green-tek-A19565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "9:00:00 AM",
    "power": 0.318,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "5/21/2024 9:15",
    "plant_id": "Green-tek-A19566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "9:15:00 AM",
    "power": 0.394,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "5/21/2024 9:30",
    "plant_id": "Green-tek-A19567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "9:30:00 AM",
    "power": 0.478,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "5/21/2024 9:45",
    "plant_id": "Green-tek-A19568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "9:45:00 AM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "5/21/2024 10:00",
    "plant_id": "Green-tek-A19569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "10:00:00 AM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "5/21/2024 10:15",
    "plant_id": "Green-tek-A19570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "10:15:00 AM",
    "power": 0.757,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "5/21/2024 10:30",
    "plant_id": "Green-tek-A19571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "10:30:00 AM",
    "power": 0.847,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "5/21/2024 10:45",
    "plant_id": "Green-tek-A19572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "10:45:00 AM",
    "power": 0.928,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "5/21/2024 11:00",
    "plant_id": "Green-tek-A19573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "11:00:00 AM",
    "power": 0.997,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "5/21/2024 11:15",
    "plant_id": "Green-tek-A19574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "11:15:00 AM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "5/21/2024 11:30",
    "plant_id": "Green-tek-A19575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "11:30:00 AM",
    "power": 1.082,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "5/21/2024 11:45",
    "plant_id": "Green-tek-A19576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "11:45:00 AM",
    "power": 1.093,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "5/21/2024 12:00",
    "plant_id": "Green-tek-A19577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "12:00:00 PM",
    "power": 1.082,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "5/21/2024 12:15",
    "plant_id": "Green-tek-A19578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "12:15:00 PM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "5/21/2024 12:30",
    "plant_id": "Green-tek-A19579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "12:30:00 PM",
    "power": 0.997,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "5/21/2024 12:45",
    "plant_id": "Green-tek-A19580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "12:45:00 PM",
    "power": 0.928,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "5/21/2024 13:00",
    "plant_id": "Green-tek-A19581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "1:00:00 PM",
    "power": 0.847,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "5/21/2024 13:15",
    "plant_id": "Green-tek-A19582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "1:15:00 PM",
    "power": 0.757,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "5/21/2024 13:30",
    "plant_id": "Green-tek-A19583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "1:30:00 PM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "5/21/2024 13:45",
    "plant_id": "Green-tek-A19584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "1:45:00 PM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "5/21/2024 14:00",
    "plant_id": "Green-tek-A19585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "2:00:00 PM",
    "power": 0.478,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "5/21/2024 14:15",
    "plant_id": "Green-tek-A19586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "2:15:00 PM",
    "power": 0.394,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "5/21/2024 14:30",
    "plant_id": "Green-tek-A19587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "2:30:00 PM",
    "power": 0.318,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "5/21/2024 14:45",
    "plant_id": "Green-tek-A19588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "2:45:00 PM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "5/21/2024 15:00",
    "plant_id": "Green-tek-A19589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "3:00:00 PM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "5/21/2024 15:15",
    "plant_id": "Green-tek-A19590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "3:15:00 PM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "5/21/2024 15:30",
    "plant_id": "Green-tek-A19591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "3:30:00 PM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "5/21/2024 15:45",
    "plant_id": "Green-tek-A19592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "3:45:00 PM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "5/21/2024 16:00",
    "plant_id": "Green-tek-A19593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "5/21/2024 16:15",
    "plant_id": "Green-tek-A19594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "5/21/2024 16:30",
    "plant_id": "Green-tek-A19595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/21/2024 16:45",
    "plant_id": "Green-tek-A19596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/21/2024 17:00",
    "plant_id": "Green-tek-A19597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/21/2024 17:15",
    "plant_id": "Green-tek-A19598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/21/2024 17:30",
    "plant_id": "Green-tek-A19599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/21/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/22/2024 6:00",
    "plant_id": "Green-tek-A19600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/22/2024 6:15",
    "plant_id": "Green-tek-A19601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/22/2024 6:30",
    "plant_id": "Green-tek-A19602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/22/2024 6:45",
    "plant_id": "Green-tek-A19603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/22/2024 7:00",
    "plant_id": "Green-tek-A19604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/22/2024 7:15",
    "plant_id": "Green-tek-A19605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/22/2024 7:30",
    "plant_id": "Green-tek-A19606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/22/2024 7:45",
    "plant_id": "Green-tek-A19607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "7:45:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/22/2024 8:00",
    "plant_id": "Green-tek-A19608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "8:00:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/22/2024 8:15",
    "plant_id": "Green-tek-A19609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "8:15:00 AM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "5/22/2024 8:30",
    "plant_id": "Green-tek-A19610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "8:30:00 AM",
    "power": 0.154,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "5/22/2024 8:45",
    "plant_id": "Green-tek-A19611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "8:45:00 AM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "5/22/2024 9:00",
    "plant_id": "Green-tek-A19612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "9:00:00 AM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "5/22/2024 9:15",
    "plant_id": "Green-tek-A19613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "9:15:00 AM",
    "power": 0.31,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "5/22/2024 9:30",
    "plant_id": "Green-tek-A19614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "9:30:00 AM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "5/22/2024 9:45",
    "plant_id": "Green-tek-A19615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "9:45:00 AM",
    "power": 0.448,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "5/22/2024 10:00",
    "plant_id": "Green-tek-A19616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "10:00:00 AM",
    "power": 0.522,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "5/22/2024 10:15",
    "plant_id": "Green-tek-A19617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "10:15:00 AM",
    "power": 0.597,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "5/22/2024 10:30",
    "plant_id": "Green-tek-A19618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "10:30:00 AM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "5/22/2024 10:45",
    "plant_id": "Green-tek-A19619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "10:45:00 AM",
    "power": 0.732,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "5/22/2024 11:00",
    "plant_id": "Green-tek-A19620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "11:00:00 AM",
    "power": 0.786,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "5/22/2024 11:15",
    "plant_id": "Green-tek-A19621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "11:15:00 AM",
    "power": 0.827,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "5/22/2024 11:30",
    "plant_id": "Green-tek-A19622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "11:30:00 AM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "5/22/2024 11:45",
    "plant_id": "Green-tek-A19623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "11:45:00 AM",
    "power": 0.861,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "5/22/2024 12:00",
    "plant_id": "Green-tek-A19624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "12:00:00 PM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "5/22/2024 12:15",
    "plant_id": "Green-tek-A19625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "12:15:00 PM",
    "power": 0.827,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "5/22/2024 12:30",
    "plant_id": "Green-tek-A19626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "12:30:00 PM",
    "power": 0.786,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "5/22/2024 12:45",
    "plant_id": "Green-tek-A19627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "12:45:00 PM",
    "power": 0.732,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "5/22/2024 13:00",
    "plant_id": "Green-tek-A19628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "1:00:00 PM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "5/22/2024 13:15",
    "plant_id": "Green-tek-A19629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "1:15:00 PM",
    "power": 0.597,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "5/22/2024 13:30",
    "plant_id": "Green-tek-A19630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "1:30:00 PM",
    "power": 0.522,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "5/22/2024 13:45",
    "plant_id": "Green-tek-A19631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "1:45:00 PM",
    "power": 0.448,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "5/22/2024 14:00",
    "plant_id": "Green-tek-A19632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "2:00:00 PM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "5/22/2024 14:15",
    "plant_id": "Green-tek-A19633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "2:15:00 PM",
    "power": 0.31,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "5/22/2024 14:30",
    "plant_id": "Green-tek-A19634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "2:30:00 PM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "5/22/2024 14:45",
    "plant_id": "Green-tek-A19635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "2:45:00 PM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "5/22/2024 15:00",
    "plant_id": "Green-tek-A19636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "3:00:00 PM",
    "power": 0.154,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "5/22/2024 15:15",
    "plant_id": "Green-tek-A19637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "3:15:00 PM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "5/22/2024 15:30",
    "plant_id": "Green-tek-A19638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "3:30:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/22/2024 15:45",
    "plant_id": "Green-tek-A19639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "3:45:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/22/2024 16:00",
    "plant_id": "Green-tek-A19640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/22/2024 16:15",
    "plant_id": "Green-tek-A19641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/22/2024 16:30",
    "plant_id": "Green-tek-A19642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/22/2024 16:45",
    "plant_id": "Green-tek-A19643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/22/2024 17:00",
    "plant_id": "Green-tek-A19644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/22/2024 17:15",
    "plant_id": "Green-tek-A19645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/22/2024 17:30",
    "plant_id": "Green-tek-A19646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/22/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/23/2024 6:00",
    "plant_id": "Green-tek-A19647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/23/2024 6:15",
    "plant_id": "Green-tek-A19648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/23/2024 6:30",
    "plant_id": "Green-tek-A19649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/23/2024 6:45",
    "plant_id": "Green-tek-A19650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/23/2024 7:00",
    "plant_id": "Green-tek-A19651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/23/2024 7:15",
    "plant_id": "Green-tek-A19652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/23/2024 7:30",
    "plant_id": "Green-tek-A19653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/23/2024 7:45",
    "plant_id": "Green-tek-A19654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "7:45:00 AM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/23/2024 8:00",
    "plant_id": "Green-tek-A19655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "8:00:00 AM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "5/23/2024 8:15",
    "plant_id": "Green-tek-A19656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "8:15:00 AM",
    "power": 0.134,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/23/2024 8:30",
    "plant_id": "Green-tek-A19657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "8:30:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "5/23/2024 8:45",
    "plant_id": "Green-tek-A19658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "8:45:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "5/23/2024 9:00",
    "plant_id": "Green-tek-A19659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "9:00:00 AM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "5/23/2024 9:15",
    "plant_id": "Green-tek-A19660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "9:15:00 AM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "5/23/2024 9:30",
    "plant_id": "Green-tek-A19661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "9:30:00 AM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "5/23/2024 9:45",
    "plant_id": "Green-tek-A19662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "9:45:00 AM",
    "power": 0.514,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "5/23/2024 10:00",
    "plant_id": "Green-tek-A19663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "10:00:00 AM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "5/23/2024 10:15",
    "plant_id": "Green-tek-A19664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "10:15:00 AM",
    "power": 0.685,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "5/23/2024 10:30",
    "plant_id": "Green-tek-A19665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "10:30:00 AM",
    "power": 0.766,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "5/23/2024 10:45",
    "plant_id": "Green-tek-A19666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "10:45:00 AM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/23/2024 11:00",
    "plant_id": "Green-tek-A19667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "11:00:00 AM",
    "power": 0.902,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "5/23/2024 11:15",
    "plant_id": "Green-tek-A19668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "11:15:00 AM",
    "power": 0.949,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "5/23/2024 11:30",
    "plant_id": "Green-tek-A19669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "11:30:00 AM",
    "power": 0.978,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "5/23/2024 11:45",
    "plant_id": "Green-tek-A19670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "11:45:00 AM",
    "power": 0.988,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "5/23/2024 12:00",
    "plant_id": "Green-tek-A19671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "12:00:00 PM",
    "power": 0.978,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "5/23/2024 12:15",
    "plant_id": "Green-tek-A19672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "12:15:00 PM",
    "power": 0.949,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "5/23/2024 12:30",
    "plant_id": "Green-tek-A19673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "12:30:00 PM",
    "power": 0.902,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "5/23/2024 12:45",
    "plant_id": "Green-tek-A19674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "12:45:00 PM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/23/2024 13:00",
    "plant_id": "Green-tek-A19675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "1:00:00 PM",
    "power": 0.766,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "5/23/2024 13:15",
    "plant_id": "Green-tek-A19676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "1:15:00 PM",
    "power": 0.685,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "5/23/2024 13:30",
    "plant_id": "Green-tek-A19677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "1:30:00 PM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "5/23/2024 13:45",
    "plant_id": "Green-tek-A19678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "1:45:00 PM",
    "power": 0.514,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "5/23/2024 14:00",
    "plant_id": "Green-tek-A19679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "2:00:00 PM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "5/23/2024 14:15",
    "plant_id": "Green-tek-A19680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "2:15:00 PM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "5/23/2024 14:30",
    "plant_id": "Green-tek-A19681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "2:30:00 PM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "5/23/2024 14:45",
    "plant_id": "Green-tek-A19682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "2:45:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "5/23/2024 15:00",
    "plant_id": "Green-tek-A19683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "3:00:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "5/23/2024 15:15",
    "plant_id": "Green-tek-A19684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "3:15:00 PM",
    "power": 0.134,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/23/2024 15:30",
    "plant_id": "Green-tek-A19685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "3:30:00 PM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "5/23/2024 15:45",
    "plant_id": "Green-tek-A19686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "3:45:00 PM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/23/2024 16:00",
    "plant_id": "Green-tek-A19687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/23/2024 16:15",
    "plant_id": "Green-tek-A19688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/23/2024 16:30",
    "plant_id": "Green-tek-A19689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/23/2024 16:45",
    "plant_id": "Green-tek-A19690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/23/2024 17:00",
    "plant_id": "Green-tek-A19691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/23/2024 17:15",
    "plant_id": "Green-tek-A19692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/23/2024 17:30",
    "plant_id": "Green-tek-A19693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/23/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/24/2024 6:00",
    "plant_id": "Green-tek-A19694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/24/2024 6:15",
    "plant_id": "Green-tek-A19695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/24/2024 6:30",
    "plant_id": "Green-tek-A19696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/24/2024 6:45",
    "plant_id": "Green-tek-A19697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/24/2024 7:00",
    "plant_id": "Green-tek-A19698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/24/2024 7:15",
    "plant_id": "Green-tek-A19699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/24/2024 7:30",
    "plant_id": "Green-tek-A19700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/24/2024 7:45",
    "plant_id": "Green-tek-A19701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "7:45:00 AM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/24/2024 8:00",
    "plant_id": "Green-tek-A19702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "8:00:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/24/2024 8:15",
    "plant_id": "Green-tek-A19703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "8:15:00 AM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/24/2024 8:30",
    "plant_id": "Green-tek-A19704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "8:30:00 AM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "5/24/2024 8:45",
    "plant_id": "Green-tek-A19705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "8:45:00 AM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/24/2024 9:00",
    "plant_id": "Green-tek-A19706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "9:00:00 AM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "5/24/2024 9:15",
    "plant_id": "Green-tek-A19707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "9:15:00 AM",
    "power": 0.462,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "5/24/2024 9:30",
    "plant_id": "Green-tek-A19708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "9:30:00 AM",
    "power": 0.56,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "5/24/2024 9:45",
    "plant_id": "Green-tek-A19709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "9:45:00 AM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "5/24/2024 10:00",
    "plant_id": "Green-tek-A19710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "10:00:00 AM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "5/24/2024 10:15",
    "plant_id": "Green-tek-A19711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "10:15:00 AM",
    "power": 0.887,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/24/2024 10:30",
    "plant_id": "Green-tek-A19712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "10:30:00 AM",
    "power": 0.992,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "5/24/2024 10:45",
    "plant_id": "Green-tek-A19713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "10:45:00 AM",
    "power": 1.088,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "5/24/2024 11:00",
    "plant_id": "Green-tek-A19714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "11:00:00 AM",
    "power": 1.168,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "5/24/2024 11:15",
    "plant_id": "Green-tek-A19715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "11:15:00 AM",
    "power": 1.229,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "5/24/2024 11:30",
    "plant_id": "Green-tek-A19716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "11:30:00 AM",
    "power": 1.268,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.3264,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "5/24/2024 11:45",
    "plant_id": "Green-tek-A19717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "11:45:00 AM",
    "power": 1.281,
    "Energy": 0.32,
    "output Energy": 0.32,
    "Ac output": 1.32,
    "dc output": 1.3464,
    "AC_R_i": 0.00041739,
    "AC_S_i": 0.000459129,
    "AC_T_i": 0.00050504,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0959997,
    "AC_S_pow": 0.10559967,
    "AC_T_Pow": 0.1161592,
    "AC_R_output": 0.396,
    "AC_S_output": 0.4356,
    "AC_T_output": 0.47916,
    "dc_current": 0.00235294,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.3264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00032,
    "carbon offset": 0.4,
    "trees planted": 8.888888889,
    "DC_R_output": 0.13068,
    "DC_S_output": 0.143748,
    "DC_T_output": 0.1581228
  },
  {
    "datetime": "5/24/2024 12:00",
    "plant_id": "Green-tek-A19718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "12:00:00 PM",
    "power": 1.268,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "5/24/2024 12:15",
    "plant_id": "Green-tek-A19719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "12:15:00 PM",
    "power": 1.229,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "5/24/2024 12:30",
    "plant_id": "Green-tek-A19720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "12:30:00 PM",
    "power": 1.168,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "5/24/2024 12:45",
    "plant_id": "Green-tek-A19721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "12:45:00 PM",
    "power": 1.088,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "5/24/2024 13:00",
    "plant_id": "Green-tek-A19722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "1:00:00 PM",
    "power": 0.992,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "5/24/2024 13:15",
    "plant_id": "Green-tek-A19723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "1:15:00 PM",
    "power": 0.887,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "5/24/2024 13:30",
    "plant_id": "Green-tek-A19724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "1:30:00 PM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "5/24/2024 13:45",
    "plant_id": "Green-tek-A19725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "1:45:00 PM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "5/24/2024 14:00",
    "plant_id": "Green-tek-A19726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "2:00:00 PM",
    "power": 0.56,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "5/24/2024 14:15",
    "plant_id": "Green-tek-A19727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "2:15:00 PM",
    "power": 0.462,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "5/24/2024 14:30",
    "plant_id": "Green-tek-A19728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "2:30:00 PM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "5/24/2024 14:45",
    "plant_id": "Green-tek-A19729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "2:45:00 PM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/24/2024 15:00",
    "plant_id": "Green-tek-A19730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "3:00:00 PM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "5/24/2024 15:15",
    "plant_id": "Green-tek-A19731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "3:15:00 PM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "5/24/2024 15:30",
    "plant_id": "Green-tek-A19732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "3:30:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "5/24/2024 15:45",
    "plant_id": "Green-tek-A19733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "3:45:00 PM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "5/24/2024 16:00",
    "plant_id": "Green-tek-A19734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/24/2024 16:15",
    "plant_id": "Green-tek-A19735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/24/2024 16:30",
    "plant_id": "Green-tek-A19736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/24/2024 16:45",
    "plant_id": "Green-tek-A19737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/24/2024 17:00",
    "plant_id": "Green-tek-A19738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/24/2024 17:15",
    "plant_id": "Green-tek-A19739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/24/2024 17:30",
    "plant_id": "Green-tek-A19740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/24/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/25/2024 6:00",
    "plant_id": "Green-tek-A19741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/25/2024 6:15",
    "plant_id": "Green-tek-A19742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/25/2024 6:30",
    "plant_id": "Green-tek-A19743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/25/2024 6:45",
    "plant_id": "Green-tek-A19744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/25/2024 7:00",
    "plant_id": "Green-tek-A19745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/25/2024 7:15",
    "plant_id": "Green-tek-A19746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/25/2024 7:30",
    "plant_id": "Green-tek-A19747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/25/2024 7:45",
    "plant_id": "Green-tek-A19748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "7:45:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/25/2024 8:00",
    "plant_id": "Green-tek-A19749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "8:00:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/25/2024 8:15",
    "plant_id": "Green-tek-A19750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "8:15:00 AM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "5/25/2024 8:30",
    "plant_id": "Green-tek-A19751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "8:30:00 AM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "5/25/2024 8:45",
    "plant_id": "Green-tek-A19752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "8:45:00 AM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "5/25/2024 9:00",
    "plant_id": "Green-tek-A19753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "9:00:00 AM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "5/25/2024 9:15",
    "plant_id": "Green-tek-A19754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "9:15:00 AM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "5/25/2024 9:30",
    "plant_id": "Green-tek-A19755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "9:30:00 AM",
    "power": 0.519,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "5/25/2024 9:45",
    "plant_id": "Green-tek-A19756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "9:45:00 AM",
    "power": 0.617,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "5/25/2024 10:00",
    "plant_id": "Green-tek-A19757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "10:00:00 AM",
    "power": 0.719,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "5/25/2024 10:15",
    "plant_id": "Green-tek-A19758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "10:15:00 AM",
    "power": 0.821,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "5/25/2024 10:30",
    "plant_id": "Green-tek-A19759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "10:30:00 AM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "5/25/2024 10:45",
    "plant_id": "Green-tek-A19760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "10:45:00 AM",
    "power": 1.007,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "5/25/2024 11:00",
    "plant_id": "Green-tek-A19761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "11:00:00 AM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "5/25/2024 11:15",
    "plant_id": "Green-tek-A19762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "11:15:00 AM",
    "power": 1.138,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "5/25/2024 11:30",
    "plant_id": "Green-tek-A19763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "11:30:00 AM",
    "power": 1.173,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "5/25/2024 11:45",
    "plant_id": "Green-tek-A19764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "11:45:00 AM",
    "power": 1.186,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "5/25/2024 12:00",
    "plant_id": "Green-tek-A19765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "12:00:00 PM",
    "power": 1.173,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "5/25/2024 12:15",
    "plant_id": "Green-tek-A19766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "12:15:00 PM",
    "power": 1.138,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "5/25/2024 12:30",
    "plant_id": "Green-tek-A19767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "12:30:00 PM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "5/25/2024 12:45",
    "plant_id": "Green-tek-A19768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "12:45:00 PM",
    "power": 1.007,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "5/25/2024 13:00",
    "plant_id": "Green-tek-A19769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "1:00:00 PM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "5/25/2024 13:15",
    "plant_id": "Green-tek-A19770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "1:15:00 PM",
    "power": 0.821,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "5/25/2024 13:30",
    "plant_id": "Green-tek-A19771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "1:30:00 PM",
    "power": 0.719,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "5/25/2024 13:45",
    "plant_id": "Green-tek-A19772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "1:45:00 PM",
    "power": 0.617,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "5/25/2024 14:00",
    "plant_id": "Green-tek-A19773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "2:00:00 PM",
    "power": 0.519,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "5/25/2024 14:15",
    "plant_id": "Green-tek-A19774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "2:15:00 PM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "5/25/2024 14:30",
    "plant_id": "Green-tek-A19775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "2:30:00 PM",
    "power": 0.345,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "5/25/2024 14:45",
    "plant_id": "Green-tek-A19776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "2:45:00 PM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "5/25/2024 15:00",
    "plant_id": "Green-tek-A19777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "3:00:00 PM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "5/25/2024 15:15",
    "plant_id": "Green-tek-A19778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "3:15:00 PM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "5/25/2024 15:30",
    "plant_id": "Green-tek-A19779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "3:30:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "5/25/2024 15:45",
    "plant_id": "Green-tek-A19780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "3:45:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "5/25/2024 16:00",
    "plant_id": "Green-tek-A19781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/25/2024 16:15",
    "plant_id": "Green-tek-A19782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/25/2024 16:30",
    "plant_id": "Green-tek-A19783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/25/2024 16:45",
    "plant_id": "Green-tek-A19784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/25/2024 17:00",
    "plant_id": "Green-tek-A19785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/25/2024 17:15",
    "plant_id": "Green-tek-A19786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/25/2024 17:30",
    "plant_id": "Green-tek-A19787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/25/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/26/2024 6:00",
    "plant_id": "Green-tek-A19788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/26/2024 6:15",
    "plant_id": "Green-tek-A19789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/26/2024 6:30",
    "plant_id": "Green-tek-A19790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/26/2024 6:45",
    "plant_id": "Green-tek-A19791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/26/2024 7:00",
    "plant_id": "Green-tek-A19792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/26/2024 7:15",
    "plant_id": "Green-tek-A19793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/26/2024 7:30",
    "plant_id": "Green-tek-A19794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/26/2024 7:45",
    "plant_id": "Green-tek-A19795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/26/2024 8:00",
    "plant_id": "Green-tek-A19796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "8:00:00 AM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "5/26/2024 8:15",
    "plant_id": "Green-tek-A19797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "8:15:00 AM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "5/26/2024 8:30",
    "plant_id": "Green-tek-A19798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "8:30:00 AM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/26/2024 8:45",
    "plant_id": "Green-tek-A19799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "8:45:00 AM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "5/26/2024 9:00",
    "plant_id": "Green-tek-A19800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "9:00:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "5/26/2024 9:15",
    "plant_id": "Green-tek-A19801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "9:15:00 AM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "5/26/2024 9:30",
    "plant_id": "Green-tek-A19802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "9:30:00 AM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "5/26/2024 9:45",
    "plant_id": "Green-tek-A19803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "9:45:00 AM",
    "power": 0.522,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "5/26/2024 10:00",
    "plant_id": "Green-tek-A19804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "10:00:00 AM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "5/26/2024 10:15",
    "plant_id": "Green-tek-A19805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "10:15:00 AM",
    "power": 0.695,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "5/26/2024 10:30",
    "plant_id": "Green-tek-A19806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "10:30:00 AM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "5/26/2024 10:45",
    "plant_id": "Green-tek-A19807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "10:45:00 AM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "5/26/2024 11:00",
    "plant_id": "Green-tek-A19808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "11:00:00 AM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "5/26/2024 11:15",
    "plant_id": "Green-tek-A19809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "11:15:00 AM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "5/26/2024 11:30",
    "plant_id": "Green-tek-A19810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "11:30:00 AM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "5/26/2024 11:45",
    "plant_id": "Green-tek-A19811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "11:45:00 AM",
    "power": 1.003,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "5/26/2024 12:00",
    "plant_id": "Green-tek-A19812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "12:00:00 PM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "5/26/2024 12:15",
    "plant_id": "Green-tek-A19813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "12:15:00 PM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "5/26/2024 12:30",
    "plant_id": "Green-tek-A19814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "12:30:00 PM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "5/26/2024 12:45",
    "plant_id": "Green-tek-A19815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "12:45:00 PM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "5/26/2024 13:00",
    "plant_id": "Green-tek-A19816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "1:00:00 PM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "5/26/2024 13:15",
    "plant_id": "Green-tek-A19817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "1:15:00 PM",
    "power": 0.695,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "5/26/2024 13:30",
    "plant_id": "Green-tek-A19818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "1:30:00 PM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "5/26/2024 13:45",
    "plant_id": "Green-tek-A19819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "1:45:00 PM",
    "power": 0.522,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "5/26/2024 14:00",
    "plant_id": "Green-tek-A19820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "2:00:00 PM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "5/26/2024 14:15",
    "plant_id": "Green-tek-A19821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "2:15:00 PM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "5/26/2024 14:30",
    "plant_id": "Green-tek-A19822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "2:30:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "5/26/2024 14:45",
    "plant_id": "Green-tek-A19823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "2:45:00 PM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "5/26/2024 15:00",
    "plant_id": "Green-tek-A19824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "3:00:00 PM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/26/2024 15:15",
    "plant_id": "Green-tek-A19825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "3:15:00 PM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "5/26/2024 15:30",
    "plant_id": "Green-tek-A19826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "3:30:00 PM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "5/26/2024 15:45",
    "plant_id": "Green-tek-A19827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/26/2024 16:00",
    "plant_id": "Green-tek-A19828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/26/2024 16:15",
    "plant_id": "Green-tek-A19829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/26/2024 16:30",
    "plant_id": "Green-tek-A19830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/26/2024 16:45",
    "plant_id": "Green-tek-A19831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/26/2024 17:00",
    "plant_id": "Green-tek-A19832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/26/2024 17:15",
    "plant_id": "Green-tek-A19833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/26/2024 17:30",
    "plant_id": "Green-tek-A19834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/26/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/27/2024 6:00",
    "plant_id": "Green-tek-A19835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/27/2024 6:15",
    "plant_id": "Green-tek-A19836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/27/2024 6:30",
    "plant_id": "Green-tek-A19837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/27/2024 6:45",
    "plant_id": "Green-tek-A19838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/27/2024 7:00",
    "plant_id": "Green-tek-A19839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/27/2024 7:15",
    "plant_id": "Green-tek-A19840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/27/2024 7:30",
    "plant_id": "Green-tek-A19841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/27/2024 7:45",
    "plant_id": "Green-tek-A19842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/27/2024 8:00",
    "plant_id": "Green-tek-A19843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "8:00:00 AM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "5/27/2024 8:15",
    "plant_id": "Green-tek-A19844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "8:15:00 AM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "5/27/2024 8:30",
    "plant_id": "Green-tek-A19845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "8:30:00 AM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/27/2024 8:45",
    "plant_id": "Green-tek-A19846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "8:45:00 AM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "5/27/2024 9:00",
    "plant_id": "Green-tek-A19847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "9:00:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "5/27/2024 9:15",
    "plant_id": "Green-tek-A19848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "9:15:00 AM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "5/27/2024 9:30",
    "plant_id": "Green-tek-A19849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "9:30:00 AM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "5/27/2024 9:45",
    "plant_id": "Green-tek-A19850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "9:45:00 AM",
    "power": 0.522,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "5/27/2024 10:00",
    "plant_id": "Green-tek-A19851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "10:00:00 AM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "5/27/2024 10:15",
    "plant_id": "Green-tek-A19852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "10:15:00 AM",
    "power": 0.695,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "5/27/2024 10:30",
    "plant_id": "Green-tek-A19853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "10:30:00 AM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "5/27/2024 10:45",
    "plant_id": "Green-tek-A19854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "10:45:00 AM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "5/27/2024 11:00",
    "plant_id": "Green-tek-A19855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "11:00:00 AM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "5/27/2024 11:15",
    "plant_id": "Green-tek-A19856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "11:15:00 AM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "5/27/2024 11:30",
    "plant_id": "Green-tek-A19857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "11:30:00 AM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "5/27/2024 11:45",
    "plant_id": "Green-tek-A19858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "11:45:00 AM",
    "power": 1.003,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "5/27/2024 12:00",
    "plant_id": "Green-tek-A19859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "12:00:00 PM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "5/27/2024 12:15",
    "plant_id": "Green-tek-A19860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "12:15:00 PM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "5/27/2024 12:30",
    "plant_id": "Green-tek-A19861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "12:30:00 PM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "5/27/2024 12:45",
    "plant_id": "Green-tek-A19862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "12:45:00 PM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "5/27/2024 13:00",
    "plant_id": "Green-tek-A19863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "1:00:00 PM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "5/27/2024 13:15",
    "plant_id": "Green-tek-A19864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "1:15:00 PM",
    "power": 0.695,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "5/27/2024 13:30",
    "plant_id": "Green-tek-A19865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "1:30:00 PM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "5/27/2024 13:45",
    "plant_id": "Green-tek-A19866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "1:45:00 PM",
    "power": 0.522,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "5/27/2024 14:00",
    "plant_id": "Green-tek-A19867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "2:00:00 PM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "5/27/2024 14:15",
    "plant_id": "Green-tek-A19868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "2:15:00 PM",
    "power": 0.361,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "5/27/2024 14:30",
    "plant_id": "Green-tek-A19869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "2:30:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "5/27/2024 14:45",
    "plant_id": "Green-tek-A19870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "2:45:00 PM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "5/27/2024 15:00",
    "plant_id": "Green-tek-A19871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "3:00:00 PM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/27/2024 15:15",
    "plant_id": "Green-tek-A19872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "3:15:00 PM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "5/27/2024 15:30",
    "plant_id": "Green-tek-A19873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "3:30:00 PM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "5/27/2024 15:45",
    "plant_id": "Green-tek-A19874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "5/27/2024 16:00",
    "plant_id": "Green-tek-A19875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/27/2024 16:15",
    "plant_id": "Green-tek-A19876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/27/2024 16:30",
    "plant_id": "Green-tek-A19877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/27/2024 16:45",
    "plant_id": "Green-tek-A19878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/27/2024 17:00",
    "plant_id": "Green-tek-A19879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/27/2024 17:15",
    "plant_id": "Green-tek-A19880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/27/2024 17:30",
    "plant_id": "Green-tek-A19881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/27/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/28/2024 6:00",
    "plant_id": "Green-tek-A19882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/28/2024 6:15",
    "plant_id": "Green-tek-A19883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/28/2024 6:30",
    "plant_id": "Green-tek-A19884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/28/2024 6:45",
    "plant_id": "Green-tek-A19885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/28/2024 7:00",
    "plant_id": "Green-tek-A19886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/28/2024 7:15",
    "plant_id": "Green-tek-A19887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/28/2024 7:30",
    "plant_id": "Green-tek-A19888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "7:30:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/28/2024 7:45",
    "plant_id": "Green-tek-A19889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "7:45:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "5/28/2024 8:00",
    "plant_id": "Green-tek-A19890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "8:00:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "5/28/2024 8:15",
    "plant_id": "Green-tek-A19891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "8:15:00 AM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "5/28/2024 8:30",
    "plant_id": "Green-tek-A19892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "8:30:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "5/28/2024 8:45",
    "plant_id": "Green-tek-A19893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "8:45:00 AM",
    "power": 0.185,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "5/28/2024 9:00",
    "plant_id": "Green-tek-A19894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "9:00:00 AM",
    "power": 0.234,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "5/28/2024 9:15",
    "plant_id": "Green-tek-A19895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "9:15:00 AM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "5/28/2024 9:30",
    "plant_id": "Green-tek-A19896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "9:30:00 AM",
    "power": 0.353,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "5/28/2024 9:45",
    "plant_id": "Green-tek-A19897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "9:45:00 AM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "5/28/2024 10:00",
    "plant_id": "Green-tek-A19898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "10:00:00 AM",
    "power": 0.489,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "5/28/2024 10:15",
    "plant_id": "Green-tek-A19899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "10:15:00 AM",
    "power": 0.558,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "5/28/2024 10:30",
    "plant_id": "Green-tek-A19900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "10:30:00 AM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "5/28/2024 10:45",
    "plant_id": "Green-tek-A19901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "10:45:00 AM",
    "power": 0.685,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "5/28/2024 11:00",
    "plant_id": "Green-tek-A19902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "11:00:00 AM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "5/28/2024 11:15",
    "plant_id": "Green-tek-A19903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "11:15:00 AM",
    "power": 0.774,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "5/28/2024 11:30",
    "plant_id": "Green-tek-A19904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "11:30:00 AM",
    "power": 0.798,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "5/28/2024 11:45",
    "plant_id": "Green-tek-A19905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "11:45:00 AM",
    "power": 0.806,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "5/28/2024 12:00",
    "plant_id": "Green-tek-A19906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "12:00:00 PM",
    "power": 0.798,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "5/28/2024 12:15",
    "plant_id": "Green-tek-A19907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "12:15:00 PM",
    "power": 0.774,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "5/28/2024 12:30",
    "plant_id": "Green-tek-A19908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "12:30:00 PM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "5/28/2024 12:45",
    "plant_id": "Green-tek-A19909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "12:45:00 PM",
    "power": 0.685,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "5/28/2024 13:00",
    "plant_id": "Green-tek-A19910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "1:00:00 PM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "5/28/2024 13:15",
    "plant_id": "Green-tek-A19911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "1:15:00 PM",
    "power": 0.558,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "5/28/2024 13:30",
    "plant_id": "Green-tek-A19912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "1:30:00 PM",
    "power": 0.489,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "5/28/2024 13:45",
    "plant_id": "Green-tek-A19913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "1:45:00 PM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "5/28/2024 14:00",
    "plant_id": "Green-tek-A19914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "2:00:00 PM",
    "power": 0.353,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "5/28/2024 14:15",
    "plant_id": "Green-tek-A19915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "2:15:00 PM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "5/28/2024 14:30",
    "plant_id": "Green-tek-A19916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "2:30:00 PM",
    "power": 0.234,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "5/28/2024 14:45",
    "plant_id": "Green-tek-A19917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "2:45:00 PM",
    "power": 0.185,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "5/28/2024 15:00",
    "plant_id": "Green-tek-A19918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "3:00:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "5/28/2024 15:15",
    "plant_id": "Green-tek-A19919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "3:15:00 PM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "5/28/2024 15:30",
    "plant_id": "Green-tek-A19920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "3:30:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "5/28/2024 15:45",
    "plant_id": "Green-tek-A19921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "3:45:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "5/28/2024 16:00",
    "plant_id": "Green-tek-A19922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "4:00:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/28/2024 16:15",
    "plant_id": "Green-tek-A19923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "5/28/2024 16:30",
    "plant_id": "Green-tek-A19924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/28/2024 16:45",
    "plant_id": "Green-tek-A19925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/28/2024 17:00",
    "plant_id": "Green-tek-A19926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/28/2024 17:15",
    "plant_id": "Green-tek-A19927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/28/2024 17:30",
    "plant_id": "Green-tek-A19928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/28/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/29/2024 6:00",
    "plant_id": "Green-tek-A19929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/29/2024 6:15",
    "plant_id": "Green-tek-A19930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/29/2024 6:30",
    "plant_id": "Green-tek-A19931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/29/2024 6:45",
    "plant_id": "Green-tek-A19932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/29/2024 7:00",
    "plant_id": "Green-tek-A19933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/29/2024 7:15",
    "plant_id": "Green-tek-A19934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/29/2024 7:30",
    "plant_id": "Green-tek-A19935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/29/2024 7:45",
    "plant_id": "Green-tek-A19936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "7:45:00 AM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "5/29/2024 8:00",
    "plant_id": "Green-tek-A19937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "8:00:00 AM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "5/29/2024 8:15",
    "plant_id": "Green-tek-A19938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "8:15:00 AM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "5/29/2024 8:30",
    "plant_id": "Green-tek-A19939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "8:30:00 AM",
    "power": 0.182,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/29/2024 8:45",
    "plant_id": "Green-tek-A19940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "8:45:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "5/29/2024 9:00",
    "plant_id": "Green-tek-A19941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "9:00:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/29/2024 9:15",
    "plant_id": "Green-tek-A19942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "9:15:00 AM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "5/29/2024 9:30",
    "plant_id": "Green-tek-A19943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "9:30:00 AM",
    "power": 0.446,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "5/29/2024 9:45",
    "plant_id": "Green-tek-A19944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "9:45:00 AM",
    "power": 0.531,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "5/29/2024 10:00",
    "plant_id": "Green-tek-A19945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "10:00:00 AM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "5/29/2024 10:15",
    "plant_id": "Green-tek-A19946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "10:15:00 AM",
    "power": 0.707,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "5/29/2024 10:30",
    "plant_id": "Green-tek-A19947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "10:30:00 AM",
    "power": 0.791,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "5/29/2024 10:45",
    "plant_id": "Green-tek-A19948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "10:45:00 AM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "5/29/2024 11:00",
    "plant_id": "Green-tek-A19949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "11:00:00 AM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "5/29/2024 11:15",
    "plant_id": "Green-tek-A19950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "11:15:00 AM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "5/29/2024 11:30",
    "plant_id": "Green-tek-A19951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "11:30:00 AM",
    "power": 1.01,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "5/29/2024 11:45",
    "plant_id": "Green-tek-A19952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "11:45:00 AM",
    "power": 1.02,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "5/29/2024 12:00",
    "plant_id": "Green-tek-A19953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "12:00:00 PM",
    "power": 1.01,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "5/29/2024 12:15",
    "plant_id": "Green-tek-A19954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "12:15:00 PM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "5/29/2024 12:30",
    "plant_id": "Green-tek-A19955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "12:30:00 PM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "5/29/2024 12:45",
    "plant_id": "Green-tek-A19956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "12:45:00 PM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "5/29/2024 13:00",
    "plant_id": "Green-tek-A19957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "1:00:00 PM",
    "power": 0.791,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "5/29/2024 13:15",
    "plant_id": "Green-tek-A19958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "1:15:00 PM",
    "power": 0.707,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "5/29/2024 13:30",
    "plant_id": "Green-tek-A19959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "1:30:00 PM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "5/29/2024 13:45",
    "plant_id": "Green-tek-A19960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "1:45:00 PM",
    "power": 0.531,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "5/29/2024 14:00",
    "plant_id": "Green-tek-A19961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "2:00:00 PM",
    "power": 0.446,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "5/29/2024 14:15",
    "plant_id": "Green-tek-A19962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "2:15:00 PM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "5/29/2024 14:30",
    "plant_id": "Green-tek-A19963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "2:30:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "5/29/2024 14:45",
    "plant_id": "Green-tek-A19964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "2:45:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "5/29/2024 15:00",
    "plant_id": "Green-tek-A19965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "3:00:00 PM",
    "power": 0.182,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "5/29/2024 15:15",
    "plant_id": "Green-tek-A19966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "3:15:00 PM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "5/29/2024 15:30",
    "plant_id": "Green-tek-A19967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "3:30:00 PM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "5/29/2024 15:45",
    "plant_id": "Green-tek-A19968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "3:45:00 PM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "5/29/2024 16:00",
    "plant_id": "Green-tek-A19969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "5/29/2024 16:15",
    "plant_id": "Green-tek-A19970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "5/29/2024 16:30",
    "plant_id": "Green-tek-A19971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/29/2024 16:45",
    "plant_id": "Green-tek-A19972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/29/2024 17:00",
    "plant_id": "Green-tek-A19973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "5/29/2024 17:15",
    "plant_id": "Green-tek-A19974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/29/2024 17:30",
    "plant_id": "Green-tek-A19975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/29/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/30/2024 6:00",
    "plant_id": "Green-tek-A19976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/30/2024 6:15",
    "plant_id": "Green-tek-A19977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/30/2024 6:30",
    "plant_id": "Green-tek-A19978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "6:30:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/30/2024 6:45",
    "plant_id": "Green-tek-A19979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/30/2024 7:00",
    "plant_id": "Green-tek-A19980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/30/2024 7:15",
    "plant_id": "Green-tek-A19981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/30/2024 7:30",
    "plant_id": "Green-tek-A19982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/30/2024 7:45",
    "plant_id": "Green-tek-A19983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "7:45:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/30/2024 8:00",
    "plant_id": "Green-tek-A19984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/30/2024 8:15",
    "plant_id": "Green-tek-A19985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "8:15:00 AM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "5/30/2024 8:30",
    "plant_id": "Green-tek-A19986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "8:30:00 AM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "5/30/2024 8:45",
    "plant_id": "Green-tek-A19987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "8:45:00 AM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "5/30/2024 9:00",
    "plant_id": "Green-tek-A19988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "9:00:00 AM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "5/30/2024 9:15",
    "plant_id": "Green-tek-A19989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "9:15:00 AM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "5/30/2024 9:30",
    "plant_id": "Green-tek-A19990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "9:30:00 AM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "5/30/2024 9:45",
    "plant_id": "Green-tek-A19991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "9:45:00 AM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "5/30/2024 10:00",
    "plant_id": "Green-tek-A19992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "10:00:00 AM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "5/30/2024 10:15",
    "plant_id": "Green-tek-A19993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "10:15:00 AM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "5/30/2024 10:30",
    "plant_id": "Green-tek-A19994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "10:30:00 AM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "5/30/2024 10:45",
    "plant_id": "Green-tek-A19995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "10:45:00 AM",
    "power": 1.109,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "5/30/2024 11:00",
    "plant_id": "Green-tek-A19996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "11:00:00 AM",
    "power": 1.191,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "5/30/2024 11:15",
    "plant_id": "Green-tek-A19997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "11:15:00 AM",
    "power": 1.253,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.32946,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "5/30/2024 11:30",
    "plant_id": "Green-tek-A19998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "11:30:00 AM",
    "power": 1.292,
    "Energy": 0.323,
    "output Energy": 0.323,
    "Ac output": 1.323,
    "dc output": 1.34946,
    "AC_R_i": 0.0004213,
    "AC_S_i": 0.00046343,
    "AC_T_i": 0.00050977,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096899,
    "AC_S_pow": 0.1065889,
    "AC_T_Pow": 0.1172471,
    "AC_R_output": 0.3969,
    "AC_S_output": 0.43659,
    "AC_T_output": 0.480249,
    "dc_current": 0.002375,
    "dc_voltage": 136,
    "dc_power": 0.33252,
    "dc_output": 0.32946,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000323,
    "carbon offset": 0.40375,
    "trees planted": 8.972222222,
    "DC_R_output": 0.130977,
    "DC_S_output": 0.1440747,
    "DC_T_output": 0.15848217
  },
  {
    "datetime": "5/30/2024 11:45",
    "plant_id": "Green-tek-A19999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "11:45:00 AM",
    "power": 1.305,
    "Energy": 0.326,
    "output Energy": 0.326,
    "Ac output": 1.326,
    "dc output": 1.35252,
    "AC_R_i": 0.00042522,
    "AC_S_i": 0.000467742,
    "AC_T_i": 0.00051452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0978006,
    "AC_S_pow": 0.10758066,
    "AC_T_Pow": 0.1183396,
    "AC_R_output": 0.3978,
    "AC_S_output": 0.43758,
    "AC_T_output": 0.481338,
    "dc_current": 0.00239706,
    "dc_voltage": 136,
    "dc_power": 0.32946,
    "dc_output": 0.33252,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000326,
    "carbon offset": 0.4075,
    "trees planted": 9.055555556,
    "DC_R_output": 0.131274,
    "DC_S_output": 0.1444014,
    "DC_T_output": 0.15884154
  },
  {
    "datetime": "5/30/2024 12:00",
    "plant_id": "Green-tek-A20000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "12:00:00 PM",
    "power": 1.292,
    "Energy": 0.323,
    "output Energy": 0.323,
    "Ac output": 1.323,
    "dc output": 1.34946,
    "AC_R_i": 0.0004213,
    "AC_S_i": 0.00046343,
    "AC_T_i": 0.00050977,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096899,
    "AC_S_pow": 0.1065889,
    "AC_T_Pow": 0.1172471,
    "AC_R_output": 0.3969,
    "AC_S_output": 0.43659,
    "AC_T_output": 0.480249,
    "dc_current": 0.002375,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.32946,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000323,
    "carbon offset": 0.40375,
    "trees planted": 8.972222222,
    "DC_R_output": 0.130977,
    "DC_S_output": 0.1440747,
    "DC_T_output": 0.15848217
  },
  {
    "datetime": "5/30/2024 12:15",
    "plant_id": "Green-tek-A20001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "12:15:00 PM",
    "power": 1.253,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "5/30/2024 12:30",
    "plant_id": "Green-tek-A20002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "12:30:00 PM",
    "power": 1.191,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "5/30/2024 12:45",
    "plant_id": "Green-tek-A20003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "12:45:00 PM",
    "power": 1.109,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "5/30/2024 13:00",
    "plant_id": "Green-tek-A20004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "1:00:00 PM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "5/30/2024 13:15",
    "plant_id": "Green-tek-A20005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "1:15:00 PM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "5/30/2024 13:30",
    "plant_id": "Green-tek-A20006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "1:30:00 PM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "5/30/2024 13:45",
    "plant_id": "Green-tek-A20007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "1:45:00 PM",
    "power": 0.679,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "5/30/2024 14:00",
    "plant_id": "Green-tek-A20008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "2:00:00 PM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "5/30/2024 14:15",
    "plant_id": "Green-tek-A20009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "2:15:00 PM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "5/30/2024 14:30",
    "plant_id": "Green-tek-A20010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "2:30:00 PM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "5/30/2024 14:45",
    "plant_id": "Green-tek-A20011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "2:45:00 PM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "5/30/2024 15:00",
    "plant_id": "Green-tek-A20012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "3:00:00 PM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "5/30/2024 15:15",
    "plant_id": "Green-tek-A20013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "3:15:00 PM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "5/30/2024 15:30",
    "plant_id": "Green-tek-A20014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "5/30/2024 15:45",
    "plant_id": "Green-tek-A20015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "3:45:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "5/30/2024 16:00",
    "plant_id": "Green-tek-A20016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "5/30/2024 16:15",
    "plant_id": "Green-tek-A20017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "5/30/2024 16:30",
    "plant_id": "Green-tek-A20018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/30/2024 16:45",
    "plant_id": "Green-tek-A20019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "5/30/2024 17:00",
    "plant_id": "Green-tek-A20020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "5:00:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/30/2024 17:15",
    "plant_id": "Green-tek-A20021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/30/2024 17:30",
    "plant_id": "Green-tek-A20022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/30/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/31/2024 6:00",
    "plant_id": "Green-tek-A20023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "5/31/2024 6:15",
    "plant_id": "Green-tek-A20024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/31/2024 6:30",
    "plant_id": "Green-tek-A20025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/31/2024 6:45",
    "plant_id": "Green-tek-A20026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/31/2024 7:00",
    "plant_id": "Green-tek-A20027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/31/2024 7:15",
    "plant_id": "Green-tek-A20028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/31/2024 7:30",
    "plant_id": "Green-tek-A20029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/31/2024 7:45",
    "plant_id": "Green-tek-A20030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/31/2024 8:00",
    "plant_id": "Green-tek-A20031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "8:00:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "5/31/2024 8:15",
    "plant_id": "Green-tek-A20032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "8:15:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "5/31/2024 8:30",
    "plant_id": "Green-tek-A20033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "8:30:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "5/31/2024 8:45",
    "plant_id": "Green-tek-A20034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "8:45:00 AM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "5/31/2024 9:00",
    "plant_id": "Green-tek-A20035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "9:00:00 AM",
    "power": 0.247,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "5/31/2024 9:15",
    "plant_id": "Green-tek-A20036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "9:15:00 AM",
    "power": 0.306,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "5/31/2024 9:30",
    "plant_id": "Green-tek-A20037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "9:30:00 AM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "5/31/2024 9:45",
    "plant_id": "Green-tek-A20038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "9:45:00 AM",
    "power": 0.442,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "5/31/2024 10:00",
    "plant_id": "Green-tek-A20039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "10:00:00 AM",
    "power": 0.515,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "5/31/2024 10:15",
    "plant_id": "Green-tek-A20040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "10:15:00 AM",
    "power": 0.588,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "5/31/2024 10:30",
    "plant_id": "Green-tek-A20041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "10:30:00 AM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "5/31/2024 10:45",
    "plant_id": "Green-tek-A20042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "10:45:00 AM",
    "power": 0.722,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "5/31/2024 11:00",
    "plant_id": "Green-tek-A20043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "11:00:00 AM",
    "power": 0.775,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "5/31/2024 11:15",
    "plant_id": "Green-tek-A20044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "11:15:00 AM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "5/31/2024 11:30",
    "plant_id": "Green-tek-A20045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "11:30:00 AM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/31/2024 11:45",
    "plant_id": "Green-tek-A20046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "11:45:00 AM",
    "power": 0.849,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "5/31/2024 12:00",
    "plant_id": "Green-tek-A20047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "12:00:00 PM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "5/31/2024 12:15",
    "plant_id": "Green-tek-A20048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "12:15:00 PM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "5/31/2024 12:30",
    "plant_id": "Green-tek-A20049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "12:30:00 PM",
    "power": 0.775,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "5/31/2024 12:45",
    "plant_id": "Green-tek-A20050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "12:45:00 PM",
    "power": 0.722,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "5/31/2024 13:00",
    "plant_id": "Green-tek-A20051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "1:00:00 PM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "5/31/2024 13:15",
    "plant_id": "Green-tek-A20052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "1:15:00 PM",
    "power": 0.588,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "5/31/2024 13:30",
    "plant_id": "Green-tek-A20053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "1:30:00 PM",
    "power": 0.515,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "5/31/2024 13:45",
    "plant_id": "Green-tek-A20054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "1:45:00 PM",
    "power": 0.442,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "5/31/2024 14:00",
    "plant_id": "Green-tek-A20055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "2:00:00 PM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "5/31/2024 14:15",
    "plant_id": "Green-tek-A20056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "2:15:00 PM",
    "power": 0.306,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "5/31/2024 14:30",
    "plant_id": "Green-tek-A20057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "2:30:00 PM",
    "power": 0.247,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "5/31/2024 14:45",
    "plant_id": "Green-tek-A20058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "2:45:00 PM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "5/31/2024 15:00",
    "plant_id": "Green-tek-A20059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "3:00:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "5/31/2024 15:15",
    "plant_id": "Green-tek-A20060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "3:15:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "5/31/2024 15:30",
    "plant_id": "Green-tek-A20061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "3:30:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "5/31/2024 15:45",
    "plant_id": "Green-tek-A20062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "5/31/2024 16:00",
    "plant_id": "Green-tek-A20063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "5/31/2024 16:15",
    "plant_id": "Green-tek-A20064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "5/31/2024 16:30",
    "plant_id": "Green-tek-A20065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "5/31/2024 16:45",
    "plant_id": "Green-tek-A20066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "5/31/2024 17:00",
    "plant_id": "Green-tek-A20067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/31/2024 17:15",
    "plant_id": "Green-tek-A20068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "5/31/2024 17:30",
    "plant_id": "Green-tek-A20069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "5/31/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/1/2024 6:00",
    "plant_id": "Green-tek-A20070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/1/2024 6:15",
    "plant_id": "Green-tek-A20071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/1/2024 6:30",
    "plant_id": "Green-tek-A20072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/1/2024 6:45",
    "plant_id": "Green-tek-A20073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/1/2024 7:00",
    "plant_id": "Green-tek-A20074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/1/2024 7:15",
    "plant_id": "Green-tek-A20075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/1/2024 7:30",
    "plant_id": "Green-tek-A20076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "7:30:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/1/2024 7:45",
    "plant_id": "Green-tek-A20077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "7:45:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/1/2024 8:00",
    "plant_id": "Green-tek-A20078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "8:00:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/1/2024 8:15",
    "plant_id": "Green-tek-A20079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "8:15:00 AM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/1/2024 8:30",
    "plant_id": "Green-tek-A20080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "8:30:00 AM",
    "power": 0.162,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "6/1/2024 8:45",
    "plant_id": "Green-tek-A20081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "8:45:00 AM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "6/1/2024 9:00",
    "plant_id": "Green-tek-A20082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "9:00:00 AM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "6/1/2024 9:15",
    "plant_id": "Green-tek-A20083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "9:15:00 AM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "6/1/2024 9:30",
    "plant_id": "Green-tek-A20084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "9:30:00 AM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "6/1/2024 9:45",
    "plant_id": "Green-tek-A20085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "9:45:00 AM",
    "power": 0.473,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "6/1/2024 10:00",
    "plant_id": "Green-tek-A20086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "10:00:00 AM",
    "power": 0.551,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "6/1/2024 10:15",
    "plant_id": "Green-tek-A20087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "10:15:00 AM",
    "power": 0.629,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "6/1/2024 10:30",
    "plant_id": "Green-tek-A20088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "10:30:00 AM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "6/1/2024 10:45",
    "plant_id": "Green-tek-A20089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "10:45:00 AM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "6/1/2024 11:00",
    "plant_id": "Green-tek-A20090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "11:00:00 AM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "6/1/2024 11:15",
    "plant_id": "Green-tek-A20091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "11:15:00 AM",
    "power": 0.872,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "6/1/2024 11:30",
    "plant_id": "Green-tek-A20092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "11:30:00 AM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "6/1/2024 11:45",
    "plant_id": "Green-tek-A20093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "11:45:00 AM",
    "power": 0.908,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "6/1/2024 12:00",
    "plant_id": "Green-tek-A20094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "12:00:00 PM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "6/1/2024 12:15",
    "plant_id": "Green-tek-A20095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "12:15:00 PM",
    "power": 0.872,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "6/1/2024 12:30",
    "plant_id": "Green-tek-A20096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "12:30:00 PM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "6/1/2024 12:45",
    "plant_id": "Green-tek-A20097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "12:45:00 PM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "6/1/2024 13:00",
    "plant_id": "Green-tek-A20098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "1:00:00 PM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "6/1/2024 13:15",
    "plant_id": "Green-tek-A20099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "1:15:00 PM",
    "power": 0.629,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "6/1/2024 13:30",
    "plant_id": "Green-tek-A20100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "1:30:00 PM",
    "power": 0.551,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "6/1/2024 13:45",
    "plant_id": "Green-tek-A20101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "1:45:00 PM",
    "power": 0.473,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "6/1/2024 14:00",
    "plant_id": "Green-tek-A20102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "2:00:00 PM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "6/1/2024 14:15",
    "plant_id": "Green-tek-A20103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "2:15:00 PM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "6/1/2024 14:30",
    "plant_id": "Green-tek-A20104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "2:30:00 PM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "6/1/2024 14:45",
    "plant_id": "Green-tek-A20105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "2:45:00 PM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "6/1/2024 15:00",
    "plant_id": "Green-tek-A20106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "3:00:00 PM",
    "power": 0.162,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "6/1/2024 15:15",
    "plant_id": "Green-tek-A20107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "3:15:00 PM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/1/2024 15:30",
    "plant_id": "Green-tek-A20108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "3:30:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/1/2024 15:45",
    "plant_id": "Green-tek-A20109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "3:45:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/1/2024 16:00",
    "plant_id": "Green-tek-A20110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "4:00:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/1/2024 16:15",
    "plant_id": "Green-tek-A20111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/1/2024 16:30",
    "plant_id": "Green-tek-A20112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/1/2024 16:45",
    "plant_id": "Green-tek-A20113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/1/2024 17:00",
    "plant_id": "Green-tek-A20114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/1/2024 17:15",
    "plant_id": "Green-tek-A20115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/1/2024 17:30",
    "plant_id": "Green-tek-A20116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/1/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/2/2024 6:00",
    "plant_id": "Green-tek-A20117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/2/2024 6:15",
    "plant_id": "Green-tek-A20118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/2/2024 6:30",
    "plant_id": "Green-tek-A20119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/2/2024 6:45",
    "plant_id": "Green-tek-A20120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/2/2024 7:00",
    "plant_id": "Green-tek-A20121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/2/2024 7:15",
    "plant_id": "Green-tek-A20122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/2/2024 7:30",
    "plant_id": "Green-tek-A20123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/2/2024 7:45",
    "plant_id": "Green-tek-A20124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "6/2/2024 8:00",
    "plant_id": "Green-tek-A20125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "8:00:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/2/2024 8:15",
    "plant_id": "Green-tek-A20126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "8:15:00 AM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "6/2/2024 8:30",
    "plant_id": "Green-tek-A20127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "8:30:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "6/2/2024 8:45",
    "plant_id": "Green-tek-A20128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "8:45:00 AM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "6/2/2024 9:00",
    "plant_id": "Green-tek-A20129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "9:00:00 AM",
    "power": 0.243,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "6/2/2024 9:15",
    "plant_id": "Green-tek-A20130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "9:15:00 AM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "6/2/2024 9:30",
    "plant_id": "Green-tek-A20131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "9:30:00 AM",
    "power": 0.366,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/2/2024 9:45",
    "plant_id": "Green-tek-A20132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "9:45:00 AM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "6/2/2024 10:00",
    "plant_id": "Green-tek-A20133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "10:00:00 AM",
    "power": 0.507,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "6/2/2024 10:15",
    "plant_id": "Green-tek-A20134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "10:15:00 AM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "6/2/2024 10:30",
    "plant_id": "Green-tek-A20135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "10:30:00 AM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "6/2/2024 10:45",
    "plant_id": "Green-tek-A20136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "10:45:00 AM",
    "power": 0.71,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "6/2/2024 11:00",
    "plant_id": "Green-tek-A20137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "11:00:00 AM",
    "power": 0.762,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "6/2/2024 11:15",
    "plant_id": "Green-tek-A20138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "11:15:00 AM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "6/2/2024 11:30",
    "plant_id": "Green-tek-A20139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "11:30:00 AM",
    "power": 0.827,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "6/2/2024 11:45",
    "plant_id": "Green-tek-A20140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "11:45:00 AM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "6/2/2024 12:00",
    "plant_id": "Green-tek-A20141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "12:00:00 PM",
    "power": 0.827,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "6/2/2024 12:15",
    "plant_id": "Green-tek-A20142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "12:15:00 PM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "6/2/2024 12:30",
    "plant_id": "Green-tek-A20143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "12:30:00 PM",
    "power": 0.762,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "6/2/2024 12:45",
    "plant_id": "Green-tek-A20144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "12:45:00 PM",
    "power": 0.71,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "6/2/2024 13:00",
    "plant_id": "Green-tek-A20145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "1:00:00 PM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "6/2/2024 13:15",
    "plant_id": "Green-tek-A20146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "1:15:00 PM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "6/2/2024 13:30",
    "plant_id": "Green-tek-A20147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "1:30:00 PM",
    "power": 0.507,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "6/2/2024 13:45",
    "plant_id": "Green-tek-A20148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "1:45:00 PM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "6/2/2024 14:00",
    "plant_id": "Green-tek-A20149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "2:00:00 PM",
    "power": 0.366,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/2/2024 14:15",
    "plant_id": "Green-tek-A20150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "2:15:00 PM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "6/2/2024 14:30",
    "plant_id": "Green-tek-A20151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "2:30:00 PM",
    "power": 0.243,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "6/2/2024 14:45",
    "plant_id": "Green-tek-A20152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "2:45:00 PM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "6/2/2024 15:00",
    "plant_id": "Green-tek-A20153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "3:00:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "6/2/2024 15:15",
    "plant_id": "Green-tek-A20154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "3:15:00 PM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "6/2/2024 15:30",
    "plant_id": "Green-tek-A20155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "3:30:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/2/2024 15:45",
    "plant_id": "Green-tek-A20156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "6/2/2024 16:00",
    "plant_id": "Green-tek-A20157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/2/2024 16:15",
    "plant_id": "Green-tek-A20158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/2/2024 16:30",
    "plant_id": "Green-tek-A20159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/2/2024 16:45",
    "plant_id": "Green-tek-A20160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/2/2024 17:00",
    "plant_id": "Green-tek-A20161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/2/2024 17:15",
    "plant_id": "Green-tek-A20162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/2/2024 17:30",
    "plant_id": "Green-tek-A20163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/2/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/3/2024 6:00",
    "plant_id": "Green-tek-A20164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/3/2024 6:15",
    "plant_id": "Green-tek-A20165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/3/2024 6:30",
    "plant_id": "Green-tek-A20166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/3/2024 6:45",
    "plant_id": "Green-tek-A20167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/3/2024 7:00",
    "plant_id": "Green-tek-A20168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/3/2024 7:15",
    "plant_id": "Green-tek-A20169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/3/2024 7:30",
    "plant_id": "Green-tek-A20170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/3/2024 7:45",
    "plant_id": "Green-tek-A20171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "7:45:00 AM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "6/3/2024 8:00",
    "plant_id": "Green-tek-A20172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "8:00:00 AM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "6/3/2024 8:15",
    "plant_id": "Green-tek-A20173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "8:15:00 AM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "6/3/2024 8:30",
    "plant_id": "Green-tek-A20174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "8:30:00 AM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/3/2024 8:45",
    "plant_id": "Green-tek-A20175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "8:45:00 AM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "6/3/2024 9:00",
    "plant_id": "Green-tek-A20176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "9:00:00 AM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "6/3/2024 9:15",
    "plant_id": "Green-tek-A20177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "9:15:00 AM",
    "power": 0.393,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "6/3/2024 9:30",
    "plant_id": "Green-tek-A20178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "9:30:00 AM",
    "power": 0.477,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "6/3/2024 9:45",
    "plant_id": "Green-tek-A20179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "9:45:00 AM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "6/3/2024 10:00",
    "plant_id": "Green-tek-A20180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "10:00:00 AM",
    "power": 0.662,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/3/2024 10:15",
    "plant_id": "Green-tek-A20181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "10:15:00 AM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "6/3/2024 10:30",
    "plant_id": "Green-tek-A20182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "10:30:00 AM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "6/3/2024 10:45",
    "plant_id": "Green-tek-A20183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "10:45:00 AM",
    "power": 0.927,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "6/3/2024 11:00",
    "plant_id": "Green-tek-A20184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "11:00:00 AM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "6/3/2024 11:15",
    "plant_id": "Green-tek-A20185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "11:15:00 AM",
    "power": 1.047,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "6/3/2024 11:30",
    "plant_id": "Green-tek-A20186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "11:30:00 AM",
    "power": 1.08,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "6/3/2024 11:45",
    "plant_id": "Green-tek-A20187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "11:45:00 AM",
    "power": 1.091,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "6/3/2024 12:00",
    "plant_id": "Green-tek-A20188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "12:00:00 PM",
    "power": 1.08,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "6/3/2024 12:15",
    "plant_id": "Green-tek-A20189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "12:15:00 PM",
    "power": 1.047,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "6/3/2024 12:30",
    "plant_id": "Green-tek-A20190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "12:30:00 PM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "6/3/2024 12:45",
    "plant_id": "Green-tek-A20191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "12:45:00 PM",
    "power": 0.927,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "6/3/2024 13:00",
    "plant_id": "Green-tek-A20192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "1:00:00 PM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "6/3/2024 13:15",
    "plant_id": "Green-tek-A20193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "1:15:00 PM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "6/3/2024 13:30",
    "plant_id": "Green-tek-A20194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "1:30:00 PM",
    "power": 0.662,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/3/2024 13:45",
    "plant_id": "Green-tek-A20195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "1:45:00 PM",
    "power": 0.568,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "6/3/2024 14:00",
    "plant_id": "Green-tek-A20196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "2:00:00 PM",
    "power": 0.477,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "6/3/2024 14:15",
    "plant_id": "Green-tek-A20197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "2:15:00 PM",
    "power": 0.393,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "6/3/2024 14:30",
    "plant_id": "Green-tek-A20198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "2:30:00 PM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "6/3/2024 14:45",
    "plant_id": "Green-tek-A20199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "2:45:00 PM",
    "power": 0.251,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "6/3/2024 15:00",
    "plant_id": "Green-tek-A20200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "3:00:00 PM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/3/2024 15:15",
    "plant_id": "Green-tek-A20201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "3:15:00 PM",
    "power": 0.148,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "6/3/2024 15:30",
    "plant_id": "Green-tek-A20202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "3:30:00 PM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "6/3/2024 15:45",
    "plant_id": "Green-tek-A20203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "3:45:00 PM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "6/3/2024 16:00",
    "plant_id": "Green-tek-A20204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/3/2024 16:15",
    "plant_id": "Green-tek-A20205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/3/2024 16:30",
    "plant_id": "Green-tek-A20206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/3/2024 16:45",
    "plant_id": "Green-tek-A20207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/3/2024 17:00",
    "plant_id": "Green-tek-A20208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/3/2024 17:15",
    "plant_id": "Green-tek-A20209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/3/2024 17:30",
    "plant_id": "Green-tek-A20210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/3/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/4/2024 6:00",
    "plant_id": "Green-tek-A20211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/4/2024 6:15",
    "plant_id": "Green-tek-A20212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/4/2024 6:30",
    "plant_id": "Green-tek-A20213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/4/2024 6:45",
    "plant_id": "Green-tek-A20214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/4/2024 7:00",
    "plant_id": "Green-tek-A20215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/4/2024 7:15",
    "plant_id": "Green-tek-A20216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/4/2024 7:30",
    "plant_id": "Green-tek-A20217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "6/4/2024 7:45",
    "plant_id": "Green-tek-A20218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "7:45:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/4/2024 8:00",
    "plant_id": "Green-tek-A20219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "8:00:00 AM",
    "power": 0.114,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "6/4/2024 8:15",
    "plant_id": "Green-tek-A20220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "8:15:00 AM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "6/4/2024 8:30",
    "plant_id": "Green-tek-A20221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "8:30:00 AM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "6/4/2024 8:45",
    "plant_id": "Green-tek-A20222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "8:45:00 AM",
    "power": 0.26,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "6/4/2024 9:00",
    "plant_id": "Green-tek-A20223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "9:00:00 AM",
    "power": 0.329,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "6/4/2024 9:15",
    "plant_id": "Green-tek-A20224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "9:15:00 AM",
    "power": 0.408,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "6/4/2024 9:30",
    "plant_id": "Green-tek-A20225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "9:30:00 AM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "6/4/2024 9:45",
    "plant_id": "Green-tek-A20226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "9:45:00 AM",
    "power": 0.589,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "6/4/2024 10:00",
    "plant_id": "Green-tek-A20227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "10:00:00 AM",
    "power": 0.686,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "6/4/2024 10:15",
    "plant_id": "Green-tek-A20228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "10:15:00 AM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "6/4/2024 10:30",
    "plant_id": "Green-tek-A20229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "10:30:00 AM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "6/4/2024 10:45",
    "plant_id": "Green-tek-A20230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "10:45:00 AM",
    "power": 0.96,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "6/4/2024 11:00",
    "plant_id": "Green-tek-A20231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "11:00:00 AM",
    "power": 1.032,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "6/4/2024 11:15",
    "plant_id": "Green-tek-A20232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "11:15:00 AM",
    "power": 1.086,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "6/4/2024 11:30",
    "plant_id": "Green-tek-A20233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "11:30:00 AM",
    "power": 1.119,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "6/4/2024 11:45",
    "plant_id": "Green-tek-A20234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "11:45:00 AM",
    "power": 1.131,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "6/4/2024 12:00",
    "plant_id": "Green-tek-A20235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "12:00:00 PM",
    "power": 1.119,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "6/4/2024 12:15",
    "plant_id": "Green-tek-A20236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "12:15:00 PM",
    "power": 1.086,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "6/4/2024 12:30",
    "plant_id": "Green-tek-A20237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "12:30:00 PM",
    "power": 1.032,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "6/4/2024 12:45",
    "plant_id": "Green-tek-A20238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "12:45:00 PM",
    "power": 0.96,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "6/4/2024 13:00",
    "plant_id": "Green-tek-A20239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "1:00:00 PM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "6/4/2024 13:15",
    "plant_id": "Green-tek-A20240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "1:15:00 PM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "6/4/2024 13:30",
    "plant_id": "Green-tek-A20241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "1:30:00 PM",
    "power": 0.686,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "6/4/2024 13:45",
    "plant_id": "Green-tek-A20242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "1:45:00 PM",
    "power": 0.589,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "6/4/2024 14:00",
    "plant_id": "Green-tek-A20243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "2:00:00 PM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "6/4/2024 14:15",
    "plant_id": "Green-tek-A20244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "2:15:00 PM",
    "power": 0.408,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "6/4/2024 14:30",
    "plant_id": "Green-tek-A20245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "2:30:00 PM",
    "power": 0.329,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "6/4/2024 14:45",
    "plant_id": "Green-tek-A20246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "2:45:00 PM",
    "power": 0.26,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "6/4/2024 15:00",
    "plant_id": "Green-tek-A20247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "3:00:00 PM",
    "power": 0.202,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "6/4/2024 15:15",
    "plant_id": "Green-tek-A20248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "3:15:00 PM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "6/4/2024 15:30",
    "plant_id": "Green-tek-A20249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "3:30:00 PM",
    "power": 0.114,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "6/4/2024 15:45",
    "plant_id": "Green-tek-A20250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "3:45:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/4/2024 16:00",
    "plant_id": "Green-tek-A20251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "6/4/2024 16:15",
    "plant_id": "Green-tek-A20252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/4/2024 16:30",
    "plant_id": "Green-tek-A20253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/4/2024 16:45",
    "plant_id": "Green-tek-A20254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/4/2024 17:00",
    "plant_id": "Green-tek-A20255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/4/2024 17:15",
    "plant_id": "Green-tek-A20256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/4/2024 17:30",
    "plant_id": "Green-tek-A20257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/4/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/5/2024 6:00",
    "plant_id": "Green-tek-A20258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/5/2024 6:15",
    "plant_id": "Green-tek-A20259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/5/2024 6:30",
    "plant_id": "Green-tek-A20260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/5/2024 6:45",
    "plant_id": "Green-tek-A20261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/5/2024 7:00",
    "plant_id": "Green-tek-A20262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/5/2024 7:15",
    "plant_id": "Green-tek-A20263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/5/2024 7:30",
    "plant_id": "Green-tek-A20264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/5/2024 7:45",
    "plant_id": "Green-tek-A20265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "7:45:00 AM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "6/5/2024 8:00",
    "plant_id": "Green-tek-A20266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "8:00:00 AM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "6/5/2024 8:15",
    "plant_id": "Green-tek-A20267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "8:15:00 AM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "6/5/2024 8:30",
    "plant_id": "Green-tek-A20268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "8:30:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "6/5/2024 8:45",
    "plant_id": "Green-tek-A20269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "8:45:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "6/5/2024 9:00",
    "plant_id": "Green-tek-A20270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "9:00:00 AM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/5/2024 9:15",
    "plant_id": "Green-tek-A20271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "9:15:00 AM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "6/5/2024 9:30",
    "plant_id": "Green-tek-A20272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "9:30:00 AM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "6/5/2024 9:45",
    "plant_id": "Green-tek-A20273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "9:45:00 AM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "6/5/2024 10:00",
    "plant_id": "Green-tek-A20274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "10:00:00 AM",
    "power": 0.598,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "6/5/2024 10:15",
    "plant_id": "Green-tek-A20275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "10:15:00 AM",
    "power": 0.683,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "6/5/2024 10:30",
    "plant_id": "Green-tek-A20276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "10:30:00 AM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "6/5/2024 10:45",
    "plant_id": "Green-tek-A20277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "10:45:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "6/5/2024 11:00",
    "plant_id": "Green-tek-A20278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "11:00:00 AM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "6/5/2024 11:15",
    "plant_id": "Green-tek-A20279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "11:15:00 AM",
    "power": 0.946,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "6/5/2024 11:30",
    "plant_id": "Green-tek-A20280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "11:30:00 AM",
    "power": 0.976,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "6/5/2024 11:45",
    "plant_id": "Green-tek-A20281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "11:45:00 AM",
    "power": 0.986,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "6/5/2024 12:00",
    "plant_id": "Green-tek-A20282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "12:00:00 PM",
    "power": 0.976,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "6/5/2024 12:15",
    "plant_id": "Green-tek-A20283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "12:15:00 PM",
    "power": 0.946,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "6/5/2024 12:30",
    "plant_id": "Green-tek-A20284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "12:30:00 PM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "6/5/2024 12:45",
    "plant_id": "Green-tek-A20285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "12:45:00 PM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "6/5/2024 13:00",
    "plant_id": "Green-tek-A20286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "1:00:00 PM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "6/5/2024 13:15",
    "plant_id": "Green-tek-A20287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "1:15:00 PM",
    "power": 0.683,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "6/5/2024 13:30",
    "plant_id": "Green-tek-A20288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "1:30:00 PM",
    "power": 0.598,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "6/5/2024 13:45",
    "plant_id": "Green-tek-A20289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "1:45:00 PM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "6/5/2024 14:00",
    "plant_id": "Green-tek-A20290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "2:00:00 PM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "6/5/2024 14:15",
    "plant_id": "Green-tek-A20291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "2:15:00 PM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "6/5/2024 14:30",
    "plant_id": "Green-tek-A20292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "2:30:00 PM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/5/2024 14:45",
    "plant_id": "Green-tek-A20293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "2:45:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "6/5/2024 15:00",
    "plant_id": "Green-tek-A20294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "3:00:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "6/5/2024 15:15",
    "plant_id": "Green-tek-A20295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "3:15:00 PM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "6/5/2024 15:30",
    "plant_id": "Green-tek-A20296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "3:30:00 PM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "6/5/2024 15:45",
    "plant_id": "Green-tek-A20297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "3:45:00 PM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "6/5/2024 16:00",
    "plant_id": "Green-tek-A20298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/5/2024 16:15",
    "plant_id": "Green-tek-A20299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/5/2024 16:30",
    "plant_id": "Green-tek-A20300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/5/2024 16:45",
    "plant_id": "Green-tek-A20301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/5/2024 17:00",
    "plant_id": "Green-tek-A20302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/5/2024 17:15",
    "plant_id": "Green-tek-A20303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/5/2024 17:30",
    "plant_id": "Green-tek-A20304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/5/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/6/2024 6:00",
    "plant_id": "Green-tek-A20305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/6/2024 6:15",
    "plant_id": "Green-tek-A20306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/6/2024 6:30",
    "plant_id": "Green-tek-A20307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/6/2024 6:45",
    "plant_id": "Green-tek-A20308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/6/2024 7:00",
    "plant_id": "Green-tek-A20309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/6/2024 7:15",
    "plant_id": "Green-tek-A20310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/6/2024 7:30",
    "plant_id": "Green-tek-A20311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/6/2024 7:45",
    "plant_id": "Green-tek-A20312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/6/2024 8:00",
    "plant_id": "Green-tek-A20313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "8:00:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/6/2024 8:15",
    "plant_id": "Green-tek-A20314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "8:15:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "6/6/2024 8:30",
    "plant_id": "Green-tek-A20315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "8:30:00 AM",
    "power": 0.226,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/6/2024 8:45",
    "plant_id": "Green-tek-A20316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "8:45:00 AM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "6/6/2024 9:00",
    "plant_id": "Green-tek-A20317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "9:00:00 AM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "6/6/2024 9:15",
    "plant_id": "Green-tek-A20318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "9:15:00 AM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "6/6/2024 9:30",
    "plant_id": "Green-tek-A20319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "9:30:00 AM",
    "power": 0.554,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "6/6/2024 9:45",
    "plant_id": "Green-tek-A20320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "9:45:00 AM",
    "power": 0.659,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/6/2024 10:00",
    "plant_id": "Green-tek-A20321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "10:00:00 AM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "6/6/2024 10:15",
    "plant_id": "Green-tek-A20322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "10:15:00 AM",
    "power": 0.877,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "6/6/2024 10:30",
    "plant_id": "Green-tek-A20323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "10:30:00 AM",
    "power": 0.981,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "6/6/2024 10:45",
    "plant_id": "Green-tek-A20324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "10:45:00 AM",
    "power": 1.075,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.29478,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "6/6/2024 11:00",
    "plant_id": "Green-tek-A20325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "11:00:00 AM",
    "power": 1.155,
    "Energy": 0.289,
    "output Energy": 0.289,
    "Ac output": 1.289,
    "dc output": 1.31478,
    "AC_R_i": 0.00037696,
    "AC_S_i": 0.000414656,
    "AC_T_i": 0.00045612,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0867008,
    "AC_S_pow": 0.09537088,
    "AC_T_Pow": 0.1049076,
    "AC_R_output": 0.3867,
    "AC_S_output": 0.42537,
    "AC_T_output": 0.467907,
    "dc_current": 0.002125,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.29478,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000289,
    "carbon offset": 0.36125,
    "trees planted": 8.027777778,
    "DC_R_output": 0.127611,
    "DC_S_output": 0.1403721,
    "DC_T_output": 0.15440931
  },
  {
    "datetime": "6/6/2024 11:15",
    "plant_id": "Green-tek-A20326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "11:15:00 AM",
    "power": 1.215,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "6/6/2024 11:30",
    "plant_id": "Green-tek-A20327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "11:30:00 AM",
    "power": 1.253,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "6/6/2024 11:45",
    "plant_id": "Green-tek-A20328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "11:45:00 AM",
    "power": 1.266,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "6/6/2024 12:00",
    "plant_id": "Green-tek-A20329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "12:00:00 PM",
    "power": 1.253,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "6/6/2024 12:15",
    "plant_id": "Green-tek-A20330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "12:15:00 PM",
    "power": 1.215,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.29478,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "6/6/2024 12:30",
    "plant_id": "Green-tek-A20331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "12:30:00 PM",
    "power": 1.155,
    "Energy": 0.289,
    "output Energy": 0.289,
    "Ac output": 1.289,
    "dc output": 1.31478,
    "AC_R_i": 0.00037696,
    "AC_S_i": 0.000414656,
    "AC_T_i": 0.00045612,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0867008,
    "AC_S_pow": 0.09537088,
    "AC_T_Pow": 0.1049076,
    "AC_R_output": 0.3867,
    "AC_S_output": 0.42537,
    "AC_T_output": 0.467907,
    "dc_current": 0.002125,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.29478,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000289,
    "carbon offset": 0.36125,
    "trees planted": 8.027777778,
    "DC_R_output": 0.127611,
    "DC_S_output": 0.1403721,
    "DC_T_output": 0.15440931
  },
  {
    "datetime": "6/6/2024 12:45",
    "plant_id": "Green-tek-A20332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "12:45:00 PM",
    "power": 1.075,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "6/6/2024 13:00",
    "plant_id": "Green-tek-A20333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "1:00:00 PM",
    "power": 0.981,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "6/6/2024 13:15",
    "plant_id": "Green-tek-A20334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "1:15:00 PM",
    "power": 0.877,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "6/6/2024 13:30",
    "plant_id": "Green-tek-A20335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "1:30:00 PM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "6/6/2024 13:45",
    "plant_id": "Green-tek-A20336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "1:45:00 PM",
    "power": 0.659,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/6/2024 14:00",
    "plant_id": "Green-tek-A20337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "2:00:00 PM",
    "power": 0.554,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "6/6/2024 14:15",
    "plant_id": "Green-tek-A20338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "2:15:00 PM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "6/6/2024 14:30",
    "plant_id": "Green-tek-A20339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "2:30:00 PM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "6/6/2024 14:45",
    "plant_id": "Green-tek-A20340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "2:45:00 PM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "6/6/2024 15:00",
    "plant_id": "Green-tek-A20341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "3:00:00 PM",
    "power": 0.226,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/6/2024 15:15",
    "plant_id": "Green-tek-A20342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "3:15:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "6/6/2024 15:30",
    "plant_id": "Green-tek-A20343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "3:30:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/6/2024 15:45",
    "plant_id": "Green-tek-A20344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/6/2024 16:00",
    "plant_id": "Green-tek-A20345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/6/2024 16:15",
    "plant_id": "Green-tek-A20346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/6/2024 16:30",
    "plant_id": "Green-tek-A20347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/6/2024 16:45",
    "plant_id": "Green-tek-A20348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/6/2024 17:00",
    "plant_id": "Green-tek-A20349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/6/2024 17:15",
    "plant_id": "Green-tek-A20350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/6/2024 17:30",
    "plant_id": "Green-tek-A20351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/6/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/7/2024 6:00",
    "plant_id": "Green-tek-A20352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/7/2024 6:15",
    "plant_id": "Green-tek-A20353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/7/2024 6:30",
    "plant_id": "Green-tek-A20354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/7/2024 6:45",
    "plant_id": "Green-tek-A20355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/7/2024 7:00",
    "plant_id": "Green-tek-A20356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/7/2024 7:15",
    "plant_id": "Green-tek-A20357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/7/2024 7:30",
    "plant_id": "Green-tek-A20358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "6/7/2024 7:45",
    "plant_id": "Green-tek-A20359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "7:45:00 AM",
    "power": 0.086,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/7/2024 8:00",
    "plant_id": "Green-tek-A20360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "8:00:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "6/7/2024 8:15",
    "plant_id": "Green-tek-A20361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "8:15:00 AM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "6/7/2024 8:30",
    "plant_id": "Green-tek-A20362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "8:30:00 AM",
    "power": 0.21,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "6/7/2024 8:45",
    "plant_id": "Green-tek-A20363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "8:45:00 AM",
    "power": 0.271,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "6/7/2024 9:00",
    "plant_id": "Green-tek-A20364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "9:00:00 AM",
    "power": 0.342,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "6/7/2024 9:15",
    "plant_id": "Green-tek-A20365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "9:15:00 AM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "6/7/2024 9:30",
    "plant_id": "Green-tek-A20366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "9:30:00 AM",
    "power": 0.515,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "6/7/2024 9:45",
    "plant_id": "Green-tek-A20367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "9:45:00 AM",
    "power": 0.613,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "6/7/2024 10:00",
    "plant_id": "Green-tek-A20368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "10:00:00 AM",
    "power": 0.714,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "6/7/2024 10:15",
    "plant_id": "Green-tek-A20369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "10:15:00 AM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "6/7/2024 10:30",
    "plant_id": "Green-tek-A20370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "10:30:00 AM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "6/7/2024 10:45",
    "plant_id": "Green-tek-A20371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "10:45:00 AM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "6/7/2024 11:00",
    "plant_id": "Green-tek-A20372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "11:00:00 AM",
    "power": 1.074,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "6/7/2024 11:15",
    "plant_id": "Green-tek-A20373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "11:15:00 AM",
    "power": 1.13,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "6/7/2024 11:30",
    "plant_id": "Green-tek-A20374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "11:30:00 AM",
    "power": 1.165,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "6/7/2024 11:45",
    "plant_id": "Green-tek-A20375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "11:45:00 AM",
    "power": 1.177,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "6/7/2024 12:00",
    "plant_id": "Green-tek-A20376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "12:00:00 PM",
    "power": 1.165,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "6/7/2024 12:15",
    "plant_id": "Green-tek-A20377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "12:15:00 PM",
    "power": 1.13,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "6/7/2024 12:30",
    "plant_id": "Green-tek-A20378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "12:30:00 PM",
    "power": 1.074,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "6/7/2024 12:45",
    "plant_id": "Green-tek-A20379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "12:45:00 PM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "6/7/2024 13:00",
    "plant_id": "Green-tek-A20380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "1:00:00 PM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "6/7/2024 13:15",
    "plant_id": "Green-tek-A20381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "1:15:00 PM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "6/7/2024 13:30",
    "plant_id": "Green-tek-A20382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "1:30:00 PM",
    "power": 0.714,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "6/7/2024 13:45",
    "plant_id": "Green-tek-A20383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "1:45:00 PM",
    "power": 0.613,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "6/7/2024 14:00",
    "plant_id": "Green-tek-A20384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "2:00:00 PM",
    "power": 0.515,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "6/7/2024 14:15",
    "plant_id": "Green-tek-A20385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "2:15:00 PM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "6/7/2024 14:30",
    "plant_id": "Green-tek-A20386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "2:30:00 PM",
    "power": 0.342,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "6/7/2024 14:45",
    "plant_id": "Green-tek-A20387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "2:45:00 PM",
    "power": 0.271,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "6/7/2024 15:00",
    "plant_id": "Green-tek-A20388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "3:00:00 PM",
    "power": 0.21,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "6/7/2024 15:15",
    "plant_id": "Green-tek-A20389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "3:15:00 PM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "6/7/2024 15:30",
    "plant_id": "Green-tek-A20390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "3:30:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "6/7/2024 15:45",
    "plant_id": "Green-tek-A20391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "3:45:00 PM",
    "power": 0.086,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/7/2024 16:00",
    "plant_id": "Green-tek-A20392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "6/7/2024 16:15",
    "plant_id": "Green-tek-A20393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/7/2024 16:30",
    "plant_id": "Green-tek-A20394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/7/2024 16:45",
    "plant_id": "Green-tek-A20395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/7/2024 17:00",
    "plant_id": "Green-tek-A20396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/7/2024 17:15",
    "plant_id": "Green-tek-A20397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/7/2024 17:30",
    "plant_id": "Green-tek-A20398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/7/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/8/2024 6:00",
    "plant_id": "Green-tek-A20399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/8/2024 6:15",
    "plant_id": "Green-tek-A20400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/8/2024 6:30",
    "plant_id": "Green-tek-A20401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/8/2024 6:45",
    "plant_id": "Green-tek-A20402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/8/2024 7:00",
    "plant_id": "Green-tek-A20403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/8/2024 7:15",
    "plant_id": "Green-tek-A20404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/8/2024 7:30",
    "plant_id": "Green-tek-A20405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/8/2024 7:45",
    "plant_id": "Green-tek-A20406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "7:45:00 AM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "6/8/2024 8:00",
    "plant_id": "Green-tek-A20407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "8:00:00 AM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "6/8/2024 8:15",
    "plant_id": "Green-tek-A20408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "8:15:00 AM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "6/8/2024 8:30",
    "plant_id": "Green-tek-A20409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "8:30:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "6/8/2024 8:45",
    "plant_id": "Green-tek-A20410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "8:45:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "6/8/2024 9:00",
    "plant_id": "Green-tek-A20411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "9:00:00 AM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/8/2024 9:15",
    "plant_id": "Green-tek-A20412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "9:15:00 AM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "6/8/2024 9:30",
    "plant_id": "Green-tek-A20413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "9:30:00 AM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "6/8/2024 9:45",
    "plant_id": "Green-tek-A20414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "9:45:00 AM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "6/8/2024 10:00",
    "plant_id": "Green-tek-A20415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "10:00:00 AM",
    "power": 0.598,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "6/8/2024 10:15",
    "plant_id": "Green-tek-A20416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "10:15:00 AM",
    "power": 0.683,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "6/8/2024 10:30",
    "plant_id": "Green-tek-A20417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "10:30:00 AM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "6/8/2024 10:45",
    "plant_id": "Green-tek-A20418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "10:45:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "6/8/2024 11:00",
    "plant_id": "Green-tek-A20419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "11:00:00 AM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "6/8/2024 11:15",
    "plant_id": "Green-tek-A20420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "11:15:00 AM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "6/8/2024 11:30",
    "plant_id": "Green-tek-A20421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "11:30:00 AM",
    "power": 0.976,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "6/8/2024 11:45",
    "plant_id": "Green-tek-A20422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "11:45:00 AM",
    "power": 0.986,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "6/8/2024 12:00",
    "plant_id": "Green-tek-A20423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "12:00:00 PM",
    "power": 0.976,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "6/8/2024 12:15",
    "plant_id": "Green-tek-A20424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "12:15:00 PM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "6/8/2024 12:30",
    "plant_id": "Green-tek-A20425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "12:30:00 PM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "6/8/2024 12:45",
    "plant_id": "Green-tek-A20426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "12:45:00 PM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "6/8/2024 13:00",
    "plant_id": "Green-tek-A20427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "1:00:00 PM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "6/8/2024 13:15",
    "plant_id": "Green-tek-A20428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "1:15:00 PM",
    "power": 0.683,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "6/8/2024 13:30",
    "plant_id": "Green-tek-A20429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "1:30:00 PM",
    "power": 0.598,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "6/8/2024 13:45",
    "plant_id": "Green-tek-A20430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "1:45:00 PM",
    "power": 0.513,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "6/8/2024 14:00",
    "plant_id": "Green-tek-A20431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "2:00:00 PM",
    "power": 0.431,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "6/8/2024 14:15",
    "plant_id": "Green-tek-A20432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "2:15:00 PM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "6/8/2024 14:30",
    "plant_id": "Green-tek-A20433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "2:30:00 PM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/8/2024 14:45",
    "plant_id": "Green-tek-A20434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "2:45:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "6/8/2024 15:00",
    "plant_id": "Green-tek-A20435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "3:00:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "6/8/2024 15:15",
    "plant_id": "Green-tek-A20436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "3:15:00 PM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "6/8/2024 15:30",
    "plant_id": "Green-tek-A20437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "3:30:00 PM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "6/8/2024 15:45",
    "plant_id": "Green-tek-A20438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "3:45:00 PM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "6/8/2024 16:00",
    "plant_id": "Green-tek-A20439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/8/2024 16:15",
    "plant_id": "Green-tek-A20440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/8/2024 16:30",
    "plant_id": "Green-tek-A20441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/8/2024 16:45",
    "plant_id": "Green-tek-A20442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/8/2024 17:00",
    "plant_id": "Green-tek-A20443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/8/2024 17:15",
    "plant_id": "Green-tek-A20444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/8/2024 17:30",
    "plant_id": "Green-tek-A20445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/8/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/9/2024 6:00",
    "plant_id": "Green-tek-A20446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/9/2024 6:15",
    "plant_id": "Green-tek-A20447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/9/2024 6:30",
    "plant_id": "Green-tek-A20448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/9/2024 6:45",
    "plant_id": "Green-tek-A20449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/9/2024 7:00",
    "plant_id": "Green-tek-A20450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/9/2024 7:15",
    "plant_id": "Green-tek-A20451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/9/2024 7:30",
    "plant_id": "Green-tek-A20452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/9/2024 7:45",
    "plant_id": "Green-tek-A20453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/9/2024 8:00",
    "plant_id": "Green-tek-A20454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "8:00:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "6/9/2024 8:15",
    "plant_id": "Green-tek-A20455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "8:15:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/9/2024 8:30",
    "plant_id": "Green-tek-A20456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "8:30:00 AM",
    "power": 0.17,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "6/9/2024 8:45",
    "plant_id": "Green-tek-A20457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "8:45:00 AM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "6/9/2024 9:00",
    "plant_id": "Green-tek-A20458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "9:00:00 AM",
    "power": 0.277,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "6/9/2024 9:15",
    "plant_id": "Green-tek-A20459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "9:15:00 AM",
    "power": 0.343,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "6/9/2024 9:30",
    "plant_id": "Green-tek-A20460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "9:30:00 AM",
    "power": 0.416,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "6/9/2024 9:45",
    "plant_id": "Green-tek-A20461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "9:45:00 AM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "6/9/2024 10:00",
    "plant_id": "Green-tek-A20462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "10:00:00 AM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "6/9/2024 10:15",
    "plant_id": "Green-tek-A20463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "10:15:00 AM",
    "power": 0.659,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/9/2024 10:30",
    "plant_id": "Green-tek-A20464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "10:30:00 AM",
    "power": 0.737,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "6/9/2024 10:45",
    "plant_id": "Green-tek-A20465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "10:45:00 AM",
    "power": 0.808,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "6/9/2024 11:00",
    "plant_id": "Green-tek-A20466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "11:00:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/9/2024 11:15",
    "plant_id": "Green-tek-A20467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "11:15:00 AM",
    "power": 0.913,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "6/9/2024 11:30",
    "plant_id": "Green-tek-A20468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "11:30:00 AM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "6/9/2024 11:45",
    "plant_id": "Green-tek-A20469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "11:45:00 AM",
    "power": 0.951,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "6/9/2024 12:00",
    "plant_id": "Green-tek-A20470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "12:00:00 PM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "6/9/2024 12:15",
    "plant_id": "Green-tek-A20471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "12:15:00 PM",
    "power": 0.913,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "6/9/2024 12:30",
    "plant_id": "Green-tek-A20472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "12:30:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/9/2024 12:45",
    "plant_id": "Green-tek-A20473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "12:45:00 PM",
    "power": 0.808,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "6/9/2024 13:00",
    "plant_id": "Green-tek-A20474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "1:00:00 PM",
    "power": 0.737,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "6/9/2024 13:15",
    "plant_id": "Green-tek-A20475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "1:15:00 PM",
    "power": 0.659,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/9/2024 13:30",
    "plant_id": "Green-tek-A20476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "1:30:00 PM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "6/9/2024 13:45",
    "plant_id": "Green-tek-A20477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "1:45:00 PM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "6/9/2024 14:00",
    "plant_id": "Green-tek-A20478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "2:00:00 PM",
    "power": 0.416,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "6/9/2024 14:15",
    "plant_id": "Green-tek-A20479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "2:15:00 PM",
    "power": 0.343,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "6/9/2024 14:30",
    "plant_id": "Green-tek-A20480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "2:30:00 PM",
    "power": 0.277,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "6/9/2024 14:45",
    "plant_id": "Green-tek-A20481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "2:45:00 PM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "6/9/2024 15:00",
    "plant_id": "Green-tek-A20482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "3:00:00 PM",
    "power": 0.17,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "6/9/2024 15:15",
    "plant_id": "Green-tek-A20483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "3:15:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/9/2024 15:30",
    "plant_id": "Green-tek-A20484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "3:30:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "6/9/2024 15:45",
    "plant_id": "Green-tek-A20485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/9/2024 16:00",
    "plant_id": "Green-tek-A20486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/9/2024 16:15",
    "plant_id": "Green-tek-A20487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/9/2024 16:30",
    "plant_id": "Green-tek-A20488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/9/2024 16:45",
    "plant_id": "Green-tek-A20489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/9/2024 17:00",
    "plant_id": "Green-tek-A20490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/9/2024 17:15",
    "plant_id": "Green-tek-A20491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/9/2024 17:30",
    "plant_id": "Green-tek-A20492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/9/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/10/2024 6:00",
    "plant_id": "Green-tek-A20493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/10/2024 6:15",
    "plant_id": "Green-tek-A20494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/10/2024 6:30",
    "plant_id": "Green-tek-A20495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/10/2024 6:45",
    "plant_id": "Green-tek-A20496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/10/2024 7:00",
    "plant_id": "Green-tek-A20497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/10/2024 7:15",
    "plant_id": "Green-tek-A20498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/10/2024 7:30",
    "plant_id": "Green-tek-A20499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/10/2024 7:45",
    "plant_id": "Green-tek-A20500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "7:45:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/10/2024 8:00",
    "plant_id": "Green-tek-A20501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "8:00:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/10/2024 8:15",
    "plant_id": "Green-tek-A20502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "8:15:00 AM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/10/2024 8:30",
    "plant_id": "Green-tek-A20503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "8:30:00 AM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "6/10/2024 8:45",
    "plant_id": "Green-tek-A20504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "8:45:00 AM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "6/10/2024 9:00",
    "plant_id": "Green-tek-A20505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "9:00:00 AM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "6/10/2024 9:15",
    "plant_id": "Green-tek-A20506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "9:15:00 AM",
    "power": 0.325,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "6/10/2024 9:30",
    "plant_id": "Green-tek-A20507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "9:30:00 AM",
    "power": 0.395,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "6/10/2024 9:45",
    "plant_id": "Green-tek-A20508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "9:45:00 AM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "6/10/2024 10:00",
    "plant_id": "Green-tek-A20509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "10:00:00 AM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/10/2024 10:15",
    "plant_id": "Green-tek-A20510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "10:15:00 AM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "6/10/2024 10:30",
    "plant_id": "Green-tek-A20511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "10:30:00 AM",
    "power": 0.7,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "6/10/2024 10:45",
    "plant_id": "Green-tek-A20512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "10:45:00 AM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "6/10/2024 11:00",
    "plant_id": "Green-tek-A20513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "11:00:00 AM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "6/10/2024 11:15",
    "plant_id": "Green-tek-A20514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "11:15:00 AM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/10/2024 11:30",
    "plant_id": "Green-tek-A20515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "11:30:00 AM",
    "power": 0.894,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "6/10/2024 11:45",
    "plant_id": "Green-tek-A20516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "11:45:00 AM",
    "power": 0.903,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "6/10/2024 12:00",
    "plant_id": "Green-tek-A20517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "12:00:00 PM",
    "power": 0.894,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "6/10/2024 12:15",
    "plant_id": "Green-tek-A20518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "12:15:00 PM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/10/2024 12:30",
    "plant_id": "Green-tek-A20519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "12:30:00 PM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "6/10/2024 12:45",
    "plant_id": "Green-tek-A20520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "12:45:00 PM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "6/10/2024 13:00",
    "plant_id": "Green-tek-A20521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "1:00:00 PM",
    "power": 0.7,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "6/10/2024 13:15",
    "plant_id": "Green-tek-A20522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "1:15:00 PM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "6/10/2024 13:30",
    "plant_id": "Green-tek-A20523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "1:30:00 PM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/10/2024 13:45",
    "plant_id": "Green-tek-A20524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "1:45:00 PM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "6/10/2024 14:00",
    "plant_id": "Green-tek-A20525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "2:00:00 PM",
    "power": 0.395,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "6/10/2024 14:15",
    "plant_id": "Green-tek-A20526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "2:15:00 PM",
    "power": 0.325,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "6/10/2024 14:30",
    "plant_id": "Green-tek-A20527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "2:30:00 PM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "6/10/2024 14:45",
    "plant_id": "Green-tek-A20528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "2:45:00 PM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "6/10/2024 15:00",
    "plant_id": "Green-tek-A20529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "3:00:00 PM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "6/10/2024 15:15",
    "plant_id": "Green-tek-A20530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "3:15:00 PM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/10/2024 15:30",
    "plant_id": "Green-tek-A20531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "3:30:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/10/2024 15:45",
    "plant_id": "Green-tek-A20532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "3:45:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/10/2024 16:00",
    "plant_id": "Green-tek-A20533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/10/2024 16:15",
    "plant_id": "Green-tek-A20534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/10/2024 16:30",
    "plant_id": "Green-tek-A20535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/10/2024 16:45",
    "plant_id": "Green-tek-A20536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/10/2024 17:00",
    "plant_id": "Green-tek-A20537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/10/2024 17:15",
    "plant_id": "Green-tek-A20538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/10/2024 17:30",
    "plant_id": "Green-tek-A20539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/10/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/11/2024 6:00",
    "plant_id": "Green-tek-A20540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/11/2024 6:15",
    "plant_id": "Green-tek-A20541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/11/2024 6:30",
    "plant_id": "Green-tek-A20542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/11/2024 6:45",
    "plant_id": "Green-tek-A20543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/11/2024 7:00",
    "plant_id": "Green-tek-A20544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/11/2024 7:15",
    "plant_id": "Green-tek-A20545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/11/2024 7:30",
    "plant_id": "Green-tek-A20546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/11/2024 7:45",
    "plant_id": "Green-tek-A20547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "7:45:00 AM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "6/11/2024 8:00",
    "plant_id": "Green-tek-A20548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "8:00:00 AM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "6/11/2024 8:15",
    "plant_id": "Green-tek-A20549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "8:15:00 AM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "6/11/2024 8:30",
    "plant_id": "Green-tek-A20550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "8:30:00 AM",
    "power": 0.191,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "6/11/2024 8:45",
    "plant_id": "Green-tek-A20551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "8:45:00 AM",
    "power": 0.247,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "6/11/2024 9:00",
    "plant_id": "Green-tek-A20552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "9:00:00 AM",
    "power": 0.312,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "6/11/2024 9:15",
    "plant_id": "Green-tek-A20553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "9:15:00 AM",
    "power": 0.387,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "6/11/2024 9:30",
    "plant_id": "Green-tek-A20554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "9:30:00 AM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "6/11/2024 9:45",
    "plant_id": "Green-tek-A20555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "9:45:00 AM",
    "power": 0.559,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "6/11/2024 10:00",
    "plant_id": "Green-tek-A20556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "10:00:00 AM",
    "power": 0.651,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "6/11/2024 10:15",
    "plant_id": "Green-tek-A20557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "10:15:00 AM",
    "power": 0.743,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "6/11/2024 10:30",
    "plant_id": "Green-tek-A20558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "10:30:00 AM",
    "power": 0.832,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "6/11/2024 10:45",
    "plant_id": "Green-tek-A20559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "10:45:00 AM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "6/11/2024 11:00",
    "plant_id": "Green-tek-A20560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "11:00:00 AM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "6/11/2024 11:15",
    "plant_id": "Green-tek-A20561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "11:15:00 AM",
    "power": 1.031,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "6/11/2024 11:30",
    "plant_id": "Green-tek-A20562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "11:30:00 AM",
    "power": 1.063,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "6/11/2024 11:45",
    "plant_id": "Green-tek-A20563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "11:45:00 AM",
    "power": 1.074,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "6/11/2024 12:00",
    "plant_id": "Green-tek-A20564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "12:00:00 PM",
    "power": 1.063,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "6/11/2024 12:15",
    "plant_id": "Green-tek-A20565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "12:15:00 PM",
    "power": 1.031,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "6/11/2024 12:30",
    "plant_id": "Green-tek-A20566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "12:30:00 PM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "6/11/2024 12:45",
    "plant_id": "Green-tek-A20567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "12:45:00 PM",
    "power": 0.912,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "6/11/2024 13:00",
    "plant_id": "Green-tek-A20568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "1:00:00 PM",
    "power": 0.832,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "6/11/2024 13:15",
    "plant_id": "Green-tek-A20569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "1:15:00 PM",
    "power": 0.743,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "6/11/2024 13:30",
    "plant_id": "Green-tek-A20570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "1:30:00 PM",
    "power": 0.651,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "6/11/2024 13:45",
    "plant_id": "Green-tek-A20571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "1:45:00 PM",
    "power": 0.559,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "6/11/2024 14:00",
    "plant_id": "Green-tek-A20572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "2:00:00 PM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "6/11/2024 14:15",
    "plant_id": "Green-tek-A20573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "2:15:00 PM",
    "power": 0.387,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "6/11/2024 14:30",
    "plant_id": "Green-tek-A20574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "2:30:00 PM",
    "power": 0.312,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "6/11/2024 14:45",
    "plant_id": "Green-tek-A20575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "2:45:00 PM",
    "power": 0.247,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "6/11/2024 15:00",
    "plant_id": "Green-tek-A20576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "3:00:00 PM",
    "power": 0.191,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "6/11/2024 15:15",
    "plant_id": "Green-tek-A20577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "3:15:00 PM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "6/11/2024 15:30",
    "plant_id": "Green-tek-A20578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "3:30:00 PM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "6/11/2024 15:45",
    "plant_id": "Green-tek-A20579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "3:45:00 PM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "6/11/2024 16:00",
    "plant_id": "Green-tek-A20580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/11/2024 16:15",
    "plant_id": "Green-tek-A20581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/11/2024 16:30",
    "plant_id": "Green-tek-A20582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/11/2024 16:45",
    "plant_id": "Green-tek-A20583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/11/2024 17:00",
    "plant_id": "Green-tek-A20584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/11/2024 17:15",
    "plant_id": "Green-tek-A20585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/11/2024 17:30",
    "plant_id": "Green-tek-A20586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/11/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/12/2024 6:00",
    "plant_id": "Green-tek-A20587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/12/2024 6:15",
    "plant_id": "Green-tek-A20588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/12/2024 6:30",
    "plant_id": "Green-tek-A20589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/12/2024 6:45",
    "plant_id": "Green-tek-A20590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/12/2024 7:00",
    "plant_id": "Green-tek-A20591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/12/2024 7:15",
    "plant_id": "Green-tek-A20592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/12/2024 7:30",
    "plant_id": "Green-tek-A20593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/12/2024 7:45",
    "plant_id": "Green-tek-A20594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "7:45:00 AM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "6/12/2024 8:00",
    "plant_id": "Green-tek-A20595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "8:00:00 AM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "6/12/2024 8:15",
    "plant_id": "Green-tek-A20596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "8:15:00 AM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "6/12/2024 8:30",
    "plant_id": "Green-tek-A20597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "8:30:00 AM",
    "power": 0.182,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "6/12/2024 8:45",
    "plant_id": "Green-tek-A20598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "8:45:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "6/12/2024 9:00",
    "plant_id": "Green-tek-A20599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "9:00:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "6/12/2024 9:15",
    "plant_id": "Green-tek-A20600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "9:15:00 AM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "6/12/2024 9:30",
    "plant_id": "Green-tek-A20601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "9:30:00 AM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "6/12/2024 9:45",
    "plant_id": "Green-tek-A20602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "9:45:00 AM",
    "power": 0.531,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "6/12/2024 10:00",
    "plant_id": "Green-tek-A20603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "10:00:00 AM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "6/12/2024 10:15",
    "plant_id": "Green-tek-A20604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "10:15:00 AM",
    "power": 0.707,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "6/12/2024 10:30",
    "plant_id": "Green-tek-A20605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "10:30:00 AM",
    "power": 0.791,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "6/12/2024 10:45",
    "plant_id": "Green-tek-A20606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "10:45:00 AM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/12/2024 11:00",
    "plant_id": "Green-tek-A20607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "11:00:00 AM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "6/12/2024 11:15",
    "plant_id": "Green-tek-A20608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "11:15:00 AM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "6/12/2024 11:30",
    "plant_id": "Green-tek-A20609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "11:30:00 AM",
    "power": 1.01,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "6/12/2024 11:45",
    "plant_id": "Green-tek-A20610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "11:45:00 AM",
    "power": 1.021,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "6/12/2024 12:00",
    "plant_id": "Green-tek-A20611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "12:00:00 PM",
    "power": 1.01,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "6/12/2024 12:15",
    "plant_id": "Green-tek-A20612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "12:15:00 PM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "6/12/2024 12:30",
    "plant_id": "Green-tek-A20613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "12:30:00 PM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "6/12/2024 12:45",
    "plant_id": "Green-tek-A20614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "12:45:00 PM",
    "power": 0.867,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/12/2024 13:00",
    "plant_id": "Green-tek-A20615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "1:00:00 PM",
    "power": 0.791,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "6/12/2024 13:15",
    "plant_id": "Green-tek-A20616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "1:15:00 PM",
    "power": 0.707,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "6/12/2024 13:30",
    "plant_id": "Green-tek-A20617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "1:30:00 PM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "6/12/2024 13:45",
    "plant_id": "Green-tek-A20618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "1:45:00 PM",
    "power": 0.531,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "6/12/2024 14:00",
    "plant_id": "Green-tek-A20619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "2:00:00 PM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "6/12/2024 14:15",
    "plant_id": "Green-tek-A20620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "2:15:00 PM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "6/12/2024 14:30",
    "plant_id": "Green-tek-A20621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "2:30:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "6/12/2024 14:45",
    "plant_id": "Green-tek-A20622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "2:45:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "6/12/2024 15:00",
    "plant_id": "Green-tek-A20623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "3:00:00 PM",
    "power": 0.182,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "6/12/2024 15:15",
    "plant_id": "Green-tek-A20624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "3:15:00 PM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "6/12/2024 15:30",
    "plant_id": "Green-tek-A20625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "3:30:00 PM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "6/12/2024 15:45",
    "plant_id": "Green-tek-A20626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "3:45:00 PM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "6/12/2024 16:00",
    "plant_id": "Green-tek-A20627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/12/2024 16:15",
    "plant_id": "Green-tek-A20628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/12/2024 16:30",
    "plant_id": "Green-tek-A20629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/12/2024 16:45",
    "plant_id": "Green-tek-A20630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/12/2024 17:00",
    "plant_id": "Green-tek-A20631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/12/2024 17:15",
    "plant_id": "Green-tek-A20632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/12/2024 17:30",
    "plant_id": "Green-tek-A20633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/12/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/13/2024 6:00",
    "plant_id": "Green-tek-A20634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/13/2024 6:15",
    "plant_id": "Green-tek-A20635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/13/2024 6:30",
    "plant_id": "Green-tek-A20636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/13/2024 6:45",
    "plant_id": "Green-tek-A20637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/13/2024 7:00",
    "plant_id": "Green-tek-A20638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/13/2024 7:15",
    "plant_id": "Green-tek-A20639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/13/2024 7:30",
    "plant_id": "Green-tek-A20640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/13/2024 7:45",
    "plant_id": "Green-tek-A20641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/13/2024 8:00",
    "plant_id": "Green-tek-A20642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "8:00:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/13/2024 8:15",
    "plant_id": "Green-tek-A20643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "8:15:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "6/13/2024 8:30",
    "plant_id": "Green-tek-A20644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "8:30:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "6/13/2024 8:45",
    "plant_id": "Green-tek-A20645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "8:45:00 AM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/13/2024 9:00",
    "plant_id": "Green-tek-A20646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "9:00:00 AM",
    "power": 0.247,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "6/13/2024 9:15",
    "plant_id": "Green-tek-A20647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "9:15:00 AM",
    "power": 0.306,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "6/13/2024 9:30",
    "plant_id": "Green-tek-A20648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "9:30:00 AM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "6/13/2024 9:45",
    "plant_id": "Green-tek-A20649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "9:45:00 AM",
    "power": 0.442,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "6/13/2024 10:00",
    "plant_id": "Green-tek-A20650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "10:00:00 AM",
    "power": 0.515,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "6/13/2024 10:15",
    "plant_id": "Green-tek-A20651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "10:15:00 AM",
    "power": 0.588,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "6/13/2024 10:30",
    "plant_id": "Green-tek-A20652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "10:30:00 AM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/13/2024 10:45",
    "plant_id": "Green-tek-A20653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "10:45:00 AM",
    "power": 0.722,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "6/13/2024 11:00",
    "plant_id": "Green-tek-A20654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "11:00:00 AM",
    "power": 0.775,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "6/13/2024 11:15",
    "plant_id": "Green-tek-A20655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "11:15:00 AM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "6/13/2024 11:30",
    "plant_id": "Green-tek-A20656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "11:30:00 AM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "6/13/2024 11:45",
    "plant_id": "Green-tek-A20657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "11:45:00 AM",
    "power": 0.849,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "6/13/2024 12:00",
    "plant_id": "Green-tek-A20658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "12:00:00 PM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "6/13/2024 12:15",
    "plant_id": "Green-tek-A20659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "12:15:00 PM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "6/13/2024 12:30",
    "plant_id": "Green-tek-A20660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "12:30:00 PM",
    "power": 0.775,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "6/13/2024 12:45",
    "plant_id": "Green-tek-A20661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "12:45:00 PM",
    "power": 0.722,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "6/13/2024 13:00",
    "plant_id": "Green-tek-A20662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "1:00:00 PM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/13/2024 13:15",
    "plant_id": "Green-tek-A20663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "1:15:00 PM",
    "power": 0.588,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "6/13/2024 13:30",
    "plant_id": "Green-tek-A20664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "1:30:00 PM",
    "power": 0.515,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "6/13/2024 13:45",
    "plant_id": "Green-tek-A20665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "1:45:00 PM",
    "power": 0.442,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "6/13/2024 14:00",
    "plant_id": "Green-tek-A20666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "2:00:00 PM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "6/13/2024 14:15",
    "plant_id": "Green-tek-A20667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "2:15:00 PM",
    "power": 0.306,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "6/13/2024 14:30",
    "plant_id": "Green-tek-A20668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "2:30:00 PM",
    "power": 0.247,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "6/13/2024 14:45",
    "plant_id": "Green-tek-A20669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "2:45:00 PM",
    "power": 0.195,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/13/2024 15:00",
    "plant_id": "Green-tek-A20670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "3:00:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "6/13/2024 15:15",
    "plant_id": "Green-tek-A20671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "3:15:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "6/13/2024 15:30",
    "plant_id": "Green-tek-A20672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "3:30:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/13/2024 15:45",
    "plant_id": "Green-tek-A20673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/13/2024 16:00",
    "plant_id": "Green-tek-A20674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/13/2024 16:15",
    "plant_id": "Green-tek-A20675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/13/2024 16:30",
    "plant_id": "Green-tek-A20676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/13/2024 16:45",
    "plant_id": "Green-tek-A20677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/13/2024 17:00",
    "plant_id": "Green-tek-A20678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/13/2024 17:15",
    "plant_id": "Green-tek-A20679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/13/2024 17:30",
    "plant_id": "Green-tek-A20680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/13/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/14/2024 6:00",
    "plant_id": "Green-tek-A20681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/14/2024 6:15",
    "plant_id": "Green-tek-A20682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/14/2024 6:30",
    "plant_id": "Green-tek-A20683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/14/2024 6:45",
    "plant_id": "Green-tek-A20684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/14/2024 7:00",
    "plant_id": "Green-tek-A20685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/14/2024 7:15",
    "plant_id": "Green-tek-A20686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/14/2024 7:30",
    "plant_id": "Green-tek-A20687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/14/2024 7:45",
    "plant_id": "Green-tek-A20688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "6/14/2024 8:00",
    "plant_id": "Green-tek-A20689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "8:00:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/14/2024 8:15",
    "plant_id": "Green-tek-A20690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "8:15:00 AM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "6/14/2024 8:30",
    "plant_id": "Green-tek-A20691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "8:30:00 AM",
    "power": 0.23,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "6/14/2024 8:45",
    "plant_id": "Green-tek-A20692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "8:45:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "6/14/2024 9:00",
    "plant_id": "Green-tek-A20693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "9:00:00 AM",
    "power": 0.376,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "6/14/2024 9:15",
    "plant_id": "Green-tek-A20694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "9:15:00 AM",
    "power": 0.465,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "6/14/2024 9:30",
    "plant_id": "Green-tek-A20695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "9:30:00 AM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "6/14/2024 9:45",
    "plant_id": "Green-tek-A20696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "9:45:00 AM",
    "power": 0.672,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "6/14/2024 10:00",
    "plant_id": "Green-tek-A20697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "10:00:00 AM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "6/14/2024 10:15",
    "plant_id": "Green-tek-A20698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "10:15:00 AM",
    "power": 0.894,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "6/14/2024 10:30",
    "plant_id": "Green-tek-A20699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "10:30:00 AM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "6/14/2024 10:45",
    "plant_id": "Green-tek-A20700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "10:45:00 AM",
    "power": 1.096,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "6/14/2024 11:00",
    "plant_id": "Green-tek-A20701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "11:00:00 AM",
    "power": 1.178,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "6/14/2024 11:15",
    "plant_id": "Green-tek-A20702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "11:15:00 AM",
    "power": 1.239,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.32538,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "6/14/2024 11:30",
    "plant_id": "Green-tek-A20703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "11:30:00 AM",
    "power": 1.278,
    "Energy": 0.319,
    "output Energy": 0.319,
    "Ac output": 1.319,
    "dc output": 1.34538,
    "AC_R_i": 0.00041609,
    "AC_S_i": 0.000457699,
    "AC_T_i": 0.00050347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0957007,
    "AC_S_pow": 0.10527077,
    "AC_T_Pow": 0.1157981,
    "AC_R_output": 0.3957,
    "AC_S_output": 0.43527,
    "AC_T_output": 0.478797,
    "dc_current": 0.00234559,
    "dc_voltage": 136,
    "dc_power": 0.32946,
    "dc_output": 0.32538,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000319,
    "carbon offset": 0.39875,
    "trees planted": 8.861111111,
    "DC_R_output": 0.130581,
    "DC_S_output": 0.1436391,
    "DC_T_output": 0.15800301
  },
  {
    "datetime": "6/14/2024 11:45",
    "plant_id": "Green-tek-A20704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "11:45:00 AM",
    "power": 1.291,
    "Energy": 0.323,
    "output Energy": 0.323,
    "Ac output": 1.323,
    "dc output": 1.34946,
    "AC_R_i": 0.0004213,
    "AC_S_i": 0.00046343,
    "AC_T_i": 0.00050977,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096899,
    "AC_S_pow": 0.1065889,
    "AC_T_Pow": 0.1172471,
    "AC_R_output": 0.3969,
    "AC_S_output": 0.43659,
    "AC_T_output": 0.480249,
    "dc_current": 0.002375,
    "dc_voltage": 136,
    "dc_power": 0.32538,
    "dc_output": 0.32946,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000323,
    "carbon offset": 0.40375,
    "trees planted": 8.972222222,
    "DC_R_output": 0.130977,
    "DC_S_output": 0.1440747,
    "DC_T_output": 0.15848217
  },
  {
    "datetime": "6/14/2024 12:00",
    "plant_id": "Green-tek-A20705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "12:00:00 PM",
    "power": 1.278,
    "Energy": 0.319,
    "output Energy": 0.319,
    "Ac output": 1.319,
    "dc output": 1.34538,
    "AC_R_i": 0.00041609,
    "AC_S_i": 0.000457699,
    "AC_T_i": 0.00050347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0957007,
    "AC_S_pow": 0.10527077,
    "AC_T_Pow": 0.1157981,
    "AC_R_output": 0.3957,
    "AC_S_output": 0.43527,
    "AC_T_output": 0.478797,
    "dc_current": 0.00234559,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.32538,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000319,
    "carbon offset": 0.39875,
    "trees planted": 8.861111111,
    "DC_R_output": 0.130581,
    "DC_S_output": 0.1436391,
    "DC_T_output": 0.15800301
  },
  {
    "datetime": "6/14/2024 12:15",
    "plant_id": "Green-tek-A20706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "12:15:00 PM",
    "power": 1.239,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "6/14/2024 12:30",
    "plant_id": "Green-tek-A20707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "12:30:00 PM",
    "power": 1.178,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "6/14/2024 12:45",
    "plant_id": "Green-tek-A20708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "12:45:00 PM",
    "power": 1.096,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "6/14/2024 13:00",
    "plant_id": "Green-tek-A20709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "1:00:00 PM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "6/14/2024 13:15",
    "plant_id": "Green-tek-A20710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "1:15:00 PM",
    "power": 0.894,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "6/14/2024 13:30",
    "plant_id": "Green-tek-A20711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "1:30:00 PM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "6/14/2024 13:45",
    "plant_id": "Green-tek-A20712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "1:45:00 PM",
    "power": 0.672,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "6/14/2024 14:00",
    "plant_id": "Green-tek-A20713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "2:00:00 PM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "6/14/2024 14:15",
    "plant_id": "Green-tek-A20714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "2:15:00 PM",
    "power": 0.465,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "6/14/2024 14:30",
    "plant_id": "Green-tek-A20715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "2:30:00 PM",
    "power": 0.376,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "6/14/2024 14:45",
    "plant_id": "Green-tek-A20716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "2:45:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "6/14/2024 15:00",
    "plant_id": "Green-tek-A20717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "3:00:00 PM",
    "power": 0.23,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "6/14/2024 15:15",
    "plant_id": "Green-tek-A20718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "3:15:00 PM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "6/14/2024 15:30",
    "plant_id": "Green-tek-A20719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "3:30:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/14/2024 15:45",
    "plant_id": "Green-tek-A20720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "6/14/2024 16:00",
    "plant_id": "Green-tek-A20721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/14/2024 16:15",
    "plant_id": "Green-tek-A20722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/14/2024 16:30",
    "plant_id": "Green-tek-A20723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/14/2024 16:45",
    "plant_id": "Green-tek-A20724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/14/2024 17:00",
    "plant_id": "Green-tek-A20725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/14/2024 17:15",
    "plant_id": "Green-tek-A20726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/14/2024 17:30",
    "plant_id": "Green-tek-A20727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/14/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/15/2024 6:00",
    "plant_id": "Green-tek-A20728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/15/2024 6:15",
    "plant_id": "Green-tek-A20729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/15/2024 6:30",
    "plant_id": "Green-tek-A20730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/15/2024 6:45",
    "plant_id": "Green-tek-A20731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/15/2024 7:00",
    "plant_id": "Green-tek-A20732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/15/2024 7:15",
    "plant_id": "Green-tek-A20733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/15/2024 7:30",
    "plant_id": "Green-tek-A20734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/15/2024 7:45",
    "plant_id": "Green-tek-A20735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "7:45:00 AM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "6/15/2024 8:00",
    "plant_id": "Green-tek-A20736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "8:00:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/15/2024 8:15",
    "plant_id": "Green-tek-A20737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "8:15:00 AM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "6/15/2024 8:30",
    "plant_id": "Green-tek-A20738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "8:30:00 AM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "6/15/2024 8:45",
    "plant_id": "Green-tek-A20739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "8:45:00 AM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "6/15/2024 9:00",
    "plant_id": "Green-tek-A20740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "9:00:00 AM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "6/15/2024 9:15",
    "plant_id": "Green-tek-A20741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "9:15:00 AM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "6/15/2024 9:30",
    "plant_id": "Green-tek-A20742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "9:30:00 AM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "6/15/2024 9:45",
    "plant_id": "Green-tek-A20743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "9:45:00 AM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "6/15/2024 10:00",
    "plant_id": "Green-tek-A20744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "10:00:00 AM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "6/15/2024 10:15",
    "plant_id": "Green-tek-A20745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "10:15:00 AM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "6/15/2024 10:30",
    "plant_id": "Green-tek-A20746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "10:30:00 AM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "6/15/2024 10:45",
    "plant_id": "Green-tek-A20747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "10:45:00 AM",
    "power": 1.091,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "6/15/2024 11:00",
    "plant_id": "Green-tek-A20748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "11:00:00 AM",
    "power": 1.172,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "6/15/2024 11:15",
    "plant_id": "Green-tek-A20749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "11:15:00 AM",
    "power": 1.234,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "6/15/2024 11:30",
    "plant_id": "Green-tek-A20750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "11:30:00 AM",
    "power": 1.272,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "6/15/2024 11:45",
    "plant_id": "Green-tek-A20751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "11:45:00 AM",
    "power": 1.285,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "6/15/2024 12:00",
    "plant_id": "Green-tek-A20752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "12:00:00 PM",
    "power": 1.272,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "6/15/2024 12:15",
    "plant_id": "Green-tek-A20753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "12:15:00 PM",
    "power": 1.234,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "6/15/2024 12:30",
    "plant_id": "Green-tek-A20754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "12:30:00 PM",
    "power": 1.172,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "6/15/2024 12:45",
    "plant_id": "Green-tek-A20755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "12:45:00 PM",
    "power": 1.091,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "6/15/2024 13:00",
    "plant_id": "Green-tek-A20756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "1:00:00 PM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "6/15/2024 13:15",
    "plant_id": "Green-tek-A20757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "1:15:00 PM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "6/15/2024 13:30",
    "plant_id": "Green-tek-A20758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "1:30:00 PM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "6/15/2024 13:45",
    "plant_id": "Green-tek-A20759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "1:45:00 PM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "6/15/2024 14:00",
    "plant_id": "Green-tek-A20760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "2:00:00 PM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "6/15/2024 14:15",
    "plant_id": "Green-tek-A20761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "2:15:00 PM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "6/15/2024 14:30",
    "plant_id": "Green-tek-A20762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "2:30:00 PM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "6/15/2024 14:45",
    "plant_id": "Green-tek-A20763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "2:45:00 PM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "6/15/2024 15:00",
    "plant_id": "Green-tek-A20764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "3:00:00 PM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "6/15/2024 15:15",
    "plant_id": "Green-tek-A20765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "3:15:00 PM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "6/15/2024 15:30",
    "plant_id": "Green-tek-A20766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "3:30:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/15/2024 15:45",
    "plant_id": "Green-tek-A20767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "3:45:00 PM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "6/15/2024 16:00",
    "plant_id": "Green-tek-A20768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/15/2024 16:15",
    "plant_id": "Green-tek-A20769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/15/2024 16:30",
    "plant_id": "Green-tek-A20770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/15/2024 16:45",
    "plant_id": "Green-tek-A20771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/15/2024 17:00",
    "plant_id": "Green-tek-A20772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/15/2024 17:15",
    "plant_id": "Green-tek-A20773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/15/2024 17:30",
    "plant_id": "Green-tek-A20774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/15/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/16/2024 6:00",
    "plant_id": "Green-tek-A20775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/16/2024 6:15",
    "plant_id": "Green-tek-A20776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/16/2024 6:30",
    "plant_id": "Green-tek-A20777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/16/2024 6:45",
    "plant_id": "Green-tek-A20778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/16/2024 7:00",
    "plant_id": "Green-tek-A20779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/16/2024 7:15",
    "plant_id": "Green-tek-A20780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/16/2024 7:30",
    "plant_id": "Green-tek-A20781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/16/2024 7:45",
    "plant_id": "Green-tek-A20782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "7:45:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/16/2024 8:00",
    "plant_id": "Green-tek-A20783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "8:00:00 AM",
    "power": 0.126,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/16/2024 8:15",
    "plant_id": "Green-tek-A20784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "8:15:00 AM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "6/16/2024 8:30",
    "plant_id": "Green-tek-A20785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "8:30:00 AM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/16/2024 8:45",
    "plant_id": "Green-tek-A20786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "8:45:00 AM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/16/2024 9:00",
    "plant_id": "Green-tek-A20787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "9:00:00 AM",
    "power": 0.364,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/16/2024 9:15",
    "plant_id": "Green-tek-A20788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "9:15:00 AM",
    "power": 0.451,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "6/16/2024 9:30",
    "plant_id": "Green-tek-A20789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "9:30:00 AM",
    "power": 0.547,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/16/2024 9:45",
    "plant_id": "Green-tek-A20790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "9:45:00 AM",
    "power": 0.651,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "6/16/2024 10:00",
    "plant_id": "Green-tek-A20791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "10:00:00 AM",
    "power": 0.759,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "6/16/2024 10:15",
    "plant_id": "Green-tek-A20792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "10:15:00 AM",
    "power": 0.866,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/16/2024 10:30",
    "plant_id": "Green-tek-A20793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "10:30:00 AM",
    "power": 0.969,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "6/16/2024 10:45",
    "plant_id": "Green-tek-A20794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "10:45:00 AM",
    "power": 1.062,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "6/16/2024 11:00",
    "plant_id": "Green-tek-A20795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "11:00:00 AM",
    "power": 1.141,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "6/16/2024 11:15",
    "plant_id": "Green-tek-A20796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "11:15:00 AM",
    "power": 1.201,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "6/16/2024 11:30",
    "plant_id": "Green-tek-A20797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "11:30:00 AM",
    "power": 1.238,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "6/16/2024 11:45",
    "plant_id": "Green-tek-A20798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "11:45:00 AM",
    "power": 1.251,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "6/16/2024 12:00",
    "plant_id": "Green-tek-A20799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "12:00:00 PM",
    "power": 1.238,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "6/16/2024 12:15",
    "plant_id": "Green-tek-A20800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "12:15:00 PM",
    "power": 1.201,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "6/16/2024 12:30",
    "plant_id": "Green-tek-A20801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "12:30:00 PM",
    "power": 1.141,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "6/16/2024 12:45",
    "plant_id": "Green-tek-A20802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "12:45:00 PM",
    "power": 1.062,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "6/16/2024 13:00",
    "plant_id": "Green-tek-A20803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "1:00:00 PM",
    "power": 0.969,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "6/16/2024 13:15",
    "plant_id": "Green-tek-A20804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "1:15:00 PM",
    "power": 0.866,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/16/2024 13:30",
    "plant_id": "Green-tek-A20805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "1:30:00 PM",
    "power": 0.759,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "6/16/2024 13:45",
    "plant_id": "Green-tek-A20806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "1:45:00 PM",
    "power": 0.651,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "6/16/2024 14:00",
    "plant_id": "Green-tek-A20807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "2:00:00 PM",
    "power": 0.547,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/16/2024 14:15",
    "plant_id": "Green-tek-A20808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "2:15:00 PM",
    "power": 0.451,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "6/16/2024 14:30",
    "plant_id": "Green-tek-A20809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "2:30:00 PM",
    "power": 0.364,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/16/2024 14:45",
    "plant_id": "Green-tek-A20810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "2:45:00 PM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/16/2024 15:00",
    "plant_id": "Green-tek-A20811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "3:00:00 PM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/16/2024 15:15",
    "plant_id": "Green-tek-A20812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "3:15:00 PM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "6/16/2024 15:30",
    "plant_id": "Green-tek-A20813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "3:30:00 PM",
    "power": 0.126,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/16/2024 15:45",
    "plant_id": "Green-tek-A20814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "3:45:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/16/2024 16:00",
    "plant_id": "Green-tek-A20815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/16/2024 16:15",
    "plant_id": "Green-tek-A20816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/16/2024 16:30",
    "plant_id": "Green-tek-A20817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/16/2024 16:45",
    "plant_id": "Green-tek-A20818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/16/2024 17:00",
    "plant_id": "Green-tek-A20819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/16/2024 17:15",
    "plant_id": "Green-tek-A20820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/16/2024 17:30",
    "plant_id": "Green-tek-A20821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/16/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/17/2024 6:00",
    "plant_id": "Green-tek-A20822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/17/2024 6:15",
    "plant_id": "Green-tek-A20823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/17/2024 6:30",
    "plant_id": "Green-tek-A20824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/17/2024 6:45",
    "plant_id": "Green-tek-A20825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/17/2024 7:00",
    "plant_id": "Green-tek-A20826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/17/2024 7:15",
    "plant_id": "Green-tek-A20827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/17/2024 7:30",
    "plant_id": "Green-tek-A20828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "6/17/2024 7:45",
    "plant_id": "Green-tek-A20829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "7:45:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/17/2024 8:00",
    "plant_id": "Green-tek-A20830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "8:00:00 AM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "6/17/2024 8:15",
    "plant_id": "Green-tek-A20831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "8:15:00 AM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "6/17/2024 8:30",
    "plant_id": "Green-tek-A20832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "8:30:00 AM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "6/17/2024 8:45",
    "plant_id": "Green-tek-A20833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "8:45:00 AM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "6/17/2024 9:00",
    "plant_id": "Green-tek-A20834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "9:00:00 AM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "6/17/2024 9:15",
    "plant_id": "Green-tek-A20835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "9:15:00 AM",
    "power": 0.422,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "6/17/2024 9:30",
    "plant_id": "Green-tek-A20836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "9:30:00 AM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "6/17/2024 9:45",
    "plant_id": "Green-tek-A20837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "9:45:00 AM",
    "power": 0.61,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "6/17/2024 10:00",
    "plant_id": "Green-tek-A20838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "10:00:00 AM",
    "power": 0.71,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "6/17/2024 10:15",
    "plant_id": "Green-tek-A20839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "10:15:00 AM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "6/17/2024 10:30",
    "plant_id": "Green-tek-A20840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "10:30:00 AM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "6/17/2024 10:45",
    "plant_id": "Green-tek-A20841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "10:45:00 AM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "6/17/2024 11:00",
    "plant_id": "Green-tek-A20842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "11:00:00 AM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "6/17/2024 11:15",
    "plant_id": "Green-tek-A20843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "11:15:00 AM",
    "power": 1.124,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "6/17/2024 11:30",
    "plant_id": "Green-tek-A20844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "11:30:00 AM",
    "power": 1.159,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "6/17/2024 11:45",
    "plant_id": "Green-tek-A20845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "11:45:00 AM",
    "power": 1.171,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "6/17/2024 12:00",
    "plant_id": "Green-tek-A20846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "12:00:00 PM",
    "power": 1.159,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "6/17/2024 12:15",
    "plant_id": "Green-tek-A20847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "12:15:00 PM",
    "power": 1.124,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "6/17/2024 12:30",
    "plant_id": "Green-tek-A20848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "12:30:00 PM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "6/17/2024 12:45",
    "plant_id": "Green-tek-A20849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "12:45:00 PM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "6/17/2024 13:00",
    "plant_id": "Green-tek-A20850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "1:00:00 PM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "6/17/2024 13:15",
    "plant_id": "Green-tek-A20851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "1:15:00 PM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "6/17/2024 13:30",
    "plant_id": "Green-tek-A20852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "1:30:00 PM",
    "power": 0.71,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "6/17/2024 13:45",
    "plant_id": "Green-tek-A20853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "1:45:00 PM",
    "power": 0.61,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "6/17/2024 14:00",
    "plant_id": "Green-tek-A20854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "2:00:00 PM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "6/17/2024 14:15",
    "plant_id": "Green-tek-A20855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "2:15:00 PM",
    "power": 0.422,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "6/17/2024 14:30",
    "plant_id": "Green-tek-A20856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "2:30:00 PM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "6/17/2024 14:45",
    "plant_id": "Green-tek-A20857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "2:45:00 PM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "6/17/2024 15:00",
    "plant_id": "Green-tek-A20858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "3:00:00 PM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "6/17/2024 15:15",
    "plant_id": "Green-tek-A20859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "3:15:00 PM",
    "power": 0.159,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "6/17/2024 15:30",
    "plant_id": "Green-tek-A20860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "3:30:00 PM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "6/17/2024 15:45",
    "plant_id": "Green-tek-A20861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "3:45:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/17/2024 16:00",
    "plant_id": "Green-tek-A20862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "6/17/2024 16:15",
    "plant_id": "Green-tek-A20863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/17/2024 16:30",
    "plant_id": "Green-tek-A20864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/17/2024 16:45",
    "plant_id": "Green-tek-A20865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/17/2024 17:00",
    "plant_id": "Green-tek-A20866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/17/2024 17:15",
    "plant_id": "Green-tek-A20867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/17/2024 17:30",
    "plant_id": "Green-tek-A20868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/17/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/18/2024 6:00",
    "plant_id": "Green-tek-A20869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/18/2024 6:15",
    "plant_id": "Green-tek-A20870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/18/2024 6:30",
    "plant_id": "Green-tek-A20871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/18/2024 6:45",
    "plant_id": "Green-tek-A20872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/18/2024 7:00",
    "plant_id": "Green-tek-A20873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/18/2024 7:15",
    "plant_id": "Green-tek-A20874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/18/2024 7:30",
    "plant_id": "Green-tek-A20875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/18/2024 7:45",
    "plant_id": "Green-tek-A20876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "6/18/2024 8:00",
    "plant_id": "Green-tek-A20877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "8:00:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "6/18/2024 8:15",
    "plant_id": "Green-tek-A20878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "8:15:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "6/18/2024 8:30",
    "plant_id": "Green-tek-A20879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "8:30:00 AM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/18/2024 8:45",
    "plant_id": "Green-tek-A20880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "8:45:00 AM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "6/18/2024 9:00",
    "plant_id": "Green-tek-A20881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "9:00:00 AM",
    "power": 0.319,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "6/18/2024 9:15",
    "plant_id": "Green-tek-A20882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "9:15:00 AM",
    "power": 0.396,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "6/18/2024 9:30",
    "plant_id": "Green-tek-A20883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "9:30:00 AM",
    "power": 0.48,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "6/18/2024 9:45",
    "plant_id": "Green-tek-A20884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "9:45:00 AM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "6/18/2024 10:00",
    "plant_id": "Green-tek-A20885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "10:00:00 AM",
    "power": 0.666,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "6/18/2024 10:15",
    "plant_id": "Green-tek-A20886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "10:15:00 AM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "6/18/2024 10:30",
    "plant_id": "Green-tek-A20887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "10:30:00 AM",
    "power": 0.851,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "6/18/2024 10:45",
    "plant_id": "Green-tek-A20888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "10:45:00 AM",
    "power": 0.933,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "6/18/2024 11:00",
    "plant_id": "Green-tek-A20889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "11:00:00 AM",
    "power": 1.002,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "6/18/2024 11:15",
    "plant_id": "Green-tek-A20890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "11:15:00 AM",
    "power": 1.054,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "6/18/2024 11:30",
    "plant_id": "Green-tek-A20891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "11:30:00 AM",
    "power": 1.087,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "6/18/2024 11:45",
    "plant_id": "Green-tek-A20892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "11:45:00 AM",
    "power": 1.098,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "6/18/2024 12:00",
    "plant_id": "Green-tek-A20893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "12:00:00 PM",
    "power": 1.087,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "6/18/2024 12:15",
    "plant_id": "Green-tek-A20894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "12:15:00 PM",
    "power": 1.054,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "6/18/2024 12:30",
    "plant_id": "Green-tek-A20895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "12:30:00 PM",
    "power": 1.002,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "6/18/2024 12:45",
    "plant_id": "Green-tek-A20896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "12:45:00 PM",
    "power": 0.933,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "6/18/2024 13:00",
    "plant_id": "Green-tek-A20897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "1:00:00 PM",
    "power": 0.851,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "6/18/2024 13:15",
    "plant_id": "Green-tek-A20898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "1:15:00 PM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "6/18/2024 13:30",
    "plant_id": "Green-tek-A20899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "1:30:00 PM",
    "power": 0.666,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "6/18/2024 13:45",
    "plant_id": "Green-tek-A20900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "1:45:00 PM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "6/18/2024 14:00",
    "plant_id": "Green-tek-A20901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "2:00:00 PM",
    "power": 0.48,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "6/18/2024 14:15",
    "plant_id": "Green-tek-A20902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "2:15:00 PM",
    "power": 0.396,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "6/18/2024 14:30",
    "plant_id": "Green-tek-A20903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "2:30:00 PM",
    "power": 0.319,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "6/18/2024 14:45",
    "plant_id": "Green-tek-A20904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "2:45:00 PM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "6/18/2024 15:00",
    "plant_id": "Green-tek-A20905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "3:00:00 PM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/18/2024 15:15",
    "plant_id": "Green-tek-A20906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "3:15:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "6/18/2024 15:30",
    "plant_id": "Green-tek-A20907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "3:30:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "6/18/2024 15:45",
    "plant_id": "Green-tek-A20908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "6/18/2024 16:00",
    "plant_id": "Green-tek-A20909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/18/2024 16:15",
    "plant_id": "Green-tek-A20910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/18/2024 16:30",
    "plant_id": "Green-tek-A20911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/18/2024 16:45",
    "plant_id": "Green-tek-A20912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/18/2024 17:00",
    "plant_id": "Green-tek-A20913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/18/2024 17:15",
    "plant_id": "Green-tek-A20914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/18/2024 17:30",
    "plant_id": "Green-tek-A20915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/18/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/19/2024 6:00",
    "plant_id": "Green-tek-A20916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/19/2024 6:15",
    "plant_id": "Green-tek-A20917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/19/2024 6:30",
    "plant_id": "Green-tek-A20918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/19/2024 6:45",
    "plant_id": "Green-tek-A20919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/19/2024 7:00",
    "plant_id": "Green-tek-A20920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/19/2024 7:15",
    "plant_id": "Green-tek-A20921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/19/2024 7:30",
    "plant_id": "Green-tek-A20922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/19/2024 7:45",
    "plant_id": "Green-tek-A20923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "6/19/2024 8:00",
    "plant_id": "Green-tek-A20924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "8:00:00 AM",
    "power": 0.106,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "6/19/2024 8:15",
    "plant_id": "Green-tek-A20925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "8:15:00 AM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "6/19/2024 8:30",
    "plant_id": "Green-tek-A20926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "8:30:00 AM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "6/19/2024 8:45",
    "plant_id": "Green-tek-A20927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "8:45:00 AM",
    "power": 0.243,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "6/19/2024 9:00",
    "plant_id": "Green-tek-A20928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "9:00:00 AM",
    "power": 0.307,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "6/19/2024 9:15",
    "plant_id": "Green-tek-A20929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "9:15:00 AM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "6/19/2024 9:30",
    "plant_id": "Green-tek-A20930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "9:30:00 AM",
    "power": 0.461,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "6/19/2024 9:45",
    "plant_id": "Green-tek-A20931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "9:45:00 AM",
    "power": 0.549,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/19/2024 10:00",
    "plant_id": "Green-tek-A20932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "10:00:00 AM",
    "power": 0.64,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "6/19/2024 10:15",
    "plant_id": "Green-tek-A20933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "10:15:00 AM",
    "power": 0.73,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "6/19/2024 10:30",
    "plant_id": "Green-tek-A20934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "10:30:00 AM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "6/19/2024 10:45",
    "plant_id": "Green-tek-A20935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "10:45:00 AM",
    "power": 0.896,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "6/19/2024 11:00",
    "plant_id": "Green-tek-A20936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "11:00:00 AM",
    "power": 0.962,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "6/19/2024 11:15",
    "plant_id": "Green-tek-A20937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "11:15:00 AM",
    "power": 1.012,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "6/19/2024 11:30",
    "plant_id": "Green-tek-A20938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "11:30:00 AM",
    "power": 1.044,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "6/19/2024 11:45",
    "plant_id": "Green-tek-A20939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "11:45:00 AM",
    "power": 1.055,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "6/19/2024 12:00",
    "plant_id": "Green-tek-A20940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "12:00:00 PM",
    "power": 1.044,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "6/19/2024 12:15",
    "plant_id": "Green-tek-A20941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "12:15:00 PM",
    "power": 1.012,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "6/19/2024 12:30",
    "plant_id": "Green-tek-A20942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "12:30:00 PM",
    "power": 0.962,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "6/19/2024 12:45",
    "plant_id": "Green-tek-A20943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "12:45:00 PM",
    "power": 0.896,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "6/19/2024 13:00",
    "plant_id": "Green-tek-A20944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "1:00:00 PM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "6/19/2024 13:15",
    "plant_id": "Green-tek-A20945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "1:15:00 PM",
    "power": 0.73,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "6/19/2024 13:30",
    "plant_id": "Green-tek-A20946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "1:30:00 PM",
    "power": 0.64,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "6/19/2024 13:45",
    "plant_id": "Green-tek-A20947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "1:45:00 PM",
    "power": 0.549,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/19/2024 14:00",
    "plant_id": "Green-tek-A20948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "2:00:00 PM",
    "power": 0.461,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "6/19/2024 14:15",
    "plant_id": "Green-tek-A20949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "2:15:00 PM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "6/19/2024 14:30",
    "plant_id": "Green-tek-A20950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "2:30:00 PM",
    "power": 0.307,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "6/19/2024 14:45",
    "plant_id": "Green-tek-A20951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "2:45:00 PM",
    "power": 0.243,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "6/19/2024 15:00",
    "plant_id": "Green-tek-A20952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "3:00:00 PM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "6/19/2024 15:15",
    "plant_id": "Green-tek-A20953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "3:15:00 PM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "6/19/2024 15:30",
    "plant_id": "Green-tek-A20954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "3:30:00 PM",
    "power": 0.106,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "6/19/2024 15:45",
    "plant_id": "Green-tek-A20955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "6/19/2024 16:00",
    "plant_id": "Green-tek-A20956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/19/2024 16:15",
    "plant_id": "Green-tek-A20957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/19/2024 16:30",
    "plant_id": "Green-tek-A20958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/19/2024 16:45",
    "plant_id": "Green-tek-A20959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/19/2024 17:00",
    "plant_id": "Green-tek-A20960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/19/2024 17:15",
    "plant_id": "Green-tek-A20961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/19/2024 17:30",
    "plant_id": "Green-tek-A20962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/19/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/20/2024 6:00",
    "plant_id": "Green-tek-A20963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/20/2024 6:15",
    "plant_id": "Green-tek-A20964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/20/2024 6:30",
    "plant_id": "Green-tek-A20965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/20/2024 6:45",
    "plant_id": "Green-tek-A20966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/20/2024 7:00",
    "plant_id": "Green-tek-A20967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/20/2024 7:15",
    "plant_id": "Green-tek-A20968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/20/2024 7:30",
    "plant_id": "Green-tek-A20969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/20/2024 7:45",
    "plant_id": "Green-tek-A20970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "6/20/2024 8:00",
    "plant_id": "Green-tek-A20971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "8:00:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "6/20/2024 8:15",
    "plant_id": "Green-tek-A20972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "8:15:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "6/20/2024 8:30",
    "plant_id": "Green-tek-A20973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "8:30:00 AM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/20/2024 8:45",
    "plant_id": "Green-tek-A20974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "8:45:00 AM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "6/20/2024 9:00",
    "plant_id": "Green-tek-A20975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "9:00:00 AM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "6/20/2024 9:15",
    "plant_id": "Green-tek-A20976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "9:15:00 AM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "6/20/2024 9:30",
    "plant_id": "Green-tek-A20977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "9:30:00 AM",
    "power": 0.482,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "6/20/2024 9:45",
    "plant_id": "Green-tek-A20978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "9:45:00 AM",
    "power": 0.573,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "6/20/2024 10:00",
    "plant_id": "Green-tek-A20979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "10:00:00 AM",
    "power": 0.668,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "6/20/2024 10:15",
    "plant_id": "Green-tek-A20980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "10:15:00 AM",
    "power": 0.762,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "6/20/2024 10:30",
    "plant_id": "Green-tek-A20981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "10:30:00 AM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "6/20/2024 10:45",
    "plant_id": "Green-tek-A20982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "10:45:00 AM",
    "power": 0.935,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "6/20/2024 11:00",
    "plant_id": "Green-tek-A20983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "11:00:00 AM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "6/20/2024 11:15",
    "plant_id": "Green-tek-A20984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "11:15:00 AM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "6/20/2024 11:30",
    "plant_id": "Green-tek-A20985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "11:30:00 AM",
    "power": 1.089,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "6/20/2024 11:45",
    "plant_id": "Green-tek-A20986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "11:45:00 AM",
    "power": 1.101,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "6/20/2024 12:00",
    "plant_id": "Green-tek-A20987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "12:00:00 PM",
    "power": 1.089,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "6/20/2024 12:15",
    "plant_id": "Green-tek-A20988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "12:15:00 PM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "6/20/2024 12:30",
    "plant_id": "Green-tek-A20989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "12:30:00 PM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "6/20/2024 12:45",
    "plant_id": "Green-tek-A20990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "12:45:00 PM",
    "power": 0.935,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "6/20/2024 13:00",
    "plant_id": "Green-tek-A20991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "1:00:00 PM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "6/20/2024 13:15",
    "plant_id": "Green-tek-A20992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "1:15:00 PM",
    "power": 0.762,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "6/20/2024 13:30",
    "plant_id": "Green-tek-A20993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "1:30:00 PM",
    "power": 0.668,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "6/20/2024 13:45",
    "plant_id": "Green-tek-A20994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "1:45:00 PM",
    "power": 0.573,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "6/20/2024 14:00",
    "plant_id": "Green-tek-A20995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "2:00:00 PM",
    "power": 0.482,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "6/20/2024 14:15",
    "plant_id": "Green-tek-A20996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "2:15:00 PM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "6/20/2024 14:30",
    "plant_id": "Green-tek-A20997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "2:30:00 PM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "6/20/2024 14:45",
    "plant_id": "Green-tek-A20998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "2:45:00 PM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "6/20/2024 15:00",
    "plant_id": "Green-tek-A20999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "3:00:00 PM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/20/2024 15:15",
    "plant_id": "Green-tek-A21000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "3:15:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "6/20/2024 15:30",
    "plant_id": "Green-tek-A21001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "3:30:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "6/20/2024 15:45",
    "plant_id": "Green-tek-A21002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "6/20/2024 16:00",
    "plant_id": "Green-tek-A21003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "6/20/2024 16:15",
    "plant_id": "Green-tek-A21004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "6/20/2024 16:30",
    "plant_id": "Green-tek-A21005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "6/20/2024 16:45",
    "plant_id": "Green-tek-A21006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/20/2024 17:00",
    "plant_id": "Green-tek-A21007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/20/2024 17:15",
    "plant_id": "Green-tek-A21008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/20/2024 17:30",
    "plant_id": "Green-tek-A21009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/20/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/21/2024 6:00",
    "plant_id": "Green-tek-A21010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/21/2024 6:15",
    "plant_id": "Green-tek-A21011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/21/2024 6:30",
    "plant_id": "Green-tek-A21012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/21/2024 6:45",
    "plant_id": "Green-tek-A21013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/21/2024 7:00",
    "plant_id": "Green-tek-A21014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/21/2024 7:15",
    "plant_id": "Green-tek-A21015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/21/2024 7:30",
    "plant_id": "Green-tek-A21016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/21/2024 7:45",
    "plant_id": "Green-tek-A21017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/21/2024 8:00",
    "plant_id": "Green-tek-A21018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "8:00:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/21/2024 8:15",
    "plant_id": "Green-tek-A21019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "8:15:00 AM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "6/21/2024 8:30",
    "plant_id": "Green-tek-A21020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "8:30:00 AM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "6/21/2024 8:45",
    "plant_id": "Green-tek-A21021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "8:45:00 AM",
    "power": 0.199,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "6/21/2024 9:00",
    "plant_id": "Green-tek-A21022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "9:00:00 AM",
    "power": 0.252,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "6/21/2024 9:15",
    "plant_id": "Green-tek-A21023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "9:15:00 AM",
    "power": 0.312,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "6/21/2024 9:30",
    "plant_id": "Green-tek-A21024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "9:30:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "6/21/2024 9:45",
    "plant_id": "Green-tek-A21025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "9:45:00 AM",
    "power": 0.451,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "6/21/2024 10:00",
    "plant_id": "Green-tek-A21026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "10:00:00 AM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "6/21/2024 10:15",
    "plant_id": "Green-tek-A21027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "10:15:00 AM",
    "power": 0.6,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "6/21/2024 10:30",
    "plant_id": "Green-tek-A21028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "10:30:00 AM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "6/21/2024 10:45",
    "plant_id": "Green-tek-A21029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "10:45:00 AM",
    "power": 0.736,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "6/21/2024 11:00",
    "plant_id": "Green-tek-A21030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "11:00:00 AM",
    "power": 0.79,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "6/21/2024 11:15",
    "plant_id": "Green-tek-A21031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "11:15:00 AM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "6/21/2024 11:30",
    "plant_id": "Green-tek-A21032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "11:30:00 AM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "6/21/2024 11:45",
    "plant_id": "Green-tek-A21033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "11:45:00 AM",
    "power": 0.866,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/21/2024 12:00",
    "plant_id": "Green-tek-A21034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "12:00:00 PM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "6/21/2024 12:15",
    "plant_id": "Green-tek-A21035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "12:15:00 PM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "6/21/2024 12:30",
    "plant_id": "Green-tek-A21036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "12:30:00 PM",
    "power": 0.79,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "6/21/2024 12:45",
    "plant_id": "Green-tek-A21037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "12:45:00 PM",
    "power": 0.736,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "6/21/2024 13:00",
    "plant_id": "Green-tek-A21038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "1:00:00 PM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "6/21/2024 13:15",
    "plant_id": "Green-tek-A21039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "1:15:00 PM",
    "power": 0.6,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "6/21/2024 13:30",
    "plant_id": "Green-tek-A21040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "1:30:00 PM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "6/21/2024 13:45",
    "plant_id": "Green-tek-A21041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "1:45:00 PM",
    "power": 0.451,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "6/21/2024 14:00",
    "plant_id": "Green-tek-A21042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "2:00:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "6/21/2024 14:15",
    "plant_id": "Green-tek-A21043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "2:15:00 PM",
    "power": 0.312,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "6/21/2024 14:30",
    "plant_id": "Green-tek-A21044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "2:30:00 PM",
    "power": 0.252,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "6/21/2024 14:45",
    "plant_id": "Green-tek-A21045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "2:45:00 PM",
    "power": 0.199,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "6/21/2024 15:00",
    "plant_id": "Green-tek-A21046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "3:00:00 PM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "6/21/2024 15:15",
    "plant_id": "Green-tek-A21047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "3:15:00 PM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "6/21/2024 15:30",
    "plant_id": "Green-tek-A21048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "3:30:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/21/2024 15:45",
    "plant_id": "Green-tek-A21049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/21/2024 16:00",
    "plant_id": "Green-tek-A21050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/21/2024 16:15",
    "plant_id": "Green-tek-A21051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/21/2024 16:30",
    "plant_id": "Green-tek-A21052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/21/2024 16:45",
    "plant_id": "Green-tek-A21053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/21/2024 17:00",
    "plant_id": "Green-tek-A21054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/21/2024 17:15",
    "plant_id": "Green-tek-A21055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/21/2024 17:30",
    "plant_id": "Green-tek-A21056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/21/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/22/2024 6:00",
    "plant_id": "Green-tek-A21057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/22/2024 6:15",
    "plant_id": "Green-tek-A21058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/22/2024 6:30",
    "plant_id": "Green-tek-A21059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/22/2024 6:45",
    "plant_id": "Green-tek-A21060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/22/2024 7:00",
    "plant_id": "Green-tek-A21061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/22/2024 7:15",
    "plant_id": "Green-tek-A21062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/22/2024 7:30",
    "plant_id": "Green-tek-A21063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/22/2024 7:45",
    "plant_id": "Green-tek-A21064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/22/2024 8:00",
    "plant_id": "Green-tek-A21065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "8:00:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/22/2024 8:15",
    "plant_id": "Green-tek-A21066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "8:15:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "6/22/2024 8:30",
    "plant_id": "Green-tek-A21067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "8:30:00 AM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/22/2024 8:45",
    "plant_id": "Green-tek-A21068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "8:45:00 AM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "6/22/2024 9:00",
    "plant_id": "Green-tek-A21069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "9:00:00 AM",
    "power": 0.367,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "6/22/2024 9:15",
    "plant_id": "Green-tek-A21070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "9:15:00 AM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "6/22/2024 9:30",
    "plant_id": "Green-tek-A21071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "9:30:00 AM",
    "power": 0.553,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "6/22/2024 9:45",
    "plant_id": "Green-tek-A21072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "9:45:00 AM",
    "power": 0.657,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "6/22/2024 10:00",
    "plant_id": "Green-tek-A21073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "10:00:00 AM",
    "power": 0.766,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "6/22/2024 10:15",
    "plant_id": "Green-tek-A21074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "10:15:00 AM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "6/22/2024 10:30",
    "plant_id": "Green-tek-A21075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "10:30:00 AM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "6/22/2024 10:45",
    "plant_id": "Green-tek-A21076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "10:45:00 AM",
    "power": 1.073,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "6/22/2024 11:00",
    "plant_id": "Green-tek-A21077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "11:00:00 AM",
    "power": 1.152,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "6/22/2024 11:15",
    "plant_id": "Green-tek-A21078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "11:15:00 AM",
    "power": 1.213,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "6/22/2024 11:30",
    "plant_id": "Green-tek-A21079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "11:30:00 AM",
    "power": 1.25,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "6/22/2024 11:45",
    "plant_id": "Green-tek-A21080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "11:45:00 AM",
    "power": 1.263,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "6/22/2024 12:00",
    "plant_id": "Green-tek-A21081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "12:00:00 PM",
    "power": 1.25,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "6/22/2024 12:15",
    "plant_id": "Green-tek-A21082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "12:15:00 PM",
    "power": 1.213,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "6/22/2024 12:30",
    "plant_id": "Green-tek-A21083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "12:30:00 PM",
    "power": 1.152,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "6/22/2024 12:45",
    "plant_id": "Green-tek-A21084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "12:45:00 PM",
    "power": 1.073,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "6/22/2024 13:00",
    "plant_id": "Green-tek-A21085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "1:00:00 PM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "6/22/2024 13:15",
    "plant_id": "Green-tek-A21086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "1:15:00 PM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "6/22/2024 13:30",
    "plant_id": "Green-tek-A21087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "1:30:00 PM",
    "power": 0.766,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "6/22/2024 13:45",
    "plant_id": "Green-tek-A21088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "1:45:00 PM",
    "power": 0.657,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "6/22/2024 14:00",
    "plant_id": "Green-tek-A21089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "2:00:00 PM",
    "power": 0.553,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "6/22/2024 14:15",
    "plant_id": "Green-tek-A21090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "2:15:00 PM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "6/22/2024 14:30",
    "plant_id": "Green-tek-A21091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "2:30:00 PM",
    "power": 0.367,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "6/22/2024 14:45",
    "plant_id": "Green-tek-A21092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "2:45:00 PM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "6/22/2024 15:00",
    "plant_id": "Green-tek-A21093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "3:00:00 PM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/22/2024 15:15",
    "plant_id": "Green-tek-A21094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "3:15:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "6/22/2024 15:30",
    "plant_id": "Green-tek-A21095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "3:30:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/22/2024 15:45",
    "plant_id": "Green-tek-A21096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/22/2024 16:00",
    "plant_id": "Green-tek-A21097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "6/22/2024 16:15",
    "plant_id": "Green-tek-A21098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/22/2024 16:30",
    "plant_id": "Green-tek-A21099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/22/2024 16:45",
    "plant_id": "Green-tek-A21100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/22/2024 17:00",
    "plant_id": "Green-tek-A21101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/22/2024 17:15",
    "plant_id": "Green-tek-A21102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/22/2024 17:30",
    "plant_id": "Green-tek-A21103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/22/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/23/2024 6:00",
    "plant_id": "Green-tek-A21104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/23/2024 6:15",
    "plant_id": "Green-tek-A21105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/23/2024 6:30",
    "plant_id": "Green-tek-A21106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/23/2024 6:45",
    "plant_id": "Green-tek-A21107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/23/2024 7:00",
    "plant_id": "Green-tek-A21108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/23/2024 7:15",
    "plant_id": "Green-tek-A21109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/23/2024 7:30",
    "plant_id": "Green-tek-A21110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/23/2024 7:45",
    "plant_id": "Green-tek-A21111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "7:45:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/23/2024 8:00",
    "plant_id": "Green-tek-A21112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "8:00:00 AM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/23/2024 8:15",
    "plant_id": "Green-tek-A21113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "8:15:00 AM",
    "power": 0.17,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "6/23/2024 8:30",
    "plant_id": "Green-tek-A21114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "8:30:00 AM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/23/2024 8:45",
    "plant_id": "Green-tek-A21115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "8:45:00 AM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/23/2024 9:00",
    "plant_id": "Green-tek-A21116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "9:00:00 AM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/23/2024 9:15",
    "plant_id": "Green-tek-A21117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "9:15:00 AM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "6/23/2024 9:30",
    "plant_id": "Green-tek-A21118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "9:30:00 AM",
    "power": 0.55,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/23/2024 9:45",
    "plant_id": "Green-tek-A21119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "9:45:00 AM",
    "power": 0.654,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "6/23/2024 10:00",
    "plant_id": "Green-tek-A21120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "10:00:00 AM",
    "power": 0.762,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "6/23/2024 10:15",
    "plant_id": "Green-tek-A21121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "10:15:00 AM",
    "power": 0.87,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/23/2024 10:30",
    "plant_id": "Green-tek-A21122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "10:30:00 AM",
    "power": 0.973,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "6/23/2024 10:45",
    "plant_id": "Green-tek-A21123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "10:45:00 AM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "6/23/2024 11:00",
    "plant_id": "Green-tek-A21124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "11:00:00 AM",
    "power": 1.146,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "6/23/2024 11:15",
    "plant_id": "Green-tek-A21125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "11:15:00 AM",
    "power": 1.206,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "6/23/2024 11:30",
    "plant_id": "Green-tek-A21126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "11:30:00 AM",
    "power": 1.243,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.32028,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "6/23/2024 11:45",
    "plant_id": "Green-tek-A21127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "11:45:00 AM",
    "power": 1.256,
    "Energy": 0.314,
    "output Energy": 0.314,
    "Ac output": 1.314,
    "dc output": 1.34028,
    "AC_R_i": 0.00040957,
    "AC_S_i": 0.000450527,
    "AC_T_i": 0.00049558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0942011,
    "AC_S_pow": 0.10362121,
    "AC_T_Pow": 0.1139834,
    "AC_R_output": 0.3942,
    "AC_S_output": 0.43362,
    "AC_T_output": 0.476982,
    "dc_current": 0.00230882,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.32028,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000314,
    "carbon offset": 0.3925,
    "trees planted": 8.722222222,
    "DC_R_output": 0.130086,
    "DC_S_output": 0.1430946,
    "DC_T_output": 0.15740406
  },
  {
    "datetime": "6/23/2024 12:00",
    "plant_id": "Green-tek-A21128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "12:00:00 PM",
    "power": 1.243,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "6/23/2024 12:15",
    "plant_id": "Green-tek-A21129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "12:15:00 PM",
    "power": 1.206,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "6/23/2024 12:30",
    "plant_id": "Green-tek-A21130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "12:30:00 PM",
    "power": 1.146,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "6/23/2024 12:45",
    "plant_id": "Green-tek-A21131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "12:45:00 PM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "6/23/2024 13:00",
    "plant_id": "Green-tek-A21132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "1:00:00 PM",
    "power": 0.973,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "6/23/2024 13:15",
    "plant_id": "Green-tek-A21133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "1:15:00 PM",
    "power": 0.87,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "6/23/2024 13:30",
    "plant_id": "Green-tek-A21134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "1:30:00 PM",
    "power": 0.762,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "6/23/2024 13:45",
    "plant_id": "Green-tek-A21135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "1:45:00 PM",
    "power": 0.654,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "6/23/2024 14:00",
    "plant_id": "Green-tek-A21136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "2:00:00 PM",
    "power": 0.55,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/23/2024 14:15",
    "plant_id": "Green-tek-A21137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "2:15:00 PM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "6/23/2024 14:30",
    "plant_id": "Green-tek-A21138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "2:30:00 PM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/23/2024 14:45",
    "plant_id": "Green-tek-A21139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "2:45:00 PM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/23/2024 15:00",
    "plant_id": "Green-tek-A21140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "3:00:00 PM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/23/2024 15:15",
    "plant_id": "Green-tek-A21141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "3:15:00 PM",
    "power": 0.17,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "6/23/2024 15:30",
    "plant_id": "Green-tek-A21142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "3:30:00 PM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "6/23/2024 15:45",
    "plant_id": "Green-tek-A21143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "3:45:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/23/2024 16:00",
    "plant_id": "Green-tek-A21144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/23/2024 16:15",
    "plant_id": "Green-tek-A21145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "6/23/2024 16:30",
    "plant_id": "Green-tek-A21146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/23/2024 16:45",
    "plant_id": "Green-tek-A21147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/23/2024 17:00",
    "plant_id": "Green-tek-A21148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/23/2024 17:15",
    "plant_id": "Green-tek-A21149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/23/2024 17:30",
    "plant_id": "Green-tek-A21150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/23/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/24/2024 6:00",
    "plant_id": "Green-tek-A21151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/24/2024 6:15",
    "plant_id": "Green-tek-A21152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/24/2024 6:30",
    "plant_id": "Green-tek-A21153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/24/2024 6:45",
    "plant_id": "Green-tek-A21154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/24/2024 7:00",
    "plant_id": "Green-tek-A21155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/24/2024 7:15",
    "plant_id": "Green-tek-A21156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/24/2024 7:30",
    "plant_id": "Green-tek-A21157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/24/2024 7:45",
    "plant_id": "Green-tek-A21158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "6/24/2024 8:00",
    "plant_id": "Green-tek-A21159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "8:00:00 AM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "6/24/2024 8:15",
    "plant_id": "Green-tek-A21160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "8:15:00 AM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "6/24/2024 8:30",
    "plant_id": "Green-tek-A21161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "8:30:00 AM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "6/24/2024 8:45",
    "plant_id": "Green-tek-A21162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "8:45:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "6/24/2024 9:00",
    "plant_id": "Green-tek-A21163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "9:00:00 AM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "6/24/2024 9:15",
    "plant_id": "Green-tek-A21164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "9:15:00 AM",
    "power": 0.364,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/24/2024 9:30",
    "plant_id": "Green-tek-A21165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "9:30:00 AM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "6/24/2024 9:45",
    "plant_id": "Green-tek-A21166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "9:45:00 AM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "6/24/2024 10:00",
    "plant_id": "Green-tek-A21167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "10:00:00 AM",
    "power": 0.612,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "6/24/2024 10:15",
    "plant_id": "Green-tek-A21168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "10:15:00 AM",
    "power": 0.699,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "6/24/2024 10:30",
    "plant_id": "Green-tek-A21169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "10:30:00 AM",
    "power": 0.782,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "6/24/2024 10:45",
    "plant_id": "Green-tek-A21170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "10:45:00 AM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "6/24/2024 11:00",
    "plant_id": "Green-tek-A21171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "11:00:00 AM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "6/24/2024 11:15",
    "plant_id": "Green-tek-A21172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "11:15:00 AM",
    "power": 0.968,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "6/24/2024 11:30",
    "plant_id": "Green-tek-A21173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "11:30:00 AM",
    "power": 0.999,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "6/24/2024 11:45",
    "plant_id": "Green-tek-A21174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "11:45:00 AM",
    "power": 1.009,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "6/24/2024 12:00",
    "plant_id": "Green-tek-A21175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "12:00:00 PM",
    "power": 0.999,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "6/24/2024 12:15",
    "plant_id": "Green-tek-A21176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "12:15:00 PM",
    "power": 0.968,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "6/24/2024 12:30",
    "plant_id": "Green-tek-A21177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "12:30:00 PM",
    "power": 0.92,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "6/24/2024 12:45",
    "plant_id": "Green-tek-A21178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "12:45:00 PM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "6/24/2024 13:00",
    "plant_id": "Green-tek-A21179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "1:00:00 PM",
    "power": 0.782,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "6/24/2024 13:15",
    "plant_id": "Green-tek-A21180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "1:15:00 PM",
    "power": 0.699,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "6/24/2024 13:30",
    "plant_id": "Green-tek-A21181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "1:30:00 PM",
    "power": 0.612,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "6/24/2024 13:45",
    "plant_id": "Green-tek-A21182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "1:45:00 PM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "6/24/2024 14:00",
    "plant_id": "Green-tek-A21183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "2:00:00 PM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "6/24/2024 14:15",
    "plant_id": "Green-tek-A21184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "2:15:00 PM",
    "power": 0.364,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/24/2024 14:30",
    "plant_id": "Green-tek-A21185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "2:30:00 PM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "6/24/2024 14:45",
    "plant_id": "Green-tek-A21186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "2:45:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "6/24/2024 15:00",
    "plant_id": "Green-tek-A21187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "3:00:00 PM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "6/24/2024 15:15",
    "plant_id": "Green-tek-A21188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "3:15:00 PM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "6/24/2024 15:30",
    "plant_id": "Green-tek-A21189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "3:30:00 PM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "6/24/2024 15:45",
    "plant_id": "Green-tek-A21190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "6/24/2024 16:00",
    "plant_id": "Green-tek-A21191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/24/2024 16:15",
    "plant_id": "Green-tek-A21192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/24/2024 16:30",
    "plant_id": "Green-tek-A21193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/24/2024 16:45",
    "plant_id": "Green-tek-A21194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/24/2024 17:00",
    "plant_id": "Green-tek-A21195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/24/2024 17:15",
    "plant_id": "Green-tek-A21196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/24/2024 17:30",
    "plant_id": "Green-tek-A21197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/24/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/25/2024 6:00",
    "plant_id": "Green-tek-A21198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/25/2024 6:15",
    "plant_id": "Green-tek-A21199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/25/2024 6:30",
    "plant_id": "Green-tek-A21200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/25/2024 6:45",
    "plant_id": "Green-tek-A21201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/25/2024 7:00",
    "plant_id": "Green-tek-A21202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/25/2024 7:15",
    "plant_id": "Green-tek-A21203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/25/2024 7:30",
    "plant_id": "Green-tek-A21204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/25/2024 7:45",
    "plant_id": "Green-tek-A21205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "7:45:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/25/2024 8:00",
    "plant_id": "Green-tek-A21206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "8:00:00 AM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "6/25/2024 8:15",
    "plant_id": "Green-tek-A21207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "8:15:00 AM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "6/25/2024 8:30",
    "plant_id": "Green-tek-A21208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "8:30:00 AM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "6/25/2024 8:45",
    "plant_id": "Green-tek-A21209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "8:45:00 AM",
    "power": 0.275,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "6/25/2024 9:00",
    "plant_id": "Green-tek-A21210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "9:00:00 AM",
    "power": 0.348,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "6/25/2024 9:15",
    "plant_id": "Green-tek-A21211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "9:15:00 AM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "6/25/2024 9:30",
    "plant_id": "Green-tek-A21212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "9:30:00 AM",
    "power": 0.524,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "6/25/2024 9:45",
    "plant_id": "Green-tek-A21213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "9:45:00 AM",
    "power": 0.623,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "6/25/2024 10:00",
    "plant_id": "Green-tek-A21214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "10:00:00 AM",
    "power": 0.726,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "6/25/2024 10:15",
    "plant_id": "Green-tek-A21215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "10:15:00 AM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "6/25/2024 10:30",
    "plant_id": "Green-tek-A21216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "10:30:00 AM",
    "power": 0.928,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "6/25/2024 10:45",
    "plant_id": "Green-tek-A21217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "10:45:00 AM",
    "power": 1.017,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "6/25/2024 11:00",
    "plant_id": "Green-tek-A21218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "11:00:00 AM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "6/25/2024 11:15",
    "plant_id": "Green-tek-A21219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "11:15:00 AM",
    "power": 1.149,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "6/25/2024 11:30",
    "plant_id": "Green-tek-A21220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "11:30:00 AM",
    "power": 1.185,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "6/25/2024 11:45",
    "plant_id": "Green-tek-A21221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "11:45:00 AM",
    "power": 1.197,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "6/25/2024 12:00",
    "plant_id": "Green-tek-A21222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "12:00:00 PM",
    "power": 1.185,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "6/25/2024 12:15",
    "plant_id": "Green-tek-A21223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "12:15:00 PM",
    "power": 1.149,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "6/25/2024 12:30",
    "plant_id": "Green-tek-A21224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "12:30:00 PM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "6/25/2024 12:45",
    "plant_id": "Green-tek-A21225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "12:45:00 PM",
    "power": 1.017,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "6/25/2024 13:00",
    "plant_id": "Green-tek-A21226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "1:00:00 PM",
    "power": 0.928,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "6/25/2024 13:15",
    "plant_id": "Green-tek-A21227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "1:15:00 PM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "6/25/2024 13:30",
    "plant_id": "Green-tek-A21228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "1:30:00 PM",
    "power": 0.726,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "6/25/2024 13:45",
    "plant_id": "Green-tek-A21229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "1:45:00 PM",
    "power": 0.623,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "6/25/2024 14:00",
    "plant_id": "Green-tek-A21230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "2:00:00 PM",
    "power": 0.524,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "6/25/2024 14:15",
    "plant_id": "Green-tek-A21231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "2:15:00 PM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "6/25/2024 14:30",
    "plant_id": "Green-tek-A21232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "2:30:00 PM",
    "power": 0.348,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "6/25/2024 14:45",
    "plant_id": "Green-tek-A21233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "2:45:00 PM",
    "power": 0.275,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "6/25/2024 15:00",
    "plant_id": "Green-tek-A21234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "3:00:00 PM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "6/25/2024 15:15",
    "plant_id": "Green-tek-A21235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "3:15:00 PM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "6/25/2024 15:30",
    "plant_id": "Green-tek-A21236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "3:30:00 PM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "6/25/2024 15:45",
    "plant_id": "Green-tek-A21237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "3:45:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/25/2024 16:00",
    "plant_id": "Green-tek-A21238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/25/2024 16:15",
    "plant_id": "Green-tek-A21239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/25/2024 16:30",
    "plant_id": "Green-tek-A21240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/25/2024 16:45",
    "plant_id": "Green-tek-A21241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/25/2024 17:00",
    "plant_id": "Green-tek-A21242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/25/2024 17:15",
    "plant_id": "Green-tek-A21243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/25/2024 17:30",
    "plant_id": "Green-tek-A21244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/25/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/26/2024 6:00",
    "plant_id": "Green-tek-A21245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/26/2024 6:15",
    "plant_id": "Green-tek-A21246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/26/2024 6:30",
    "plant_id": "Green-tek-A21247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/26/2024 6:45",
    "plant_id": "Green-tek-A21248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/26/2024 7:00",
    "plant_id": "Green-tek-A21249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/26/2024 7:15",
    "plant_id": "Green-tek-A21250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/26/2024 7:30",
    "plant_id": "Green-tek-A21251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/26/2024 7:45",
    "plant_id": "Green-tek-A21252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "7:45:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/26/2024 8:00",
    "plant_id": "Green-tek-A21253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "8:00:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/26/2024 8:15",
    "plant_id": "Green-tek-A21254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "8:15:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "6/26/2024 8:30",
    "plant_id": "Green-tek-A21255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "8:30:00 AM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "6/26/2024 8:45",
    "plant_id": "Green-tek-A21256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "8:45:00 AM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/26/2024 9:00",
    "plant_id": "Green-tek-A21257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "9:00:00 AM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "6/26/2024 9:15",
    "plant_id": "Green-tek-A21258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "9:15:00 AM",
    "power": 0.307,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "6/26/2024 9:30",
    "plant_id": "Green-tek-A21259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "9:30:00 AM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "6/26/2024 9:45",
    "plant_id": "Green-tek-A21260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "9:45:00 AM",
    "power": 0.444,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "6/26/2024 10:00",
    "plant_id": "Green-tek-A21261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "10:00:00 AM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "6/26/2024 10:15",
    "plant_id": "Green-tek-A21262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "10:15:00 AM",
    "power": 0.59,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "6/26/2024 10:30",
    "plant_id": "Green-tek-A21263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "10:30:00 AM",
    "power": 0.66,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/26/2024 10:45",
    "plant_id": "Green-tek-A21264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "10:45:00 AM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "6/26/2024 11:00",
    "plant_id": "Green-tek-A21265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "11:00:00 AM",
    "power": 0.778,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "6/26/2024 11:15",
    "plant_id": "Green-tek-A21266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "11:15:00 AM",
    "power": 0.818,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "6/26/2024 11:30",
    "plant_id": "Green-tek-A21267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "11:30:00 AM",
    "power": 0.844,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "6/26/2024 11:45",
    "plant_id": "Green-tek-A21268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "11:45:00 AM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "6/26/2024 12:00",
    "plant_id": "Green-tek-A21269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "12:00:00 PM",
    "power": 0.844,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "6/26/2024 12:15",
    "plant_id": "Green-tek-A21270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "12:15:00 PM",
    "power": 0.818,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "6/26/2024 12:30",
    "plant_id": "Green-tek-A21271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "12:30:00 PM",
    "power": 0.778,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "6/26/2024 12:45",
    "plant_id": "Green-tek-A21272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "12:45:00 PM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "6/26/2024 13:00",
    "plant_id": "Green-tek-A21273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "1:00:00 PM",
    "power": 0.66,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "6/26/2024 13:15",
    "plant_id": "Green-tek-A21274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "1:15:00 PM",
    "power": 0.59,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "6/26/2024 13:30",
    "plant_id": "Green-tek-A21275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "1:30:00 PM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "6/26/2024 13:45",
    "plant_id": "Green-tek-A21276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "1:45:00 PM",
    "power": 0.444,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "6/26/2024 14:00",
    "plant_id": "Green-tek-A21277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "2:00:00 PM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "6/26/2024 14:15",
    "plant_id": "Green-tek-A21278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "2:15:00 PM",
    "power": 0.307,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "6/26/2024 14:30",
    "plant_id": "Green-tek-A21279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "2:30:00 PM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "6/26/2024 14:45",
    "plant_id": "Green-tek-A21280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "2:45:00 PM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "6/26/2024 15:00",
    "plant_id": "Green-tek-A21281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "3:00:00 PM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "6/26/2024 15:15",
    "plant_id": "Green-tek-A21282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "3:15:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "6/26/2024 15:30",
    "plant_id": "Green-tek-A21283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "3:30:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "6/26/2024 15:45",
    "plant_id": "Green-tek-A21284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "3:45:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/26/2024 16:00",
    "plant_id": "Green-tek-A21285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/26/2024 16:15",
    "plant_id": "Green-tek-A21286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/26/2024 16:30",
    "plant_id": "Green-tek-A21287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/26/2024 16:45",
    "plant_id": "Green-tek-A21288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/26/2024 17:00",
    "plant_id": "Green-tek-A21289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/26/2024 17:15",
    "plant_id": "Green-tek-A21290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/26/2024 17:30",
    "plant_id": "Green-tek-A21291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/26/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/27/2024 6:00",
    "plant_id": "Green-tek-A21292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/27/2024 6:15",
    "plant_id": "Green-tek-A21293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/27/2024 6:30",
    "plant_id": "Green-tek-A21294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/27/2024 6:45",
    "plant_id": "Green-tek-A21295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/27/2024 7:00",
    "plant_id": "Green-tek-A21296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/27/2024 7:15",
    "plant_id": "Green-tek-A21297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/27/2024 7:30",
    "plant_id": "Green-tek-A21298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/27/2024 7:45",
    "plant_id": "Green-tek-A21299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "7:45:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/27/2024 8:00",
    "plant_id": "Green-tek-A21300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "8:00:00 AM",
    "power": 0.126,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/27/2024 8:15",
    "plant_id": "Green-tek-A21301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "8:15:00 AM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "6/27/2024 8:30",
    "plant_id": "Green-tek-A21302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "8:30:00 AM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/27/2024 8:45",
    "plant_id": "Green-tek-A21303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "8:45:00 AM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/27/2024 9:00",
    "plant_id": "Green-tek-A21304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "9:00:00 AM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/27/2024 9:15",
    "plant_id": "Green-tek-A21305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "9:15:00 AM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "6/27/2024 9:30",
    "plant_id": "Green-tek-A21306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "9:30:00 AM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/27/2024 9:45",
    "plant_id": "Green-tek-A21307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "9:45:00 AM",
    "power": 0.65,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "6/27/2024 10:00",
    "plant_id": "Green-tek-A21308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "10:00:00 AM",
    "power": 0.757,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "6/27/2024 10:15",
    "plant_id": "Green-tek-A21309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "10:15:00 AM",
    "power": 0.865,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "6/27/2024 10:30",
    "plant_id": "Green-tek-A21310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "10:30:00 AM",
    "power": 0.967,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "6/27/2024 10:45",
    "plant_id": "Green-tek-A21311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "10:45:00 AM",
    "power": 1.06,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "6/27/2024 11:00",
    "plant_id": "Green-tek-A21312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "11:00:00 AM",
    "power": 1.139,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "6/27/2024 11:15",
    "plant_id": "Green-tek-A21313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "11:15:00 AM",
    "power": 1.199,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "6/27/2024 11:30",
    "plant_id": "Green-tek-A21314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "11:30:00 AM",
    "power": 1.236,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "6/27/2024 11:45",
    "plant_id": "Green-tek-A21315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "11:45:00 AM",
    "power": 1.248,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "6/27/2024 12:00",
    "plant_id": "Green-tek-A21316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "12:00:00 PM",
    "power": 1.236,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "6/27/2024 12:15",
    "plant_id": "Green-tek-A21317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "12:15:00 PM",
    "power": 1.199,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "6/27/2024 12:30",
    "plant_id": "Green-tek-A21318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "12:30:00 PM",
    "power": 1.139,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "6/27/2024 12:45",
    "plant_id": "Green-tek-A21319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "12:45:00 PM",
    "power": 1.06,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "6/27/2024 13:00",
    "plant_id": "Green-tek-A21320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "1:00:00 PM",
    "power": 0.967,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "6/27/2024 13:15",
    "plant_id": "Green-tek-A21321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "1:15:00 PM",
    "power": 0.865,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "6/27/2024 13:30",
    "plant_id": "Green-tek-A21322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "1:30:00 PM",
    "power": 0.757,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "6/27/2024 13:45",
    "plant_id": "Green-tek-A21323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "1:45:00 PM",
    "power": 0.65,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "6/27/2024 14:00",
    "plant_id": "Green-tek-A21324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "2:00:00 PM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "6/27/2024 14:15",
    "plant_id": "Green-tek-A21325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "2:15:00 PM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "6/27/2024 14:30",
    "plant_id": "Green-tek-A21326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "2:30:00 PM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "6/27/2024 14:45",
    "plant_id": "Green-tek-A21327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "2:45:00 PM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "6/27/2024 15:00",
    "plant_id": "Green-tek-A21328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "3:00:00 PM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/27/2024 15:15",
    "plant_id": "Green-tek-A21329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "3:15:00 PM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "6/27/2024 15:30",
    "plant_id": "Green-tek-A21330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "3:30:00 PM",
    "power": 0.126,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/27/2024 15:45",
    "plant_id": "Green-tek-A21331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "3:45:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "6/27/2024 16:00",
    "plant_id": "Green-tek-A21332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/27/2024 16:15",
    "plant_id": "Green-tek-A21333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/27/2024 16:30",
    "plant_id": "Green-tek-A21334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/27/2024 16:45",
    "plant_id": "Green-tek-A21335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/27/2024 17:00",
    "plant_id": "Green-tek-A21336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/27/2024 17:15",
    "plant_id": "Green-tek-A21337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/27/2024 17:30",
    "plant_id": "Green-tek-A21338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/27/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/28/2024 6:00",
    "plant_id": "Green-tek-A21339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/28/2024 6:15",
    "plant_id": "Green-tek-A21340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/28/2024 6:30",
    "plant_id": "Green-tek-A21341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/28/2024 6:45",
    "plant_id": "Green-tek-A21342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/28/2024 7:00",
    "plant_id": "Green-tek-A21343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/28/2024 7:15",
    "plant_id": "Green-tek-A21344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/28/2024 7:30",
    "plant_id": "Green-tek-A21345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "7:30:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/28/2024 7:45",
    "plant_id": "Green-tek-A21346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "7:45:00 AM",
    "power": 0.09,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/28/2024 8:00",
    "plant_id": "Green-tek-A21347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "8:00:00 AM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/28/2024 8:15",
    "plant_id": "Green-tek-A21348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "8:15:00 AM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "6/28/2024 8:30",
    "plant_id": "Green-tek-A21349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "8:30:00 AM",
    "power": 0.218,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "6/28/2024 8:45",
    "plant_id": "Green-tek-A21350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "8:45:00 AM",
    "power": 0.281,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "6/28/2024 9:00",
    "plant_id": "Green-tek-A21351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "9:00:00 AM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "6/28/2024 9:15",
    "plant_id": "Green-tek-A21352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "9:15:00 AM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "6/28/2024 9:30",
    "plant_id": "Green-tek-A21353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "9:30:00 AM",
    "power": 0.535,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "6/28/2024 9:45",
    "plant_id": "Green-tek-A21354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "9:45:00 AM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "6/28/2024 10:00",
    "plant_id": "Green-tek-A21355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "10:00:00 AM",
    "power": 0.741,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "6/28/2024 10:15",
    "plant_id": "Green-tek-A21356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "10:15:00 AM",
    "power": 0.847,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "6/28/2024 10:30",
    "plant_id": "Green-tek-A21357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "10:30:00 AM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "6/28/2024 10:45",
    "plant_id": "Green-tek-A21358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "10:45:00 AM",
    "power": 1.038,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "6/28/2024 11:00",
    "plant_id": "Green-tek-A21359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "11:00:00 AM",
    "power": 1.115,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "6/28/2024 11:15",
    "plant_id": "Green-tek-A21360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "11:15:00 AM",
    "power": 1.174,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "6/28/2024 11:30",
    "plant_id": "Green-tek-A21361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "11:30:00 AM",
    "power": 1.21,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "6/28/2024 11:45",
    "plant_id": "Green-tek-A21362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "11:45:00 AM",
    "power": 1.222,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "6/28/2024 12:00",
    "plant_id": "Green-tek-A21363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "12:00:00 PM",
    "power": 1.21,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "6/28/2024 12:15",
    "plant_id": "Green-tek-A21364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "12:15:00 PM",
    "power": 1.174,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "6/28/2024 12:30",
    "plant_id": "Green-tek-A21365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "12:30:00 PM",
    "power": 1.115,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "6/28/2024 12:45",
    "plant_id": "Green-tek-A21366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "12:45:00 PM",
    "power": 1.038,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "6/28/2024 13:00",
    "plant_id": "Green-tek-A21367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "1:00:00 PM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "6/28/2024 13:15",
    "plant_id": "Green-tek-A21368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "1:15:00 PM",
    "power": 0.847,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "6/28/2024 13:30",
    "plant_id": "Green-tek-A21369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "1:30:00 PM",
    "power": 0.741,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "6/28/2024 13:45",
    "plant_id": "Green-tek-A21370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "1:45:00 PM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "6/28/2024 14:00",
    "plant_id": "Green-tek-A21371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "2:00:00 PM",
    "power": 0.535,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "6/28/2024 14:15",
    "plant_id": "Green-tek-A21372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "2:15:00 PM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "6/28/2024 14:30",
    "plant_id": "Green-tek-A21373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "2:30:00 PM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "6/28/2024 14:45",
    "plant_id": "Green-tek-A21374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "2:45:00 PM",
    "power": 0.281,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "6/28/2024 15:00",
    "plant_id": "Green-tek-A21375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "3:00:00 PM",
    "power": 0.218,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "6/28/2024 15:15",
    "plant_id": "Green-tek-A21376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "3:15:00 PM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "6/28/2024 15:30",
    "plant_id": "Green-tek-A21377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "3:30:00 PM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "6/28/2024 15:45",
    "plant_id": "Green-tek-A21378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "3:45:00 PM",
    "power": 0.09,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/28/2024 16:00",
    "plant_id": "Green-tek-A21379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "4:00:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/28/2024 16:15",
    "plant_id": "Green-tek-A21380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/28/2024 16:30",
    "plant_id": "Green-tek-A21381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/28/2024 16:45",
    "plant_id": "Green-tek-A21382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/28/2024 17:00",
    "plant_id": "Green-tek-A21383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/28/2024 17:15",
    "plant_id": "Green-tek-A21384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/28/2024 17:30",
    "plant_id": "Green-tek-A21385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/28/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/29/2024 6:00",
    "plant_id": "Green-tek-A21386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/29/2024 6:15",
    "plant_id": "Green-tek-A21387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/29/2024 6:30",
    "plant_id": "Green-tek-A21388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/29/2024 6:45",
    "plant_id": "Green-tek-A21389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/29/2024 7:00",
    "plant_id": "Green-tek-A21390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/29/2024 7:15",
    "plant_id": "Green-tek-A21391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/29/2024 7:30",
    "plant_id": "Green-tek-A21392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/29/2024 7:45",
    "plant_id": "Green-tek-A21393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/29/2024 8:00",
    "plant_id": "Green-tek-A21394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "8:00:00 AM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "6/29/2024 8:15",
    "plant_id": "Green-tek-A21395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "8:15:00 AM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "6/29/2024 8:30",
    "plant_id": "Green-tek-A21396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "8:30:00 AM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "6/29/2024 8:45",
    "plant_id": "Green-tek-A21397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "8:45:00 AM",
    "power": 0.278,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "6/29/2024 9:00",
    "plant_id": "Green-tek-A21398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "9:00:00 AM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "6/29/2024 9:15",
    "plant_id": "Green-tek-A21399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "9:15:00 AM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "6/29/2024 9:30",
    "plant_id": "Green-tek-A21400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "9:30:00 AM",
    "power": 0.529,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "6/29/2024 9:45",
    "plant_id": "Green-tek-A21401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "9:45:00 AM",
    "power": 0.629,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "6/29/2024 10:00",
    "plant_id": "Green-tek-A21402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "10:00:00 AM",
    "power": 0.733,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "6/29/2024 10:15",
    "plant_id": "Green-tek-A21403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "10:15:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "6/29/2024 10:30",
    "plant_id": "Green-tek-A21404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "10:30:00 AM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "6/29/2024 10:45",
    "plant_id": "Green-tek-A21405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "10:45:00 AM",
    "power": 1.027,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "6/29/2024 11:00",
    "plant_id": "Green-tek-A21406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "11:00:00 AM",
    "power": 1.103,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "6/29/2024 11:15",
    "plant_id": "Green-tek-A21407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "11:15:00 AM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "6/29/2024 11:30",
    "plant_id": "Green-tek-A21408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "11:30:00 AM",
    "power": 1.197,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "6/29/2024 11:45",
    "plant_id": "Green-tek-A21409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "11:45:00 AM",
    "power": 1.209,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "6/29/2024 12:00",
    "plant_id": "Green-tek-A21410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "12:00:00 PM",
    "power": 1.197,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "6/29/2024 12:15",
    "plant_id": "Green-tek-A21411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "12:15:00 PM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "6/29/2024 12:30",
    "plant_id": "Green-tek-A21412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "12:30:00 PM",
    "power": 1.103,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "6/29/2024 12:45",
    "plant_id": "Green-tek-A21413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "12:45:00 PM",
    "power": 1.027,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "6/29/2024 13:00",
    "plant_id": "Green-tek-A21414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "1:00:00 PM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "6/29/2024 13:15",
    "plant_id": "Green-tek-A21415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "1:15:00 PM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "6/29/2024 13:30",
    "plant_id": "Green-tek-A21416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "1:30:00 PM",
    "power": 0.733,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "6/29/2024 13:45",
    "plant_id": "Green-tek-A21417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "1:45:00 PM",
    "power": 0.629,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "6/29/2024 14:00",
    "plant_id": "Green-tek-A21418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "2:00:00 PM",
    "power": 0.529,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "6/29/2024 14:15",
    "plant_id": "Green-tek-A21419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "2:15:00 PM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "6/29/2024 14:30",
    "plant_id": "Green-tek-A21420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "2:30:00 PM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "6/29/2024 14:45",
    "plant_id": "Green-tek-A21421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "2:45:00 PM",
    "power": 0.278,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "6/29/2024 15:00",
    "plant_id": "Green-tek-A21422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "3:00:00 PM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "6/29/2024 15:15",
    "plant_id": "Green-tek-A21423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "3:15:00 PM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "6/29/2024 15:30",
    "plant_id": "Green-tek-A21424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "3:30:00 PM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "6/29/2024 15:45",
    "plant_id": "Green-tek-A21425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "6/29/2024 16:00",
    "plant_id": "Green-tek-A21426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "6/29/2024 16:15",
    "plant_id": "Green-tek-A21427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "6/29/2024 16:30",
    "plant_id": "Green-tek-A21428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "6/29/2024 16:45",
    "plant_id": "Green-tek-A21429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "6/29/2024 17:00",
    "plant_id": "Green-tek-A21430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/29/2024 17:15",
    "plant_id": "Green-tek-A21431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/29/2024 17:30",
    "plant_id": "Green-tek-A21432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/29/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/30/2024 6:00",
    "plant_id": "Green-tek-A21433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "6/30/2024 6:15",
    "plant_id": "Green-tek-A21434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/30/2024 6:30",
    "plant_id": "Green-tek-A21435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/30/2024 6:45",
    "plant_id": "Green-tek-A21436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/30/2024 7:00",
    "plant_id": "Green-tek-A21437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/30/2024 7:15",
    "plant_id": "Green-tek-A21438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/30/2024 7:30",
    "plant_id": "Green-tek-A21439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "7:30:00 AM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/30/2024 7:45",
    "plant_id": "Green-tek-A21440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "7:45:00 AM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "6/30/2024 8:00",
    "plant_id": "Green-tek-A21441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "8:00:00 AM",
    "power": 0.098,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "6/30/2024 8:15",
    "plant_id": "Green-tek-A21442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "8:15:00 AM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "6/30/2024 8:30",
    "plant_id": "Green-tek-A21443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "8:30:00 AM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "6/30/2024 8:45",
    "plant_id": "Green-tek-A21444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "8:45:00 AM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/30/2024 9:00",
    "plant_id": "Green-tek-A21445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "9:00:00 AM",
    "power": 0.284,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "6/30/2024 9:15",
    "plant_id": "Green-tek-A21446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "9:15:00 AM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "6/30/2024 9:30",
    "plant_id": "Green-tek-A21447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "9:30:00 AM",
    "power": 0.426,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "6/30/2024 9:45",
    "plant_id": "Green-tek-A21448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "9:45:00 AM",
    "power": 0.507,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "6/30/2024 10:00",
    "plant_id": "Green-tek-A21449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "10:00:00 AM",
    "power": 0.591,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "6/30/2024 10:15",
    "plant_id": "Green-tek-A21450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "10:15:00 AM",
    "power": 0.675,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "6/30/2024 10:30",
    "plant_id": "Green-tek-A21451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "10:30:00 AM",
    "power": 0.755,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "6/30/2024 10:45",
    "plant_id": "Green-tek-A21452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "10:45:00 AM",
    "power": 0.828,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "6/30/2024 11:00",
    "plant_id": "Green-tek-A21453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "11:00:00 AM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "6/30/2024 11:15",
    "plant_id": "Green-tek-A21454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "11:15:00 AM",
    "power": 0.936,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "6/30/2024 11:30",
    "plant_id": "Green-tek-A21455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "11:30:00 AM",
    "power": 0.965,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "6/30/2024 11:45",
    "plant_id": "Green-tek-A21456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "11:45:00 AM",
    "power": 0.975,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "6/30/2024 12:00",
    "plant_id": "Green-tek-A21457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "12:00:00 PM",
    "power": 0.965,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "6/30/2024 12:15",
    "plant_id": "Green-tek-A21458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "12:15:00 PM",
    "power": 0.936,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "6/30/2024 12:30",
    "plant_id": "Green-tek-A21459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "12:30:00 PM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "6/30/2024 12:45",
    "plant_id": "Green-tek-A21460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "12:45:00 PM",
    "power": 0.828,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "6/30/2024 13:00",
    "plant_id": "Green-tek-A21461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "1:00:00 PM",
    "power": 0.755,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "6/30/2024 13:15",
    "plant_id": "Green-tek-A21462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "1:15:00 PM",
    "power": 0.675,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "6/30/2024 13:30",
    "plant_id": "Green-tek-A21463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "1:30:00 PM",
    "power": 0.591,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "6/30/2024 13:45",
    "plant_id": "Green-tek-A21464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "1:45:00 PM",
    "power": 0.507,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "6/30/2024 14:00",
    "plant_id": "Green-tek-A21465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "2:00:00 PM",
    "power": 0.426,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "6/30/2024 14:15",
    "plant_id": "Green-tek-A21466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "2:15:00 PM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "6/30/2024 14:30",
    "plant_id": "Green-tek-A21467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "2:30:00 PM",
    "power": 0.284,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "6/30/2024 14:45",
    "plant_id": "Green-tek-A21468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "2:45:00 PM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "6/30/2024 15:00",
    "plant_id": "Green-tek-A21469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "3:00:00 PM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "6/30/2024 15:15",
    "plant_id": "Green-tek-A21470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "3:15:00 PM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "6/30/2024 15:30",
    "plant_id": "Green-tek-A21471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "3:30:00 PM",
    "power": 0.098,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "6/30/2024 15:45",
    "plant_id": "Green-tek-A21472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "3:45:00 PM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "6/30/2024 16:00",
    "plant_id": "Green-tek-A21473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "4:00:00 PM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "6/30/2024 16:15",
    "plant_id": "Green-tek-A21474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "6/30/2024 16:30",
    "plant_id": "Green-tek-A21475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "6/30/2024 16:45",
    "plant_id": "Green-tek-A21476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "6/30/2024 17:00",
    "plant_id": "Green-tek-A21477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "6/30/2024 17:15",
    "plant_id": "Green-tek-A21478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "6/30/2024 17:30",
    "plant_id": "Green-tek-A21479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "6/30/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/1/2024 6:00",
    "plant_id": "Green-tek-A21480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/1/2024 6:15",
    "plant_id": "Green-tek-A21481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/1/2024 6:30",
    "plant_id": "Green-tek-A21482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/1/2024 6:45",
    "plant_id": "Green-tek-A21483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/1/2024 7:00",
    "plant_id": "Green-tek-A21484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/1/2024 7:15",
    "plant_id": "Green-tek-A21485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/1/2024 7:30",
    "plant_id": "Green-tek-A21486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/1/2024 7:45",
    "plant_id": "Green-tek-A21487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "7/1/2024 8:00",
    "plant_id": "Green-tek-A21488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "7/1/2024 8:15",
    "plant_id": "Green-tek-A21489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "8:15:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "7/1/2024 8:30",
    "plant_id": "Green-tek-A21490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "8:30:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/1/2024 8:45",
    "plant_id": "Green-tek-A21491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "8:45:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "7/1/2024 9:00",
    "plant_id": "Green-tek-A21492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "9:00:00 AM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "7/1/2024 9:15",
    "plant_id": "Green-tek-A21493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "9:15:00 AM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "7/1/2024 9:30",
    "plant_id": "Green-tek-A21494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "9:30:00 AM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "7/1/2024 9:45",
    "plant_id": "Green-tek-A21495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "9:45:00 AM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "7/1/2024 10:00",
    "plant_id": "Green-tek-A21496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "10:00:00 AM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "7/1/2024 10:15",
    "plant_id": "Green-tek-A21497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "10:15:00 AM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "7/1/2024 10:30",
    "plant_id": "Green-tek-A21498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "10:30:00 AM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "7/1/2024 10:45",
    "plant_id": "Green-tek-A21499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "10:45:00 AM",
    "power": 1.104,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "7/1/2024 11:00",
    "plant_id": "Green-tek-A21500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "11:00:00 AM",
    "power": 1.186,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "7/1/2024 11:15",
    "plant_id": "Green-tek-A21501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "11:15:00 AM",
    "power": 1.248,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "7/1/2024 11:30",
    "plant_id": "Green-tek-A21502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "11:30:00 AM",
    "power": 1.287,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.3315,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "7/1/2024 11:45",
    "plant_id": "Green-tek-A21503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "11:45:00 AM",
    "power": 1.3,
    "Energy": 0.325,
    "output Energy": 0.325,
    "Ac output": 1.325,
    "dc output": 1.3515,
    "AC_R_i": 0.00042391,
    "AC_S_i": 0.000466301,
    "AC_T_i": 0.00051293,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0974993,
    "AC_S_pow": 0.10724923,
    "AC_T_Pow": 0.1179739,
    "AC_R_output": 0.3975,
    "AC_S_output": 0.43725,
    "AC_T_output": 0.480975,
    "dc_current": 0.00238971,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.3315,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000325,
    "carbon offset": 0.40625,
    "trees planted": 9.027777778,
    "DC_R_output": 0.131175,
    "DC_S_output": 0.1442925,
    "DC_T_output": 0.15872175
  },
  {
    "datetime": "7/1/2024 12:00",
    "plant_id": "Green-tek-A21504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "12:00:00 PM",
    "power": 1.287,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "7/1/2024 12:15",
    "plant_id": "Green-tek-A21505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "12:15:00 PM",
    "power": 1.248,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "7/1/2024 12:30",
    "plant_id": "Green-tek-A21506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "12:30:00 PM",
    "power": 1.186,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "7/1/2024 12:45",
    "plant_id": "Green-tek-A21507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "12:45:00 PM",
    "power": 1.104,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "7/1/2024 13:00",
    "plant_id": "Green-tek-A21508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "1:00:00 PM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "7/1/2024 13:15",
    "plant_id": "Green-tek-A21509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "1:15:00 PM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "7/1/2024 13:30",
    "plant_id": "Green-tek-A21510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "1:30:00 PM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "7/1/2024 13:45",
    "plant_id": "Green-tek-A21511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "1:45:00 PM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "7/1/2024 14:00",
    "plant_id": "Green-tek-A21512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "2:00:00 PM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "7/1/2024 14:15",
    "plant_id": "Green-tek-A21513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "2:15:00 PM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "7/1/2024 14:30",
    "plant_id": "Green-tek-A21514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "2:30:00 PM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "7/1/2024 14:45",
    "plant_id": "Green-tek-A21515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "2:45:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "7/1/2024 15:00",
    "plant_id": "Green-tek-A21516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "3:00:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/1/2024 15:15",
    "plant_id": "Green-tek-A21517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "3:15:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "7/1/2024 15:30",
    "plant_id": "Green-tek-A21518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "7/1/2024 15:45",
    "plant_id": "Green-tek-A21519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "7/1/2024 16:00",
    "plant_id": "Green-tek-A21520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/1/2024 16:15",
    "plant_id": "Green-tek-A21521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/1/2024 16:30",
    "plant_id": "Green-tek-A21522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/1/2024 16:45",
    "plant_id": "Green-tek-A21523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/1/2024 17:00",
    "plant_id": "Green-tek-A21524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/1/2024 17:15",
    "plant_id": "Green-tek-A21525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/1/2024 17:30",
    "plant_id": "Green-tek-A21526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/1/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/2/2024 6:00",
    "plant_id": "Green-tek-A21527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/2/2024 6:15",
    "plant_id": "Green-tek-A21528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/2/2024 6:30",
    "plant_id": "Green-tek-A21529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/2/2024 6:45",
    "plant_id": "Green-tek-A21530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/2/2024 7:00",
    "plant_id": "Green-tek-A21531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/2/2024 7:15",
    "plant_id": "Green-tek-A21532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/2/2024 7:30",
    "plant_id": "Green-tek-A21533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/2/2024 7:45",
    "plant_id": "Green-tek-A21534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "7:45:00 AM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/2/2024 8:00",
    "plant_id": "Green-tek-A21535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "8:00:00 AM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/2/2024 8:15",
    "plant_id": "Green-tek-A21536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "8:15:00 AM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/2/2024 8:30",
    "plant_id": "Green-tek-A21537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "8:30:00 AM",
    "power": 0.178,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "7/2/2024 8:45",
    "plant_id": "Green-tek-A21538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "8:45:00 AM",
    "power": 0.23,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "7/2/2024 9:00",
    "plant_id": "Green-tek-A21539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "9:00:00 AM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/2/2024 9:15",
    "plant_id": "Green-tek-A21540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "9:15:00 AM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "7/2/2024 9:30",
    "plant_id": "Green-tek-A21541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "9:30:00 AM",
    "power": 0.437,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "7/2/2024 9:45",
    "plant_id": "Green-tek-A21542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "9:45:00 AM",
    "power": 0.519,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "7/2/2024 10:00",
    "plant_id": "Green-tek-A21543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "10:00:00 AM",
    "power": 0.605,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "7/2/2024 10:15",
    "plant_id": "Green-tek-A21544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "10:15:00 AM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "7/2/2024 10:30",
    "plant_id": "Green-tek-A21545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "10:30:00 AM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "7/2/2024 10:45",
    "plant_id": "Green-tek-A21546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "10:45:00 AM",
    "power": 0.847,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "7/2/2024 11:00",
    "plant_id": "Green-tek-A21547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "11:00:00 AM",
    "power": 0.91,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "7/2/2024 11:15",
    "plant_id": "Green-tek-A21548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "11:15:00 AM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "7/2/2024 11:30",
    "plant_id": "Green-tek-A21549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "11:30:00 AM",
    "power": 0.987,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "7/2/2024 11:45",
    "plant_id": "Green-tek-A21550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "11:45:00 AM",
    "power": 0.998,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "7/2/2024 12:00",
    "plant_id": "Green-tek-A21551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "12:00:00 PM",
    "power": 0.987,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "7/2/2024 12:15",
    "plant_id": "Green-tek-A21552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "12:15:00 PM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "7/2/2024 12:30",
    "plant_id": "Green-tek-A21553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "12:30:00 PM",
    "power": 0.91,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "7/2/2024 12:45",
    "plant_id": "Green-tek-A21554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "12:45:00 PM",
    "power": 0.847,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "7/2/2024 13:00",
    "plant_id": "Green-tek-A21555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "1:00:00 PM",
    "power": 0.773,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "7/2/2024 13:15",
    "plant_id": "Green-tek-A21556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "1:15:00 PM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "7/2/2024 13:30",
    "plant_id": "Green-tek-A21557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "1:30:00 PM",
    "power": 0.605,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "7/2/2024 13:45",
    "plant_id": "Green-tek-A21558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "1:45:00 PM",
    "power": 0.519,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "7/2/2024 14:00",
    "plant_id": "Green-tek-A21559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "2:00:00 PM",
    "power": 0.437,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "7/2/2024 14:15",
    "plant_id": "Green-tek-A21560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "2:15:00 PM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "7/2/2024 14:30",
    "plant_id": "Green-tek-A21561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "2:30:00 PM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/2/2024 14:45",
    "plant_id": "Green-tek-A21562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "2:45:00 PM",
    "power": 0.23,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "7/2/2024 15:00",
    "plant_id": "Green-tek-A21563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "3:00:00 PM",
    "power": 0.178,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "7/2/2024 15:15",
    "plant_id": "Green-tek-A21564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "3:15:00 PM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/2/2024 15:30",
    "plant_id": "Green-tek-A21565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "3:30:00 PM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/2/2024 15:45",
    "plant_id": "Green-tek-A21566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "3:45:00 PM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/2/2024 16:00",
    "plant_id": "Green-tek-A21567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/2/2024 16:15",
    "plant_id": "Green-tek-A21568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/2/2024 16:30",
    "plant_id": "Green-tek-A21569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/2/2024 16:45",
    "plant_id": "Green-tek-A21570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/2/2024 17:00",
    "plant_id": "Green-tek-A21571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/2/2024 17:15",
    "plant_id": "Green-tek-A21572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/2/2024 17:30",
    "plant_id": "Green-tek-A21573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/2/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/3/2024 6:00",
    "plant_id": "Green-tek-A21574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/3/2024 6:15",
    "plant_id": "Green-tek-A21575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/3/2024 6:30",
    "plant_id": "Green-tek-A21576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/3/2024 6:45",
    "plant_id": "Green-tek-A21577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/3/2024 7:00",
    "plant_id": "Green-tek-A21578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/3/2024 7:15",
    "plant_id": "Green-tek-A21579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/3/2024 7:30",
    "plant_id": "Green-tek-A21580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "7:30:00 AM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "7/3/2024 7:45",
    "plant_id": "Green-tek-A21581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "7:45:00 AM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "7/3/2024 8:00",
    "plant_id": "Green-tek-A21582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "8:00:00 AM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "7/3/2024 8:15",
    "plant_id": "Green-tek-A21583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "8:15:00 AM",
    "power": 0.14,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "7/3/2024 8:30",
    "plant_id": "Green-tek-A21584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "8:30:00 AM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "7/3/2024 8:45",
    "plant_id": "Green-tek-A21585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "8:45:00 AM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "7/3/2024 9:00",
    "plant_id": "Green-tek-A21586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "9:00:00 AM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "7/3/2024 9:15",
    "plant_id": "Green-tek-A21587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "9:15:00 AM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "7/3/2024 9:30",
    "plant_id": "Green-tek-A21588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "9:30:00 AM",
    "power": 0.451,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "7/3/2024 9:45",
    "plant_id": "Green-tek-A21589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "9:45:00 AM",
    "power": 0.537,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "7/3/2024 10:00",
    "plant_id": "Green-tek-A21590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "10:00:00 AM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "7/3/2024 10:15",
    "plant_id": "Green-tek-A21591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "10:15:00 AM",
    "power": 0.714,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "7/3/2024 10:30",
    "plant_id": "Green-tek-A21592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "10:30:00 AM",
    "power": 0.799,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "7/3/2024 10:45",
    "plant_id": "Green-tek-A21593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "10:45:00 AM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "7/3/2024 11:00",
    "plant_id": "Green-tek-A21594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "11:00:00 AM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "7/3/2024 11:15",
    "plant_id": "Green-tek-A21595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "11:15:00 AM",
    "power": 0.99,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "7/3/2024 11:30",
    "plant_id": "Green-tek-A21596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "11:30:00 AM",
    "power": 1.021,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "7/3/2024 11:45",
    "plant_id": "Green-tek-A21597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "11:45:00 AM",
    "power": 1.031,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "7/3/2024 12:00",
    "plant_id": "Green-tek-A21598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "12:00:00 PM",
    "power": 1.021,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "7/3/2024 12:15",
    "plant_id": "Green-tek-A21599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "12:15:00 PM",
    "power": 0.99,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "7/3/2024 12:30",
    "plant_id": "Green-tek-A21600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "12:30:00 PM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "7/3/2024 12:45",
    "plant_id": "Green-tek-A21601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "12:45:00 PM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "7/3/2024 13:00",
    "plant_id": "Green-tek-A21602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "1:00:00 PM",
    "power": 0.799,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "7/3/2024 13:15",
    "plant_id": "Green-tek-A21603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "1:15:00 PM",
    "power": 0.714,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "7/3/2024 13:30",
    "plant_id": "Green-tek-A21604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "1:30:00 PM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "7/3/2024 13:45",
    "plant_id": "Green-tek-A21605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "1:45:00 PM",
    "power": 0.537,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "7/3/2024 14:00",
    "plant_id": "Green-tek-A21606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "2:00:00 PM",
    "power": 0.451,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "7/3/2024 14:15",
    "plant_id": "Green-tek-A21607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "2:15:00 PM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "7/3/2024 14:30",
    "plant_id": "Green-tek-A21608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "2:30:00 PM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "7/3/2024 14:45",
    "plant_id": "Green-tek-A21609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "2:45:00 PM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "7/3/2024 15:00",
    "plant_id": "Green-tek-A21610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "3:00:00 PM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "7/3/2024 15:15",
    "plant_id": "Green-tek-A21611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "3:15:00 PM",
    "power": 0.14,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "7/3/2024 15:30",
    "plant_id": "Green-tek-A21612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "3:30:00 PM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "7/3/2024 15:45",
    "plant_id": "Green-tek-A21613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "3:45:00 PM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "7/3/2024 16:00",
    "plant_id": "Green-tek-A21614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "4:00:00 PM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "7/3/2024 16:15",
    "plant_id": "Green-tek-A21615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/3/2024 16:30",
    "plant_id": "Green-tek-A21616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/3/2024 16:45",
    "plant_id": "Green-tek-A21617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/3/2024 17:00",
    "plant_id": "Green-tek-A21618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/3/2024 17:15",
    "plant_id": "Green-tek-A21619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/3/2024 17:30",
    "plant_id": "Green-tek-A21620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/3/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/4/2024 6:00",
    "plant_id": "Green-tek-A21621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/4/2024 6:15",
    "plant_id": "Green-tek-A21622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/4/2024 6:30",
    "plant_id": "Green-tek-A21623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/4/2024 6:45",
    "plant_id": "Green-tek-A21624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/4/2024 7:00",
    "plant_id": "Green-tek-A21625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/4/2024 7:15",
    "plant_id": "Green-tek-A21626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/4/2024 7:30",
    "plant_id": "Green-tek-A21627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "7/4/2024 7:45",
    "plant_id": "Green-tek-A21628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "7/4/2024 8:00",
    "plant_id": "Green-tek-A21629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "8:00:00 AM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "7/4/2024 8:15",
    "plant_id": "Green-tek-A21630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "8:15:00 AM",
    "power": 0.142,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "7/4/2024 8:30",
    "plant_id": "Green-tek-A21631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "8:30:00 AM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "7/4/2024 8:45",
    "plant_id": "Green-tek-A21632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "8:45:00 AM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "7/4/2024 9:00",
    "plant_id": "Green-tek-A21633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "9:00:00 AM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "7/4/2024 9:15",
    "plant_id": "Green-tek-A21634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "9:15:00 AM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "7/4/2024 9:30",
    "plant_id": "Green-tek-A21635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "9:30:00 AM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "7/4/2024 9:45",
    "plant_id": "Green-tek-A21636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "9:45:00 AM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "7/4/2024 10:00",
    "plant_id": "Green-tek-A21637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "10:00:00 AM",
    "power": 0.634,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/4/2024 10:15",
    "plant_id": "Green-tek-A21638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "10:15:00 AM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "7/4/2024 10:30",
    "plant_id": "Green-tek-A21639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "10:30:00 AM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "7/4/2024 10:45",
    "plant_id": "Green-tek-A21640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "10:45:00 AM",
    "power": 0.888,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "7/4/2024 11:00",
    "plant_id": "Green-tek-A21641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "11:00:00 AM",
    "power": 0.954,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "7/4/2024 11:15",
    "plant_id": "Green-tek-A21642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "11:15:00 AM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "7/4/2024 11:30",
    "plant_id": "Green-tek-A21643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "11:30:00 AM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "7/4/2024 11:45",
    "plant_id": "Green-tek-A21644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "11:45:00 AM",
    "power": 1.046,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "7/4/2024 12:00",
    "plant_id": "Green-tek-A21645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "12:00:00 PM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "7/4/2024 12:15",
    "plant_id": "Green-tek-A21646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "12:15:00 PM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "7/4/2024 12:30",
    "plant_id": "Green-tek-A21647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "12:30:00 PM",
    "power": 0.954,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "7/4/2024 12:45",
    "plant_id": "Green-tek-A21648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "12:45:00 PM",
    "power": 0.888,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "7/4/2024 13:00",
    "plant_id": "Green-tek-A21649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "1:00:00 PM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "7/4/2024 13:15",
    "plant_id": "Green-tek-A21650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "1:15:00 PM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "7/4/2024 13:30",
    "plant_id": "Green-tek-A21651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "1:30:00 PM",
    "power": 0.634,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/4/2024 13:45",
    "plant_id": "Green-tek-A21652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "1:45:00 PM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "7/4/2024 14:00",
    "plant_id": "Green-tek-A21653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "2:00:00 PM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "7/4/2024 14:15",
    "plant_id": "Green-tek-A21654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "2:15:00 PM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "7/4/2024 14:30",
    "plant_id": "Green-tek-A21655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "2:30:00 PM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "7/4/2024 14:45",
    "plant_id": "Green-tek-A21656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "2:45:00 PM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "7/4/2024 15:00",
    "plant_id": "Green-tek-A21657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "3:00:00 PM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "7/4/2024 15:15",
    "plant_id": "Green-tek-A21658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "3:15:00 PM",
    "power": 0.142,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "7/4/2024 15:30",
    "plant_id": "Green-tek-A21659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "3:30:00 PM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "7/4/2024 15:45",
    "plant_id": "Green-tek-A21660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "7/4/2024 16:00",
    "plant_id": "Green-tek-A21661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "7/4/2024 16:15",
    "plant_id": "Green-tek-A21662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/4/2024 16:30",
    "plant_id": "Green-tek-A21663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/4/2024 16:45",
    "plant_id": "Green-tek-A21664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/4/2024 17:00",
    "plant_id": "Green-tek-A21665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/4/2024 17:15",
    "plant_id": "Green-tek-A21666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/4/2024 17:30",
    "plant_id": "Green-tek-A21667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/4/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/5/2024 6:00",
    "plant_id": "Green-tek-A21668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/5/2024 6:15",
    "plant_id": "Green-tek-A21669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/5/2024 6:30",
    "plant_id": "Green-tek-A21670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/5/2024 6:45",
    "plant_id": "Green-tek-A21671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/5/2024 7:00",
    "plant_id": "Green-tek-A21672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/5/2024 7:15",
    "plant_id": "Green-tek-A21673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/5/2024 7:30",
    "plant_id": "Green-tek-A21674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/5/2024 7:45",
    "plant_id": "Green-tek-A21675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/5/2024 8:00",
    "plant_id": "Green-tek-A21676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "8:00:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/5/2024 8:15",
    "plant_id": "Green-tek-A21677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "8:15:00 AM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/5/2024 8:30",
    "plant_id": "Green-tek-A21678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "8:30:00 AM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "7/5/2024 8:45",
    "plant_id": "Green-tek-A21679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "8:45:00 AM",
    "power": 0.262,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "7/5/2024 9:00",
    "plant_id": "Green-tek-A21680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "9:00:00 AM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "7/5/2024 9:15",
    "plant_id": "Green-tek-A21681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "9:15:00 AM",
    "power": 0.41,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "7/5/2024 9:30",
    "plant_id": "Green-tek-A21682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "9:30:00 AM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "7/5/2024 9:45",
    "plant_id": "Green-tek-A21683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "9:45:00 AM",
    "power": 0.593,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "7/5/2024 10:00",
    "plant_id": "Green-tek-A21684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "10:00:00 AM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "7/5/2024 10:15",
    "plant_id": "Green-tek-A21685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "10:15:00 AM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "7/5/2024 10:30",
    "plant_id": "Green-tek-A21686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "10:30:00 AM",
    "power": 0.882,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "7/5/2024 10:45",
    "plant_id": "Green-tek-A21687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "10:45:00 AM",
    "power": 0.967,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/5/2024 11:00",
    "plant_id": "Green-tek-A21688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "11:00:00 AM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "7/5/2024 11:15",
    "plant_id": "Green-tek-A21689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "11:15:00 AM",
    "power": 1.093,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "7/5/2024 11:30",
    "plant_id": "Green-tek-A21690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "11:30:00 AM",
    "power": 1.127,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "7/5/2024 11:45",
    "plant_id": "Green-tek-A21691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "11:45:00 AM",
    "power": 1.139,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "7/5/2024 12:00",
    "plant_id": "Green-tek-A21692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "12:00:00 PM",
    "power": 1.127,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "7/5/2024 12:15",
    "plant_id": "Green-tek-A21693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "12:15:00 PM",
    "power": 1.093,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "7/5/2024 12:30",
    "plant_id": "Green-tek-A21694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "12:30:00 PM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "7/5/2024 12:45",
    "plant_id": "Green-tek-A21695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "12:45:00 PM",
    "power": 0.967,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/5/2024 13:00",
    "plant_id": "Green-tek-A21696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "1:00:00 PM",
    "power": 0.882,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "7/5/2024 13:15",
    "plant_id": "Green-tek-A21697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "1:15:00 PM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "7/5/2024 13:30",
    "plant_id": "Green-tek-A21698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "1:30:00 PM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "7/5/2024 13:45",
    "plant_id": "Green-tek-A21699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "1:45:00 PM",
    "power": 0.593,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "7/5/2024 14:00",
    "plant_id": "Green-tek-A21700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "2:00:00 PM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "7/5/2024 14:15",
    "plant_id": "Green-tek-A21701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "2:15:00 PM",
    "power": 0.41,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "7/5/2024 14:30",
    "plant_id": "Green-tek-A21702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "2:30:00 PM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "7/5/2024 14:45",
    "plant_id": "Green-tek-A21703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "2:45:00 PM",
    "power": 0.262,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "7/5/2024 15:00",
    "plant_id": "Green-tek-A21704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "3:00:00 PM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "7/5/2024 15:15",
    "plant_id": "Green-tek-A21705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "3:15:00 PM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/5/2024 15:30",
    "plant_id": "Green-tek-A21706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "3:30:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/5/2024 15:45",
    "plant_id": "Green-tek-A21707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/5/2024 16:00",
    "plant_id": "Green-tek-A21708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/5/2024 16:15",
    "plant_id": "Green-tek-A21709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/5/2024 16:30",
    "plant_id": "Green-tek-A21710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/5/2024 16:45",
    "plant_id": "Green-tek-A21711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/5/2024 17:00",
    "plant_id": "Green-tek-A21712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/5/2024 17:15",
    "plant_id": "Green-tek-A21713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/5/2024 17:30",
    "plant_id": "Green-tek-A21714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/5/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/6/2024 6:00",
    "plant_id": "Green-tek-A21715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/6/2024 6:15",
    "plant_id": "Green-tek-A21716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/6/2024 6:30",
    "plant_id": "Green-tek-A21717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/6/2024 6:45",
    "plant_id": "Green-tek-A21718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/6/2024 7:00",
    "plant_id": "Green-tek-A21719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/6/2024 7:15",
    "plant_id": "Green-tek-A21720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/6/2024 7:30",
    "plant_id": "Green-tek-A21721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/6/2024 7:45",
    "plant_id": "Green-tek-A21722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "7:45:00 AM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/6/2024 8:00",
    "plant_id": "Green-tek-A21723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "8:00:00 AM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "7/6/2024 8:15",
    "plant_id": "Green-tek-A21724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "8:15:00 AM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "7/6/2024 8:30",
    "plant_id": "Green-tek-A21725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "8:30:00 AM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "7/6/2024 8:45",
    "plant_id": "Green-tek-A21726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "8:45:00 AM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "7/6/2024 9:00",
    "plant_id": "Green-tek-A21727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "9:00:00 AM",
    "power": 0.358,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "7/6/2024 9:15",
    "plant_id": "Green-tek-A21728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "9:15:00 AM",
    "power": 0.444,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "7/6/2024 9:30",
    "plant_id": "Green-tek-A21729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "9:30:00 AM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "7/6/2024 9:45",
    "plant_id": "Green-tek-A21730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "9:45:00 AM",
    "power": 0.641,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "7/6/2024 10:00",
    "plant_id": "Green-tek-A21731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "10:00:00 AM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "7/6/2024 10:15",
    "plant_id": "Green-tek-A21732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "10:15:00 AM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "7/6/2024 10:30",
    "plant_id": "Green-tek-A21733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "10:30:00 AM",
    "power": 0.954,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "7/6/2024 10:45",
    "plant_id": "Green-tek-A21734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "10:45:00 AM",
    "power": 1.046,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "7/6/2024 11:00",
    "plant_id": "Green-tek-A21735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "11:00:00 AM",
    "power": 1.123,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "7/6/2024 11:15",
    "plant_id": "Green-tek-A21736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "11:15:00 AM",
    "power": 1.182,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "7/6/2024 11:30",
    "plant_id": "Green-tek-A21737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "11:30:00 AM",
    "power": 1.219,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "7/6/2024 11:45",
    "plant_id": "Green-tek-A21738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "11:45:00 AM",
    "power": 1.231,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "7/6/2024 12:00",
    "plant_id": "Green-tek-A21739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "12:00:00 PM",
    "power": 1.219,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "7/6/2024 12:15",
    "plant_id": "Green-tek-A21740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "12:15:00 PM",
    "power": 1.182,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "7/6/2024 12:30",
    "plant_id": "Green-tek-A21741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "12:30:00 PM",
    "power": 1.123,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "7/6/2024 12:45",
    "plant_id": "Green-tek-A21742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "12:45:00 PM",
    "power": 1.046,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "7/6/2024 13:00",
    "plant_id": "Green-tek-A21743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "1:00:00 PM",
    "power": 0.954,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "7/6/2024 13:15",
    "plant_id": "Green-tek-A21744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "1:15:00 PM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "7/6/2024 13:30",
    "plant_id": "Green-tek-A21745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "1:30:00 PM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "7/6/2024 13:45",
    "plant_id": "Green-tek-A21746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "1:45:00 PM",
    "power": 0.641,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "7/6/2024 14:00",
    "plant_id": "Green-tek-A21747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "2:00:00 PM",
    "power": 0.539,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "7/6/2024 14:15",
    "plant_id": "Green-tek-A21748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "2:15:00 PM",
    "power": 0.444,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "7/6/2024 14:30",
    "plant_id": "Green-tek-A21749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "2:30:00 PM",
    "power": 0.358,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "7/6/2024 14:45",
    "plant_id": "Green-tek-A21750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "2:45:00 PM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "7/6/2024 15:00",
    "plant_id": "Green-tek-A21751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "3:00:00 PM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "7/6/2024 15:15",
    "plant_id": "Green-tek-A21752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "3:15:00 PM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "7/6/2024 15:30",
    "plant_id": "Green-tek-A21753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "3:30:00 PM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "7/6/2024 15:45",
    "plant_id": "Green-tek-A21754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "3:45:00 PM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/6/2024 16:00",
    "plant_id": "Green-tek-A21755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/6/2024 16:15",
    "plant_id": "Green-tek-A21756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/6/2024 16:30",
    "plant_id": "Green-tek-A21757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/6/2024 16:45",
    "plant_id": "Green-tek-A21758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/6/2024 17:00",
    "plant_id": "Green-tek-A21759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/6/2024 17:15",
    "plant_id": "Green-tek-A21760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/6/2024 17:30",
    "plant_id": "Green-tek-A21761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/6/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/7/2024 6:00",
    "plant_id": "Green-tek-A21762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/7/2024 6:15",
    "plant_id": "Green-tek-A21763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/7/2024 6:30",
    "plant_id": "Green-tek-A21764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/7/2024 6:45",
    "plant_id": "Green-tek-A21765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/7/2024 7:00",
    "plant_id": "Green-tek-A21766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/7/2024 7:15",
    "plant_id": "Green-tek-A21767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/7/2024 7:30",
    "plant_id": "Green-tek-A21768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/7/2024 7:45",
    "plant_id": "Green-tek-A21769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/7/2024 8:00",
    "plant_id": "Green-tek-A21770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "8:00:00 AM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/7/2024 8:15",
    "plant_id": "Green-tek-A21771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "8:15:00 AM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/7/2024 8:30",
    "plant_id": "Green-tek-A21772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "8:30:00 AM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "7/7/2024 8:45",
    "plant_id": "Green-tek-A21773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "8:45:00 AM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/7/2024 9:00",
    "plant_id": "Green-tek-A21774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "9:00:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/7/2024 9:15",
    "plant_id": "Green-tek-A21775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "9:15:00 AM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "7/7/2024 9:30",
    "plant_id": "Green-tek-A21776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "9:30:00 AM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "7/7/2024 9:45",
    "plant_id": "Green-tek-A21777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "9:45:00 AM",
    "power": 0.523,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "7/7/2024 10:00",
    "plant_id": "Green-tek-A21778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "10:00:00 AM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "7/7/2024 10:15",
    "plant_id": "Green-tek-A21779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "10:15:00 AM",
    "power": 0.695,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "7/7/2024 10:30",
    "plant_id": "Green-tek-A21780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "10:30:00 AM",
    "power": 0.778,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/7/2024 10:45",
    "plant_id": "Green-tek-A21781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "10:45:00 AM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "7/7/2024 11:00",
    "plant_id": "Green-tek-A21782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "11:00:00 AM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "7/7/2024 11:15",
    "plant_id": "Green-tek-A21783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "11:15:00 AM",
    "power": 0.964,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "7/7/2024 11:30",
    "plant_id": "Green-tek-A21784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "11:30:00 AM",
    "power": 0.994,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "7/7/2024 11:45",
    "plant_id": "Green-tek-A21785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "11:45:00 AM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "7/7/2024 12:00",
    "plant_id": "Green-tek-A21786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "12:00:00 PM",
    "power": 0.994,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "7/7/2024 12:15",
    "plant_id": "Green-tek-A21787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "12:15:00 PM",
    "power": 0.964,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "7/7/2024 12:30",
    "plant_id": "Green-tek-A21788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "12:30:00 PM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "7/7/2024 12:45",
    "plant_id": "Green-tek-A21789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "12:45:00 PM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "7/7/2024 13:00",
    "plant_id": "Green-tek-A21790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "1:00:00 PM",
    "power": 0.778,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/7/2024 13:15",
    "plant_id": "Green-tek-A21791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "1:15:00 PM",
    "power": 0.695,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "7/7/2024 13:30",
    "plant_id": "Green-tek-A21792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "1:30:00 PM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "7/7/2024 13:45",
    "plant_id": "Green-tek-A21793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "1:45:00 PM",
    "power": 0.523,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "7/7/2024 14:00",
    "plant_id": "Green-tek-A21794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "2:00:00 PM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "7/7/2024 14:15",
    "plant_id": "Green-tek-A21795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "2:15:00 PM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "7/7/2024 14:30",
    "plant_id": "Green-tek-A21796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "2:30:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/7/2024 14:45",
    "plant_id": "Green-tek-A21797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "2:45:00 PM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/7/2024 15:00",
    "plant_id": "Green-tek-A21798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "3:00:00 PM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "7/7/2024 15:15",
    "plant_id": "Green-tek-A21799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "3:15:00 PM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/7/2024 15:30",
    "plant_id": "Green-tek-A21800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "3:30:00 PM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/7/2024 15:45",
    "plant_id": "Green-tek-A21801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/7/2024 16:00",
    "plant_id": "Green-tek-A21802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/7/2024 16:15",
    "plant_id": "Green-tek-A21803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/7/2024 16:30",
    "plant_id": "Green-tek-A21804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/7/2024 16:45",
    "plant_id": "Green-tek-A21805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/7/2024 17:00",
    "plant_id": "Green-tek-A21806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/7/2024 17:15",
    "plant_id": "Green-tek-A21807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/7/2024 17:30",
    "plant_id": "Green-tek-A21808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/7/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/8/2024 6:00",
    "plant_id": "Green-tek-A21809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/8/2024 6:15",
    "plant_id": "Green-tek-A21810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/8/2024 6:30",
    "plant_id": "Green-tek-A21811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/8/2024 6:45",
    "plant_id": "Green-tek-A21812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/8/2024 7:00",
    "plant_id": "Green-tek-A21813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/8/2024 7:15",
    "plant_id": "Green-tek-A21814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/8/2024 7:30",
    "plant_id": "Green-tek-A21815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/8/2024 7:45",
    "plant_id": "Green-tek-A21816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/8/2024 8:00",
    "plant_id": "Green-tek-A21817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "8:00:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/8/2024 8:15",
    "plant_id": "Green-tek-A21818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "8:15:00 AM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/8/2024 8:30",
    "plant_id": "Green-tek-A21819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "8:30:00 AM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/8/2024 8:45",
    "plant_id": "Green-tek-A21820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "8:45:00 AM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "7/8/2024 9:00",
    "plant_id": "Green-tek-A21821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "9:00:00 AM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "7/8/2024 9:15",
    "plant_id": "Green-tek-A21822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "9:15:00 AM",
    "power": 0.313,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "7/8/2024 9:30",
    "plant_id": "Green-tek-A21823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "9:30:00 AM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "7/8/2024 9:45",
    "plant_id": "Green-tek-A21824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "9:45:00 AM",
    "power": 0.452,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "7/8/2024 10:00",
    "plant_id": "Green-tek-A21825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "10:00:00 AM",
    "power": 0.527,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "7/8/2024 10:15",
    "plant_id": "Green-tek-A21826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "10:15:00 AM",
    "power": 0.602,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "7/8/2024 10:30",
    "plant_id": "Green-tek-A21827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "10:30:00 AM",
    "power": 0.673,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "7/8/2024 10:45",
    "plant_id": "Green-tek-A21828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "10:45:00 AM",
    "power": 0.738,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "7/8/2024 11:00",
    "plant_id": "Green-tek-A21829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "11:00:00 AM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "7/8/2024 11:15",
    "plant_id": "Green-tek-A21830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "11:15:00 AM",
    "power": 0.834,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "7/8/2024 11:30",
    "plant_id": "Green-tek-A21831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "11:30:00 AM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "7/8/2024 11:45",
    "plant_id": "Green-tek-A21832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "11:45:00 AM",
    "power": 0.869,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "7/8/2024 12:00",
    "plant_id": "Green-tek-A21833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "12:00:00 PM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "7/8/2024 12:15",
    "plant_id": "Green-tek-A21834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "12:15:00 PM",
    "power": 0.834,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "7/8/2024 12:30",
    "plant_id": "Green-tek-A21835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "12:30:00 PM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "7/8/2024 12:45",
    "plant_id": "Green-tek-A21836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "12:45:00 PM",
    "power": 0.738,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "7/8/2024 13:00",
    "plant_id": "Green-tek-A21837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "1:00:00 PM",
    "power": 0.673,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "7/8/2024 13:15",
    "plant_id": "Green-tek-A21838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "1:15:00 PM",
    "power": 0.602,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "7/8/2024 13:30",
    "plant_id": "Green-tek-A21839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "1:30:00 PM",
    "power": 0.527,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "7/8/2024 13:45",
    "plant_id": "Green-tek-A21840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "1:45:00 PM",
    "power": 0.452,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "7/8/2024 14:00",
    "plant_id": "Green-tek-A21841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "2:00:00 PM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "7/8/2024 14:15",
    "plant_id": "Green-tek-A21842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "2:15:00 PM",
    "power": 0.313,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "7/8/2024 14:30",
    "plant_id": "Green-tek-A21843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "2:30:00 PM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "7/8/2024 14:45",
    "plant_id": "Green-tek-A21844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "2:45:00 PM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "7/8/2024 15:00",
    "plant_id": "Green-tek-A21845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "3:00:00 PM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/8/2024 15:15",
    "plant_id": "Green-tek-A21846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "3:15:00 PM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/8/2024 15:30",
    "plant_id": "Green-tek-A21847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "3:30:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/8/2024 15:45",
    "plant_id": "Green-tek-A21848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/8/2024 16:00",
    "plant_id": "Green-tek-A21849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/8/2024 16:15",
    "plant_id": "Green-tek-A21850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/8/2024 16:30",
    "plant_id": "Green-tek-A21851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/8/2024 16:45",
    "plant_id": "Green-tek-A21852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/8/2024 17:00",
    "plant_id": "Green-tek-A21853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/8/2024 17:15",
    "plant_id": "Green-tek-A21854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/8/2024 17:30",
    "plant_id": "Green-tek-A21855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/8/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/9/2024 6:00",
    "plant_id": "Green-tek-A21856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/9/2024 6:15",
    "plant_id": "Green-tek-A21857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/9/2024 6:30",
    "plant_id": "Green-tek-A21858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/9/2024 6:45",
    "plant_id": "Green-tek-A21859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/9/2024 7:00",
    "plant_id": "Green-tek-A21860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/9/2024 7:15",
    "plant_id": "Green-tek-A21861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/9/2024 7:30",
    "plant_id": "Green-tek-A21862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "7/9/2024 7:45",
    "plant_id": "Green-tek-A21863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "7:45:00 AM",
    "power": 0.078,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "7/9/2024 8:00",
    "plant_id": "Green-tek-A21864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "8:00:00 AM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "7/9/2024 8:15",
    "plant_id": "Green-tek-A21865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "8:15:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "7/9/2024 8:30",
    "plant_id": "Green-tek-A21866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "8:30:00 AM",
    "power": 0.19,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "7/9/2024 8:45",
    "plant_id": "Green-tek-A21867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "8:45:00 AM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "7/9/2024 9:00",
    "plant_id": "Green-tek-A21868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "9:00:00 AM",
    "power": 0.31,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "7/9/2024 9:15",
    "plant_id": "Green-tek-A21869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "9:15:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "7/9/2024 9:30",
    "plant_id": "Green-tek-A21870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "9:30:00 AM",
    "power": 0.466,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "7/9/2024 9:45",
    "plant_id": "Green-tek-A21871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "9:45:00 AM",
    "power": 0.554,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/9/2024 10:00",
    "plant_id": "Green-tek-A21872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "10:00:00 AM",
    "power": 0.646,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "7/9/2024 10:15",
    "plant_id": "Green-tek-A21873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "10:15:00 AM",
    "power": 0.737,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "7/9/2024 10:30",
    "plant_id": "Green-tek-A21874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "10:30:00 AM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "7/9/2024 10:45",
    "plant_id": "Green-tek-A21875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "10:45:00 AM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "7/9/2024 11:00",
    "plant_id": "Green-tek-A21876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "11:00:00 AM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "7/9/2024 11:15",
    "plant_id": "Green-tek-A21877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "11:15:00 AM",
    "power": 1.022,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "7/9/2024 11:30",
    "plant_id": "Green-tek-A21878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "11:30:00 AM",
    "power": 1.054,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "7/9/2024 11:45",
    "plant_id": "Green-tek-A21879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "11:45:00 AM",
    "power": 1.065,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "7/9/2024 12:00",
    "plant_id": "Green-tek-A21880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "12:00:00 PM",
    "power": 1.054,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "7/9/2024 12:15",
    "plant_id": "Green-tek-A21881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "12:15:00 PM",
    "power": 1.022,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "7/9/2024 12:30",
    "plant_id": "Green-tek-A21882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "12:30:00 PM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "7/9/2024 12:45",
    "plant_id": "Green-tek-A21883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "12:45:00 PM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "7/9/2024 13:00",
    "plant_id": "Green-tek-A21884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "1:00:00 PM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "7/9/2024 13:15",
    "plant_id": "Green-tek-A21885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "1:15:00 PM",
    "power": 0.737,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "7/9/2024 13:30",
    "plant_id": "Green-tek-A21886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "1:30:00 PM",
    "power": 0.646,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "7/9/2024 13:45",
    "plant_id": "Green-tek-A21887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "1:45:00 PM",
    "power": 0.554,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/9/2024 14:00",
    "plant_id": "Green-tek-A21888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "2:00:00 PM",
    "power": 0.466,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "7/9/2024 14:15",
    "plant_id": "Green-tek-A21889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "2:15:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "7/9/2024 14:30",
    "plant_id": "Green-tek-A21890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "2:30:00 PM",
    "power": 0.31,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "7/9/2024 14:45",
    "plant_id": "Green-tek-A21891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "2:45:00 PM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "7/9/2024 15:00",
    "plant_id": "Green-tek-A21892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "3:00:00 PM",
    "power": 0.19,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "7/9/2024 15:15",
    "plant_id": "Green-tek-A21893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "3:15:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "7/9/2024 15:30",
    "plant_id": "Green-tek-A21894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "3:30:00 PM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "7/9/2024 15:45",
    "plant_id": "Green-tek-A21895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "3:45:00 PM",
    "power": 0.078,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "7/9/2024 16:00",
    "plant_id": "Green-tek-A21896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "7/9/2024 16:15",
    "plant_id": "Green-tek-A21897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/9/2024 16:30",
    "plant_id": "Green-tek-A21898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/9/2024 16:45",
    "plant_id": "Green-tek-A21899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/9/2024 17:00",
    "plant_id": "Green-tek-A21900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/9/2024 17:15",
    "plant_id": "Green-tek-A21901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/9/2024 17:30",
    "plant_id": "Green-tek-A21902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/9/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/10/2024 6:00",
    "plant_id": "Green-tek-A21903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/10/2024 6:15",
    "plant_id": "Green-tek-A21904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/10/2024 6:30",
    "plant_id": "Green-tek-A21905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/10/2024 6:45",
    "plant_id": "Green-tek-A21906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/10/2024 7:00",
    "plant_id": "Green-tek-A21907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/10/2024 7:15",
    "plant_id": "Green-tek-A21908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/10/2024 7:30",
    "plant_id": "Green-tek-A21909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/10/2024 7:45",
    "plant_id": "Green-tek-A21910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "7:45:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/10/2024 8:00",
    "plant_id": "Green-tek-A21911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "8:00:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/10/2024 8:15",
    "plant_id": "Green-tek-A21912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "8:15:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "7/10/2024 8:30",
    "plant_id": "Green-tek-A21913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "8:30:00 AM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/10/2024 8:45",
    "plant_id": "Green-tek-A21914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "8:45:00 AM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "7/10/2024 9:00",
    "plant_id": "Green-tek-A21915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "9:00:00 AM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "7/10/2024 9:15",
    "plant_id": "Green-tek-A21916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "9:15:00 AM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "7/10/2024 9:30",
    "plant_id": "Green-tek-A21917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "9:30:00 AM",
    "power": 0.385,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "7/10/2024 9:45",
    "plant_id": "Green-tek-A21918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "9:45:00 AM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "7/10/2024 10:00",
    "plant_id": "Green-tek-A21919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "10:00:00 AM",
    "power": 0.534,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "7/10/2024 10:15",
    "plant_id": "Green-tek-A21920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "10:15:00 AM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "7/10/2024 10:30",
    "plant_id": "Green-tek-A21921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "10:30:00 AM",
    "power": 0.682,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "7/10/2024 10:45",
    "plant_id": "Green-tek-A21922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "10:45:00 AM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "7/10/2024 11:00",
    "plant_id": "Green-tek-A21923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "11:00:00 AM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "7/10/2024 11:15",
    "plant_id": "Green-tek-A21924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "11:15:00 AM",
    "power": 0.844,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "7/10/2024 11:30",
    "plant_id": "Green-tek-A21925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "11:30:00 AM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "7/10/2024 11:45",
    "plant_id": "Green-tek-A21926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "11:45:00 AM",
    "power": 0.88,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "7/10/2024 12:00",
    "plant_id": "Green-tek-A21927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "12:00:00 PM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "7/10/2024 12:15",
    "plant_id": "Green-tek-A21928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "12:15:00 PM",
    "power": 0.844,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "7/10/2024 12:30",
    "plant_id": "Green-tek-A21929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "12:30:00 PM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "7/10/2024 12:45",
    "plant_id": "Green-tek-A21930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "12:45:00 PM",
    "power": 0.747,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "7/10/2024 13:00",
    "plant_id": "Green-tek-A21931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "1:00:00 PM",
    "power": 0.682,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "7/10/2024 13:15",
    "plant_id": "Green-tek-A21932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "1:15:00 PM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "7/10/2024 13:30",
    "plant_id": "Green-tek-A21933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "1:30:00 PM",
    "power": 0.534,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "7/10/2024 13:45",
    "plant_id": "Green-tek-A21934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "1:45:00 PM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "7/10/2024 14:00",
    "plant_id": "Green-tek-A21935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "2:00:00 PM",
    "power": 0.385,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "7/10/2024 14:15",
    "plant_id": "Green-tek-A21936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "2:15:00 PM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "7/10/2024 14:30",
    "plant_id": "Green-tek-A21937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "2:30:00 PM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "7/10/2024 14:45",
    "plant_id": "Green-tek-A21938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "2:45:00 PM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "7/10/2024 15:00",
    "plant_id": "Green-tek-A21939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "3:00:00 PM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/10/2024 15:15",
    "plant_id": "Green-tek-A21940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "3:15:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "7/10/2024 15:30",
    "plant_id": "Green-tek-A21941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "3:30:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/10/2024 15:45",
    "plant_id": "Green-tek-A21942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "3:45:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/10/2024 16:00",
    "plant_id": "Green-tek-A21943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/10/2024 16:15",
    "plant_id": "Green-tek-A21944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/10/2024 16:30",
    "plant_id": "Green-tek-A21945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/10/2024 16:45",
    "plant_id": "Green-tek-A21946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/10/2024 17:00",
    "plant_id": "Green-tek-A21947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/10/2024 17:15",
    "plant_id": "Green-tek-A21948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/10/2024 17:30",
    "plant_id": "Green-tek-A21949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/10/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/11/2024 6:00",
    "plant_id": "Green-tek-A21950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/11/2024 6:15",
    "plant_id": "Green-tek-A21951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/11/2024 6:30",
    "plant_id": "Green-tek-A21952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/11/2024 6:45",
    "plant_id": "Green-tek-A21953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/11/2024 7:00",
    "plant_id": "Green-tek-A21954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/11/2024 7:15",
    "plant_id": "Green-tek-A21955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/11/2024 7:30",
    "plant_id": "Green-tek-A21956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "7:30:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/11/2024 7:45",
    "plant_id": "Green-tek-A21957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "7:45:00 AM",
    "power": 0.09,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/11/2024 8:00",
    "plant_id": "Green-tek-A21958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "8:00:00 AM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "7/11/2024 8:15",
    "plant_id": "Green-tek-A21959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "8:15:00 AM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "7/11/2024 8:30",
    "plant_id": "Green-tek-A21960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "8:30:00 AM",
    "power": 0.218,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "7/11/2024 8:45",
    "plant_id": "Green-tek-A21961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "8:45:00 AM",
    "power": 0.281,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "7/11/2024 9:00",
    "plant_id": "Green-tek-A21962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "9:00:00 AM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "7/11/2024 9:15",
    "plant_id": "Green-tek-A21963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "9:15:00 AM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "7/11/2024 9:30",
    "plant_id": "Green-tek-A21964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "9:30:00 AM",
    "power": 0.535,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "7/11/2024 9:45",
    "plant_id": "Green-tek-A21965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "9:45:00 AM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/11/2024 10:00",
    "plant_id": "Green-tek-A21966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "10:00:00 AM",
    "power": 0.741,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "7/11/2024 10:15",
    "plant_id": "Green-tek-A21967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "10:15:00 AM",
    "power": 0.846,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "7/11/2024 10:30",
    "plant_id": "Green-tek-A21968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "10:30:00 AM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "7/11/2024 10:45",
    "plant_id": "Green-tek-A21969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "10:45:00 AM",
    "power": 1.038,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "7/11/2024 11:00",
    "plant_id": "Green-tek-A21970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "11:00:00 AM",
    "power": 1.115,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "7/11/2024 11:15",
    "plant_id": "Green-tek-A21971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "11:15:00 AM",
    "power": 1.173,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "7/11/2024 11:30",
    "plant_id": "Green-tek-A21972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "11:30:00 AM",
    "power": 1.209,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "7/11/2024 11:45",
    "plant_id": "Green-tek-A21973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "11:45:00 AM",
    "power": 1.222,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "7/11/2024 12:00",
    "plant_id": "Green-tek-A21974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "12:00:00 PM",
    "power": 1.209,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "7/11/2024 12:15",
    "plant_id": "Green-tek-A21975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "12:15:00 PM",
    "power": 1.173,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "7/11/2024 12:30",
    "plant_id": "Green-tek-A21976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "12:30:00 PM",
    "power": 1.115,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "7/11/2024 12:45",
    "plant_id": "Green-tek-A21977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "12:45:00 PM",
    "power": 1.038,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "7/11/2024 13:00",
    "plant_id": "Green-tek-A21978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "1:00:00 PM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "7/11/2024 13:15",
    "plant_id": "Green-tek-A21979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "1:15:00 PM",
    "power": 0.846,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "7/11/2024 13:30",
    "plant_id": "Green-tek-A21980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "1:30:00 PM",
    "power": 0.741,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "7/11/2024 13:45",
    "plant_id": "Green-tek-A21981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "1:45:00 PM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/11/2024 14:00",
    "plant_id": "Green-tek-A21982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "2:00:00 PM",
    "power": 0.535,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "7/11/2024 14:15",
    "plant_id": "Green-tek-A21983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "2:15:00 PM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "7/11/2024 14:30",
    "plant_id": "Green-tek-A21984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "2:30:00 PM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "7/11/2024 14:45",
    "plant_id": "Green-tek-A21985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "2:45:00 PM",
    "power": 0.281,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "7/11/2024 15:00",
    "plant_id": "Green-tek-A21986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "3:00:00 PM",
    "power": 0.218,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "7/11/2024 15:15",
    "plant_id": "Green-tek-A21987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "3:15:00 PM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "7/11/2024 15:30",
    "plant_id": "Green-tek-A21988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "3:30:00 PM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "7/11/2024 15:45",
    "plant_id": "Green-tek-A21989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "3:45:00 PM",
    "power": 0.09,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/11/2024 16:00",
    "plant_id": "Green-tek-A21990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "4:00:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/11/2024 16:15",
    "plant_id": "Green-tek-A21991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/11/2024 16:30",
    "plant_id": "Green-tek-A21992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/11/2024 16:45",
    "plant_id": "Green-tek-A21993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/11/2024 17:00",
    "plant_id": "Green-tek-A21994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/11/2024 17:15",
    "plant_id": "Green-tek-A21995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/11/2024 17:30",
    "plant_id": "Green-tek-A21996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/11/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/12/2024 6:00",
    "plant_id": "Green-tek-A21997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/12/2024 6:15",
    "plant_id": "Green-tek-A21998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/12/2024 6:30",
    "plant_id": "Green-tek-A21999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/12/2024 6:45",
    "plant_id": "Green-tek-A22000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/12/2024 7:00",
    "plant_id": "Green-tek-A22001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/12/2024 7:15",
    "plant_id": "Green-tek-A22002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/12/2024 7:30",
    "plant_id": "Green-tek-A22003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/12/2024 7:45",
    "plant_id": "Green-tek-A22004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "7/12/2024 8:00",
    "plant_id": "Green-tek-A22005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "8:00:00 AM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/12/2024 8:15",
    "plant_id": "Green-tek-A22006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "8:15:00 AM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/12/2024 8:30",
    "plant_id": "Green-tek-A22007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "8:30:00 AM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "7/12/2024 8:45",
    "plant_id": "Green-tek-A22008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "8:45:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/12/2024 9:00",
    "plant_id": "Green-tek-A22009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "9:00:00 AM",
    "power": 0.294,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/12/2024 9:15",
    "plant_id": "Green-tek-A22010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "9:15:00 AM",
    "power": 0.364,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "7/12/2024 9:30",
    "plant_id": "Green-tek-A22011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "9:30:00 AM",
    "power": 0.442,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "7/12/2024 9:45",
    "plant_id": "Green-tek-A22012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "9:45:00 AM",
    "power": 0.526,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "7/12/2024 10:00",
    "plant_id": "Green-tek-A22013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "10:00:00 AM",
    "power": 0.613,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "7/12/2024 10:15",
    "plant_id": "Green-tek-A22014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "10:15:00 AM",
    "power": 0.7,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "7/12/2024 10:30",
    "plant_id": "Green-tek-A22015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "10:30:00 AM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "7/12/2024 10:45",
    "plant_id": "Green-tek-A22016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "10:45:00 AM",
    "power": 0.858,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "7/12/2024 11:00",
    "plant_id": "Green-tek-A22017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "11:00:00 AM",
    "power": 0.922,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "7/12/2024 11:15",
    "plant_id": "Green-tek-A22018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "11:15:00 AM",
    "power": 0.97,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/12/2024 11:30",
    "plant_id": "Green-tek-A22019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "11:30:00 AM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "7/12/2024 11:45",
    "plant_id": "Green-tek-A22020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "11:45:00 AM",
    "power": 1.01,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "7/12/2024 12:00",
    "plant_id": "Green-tek-A22021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "12:00:00 PM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "7/12/2024 12:15",
    "plant_id": "Green-tek-A22022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "12:15:00 PM",
    "power": 0.97,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/12/2024 12:30",
    "plant_id": "Green-tek-A22023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "12:30:00 PM",
    "power": 0.922,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "7/12/2024 12:45",
    "plant_id": "Green-tek-A22024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "12:45:00 PM",
    "power": 0.858,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "7/12/2024 13:00",
    "plant_id": "Green-tek-A22025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "1:00:00 PM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "7/12/2024 13:15",
    "plant_id": "Green-tek-A22026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "1:15:00 PM",
    "power": 0.7,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "7/12/2024 13:30",
    "plant_id": "Green-tek-A22027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "1:30:00 PM",
    "power": 0.613,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "7/12/2024 13:45",
    "plant_id": "Green-tek-A22028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "1:45:00 PM",
    "power": 0.526,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "7/12/2024 14:00",
    "plant_id": "Green-tek-A22029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "2:00:00 PM",
    "power": 0.442,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "7/12/2024 14:15",
    "plant_id": "Green-tek-A22030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "2:15:00 PM",
    "power": 0.364,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "7/12/2024 14:30",
    "plant_id": "Green-tek-A22031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "2:30:00 PM",
    "power": 0.294,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/12/2024 14:45",
    "plant_id": "Green-tek-A22032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "2:45:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/12/2024 15:00",
    "plant_id": "Green-tek-A22033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "3:00:00 PM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "7/12/2024 15:15",
    "plant_id": "Green-tek-A22034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "3:15:00 PM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/12/2024 15:30",
    "plant_id": "Green-tek-A22035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "3:30:00 PM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/12/2024 15:45",
    "plant_id": "Green-tek-A22036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "7/12/2024 16:00",
    "plant_id": "Green-tek-A22037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/12/2024 16:15",
    "plant_id": "Green-tek-A22038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/12/2024 16:30",
    "plant_id": "Green-tek-A22039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/12/2024 16:45",
    "plant_id": "Green-tek-A22040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/12/2024 17:00",
    "plant_id": "Green-tek-A22041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/12/2024 17:15",
    "plant_id": "Green-tek-A22042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/12/2024 17:30",
    "plant_id": "Green-tek-A22043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/12/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/13/2024 6:00",
    "plant_id": "Green-tek-A22044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/13/2024 6:15",
    "plant_id": "Green-tek-A22045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/13/2024 6:30",
    "plant_id": "Green-tek-A22046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/13/2024 6:45",
    "plant_id": "Green-tek-A22047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/13/2024 7:00",
    "plant_id": "Green-tek-A22048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/13/2024 7:15",
    "plant_id": "Green-tek-A22049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/13/2024 7:30",
    "plant_id": "Green-tek-A22050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/13/2024 7:45",
    "plant_id": "Green-tek-A22051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/13/2024 8:00",
    "plant_id": "Green-tek-A22052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "8:00:00 AM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "7/13/2024 8:15",
    "plant_id": "Green-tek-A22053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "8:15:00 AM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "7/13/2024 8:30",
    "plant_id": "Green-tek-A22054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "8:30:00 AM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "7/13/2024 8:45",
    "plant_id": "Green-tek-A22055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "8:45:00 AM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "7/13/2024 9:00",
    "plant_id": "Green-tek-A22056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "9:00:00 AM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "7/13/2024 9:15",
    "plant_id": "Green-tek-A22057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "9:15:00 AM",
    "power": 0.437,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "7/13/2024 9:30",
    "plant_id": "Green-tek-A22058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "9:30:00 AM",
    "power": 0.53,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "7/13/2024 9:45",
    "plant_id": "Green-tek-A22059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "9:45:00 AM",
    "power": 0.63,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "7/13/2024 10:00",
    "plant_id": "Green-tek-A22060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "10:00:00 AM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "7/13/2024 10:15",
    "plant_id": "Green-tek-A22061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "10:15:00 AM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "7/13/2024 10:30",
    "plant_id": "Green-tek-A22062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "10:30:00 AM",
    "power": 0.939,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "7/13/2024 10:45",
    "plant_id": "Green-tek-A22063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "10:45:00 AM",
    "power": 1.029,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "7/13/2024 11:00",
    "plant_id": "Green-tek-A22064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "11:00:00 AM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "7/13/2024 11:15",
    "plant_id": "Green-tek-A22065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "11:15:00 AM",
    "power": 1.163,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "7/13/2024 11:30",
    "plant_id": "Green-tek-A22066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "11:30:00 AM",
    "power": 1.199,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "7/13/2024 11:45",
    "plant_id": "Green-tek-A22067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "11:45:00 AM",
    "power": 1.211,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "7/13/2024 12:00",
    "plant_id": "Green-tek-A22068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "12:00:00 PM",
    "power": 1.199,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "7/13/2024 12:15",
    "plant_id": "Green-tek-A22069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "12:15:00 PM",
    "power": 1.163,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "7/13/2024 12:30",
    "plant_id": "Green-tek-A22070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "12:30:00 PM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "7/13/2024 12:45",
    "plant_id": "Green-tek-A22071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "12:45:00 PM",
    "power": 1.029,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "7/13/2024 13:00",
    "plant_id": "Green-tek-A22072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "1:00:00 PM",
    "power": 0.939,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "7/13/2024 13:15",
    "plant_id": "Green-tek-A22073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "1:15:00 PM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "7/13/2024 13:30",
    "plant_id": "Green-tek-A22074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "1:30:00 PM",
    "power": 0.735,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "7/13/2024 13:45",
    "plant_id": "Green-tek-A22075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "1:45:00 PM",
    "power": 0.63,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "7/13/2024 14:00",
    "plant_id": "Green-tek-A22076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "2:00:00 PM",
    "power": 0.53,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "7/13/2024 14:15",
    "plant_id": "Green-tek-A22077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "2:15:00 PM",
    "power": 0.437,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "7/13/2024 14:30",
    "plant_id": "Green-tek-A22078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "2:30:00 PM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "7/13/2024 14:45",
    "plant_id": "Green-tek-A22079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "2:45:00 PM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "7/13/2024 15:00",
    "plant_id": "Green-tek-A22080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "3:00:00 PM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "7/13/2024 15:15",
    "plant_id": "Green-tek-A22081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "3:15:00 PM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "7/13/2024 15:30",
    "plant_id": "Green-tek-A22082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "3:30:00 PM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "7/13/2024 15:45",
    "plant_id": "Green-tek-A22083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/13/2024 16:00",
    "plant_id": "Green-tek-A22084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/13/2024 16:15",
    "plant_id": "Green-tek-A22085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/13/2024 16:30",
    "plant_id": "Green-tek-A22086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/13/2024 16:45",
    "plant_id": "Green-tek-A22087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/13/2024 17:00",
    "plant_id": "Green-tek-A22088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/13/2024 17:15",
    "plant_id": "Green-tek-A22089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/13/2024 17:30",
    "plant_id": "Green-tek-A22090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/13/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/14/2024 6:00",
    "plant_id": "Green-tek-A22091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/14/2024 6:15",
    "plant_id": "Green-tek-A22092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/14/2024 6:30",
    "plant_id": "Green-tek-A22093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/14/2024 6:45",
    "plant_id": "Green-tek-A22094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/14/2024 7:00",
    "plant_id": "Green-tek-A22095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/14/2024 7:15",
    "plant_id": "Green-tek-A22096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/14/2024 7:30",
    "plant_id": "Green-tek-A22097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/14/2024 7:45",
    "plant_id": "Green-tek-A22098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/14/2024 8:00",
    "plant_id": "Green-tek-A22099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "8:00:00 AM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "7/14/2024 8:15",
    "plant_id": "Green-tek-A22100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "8:15:00 AM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "7/14/2024 8:30",
    "plant_id": "Green-tek-A22101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "8:30:00 AM",
    "power": 0.226,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "7/14/2024 8:45",
    "plant_id": "Green-tek-A22102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "8:45:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/14/2024 9:00",
    "plant_id": "Green-tek-A22103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "9:00:00 AM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "7/14/2024 9:15",
    "plant_id": "Green-tek-A22104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "9:15:00 AM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "7/14/2024 9:30",
    "plant_id": "Green-tek-A22105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "9:30:00 AM",
    "power": 0.555,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/14/2024 9:45",
    "plant_id": "Green-tek-A22106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "9:45:00 AM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "7/14/2024 10:00",
    "plant_id": "Green-tek-A22107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "10:00:00 AM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "7/14/2024 10:15",
    "plant_id": "Green-tek-A22108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "10:15:00 AM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "7/14/2024 10:30",
    "plant_id": "Green-tek-A22109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "10:30:00 AM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "7/14/2024 10:45",
    "plant_id": "Green-tek-A22110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "10:45:00 AM",
    "power": 1.078,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "7/14/2024 11:00",
    "plant_id": "Green-tek-A22111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "11:00:00 AM",
    "power": 1.158,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "7/14/2024 11:15",
    "plant_id": "Green-tek-A22112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "11:15:00 AM",
    "power": 1.219,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.32028,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "7/14/2024 11:30",
    "plant_id": "Green-tek-A22113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "11:30:00 AM",
    "power": 1.256,
    "Energy": 0.314,
    "output Energy": 0.314,
    "Ac output": 1.314,
    "dc output": 1.34028,
    "AC_R_i": 0.00040957,
    "AC_S_i": 0.000450527,
    "AC_T_i": 0.00049558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0942011,
    "AC_S_pow": 0.10362121,
    "AC_T_Pow": 0.1139834,
    "AC_R_output": 0.3942,
    "AC_S_output": 0.43362,
    "AC_T_output": 0.476982,
    "dc_current": 0.00230882,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.32028,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000314,
    "carbon offset": 0.3925,
    "trees planted": 8.722222222,
    "DC_R_output": 0.130086,
    "DC_S_output": 0.1430946,
    "DC_T_output": 0.15740406
  },
  {
    "datetime": "7/14/2024 11:45",
    "plant_id": "Green-tek-A22114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "11:45:00 AM",
    "power": 1.269,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.32028,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "7/14/2024 12:00",
    "plant_id": "Green-tek-A22115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "12:00:00 PM",
    "power": 1.256,
    "Energy": 0.314,
    "output Energy": 0.314,
    "Ac output": 1.314,
    "dc output": 1.34028,
    "AC_R_i": 0.00040957,
    "AC_S_i": 0.000450527,
    "AC_T_i": 0.00049558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0942011,
    "AC_S_pow": 0.10362121,
    "AC_T_Pow": 0.1139834,
    "AC_R_output": 0.3942,
    "AC_S_output": 0.43362,
    "AC_T_output": 0.476982,
    "dc_current": 0.00230882,
    "dc_voltage": 136,
    "dc_power": 0.3111,
    "dc_output": 0.32028,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000314,
    "carbon offset": 0.3925,
    "trees planted": 8.722222222,
    "DC_R_output": 0.130086,
    "DC_S_output": 0.1430946,
    "DC_T_output": 0.15740406
  },
  {
    "datetime": "7/14/2024 12:15",
    "plant_id": "Green-tek-A22116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "12:15:00 PM",
    "power": 1.219,
    "Energy": 0.305,
    "output Energy": 0.305,
    "Ac output": 1.305,
    "dc output": 1.3311,
    "AC_R_i": 0.00039783,
    "AC_S_i": 0.000437613,
    "AC_T_i": 0.00048137,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0915009,
    "AC_S_pow": 0.10065099,
    "AC_T_Pow": 0.1107151,
    "AC_R_output": 0.3915,
    "AC_S_output": 0.43065,
    "AC_T_output": 0.473715,
    "dc_current": 0.00224265,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.3111,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000305,
    "carbon offset": 0.38125,
    "trees planted": 8.472222222,
    "DC_R_output": 0.129195,
    "DC_S_output": 0.1421145,
    "DC_T_output": 0.15632595
  },
  {
    "datetime": "7/14/2024 12:30",
    "plant_id": "Green-tek-A22117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "12:30:00 PM",
    "power": 1.158,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "7/14/2024 12:45",
    "plant_id": "Green-tek-A22118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "12:45:00 PM",
    "power": 1.078,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "7/14/2024 13:00",
    "plant_id": "Green-tek-A22119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "1:00:00 PM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "7/14/2024 13:15",
    "plant_id": "Green-tek-A22120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "1:15:00 PM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "7/14/2024 13:30",
    "plant_id": "Green-tek-A22121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "1:30:00 PM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "7/14/2024 13:45",
    "plant_id": "Green-tek-A22122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "1:45:00 PM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "7/14/2024 14:00",
    "plant_id": "Green-tek-A22123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "2:00:00 PM",
    "power": 0.555,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/14/2024 14:15",
    "plant_id": "Green-tek-A22124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "2:15:00 PM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "7/14/2024 14:30",
    "plant_id": "Green-tek-A22125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "2:30:00 PM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "7/14/2024 14:45",
    "plant_id": "Green-tek-A22126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "2:45:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/14/2024 15:00",
    "plant_id": "Green-tek-A22127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "3:00:00 PM",
    "power": 0.226,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "7/14/2024 15:15",
    "plant_id": "Green-tek-A22128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "3:15:00 PM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "7/14/2024 15:30",
    "plant_id": "Green-tek-A22129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "3:30:00 PM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "7/14/2024 15:45",
    "plant_id": "Green-tek-A22130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/14/2024 16:00",
    "plant_id": "Green-tek-A22131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/14/2024 16:15",
    "plant_id": "Green-tek-A22132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/14/2024 16:30",
    "plant_id": "Green-tek-A22133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/14/2024 16:45",
    "plant_id": "Green-tek-A22134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/14/2024 17:00",
    "plant_id": "Green-tek-A22135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/14/2024 17:15",
    "plant_id": "Green-tek-A22136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/14/2024 17:30",
    "plant_id": "Green-tek-A22137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/14/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/15/2024 6:00",
    "plant_id": "Green-tek-A22138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/15/2024 6:15",
    "plant_id": "Green-tek-A22139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/15/2024 6:30",
    "plant_id": "Green-tek-A22140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/15/2024 6:45",
    "plant_id": "Green-tek-A22141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/15/2024 7:00",
    "plant_id": "Green-tek-A22142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/15/2024 7:15",
    "plant_id": "Green-tek-A22143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/15/2024 7:30",
    "plant_id": "Green-tek-A22144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "7:30:00 AM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/15/2024 7:45",
    "plant_id": "Green-tek-A22145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "7:45:00 AM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/15/2024 8:00",
    "plant_id": "Green-tek-A22146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "8:00:00 AM",
    "power": 0.098,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/15/2024 8:15",
    "plant_id": "Green-tek-A22147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "8:15:00 AM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "7/15/2024 8:30",
    "plant_id": "Green-tek-A22148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "8:30:00 AM",
    "power": 0.174,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "7/15/2024 8:45",
    "plant_id": "Green-tek-A22149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "8:45:00 AM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "7/15/2024 9:00",
    "plant_id": "Green-tek-A22150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "9:00:00 AM",
    "power": 0.284,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "7/15/2024 9:15",
    "plant_id": "Green-tek-A22151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "9:15:00 AM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "7/15/2024 9:30",
    "plant_id": "Green-tek-A22152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "9:30:00 AM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "7/15/2024 9:45",
    "plant_id": "Green-tek-A22153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "9:45:00 AM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "7/15/2024 10:00",
    "plant_id": "Green-tek-A22154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "10:00:00 AM",
    "power": 0.592,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "7/15/2024 10:15",
    "plant_id": "Green-tek-A22155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "10:15:00 AM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "7/15/2024 10:30",
    "plant_id": "Green-tek-A22156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "10:30:00 AM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "7/15/2024 10:45",
    "plant_id": "Green-tek-A22157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "10:45:00 AM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "7/15/2024 11:00",
    "plant_id": "Green-tek-A22158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "11:00:00 AM",
    "power": 0.891,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "7/15/2024 11:15",
    "plant_id": "Green-tek-A22159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "11:15:00 AM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "7/15/2024 11:30",
    "plant_id": "Green-tek-A22160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "11:30:00 AM",
    "power": 0.966,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/15/2024 11:45",
    "plant_id": "Green-tek-A22161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "11:45:00 AM",
    "power": 0.976,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "7/15/2024 12:00",
    "plant_id": "Green-tek-A22162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "12:00:00 PM",
    "power": 0.966,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/15/2024 12:15",
    "plant_id": "Green-tek-A22163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "12:15:00 PM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "7/15/2024 12:30",
    "plant_id": "Green-tek-A22164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "12:30:00 PM",
    "power": 0.891,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "7/15/2024 12:45",
    "plant_id": "Green-tek-A22165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "12:45:00 PM",
    "power": 0.829,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "7/15/2024 13:00",
    "plant_id": "Green-tek-A22166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "1:00:00 PM",
    "power": 0.756,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "7/15/2024 13:15",
    "plant_id": "Green-tek-A22167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "1:15:00 PM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "7/15/2024 13:30",
    "plant_id": "Green-tek-A22168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "1:30:00 PM",
    "power": 0.592,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "7/15/2024 13:45",
    "plant_id": "Green-tek-A22169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "1:45:00 PM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "7/15/2024 14:00",
    "plant_id": "Green-tek-A22170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "2:00:00 PM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "7/15/2024 14:15",
    "plant_id": "Green-tek-A22171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "2:15:00 PM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "7/15/2024 14:30",
    "plant_id": "Green-tek-A22172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "2:30:00 PM",
    "power": 0.284,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "7/15/2024 14:45",
    "plant_id": "Green-tek-A22173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "2:45:00 PM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "7/15/2024 15:00",
    "plant_id": "Green-tek-A22174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "3:00:00 PM",
    "power": 0.174,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "7/15/2024 15:15",
    "plant_id": "Green-tek-A22175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "3:15:00 PM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "7/15/2024 15:30",
    "plant_id": "Green-tek-A22176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "3:30:00 PM",
    "power": 0.098,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/15/2024 15:45",
    "plant_id": "Green-tek-A22177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "3:45:00 PM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/15/2024 16:00",
    "plant_id": "Green-tek-A22178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "4:00:00 PM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/15/2024 16:15",
    "plant_id": "Green-tek-A22179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/15/2024 16:30",
    "plant_id": "Green-tek-A22180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/15/2024 16:45",
    "plant_id": "Green-tek-A22181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/15/2024 17:00",
    "plant_id": "Green-tek-A22182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/15/2024 17:15",
    "plant_id": "Green-tek-A22183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/15/2024 17:30",
    "plant_id": "Green-tek-A22184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/15/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/16/2024 6:00",
    "plant_id": "Green-tek-A22185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/16/2024 6:15",
    "plant_id": "Green-tek-A22186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/16/2024 6:30",
    "plant_id": "Green-tek-A22187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/16/2024 6:45",
    "plant_id": "Green-tek-A22188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/16/2024 7:00",
    "plant_id": "Green-tek-A22189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/16/2024 7:15",
    "plant_id": "Green-tek-A22190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/16/2024 7:30",
    "plant_id": "Green-tek-A22191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "7:30:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/16/2024 7:45",
    "plant_id": "Green-tek-A22192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "7:45:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/16/2024 8:00",
    "plant_id": "Green-tek-A22193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "8:00:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/16/2024 8:15",
    "plant_id": "Green-tek-A22194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "8:15:00 AM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "7/16/2024 8:30",
    "plant_id": "Green-tek-A22195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "8:30:00 AM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "7/16/2024 8:45",
    "plant_id": "Green-tek-A22196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "8:45:00 AM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "7/16/2024 9:00",
    "plant_id": "Green-tek-A22197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "9:00:00 AM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "7/16/2024 9:15",
    "plant_id": "Green-tek-A22198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "9:15:00 AM",
    "power": 0.33,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "7/16/2024 9:30",
    "plant_id": "Green-tek-A22199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "9:30:00 AM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "7/16/2024 9:45",
    "plant_id": "Green-tek-A22200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "9:45:00 AM",
    "power": 0.477,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "7/16/2024 10:00",
    "plant_id": "Green-tek-A22201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "10:00:00 AM",
    "power": 0.556,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/16/2024 10:15",
    "plant_id": "Green-tek-A22202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "10:15:00 AM",
    "power": 0.635,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/16/2024 10:30",
    "plant_id": "Green-tek-A22203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "10:30:00 AM",
    "power": 0.71,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "7/16/2024 10:45",
    "plant_id": "Green-tek-A22204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "10:45:00 AM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/16/2024 11:00",
    "plant_id": "Green-tek-A22205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "11:00:00 AM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "7/16/2024 11:15",
    "plant_id": "Green-tek-A22206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "11:15:00 AM",
    "power": 0.88,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "7/16/2024 11:30",
    "plant_id": "Green-tek-A22207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "11:30:00 AM",
    "power": 0.908,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "7/16/2024 11:45",
    "plant_id": "Green-tek-A22208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "11:45:00 AM",
    "power": 0.917,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "7/16/2024 12:00",
    "plant_id": "Green-tek-A22209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "12:00:00 PM",
    "power": 0.908,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "7/16/2024 12:15",
    "plant_id": "Green-tek-A22210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "12:15:00 PM",
    "power": 0.88,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "7/16/2024 12:30",
    "plant_id": "Green-tek-A22211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "12:30:00 PM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "7/16/2024 12:45",
    "plant_id": "Green-tek-A22212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "12:45:00 PM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/16/2024 13:00",
    "plant_id": "Green-tek-A22213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "1:00:00 PM",
    "power": 0.71,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "7/16/2024 13:15",
    "plant_id": "Green-tek-A22214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "1:15:00 PM",
    "power": 0.635,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/16/2024 13:30",
    "plant_id": "Green-tek-A22215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "1:30:00 PM",
    "power": 0.556,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/16/2024 13:45",
    "plant_id": "Green-tek-A22216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "1:45:00 PM",
    "power": 0.477,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "7/16/2024 14:00",
    "plant_id": "Green-tek-A22217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "2:00:00 PM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "7/16/2024 14:15",
    "plant_id": "Green-tek-A22218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "2:15:00 PM",
    "power": 0.33,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "7/16/2024 14:30",
    "plant_id": "Green-tek-A22219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "2:30:00 PM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "7/16/2024 14:45",
    "plant_id": "Green-tek-A22220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "2:45:00 PM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "7/16/2024 15:00",
    "plant_id": "Green-tek-A22221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "3:00:00 PM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "7/16/2024 15:15",
    "plant_id": "Green-tek-A22222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "3:15:00 PM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "7/16/2024 15:30",
    "plant_id": "Green-tek-A22223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "3:30:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/16/2024 15:45",
    "plant_id": "Green-tek-A22224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "3:45:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/16/2024 16:00",
    "plant_id": "Green-tek-A22225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "4:00:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/16/2024 16:15",
    "plant_id": "Green-tek-A22226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/16/2024 16:30",
    "plant_id": "Green-tek-A22227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/16/2024 16:45",
    "plant_id": "Green-tek-A22228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/16/2024 17:00",
    "plant_id": "Green-tek-A22229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/16/2024 17:15",
    "plant_id": "Green-tek-A22230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/16/2024 17:30",
    "plant_id": "Green-tek-A22231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/16/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/17/2024 6:00",
    "plant_id": "Green-tek-A22232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/17/2024 6:15",
    "plant_id": "Green-tek-A22233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/17/2024 6:30",
    "plant_id": "Green-tek-A22234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/17/2024 6:45",
    "plant_id": "Green-tek-A22235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/17/2024 7:00",
    "plant_id": "Green-tek-A22236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/17/2024 7:15",
    "plant_id": "Green-tek-A22237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/17/2024 7:30",
    "plant_id": "Green-tek-A22238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/17/2024 7:45",
    "plant_id": "Green-tek-A22239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "7:45:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/17/2024 8:00",
    "plant_id": "Green-tek-A22240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "8:00:00 AM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/17/2024 8:15",
    "plant_id": "Green-tek-A22241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "8:15:00 AM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "7/17/2024 8:30",
    "plant_id": "Green-tek-A22242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "8:30:00 AM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "7/17/2024 8:45",
    "plant_id": "Green-tek-A22243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "8:45:00 AM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "7/17/2024 9:00",
    "plant_id": "Green-tek-A22244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "9:00:00 AM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "7/17/2024 9:15",
    "plant_id": "Green-tek-A22245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "9:15:00 AM",
    "power": 0.294,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/17/2024 9:30",
    "plant_id": "Green-tek-A22246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "9:30:00 AM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "7/17/2024 9:45",
    "plant_id": "Green-tek-A22247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "9:45:00 AM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "7/17/2024 10:00",
    "plant_id": "Green-tek-A22248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "10:00:00 AM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "7/17/2024 10:15",
    "plant_id": "Green-tek-A22249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "10:15:00 AM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "7/17/2024 10:30",
    "plant_id": "Green-tek-A22250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "10:30:00 AM",
    "power": 0.632,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "7/17/2024 10:45",
    "plant_id": "Green-tek-A22251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "10:45:00 AM",
    "power": 0.693,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "7/17/2024 11:00",
    "plant_id": "Green-tek-A22252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "11:00:00 AM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "7/17/2024 11:15",
    "plant_id": "Green-tek-A22253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "11:15:00 AM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "7/17/2024 11:30",
    "plant_id": "Green-tek-A22254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "11:30:00 AM",
    "power": 0.807,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "7/17/2024 11:45",
    "plant_id": "Green-tek-A22255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "11:45:00 AM",
    "power": 0.815,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "7/17/2024 12:00",
    "plant_id": "Green-tek-A22256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "12:00:00 PM",
    "power": 0.807,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "7/17/2024 12:15",
    "plant_id": "Green-tek-A22257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "12:15:00 PM",
    "power": 0.783,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "7/17/2024 12:30",
    "plant_id": "Green-tek-A22258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "12:30:00 PM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "7/17/2024 12:45",
    "plant_id": "Green-tek-A22259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "12:45:00 PM",
    "power": 0.693,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "7/17/2024 13:00",
    "plant_id": "Green-tek-A22260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "1:00:00 PM",
    "power": 0.632,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "7/17/2024 13:15",
    "plant_id": "Green-tek-A22261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "1:15:00 PM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "7/17/2024 13:30",
    "plant_id": "Green-tek-A22262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "1:30:00 PM",
    "power": 0.495,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "7/17/2024 13:45",
    "plant_id": "Green-tek-A22263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "1:45:00 PM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "7/17/2024 14:00",
    "plant_id": "Green-tek-A22264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "2:00:00 PM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "7/17/2024 14:15",
    "plant_id": "Green-tek-A22265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "2:15:00 PM",
    "power": 0.294,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/17/2024 14:30",
    "plant_id": "Green-tek-A22266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "2:30:00 PM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "7/17/2024 14:45",
    "plant_id": "Green-tek-A22267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "2:45:00 PM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "7/17/2024 15:00",
    "plant_id": "Green-tek-A22268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "3:00:00 PM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "7/17/2024 15:15",
    "plant_id": "Green-tek-A22269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "3:15:00 PM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "7/17/2024 15:30",
    "plant_id": "Green-tek-A22270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "3:30:00 PM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/17/2024 15:45",
    "plant_id": "Green-tek-A22271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "3:45:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/17/2024 16:00",
    "plant_id": "Green-tek-A22272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/17/2024 16:15",
    "plant_id": "Green-tek-A22273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/17/2024 16:30",
    "plant_id": "Green-tek-A22274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/17/2024 16:45",
    "plant_id": "Green-tek-A22275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/17/2024 17:00",
    "plant_id": "Green-tek-A22276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/17/2024 17:15",
    "plant_id": "Green-tek-A22277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/17/2024 17:30",
    "plant_id": "Green-tek-A22278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/17/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/18/2024 6:00",
    "plant_id": "Green-tek-A22279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/18/2024 6:15",
    "plant_id": "Green-tek-A22280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/18/2024 6:30",
    "plant_id": "Green-tek-A22281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/18/2024 6:45",
    "plant_id": "Green-tek-A22282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/18/2024 7:00",
    "plant_id": "Green-tek-A22283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/18/2024 7:15",
    "plant_id": "Green-tek-A22284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/18/2024 7:30",
    "plant_id": "Green-tek-A22285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/18/2024 7:45",
    "plant_id": "Green-tek-A22286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "7:45:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "7/18/2024 8:00",
    "plant_id": "Green-tek-A22287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "8:00:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "7/18/2024 8:15",
    "plant_id": "Green-tek-A22288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "8:15:00 AM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "7/18/2024 8:30",
    "plant_id": "Green-tek-A22289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "8:30:00 AM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "7/18/2024 8:45",
    "plant_id": "Green-tek-A22290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "8:45:00 AM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "7/18/2024 9:00",
    "plant_id": "Green-tek-A22291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "9:00:00 AM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "7/18/2024 9:15",
    "plant_id": "Green-tek-A22292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "9:15:00 AM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "7/18/2024 9:30",
    "plant_id": "Green-tek-A22293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "9:30:00 AM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "7/18/2024 9:45",
    "plant_id": "Green-tek-A22294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "9:45:00 AM",
    "power": 0.578,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "7/18/2024 10:00",
    "plant_id": "Green-tek-A22295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "10:00:00 AM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "7/18/2024 10:15",
    "plant_id": "Green-tek-A22296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "10:15:00 AM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "7/18/2024 10:30",
    "plant_id": "Green-tek-A22297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "10:30:00 AM",
    "power": 0.861,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "7/18/2024 10:45",
    "plant_id": "Green-tek-A22298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "10:45:00 AM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "7/18/2024 11:00",
    "plant_id": "Green-tek-A22299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "11:00:00 AM",
    "power": 1.014,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "7/18/2024 11:15",
    "plant_id": "Green-tek-A22300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "11:15:00 AM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "7/18/2024 11:30",
    "plant_id": "Green-tek-A22301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "11:30:00 AM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "7/18/2024 11:45",
    "plant_id": "Green-tek-A22302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "11:45:00 AM",
    "power": 1.112,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "7/18/2024 12:00",
    "plant_id": "Green-tek-A22303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "12:00:00 PM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "7/18/2024 12:15",
    "plant_id": "Green-tek-A22304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "12:15:00 PM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "7/18/2024 12:30",
    "plant_id": "Green-tek-A22305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "12:30:00 PM",
    "power": 1.014,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "7/18/2024 12:45",
    "plant_id": "Green-tek-A22306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "12:45:00 PM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "7/18/2024 13:00",
    "plant_id": "Green-tek-A22307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "1:00:00 PM",
    "power": 0.861,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "7/18/2024 13:15",
    "plant_id": "Green-tek-A22308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "1:15:00 PM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "7/18/2024 13:30",
    "plant_id": "Green-tek-A22309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "1:30:00 PM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "7/18/2024 13:45",
    "plant_id": "Green-tek-A22310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "1:45:00 PM",
    "power": 0.578,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "7/18/2024 14:00",
    "plant_id": "Green-tek-A22311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "2:00:00 PM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "7/18/2024 14:15",
    "plant_id": "Green-tek-A22312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "2:15:00 PM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "7/18/2024 14:30",
    "plant_id": "Green-tek-A22313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "2:30:00 PM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "7/18/2024 14:45",
    "plant_id": "Green-tek-A22314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "2:45:00 PM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "7/18/2024 15:00",
    "plant_id": "Green-tek-A22315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "3:00:00 PM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "7/18/2024 15:15",
    "plant_id": "Green-tek-A22316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "3:15:00 PM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "7/18/2024 15:30",
    "plant_id": "Green-tek-A22317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "3:30:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "7/18/2024 15:45",
    "plant_id": "Green-tek-A22318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "3:45:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "7/18/2024 16:00",
    "plant_id": "Green-tek-A22319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/18/2024 16:15",
    "plant_id": "Green-tek-A22320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/18/2024 16:30",
    "plant_id": "Green-tek-A22321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/18/2024 16:45",
    "plant_id": "Green-tek-A22322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/18/2024 17:00",
    "plant_id": "Green-tek-A22323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/18/2024 17:15",
    "plant_id": "Green-tek-A22324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/18/2024 17:30",
    "plant_id": "Green-tek-A22325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/18/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/19/2024 6:00",
    "plant_id": "Green-tek-A22326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/19/2024 6:15",
    "plant_id": "Green-tek-A22327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/19/2024 6:30",
    "plant_id": "Green-tek-A22328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/19/2024 6:45",
    "plant_id": "Green-tek-A22329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/19/2024 7:00",
    "plant_id": "Green-tek-A22330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/19/2024 7:15",
    "plant_id": "Green-tek-A22331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/19/2024 7:30",
    "plant_id": "Green-tek-A22332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/19/2024 7:45",
    "plant_id": "Green-tek-A22333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "7:45:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/19/2024 8:00",
    "plant_id": "Green-tek-A22334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "8:00:00 AM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "7/19/2024 8:15",
    "plant_id": "Green-tek-A22335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "8:15:00 AM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "7/19/2024 8:30",
    "plant_id": "Green-tek-A22336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "8:30:00 AM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "7/19/2024 8:45",
    "plant_id": "Green-tek-A22337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "8:45:00 AM",
    "power": 0.274,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "7/19/2024 9:00",
    "plant_id": "Green-tek-A22338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "9:00:00 AM",
    "power": 0.347,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "7/19/2024 9:15",
    "plant_id": "Green-tek-A22339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "9:15:00 AM",
    "power": 0.43,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "7/19/2024 9:30",
    "plant_id": "Green-tek-A22340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "9:30:00 AM",
    "power": 0.522,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "7/19/2024 9:45",
    "plant_id": "Green-tek-A22341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "9:45:00 AM",
    "power": 0.621,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "7/19/2024 10:00",
    "plant_id": "Green-tek-A22342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "10:00:00 AM",
    "power": 0.723,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "7/19/2024 10:15",
    "plant_id": "Green-tek-A22343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "10:15:00 AM",
    "power": 0.826,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "7/19/2024 10:30",
    "plant_id": "Green-tek-A22344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "10:30:00 AM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "7/19/2024 10:45",
    "plant_id": "Green-tek-A22345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "10:45:00 AM",
    "power": 1.013,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "7/19/2024 11:00",
    "plant_id": "Green-tek-A22346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "11:00:00 AM",
    "power": 1.088,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "7/19/2024 11:15",
    "plant_id": "Green-tek-A22347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "11:15:00 AM",
    "power": 1.145,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "7/19/2024 11:30",
    "plant_id": "Green-tek-A22348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "11:30:00 AM",
    "power": 1.18,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "7/19/2024 11:45",
    "plant_id": "Green-tek-A22349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "11:45:00 AM",
    "power": 1.193,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "7/19/2024 12:00",
    "plant_id": "Green-tek-A22350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "12:00:00 PM",
    "power": 1.18,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "7/19/2024 12:15",
    "plant_id": "Green-tek-A22351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "12:15:00 PM",
    "power": 1.145,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "7/19/2024 12:30",
    "plant_id": "Green-tek-A22352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "12:30:00 PM",
    "power": 1.088,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "7/19/2024 12:45",
    "plant_id": "Green-tek-A22353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "12:45:00 PM",
    "power": 1.013,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "7/19/2024 13:00",
    "plant_id": "Green-tek-A22354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "1:00:00 PM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "7/19/2024 13:15",
    "plant_id": "Green-tek-A22355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "1:15:00 PM",
    "power": 0.826,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "7/19/2024 13:30",
    "plant_id": "Green-tek-A22356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "1:30:00 PM",
    "power": 0.723,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "7/19/2024 13:45",
    "plant_id": "Green-tek-A22357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "1:45:00 PM",
    "power": 0.621,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "7/19/2024 14:00",
    "plant_id": "Green-tek-A22358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "2:00:00 PM",
    "power": 0.522,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "7/19/2024 14:15",
    "plant_id": "Green-tek-A22359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "2:15:00 PM",
    "power": 0.43,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "7/19/2024 14:30",
    "plant_id": "Green-tek-A22360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "2:30:00 PM",
    "power": 0.347,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "7/19/2024 14:45",
    "plant_id": "Green-tek-A22361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "2:45:00 PM",
    "power": 0.274,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "7/19/2024 15:00",
    "plant_id": "Green-tek-A22362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "3:00:00 PM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "7/19/2024 15:15",
    "plant_id": "Green-tek-A22363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "3:15:00 PM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "7/19/2024 15:30",
    "plant_id": "Green-tek-A22364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "3:30:00 PM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "7/19/2024 15:45",
    "plant_id": "Green-tek-A22365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "3:45:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "7/19/2024 16:00",
    "plant_id": "Green-tek-A22366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/19/2024 16:15",
    "plant_id": "Green-tek-A22367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/19/2024 16:30",
    "plant_id": "Green-tek-A22368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/19/2024 16:45",
    "plant_id": "Green-tek-A22369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/19/2024 17:00",
    "plant_id": "Green-tek-A22370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/19/2024 17:15",
    "plant_id": "Green-tek-A22371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/19/2024 17:30",
    "plant_id": "Green-tek-A22372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/19/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/20/2024 6:00",
    "plant_id": "Green-tek-A22373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/20/2024 6:15",
    "plant_id": "Green-tek-A22374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/20/2024 6:30",
    "plant_id": "Green-tek-A22375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/20/2024 6:45",
    "plant_id": "Green-tek-A22376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/20/2024 7:00",
    "plant_id": "Green-tek-A22377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/20/2024 7:15",
    "plant_id": "Green-tek-A22378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/20/2024 7:30",
    "plant_id": "Green-tek-A22379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "7:30:00 AM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/20/2024 7:45",
    "plant_id": "Green-tek-A22380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "7:45:00 AM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/20/2024 8:00",
    "plant_id": "Green-tek-A22381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "8:00:00 AM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "7/20/2024 8:15",
    "plant_id": "Green-tek-A22382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "8:15:00 AM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "7/20/2024 8:30",
    "plant_id": "Green-tek-A22383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "8:30:00 AM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "7/20/2024 8:45",
    "plant_id": "Green-tek-A22384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "8:45:00 AM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "7/20/2024 9:00",
    "plant_id": "Green-tek-A22385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "9:00:00 AM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "7/20/2024 9:15",
    "plant_id": "Green-tek-A22386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "9:15:00 AM",
    "power": 0.35,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "7/20/2024 9:30",
    "plant_id": "Green-tek-A22387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "9:30:00 AM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "7/20/2024 9:45",
    "plant_id": "Green-tek-A22388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "9:45:00 AM",
    "power": 0.506,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "7/20/2024 10:00",
    "plant_id": "Green-tek-A22389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "10:00:00 AM",
    "power": 0.59,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "7/20/2024 10:15",
    "plant_id": "Green-tek-A22390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "10:15:00 AM",
    "power": 0.673,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "7/20/2024 10:30",
    "plant_id": "Green-tek-A22391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "10:30:00 AM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "7/20/2024 10:45",
    "plant_id": "Green-tek-A22392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "10:45:00 AM",
    "power": 0.826,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "7/20/2024 11:00",
    "plant_id": "Green-tek-A22393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "11:00:00 AM",
    "power": 0.887,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "7/20/2024 11:15",
    "plant_id": "Green-tek-A22394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "11:15:00 AM",
    "power": 0.933,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "7/20/2024 11:30",
    "plant_id": "Green-tek-A22395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "11:30:00 AM",
    "power": 0.962,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "7/20/2024 11:45",
    "plant_id": "Green-tek-A22396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "11:45:00 AM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "7/20/2024 12:00",
    "plant_id": "Green-tek-A22397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "12:00:00 PM",
    "power": 0.962,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "7/20/2024 12:15",
    "plant_id": "Green-tek-A22398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "12:15:00 PM",
    "power": 0.933,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "7/20/2024 12:30",
    "plant_id": "Green-tek-A22399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "12:30:00 PM",
    "power": 0.887,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "7/20/2024 12:45",
    "plant_id": "Green-tek-A22400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "12:45:00 PM",
    "power": 0.826,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "7/20/2024 13:00",
    "plant_id": "Green-tek-A22401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "1:00:00 PM",
    "power": 0.753,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "7/20/2024 13:15",
    "plant_id": "Green-tek-A22402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "1:15:00 PM",
    "power": 0.673,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "7/20/2024 13:30",
    "plant_id": "Green-tek-A22403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "1:30:00 PM",
    "power": 0.59,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "7/20/2024 13:45",
    "plant_id": "Green-tek-A22404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "1:45:00 PM",
    "power": 0.506,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "7/20/2024 14:00",
    "plant_id": "Green-tek-A22405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "2:00:00 PM",
    "power": 0.425,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "7/20/2024 14:15",
    "plant_id": "Green-tek-A22406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "2:15:00 PM",
    "power": 0.35,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "7/20/2024 14:30",
    "plant_id": "Green-tek-A22407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "2:30:00 PM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "7/20/2024 14:45",
    "plant_id": "Green-tek-A22408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "2:45:00 PM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "7/20/2024 15:00",
    "plant_id": "Green-tek-A22409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "3:00:00 PM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "7/20/2024 15:15",
    "plant_id": "Green-tek-A22410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "3:15:00 PM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "7/20/2024 15:30",
    "plant_id": "Green-tek-A22411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "3:30:00 PM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "7/20/2024 15:45",
    "plant_id": "Green-tek-A22412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "3:45:00 PM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/20/2024 16:00",
    "plant_id": "Green-tek-A22413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "4:00:00 PM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/20/2024 16:15",
    "plant_id": "Green-tek-A22414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/20/2024 16:30",
    "plant_id": "Green-tek-A22415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/20/2024 16:45",
    "plant_id": "Green-tek-A22416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/20/2024 17:00",
    "plant_id": "Green-tek-A22417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/20/2024 17:15",
    "plant_id": "Green-tek-A22418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/20/2024 17:30",
    "plant_id": "Green-tek-A22419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/20/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/21/2024 6:00",
    "plant_id": "Green-tek-A22420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/21/2024 6:15",
    "plant_id": "Green-tek-A22421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/21/2024 6:30",
    "plant_id": "Green-tek-A22422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/21/2024 6:45",
    "plant_id": "Green-tek-A22423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/21/2024 7:00",
    "plant_id": "Green-tek-A22424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/21/2024 7:15",
    "plant_id": "Green-tek-A22425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/21/2024 7:30",
    "plant_id": "Green-tek-A22426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "7:30:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/21/2024 7:45",
    "plant_id": "Green-tek-A22427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "7:45:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/21/2024 8:00",
    "plant_id": "Green-tek-A22428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "8:00:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/21/2024 8:15",
    "plant_id": "Green-tek-A22429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "8:15:00 AM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "7/21/2024 8:30",
    "plant_id": "Green-tek-A22430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "8:30:00 AM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "7/21/2024 8:45",
    "plant_id": "Green-tek-A22431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "8:45:00 AM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "7/21/2024 9:00",
    "plant_id": "Green-tek-A22432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "9:00:00 AM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "7/21/2024 9:15",
    "plant_id": "Green-tek-A22433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "9:15:00 AM",
    "power": 0.33,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "7/21/2024 9:30",
    "plant_id": "Green-tek-A22434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "9:30:00 AM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "7/21/2024 9:45",
    "plant_id": "Green-tek-A22435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "9:45:00 AM",
    "power": 0.477,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "7/21/2024 10:00",
    "plant_id": "Green-tek-A22436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "10:00:00 AM",
    "power": 0.556,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/21/2024 10:15",
    "plant_id": "Green-tek-A22437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "10:15:00 AM",
    "power": 0.635,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/21/2024 10:30",
    "plant_id": "Green-tek-A22438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "10:30:00 AM",
    "power": 0.71,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "7/21/2024 10:45",
    "plant_id": "Green-tek-A22439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "10:45:00 AM",
    "power": 0.778,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/21/2024 11:00",
    "plant_id": "Green-tek-A22440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "11:00:00 AM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "7/21/2024 11:15",
    "plant_id": "Green-tek-A22441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "11:15:00 AM",
    "power": 0.88,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "7/21/2024 11:30",
    "plant_id": "Green-tek-A22442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "11:30:00 AM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "7/21/2024 11:45",
    "plant_id": "Green-tek-A22443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "11:45:00 AM",
    "power": 0.916,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "7/21/2024 12:00",
    "plant_id": "Green-tek-A22444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "12:00:00 PM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "7/21/2024 12:15",
    "plant_id": "Green-tek-A22445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "12:15:00 PM",
    "power": 0.88,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "7/21/2024 12:30",
    "plant_id": "Green-tek-A22446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "12:30:00 PM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "7/21/2024 12:45",
    "plant_id": "Green-tek-A22447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "12:45:00 PM",
    "power": 0.778,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/21/2024 13:00",
    "plant_id": "Green-tek-A22448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "1:00:00 PM",
    "power": 0.71,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "7/21/2024 13:15",
    "plant_id": "Green-tek-A22449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "1:15:00 PM",
    "power": 0.635,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/21/2024 13:30",
    "plant_id": "Green-tek-A22450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "1:30:00 PM",
    "power": 0.556,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/21/2024 13:45",
    "plant_id": "Green-tek-A22451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "1:45:00 PM",
    "power": 0.477,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "7/21/2024 14:00",
    "plant_id": "Green-tek-A22452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "2:00:00 PM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "7/21/2024 14:15",
    "plant_id": "Green-tek-A22453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "2:15:00 PM",
    "power": 0.33,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "7/21/2024 14:30",
    "plant_id": "Green-tek-A22454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "2:30:00 PM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "7/21/2024 14:45",
    "plant_id": "Green-tek-A22455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "2:45:00 PM",
    "power": 0.211,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "7/21/2024 15:00",
    "plant_id": "Green-tek-A22456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "3:00:00 PM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "7/21/2024 15:15",
    "plant_id": "Green-tek-A22457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "3:15:00 PM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "7/21/2024 15:30",
    "plant_id": "Green-tek-A22458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "3:30:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/21/2024 15:45",
    "plant_id": "Green-tek-A22459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "3:45:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/21/2024 16:00",
    "plant_id": "Green-tek-A22460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "4:00:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/21/2024 16:15",
    "plant_id": "Green-tek-A22461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/21/2024 16:30",
    "plant_id": "Green-tek-A22462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/21/2024 16:45",
    "plant_id": "Green-tek-A22463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/21/2024 17:00",
    "plant_id": "Green-tek-A22464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/21/2024 17:15",
    "plant_id": "Green-tek-A22465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/21/2024 17:30",
    "plant_id": "Green-tek-A22466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/21/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/22/2024 6:00",
    "plant_id": "Green-tek-A22467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/22/2024 6:15",
    "plant_id": "Green-tek-A22468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/22/2024 6:30",
    "plant_id": "Green-tek-A22469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/22/2024 6:45",
    "plant_id": "Green-tek-A22470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/22/2024 7:00",
    "plant_id": "Green-tek-A22471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/22/2024 7:15",
    "plant_id": "Green-tek-A22472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/22/2024 7:30",
    "plant_id": "Green-tek-A22473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/22/2024 7:45",
    "plant_id": "Green-tek-A22474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "7:45:00 AM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/22/2024 8:00",
    "plant_id": "Green-tek-A22475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "8:00:00 AM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "7/22/2024 8:15",
    "plant_id": "Green-tek-A22476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "7/22/2024 8:30",
    "plant_id": "Green-tek-A22477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "8:30:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "7/22/2024 8:45",
    "plant_id": "Green-tek-A22478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "8:45:00 AM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "7/22/2024 9:00",
    "plant_id": "Green-tek-A22479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "9:00:00 AM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "7/22/2024 9:15",
    "plant_id": "Green-tek-A22480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "9:15:00 AM",
    "power": 0.346,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "7/22/2024 9:30",
    "plant_id": "Green-tek-A22481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "9:30:00 AM",
    "power": 0.42,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "7/22/2024 9:45",
    "plant_id": "Green-tek-A22482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "9:45:00 AM",
    "power": 0.5,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "7/22/2024 10:00",
    "plant_id": "Green-tek-A22483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "10:00:00 AM",
    "power": 0.583,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "7/22/2024 10:15",
    "plant_id": "Green-tek-A22484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "10:15:00 AM",
    "power": 0.665,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "7/22/2024 10:30",
    "plant_id": "Green-tek-A22485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "10:30:00 AM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "7/22/2024 10:45",
    "plant_id": "Green-tek-A22486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "10:45:00 AM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "7/22/2024 11:00",
    "plant_id": "Green-tek-A22487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "11:00:00 AM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "7/22/2024 11:15",
    "plant_id": "Green-tek-A22488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "11:15:00 AM",
    "power": 0.922,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "7/22/2024 11:30",
    "plant_id": "Green-tek-A22489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "11:30:00 AM",
    "power": 0.951,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "7/22/2024 11:45",
    "plant_id": "Green-tek-A22490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "11:45:00 AM",
    "power": 0.961,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "7/22/2024 12:00",
    "plant_id": "Green-tek-A22491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "12:00:00 PM",
    "power": 0.951,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "7/22/2024 12:15",
    "plant_id": "Green-tek-A22492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "12:15:00 PM",
    "power": 0.922,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "7/22/2024 12:30",
    "plant_id": "Green-tek-A22493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "12:30:00 PM",
    "power": 0.876,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "7/22/2024 12:45",
    "plant_id": "Green-tek-A22494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "12:45:00 PM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "7/22/2024 13:00",
    "plant_id": "Green-tek-A22495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "1:00:00 PM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "7/22/2024 13:15",
    "plant_id": "Green-tek-A22496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "1:15:00 PM",
    "power": 0.665,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "7/22/2024 13:30",
    "plant_id": "Green-tek-A22497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "1:30:00 PM",
    "power": 0.583,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "7/22/2024 13:45",
    "plant_id": "Green-tek-A22498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "1:45:00 PM",
    "power": 0.5,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "7/22/2024 14:00",
    "plant_id": "Green-tek-A22499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "2:00:00 PM",
    "power": 0.42,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "7/22/2024 14:15",
    "plant_id": "Green-tek-A22500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "2:15:00 PM",
    "power": 0.346,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "7/22/2024 14:30",
    "plant_id": "Green-tek-A22501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "2:30:00 PM",
    "power": 0.279,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "7/22/2024 14:45",
    "plant_id": "Green-tek-A22502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "2:45:00 PM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "7/22/2024 15:00",
    "plant_id": "Green-tek-A22503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "3:00:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "7/22/2024 15:15",
    "plant_id": "Green-tek-A22504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "7/22/2024 15:30",
    "plant_id": "Green-tek-A22505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "3:30:00 PM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "7/22/2024 15:45",
    "plant_id": "Green-tek-A22506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "3:45:00 PM",
    "power": 0.07,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/22/2024 16:00",
    "plant_id": "Green-tek-A22507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/22/2024 16:15",
    "plant_id": "Green-tek-A22508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/22/2024 16:30",
    "plant_id": "Green-tek-A22509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/22/2024 16:45",
    "plant_id": "Green-tek-A22510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/22/2024 17:00",
    "plant_id": "Green-tek-A22511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/22/2024 17:15",
    "plant_id": "Green-tek-A22512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/22/2024 17:30",
    "plant_id": "Green-tek-A22513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/22/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/23/2024 6:00",
    "plant_id": "Green-tek-A22514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/23/2024 6:15",
    "plant_id": "Green-tek-A22515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/23/2024 6:30",
    "plant_id": "Green-tek-A22516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/23/2024 6:45",
    "plant_id": "Green-tek-A22517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/23/2024 7:00",
    "plant_id": "Green-tek-A22518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/23/2024 7:15",
    "plant_id": "Green-tek-A22519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/23/2024 7:30",
    "plant_id": "Green-tek-A22520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/23/2024 7:45",
    "plant_id": "Green-tek-A22521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "7:45:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/23/2024 8:00",
    "plant_id": "Green-tek-A22522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "8:00:00 AM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/23/2024 8:15",
    "plant_id": "Green-tek-A22523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "8:15:00 AM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/23/2024 8:30",
    "plant_id": "Green-tek-A22524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "8:30:00 AM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "7/23/2024 8:45",
    "plant_id": "Green-tek-A22525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "8:45:00 AM",
    "power": 0.265,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "7/23/2024 9:00",
    "plant_id": "Green-tek-A22526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "9:00:00 AM",
    "power": 0.335,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "7/23/2024 9:15",
    "plant_id": "Green-tek-A22527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "9:15:00 AM",
    "power": 0.415,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "7/23/2024 9:30",
    "plant_id": "Green-tek-A22528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "9:30:00 AM",
    "power": 0.504,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "7/23/2024 9:45",
    "plant_id": "Green-tek-A22529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "9:45:00 AM",
    "power": 0.6,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "7/23/2024 10:00",
    "plant_id": "Green-tek-A22530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "10:00:00 AM",
    "power": 0.699,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "7/23/2024 10:15",
    "plant_id": "Green-tek-A22531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "10:15:00 AM",
    "power": 0.798,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "7/23/2024 10:30",
    "plant_id": "Green-tek-A22532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "10:30:00 AM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "7/23/2024 10:45",
    "plant_id": "Green-tek-A22533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "10:45:00 AM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "7/23/2024 11:00",
    "plant_id": "Green-tek-A22534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "11:00:00 AM",
    "power": 1.051,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "7/23/2024 11:15",
    "plant_id": "Green-tek-A22535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "11:15:00 AM",
    "power": 1.106,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "7/23/2024 11:30",
    "plant_id": "Green-tek-A22536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "11:30:00 AM",
    "power": 1.14,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "7/23/2024 11:45",
    "plant_id": "Green-tek-A22537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "11:45:00 AM",
    "power": 1.152,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "7/23/2024 12:00",
    "plant_id": "Green-tek-A22538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "12:00:00 PM",
    "power": 1.14,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "7/23/2024 12:15",
    "plant_id": "Green-tek-A22539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "12:15:00 PM",
    "power": 1.106,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "7/23/2024 12:30",
    "plant_id": "Green-tek-A22540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "12:30:00 PM",
    "power": 1.051,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "7/23/2024 12:45",
    "plant_id": "Green-tek-A22541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "12:45:00 PM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "7/23/2024 13:00",
    "plant_id": "Green-tek-A22542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "1:00:00 PM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "7/23/2024 13:15",
    "plant_id": "Green-tek-A22543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "1:15:00 PM",
    "power": 0.798,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "7/23/2024 13:30",
    "plant_id": "Green-tek-A22544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "1:30:00 PM",
    "power": 0.699,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "7/23/2024 13:45",
    "plant_id": "Green-tek-A22545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "1:45:00 PM",
    "power": 0.6,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "7/23/2024 14:00",
    "plant_id": "Green-tek-A22546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "2:00:00 PM",
    "power": 0.504,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "7/23/2024 14:15",
    "plant_id": "Green-tek-A22547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "2:15:00 PM",
    "power": 0.415,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "7/23/2024 14:30",
    "plant_id": "Green-tek-A22548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "2:30:00 PM",
    "power": 0.335,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "7/23/2024 14:45",
    "plant_id": "Green-tek-A22549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "2:45:00 PM",
    "power": 0.265,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "7/23/2024 15:00",
    "plant_id": "Green-tek-A22550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "3:00:00 PM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "7/23/2024 15:15",
    "plant_id": "Green-tek-A22551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "3:15:00 PM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/23/2024 15:30",
    "plant_id": "Green-tek-A22552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "3:30:00 PM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/23/2024 15:45",
    "plant_id": "Green-tek-A22553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "3:45:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/23/2024 16:00",
    "plant_id": "Green-tek-A22554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/23/2024 16:15",
    "plant_id": "Green-tek-A22555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/23/2024 16:30",
    "plant_id": "Green-tek-A22556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/23/2024 16:45",
    "plant_id": "Green-tek-A22557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/23/2024 17:00",
    "plant_id": "Green-tek-A22558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/23/2024 17:15",
    "plant_id": "Green-tek-A22559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/23/2024 17:30",
    "plant_id": "Green-tek-A22560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/23/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/24/2024 6:00",
    "plant_id": "Green-tek-A22561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/24/2024 6:15",
    "plant_id": "Green-tek-A22562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/24/2024 6:30",
    "plant_id": "Green-tek-A22563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/24/2024 6:45",
    "plant_id": "Green-tek-A22564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/24/2024 7:00",
    "plant_id": "Green-tek-A22565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/24/2024 7:15",
    "plant_id": "Green-tek-A22566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/24/2024 7:30",
    "plant_id": "Green-tek-A22567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/24/2024 7:45",
    "plant_id": "Green-tek-A22568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/24/2024 8:00",
    "plant_id": "Green-tek-A22569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "8:00:00 AM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/24/2024 8:15",
    "plant_id": "Green-tek-A22570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "8:15:00 AM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/24/2024 8:30",
    "plant_id": "Green-tek-A22571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "8:30:00 AM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "7/24/2024 8:45",
    "plant_id": "Green-tek-A22572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "8:45:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/24/2024 9:00",
    "plant_id": "Green-tek-A22573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "9:00:00 AM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/24/2024 9:15",
    "plant_id": "Green-tek-A22574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "9:15:00 AM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "7/24/2024 9:30",
    "plant_id": "Green-tek-A22575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "9:30:00 AM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "7/24/2024 9:45",
    "plant_id": "Green-tek-A22576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "9:45:00 AM",
    "power": 0.524,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "7/24/2024 10:00",
    "plant_id": "Green-tek-A22577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "10:00:00 AM",
    "power": 0.611,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "7/24/2024 10:15",
    "plant_id": "Green-tek-A22578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "10:15:00 AM",
    "power": 0.698,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "7/24/2024 10:30",
    "plant_id": "Green-tek-A22579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "10:30:00 AM",
    "power": 0.781,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/24/2024 10:45",
    "plant_id": "Green-tek-A22580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "10:45:00 AM",
    "power": 0.856,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "7/24/2024 11:00",
    "plant_id": "Green-tek-A22581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "11:00:00 AM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "7/24/2024 11:15",
    "plant_id": "Green-tek-A22582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "11:15:00 AM",
    "power": 0.967,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/24/2024 11:30",
    "plant_id": "Green-tek-A22583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "11:30:00 AM",
    "power": 0.997,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "7/24/2024 11:45",
    "plant_id": "Green-tek-A22584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "11:45:00 AM",
    "power": 1.007,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "7/24/2024 12:00",
    "plant_id": "Green-tek-A22585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "12:00:00 PM",
    "power": 0.997,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "7/24/2024 12:15",
    "plant_id": "Green-tek-A22586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "12:15:00 PM",
    "power": 0.967,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/24/2024 12:30",
    "plant_id": "Green-tek-A22587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "12:30:00 PM",
    "power": 0.919,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "7/24/2024 12:45",
    "plant_id": "Green-tek-A22588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "12:45:00 PM",
    "power": 0.856,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "7/24/2024 13:00",
    "plant_id": "Green-tek-A22589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "1:00:00 PM",
    "power": 0.781,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/24/2024 13:15",
    "plant_id": "Green-tek-A22590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "1:15:00 PM",
    "power": 0.698,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "7/24/2024 13:30",
    "plant_id": "Green-tek-A22591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "1:30:00 PM",
    "power": 0.611,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "7/24/2024 13:45",
    "plant_id": "Green-tek-A22592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "1:45:00 PM",
    "power": 0.524,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "7/24/2024 14:00",
    "plant_id": "Green-tek-A22593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "2:00:00 PM",
    "power": 0.441,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "7/24/2024 14:15",
    "plant_id": "Green-tek-A22594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "2:15:00 PM",
    "power": 0.363,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "7/24/2024 14:30",
    "plant_id": "Green-tek-A22595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "2:30:00 PM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/24/2024 14:45",
    "plant_id": "Green-tek-A22596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "2:45:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/24/2024 15:00",
    "plant_id": "Green-tek-A22597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "3:00:00 PM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "7/24/2024 15:15",
    "plant_id": "Green-tek-A22598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "3:15:00 PM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/24/2024 15:30",
    "plant_id": "Green-tek-A22599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "3:30:00 PM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/24/2024 15:45",
    "plant_id": "Green-tek-A22600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/24/2024 16:00",
    "plant_id": "Green-tek-A22601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/24/2024 16:15",
    "plant_id": "Green-tek-A22602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/24/2024 16:30",
    "plant_id": "Green-tek-A22603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/24/2024 16:45",
    "plant_id": "Green-tek-A22604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/24/2024 17:00",
    "plant_id": "Green-tek-A22605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/24/2024 17:15",
    "plant_id": "Green-tek-A22606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/24/2024 17:30",
    "plant_id": "Green-tek-A22607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/24/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/25/2024 6:00",
    "plant_id": "Green-tek-A22608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/25/2024 6:15",
    "plant_id": "Green-tek-A22609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/25/2024 6:30",
    "plant_id": "Green-tek-A22610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/25/2024 6:45",
    "plant_id": "Green-tek-A22611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/25/2024 7:00",
    "plant_id": "Green-tek-A22612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/25/2024 7:15",
    "plant_id": "Green-tek-A22613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/25/2024 7:30",
    "plant_id": "Green-tek-A22614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/25/2024 7:45",
    "plant_id": "Green-tek-A22615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/25/2024 8:00",
    "plant_id": "Green-tek-A22616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "8:00:00 AM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/25/2024 8:15",
    "plant_id": "Green-tek-A22617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "8:15:00 AM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/25/2024 8:30",
    "plant_id": "Green-tek-A22618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "8:30:00 AM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "7/25/2024 8:45",
    "plant_id": "Green-tek-A22619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "8:45:00 AM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/25/2024 9:00",
    "plant_id": "Green-tek-A22620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "9:00:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/25/2024 9:15",
    "plant_id": "Green-tek-A22621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "9:15:00 AM",
    "power": 0.362,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "7/25/2024 9:30",
    "plant_id": "Green-tek-A22622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "9:30:00 AM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "7/25/2024 9:45",
    "plant_id": "Green-tek-A22623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "9:45:00 AM",
    "power": 0.523,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "7/25/2024 10:00",
    "plant_id": "Green-tek-A22624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "10:00:00 AM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "7/25/2024 10:15",
    "plant_id": "Green-tek-A22625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "10:15:00 AM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "7/25/2024 10:30",
    "plant_id": "Green-tek-A22626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "10:30:00 AM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/25/2024 10:45",
    "plant_id": "Green-tek-A22627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "10:45:00 AM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "7/25/2024 11:00",
    "plant_id": "Green-tek-A22628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "11:00:00 AM",
    "power": 0.917,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "7/25/2024 11:15",
    "plant_id": "Green-tek-A22629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "11:15:00 AM",
    "power": 0.965,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "7/25/2024 11:30",
    "plant_id": "Green-tek-A22630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "11:30:00 AM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "7/25/2024 11:45",
    "plant_id": "Green-tek-A22631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "11:45:00 AM",
    "power": 1.005,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "7/25/2024 12:00",
    "plant_id": "Green-tek-A22632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "12:00:00 PM",
    "power": 0.995,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "7/25/2024 12:15",
    "plant_id": "Green-tek-A22633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "12:15:00 PM",
    "power": 0.965,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "7/25/2024 12:30",
    "plant_id": "Green-tek-A22634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "12:30:00 PM",
    "power": 0.917,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "7/25/2024 12:45",
    "plant_id": "Green-tek-A22635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "12:45:00 PM",
    "power": 0.853,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "7/25/2024 13:00",
    "plant_id": "Green-tek-A22636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "1:00:00 PM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "7/25/2024 13:15",
    "plant_id": "Green-tek-A22637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "1:15:00 PM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "7/25/2024 13:30",
    "plant_id": "Green-tek-A22638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "1:30:00 PM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "7/25/2024 13:45",
    "plant_id": "Green-tek-A22639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "1:45:00 PM",
    "power": 0.523,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "7/25/2024 14:00",
    "plant_id": "Green-tek-A22640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "2:00:00 PM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "7/25/2024 14:15",
    "plant_id": "Green-tek-A22641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "2:15:00 PM",
    "power": 0.362,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "7/25/2024 14:30",
    "plant_id": "Green-tek-A22642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "2:30:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "7/25/2024 14:45",
    "plant_id": "Green-tek-A22643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "2:45:00 PM",
    "power": 0.231,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/25/2024 15:00",
    "plant_id": "Green-tek-A22644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "3:00:00 PM",
    "power": 0.179,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "7/25/2024 15:15",
    "plant_id": "Green-tek-A22645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "3:15:00 PM",
    "power": 0.136,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "7/25/2024 15:30",
    "plant_id": "Green-tek-A22646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "3:30:00 PM",
    "power": 0.101,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "7/25/2024 15:45",
    "plant_id": "Green-tek-A22647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "7/25/2024 16:00",
    "plant_id": "Green-tek-A22648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "7/25/2024 16:15",
    "plant_id": "Green-tek-A22649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/25/2024 16:30",
    "plant_id": "Green-tek-A22650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/25/2024 16:45",
    "plant_id": "Green-tek-A22651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/25/2024 17:00",
    "plant_id": "Green-tek-A22652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/25/2024 17:15",
    "plant_id": "Green-tek-A22653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/25/2024 17:30",
    "plant_id": "Green-tek-A22654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/25/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/26/2024 6:00",
    "plant_id": "Green-tek-A22655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/26/2024 6:15",
    "plant_id": "Green-tek-A22656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/26/2024 6:30",
    "plant_id": "Green-tek-A22657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/26/2024 6:45",
    "plant_id": "Green-tek-A22658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/26/2024 7:00",
    "plant_id": "Green-tek-A22659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/26/2024 7:15",
    "plant_id": "Green-tek-A22660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/26/2024 7:30",
    "plant_id": "Green-tek-A22661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/26/2024 7:45",
    "plant_id": "Green-tek-A22662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "7:45:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/26/2024 8:00",
    "plant_id": "Green-tek-A22663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "8:00:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/26/2024 8:15",
    "plant_id": "Green-tek-A22664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "8:15:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "7/26/2024 8:30",
    "plant_id": "Green-tek-A22665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "8:30:00 AM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "7/26/2024 8:45",
    "plant_id": "Green-tek-A22666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "8:45:00 AM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "7/26/2024 9:00",
    "plant_id": "Green-tek-A22667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "9:00:00 AM",
    "power": 0.239,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "7/26/2024 9:15",
    "plant_id": "Green-tek-A22668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "9:15:00 AM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "7/26/2024 9:30",
    "plant_id": "Green-tek-A22669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "9:30:00 AM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "7/26/2024 9:45",
    "plant_id": "Green-tek-A22670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "9:45:00 AM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "7/26/2024 10:00",
    "plant_id": "Green-tek-A22671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "10:00:00 AM",
    "power": 0.498,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "7/26/2024 10:15",
    "plant_id": "Green-tek-A22672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "10:15:00 AM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "7/26/2024 10:30",
    "plant_id": "Green-tek-A22673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "10:30:00 AM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/26/2024 10:45",
    "plant_id": "Green-tek-A22674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "10:45:00 AM",
    "power": 0.697,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "7/26/2024 11:00",
    "plant_id": "Green-tek-A22675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "11:00:00 AM",
    "power": 0.749,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "7/26/2024 11:15",
    "plant_id": "Green-tek-A22676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "11:15:00 AM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "7/26/2024 11:30",
    "plant_id": "Green-tek-A22677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "11:30:00 AM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "7/26/2024 11:45",
    "plant_id": "Green-tek-A22678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "11:45:00 AM",
    "power": 0.821,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "7/26/2024 12:00",
    "plant_id": "Green-tek-A22679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "12:00:00 PM",
    "power": 0.813,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "7/26/2024 12:15",
    "plant_id": "Green-tek-A22680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "12:15:00 PM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "7/26/2024 12:30",
    "plant_id": "Green-tek-A22681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "12:30:00 PM",
    "power": 0.749,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "7/26/2024 12:45",
    "plant_id": "Green-tek-A22682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "12:45:00 PM",
    "power": 0.697,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "7/26/2024 13:00",
    "plant_id": "Green-tek-A22683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "1:00:00 PM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "7/26/2024 13:15",
    "plant_id": "Green-tek-A22684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "1:15:00 PM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "7/26/2024 13:30",
    "plant_id": "Green-tek-A22685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "1:30:00 PM",
    "power": 0.498,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "7/26/2024 13:45",
    "plant_id": "Green-tek-A22686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "1:45:00 PM",
    "power": 0.427,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "7/26/2024 14:00",
    "plant_id": "Green-tek-A22687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "2:00:00 PM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "7/26/2024 14:15",
    "plant_id": "Green-tek-A22688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "2:15:00 PM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "7/26/2024 14:30",
    "plant_id": "Green-tek-A22689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "2:30:00 PM",
    "power": 0.239,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "7/26/2024 14:45",
    "plant_id": "Green-tek-A22690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "2:45:00 PM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "7/26/2024 15:00",
    "plant_id": "Green-tek-A22691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "3:00:00 PM",
    "power": 0.146,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "7/26/2024 15:15",
    "plant_id": "Green-tek-A22692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "3:15:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "7/26/2024 15:30",
    "plant_id": "Green-tek-A22693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "3:30:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/26/2024 15:45",
    "plant_id": "Green-tek-A22694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "3:45:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/26/2024 16:00",
    "plant_id": "Green-tek-A22695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/26/2024 16:15",
    "plant_id": "Green-tek-A22696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/26/2024 16:30",
    "plant_id": "Green-tek-A22697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/26/2024 16:45",
    "plant_id": "Green-tek-A22698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/26/2024 17:00",
    "plant_id": "Green-tek-A22699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/26/2024 17:15",
    "plant_id": "Green-tek-A22700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/26/2024 17:30",
    "plant_id": "Green-tek-A22701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/26/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/27/2024 6:00",
    "plant_id": "Green-tek-A22702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/27/2024 6:15",
    "plant_id": "Green-tek-A22703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/27/2024 6:30",
    "plant_id": "Green-tek-A22704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/27/2024 6:45",
    "plant_id": "Green-tek-A22705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/27/2024 7:00",
    "plant_id": "Green-tek-A22706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/27/2024 7:15",
    "plant_id": "Green-tek-A22707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/27/2024 7:30",
    "plant_id": "Green-tek-A22708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/27/2024 7:45",
    "plant_id": "Green-tek-A22709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/27/2024 8:00",
    "plant_id": "Green-tek-A22710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "8:00:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/27/2024 8:15",
    "plant_id": "Green-tek-A22711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "8:15:00 AM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/27/2024 8:30",
    "plant_id": "Green-tek-A22712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "8:30:00 AM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "7/27/2024 8:45",
    "plant_id": "Green-tek-A22713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "8:45:00 AM",
    "power": 0.262,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "7/27/2024 9:00",
    "plant_id": "Green-tek-A22714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "9:00:00 AM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "7/27/2024 9:15",
    "plant_id": "Green-tek-A22715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "9:15:00 AM",
    "power": 0.411,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "7/27/2024 9:30",
    "plant_id": "Green-tek-A22716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "9:30:00 AM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "7/27/2024 9:45",
    "plant_id": "Green-tek-A22717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "9:45:00 AM",
    "power": 0.593,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "7/27/2024 10:00",
    "plant_id": "Green-tek-A22718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "10:00:00 AM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "7/27/2024 10:15",
    "plant_id": "Green-tek-A22719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "10:15:00 AM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "7/27/2024 10:30",
    "plant_id": "Green-tek-A22720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "10:30:00 AM",
    "power": 0.883,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "7/27/2024 10:45",
    "plant_id": "Green-tek-A22721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "10:45:00 AM",
    "power": 0.968,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/27/2024 11:00",
    "plant_id": "Green-tek-A22722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "11:00:00 AM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "7/27/2024 11:15",
    "plant_id": "Green-tek-A22723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "11:15:00 AM",
    "power": 1.094,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "7/27/2024 11:30",
    "plant_id": "Green-tek-A22724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "11:30:00 AM",
    "power": 1.128,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "7/27/2024 11:45",
    "plant_id": "Green-tek-A22725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "11:45:00 AM",
    "power": 1.139,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "7/27/2024 12:00",
    "plant_id": "Green-tek-A22726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "12:00:00 PM",
    "power": 1.128,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "7/27/2024 12:15",
    "plant_id": "Green-tek-A22727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "12:15:00 PM",
    "power": 1.094,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "7/27/2024 12:30",
    "plant_id": "Green-tek-A22728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "12:30:00 PM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "7/27/2024 12:45",
    "plant_id": "Green-tek-A22729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "12:45:00 PM",
    "power": 0.968,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "7/27/2024 13:00",
    "plant_id": "Green-tek-A22730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "1:00:00 PM",
    "power": 0.883,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "7/27/2024 13:15",
    "plant_id": "Green-tek-A22731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "1:15:00 PM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "7/27/2024 13:30",
    "plant_id": "Green-tek-A22732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "1:30:00 PM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "7/27/2024 13:45",
    "plant_id": "Green-tek-A22733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "1:45:00 PM",
    "power": 0.593,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "7/27/2024 14:00",
    "plant_id": "Green-tek-A22734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "2:00:00 PM",
    "power": 0.498,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "7/27/2024 14:15",
    "plant_id": "Green-tek-A22735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "2:15:00 PM",
    "power": 0.411,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "7/27/2024 14:30",
    "plant_id": "Green-tek-A22736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "2:30:00 PM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "7/27/2024 14:45",
    "plant_id": "Green-tek-A22737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "2:45:00 PM",
    "power": 0.262,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "7/27/2024 15:00",
    "plant_id": "Green-tek-A22738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "3:00:00 PM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "7/27/2024 15:15",
    "plant_id": "Green-tek-A22739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "3:15:00 PM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "7/27/2024 15:30",
    "plant_id": "Green-tek-A22740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "3:30:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/27/2024 15:45",
    "plant_id": "Green-tek-A22741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/27/2024 16:00",
    "plant_id": "Green-tek-A22742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/27/2024 16:15",
    "plant_id": "Green-tek-A22743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/27/2024 16:30",
    "plant_id": "Green-tek-A22744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/27/2024 16:45",
    "plant_id": "Green-tek-A22745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/27/2024 17:00",
    "plant_id": "Green-tek-A22746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/27/2024 17:15",
    "plant_id": "Green-tek-A22747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/27/2024 17:30",
    "plant_id": "Green-tek-A22748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/27/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/28/2024 6:00",
    "plant_id": "Green-tek-A22749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/28/2024 6:15",
    "plant_id": "Green-tek-A22750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/28/2024 6:30",
    "plant_id": "Green-tek-A22751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/28/2024 6:45",
    "plant_id": "Green-tek-A22752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/28/2024 7:00",
    "plant_id": "Green-tek-A22753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/28/2024 7:15",
    "plant_id": "Green-tek-A22754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/28/2024 7:30",
    "plant_id": "Green-tek-A22755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/28/2024 7:45",
    "plant_id": "Green-tek-A22756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "7:45:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/28/2024 8:00",
    "plant_id": "Green-tek-A22757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "8:00:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "7/28/2024 8:15",
    "plant_id": "Green-tek-A22758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "8:15:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "7/28/2024 8:30",
    "plant_id": "Green-tek-A22759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "8:30:00 AM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "7/28/2024 8:45",
    "plant_id": "Green-tek-A22760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "8:45:00 AM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "7/28/2024 9:00",
    "plant_id": "Green-tek-A22761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "9:00:00 AM",
    "power": 0.274,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "7/28/2024 9:15",
    "plant_id": "Green-tek-A22762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "9:15:00 AM",
    "power": 0.339,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "7/28/2024 9:30",
    "plant_id": "Green-tek-A22763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "9:30:00 AM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "7/28/2024 9:45",
    "plant_id": "Green-tek-A22764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "9:45:00 AM",
    "power": 0.49,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "7/28/2024 10:00",
    "plant_id": "Green-tek-A22765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "10:00:00 AM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "7/28/2024 10:15",
    "plant_id": "Green-tek-A22766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "10:15:00 AM",
    "power": 0.652,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "7/28/2024 10:30",
    "plant_id": "Green-tek-A22767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "10:30:00 AM",
    "power": 0.729,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "7/28/2024 10:45",
    "plant_id": "Green-tek-A22768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "10:45:00 AM",
    "power": 0.799,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "7/28/2024 11:00",
    "plant_id": "Green-tek-A22769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "11:00:00 AM",
    "power": 0.859,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "7/28/2024 11:15",
    "plant_id": "Green-tek-A22770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "11:15:00 AM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "7/28/2024 11:30",
    "plant_id": "Green-tek-A22771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "11:30:00 AM",
    "power": 0.932,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "7/28/2024 11:45",
    "plant_id": "Green-tek-A22772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "11:45:00 AM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "7/28/2024 12:00",
    "plant_id": "Green-tek-A22773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "12:00:00 PM",
    "power": 0.932,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "7/28/2024 12:15",
    "plant_id": "Green-tek-A22774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "12:15:00 PM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "7/28/2024 12:30",
    "plant_id": "Green-tek-A22775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "12:30:00 PM",
    "power": 0.859,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "7/28/2024 12:45",
    "plant_id": "Green-tek-A22776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "12:45:00 PM",
    "power": 0.799,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "7/28/2024 13:00",
    "plant_id": "Green-tek-A22777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "1:00:00 PM",
    "power": 0.729,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "7/28/2024 13:15",
    "plant_id": "Green-tek-A22778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "1:15:00 PM",
    "power": 0.652,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "7/28/2024 13:30",
    "plant_id": "Green-tek-A22779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "1:30:00 PM",
    "power": 0.571,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "7/28/2024 13:45",
    "plant_id": "Green-tek-A22780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "1:45:00 PM",
    "power": 0.49,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "7/28/2024 14:00",
    "plant_id": "Green-tek-A22781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "2:00:00 PM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "7/28/2024 14:15",
    "plant_id": "Green-tek-A22782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "2:15:00 PM",
    "power": 0.339,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "7/28/2024 14:30",
    "plant_id": "Green-tek-A22783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "2:30:00 PM",
    "power": 0.274,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "7/28/2024 14:45",
    "plant_id": "Green-tek-A22784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "2:45:00 PM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "7/28/2024 15:00",
    "plant_id": "Green-tek-A22785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "3:00:00 PM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "7/28/2024 15:15",
    "plant_id": "Green-tek-A22786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "3:15:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "7/28/2024 15:30",
    "plant_id": "Green-tek-A22787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "3:30:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "7/28/2024 15:45",
    "plant_id": "Green-tek-A22788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "3:45:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "7/28/2024 16:00",
    "plant_id": "Green-tek-A22789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "7/28/2024 16:15",
    "plant_id": "Green-tek-A22790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "7/28/2024 16:30",
    "plant_id": "Green-tek-A22791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "7/28/2024 16:45",
    "plant_id": "Green-tek-A22792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "7/28/2024 17:00",
    "plant_id": "Green-tek-A22793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/28/2024 17:15",
    "plant_id": "Green-tek-A22794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/28/2024 17:30",
    "plant_id": "Green-tek-A22795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/28/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/29/2024 6:00",
    "plant_id": "Green-tek-A22796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/29/2024 6:15",
    "plant_id": "Green-tek-A22797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/29/2024 6:30",
    "plant_id": "Green-tek-A22798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/29/2024 6:45",
    "plant_id": "Green-tek-A22799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/29/2024 7:00",
    "plant_id": "Green-tek-A22800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/29/2024 7:15",
    "plant_id": "Green-tek-A22801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "7:15:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/29/2024 7:30",
    "plant_id": "Green-tek-A22802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "7:30:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/29/2024 7:45",
    "plant_id": "Green-tek-A22803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "7:45:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/29/2024 8:00",
    "plant_id": "Green-tek-A22804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "8:00:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "7/29/2024 8:15",
    "plant_id": "Green-tek-A22805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "8:15:00 AM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "7/29/2024 8:30",
    "plant_id": "Green-tek-A22806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "8:30:00 AM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "7/29/2024 8:45",
    "plant_id": "Green-tek-A22807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "8:45:00 AM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "7/29/2024 9:00",
    "plant_id": "Green-tek-A22808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "9:00:00 AM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/29/2024 9:15",
    "plant_id": "Green-tek-A22809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "9:15:00 AM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "7/29/2024 9:30",
    "plant_id": "Green-tek-A22810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "9:30:00 AM",
    "power": 0.35,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "7/29/2024 9:45",
    "plant_id": "Green-tek-A22811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "9:45:00 AM",
    "power": 0.416,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "7/29/2024 10:00",
    "plant_id": "Green-tek-A22812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "10:00:00 AM",
    "power": 0.485,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "7/29/2024 10:15",
    "plant_id": "Green-tek-A22813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "10:15:00 AM",
    "power": 0.554,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/29/2024 10:30",
    "plant_id": "Green-tek-A22814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "10:30:00 AM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "7/29/2024 10:45",
    "plant_id": "Green-tek-A22815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "10:45:00 AM",
    "power": 0.68,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "7/29/2024 11:00",
    "plant_id": "Green-tek-A22816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "11:00:00 AM",
    "power": 0.73,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "7/29/2024 11:15",
    "plant_id": "Green-tek-A22817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "11:15:00 AM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "7/29/2024 11:30",
    "plant_id": "Green-tek-A22818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "11:30:00 AM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "7/29/2024 11:45",
    "plant_id": "Green-tek-A22819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "11:45:00 AM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "7/29/2024 12:00",
    "plant_id": "Green-tek-A22820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "12:00:00 PM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "7/29/2024 12:15",
    "plant_id": "Green-tek-A22821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "12:15:00 PM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "7/29/2024 12:30",
    "plant_id": "Green-tek-A22822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "12:30:00 PM",
    "power": 0.73,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "7/29/2024 12:45",
    "plant_id": "Green-tek-A22823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "12:45:00 PM",
    "power": 0.68,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "7/29/2024 13:00",
    "plant_id": "Green-tek-A22824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "1:00:00 PM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "7/29/2024 13:15",
    "plant_id": "Green-tek-A22825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "1:15:00 PM",
    "power": 0.554,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "7/29/2024 13:30",
    "plant_id": "Green-tek-A22826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "1:30:00 PM",
    "power": 0.485,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "7/29/2024 13:45",
    "plant_id": "Green-tek-A22827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "1:45:00 PM",
    "power": 0.416,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "7/29/2024 14:00",
    "plant_id": "Green-tek-A22828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "2:00:00 PM",
    "power": 0.35,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "7/29/2024 14:15",
    "plant_id": "Green-tek-A22829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "2:15:00 PM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "7/29/2024 14:30",
    "plant_id": "Green-tek-A22830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "2:30:00 PM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "7/29/2024 14:45",
    "plant_id": "Green-tek-A22831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "2:45:00 PM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "7/29/2024 15:00",
    "plant_id": "Green-tek-A22832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "3:00:00 PM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "7/29/2024 15:15",
    "plant_id": "Green-tek-A22833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "3:15:00 PM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "7/29/2024 15:30",
    "plant_id": "Green-tek-A22834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "3:30:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "7/29/2024 15:45",
    "plant_id": "Green-tek-A22835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "3:45:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/29/2024 16:00",
    "plant_id": "Green-tek-A22836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "4:00:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "7/29/2024 16:15",
    "plant_id": "Green-tek-A22837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "4:15:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/29/2024 16:30",
    "plant_id": "Green-tek-A22838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/29/2024 16:45",
    "plant_id": "Green-tek-A22839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/29/2024 17:00",
    "plant_id": "Green-tek-A22840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/29/2024 17:15",
    "plant_id": "Green-tek-A22841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/29/2024 17:30",
    "plant_id": "Green-tek-A22842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/29/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/30/2024 6:00",
    "plant_id": "Green-tek-A22843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/30/2024 6:15",
    "plant_id": "Green-tek-A22844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/30/2024 6:30",
    "plant_id": "Green-tek-A22845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/30/2024 6:45",
    "plant_id": "Green-tek-A22846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/30/2024 7:00",
    "plant_id": "Green-tek-A22847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/30/2024 7:15",
    "plant_id": "Green-tek-A22848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/30/2024 7:30",
    "plant_id": "Green-tek-A22849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/30/2024 7:45",
    "plant_id": "Green-tek-A22850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "7:45:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/30/2024 8:00",
    "plant_id": "Green-tek-A22851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "8:00:00 AM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "7/30/2024 8:15",
    "plant_id": "Green-tek-A22852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "8:15:00 AM",
    "power": 0.17,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "7/30/2024 8:30",
    "plant_id": "Green-tek-A22853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "8:30:00 AM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "7/30/2024 8:45",
    "plant_id": "Green-tek-A22854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "8:45:00 AM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "7/30/2024 9:00",
    "plant_id": "Green-tek-A22855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "9:00:00 AM",
    "power": 0.364,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "7/30/2024 9:15",
    "plant_id": "Green-tek-A22856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "9:15:00 AM",
    "power": 0.452,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "7/30/2024 9:30",
    "plant_id": "Green-tek-A22857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "9:30:00 AM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "7/30/2024 9:45",
    "plant_id": "Green-tek-A22858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "9:45:00 AM",
    "power": 0.652,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "7/30/2024 10:00",
    "plant_id": "Green-tek-A22859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "10:00:00 AM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "7/30/2024 10:15",
    "plant_id": "Green-tek-A22860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "10:15:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "7/30/2024 10:30",
    "plant_id": "Green-tek-A22861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "10:30:00 AM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "7/30/2024 10:45",
    "plant_id": "Green-tek-A22862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "10:45:00 AM",
    "power": 1.064,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "7/30/2024 11:00",
    "plant_id": "Green-tek-A22863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "11:00:00 AM",
    "power": 1.143,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "7/30/2024 11:15",
    "plant_id": "Green-tek-A22864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "11:15:00 AM",
    "power": 1.203,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "7/30/2024 11:30",
    "plant_id": "Green-tek-A22865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "11:30:00 AM",
    "power": 1.24,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "7/30/2024 11:45",
    "plant_id": "Green-tek-A22866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "11:45:00 AM",
    "power": 1.253,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "7/30/2024 12:00",
    "plant_id": "Green-tek-A22867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "12:00:00 PM",
    "power": 1.24,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "7/30/2024 12:15",
    "plant_id": "Green-tek-A22868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "12:15:00 PM",
    "power": 1.203,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "7/30/2024 12:30",
    "plant_id": "Green-tek-A22869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "12:30:00 PM",
    "power": 1.143,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "7/30/2024 12:45",
    "plant_id": "Green-tek-A22870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "12:45:00 PM",
    "power": 1.064,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "7/30/2024 13:00",
    "plant_id": "Green-tek-A22871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "1:00:00 PM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "7/30/2024 13:15",
    "plant_id": "Green-tek-A22872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "1:15:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "7/30/2024 13:30",
    "plant_id": "Green-tek-A22873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "1:30:00 PM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "7/30/2024 13:45",
    "plant_id": "Green-tek-A22874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "1:45:00 PM",
    "power": 0.652,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "7/30/2024 14:00",
    "plant_id": "Green-tek-A22875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "2:00:00 PM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "7/30/2024 14:15",
    "plant_id": "Green-tek-A22876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "2:15:00 PM",
    "power": 0.452,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "7/30/2024 14:30",
    "plant_id": "Green-tek-A22877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "2:30:00 PM",
    "power": 0.364,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "7/30/2024 14:45",
    "plant_id": "Green-tek-A22878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "2:45:00 PM",
    "power": 0.288,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "7/30/2024 15:00",
    "plant_id": "Green-tek-A22879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "3:00:00 PM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "7/30/2024 15:15",
    "plant_id": "Green-tek-A22880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "3:15:00 PM",
    "power": 0.17,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "7/30/2024 15:30",
    "plant_id": "Green-tek-A22881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "3:30:00 PM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "7/30/2024 15:45",
    "plant_id": "Green-tek-A22882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "3:45:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "7/30/2024 16:00",
    "plant_id": "Green-tek-A22883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "7/30/2024 16:15",
    "plant_id": "Green-tek-A22884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/30/2024 16:30",
    "plant_id": "Green-tek-A22885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "7/30/2024 16:45",
    "plant_id": "Green-tek-A22886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/30/2024 17:00",
    "plant_id": "Green-tek-A22887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/30/2024 17:15",
    "plant_id": "Green-tek-A22888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/30/2024 17:30",
    "plant_id": "Green-tek-A22889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/30/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/31/2024 6:00",
    "plant_id": "Green-tek-A22890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "7/31/2024 6:15",
    "plant_id": "Green-tek-A22891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/31/2024 6:30",
    "plant_id": "Green-tek-A22892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/31/2024 6:45",
    "plant_id": "Green-tek-A22893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/31/2024 7:00",
    "plant_id": "Green-tek-A22894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/31/2024 7:15",
    "plant_id": "Green-tek-A22895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/31/2024 7:30",
    "plant_id": "Green-tek-A22896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/31/2024 7:45",
    "plant_id": "Green-tek-A22897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "7:45:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/31/2024 8:00",
    "plant_id": "Green-tek-A22898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "8:00:00 AM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/31/2024 8:15",
    "plant_id": "Green-tek-A22899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "8:15:00 AM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "7/31/2024 8:30",
    "plant_id": "Green-tek-A22900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "8:30:00 AM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "7/31/2024 8:45",
    "plant_id": "Green-tek-A22901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "8:45:00 AM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "7/31/2024 9:00",
    "plant_id": "Green-tek-A22902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "9:00:00 AM",
    "power": 0.34,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "7/31/2024 9:15",
    "plant_id": "Green-tek-A22903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "9:15:00 AM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "7/31/2024 9:30",
    "plant_id": "Green-tek-A22904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "9:30:00 AM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "7/31/2024 9:45",
    "plant_id": "Green-tek-A22905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "9:45:00 AM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "7/31/2024 10:00",
    "plant_id": "Green-tek-A22906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "10:00:00 AM",
    "power": 0.709,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "7/31/2024 10:15",
    "plant_id": "Green-tek-A22907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "10:15:00 AM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "7/31/2024 10:30",
    "plant_id": "Green-tek-A22908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "10:30:00 AM",
    "power": 0.906,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "7/31/2024 10:45",
    "plant_id": "Green-tek-A22909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "10:45:00 AM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "7/31/2024 11:00",
    "plant_id": "Green-tek-A22910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "11:00:00 AM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "7/31/2024 11:15",
    "plant_id": "Green-tek-A22911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "11:15:00 AM",
    "power": 1.123,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.29478,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "7/31/2024 11:30",
    "plant_id": "Green-tek-A22912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "11:30:00 AM",
    "power": 1.158,
    "Energy": 0.289,
    "output Energy": 0.289,
    "Ac output": 1.289,
    "dc output": 1.31478,
    "AC_R_i": 0.00037696,
    "AC_S_i": 0.000414656,
    "AC_T_i": 0.00045612,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0867008,
    "AC_S_pow": 0.09537088,
    "AC_T_Pow": 0.1049076,
    "AC_R_output": 0.3867,
    "AC_S_output": 0.42537,
    "AC_T_output": 0.467907,
    "dc_current": 0.002125,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.29478,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000289,
    "carbon offset": 0.36125,
    "trees planted": 8.027777778,
    "DC_R_output": 0.127611,
    "DC_S_output": 0.1403721,
    "DC_T_output": 0.15440931
  },
  {
    "datetime": "7/31/2024 11:45",
    "plant_id": "Green-tek-A22913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "11:45:00 AM",
    "power": 1.17,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.29478,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "7/31/2024 12:00",
    "plant_id": "Green-tek-A22914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "12:00:00 PM",
    "power": 1.158,
    "Energy": 0.289,
    "output Energy": 0.289,
    "Ac output": 1.289,
    "dc output": 1.31478,
    "AC_R_i": 0.00037696,
    "AC_S_i": 0.000414656,
    "AC_T_i": 0.00045612,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0867008,
    "AC_S_pow": 0.09537088,
    "AC_T_Pow": 0.1049076,
    "AC_R_output": 0.3867,
    "AC_S_output": 0.42537,
    "AC_T_output": 0.467907,
    "dc_current": 0.002125,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.29478,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000289,
    "carbon offset": 0.36125,
    "trees planted": 8.027777778,
    "DC_R_output": 0.127611,
    "DC_S_output": 0.1403721,
    "DC_T_output": 0.15440931
  },
  {
    "datetime": "7/31/2024 12:15",
    "plant_id": "Green-tek-A22915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "12:15:00 PM",
    "power": 1.123,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "7/31/2024 12:30",
    "plant_id": "Green-tek-A22916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "12:30:00 PM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "7/31/2024 12:45",
    "plant_id": "Green-tek-A22917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "12:45:00 PM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "7/31/2024 13:00",
    "plant_id": "Green-tek-A22918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "1:00:00 PM",
    "power": 0.906,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "7/31/2024 13:15",
    "plant_id": "Green-tek-A22919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "1:15:00 PM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "7/31/2024 13:30",
    "plant_id": "Green-tek-A22920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "1:30:00 PM",
    "power": 0.709,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "7/31/2024 13:45",
    "plant_id": "Green-tek-A22921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "1:45:00 PM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "7/31/2024 14:00",
    "plant_id": "Green-tek-A22922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "2:00:00 PM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "7/31/2024 14:15",
    "plant_id": "Green-tek-A22923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "2:15:00 PM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "7/31/2024 14:30",
    "plant_id": "Green-tek-A22924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "2:30:00 PM",
    "power": 0.34,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "7/31/2024 14:45",
    "plant_id": "Green-tek-A22925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "2:45:00 PM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "7/31/2024 15:00",
    "plant_id": "Green-tek-A22926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "3:00:00 PM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "7/31/2024 15:15",
    "plant_id": "Green-tek-A22927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "3:15:00 PM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "7/31/2024 15:30",
    "plant_id": "Green-tek-A22928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "3:30:00 PM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "7/31/2024 15:45",
    "plant_id": "Green-tek-A22929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "3:45:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "7/31/2024 16:00",
    "plant_id": "Green-tek-A22930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "7/31/2024 16:15",
    "plant_id": "Green-tek-A22931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "7/31/2024 16:30",
    "plant_id": "Green-tek-A22932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "7/31/2024 16:45",
    "plant_id": "Green-tek-A22933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "7/31/2024 17:00",
    "plant_id": "Green-tek-A22934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "7/31/2024 17:15",
    "plant_id": "Green-tek-A22935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "7/31/2024 17:30",
    "plant_id": "Green-tek-A22936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "7/31/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/1/2024 6:00",
    "plant_id": "Green-tek-A22937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/1/2024 6:15",
    "plant_id": "Green-tek-A22938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/1/2024 6:30",
    "plant_id": "Green-tek-A22939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/1/2024 6:45",
    "plant_id": "Green-tek-A22940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/1/2024 7:00",
    "plant_id": "Green-tek-A22941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/1/2024 7:15",
    "plant_id": "Green-tek-A22942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/1/2024 7:30",
    "plant_id": "Green-tek-A22943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/1/2024 7:45",
    "plant_id": "Green-tek-A22944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "7:45:00 AM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/1/2024 8:00",
    "plant_id": "Green-tek-A22945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "8:00:00 AM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/1/2024 8:15",
    "plant_id": "Green-tek-A22946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "8:15:00 AM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/1/2024 8:30",
    "plant_id": "Green-tek-A22947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "8:30:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/1/2024 8:45",
    "plant_id": "Green-tek-A22948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "8:45:00 AM",
    "power": 0.294,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "8/1/2024 9:00",
    "plant_id": "Green-tek-A22949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "9:00:00 AM",
    "power": 0.371,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "8/1/2024 9:15",
    "plant_id": "Green-tek-A22950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "9:15:00 AM",
    "power": 0.46,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "8/1/2024 9:30",
    "plant_id": "Green-tek-A22951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "9:30:00 AM",
    "power": 0.558,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "8/1/2024 9:45",
    "plant_id": "Green-tek-A22952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "9:45:00 AM",
    "power": 0.664,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "8/1/2024 10:00",
    "plant_id": "Green-tek-A22953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "10:00:00 AM",
    "power": 0.774,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "8/1/2024 10:15",
    "plant_id": "Green-tek-A22954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "10:15:00 AM",
    "power": 0.884,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "8/1/2024 10:30",
    "plant_id": "Green-tek-A22955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "10:30:00 AM",
    "power": 0.989,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "8/1/2024 10:45",
    "plant_id": "Green-tek-A22956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "10:45:00 AM",
    "power": 1.084,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "8/1/2024 11:00",
    "plant_id": "Green-tek-A22957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "11:00:00 AM",
    "power": 1.164,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "8/1/2024 11:15",
    "plant_id": "Green-tek-A22958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "11:15:00 AM",
    "power": 1.225,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "8/1/2024 11:30",
    "plant_id": "Green-tek-A22959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "11:30:00 AM",
    "power": 1.263,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.32538,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "8/1/2024 11:45",
    "plant_id": "Green-tek-A22960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "11:45:00 AM",
    "power": 1.276,
    "Energy": 0.319,
    "output Energy": 0.319,
    "Ac output": 1.319,
    "dc output": 1.34538,
    "AC_R_i": 0.00041609,
    "AC_S_i": 0.000457699,
    "AC_T_i": 0.00050347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0957007,
    "AC_S_pow": 0.10527077,
    "AC_T_Pow": 0.1157981,
    "AC_R_output": 0.3957,
    "AC_S_output": 0.43527,
    "AC_T_output": 0.478797,
    "dc_current": 0.00234559,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.32538,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000319,
    "carbon offset": 0.39875,
    "trees planted": 8.861111111,
    "DC_R_output": 0.130581,
    "DC_S_output": 0.1436391,
    "DC_T_output": 0.15800301
  },
  {
    "datetime": "8/1/2024 12:00",
    "plant_id": "Green-tek-A22961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "12:00:00 PM",
    "power": 1.263,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "8/1/2024 12:15",
    "plant_id": "Green-tek-A22962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "12:15:00 PM",
    "power": 1.225,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "8/1/2024 12:30",
    "plant_id": "Green-tek-A22963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "12:30:00 PM",
    "power": 1.164,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "8/1/2024 12:45",
    "plant_id": "Green-tek-A22964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "12:45:00 PM",
    "power": 1.084,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "8/1/2024 13:00",
    "plant_id": "Green-tek-A22965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "1:00:00 PM",
    "power": 0.989,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "8/1/2024 13:15",
    "plant_id": "Green-tek-A22966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "1:15:00 PM",
    "power": 0.884,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "8/1/2024 13:30",
    "plant_id": "Green-tek-A22967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "1:30:00 PM",
    "power": 0.774,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "8/1/2024 13:45",
    "plant_id": "Green-tek-A22968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "1:45:00 PM",
    "power": 0.664,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "8/1/2024 14:00",
    "plant_id": "Green-tek-A22969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "2:00:00 PM",
    "power": 0.558,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "8/1/2024 14:15",
    "plant_id": "Green-tek-A22970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "2:15:00 PM",
    "power": 0.46,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "8/1/2024 14:30",
    "plant_id": "Green-tek-A22971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "2:30:00 PM",
    "power": 0.371,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "8/1/2024 14:45",
    "plant_id": "Green-tek-A22972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "2:45:00 PM",
    "power": 0.294,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "8/1/2024 15:00",
    "plant_id": "Green-tek-A22973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "3:00:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/1/2024 15:15",
    "plant_id": "Green-tek-A22974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "3:15:00 PM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/1/2024 15:30",
    "plant_id": "Green-tek-A22975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "3:30:00 PM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/1/2024 15:45",
    "plant_id": "Green-tek-A22976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "3:45:00 PM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/1/2024 16:00",
    "plant_id": "Green-tek-A22977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/1/2024 16:15",
    "plant_id": "Green-tek-A22978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/1/2024 16:30",
    "plant_id": "Green-tek-A22979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/1/2024 16:45",
    "plant_id": "Green-tek-A22980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/1/2024 17:00",
    "plant_id": "Green-tek-A22981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/1/2024 17:15",
    "plant_id": "Green-tek-A22982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/1/2024 17:30",
    "plant_id": "Green-tek-A22983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/1/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/2/2024 6:00",
    "plant_id": "Green-tek-A22984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/2/2024 6:15",
    "plant_id": "Green-tek-A22985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/2/2024 6:30",
    "plant_id": "Green-tek-A22986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/2/2024 6:45",
    "plant_id": "Green-tek-A22987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/2/2024 7:00",
    "plant_id": "Green-tek-A22988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/2/2024 7:15",
    "plant_id": "Green-tek-A22989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/2/2024 7:30",
    "plant_id": "Green-tek-A22990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "7:30:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/2/2024 7:45",
    "plant_id": "Green-tek-A22991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "7:45:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/2/2024 8:00",
    "plant_id": "Green-tek-A22992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "8:00:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "8/2/2024 8:15",
    "plant_id": "Green-tek-A22993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "8:15:00 AM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "8/2/2024 8:30",
    "plant_id": "Green-tek-A22994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "8:30:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "8/2/2024 8:45",
    "plant_id": "Green-tek-A22995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "8:45:00 AM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/2/2024 9:00",
    "plant_id": "Green-tek-A22996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "9:00:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "8/2/2024 9:15",
    "plant_id": "Green-tek-A22997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "9:15:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "8/2/2024 9:30",
    "plant_id": "Green-tek-A22998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "9:30:00 AM",
    "power": 0.354,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "8/2/2024 9:45",
    "plant_id": "Green-tek-A22999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "9:45:00 AM",
    "power": 0.421,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "8/2/2024 10:00",
    "plant_id": "Green-tek-A23000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "10:00:00 AM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "8/2/2024 10:15",
    "plant_id": "Green-tek-A23001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "10:15:00 AM",
    "power": 0.56,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "8/2/2024 10:30",
    "plant_id": "Green-tek-A23002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "10:30:00 AM",
    "power": 0.627,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "8/2/2024 10:45",
    "plant_id": "Green-tek-A23003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "10:45:00 AM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "8/2/2024 11:00",
    "plant_id": "Green-tek-A23004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "11:00:00 AM",
    "power": 0.738,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "8/2/2024 11:15",
    "plant_id": "Green-tek-A23005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "11:15:00 AM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "8/2/2024 11:30",
    "plant_id": "Green-tek-A23006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "11:30:00 AM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "8/2/2024 11:45",
    "plant_id": "Green-tek-A23007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "11:45:00 AM",
    "power": 0.809,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "8/2/2024 12:00",
    "plant_id": "Green-tek-A23008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "12:00:00 PM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "8/2/2024 12:15",
    "plant_id": "Green-tek-A23009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "12:15:00 PM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "8/2/2024 12:30",
    "plant_id": "Green-tek-A23010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "12:30:00 PM",
    "power": 0.738,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "8/2/2024 12:45",
    "plant_id": "Green-tek-A23011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "12:45:00 PM",
    "power": 0.687,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "8/2/2024 13:00",
    "plant_id": "Green-tek-A23012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "1:00:00 PM",
    "power": 0.627,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "8/2/2024 13:15",
    "plant_id": "Green-tek-A23013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "1:15:00 PM",
    "power": 0.56,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "8/2/2024 13:30",
    "plant_id": "Green-tek-A23014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "1:30:00 PM",
    "power": 0.491,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "8/2/2024 13:45",
    "plant_id": "Green-tek-A23015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "1:45:00 PM",
    "power": 0.421,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "8/2/2024 14:00",
    "plant_id": "Green-tek-A23016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "2:00:00 PM",
    "power": 0.354,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "8/2/2024 14:15",
    "plant_id": "Green-tek-A23017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "2:15:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "8/2/2024 14:30",
    "plant_id": "Green-tek-A23018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "2:30:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "8/2/2024 14:45",
    "plant_id": "Green-tek-A23019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "2:45:00 PM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/2/2024 15:00",
    "plant_id": "Green-tek-A23020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "3:00:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "8/2/2024 15:15",
    "plant_id": "Green-tek-A23021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "3:15:00 PM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "8/2/2024 15:30",
    "plant_id": "Green-tek-A23022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "3:30:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "8/2/2024 15:45",
    "plant_id": "Green-tek-A23023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "3:45:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/2/2024 16:00",
    "plant_id": "Green-tek-A23024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "4:00:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/2/2024 16:15",
    "plant_id": "Green-tek-A23025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/2/2024 16:30",
    "plant_id": "Green-tek-A23026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/2/2024 16:45",
    "plant_id": "Green-tek-A23027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/2/2024 17:00",
    "plant_id": "Green-tek-A23028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/2/2024 17:15",
    "plant_id": "Green-tek-A23029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/2/2024 17:30",
    "plant_id": "Green-tek-A23030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/2/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/3/2024 6:00",
    "plant_id": "Green-tek-A23031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/3/2024 6:15",
    "plant_id": "Green-tek-A23032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/3/2024 6:30",
    "plant_id": "Green-tek-A23033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/3/2024 6:45",
    "plant_id": "Green-tek-A23034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/3/2024 7:00",
    "plant_id": "Green-tek-A23035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/3/2024 7:15",
    "plant_id": "Green-tek-A23036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/3/2024 7:30",
    "plant_id": "Green-tek-A23037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "7:30:00 AM",
    "power": 0.054,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/3/2024 7:45",
    "plant_id": "Green-tek-A23038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "7:45:00 AM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/3/2024 8:00",
    "plant_id": "Green-tek-A23039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "8:00:00 AM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/3/2024 8:15",
    "plant_id": "Green-tek-A23040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "8:15:00 AM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/3/2024 8:30",
    "plant_id": "Green-tek-A23041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "8:30:00 AM",
    "power": 0.182,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "8/3/2024 8:45",
    "plant_id": "Green-tek-A23042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "8:45:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "8/3/2024 9:00",
    "plant_id": "Green-tek-A23043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "9:00:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "8/3/2024 9:15",
    "plant_id": "Green-tek-A23044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "9:15:00 AM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "8/3/2024 9:30",
    "plant_id": "Green-tek-A23045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "9:30:00 AM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "8/3/2024 9:45",
    "plant_id": "Green-tek-A23046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "9:45:00 AM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "8/3/2024 10:00",
    "plant_id": "Green-tek-A23047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "10:00:00 AM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "8/3/2024 10:15",
    "plant_id": "Green-tek-A23048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "10:15:00 AM",
    "power": 0.708,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "8/3/2024 10:30",
    "plant_id": "Green-tek-A23049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "10:30:00 AM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "8/3/2024 10:45",
    "plant_id": "Green-tek-A23050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "10:45:00 AM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "8/3/2024 11:00",
    "plant_id": "Green-tek-A23051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "11:00:00 AM",
    "power": 0.932,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "8/3/2024 11:15",
    "plant_id": "Green-tek-A23052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "11:15:00 AM",
    "power": 0.981,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "8/3/2024 11:30",
    "plant_id": "Green-tek-A23053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "11:30:00 AM",
    "power": 1.012,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "8/3/2024 11:45",
    "plant_id": "Green-tek-A23054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "11:45:00 AM",
    "power": 1.022,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "8/3/2024 12:00",
    "plant_id": "Green-tek-A23055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "12:00:00 PM",
    "power": 1.012,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "8/3/2024 12:15",
    "plant_id": "Green-tek-A23056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "12:15:00 PM",
    "power": 0.981,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "8/3/2024 12:30",
    "plant_id": "Green-tek-A23057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "12:30:00 PM",
    "power": 0.932,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "8/3/2024 12:45",
    "plant_id": "Green-tek-A23058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "12:45:00 PM",
    "power": 0.868,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "8/3/2024 13:00",
    "plant_id": "Green-tek-A23059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "1:00:00 PM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "8/3/2024 13:15",
    "plant_id": "Green-tek-A23060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "1:15:00 PM",
    "power": 0.708,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "8/3/2024 13:30",
    "plant_id": "Green-tek-A23061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "1:30:00 PM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "8/3/2024 13:45",
    "plant_id": "Green-tek-A23062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "1:45:00 PM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "8/3/2024 14:00",
    "plant_id": "Green-tek-A23063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "2:00:00 PM",
    "power": 0.447,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "8/3/2024 14:15",
    "plant_id": "Green-tek-A23064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "2:15:00 PM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "8/3/2024 14:30",
    "plant_id": "Green-tek-A23065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "2:30:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "8/3/2024 14:45",
    "plant_id": "Green-tek-A23066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "2:45:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "8/3/2024 15:00",
    "plant_id": "Green-tek-A23067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "3:00:00 PM",
    "power": 0.182,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "8/3/2024 15:15",
    "plant_id": "Green-tek-A23068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "3:15:00 PM",
    "power": 0.138,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/3/2024 15:30",
    "plant_id": "Green-tek-A23069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "3:30:00 PM",
    "power": 0.103,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/3/2024 15:45",
    "plant_id": "Green-tek-A23070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "3:45:00 PM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/3/2024 16:00",
    "plant_id": "Green-tek-A23071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "4:00:00 PM",
    "power": 0.054,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/3/2024 16:15",
    "plant_id": "Green-tek-A23072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/3/2024 16:30",
    "plant_id": "Green-tek-A23073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/3/2024 16:45",
    "plant_id": "Green-tek-A23074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/3/2024 17:00",
    "plant_id": "Green-tek-A23075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/3/2024 17:15",
    "plant_id": "Green-tek-A23076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/3/2024 17:30",
    "plant_id": "Green-tek-A23077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/3/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/4/2024 6:00",
    "plant_id": "Green-tek-A23078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/4/2024 6:15",
    "plant_id": "Green-tek-A23079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/4/2024 6:30",
    "plant_id": "Green-tek-A23080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/4/2024 6:45",
    "plant_id": "Green-tek-A23081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/4/2024 7:00",
    "plant_id": "Green-tek-A23082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/4/2024 7:15",
    "plant_id": "Green-tek-A23083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/4/2024 7:30",
    "plant_id": "Green-tek-A23084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/4/2024 7:45",
    "plant_id": "Green-tek-A23085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "7:45:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/4/2024 8:00",
    "plant_id": "Green-tek-A23086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "8:00:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "8/4/2024 8:15",
    "plant_id": "Green-tek-A23087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "8:15:00 AM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "8/4/2024 8:30",
    "plant_id": "Green-tek-A23088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "8:30:00 AM",
    "power": 0.222,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "8/4/2024 8:45",
    "plant_id": "Green-tek-A23089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "8:45:00 AM",
    "power": 0.286,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "8/4/2024 9:00",
    "plant_id": "Green-tek-A23090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "9:00:00 AM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "8/4/2024 9:15",
    "plant_id": "Green-tek-A23091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "9:15:00 AM",
    "power": 0.448,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "8/4/2024 9:30",
    "plant_id": "Green-tek-A23092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "9:30:00 AM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "8/4/2024 9:45",
    "plant_id": "Green-tek-A23093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "9:45:00 AM",
    "power": 0.648,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "8/4/2024 10:00",
    "plant_id": "Green-tek-A23094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "10:00:00 AM",
    "power": 0.755,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "8/4/2024 10:15",
    "plant_id": "Green-tek-A23095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "10:15:00 AM",
    "power": 0.862,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "8/4/2024 10:30",
    "plant_id": "Green-tek-A23096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "10:30:00 AM",
    "power": 0.964,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "8/4/2024 10:45",
    "plant_id": "Green-tek-A23097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "10:45:00 AM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "8/4/2024 11:00",
    "plant_id": "Green-tek-A23098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "11:00:00 AM",
    "power": 1.135,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "8/4/2024 11:15",
    "plant_id": "Green-tek-A23099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "11:15:00 AM",
    "power": 1.195,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "8/4/2024 11:30",
    "plant_id": "Green-tek-A23100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "11:30:00 AM",
    "power": 1.232,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "8/4/2024 11:45",
    "plant_id": "Green-tek-A23101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "11:45:00 AM",
    "power": 1.244,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "8/4/2024 12:00",
    "plant_id": "Green-tek-A23102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "12:00:00 PM",
    "power": 1.232,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "8/4/2024 12:15",
    "plant_id": "Green-tek-A23103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "12:15:00 PM",
    "power": 1.195,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "8/4/2024 12:30",
    "plant_id": "Green-tek-A23104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "12:30:00 PM",
    "power": 1.135,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "8/4/2024 12:45",
    "plant_id": "Green-tek-A23105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "12:45:00 PM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "8/4/2024 13:00",
    "plant_id": "Green-tek-A23106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "1:00:00 PM",
    "power": 0.964,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "8/4/2024 13:15",
    "plant_id": "Green-tek-A23107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "1:15:00 PM",
    "power": 0.862,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "8/4/2024 13:30",
    "plant_id": "Green-tek-A23108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "1:30:00 PM",
    "power": 0.755,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "8/4/2024 13:45",
    "plant_id": "Green-tek-A23109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "1:45:00 PM",
    "power": 0.648,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "8/4/2024 14:00",
    "plant_id": "Green-tek-A23110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "2:00:00 PM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "8/4/2024 14:15",
    "plant_id": "Green-tek-A23111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "2:15:00 PM",
    "power": 0.448,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "8/4/2024 14:30",
    "plant_id": "Green-tek-A23112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "2:30:00 PM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "8/4/2024 14:45",
    "plant_id": "Green-tek-A23113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "2:45:00 PM",
    "power": 0.286,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "8/4/2024 15:00",
    "plant_id": "Green-tek-A23114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "3:00:00 PM",
    "power": 0.222,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "8/4/2024 15:15",
    "plant_id": "Green-tek-A23115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "3:15:00 PM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "8/4/2024 15:30",
    "plant_id": "Green-tek-A23116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "3:30:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "8/4/2024 15:45",
    "plant_id": "Green-tek-A23117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "3:45:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/4/2024 16:00",
    "plant_id": "Green-tek-A23118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/4/2024 16:15",
    "plant_id": "Green-tek-A23119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/4/2024 16:30",
    "plant_id": "Green-tek-A23120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/4/2024 16:45",
    "plant_id": "Green-tek-A23121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/4/2024 17:00",
    "plant_id": "Green-tek-A23122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/4/2024 17:15",
    "plant_id": "Green-tek-A23123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/4/2024 17:30",
    "plant_id": "Green-tek-A23124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/4/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/5/2024 6:00",
    "plant_id": "Green-tek-A23125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/5/2024 6:15",
    "plant_id": "Green-tek-A23126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/5/2024 6:30",
    "plant_id": "Green-tek-A23127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/5/2024 6:45",
    "plant_id": "Green-tek-A23128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/5/2024 7:00",
    "plant_id": "Green-tek-A23129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/5/2024 7:15",
    "plant_id": "Green-tek-A23130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/5/2024 7:30",
    "plant_id": "Green-tek-A23131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/5/2024 7:45",
    "plant_id": "Green-tek-A23132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "7:45:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/5/2024 8:00",
    "plant_id": "Green-tek-A23133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "8:00:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "8/5/2024 8:15",
    "plant_id": "Green-tek-A23134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "8:15:00 AM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "8/5/2024 8:30",
    "plant_id": "Green-tek-A23135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "8:30:00 AM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "8/5/2024 8:45",
    "plant_id": "Green-tek-A23136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "8:45:00 AM",
    "power": 0.285,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "8/5/2024 9:00",
    "plant_id": "Green-tek-A23137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "9:00:00 AM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "8/5/2024 9:15",
    "plant_id": "Green-tek-A23138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "9:15:00 AM",
    "power": 0.446,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "8/5/2024 9:30",
    "plant_id": "Green-tek-A23139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "9:30:00 AM",
    "power": 0.542,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "8/5/2024 9:45",
    "plant_id": "Green-tek-A23140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "9:45:00 AM",
    "power": 0.644,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "8/5/2024 10:00",
    "plant_id": "Green-tek-A23141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "10:00:00 AM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "8/5/2024 10:15",
    "plant_id": "Green-tek-A23142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "10:15:00 AM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "8/5/2024 10:30",
    "plant_id": "Green-tek-A23143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "10:30:00 AM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "8/5/2024 10:45",
    "plant_id": "Green-tek-A23144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "10:45:00 AM",
    "power": 1.051,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "8/5/2024 11:00",
    "plant_id": "Green-tek-A23145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "11:00:00 AM",
    "power": 1.129,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "8/5/2024 11:15",
    "plant_id": "Green-tek-A23146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "11:15:00 AM",
    "power": 1.188,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "8/5/2024 11:30",
    "plant_id": "Green-tek-A23147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "11:30:00 AM",
    "power": 1.225,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "8/5/2024 11:45",
    "plant_id": "Green-tek-A23148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "11:45:00 AM",
    "power": 1.238,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "8/5/2024 12:00",
    "plant_id": "Green-tek-A23149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "12:00:00 PM",
    "power": 1.225,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "8/5/2024 12:15",
    "plant_id": "Green-tek-A23150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "12:15:00 PM",
    "power": 1.188,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "8/5/2024 12:30",
    "plant_id": "Green-tek-A23151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "12:30:00 PM",
    "power": 1.129,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "8/5/2024 12:45",
    "plant_id": "Green-tek-A23152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "12:45:00 PM",
    "power": 1.051,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "8/5/2024 13:00",
    "plant_id": "Green-tek-A23153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "1:00:00 PM",
    "power": 0.959,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "8/5/2024 13:15",
    "plant_id": "Green-tek-A23154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "1:15:00 PM",
    "power": 0.857,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "8/5/2024 13:30",
    "plant_id": "Green-tek-A23155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "1:30:00 PM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "8/5/2024 13:45",
    "plant_id": "Green-tek-A23156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "1:45:00 PM",
    "power": 0.644,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "8/5/2024 14:00",
    "plant_id": "Green-tek-A23157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "2:00:00 PM",
    "power": 0.542,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11424,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "8/5/2024 14:15",
    "plant_id": "Green-tek-A23158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "2:15:00 PM",
    "power": 0.446,
    "Energy": 0.112,
    "output Energy": 0.112,
    "Ac output": 1.112,
    "dc output": 1.13424,
    "AC_R_i": 0.00014609,
    "AC_S_i": 0.000160699,
    "AC_T_i": 0.00017677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0336007,
    "AC_S_pow": 0.03696077,
    "AC_T_Pow": 0.0406571,
    "AC_R_output": 0.3336,
    "AC_S_output": 0.36696,
    "AC_T_output": 0.403656,
    "dc_current": 0.00082353,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11424,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000112,
    "carbon offset": 0.14,
    "trees planted": 3.111111111,
    "DC_R_output": 0.110088,
    "DC_S_output": 0.1210968,
    "DC_T_output": 0.13320648
  },
  {
    "datetime": "8/5/2024 14:30",
    "plant_id": "Green-tek-A23159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "2:30:00 PM",
    "power": 0.36,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "8/5/2024 14:45",
    "plant_id": "Green-tek-A23160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "2:45:00 PM",
    "power": 0.285,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "8/5/2024 15:00",
    "plant_id": "Green-tek-A23161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "3:00:00 PM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "8/5/2024 15:15",
    "plant_id": "Green-tek-A23162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "3:15:00 PM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "8/5/2024 15:30",
    "plant_id": "Green-tek-A23163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "3:30:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "8/5/2024 15:45",
    "plant_id": "Green-tek-A23164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "3:45:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/5/2024 16:00",
    "plant_id": "Green-tek-A23165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/5/2024 16:15",
    "plant_id": "Green-tek-A23166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/5/2024 16:30",
    "plant_id": "Green-tek-A23167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/5/2024 16:45",
    "plant_id": "Green-tek-A23168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/5/2024 17:00",
    "plant_id": "Green-tek-A23169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/5/2024 17:15",
    "plant_id": "Green-tek-A23170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/5/2024 17:30",
    "plant_id": "Green-tek-A23171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/5/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/6/2024 6:00",
    "plant_id": "Green-tek-A23172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/6/2024 6:15",
    "plant_id": "Green-tek-A23173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/6/2024 6:30",
    "plant_id": "Green-tek-A23174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/6/2024 6:45",
    "plant_id": "Green-tek-A23175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/6/2024 7:00",
    "plant_id": "Green-tek-A23176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/6/2024 7:15",
    "plant_id": "Green-tek-A23177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/6/2024 7:30",
    "plant_id": "Green-tek-A23178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/6/2024 7:45",
    "plant_id": "Green-tek-A23179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "7:45:00 AM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/6/2024 8:00",
    "plant_id": "Green-tek-A23180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "8:00:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/6/2024 8:15",
    "plant_id": "Green-tek-A23181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "8:15:00 AM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/6/2024 8:30",
    "plant_id": "Green-tek-A23182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "8:30:00 AM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/6/2024 8:45",
    "plant_id": "Green-tek-A23183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "8:45:00 AM",
    "power": 0.294,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "8/6/2024 9:00",
    "plant_id": "Green-tek-A23184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "9:00:00 AM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "8/6/2024 9:15",
    "plant_id": "Green-tek-A23185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "9:15:00 AM",
    "power": 0.461,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "8/6/2024 9:30",
    "plant_id": "Green-tek-A23186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "9:30:00 AM",
    "power": 0.56,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "8/6/2024 9:45",
    "plant_id": "Green-tek-A23187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "9:45:00 AM",
    "power": 0.666,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "8/6/2024 10:00",
    "plant_id": "Green-tek-A23188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "10:00:00 AM",
    "power": 0.776,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "8/6/2024 10:15",
    "plant_id": "Green-tek-A23189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "10:15:00 AM",
    "power": 0.886,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "8/6/2024 10:30",
    "plant_id": "Green-tek-A23190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "10:30:00 AM",
    "power": 0.991,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "8/6/2024 10:45",
    "plant_id": "Green-tek-A23191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "10:45:00 AM",
    "power": 1.086,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "8/6/2024 11:00",
    "plant_id": "Green-tek-A23192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "11:00:00 AM",
    "power": 1.167,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "8/6/2024 11:15",
    "plant_id": "Green-tek-A23193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "11:15:00 AM",
    "power": 1.228,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "8/6/2024 11:30",
    "plant_id": "Green-tek-A23194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "11:30:00 AM",
    "power": 1.266,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.3264,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "8/6/2024 11:45",
    "plant_id": "Green-tek-A23195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "11:45:00 AM",
    "power": 1.279,
    "Energy": 0.32,
    "output Energy": 0.32,
    "Ac output": 1.32,
    "dc output": 1.3464,
    "AC_R_i": 0.00041739,
    "AC_S_i": 0.000459129,
    "AC_T_i": 0.00050504,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0959997,
    "AC_S_pow": 0.10559967,
    "AC_T_Pow": 0.1161592,
    "AC_R_output": 0.396,
    "AC_S_output": 0.4356,
    "AC_T_output": 0.47916,
    "dc_current": 0.00235294,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.3264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00032,
    "carbon offset": 0.4,
    "trees planted": 8.888888889,
    "DC_R_output": 0.13068,
    "DC_S_output": 0.143748,
    "DC_T_output": 0.1581228
  },
  {
    "datetime": "8/6/2024 12:00",
    "plant_id": "Green-tek-A23196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "12:00:00 PM",
    "power": 1.266,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "8/6/2024 12:15",
    "plant_id": "Green-tek-A23197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "12:15:00 PM",
    "power": 1.228,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "8/6/2024 12:30",
    "plant_id": "Green-tek-A23198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "12:30:00 PM",
    "power": 1.167,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "8/6/2024 12:45",
    "plant_id": "Green-tek-A23199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "12:45:00 PM",
    "power": 1.086,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "8/6/2024 13:00",
    "plant_id": "Green-tek-A23200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "1:00:00 PM",
    "power": 0.991,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "8/6/2024 13:15",
    "plant_id": "Green-tek-A23201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "1:15:00 PM",
    "power": 0.886,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "8/6/2024 13:30",
    "plant_id": "Green-tek-A23202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "1:30:00 PM",
    "power": 0.776,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "8/6/2024 13:45",
    "plant_id": "Green-tek-A23203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "1:45:00 PM",
    "power": 0.666,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "8/6/2024 14:00",
    "plant_id": "Green-tek-A23204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "2:00:00 PM",
    "power": 0.56,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "8/6/2024 14:15",
    "plant_id": "Green-tek-A23205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "2:15:00 PM",
    "power": 0.461,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "8/6/2024 14:30",
    "plant_id": "Green-tek-A23206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "2:30:00 PM",
    "power": 0.372,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "8/6/2024 14:45",
    "plant_id": "Green-tek-A23207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "2:45:00 PM",
    "power": 0.294,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "8/6/2024 15:00",
    "plant_id": "Green-tek-A23208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "3:00:00 PM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/6/2024 15:15",
    "plant_id": "Green-tek-A23209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "3:15:00 PM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/6/2024 15:30",
    "plant_id": "Green-tek-A23210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "3:30:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/6/2024 15:45",
    "plant_id": "Green-tek-A23211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "3:45:00 PM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/6/2024 16:00",
    "plant_id": "Green-tek-A23212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/6/2024 16:15",
    "plant_id": "Green-tek-A23213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/6/2024 16:30",
    "plant_id": "Green-tek-A23214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/6/2024 16:45",
    "plant_id": "Green-tek-A23215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/6/2024 17:00",
    "plant_id": "Green-tek-A23216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/6/2024 17:15",
    "plant_id": "Green-tek-A23217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/6/2024 17:30",
    "plant_id": "Green-tek-A23218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/6/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/7/2024 6:00",
    "plant_id": "Green-tek-A23219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/7/2024 6:15",
    "plant_id": "Green-tek-A23220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/7/2024 6:30",
    "plant_id": "Green-tek-A23221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/7/2024 6:45",
    "plant_id": "Green-tek-A23222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/7/2024 7:00",
    "plant_id": "Green-tek-A23223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/7/2024 7:15",
    "plant_id": "Green-tek-A23224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/7/2024 7:30",
    "plant_id": "Green-tek-A23225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/7/2024 7:45",
    "plant_id": "Green-tek-A23226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "7:45:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "8/7/2024 8:00",
    "plant_id": "Green-tek-A23227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "8:00:00 AM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "8/7/2024 8:15",
    "plant_id": "Green-tek-A23228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "8:15:00 AM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "8/7/2024 8:30",
    "plant_id": "Green-tek-A23229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "8:30:00 AM",
    "power": 0.214,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "8/7/2024 8:45",
    "plant_id": "Green-tek-A23230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "8:45:00 AM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "8/7/2024 9:00",
    "plant_id": "Green-tek-A23231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "9:00:00 AM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "8/7/2024 9:15",
    "plant_id": "Green-tek-A23232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "9:15:00 AM",
    "power": 0.433,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "8/7/2024 9:30",
    "plant_id": "Green-tek-A23233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "9:30:00 AM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "8/7/2024 9:45",
    "plant_id": "Green-tek-A23234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "9:45:00 AM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "8/7/2024 10:00",
    "plant_id": "Green-tek-A23235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "10:00:00 AM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "8/7/2024 10:15",
    "plant_id": "Green-tek-A23236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "10:15:00 AM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "8/7/2024 10:30",
    "plant_id": "Green-tek-A23237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "10:30:00 AM",
    "power": 0.93,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "8/7/2024 10:45",
    "plant_id": "Green-tek-A23238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "10:45:00 AM",
    "power": 1.02,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "8/7/2024 11:00",
    "plant_id": "Green-tek-A23239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "11:00:00 AM",
    "power": 1.095,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "8/7/2024 11:15",
    "plant_id": "Green-tek-A23240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "11:15:00 AM",
    "power": 1.152,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "8/7/2024 11:30",
    "plant_id": "Green-tek-A23241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "11:30:00 AM",
    "power": 1.188,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "8/7/2024 11:45",
    "plant_id": "Green-tek-A23242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "11:45:00 AM",
    "power": 1.2,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "8/7/2024 12:00",
    "plant_id": "Green-tek-A23243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "12:00:00 PM",
    "power": 1.188,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "8/7/2024 12:15",
    "plant_id": "Green-tek-A23244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "12:15:00 PM",
    "power": 1.152,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "8/7/2024 12:30",
    "plant_id": "Green-tek-A23245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "12:30:00 PM",
    "power": 1.095,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "8/7/2024 12:45",
    "plant_id": "Green-tek-A23246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "12:45:00 PM",
    "power": 1.02,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "8/7/2024 13:00",
    "plant_id": "Green-tek-A23247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "1:00:00 PM",
    "power": 0.93,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "8/7/2024 13:15",
    "plant_id": "Green-tek-A23248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "1:15:00 PM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "8/7/2024 13:30",
    "plant_id": "Green-tek-A23249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "1:30:00 PM",
    "power": 0.728,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "8/7/2024 13:45",
    "plant_id": "Green-tek-A23250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "1:45:00 PM",
    "power": 0.625,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13362,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "8/7/2024 14:00",
    "plant_id": "Green-tek-A23251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "2:00:00 PM",
    "power": 0.525,
    "Energy": 0.131,
    "output Energy": 0.131,
    "Ac output": 1.131,
    "dc output": 1.15362,
    "AC_R_i": 0.00017087,
    "AC_S_i": 0.000187957,
    "AC_T_i": 0.00020675,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0393001,
    "AC_S_pow": 0.04323011,
    "AC_T_Pow": 0.0475525,
    "AC_R_output": 0.3393,
    "AC_S_output": 0.37323,
    "AC_T_output": 0.410553,
    "dc_current": 0.00096324,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13362,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000131,
    "carbon offset": 0.16375,
    "trees planted": 3.638888889,
    "DC_R_output": 0.111969,
    "DC_S_output": 0.1231659,
    "DC_T_output": 0.13548249
  },
  {
    "datetime": "8/7/2024 14:15",
    "plant_id": "Green-tek-A23252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "2:15:00 PM",
    "power": 0.433,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "8/7/2024 14:30",
    "plant_id": "Green-tek-A23253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "2:30:00 PM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "8/7/2024 14:45",
    "plant_id": "Green-tek-A23254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "2:45:00 PM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "8/7/2024 15:00",
    "plant_id": "Green-tek-A23255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "3:00:00 PM",
    "power": 0.214,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "8/7/2024 15:15",
    "plant_id": "Green-tek-A23256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "3:15:00 PM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "8/7/2024 15:30",
    "plant_id": "Green-tek-A23257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "3:30:00 PM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "8/7/2024 15:45",
    "plant_id": "Green-tek-A23258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "3:45:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "8/7/2024 16:00",
    "plant_id": "Green-tek-A23259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/7/2024 16:15",
    "plant_id": "Green-tek-A23260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/7/2024 16:30",
    "plant_id": "Green-tek-A23261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/7/2024 16:45",
    "plant_id": "Green-tek-A23262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/7/2024 17:00",
    "plant_id": "Green-tek-A23263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/7/2024 17:15",
    "plant_id": "Green-tek-A23264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/7/2024 17:30",
    "plant_id": "Green-tek-A23265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/7/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/8/2024 6:00",
    "plant_id": "Green-tek-A23266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/8/2024 6:15",
    "plant_id": "Green-tek-A23267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/8/2024 6:30",
    "plant_id": "Green-tek-A23268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/8/2024 6:45",
    "plant_id": "Green-tek-A23269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/8/2024 7:00",
    "plant_id": "Green-tek-A23270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/8/2024 7:15",
    "plant_id": "Green-tek-A23271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "7:15:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/8/2024 7:30",
    "plant_id": "Green-tek-A23272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "7:30:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/8/2024 7:45",
    "plant_id": "Green-tek-A23273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "7:45:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/8/2024 8:00",
    "plant_id": "Green-tek-A23274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "8:00:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "8/8/2024 8:15",
    "plant_id": "Green-tek-A23275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "8:15:00 AM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "8/8/2024 8:30",
    "plant_id": "Green-tek-A23276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "8:30:00 AM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "8/8/2024 8:45",
    "plant_id": "Green-tek-A23277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "8:45:00 AM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "8/8/2024 9:00",
    "plant_id": "Green-tek-A23278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "9:00:00 AM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "8/8/2024 9:15",
    "plant_id": "Green-tek-A23279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "9:15:00 AM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "8/8/2024 9:30",
    "plant_id": "Green-tek-A23280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "9:30:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "8/8/2024 9:45",
    "plant_id": "Green-tek-A23281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "9:45:00 AM",
    "power": 0.457,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "8/8/2024 10:00",
    "plant_id": "Green-tek-A23282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "10:00:00 AM",
    "power": 0.533,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "8/8/2024 10:15",
    "plant_id": "Green-tek-A23283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "10:15:00 AM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "8/8/2024 10:30",
    "plant_id": "Green-tek-A23284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "10:30:00 AM",
    "power": 0.681,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "8/8/2024 10:45",
    "plant_id": "Green-tek-A23285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "10:45:00 AM",
    "power": 0.746,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "8/8/2024 11:00",
    "plant_id": "Green-tek-A23286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "11:00:00 AM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "8/8/2024 11:15",
    "plant_id": "Green-tek-A23287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "11:15:00 AM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "8/8/2024 11:30",
    "plant_id": "Green-tek-A23288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "11:30:00 AM",
    "power": 0.87,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "8/8/2024 11:45",
    "plant_id": "Green-tek-A23289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "11:45:00 AM",
    "power": 0.878,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.22134,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "8/8/2024 12:00",
    "plant_id": "Green-tek-A23290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "12:00:00 PM",
    "power": 0.87,
    "Energy": 0.217,
    "output Energy": 0.217,
    "Ac output": 1.217,
    "dc output": 1.24134,
    "AC_R_i": 0.00028304,
    "AC_S_i": 0.000311344,
    "AC_T_i": 0.00034248,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0650992,
    "AC_S_pow": 0.07160912,
    "AC_T_Pow": 0.0787704,
    "AC_R_output": 0.3651,
    "AC_S_output": 0.40161,
    "AC_T_output": 0.441771,
    "dc_current": 0.00159559,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.22134,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000217,
    "carbon offset": 0.27125,
    "trees planted": 6.027777778,
    "DC_R_output": 0.120483,
    "DC_S_output": 0.1325313,
    "DC_T_output": 0.14578443
  },
  {
    "datetime": "8/8/2024 12:15",
    "plant_id": "Green-tek-A23291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "12:15:00 PM",
    "power": 0.843,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "8/8/2024 12:30",
    "plant_id": "Green-tek-A23292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "12:30:00 PM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "8/8/2024 12:45",
    "plant_id": "Green-tek-A23293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "12:45:00 PM",
    "power": 0.746,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "8/8/2024 13:00",
    "plant_id": "Green-tek-A23294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "1:00:00 PM",
    "power": 0.681,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "8/8/2024 13:15",
    "plant_id": "Green-tek-A23295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "1:15:00 PM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "8/8/2024 13:30",
    "plant_id": "Green-tek-A23296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "1:30:00 PM",
    "power": 0.533,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "8/8/2024 13:45",
    "plant_id": "Green-tek-A23297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "1:45:00 PM",
    "power": 0.457,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "8/8/2024 14:00",
    "plant_id": "Green-tek-A23298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "2:00:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "8/8/2024 14:15",
    "plant_id": "Green-tek-A23299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "2:15:00 PM",
    "power": 0.317,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "8/8/2024 14:30",
    "plant_id": "Green-tek-A23300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "2:30:00 PM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "8/8/2024 14:45",
    "plant_id": "Green-tek-A23301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "2:45:00 PM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "8/8/2024 15:00",
    "plant_id": "Green-tek-A23302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "3:00:00 PM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "8/8/2024 15:15",
    "plant_id": "Green-tek-A23303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "3:15:00 PM",
    "power": 0.119,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "8/8/2024 15:30",
    "plant_id": "Green-tek-A23304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "3:30:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "8/8/2024 15:45",
    "plant_id": "Green-tek-A23305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "3:45:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/8/2024 16:00",
    "plant_id": "Green-tek-A23306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "4:00:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/8/2024 16:15",
    "plant_id": "Green-tek-A23307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "4:15:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/8/2024 16:30",
    "plant_id": "Green-tek-A23308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/8/2024 16:45",
    "plant_id": "Green-tek-A23309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/8/2024 17:00",
    "plant_id": "Green-tek-A23310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/8/2024 17:15",
    "plant_id": "Green-tek-A23311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/8/2024 17:30",
    "plant_id": "Green-tek-A23312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/8/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/9/2024 6:00",
    "plant_id": "Green-tek-A23313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/9/2024 6:15",
    "plant_id": "Green-tek-A23314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/9/2024 6:30",
    "plant_id": "Green-tek-A23315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/9/2024 6:45",
    "plant_id": "Green-tek-A23316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/9/2024 7:00",
    "plant_id": "Green-tek-A23317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/9/2024 7:15",
    "plant_id": "Green-tek-A23318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/9/2024 7:30",
    "plant_id": "Green-tek-A23319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/9/2024 7:45",
    "plant_id": "Green-tek-A23320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/9/2024 8:00",
    "plant_id": "Green-tek-A23321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "8:00:00 AM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/9/2024 8:15",
    "plant_id": "Green-tek-A23322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "8:15:00 AM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/9/2024 8:30",
    "plant_id": "Green-tek-A23323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "8:30:00 AM",
    "power": 0.23,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/9/2024 8:45",
    "plant_id": "Green-tek-A23324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "8:45:00 AM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "8/9/2024 9:00",
    "plant_id": "Green-tek-A23325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "9:00:00 AM",
    "power": 0.375,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "8/9/2024 9:15",
    "plant_id": "Green-tek-A23326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "9:15:00 AM",
    "power": 0.465,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "8/9/2024 9:30",
    "plant_id": "Green-tek-A23327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "9:30:00 AM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "8/9/2024 9:45",
    "plant_id": "Green-tek-A23328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "9:45:00 AM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "8/9/2024 10:00",
    "plant_id": "Green-tek-A23329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "10:00:00 AM",
    "power": 0.782,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "8/9/2024 10:15",
    "plant_id": "Green-tek-A23330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "10:15:00 AM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "8/9/2024 10:30",
    "plant_id": "Green-tek-A23331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "10:30:00 AM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "8/9/2024 10:45",
    "plant_id": "Green-tek-A23332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "10:45:00 AM",
    "power": 1.096,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "8/9/2024 11:00",
    "plant_id": "Green-tek-A23333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "11:00:00 AM",
    "power": 1.177,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "8/9/2024 11:15",
    "plant_id": "Green-tek-A23334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "11:15:00 AM",
    "power": 1.239,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.32538,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "8/9/2024 11:30",
    "plant_id": "Green-tek-A23335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "11:30:00 AM",
    "power": 1.277,
    "Energy": 0.319,
    "output Energy": 0.319,
    "Ac output": 1.319,
    "dc output": 1.34538,
    "AC_R_i": 0.00041609,
    "AC_S_i": 0.000457699,
    "AC_T_i": 0.00050347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0957007,
    "AC_S_pow": 0.10527077,
    "AC_T_Pow": 0.1157981,
    "AC_R_output": 0.3957,
    "AC_S_output": 0.43527,
    "AC_T_output": 0.478797,
    "dc_current": 0.00234559,
    "dc_voltage": 136,
    "dc_power": 0.32946,
    "dc_output": 0.32538,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000319,
    "carbon offset": 0.39875,
    "trees planted": 8.861111111,
    "DC_R_output": 0.130581,
    "DC_S_output": 0.1436391,
    "DC_T_output": 0.15800301
  },
  {
    "datetime": "8/9/2024 11:45",
    "plant_id": "Green-tek-A23336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "11:45:00 AM",
    "power": 1.29,
    "Energy": 0.323,
    "output Energy": 0.323,
    "Ac output": 1.323,
    "dc output": 1.34946,
    "AC_R_i": 0.0004213,
    "AC_S_i": 0.00046343,
    "AC_T_i": 0.00050977,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096899,
    "AC_S_pow": 0.1065889,
    "AC_T_Pow": 0.1172471,
    "AC_R_output": 0.3969,
    "AC_S_output": 0.43659,
    "AC_T_output": 0.480249,
    "dc_current": 0.002375,
    "dc_voltage": 136,
    "dc_power": 0.32538,
    "dc_output": 0.32946,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000323,
    "carbon offset": 0.40375,
    "trees planted": 8.972222222,
    "DC_R_output": 0.130977,
    "DC_S_output": 0.1440747,
    "DC_T_output": 0.15848217
  },
  {
    "datetime": "8/9/2024 12:00",
    "plant_id": "Green-tek-A23337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "12:00:00 PM",
    "power": 1.277,
    "Energy": 0.319,
    "output Energy": 0.319,
    "Ac output": 1.319,
    "dc output": 1.34538,
    "AC_R_i": 0.00041609,
    "AC_S_i": 0.000457699,
    "AC_T_i": 0.00050347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0957007,
    "AC_S_pow": 0.10527077,
    "AC_T_Pow": 0.1157981,
    "AC_R_output": 0.3957,
    "AC_S_output": 0.43527,
    "AC_T_output": 0.478797,
    "dc_current": 0.00234559,
    "dc_voltage": 136,
    "dc_power": 0.3162,
    "dc_output": 0.32538,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000319,
    "carbon offset": 0.39875,
    "trees planted": 8.861111111,
    "DC_R_output": 0.130581,
    "DC_S_output": 0.1436391,
    "DC_T_output": 0.15800301
  },
  {
    "datetime": "8/9/2024 12:15",
    "plant_id": "Green-tek-A23338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "12:15:00 PM",
    "power": 1.239,
    "Energy": 0.31,
    "output Energy": 0.31,
    "Ac output": 1.31,
    "dc output": 1.3362,
    "AC_R_i": 0.00040435,
    "AC_S_i": 0.000444785,
    "AC_T_i": 0.00048926,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0930005,
    "AC_S_pow": 0.10230055,
    "AC_T_Pow": 0.1125298,
    "AC_R_output": 0.393,
    "AC_S_output": 0.4323,
    "AC_T_output": 0.47553,
    "dc_current": 0.00227941,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.3162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00031,
    "carbon offset": 0.3875,
    "trees planted": 8.611111111,
    "DC_R_output": 0.12969,
    "DC_S_output": 0.142659,
    "DC_T_output": 0.1569249
  },
  {
    "datetime": "8/9/2024 12:30",
    "plant_id": "Green-tek-A23339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "12:30:00 PM",
    "power": 1.177,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "8/9/2024 12:45",
    "plant_id": "Green-tek-A23340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "12:45:00 PM",
    "power": 1.096,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "8/9/2024 13:00",
    "plant_id": "Green-tek-A23341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "1:00:00 PM",
    "power": 1,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "8/9/2024 13:15",
    "plant_id": "Green-tek-A23342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "1:15:00 PM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "8/9/2024 13:30",
    "plant_id": "Green-tek-A23343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "1:30:00 PM",
    "power": 0.782,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "8/9/2024 13:45",
    "plant_id": "Green-tek-A23344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "1:45:00 PM",
    "power": 0.671,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "8/9/2024 14:00",
    "plant_id": "Green-tek-A23345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "2:00:00 PM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "8/9/2024 14:15",
    "plant_id": "Green-tek-A23346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "2:15:00 PM",
    "power": 0.465,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "8/9/2024 14:30",
    "plant_id": "Green-tek-A23347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "2:30:00 PM",
    "power": 0.375,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "8/9/2024 14:45",
    "plant_id": "Green-tek-A23348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "2:45:00 PM",
    "power": 0.297,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "8/9/2024 15:00",
    "plant_id": "Green-tek-A23349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "3:00:00 PM",
    "power": 0.23,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/9/2024 15:15",
    "plant_id": "Green-tek-A23350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "3:15:00 PM",
    "power": 0.175,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/9/2024 15:30",
    "plant_id": "Green-tek-A23351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "3:30:00 PM",
    "power": 0.13,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/9/2024 15:45",
    "plant_id": "Green-tek-A23352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/9/2024 16:00",
    "plant_id": "Green-tek-A23353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/9/2024 16:15",
    "plant_id": "Green-tek-A23354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/9/2024 16:30",
    "plant_id": "Green-tek-A23355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/9/2024 16:45",
    "plant_id": "Green-tek-A23356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/9/2024 17:00",
    "plant_id": "Green-tek-A23357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/9/2024 17:15",
    "plant_id": "Green-tek-A23358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/9/2024 17:30",
    "plant_id": "Green-tek-A23359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/9/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/10/2024 6:00",
    "plant_id": "Green-tek-A23360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/10/2024 6:15",
    "plant_id": "Green-tek-A23361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/10/2024 6:30",
    "plant_id": "Green-tek-A23362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/10/2024 6:45",
    "plant_id": "Green-tek-A23363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/10/2024 7:00",
    "plant_id": "Green-tek-A23364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/10/2024 7:15",
    "plant_id": "Green-tek-A23365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/10/2024 7:30",
    "plant_id": "Green-tek-A23366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/10/2024 7:45",
    "plant_id": "Green-tek-A23367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/10/2024 8:00",
    "plant_id": "Green-tek-A23368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "8:00:00 AM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "8/10/2024 8:15",
    "plant_id": "Green-tek-A23369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "8:15:00 AM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "8/10/2024 8:30",
    "plant_id": "Green-tek-A23370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "8:30:00 AM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "8/10/2024 8:45",
    "plant_id": "Green-tek-A23371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "8:45:00 AM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "8/10/2024 9:00",
    "plant_id": "Green-tek-A23372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "9:00:00 AM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "8/10/2024 9:15",
    "plant_id": "Green-tek-A23373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "9:15:00 AM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "8/10/2024 9:30",
    "plant_id": "Green-tek-A23374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "9:30:00 AM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "8/10/2024 9:45",
    "plant_id": "Green-tek-A23375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "9:45:00 AM",
    "power": 0.527,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "8/10/2024 10:00",
    "plant_id": "Green-tek-A23376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "10:00:00 AM",
    "power": 0.614,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "8/10/2024 10:15",
    "plant_id": "Green-tek-A23377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "10:15:00 AM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "8/10/2024 10:30",
    "plant_id": "Green-tek-A23378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "10:30:00 AM",
    "power": 0.784,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "8/10/2024 10:45",
    "plant_id": "Green-tek-A23379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "10:45:00 AM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "8/10/2024 11:00",
    "plant_id": "Green-tek-A23380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "11:00:00 AM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "8/10/2024 11:15",
    "plant_id": "Green-tek-A23381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "11:15:00 AM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "8/10/2024 11:30",
    "plant_id": "Green-tek-A23382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "11:30:00 AM",
    "power": 1.002,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/10/2024 11:45",
    "plant_id": "Green-tek-A23383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "11:45:00 AM",
    "power": 1.012,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "8/10/2024 12:00",
    "plant_id": "Green-tek-A23384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "12:00:00 PM",
    "power": 1.002,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/10/2024 12:15",
    "plant_id": "Green-tek-A23385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "12:15:00 PM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "8/10/2024 12:30",
    "plant_id": "Green-tek-A23386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "12:30:00 PM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "8/10/2024 12:45",
    "plant_id": "Green-tek-A23387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "12:45:00 PM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "8/10/2024 13:00",
    "plant_id": "Green-tek-A23388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "1:00:00 PM",
    "power": 0.784,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "8/10/2024 13:15",
    "plant_id": "Green-tek-A23389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "1:15:00 PM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.15708,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "8/10/2024 13:30",
    "plant_id": "Green-tek-A23390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "1:30:00 PM",
    "power": 0.614,
    "Energy": 0.154,
    "output Energy": 0.154,
    "Ac output": 1.154,
    "dc output": 1.17708,
    "AC_R_i": 0.00020087,
    "AC_S_i": 0.000220957,
    "AC_T_i": 0.00024305,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0462001,
    "AC_S_pow": 0.05082011,
    "AC_T_Pow": 0.0559015,
    "AC_R_output": 0.3462,
    "AC_S_output": 0.38082,
    "AC_T_output": 0.418902,
    "dc_current": 0.00113235,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.15708,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000154,
    "carbon offset": 0.1925,
    "trees planted": 4.277777778,
    "DC_R_output": 0.114246,
    "DC_S_output": 0.1256706,
    "DC_T_output": 0.13823766
  },
  {
    "datetime": "8/10/2024 13:45",
    "plant_id": "Green-tek-A23391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "1:45:00 PM",
    "power": 0.527,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "8/10/2024 14:00",
    "plant_id": "Green-tek-A23392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "2:00:00 PM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "8/10/2024 14:15",
    "plant_id": "Green-tek-A23393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "2:15:00 PM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "8/10/2024 14:30",
    "plant_id": "Green-tek-A23394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "2:30:00 PM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "8/10/2024 14:45",
    "plant_id": "Green-tek-A23395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "2:45:00 PM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "8/10/2024 15:00",
    "plant_id": "Green-tek-A23396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "3:00:00 PM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "8/10/2024 15:15",
    "plant_id": "Green-tek-A23397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "3:15:00 PM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "8/10/2024 15:30",
    "plant_id": "Green-tek-A23398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "3:30:00 PM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "8/10/2024 15:45",
    "plant_id": "Green-tek-A23399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/10/2024 16:00",
    "plant_id": "Green-tek-A23400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/10/2024 16:15",
    "plant_id": "Green-tek-A23401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/10/2024 16:30",
    "plant_id": "Green-tek-A23402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/10/2024 16:45",
    "plant_id": "Green-tek-A23403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/10/2024 17:00",
    "plant_id": "Green-tek-A23404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/10/2024 17:15",
    "plant_id": "Green-tek-A23405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/10/2024 17:30",
    "plant_id": "Green-tek-A23406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/10/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/11/2024 6:00",
    "plant_id": "Green-tek-A23407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/11/2024 6:15",
    "plant_id": "Green-tek-A23408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/11/2024 6:30",
    "plant_id": "Green-tek-A23409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/11/2024 6:45",
    "plant_id": "Green-tek-A23410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/11/2024 7:00",
    "plant_id": "Green-tek-A23411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/11/2024 7:15",
    "plant_id": "Green-tek-A23412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/11/2024 7:30",
    "plant_id": "Green-tek-A23413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/11/2024 7:45",
    "plant_id": "Green-tek-A23414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "7:45:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "8/11/2024 8:00",
    "plant_id": "Green-tek-A23415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "8:00:00 AM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "8/11/2024 8:15",
    "plant_id": "Green-tek-A23416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "8:15:00 AM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "8/11/2024 8:30",
    "plant_id": "Green-tek-A23417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "8:30:00 AM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "8/11/2024 8:45",
    "plant_id": "Green-tek-A23418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "8:45:00 AM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "8/11/2024 9:00",
    "plant_id": "Green-tek-A23419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "9:00:00 AM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "8/11/2024 9:15",
    "plant_id": "Green-tek-A23420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "9:15:00 AM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "8/11/2024 9:30",
    "plant_id": "Green-tek-A23421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "9:30:00 AM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "8/11/2024 9:45",
    "plant_id": "Green-tek-A23422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "9:45:00 AM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "8/11/2024 10:00",
    "plant_id": "Green-tek-A23423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "10:00:00 AM",
    "power": 0.71,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "8/11/2024 10:15",
    "plant_id": "Green-tek-A23424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "10:15:00 AM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/11/2024 10:30",
    "plant_id": "Green-tek-A23425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "10:30:00 AM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "8/11/2024 10:45",
    "plant_id": "Green-tek-A23426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "10:45:00 AM",
    "power": 0.994,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "8/11/2024 11:00",
    "plant_id": "Green-tek-A23427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "11:00:00 AM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "8/11/2024 11:15",
    "plant_id": "Green-tek-A23428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "11:15:00 AM",
    "power": 1.124,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "8/11/2024 11:30",
    "plant_id": "Green-tek-A23429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "11:30:00 AM",
    "power": 1.159,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "8/11/2024 11:45",
    "plant_id": "Green-tek-A23430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "11:45:00 AM",
    "power": 1.171,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "8/11/2024 12:00",
    "plant_id": "Green-tek-A23431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "12:00:00 PM",
    "power": 1.159,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "8/11/2024 12:15",
    "plant_id": "Green-tek-A23432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "12:15:00 PM",
    "power": 1.124,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "8/11/2024 12:30",
    "plant_id": "Green-tek-A23433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "12:30:00 PM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "8/11/2024 12:45",
    "plant_id": "Green-tek-A23434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "12:45:00 PM",
    "power": 0.994,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "8/11/2024 13:00",
    "plant_id": "Green-tek-A23435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "1:00:00 PM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "8/11/2024 13:15",
    "plant_id": "Green-tek-A23436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "1:15:00 PM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/11/2024 13:30",
    "plant_id": "Green-tek-A23437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "1:30:00 PM",
    "power": 0.71,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "8/11/2024 13:45",
    "plant_id": "Green-tek-A23438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "1:45:00 PM",
    "power": 0.609,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "8/11/2024 14:00",
    "plant_id": "Green-tek-A23439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "2:00:00 PM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "8/11/2024 14:15",
    "plant_id": "Green-tek-A23440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "2:15:00 PM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "8/11/2024 14:30",
    "plant_id": "Green-tek-A23441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "2:30:00 PM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "8/11/2024 14:45",
    "plant_id": "Green-tek-A23442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "2:45:00 PM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "8/11/2024 15:00",
    "plant_id": "Green-tek-A23443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "3:00:00 PM",
    "power": 0.209,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "8/11/2024 15:15",
    "plant_id": "Green-tek-A23444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "3:15:00 PM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "8/11/2024 15:30",
    "plant_id": "Green-tek-A23445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "3:30:00 PM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "8/11/2024 15:45",
    "plant_id": "Green-tek-A23446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "3:45:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "8/11/2024 16:00",
    "plant_id": "Green-tek-A23447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/11/2024 16:15",
    "plant_id": "Green-tek-A23448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/11/2024 16:30",
    "plant_id": "Green-tek-A23449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/11/2024 16:45",
    "plant_id": "Green-tek-A23450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/11/2024 17:00",
    "plant_id": "Green-tek-A23451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/11/2024 17:15",
    "plant_id": "Green-tek-A23452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/11/2024 17:30",
    "plant_id": "Green-tek-A23453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/11/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/12/2024 6:00",
    "plant_id": "Green-tek-A23454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/12/2024 6:15",
    "plant_id": "Green-tek-A23455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/12/2024 6:30",
    "plant_id": "Green-tek-A23456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/12/2024 6:45",
    "plant_id": "Green-tek-A23457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/12/2024 7:00",
    "plant_id": "Green-tek-A23458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/12/2024 7:15",
    "plant_id": "Green-tek-A23459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/12/2024 7:30",
    "plant_id": "Green-tek-A23460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/12/2024 7:45",
    "plant_id": "Green-tek-A23461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/12/2024 8:00",
    "plant_id": "Green-tek-A23462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/12/2024 8:15",
    "plant_id": "Green-tek-A23463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "8:15:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/12/2024 8:30",
    "plant_id": "Green-tek-A23464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "8:30:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "8/12/2024 8:45",
    "plant_id": "Green-tek-A23465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "8:45:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "8/12/2024 9:00",
    "plant_id": "Green-tek-A23466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "9:00:00 AM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "8/12/2024 9:15",
    "plant_id": "Green-tek-A23467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "9:15:00 AM",
    "power": 0.468,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "8/12/2024 9:30",
    "plant_id": "Green-tek-A23468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "9:30:00 AM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "8/12/2024 9:45",
    "plant_id": "Green-tek-A23469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "9:45:00 AM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "8/12/2024 10:00",
    "plant_id": "Green-tek-A23470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "10:00:00 AM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "8/12/2024 10:15",
    "plant_id": "Green-tek-A23471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "10:15:00 AM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "8/12/2024 10:30",
    "plant_id": "Green-tek-A23472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "10:30:00 AM",
    "power": 1.007,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "8/12/2024 10:45",
    "plant_id": "Green-tek-A23473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "10:45:00 AM",
    "power": 1.104,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "8/12/2024 11:00",
    "plant_id": "Green-tek-A23474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "11:00:00 AM",
    "power": 1.186,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "8/12/2024 11:15",
    "plant_id": "Green-tek-A23475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "11:15:00 AM",
    "power": 1.248,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "8/12/2024 11:30",
    "plant_id": "Green-tek-A23476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "11:30:00 AM",
    "power": 1.286,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.3315,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "8/12/2024 11:45",
    "plant_id": "Green-tek-A23477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "11:45:00 AM",
    "power": 1.3,
    "Energy": 0.325,
    "output Energy": 0.325,
    "Ac output": 1.325,
    "dc output": 1.3515,
    "AC_R_i": 0.00042391,
    "AC_S_i": 0.000466301,
    "AC_T_i": 0.00051293,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0974993,
    "AC_S_pow": 0.10724923,
    "AC_T_Pow": 0.1179739,
    "AC_R_output": 0.3975,
    "AC_S_output": 0.43725,
    "AC_T_output": 0.480975,
    "dc_current": 0.00238971,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.3315,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000325,
    "carbon offset": 0.40625,
    "trees planted": 9.027777778,
    "DC_R_output": 0.131175,
    "DC_S_output": 0.1442925,
    "DC_T_output": 0.15872175
  },
  {
    "datetime": "8/12/2024 12:00",
    "plant_id": "Green-tek-A23478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "12:00:00 PM",
    "power": 1.286,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "8/12/2024 12:15",
    "plant_id": "Green-tek-A23479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "12:15:00 PM",
    "power": 1.248,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "8/12/2024 12:30",
    "plant_id": "Green-tek-A23480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "12:30:00 PM",
    "power": 1.186,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "8/12/2024 12:45",
    "plant_id": "Green-tek-A23481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "12:45:00 PM",
    "power": 1.104,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "8/12/2024 13:00",
    "plant_id": "Green-tek-A23482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "1:00:00 PM",
    "power": 1.007,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "8/12/2024 13:15",
    "plant_id": "Green-tek-A23483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "1:15:00 PM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "8/12/2024 13:30",
    "plant_id": "Green-tek-A23484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "1:30:00 PM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "8/12/2024 13:45",
    "plant_id": "Green-tek-A23485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "1:45:00 PM",
    "power": 0.676,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "8/12/2024 14:00",
    "plant_id": "Green-tek-A23486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "2:00:00 PM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "8/12/2024 14:15",
    "plant_id": "Green-tek-A23487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "2:15:00 PM",
    "power": 0.468,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "8/12/2024 14:30",
    "plant_id": "Green-tek-A23488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "2:30:00 PM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "8/12/2024 14:45",
    "plant_id": "Green-tek-A23489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "2:45:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "8/12/2024 15:00",
    "plant_id": "Green-tek-A23490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "3:00:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "8/12/2024 15:15",
    "plant_id": "Green-tek-A23491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "3:15:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/12/2024 15:30",
    "plant_id": "Green-tek-A23492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/12/2024 15:45",
    "plant_id": "Green-tek-A23493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/12/2024 16:00",
    "plant_id": "Green-tek-A23494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/12/2024 16:15",
    "plant_id": "Green-tek-A23495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/12/2024 16:30",
    "plant_id": "Green-tek-A23496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/12/2024 16:45",
    "plant_id": "Green-tek-A23497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/12/2024 17:00",
    "plant_id": "Green-tek-A23498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/12/2024 17:15",
    "plant_id": "Green-tek-A23499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/12/2024 17:30",
    "plant_id": "Green-tek-A23500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/12/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/13/2024 6:00",
    "plant_id": "Green-tek-A23501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/13/2024 6:15",
    "plant_id": "Green-tek-A23502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/13/2024 6:30",
    "plant_id": "Green-tek-A23503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/13/2024 6:45",
    "plant_id": "Green-tek-A23504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/13/2024 7:00",
    "plant_id": "Green-tek-A23505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/13/2024 7:15",
    "plant_id": "Green-tek-A23506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/13/2024 7:30",
    "plant_id": "Green-tek-A23507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/13/2024 7:45",
    "plant_id": "Green-tek-A23508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/13/2024 8:00",
    "plant_id": "Green-tek-A23509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "8:00:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/13/2024 8:15",
    "plant_id": "Green-tek-A23510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "8:15:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/13/2024 8:30",
    "plant_id": "Green-tek-A23511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "8:30:00 AM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "8/13/2024 8:45",
    "plant_id": "Green-tek-A23512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "8:45:00 AM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "8/13/2024 9:00",
    "plant_id": "Green-tek-A23513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "9:00:00 AM",
    "power": 0.367,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "8/13/2024 9:15",
    "plant_id": "Green-tek-A23514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "9:15:00 AM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "8/13/2024 9:30",
    "plant_id": "Green-tek-A23515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "9:30:00 AM",
    "power": 0.552,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "8/13/2024 9:45",
    "plant_id": "Green-tek-A23516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "9:45:00 AM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "8/13/2024 10:00",
    "plant_id": "Green-tek-A23517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "10:00:00 AM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "8/13/2024 10:15",
    "plant_id": "Green-tek-A23518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "10:15:00 AM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "8/13/2024 10:30",
    "plant_id": "Green-tek-A23519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "10:30:00 AM",
    "power": 0.977,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "8/13/2024 10:45",
    "plant_id": "Green-tek-A23520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "10:45:00 AM",
    "power": 1.071,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "8/13/2024 11:00",
    "plant_id": "Green-tek-A23521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "11:00:00 AM",
    "power": 1.15,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "8/13/2024 11:15",
    "plant_id": "Green-tek-A23522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "11:15:00 AM",
    "power": 1.211,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "8/13/2024 11:30",
    "plant_id": "Green-tek-A23523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "11:30:00 AM",
    "power": 1.248,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.3213,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "8/13/2024 11:45",
    "plant_id": "Green-tek-A23524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "11:45:00 AM",
    "power": 1.261,
    "Energy": 0.315,
    "output Energy": 0.315,
    "Ac output": 1.315,
    "dc output": 1.3413,
    "AC_R_i": 0.00041087,
    "AC_S_i": 0.000451957,
    "AC_T_i": 0.00049715,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0945001,
    "AC_S_pow": 0.10395011,
    "AC_T_Pow": 0.1143445,
    "AC_R_output": 0.3945,
    "AC_S_output": 0.43395,
    "AC_T_output": 0.477345,
    "dc_current": 0.00231618,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.3213,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000315,
    "carbon offset": 0.39375,
    "trees planted": 8.75,
    "DC_R_output": 0.130185,
    "DC_S_output": 0.1432035,
    "DC_T_output": 0.15752385
  },
  {
    "datetime": "8/13/2024 12:00",
    "plant_id": "Green-tek-A23525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "12:00:00 PM",
    "power": 1.248,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "8/13/2024 12:15",
    "plant_id": "Green-tek-A23526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "12:15:00 PM",
    "power": 1.211,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "8/13/2024 12:30",
    "plant_id": "Green-tek-A23527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "12:30:00 PM",
    "power": 1.15,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "8/13/2024 12:45",
    "plant_id": "Green-tek-A23528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "12:45:00 PM",
    "power": 1.071,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "8/13/2024 13:00",
    "plant_id": "Green-tek-A23529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "1:00:00 PM",
    "power": 0.977,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "8/13/2024 13:15",
    "plant_id": "Green-tek-A23530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "1:15:00 PM",
    "power": 0.873,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "8/13/2024 13:30",
    "plant_id": "Green-tek-A23531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "1:30:00 PM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "8/13/2024 13:45",
    "plant_id": "Green-tek-A23532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "1:45:00 PM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "8/13/2024 14:00",
    "plant_id": "Green-tek-A23533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "2:00:00 PM",
    "power": 0.552,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "8/13/2024 14:15",
    "plant_id": "Green-tek-A23534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "2:15:00 PM",
    "power": 0.455,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "8/13/2024 14:30",
    "plant_id": "Green-tek-A23535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "2:30:00 PM",
    "power": 0.367,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "8/13/2024 14:45",
    "plant_id": "Green-tek-A23536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "2:45:00 PM",
    "power": 0.29,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "8/13/2024 15:00",
    "plant_id": "Green-tek-A23537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "3:00:00 PM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "8/13/2024 15:15",
    "plant_id": "Green-tek-A23538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "3:15:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/13/2024 15:30",
    "plant_id": "Green-tek-A23539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "3:30:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/13/2024 15:45",
    "plant_id": "Green-tek-A23540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/13/2024 16:00",
    "plant_id": "Green-tek-A23541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/13/2024 16:15",
    "plant_id": "Green-tek-A23542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/13/2024 16:30",
    "plant_id": "Green-tek-A23543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/13/2024 16:45",
    "plant_id": "Green-tek-A23544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/13/2024 17:00",
    "plant_id": "Green-tek-A23545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/13/2024 17:15",
    "plant_id": "Green-tek-A23546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/13/2024 17:30",
    "plant_id": "Green-tek-A23547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/13/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/14/2024 6:00",
    "plant_id": "Green-tek-A23548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/14/2024 6:15",
    "plant_id": "Green-tek-A23549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/14/2024 6:30",
    "plant_id": "Green-tek-A23550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/14/2024 6:45",
    "plant_id": "Green-tek-A23551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/14/2024 7:00",
    "plant_id": "Green-tek-A23552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/14/2024 7:15",
    "plant_id": "Green-tek-A23553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/14/2024 7:30",
    "plant_id": "Green-tek-A23554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "7:30:00 AM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/14/2024 7:45",
    "plant_id": "Green-tek-A23555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "7:45:00 AM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/14/2024 8:00",
    "plant_id": "Green-tek-A23556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "8:00:00 AM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "8/14/2024 8:15",
    "plant_id": "Green-tek-A23557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "8:15:00 AM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/14/2024 8:30",
    "plant_id": "Green-tek-A23558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "8:30:00 AM",
    "power": 0.174,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/14/2024 8:45",
    "plant_id": "Green-tek-A23559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "8:45:00 AM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "8/14/2024 9:00",
    "plant_id": "Green-tek-A23560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "9:00:00 AM",
    "power": 0.285,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "8/14/2024 9:15",
    "plant_id": "Green-tek-A23561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "9:15:00 AM",
    "power": 0.353,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "8/14/2024 9:30",
    "plant_id": "Green-tek-A23562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "9:30:00 AM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "8/14/2024 9:45",
    "plant_id": "Green-tek-A23563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "9:45:00 AM",
    "power": 0.509,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "8/14/2024 10:00",
    "plant_id": "Green-tek-A23564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "10:00:00 AM",
    "power": 0.594,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "8/14/2024 10:15",
    "plant_id": "Green-tek-A23565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "10:15:00 AM",
    "power": 0.678,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "8/14/2024 10:30",
    "plant_id": "Green-tek-A23566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "10:30:00 AM",
    "power": 0.758,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "8/14/2024 10:45",
    "plant_id": "Green-tek-A23567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "10:45:00 AM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "8/14/2024 11:00",
    "plant_id": "Green-tek-A23568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "11:00:00 AM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "8/14/2024 11:15",
    "plant_id": "Green-tek-A23569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "11:15:00 AM",
    "power": 0.94,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "8/14/2024 11:30",
    "plant_id": "Green-tek-A23570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "11:30:00 AM",
    "power": 0.969,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "8/14/2024 11:45",
    "plant_id": "Green-tek-A23571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "11:45:00 AM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "8/14/2024 12:00",
    "plant_id": "Green-tek-A23572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "12:00:00 PM",
    "power": 0.969,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "8/14/2024 12:15",
    "plant_id": "Green-tek-A23573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "12:15:00 PM",
    "power": 0.94,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "8/14/2024 12:30",
    "plant_id": "Green-tek-A23574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "12:30:00 PM",
    "power": 0.893,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "8/14/2024 12:45",
    "plant_id": "Green-tek-A23575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "12:45:00 PM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "8/14/2024 13:00",
    "plant_id": "Green-tek-A23576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "1:00:00 PM",
    "power": 0.758,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "8/14/2024 13:15",
    "plant_id": "Green-tek-A23577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "1:15:00 PM",
    "power": 0.678,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "8/14/2024 13:30",
    "plant_id": "Green-tek-A23578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "1:30:00 PM",
    "power": 0.594,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "8/14/2024 13:45",
    "plant_id": "Green-tek-A23579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "1:45:00 PM",
    "power": 0.509,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "8/14/2024 14:00",
    "plant_id": "Green-tek-A23580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "2:00:00 PM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "8/14/2024 14:15",
    "plant_id": "Green-tek-A23581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "2:15:00 PM",
    "power": 0.353,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "8/14/2024 14:30",
    "plant_id": "Green-tek-A23582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "2:30:00 PM",
    "power": 0.285,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "8/14/2024 14:45",
    "plant_id": "Green-tek-A23583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "2:45:00 PM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "8/14/2024 15:00",
    "plant_id": "Green-tek-A23584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "3:00:00 PM",
    "power": 0.174,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/14/2024 15:15",
    "plant_id": "Green-tek-A23585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "3:15:00 PM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/14/2024 15:30",
    "plant_id": "Green-tek-A23586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "3:30:00 PM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "8/14/2024 15:45",
    "plant_id": "Green-tek-A23587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "3:45:00 PM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/14/2024 16:00",
    "plant_id": "Green-tek-A23588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "4:00:00 PM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/14/2024 16:15",
    "plant_id": "Green-tek-A23589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/14/2024 16:30",
    "plant_id": "Green-tek-A23590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/14/2024 16:45",
    "plant_id": "Green-tek-A23591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/14/2024 17:00",
    "plant_id": "Green-tek-A23592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/14/2024 17:15",
    "plant_id": "Green-tek-A23593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/14/2024 17:30",
    "plant_id": "Green-tek-A23594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/14/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/15/2024 6:00",
    "plant_id": "Green-tek-A23595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/15/2024 6:15",
    "plant_id": "Green-tek-A23596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/15/2024 6:30",
    "plant_id": "Green-tek-A23597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/15/2024 6:45",
    "plant_id": "Green-tek-A23598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/15/2024 7:00",
    "plant_id": "Green-tek-A23599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/15/2024 7:15",
    "plant_id": "Green-tek-A23600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/15/2024 7:30",
    "plant_id": "Green-tek-A23601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/15/2024 7:45",
    "plant_id": "Green-tek-A23602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "7:45:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/15/2024 8:00",
    "plant_id": "Green-tek-A23603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "8:00:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/15/2024 8:15",
    "plant_id": "Green-tek-A23604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "8:15:00 AM",
    "power": 0.17,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/15/2024 8:30",
    "plant_id": "Green-tek-A23605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "8:30:00 AM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "8/15/2024 8:45",
    "plant_id": "Green-tek-A23606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "8:45:00 AM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "8/15/2024 9:00",
    "plant_id": "Green-tek-A23607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "9:00:00 AM",
    "power": 0.366,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "8/15/2024 9:15",
    "plant_id": "Green-tek-A23608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "9:15:00 AM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "8/15/2024 9:30",
    "plant_id": "Green-tek-A23609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "9:30:00 AM",
    "power": 0.55,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "8/15/2024 9:45",
    "plant_id": "Green-tek-A23610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "9:45:00 AM",
    "power": 0.654,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "8/15/2024 10:00",
    "plant_id": "Green-tek-A23611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "10:00:00 AM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "8/15/2024 10:15",
    "plant_id": "Green-tek-A23612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "10:15:00 AM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "8/15/2024 10:30",
    "plant_id": "Green-tek-A23613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "10:30:00 AM",
    "power": 0.974,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "8/15/2024 10:45",
    "plant_id": "Green-tek-A23614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "10:45:00 AM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "8/15/2024 11:00",
    "plant_id": "Green-tek-A23615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "11:00:00 AM",
    "power": 1.147,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "8/15/2024 11:15",
    "plant_id": "Green-tek-A23616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "11:15:00 AM",
    "power": 1.207,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "8/15/2024 11:30",
    "plant_id": "Green-tek-A23617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "11:30:00 AM",
    "power": 1.245,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.32028,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "8/15/2024 11:45",
    "plant_id": "Green-tek-A23618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "11:45:00 AM",
    "power": 1.258,
    "Energy": 0.314,
    "output Energy": 0.314,
    "Ac output": 1.314,
    "dc output": 1.34028,
    "AC_R_i": 0.00040957,
    "AC_S_i": 0.000450527,
    "AC_T_i": 0.00049558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0942011,
    "AC_S_pow": 0.10362121,
    "AC_T_Pow": 0.1139834,
    "AC_R_output": 0.3942,
    "AC_S_output": 0.43362,
    "AC_T_output": 0.476982,
    "dc_current": 0.00230882,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.32028,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000314,
    "carbon offset": 0.3925,
    "trees planted": 8.722222222,
    "DC_R_output": 0.130086,
    "DC_S_output": 0.1430946,
    "DC_T_output": 0.15740406
  },
  {
    "datetime": "8/15/2024 12:00",
    "plant_id": "Green-tek-A23619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "12:00:00 PM",
    "power": 1.245,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "8/15/2024 12:15",
    "plant_id": "Green-tek-A23620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "12:15:00 PM",
    "power": 1.207,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "8/15/2024 12:30",
    "plant_id": "Green-tek-A23621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "12:30:00 PM",
    "power": 1.147,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "8/15/2024 12:45",
    "plant_id": "Green-tek-A23622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "12:45:00 PM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "8/15/2024 13:00",
    "plant_id": "Green-tek-A23623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "1:00:00 PM",
    "power": 0.974,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "8/15/2024 13:15",
    "plant_id": "Green-tek-A23624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "1:15:00 PM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "8/15/2024 13:30",
    "plant_id": "Green-tek-A23625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "1:30:00 PM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "8/15/2024 13:45",
    "plant_id": "Green-tek-A23626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "1:45:00 PM",
    "power": 0.654,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "8/15/2024 14:00",
    "plant_id": "Green-tek-A23627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "2:00:00 PM",
    "power": 0.55,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "8/15/2024 14:15",
    "plant_id": "Green-tek-A23628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "2:15:00 PM",
    "power": 0.453,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "8/15/2024 14:30",
    "plant_id": "Green-tek-A23629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "2:30:00 PM",
    "power": 0.366,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "8/15/2024 14:45",
    "plant_id": "Green-tek-A23630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "2:45:00 PM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "8/15/2024 15:00",
    "plant_id": "Green-tek-A23631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "3:00:00 PM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "8/15/2024 15:15",
    "plant_id": "Green-tek-A23632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "3:15:00 PM",
    "power": 0.17,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/15/2024 15:30",
    "plant_id": "Green-tek-A23633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "3:30:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/15/2024 15:45",
    "plant_id": "Green-tek-A23634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "3:45:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/15/2024 16:00",
    "plant_id": "Green-tek-A23635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/15/2024 16:15",
    "plant_id": "Green-tek-A23636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/15/2024 16:30",
    "plant_id": "Green-tek-A23637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/15/2024 16:45",
    "plant_id": "Green-tek-A23638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/15/2024 17:00",
    "plant_id": "Green-tek-A23639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/15/2024 17:15",
    "plant_id": "Green-tek-A23640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/15/2024 17:30",
    "plant_id": "Green-tek-A23641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/15/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/16/2024 6:00",
    "plant_id": "Green-tek-A23642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/16/2024 6:15",
    "plant_id": "Green-tek-A23643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/16/2024 6:30",
    "plant_id": "Green-tek-A23644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/16/2024 6:45",
    "plant_id": "Green-tek-A23645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/16/2024 7:00",
    "plant_id": "Green-tek-A23646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/16/2024 7:15",
    "plant_id": "Green-tek-A23647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/16/2024 7:30",
    "plant_id": "Green-tek-A23648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/16/2024 7:45",
    "plant_id": "Green-tek-A23649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "7:45:00 AM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/16/2024 8:00",
    "plant_id": "Green-tek-A23650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "8:00:00 AM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "8/16/2024 8:15",
    "plant_id": "Green-tek-A23651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "8:15:00 AM",
    "power": 0.134,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/16/2024 8:30",
    "plant_id": "Green-tek-A23652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "8:30:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/16/2024 8:45",
    "plant_id": "Green-tek-A23653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "8:45:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/16/2024 9:00",
    "plant_id": "Green-tek-A23654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "9:00:00 AM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "8/16/2024 9:15",
    "plant_id": "Green-tek-A23655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "9:15:00 AM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "8/16/2024 9:30",
    "plant_id": "Green-tek-A23656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "9:30:00 AM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "8/16/2024 9:45",
    "plant_id": "Green-tek-A23657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "9:45:00 AM",
    "power": 0.514,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "8/16/2024 10:00",
    "plant_id": "Green-tek-A23658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "10:00:00 AM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "8/16/2024 10:15",
    "plant_id": "Green-tek-A23659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "10:15:00 AM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "8/16/2024 10:30",
    "plant_id": "Green-tek-A23660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "10:30:00 AM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "8/16/2024 10:45",
    "plant_id": "Green-tek-A23661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "10:45:00 AM",
    "power": 0.838,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "8/16/2024 11:00",
    "plant_id": "Green-tek-A23662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "11:00:00 AM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "8/16/2024 11:15",
    "plant_id": "Green-tek-A23663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "11:15:00 AM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "8/16/2024 11:30",
    "plant_id": "Green-tek-A23664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "11:30:00 AM",
    "power": 0.977,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "8/16/2024 11:45",
    "plant_id": "Green-tek-A23665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "11:45:00 AM",
    "power": 0.987,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "8/16/2024 12:00",
    "plant_id": "Green-tek-A23666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "12:00:00 PM",
    "power": 0.977,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "8/16/2024 12:15",
    "plant_id": "Green-tek-A23667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "12:15:00 PM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "8/16/2024 12:30",
    "plant_id": "Green-tek-A23668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "12:30:00 PM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "8/16/2024 12:45",
    "plant_id": "Green-tek-A23669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "12:45:00 PM",
    "power": 0.838,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "8/16/2024 13:00",
    "plant_id": "Green-tek-A23670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "1:00:00 PM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "8/16/2024 13:15",
    "plant_id": "Green-tek-A23671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "1:15:00 PM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "8/16/2024 13:30",
    "plant_id": "Green-tek-A23672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "1:30:00 PM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "8/16/2024 13:45",
    "plant_id": "Green-tek-A23673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "1:45:00 PM",
    "power": 0.514,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "8/16/2024 14:00",
    "plant_id": "Green-tek-A23674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "2:00:00 PM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "8/16/2024 14:15",
    "plant_id": "Green-tek-A23675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "2:15:00 PM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "8/16/2024 14:30",
    "plant_id": "Green-tek-A23676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "2:30:00 PM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "8/16/2024 14:45",
    "plant_id": "Green-tek-A23677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "2:45:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/16/2024 15:00",
    "plant_id": "Green-tek-A23678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "3:00:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/16/2024 15:15",
    "plant_id": "Green-tek-A23679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "3:15:00 PM",
    "power": 0.134,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/16/2024 15:30",
    "plant_id": "Green-tek-A23680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "3:30:00 PM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "8/16/2024 15:45",
    "plant_id": "Green-tek-A23681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "3:45:00 PM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/16/2024 16:00",
    "plant_id": "Green-tek-A23682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/16/2024 16:15",
    "plant_id": "Green-tek-A23683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/16/2024 16:30",
    "plant_id": "Green-tek-A23684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/16/2024 16:45",
    "plant_id": "Green-tek-A23685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/16/2024 17:00",
    "plant_id": "Green-tek-A23686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/16/2024 17:15",
    "plant_id": "Green-tek-A23687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/16/2024 17:30",
    "plant_id": "Green-tek-A23688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/16/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/17/2024 6:00",
    "plant_id": "Green-tek-A23689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/17/2024 6:15",
    "plant_id": "Green-tek-A23690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/17/2024 6:30",
    "plant_id": "Green-tek-A23691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/17/2024 6:45",
    "plant_id": "Green-tek-A23692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/17/2024 7:00",
    "plant_id": "Green-tek-A23693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/17/2024 7:15",
    "plant_id": "Green-tek-A23694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/17/2024 7:30",
    "plant_id": "Green-tek-A23695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/17/2024 7:45",
    "plant_id": "Green-tek-A23696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "7:45:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/17/2024 8:00",
    "plant_id": "Green-tek-A23697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "8:00:00 AM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/17/2024 8:15",
    "plant_id": "Green-tek-A23698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "8:15:00 AM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/17/2024 8:30",
    "plant_id": "Green-tek-A23699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "8:30:00 AM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "8/17/2024 8:45",
    "plant_id": "Green-tek-A23700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "8:45:00 AM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "8/17/2024 9:00",
    "plant_id": "Green-tek-A23701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "9:00:00 AM",
    "power": 0.272,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "8/17/2024 9:15",
    "plant_id": "Green-tek-A23702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "9:15:00 AM",
    "power": 0.337,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "8/17/2024 9:30",
    "plant_id": "Green-tek-A23703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "9:30:00 AM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "8/17/2024 9:45",
    "plant_id": "Green-tek-A23704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "9:45:00 AM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "8/17/2024 10:00",
    "plant_id": "Green-tek-A23705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "10:00:00 AM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "8/17/2024 10:15",
    "plant_id": "Green-tek-A23706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "10:15:00 AM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "8/17/2024 10:30",
    "plant_id": "Green-tek-A23707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "10:30:00 AM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "8/17/2024 10:45",
    "plant_id": "Green-tek-A23708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "10:45:00 AM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "8/17/2024 11:00",
    "plant_id": "Green-tek-A23709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "11:00:00 AM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "8/17/2024 11:15",
    "plant_id": "Green-tek-A23710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "11:15:00 AM",
    "power": 0.897,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "8/17/2024 11:30",
    "plant_id": "Green-tek-A23711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "11:30:00 AM",
    "power": 0.925,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "8/17/2024 11:45",
    "plant_id": "Green-tek-A23712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "11:45:00 AM",
    "power": 0.934,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "8/17/2024 12:00",
    "plant_id": "Green-tek-A23713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "12:00:00 PM",
    "power": 0.925,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "8/17/2024 12:15",
    "plant_id": "Green-tek-A23714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "12:15:00 PM",
    "power": 0.897,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "8/17/2024 12:30",
    "plant_id": "Green-tek-A23715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "12:30:00 PM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "8/17/2024 12:45",
    "plant_id": "Green-tek-A23716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "12:45:00 PM",
    "power": 0.793,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "8/17/2024 13:00",
    "plant_id": "Green-tek-A23717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "1:00:00 PM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "8/17/2024 13:15",
    "plant_id": "Green-tek-A23718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "1:15:00 PM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "8/17/2024 13:30",
    "plant_id": "Green-tek-A23719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "1:30:00 PM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "8/17/2024 13:45",
    "plant_id": "Green-tek-A23720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "1:45:00 PM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "8/17/2024 14:00",
    "plant_id": "Green-tek-A23721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "2:00:00 PM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "8/17/2024 14:15",
    "plant_id": "Green-tek-A23722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "2:15:00 PM",
    "power": 0.337,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "8/17/2024 14:30",
    "plant_id": "Green-tek-A23723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "2:30:00 PM",
    "power": 0.272,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "8/17/2024 14:45",
    "plant_id": "Green-tek-A23724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "2:45:00 PM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "8/17/2024 15:00",
    "plant_id": "Green-tek-A23725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "3:00:00 PM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "8/17/2024 15:15",
    "plant_id": "Green-tek-A23726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "3:15:00 PM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "8/17/2024 15:30",
    "plant_id": "Green-tek-A23727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "3:30:00 PM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/17/2024 15:45",
    "plant_id": "Green-tek-A23728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "3:45:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "8/17/2024 16:00",
    "plant_id": "Green-tek-A23729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "8/17/2024 16:15",
    "plant_id": "Green-tek-A23730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/17/2024 16:30",
    "plant_id": "Green-tek-A23731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/17/2024 16:45",
    "plant_id": "Green-tek-A23732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/17/2024 17:00",
    "plant_id": "Green-tek-A23733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/17/2024 17:15",
    "plant_id": "Green-tek-A23734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/17/2024 17:30",
    "plant_id": "Green-tek-A23735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/17/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/18/2024 6:00",
    "plant_id": "Green-tek-A23736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/18/2024 6:15",
    "plant_id": "Green-tek-A23737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/18/2024 6:30",
    "plant_id": "Green-tek-A23738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/18/2024 6:45",
    "plant_id": "Green-tek-A23739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/18/2024 7:00",
    "plant_id": "Green-tek-A23740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/18/2024 7:15",
    "plant_id": "Green-tek-A23741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/18/2024 7:30",
    "plant_id": "Green-tek-A23742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/18/2024 7:45",
    "plant_id": "Green-tek-A23743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/18/2024 8:00",
    "plant_id": "Green-tek-A23744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "8:00:00 AM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/18/2024 8:15",
    "plant_id": "Green-tek-A23745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "8:15:00 AM",
    "power": 0.142,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/18/2024 8:30",
    "plant_id": "Green-tek-A23746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "8:30:00 AM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/18/2024 8:45",
    "plant_id": "Green-tek-A23747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "8:45:00 AM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "8/18/2024 9:00",
    "plant_id": "Green-tek-A23748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "9:00:00 AM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "8/18/2024 9:15",
    "plant_id": "Green-tek-A23749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "9:15:00 AM",
    "power": 0.378,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "8/18/2024 9:30",
    "plant_id": "Green-tek-A23750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "9:30:00 AM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "8/18/2024 9:45",
    "plant_id": "Green-tek-A23751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "9:45:00 AM",
    "power": 0.545,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "8/18/2024 10:00",
    "plant_id": "Green-tek-A23752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "10:00:00 AM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "8/18/2024 10:15",
    "plant_id": "Green-tek-A23753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "10:15:00 AM",
    "power": 0.726,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "8/18/2024 10:30",
    "plant_id": "Green-tek-A23754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "10:30:00 AM",
    "power": 0.812,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/18/2024 10:45",
    "plant_id": "Green-tek-A23755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "10:45:00 AM",
    "power": 0.89,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "8/18/2024 11:00",
    "plant_id": "Green-tek-A23756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "11:00:00 AM",
    "power": 0.956,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "8/18/2024 11:15",
    "plant_id": "Green-tek-A23757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "11:15:00 AM",
    "power": 1.006,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/18/2024 11:30",
    "plant_id": "Green-tek-A23758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "11:30:00 AM",
    "power": 1.037,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "8/18/2024 11:45",
    "plant_id": "Green-tek-A23759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "11:45:00 AM",
    "power": 1.048,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "8/18/2024 12:00",
    "plant_id": "Green-tek-A23760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "12:00:00 PM",
    "power": 1.037,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "8/18/2024 12:15",
    "plant_id": "Green-tek-A23761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "12:15:00 PM",
    "power": 1.006,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/18/2024 12:30",
    "plant_id": "Green-tek-A23762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "12:30:00 PM",
    "power": 0.956,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "8/18/2024 12:45",
    "plant_id": "Green-tek-A23763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "12:45:00 PM",
    "power": 0.89,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "8/18/2024 13:00",
    "plant_id": "Green-tek-A23764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "1:00:00 PM",
    "power": 0.812,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/18/2024 13:15",
    "plant_id": "Green-tek-A23765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "1:15:00 PM",
    "power": 0.726,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "8/18/2024 13:30",
    "plant_id": "Green-tek-A23766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "1:30:00 PM",
    "power": 0.636,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "8/18/2024 13:45",
    "plant_id": "Green-tek-A23767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "1:45:00 PM",
    "power": 0.545,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "8/18/2024 14:00",
    "plant_id": "Green-tek-A23768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "2:00:00 PM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "8/18/2024 14:15",
    "plant_id": "Green-tek-A23769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "2:15:00 PM",
    "power": 0.378,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "8/18/2024 14:30",
    "plant_id": "Green-tek-A23770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "2:30:00 PM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "8/18/2024 14:45",
    "plant_id": "Green-tek-A23771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "2:45:00 PM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "8/18/2024 15:00",
    "plant_id": "Green-tek-A23772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "3:00:00 PM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/18/2024 15:15",
    "plant_id": "Green-tek-A23773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "3:15:00 PM",
    "power": 0.142,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/18/2024 15:30",
    "plant_id": "Green-tek-A23774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "3:30:00 PM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/18/2024 15:45",
    "plant_id": "Green-tek-A23775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/18/2024 16:00",
    "plant_id": "Green-tek-A23776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/18/2024 16:15",
    "plant_id": "Green-tek-A23777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/18/2024 16:30",
    "plant_id": "Green-tek-A23778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/18/2024 16:45",
    "plant_id": "Green-tek-A23779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/18/2024 17:00",
    "plant_id": "Green-tek-A23780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/18/2024 17:15",
    "plant_id": "Green-tek-A23781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/18/2024 17:30",
    "plant_id": "Green-tek-A23782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/18/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/19/2024 6:00",
    "plant_id": "Green-tek-A23783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/19/2024 6:15",
    "plant_id": "Green-tek-A23784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/19/2024 6:30",
    "plant_id": "Green-tek-A23785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/19/2024 6:45",
    "plant_id": "Green-tek-A23786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/19/2024 7:00",
    "plant_id": "Green-tek-A23787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/19/2024 7:15",
    "plant_id": "Green-tek-A23788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/19/2024 7:30",
    "plant_id": "Green-tek-A23789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/19/2024 7:45",
    "plant_id": "Green-tek-A23790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "8/19/2024 8:00",
    "plant_id": "Green-tek-A23791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "8:00:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "8/19/2024 8:15",
    "plant_id": "Green-tek-A23792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "8:15:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "8/19/2024 8:30",
    "plant_id": "Green-tek-A23793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "8:30:00 AM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "8/19/2024 8:45",
    "plant_id": "Green-tek-A23794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "8:45:00 AM",
    "power": 0.254,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "8/19/2024 9:00",
    "plant_id": "Green-tek-A23795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "9:00:00 AM",
    "power": 0.321,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "8/19/2024 9:15",
    "plant_id": "Green-tek-A23796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "9:15:00 AM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "8/19/2024 9:30",
    "plant_id": "Green-tek-A23797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "9:30:00 AM",
    "power": 0.482,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "8/19/2024 9:45",
    "plant_id": "Green-tek-A23798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "9:45:00 AM",
    "power": 0.574,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "8/19/2024 10:00",
    "plant_id": "Green-tek-A23799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "10:00:00 AM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "8/19/2024 10:15",
    "plant_id": "Green-tek-A23800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "10:15:00 AM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "8/19/2024 10:30",
    "plant_id": "Green-tek-A23801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "10:30:00 AM",
    "power": 0.854,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "8/19/2024 10:45",
    "plant_id": "Green-tek-A23802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "10:45:00 AM",
    "power": 0.936,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "8/19/2024 11:00",
    "plant_id": "Green-tek-A23803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "11:00:00 AM",
    "power": 1.006,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/19/2024 11:15",
    "plant_id": "Green-tek-A23804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "11:15:00 AM",
    "power": 1.058,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "8/19/2024 11:30",
    "plant_id": "Green-tek-A23805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "11:30:00 AM",
    "power": 1.091,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "8/19/2024 11:45",
    "plant_id": "Green-tek-A23806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "11:45:00 AM",
    "power": 1.102,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "8/19/2024 12:00",
    "plant_id": "Green-tek-A23807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "12:00:00 PM",
    "power": 1.091,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "8/19/2024 12:15",
    "plant_id": "Green-tek-A23808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "12:15:00 PM",
    "power": 1.058,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "8/19/2024 12:30",
    "plant_id": "Green-tek-A23809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "12:30:00 PM",
    "power": 1.006,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/19/2024 12:45",
    "plant_id": "Green-tek-A23810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "12:45:00 PM",
    "power": 0.936,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "8/19/2024 13:00",
    "plant_id": "Green-tek-A23811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "1:00:00 PM",
    "power": 0.854,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "8/19/2024 13:15",
    "plant_id": "Green-tek-A23812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "1:15:00 PM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "8/19/2024 13:30",
    "plant_id": "Green-tek-A23813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "1:30:00 PM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "8/19/2024 13:45",
    "plant_id": "Green-tek-A23814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "1:45:00 PM",
    "power": 0.574,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "8/19/2024 14:00",
    "plant_id": "Green-tek-A23815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "2:00:00 PM",
    "power": 0.482,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "8/19/2024 14:15",
    "plant_id": "Green-tek-A23816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "2:15:00 PM",
    "power": 0.397,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "8/19/2024 14:30",
    "plant_id": "Green-tek-A23817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "2:30:00 PM",
    "power": 0.321,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "8/19/2024 14:45",
    "plant_id": "Green-tek-A23818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "2:45:00 PM",
    "power": 0.254,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "8/19/2024 15:00",
    "plant_id": "Green-tek-A23819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "3:00:00 PM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "8/19/2024 15:15",
    "plant_id": "Green-tek-A23820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "3:15:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "8/19/2024 15:30",
    "plant_id": "Green-tek-A23821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "3:30:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "8/19/2024 15:45",
    "plant_id": "Green-tek-A23822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "8/19/2024 16:00",
    "plant_id": "Green-tek-A23823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/19/2024 16:15",
    "plant_id": "Green-tek-A23824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/19/2024 16:30",
    "plant_id": "Green-tek-A23825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/19/2024 16:45",
    "plant_id": "Green-tek-A23826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/19/2024 17:00",
    "plant_id": "Green-tek-A23827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/19/2024 17:15",
    "plant_id": "Green-tek-A23828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/19/2024 17:30",
    "plant_id": "Green-tek-A23829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/19/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/20/2024 6:00",
    "plant_id": "Green-tek-A23830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/20/2024 6:15",
    "plant_id": "Green-tek-A23831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/20/2024 6:30",
    "plant_id": "Green-tek-A23832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/20/2024 6:45",
    "plant_id": "Green-tek-A23833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/20/2024 7:00",
    "plant_id": "Green-tek-A23834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/20/2024 7:15",
    "plant_id": "Green-tek-A23835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/20/2024 7:30",
    "plant_id": "Green-tek-A23836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/20/2024 7:45",
    "plant_id": "Green-tek-A23837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/20/2024 8:00",
    "plant_id": "Green-tek-A23838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "8:00:00 AM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/20/2024 8:15",
    "plant_id": "Green-tek-A23839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "8:15:00 AM",
    "power": 0.142,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/20/2024 8:30",
    "plant_id": "Green-tek-A23840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "8:30:00 AM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/20/2024 8:45",
    "plant_id": "Green-tek-A23841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "8:45:00 AM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "8/20/2024 9:00",
    "plant_id": "Green-tek-A23842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "9:00:00 AM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "8/20/2024 9:15",
    "plant_id": "Green-tek-A23843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "9:15:00 AM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "8/20/2024 9:30",
    "plant_id": "Green-tek-A23844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "9:30:00 AM",
    "power": 0.458,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "8/20/2024 9:45",
    "plant_id": "Green-tek-A23845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "9:45:00 AM",
    "power": 0.545,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "8/20/2024 10:00",
    "plant_id": "Green-tek-A23846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "10:00:00 AM",
    "power": 0.635,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "8/20/2024 10:15",
    "plant_id": "Green-tek-A23847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "10:15:00 AM",
    "power": 0.725,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "8/20/2024 10:30",
    "plant_id": "Green-tek-A23848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "10:30:00 AM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/20/2024 10:45",
    "plant_id": "Green-tek-A23849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "10:45:00 AM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "8/20/2024 11:00",
    "plant_id": "Green-tek-A23850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "11:00:00 AM",
    "power": 0.955,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "8/20/2024 11:15",
    "plant_id": "Green-tek-A23851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "11:15:00 AM",
    "power": 1.005,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/20/2024 11:30",
    "plant_id": "Green-tek-A23852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "11:30:00 AM",
    "power": 1.036,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "8/20/2024 11:45",
    "plant_id": "Green-tek-A23853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "11:45:00 AM",
    "power": 1.047,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "8/20/2024 12:00",
    "plant_id": "Green-tek-A23854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "12:00:00 PM",
    "power": 1.036,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "8/20/2024 12:15",
    "plant_id": "Green-tek-A23855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "12:15:00 PM",
    "power": 1.005,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/20/2024 12:30",
    "plant_id": "Green-tek-A23856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "12:30:00 PM",
    "power": 0.955,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "8/20/2024 12:45",
    "plant_id": "Green-tek-A23857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "12:45:00 PM",
    "power": 0.889,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "8/20/2024 13:00",
    "plant_id": "Green-tek-A23858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "1:00:00 PM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/20/2024 13:15",
    "plant_id": "Green-tek-A23859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "1:15:00 PM",
    "power": 0.725,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "8/20/2024 13:30",
    "plant_id": "Green-tek-A23860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "1:30:00 PM",
    "power": 0.635,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "8/20/2024 13:45",
    "plant_id": "Green-tek-A23861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "1:45:00 PM",
    "power": 0.545,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "8/20/2024 14:00",
    "plant_id": "Green-tek-A23862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "2:00:00 PM",
    "power": 0.458,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "8/20/2024 14:15",
    "plant_id": "Green-tek-A23863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "2:15:00 PM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "8/20/2024 14:30",
    "plant_id": "Green-tek-A23864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "2:30:00 PM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "8/20/2024 14:45",
    "plant_id": "Green-tek-A23865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "2:45:00 PM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "8/20/2024 15:00",
    "plant_id": "Green-tek-A23866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "3:00:00 PM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/20/2024 15:15",
    "plant_id": "Green-tek-A23867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "3:15:00 PM",
    "power": 0.142,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/20/2024 15:30",
    "plant_id": "Green-tek-A23868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "3:30:00 PM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/20/2024 15:45",
    "plant_id": "Green-tek-A23869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/20/2024 16:00",
    "plant_id": "Green-tek-A23870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/20/2024 16:15",
    "plant_id": "Green-tek-A23871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/20/2024 16:30",
    "plant_id": "Green-tek-A23872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/20/2024 16:45",
    "plant_id": "Green-tek-A23873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/20/2024 17:00",
    "plant_id": "Green-tek-A23874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/20/2024 17:15",
    "plant_id": "Green-tek-A23875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/20/2024 17:30",
    "plant_id": "Green-tek-A23876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/20/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/21/2024 6:00",
    "plant_id": "Green-tek-A23877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/21/2024 6:15",
    "plant_id": "Green-tek-A23878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/21/2024 6:30",
    "plant_id": "Green-tek-A23879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/21/2024 6:45",
    "plant_id": "Green-tek-A23880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/21/2024 7:00",
    "plant_id": "Green-tek-A23881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/21/2024 7:15",
    "plant_id": "Green-tek-A23882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/21/2024 7:30",
    "plant_id": "Green-tek-A23883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/21/2024 7:45",
    "plant_id": "Green-tek-A23884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "7:45:00 AM",
    "power": 0.078,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/21/2024 8:00",
    "plant_id": "Green-tek-A23885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "8:00:00 AM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "8/21/2024 8:15",
    "plant_id": "Green-tek-A23886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "8:15:00 AM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "8/21/2024 8:30",
    "plant_id": "Green-tek-A23887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "8:30:00 AM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/21/2024 8:45",
    "plant_id": "Green-tek-A23888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "8:45:00 AM",
    "power": 0.244,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "8/21/2024 9:00",
    "plant_id": "Green-tek-A23889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "9:00:00 AM",
    "power": 0.308,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "8/21/2024 9:15",
    "plant_id": "Green-tek-A23890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "9:15:00 AM",
    "power": 0.382,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "8/21/2024 9:30",
    "plant_id": "Green-tek-A23891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "9:30:00 AM",
    "power": 0.464,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "8/21/2024 9:45",
    "plant_id": "Green-tek-A23892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "9:45:00 AM",
    "power": 0.551,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "8/21/2024 10:00",
    "plant_id": "Green-tek-A23893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "10:00:00 AM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "8/21/2024 10:15",
    "plant_id": "Green-tek-A23894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "10:15:00 AM",
    "power": 0.734,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "8/21/2024 10:30",
    "plant_id": "Green-tek-A23895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "10:30:00 AM",
    "power": 0.821,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "8/21/2024 10:45",
    "plant_id": "Green-tek-A23896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "10:45:00 AM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "8/21/2024 11:00",
    "plant_id": "Green-tek-A23897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "11:00:00 AM",
    "power": 0.966,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "8/21/2024 11:15",
    "plant_id": "Green-tek-A23898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "11:15:00 AM",
    "power": 1.017,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "8/21/2024 11:30",
    "plant_id": "Green-tek-A23899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "11:30:00 AM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "8/21/2024 11:45",
    "plant_id": "Green-tek-A23900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "11:45:00 AM",
    "power": 1.059,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "8/21/2024 12:00",
    "plant_id": "Green-tek-A23901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "12:00:00 PM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "8/21/2024 12:15",
    "plant_id": "Green-tek-A23902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "12:15:00 PM",
    "power": 1.017,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "8/21/2024 12:30",
    "plant_id": "Green-tek-A23903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "12:30:00 PM",
    "power": 0.966,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "8/21/2024 12:45",
    "plant_id": "Green-tek-A23904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "12:45:00 PM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "8/21/2024 13:00",
    "plant_id": "Green-tek-A23905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "1:00:00 PM",
    "power": 0.821,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "8/21/2024 13:15",
    "plant_id": "Green-tek-A23906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "1:15:00 PM",
    "power": 0.734,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "8/21/2024 13:30",
    "plant_id": "Green-tek-A23907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "1:30:00 PM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "8/21/2024 13:45",
    "plant_id": "Green-tek-A23908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "1:45:00 PM",
    "power": 0.551,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "8/21/2024 14:00",
    "plant_id": "Green-tek-A23909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "2:00:00 PM",
    "power": 0.464,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "8/21/2024 14:15",
    "plant_id": "Green-tek-A23910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "2:15:00 PM",
    "power": 0.382,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "8/21/2024 14:30",
    "plant_id": "Green-tek-A23911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "2:30:00 PM",
    "power": 0.308,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "8/21/2024 14:45",
    "plant_id": "Green-tek-A23912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "2:45:00 PM",
    "power": 0.244,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "8/21/2024 15:00",
    "plant_id": "Green-tek-A23913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "3:00:00 PM",
    "power": 0.189,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/21/2024 15:15",
    "plant_id": "Green-tek-A23914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "3:15:00 PM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "8/21/2024 15:30",
    "plant_id": "Green-tek-A23915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "3:30:00 PM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "8/21/2024 15:45",
    "plant_id": "Green-tek-A23916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "3:45:00 PM",
    "power": 0.078,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/21/2024 16:00",
    "plant_id": "Green-tek-A23917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/21/2024 16:15",
    "plant_id": "Green-tek-A23918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/21/2024 16:30",
    "plant_id": "Green-tek-A23919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/21/2024 16:45",
    "plant_id": "Green-tek-A23920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/21/2024 17:00",
    "plant_id": "Green-tek-A23921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/21/2024 17:15",
    "plant_id": "Green-tek-A23922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/21/2024 17:30",
    "plant_id": "Green-tek-A23923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/21/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/22/2024 6:00",
    "plant_id": "Green-tek-A23924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/22/2024 6:15",
    "plant_id": "Green-tek-A23925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/22/2024 6:30",
    "plant_id": "Green-tek-A23926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/22/2024 6:45",
    "plant_id": "Green-tek-A23927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/22/2024 7:00",
    "plant_id": "Green-tek-A23928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/22/2024 7:15",
    "plant_id": "Green-tek-A23929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/22/2024 7:30",
    "plant_id": "Green-tek-A23930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/22/2024 7:45",
    "plant_id": "Green-tek-A23931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/22/2024 8:00",
    "plant_id": "Green-tek-A23932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "8:00:00 AM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/22/2024 8:15",
    "plant_id": "Green-tek-A23933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "8:15:00 AM",
    "power": 0.142,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/22/2024 8:30",
    "plant_id": "Green-tek-A23934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "8:30:00 AM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/22/2024 8:45",
    "plant_id": "Green-tek-A23935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "8:45:00 AM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "8/22/2024 9:00",
    "plant_id": "Green-tek-A23936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "9:00:00 AM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "8/22/2024 9:15",
    "plant_id": "Green-tek-A23937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "9:15:00 AM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "8/22/2024 9:30",
    "plant_id": "Green-tek-A23938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "9:30:00 AM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "8/22/2024 9:45",
    "plant_id": "Green-tek-A23939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "9:45:00 AM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "8/22/2024 10:00",
    "plant_id": "Green-tek-A23940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "10:00:00 AM",
    "power": 0.634,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "8/22/2024 10:15",
    "plant_id": "Green-tek-A23941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "10:15:00 AM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "8/22/2024 10:30",
    "plant_id": "Green-tek-A23942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "10:30:00 AM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/22/2024 10:45",
    "plant_id": "Green-tek-A23943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "10:45:00 AM",
    "power": 0.888,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "8/22/2024 11:00",
    "plant_id": "Green-tek-A23944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "11:00:00 AM",
    "power": 0.954,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "8/22/2024 11:15",
    "plant_id": "Green-tek-A23945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "11:15:00 AM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/22/2024 11:30",
    "plant_id": "Green-tek-A23946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "11:30:00 AM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "8/22/2024 11:45",
    "plant_id": "Green-tek-A23947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "11:45:00 AM",
    "power": 1.046,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "8/22/2024 12:00",
    "plant_id": "Green-tek-A23948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "12:00:00 PM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "8/22/2024 12:15",
    "plant_id": "Green-tek-A23949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "12:15:00 PM",
    "power": 1.004,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "8/22/2024 12:30",
    "plant_id": "Green-tek-A23950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "12:30:00 PM",
    "power": 0.954,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "8/22/2024 12:45",
    "plant_id": "Green-tek-A23951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "12:45:00 PM",
    "power": 0.888,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "8/22/2024 13:00",
    "plant_id": "Green-tek-A23952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "1:00:00 PM",
    "power": 0.81,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/22/2024 13:15",
    "plant_id": "Green-tek-A23953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "1:15:00 PM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "8/22/2024 13:30",
    "plant_id": "Green-tek-A23954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "1:30:00 PM",
    "power": 0.634,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "8/22/2024 13:45",
    "plant_id": "Green-tek-A23955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "1:45:00 PM",
    "power": 0.544,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "8/22/2024 14:00",
    "plant_id": "Green-tek-A23956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "2:00:00 PM",
    "power": 0.458,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09588,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "8/22/2024 14:15",
    "plant_id": "Green-tek-A23957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "2:15:00 PM",
    "power": 0.377,
    "Energy": 0.094,
    "output Energy": 0.094,
    "Ac output": 1.094,
    "dc output": 1.11588,
    "AC_R_i": 0.00012261,
    "AC_S_i": 0.000134871,
    "AC_T_i": 0.00014836,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0282003,
    "AC_S_pow": 0.03102033,
    "AC_T_Pow": 0.0341228,
    "AC_R_output": 0.3282,
    "AC_S_output": 0.36102,
    "AC_T_output": 0.397122,
    "dc_current": 0.00069118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09588,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000094,
    "carbon offset": 0.1175,
    "trees planted": 2.611111111,
    "DC_R_output": 0.108306,
    "DC_S_output": 0.1191366,
    "DC_T_output": 0.13105026
  },
  {
    "datetime": "8/22/2024 14:30",
    "plant_id": "Green-tek-A23958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "2:30:00 PM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "8/22/2024 14:45",
    "plant_id": "Green-tek-A23959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "2:45:00 PM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "8/22/2024 15:00",
    "plant_id": "Green-tek-A23960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "3:00:00 PM",
    "power": 0.186,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/22/2024 15:15",
    "plant_id": "Green-tek-A23961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "3:15:00 PM",
    "power": 0.142,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/22/2024 15:30",
    "plant_id": "Green-tek-A23962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "3:30:00 PM",
    "power": 0.105,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/22/2024 15:45",
    "plant_id": "Green-tek-A23963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/22/2024 16:00",
    "plant_id": "Green-tek-A23964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/22/2024 16:15",
    "plant_id": "Green-tek-A23965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/22/2024 16:30",
    "plant_id": "Green-tek-A23966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/22/2024 16:45",
    "plant_id": "Green-tek-A23967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/22/2024 17:00",
    "plant_id": "Green-tek-A23968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/22/2024 17:15",
    "plant_id": "Green-tek-A23969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/22/2024 17:30",
    "plant_id": "Green-tek-A23970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/22/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/23/2024 6:00",
    "plant_id": "Green-tek-A23971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/23/2024 6:15",
    "plant_id": "Green-tek-A23972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/23/2024 6:30",
    "plant_id": "Green-tek-A23973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/23/2024 6:45",
    "plant_id": "Green-tek-A23974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/23/2024 7:00",
    "plant_id": "Green-tek-A23975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/23/2024 7:15",
    "plant_id": "Green-tek-A23976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/23/2024 7:30",
    "plant_id": "Green-tek-A23977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/23/2024 7:45",
    "plant_id": "Green-tek-A23978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/23/2024 8:00",
    "plant_id": "Green-tek-A23979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "8:00:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "8/23/2024 8:15",
    "plant_id": "Green-tek-A23980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "8:15:00 AM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "8/23/2024 8:30",
    "plant_id": "Green-tek-A23981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "8:30:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "8/23/2024 8:45",
    "plant_id": "Green-tek-A23982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "8:45:00 AM",
    "power": 0.194,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "8/23/2024 9:00",
    "plant_id": "Green-tek-A23983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "9:00:00 AM",
    "power": 0.246,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "8/23/2024 9:15",
    "plant_id": "Green-tek-A23984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "9:15:00 AM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "8/23/2024 9:30",
    "plant_id": "Green-tek-A23985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "9:30:00 AM",
    "power": 0.37,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "8/23/2024 9:45",
    "plant_id": "Green-tek-A23986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "9:45:00 AM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "8/23/2024 10:00",
    "plant_id": "Green-tek-A23987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "10:00:00 AM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "8/23/2024 10:15",
    "plant_id": "Green-tek-A23988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "10:15:00 AM",
    "power": 0.585,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "8/23/2024 10:30",
    "plant_id": "Green-tek-A23989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "10:30:00 AM",
    "power": 0.654,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "8/23/2024 10:45",
    "plant_id": "Green-tek-A23990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "10:45:00 AM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "8/23/2024 11:00",
    "plant_id": "Green-tek-A23991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "11:00:00 AM",
    "power": 0.77,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "8/23/2024 11:15",
    "plant_id": "Green-tek-A23992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "11:15:00 AM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/23/2024 11:30",
    "plant_id": "Green-tek-A23993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "11:30:00 AM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "8/23/2024 11:45",
    "plant_id": "Green-tek-A23994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "11:45:00 AM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "8/23/2024 12:00",
    "plant_id": "Green-tek-A23995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "12:00:00 PM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "8/23/2024 12:15",
    "plant_id": "Green-tek-A23996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "12:15:00 PM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/23/2024 12:30",
    "plant_id": "Green-tek-A23997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "12:30:00 PM",
    "power": 0.77,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "8/23/2024 12:45",
    "plant_id": "Green-tek-A23998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "12:45:00 PM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "8/23/2024 13:00",
    "plant_id": "Green-tek-A23999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "1:00:00 PM",
    "power": 0.654,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "8/23/2024 13:15",
    "plant_id": "Green-tek-A24000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "1:15:00 PM",
    "power": 0.585,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "8/23/2024 13:30",
    "plant_id": "Green-tek-A24001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "1:30:00 PM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "8/23/2024 13:45",
    "plant_id": "Green-tek-A24002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "1:45:00 PM",
    "power": 0.44,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "8/23/2024 14:00",
    "plant_id": "Green-tek-A24003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "2:00:00 PM",
    "power": 0.37,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "8/23/2024 14:15",
    "plant_id": "Green-tek-A24004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "2:15:00 PM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "8/23/2024 14:30",
    "plant_id": "Green-tek-A24005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "2:30:00 PM",
    "power": 0.246,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "8/23/2024 14:45",
    "plant_id": "Green-tek-A24006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "2:45:00 PM",
    "power": 0.194,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "8/23/2024 15:00",
    "plant_id": "Green-tek-A24007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "3:00:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "8/23/2024 15:15",
    "plant_id": "Green-tek-A24008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "3:15:00 PM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "8/23/2024 15:30",
    "plant_id": "Green-tek-A24009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "3:30:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "8/23/2024 15:45",
    "plant_id": "Green-tek-A24010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/23/2024 16:00",
    "plant_id": "Green-tek-A24011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/23/2024 16:15",
    "plant_id": "Green-tek-A24012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/23/2024 16:30",
    "plant_id": "Green-tek-A24013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/23/2024 16:45",
    "plant_id": "Green-tek-A24014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/23/2024 17:00",
    "plant_id": "Green-tek-A24015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/23/2024 17:15",
    "plant_id": "Green-tek-A24016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/23/2024 17:30",
    "plant_id": "Green-tek-A24017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/23/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/24/2024 6:00",
    "plant_id": "Green-tek-A24018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/24/2024 6:15",
    "plant_id": "Green-tek-A24019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/24/2024 6:30",
    "plant_id": "Green-tek-A24020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/24/2024 6:45",
    "plant_id": "Green-tek-A24021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/24/2024 7:00",
    "plant_id": "Green-tek-A24022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/24/2024 7:15",
    "plant_id": "Green-tek-A24023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/24/2024 7:30",
    "plant_id": "Green-tek-A24024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "7:30:00 AM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/24/2024 7:45",
    "plant_id": "Green-tek-A24025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "7:45:00 AM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/24/2024 8:00",
    "plant_id": "Green-tek-A24026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "8:00:00 AM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/24/2024 8:15",
    "plant_id": "Green-tek-A24027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "8:15:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/24/2024 8:30",
    "plant_id": "Green-tek-A24028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "8:30:00 AM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/24/2024 8:45",
    "plant_id": "Green-tek-A24029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "8:45:00 AM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "8/24/2024 9:00",
    "plant_id": "Green-tek-A24030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "9:00:00 AM",
    "power": 0.282,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "8/24/2024 9:15",
    "plant_id": "Green-tek-A24031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "9:15:00 AM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "8/24/2024 9:30",
    "plant_id": "Green-tek-A24032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "9:30:00 AM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "8/24/2024 9:45",
    "plant_id": "Green-tek-A24033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "9:45:00 AM",
    "power": 0.505,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "8/24/2024 10:00",
    "plant_id": "Green-tek-A24034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "10:00:00 AM",
    "power": 0.588,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "8/24/2024 10:15",
    "plant_id": "Green-tek-A24035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "10:15:00 AM",
    "power": 0.672,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "8/24/2024 10:30",
    "plant_id": "Green-tek-A24036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "10:30:00 AM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "8/24/2024 10:45",
    "plant_id": "Green-tek-A24037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "10:45:00 AM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "8/24/2024 11:00",
    "plant_id": "Green-tek-A24038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "11:00:00 AM",
    "power": 0.885,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "8/24/2024 11:15",
    "plant_id": "Green-tek-A24039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "11:15:00 AM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "8/24/2024 11:30",
    "plant_id": "Green-tek-A24040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "11:30:00 AM",
    "power": 0.96,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "8/24/2024 11:45",
    "plant_id": "Green-tek-A24041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "11:45:00 AM",
    "power": 0.97,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "8/24/2024 12:00",
    "plant_id": "Green-tek-A24042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "12:00:00 PM",
    "power": 0.96,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "8/24/2024 12:15",
    "plant_id": "Green-tek-A24043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "12:15:00 PM",
    "power": 0.931,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "8/24/2024 12:30",
    "plant_id": "Green-tek-A24044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "12:30:00 PM",
    "power": 0.885,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "8/24/2024 12:45",
    "plant_id": "Green-tek-A24045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "12:45:00 PM",
    "power": 0.824,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "8/24/2024 13:00",
    "plant_id": "Green-tek-A24046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "1:00:00 PM",
    "power": 0.751,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "8/24/2024 13:15",
    "plant_id": "Green-tek-A24047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "1:15:00 PM",
    "power": 0.672,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14994,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "8/24/2024 13:30",
    "plant_id": "Green-tek-A24048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "1:30:00 PM",
    "power": 0.588,
    "Energy": 0.147,
    "output Energy": 0.147,
    "Ac output": 1.147,
    "dc output": 1.16994,
    "AC_R_i": 0.00019174,
    "AC_S_i": 0.000210914,
    "AC_T_i": 0.00023201,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0441002,
    "AC_S_pow": 0.04851022,
    "AC_T_Pow": 0.0533623,
    "AC_R_output": 0.3441,
    "AC_S_output": 0.37851,
    "AC_T_output": 0.416361,
    "dc_current": 0.00108088,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.14994,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000147,
    "carbon offset": 0.18375,
    "trees planted": 4.083333333,
    "DC_R_output": 0.113553,
    "DC_S_output": 0.1249083,
    "DC_T_output": 0.13739913
  },
  {
    "datetime": "8/24/2024 13:45",
    "plant_id": "Green-tek-A24049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "1:45:00 PM",
    "power": 0.505,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "8/24/2024 14:00",
    "plant_id": "Green-tek-A24050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "2:00:00 PM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "8/24/2024 14:15",
    "plant_id": "Green-tek-A24051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "2:15:00 PM",
    "power": 0.349,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "8/24/2024 14:30",
    "plant_id": "Green-tek-A24052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "2:30:00 PM",
    "power": 0.282,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "8/24/2024 14:45",
    "plant_id": "Green-tek-A24053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "2:45:00 PM",
    "power": 0.223,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "8/24/2024 15:00",
    "plant_id": "Green-tek-A24054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "3:00:00 PM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/24/2024 15:15",
    "plant_id": "Green-tek-A24055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "3:15:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/24/2024 15:30",
    "plant_id": "Green-tek-A24056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "3:30:00 PM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/24/2024 15:45",
    "plant_id": "Green-tek-A24057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "3:45:00 PM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/24/2024 16:00",
    "plant_id": "Green-tek-A24058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "4:00:00 PM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/24/2024 16:15",
    "plant_id": "Green-tek-A24059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/24/2024 16:30",
    "plant_id": "Green-tek-A24060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/24/2024 16:45",
    "plant_id": "Green-tek-A24061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/24/2024 17:00",
    "plant_id": "Green-tek-A24062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/24/2024 17:15",
    "plant_id": "Green-tek-A24063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/24/2024 17:30",
    "plant_id": "Green-tek-A24064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/24/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/25/2024 6:00",
    "plant_id": "Green-tek-A24065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/25/2024 6:15",
    "plant_id": "Green-tek-A24066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/25/2024 6:30",
    "plant_id": "Green-tek-A24067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/25/2024 6:45",
    "plant_id": "Green-tek-A24068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/25/2024 7:00",
    "plant_id": "Green-tek-A24069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/25/2024 7:15",
    "plant_id": "Green-tek-A24070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/25/2024 7:30",
    "plant_id": "Green-tek-A24071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/25/2024 7:45",
    "plant_id": "Green-tek-A24072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "8/25/2024 8:00",
    "plant_id": "Green-tek-A24073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "8:00:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "8/25/2024 8:15",
    "plant_id": "Green-tek-A24074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "8:15:00 AM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "8/25/2024 8:30",
    "plant_id": "Green-tek-A24075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "8:30:00 AM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "8/25/2024 8:45",
    "plant_id": "Green-tek-A24076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "8:45:00 AM",
    "power": 0.262,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "8/25/2024 9:00",
    "plant_id": "Green-tek-A24077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "9:00:00 AM",
    "power": 0.332,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "8/25/2024 9:15",
    "plant_id": "Green-tek-A24078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "9:15:00 AM",
    "power": 0.411,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "8/25/2024 9:30",
    "plant_id": "Green-tek-A24079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "9:30:00 AM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "8/25/2024 9:45",
    "plant_id": "Green-tek-A24080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "9:45:00 AM",
    "power": 0.594,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "8/25/2024 10:00",
    "plant_id": "Green-tek-A24081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "10:00:00 AM",
    "power": 0.692,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "8/25/2024 10:15",
    "plant_id": "Green-tek-A24082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "10:15:00 AM",
    "power": 0.79,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "8/25/2024 10:30",
    "plant_id": "Green-tek-A24083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "10:30:00 AM",
    "power": 0.884,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "8/25/2024 10:45",
    "plant_id": "Green-tek-A24084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "10:45:00 AM",
    "power": 0.969,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "8/25/2024 11:00",
    "plant_id": "Green-tek-A24085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "11:00:00 AM",
    "power": 1.04,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "8/25/2024 11:15",
    "plant_id": "Green-tek-A24086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "11:15:00 AM",
    "power": 1.095,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "8/25/2024 11:30",
    "plant_id": "Green-tek-A24087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "11:30:00 AM",
    "power": 1.129,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "8/25/2024 11:45",
    "plant_id": "Green-tek-A24088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "11:45:00 AM",
    "power": 1.141,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "8/25/2024 12:00",
    "plant_id": "Green-tek-A24089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "12:00:00 PM",
    "power": 1.129,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "8/25/2024 12:15",
    "plant_id": "Green-tek-A24090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "12:15:00 PM",
    "power": 1.095,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "8/25/2024 12:30",
    "plant_id": "Green-tek-A24091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "12:30:00 PM",
    "power": 1.04,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "8/25/2024 12:45",
    "plant_id": "Green-tek-A24092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "12:45:00 PM",
    "power": 0.969,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "8/25/2024 13:00",
    "plant_id": "Green-tek-A24093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "1:00:00 PM",
    "power": 0.884,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "8/25/2024 13:15",
    "plant_id": "Green-tek-A24094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "1:15:00 PM",
    "power": 0.79,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "8/25/2024 13:30",
    "plant_id": "Green-tek-A24095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "1:30:00 PM",
    "power": 0.692,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "8/25/2024 13:45",
    "plant_id": "Green-tek-A24096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "1:45:00 PM",
    "power": 0.594,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "8/25/2024 14:00",
    "plant_id": "Green-tek-A24097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "2:00:00 PM",
    "power": 0.499,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "8/25/2024 14:15",
    "plant_id": "Green-tek-A24098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "2:15:00 PM",
    "power": 0.411,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "8/25/2024 14:30",
    "plant_id": "Green-tek-A24099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "2:30:00 PM",
    "power": 0.332,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "8/25/2024 14:45",
    "plant_id": "Green-tek-A24100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "2:45:00 PM",
    "power": 0.262,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "8/25/2024 15:00",
    "plant_id": "Green-tek-A24101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "3:00:00 PM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "8/25/2024 15:15",
    "plant_id": "Green-tek-A24102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "3:15:00 PM",
    "power": 0.154,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "8/25/2024 15:30",
    "plant_id": "Green-tek-A24103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "3:30:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "8/25/2024 15:45",
    "plant_id": "Green-tek-A24104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "8/25/2024 16:00",
    "plant_id": "Green-tek-A24105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/25/2024 16:15",
    "plant_id": "Green-tek-A24106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/25/2024 16:30",
    "plant_id": "Green-tek-A24107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/25/2024 16:45",
    "plant_id": "Green-tek-A24108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/25/2024 17:00",
    "plant_id": "Green-tek-A24109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/25/2024 17:15",
    "plant_id": "Green-tek-A24110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/25/2024 17:30",
    "plant_id": "Green-tek-A24111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/25/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/26/2024 6:00",
    "plant_id": "Green-tek-A24112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/26/2024 6:15",
    "plant_id": "Green-tek-A24113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/26/2024 6:30",
    "plant_id": "Green-tek-A24114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/26/2024 6:45",
    "plant_id": "Green-tek-A24115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/26/2024 7:00",
    "plant_id": "Green-tek-A24116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/26/2024 7:15",
    "plant_id": "Green-tek-A24117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/26/2024 7:30",
    "plant_id": "Green-tek-A24118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/26/2024 7:45",
    "plant_id": "Green-tek-A24119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "7:45:00 AM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/26/2024 8:00",
    "plant_id": "Green-tek-A24120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "8:00:00 AM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "8/26/2024 8:15",
    "plant_id": "Green-tek-A24121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "8:15:00 AM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "8/26/2024 8:30",
    "plant_id": "Green-tek-A24122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "8:30:00 AM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/26/2024 8:45",
    "plant_id": "Green-tek-A24123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "8:45:00 AM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/26/2024 9:00",
    "plant_id": "Green-tek-A24124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "9:00:00 AM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "8/26/2024 9:15",
    "plant_id": "Green-tek-A24125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "9:15:00 AM",
    "power": 0.358,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "8/26/2024 9:30",
    "plant_id": "Green-tek-A24126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "9:30:00 AM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "8/26/2024 9:45",
    "plant_id": "Green-tek-A24127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "9:45:00 AM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "8/26/2024 10:00",
    "plant_id": "Green-tek-A24128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "10:00:00 AM",
    "power": 0.603,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "8/26/2024 10:15",
    "plant_id": "Green-tek-A24129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "10:15:00 AM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "8/26/2024 10:30",
    "plant_id": "Green-tek-A24130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "10:30:00 AM",
    "power": 0.77,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "8/26/2024 10:45",
    "plant_id": "Green-tek-A24131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "10:45:00 AM",
    "power": 0.844,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "8/26/2024 11:00",
    "plant_id": "Green-tek-A24132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "11:00:00 AM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "8/26/2024 11:15",
    "plant_id": "Green-tek-A24133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "11:15:00 AM",
    "power": 0.954,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "8/26/2024 11:30",
    "plant_id": "Green-tek-A24134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "11:30:00 AM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "8/26/2024 11:45",
    "plant_id": "Green-tek-A24135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "11:45:00 AM",
    "power": 0.994,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "8/26/2024 12:00",
    "plant_id": "Green-tek-A24136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "12:00:00 PM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "8/26/2024 12:15",
    "plant_id": "Green-tek-A24137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "12:15:00 PM",
    "power": 0.954,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "8/26/2024 12:30",
    "plant_id": "Green-tek-A24138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "12:30:00 PM",
    "power": 0.907,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "8/26/2024 12:45",
    "plant_id": "Green-tek-A24139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "12:45:00 PM",
    "power": 0.844,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "8/26/2024 13:00",
    "plant_id": "Green-tek-A24140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "1:00:00 PM",
    "power": 0.77,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "8/26/2024 13:15",
    "plant_id": "Green-tek-A24141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "1:15:00 PM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "8/26/2024 13:30",
    "plant_id": "Green-tek-A24142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "1:30:00 PM",
    "power": 0.603,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "8/26/2024 13:45",
    "plant_id": "Green-tek-A24143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "1:45:00 PM",
    "power": 0.517,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "8/26/2024 14:00",
    "plant_id": "Green-tek-A24144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "2:00:00 PM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "8/26/2024 14:15",
    "plant_id": "Green-tek-A24145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "2:15:00 PM",
    "power": 0.358,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "8/26/2024 14:30",
    "plant_id": "Green-tek-A24146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "2:30:00 PM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "8/26/2024 14:45",
    "plant_id": "Green-tek-A24147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "2:45:00 PM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "8/26/2024 15:00",
    "plant_id": "Green-tek-A24148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "3:00:00 PM",
    "power": 0.177,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "8/26/2024 15:15",
    "plant_id": "Green-tek-A24149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "3:15:00 PM",
    "power": 0.135,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "8/26/2024 15:30",
    "plant_id": "Green-tek-A24150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "3:30:00 PM",
    "power": 0.1,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "8/26/2024 15:45",
    "plant_id": "Green-tek-A24151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "3:45:00 PM",
    "power": 0.073,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/26/2024 16:00",
    "plant_id": "Green-tek-A24152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/26/2024 16:15",
    "plant_id": "Green-tek-A24153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/26/2024 16:30",
    "plant_id": "Green-tek-A24154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/26/2024 16:45",
    "plant_id": "Green-tek-A24155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/26/2024 17:00",
    "plant_id": "Green-tek-A24156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/26/2024 17:15",
    "plant_id": "Green-tek-A24157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/26/2024 17:30",
    "plant_id": "Green-tek-A24158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/26/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/27/2024 6:00",
    "plant_id": "Green-tek-A24159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/27/2024 6:15",
    "plant_id": "Green-tek-A24160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/27/2024 6:30",
    "plant_id": "Green-tek-A24161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/27/2024 6:45",
    "plant_id": "Green-tek-A24162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/27/2024 7:00",
    "plant_id": "Green-tek-A24163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/27/2024 7:15",
    "plant_id": "Green-tek-A24164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/27/2024 7:30",
    "plant_id": "Green-tek-A24165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "7:30:00 AM",
    "power": 0.054,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/27/2024 7:45",
    "plant_id": "Green-tek-A24166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "7:45:00 AM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/27/2024 8:00",
    "plant_id": "Green-tek-A24167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "8:00:00 AM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/27/2024 8:15",
    "plant_id": "Green-tek-A24168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "8:15:00 AM",
    "power": 0.139,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/27/2024 8:30",
    "plant_id": "Green-tek-A24169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "8:30:00 AM",
    "power": 0.183,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "8/27/2024 8:45",
    "plant_id": "Green-tek-A24170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "8:45:00 AM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "8/27/2024 9:00",
    "plant_id": "Green-tek-A24171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "9:00:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "8/27/2024 9:15",
    "plant_id": "Green-tek-A24172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "9:15:00 AM",
    "power": 0.371,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "8/27/2024 9:30",
    "plant_id": "Green-tek-A24173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "9:30:00 AM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "8/27/2024 9:45",
    "plant_id": "Green-tek-A24174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "9:45:00 AM",
    "power": 0.535,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "8/27/2024 10:00",
    "plant_id": "Green-tek-A24175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "10:00:00 AM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "8/27/2024 10:15",
    "plant_id": "Green-tek-A24176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "10:15:00 AM",
    "power": 0.713,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "8/27/2024 10:30",
    "plant_id": "Green-tek-A24177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "10:30:00 AM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "8/27/2024 10:45",
    "plant_id": "Green-tek-A24178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "10:45:00 AM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "8/27/2024 11:00",
    "plant_id": "Green-tek-A24179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "11:00:00 AM",
    "power": 0.939,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "8/27/2024 11:15",
    "plant_id": "Green-tek-A24180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "11:15:00 AM",
    "power": 0.988,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "8/27/2024 11:30",
    "plant_id": "Green-tek-A24181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "11:30:00 AM",
    "power": 1.018,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "8/27/2024 11:45",
    "plant_id": "Green-tek-A24182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "11:45:00 AM",
    "power": 1.029,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "8/27/2024 12:00",
    "plant_id": "Green-tek-A24183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "12:00:00 PM",
    "power": 1.018,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "8/27/2024 12:15",
    "plant_id": "Green-tek-A24184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "12:15:00 PM",
    "power": 0.988,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "8/27/2024 12:30",
    "plant_id": "Green-tek-A24185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "12:30:00 PM",
    "power": 0.939,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "8/27/2024 12:45",
    "plant_id": "Green-tek-A24186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "12:45:00 PM",
    "power": 0.874,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "8/27/2024 13:00",
    "plant_id": "Green-tek-A24187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "1:00:00 PM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.18156,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "8/27/2024 13:15",
    "plant_id": "Green-tek-A24188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "1:15:00 PM",
    "power": 0.713,
    "Energy": 0.178,
    "output Energy": 0.178,
    "Ac output": 1.178,
    "dc output": 1.20156,
    "AC_R_i": 0.00023217,
    "AC_S_i": 0.000255387,
    "AC_T_i": 0.00028093,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0533991,
    "AC_S_pow": 0.05873901,
    "AC_T_Pow": 0.0646139,
    "AC_R_output": 0.3534,
    "AC_S_output": 0.38874,
    "AC_T_output": 0.427614,
    "dc_current": 0.00130882,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.18156,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000178,
    "carbon offset": 0.2225,
    "trees planted": 4.944444444,
    "DC_R_output": 0.116622,
    "DC_S_output": 0.1282842,
    "DC_T_output": 0.14111262
  },
  {
    "datetime": "8/27/2024 13:30",
    "plant_id": "Green-tek-A24189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "1:30:00 PM",
    "power": 0.624,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "8/27/2024 13:45",
    "plant_id": "Green-tek-A24190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "1:45:00 PM",
    "power": 0.535,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "8/27/2024 14:00",
    "plant_id": "Green-tek-A24191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "2:00:00 PM",
    "power": 0.45,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "8/27/2024 14:15",
    "plant_id": "Green-tek-A24192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "2:15:00 PM",
    "power": 0.371,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "8/27/2024 14:30",
    "plant_id": "Green-tek-A24193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "2:30:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "8/27/2024 14:45",
    "plant_id": "Green-tek-A24194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "2:45:00 PM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "8/27/2024 15:00",
    "plant_id": "Green-tek-A24195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "3:00:00 PM",
    "power": 0.183,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "8/27/2024 15:15",
    "plant_id": "Green-tek-A24196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "3:15:00 PM",
    "power": 0.139,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "8/27/2024 15:30",
    "plant_id": "Green-tek-A24197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "3:30:00 PM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "8/27/2024 15:45",
    "plant_id": "Green-tek-A24198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "3:45:00 PM",
    "power": 0.075,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "8/27/2024 16:00",
    "plant_id": "Green-tek-A24199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "4:00:00 PM",
    "power": 0.054,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/27/2024 16:15",
    "plant_id": "Green-tek-A24200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/27/2024 16:30",
    "plant_id": "Green-tek-A24201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/27/2024 16:45",
    "plant_id": "Green-tek-A24202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/27/2024 17:00",
    "plant_id": "Green-tek-A24203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/27/2024 17:15",
    "plant_id": "Green-tek-A24204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/27/2024 17:30",
    "plant_id": "Green-tek-A24205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/27/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/28/2024 6:00",
    "plant_id": "Green-tek-A24206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/28/2024 6:15",
    "plant_id": "Green-tek-A24207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/28/2024 6:30",
    "plant_id": "Green-tek-A24208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/28/2024 6:45",
    "plant_id": "Green-tek-A24209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/28/2024 7:00",
    "plant_id": "Green-tek-A24210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/28/2024 7:15",
    "plant_id": "Green-tek-A24211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/28/2024 7:30",
    "plant_id": "Green-tek-A24212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/28/2024 7:45",
    "plant_id": "Green-tek-A24213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "7:45:00 AM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/28/2024 8:00",
    "plant_id": "Green-tek-A24214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "8:00:00 AM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/28/2024 8:15",
    "plant_id": "Green-tek-A24215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "8:15:00 AM",
    "power": 0.13,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/28/2024 8:30",
    "plant_id": "Green-tek-A24216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "8:30:00 AM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/28/2024 8:45",
    "plant_id": "Green-tek-A24217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "8:45:00 AM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "8/28/2024 9:00",
    "plant_id": "Green-tek-A24218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "9:00:00 AM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "8/28/2024 9:15",
    "plant_id": "Green-tek-A24219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "9:15:00 AM",
    "power": 0.347,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "8/28/2024 9:30",
    "plant_id": "Green-tek-A24220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "9:30:00 AM",
    "power": 0.421,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "8/28/2024 9:45",
    "plant_id": "Green-tek-A24221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "9:45:00 AM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "8/28/2024 10:00",
    "plant_id": "Green-tek-A24222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "10:00:00 AM",
    "power": 0.584,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "8/28/2024 10:15",
    "plant_id": "Green-tek-A24223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "10:15:00 AM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "8/28/2024 10:30",
    "plant_id": "Green-tek-A24224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "10:30:00 AM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "8/28/2024 10:45",
    "plant_id": "Green-tek-A24225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "10:45:00 AM",
    "power": 0.818,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "8/28/2024 11:00",
    "plant_id": "Green-tek-A24226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "11:00:00 AM",
    "power": 0.878,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "8/28/2024 11:15",
    "plant_id": "Green-tek-A24227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "11:15:00 AM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "8/28/2024 11:30",
    "plant_id": "Green-tek-A24228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "11:30:00 AM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "8/28/2024 11:45",
    "plant_id": "Green-tek-A24229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "11:45:00 AM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "8/28/2024 12:00",
    "plant_id": "Green-tek-A24230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "12:00:00 PM",
    "power": 0.953,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "8/28/2024 12:15",
    "plant_id": "Green-tek-A24231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "12:15:00 PM",
    "power": 0.924,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "8/28/2024 12:30",
    "plant_id": "Green-tek-A24232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "12:30:00 PM",
    "power": 0.878,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "8/28/2024 12:45",
    "plant_id": "Green-tek-A24233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "12:45:00 PM",
    "power": 0.818,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "8/28/2024 13:00",
    "plant_id": "Green-tek-A24234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "1:00:00 PM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "8/28/2024 13:15",
    "plant_id": "Green-tek-A24235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "1:15:00 PM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "8/28/2024 13:30",
    "plant_id": "Green-tek-A24236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "1:30:00 PM",
    "power": 0.584,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "8/28/2024 13:45",
    "plant_id": "Green-tek-A24237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "1:45:00 PM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "8/28/2024 14:00",
    "plant_id": "Green-tek-A24238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "2:00:00 PM",
    "power": 0.421,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "8/28/2024 14:15",
    "plant_id": "Green-tek-A24239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "2:15:00 PM",
    "power": 0.347,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "8/28/2024 14:30",
    "plant_id": "Green-tek-A24240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "2:30:00 PM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "8/28/2024 14:45",
    "plant_id": "Green-tek-A24241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "2:45:00 PM",
    "power": 0.221,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "8/28/2024 15:00",
    "plant_id": "Green-tek-A24242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "3:00:00 PM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "8/28/2024 15:15",
    "plant_id": "Green-tek-A24243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "3:15:00 PM",
    "power": 0.13,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "8/28/2024 15:30",
    "plant_id": "Green-tek-A24244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "3:30:00 PM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "8/28/2024 15:45",
    "plant_id": "Green-tek-A24245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "3:45:00 PM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "8/28/2024 16:00",
    "plant_id": "Green-tek-A24246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "8/28/2024 16:15",
    "plant_id": "Green-tek-A24247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "8/28/2024 16:30",
    "plant_id": "Green-tek-A24248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "8/28/2024 16:45",
    "plant_id": "Green-tek-A24249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "8/28/2024 17:00",
    "plant_id": "Green-tek-A24250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/28/2024 17:15",
    "plant_id": "Green-tek-A24251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/28/2024 17:30",
    "plant_id": "Green-tek-A24252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/28/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/29/2024 6:00",
    "plant_id": "Green-tek-A24253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/29/2024 6:15",
    "plant_id": "Green-tek-A24254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/29/2024 6:30",
    "plant_id": "Green-tek-A24255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/29/2024 6:45",
    "plant_id": "Green-tek-A24256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/29/2024 7:00",
    "plant_id": "Green-tek-A24257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/29/2024 7:15",
    "plant_id": "Green-tek-A24258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/29/2024 7:30",
    "plant_id": "Green-tek-A24259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "7:30:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/29/2024 7:45",
    "plant_id": "Green-tek-A24260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "7:45:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/29/2024 8:00",
    "plant_id": "Green-tek-A24261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "8:00:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "8/29/2024 8:15",
    "plant_id": "Green-tek-A24262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "8:15:00 AM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "8/29/2024 8:30",
    "plant_id": "Green-tek-A24263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "8:30:00 AM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "8/29/2024 8:45",
    "plant_id": "Green-tek-A24264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "8:45:00 AM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/29/2024 9:00",
    "plant_id": "Green-tek-A24265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "9:00:00 AM",
    "power": 0.236,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "8/29/2024 9:15",
    "plant_id": "Green-tek-A24266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "9:15:00 AM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "8/29/2024 9:30",
    "plant_id": "Green-tek-A24267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "9:30:00 AM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "8/29/2024 9:45",
    "plant_id": "Green-tek-A24268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "9:45:00 AM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "8/29/2024 10:00",
    "plant_id": "Green-tek-A24269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "10:00:00 AM",
    "power": 0.492,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "8/29/2024 10:15",
    "plant_id": "Green-tek-A24270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "10:15:00 AM",
    "power": 0.562,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "8/29/2024 10:30",
    "plant_id": "Green-tek-A24271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "10:30:00 AM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "8/29/2024 10:45",
    "plant_id": "Green-tek-A24272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "10:45:00 AM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "8/29/2024 11:00",
    "plant_id": "Green-tek-A24273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "11:00:00 AM",
    "power": 0.74,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "8/29/2024 11:15",
    "plant_id": "Green-tek-A24274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "11:15:00 AM",
    "power": 0.778,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "8/29/2024 11:30",
    "plant_id": "Green-tek-A24275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "11:30:00 AM",
    "power": 0.803,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "8/29/2024 11:45",
    "plant_id": "Green-tek-A24276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "11:45:00 AM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "8/29/2024 12:00",
    "plant_id": "Green-tek-A24277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "12:00:00 PM",
    "power": 0.803,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "8/29/2024 12:15",
    "plant_id": "Green-tek-A24278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "12:15:00 PM",
    "power": 0.778,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "8/29/2024 12:30",
    "plant_id": "Green-tek-A24279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "12:30:00 PM",
    "power": 0.74,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "8/29/2024 12:45",
    "plant_id": "Green-tek-A24280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "12:45:00 PM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "8/29/2024 13:00",
    "plant_id": "Green-tek-A24281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "1:00:00 PM",
    "power": 0.628,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "8/29/2024 13:15",
    "plant_id": "Green-tek-A24282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "1:15:00 PM",
    "power": 0.562,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "8/29/2024 13:30",
    "plant_id": "Green-tek-A24283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "1:30:00 PM",
    "power": 0.492,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "8/29/2024 13:45",
    "plant_id": "Green-tek-A24284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "1:45:00 PM",
    "power": 0.422,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "8/29/2024 14:00",
    "plant_id": "Green-tek-A24285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "2:00:00 PM",
    "power": 0.355,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "8/29/2024 14:15",
    "plant_id": "Green-tek-A24286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "2:15:00 PM",
    "power": 0.292,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "8/29/2024 14:30",
    "plant_id": "Green-tek-A24287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "2:30:00 PM",
    "power": 0.236,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "8/29/2024 14:45",
    "plant_id": "Green-tek-A24288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "2:45:00 PM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "8/29/2024 15:00",
    "plant_id": "Green-tek-A24289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "3:00:00 PM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "8/29/2024 15:15",
    "plant_id": "Green-tek-A24290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "3:15:00 PM",
    "power": 0.11,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "8/29/2024 15:30",
    "plant_id": "Green-tek-A24291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "3:30:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "8/29/2024 15:45",
    "plant_id": "Green-tek-A24292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "3:45:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "8/29/2024 16:00",
    "plant_id": "Green-tek-A24293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "4:00:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/29/2024 16:15",
    "plant_id": "Green-tek-A24294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/29/2024 16:30",
    "plant_id": "Green-tek-A24295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/29/2024 16:45",
    "plant_id": "Green-tek-A24296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/29/2024 17:00",
    "plant_id": "Green-tek-A24297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/29/2024 17:15",
    "plant_id": "Green-tek-A24298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/29/2024 17:30",
    "plant_id": "Green-tek-A24299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/29/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/30/2024 6:00",
    "plant_id": "Green-tek-A24300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/30/2024 6:15",
    "plant_id": "Green-tek-A24301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/30/2024 6:30",
    "plant_id": "Green-tek-A24302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/30/2024 6:45",
    "plant_id": "Green-tek-A24303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/30/2024 7:00",
    "plant_id": "Green-tek-A24304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/30/2024 7:15",
    "plant_id": "Green-tek-A24305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/30/2024 7:30",
    "plant_id": "Green-tek-A24306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/30/2024 7:45",
    "plant_id": "Green-tek-A24307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "7:45:00 AM",
    "power": 0.078,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "8/30/2024 8:00",
    "plant_id": "Green-tek-A24308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "8:00:00 AM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "8/30/2024 8:15",
    "plant_id": "Green-tek-A24309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "8:15:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "8/30/2024 8:30",
    "plant_id": "Green-tek-A24310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "8:30:00 AM",
    "power": 0.19,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "8/30/2024 8:45",
    "plant_id": "Green-tek-A24311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "8:45:00 AM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "8/30/2024 9:00",
    "plant_id": "Green-tek-A24312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "9:00:00 AM",
    "power": 0.31,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "8/30/2024 9:15",
    "plant_id": "Green-tek-A24313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "9:15:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "8/30/2024 9:30",
    "plant_id": "Green-tek-A24314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "9:30:00 AM",
    "power": 0.467,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "8/30/2024 9:45",
    "plant_id": "Green-tek-A24315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "9:45:00 AM",
    "power": 0.555,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "8/30/2024 10:00",
    "plant_id": "Green-tek-A24316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "10:00:00 AM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "8/30/2024 10:15",
    "plant_id": "Green-tek-A24317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "10:15:00 AM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "8/30/2024 10:30",
    "plant_id": "Green-tek-A24318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "10:30:00 AM",
    "power": 0.826,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "8/30/2024 10:45",
    "plant_id": "Green-tek-A24319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "10:45:00 AM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "8/30/2024 11:00",
    "plant_id": "Green-tek-A24320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "11:00:00 AM",
    "power": 0.973,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "8/30/2024 11:15",
    "plant_id": "Green-tek-A24321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "11:15:00 AM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "8/30/2024 11:30",
    "plant_id": "Green-tek-A24322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "11:30:00 AM",
    "power": 1.056,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "8/30/2024 11:45",
    "plant_id": "Green-tek-A24323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "11:45:00 AM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "8/30/2024 12:00",
    "plant_id": "Green-tek-A24324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "12:00:00 PM",
    "power": 1.056,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "8/30/2024 12:15",
    "plant_id": "Green-tek-A24325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "12:15:00 PM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "8/30/2024 12:30",
    "plant_id": "Green-tek-A24326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "12:30:00 PM",
    "power": 0.973,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "8/30/2024 12:45",
    "plant_id": "Green-tek-A24327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "12:45:00 PM",
    "power": 0.906,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "8/30/2024 13:00",
    "plant_id": "Green-tek-A24328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "1:00:00 PM",
    "power": 0.826,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.1887,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "8/30/2024 13:15",
    "plant_id": "Green-tek-A24329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "1:15:00 PM",
    "power": 0.739,
    "Energy": 0.185,
    "output Energy": 0.185,
    "Ac output": 1.185,
    "dc output": 1.2087,
    "AC_R_i": 0.0002413,
    "AC_S_i": 0.00026543,
    "AC_T_i": 0.00029197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.055499,
    "AC_S_pow": 0.0610489,
    "AC_T_Pow": 0.0671531,
    "AC_R_output": 0.3555,
    "AC_S_output": 0.39105,
    "AC_T_output": 0.430155,
    "dc_current": 0.00136029,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.1887,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000185,
    "carbon offset": 0.23125,
    "trees planted": 5.138888889,
    "DC_R_output": 0.117315,
    "DC_S_output": 0.1290465,
    "DC_T_output": 0.14195115
  },
  {
    "datetime": "8/30/2024 13:30",
    "plant_id": "Green-tek-A24330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "1:30:00 PM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "8/30/2024 13:45",
    "plant_id": "Green-tek-A24331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "1:45:00 PM",
    "power": 0.555,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "8/30/2024 14:00",
    "plant_id": "Green-tek-A24332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "2:00:00 PM",
    "power": 0.467,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "8/30/2024 14:15",
    "plant_id": "Green-tek-A24333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "2:15:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "8/30/2024 14:30",
    "plant_id": "Green-tek-A24334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "2:30:00 PM",
    "power": 0.31,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "8/30/2024 14:45",
    "plant_id": "Green-tek-A24335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "2:45:00 PM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "8/30/2024 15:00",
    "plant_id": "Green-tek-A24336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "3:00:00 PM",
    "power": 0.19,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "8/30/2024 15:15",
    "plant_id": "Green-tek-A24337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "3:15:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "8/30/2024 15:30",
    "plant_id": "Green-tek-A24338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "3:30:00 PM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "8/30/2024 15:45",
    "plant_id": "Green-tek-A24339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "3:45:00 PM",
    "power": 0.078,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "8/30/2024 16:00",
    "plant_id": "Green-tek-A24340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "8/30/2024 16:15",
    "plant_id": "Green-tek-A24341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "8/30/2024 16:30",
    "plant_id": "Green-tek-A24342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "8/30/2024 16:45",
    "plant_id": "Green-tek-A24343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/30/2024 17:00",
    "plant_id": "Green-tek-A24344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/30/2024 17:15",
    "plant_id": "Green-tek-A24345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/30/2024 17:30",
    "plant_id": "Green-tek-A24346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/30/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/31/2024 6:00",
    "plant_id": "Green-tek-A24347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "8/31/2024 6:15",
    "plant_id": "Green-tek-A24348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/31/2024 6:30",
    "plant_id": "Green-tek-A24349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/31/2024 6:45",
    "plant_id": "Green-tek-A24350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/31/2024 7:00",
    "plant_id": "Green-tek-A24351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/31/2024 7:15",
    "plant_id": "Green-tek-A24352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/31/2024 7:30",
    "plant_id": "Green-tek-A24353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/31/2024 7:45",
    "plant_id": "Green-tek-A24354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "7:45:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/31/2024 8:00",
    "plant_id": "Green-tek-A24355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "8:00:00 AM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "8/31/2024 8:15",
    "plant_id": "Green-tek-A24356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "8:15:00 AM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "8/31/2024 8:30",
    "plant_id": "Green-tek-A24357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "8:30:00 AM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "8/31/2024 8:45",
    "plant_id": "Green-tek-A24358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "8:45:00 AM",
    "power": 0.284,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "8/31/2024 9:00",
    "plant_id": "Green-tek-A24359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "9:00:00 AM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "8/31/2024 9:15",
    "plant_id": "Green-tek-A24360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "9:15:00 AM",
    "power": 0.445,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "8/31/2024 9:30",
    "plant_id": "Green-tek-A24361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "9:30:00 AM",
    "power": 0.541,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "8/31/2024 9:45",
    "plant_id": "Green-tek-A24362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "9:45:00 AM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "8/31/2024 10:00",
    "plant_id": "Green-tek-A24363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "10:00:00 AM",
    "power": 0.749,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "8/31/2024 10:15",
    "plant_id": "Green-tek-A24364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "10:15:00 AM",
    "power": 0.856,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "8/31/2024 10:30",
    "plant_id": "Green-tek-A24365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "10:30:00 AM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "8/31/2024 10:45",
    "plant_id": "Green-tek-A24366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "10:45:00 AM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "8/31/2024 11:00",
    "plant_id": "Green-tek-A24367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "11:00:00 AM",
    "power": 1.127,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "8/31/2024 11:15",
    "plant_id": "Green-tek-A24368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "11:15:00 AM",
    "power": 1.186,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "8/31/2024 11:30",
    "plant_id": "Green-tek-A24369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "11:30:00 AM",
    "power": 1.223,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "8/31/2024 11:45",
    "plant_id": "Green-tek-A24370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "11:45:00 AM",
    "power": 1.235,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "8/31/2024 12:00",
    "plant_id": "Green-tek-A24371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "12:00:00 PM",
    "power": 1.223,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.30192,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "8/31/2024 12:15",
    "plant_id": "Green-tek-A24372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "12:15:00 PM",
    "power": 1.186,
    "Energy": 0.296,
    "output Energy": 0.296,
    "Ac output": 1.296,
    "dc output": 1.32192,
    "AC_R_i": 0.00038609,
    "AC_S_i": 0.000424699,
    "AC_T_i": 0.00046717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0888007,
    "AC_S_pow": 0.09768077,
    "AC_T_Pow": 0.1074491,
    "AC_R_output": 0.3888,
    "AC_S_output": 0.42768,
    "AC_T_output": 0.470448,
    "dc_current": 0.00217647,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.30192,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000296,
    "carbon offset": 0.37,
    "trees planted": 8.222222222,
    "DC_R_output": 0.128304,
    "DC_S_output": 0.1411344,
    "DC_T_output": 0.15524784
  },
  {
    "datetime": "8/31/2024 12:30",
    "plant_id": "Green-tek-A24373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "12:30:00 PM",
    "power": 1.127,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "8/31/2024 12:45",
    "plant_id": "Green-tek-A24374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "12:45:00 PM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "8/31/2024 13:00",
    "plant_id": "Green-tek-A24375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "1:00:00 PM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "8/31/2024 13:15",
    "plant_id": "Green-tek-A24376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "1:15:00 PM",
    "power": 0.856,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "8/31/2024 13:30",
    "plant_id": "Green-tek-A24377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "1:30:00 PM",
    "power": 0.749,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "8/31/2024 13:45",
    "plant_id": "Green-tek-A24378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "1:45:00 PM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "8/31/2024 14:00",
    "plant_id": "Green-tek-A24379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "2:00:00 PM",
    "power": 0.541,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "8/31/2024 14:15",
    "plant_id": "Green-tek-A24380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "2:15:00 PM",
    "power": 0.445,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "8/31/2024 14:30",
    "plant_id": "Green-tek-A24381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "2:30:00 PM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "8/31/2024 14:45",
    "plant_id": "Green-tek-A24382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "2:45:00 PM",
    "power": 0.284,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "8/31/2024 15:00",
    "plant_id": "Green-tek-A24383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "3:00:00 PM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "8/31/2024 15:15",
    "plant_id": "Green-tek-A24384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "3:15:00 PM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "8/31/2024 15:30",
    "plant_id": "Green-tek-A24385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "3:30:00 PM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "8/31/2024 15:45",
    "plant_id": "Green-tek-A24386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "3:45:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "8/31/2024 16:00",
    "plant_id": "Green-tek-A24387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "8/31/2024 16:15",
    "plant_id": "Green-tek-A24388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "8/31/2024 16:30",
    "plant_id": "Green-tek-A24389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "8/31/2024 16:45",
    "plant_id": "Green-tek-A24390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "8/31/2024 17:00",
    "plant_id": "Green-tek-A24391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "8/31/2024 17:15",
    "plant_id": "Green-tek-A24392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "8/31/2024 17:30",
    "plant_id": "Green-tek-A24393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "8/31/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/1/2024 6:00",
    "plant_id": "Green-tek-A24394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/1/2024 6:15",
    "plant_id": "Green-tek-A24395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/1/2024 6:30",
    "plant_id": "Green-tek-A24396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/1/2024 6:45",
    "plant_id": "Green-tek-A24397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/1/2024 7:00",
    "plant_id": "Green-tek-A24398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/1/2024 7:15",
    "plant_id": "Green-tek-A24399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/1/2024 7:30",
    "plant_id": "Green-tek-A24400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "9/1/2024 7:45",
    "plant_id": "Green-tek-A24401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "9/1/2024 8:00",
    "plant_id": "Green-tek-A24402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "8:00:00 AM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "9/1/2024 8:15",
    "plant_id": "Green-tek-A24403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "8:15:00 AM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "9/1/2024 8:30",
    "plant_id": "Green-tek-A24404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "8:30:00 AM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "9/1/2024 8:45",
    "plant_id": "Green-tek-A24405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "8:45:00 AM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "9/1/2024 9:00",
    "plant_id": "Green-tek-A24406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "9:00:00 AM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "9/1/2024 9:15",
    "plant_id": "Green-tek-A24407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "9:15:00 AM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "9/1/2024 9:30",
    "plant_id": "Green-tek-A24408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "9:30:00 AM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "9/1/2024 9:45",
    "plant_id": "Green-tek-A24409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "9:45:00 AM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "9/1/2024 10:00",
    "plant_id": "Green-tek-A24410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "10:00:00 AM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "9/1/2024 10:15",
    "plant_id": "Green-tek-A24411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "10:15:00 AM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "9/1/2024 10:30",
    "plant_id": "Green-tek-A24412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "10:30:00 AM",
    "power": 0.814,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "9/1/2024 10:45",
    "plant_id": "Green-tek-A24413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "10:45:00 AM",
    "power": 0.892,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "9/1/2024 11:00",
    "plant_id": "Green-tek-A24414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "11:00:00 AM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "9/1/2024 11:15",
    "plant_id": "Green-tek-A24415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "11:15:00 AM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "9/1/2024 11:30",
    "plant_id": "Green-tek-A24416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "11:30:00 AM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "9/1/2024 11:45",
    "plant_id": "Green-tek-A24417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "11:45:00 AM",
    "power": 1.05,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "9/1/2024 12:00",
    "plant_id": "Green-tek-A24418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "12:00:00 PM",
    "power": 1.039,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "9/1/2024 12:15",
    "plant_id": "Green-tek-A24419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "12:15:00 PM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "9/1/2024 12:30",
    "plant_id": "Green-tek-A24420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "12:30:00 PM",
    "power": 0.958,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "9/1/2024 12:45",
    "plant_id": "Green-tek-A24421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "12:45:00 PM",
    "power": 0.892,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "9/1/2024 13:00",
    "plant_id": "Green-tek-A24422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "1:00:00 PM",
    "power": 0.814,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "9/1/2024 13:15",
    "plant_id": "Green-tek-A24423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "1:15:00 PM",
    "power": 0.727,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.16218,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "9/1/2024 13:30",
    "plant_id": "Green-tek-A24424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "1:30:00 PM",
    "power": 0.637,
    "Energy": 0.159,
    "output Energy": 0.159,
    "Ac output": 1.159,
    "dc output": 1.18218,
    "AC_R_i": 0.00020739,
    "AC_S_i": 0.000228129,
    "AC_T_i": 0.00025094,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0476997,
    "AC_S_pow": 0.05246967,
    "AC_T_Pow": 0.0577162,
    "AC_R_output": 0.3477,
    "AC_S_output": 0.38247,
    "AC_T_output": 0.420717,
    "dc_current": 0.00116912,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.16218,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000159,
    "carbon offset": 0.19875,
    "trees planted": 4.416666667,
    "DC_R_output": 0.114741,
    "DC_S_output": 0.1262151,
    "DC_T_output": 0.13883661
  },
  {
    "datetime": "9/1/2024 13:45",
    "plant_id": "Green-tek-A24425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "1:45:00 PM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "9/1/2024 14:00",
    "plant_id": "Green-tek-A24426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "2:00:00 PM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "9/1/2024 14:15",
    "plant_id": "Green-tek-A24427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "2:15:00 PM",
    "power": 0.378,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "9/1/2024 14:30",
    "plant_id": "Green-tek-A24428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "2:30:00 PM",
    "power": 0.305,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "9/1/2024 14:45",
    "plant_id": "Green-tek-A24429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "2:45:00 PM",
    "power": 0.242,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "9/1/2024 15:00",
    "plant_id": "Green-tek-A24430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "3:00:00 PM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "9/1/2024 15:15",
    "plant_id": "Green-tek-A24431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "3:15:00 PM",
    "power": 0.142,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "9/1/2024 15:30",
    "plant_id": "Green-tek-A24432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "3:30:00 PM",
    "power": 0.106,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "9/1/2024 15:45",
    "plant_id": "Green-tek-A24433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "9/1/2024 16:00",
    "plant_id": "Green-tek-A24434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "9/1/2024 16:15",
    "plant_id": "Green-tek-A24435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/1/2024 16:30",
    "plant_id": "Green-tek-A24436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/1/2024 16:45",
    "plant_id": "Green-tek-A24437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/1/2024 17:00",
    "plant_id": "Green-tek-A24438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/1/2024 17:15",
    "plant_id": "Green-tek-A24439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/1/2024 17:30",
    "plant_id": "Green-tek-A24440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/1/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/2/2024 6:00",
    "plant_id": "Green-tek-A24441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/2/2024 6:15",
    "plant_id": "Green-tek-A24442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/2/2024 6:30",
    "plant_id": "Green-tek-A24443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/2/2024 6:45",
    "plant_id": "Green-tek-A24444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/2/2024 7:00",
    "plant_id": "Green-tek-A24445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/2/2024 7:15",
    "plant_id": "Green-tek-A24446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/2/2024 7:30",
    "plant_id": "Green-tek-A24447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "9/2/2024 7:45",
    "plant_id": "Green-tek-A24448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "7:45:00 AM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/2/2024 8:00",
    "plant_id": "Green-tek-A24449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "8:00:00 AM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "9/2/2024 8:15",
    "plant_id": "Green-tek-A24450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "8:15:00 AM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "9/2/2024 8:30",
    "plant_id": "Green-tek-A24451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "8:30:00 AM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "9/2/2024 8:45",
    "plant_id": "Green-tek-A24452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "8:45:00 AM",
    "power": 0.247,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "9/2/2024 9:00",
    "plant_id": "Green-tek-A24453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "9:00:00 AM",
    "power": 0.313,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "9/2/2024 9:15",
    "plant_id": "Green-tek-A24454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "9:15:00 AM",
    "power": 0.387,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "9/2/2024 9:30",
    "plant_id": "Green-tek-A24455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "9:30:00 AM",
    "power": 0.47,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "9/2/2024 9:45",
    "plant_id": "Green-tek-A24456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "9:45:00 AM",
    "power": 0.559,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "9/2/2024 10:00",
    "plant_id": "Green-tek-A24457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "10:00:00 AM",
    "power": 0.652,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "9/2/2024 10:15",
    "plant_id": "Green-tek-A24458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "10:15:00 AM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "9/2/2024 10:30",
    "plant_id": "Green-tek-A24459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "10:30:00 AM",
    "power": 0.833,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "9/2/2024 10:45",
    "plant_id": "Green-tek-A24460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "10:45:00 AM",
    "power": 0.913,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "9/2/2024 11:00",
    "plant_id": "Green-tek-A24461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "11:00:00 AM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "9/2/2024 11:15",
    "plant_id": "Green-tek-A24462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "11:15:00 AM",
    "power": 1.032,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "9/2/2024 11:30",
    "plant_id": "Green-tek-A24463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "11:30:00 AM",
    "power": 1.064,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "9/2/2024 11:45",
    "plant_id": "Green-tek-A24464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "11:45:00 AM",
    "power": 1.075,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "9/2/2024 12:00",
    "plant_id": "Green-tek-A24465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "12:00:00 PM",
    "power": 1.064,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "9/2/2024 12:15",
    "plant_id": "Green-tek-A24466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "12:15:00 PM",
    "power": 1.032,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "9/2/2024 12:30",
    "plant_id": "Green-tek-A24467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "12:30:00 PM",
    "power": 0.98,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "9/2/2024 12:45",
    "plant_id": "Green-tek-A24468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "12:45:00 PM",
    "power": 0.913,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "9/2/2024 13:00",
    "plant_id": "Green-tek-A24469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "1:00:00 PM",
    "power": 0.833,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "9/2/2024 13:15",
    "plant_id": "Green-tek-A24470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "1:15:00 PM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "9/2/2024 13:30",
    "plant_id": "Green-tek-A24471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "1:30:00 PM",
    "power": 0.652,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "9/2/2024 13:45",
    "plant_id": "Green-tek-A24472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "1:45:00 PM",
    "power": 0.559,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "9/2/2024 14:00",
    "plant_id": "Green-tek-A24473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "2:00:00 PM",
    "power": 0.47,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "9/2/2024 14:15",
    "plant_id": "Green-tek-A24474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "2:15:00 PM",
    "power": 0.387,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "9/2/2024 14:30",
    "plant_id": "Green-tek-A24475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "2:30:00 PM",
    "power": 0.313,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "9/2/2024 14:45",
    "plant_id": "Green-tek-A24476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "2:45:00 PM",
    "power": 0.247,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "9/2/2024 15:00",
    "plant_id": "Green-tek-A24477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "3:00:00 PM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "9/2/2024 15:15",
    "plant_id": "Green-tek-A24478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "3:15:00 PM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "9/2/2024 15:30",
    "plant_id": "Green-tek-A24479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "3:30:00 PM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "9/2/2024 15:45",
    "plant_id": "Green-tek-A24480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "3:45:00 PM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/2/2024 16:00",
    "plant_id": "Green-tek-A24481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "9/2/2024 16:15",
    "plant_id": "Green-tek-A24482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/2/2024 16:30",
    "plant_id": "Green-tek-A24483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/2/2024 16:45",
    "plant_id": "Green-tek-A24484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/2/2024 17:00",
    "plant_id": "Green-tek-A24485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/2/2024 17:15",
    "plant_id": "Green-tek-A24486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/2/2024 17:30",
    "plant_id": "Green-tek-A24487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/2/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/3/2024 6:00",
    "plant_id": "Green-tek-A24488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/3/2024 6:15",
    "plant_id": "Green-tek-A24489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/3/2024 6:30",
    "plant_id": "Green-tek-A24490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/3/2024 6:45",
    "plant_id": "Green-tek-A24491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/3/2024 7:00",
    "plant_id": "Green-tek-A24492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/3/2024 7:15",
    "plant_id": "Green-tek-A24493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "9/3/2024 7:30",
    "plant_id": "Green-tek-A24494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/3/2024 7:45",
    "plant_id": "Green-tek-A24495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "7:45:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/3/2024 8:00",
    "plant_id": "Green-tek-A24496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "8:00:00 AM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/3/2024 8:15",
    "plant_id": "Green-tek-A24497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "8:15:00 AM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/3/2024 8:30",
    "plant_id": "Green-tek-A24498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "8:30:00 AM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "9/3/2024 8:45",
    "plant_id": "Green-tek-A24499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "8:45:00 AM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/3/2024 9:00",
    "plant_id": "Green-tek-A24500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "9:00:00 AM",
    "power": 0.272,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "9/3/2024 9:15",
    "plant_id": "Green-tek-A24501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "9:15:00 AM",
    "power": 0.337,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "9/3/2024 9:30",
    "plant_id": "Green-tek-A24502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "9:30:00 AM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "9/3/2024 9:45",
    "plant_id": "Green-tek-A24503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "9:45:00 AM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "9/3/2024 10:00",
    "plant_id": "Green-tek-A24504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "10:00:00 AM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "9/3/2024 10:15",
    "plant_id": "Green-tek-A24505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "10:15:00 AM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "9/3/2024 10:30",
    "plant_id": "Green-tek-A24506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "10:30:00 AM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "9/3/2024 10:45",
    "plant_id": "Green-tek-A24507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "10:45:00 AM",
    "power": 0.794,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "9/3/2024 11:00",
    "plant_id": "Green-tek-A24508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "11:00:00 AM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "9/3/2024 11:15",
    "plant_id": "Green-tek-A24509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "11:15:00 AM",
    "power": 0.897,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "9/3/2024 11:30",
    "plant_id": "Green-tek-A24510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "11:30:00 AM",
    "power": 0.925,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "9/3/2024 11:45",
    "plant_id": "Green-tek-A24511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "11:45:00 AM",
    "power": 0.934,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "9/3/2024 12:00",
    "plant_id": "Green-tek-A24512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "12:00:00 PM",
    "power": 0.925,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "9/3/2024 12:15",
    "plant_id": "Green-tek-A24513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "12:15:00 PM",
    "power": 0.897,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "9/3/2024 12:30",
    "plant_id": "Green-tek-A24514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "12:30:00 PM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "9/3/2024 12:45",
    "plant_id": "Green-tek-A24515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "12:45:00 PM",
    "power": 0.794,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "9/3/2024 13:00",
    "plant_id": "Green-tek-A24516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "1:00:00 PM",
    "power": 0.724,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.16524,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "9/3/2024 13:15",
    "plant_id": "Green-tek-A24517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "1:15:00 PM",
    "power": 0.647,
    "Energy": 0.162,
    "output Energy": 0.162,
    "Ac output": 1.162,
    "dc output": 1.18524,
    "AC_R_i": 0.0002113,
    "AC_S_i": 0.00023243,
    "AC_T_i": 0.00025567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048599,
    "AC_S_pow": 0.0534589,
    "AC_T_Pow": 0.0588041,
    "AC_R_output": 0.3486,
    "AC_S_output": 0.38346,
    "AC_T_output": 0.421806,
    "dc_current": 0.00119118,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.16524,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000162,
    "carbon offset": 0.2025,
    "trees planted": 4.5,
    "DC_R_output": 0.115038,
    "DC_S_output": 0.1265418,
    "DC_T_output": 0.13919598
  },
  {
    "datetime": "9/3/2024 13:30",
    "plant_id": "Green-tek-A24518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "1:30:00 PM",
    "power": 0.567,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "9/3/2024 13:45",
    "plant_id": "Green-tek-A24519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "1:45:00 PM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "9/3/2024 14:00",
    "plant_id": "Green-tek-A24520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "2:00:00 PM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "9/3/2024 14:15",
    "plant_id": "Green-tek-A24521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "2:15:00 PM",
    "power": 0.337,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "9/3/2024 14:30",
    "plant_id": "Green-tek-A24522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "2:30:00 PM",
    "power": 0.272,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "9/3/2024 14:45",
    "plant_id": "Green-tek-A24523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "2:45:00 PM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/3/2024 15:00",
    "plant_id": "Green-tek-A24524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "3:00:00 PM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "9/3/2024 15:15",
    "plant_id": "Green-tek-A24525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "3:15:00 PM",
    "power": 0.126,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/3/2024 15:30",
    "plant_id": "Green-tek-A24526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "3:30:00 PM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/3/2024 15:45",
    "plant_id": "Green-tek-A24527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "3:45:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/3/2024 16:00",
    "plant_id": "Green-tek-A24528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/3/2024 16:15",
    "plant_id": "Green-tek-A24529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "9/3/2024 16:30",
    "plant_id": "Green-tek-A24530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/3/2024 16:45",
    "plant_id": "Green-tek-A24531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/3/2024 17:00",
    "plant_id": "Green-tek-A24532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/3/2024 17:15",
    "plant_id": "Green-tek-A24533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/3/2024 17:30",
    "plant_id": "Green-tek-A24534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/3/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/4/2024 6:00",
    "plant_id": "Green-tek-A24535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/4/2024 6:15",
    "plant_id": "Green-tek-A24536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/4/2024 6:30",
    "plant_id": "Green-tek-A24537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/4/2024 6:45",
    "plant_id": "Green-tek-A24538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/4/2024 7:00",
    "plant_id": "Green-tek-A24539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/4/2024 7:15",
    "plant_id": "Green-tek-A24540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/4/2024 7:30",
    "plant_id": "Green-tek-A24541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/4/2024 7:45",
    "plant_id": "Green-tek-A24542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/4/2024 8:00",
    "plant_id": "Green-tek-A24543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "8:00:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/4/2024 8:15",
    "plant_id": "Green-tek-A24544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "8:15:00 AM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/4/2024 8:30",
    "plant_id": "Green-tek-A24545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "8:30:00 AM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/4/2024 8:45",
    "plant_id": "Green-tek-A24546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "8:45:00 AM",
    "power": 0.194,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "9/4/2024 9:00",
    "plant_id": "Green-tek-A24547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "9:00:00 AM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "9/4/2024 9:15",
    "plant_id": "Green-tek-A24548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "9:15:00 AM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "9/4/2024 9:30",
    "plant_id": "Green-tek-A24549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "9:30:00 AM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "9/4/2024 9:45",
    "plant_id": "Green-tek-A24550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "9:45:00 AM",
    "power": 0.438,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "9/4/2024 10:00",
    "plant_id": "Green-tek-A24551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "10:00:00 AM",
    "power": 0.511,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "9/4/2024 10:15",
    "plant_id": "Green-tek-A24552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "10:15:00 AM",
    "power": 0.583,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "9/4/2024 10:30",
    "plant_id": "Green-tek-A24553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "10:30:00 AM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "9/4/2024 10:45",
    "plant_id": "Green-tek-A24554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "10:45:00 AM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "9/4/2024 11:00",
    "plant_id": "Green-tek-A24555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "11:00:00 AM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "9/4/2024 11:15",
    "plant_id": "Green-tek-A24556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "11:15:00 AM",
    "power": 0.809,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "9/4/2024 11:30",
    "plant_id": "Green-tek-A24557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "11:30:00 AM",
    "power": 0.834,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "9/4/2024 11:45",
    "plant_id": "Green-tek-A24558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "11:45:00 AM",
    "power": 0.842,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "9/4/2024 12:00",
    "plant_id": "Green-tek-A24559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "12:00:00 PM",
    "power": 0.834,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "9/4/2024 12:15",
    "plant_id": "Green-tek-A24560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "12:15:00 PM",
    "power": 0.809,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "9/4/2024 12:30",
    "plant_id": "Green-tek-A24561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "12:30:00 PM",
    "power": 0.769,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "9/4/2024 12:45",
    "plant_id": "Green-tek-A24562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "12:45:00 PM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "9/4/2024 13:00",
    "plant_id": "Green-tek-A24563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "1:00:00 PM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "9/4/2024 13:15",
    "plant_id": "Green-tek-A24564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "1:15:00 PM",
    "power": 0.583,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "9/4/2024 13:30",
    "plant_id": "Green-tek-A24565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "1:30:00 PM",
    "power": 0.511,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "9/4/2024 13:45",
    "plant_id": "Green-tek-A24566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "1:45:00 PM",
    "power": 0.438,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "9/4/2024 14:00",
    "plant_id": "Green-tek-A24567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "2:00:00 PM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "9/4/2024 14:15",
    "plant_id": "Green-tek-A24568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "2:15:00 PM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "9/4/2024 14:30",
    "plant_id": "Green-tek-A24569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "2:30:00 PM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "9/4/2024 14:45",
    "plant_id": "Green-tek-A24570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "2:45:00 PM",
    "power": 0.194,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "9/4/2024 15:00",
    "plant_id": "Green-tek-A24571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "3:00:00 PM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/4/2024 15:15",
    "plant_id": "Green-tek-A24572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "3:15:00 PM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/4/2024 15:30",
    "plant_id": "Green-tek-A24573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "3:30:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/4/2024 15:45",
    "plant_id": "Green-tek-A24574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/4/2024 16:00",
    "plant_id": "Green-tek-A24575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/4/2024 16:15",
    "plant_id": "Green-tek-A24576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/4/2024 16:30",
    "plant_id": "Green-tek-A24577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/4/2024 16:45",
    "plant_id": "Green-tek-A24578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/4/2024 17:00",
    "plant_id": "Green-tek-A24579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/4/2024 17:15",
    "plant_id": "Green-tek-A24580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/4/2024 17:30",
    "plant_id": "Green-tek-A24581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/4/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/5/2024 6:00",
    "plant_id": "Green-tek-A24582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/5/2024 6:15",
    "plant_id": "Green-tek-A24583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/5/2024 6:30",
    "plant_id": "Green-tek-A24584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/5/2024 6:45",
    "plant_id": "Green-tek-A24585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/5/2024 7:00",
    "plant_id": "Green-tek-A24586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/5/2024 7:15",
    "plant_id": "Green-tek-A24587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/5/2024 7:30",
    "plant_id": "Green-tek-A24588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "7:30:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "9/5/2024 7:45",
    "plant_id": "Green-tek-A24589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "9/5/2024 8:00",
    "plant_id": "Green-tek-A24590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "8:00:00 AM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "9/5/2024 8:15",
    "plant_id": "Green-tek-A24591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "8:15:00 AM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "9/5/2024 8:30",
    "plant_id": "Green-tek-A24592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "8:30:00 AM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/5/2024 8:45",
    "plant_id": "Green-tek-A24593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "8:45:00 AM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "9/5/2024 9:00",
    "plant_id": "Green-tek-A24594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "9:00:00 AM",
    "power": 0.354,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "9/5/2024 9:15",
    "plant_id": "Green-tek-A24595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "9:15:00 AM",
    "power": 0.438,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "9/5/2024 9:30",
    "plant_id": "Green-tek-A24596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "9:30:00 AM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "9/5/2024 9:45",
    "plant_id": "Green-tek-A24597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "9:45:00 AM",
    "power": 0.633,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "9/5/2024 10:00",
    "plant_id": "Green-tek-A24598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "10:00:00 AM",
    "power": 0.738,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "9/5/2024 10:15",
    "plant_id": "Green-tek-A24599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "10:15:00 AM",
    "power": 0.842,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "9/5/2024 10:30",
    "plant_id": "Green-tek-A24600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "10:30:00 AM",
    "power": 0.942,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "9/5/2024 10:45",
    "plant_id": "Green-tek-A24601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "10:45:00 AM",
    "power": 1.033,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "9/5/2024 11:00",
    "plant_id": "Green-tek-A24602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "11:00:00 AM",
    "power": 1.109,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "9/5/2024 11:15",
    "plant_id": "Green-tek-A24603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "11:15:00 AM",
    "power": 1.168,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "9/5/2024 11:30",
    "plant_id": "Green-tek-A24604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "11:30:00 AM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "9/5/2024 11:45",
    "plant_id": "Green-tek-A24605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "11:45:00 AM",
    "power": 1.216,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "9/5/2024 12:00",
    "plant_id": "Green-tek-A24606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "12:00:00 PM",
    "power": 1.204,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "9/5/2024 12:15",
    "plant_id": "Green-tek-A24607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "12:15:00 PM",
    "power": 1.168,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "9/5/2024 12:30",
    "plant_id": "Green-tek-A24608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "12:30:00 PM",
    "power": 1.109,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "9/5/2024 12:45",
    "plant_id": "Green-tek-A24609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "12:45:00 PM",
    "power": 1.033,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "9/5/2024 13:00",
    "plant_id": "Green-tek-A24610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "1:00:00 PM",
    "power": 0.942,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "9/5/2024 13:15",
    "plant_id": "Green-tek-A24611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "1:15:00 PM",
    "power": 0.842,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "9/5/2024 13:30",
    "plant_id": "Green-tek-A24612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "1:30:00 PM",
    "power": 0.738,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "9/5/2024 13:45",
    "plant_id": "Green-tek-A24613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "1:45:00 PM",
    "power": 0.633,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.13566,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "9/5/2024 14:00",
    "plant_id": "Green-tek-A24614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "2:00:00 PM",
    "power": 0.532,
    "Energy": 0.133,
    "output Energy": 0.133,
    "Ac output": 1.133,
    "dc output": 1.15566,
    "AC_R_i": 0.00017348,
    "AC_S_i": 0.000190828,
    "AC_T_i": 0.00020991,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0399004,
    "AC_S_pow": 0.04389044,
    "AC_T_Pow": 0.0482793,
    "AC_R_output": 0.3399,
    "AC_S_output": 0.37389,
    "AC_T_output": 0.411279,
    "dc_current": 0.00097794,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13566,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000133,
    "carbon offset": 0.16625,
    "trees planted": 3.694444444,
    "DC_R_output": 0.112167,
    "DC_S_output": 0.1233837,
    "DC_T_output": 0.13572207
  },
  {
    "datetime": "9/5/2024 14:15",
    "plant_id": "Green-tek-A24615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "2:15:00 PM",
    "power": 0.438,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "9/5/2024 14:30",
    "plant_id": "Green-tek-A24616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "2:30:00 PM",
    "power": 0.354,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "9/5/2024 14:45",
    "plant_id": "Green-tek-A24617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "2:45:00 PM",
    "power": 0.28,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "9/5/2024 15:00",
    "plant_id": "Green-tek-A24618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "3:00:00 PM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/5/2024 15:15",
    "plant_id": "Green-tek-A24619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "3:15:00 PM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "9/5/2024 15:30",
    "plant_id": "Green-tek-A24620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "3:30:00 PM",
    "power": 0.122,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "9/5/2024 15:45",
    "plant_id": "Green-tek-A24621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "9/5/2024 16:00",
    "plant_id": "Green-tek-A24622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "4:00:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "9/5/2024 16:15",
    "plant_id": "Green-tek-A24623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/5/2024 16:30",
    "plant_id": "Green-tek-A24624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/5/2024 16:45",
    "plant_id": "Green-tek-A24625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/5/2024 17:00",
    "plant_id": "Green-tek-A24626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/5/2024 17:15",
    "plant_id": "Green-tek-A24627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/5/2024 17:30",
    "plant_id": "Green-tek-A24628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/5/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/6/2024 6:00",
    "plant_id": "Green-tek-A24629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/6/2024 6:15",
    "plant_id": "Green-tek-A24630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/6/2024 6:30",
    "plant_id": "Green-tek-A24631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/6/2024 6:45",
    "plant_id": "Green-tek-A24632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/6/2024 7:00",
    "plant_id": "Green-tek-A24633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/6/2024 7:15",
    "plant_id": "Green-tek-A24634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/6/2024 7:30",
    "plant_id": "Green-tek-A24635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/6/2024 7:45",
    "plant_id": "Green-tek-A24636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "7:45:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/6/2024 8:00",
    "plant_id": "Green-tek-A24637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "8:00:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/6/2024 8:15",
    "plant_id": "Green-tek-A24638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "8:15:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/6/2024 8:30",
    "plant_id": "Green-tek-A24639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "8:30:00 AM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "9/6/2024 8:45",
    "plant_id": "Green-tek-A24640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "8:45:00 AM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "9/6/2024 9:00",
    "plant_id": "Green-tek-A24641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "9:00:00 AM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "9/6/2024 9:15",
    "plant_id": "Green-tek-A24642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "9:15:00 AM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "9/6/2024 9:30",
    "plant_id": "Green-tek-A24643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "9:30:00 AM",
    "power": 0.487,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "9/6/2024 9:45",
    "plant_id": "Green-tek-A24644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "9:45:00 AM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "9/6/2024 10:00",
    "plant_id": "Green-tek-A24645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "10:00:00 AM",
    "power": 0.675,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "9/6/2024 10:15",
    "plant_id": "Green-tek-A24646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "10:15:00 AM",
    "power": 0.771,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "9/6/2024 10:30",
    "plant_id": "Green-tek-A24647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "10:30:00 AM",
    "power": 0.862,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "9/6/2024 10:45",
    "plant_id": "Green-tek-A24648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "10:45:00 AM",
    "power": 0.945,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "9/6/2024 11:00",
    "plant_id": "Green-tek-A24649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "11:00:00 AM",
    "power": 1.015,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "9/6/2024 11:15",
    "plant_id": "Green-tek-A24650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "11:15:00 AM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "9/6/2024 11:30",
    "plant_id": "Green-tek-A24651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "11:30:00 AM",
    "power": 1.102,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "9/6/2024 11:45",
    "plant_id": "Green-tek-A24652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "11:45:00 AM",
    "power": 1.113,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "9/6/2024 12:00",
    "plant_id": "Green-tek-A24653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "12:00:00 PM",
    "power": 1.102,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "9/6/2024 12:15",
    "plant_id": "Green-tek-A24654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "12:15:00 PM",
    "power": 1.068,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "9/6/2024 12:30",
    "plant_id": "Green-tek-A24655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "12:30:00 PM",
    "power": 1.015,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "9/6/2024 12:45",
    "plant_id": "Green-tek-A24656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "12:45:00 PM",
    "power": 0.945,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "9/6/2024 13:00",
    "plant_id": "Green-tek-A24657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "1:00:00 PM",
    "power": 0.862,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "9/6/2024 13:15",
    "plant_id": "Green-tek-A24658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "1:15:00 PM",
    "power": 0.771,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "9/6/2024 13:30",
    "plant_id": "Green-tek-A24659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "1:30:00 PM",
    "power": 0.675,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "9/6/2024 13:45",
    "plant_id": "Green-tek-A24660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "1:45:00 PM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "9/6/2024 14:00",
    "plant_id": "Green-tek-A24661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "2:00:00 PM",
    "power": 0.487,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "9/6/2024 14:15",
    "plant_id": "Green-tek-A24662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "2:15:00 PM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "9/6/2024 14:30",
    "plant_id": "Green-tek-A24663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "2:30:00 PM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "9/6/2024 14:45",
    "plant_id": "Green-tek-A24664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "2:45:00 PM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "9/6/2024 15:00",
    "plant_id": "Green-tek-A24665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "3:00:00 PM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "9/6/2024 15:15",
    "plant_id": "Green-tek-A24666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "3:15:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/6/2024 15:30",
    "plant_id": "Green-tek-A24667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "3:30:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/6/2024 15:45",
    "plant_id": "Green-tek-A24668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "3:45:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/6/2024 16:00",
    "plant_id": "Green-tek-A24669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/6/2024 16:15",
    "plant_id": "Green-tek-A24670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/6/2024 16:30",
    "plant_id": "Green-tek-A24671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/6/2024 16:45",
    "plant_id": "Green-tek-A24672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/6/2024 17:00",
    "plant_id": "Green-tek-A24673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/6/2024 17:15",
    "plant_id": "Green-tek-A24674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/6/2024 17:30",
    "plant_id": "Green-tek-A24675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/6/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/7/2024 6:00",
    "plant_id": "Green-tek-A24676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/7/2024 6:15",
    "plant_id": "Green-tek-A24677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/7/2024 6:30",
    "plant_id": "Green-tek-A24678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/7/2024 6:45",
    "plant_id": "Green-tek-A24679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/7/2024 7:00",
    "plant_id": "Green-tek-A24680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/7/2024 7:15",
    "plant_id": "Green-tek-A24681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/7/2024 7:30",
    "plant_id": "Green-tek-A24682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/7/2024 7:45",
    "plant_id": "Green-tek-A24683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "7:45:00 AM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/7/2024 8:00",
    "plant_id": "Green-tek-A24684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "8:00:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/7/2024 8:15",
    "plant_id": "Green-tek-A24685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "8:15:00 AM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "9/7/2024 8:30",
    "plant_id": "Green-tek-A24686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "8:30:00 AM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "9/7/2024 8:45",
    "plant_id": "Green-tek-A24687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "8:45:00 AM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "9/7/2024 9:00",
    "plant_id": "Green-tek-A24688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "9:00:00 AM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "9/7/2024 9:15",
    "plant_id": "Green-tek-A24689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "9:15:00 AM",
    "power": 0.462,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "9/7/2024 9:30",
    "plant_id": "Green-tek-A24690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "9:30:00 AM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "9/7/2024 9:45",
    "plant_id": "Green-tek-A24691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "9:45:00 AM",
    "power": 0.668,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "9/7/2024 10:00",
    "plant_id": "Green-tek-A24692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "10:00:00 AM",
    "power": 0.778,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "9/7/2024 10:15",
    "plant_id": "Green-tek-A24693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "10:15:00 AM",
    "power": 0.888,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "9/7/2024 10:30",
    "plant_id": "Green-tek-A24694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "10:30:00 AM",
    "power": 0.994,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "9/7/2024 10:45",
    "plant_id": "Green-tek-A24695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "10:45:00 AM",
    "power": 1.09,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "9/7/2024 11:00",
    "plant_id": "Green-tek-A24696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "11:00:00 AM",
    "power": 1.17,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "9/7/2024 11:15",
    "plant_id": "Green-tek-A24697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "11:15:00 AM",
    "power": 1.232,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "9/7/2024 11:30",
    "plant_id": "Green-tek-A24698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "11:30:00 AM",
    "power": 1.27,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "9/7/2024 11:45",
    "plant_id": "Green-tek-A24699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "11:45:00 AM",
    "power": 1.283,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "9/7/2024 12:00",
    "plant_id": "Green-tek-A24700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "12:00:00 PM",
    "power": 1.27,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "9/7/2024 12:15",
    "plant_id": "Green-tek-A24701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "12:15:00 PM",
    "power": 1.232,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "9/7/2024 12:30",
    "plant_id": "Green-tek-A24702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "12:30:00 PM",
    "power": 1.17,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "9/7/2024 12:45",
    "plant_id": "Green-tek-A24703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "12:45:00 PM",
    "power": 1.09,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "9/7/2024 13:00",
    "plant_id": "Green-tek-A24704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "1:00:00 PM",
    "power": 0.994,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "9/7/2024 13:15",
    "plant_id": "Green-tek-A24705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "1:15:00 PM",
    "power": 0.888,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "9/7/2024 13:30",
    "plant_id": "Green-tek-A24706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "1:30:00 PM",
    "power": 0.778,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "9/7/2024 13:45",
    "plant_id": "Green-tek-A24707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "1:45:00 PM",
    "power": 0.668,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "9/7/2024 14:00",
    "plant_id": "Green-tek-A24708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "2:00:00 PM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "9/7/2024 14:15",
    "plant_id": "Green-tek-A24709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "2:15:00 PM",
    "power": 0.462,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "9/7/2024 14:30",
    "plant_id": "Green-tek-A24710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "2:30:00 PM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "9/7/2024 14:45",
    "plant_id": "Green-tek-A24711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "2:45:00 PM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "9/7/2024 15:00",
    "plant_id": "Green-tek-A24712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "3:00:00 PM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "9/7/2024 15:15",
    "plant_id": "Green-tek-A24713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "3:15:00 PM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "9/7/2024 15:30",
    "plant_id": "Green-tek-A24714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "3:30:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/7/2024 15:45",
    "plant_id": "Green-tek-A24715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "3:45:00 PM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/7/2024 16:00",
    "plant_id": "Green-tek-A24716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/7/2024 16:15",
    "plant_id": "Green-tek-A24717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/7/2024 16:30",
    "plant_id": "Green-tek-A24718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/7/2024 16:45",
    "plant_id": "Green-tek-A24719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/7/2024 17:00",
    "plant_id": "Green-tek-A24720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/7/2024 17:15",
    "plant_id": "Green-tek-A24721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/7/2024 17:30",
    "plant_id": "Green-tek-A24722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/7/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/8/2024 6:00",
    "plant_id": "Green-tek-A24723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/8/2024 6:15",
    "plant_id": "Green-tek-A24724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/8/2024 6:30",
    "plant_id": "Green-tek-A24725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/8/2024 6:45",
    "plant_id": "Green-tek-A24726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/8/2024 7:00",
    "plant_id": "Green-tek-A24727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/8/2024 7:15",
    "plant_id": "Green-tek-A24728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/8/2024 7:30",
    "plant_id": "Green-tek-A24729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "7:30:00 AM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "9/8/2024 7:45",
    "plant_id": "Green-tek-A24730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "7:45:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/8/2024 8:00",
    "plant_id": "Green-tek-A24731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "8:00:00 AM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "9/8/2024 8:15",
    "plant_id": "Green-tek-A24732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "8:15:00 AM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "9/8/2024 8:30",
    "plant_id": "Green-tek-A24733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "8:30:00 AM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "9/8/2024 8:45",
    "plant_id": "Green-tek-A24734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "8:45:00 AM",
    "power": 0.284,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "9/8/2024 9:00",
    "plant_id": "Green-tek-A24735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "9:00:00 AM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "9/8/2024 9:15",
    "plant_id": "Green-tek-A24736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "9:15:00 AM",
    "power": 0.445,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "9/8/2024 9:30",
    "plant_id": "Green-tek-A24737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "9:30:00 AM",
    "power": 0.541,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "9/8/2024 9:45",
    "plant_id": "Green-tek-A24738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "9:45:00 AM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "9/8/2024 10:00",
    "plant_id": "Green-tek-A24739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "10:00:00 AM",
    "power": 0.749,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "9/8/2024 10:15",
    "plant_id": "Green-tek-A24740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "10:15:00 AM",
    "power": 0.856,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "9/8/2024 10:30",
    "plant_id": "Green-tek-A24741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "10:30:00 AM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "9/8/2024 10:45",
    "plant_id": "Green-tek-A24742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "10:45:00 AM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "9/8/2024 11:00",
    "plant_id": "Green-tek-A24743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "11:00:00 AM",
    "power": 1.127,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "9/8/2024 11:15",
    "plant_id": "Green-tek-A24744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "11:15:00 AM",
    "power": 1.186,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "9/8/2024 11:30",
    "plant_id": "Green-tek-A24745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "11:30:00 AM",
    "power": 1.223,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "9/8/2024 11:45",
    "plant_id": "Green-tek-A24746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "11:45:00 AM",
    "power": 1.236,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "9/8/2024 12:00",
    "plant_id": "Green-tek-A24747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "12:00:00 PM",
    "power": 1.223,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "9/8/2024 12:15",
    "plant_id": "Green-tek-A24748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "12:15:00 PM",
    "power": 1.186,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "9/8/2024 12:30",
    "plant_id": "Green-tek-A24749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "12:30:00 PM",
    "power": 1.127,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "9/8/2024 12:45",
    "plant_id": "Green-tek-A24750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "12:45:00 PM",
    "power": 1.049,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "9/8/2024 13:00",
    "plant_id": "Green-tek-A24751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "1:00:00 PM",
    "power": 0.957,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "9/8/2024 13:15",
    "plant_id": "Green-tek-A24752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "1:15:00 PM",
    "power": 0.856,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.19074,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "9/8/2024 13:30",
    "plant_id": "Green-tek-A24753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "1:30:00 PM",
    "power": 0.749,
    "Energy": 0.187,
    "output Energy": 0.187,
    "Ac output": 1.187,
    "dc output": 1.21074,
    "AC_R_i": 0.00024391,
    "AC_S_i": 0.000268301,
    "AC_T_i": 0.00029513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0560993,
    "AC_S_pow": 0.06170923,
    "AC_T_Pow": 0.0678799,
    "AC_R_output": 0.3561,
    "AC_S_output": 0.39171,
    "AC_T_output": 0.430881,
    "dc_current": 0.001375,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.19074,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000187,
    "carbon offset": 0.23375,
    "trees planted": 5.194444444,
    "DC_R_output": 0.117513,
    "DC_S_output": 0.1292643,
    "DC_T_output": 0.14219073
  },
  {
    "datetime": "9/8/2024 13:45",
    "plant_id": "Green-tek-A24754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "1:45:00 PM",
    "power": 0.643,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "9/8/2024 14:00",
    "plant_id": "Green-tek-A24755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "2:00:00 PM",
    "power": 0.541,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "9/8/2024 14:15",
    "plant_id": "Green-tek-A24756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "2:15:00 PM",
    "power": 0.445,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "9/8/2024 14:30",
    "plant_id": "Green-tek-A24757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "2:30:00 PM",
    "power": 0.359,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "9/8/2024 14:45",
    "plant_id": "Green-tek-A24758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "2:45:00 PM",
    "power": 0.284,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "9/8/2024 15:00",
    "plant_id": "Green-tek-A24759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "3:00:00 PM",
    "power": 0.22,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "9/8/2024 15:15",
    "plant_id": "Green-tek-A24760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "3:15:00 PM",
    "power": 0.167,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "9/8/2024 15:30",
    "plant_id": "Green-tek-A24761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "3:30:00 PM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "9/8/2024 15:45",
    "plant_id": "Green-tek-A24762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "3:45:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/8/2024 16:00",
    "plant_id": "Green-tek-A24763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "4:00:00 PM",
    "power": 0.065,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "9/8/2024 16:15",
    "plant_id": "Green-tek-A24764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/8/2024 16:30",
    "plant_id": "Green-tek-A24765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/8/2024 16:45",
    "plant_id": "Green-tek-A24766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/8/2024 17:00",
    "plant_id": "Green-tek-A24767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/8/2024 17:15",
    "plant_id": "Green-tek-A24768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/8/2024 17:30",
    "plant_id": "Green-tek-A24769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/8/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/9/2024 6:00",
    "plant_id": "Green-tek-A24770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/9/2024 6:15",
    "plant_id": "Green-tek-A24771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/9/2024 6:30",
    "plant_id": "Green-tek-A24772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/9/2024 6:45",
    "plant_id": "Green-tek-A24773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/9/2024 7:00",
    "plant_id": "Green-tek-A24774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/9/2024 7:15",
    "plant_id": "Green-tek-A24775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/9/2024 7:30",
    "plant_id": "Green-tek-A24776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/9/2024 7:45",
    "plant_id": "Green-tek-A24777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "7:45:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/9/2024 8:00",
    "plant_id": "Green-tek-A24778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "8:00:00 AM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/9/2024 8:15",
    "plant_id": "Green-tek-A24779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "8:15:00 AM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "9/9/2024 8:30",
    "plant_id": "Green-tek-A24780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "8:30:00 AM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "9/9/2024 8:45",
    "plant_id": "Green-tek-A24781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "8:45:00 AM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "9/9/2024 9:00",
    "plant_id": "Green-tek-A24782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "9:00:00 AM",
    "power": 0.337,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "9/9/2024 9:15",
    "plant_id": "Green-tek-A24783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "9:15:00 AM",
    "power": 0.418,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "9/9/2024 9:30",
    "plant_id": "Green-tek-A24784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "9:30:00 AM",
    "power": 0.507,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "9/9/2024 9:45",
    "plant_id": "Green-tek-A24785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "9:45:00 AM",
    "power": 0.603,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "9/9/2024 10:00",
    "plant_id": "Green-tek-A24786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "10:00:00 AM",
    "power": 0.703,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "9/9/2024 10:15",
    "plant_id": "Green-tek-A24787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "10:15:00 AM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "9/9/2024 10:30",
    "plant_id": "Green-tek-A24788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "10:30:00 AM",
    "power": 0.898,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "9/9/2024 10:45",
    "plant_id": "Green-tek-A24789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "10:45:00 AM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "9/9/2024 11:00",
    "plant_id": "Green-tek-A24790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "11:00:00 AM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "9/9/2024 11:15",
    "plant_id": "Green-tek-A24791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "11:15:00 AM",
    "power": 1.112,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "9/9/2024 11:30",
    "plant_id": "Green-tek-A24792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "11:30:00 AM",
    "power": 1.147,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "9/9/2024 11:45",
    "plant_id": "Green-tek-A24793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "11:45:00 AM",
    "power": 1.159,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "9/9/2024 12:00",
    "plant_id": "Green-tek-A24794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "12:00:00 PM",
    "power": 1.147,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "9/9/2024 12:15",
    "plant_id": "Green-tek-A24795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "12:15:00 PM",
    "power": 1.112,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "9/9/2024 12:30",
    "plant_id": "Green-tek-A24796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "12:30:00 PM",
    "power": 1.057,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "9/9/2024 12:45",
    "plant_id": "Green-tek-A24797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "12:45:00 PM",
    "power": 0.984,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "9/9/2024 13:00",
    "plant_id": "Green-tek-A24798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "1:00:00 PM",
    "power": 0.898,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "9/9/2024 13:15",
    "plant_id": "Green-tek-A24799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "1:15:00 PM",
    "power": 0.802,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "9/9/2024 13:30",
    "plant_id": "Green-tek-A24800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "1:30:00 PM",
    "power": 0.703,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "9/9/2024 13:45",
    "plant_id": "Green-tek-A24801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "1:45:00 PM",
    "power": 0.603,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "9/9/2024 14:00",
    "plant_id": "Green-tek-A24802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "2:00:00 PM",
    "power": 0.507,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "9/9/2024 14:15",
    "plant_id": "Green-tek-A24803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "2:15:00 PM",
    "power": 0.418,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "9/9/2024 14:30",
    "plant_id": "Green-tek-A24804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "2:30:00 PM",
    "power": 0.337,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "9/9/2024 14:45",
    "plant_id": "Green-tek-A24805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "2:45:00 PM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "9/9/2024 15:00",
    "plant_id": "Green-tek-A24806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "3:00:00 PM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "9/9/2024 15:15",
    "plant_id": "Green-tek-A24807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "3:15:00 PM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "9/9/2024 15:30",
    "plant_id": "Green-tek-A24808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "3:30:00 PM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/9/2024 15:45",
    "plant_id": "Green-tek-A24809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "3:45:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/9/2024 16:00",
    "plant_id": "Green-tek-A24810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/9/2024 16:15",
    "plant_id": "Green-tek-A24811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/9/2024 16:30",
    "plant_id": "Green-tek-A24812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/9/2024 16:45",
    "plant_id": "Green-tek-A24813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/9/2024 17:00",
    "plant_id": "Green-tek-A24814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/9/2024 17:15",
    "plant_id": "Green-tek-A24815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/9/2024 17:30",
    "plant_id": "Green-tek-A24816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/9/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/10/2024 6:00",
    "plant_id": "Green-tek-A24817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/10/2024 6:15",
    "plant_id": "Green-tek-A24818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/10/2024 6:30",
    "plant_id": "Green-tek-A24819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/10/2024 6:45",
    "plant_id": "Green-tek-A24820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/10/2024 7:00",
    "plant_id": "Green-tek-A24821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/10/2024 7:15",
    "plant_id": "Green-tek-A24822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "9/10/2024 7:30",
    "plant_id": "Green-tek-A24823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/10/2024 7:45",
    "plant_id": "Green-tek-A24824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "7:45:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/10/2024 8:00",
    "plant_id": "Green-tek-A24825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "8:00:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/10/2024 8:15",
    "plant_id": "Green-tek-A24826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "8:15:00 AM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/10/2024 8:30",
    "plant_id": "Green-tek-A24827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "8:30:00 AM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "9/10/2024 8:45",
    "plant_id": "Green-tek-A24828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "8:45:00 AM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/10/2024 9:00",
    "plant_id": "Green-tek-A24829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "9:00:00 AM",
    "power": 0.274,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "9/10/2024 9:15",
    "plant_id": "Green-tek-A24830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "9:15:00 AM",
    "power": 0.34,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "9/10/2024 9:30",
    "plant_id": "Green-tek-A24831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "9:30:00 AM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "9/10/2024 9:45",
    "plant_id": "Green-tek-A24832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "9:45:00 AM",
    "power": 0.49,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "9/10/2024 10:00",
    "plant_id": "Green-tek-A24833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "10:00:00 AM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "9/10/2024 10:15",
    "plant_id": "Green-tek-A24834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "10:15:00 AM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "9/10/2024 10:30",
    "plant_id": "Green-tek-A24835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "10:30:00 AM",
    "power": 0.73,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "9/10/2024 10:45",
    "plant_id": "Green-tek-A24836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "10:45:00 AM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "9/10/2024 11:00",
    "plant_id": "Green-tek-A24837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "11:00:00 AM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "9/10/2024 11:15",
    "plant_id": "Green-tek-A24838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "11:15:00 AM",
    "power": 0.905,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "9/10/2024 11:30",
    "plant_id": "Green-tek-A24839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "11:30:00 AM",
    "power": 0.933,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "9/10/2024 11:45",
    "plant_id": "Green-tek-A24840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "11:45:00 AM",
    "power": 0.942,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "9/10/2024 12:00",
    "plant_id": "Green-tek-A24841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "12:00:00 PM",
    "power": 0.933,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "9/10/2024 12:15",
    "plant_id": "Green-tek-A24842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "12:15:00 PM",
    "power": 0.905,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "9/10/2024 12:30",
    "plant_id": "Green-tek-A24843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "12:30:00 PM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "9/10/2024 12:45",
    "plant_id": "Green-tek-A24844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "12:45:00 PM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "9/10/2024 13:00",
    "plant_id": "Green-tek-A24845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "1:00:00 PM",
    "power": 0.73,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "9/10/2024 13:15",
    "plant_id": "Green-tek-A24846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "1:15:00 PM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "9/10/2024 13:30",
    "plant_id": "Green-tek-A24847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "1:30:00 PM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "9/10/2024 13:45",
    "plant_id": "Green-tek-A24848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "1:45:00 PM",
    "power": 0.49,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "9/10/2024 14:00",
    "plant_id": "Green-tek-A24849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "2:00:00 PM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "9/10/2024 14:15",
    "plant_id": "Green-tek-A24850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "2:15:00 PM",
    "power": 0.34,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "9/10/2024 14:30",
    "plant_id": "Green-tek-A24851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "2:30:00 PM",
    "power": 0.274,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "9/10/2024 14:45",
    "plant_id": "Green-tek-A24852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "2:45:00 PM",
    "power": 0.217,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/10/2024 15:00",
    "plant_id": "Green-tek-A24853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "3:00:00 PM",
    "power": 0.168,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "9/10/2024 15:15",
    "plant_id": "Green-tek-A24854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "3:15:00 PM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/10/2024 15:30",
    "plant_id": "Green-tek-A24855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "3:30:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/10/2024 15:45",
    "plant_id": "Green-tek-A24856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "3:45:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/10/2024 16:00",
    "plant_id": "Green-tek-A24857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/10/2024 16:15",
    "plant_id": "Green-tek-A24858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "9/10/2024 16:30",
    "plant_id": "Green-tek-A24859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/10/2024 16:45",
    "plant_id": "Green-tek-A24860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/10/2024 17:00",
    "plant_id": "Green-tek-A24861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/10/2024 17:15",
    "plant_id": "Green-tek-A24862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/10/2024 17:30",
    "plant_id": "Green-tek-A24863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/10/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/11/2024 6:00",
    "plant_id": "Green-tek-A24864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/11/2024 6:15",
    "plant_id": "Green-tek-A24865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/11/2024 6:30",
    "plant_id": "Green-tek-A24866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/11/2024 6:45",
    "plant_id": "Green-tek-A24867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/11/2024 7:00",
    "plant_id": "Green-tek-A24868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/11/2024 7:15",
    "plant_id": "Green-tek-A24869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/11/2024 7:30",
    "plant_id": "Green-tek-A24870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/11/2024 7:45",
    "plant_id": "Green-tek-A24871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/11/2024 8:00",
    "plant_id": "Green-tek-A24872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "8:00:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/11/2024 8:15",
    "plant_id": "Green-tek-A24873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "8:15:00 AM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "9/11/2024 8:30",
    "plant_id": "Green-tek-A24874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "8:30:00 AM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "9/11/2024 8:45",
    "plant_id": "Green-tek-A24875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "8:45:00 AM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "9/11/2024 9:00",
    "plant_id": "Green-tek-A24876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "9:00:00 AM",
    "power": 0.333,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "9/11/2024 9:15",
    "plant_id": "Green-tek-A24877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "9:15:00 AM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "9/11/2024 9:30",
    "plant_id": "Green-tek-A24878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "9:30:00 AM",
    "power": 0.5,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "9/11/2024 9:45",
    "plant_id": "Green-tek-A24879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "9:45:00 AM",
    "power": 0.595,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "9/11/2024 10:00",
    "plant_id": "Green-tek-A24880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "10:00:00 AM",
    "power": 0.694,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "9/11/2024 10:15",
    "plant_id": "Green-tek-A24881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "10:15:00 AM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "9/11/2024 10:30",
    "plant_id": "Green-tek-A24882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "10:30:00 AM",
    "power": 0.886,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "9/11/2024 10:45",
    "plant_id": "Green-tek-A24883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "10:45:00 AM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "9/11/2024 11:00",
    "plant_id": "Green-tek-A24884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "11:00:00 AM",
    "power": 1.043,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "9/11/2024 11:15",
    "plant_id": "Green-tek-A24885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "11:15:00 AM",
    "power": 1.098,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "9/11/2024 11:30",
    "plant_id": "Green-tek-A24886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "11:30:00 AM",
    "power": 1.132,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "9/11/2024 11:45",
    "plant_id": "Green-tek-A24887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "11:45:00 AM",
    "power": 1.144,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "9/11/2024 12:00",
    "plant_id": "Green-tek-A24888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "12:00:00 PM",
    "power": 1.132,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "9/11/2024 12:15",
    "plant_id": "Green-tek-A24889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "12:15:00 PM",
    "power": 1.098,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "9/11/2024 12:30",
    "plant_id": "Green-tek-A24890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "12:30:00 PM",
    "power": 1.043,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "9/11/2024 12:45",
    "plant_id": "Green-tek-A24891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "12:45:00 PM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "9/11/2024 13:00",
    "plant_id": "Green-tek-A24892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "1:00:00 PM",
    "power": 0.886,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "9/11/2024 13:15",
    "plant_id": "Green-tek-A24893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "1:15:00 PM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "9/11/2024 13:30",
    "plant_id": "Green-tek-A24894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "1:30:00 PM",
    "power": 0.694,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "9/11/2024 13:45",
    "plant_id": "Green-tek-A24895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "1:45:00 PM",
    "power": 0.595,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "9/11/2024 14:00",
    "plant_id": "Green-tek-A24896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "2:00:00 PM",
    "power": 0.5,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "9/11/2024 14:15",
    "plant_id": "Green-tek-A24897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "2:15:00 PM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "9/11/2024 14:30",
    "plant_id": "Green-tek-A24898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "2:30:00 PM",
    "power": 0.333,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "9/11/2024 14:45",
    "plant_id": "Green-tek-A24899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "2:45:00 PM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "9/11/2024 15:00",
    "plant_id": "Green-tek-A24900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "3:00:00 PM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "9/11/2024 15:15",
    "plant_id": "Green-tek-A24901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "3:15:00 PM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "9/11/2024 15:30",
    "plant_id": "Green-tek-A24902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "3:30:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/11/2024 15:45",
    "plant_id": "Green-tek-A24903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/11/2024 16:00",
    "plant_id": "Green-tek-A24904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/11/2024 16:15",
    "plant_id": "Green-tek-A24905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/11/2024 16:30",
    "plant_id": "Green-tek-A24906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/11/2024 16:45",
    "plant_id": "Green-tek-A24907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/11/2024 17:00",
    "plant_id": "Green-tek-A24908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/11/2024 17:15",
    "plant_id": "Green-tek-A24909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/11/2024 17:30",
    "plant_id": "Green-tek-A24910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/11/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/12/2024 6:00",
    "plant_id": "Green-tek-A24911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/12/2024 6:15",
    "plant_id": "Green-tek-A24912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/12/2024 6:30",
    "plant_id": "Green-tek-A24913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/12/2024 6:45",
    "plant_id": "Green-tek-A24914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/12/2024 7:00",
    "plant_id": "Green-tek-A24915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/12/2024 7:15",
    "plant_id": "Green-tek-A24916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/12/2024 7:30",
    "plant_id": "Green-tek-A24917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/12/2024 7:45",
    "plant_id": "Green-tek-A24918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "7:45:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/12/2024 8:00",
    "plant_id": "Green-tek-A24919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "8:00:00 AM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/12/2024 8:15",
    "plant_id": "Green-tek-A24920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "8:15:00 AM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "9/12/2024 8:30",
    "plant_id": "Green-tek-A24921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "8:30:00 AM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "9/12/2024 8:45",
    "plant_id": "Green-tek-A24922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "8:45:00 AM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "9/12/2024 9:00",
    "plant_id": "Green-tek-A24923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "9:00:00 AM",
    "power": 0.34,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "9/12/2024 9:15",
    "plant_id": "Green-tek-A24924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "9:15:00 AM",
    "power": 0.421,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "9/12/2024 9:30",
    "plant_id": "Green-tek-A24925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "9:30:00 AM",
    "power": 0.511,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "9/12/2024 9:45",
    "plant_id": "Green-tek-A24926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "9:45:00 AM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "9/12/2024 10:00",
    "plant_id": "Green-tek-A24927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "10:00:00 AM",
    "power": 0.708,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "9/12/2024 10:15",
    "plant_id": "Green-tek-A24928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "10:15:00 AM",
    "power": 0.809,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "9/12/2024 10:30",
    "plant_id": "Green-tek-A24929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "10:30:00 AM",
    "power": 0.905,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "9/12/2024 10:45",
    "plant_id": "Green-tek-A24930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "10:45:00 AM",
    "power": 0.992,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "9/12/2024 11:00",
    "plant_id": "Green-tek-A24931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "11:00:00 AM",
    "power": 1.065,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "9/12/2024 11:15",
    "plant_id": "Green-tek-A24932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "11:15:00 AM",
    "power": 1.121,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.29478,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "9/12/2024 11:30",
    "plant_id": "Green-tek-A24933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "11:30:00 AM",
    "power": 1.156,
    "Energy": 0.289,
    "output Energy": 0.289,
    "Ac output": 1.289,
    "dc output": 1.31478,
    "AC_R_i": 0.00037696,
    "AC_S_i": 0.000414656,
    "AC_T_i": 0.00045612,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0867008,
    "AC_S_pow": 0.09537088,
    "AC_T_Pow": 0.1049076,
    "AC_R_output": 0.3867,
    "AC_S_output": 0.42537,
    "AC_T_output": 0.467907,
    "dc_current": 0.002125,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.29478,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000289,
    "carbon offset": 0.36125,
    "trees planted": 8.027777778,
    "DC_R_output": 0.127611,
    "DC_S_output": 0.1403721,
    "DC_T_output": 0.15440931
  },
  {
    "datetime": "9/12/2024 11:45",
    "plant_id": "Green-tek-A24934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "11:45:00 AM",
    "power": 1.168,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.29478,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "9/12/2024 12:00",
    "plant_id": "Green-tek-A24935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "12:00:00 PM",
    "power": 1.156,
    "Energy": 0.289,
    "output Energy": 0.289,
    "Ac output": 1.289,
    "dc output": 1.31478,
    "AC_R_i": 0.00037696,
    "AC_S_i": 0.000414656,
    "AC_T_i": 0.00045612,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0867008,
    "AC_S_pow": 0.09537088,
    "AC_T_Pow": 0.1049076,
    "AC_R_output": 0.3867,
    "AC_S_output": 0.42537,
    "AC_T_output": 0.467907,
    "dc_current": 0.002125,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.29478,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000289,
    "carbon offset": 0.36125,
    "trees planted": 8.027777778,
    "DC_R_output": 0.127611,
    "DC_S_output": 0.1403721,
    "DC_T_output": 0.15440931
  },
  {
    "datetime": "9/12/2024 12:15",
    "plant_id": "Green-tek-A24936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "12:15:00 PM",
    "power": 1.121,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "9/12/2024 12:30",
    "plant_id": "Green-tek-A24937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "12:30:00 PM",
    "power": 1.065,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "9/12/2024 12:45",
    "plant_id": "Green-tek-A24938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "12:45:00 PM",
    "power": 0.992,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "9/12/2024 13:00",
    "plant_id": "Green-tek-A24939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "1:00:00 PM",
    "power": 0.905,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.20604,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "9/12/2024 13:15",
    "plant_id": "Green-tek-A24940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "1:15:00 PM",
    "power": 0.809,
    "Energy": 0.202,
    "output Energy": 0.202,
    "Ac output": 1.202,
    "dc output": 1.22604,
    "AC_R_i": 0.00026348,
    "AC_S_i": 0.000289828,
    "AC_T_i": 0.00031881,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0606004,
    "AC_S_pow": 0.06666044,
    "AC_T_Pow": 0.0733263,
    "AC_R_output": 0.3606,
    "AC_S_output": 0.39666,
    "AC_T_output": 0.436326,
    "dc_current": 0.00148529,
    "dc_voltage": 136,
    "dc_power": 0.18054,
    "dc_output": 0.20604,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000202,
    "carbon offset": 0.2525,
    "trees planted": 5.611111111,
    "DC_R_output": 0.118998,
    "DC_S_output": 0.1308978,
    "DC_T_output": 0.14398758
  },
  {
    "datetime": "9/12/2024 13:30",
    "plant_id": "Green-tek-A24941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "1:30:00 PM",
    "power": 0.708,
    "Energy": 0.177,
    "output Energy": 0.177,
    "Ac output": 1.177,
    "dc output": 1.20054,
    "AC_R_i": 0.00023087,
    "AC_S_i": 0.000253957,
    "AC_T_i": 0.00027935,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0531001,
    "AC_S_pow": 0.05841011,
    "AC_T_Pow": 0.0642505,
    "AC_R_output": 0.3531,
    "AC_S_output": 0.38841,
    "AC_T_output": 0.427251,
    "dc_current": 0.00130147,
    "dc_voltage": 136,
    "dc_power": 0.15504,
    "dc_output": 0.18054,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000177,
    "carbon offset": 0.22125,
    "trees planted": 4.916666667,
    "DC_R_output": 0.116523,
    "DC_S_output": 0.1281753,
    "DC_T_output": 0.14099283
  },
  {
    "datetime": "9/12/2024 13:45",
    "plant_id": "Green-tek-A24942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "1:45:00 PM",
    "power": 0.608,
    "Energy": 0.152,
    "output Energy": 0.152,
    "Ac output": 1.152,
    "dc output": 1.17504,
    "AC_R_i": 0.00019826,
    "AC_S_i": 0.000218086,
    "AC_T_i": 0.00023989,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0455998,
    "AC_S_pow": 0.05015978,
    "AC_T_Pow": 0.0551747,
    "AC_R_output": 0.3456,
    "AC_S_output": 0.38016,
    "AC_T_output": 0.418176,
    "dc_current": 0.00111765,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.15504,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000152,
    "carbon offset": 0.19,
    "trees planted": 4.222222222,
    "DC_R_output": 0.114048,
    "DC_S_output": 0.1254528,
    "DC_T_output": 0.13799808
  },
  {
    "datetime": "9/12/2024 14:00",
    "plant_id": "Green-tek-A24943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "2:00:00 PM",
    "power": 0.511,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "9/12/2024 14:15",
    "plant_id": "Green-tek-A24944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "2:15:00 PM",
    "power": 0.421,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "9/12/2024 14:30",
    "plant_id": "Green-tek-A24945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "2:30:00 PM",
    "power": 0.34,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "9/12/2024 14:45",
    "plant_id": "Green-tek-A24946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "2:45:00 PM",
    "power": 0.269,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "9/12/2024 15:00",
    "plant_id": "Green-tek-A24947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "3:00:00 PM",
    "power": 0.208,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "9/12/2024 15:15",
    "plant_id": "Green-tek-A24948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "3:15:00 PM",
    "power": 0.158,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "9/12/2024 15:30",
    "plant_id": "Green-tek-A24949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "3:30:00 PM",
    "power": 0.118,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/12/2024 15:45",
    "plant_id": "Green-tek-A24950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "3:45:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/12/2024 16:00",
    "plant_id": "Green-tek-A24951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/12/2024 16:15",
    "plant_id": "Green-tek-A24952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/12/2024 16:30",
    "plant_id": "Green-tek-A24953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/12/2024 16:45",
    "plant_id": "Green-tek-A24954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/12/2024 17:00",
    "plant_id": "Green-tek-A24955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/12/2024 17:15",
    "plant_id": "Green-tek-A24956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/12/2024 17:30",
    "plant_id": "Green-tek-A24957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/12/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/13/2024 6:00",
    "plant_id": "Green-tek-A24958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/13/2024 6:15",
    "plant_id": "Green-tek-A24959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/13/2024 6:30",
    "plant_id": "Green-tek-A24960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/13/2024 6:45",
    "plant_id": "Green-tek-A24961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/13/2024 7:00",
    "plant_id": "Green-tek-A24962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/13/2024 7:15",
    "plant_id": "Green-tek-A24963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/13/2024 7:30",
    "plant_id": "Green-tek-A24964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "9/13/2024 7:45",
    "plant_id": "Green-tek-A24965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "7:45:00 AM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/13/2024 8:00",
    "plant_id": "Green-tek-A24966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "8:00:00 AM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "9/13/2024 8:15",
    "plant_id": "Green-tek-A24967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "8:15:00 AM",
    "power": 0.146,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "9/13/2024 8:30",
    "plant_id": "Green-tek-A24968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "8:30:00 AM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "9/13/2024 8:45",
    "plant_id": "Green-tek-A24969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "8:45:00 AM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "9/13/2024 9:00",
    "plant_id": "Green-tek-A24970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "9:00:00 AM",
    "power": 0.313,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "9/13/2024 9:15",
    "plant_id": "Green-tek-A24971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "9:15:00 AM",
    "power": 0.388,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "9/13/2024 9:30",
    "plant_id": "Green-tek-A24972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "9:30:00 AM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "9/13/2024 9:45",
    "plant_id": "Green-tek-A24973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "9:45:00 AM",
    "power": 0.56,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "9/13/2024 10:00",
    "plant_id": "Green-tek-A24974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "10:00:00 AM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "9/13/2024 10:15",
    "plant_id": "Green-tek-A24975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "10:15:00 AM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "9/13/2024 10:30",
    "plant_id": "Green-tek-A24976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "10:30:00 AM",
    "power": 0.834,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "9/13/2024 10:45",
    "plant_id": "Green-tek-A24977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "10:45:00 AM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "9/13/2024 11:00",
    "plant_id": "Green-tek-A24978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "11:00:00 AM",
    "power": 0.982,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "9/13/2024 11:15",
    "plant_id": "Green-tek-A24979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "11:15:00 AM",
    "power": 1.034,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "9/13/2024 11:30",
    "plant_id": "Green-tek-A24980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "11:30:00 AM",
    "power": 1.066,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "9/13/2024 11:45",
    "plant_id": "Green-tek-A24981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "11:45:00 AM",
    "power": 1.077,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "9/13/2024 12:00",
    "plant_id": "Green-tek-A24982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "12:00:00 PM",
    "power": 1.066,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "9/13/2024 12:15",
    "plant_id": "Green-tek-A24983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "12:15:00 PM",
    "power": 1.034,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "9/13/2024 12:30",
    "plant_id": "Green-tek-A24984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "12:30:00 PM",
    "power": 0.982,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "9/13/2024 12:45",
    "plant_id": "Green-tek-A24985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "12:45:00 PM",
    "power": 0.915,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "9/13/2024 13:00",
    "plant_id": "Green-tek-A24986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "1:00:00 PM",
    "power": 0.834,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "9/13/2024 13:15",
    "plant_id": "Green-tek-A24987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "1:15:00 PM",
    "power": 0.746,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.16626,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "9/13/2024 13:30",
    "plant_id": "Green-tek-A24988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "1:30:00 PM",
    "power": 0.653,
    "Energy": 0.163,
    "output Energy": 0.163,
    "Ac output": 1.163,
    "dc output": 1.18626,
    "AC_R_i": 0.00021261,
    "AC_S_i": 0.000233871,
    "AC_T_i": 0.00025726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0489003,
    "AC_S_pow": 0.05379033,
    "AC_T_Pow": 0.0591698,
    "AC_R_output": 0.3489,
    "AC_S_output": 0.38379,
    "AC_T_output": 0.422169,
    "dc_current": 0.00119853,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.16626,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000163,
    "carbon offset": 0.20375,
    "trees planted": 4.527777778,
    "DC_R_output": 0.115137,
    "DC_S_output": 0.1266507,
    "DC_T_output": 0.13931577
  },
  {
    "datetime": "9/13/2024 13:45",
    "plant_id": "Green-tek-A24989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "1:45:00 PM",
    "power": 0.56,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.12036,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "9/13/2024 14:00",
    "plant_id": "Green-tek-A24990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "2:00:00 PM",
    "power": 0.471,
    "Energy": 0.118,
    "output Energy": 0.118,
    "Ac output": 1.118,
    "dc output": 1.14036,
    "AC_R_i": 0.00015391,
    "AC_S_i": 0.000169301,
    "AC_T_i": 0.00018623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0353993,
    "AC_S_pow": 0.03893923,
    "AC_T_Pow": 0.0428329,
    "AC_R_output": 0.3354,
    "AC_S_output": 0.36894,
    "AC_T_output": 0.405834,
    "dc_current": 0.00086765,
    "dc_voltage": 136,
    "dc_power": 0.09894,
    "dc_output": 0.12036,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000118,
    "carbon offset": 0.1475,
    "trees planted": 3.277777778,
    "DC_R_output": 0.110682,
    "DC_S_output": 0.1217502,
    "DC_T_output": 0.13392522
  },
  {
    "datetime": "9/13/2024 14:15",
    "plant_id": "Green-tek-A24991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "2:15:00 PM",
    "power": 0.388,
    "Energy": 0.097,
    "output Energy": 0.097,
    "Ac output": 1.097,
    "dc output": 1.11894,
    "AC_R_i": 0.00012652,
    "AC_S_i": 0.000139172,
    "AC_T_i": 0.00015309,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0290996,
    "AC_S_pow": 0.03200956,
    "AC_T_Pow": 0.0352107,
    "AC_R_output": 0.3291,
    "AC_S_output": 0.36201,
    "AC_T_output": 0.398211,
    "dc_current": 0.00071324,
    "dc_voltage": 136,
    "dc_power": 0.07956,
    "dc_output": 0.09894,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000097,
    "carbon offset": 0.12125,
    "trees planted": 2.694444444,
    "DC_R_output": 0.108603,
    "DC_S_output": 0.1194633,
    "DC_T_output": 0.13140963
  },
  {
    "datetime": "9/13/2024 14:30",
    "plant_id": "Green-tek-A24992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "2:30:00 PM",
    "power": 0.313,
    "Energy": 0.078,
    "output Energy": 0.078,
    "Ac output": 1.078,
    "dc output": 1.09956,
    "AC_R_i": 0.00010174,
    "AC_S_i": 0.000111914,
    "AC_T_i": 0.00012311,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0234002,
    "AC_S_pow": 0.02574022,
    "AC_T_Pow": 0.0283153,
    "AC_R_output": 0.3234,
    "AC_S_output": 0.35574,
    "AC_T_output": 0.391314,
    "dc_current": 0.00057353,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07956,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000078,
    "carbon offset": 0.0975,
    "trees planted": 2.166666667,
    "DC_R_output": 0.106722,
    "DC_S_output": 0.1173942,
    "DC_T_output": 0.12913362
  },
  {
    "datetime": "9/13/2024 14:45",
    "plant_id": "Green-tek-A24993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "2:45:00 PM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "9/13/2024 15:00",
    "plant_id": "Green-tek-A24994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "3:00:00 PM",
    "power": 0.192,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "9/13/2024 15:15",
    "plant_id": "Green-tek-A24995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "3:15:00 PM",
    "power": 0.146,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "9/13/2024 15:30",
    "plant_id": "Green-tek-A24996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "3:30:00 PM",
    "power": 0.108,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "9/13/2024 15:45",
    "plant_id": "Green-tek-A24997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "3:45:00 PM",
    "power": 0.079,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/13/2024 16:00",
    "plant_id": "Green-tek-A24998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "9/13/2024 16:15",
    "plant_id": "Green-tek-A24999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/13/2024 16:30",
    "plant_id": "Green-tek-A25000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/13/2024 16:45",
    "plant_id": "Green-tek-A25001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/13/2024 17:00",
    "plant_id": "Green-tek-A25002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/13/2024 17:15",
    "plant_id": "Green-tek-A25003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/13/2024 17:30",
    "plant_id": "Green-tek-A25004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/13/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/14/2024 6:00",
    "plant_id": "Green-tek-A25005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/14/2024 6:15",
    "plant_id": "Green-tek-A25006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/14/2024 6:30",
    "plant_id": "Green-tek-A25007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/14/2024 6:45",
    "plant_id": "Green-tek-A25008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/14/2024 7:00",
    "plant_id": "Green-tek-A25009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/14/2024 7:15",
    "plant_id": "Green-tek-A25010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/14/2024 7:30",
    "plant_id": "Green-tek-A25011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/14/2024 7:45",
    "plant_id": "Green-tek-A25012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "7:45:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/14/2024 8:00",
    "plant_id": "Green-tek-A25013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "8:00:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/14/2024 8:15",
    "plant_id": "Green-tek-A25014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "8:15:00 AM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/14/2024 8:30",
    "plant_id": "Green-tek-A25015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "8:30:00 AM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "9/14/2024 8:45",
    "plant_id": "Green-tek-A25016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "8:45:00 AM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "9/14/2024 9:00",
    "plant_id": "Green-tek-A25017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "9:00:00 AM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "9/14/2024 9:15",
    "plant_id": "Green-tek-A25018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "9:15:00 AM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "9/14/2024 9:30",
    "plant_id": "Green-tek-A25019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "9:30:00 AM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "9/14/2024 9:45",
    "plant_id": "Green-tek-A25020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "9:45:00 AM",
    "power": 0.578,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "9/14/2024 10:00",
    "plant_id": "Green-tek-A25021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "10:00:00 AM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "9/14/2024 10:15",
    "plant_id": "Green-tek-A25022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "10:15:00 AM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "9/14/2024 10:30",
    "plant_id": "Green-tek-A25023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "10:30:00 AM",
    "power": 0.861,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "9/14/2024 10:45",
    "plant_id": "Green-tek-A25024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "10:45:00 AM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "9/14/2024 11:00",
    "plant_id": "Green-tek-A25025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "11:00:00 AM",
    "power": 1.014,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "9/14/2024 11:15",
    "plant_id": "Green-tek-A25026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "11:15:00 AM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "9/14/2024 11:30",
    "plant_id": "Green-tek-A25027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "11:30:00 AM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "9/14/2024 11:45",
    "plant_id": "Green-tek-A25028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "11:45:00 AM",
    "power": 1.112,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "9/14/2024 12:00",
    "plant_id": "Green-tek-A25029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "12:00:00 PM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "9/14/2024 12:15",
    "plant_id": "Green-tek-A25030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "12:15:00 PM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "9/14/2024 12:30",
    "plant_id": "Green-tek-A25031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "12:30:00 PM",
    "power": 1.014,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "9/14/2024 12:45",
    "plant_id": "Green-tek-A25032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "12:45:00 PM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "9/14/2024 13:00",
    "plant_id": "Green-tek-A25033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "1:00:00 PM",
    "power": 0.861,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "9/14/2024 13:15",
    "plant_id": "Green-tek-A25034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "1:15:00 PM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "9/14/2024 13:30",
    "plant_id": "Green-tek-A25035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "1:30:00 PM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "9/14/2024 13:45",
    "plant_id": "Green-tek-A25036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "1:45:00 PM",
    "power": 0.578,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "9/14/2024 14:00",
    "plant_id": "Green-tek-A25037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "2:00:00 PM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "9/14/2024 14:15",
    "plant_id": "Green-tek-A25038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "2:15:00 PM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "9/14/2024 14:30",
    "plant_id": "Green-tek-A25039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "2:30:00 PM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "9/14/2024 14:45",
    "plant_id": "Green-tek-A25040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "2:45:00 PM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "9/14/2024 15:00",
    "plant_id": "Green-tek-A25041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "3:00:00 PM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "9/14/2024 15:15",
    "plant_id": "Green-tek-A25042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "3:15:00 PM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/14/2024 15:30",
    "plant_id": "Green-tek-A25043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "3:30:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/14/2024 15:45",
    "plant_id": "Green-tek-A25044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "3:45:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/14/2024 16:00",
    "plant_id": "Green-tek-A25045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/14/2024 16:15",
    "plant_id": "Green-tek-A25046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/14/2024 16:30",
    "plant_id": "Green-tek-A25047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/14/2024 16:45",
    "plant_id": "Green-tek-A25048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/14/2024 17:00",
    "plant_id": "Green-tek-A25049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/14/2024 17:15",
    "plant_id": "Green-tek-A25050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/14/2024 17:30",
    "plant_id": "Green-tek-A25051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/14/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/15/2024 6:00",
    "plant_id": "Green-tek-A25052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/15/2024 6:15",
    "plant_id": "Green-tek-A25053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/15/2024 6:30",
    "plant_id": "Green-tek-A25054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/15/2024 6:45",
    "plant_id": "Green-tek-A25055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/15/2024 7:00",
    "plant_id": "Green-tek-A25056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/15/2024 7:15",
    "plant_id": "Green-tek-A25057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/15/2024 7:30",
    "plant_id": "Green-tek-A25058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/15/2024 7:45",
    "plant_id": "Green-tek-A25059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "7:45:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/15/2024 8:00",
    "plant_id": "Green-tek-A25060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "8:00:00 AM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/15/2024 8:15",
    "plant_id": "Green-tek-A25061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "8:15:00 AM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/15/2024 8:30",
    "plant_id": "Green-tek-A25062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "8:30:00 AM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "9/15/2024 8:45",
    "plant_id": "Green-tek-A25063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "8:45:00 AM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "9/15/2024 9:00",
    "plant_id": "Green-tek-A25064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "9:00:00 AM",
    "power": 0.33,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "9/15/2024 9:15",
    "plant_id": "Green-tek-A25065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "9:15:00 AM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "9/15/2024 9:30",
    "plant_id": "Green-tek-A25066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "9:30:00 AM",
    "power": 0.496,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "9/15/2024 9:45",
    "plant_id": "Green-tek-A25067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "9:45:00 AM",
    "power": 0.59,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "9/15/2024 10:00",
    "plant_id": "Green-tek-A25068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "10:00:00 AM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "9/15/2024 10:15",
    "plant_id": "Green-tek-A25069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "10:15:00 AM",
    "power": 0.785,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "9/15/2024 10:30",
    "plant_id": "Green-tek-A25070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "10:30:00 AM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "9/15/2024 10:45",
    "plant_id": "Green-tek-A25071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "10:45:00 AM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "9/15/2024 11:00",
    "plant_id": "Green-tek-A25072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "11:00:00 AM",
    "power": 1.034,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "9/15/2024 11:15",
    "plant_id": "Green-tek-A25073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "11:15:00 AM",
    "power": 1.089,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "9/15/2024 11:30",
    "plant_id": "Green-tek-A25074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "11:30:00 AM",
    "power": 1.122,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "9/15/2024 11:45",
    "plant_id": "Green-tek-A25075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "11:45:00 AM",
    "power": 1.134,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "9/15/2024 12:00",
    "plant_id": "Green-tek-A25076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "12:00:00 PM",
    "power": 1.122,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "9/15/2024 12:15",
    "plant_id": "Green-tek-A25077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "12:15:00 PM",
    "power": 1.089,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "9/15/2024 12:30",
    "plant_id": "Green-tek-A25078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "12:30:00 PM",
    "power": 1.034,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "9/15/2024 12:45",
    "plant_id": "Green-tek-A25079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "12:45:00 PM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "9/15/2024 13:00",
    "plant_id": "Green-tek-A25080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "1:00:00 PM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "9/15/2024 13:15",
    "plant_id": "Green-tek-A25081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "1:15:00 PM",
    "power": 0.785,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "9/15/2024 13:30",
    "plant_id": "Green-tek-A25082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "1:30:00 PM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "9/15/2024 13:45",
    "plant_id": "Green-tek-A25083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "1:45:00 PM",
    "power": 0.59,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "9/15/2024 14:00",
    "plant_id": "Green-tek-A25084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "2:00:00 PM",
    "power": 0.496,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "9/15/2024 14:15",
    "plant_id": "Green-tek-A25085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "2:15:00 PM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "9/15/2024 14:30",
    "plant_id": "Green-tek-A25086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "2:30:00 PM",
    "power": 0.33,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "9/15/2024 14:45",
    "plant_id": "Green-tek-A25087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "2:45:00 PM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "9/15/2024 15:00",
    "plant_id": "Green-tek-A25088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "3:00:00 PM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "9/15/2024 15:15",
    "plant_id": "Green-tek-A25089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "3:15:00 PM",
    "power": 0.153,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/15/2024 15:30",
    "plant_id": "Green-tek-A25090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "3:30:00 PM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/15/2024 15:45",
    "plant_id": "Green-tek-A25091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "3:45:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/15/2024 16:00",
    "plant_id": "Green-tek-A25092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/15/2024 16:15",
    "plant_id": "Green-tek-A25093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/15/2024 16:30",
    "plant_id": "Green-tek-A25094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/15/2024 16:45",
    "plant_id": "Green-tek-A25095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/15/2024 17:00",
    "plant_id": "Green-tek-A25096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/15/2024 17:15",
    "plant_id": "Green-tek-A25097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/15/2024 17:30",
    "plant_id": "Green-tek-A25098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/15/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/16/2024 6:00",
    "plant_id": "Green-tek-A25099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/16/2024 6:15",
    "plant_id": "Green-tek-A25100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/16/2024 6:30",
    "plant_id": "Green-tek-A25101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/16/2024 6:45",
    "plant_id": "Green-tek-A25102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/16/2024 7:00",
    "plant_id": "Green-tek-A25103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/16/2024 7:15",
    "plant_id": "Green-tek-A25104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/16/2024 7:30",
    "plant_id": "Green-tek-A25105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/16/2024 7:45",
    "plant_id": "Green-tek-A25106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/16/2024 8:00",
    "plant_id": "Green-tek-A25107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "8:00:00 AM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/16/2024 8:15",
    "plant_id": "Green-tek-A25108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "8:15:00 AM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "9/16/2024 8:30",
    "plant_id": "Green-tek-A25109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "8:30:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "9/16/2024 8:45",
    "plant_id": "Green-tek-A25110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "8:45:00 AM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "9/16/2024 9:00",
    "plant_id": "Green-tek-A25111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "9:00:00 AM",
    "power": 0.37,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "9/16/2024 9:15",
    "plant_id": "Green-tek-A25112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "9:15:00 AM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "9/16/2024 9:30",
    "plant_id": "Green-tek-A25113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "9:30:00 AM",
    "power": 0.557,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "9/16/2024 9:45",
    "plant_id": "Green-tek-A25114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "9:45:00 AM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "9/16/2024 10:00",
    "plant_id": "Green-tek-A25115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "10:00:00 AM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "9/16/2024 10:15",
    "plant_id": "Green-tek-A25116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "10:15:00 AM",
    "power": 0.882,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "9/16/2024 10:30",
    "plant_id": "Green-tek-A25117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "10:30:00 AM",
    "power": 0.986,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "9/16/2024 10:45",
    "plant_id": "Green-tek-A25118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "10:45:00 AM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "9/16/2024 11:00",
    "plant_id": "Green-tek-A25119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "11:00:00 AM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "9/16/2024 11:15",
    "plant_id": "Green-tek-A25120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "11:15:00 AM",
    "power": 1.222,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.3213,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "9/16/2024 11:30",
    "plant_id": "Green-tek-A25121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "11:30:00 AM",
    "power": 1.26,
    "Energy": 0.315,
    "output Energy": 0.315,
    "Ac output": 1.315,
    "dc output": 1.3413,
    "AC_R_i": 0.00041087,
    "AC_S_i": 0.000451957,
    "AC_T_i": 0.00049715,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0945001,
    "AC_S_pow": 0.10395011,
    "AC_T_Pow": 0.1143445,
    "AC_R_output": 0.3945,
    "AC_S_output": 0.43395,
    "AC_T_output": 0.477345,
    "dc_current": 0.00231618,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.3213,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000315,
    "carbon offset": 0.39375,
    "trees planted": 8.75,
    "DC_R_output": 0.130185,
    "DC_S_output": 0.1432035,
    "DC_T_output": 0.15752385
  },
  {
    "datetime": "9/16/2024 11:45",
    "plant_id": "Green-tek-A25122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "11:45:00 AM",
    "power": 1.273,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.3213,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "9/16/2024 12:00",
    "plant_id": "Green-tek-A25123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "12:00:00 PM",
    "power": 1.26,
    "Energy": 0.315,
    "output Energy": 0.315,
    "Ac output": 1.315,
    "dc output": 1.3413,
    "AC_R_i": 0.00041087,
    "AC_S_i": 0.000451957,
    "AC_T_i": 0.00049715,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0945001,
    "AC_S_pow": 0.10395011,
    "AC_T_Pow": 0.1143445,
    "AC_R_output": 0.3945,
    "AC_S_output": 0.43395,
    "AC_T_output": 0.477345,
    "dc_current": 0.00231618,
    "dc_voltage": 136,
    "dc_power": 0.31212,
    "dc_output": 0.3213,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000315,
    "carbon offset": 0.39375,
    "trees planted": 8.75,
    "DC_R_output": 0.130185,
    "DC_S_output": 0.1432035,
    "DC_T_output": 0.15752385
  },
  {
    "datetime": "9/16/2024 12:15",
    "plant_id": "Green-tek-A25124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "12:15:00 PM",
    "power": 1.222,
    "Energy": 0.306,
    "output Energy": 0.306,
    "Ac output": 1.306,
    "dc output": 1.33212,
    "AC_R_i": 0.00039913,
    "AC_S_i": 0.000439043,
    "AC_T_i": 0.00048295,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0917999,
    "AC_S_pow": 0.10097989,
    "AC_T_Pow": 0.1110785,
    "AC_R_output": 0.3918,
    "AC_S_output": 0.43098,
    "AC_T_output": 0.474078,
    "dc_current": 0.00225,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.31212,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000306,
    "carbon offset": 0.3825,
    "trees planted": 8.5,
    "DC_R_output": 0.129294,
    "DC_S_output": 0.1422234,
    "DC_T_output": 0.15644574
  },
  {
    "datetime": "9/16/2024 12:30",
    "plant_id": "Green-tek-A25125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "12:30:00 PM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "9/16/2024 12:45",
    "plant_id": "Green-tek-A25126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "12:45:00 PM",
    "power": 1.081,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "9/16/2024 13:00",
    "plant_id": "Green-tek-A25127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "1:00:00 PM",
    "power": 0.986,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "9/16/2024 13:15",
    "plant_id": "Green-tek-A25128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "1:15:00 PM",
    "power": 0.882,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "9/16/2024 13:30",
    "plant_id": "Green-tek-A25129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "1:30:00 PM",
    "power": 0.772,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.16932,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "9/16/2024 13:45",
    "plant_id": "Green-tek-A25130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "1:45:00 PM",
    "power": 0.663,
    "Energy": 0.166,
    "output Energy": 0.166,
    "Ac output": 1.166,
    "dc output": 1.18932,
    "AC_R_i": 0.00021652,
    "AC_S_i": 0.000238172,
    "AC_T_i": 0.00026199,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0497996,
    "AC_S_pow": 0.05477956,
    "AC_T_Pow": 0.0602577,
    "AC_R_output": 0.3498,
    "AC_S_output": 0.38478,
    "AC_T_output": 0.423258,
    "dc_current": 0.00122059,
    "dc_voltage": 136,
    "dc_power": 0.14178,
    "dc_output": 0.16932,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000166,
    "carbon offset": 0.2075,
    "trees planted": 4.611111111,
    "DC_R_output": 0.115434,
    "DC_S_output": 0.1269774,
    "DC_T_output": 0.13967514
  },
  {
    "datetime": "9/16/2024 14:00",
    "plant_id": "Green-tek-A25131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "2:00:00 PM",
    "power": 0.557,
    "Energy": 0.139,
    "output Energy": 0.139,
    "Ac output": 1.139,
    "dc output": 1.16178,
    "AC_R_i": 0.0001813,
    "AC_S_i": 0.00019943,
    "AC_T_i": 0.00021937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041699,
    "AC_S_pow": 0.0458689,
    "AC_T_Pow": 0.0504551,
    "AC_R_output": 0.3417,
    "AC_S_output": 0.37587,
    "AC_T_output": 0.413457,
    "dc_current": 0.00102206,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.14178,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000139,
    "carbon offset": 0.17375,
    "trees planted": 3.861111111,
    "DC_R_output": 0.112761,
    "DC_S_output": 0.1240371,
    "DC_T_output": 0.13644081
  },
  {
    "datetime": "9/16/2024 14:15",
    "plant_id": "Green-tek-A25132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "2:15:00 PM",
    "power": 0.459,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "9/16/2024 14:30",
    "plant_id": "Green-tek-A25133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "2:30:00 PM",
    "power": 0.37,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "9/16/2024 14:45",
    "plant_id": "Green-tek-A25134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "2:45:00 PM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "9/16/2024 15:00",
    "plant_id": "Green-tek-A25135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "3:00:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "9/16/2024 15:15",
    "plant_id": "Green-tek-A25136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "3:15:00 PM",
    "power": 0.172,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "9/16/2024 15:30",
    "plant_id": "Green-tek-A25137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "3:30:00 PM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/16/2024 15:45",
    "plant_id": "Green-tek-A25138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/16/2024 16:00",
    "plant_id": "Green-tek-A25139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/16/2024 16:15",
    "plant_id": "Green-tek-A25140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/16/2024 16:30",
    "plant_id": "Green-tek-A25141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/16/2024 16:45",
    "plant_id": "Green-tek-A25142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/16/2024 17:00",
    "plant_id": "Green-tek-A25143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/16/2024 17:15",
    "plant_id": "Green-tek-A25144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/16/2024 17:30",
    "plant_id": "Green-tek-A25145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/16/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/17/2024 6:00",
    "plant_id": "Green-tek-A25146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/17/2024 6:15",
    "plant_id": "Green-tek-A25147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/17/2024 6:30",
    "plant_id": "Green-tek-A25148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "6:30:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/17/2024 6:45",
    "plant_id": "Green-tek-A25149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/17/2024 7:00",
    "plant_id": "Green-tek-A25150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/17/2024 7:15",
    "plant_id": "Green-tek-A25151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/17/2024 7:30",
    "plant_id": "Green-tek-A25152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "7:30:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/17/2024 7:45",
    "plant_id": "Green-tek-A25153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "7:45:00 AM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/17/2024 8:00",
    "plant_id": "Green-tek-A25154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "8:00:00 AM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "9/17/2024 8:15",
    "plant_id": "Green-tek-A25155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "8:15:00 AM",
    "power": 0.178,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "9/17/2024 8:30",
    "plant_id": "Green-tek-A25156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "8:30:00 AM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "9/17/2024 8:45",
    "plant_id": "Green-tek-A25157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "8:45:00 AM",
    "power": 0.303,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "9/17/2024 9:00",
    "plant_id": "Green-tek-A25158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "9:00:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "9/17/2024 9:15",
    "plant_id": "Green-tek-A25159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "9:15:00 AM",
    "power": 0.475,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "9/17/2024 9:30",
    "plant_id": "Green-tek-A25160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "9:30:00 AM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "9/17/2024 9:45",
    "plant_id": "Green-tek-A25161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "9:45:00 AM",
    "power": 0.686,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "9/17/2024 10:00",
    "plant_id": "Green-tek-A25162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "10:00:00 AM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "9/17/2024 10:15",
    "plant_id": "Green-tek-A25163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "10:15:00 AM",
    "power": 0.913,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "9/17/2024 10:30",
    "plant_id": "Green-tek-A25164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "10:30:00 AM",
    "power": 1.022,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "9/17/2024 10:45",
    "plant_id": "Green-tek-A25165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "10:45:00 AM",
    "power": 1.12,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "9/17/2024 11:00",
    "plant_id": "Green-tek-A25166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "11:00:00 AM",
    "power": 1.203,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "9/17/2024 11:15",
    "plant_id": "Green-tek-A25167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "11:15:00 AM",
    "power": 1.266,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.33252,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "9/17/2024 11:30",
    "plant_id": "Green-tek-A25168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "11:30:00 AM",
    "power": 1.305,
    "Energy": 0.326,
    "output Energy": 0.326,
    "Ac output": 1.326,
    "dc output": 1.35252,
    "AC_R_i": 0.00042522,
    "AC_S_i": 0.000467742,
    "AC_T_i": 0.00051452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0978006,
    "AC_S_pow": 0.10758066,
    "AC_T_Pow": 0.1183396,
    "AC_R_output": 0.3978,
    "AC_S_output": 0.43758,
    "AC_T_output": 0.481338,
    "dc_current": 0.00239706,
    "dc_voltage": 136,
    "dc_power": 0.3366,
    "dc_output": 0.33252,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000326,
    "carbon offset": 0.4075,
    "trees planted": 9.055555556,
    "DC_R_output": 0.131274,
    "DC_S_output": 0.1444014,
    "DC_T_output": 0.15884154
  },
  {
    "datetime": "9/17/2024 11:45",
    "plant_id": "Green-tek-A25169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "11:45:00 AM",
    "power": 1.318,
    "Energy": 0.33,
    "output Energy": 0.33,
    "Ac output": 1.33,
    "dc output": 1.3566,
    "AC_R_i": 0.00043043,
    "AC_S_i": 0.000473473,
    "AC_T_i": 0.00052082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0989989,
    "AC_S_pow": 0.10889879,
    "AC_T_Pow": 0.1197886,
    "AC_R_output": 0.399,
    "AC_S_output": 0.4389,
    "AC_T_output": 0.48279,
    "dc_current": 0.00242647,
    "dc_voltage": 136,
    "dc_power": 0.33252,
    "dc_output": 0.3366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00033,
    "carbon offset": 0.4125,
    "trees planted": 9.166666667,
    "DC_R_output": 0.13167,
    "DC_S_output": 0.144837,
    "DC_T_output": 0.1593207
  },
  {
    "datetime": "9/17/2024 12:00",
    "plant_id": "Green-tek-A25170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "12:00:00 PM",
    "power": 1.305,
    "Energy": 0.326,
    "output Energy": 0.326,
    "Ac output": 1.326,
    "dc output": 1.35252,
    "AC_R_i": 0.00042522,
    "AC_S_i": 0.000467742,
    "AC_T_i": 0.00051452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0978006,
    "AC_S_pow": 0.10758066,
    "AC_T_Pow": 0.1183396,
    "AC_R_output": 0.3978,
    "AC_S_output": 0.43758,
    "AC_T_output": 0.481338,
    "dc_current": 0.00239706,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.33252,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000326,
    "carbon offset": 0.4075,
    "trees planted": 9.055555556,
    "DC_R_output": 0.131274,
    "DC_S_output": 0.1444014,
    "DC_T_output": 0.15884154
  },
  {
    "datetime": "9/17/2024 12:15",
    "plant_id": "Green-tek-A25171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "12:15:00 PM",
    "power": 1.266,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "9/17/2024 12:30",
    "plant_id": "Green-tek-A25172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "12:30:00 PM",
    "power": 1.203,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "9/17/2024 12:45",
    "plant_id": "Green-tek-A25173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "12:45:00 PM",
    "power": 1.12,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "9/17/2024 13:00",
    "plant_id": "Green-tek-A25174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "1:00:00 PM",
    "power": 1.022,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "9/17/2024 13:15",
    "plant_id": "Green-tek-A25175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "1:15:00 PM",
    "power": 0.913,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "9/17/2024 13:30",
    "plant_id": "Green-tek-A25176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "1:30:00 PM",
    "power": 0.8,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "9/17/2024 13:45",
    "plant_id": "Green-tek-A25177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "1:45:00 PM",
    "power": 0.686,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "9/17/2024 14:00",
    "plant_id": "Green-tek-A25178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "2:00:00 PM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "9/17/2024 14:15",
    "plant_id": "Green-tek-A25179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "2:15:00 PM",
    "power": 0.475,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "9/17/2024 14:30",
    "plant_id": "Green-tek-A25180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "2:30:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "9/17/2024 14:45",
    "plant_id": "Green-tek-A25181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "2:45:00 PM",
    "power": 0.303,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "9/17/2024 15:00",
    "plant_id": "Green-tek-A25182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "3:00:00 PM",
    "power": 0.235,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "9/17/2024 15:15",
    "plant_id": "Green-tek-A25183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "3:15:00 PM",
    "power": 0.178,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "9/17/2024 15:30",
    "plant_id": "Green-tek-A25184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "3:30:00 PM",
    "power": 0.133,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "9/17/2024 15:45",
    "plant_id": "Green-tek-A25185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "3:45:00 PM",
    "power": 0.097,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/17/2024 16:00",
    "plant_id": "Green-tek-A25186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "4:00:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/17/2024 16:15",
    "plant_id": "Green-tek-A25187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/17/2024 16:30",
    "plant_id": "Green-tek-A25188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/17/2024 16:45",
    "plant_id": "Green-tek-A25189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/17/2024 17:00",
    "plant_id": "Green-tek-A25190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "5:00:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/17/2024 17:15",
    "plant_id": "Green-tek-A25191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/17/2024 17:30",
    "plant_id": "Green-tek-A25192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/17/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/18/2024 6:00",
    "plant_id": "Green-tek-A25193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/18/2024 6:15",
    "plant_id": "Green-tek-A25194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/18/2024 6:30",
    "plant_id": "Green-tek-A25195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/18/2024 6:45",
    "plant_id": "Green-tek-A25196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/18/2024 7:00",
    "plant_id": "Green-tek-A25197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "7:00:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/18/2024 7:15",
    "plant_id": "Green-tek-A25198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/18/2024 7:30",
    "plant_id": "Green-tek-A25199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/18/2024 7:45",
    "plant_id": "Green-tek-A25200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "7:45:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/18/2024 8:00",
    "plant_id": "Green-tek-A25201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "8:00:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/18/2024 8:15",
    "plant_id": "Green-tek-A25202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "8:15:00 AM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/18/2024 8:30",
    "plant_id": "Green-tek-A25203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "8:30:00 AM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "9/18/2024 8:45",
    "plant_id": "Green-tek-A25204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "8:45:00 AM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "9/18/2024 9:00",
    "plant_id": "Green-tek-A25205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "9:00:00 AM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "9/18/2024 9:15",
    "plant_id": "Green-tek-A25206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "9:15:00 AM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "9/18/2024 9:30",
    "plant_id": "Green-tek-A25207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "9:30:00 AM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "9/18/2024 9:45",
    "plant_id": "Green-tek-A25208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "9:45:00 AM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "9/18/2024 10:00",
    "plant_id": "Green-tek-A25209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "10:00:00 AM",
    "power": 0.494,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "9/18/2024 10:15",
    "plant_id": "Green-tek-A25210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "10:15:00 AM",
    "power": 0.564,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "9/18/2024 10:30",
    "plant_id": "Green-tek-A25211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "10:30:00 AM",
    "power": 0.631,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "9/18/2024 10:45",
    "plant_id": "Green-tek-A25212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "10:45:00 AM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "9/18/2024 11:00",
    "plant_id": "Green-tek-A25213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "11:00:00 AM",
    "power": 0.743,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "9/18/2024 11:15",
    "plant_id": "Green-tek-A25214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "11:15:00 AM",
    "power": 0.781,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "9/18/2024 11:30",
    "plant_id": "Green-tek-A25215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "11:30:00 AM",
    "power": 0.806,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "9/18/2024 11:45",
    "plant_id": "Green-tek-A25216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "11:45:00 AM",
    "power": 0.814,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "9/18/2024 12:00",
    "plant_id": "Green-tek-A25217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "12:00:00 PM",
    "power": 0.806,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "9/18/2024 12:15",
    "plant_id": "Green-tek-A25218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "12:15:00 PM",
    "power": 0.781,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "9/18/2024 12:30",
    "plant_id": "Green-tek-A25219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "12:30:00 PM",
    "power": 0.743,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "9/18/2024 12:45",
    "plant_id": "Green-tek-A25220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "12:45:00 PM",
    "power": 0.691,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "9/18/2024 13:00",
    "plant_id": "Green-tek-A25221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "1:00:00 PM",
    "power": 0.631,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "9/18/2024 13:15",
    "plant_id": "Green-tek-A25222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "1:15:00 PM",
    "power": 0.564,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "9/18/2024 13:30",
    "plant_id": "Green-tek-A25223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "1:30:00 PM",
    "power": 0.494,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "9/18/2024 13:45",
    "plant_id": "Green-tek-A25224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "1:45:00 PM",
    "power": 0.424,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "9/18/2024 14:00",
    "plant_id": "Green-tek-A25225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "2:00:00 PM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "9/18/2024 14:15",
    "plant_id": "Green-tek-A25226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "2:15:00 PM",
    "power": 0.293,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "9/18/2024 14:30",
    "plant_id": "Green-tek-A25227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "2:30:00 PM",
    "power": 0.237,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "9/18/2024 14:45",
    "plant_id": "Green-tek-A25228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "2:45:00 PM",
    "power": 0.187,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "9/18/2024 15:00",
    "plant_id": "Green-tek-A25229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "3:00:00 PM",
    "power": 0.145,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "9/18/2024 15:15",
    "plant_id": "Green-tek-A25230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "3:15:00 PM",
    "power": 0.11,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/18/2024 15:30",
    "plant_id": "Green-tek-A25231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "3:30:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/18/2024 15:45",
    "plant_id": "Green-tek-A25232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "3:45:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/18/2024 16:00",
    "plant_id": "Green-tek-A25233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/18/2024 16:15",
    "plant_id": "Green-tek-A25234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/18/2024 16:30",
    "plant_id": "Green-tek-A25235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "4:30:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/18/2024 16:45",
    "plant_id": "Green-tek-A25236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/18/2024 17:00",
    "plant_id": "Green-tek-A25237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/18/2024 17:15",
    "plant_id": "Green-tek-A25238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/18/2024 17:30",
    "plant_id": "Green-tek-A25239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/18/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/19/2024 6:00",
    "plant_id": "Green-tek-A25240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/19/2024 6:15",
    "plant_id": "Green-tek-A25241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/19/2024 6:30",
    "plant_id": "Green-tek-A25242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/19/2024 6:45",
    "plant_id": "Green-tek-A25243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/19/2024 7:00",
    "plant_id": "Green-tek-A25244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/19/2024 7:15",
    "plant_id": "Green-tek-A25245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/19/2024 7:30",
    "plant_id": "Green-tek-A25246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "9/19/2024 7:45",
    "plant_id": "Green-tek-A25247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "9/19/2024 8:00",
    "plant_id": "Green-tek-A25248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "8:00:00 AM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "9/19/2024 8:15",
    "plant_id": "Green-tek-A25249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "8:15:00 AM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "9/19/2024 8:30",
    "plant_id": "Green-tek-A25250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "8:30:00 AM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/19/2024 8:45",
    "plant_id": "Green-tek-A25251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "8:45:00 AM",
    "power": 0.278,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "9/19/2024 9:00",
    "plant_id": "Green-tek-A25252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "9:00:00 AM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "9/19/2024 9:15",
    "plant_id": "Green-tek-A25253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "9:15:00 AM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "9/19/2024 9:30",
    "plant_id": "Green-tek-A25254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "9:30:00 AM",
    "power": 0.529,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "9/19/2024 9:45",
    "plant_id": "Green-tek-A25255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "9:45:00 AM",
    "power": 0.629,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "9/19/2024 10:00",
    "plant_id": "Green-tek-A25256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "10:00:00 AM",
    "power": 0.733,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "9/19/2024 10:15",
    "plant_id": "Green-tek-A25257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "10:15:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "9/19/2024 10:30",
    "plant_id": "Green-tek-A25258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "10:30:00 AM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "9/19/2024 10:45",
    "plant_id": "Green-tek-A25259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "10:45:00 AM",
    "power": 1.027,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "9/19/2024 11:00",
    "plant_id": "Green-tek-A25260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "11:00:00 AM",
    "power": 1.103,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "9/19/2024 11:15",
    "plant_id": "Green-tek-A25261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "11:15:00 AM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "9/19/2024 11:30",
    "plant_id": "Green-tek-A25262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "11:30:00 AM",
    "power": 1.197,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "9/19/2024 11:45",
    "plant_id": "Green-tek-A25263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "11:45:00 AM",
    "power": 1.209,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "9/19/2024 12:00",
    "plant_id": "Green-tek-A25264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "12:00:00 PM",
    "power": 1.197,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "9/19/2024 12:15",
    "plant_id": "Green-tek-A25265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "12:15:00 PM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "9/19/2024 12:30",
    "plant_id": "Green-tek-A25266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "12:30:00 PM",
    "power": 1.103,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "9/19/2024 12:45",
    "plant_id": "Green-tek-A25267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "12:45:00 PM",
    "power": 1.027,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "9/19/2024 13:00",
    "plant_id": "Green-tek-A25268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "1:00:00 PM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "9/19/2024 13:15",
    "plant_id": "Green-tek-A25269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "1:15:00 PM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "9/19/2024 13:30",
    "plant_id": "Green-tek-A25270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "1:30:00 PM",
    "power": 0.733,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "9/19/2024 13:45",
    "plant_id": "Green-tek-A25271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "1:45:00 PM",
    "power": 0.629,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "9/19/2024 14:00",
    "plant_id": "Green-tek-A25272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "2:00:00 PM",
    "power": 0.529,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "9/19/2024 14:15",
    "plant_id": "Green-tek-A25273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "2:15:00 PM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "9/19/2024 14:30",
    "plant_id": "Green-tek-A25274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "2:30:00 PM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "9/19/2024 14:45",
    "plant_id": "Green-tek-A25275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "2:45:00 PM",
    "power": 0.278,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "9/19/2024 15:00",
    "plant_id": "Green-tek-A25276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "3:00:00 PM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/19/2024 15:15",
    "plant_id": "Green-tek-A25277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "3:15:00 PM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "9/19/2024 15:30",
    "plant_id": "Green-tek-A25278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "3:30:00 PM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "9/19/2024 15:45",
    "plant_id": "Green-tek-A25279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "9/19/2024 16:00",
    "plant_id": "Green-tek-A25280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "9/19/2024 16:15",
    "plant_id": "Green-tek-A25281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/19/2024 16:30",
    "plant_id": "Green-tek-A25282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/19/2024 16:45",
    "plant_id": "Green-tek-A25283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/19/2024 17:00",
    "plant_id": "Green-tek-A25284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/19/2024 17:15",
    "plant_id": "Green-tek-A25285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/19/2024 17:30",
    "plant_id": "Green-tek-A25286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/19/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/20/2024 6:00",
    "plant_id": "Green-tek-A25287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/20/2024 6:15",
    "plant_id": "Green-tek-A25288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/20/2024 6:30",
    "plant_id": "Green-tek-A25289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/20/2024 6:45",
    "plant_id": "Green-tek-A25290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/20/2024 7:00",
    "plant_id": "Green-tek-A25291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/20/2024 7:15",
    "plant_id": "Green-tek-A25292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/20/2024 7:30",
    "plant_id": "Green-tek-A25293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/20/2024 7:45",
    "plant_id": "Green-tek-A25294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "7:45:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/20/2024 8:00",
    "plant_id": "Green-tek-A25295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "8:00:00 AM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/20/2024 8:15",
    "plant_id": "Green-tek-A25296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "8:15:00 AM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "9/20/2024 8:30",
    "plant_id": "Green-tek-A25297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "8:30:00 AM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "9/20/2024 8:45",
    "plant_id": "Green-tek-A25298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "8:45:00 AM",
    "power": 0.268,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "9/20/2024 9:00",
    "plant_id": "Green-tek-A25299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "9:00:00 AM",
    "power": 0.338,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "9/20/2024 9:15",
    "plant_id": "Green-tek-A25300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "9:15:00 AM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "9/20/2024 9:30",
    "plant_id": "Green-tek-A25301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "9:30:00 AM",
    "power": 0.509,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "9/20/2024 9:45",
    "plant_id": "Green-tek-A25302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "9:45:00 AM",
    "power": 0.605,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "9/20/2024 10:00",
    "plant_id": "Green-tek-A25303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "10:00:00 AM",
    "power": 0.706,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "9/20/2024 10:15",
    "plant_id": "Green-tek-A25304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "10:15:00 AM",
    "power": 0.806,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "9/20/2024 10:30",
    "plant_id": "Green-tek-A25305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "10:30:00 AM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "9/20/2024 10:45",
    "plant_id": "Green-tek-A25306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "10:45:00 AM",
    "power": 0.988,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "9/20/2024 11:00",
    "plant_id": "Green-tek-A25307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "11:00:00 AM",
    "power": 1.061,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "9/20/2024 11:15",
    "plant_id": "Green-tek-A25308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "11:15:00 AM",
    "power": 1.117,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "9/20/2024 11:30",
    "plant_id": "Green-tek-A25309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "11:30:00 AM",
    "power": 1.152,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.29682,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "9/20/2024 11:45",
    "plant_id": "Green-tek-A25310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "11:45:00 AM",
    "power": 1.163,
    "Energy": 0.291,
    "output Energy": 0.291,
    "Ac output": 1.291,
    "dc output": 1.31682,
    "AC_R_i": 0.00037957,
    "AC_S_i": 0.000417527,
    "AC_T_i": 0.00045928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0873011,
    "AC_S_pow": 0.09603121,
    "AC_T_Pow": 0.1056344,
    "AC_R_output": 0.3873,
    "AC_S_output": 0.42603,
    "AC_T_output": 0.468633,
    "dc_current": 0.00213971,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.29682,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000291,
    "carbon offset": 0.36375,
    "trees planted": 8.083333333,
    "DC_R_output": 0.127809,
    "DC_S_output": 0.1405899,
    "DC_T_output": 0.15464889
  },
  {
    "datetime": "9/20/2024 12:00",
    "plant_id": "Green-tek-A25311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "12:00:00 PM",
    "power": 1.152,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "9/20/2024 12:15",
    "plant_id": "Green-tek-A25312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "12:15:00 PM",
    "power": 1.117,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "9/20/2024 12:30",
    "plant_id": "Green-tek-A25313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "12:30:00 PM",
    "power": 1.061,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "9/20/2024 12:45",
    "plant_id": "Green-tek-A25314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "12:45:00 PM",
    "power": 0.988,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "9/20/2024 13:00",
    "plant_id": "Green-tek-A25315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "1:00:00 PM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "9/20/2024 13:15",
    "plant_id": "Green-tek-A25316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "1:15:00 PM",
    "power": 0.806,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "9/20/2024 13:30",
    "plant_id": "Green-tek-A25317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "1:30:00 PM",
    "power": 0.706,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "9/20/2024 13:45",
    "plant_id": "Green-tek-A25318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "1:45:00 PM",
    "power": 0.605,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "9/20/2024 14:00",
    "plant_id": "Green-tek-A25319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "2:00:00 PM",
    "power": 0.509,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "9/20/2024 14:15",
    "plant_id": "Green-tek-A25320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "2:15:00 PM",
    "power": 0.419,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "9/20/2024 14:30",
    "plant_id": "Green-tek-A25321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "2:30:00 PM",
    "power": 0.338,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "9/20/2024 14:45",
    "plant_id": "Green-tek-A25322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "2:45:00 PM",
    "power": 0.268,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "9/20/2024 15:00",
    "plant_id": "Green-tek-A25323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "3:00:00 PM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "9/20/2024 15:15",
    "plant_id": "Green-tek-A25324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "3:15:00 PM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "9/20/2024 15:30",
    "plant_id": "Green-tek-A25325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "3:30:00 PM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/20/2024 15:45",
    "plant_id": "Green-tek-A25326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "3:45:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/20/2024 16:00",
    "plant_id": "Green-tek-A25327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/20/2024 16:15",
    "plant_id": "Green-tek-A25328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/20/2024 16:30",
    "plant_id": "Green-tek-A25329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/20/2024 16:45",
    "plant_id": "Green-tek-A25330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/20/2024 17:00",
    "plant_id": "Green-tek-A25331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/20/2024 17:15",
    "plant_id": "Green-tek-A25332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/20/2024 17:30",
    "plant_id": "Green-tek-A25333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/20/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/21/2024 6:00",
    "plant_id": "Green-tek-A25334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/21/2024 6:15",
    "plant_id": "Green-tek-A25335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/21/2024 6:30",
    "plant_id": "Green-tek-A25336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/21/2024 6:45",
    "plant_id": "Green-tek-A25337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/21/2024 7:00",
    "plant_id": "Green-tek-A25338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/21/2024 7:15",
    "plant_id": "Green-tek-A25339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/21/2024 7:30",
    "plant_id": "Green-tek-A25340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/21/2024 7:45",
    "plant_id": "Green-tek-A25341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "7:45:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/21/2024 8:00",
    "plant_id": "Green-tek-A25342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "8:00:00 AM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/21/2024 8:15",
    "plant_id": "Green-tek-A25343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "8:15:00 AM",
    "power": 0.154,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/21/2024 8:30",
    "plant_id": "Green-tek-A25344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "8:30:00 AM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "9/21/2024 8:45",
    "plant_id": "Green-tek-A25345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "8:45:00 AM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "9/21/2024 9:00",
    "plant_id": "Green-tek-A25346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "9:00:00 AM",
    "power": 0.33,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "9/21/2024 9:15",
    "plant_id": "Green-tek-A25347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "9:15:00 AM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "9/21/2024 9:30",
    "plant_id": "Green-tek-A25348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "9:30:00 AM",
    "power": 0.496,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "9/21/2024 9:45",
    "plant_id": "Green-tek-A25349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "9:45:00 AM",
    "power": 0.59,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "9/21/2024 10:00",
    "plant_id": "Green-tek-A25350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "10:00:00 AM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "9/21/2024 10:15",
    "plant_id": "Green-tek-A25351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "10:15:00 AM",
    "power": 0.786,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "9/21/2024 10:30",
    "plant_id": "Green-tek-A25352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "10:30:00 AM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "9/21/2024 10:45",
    "plant_id": "Green-tek-A25353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "10:45:00 AM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "9/21/2024 11:00",
    "plant_id": "Green-tek-A25354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "11:00:00 AM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "9/21/2024 11:15",
    "plant_id": "Green-tek-A25355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "11:15:00 AM",
    "power": 1.089,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "9/21/2024 11:30",
    "plant_id": "Green-tek-A25356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "11:30:00 AM",
    "power": 1.123,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "9/21/2024 11:45",
    "plant_id": "Green-tek-A25357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "11:45:00 AM",
    "power": 1.134,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "9/21/2024 12:00",
    "plant_id": "Green-tek-A25358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "12:00:00 PM",
    "power": 1.123,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "9/21/2024 12:15",
    "plant_id": "Green-tek-A25359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "12:15:00 PM",
    "power": 1.089,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "9/21/2024 12:30",
    "plant_id": "Green-tek-A25360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "12:30:00 PM",
    "power": 1.035,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "9/21/2024 12:45",
    "plant_id": "Green-tek-A25361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "12:45:00 PM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "9/21/2024 13:00",
    "plant_id": "Green-tek-A25362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "1:00:00 PM",
    "power": 0.879,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "9/21/2024 13:15",
    "plant_id": "Green-tek-A25363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "1:15:00 PM",
    "power": 0.786,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "9/21/2024 13:30",
    "plant_id": "Green-tek-A25364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "1:30:00 PM",
    "power": 0.688,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "9/21/2024 13:45",
    "plant_id": "Green-tek-A25365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "1:45:00 PM",
    "power": 0.59,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "9/21/2024 14:00",
    "plant_id": "Green-tek-A25366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "2:00:00 PM",
    "power": 0.496,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "9/21/2024 14:15",
    "plant_id": "Green-tek-A25367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "2:15:00 PM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "9/21/2024 14:30",
    "plant_id": "Green-tek-A25368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "2:30:00 PM",
    "power": 0.33,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "9/21/2024 14:45",
    "plant_id": "Green-tek-A25369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "2:45:00 PM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "9/21/2024 15:00",
    "plant_id": "Green-tek-A25370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "3:00:00 PM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "9/21/2024 15:15",
    "plant_id": "Green-tek-A25371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "3:15:00 PM",
    "power": 0.154,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/21/2024 15:30",
    "plant_id": "Green-tek-A25372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "3:30:00 PM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/21/2024 15:45",
    "plant_id": "Green-tek-A25373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "3:45:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/21/2024 16:00",
    "plant_id": "Green-tek-A25374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/21/2024 16:15",
    "plant_id": "Green-tek-A25375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/21/2024 16:30",
    "plant_id": "Green-tek-A25376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/21/2024 16:45",
    "plant_id": "Green-tek-A25377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/21/2024 17:00",
    "plant_id": "Green-tek-A25378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/21/2024 17:15",
    "plant_id": "Green-tek-A25379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/21/2024 17:30",
    "plant_id": "Green-tek-A25380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/21/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/22/2024 6:00",
    "plant_id": "Green-tek-A25381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/22/2024 6:15",
    "plant_id": "Green-tek-A25382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/22/2024 6:30",
    "plant_id": "Green-tek-A25383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/22/2024 6:45",
    "plant_id": "Green-tek-A25384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/22/2024 7:00",
    "plant_id": "Green-tek-A25385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/22/2024 7:15",
    "plant_id": "Green-tek-A25386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/22/2024 7:30",
    "plant_id": "Green-tek-A25387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/22/2024 7:45",
    "plant_id": "Green-tek-A25388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "7:45:00 AM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/22/2024 8:00",
    "plant_id": "Green-tek-A25389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "8:00:00 AM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/22/2024 8:15",
    "plant_id": "Green-tek-A25390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "8:15:00 AM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/22/2024 8:30",
    "plant_id": "Green-tek-A25391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "8:30:00 AM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "9/22/2024 8:45",
    "plant_id": "Green-tek-A25392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "8:45:00 AM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "9/22/2024 9:00",
    "plant_id": "Green-tek-A25393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "9:00:00 AM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "9/22/2024 9:15",
    "plant_id": "Green-tek-A25394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "9:15:00 AM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "9/22/2024 9:30",
    "plant_id": "Green-tek-A25395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "9:30:00 AM",
    "power": 0.492,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "9/22/2024 9:45",
    "plant_id": "Green-tek-A25396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "9:45:00 AM",
    "power": 0.585,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "9/22/2024 10:00",
    "plant_id": "Green-tek-A25397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "10:00:00 AM",
    "power": 0.682,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "9/22/2024 10:15",
    "plant_id": "Green-tek-A25398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "10:15:00 AM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "9/22/2024 10:30",
    "plant_id": "Green-tek-A25399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "10:30:00 AM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "9/22/2024 10:45",
    "plant_id": "Green-tek-A25400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "10:45:00 AM",
    "power": 0.955,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "9/22/2024 11:00",
    "plant_id": "Green-tek-A25401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "11:00:00 AM",
    "power": 1.025,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "9/22/2024 11:15",
    "plant_id": "Green-tek-A25402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "11:15:00 AM",
    "power": 1.079,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "9/22/2024 11:30",
    "plant_id": "Green-tek-A25403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "11:30:00 AM",
    "power": 1.113,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "9/22/2024 11:45",
    "plant_id": "Green-tek-A25404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "11:45:00 AM",
    "power": 1.124,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "9/22/2024 12:00",
    "plant_id": "Green-tek-A25405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "12:00:00 PM",
    "power": 1.113,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.2754,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "9/22/2024 12:15",
    "plant_id": "Green-tek-A25406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "12:15:00 PM",
    "power": 1.079,
    "Energy": 0.27,
    "output Energy": 0.27,
    "Ac output": 1.27,
    "dc output": 1.2954,
    "AC_R_i": 0.00035217,
    "AC_S_i": 0.000387387,
    "AC_T_i": 0.00042613,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0809991,
    "AC_S_pow": 0.08909901,
    "AC_T_Pow": 0.0980099,
    "AC_R_output": 0.381,
    "AC_S_output": 0.4191,
    "AC_T_output": 0.46101,
    "dc_current": 0.00198529,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.2754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00027,
    "carbon offset": 0.3375,
    "trees planted": 7.5,
    "DC_R_output": 0.12573,
    "DC_S_output": 0.138303,
    "DC_T_output": 0.1521333
  },
  {
    "datetime": "9/22/2024 12:30",
    "plant_id": "Green-tek-A25407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "12:30:00 PM",
    "power": 1.025,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.24378,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "9/22/2024 12:45",
    "plant_id": "Green-tek-A25408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "12:45:00 PM",
    "power": 0.955,
    "Energy": 0.239,
    "output Energy": 0.239,
    "Ac output": 1.239,
    "dc output": 1.26378,
    "AC_R_i": 0.00031174,
    "AC_S_i": 0.000342914,
    "AC_T_i": 0.00037721,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0717002,
    "AC_S_pow": 0.07887022,
    "AC_T_Pow": 0.0867583,
    "AC_R_output": 0.3717,
    "AC_S_output": 0.40887,
    "AC_T_output": 0.449757,
    "dc_current": 0.00175735,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.24378,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000239,
    "carbon offset": 0.29875,
    "trees planted": 6.638888889,
    "DC_R_output": 0.122661,
    "DC_S_output": 0.1349271,
    "DC_T_output": 0.14841981
  },
  {
    "datetime": "9/22/2024 13:00",
    "plant_id": "Green-tek-A25409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "1:00:00 PM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "9/22/2024 13:15",
    "plant_id": "Green-tek-A25410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "1:15:00 PM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "9/22/2024 13:30",
    "plant_id": "Green-tek-A25411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "1:30:00 PM",
    "power": 0.682,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "9/22/2024 13:45",
    "plant_id": "Green-tek-A25412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "1:45:00 PM",
    "power": 0.585,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "9/22/2024 14:00",
    "plant_id": "Green-tek-A25413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "2:00:00 PM",
    "power": 0.492,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "9/22/2024 14:15",
    "plant_id": "Green-tek-A25414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "2:15:00 PM",
    "power": 0.405,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "9/22/2024 14:30",
    "plant_id": "Green-tek-A25415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "2:30:00 PM",
    "power": 0.327,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "9/22/2024 14:45",
    "plant_id": "Green-tek-A25416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "2:45:00 PM",
    "power": 0.259,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "9/22/2024 15:00",
    "plant_id": "Green-tek-A25417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "3:00:00 PM",
    "power": 0.2,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "9/22/2024 15:15",
    "plant_id": "Green-tek-A25418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "3:15:00 PM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/22/2024 15:30",
    "plant_id": "Green-tek-A25419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "3:30:00 PM",
    "power": 0.113,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/22/2024 15:45",
    "plant_id": "Green-tek-A25420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "3:45:00 PM",
    "power": 0.082,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/22/2024 16:00",
    "plant_id": "Green-tek-A25421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/22/2024 16:15",
    "plant_id": "Green-tek-A25422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/22/2024 16:30",
    "plant_id": "Green-tek-A25423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/22/2024 16:45",
    "plant_id": "Green-tek-A25424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/22/2024 17:00",
    "plant_id": "Green-tek-A25425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/22/2024 17:15",
    "plant_id": "Green-tek-A25426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/22/2024 17:30",
    "plant_id": "Green-tek-A25427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/22/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/23/2024 6:00",
    "plant_id": "Green-tek-A25428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/23/2024 6:15",
    "plant_id": "Green-tek-A25429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/23/2024 6:30",
    "plant_id": "Green-tek-A25430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/23/2024 6:45",
    "plant_id": "Green-tek-A25431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/23/2024 7:00",
    "plant_id": "Green-tek-A25432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/23/2024 7:15",
    "plant_id": "Green-tek-A25433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/23/2024 7:30",
    "plant_id": "Green-tek-A25434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/23/2024 7:45",
    "plant_id": "Green-tek-A25435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/23/2024 8:00",
    "plant_id": "Green-tek-A25436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "8:00:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/23/2024 8:15",
    "plant_id": "Green-tek-A25437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "8:15:00 AM",
    "power": 0.15,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "9/23/2024 8:30",
    "plant_id": "Green-tek-A25438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "8:30:00 AM",
    "power": 0.198,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "9/23/2024 8:45",
    "plant_id": "Green-tek-A25439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "8:45:00 AM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "9/23/2024 9:00",
    "plant_id": "Green-tek-A25440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "9:00:00 AM",
    "power": 0.322,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "9/23/2024 9:15",
    "plant_id": "Green-tek-A25441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "9:15:00 AM",
    "power": 0.399,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "9/23/2024 9:30",
    "plant_id": "Green-tek-A25442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "9:30:00 AM",
    "power": 0.485,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "9/23/2024 9:45",
    "plant_id": "Green-tek-A25443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "9:45:00 AM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "9/23/2024 10:00",
    "plant_id": "Green-tek-A25444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "10:00:00 AM",
    "power": 0.672,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "9/23/2024 10:15",
    "plant_id": "Green-tek-A25445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "10:15:00 AM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "9/23/2024 10:30",
    "plant_id": "Green-tek-A25446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "10:30:00 AM",
    "power": 0.859,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "9/23/2024 10:45",
    "plant_id": "Green-tek-A25447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "10:45:00 AM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "9/23/2024 11:00",
    "plant_id": "Green-tek-A25448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "11:00:00 AM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "9/23/2024 11:15",
    "plant_id": "Green-tek-A25449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "11:15:00 AM",
    "power": 1.064,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "9/23/2024 11:30",
    "plant_id": "Green-tek-A25450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "11:30:00 AM",
    "power": 1.097,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "9/23/2024 11:45",
    "plant_id": "Green-tek-A25451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "11:45:00 AM",
    "power": 1.108,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "9/23/2024 12:00",
    "plant_id": "Green-tek-A25452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "12:00:00 PM",
    "power": 1.097,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "9/23/2024 12:15",
    "plant_id": "Green-tek-A25453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "12:15:00 PM",
    "power": 1.064,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "9/23/2024 12:30",
    "plant_id": "Green-tek-A25454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "12:30:00 PM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2397,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "9/23/2024 12:45",
    "plant_id": "Green-tek-A25455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "12:45:00 PM",
    "power": 0.941,
    "Energy": 0.235,
    "output Energy": 0.235,
    "Ac output": 1.235,
    "dc output": 1.2597,
    "AC_R_i": 0.00030652,
    "AC_S_i": 0.000337172,
    "AC_T_i": 0.00037089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0704996,
    "AC_S_pow": 0.07754956,
    "AC_T_Pow": 0.0853047,
    "AC_R_output": 0.3705,
    "AC_S_output": 0.40755,
    "AC_T_output": 0.448305,
    "dc_current": 0.00172794,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.2397,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000235,
    "carbon offset": 0.29375,
    "trees planted": 6.527777778,
    "DC_R_output": 0.122265,
    "DC_S_output": 0.1344915,
    "DC_T_output": 0.14794065
  },
  {
    "datetime": "9/23/2024 13:00",
    "plant_id": "Green-tek-A25456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "1:00:00 PM",
    "power": 0.859,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "9/23/2024 13:15",
    "plant_id": "Green-tek-A25457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "1:15:00 PM",
    "power": 0.768,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "9/23/2024 13:30",
    "plant_id": "Green-tek-A25458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "1:30:00 PM",
    "power": 0.672,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "9/23/2024 13:45",
    "plant_id": "Green-tek-A25459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "1:45:00 PM",
    "power": 0.577,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "9/23/2024 14:00",
    "plant_id": "Green-tek-A25460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "2:00:00 PM",
    "power": 0.485,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "9/23/2024 14:15",
    "plant_id": "Green-tek-A25461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "2:15:00 PM",
    "power": 0.399,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "9/23/2024 14:30",
    "plant_id": "Green-tek-A25462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "2:30:00 PM",
    "power": 0.322,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "9/23/2024 14:45",
    "plant_id": "Green-tek-A25463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "2:45:00 PM",
    "power": 0.255,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "9/23/2024 15:00",
    "plant_id": "Green-tek-A25464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "3:00:00 PM",
    "power": 0.198,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "9/23/2024 15:15",
    "plant_id": "Green-tek-A25465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "3:15:00 PM",
    "power": 0.15,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "9/23/2024 15:30",
    "plant_id": "Green-tek-A25466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "3:30:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "9/23/2024 15:45",
    "plant_id": "Green-tek-A25467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "9/23/2024 16:00",
    "plant_id": "Green-tek-A25468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/23/2024 16:15",
    "plant_id": "Green-tek-A25469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/23/2024 16:30",
    "plant_id": "Green-tek-A25470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/23/2024 16:45",
    "plant_id": "Green-tek-A25471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/23/2024 17:00",
    "plant_id": "Green-tek-A25472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/23/2024 17:15",
    "plant_id": "Green-tek-A25473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/23/2024 17:30",
    "plant_id": "Green-tek-A25474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/23/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/24/2024 6:00",
    "plant_id": "Green-tek-A25475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/24/2024 6:15",
    "plant_id": "Green-tek-A25476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/24/2024 6:30",
    "plant_id": "Green-tek-A25477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/24/2024 6:45",
    "plant_id": "Green-tek-A25478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/24/2024 7:00",
    "plant_id": "Green-tek-A25479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/24/2024 7:15",
    "plant_id": "Green-tek-A25480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "7:15:00 AM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "9/24/2024 7:30",
    "plant_id": "Green-tek-A25481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "7:30:00 AM",
    "power": 0.05,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/24/2024 7:45",
    "plant_id": "Green-tek-A25482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "7:45:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/24/2024 8:00",
    "plant_id": "Green-tek-A25483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "8:00:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/24/2024 8:15",
    "plant_id": "Green-tek-A25484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "8:15:00 AM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/24/2024 8:30",
    "plant_id": "Green-tek-A25485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "8:30:00 AM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "9/24/2024 8:45",
    "plant_id": "Green-tek-A25486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "8:45:00 AM",
    "power": 0.218,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/24/2024 9:00",
    "plant_id": "Green-tek-A25487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "9:00:00 AM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "9/24/2024 9:15",
    "plant_id": "Green-tek-A25488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "9:15:00 AM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "9/24/2024 9:30",
    "plant_id": "Green-tek-A25489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "9:30:00 AM",
    "power": 0.414,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "9/24/2024 9:45",
    "plant_id": "Green-tek-A25490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "9:45:00 AM",
    "power": 0.493,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "9/24/2024 10:00",
    "plant_id": "Green-tek-A25491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "10:00:00 AM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "9/24/2024 10:15",
    "plant_id": "Green-tek-A25492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "10:15:00 AM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "9/24/2024 10:30",
    "plant_id": "Green-tek-A25493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "10:30:00 AM",
    "power": 0.734,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "9/24/2024 10:45",
    "plant_id": "Green-tek-A25494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "10:45:00 AM",
    "power": 0.805,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "9/24/2024 11:00",
    "plant_id": "Green-tek-A25495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "11:00:00 AM",
    "power": 0.864,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "9/24/2024 11:15",
    "plant_id": "Green-tek-A25496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "11:15:00 AM",
    "power": 0.909,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "9/24/2024 11:30",
    "plant_id": "Green-tek-A25497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "11:30:00 AM",
    "power": 0.938,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "9/24/2024 11:45",
    "plant_id": "Green-tek-A25498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "11:45:00 AM",
    "power": 0.947,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "9/24/2024 12:00",
    "plant_id": "Green-tek-A25499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "12:00:00 PM",
    "power": 0.938,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.23154,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "9/24/2024 12:15",
    "plant_id": "Green-tek-A25500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "12:15:00 PM",
    "power": 0.909,
    "Energy": 0.227,
    "output Energy": 0.227,
    "Ac output": 1.227,
    "dc output": 1.25154,
    "AC_R_i": 0.00029609,
    "AC_S_i": 0.000325699,
    "AC_T_i": 0.00035827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0681007,
    "AC_S_pow": 0.07491077,
    "AC_T_Pow": 0.0824021,
    "AC_R_output": 0.3681,
    "AC_S_output": 0.40491,
    "AC_T_output": 0.445401,
    "dc_current": 0.00166912,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.23154,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000227,
    "carbon offset": 0.28375,
    "trees planted": 6.305555556,
    "DC_R_output": 0.121473,
    "DC_S_output": 0.1336203,
    "DC_T_output": 0.14698233
  },
  {
    "datetime": "9/24/2024 12:30",
    "plant_id": "Green-tek-A25501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "12:30:00 PM",
    "power": 0.864,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "9/24/2024 12:45",
    "plant_id": "Green-tek-A25502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "12:45:00 PM",
    "power": 0.805,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "9/24/2024 13:00",
    "plant_id": "Green-tek-A25503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "1:00:00 PM",
    "power": 0.734,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "9/24/2024 13:15",
    "plant_id": "Green-tek-A25504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "1:15:00 PM",
    "power": 0.656,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "9/24/2024 13:30",
    "plant_id": "Green-tek-A25505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "1:30:00 PM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12546,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "9/24/2024 13:45",
    "plant_id": "Green-tek-A25506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "1:45:00 PM",
    "power": 0.493,
    "Energy": 0.123,
    "output Energy": 0.123,
    "Ac output": 1.123,
    "dc output": 1.14546,
    "AC_R_i": 0.00016043,
    "AC_S_i": 0.000176473,
    "AC_T_i": 0.00019412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0368989,
    "AC_S_pow": 0.04058879,
    "AC_T_Pow": 0.0446476,
    "AC_R_output": 0.3369,
    "AC_S_output": 0.37059,
    "AC_T_output": 0.407649,
    "dc_current": 0.00090441,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12546,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000123,
    "carbon offset": 0.15375,
    "trees planted": 3.416666667,
    "DC_R_output": 0.111177,
    "DC_S_output": 0.1222947,
    "DC_T_output": 0.13452417
  },
  {
    "datetime": "9/24/2024 14:00",
    "plant_id": "Green-tek-A25507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "2:00:00 PM",
    "power": 0.414,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.0867,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "9/24/2024 14:15",
    "plant_id": "Green-tek-A25508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "2:15:00 PM",
    "power": 0.341,
    "Energy": 0.085,
    "output Energy": 0.085,
    "Ac output": 1.085,
    "dc output": 1.1067,
    "AC_R_i": 0.00011087,
    "AC_S_i": 0.000121957,
    "AC_T_i": 0.00013415,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0255001,
    "AC_S_pow": 0.02805011,
    "AC_T_Pow": 0.0308545,
    "AC_R_output": 0.3255,
    "AC_S_output": 0.35805,
    "AC_T_output": 0.393855,
    "dc_current": 0.000625,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.0867,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000085,
    "carbon offset": 0.10625,
    "trees planted": 2.361111111,
    "DC_R_output": 0.107415,
    "DC_S_output": 0.1181565,
    "DC_T_output": 0.12997215
  },
  {
    "datetime": "9/24/2024 14:30",
    "plant_id": "Green-tek-A25509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "2:30:00 PM",
    "power": 0.276,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "9/24/2024 14:45",
    "plant_id": "Green-tek-A25510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "2:45:00 PM",
    "power": 0.218,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "9/24/2024 15:00",
    "plant_id": "Green-tek-A25511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "3:00:00 PM",
    "power": 0.169,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "9/24/2024 15:15",
    "plant_id": "Green-tek-A25512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "3:15:00 PM",
    "power": 0.128,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/24/2024 15:30",
    "plant_id": "Green-tek-A25513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "3:30:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/24/2024 15:45",
    "plant_id": "Green-tek-A25514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "3:45:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/24/2024 16:00",
    "plant_id": "Green-tek-A25515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "4:00:00 PM",
    "power": 0.05,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/24/2024 16:15",
    "plant_id": "Green-tek-A25516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "4:15:00 PM",
    "power": 0.035,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "9/24/2024 16:30",
    "plant_id": "Green-tek-A25517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/24/2024 16:45",
    "plant_id": "Green-tek-A25518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/24/2024 17:00",
    "plant_id": "Green-tek-A25519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/24/2024 17:15",
    "plant_id": "Green-tek-A25520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/24/2024 17:30",
    "plant_id": "Green-tek-A25521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/24/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/25/2024 6:00",
    "plant_id": "Green-tek-A25522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/25/2024 6:15",
    "plant_id": "Green-tek-A25523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/25/2024 6:30",
    "plant_id": "Green-tek-A25524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/25/2024 6:45",
    "plant_id": "Green-tek-A25525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/25/2024 7:00",
    "plant_id": "Green-tek-A25526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/25/2024 7:15",
    "plant_id": "Green-tek-A25527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/25/2024 7:30",
    "plant_id": "Green-tek-A25528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/25/2024 7:45",
    "plant_id": "Green-tek-A25529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "7:45:00 AM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/25/2024 8:00",
    "plant_id": "Green-tek-A25530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "8:00:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/25/2024 8:15",
    "plant_id": "Green-tek-A25531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "8:15:00 AM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "9/25/2024 8:30",
    "plant_id": "Green-tek-A25532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "8:30:00 AM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "9/25/2024 8:45",
    "plant_id": "Green-tek-A25533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "8:45:00 AM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "9/25/2024 9:00",
    "plant_id": "Green-tek-A25534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "9:00:00 AM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "9/25/2024 9:15",
    "plant_id": "Green-tek-A25535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "9:15:00 AM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "9/25/2024 9:30",
    "plant_id": "Green-tek-A25536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "9:30:00 AM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "9/25/2024 9:45",
    "plant_id": "Green-tek-A25537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "9:45:00 AM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "9/25/2024 10:00",
    "plant_id": "Green-tek-A25538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "10:00:00 AM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "9/25/2024 10:15",
    "plant_id": "Green-tek-A25539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "10:15:00 AM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "9/25/2024 10:30",
    "plant_id": "Green-tek-A25540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "10:30:00 AM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "9/25/2024 10:45",
    "plant_id": "Green-tek-A25541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "10:45:00 AM",
    "power": 1.091,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "9/25/2024 11:00",
    "plant_id": "Green-tek-A25542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "11:00:00 AM",
    "power": 1.172,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "9/25/2024 11:15",
    "plant_id": "Green-tek-A25543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "11:15:00 AM",
    "power": 1.233,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "9/25/2024 11:30",
    "plant_id": "Green-tek-A25544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "11:30:00 AM",
    "power": 1.272,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "9/25/2024 11:45",
    "plant_id": "Green-tek-A25545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "11:45:00 AM",
    "power": 1.285,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "9/25/2024 12:00",
    "plant_id": "Green-tek-A25546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "12:00:00 PM",
    "power": 1.272,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.31416,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "9/25/2024 12:15",
    "plant_id": "Green-tek-A25547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "12:15:00 PM",
    "power": 1.233,
    "Energy": 0.308,
    "output Energy": 0.308,
    "Ac output": 1.308,
    "dc output": 1.33416,
    "AC_R_i": 0.00040174,
    "AC_S_i": 0.000441914,
    "AC_T_i": 0.00048611,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0924002,
    "AC_S_pow": 0.10164022,
    "AC_T_Pow": 0.1118053,
    "AC_R_output": 0.3924,
    "AC_S_output": 0.43164,
    "AC_T_output": 0.474804,
    "dc_current": 0.00226471,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.31416,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000308,
    "carbon offset": 0.385,
    "trees planted": 8.555555556,
    "DC_R_output": 0.129492,
    "DC_S_output": 0.1424412,
    "DC_T_output": 0.15668532
  },
  {
    "datetime": "9/25/2024 12:30",
    "plant_id": "Green-tek-A25548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "12:30:00 PM",
    "power": 1.172,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "9/25/2024 12:45",
    "plant_id": "Green-tek-A25549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "12:45:00 PM",
    "power": 1.091,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "9/25/2024 13:00",
    "plant_id": "Green-tek-A25550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "1:00:00 PM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "9/25/2024 13:15",
    "plant_id": "Green-tek-A25551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "1:15:00 PM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "9/25/2024 13:30",
    "plant_id": "Green-tek-A25552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "1:30:00 PM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "9/25/2024 13:45",
    "plant_id": "Green-tek-A25553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "1:45:00 PM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "9/25/2024 14:00",
    "plant_id": "Green-tek-A25554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "2:00:00 PM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "9/25/2024 14:15",
    "plant_id": "Green-tek-A25555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "2:15:00 PM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "9/25/2024 14:30",
    "plant_id": "Green-tek-A25556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "2:30:00 PM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "9/25/2024 14:45",
    "plant_id": "Green-tek-A25557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "2:45:00 PM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "9/25/2024 15:00",
    "plant_id": "Green-tek-A25558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "3:00:00 PM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "9/25/2024 15:15",
    "plant_id": "Green-tek-A25559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "3:15:00 PM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "9/25/2024 15:30",
    "plant_id": "Green-tek-A25560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "3:30:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/25/2024 15:45",
    "plant_id": "Green-tek-A25561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "3:45:00 PM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "9/25/2024 16:00",
    "plant_id": "Green-tek-A25562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/25/2024 16:15",
    "plant_id": "Green-tek-A25563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/25/2024 16:30",
    "plant_id": "Green-tek-A25564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/25/2024 16:45",
    "plant_id": "Green-tek-A25565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/25/2024 17:00",
    "plant_id": "Green-tek-A25566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/25/2024 17:15",
    "plant_id": "Green-tek-A25567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/25/2024 17:30",
    "plant_id": "Green-tek-A25568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/25/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/26/2024 6:00",
    "plant_id": "Green-tek-A25569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/26/2024 6:15",
    "plant_id": "Green-tek-A25570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/26/2024 6:30",
    "plant_id": "Green-tek-A25571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/26/2024 6:45",
    "plant_id": "Green-tek-A25572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/26/2024 7:00",
    "plant_id": "Green-tek-A25573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/26/2024 7:15",
    "plant_id": "Green-tek-A25574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/26/2024 7:30",
    "plant_id": "Green-tek-A25575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "9/26/2024 7:45",
    "plant_id": "Green-tek-A25576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "7:45:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "9/26/2024 8:00",
    "plant_id": "Green-tek-A25577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "8:00:00 AM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "9/26/2024 8:15",
    "plant_id": "Green-tek-A25578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "8:15:00 AM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "9/26/2024 8:30",
    "plant_id": "Green-tek-A25579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "8:30:00 AM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "9/26/2024 8:45",
    "plant_id": "Green-tek-A25580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "8:45:00 AM",
    "power": 0.274,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "9/26/2024 9:00",
    "plant_id": "Green-tek-A25581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "9:00:00 AM",
    "power": 0.346,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "9/26/2024 9:15",
    "plant_id": "Green-tek-A25582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "9:15:00 AM",
    "power": 0.429,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "9/26/2024 9:30",
    "plant_id": "Green-tek-A25583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "9:30:00 AM",
    "power": 0.521,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "9/26/2024 9:45",
    "plant_id": "Green-tek-A25584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "9:45:00 AM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "9/26/2024 10:00",
    "plant_id": "Green-tek-A25585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "10:00:00 AM",
    "power": 0.722,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "9/26/2024 10:15",
    "plant_id": "Green-tek-A25586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "10:15:00 AM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "9/26/2024 10:30",
    "plant_id": "Green-tek-A25587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "10:30:00 AM",
    "power": 0.923,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "9/26/2024 10:45",
    "plant_id": "Green-tek-A25588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "10:45:00 AM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "9/26/2024 11:00",
    "plant_id": "Green-tek-A25589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "11:00:00 AM",
    "power": 1.086,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "9/26/2024 11:15",
    "plant_id": "Green-tek-A25590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "11:15:00 AM",
    "power": 1.143,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "9/26/2024 11:30",
    "plant_id": "Green-tek-A25591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "11:30:00 AM",
    "power": 1.179,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "9/26/2024 11:45",
    "plant_id": "Green-tek-A25592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "11:45:00 AM",
    "power": 1.191,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.3009,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "9/26/2024 12:00",
    "plant_id": "Green-tek-A25593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "12:00:00 PM",
    "power": 1.179,
    "Energy": 0.295,
    "output Energy": 0.295,
    "Ac output": 1.295,
    "dc output": 1.3209,
    "AC_R_i": 0.00038478,
    "AC_S_i": 0.000423258,
    "AC_T_i": 0.00046558,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0884994,
    "AC_S_pow": 0.09734934,
    "AC_T_Pow": 0.1070834,
    "AC_R_output": 0.3885,
    "AC_S_output": 0.42735,
    "AC_T_output": 0.470085,
    "dc_current": 0.00216912,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.3009,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000295,
    "carbon offset": 0.36875,
    "trees planted": 8.194444444,
    "DC_R_output": 0.128205,
    "DC_S_output": 0.1410255,
    "DC_T_output": 0.15512805
  },
  {
    "datetime": "9/26/2024 12:15",
    "plant_id": "Green-tek-A25594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "12:15:00 PM",
    "power": 1.143,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "9/26/2024 12:30",
    "plant_id": "Green-tek-A25595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "12:30:00 PM",
    "power": 1.086,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "9/26/2024 12:45",
    "plant_id": "Green-tek-A25596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "12:45:00 PM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "9/26/2024 13:00",
    "plant_id": "Green-tek-A25597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "1:00:00 PM",
    "power": 0.923,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "9/26/2024 13:15",
    "plant_id": "Green-tek-A25598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "1:15:00 PM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "9/26/2024 13:30",
    "plant_id": "Green-tek-A25599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "1:30:00 PM",
    "power": 0.722,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "9/26/2024 13:45",
    "plant_id": "Green-tek-A25600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "1:45:00 PM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "9/26/2024 14:00",
    "plant_id": "Green-tek-A25601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "2:00:00 PM",
    "power": 0.521,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "9/26/2024 14:15",
    "plant_id": "Green-tek-A25602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "2:15:00 PM",
    "power": 0.429,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.08874,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "9/26/2024 14:30",
    "plant_id": "Green-tek-A25603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "2:30:00 PM",
    "power": 0.346,
    "Energy": 0.087,
    "output Energy": 0.087,
    "Ac output": 1.087,
    "dc output": 1.10874,
    "AC_R_i": 0.00011348,
    "AC_S_i": 0.000124828,
    "AC_T_i": 0.00013731,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0261004,
    "AC_S_pow": 0.02871044,
    "AC_T_Pow": 0.0315813,
    "AC_R_output": 0.3261,
    "AC_S_output": 0.35871,
    "AC_T_output": 0.394581,
    "dc_current": 0.00063971,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08874,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000087,
    "carbon offset": 0.10875,
    "trees planted": 2.416666667,
    "DC_R_output": 0.107613,
    "DC_S_output": 0.1183743,
    "DC_T_output": 0.13021173
  },
  {
    "datetime": "9/26/2024 14:45",
    "plant_id": "Green-tek-A25604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "2:45:00 PM",
    "power": 0.274,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "9/26/2024 15:00",
    "plant_id": "Green-tek-A25605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "3:00:00 PM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "9/26/2024 15:15",
    "plant_id": "Green-tek-A25606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "3:15:00 PM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "9/26/2024 15:30",
    "plant_id": "Green-tek-A25607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "3:30:00 PM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "9/26/2024 15:45",
    "plant_id": "Green-tek-A25608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "3:45:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "9/26/2024 16:00",
    "plant_id": "Green-tek-A25609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "9/26/2024 16:15",
    "plant_id": "Green-tek-A25610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "9/26/2024 16:30",
    "plant_id": "Green-tek-A25611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/26/2024 16:45",
    "plant_id": "Green-tek-A25612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/26/2024 17:00",
    "plant_id": "Green-tek-A25613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/26/2024 17:15",
    "plant_id": "Green-tek-A25614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/26/2024 17:30",
    "plant_id": "Green-tek-A25615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/26/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/27/2024 6:00",
    "plant_id": "Green-tek-A25616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/27/2024 6:15",
    "plant_id": "Green-tek-A25617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/27/2024 6:30",
    "plant_id": "Green-tek-A25618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/27/2024 6:45",
    "plant_id": "Green-tek-A25619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/27/2024 7:00",
    "plant_id": "Green-tek-A25620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/27/2024 7:15",
    "plant_id": "Green-tek-A25621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "7:15:00 AM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/27/2024 7:30",
    "plant_id": "Green-tek-A25622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "7:30:00 AM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/27/2024 7:45",
    "plant_id": "Green-tek-A25623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "7:45:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/27/2024 8:00",
    "plant_id": "Green-tek-A25624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "8:00:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/27/2024 8:15",
    "plant_id": "Green-tek-A25625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "8:15:00 AM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "9/27/2024 8:30",
    "plant_id": "Green-tek-A25626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "8:30:00 AM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "9/27/2024 8:45",
    "plant_id": "Green-tek-A25627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "8:45:00 AM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "9/27/2024 9:00",
    "plant_id": "Green-tek-A25628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "9:00:00 AM",
    "power": 0.27,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "9/27/2024 9:15",
    "plant_id": "Green-tek-A25629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "9:15:00 AM",
    "power": 0.334,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "9/27/2024 9:30",
    "plant_id": "Green-tek-A25630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "9:30:00 AM",
    "power": 0.406,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "9/27/2024 9:45",
    "plant_id": "Green-tek-A25631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "9:45:00 AM",
    "power": 0.482,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "9/27/2024 10:00",
    "plant_id": "Green-tek-A25632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "10:00:00 AM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "9/27/2024 10:15",
    "plant_id": "Green-tek-A25633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "10:15:00 AM",
    "power": 0.642,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "9/27/2024 10:30",
    "plant_id": "Green-tek-A25634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "10:30:00 AM",
    "power": 0.718,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "9/27/2024 10:45",
    "plant_id": "Green-tek-A25635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "10:45:00 AM",
    "power": 0.787,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "9/27/2024 11:00",
    "plant_id": "Green-tek-A25636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "11:00:00 AM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "9/27/2024 11:15",
    "plant_id": "Green-tek-A25637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "11:15:00 AM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "9/27/2024 11:30",
    "plant_id": "Green-tek-A25638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "11:30:00 AM",
    "power": 0.917,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.23664,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "9/27/2024 11:45",
    "plant_id": "Green-tek-A25639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "11:45:00 AM",
    "power": 0.927,
    "Energy": 0.232,
    "output Energy": 0.232,
    "Ac output": 1.232,
    "dc output": 1.25664,
    "AC_R_i": 0.00030261,
    "AC_S_i": 0.000332871,
    "AC_T_i": 0.00036616,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0696003,
    "AC_S_pow": 0.07656033,
    "AC_T_Pow": 0.0842168,
    "AC_R_output": 0.3696,
    "AC_S_output": 0.40656,
    "AC_T_output": 0.447216,
    "dc_current": 0.00170588,
    "dc_voltage": 136,
    "dc_power": 0.23358,
    "dc_output": 0.23664,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000232,
    "carbon offset": 0.29,
    "trees planted": 6.444444444,
    "DC_R_output": 0.121968,
    "DC_S_output": 0.1341648,
    "DC_T_output": 0.14758128
  },
  {
    "datetime": "9/27/2024 12:00",
    "plant_id": "Green-tek-A25640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "12:00:00 PM",
    "power": 0.917,
    "Energy": 0.229,
    "output Energy": 0.229,
    "Ac output": 1.229,
    "dc output": 1.25358,
    "AC_R_i": 0.0002987,
    "AC_S_i": 0.00032857,
    "AC_T_i": 0.00036143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.068701,
    "AC_S_pow": 0.0755711,
    "AC_T_Pow": 0.0831289,
    "AC_R_output": 0.3687,
    "AC_S_output": 0.40557,
    "AC_T_output": 0.446127,
    "dc_current": 0.00168382,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.23358,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000229,
    "carbon offset": 0.28625,
    "trees planted": 6.361111111,
    "DC_R_output": 0.121671,
    "DC_S_output": 0.1338381,
    "DC_T_output": 0.14722191
  },
  {
    "datetime": "9/27/2024 12:15",
    "plant_id": "Green-tek-A25641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "12:15:00 PM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "9/27/2024 12:30",
    "plant_id": "Green-tek-A25642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "12:30:00 PM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "9/27/2024 12:45",
    "plant_id": "Green-tek-A25643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "12:45:00 PM",
    "power": 0.787,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "9/27/2024 13:00",
    "plant_id": "Green-tek-A25644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "1:00:00 PM",
    "power": 0.718,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "9/27/2024 13:15",
    "plant_id": "Green-tek-A25645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "1:15:00 PM",
    "power": 0.642,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "9/27/2024 13:30",
    "plant_id": "Green-tek-A25646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "1:30:00 PM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "9/27/2024 13:45",
    "plant_id": "Green-tek-A25647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "1:45:00 PM",
    "power": 0.482,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.10302,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "9/27/2024 14:00",
    "plant_id": "Green-tek-A25648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "2:00:00 PM",
    "power": 0.406,
    "Energy": 0.101,
    "output Energy": 0.101,
    "Ac output": 1.101,
    "dc output": 1.12302,
    "AC_R_i": 0.00013174,
    "AC_S_i": 0.000144914,
    "AC_T_i": 0.00015941,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0303002,
    "AC_S_pow": 0.03333022,
    "AC_T_Pow": 0.0366643,
    "AC_R_output": 0.3303,
    "AC_S_output": 0.36333,
    "AC_T_output": 0.399663,
    "dc_current": 0.00074265,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.10302,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000101,
    "carbon offset": 0.12625,
    "trees planted": 2.805555556,
    "DC_R_output": 0.108999,
    "DC_S_output": 0.1198989,
    "DC_T_output": 0.13188879
  },
  {
    "datetime": "9/27/2024 14:15",
    "plant_id": "Green-tek-A25649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "2:15:00 PM",
    "power": 0.334,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "9/27/2024 14:30",
    "plant_id": "Green-tek-A25650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "2:30:00 PM",
    "power": 0.27,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "9/27/2024 14:45",
    "plant_id": "Green-tek-A25651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "2:45:00 PM",
    "power": 0.213,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "9/27/2024 15:00",
    "plant_id": "Green-tek-A25652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "3:00:00 PM",
    "power": 0.165,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "9/27/2024 15:15",
    "plant_id": "Green-tek-A25653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "3:15:00 PM",
    "power": 0.125,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "9/27/2024 15:30",
    "plant_id": "Green-tek-A25654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "3:30:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/27/2024 15:45",
    "plant_id": "Green-tek-A25655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "3:45:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/27/2024 16:00",
    "plant_id": "Green-tek-A25656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "4:00:00 PM",
    "power": 0.049,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/27/2024 16:15",
    "plant_id": "Green-tek-A25657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "4:15:00 PM",
    "power": 0.034,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/27/2024 16:30",
    "plant_id": "Green-tek-A25658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/27/2024 16:45",
    "plant_id": "Green-tek-A25659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/27/2024 17:00",
    "plant_id": "Green-tek-A25660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/27/2024 17:15",
    "plant_id": "Green-tek-A25661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/27/2024 17:30",
    "plant_id": "Green-tek-A25662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/27/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/28/2024 6:00",
    "plant_id": "Green-tek-A25663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/28/2024 6:15",
    "plant_id": "Green-tek-A25664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/28/2024 6:30",
    "plant_id": "Green-tek-A25665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/28/2024 6:45",
    "plant_id": "Green-tek-A25666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/28/2024 7:00",
    "plant_id": "Green-tek-A25667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/28/2024 7:15",
    "plant_id": "Green-tek-A25668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/28/2024 7:30",
    "plant_id": "Green-tek-A25669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/28/2024 7:45",
    "plant_id": "Green-tek-A25670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "7:45:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/28/2024 8:00",
    "plant_id": "Green-tek-A25671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "8:00:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/28/2024 8:15",
    "plant_id": "Green-tek-A25672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "8:15:00 AM",
    "power": 0.154,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/28/2024 8:30",
    "plant_id": "Green-tek-A25673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "8:30:00 AM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "9/28/2024 8:45",
    "plant_id": "Green-tek-A25674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "8:45:00 AM",
    "power": 0.262,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "9/28/2024 9:00",
    "plant_id": "Green-tek-A25675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "9:00:00 AM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "9/28/2024 9:15",
    "plant_id": "Green-tek-A25676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "9:15:00 AM",
    "power": 0.41,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "9/28/2024 9:30",
    "plant_id": "Green-tek-A25677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "9:30:00 AM",
    "power": 0.498,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "9/28/2024 9:45",
    "plant_id": "Green-tek-A25678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "9:45:00 AM",
    "power": 0.592,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "9/28/2024 10:00",
    "plant_id": "Green-tek-A25679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "10:00:00 AM",
    "power": 0.69,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "9/28/2024 10:15",
    "plant_id": "Green-tek-A25680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "10:15:00 AM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "9/28/2024 10:30",
    "plant_id": "Green-tek-A25681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "10:30:00 AM",
    "power": 0.881,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "9/28/2024 10:45",
    "plant_id": "Green-tek-A25682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "10:45:00 AM",
    "power": 0.966,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "9/28/2024 11:00",
    "plant_id": "Green-tek-A25683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "11:00:00 AM",
    "power": 1.038,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "9/28/2024 11:15",
    "plant_id": "Green-tek-A25684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "11:15:00 AM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "9/28/2024 11:30",
    "plant_id": "Green-tek-A25685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "11:30:00 AM",
    "power": 1.126,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "9/28/2024 11:45",
    "plant_id": "Green-tek-A25686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "11:45:00 AM",
    "power": 1.138,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.28764,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "9/28/2024 12:00",
    "plant_id": "Green-tek-A25687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "12:00:00 PM",
    "power": 1.126,
    "Energy": 0.282,
    "output Energy": 0.282,
    "Ac output": 1.282,
    "dc output": 1.30764,
    "AC_R_i": 0.00036783,
    "AC_S_i": 0.000404613,
    "AC_T_i": 0.00044507,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0846009,
    "AC_S_pow": 0.09306099,
    "AC_T_Pow": 0.1023661,
    "AC_R_output": 0.3846,
    "AC_S_output": 0.42306,
    "AC_T_output": 0.465366,
    "dc_current": 0.00207353,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.28764,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000282,
    "carbon offset": 0.3525,
    "trees planted": 7.833333333,
    "DC_R_output": 0.126918,
    "DC_S_output": 0.1396098,
    "DC_T_output": 0.15357078
  },
  {
    "datetime": "9/28/2024 12:15",
    "plant_id": "Green-tek-A25688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "12:15:00 PM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "9/28/2024 12:30",
    "plant_id": "Green-tek-A25689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "12:30:00 PM",
    "power": 1.038,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.24684,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "9/28/2024 12:45",
    "plant_id": "Green-tek-A25690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "12:45:00 PM",
    "power": 0.966,
    "Energy": 0.242,
    "output Energy": 0.242,
    "Ac output": 1.242,
    "dc output": 1.26684,
    "AC_R_i": 0.00031565,
    "AC_S_i": 0.000347215,
    "AC_T_i": 0.00038194,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0725995,
    "AC_S_pow": 0.07985945,
    "AC_T_Pow": 0.0878462,
    "AC_R_output": 0.3726,
    "AC_S_output": 0.40986,
    "AC_T_output": 0.450846,
    "dc_current": 0.00177941,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.24684,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000242,
    "carbon offset": 0.3025,
    "trees planted": 6.722222222,
    "DC_R_output": 0.122958,
    "DC_S_output": 0.1352538,
    "DC_T_output": 0.14877918
  },
  {
    "datetime": "9/28/2024 13:00",
    "plant_id": "Green-tek-A25691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "1:00:00 PM",
    "power": 0.881,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "9/28/2024 13:15",
    "plant_id": "Green-tek-A25692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "1:15:00 PM",
    "power": 0.788,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "9/28/2024 13:30",
    "plant_id": "Green-tek-A25693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "1:30:00 PM",
    "power": 0.69,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "9/28/2024 13:45",
    "plant_id": "Green-tek-A25694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "1:45:00 PM",
    "power": 0.592,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "9/28/2024 14:00",
    "plant_id": "Green-tek-A25695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "2:00:00 PM",
    "power": 0.498,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "9/28/2024 14:15",
    "plant_id": "Green-tek-A25696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "2:15:00 PM",
    "power": 0.41,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "9/28/2024 14:30",
    "plant_id": "Green-tek-A25697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "2:30:00 PM",
    "power": 0.331,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "9/28/2024 14:45",
    "plant_id": "Green-tek-A25698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "2:45:00 PM",
    "power": 0.262,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "9/28/2024 15:00",
    "plant_id": "Green-tek-A25699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "3:00:00 PM",
    "power": 0.203,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "9/28/2024 15:15",
    "plant_id": "Green-tek-A25700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "3:15:00 PM",
    "power": 0.154,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "9/28/2024 15:30",
    "plant_id": "Green-tek-A25701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "3:30:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "9/28/2024 15:45",
    "plant_id": "Green-tek-A25702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "3:45:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "9/28/2024 16:00",
    "plant_id": "Green-tek-A25703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "9/28/2024 16:15",
    "plant_id": "Green-tek-A25704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "9/28/2024 16:30",
    "plant_id": "Green-tek-A25705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "9/28/2024 16:45",
    "plant_id": "Green-tek-A25706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/28/2024 17:00",
    "plant_id": "Green-tek-A25707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/28/2024 17:15",
    "plant_id": "Green-tek-A25708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/28/2024 17:30",
    "plant_id": "Green-tek-A25709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/28/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/29/2024 6:00",
    "plant_id": "Green-tek-A25710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/29/2024 6:15",
    "plant_id": "Green-tek-A25711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/29/2024 6:30",
    "plant_id": "Green-tek-A25712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/29/2024 6:45",
    "plant_id": "Green-tek-A25713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/29/2024 7:00",
    "plant_id": "Green-tek-A25714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/29/2024 7:15",
    "plant_id": "Green-tek-A25715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/29/2024 7:30",
    "plant_id": "Green-tek-A25716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/29/2024 7:45",
    "plant_id": "Green-tek-A25717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "7:45:00 AM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/29/2024 8:00",
    "plant_id": "Green-tek-A25718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "8:00:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/29/2024 8:15",
    "plant_id": "Green-tek-A25719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "8:15:00 AM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "9/29/2024 8:30",
    "plant_id": "Green-tek-A25720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "8:30:00 AM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "9/29/2024 8:45",
    "plant_id": "Green-tek-A25721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "8:45:00 AM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "9/29/2024 9:00",
    "plant_id": "Green-tek-A25722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "9:00:00 AM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "9/29/2024 9:15",
    "plant_id": "Green-tek-A25723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "9:15:00 AM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "9/29/2024 9:30",
    "plant_id": "Green-tek-A25724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "9:30:00 AM",
    "power": 0.553,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "9/29/2024 9:45",
    "plant_id": "Green-tek-A25725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "9:45:00 AM",
    "power": 0.658,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "9/29/2024 10:00",
    "plant_id": "Green-tek-A25726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "10:00:00 AM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "9/29/2024 10:15",
    "plant_id": "Green-tek-A25727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "10:15:00 AM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "9/29/2024 10:30",
    "plant_id": "Green-tek-A25728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "10:30:00 AM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "9/29/2024 10:45",
    "plant_id": "Green-tek-A25729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "10:45:00 AM",
    "power": 1.074,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "9/29/2024 11:00",
    "plant_id": "Green-tek-A25730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "11:00:00 AM",
    "power": 1.153,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "9/29/2024 11:15",
    "plant_id": "Green-tek-A25731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "11:15:00 AM",
    "power": 1.213,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "9/29/2024 11:30",
    "plant_id": "Green-tek-A25732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "11:30:00 AM",
    "power": 1.251,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "9/29/2024 11:45",
    "plant_id": "Green-tek-A25733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "11:45:00 AM",
    "power": 1.264,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "9/29/2024 12:00",
    "plant_id": "Green-tek-A25734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "12:00:00 PM",
    "power": 1.251,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.30906,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "9/29/2024 12:15",
    "plant_id": "Green-tek-A25735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "12:15:00 PM",
    "power": 1.213,
    "Energy": 0.303,
    "output Energy": 0.303,
    "Ac output": 1.303,
    "dc output": 1.32906,
    "AC_R_i": 0.00039522,
    "AC_S_i": 0.000434742,
    "AC_T_i": 0.00047822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0909006,
    "AC_S_pow": 0.09999066,
    "AC_T_Pow": 0.1099906,
    "AC_R_output": 0.3909,
    "AC_S_output": 0.42999,
    "AC_T_output": 0.472989,
    "dc_current": 0.00222794,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.30906,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000303,
    "carbon offset": 0.37875,
    "trees planted": 8.416666667,
    "DC_R_output": 0.128997,
    "DC_S_output": 0.1418967,
    "DC_T_output": 0.15608637
  },
  {
    "datetime": "9/29/2024 12:30",
    "plant_id": "Green-tek-A25736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "12:30:00 PM",
    "power": 1.153,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.27336,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "9/29/2024 12:45",
    "plant_id": "Green-tek-A25737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "12:45:00 PM",
    "power": 1.074,
    "Energy": 0.268,
    "output Energy": 0.268,
    "Ac output": 1.268,
    "dc output": 1.29336,
    "AC_R_i": 0.00034957,
    "AC_S_i": 0.000384527,
    "AC_T_i": 0.00042298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0804011,
    "AC_S_pow": 0.08844121,
    "AC_T_Pow": 0.0972854,
    "AC_R_output": 0.3804,
    "AC_S_output": 0.41844,
    "AC_T_output": 0.460284,
    "dc_current": 0.00197059,
    "dc_voltage": 136,
    "dc_power": 0.2499,
    "dc_output": 0.27336,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000268,
    "carbon offset": 0.335,
    "trees planted": 7.444444444,
    "DC_R_output": 0.125532,
    "DC_S_output": 0.1380852,
    "DC_T_output": 0.15189372
  },
  {
    "datetime": "9/29/2024 13:00",
    "plant_id": "Green-tek-A25738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "1:00:00 PM",
    "power": 0.979,
    "Energy": 0.245,
    "output Energy": 0.245,
    "Ac output": 1.245,
    "dc output": 1.2699,
    "AC_R_i": 0.00031957,
    "AC_S_i": 0.000351527,
    "AC_T_i": 0.00038668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0735011,
    "AC_S_pow": 0.08085121,
    "AC_T_Pow": 0.0889364,
    "AC_R_output": 0.3735,
    "AC_S_output": 0.41085,
    "AC_T_output": 0.451935,
    "dc_current": 0.00180147,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2499,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000245,
    "carbon offset": 0.30625,
    "trees planted": 6.805555556,
    "DC_R_output": 0.123255,
    "DC_S_output": 0.1355805,
    "DC_T_output": 0.14913855
  },
  {
    "datetime": "9/29/2024 13:15",
    "plant_id": "Green-tek-A25739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "1:15:00 PM",
    "power": 0.875,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "9/29/2024 13:30",
    "plant_id": "Green-tek-A25740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "1:30:00 PM",
    "power": 0.767,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "9/29/2024 13:45",
    "plant_id": "Green-tek-A25741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "1:45:00 PM",
    "power": 0.658,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "9/29/2024 14:00",
    "plant_id": "Green-tek-A25742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "2:00:00 PM",
    "power": 0.553,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11628,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "9/29/2024 14:15",
    "plant_id": "Green-tek-A25743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "2:15:00 PM",
    "power": 0.456,
    "Energy": 0.114,
    "output Energy": 0.114,
    "Ac output": 1.114,
    "dc output": 1.13628,
    "AC_R_i": 0.0001487,
    "AC_S_i": 0.00016357,
    "AC_T_i": 0.00017993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034201,
    "AC_S_pow": 0.0376211,
    "AC_T_Pow": 0.0413839,
    "AC_R_output": 0.3342,
    "AC_S_output": 0.36762,
    "AC_T_output": 0.404382,
    "dc_current": 0.00083824,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11628,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000114,
    "carbon offset": 0.1425,
    "trees planted": 3.166666667,
    "DC_R_output": 0.110286,
    "DC_S_output": 0.1213146,
    "DC_T_output": 0.13344606
  },
  {
    "datetime": "9/29/2024 14:30",
    "plant_id": "Green-tek-A25744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "2:30:00 PM",
    "power": 0.368,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07446,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "9/29/2024 14:45",
    "plant_id": "Green-tek-A25745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "2:45:00 PM",
    "power": 0.291,
    "Energy": 0.073,
    "output Energy": 0.073,
    "Ac output": 1.073,
    "dc output": 1.09446,
    "AC_R_i": 0.00009522,
    "AC_S_i": 0.000104742,
    "AC_T_i": 0.00011522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0219006,
    "AC_S_pow": 0.02409066,
    "AC_T_Pow": 0.0265006,
    "AC_R_output": 0.3219,
    "AC_S_output": 0.35409,
    "AC_T_output": 0.389499,
    "dc_current": 0.00053676,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07446,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000073,
    "carbon offset": 0.09125,
    "trees planted": 2.027777778,
    "DC_R_output": 0.106227,
    "DC_S_output": 0.1168497,
    "DC_T_output": 0.12853467
  },
  {
    "datetime": "9/29/2024 15:00",
    "plant_id": "Green-tek-A25746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "3:00:00 PM",
    "power": 0.225,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "9/29/2024 15:15",
    "plant_id": "Green-tek-A25747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "3:15:00 PM",
    "power": 0.171,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "9/29/2024 15:30",
    "plant_id": "Green-tek-A25748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "3:30:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "9/29/2024 15:45",
    "plant_id": "Green-tek-A25749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "3:45:00 PM",
    "power": 0.093,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/29/2024 16:00",
    "plant_id": "Green-tek-A25750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/29/2024 16:15",
    "plant_id": "Green-tek-A25751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/29/2024 16:30",
    "plant_id": "Green-tek-A25752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/29/2024 16:45",
    "plant_id": "Green-tek-A25753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "9/29/2024 17:00",
    "plant_id": "Green-tek-A25754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/29/2024 17:15",
    "plant_id": "Green-tek-A25755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/29/2024 17:30",
    "plant_id": "Green-tek-A25756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/29/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/30/2024 6:00",
    "plant_id": "Green-tek-A25757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "9/30/2024 6:15",
    "plant_id": "Green-tek-A25758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/30/2024 6:30",
    "plant_id": "Green-tek-A25759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/30/2024 6:45",
    "plant_id": "Green-tek-A25760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/30/2024 7:00",
    "plant_id": "Green-tek-A25761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/30/2024 7:15",
    "plant_id": "Green-tek-A25762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/30/2024 7:30",
    "plant_id": "Green-tek-A25763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "7:30:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/30/2024 7:45",
    "plant_id": "Green-tek-A25764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "7:45:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/30/2024 8:00",
    "plant_id": "Green-tek-A25765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "8:00:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/30/2024 8:15",
    "plant_id": "Green-tek-A25766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "8:15:00 AM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "9/30/2024 8:30",
    "plant_id": "Green-tek-A25767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "8:30:00 AM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "9/30/2024 8:45",
    "plant_id": "Green-tek-A25768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "8:45:00 AM",
    "power": 0.21,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "9/30/2024 9:00",
    "plant_id": "Green-tek-A25769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "9:00:00 AM",
    "power": 0.265,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "9/30/2024 9:15",
    "plant_id": "Green-tek-A25770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "9:15:00 AM",
    "power": 0.328,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "9/30/2024 9:30",
    "plant_id": "Green-tek-A25771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "9:30:00 AM",
    "power": 0.399,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "9/30/2024 9:45",
    "plant_id": "Green-tek-A25772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "9:45:00 AM",
    "power": 0.474,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "9/30/2024 10:00",
    "plant_id": "Green-tek-A25773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "10:00:00 AM",
    "power": 0.552,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "9/30/2024 10:15",
    "plant_id": "Green-tek-A25774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "10:15:00 AM",
    "power": 0.631,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "9/30/2024 10:30",
    "plant_id": "Green-tek-A25775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "10:30:00 AM",
    "power": 0.706,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "9/30/2024 10:45",
    "plant_id": "Green-tek-A25776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "10:45:00 AM",
    "power": 0.774,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "9/30/2024 11:00",
    "plant_id": "Green-tek-A25777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "11:00:00 AM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "9/30/2024 11:15",
    "plant_id": "Green-tek-A25778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "11:15:00 AM",
    "power": 0.874,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "9/30/2024 11:30",
    "plant_id": "Green-tek-A25779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "11:30:00 AM",
    "power": 0.902,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "9/30/2024 11:45",
    "plant_id": "Green-tek-A25780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "11:45:00 AM",
    "power": 0.911,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "9/30/2024 12:00",
    "plant_id": "Green-tek-A25781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "12:00:00 PM",
    "power": 0.902,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.22338,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "9/30/2024 12:15",
    "plant_id": "Green-tek-A25782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "12:15:00 PM",
    "power": 0.874,
    "Energy": 0.219,
    "output Energy": 0.219,
    "Ac output": 1.219,
    "dc output": 1.24338,
    "AC_R_i": 0.00028565,
    "AC_S_i": 0.000314215,
    "AC_T_i": 0.00034564,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0656995,
    "AC_S_pow": 0.07226945,
    "AC_T_Pow": 0.0794972,
    "AC_R_output": 0.3657,
    "AC_S_output": 0.40227,
    "AC_T_output": 0.442497,
    "dc_current": 0.00161029,
    "dc_voltage": 136,
    "dc_power": 0.21216,
    "dc_output": 0.22338,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000219,
    "carbon offset": 0.27375,
    "trees planted": 6.083333333,
    "DC_R_output": 0.120681,
    "DC_S_output": 0.1327491,
    "DC_T_output": 0.14602401
  },
  {
    "datetime": "9/30/2024 12:30",
    "plant_id": "Green-tek-A25783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "12:30:00 PM",
    "power": 0.831,
    "Energy": 0.208,
    "output Energy": 0.208,
    "Ac output": 1.208,
    "dc output": 1.23216,
    "AC_R_i": 0.0002713,
    "AC_S_i": 0.00029843,
    "AC_T_i": 0.00032827,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.062399,
    "AC_S_pow": 0.0686389,
    "AC_T_Pow": 0.0755021,
    "AC_R_output": 0.3624,
    "AC_S_output": 0.39864,
    "AC_T_output": 0.438504,
    "dc_current": 0.00152941,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.21216,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000208,
    "carbon offset": 0.26,
    "trees planted": 5.777777778,
    "DC_R_output": 0.119592,
    "DC_S_output": 0.1315512,
    "DC_T_output": 0.14470632
  },
  {
    "datetime": "9/30/2024 12:45",
    "plant_id": "Green-tek-A25784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "12:45:00 PM",
    "power": 0.774,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "9/30/2024 13:00",
    "plant_id": "Green-tek-A25785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "1:00:00 PM",
    "power": 0.706,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.16116,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "9/30/2024 13:15",
    "plant_id": "Green-tek-A25786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "1:15:00 PM",
    "power": 0.631,
    "Energy": 0.158,
    "output Energy": 0.158,
    "Ac output": 1.158,
    "dc output": 1.18116,
    "AC_R_i": 0.00020609,
    "AC_S_i": 0.000226699,
    "AC_T_i": 0.00024937,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0474007,
    "AC_S_pow": 0.05214077,
    "AC_T_Pow": 0.0573551,
    "AC_R_output": 0.3474,
    "AC_S_output": 0.38214,
    "AC_T_output": 0.420354,
    "dc_current": 0.00116176,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16116,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000158,
    "carbon offset": 0.1975,
    "trees planted": 4.388888889,
    "DC_R_output": 0.114642,
    "DC_S_output": 0.1261062,
    "DC_T_output": 0.13871682
  },
  {
    "datetime": "9/30/2024 13:30",
    "plant_id": "Green-tek-A25787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "1:30:00 PM",
    "power": 0.552,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "9/30/2024 13:45",
    "plant_id": "Green-tek-A25788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "1:45:00 PM",
    "power": 0.474,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "9/30/2024 14:00",
    "plant_id": "Green-tek-A25789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "2:00:00 PM",
    "power": 0.399,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08364,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "9/30/2024 14:15",
    "plant_id": "Green-tek-A25790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "2:15:00 PM",
    "power": 0.328,
    "Energy": 0.082,
    "output Energy": 0.082,
    "Ac output": 1.082,
    "dc output": 1.10364,
    "AC_R_i": 0.00010696,
    "AC_S_i": 0.000117656,
    "AC_T_i": 0.00012942,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0246008,
    "AC_S_pow": 0.02706088,
    "AC_T_Pow": 0.0297666,
    "AC_R_output": 0.3246,
    "AC_S_output": 0.35706,
    "AC_T_output": 0.392766,
    "dc_current": 0.00060294,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08364,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000082,
    "carbon offset": 0.1025,
    "trees planted": 2.277777778,
    "DC_R_output": 0.107118,
    "DC_S_output": 0.1178298,
    "DC_T_output": 0.12961278
  },
  {
    "datetime": "9/30/2024 14:30",
    "plant_id": "Green-tek-A25791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "2:30:00 PM",
    "power": 0.265,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "9/30/2024 14:45",
    "plant_id": "Green-tek-A25792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "2:45:00 PM",
    "power": 0.21,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "9/30/2024 15:00",
    "plant_id": "Green-tek-A25793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "3:00:00 PM",
    "power": 0.162,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "9/30/2024 15:15",
    "plant_id": "Green-tek-A25794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "3:15:00 PM",
    "power": 0.123,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "9/30/2024 15:30",
    "plant_id": "Green-tek-A25795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "3:30:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "9/30/2024 15:45",
    "plant_id": "Green-tek-A25796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "3:45:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "9/30/2024 16:00",
    "plant_id": "Green-tek-A25797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "4:00:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "9/30/2024 16:15",
    "plant_id": "Green-tek-A25798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "9/30/2024 16:30",
    "plant_id": "Green-tek-A25799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "9/30/2024 16:45",
    "plant_id": "Green-tek-A25800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "9/30/2024 17:00",
    "plant_id": "Green-tek-A25801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "9/30/2024 17:15",
    "plant_id": "Green-tek-A25802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "9/30/2024 17:30",
    "plant_id": "Green-tek-A25803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "9/30/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/1/2024 6:00",
    "plant_id": "Green-tek-A25804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/1/2024 6:15",
    "plant_id": "Green-tek-A25805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/1/2024 6:30",
    "plant_id": "Green-tek-A25806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/1/2024 6:45",
    "plant_id": "Green-tek-A25807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/1/2024 7:00",
    "plant_id": "Green-tek-A25808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/1/2024 7:15",
    "plant_id": "Green-tek-A25809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "7:15:00 AM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "10/1/2024 7:30",
    "plant_id": "Green-tek-A25810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "7:30:00 AM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/1/2024 7:45",
    "plant_id": "Green-tek-A25811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "7:45:00 AM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "10/1/2024 8:00",
    "plant_id": "Green-tek-A25812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "8:00:00 AM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "10/1/2024 8:15",
    "plant_id": "Green-tek-A25813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "8:15:00 AM",
    "power": 0.14,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "10/1/2024 8:30",
    "plant_id": "Green-tek-A25814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "8:30:00 AM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "10/1/2024 8:45",
    "plant_id": "Green-tek-A25815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "8:45:00 AM",
    "power": 0.238,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "10/1/2024 9:00",
    "plant_id": "Green-tek-A25816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "9:00:00 AM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "10/1/2024 9:15",
    "plant_id": "Green-tek-A25817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "9:15:00 AM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "10/1/2024 9:30",
    "plant_id": "Green-tek-A25818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "9:30:00 AM",
    "power": 0.452,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "10/1/2024 9:45",
    "plant_id": "Green-tek-A25819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "9:45:00 AM",
    "power": 0.538,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "10/1/2024 10:00",
    "plant_id": "Green-tek-A25820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "10:00:00 AM",
    "power": 0.627,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "10/1/2024 10:15",
    "plant_id": "Green-tek-A25821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "10:15:00 AM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "10/1/2024 10:30",
    "plant_id": "Green-tek-A25822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "10:30:00 AM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "10/1/2024 10:45",
    "plant_id": "Green-tek-A25823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "10:45:00 AM",
    "power": 0.878,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "10/1/2024 11:00",
    "plant_id": "Green-tek-A25824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "11:00:00 AM",
    "power": 0.943,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "10/1/2024 11:15",
    "plant_id": "Green-tek-A25825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "11:15:00 AM",
    "power": 0.992,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "10/1/2024 11:30",
    "plant_id": "Green-tek-A25826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "11:30:00 AM",
    "power": 1.023,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.26316,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "10/1/2024 11:45",
    "plant_id": "Green-tek-A25827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "11:45:00 AM",
    "power": 1.034,
    "Energy": 0.258,
    "output Energy": 0.258,
    "Ac output": 1.258,
    "dc output": 1.28316,
    "AC_R_i": 0.00033652,
    "AC_S_i": 0.000370172,
    "AC_T_i": 0.00040719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0773996,
    "AC_S_pow": 0.08513956,
    "AC_T_Pow": 0.0936537,
    "AC_R_output": 0.3774,
    "AC_S_output": 0.41514,
    "AC_T_output": 0.456654,
    "dc_current": 0.00189706,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.26316,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000258,
    "carbon offset": 0.3225,
    "trees planted": 7.166666667,
    "DC_R_output": 0.124542,
    "DC_S_output": 0.1369962,
    "DC_T_output": 0.15069582
  },
  {
    "datetime": "10/1/2024 12:00",
    "plant_id": "Green-tek-A25828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "12:00:00 PM",
    "power": 1.023,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "10/1/2024 12:15",
    "plant_id": "Green-tek-A25829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "12:15:00 PM",
    "power": 0.992,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "10/1/2024 12:30",
    "plant_id": "Green-tek-A25830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "12:30:00 PM",
    "power": 0.943,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "10/1/2024 12:45",
    "plant_id": "Green-tek-A25831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "12:45:00 PM",
    "power": 0.878,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.204,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "10/1/2024 13:00",
    "plant_id": "Green-tek-A25832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "1:00:00 PM",
    "power": 0.801,
    "Energy": 0.2,
    "output Energy": 0.2,
    "Ac output": 1.2,
    "dc output": 1.224,
    "AC_R_i": 0.00026087,
    "AC_S_i": 0.000286957,
    "AC_T_i": 0.00031565,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0600001,
    "AC_S_pow": 0.06600011,
    "AC_T_Pow": 0.0725995,
    "AC_R_output": 0.36,
    "AC_S_output": 0.396,
    "AC_T_output": 0.4356,
    "dc_current": 0.00147059,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0002,
    "carbon offset": 0.25,
    "trees planted": 5.555555556,
    "DC_R_output": 0.1188,
    "DC_S_output": 0.13068,
    "DC_T_output": 0.143748
  },
  {
    "datetime": "10/1/2024 13:15",
    "plant_id": "Green-tek-A25833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "1:15:00 PM",
    "power": 0.716,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "10/1/2024 13:30",
    "plant_id": "Green-tek-A25834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "1:30:00 PM",
    "power": 0.627,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.1377,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "10/1/2024 13:45",
    "plant_id": "Green-tek-A25835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "1:45:00 PM",
    "power": 0.538,
    "Energy": 0.135,
    "output Energy": 0.135,
    "Ac output": 1.135,
    "dc output": 1.1577,
    "AC_R_i": 0.00017609,
    "AC_S_i": 0.000193699,
    "AC_T_i": 0.00021307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0405007,
    "AC_S_pow": 0.04455077,
    "AC_T_Pow": 0.0490061,
    "AC_R_output": 0.3405,
    "AC_S_output": 0.37455,
    "AC_T_output": 0.412005,
    "dc_current": 0.00099265,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.1377,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000135,
    "carbon offset": 0.16875,
    "trees planted": 3.75,
    "DC_R_output": 0.112365,
    "DC_S_output": 0.1236015,
    "DC_T_output": 0.13596165
  },
  {
    "datetime": "10/1/2024 14:00",
    "plant_id": "Green-tek-A25836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "2:00:00 PM",
    "power": 0.452,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "10/1/2024 14:15",
    "plant_id": "Green-tek-A25837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "2:15:00 PM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "10/1/2024 14:30",
    "plant_id": "Green-tek-A25838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "2:30:00 PM",
    "power": 0.301,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "10/1/2024 14:45",
    "plant_id": "Green-tek-A25839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "2:45:00 PM",
    "power": 0.238,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04692,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "10/1/2024 15:00",
    "plant_id": "Green-tek-A25840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "3:00:00 PM",
    "power": 0.184,
    "Energy": 0.046,
    "output Energy": 0.046,
    "Ac output": 1.046,
    "dc output": 1.06692,
    "AC_R_i": 0.00006,
    "AC_S_i": 0.000066,
    "AC_T_i": 0.0000726,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0138,
    "AC_S_pow": 0.01518,
    "AC_T_Pow": 0.016698,
    "AC_R_output": 0.3138,
    "AC_S_output": 0.34518,
    "AC_T_output": 0.379698,
    "dc_current": 0.00033824,
    "dc_voltage": 136,
    "dc_power": 0.0357,
    "dc_output": 0.04692,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000046,
    "carbon offset": 0.0575,
    "trees planted": 1.277777778,
    "DC_R_output": 0.103554,
    "DC_S_output": 0.1139094,
    "DC_T_output": 0.12530034
  },
  {
    "datetime": "10/1/2024 15:15",
    "plant_id": "Green-tek-A25841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "3:15:00 PM",
    "power": 0.14,
    "Energy": 0.035,
    "output Energy": 0.035,
    "Ac output": 1.035,
    "dc output": 1.0557,
    "AC_R_i": 0.00004565,
    "AC_S_i": 0.000050215,
    "AC_T_i": 0.00005524,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0104995,
    "AC_S_pow": 0.01154945,
    "AC_T_Pow": 0.0127052,
    "AC_R_output": 0.3105,
    "AC_S_output": 0.34155,
    "AC_T_output": 0.375705,
    "dc_current": 0.00025735,
    "dc_voltage": 136,
    "dc_power": 0.02652,
    "dc_output": 0.0357,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000035,
    "carbon offset": 0.04375,
    "trees planted": 0.972222222,
    "DC_R_output": 0.102465,
    "DC_S_output": 0.1127115,
    "DC_T_output": 0.12398265
  },
  {
    "datetime": "10/1/2024 15:30",
    "plant_id": "Green-tek-A25842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "3:30:00 PM",
    "power": 0.104,
    "Energy": 0.026,
    "output Energy": 0.026,
    "Ac output": 1.026,
    "dc output": 1.04652,
    "AC_R_i": 0.00003391,
    "AC_S_i": 0.000037301,
    "AC_T_i": 0.00004103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0077993,
    "AC_S_pow": 0.00857923,
    "AC_T_Pow": 0.0094369,
    "AC_R_output": 0.3078,
    "AC_S_output": 0.33858,
    "AC_T_output": 0.372438,
    "dc_current": 0.00019118,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000026,
    "carbon offset": 0.0325,
    "trees planted": 0.722222222,
    "DC_R_output": 0.101574,
    "DC_S_output": 0.1117314,
    "DC_T_output": 0.12290454
  },
  {
    "datetime": "10/1/2024 15:45",
    "plant_id": "Green-tek-A25843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "3:45:00 PM",
    "power": 0.076,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "10/1/2024 16:00",
    "plant_id": "Green-tek-A25844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "4:00:00 PM",
    "power": 0.054,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/1/2024 16:15",
    "plant_id": "Green-tek-A25845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "4:15:00 PM",
    "power": 0.038,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "10/1/2024 16:30",
    "plant_id": "Green-tek-A25846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/1/2024 16:45",
    "plant_id": "Green-tek-A25847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/1/2024 17:00",
    "plant_id": "Green-tek-A25848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/1/2024 17:15",
    "plant_id": "Green-tek-A25849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/1/2024 17:30",
    "plant_id": "Green-tek-A25850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/1/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/2/2024 6:00",
    "plant_id": "Green-tek-A25851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/2/2024 6:15",
    "plant_id": "Green-tek-A25852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/2/2024 6:30",
    "plant_id": "Green-tek-A25853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "6:30:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/2/2024 6:45",
    "plant_id": "Green-tek-A25854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/2/2024 7:00",
    "plant_id": "Green-tek-A25855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/2/2024 7:15",
    "plant_id": "Green-tek-A25856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/2/2024 7:30",
    "plant_id": "Green-tek-A25857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "7:30:00 AM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/2/2024 7:45",
    "plant_id": "Green-tek-A25858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "7:45:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/2/2024 8:00",
    "plant_id": "Green-tek-A25859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "8:00:00 AM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/2/2024 8:15",
    "plant_id": "Green-tek-A25860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "8:15:00 AM",
    "power": 0.178,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "10/2/2024 8:30",
    "plant_id": "Green-tek-A25861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "8:30:00 AM",
    "power": 0.234,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "10/2/2024 8:45",
    "plant_id": "Green-tek-A25862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "8:45:00 AM",
    "power": 0.303,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "10/2/2024 9:00",
    "plant_id": "Green-tek-A25863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "9:00:00 AM",
    "power": 0.383,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "10/2/2024 9:15",
    "plant_id": "Green-tek-A25864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "9:15:00 AM",
    "power": 0.474,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "10/2/2024 9:30",
    "plant_id": "Green-tek-A25865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "9:30:00 AM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "10/2/2024 9:45",
    "plant_id": "Green-tek-A25866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "9:45:00 AM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "10/2/2024 10:00",
    "plant_id": "Green-tek-A25867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "10:00:00 AM",
    "power": 0.798,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "10/2/2024 10:15",
    "plant_id": "Green-tek-A25868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "10:15:00 AM",
    "power": 0.911,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "10/2/2024 10:30",
    "plant_id": "Green-tek-A25869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "10:30:00 AM",
    "power": 1.019,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "10/2/2024 10:45",
    "plant_id": "Green-tek-A25870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "10:45:00 AM",
    "power": 1.117,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "10/2/2024 11:00",
    "plant_id": "Green-tek-A25871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "11:00:00 AM",
    "power": 1.2,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "10/2/2024 11:15",
    "plant_id": "Green-tek-A25872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "11:15:00 AM",
    "power": 1.262,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.3315,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "10/2/2024 11:30",
    "plant_id": "Green-tek-A25873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "11:30:00 AM",
    "power": 1.302,
    "Energy": 0.325,
    "output Energy": 0.325,
    "Ac output": 1.325,
    "dc output": 1.3515,
    "AC_R_i": 0.00042391,
    "AC_S_i": 0.000466301,
    "AC_T_i": 0.00051293,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0974993,
    "AC_S_pow": 0.10724923,
    "AC_T_Pow": 0.1179739,
    "AC_R_output": 0.3975,
    "AC_S_output": 0.43725,
    "AC_T_output": 0.480975,
    "dc_current": 0.00238971,
    "dc_voltage": 136,
    "dc_power": 0.33558,
    "dc_output": 0.3315,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000325,
    "carbon offset": 0.40625,
    "trees planted": 9.027777778,
    "DC_R_output": 0.131175,
    "DC_S_output": 0.1442925,
    "DC_T_output": 0.15872175
  },
  {
    "datetime": "10/2/2024 11:45",
    "plant_id": "Green-tek-A25874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "11:45:00 AM",
    "power": 1.315,
    "Energy": 0.329,
    "output Energy": 0.329,
    "Ac output": 1.329,
    "dc output": 1.35558,
    "AC_R_i": 0.00042913,
    "AC_S_i": 0.000472043,
    "AC_T_i": 0.00051925,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0986999,
    "AC_S_pow": 0.10856989,
    "AC_T_Pow": 0.1194275,
    "AC_R_output": 0.3987,
    "AC_S_output": 0.43857,
    "AC_T_output": 0.482427,
    "dc_current": 0.00241912,
    "dc_voltage": 136,
    "dc_power": 0.3315,
    "dc_output": 0.33558,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000329,
    "carbon offset": 0.41125,
    "trees planted": 9.138888889,
    "DC_R_output": 0.131571,
    "DC_S_output": 0.1447281,
    "DC_T_output": 0.15920091
  },
  {
    "datetime": "10/2/2024 12:00",
    "plant_id": "Green-tek-A25875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "12:00:00 PM",
    "power": 1.302,
    "Energy": 0.325,
    "output Energy": 0.325,
    "Ac output": 1.325,
    "dc output": 1.3515,
    "AC_R_i": 0.00042391,
    "AC_S_i": 0.000466301,
    "AC_T_i": 0.00051293,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0974993,
    "AC_S_pow": 0.10724923,
    "AC_T_Pow": 0.1179739,
    "AC_R_output": 0.3975,
    "AC_S_output": 0.43725,
    "AC_T_output": 0.480975,
    "dc_current": 0.00238971,
    "dc_voltage": 136,
    "dc_power": 0.32232,
    "dc_output": 0.3315,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000325,
    "carbon offset": 0.40625,
    "trees planted": 9.027777778,
    "DC_R_output": 0.131175,
    "DC_S_output": 0.1442925,
    "DC_T_output": 0.15872175
  },
  {
    "datetime": "10/2/2024 12:15",
    "plant_id": "Green-tek-A25876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "12:15:00 PM",
    "power": 1.262,
    "Energy": 0.316,
    "output Energy": 0.316,
    "Ac output": 1.316,
    "dc output": 1.34232,
    "AC_R_i": 0.00041217,
    "AC_S_i": 0.000453387,
    "AC_T_i": 0.00049873,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0947991,
    "AC_S_pow": 0.10427901,
    "AC_T_Pow": 0.1147079,
    "AC_R_output": 0.3948,
    "AC_S_output": 0.43428,
    "AC_T_output": 0.477708,
    "dc_current": 0.00232353,
    "dc_voltage": 136,
    "dc_power": 0.306,
    "dc_output": 0.32232,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000316,
    "carbon offset": 0.395,
    "trees planted": 8.777777778,
    "DC_R_output": 0.130284,
    "DC_S_output": 0.1433124,
    "DC_T_output": 0.15764364
  },
  {
    "datetime": "10/2/2024 12:30",
    "plant_id": "Green-tek-A25877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "12:30:00 PM",
    "power": 1.2,
    "Energy": 0.3,
    "output Energy": 0.3,
    "Ac output": 1.3,
    "dc output": 1.326,
    "AC_R_i": 0.0003913,
    "AC_S_i": 0.00043043,
    "AC_T_i": 0.00047347,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089999,
    "AC_S_pow": 0.0989989,
    "AC_T_Pow": 0.1088981,
    "AC_R_output": 0.39,
    "AC_S_output": 0.429,
    "AC_T_output": 0.4719,
    "dc_current": 0.00220588,
    "dc_voltage": 136,
    "dc_power": 0.28458,
    "dc_output": 0.306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0003,
    "carbon offset": 0.375,
    "trees planted": 8.333333333,
    "DC_R_output": 0.1287,
    "DC_S_output": 0.14157,
    "DC_T_output": 0.155727
  },
  {
    "datetime": "10/2/2024 12:45",
    "plant_id": "Green-tek-A25878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "12:45:00 PM",
    "power": 1.117,
    "Energy": 0.279,
    "output Energy": 0.279,
    "Ac output": 1.279,
    "dc output": 1.30458,
    "AC_R_i": 0.00036391,
    "AC_S_i": 0.000400301,
    "AC_T_i": 0.00044033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0836993,
    "AC_S_pow": 0.09206923,
    "AC_T_Pow": 0.1012759,
    "AC_R_output": 0.3837,
    "AC_S_output": 0.42207,
    "AC_T_output": 0.464277,
    "dc_current": 0.00205147,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.28458,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000279,
    "carbon offset": 0.34875,
    "trees planted": 7.75,
    "DC_R_output": 0.126621,
    "DC_S_output": 0.1392831,
    "DC_T_output": 0.15321141
  },
  {
    "datetime": "10/2/2024 13:00",
    "plant_id": "Green-tek-A25879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "1:00:00 PM",
    "power": 1.019,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.23256,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "10/2/2024 13:15",
    "plant_id": "Green-tek-A25880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "1:15:00 PM",
    "power": 0.911,
    "Energy": 0.228,
    "output Energy": 0.228,
    "Ac output": 1.228,
    "dc output": 1.25256,
    "AC_R_i": 0.00029739,
    "AC_S_i": 0.000327129,
    "AC_T_i": 0.00035984,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0683997,
    "AC_S_pow": 0.07523967,
    "AC_T_Pow": 0.0827632,
    "AC_R_output": 0.3684,
    "AC_S_output": 0.40524,
    "AC_T_output": 0.445764,
    "dc_current": 0.00167647,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.23256,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000228,
    "carbon offset": 0.285,
    "trees planted": 6.333333333,
    "DC_R_output": 0.121572,
    "DC_S_output": 0.1337292,
    "DC_T_output": 0.14710212
  },
  {
    "datetime": "10/2/2024 13:30",
    "plant_id": "Green-tek-A25881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "1:30:00 PM",
    "power": 0.798,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "10/2/2024 13:45",
    "plant_id": "Green-tek-A25882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "1:45:00 PM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "10/2/2024 14:00",
    "plant_id": "Green-tek-A25883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "2:00:00 PM",
    "power": 0.575,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "10/2/2024 14:15",
    "plant_id": "Green-tek-A25884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "2:15:00 PM",
    "power": 0.474,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "10/2/2024 14:30",
    "plant_id": "Green-tek-A25885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "2:30:00 PM",
    "power": 0.383,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "10/2/2024 14:45",
    "plant_id": "Green-tek-A25886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "2:45:00 PM",
    "power": 0.303,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06018,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "10/2/2024 15:00",
    "plant_id": "Green-tek-A25887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "3:00:00 PM",
    "power": 0.234,
    "Energy": 0.059,
    "output Energy": 0.059,
    "Ac output": 1.059,
    "dc output": 1.08018,
    "AC_R_i": 0.00007696,
    "AC_S_i": 0.000084656,
    "AC_T_i": 0.00009312,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0177008,
    "AC_S_pow": 0.01947088,
    "AC_T_Pow": 0.0214176,
    "AC_R_output": 0.3177,
    "AC_S_output": 0.34947,
    "AC_T_output": 0.384417,
    "dc_current": 0.00043382,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.06018,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000059,
    "carbon offset": 0.07375,
    "trees planted": 1.638888889,
    "DC_R_output": 0.104841,
    "DC_S_output": 0.1153251,
    "DC_T_output": 0.12685761
  },
  {
    "datetime": "10/2/2024 15:15",
    "plant_id": "Green-tek-A25888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "3:15:00 PM",
    "power": 0.178,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "10/2/2024 15:30",
    "plant_id": "Green-tek-A25889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "3:30:00 PM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/2/2024 15:45",
    "plant_id": "Green-tek-A25890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "3:45:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/2/2024 16:00",
    "plant_id": "Green-tek-A25891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "4:00:00 PM",
    "power": 0.069,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/2/2024 16:15",
    "plant_id": "Green-tek-A25892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/2/2024 16:30",
    "plant_id": "Green-tek-A25893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/2/2024 16:45",
    "plant_id": "Green-tek-A25894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/2/2024 17:00",
    "plant_id": "Green-tek-A25895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "5:00:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/2/2024 17:15",
    "plant_id": "Green-tek-A25896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/2/2024 17:30",
    "plant_id": "Green-tek-A25897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/2/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/3/2024 6:00",
    "plant_id": "Green-tek-A25898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/3/2024 6:15",
    "plant_id": "Green-tek-A25899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/3/2024 6:30",
    "plant_id": "Green-tek-A25900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/3/2024 6:45",
    "plant_id": "Green-tek-A25901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/3/2024 7:00",
    "plant_id": "Green-tek-A25902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/3/2024 7:15",
    "plant_id": "Green-tek-A25903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/3/2024 7:30",
    "plant_id": "Green-tek-A25904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "7:30:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/3/2024 7:45",
    "plant_id": "Green-tek-A25905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "7:45:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/3/2024 8:00",
    "plant_id": "Green-tek-A25906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "8:00:00 AM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/3/2024 8:15",
    "plant_id": "Green-tek-A25907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "8:15:00 AM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/3/2024 8:30",
    "plant_id": "Green-tek-A25908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "8:30:00 AM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "10/3/2024 8:45",
    "plant_id": "Green-tek-A25909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "8:45:00 AM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "10/3/2024 9:00",
    "plant_id": "Green-tek-A25910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "9:00:00 AM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "10/3/2024 9:15",
    "plant_id": "Green-tek-A25911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "9:15:00 AM",
    "power": 0.308,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "10/3/2024 9:30",
    "plant_id": "Green-tek-A25912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "9:30:00 AM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "10/3/2024 9:45",
    "plant_id": "Green-tek-A25913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "9:45:00 AM",
    "power": 0.444,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "10/3/2024 10:00",
    "plant_id": "Green-tek-A25914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "10:00:00 AM",
    "power": 0.518,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "10/3/2024 10:15",
    "plant_id": "Green-tek-A25915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "10:15:00 AM",
    "power": 0.591,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "10/3/2024 10:30",
    "plant_id": "Green-tek-A25916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "10:30:00 AM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "10/3/2024 10:45",
    "plant_id": "Green-tek-A25917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "10:45:00 AM",
    "power": 0.725,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "10/3/2024 11:00",
    "plant_id": "Green-tek-A25918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "11:00:00 AM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "10/3/2024 11:15",
    "plant_id": "Green-tek-A25919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "11:15:00 AM",
    "power": 0.819,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "10/3/2024 11:30",
    "plant_id": "Green-tek-A25920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "11:30:00 AM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "10/3/2024 11:45",
    "plant_id": "Green-tek-A25921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "11:45:00 AM",
    "power": 0.854,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "10/3/2024 12:00",
    "plant_id": "Green-tek-A25922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "12:00:00 PM",
    "power": 0.845,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "10/3/2024 12:15",
    "plant_id": "Green-tek-A25923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "12:15:00 PM",
    "power": 0.819,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "10/3/2024 12:30",
    "plant_id": "Green-tek-A25924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "12:30:00 PM",
    "power": 0.779,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.18462,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "10/3/2024 12:45",
    "plant_id": "Green-tek-A25925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "12:45:00 PM",
    "power": 0.725,
    "Energy": 0.181,
    "output Energy": 0.181,
    "Ac output": 1.181,
    "dc output": 1.20462,
    "AC_R_i": 0.00023609,
    "AC_S_i": 0.000259699,
    "AC_T_i": 0.00028567,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0543007,
    "AC_S_pow": 0.05973077,
    "AC_T_Pow": 0.0657041,
    "AC_R_output": 0.3543,
    "AC_S_output": 0.38973,
    "AC_T_output": 0.428703,
    "dc_current": 0.00133088,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.18462,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000181,
    "carbon offset": 0.22625,
    "trees planted": 5.027777778,
    "DC_R_output": 0.116919,
    "DC_S_output": 0.1286109,
    "DC_T_output": 0.14147199
  },
  {
    "datetime": "10/3/2024 13:00",
    "plant_id": "Green-tek-A25926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "1:00:00 PM",
    "power": 0.661,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "10/3/2024 13:15",
    "plant_id": "Green-tek-A25927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "1:15:00 PM",
    "power": 0.591,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "10/3/2024 13:30",
    "plant_id": "Green-tek-A25928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "1:30:00 PM",
    "power": 0.518,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "10/3/2024 13:45",
    "plant_id": "Green-tek-A25929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "1:45:00 PM",
    "power": 0.444,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "10/3/2024 14:00",
    "plant_id": "Green-tek-A25930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "2:00:00 PM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "10/3/2024 14:15",
    "plant_id": "Green-tek-A25931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "2:15:00 PM",
    "power": 0.308,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "10/3/2024 14:30",
    "plant_id": "Green-tek-A25932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "2:30:00 PM",
    "power": 0.248,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "10/3/2024 14:45",
    "plant_id": "Green-tek-A25933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "2:45:00 PM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "10/3/2024 15:00",
    "plant_id": "Green-tek-A25934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "3:00:00 PM",
    "power": 0.152,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "10/3/2024 15:15",
    "plant_id": "Green-tek-A25935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "3:15:00 PM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/3/2024 15:30",
    "plant_id": "Green-tek-A25936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "3:30:00 PM",
    "power": 0.086,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/3/2024 15:45",
    "plant_id": "Green-tek-A25937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "3:45:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/3/2024 16:00",
    "plant_id": "Green-tek-A25938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "4:00:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/3/2024 16:15",
    "plant_id": "Green-tek-A25939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/3/2024 16:30",
    "plant_id": "Green-tek-A25940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/3/2024 16:45",
    "plant_id": "Green-tek-A25941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/3/2024 17:00",
    "plant_id": "Green-tek-A25942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/3/2024 17:15",
    "plant_id": "Green-tek-A25943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/3/2024 17:30",
    "plant_id": "Green-tek-A25944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/3/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/4/2024 6:00",
    "plant_id": "Green-tek-A25945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/4/2024 6:15",
    "plant_id": "Green-tek-A25946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/4/2024 6:30",
    "plant_id": "Green-tek-A25947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/4/2024 6:45",
    "plant_id": "Green-tek-A25948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/4/2024 7:00",
    "plant_id": "Green-tek-A25949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/4/2024 7:15",
    "plant_id": "Green-tek-A25950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/4/2024 7:30",
    "plant_id": "Green-tek-A25951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/4/2024 7:45",
    "plant_id": "Green-tek-A25952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "10/4/2024 8:00",
    "plant_id": "Green-tek-A25953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "8:00:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "10/4/2024 8:15",
    "plant_id": "Green-tek-A25954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "8:15:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "10/4/2024 8:30",
    "plant_id": "Green-tek-A25955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "8:30:00 AM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "10/4/2024 8:45",
    "plant_id": "Green-tek-A25956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "8:45:00 AM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "10/4/2024 9:00",
    "plant_id": "Green-tek-A25957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "9:00:00 AM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "10/4/2024 9:15",
    "plant_id": "Green-tek-A25958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "9:15:00 AM",
    "power": 0.396,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "10/4/2024 9:30",
    "plant_id": "Green-tek-A25959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "9:30:00 AM",
    "power": 0.481,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "10/4/2024 9:45",
    "plant_id": "Green-tek-A25960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "9:45:00 AM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "10/4/2024 10:00",
    "plant_id": "Green-tek-A25961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "10:00:00 AM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "10/4/2024 10:15",
    "plant_id": "Green-tek-A25962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "10:15:00 AM",
    "power": 0.761,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "10/4/2024 10:30",
    "plant_id": "Green-tek-A25963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "10:30:00 AM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "10/4/2024 10:45",
    "plant_id": "Green-tek-A25964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "10:45:00 AM",
    "power": 0.934,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "10/4/2024 11:00",
    "plant_id": "Green-tek-A25965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "11:00:00 AM",
    "power": 1.003,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "10/4/2024 11:15",
    "plant_id": "Green-tek-A25966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "11:15:00 AM",
    "power": 1.056,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "10/4/2024 11:30",
    "plant_id": "Green-tek-A25967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "11:30:00 AM",
    "power": 1.088,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "10/4/2024 11:45",
    "plant_id": "Green-tek-A25968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "11:45:00 AM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "10/4/2024 12:00",
    "plant_id": "Green-tek-A25969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "12:00:00 PM",
    "power": 1.088,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.26928,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "10/4/2024 12:15",
    "plant_id": "Green-tek-A25970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "12:15:00 PM",
    "power": 1.056,
    "Energy": 0.264,
    "output Energy": 0.264,
    "Ac output": 1.264,
    "dc output": 1.28928,
    "AC_R_i": 0.00034435,
    "AC_S_i": 0.000378785,
    "AC_T_i": 0.00041666,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0792005,
    "AC_S_pow": 0.08712055,
    "AC_T_Pow": 0.0958318,
    "AC_R_output": 0.3792,
    "AC_S_output": 0.41712,
    "AC_T_output": 0.458832,
    "dc_current": 0.00194118,
    "dc_voltage": 136,
    "dc_power": 0.25602,
    "dc_output": 0.26928,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000264,
    "carbon offset": 0.33,
    "trees planted": 7.333333333,
    "DC_R_output": 0.125136,
    "DC_S_output": 0.1376496,
    "DC_T_output": 0.15141456
  },
  {
    "datetime": "10/4/2024 12:30",
    "plant_id": "Green-tek-A25971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "12:30:00 PM",
    "power": 1.003,
    "Energy": 0.251,
    "output Energy": 0.251,
    "Ac output": 1.251,
    "dc output": 1.27602,
    "AC_R_i": 0.00032739,
    "AC_S_i": 0.000360129,
    "AC_T_i": 0.00039614,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0752997,
    "AC_S_pow": 0.08282967,
    "AC_T_Pow": 0.0911122,
    "AC_R_output": 0.3753,
    "AC_S_output": 0.41283,
    "AC_T_output": 0.454113,
    "dc_current": 0.00184559,
    "dc_voltage": 136,
    "dc_power": 0.23766,
    "dc_output": 0.25602,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000251,
    "carbon offset": 0.31375,
    "trees planted": 6.972222222,
    "DC_R_output": 0.123849,
    "DC_S_output": 0.1362339,
    "DC_T_output": 0.14985729
  },
  {
    "datetime": "10/4/2024 12:45",
    "plant_id": "Green-tek-A25972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "12:45:00 PM",
    "power": 0.934,
    "Energy": 0.233,
    "output Energy": 0.233,
    "Ac output": 1.233,
    "dc output": 1.25766,
    "AC_R_i": 0.00030391,
    "AC_S_i": 0.000334301,
    "AC_T_i": 0.00036773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0698993,
    "AC_S_pow": 0.07688923,
    "AC_T_Pow": 0.0845779,
    "AC_R_output": 0.3699,
    "AC_S_output": 0.40689,
    "AC_T_output": 0.447579,
    "dc_current": 0.00171324,
    "dc_voltage": 136,
    "dc_power": 0.21726,
    "dc_output": 0.23766,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000233,
    "carbon offset": 0.29125,
    "trees planted": 6.472222222,
    "DC_R_output": 0.122067,
    "DC_S_output": 0.1342737,
    "DC_T_output": 0.14770107
  },
  {
    "datetime": "10/4/2024 13:00",
    "plant_id": "Green-tek-A25973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "1:00:00 PM",
    "power": 0.852,
    "Energy": 0.213,
    "output Energy": 0.213,
    "Ac output": 1.213,
    "dc output": 1.23726,
    "AC_R_i": 0.00027783,
    "AC_S_i": 0.000305613,
    "AC_T_i": 0.00033617,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0639009,
    "AC_S_pow": 0.07029099,
    "AC_T_Pow": 0.0773191,
    "AC_R_output": 0.3639,
    "AC_S_output": 0.40029,
    "AC_T_output": 0.440319,
    "dc_current": 0.00156618,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.21726,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000213,
    "carbon offset": 0.26625,
    "trees planted": 5.916666667,
    "DC_R_output": 0.120087,
    "DC_S_output": 0.1320957,
    "DC_T_output": 0.14530527
  },
  {
    "datetime": "10/4/2024 13:15",
    "plant_id": "Green-tek-A25974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "1:15:00 PM",
    "power": 0.761,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "10/4/2024 13:30",
    "plant_id": "Green-tek-A25975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "1:30:00 PM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "10/4/2024 13:45",
    "plant_id": "Green-tek-A25976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "1:45:00 PM",
    "power": 0.572,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1224,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "10/4/2024 14:00",
    "plant_id": "Green-tek-A25977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "2:00:00 PM",
    "power": 0.481,
    "Energy": 0.12,
    "output Energy": 0.12,
    "Ac output": 1.12,
    "dc output": 1.1424,
    "AC_R_i": 0.00015652,
    "AC_S_i": 0.000172172,
    "AC_T_i": 0.00018939,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0359996,
    "AC_S_pow": 0.03959956,
    "AC_T_Pow": 0.0435597,
    "AC_R_output": 0.336,
    "AC_S_output": 0.3696,
    "AC_T_output": 0.40656,
    "dc_current": 0.00088235,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.1224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00012,
    "carbon offset": 0.15,
    "trees planted": 3.333333333,
    "DC_R_output": 0.11088,
    "DC_S_output": 0.121968,
    "DC_T_output": 0.1341648
  },
  {
    "datetime": "10/4/2024 14:15",
    "plant_id": "Green-tek-A25978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "2:15:00 PM",
    "power": 0.396,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "10/4/2024 14:30",
    "plant_id": "Green-tek-A25979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "2:30:00 PM",
    "power": 0.32,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "10/4/2024 14:45",
    "plant_id": "Green-tek-A25980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "2:45:00 PM",
    "power": 0.253,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "10/4/2024 15:00",
    "plant_id": "Green-tek-A25981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "3:00:00 PM",
    "power": 0.196,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "10/4/2024 15:15",
    "plant_id": "Green-tek-A25982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "3:15:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "10/4/2024 15:30",
    "plant_id": "Green-tek-A25983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "3:30:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "10/4/2024 15:45",
    "plant_id": "Green-tek-A25984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "10/4/2024 16:00",
    "plant_id": "Green-tek-A25985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/4/2024 16:15",
    "plant_id": "Green-tek-A25986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/4/2024 16:30",
    "plant_id": "Green-tek-A25987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/4/2024 16:45",
    "plant_id": "Green-tek-A25988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/4/2024 17:00",
    "plant_id": "Green-tek-A25989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/4/2024 17:15",
    "plant_id": "Green-tek-A25990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/4/2024 17:30",
    "plant_id": "Green-tek-A25991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/4/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/5/2024 6:00",
    "plant_id": "Green-tek-A25992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/5/2024 6:15",
    "plant_id": "Green-tek-A25993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/5/2024 6:30",
    "plant_id": "Green-tek-A25994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/5/2024 6:45",
    "plant_id": "Green-tek-A25995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/5/2024 7:00",
    "plant_id": "Green-tek-A25996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/5/2024 7:15",
    "plant_id": "Green-tek-A25997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/5/2024 7:30",
    "plant_id": "Green-tek-A25998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "7:30:00 AM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/5/2024 7:45",
    "plant_id": "Green-tek-A25999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "7:45:00 AM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "10/5/2024 8:00",
    "plant_id": "Green-tek-A26000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "8:00:00 AM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/5/2024 8:15",
    "plant_id": "Green-tek-A26001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "8:15:00 AM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "10/5/2024 8:30",
    "plant_id": "Green-tek-A26002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "8:30:00 AM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "10/5/2024 8:45",
    "plant_id": "Green-tek-A26003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "8:45:00 AM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "10/5/2024 9:00",
    "plant_id": "Green-tek-A26004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "9:00:00 AM",
    "power": 0.346,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "10/5/2024 9:15",
    "plant_id": "Green-tek-A26005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "9:15:00 AM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "10/5/2024 9:30",
    "plant_id": "Green-tek-A26006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "9:30:00 AM",
    "power": 0.52,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "10/5/2024 9:45",
    "plant_id": "Green-tek-A26007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "9:45:00 AM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "10/5/2024 10:00",
    "plant_id": "Green-tek-A26008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "10:00:00 AM",
    "power": 0.721,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "10/5/2024 10:15",
    "plant_id": "Green-tek-A26009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "10:15:00 AM",
    "power": 0.823,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "10/5/2024 10:30",
    "plant_id": "Green-tek-A26010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "10:30:00 AM",
    "power": 0.921,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "10/5/2024 10:45",
    "plant_id": "Green-tek-A26011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "10:45:00 AM",
    "power": 1.009,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "10/5/2024 11:00",
    "plant_id": "Green-tek-A26012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "11:00:00 AM",
    "power": 1.084,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "10/5/2024 11:15",
    "plant_id": "Green-tek-A26013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "11:15:00 AM",
    "power": 1.141,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "10/5/2024 11:30",
    "plant_id": "Green-tek-A26014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "11:30:00 AM",
    "power": 1.176,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "10/5/2024 11:45",
    "plant_id": "Green-tek-A26015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "11:45:00 AM",
    "power": 1.188,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "10/5/2024 12:00",
    "plant_id": "Green-tek-A26016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "12:00:00 PM",
    "power": 1.176,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "10/5/2024 12:15",
    "plant_id": "Green-tek-A26017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "12:15:00 PM",
    "power": 1.141,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "10/5/2024 12:30",
    "plant_id": "Green-tek-A26018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "12:30:00 PM",
    "power": 1.084,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "10/5/2024 12:45",
    "plant_id": "Green-tek-A26019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "12:45:00 PM",
    "power": 1.009,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "10/5/2024 13:00",
    "plant_id": "Green-tek-A26020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "1:00:00 PM",
    "power": 0.921,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "10/5/2024 13:15",
    "plant_id": "Green-tek-A26021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "1:15:00 PM",
    "power": 0.823,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.1836,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "10/5/2024 13:30",
    "plant_id": "Green-tek-A26022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "1:30:00 PM",
    "power": 0.721,
    "Energy": 0.18,
    "output Energy": 0.18,
    "Ac output": 1.18,
    "dc output": 1.2036,
    "AC_R_i": 0.00023478,
    "AC_S_i": 0.000258258,
    "AC_T_i": 0.00028408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0539994,
    "AC_S_pow": 0.05939934,
    "AC_T_Pow": 0.0653384,
    "AC_R_output": 0.354,
    "AC_S_output": 0.3894,
    "AC_T_output": 0.42834,
    "dc_current": 0.00132353,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.1836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00018,
    "carbon offset": 0.225,
    "trees planted": 5,
    "DC_R_output": 0.11682,
    "DC_S_output": 0.128502,
    "DC_T_output": 0.1413522
  },
  {
    "datetime": "10/5/2024 13:45",
    "plant_id": "Green-tek-A26023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "1:45:00 PM",
    "power": 0.619,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.1326,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "10/5/2024 14:00",
    "plant_id": "Green-tek-A26024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "2:00:00 PM",
    "power": 0.52,
    "Energy": 0.13,
    "output Energy": 0.13,
    "Ac output": 1.13,
    "dc output": 1.1526,
    "AC_R_i": 0.00016957,
    "AC_S_i": 0.000186527,
    "AC_T_i": 0.00020518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0390011,
    "AC_S_pow": 0.04290121,
    "AC_T_Pow": 0.0471914,
    "AC_R_output": 0.339,
    "AC_S_output": 0.3729,
    "AC_T_output": 0.41019,
    "dc_current": 0.00095588,
    "dc_voltage": 136,
    "dc_power": 0.10914,
    "dc_output": 0.1326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00013,
    "carbon offset": 0.1625,
    "trees planted": 3.611111111,
    "DC_R_output": 0.11187,
    "DC_S_output": 0.123057,
    "DC_T_output": 0.1353627
  },
  {
    "datetime": "10/5/2024 14:15",
    "plant_id": "Green-tek-A26025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "2:15:00 PM",
    "power": 0.428,
    "Energy": 0.107,
    "output Energy": 0.107,
    "Ac output": 1.107,
    "dc output": 1.12914,
    "AC_R_i": 0.00013957,
    "AC_S_i": 0.000153527,
    "AC_T_i": 0.00016888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0321011,
    "AC_S_pow": 0.03531121,
    "AC_T_Pow": 0.0388424,
    "AC_R_output": 0.3321,
    "AC_S_output": 0.36531,
    "AC_T_output": 0.401841,
    "dc_current": 0.00078676,
    "dc_voltage": 136,
    "dc_power": 0.08772,
    "dc_output": 0.10914,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000107,
    "carbon offset": 0.13375,
    "trees planted": 2.972222222,
    "DC_R_output": 0.109593,
    "DC_S_output": 0.1205523,
    "DC_T_output": 0.13260753
  },
  {
    "datetime": "10/5/2024 14:30",
    "plant_id": "Green-tek-A26026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "2:30:00 PM",
    "power": 0.346,
    "Energy": 0.086,
    "output Energy": 0.086,
    "Ac output": 1.086,
    "dc output": 1.10772,
    "AC_R_i": 0.00011217,
    "AC_S_i": 0.000123387,
    "AC_T_i": 0.00013573,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0257991,
    "AC_S_pow": 0.02837901,
    "AC_T_Pow": 0.0312179,
    "AC_R_output": 0.3258,
    "AC_S_output": 0.35838,
    "AC_T_output": 0.394218,
    "dc_current": 0.00063235,
    "dc_voltage": 136,
    "dc_power": 0.06936,
    "dc_output": 0.08772,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000086,
    "carbon offset": 0.1075,
    "trees planted": 2.388888889,
    "DC_R_output": 0.107514,
    "DC_S_output": 0.1182654,
    "DC_T_output": 0.13009194
  },
  {
    "datetime": "10/5/2024 14:45",
    "plant_id": "Green-tek-A26027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "2:45:00 PM",
    "power": 0.273,
    "Energy": 0.068,
    "output Energy": 0.068,
    "Ac output": 1.068,
    "dc output": 1.08936,
    "AC_R_i": 0.0000887,
    "AC_S_i": 0.00009757,
    "AC_T_i": 0.00010733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020401,
    "AC_S_pow": 0.0224411,
    "AC_T_Pow": 0.0246859,
    "AC_R_output": 0.3204,
    "AC_S_output": 0.35244,
    "AC_T_output": 0.387684,
    "dc_current": 0.0005,
    "dc_voltage": 136,
    "dc_power": 0.05406,
    "dc_output": 0.06936,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000068,
    "carbon offset": 0.085,
    "trees planted": 1.888888889,
    "DC_R_output": 0.105732,
    "DC_S_output": 0.1163052,
    "DC_T_output": 0.12793572
  },
  {
    "datetime": "10/5/2024 15:00",
    "plant_id": "Green-tek-A26028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "3:00:00 PM",
    "power": 0.212,
    "Energy": 0.053,
    "output Energy": 0.053,
    "Ac output": 1.053,
    "dc output": 1.07406,
    "AC_R_i": 0.00006913,
    "AC_S_i": 0.000076043,
    "AC_T_i": 0.00008365,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0158999,
    "AC_S_pow": 0.01748989,
    "AC_T_Pow": 0.0192395,
    "AC_R_output": 0.3159,
    "AC_S_output": 0.34749,
    "AC_T_output": 0.382239,
    "dc_current": 0.00038971,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05406,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000053,
    "carbon offset": 0.06625,
    "trees planted": 1.472222222,
    "DC_R_output": 0.104247,
    "DC_S_output": 0.1146717,
    "DC_T_output": 0.12613887
  },
  {
    "datetime": "10/5/2024 15:15",
    "plant_id": "Green-tek-A26029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "3:15:00 PM",
    "power": 0.161,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "10/5/2024 15:30",
    "plant_id": "Green-tek-A26030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "3:30:00 PM",
    "power": 0.12,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/5/2024 15:45",
    "plant_id": "Green-tek-A26031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "3:45:00 PM",
    "power": 0.087,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "10/5/2024 16:00",
    "plant_id": "Green-tek-A26032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "4:00:00 PM",
    "power": 0.062,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/5/2024 16:15",
    "plant_id": "Green-tek-A26033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/5/2024 16:30",
    "plant_id": "Green-tek-A26034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/5/2024 16:45",
    "plant_id": "Green-tek-A26035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/5/2024 17:00",
    "plant_id": "Green-tek-A26036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/5/2024 17:15",
    "plant_id": "Green-tek-A26037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/5/2024 17:30",
    "plant_id": "Green-tek-A26038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/5/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/6/2024 6:00",
    "plant_id": "Green-tek-A26039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/6/2024 6:15",
    "plant_id": "Green-tek-A26040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/6/2024 6:30",
    "plant_id": "Green-tek-A26041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/6/2024 6:45",
    "plant_id": "Green-tek-A26042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/6/2024 7:00",
    "plant_id": "Green-tek-A26043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/6/2024 7:15",
    "plant_id": "Green-tek-A26044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/6/2024 7:30",
    "plant_id": "Green-tek-A26045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/6/2024 7:45",
    "plant_id": "Green-tek-A26046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/6/2024 8:00",
    "plant_id": "Green-tek-A26047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "8:00:00 AM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/6/2024 8:15",
    "plant_id": "Green-tek-A26048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "8:15:00 AM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "10/6/2024 8:30",
    "plant_id": "Green-tek-A26049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "8:30:00 AM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/6/2024 8:45",
    "plant_id": "Green-tek-A26050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "8:45:00 AM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "10/6/2024 9:00",
    "plant_id": "Green-tek-A26051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "9:00:00 AM",
    "power": 0.332,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "10/6/2024 9:15",
    "plant_id": "Green-tek-A26052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "9:15:00 AM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "10/6/2024 9:30",
    "plant_id": "Green-tek-A26053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "9:30:00 AM",
    "power": 0.5,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "10/6/2024 9:45",
    "plant_id": "Green-tek-A26054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "9:45:00 AM",
    "power": 0.595,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "10/6/2024 10:00",
    "plant_id": "Green-tek-A26055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "10:00:00 AM",
    "power": 0.693,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "10/6/2024 10:15",
    "plant_id": "Green-tek-A26056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "10:15:00 AM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "10/6/2024 10:30",
    "plant_id": "Green-tek-A26057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "10:30:00 AM",
    "power": 0.886,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "10/6/2024 10:45",
    "plant_id": "Green-tek-A26058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "10:45:00 AM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "10/6/2024 11:00",
    "plant_id": "Green-tek-A26059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "11:00:00 AM",
    "power": 1.043,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "10/6/2024 11:15",
    "plant_id": "Green-tek-A26060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "11:15:00 AM",
    "power": 1.097,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "10/6/2024 11:30",
    "plant_id": "Green-tek-A26061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "11:30:00 AM",
    "power": 1.131,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.29172,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "10/6/2024 11:45",
    "plant_id": "Green-tek-A26062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "11:45:00 AM",
    "power": 1.143,
    "Energy": 0.286,
    "output Energy": 0.286,
    "Ac output": 1.286,
    "dc output": 1.31172,
    "AC_R_i": 0.00037304,
    "AC_S_i": 0.000410344,
    "AC_T_i": 0.00045138,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0857992,
    "AC_S_pow": 0.09437912,
    "AC_T_Pow": 0.1038174,
    "AC_R_output": 0.3858,
    "AC_S_output": 0.42438,
    "AC_T_output": 0.466818,
    "dc_current": 0.00210294,
    "dc_voltage": 136,
    "dc_power": 0.28866,
    "dc_output": 0.29172,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000286,
    "carbon offset": 0.3575,
    "trees planted": 7.944444444,
    "DC_R_output": 0.127314,
    "DC_S_output": 0.1400454,
    "DC_T_output": 0.15404994
  },
  {
    "datetime": "10/6/2024 12:00",
    "plant_id": "Green-tek-A26063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "12:00:00 PM",
    "power": 1.131,
    "Energy": 0.283,
    "output Energy": 0.283,
    "Ac output": 1.283,
    "dc output": 1.30866,
    "AC_R_i": 0.00036913,
    "AC_S_i": 0.000406043,
    "AC_T_i": 0.00044665,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0848999,
    "AC_S_pow": 0.09338989,
    "AC_T_Pow": 0.1027295,
    "AC_R_output": 0.3849,
    "AC_S_output": 0.42339,
    "AC_T_output": 0.465729,
    "dc_current": 0.00208088,
    "dc_voltage": 136,
    "dc_power": 0.27948,
    "dc_output": 0.28866,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000283,
    "carbon offset": 0.35375,
    "trees planted": 7.861111111,
    "DC_R_output": 0.127017,
    "DC_S_output": 0.1397187,
    "DC_T_output": 0.15369057
  },
  {
    "datetime": "10/6/2024 12:15",
    "plant_id": "Green-tek-A26064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "12:15:00 PM",
    "power": 1.097,
    "Energy": 0.274,
    "output Energy": 0.274,
    "Ac output": 1.274,
    "dc output": 1.29948,
    "AC_R_i": 0.00035739,
    "AC_S_i": 0.000393129,
    "AC_T_i": 0.00043244,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0821997,
    "AC_S_pow": 0.09041967,
    "AC_T_Pow": 0.0994612,
    "AC_R_output": 0.3822,
    "AC_S_output": 0.42042,
    "AC_T_output": 0.462462,
    "dc_current": 0.00201471,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.27948,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000274,
    "carbon offset": 0.3425,
    "trees planted": 7.611111111,
    "DC_R_output": 0.126126,
    "DC_S_output": 0.1387386,
    "DC_T_output": 0.15261246
  },
  {
    "datetime": "10/6/2024 12:30",
    "plant_id": "Green-tek-A26065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "12:30:00 PM",
    "power": 1.043,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "10/6/2024 12:45",
    "plant_id": "Green-tek-A26066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "12:45:00 PM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "10/6/2024 13:00",
    "plant_id": "Green-tek-A26067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "1:00:00 PM",
    "power": 0.886,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "10/6/2024 13:15",
    "plant_id": "Green-tek-A26068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "1:15:00 PM",
    "power": 0.792,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "10/6/2024 13:30",
    "plant_id": "Green-tek-A26069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "1:30:00 PM",
    "power": 0.693,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "10/6/2024 13:45",
    "plant_id": "Green-tek-A26070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "1:45:00 PM",
    "power": 0.595,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "10/6/2024 14:00",
    "plant_id": "Green-tek-A26071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "2:00:00 PM",
    "power": 0.5,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "10/6/2024 14:15",
    "plant_id": "Green-tek-A26072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "2:15:00 PM",
    "power": 0.412,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "10/6/2024 14:30",
    "plant_id": "Green-tek-A26073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "2:30:00 PM",
    "power": 0.332,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "10/6/2024 14:45",
    "plant_id": "Green-tek-A26074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "2:45:00 PM",
    "power": 0.263,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "10/6/2024 15:00",
    "plant_id": "Green-tek-A26075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "3:00:00 PM",
    "power": 0.204,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/6/2024 15:15",
    "plant_id": "Green-tek-A26076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "3:15:00 PM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "10/6/2024 15:30",
    "plant_id": "Green-tek-A26077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "3:30:00 PM",
    "power": 0.115,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/6/2024 15:45",
    "plant_id": "Green-tek-A26078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/6/2024 16:00",
    "plant_id": "Green-tek-A26079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/6/2024 16:15",
    "plant_id": "Green-tek-A26080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/6/2024 16:30",
    "plant_id": "Green-tek-A26081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/6/2024 16:45",
    "plant_id": "Green-tek-A26082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/6/2024 17:00",
    "plant_id": "Green-tek-A26083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/6/2024 17:15",
    "plant_id": "Green-tek-A26084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/6/2024 17:30",
    "plant_id": "Green-tek-A26085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/6/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/7/2024 6:00",
    "plant_id": "Green-tek-A26086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/7/2024 6:15",
    "plant_id": "Green-tek-A26087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/7/2024 6:30",
    "plant_id": "Green-tek-A26088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/7/2024 6:45",
    "plant_id": "Green-tek-A26089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/7/2024 7:00",
    "plant_id": "Green-tek-A26090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/7/2024 7:15",
    "plant_id": "Green-tek-A26091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "7:15:00 AM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/7/2024 7:30",
    "plant_id": "Green-tek-A26092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "7:30:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/7/2024 7:45",
    "plant_id": "Green-tek-A26093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "7:45:00 AM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/7/2024 8:00",
    "plant_id": "Green-tek-A26094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "8:00:00 AM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "10/7/2024 8:15",
    "plant_id": "Green-tek-A26095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "8:15:00 AM",
    "power": 0.17,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "10/7/2024 8:30",
    "plant_id": "Green-tek-A26096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "8:30:00 AM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "10/7/2024 8:45",
    "plant_id": "Green-tek-A26097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "8:45:00 AM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "10/7/2024 9:00",
    "plant_id": "Green-tek-A26098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "9:00:00 AM",
    "power": 0.366,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "10/7/2024 9:15",
    "plant_id": "Green-tek-A26099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "9:15:00 AM",
    "power": 0.454,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "10/7/2024 9:30",
    "plant_id": "Green-tek-A26100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "9:30:00 AM",
    "power": 0.551,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "10/7/2024 9:45",
    "plant_id": "Green-tek-A26101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "9:45:00 AM",
    "power": 0.655,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "10/7/2024 10:00",
    "plant_id": "Green-tek-A26102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "10:00:00 AM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/7/2024 10:15",
    "plant_id": "Green-tek-A26103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "10:15:00 AM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "10/7/2024 10:30",
    "plant_id": "Green-tek-A26104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "10:30:00 AM",
    "power": 0.975,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "10/7/2024 10:45",
    "plant_id": "Green-tek-A26105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "10:45:00 AM",
    "power": 1.069,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "10/7/2024 11:00",
    "plant_id": "Green-tek-A26106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "11:00:00 AM",
    "power": 1.148,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "10/7/2024 11:15",
    "plant_id": "Green-tek-A26107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "11:15:00 AM",
    "power": 1.208,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "10/7/2024 11:30",
    "plant_id": "Green-tek-A26108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "11:30:00 AM",
    "power": 1.245,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.3213,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "10/7/2024 11:45",
    "plant_id": "Green-tek-A26109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "11:45:00 AM",
    "power": 1.258,
    "Energy": 0.315,
    "output Energy": 0.315,
    "Ac output": 1.315,
    "dc output": 1.3413,
    "AC_R_i": 0.00041087,
    "AC_S_i": 0.000451957,
    "AC_T_i": 0.00049715,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0945001,
    "AC_S_pow": 0.10395011,
    "AC_T_Pow": 0.1143445,
    "AC_R_output": 0.3945,
    "AC_S_output": 0.43395,
    "AC_T_output": 0.477345,
    "dc_current": 0.00231618,
    "dc_voltage": 136,
    "dc_power": 0.31722,
    "dc_output": 0.3213,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000315,
    "carbon offset": 0.39375,
    "trees planted": 8.75,
    "DC_R_output": 0.130185,
    "DC_S_output": 0.1432035,
    "DC_T_output": 0.15752385
  },
  {
    "datetime": "10/7/2024 12:00",
    "plant_id": "Green-tek-A26110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "12:00:00 PM",
    "power": 1.245,
    "Energy": 0.311,
    "output Energy": 0.311,
    "Ac output": 1.311,
    "dc output": 1.33722,
    "AC_R_i": 0.00040565,
    "AC_S_i": 0.000446215,
    "AC_T_i": 0.00049084,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0932995,
    "AC_S_pow": 0.10262945,
    "AC_T_Pow": 0.1128932,
    "AC_R_output": 0.3933,
    "AC_S_output": 0.43263,
    "AC_T_output": 0.475893,
    "dc_current": 0.00228676,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.31722,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000311,
    "carbon offset": 0.38875,
    "trees planted": 8.638888889,
    "DC_R_output": 0.129789,
    "DC_S_output": 0.1427679,
    "DC_T_output": 0.15704469
  },
  {
    "datetime": "10/7/2024 12:15",
    "plant_id": "Green-tek-A26111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "12:15:00 PM",
    "power": 1.208,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "10/7/2024 12:30",
    "plant_id": "Green-tek-A26112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "12:30:00 PM",
    "power": 1.148,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "10/7/2024 12:45",
    "plant_id": "Green-tek-A26113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "12:45:00 PM",
    "power": 1.069,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "10/7/2024 13:00",
    "plant_id": "Green-tek-A26114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "1:00:00 PM",
    "power": 0.975,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.22236,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "10/7/2024 13:15",
    "plant_id": "Green-tek-A26115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "1:15:00 PM",
    "power": 0.871,
    "Energy": 0.218,
    "output Energy": 0.218,
    "Ac output": 1.218,
    "dc output": 1.24236,
    "AC_R_i": 0.00028435,
    "AC_S_i": 0.000312785,
    "AC_T_i": 0.00034406,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0654005,
    "AC_S_pow": 0.07194055,
    "AC_T_Pow": 0.0791338,
    "AC_R_output": 0.3654,
    "AC_S_output": 0.40194,
    "AC_T_output": 0.442134,
    "dc_current": 0.00160294,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.22236,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000218,
    "carbon offset": 0.2725,
    "trees planted": 6.055555556,
    "DC_R_output": 0.120582,
    "DC_S_output": 0.1326402,
    "DC_T_output": 0.14590422
  },
  {
    "datetime": "10/7/2024 13:30",
    "plant_id": "Green-tek-A26116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "1:30:00 PM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/7/2024 13:45",
    "plant_id": "Green-tek-A26117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "1:45:00 PM",
    "power": 0.655,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "10/7/2024 14:00",
    "plant_id": "Green-tek-A26118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "2:00:00 PM",
    "power": 0.551,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11526,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "10/7/2024 14:15",
    "plant_id": "Green-tek-A26119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "2:15:00 PM",
    "power": 0.454,
    "Energy": 0.113,
    "output Energy": 0.113,
    "Ac output": 1.113,
    "dc output": 1.13526,
    "AC_R_i": 0.00014739,
    "AC_S_i": 0.000162129,
    "AC_T_i": 0.00017834,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0338997,
    "AC_S_pow": 0.03728967,
    "AC_T_Pow": 0.0410182,
    "AC_R_output": 0.3339,
    "AC_S_output": 0.36729,
    "AC_T_output": 0.404019,
    "dc_current": 0.00083088,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.11526,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000113,
    "carbon offset": 0.14125,
    "trees planted": 3.138888889,
    "DC_R_output": 0.110187,
    "DC_S_output": 0.1212057,
    "DC_T_output": 0.13332627
  },
  {
    "datetime": "10/7/2024 14:30",
    "plant_id": "Green-tek-A26120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "2:30:00 PM",
    "power": 0.366,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "10/7/2024 14:45",
    "plant_id": "Green-tek-A26121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "2:45:00 PM",
    "power": 0.289,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "10/7/2024 15:00",
    "plant_id": "Green-tek-A26122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "3:00:00 PM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "10/7/2024 15:15",
    "plant_id": "Green-tek-A26123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "3:15:00 PM",
    "power": 0.17,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "10/7/2024 15:30",
    "plant_id": "Green-tek-A26124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "3:30:00 PM",
    "power": 0.127,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "10/7/2024 15:45",
    "plant_id": "Green-tek-A26125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "3:45:00 PM",
    "power": 0.092,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/7/2024 16:00",
    "plant_id": "Green-tek-A26126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "4:00:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/7/2024 16:15",
    "plant_id": "Green-tek-A26127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "4:15:00 PM",
    "power": 0.046,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/7/2024 16:30",
    "plant_id": "Green-tek-A26128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/7/2024 16:45",
    "plant_id": "Green-tek-A26129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/7/2024 17:00",
    "plant_id": "Green-tek-A26130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/7/2024 17:15",
    "plant_id": "Green-tek-A26131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/7/2024 17:30",
    "plant_id": "Green-tek-A26132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/7/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/8/2024 6:00",
    "plant_id": "Green-tek-A26133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/8/2024 6:15",
    "plant_id": "Green-tek-A26134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/8/2024 6:30",
    "plant_id": "Green-tek-A26135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/8/2024 6:45",
    "plant_id": "Green-tek-A26136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/8/2024 7:00",
    "plant_id": "Green-tek-A26137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/8/2024 7:15",
    "plant_id": "Green-tek-A26138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "10/8/2024 7:30",
    "plant_id": "Green-tek-A26139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "7:30:00 AM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "10/8/2024 7:45",
    "plant_id": "Green-tek-A26140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "7:45:00 AM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "10/8/2024 8:00",
    "plant_id": "Green-tek-A26141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "8:00:00 AM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "10/8/2024 8:15",
    "plant_id": "Green-tek-A26142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "8:15:00 AM",
    "power": 0.134,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/8/2024 8:30",
    "plant_id": "Green-tek-A26143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "8:30:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "10/8/2024 8:45",
    "plant_id": "Green-tek-A26144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "8:45:00 AM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "10/8/2024 9:00",
    "plant_id": "Green-tek-A26145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "9:00:00 AM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "10/8/2024 9:15",
    "plant_id": "Green-tek-A26146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "9:15:00 AM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "10/8/2024 9:30",
    "plant_id": "Green-tek-A26147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "9:30:00 AM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "10/8/2024 9:45",
    "plant_id": "Green-tek-A26148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "9:45:00 AM",
    "power": 0.514,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "10/8/2024 10:00",
    "plant_id": "Green-tek-A26149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "10:00:00 AM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "10/8/2024 10:15",
    "plant_id": "Green-tek-A26150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "10:15:00 AM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "10/8/2024 10:30",
    "plant_id": "Green-tek-A26151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "10:30:00 AM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/8/2024 10:45",
    "plant_id": "Green-tek-A26152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "10:45:00 AM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "10/8/2024 11:00",
    "plant_id": "Green-tek-A26153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "11:00:00 AM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "10/8/2024 11:15",
    "plant_id": "Green-tek-A26154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "11:15:00 AM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "10/8/2024 11:30",
    "plant_id": "Green-tek-A26155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "11:30:00 AM",
    "power": 0.978,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.25194,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "10/8/2024 11:45",
    "plant_id": "Green-tek-A26156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "11:45:00 AM",
    "power": 0.988,
    "Energy": 0.247,
    "output Energy": 0.247,
    "Ac output": 1.247,
    "dc output": 1.27194,
    "AC_R_i": 0.00032217,
    "AC_S_i": 0.000354387,
    "AC_T_i": 0.00038983,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0740991,
    "AC_S_pow": 0.08150901,
    "AC_T_Pow": 0.0896609,
    "AC_R_output": 0.3741,
    "AC_S_output": 0.41151,
    "AC_T_output": 0.452661,
    "dc_current": 0.00181618,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.25194,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000247,
    "carbon offset": 0.30875,
    "trees planted": 6.861111111,
    "DC_R_output": 0.123453,
    "DC_S_output": 0.1357983,
    "DC_T_output": 0.14937813
  },
  {
    "datetime": "10/8/2024 12:00",
    "plant_id": "Green-tek-A26157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "12:00:00 PM",
    "power": 0.978,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.24174,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "10/8/2024 12:15",
    "plant_id": "Green-tek-A26158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "12:15:00 PM",
    "power": 0.948,
    "Energy": 0.237,
    "output Energy": 0.237,
    "Ac output": 1.237,
    "dc output": 1.26174,
    "AC_R_i": 0.00030913,
    "AC_S_i": 0.000340043,
    "AC_T_i": 0.00037405,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0710999,
    "AC_S_pow": 0.07820989,
    "AC_T_Pow": 0.0860315,
    "AC_R_output": 0.3711,
    "AC_S_output": 0.40821,
    "AC_T_output": 0.449031,
    "dc_current": 0.00174265,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.24174,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000237,
    "carbon offset": 0.29625,
    "trees planted": 6.583333333,
    "DC_R_output": 0.122463,
    "DC_S_output": 0.1347093,
    "DC_T_output": 0.14818023
  },
  {
    "datetime": "10/8/2024 12:30",
    "plant_id": "Green-tek-A26159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "12:30:00 PM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "10/8/2024 12:45",
    "plant_id": "Green-tek-A26160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "12:45:00 PM",
    "power": 0.839,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "10/8/2024 13:00",
    "plant_id": "Green-tek-A26161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "1:00:00 PM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17442,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/8/2024 13:15",
    "plant_id": "Green-tek-A26162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "1:15:00 PM",
    "power": 0.684,
    "Energy": 0.171,
    "output Energy": 0.171,
    "Ac output": 1.171,
    "dc output": 1.19442,
    "AC_R_i": 0.00022304,
    "AC_S_i": 0.000245344,
    "AC_T_i": 0.00026988,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0512992,
    "AC_S_pow": 0.05642912,
    "AC_T_Pow": 0.0620724,
    "AC_R_output": 0.3513,
    "AC_S_output": 0.38643,
    "AC_T_output": 0.425073,
    "dc_current": 0.00125735,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17442,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000171,
    "carbon offset": 0.21375,
    "trees planted": 4.75,
    "DC_R_output": 0.115929,
    "DC_S_output": 0.1275219,
    "DC_T_output": 0.14027409
  },
  {
    "datetime": "10/8/2024 13:30",
    "plant_id": "Green-tek-A26163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "1:30:00 PM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.13158,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "10/8/2024 13:45",
    "plant_id": "Green-tek-A26164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "1:45:00 PM",
    "power": 0.514,
    "Energy": 0.129,
    "output Energy": 0.129,
    "Ac output": 1.129,
    "dc output": 1.15158,
    "AC_R_i": 0.00016826,
    "AC_S_i": 0.000185086,
    "AC_T_i": 0.00020359,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0386998,
    "AC_S_pow": 0.04256978,
    "AC_T_Pow": 0.0468257,
    "AC_R_output": 0.3387,
    "AC_S_output": 0.37257,
    "AC_T_output": 0.409827,
    "dc_current": 0.00094853,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.13158,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000129,
    "carbon offset": 0.16125,
    "trees planted": 3.583333333,
    "DC_R_output": 0.111771,
    "DC_S_output": 0.1229481,
    "DC_T_output": 0.13524291
  },
  {
    "datetime": "10/8/2024 14:00",
    "plant_id": "Green-tek-A26165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "2:00:00 PM",
    "power": 0.432,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "10/8/2024 14:15",
    "plant_id": "Green-tek-A26166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "2:15:00 PM",
    "power": 0.356,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07344,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "10/8/2024 14:30",
    "plant_id": "Green-tek-A26167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "2:30:00 PM",
    "power": 0.287,
    "Energy": 0.072,
    "output Energy": 0.072,
    "Ac output": 1.072,
    "dc output": 1.09344,
    "AC_R_i": 0.00009391,
    "AC_S_i": 0.000103301,
    "AC_T_i": 0.00011363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0215993,
    "AC_S_pow": 0.02375923,
    "AC_T_Pow": 0.0261349,
    "AC_R_output": 0.3216,
    "AC_S_output": 0.35376,
    "AC_T_output": 0.389136,
    "dc_current": 0.00052941,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07344,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000072,
    "carbon offset": 0.09,
    "trees planted": 2,
    "DC_R_output": 0.106128,
    "DC_S_output": 0.1167408,
    "DC_T_output": 0.12841488
  },
  {
    "datetime": "10/8/2024 14:45",
    "plant_id": "Green-tek-A26168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "2:45:00 PM",
    "power": 0.227,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "10/8/2024 15:00",
    "plant_id": "Green-tek-A26169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "3:00:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "10/8/2024 15:15",
    "plant_id": "Green-tek-A26170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "3:15:00 PM",
    "power": 0.134,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/8/2024 15:30",
    "plant_id": "Green-tek-A26171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "3:30:00 PM",
    "power": 0.099,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "10/8/2024 15:45",
    "plant_id": "Green-tek-A26172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "3:45:00 PM",
    "power": 0.072,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "10/8/2024 16:00",
    "plant_id": "Green-tek-A26173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "4:00:00 PM",
    "power": 0.052,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "10/8/2024 16:15",
    "plant_id": "Green-tek-A26174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "10/8/2024 16:30",
    "plant_id": "Green-tek-A26175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/8/2024 16:45",
    "plant_id": "Green-tek-A26176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/8/2024 17:00",
    "plant_id": "Green-tek-A26177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/8/2024 17:15",
    "plant_id": "Green-tek-A26178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/8/2024 17:30",
    "plant_id": "Green-tek-A26179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/8/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/9/2024 6:00",
    "plant_id": "Green-tek-A26180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/9/2024 6:15",
    "plant_id": "Green-tek-A26181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/9/2024 6:30",
    "plant_id": "Green-tek-A26182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/9/2024 6:45",
    "plant_id": "Green-tek-A26183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "6:45:00 AM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/9/2024 7:00",
    "plant_id": "Green-tek-A26184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "7:00:00 AM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/9/2024 7:15",
    "plant_id": "Green-tek-A26185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "7:15:00 AM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "10/9/2024 7:30",
    "plant_id": "Green-tek-A26186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "7:30:00 AM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "10/9/2024 7:45",
    "plant_id": "Green-tek-A26187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "7:45:00 AM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "10/9/2024 8:00",
    "plant_id": "Green-tek-A26188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "8:00:00 AM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "10/9/2024 8:15",
    "plant_id": "Green-tek-A26189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "8:15:00 AM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "10/9/2024 8:30",
    "plant_id": "Green-tek-A26190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "8:30:00 AM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "10/9/2024 8:45",
    "plant_id": "Green-tek-A26191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "8:45:00 AM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "10/9/2024 9:00",
    "plant_id": "Green-tek-A26192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "9:00:00 AM",
    "power": 0.294,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "10/9/2024 9:15",
    "plant_id": "Green-tek-A26193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "9:15:00 AM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "10/9/2024 9:30",
    "plant_id": "Green-tek-A26194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "9:30:00 AM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "10/9/2024 9:45",
    "plant_id": "Green-tek-A26195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "9:45:00 AM",
    "power": 0.527,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "10/9/2024 10:00",
    "plant_id": "Green-tek-A26196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "10:00:00 AM",
    "power": 0.614,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "10/9/2024 10:15",
    "plant_id": "Green-tek-A26197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "10:15:00 AM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "10/9/2024 10:30",
    "plant_id": "Green-tek-A26198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "10:30:00 AM",
    "power": 0.784,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "10/9/2024 10:45",
    "plant_id": "Green-tek-A26199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "10:45:00 AM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "10/9/2024 11:00",
    "plant_id": "Green-tek-A26200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "11:00:00 AM",
    "power": 0.923,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "10/9/2024 11:15",
    "plant_id": "Green-tek-A26201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "11:15:00 AM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "10/9/2024 11:30",
    "plant_id": "Green-tek-A26202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "11:30:00 AM",
    "power": 1.002,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "10/9/2024 11:45",
    "plant_id": "Green-tek-A26203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "11:45:00 AM",
    "power": 1.012,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.255,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "10/9/2024 12:00",
    "plant_id": "Green-tek-A26204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "12:00:00 PM",
    "power": 1.002,
    "Energy": 0.25,
    "output Energy": 0.25,
    "Ac output": 1.25,
    "dc output": 1.275,
    "AC_R_i": 0.00032609,
    "AC_S_i": 0.000358699,
    "AC_T_i": 0.00039457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0750007,
    "AC_S_pow": 0.08250077,
    "AC_T_Pow": 0.0907511,
    "AC_R_output": 0.375,
    "AC_S_output": 0.4125,
    "AC_T_output": 0.45375,
    "dc_current": 0.00183824,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00025,
    "carbon offset": 0.3125,
    "trees planted": 6.944444444,
    "DC_R_output": 0.12375,
    "DC_S_output": 0.136125,
    "DC_T_output": 0.1497375
  },
  {
    "datetime": "10/9/2024 12:15",
    "plant_id": "Green-tek-A26205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "12:15:00 PM",
    "power": 0.972,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.23562,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "10/9/2024 12:30",
    "plant_id": "Green-tek-A26206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "12:30:00 PM",
    "power": 0.923,
    "Energy": 0.231,
    "output Energy": 0.231,
    "Ac output": 1.231,
    "dc output": 1.25562,
    "AC_R_i": 0.0003013,
    "AC_S_i": 0.00033143,
    "AC_T_i": 0.00036457,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069299,
    "AC_S_pow": 0.0762289,
    "AC_T_Pow": 0.0838511,
    "AC_R_output": 0.3693,
    "AC_S_output": 0.40623,
    "AC_T_output": 0.446853,
    "dc_current": 0.00169853,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.23562,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000231,
    "carbon offset": 0.28875,
    "trees planted": 6.416666667,
    "DC_R_output": 0.121869,
    "DC_S_output": 0.1340559,
    "DC_T_output": 0.14746149
  },
  {
    "datetime": "10/9/2024 12:45",
    "plant_id": "Green-tek-A26207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "12:45:00 PM",
    "power": 0.86,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19992,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "10/9/2024 13:00",
    "plant_id": "Green-tek-A26208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "1:00:00 PM",
    "power": 0.784,
    "Energy": 0.196,
    "output Energy": 0.196,
    "Ac output": 1.196,
    "dc output": 1.21992,
    "AC_R_i": 0.00025565,
    "AC_S_i": 0.000281215,
    "AC_T_i": 0.00030934,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0587995,
    "AC_S_pow": 0.06467945,
    "AC_T_Pow": 0.0711482,
    "AC_R_output": 0.3588,
    "AC_S_output": 0.39468,
    "AC_T_output": 0.434148,
    "dc_current": 0.00144118,
    "dc_voltage": 136,
    "dc_power": 0.1785,
    "dc_output": 0.19992,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000196,
    "carbon offset": 0.245,
    "trees planted": 5.444444444,
    "DC_R_output": 0.118404,
    "DC_S_output": 0.1302444,
    "DC_T_output": 0.14326884
  },
  {
    "datetime": "10/9/2024 13:15",
    "plant_id": "Green-tek-A26209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "1:15:00 PM",
    "power": 0.701,
    "Energy": 0.175,
    "output Energy": 0.175,
    "Ac output": 1.175,
    "dc output": 1.1985,
    "AC_R_i": 0.00022826,
    "AC_S_i": 0.000251086,
    "AC_T_i": 0.00027619,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0524998,
    "AC_S_pow": 0.05774978,
    "AC_T_Pow": 0.0635237,
    "AC_R_output": 0.3525,
    "AC_S_output": 0.38775,
    "AC_T_output": 0.426525,
    "dc_current": 0.00128676,
    "dc_voltage": 136,
    "dc_power": 0.15606,
    "dc_output": 0.1785,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000175,
    "carbon offset": 0.21875,
    "trees planted": 4.861111111,
    "DC_R_output": 0.116325,
    "DC_S_output": 0.1279575,
    "DC_T_output": 0.14075325
  },
  {
    "datetime": "10/9/2024 13:30",
    "plant_id": "Green-tek-A26210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "1:30:00 PM",
    "power": 0.614,
    "Energy": 0.153,
    "output Energy": 0.153,
    "Ac output": 1.153,
    "dc output": 1.17606,
    "AC_R_i": 0.00019957,
    "AC_S_i": 0.000219527,
    "AC_T_i": 0.00024148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0459011,
    "AC_S_pow": 0.05049121,
    "AC_T_Pow": 0.0555404,
    "AC_R_output": 0.3459,
    "AC_S_output": 0.38049,
    "AC_T_output": 0.418539,
    "dc_current": 0.001125,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.15606,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000153,
    "carbon offset": 0.19125,
    "trees planted": 4.25,
    "DC_R_output": 0.114147,
    "DC_S_output": 0.1255617,
    "DC_T_output": 0.13811787
  },
  {
    "datetime": "10/9/2024 13:45",
    "plant_id": "Green-tek-A26211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "1:45:00 PM",
    "power": 0.527,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "10/9/2024 14:00",
    "plant_id": "Green-tek-A26212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "2:00:00 PM",
    "power": 0.443,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09282,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "10/9/2024 14:15",
    "plant_id": "Green-tek-A26213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "2:15:00 PM",
    "power": 0.365,
    "Energy": 0.091,
    "output Energy": 0.091,
    "Ac output": 1.091,
    "dc output": 1.11282,
    "AC_R_i": 0.0001187,
    "AC_S_i": 0.00013057,
    "AC_T_i": 0.00014363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027301,
    "AC_S_pow": 0.0300311,
    "AC_T_Pow": 0.0330349,
    "AC_R_output": 0.3273,
    "AC_S_output": 0.36003,
    "AC_T_output": 0.396033,
    "dc_current": 0.00066912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09282,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000091,
    "carbon offset": 0.11375,
    "trees planted": 2.527777778,
    "DC_R_output": 0.108009,
    "DC_S_output": 0.1188099,
    "DC_T_output": 0.13069089
  },
  {
    "datetime": "10/9/2024 14:30",
    "plant_id": "Green-tek-A26214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "2:30:00 PM",
    "power": 0.294,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "10/9/2024 14:45",
    "plant_id": "Green-tek-A26215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "2:45:00 PM",
    "power": 0.233,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0459,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "10/9/2024 15:00",
    "plant_id": "Green-tek-A26216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "3:00:00 PM",
    "power": 0.18,
    "Energy": 0.045,
    "output Energy": 0.045,
    "Ac output": 1.045,
    "dc output": 1.0659,
    "AC_R_i": 0.0000587,
    "AC_S_i": 0.00006457,
    "AC_T_i": 0.00007103,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.013501,
    "AC_S_pow": 0.0148511,
    "AC_T_Pow": 0.0163369,
    "AC_R_output": 0.3135,
    "AC_S_output": 0.34485,
    "AC_T_output": 0.379335,
    "dc_current": 0.00033088,
    "dc_voltage": 136,
    "dc_power": 0.03468,
    "dc_output": 0.0459,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000045,
    "carbon offset": 0.05625,
    "trees planted": 1.25,
    "DC_R_output": 0.103455,
    "DC_S_output": 0.1138005,
    "DC_T_output": 0.12518055
  },
  {
    "datetime": "10/9/2024 15:15",
    "plant_id": "Green-tek-A26217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "3:15:00 PM",
    "power": 0.137,
    "Energy": 0.034,
    "output Energy": 0.034,
    "Ac output": 1.034,
    "dc output": 1.05468,
    "AC_R_i": 0.00004435,
    "AC_S_i": 0.000048785,
    "AC_T_i": 0.00005366,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0102005,
    "AC_S_pow": 0.01122055,
    "AC_T_Pow": 0.0123418,
    "AC_R_output": 0.3102,
    "AC_S_output": 0.34122,
    "AC_T_output": 0.375342,
    "dc_current": 0.00025,
    "dc_voltage": 136,
    "dc_power": 0.0255,
    "dc_output": 0.03468,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000034,
    "carbon offset": 0.0425,
    "trees planted": 0.944444444,
    "DC_R_output": 0.102366,
    "DC_S_output": 0.1126026,
    "DC_T_output": 0.12386286
  },
  {
    "datetime": "10/9/2024 15:30",
    "plant_id": "Green-tek-A26218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "3:30:00 PM",
    "power": 0.102,
    "Energy": 0.025,
    "output Energy": 0.025,
    "Ac output": 1.025,
    "dc output": 1.0455,
    "AC_R_i": 0.00003261,
    "AC_S_i": 0.000035871,
    "AC_T_i": 0.00003946,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0075003,
    "AC_S_pow": 0.00825033,
    "AC_T_Pow": 0.0090758,
    "AC_R_output": 0.3075,
    "AC_S_output": 0.33825,
    "AC_T_output": 0.372075,
    "dc_current": 0.00018382,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.0255,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000025,
    "carbon offset": 0.03125,
    "trees planted": 0.694444444,
    "DC_R_output": 0.101475,
    "DC_S_output": 0.1116225,
    "DC_T_output": 0.12278475
  },
  {
    "datetime": "10/9/2024 15:45",
    "plant_id": "Green-tek-A26219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "3:45:00 PM",
    "power": 0.074,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "10/9/2024 16:00",
    "plant_id": "Green-tek-A26220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "4:00:00 PM",
    "power": 0.053,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "10/9/2024 16:15",
    "plant_id": "Green-tek-A26221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "4:15:00 PM",
    "power": 0.037,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "10/9/2024 16:30",
    "plant_id": "Green-tek-A26222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "4:30:00 PM",
    "power": 0.025,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/9/2024 16:45",
    "plant_id": "Green-tek-A26223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "4:45:00 PM",
    "power": 0.017,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/9/2024 17:00",
    "plant_id": "Green-tek-A26224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/9/2024 17:15",
    "plant_id": "Green-tek-A26225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/9/2024 17:30",
    "plant_id": "Green-tek-A26226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/9/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/10/2024 6:00",
    "plant_id": "Green-tek-A26227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/10/2024 6:15",
    "plant_id": "Green-tek-A26228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/10/2024 6:30",
    "plant_id": "Green-tek-A26229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/10/2024 6:45",
    "plant_id": "Green-tek-A26230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/10/2024 7:00",
    "plant_id": "Green-tek-A26231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/10/2024 7:15",
    "plant_id": "Green-tek-A26232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/10/2024 7:30",
    "plant_id": "Green-tek-A26233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "7:30:00 AM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/10/2024 7:45",
    "plant_id": "Green-tek-A26234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "7:45:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/10/2024 8:00",
    "plant_id": "Green-tek-A26235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "8:00:00 AM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/10/2024 8:15",
    "plant_id": "Green-tek-A26236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "8:15:00 AM",
    "power": 0.154,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "10/10/2024 8:30",
    "plant_id": "Green-tek-A26237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "8:30:00 AM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/10/2024 8:45",
    "plant_id": "Green-tek-A26238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "8:45:00 AM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "10/10/2024 9:00",
    "plant_id": "Green-tek-A26239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "9:00:00 AM",
    "power": 0.33,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "10/10/2024 9:15",
    "plant_id": "Green-tek-A26240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "9:15:00 AM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "10/10/2024 9:30",
    "plant_id": "Green-tek-A26241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "9:30:00 AM",
    "power": 0.497,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "10/10/2024 9:45",
    "plant_id": "Green-tek-A26242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "9:45:00 AM",
    "power": 0.591,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "10/10/2024 10:00",
    "plant_id": "Green-tek-A26243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "10:00:00 AM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "10/10/2024 10:15",
    "plant_id": "Green-tek-A26244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "10:15:00 AM",
    "power": 0.786,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "10/10/2024 10:30",
    "plant_id": "Green-tek-A26245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "10:30:00 AM",
    "power": 0.88,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "10/10/2024 10:45",
    "plant_id": "Green-tek-A26246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "10:45:00 AM",
    "power": 0.964,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "10/10/2024 11:00",
    "plant_id": "Green-tek-A26247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "11:00:00 AM",
    "power": 1.036,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "10/10/2024 11:15",
    "plant_id": "Green-tek-A26248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "11:15:00 AM",
    "power": 1.09,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "10/10/2024 11:30",
    "plant_id": "Green-tek-A26249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "11:30:00 AM",
    "power": 1.124,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "10/10/2024 11:45",
    "plant_id": "Green-tek-A26250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "11:45:00 AM",
    "power": 1.135,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.28662,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "10/10/2024 12:00",
    "plant_id": "Green-tek-A26251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "12:00:00 PM",
    "power": 1.124,
    "Energy": 0.281,
    "output Energy": 0.281,
    "Ac output": 1.281,
    "dc output": 1.30662,
    "AC_R_i": 0.00036652,
    "AC_S_i": 0.000403172,
    "AC_T_i": 0.00044349,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0842996,
    "AC_S_pow": 0.09272956,
    "AC_T_Pow": 0.1020027,
    "AC_R_output": 0.3843,
    "AC_S_output": 0.42273,
    "AC_T_output": 0.465003,
    "dc_current": 0.00206618,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.28662,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000281,
    "carbon offset": 0.35125,
    "trees planted": 7.805555556,
    "DC_R_output": 0.126819,
    "DC_S_output": 0.1395009,
    "DC_T_output": 0.15345099
  },
  {
    "datetime": "10/10/2024 12:15",
    "plant_id": "Green-tek-A26252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "12:15:00 PM",
    "power": 1.09,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.26418,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "10/10/2024 12:30",
    "plant_id": "Green-tek-A26253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "12:30:00 PM",
    "power": 1.036,
    "Energy": 0.259,
    "output Energy": 0.259,
    "Ac output": 1.259,
    "dc output": 1.28418,
    "AC_R_i": 0.00033783,
    "AC_S_i": 0.000371613,
    "AC_T_i": 0.00040877,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0777009,
    "AC_S_pow": 0.08547099,
    "AC_T_Pow": 0.0940171,
    "AC_R_output": 0.3777,
    "AC_S_output": 0.41547,
    "AC_T_output": 0.457017,
    "dc_current": 0.00190441,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.26418,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000259,
    "carbon offset": 0.32375,
    "trees planted": 7.194444444,
    "DC_R_output": 0.124641,
    "DC_S_output": 0.1371051,
    "DC_T_output": 0.15081561
  },
  {
    "datetime": "10/10/2024 12:45",
    "plant_id": "Green-tek-A26254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "12:45:00 PM",
    "power": 0.964,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.2244,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "10/10/2024 13:00",
    "plant_id": "Green-tek-A26255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "1:00:00 PM",
    "power": 0.88,
    "Energy": 0.22,
    "output Energy": 0.22,
    "Ac output": 1.22,
    "dc output": 1.2444,
    "AC_R_i": 0.00028696,
    "AC_S_i": 0.000315656,
    "AC_T_i": 0.00034722,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0660008,
    "AC_S_pow": 0.07260088,
    "AC_T_Pow": 0.0798606,
    "AC_R_output": 0.366,
    "AC_S_output": 0.4026,
    "AC_T_output": 0.44286,
    "dc_current": 0.00161765,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00022,
    "carbon offset": 0.275,
    "trees planted": 6.111111111,
    "DC_R_output": 0.12078,
    "DC_S_output": 0.132858,
    "DC_T_output": 0.1461438
  },
  {
    "datetime": "10/10/2024 13:15",
    "plant_id": "Green-tek-A26256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "1:15:00 PM",
    "power": 0.786,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17544,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "10/10/2024 13:30",
    "plant_id": "Green-tek-A26257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "1:30:00 PM",
    "power": 0.689,
    "Energy": 0.172,
    "output Energy": 0.172,
    "Ac output": 1.172,
    "dc output": 1.19544,
    "AC_R_i": 0.00022435,
    "AC_S_i": 0.000246785,
    "AC_T_i": 0.00027146,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0516005,
    "AC_S_pow": 0.05676055,
    "AC_T_Pow": 0.0624358,
    "AC_R_output": 0.3516,
    "AC_S_output": 0.38676,
    "AC_T_output": 0.425436,
    "dc_current": 0.00126471,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17544,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000172,
    "carbon offset": 0.215,
    "trees planted": 4.777777778,
    "DC_R_output": 0.116028,
    "DC_S_output": 0.1276308,
    "DC_T_output": 0.14039388
  },
  {
    "datetime": "10/10/2024 13:45",
    "plant_id": "Green-tek-A26258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "1:45:00 PM",
    "power": 0.591,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.12648,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "10/10/2024 14:00",
    "plant_id": "Green-tek-A26259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "2:00:00 PM",
    "power": 0.497,
    "Energy": 0.124,
    "output Energy": 0.124,
    "Ac output": 1.124,
    "dc output": 1.14648,
    "AC_R_i": 0.00016174,
    "AC_S_i": 0.000177914,
    "AC_T_i": 0.00019571,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0372002,
    "AC_S_pow": 0.04092022,
    "AC_T_Pow": 0.0450133,
    "AC_R_output": 0.3372,
    "AC_S_output": 0.37092,
    "AC_T_output": 0.408012,
    "dc_current": 0.00091176,
    "dc_voltage": 136,
    "dc_power": 0.10404,
    "dc_output": 0.12648,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000124,
    "carbon offset": 0.155,
    "trees planted": 3.444444444,
    "DC_R_output": 0.111276,
    "DC_S_output": 0.1224036,
    "DC_T_output": 0.13464396
  },
  {
    "datetime": "10/10/2024 14:15",
    "plant_id": "Green-tek-A26260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "2:15:00 PM",
    "power": 0.409,
    "Energy": 0.102,
    "output Energy": 0.102,
    "Ac output": 1.102,
    "dc output": 1.12404,
    "AC_R_i": 0.00013304,
    "AC_S_i": 0.000146344,
    "AC_T_i": 0.00016098,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0305992,
    "AC_S_pow": 0.03365912,
    "AC_T_Pow": 0.0370254,
    "AC_R_output": 0.3306,
    "AC_S_output": 0.36366,
    "AC_T_output": 0.400026,
    "dc_current": 0.00075,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10404,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000102,
    "carbon offset": 0.1275,
    "trees planted": 2.833333333,
    "DC_R_output": 0.109098,
    "DC_S_output": 0.1200078,
    "DC_T_output": 0.13200858
  },
  {
    "datetime": "10/10/2024 14:30",
    "plant_id": "Green-tek-A26261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "2:30:00 PM",
    "power": 0.33,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "10/10/2024 14:45",
    "plant_id": "Green-tek-A26262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "2:45:00 PM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "10/10/2024 15:00",
    "plant_id": "Green-tek-A26263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "3:00:00 PM",
    "power": 0.202,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/10/2024 15:15",
    "plant_id": "Green-tek-A26264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "3:15:00 PM",
    "power": 0.154,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "10/10/2024 15:30",
    "plant_id": "Green-tek-A26265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "3:30:00 PM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/10/2024 15:45",
    "plant_id": "Green-tek-A26266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "3:45:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/10/2024 16:00",
    "plant_id": "Green-tek-A26267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "4:00:00 PM",
    "power": 0.059,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/10/2024 16:15",
    "plant_id": "Green-tek-A26268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/10/2024 16:30",
    "plant_id": "Green-tek-A26269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/10/2024 16:45",
    "plant_id": "Green-tek-A26270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/10/2024 17:00",
    "plant_id": "Green-tek-A26271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/10/2024 17:15",
    "plant_id": "Green-tek-A26272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/10/2024 17:30",
    "plant_id": "Green-tek-A26273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/10/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/11/2024 6:00",
    "plant_id": "Green-tek-A26274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/11/2024 6:15",
    "plant_id": "Green-tek-A26275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/11/2024 6:30",
    "plant_id": "Green-tek-A26276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/11/2024 6:45",
    "plant_id": "Green-tek-A26277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/11/2024 7:00",
    "plant_id": "Green-tek-A26278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/11/2024 7:15",
    "plant_id": "Green-tek-A26279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/11/2024 7:30",
    "plant_id": "Green-tek-A26280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/11/2024 7:45",
    "plant_id": "Green-tek-A26281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "7:45:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/11/2024 8:00",
    "plant_id": "Green-tek-A26282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "8:00:00 AM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/11/2024 8:15",
    "plant_id": "Green-tek-A26283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "8:15:00 AM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/11/2024 8:30",
    "plant_id": "Green-tek-A26284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "8:30:00 AM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "10/11/2024 8:45",
    "plant_id": "Green-tek-A26285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "8:45:00 AM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "10/11/2024 9:00",
    "plant_id": "Green-tek-A26286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "9:00:00 AM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "10/11/2024 9:15",
    "plant_id": "Green-tek-A26287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "9:15:00 AM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "10/11/2024 9:30",
    "plant_id": "Green-tek-A26288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "9:30:00 AM",
    "power": 0.393,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "10/11/2024 9:45",
    "plant_id": "Green-tek-A26289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "9:45:00 AM",
    "power": 0.468,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "10/11/2024 10:00",
    "plant_id": "Green-tek-A26290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "10:00:00 AM",
    "power": 0.545,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "10/11/2024 10:15",
    "plant_id": "Green-tek-A26291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "10:15:00 AM",
    "power": 0.622,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "10/11/2024 10:30",
    "plant_id": "Green-tek-A26292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "10:30:00 AM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "10/11/2024 10:45",
    "plant_id": "Green-tek-A26293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "10:45:00 AM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/11/2024 11:00",
    "plant_id": "Green-tek-A26294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "11:00:00 AM",
    "power": 0.82,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "10/11/2024 11:15",
    "plant_id": "Green-tek-A26295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "11:15:00 AM",
    "power": 0.863,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "10/11/2024 11:30",
    "plant_id": "Green-tek-A26296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "11:30:00 AM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "10/11/2024 11:45",
    "plant_id": "Green-tek-A26297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "11:45:00 AM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "10/11/2024 12:00",
    "plant_id": "Green-tek-A26298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "12:00:00 PM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "10/11/2024 12:15",
    "plant_id": "Green-tek-A26299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "12:15:00 PM",
    "power": 0.863,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "10/11/2024 12:30",
    "plant_id": "Green-tek-A26300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "12:30:00 PM",
    "power": 0.82,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "10/11/2024 12:45",
    "plant_id": "Green-tek-A26301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "12:45:00 PM",
    "power": 0.763,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/11/2024 13:00",
    "plant_id": "Green-tek-A26302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "1:00:00 PM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "10/11/2024 13:15",
    "plant_id": "Green-tek-A26303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "1:15:00 PM",
    "power": 0.622,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "10/11/2024 13:30",
    "plant_id": "Green-tek-A26304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "1:30:00 PM",
    "power": 0.545,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "10/11/2024 13:45",
    "plant_id": "Green-tek-A26305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "1:45:00 PM",
    "power": 0.468,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "10/11/2024 14:00",
    "plant_id": "Green-tek-A26306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "2:00:00 PM",
    "power": 0.393,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "10/11/2024 14:15",
    "plant_id": "Green-tek-A26307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "2:15:00 PM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "10/11/2024 14:30",
    "plant_id": "Green-tek-A26308",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "2:30:00 PM",
    "power": 0.261,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "10/11/2024 14:45",
    "plant_id": "Green-tek-A26309",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "2:45:00 PM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "10/11/2024 15:00",
    "plant_id": "Green-tek-A26310",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "3:00:00 PM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "10/11/2024 15:15",
    "plant_id": "Green-tek-A26311",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "3:15:00 PM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/11/2024 15:30",
    "plant_id": "Green-tek-A26312",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "3:30:00 PM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/11/2024 15:45",
    "plant_id": "Green-tek-A26313",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "3:45:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/11/2024 16:00",
    "plant_id": "Green-tek-A26314",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/11/2024 16:15",
    "plant_id": "Green-tek-A26315",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/11/2024 16:30",
    "plant_id": "Green-tek-A26316",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/11/2024 16:45",
    "plant_id": "Green-tek-A26317",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/11/2024 17:00",
    "plant_id": "Green-tek-A26318",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/11/2024 17:15",
    "plant_id": "Green-tek-A26319",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/11/2024 17:30",
    "plant_id": "Green-tek-A26320",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/11/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/12/2024 6:00",
    "plant_id": "Green-tek-A26321",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/12/2024 6:15",
    "plant_id": "Green-tek-A26322",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/12/2024 6:30",
    "plant_id": "Green-tek-A26323",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/12/2024 6:45",
    "plant_id": "Green-tek-A26324",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/12/2024 7:00",
    "plant_id": "Green-tek-A26325",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/12/2024 7:15",
    "plant_id": "Green-tek-A26326",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "7:15:00 AM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/12/2024 7:30",
    "plant_id": "Green-tek-A26327",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/12/2024 7:45",
    "plant_id": "Green-tek-A26328",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "7:45:00 AM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "10/12/2024 8:00",
    "plant_id": "Green-tek-A26329",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "8:00:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "10/12/2024 8:15",
    "plant_id": "Green-tek-A26330",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "8:15:00 AM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "10/12/2024 8:30",
    "plant_id": "Green-tek-A26331",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "8:30:00 AM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "10/12/2024 8:45",
    "plant_id": "Green-tek-A26332",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "8:45:00 AM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "10/12/2024 9:00",
    "plant_id": "Green-tek-A26333",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "9:00:00 AM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "10/12/2024 9:15",
    "plant_id": "Green-tek-A26334",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "9:15:00 AM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "10/12/2024 9:30",
    "plant_id": "Green-tek-A26335",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "9:30:00 AM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "10/12/2024 9:45",
    "plant_id": "Green-tek-A26336",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "9:45:00 AM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "10/12/2024 10:00",
    "plant_id": "Green-tek-A26337",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "10:00:00 AM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "10/12/2024 10:15",
    "plant_id": "Green-tek-A26338",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "10:15:00 AM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "10/12/2024 10:30",
    "plant_id": "Green-tek-A26339",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "10:30:00 AM",
    "power": 0.861,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "10/12/2024 10:45",
    "plant_id": "Green-tek-A26340",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "10:45:00 AM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "10/12/2024 11:00",
    "plant_id": "Green-tek-A26341",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "11:00:00 AM",
    "power": 1.014,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "10/12/2024 11:15",
    "plant_id": "Green-tek-A26342",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "11:15:00 AM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "10/12/2024 11:30",
    "plant_id": "Green-tek-A26343",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "11:30:00 AM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "10/12/2024 11:45",
    "plant_id": "Green-tek-A26344",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "11:45:00 AM",
    "power": 1.112,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "10/12/2024 12:00",
    "plant_id": "Green-tek-A26345",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "12:00:00 PM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.27234,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "10/12/2024 12:15",
    "plant_id": "Green-tek-A26346",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "12:15:00 PM",
    "power": 1.067,
    "Energy": 0.267,
    "output Energy": 0.267,
    "Ac output": 1.267,
    "dc output": 1.29234,
    "AC_R_i": 0.00034826,
    "AC_S_i": 0.000383086,
    "AC_T_i": 0.00042139,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0800998,
    "AC_S_pow": 0.08810978,
    "AC_T_Pow": 0.0969197,
    "AC_R_output": 0.3801,
    "AC_S_output": 0.41811,
    "AC_T_output": 0.459921,
    "dc_current": 0.00196324,
    "dc_voltage": 136,
    "dc_power": 0.25908,
    "dc_output": 0.27234,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000267,
    "carbon offset": 0.33375,
    "trees planted": 7.416666667,
    "DC_R_output": 0.125433,
    "DC_S_output": 0.1379763,
    "DC_T_output": 0.15177393
  },
  {
    "datetime": "10/12/2024 12:30",
    "plant_id": "Green-tek-A26347",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "12:30:00 PM",
    "power": 1.014,
    "Energy": 0.254,
    "output Energy": 0.254,
    "Ac output": 1.254,
    "dc output": 1.27908,
    "AC_R_i": 0.0003313,
    "AC_S_i": 0.00036443,
    "AC_T_i": 0.00040087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.076199,
    "AC_S_pow": 0.0838189,
    "AC_T_Pow": 0.0922001,
    "AC_R_output": 0.3762,
    "AC_S_output": 0.41382,
    "AC_T_output": 0.455202,
    "dc_current": 0.00186765,
    "dc_voltage": 136,
    "dc_power": 0.24072,
    "dc_output": 0.25908,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000254,
    "carbon offset": 0.3175,
    "trees planted": 7.055555556,
    "DC_R_output": 0.124146,
    "DC_S_output": 0.1365606,
    "DC_T_output": 0.15021666
  },
  {
    "datetime": "10/12/2024 12:45",
    "plant_id": "Green-tek-A26348",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "12:45:00 PM",
    "power": 0.944,
    "Energy": 0.236,
    "output Energy": 0.236,
    "Ac output": 1.236,
    "dc output": 1.26072,
    "AC_R_i": 0.00030783,
    "AC_S_i": 0.000338613,
    "AC_T_i": 0.00037247,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0708009,
    "AC_S_pow": 0.07788099,
    "AC_T_Pow": 0.0856681,
    "AC_R_output": 0.3708,
    "AC_S_output": 0.40788,
    "AC_T_output": 0.448668,
    "dc_current": 0.00173529,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.24072,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000236,
    "carbon offset": 0.295,
    "trees planted": 6.555555556,
    "DC_R_output": 0.122364,
    "DC_S_output": 0.1346004,
    "DC_T_output": 0.14806044
  },
  {
    "datetime": "10/12/2024 13:00",
    "plant_id": "Green-tek-A26349",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "1:00:00 PM",
    "power": 0.861,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.19584,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "10/12/2024 13:15",
    "plant_id": "Green-tek-A26350",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "1:15:00 PM",
    "power": 0.77,
    "Energy": 0.192,
    "output Energy": 0.192,
    "Ac output": 1.192,
    "dc output": 1.21584,
    "AC_R_i": 0.00025043,
    "AC_S_i": 0.000275473,
    "AC_T_i": 0.00030302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0575989,
    "AC_S_pow": 0.06335879,
    "AC_T_Pow": 0.0696946,
    "AC_R_output": 0.3576,
    "AC_S_output": 0.39336,
    "AC_T_output": 0.432696,
    "dc_current": 0.00141176,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.19584,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000192,
    "carbon offset": 0.24,
    "trees planted": 5.333333333,
    "DC_R_output": 0.118008,
    "DC_S_output": 0.1298088,
    "DC_T_output": 0.14278968
  },
  {
    "datetime": "10/12/2024 13:30",
    "plant_id": "Green-tek-A26351",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "1:30:00 PM",
    "power": 0.674,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.1479,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "10/12/2024 13:45",
    "plant_id": "Green-tek-A26352",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "1:45:00 PM",
    "power": 0.579,
    "Energy": 0.145,
    "output Energy": 0.145,
    "Ac output": 1.145,
    "dc output": 1.1679,
    "AC_R_i": 0.00018913,
    "AC_S_i": 0.000208043,
    "AC_T_i": 0.00022885,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0434999,
    "AC_S_pow": 0.04784989,
    "AC_T_Pow": 0.0526355,
    "AC_R_output": 0.3435,
    "AC_S_output": 0.37785,
    "AC_T_output": 0.415635,
    "dc_current": 0.00106618,
    "dc_voltage": 136,
    "dc_power": 0.12444,
    "dc_output": 0.1479,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000145,
    "carbon offset": 0.18125,
    "trees planted": 4.027777778,
    "DC_R_output": 0.113355,
    "DC_S_output": 0.1246905,
    "DC_T_output": 0.13715955
  },
  {
    "datetime": "10/12/2024 14:00",
    "plant_id": "Green-tek-A26353",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "2:00:00 PM",
    "power": 0.486,
    "Energy": 0.122,
    "output Energy": 0.122,
    "Ac output": 1.122,
    "dc output": 1.14444,
    "AC_R_i": 0.00015913,
    "AC_S_i": 0.000175043,
    "AC_T_i": 0.00019255,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0365999,
    "AC_S_pow": 0.04025989,
    "AC_T_Pow": 0.0442865,
    "AC_R_output": 0.3366,
    "AC_S_output": 0.37026,
    "AC_T_output": 0.407286,
    "dc_current": 0.00089706,
    "dc_voltage": 136,
    "dc_power": 0.102,
    "dc_output": 0.12444,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000122,
    "carbon offset": 0.1525,
    "trees planted": 3.388888889,
    "DC_R_output": 0.111078,
    "DC_S_output": 0.1221858,
    "DC_T_output": 0.13440438
  },
  {
    "datetime": "10/12/2024 14:15",
    "plant_id": "Green-tek-A26354",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "2:15:00 PM",
    "power": 0.401,
    "Energy": 0.1,
    "output Energy": 0.1,
    "Ac output": 1.1,
    "dc output": 1.122,
    "AC_R_i": 0.00013043,
    "AC_S_i": 0.000143473,
    "AC_T_i": 0.00015782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0299989,
    "AC_S_pow": 0.03299879,
    "AC_T_Pow": 0.0362986,
    "AC_R_output": 0.33,
    "AC_S_output": 0.363,
    "AC_T_output": 0.3993,
    "dc_current": 0.00073529,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.0001,
    "carbon offset": 0.125,
    "trees planted": 2.777777778,
    "DC_R_output": 0.1089,
    "DC_S_output": 0.11979,
    "DC_T_output": 0.131769
  },
  {
    "datetime": "10/12/2024 14:30",
    "plant_id": "Green-tek-A26355",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "2:30:00 PM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.06528,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "10/12/2024 14:45",
    "plant_id": "Green-tek-A26356",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "2:45:00 PM",
    "power": 0.256,
    "Energy": 0.064,
    "output Energy": 0.064,
    "Ac output": 1.064,
    "dc output": 1.08528,
    "AC_R_i": 0.00008348,
    "AC_S_i": 0.000091828,
    "AC_T_i": 0.00010101,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0192004,
    "AC_S_pow": 0.02112044,
    "AC_T_Pow": 0.0232323,
    "AC_R_output": 0.3192,
    "AC_S_output": 0.35112,
    "AC_T_output": 0.386232,
    "dc_current": 0.00047059,
    "dc_voltage": 136,
    "dc_power": 0.051,
    "dc_output": 0.06528,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000064,
    "carbon offset": 0.08,
    "trees planted": 1.777777778,
    "DC_R_output": 0.105336,
    "DC_S_output": 0.1158696,
    "DC_T_output": 0.12745656
  },
  {
    "datetime": "10/12/2024 15:00",
    "plant_id": "Green-tek-A26357",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "3:00:00 PM",
    "power": 0.198,
    "Energy": 0.05,
    "output Energy": 0.05,
    "Ac output": 1.05,
    "dc output": 1.071,
    "AC_R_i": 0.00006522,
    "AC_S_i": 0.000071742,
    "AC_T_i": 0.00007892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0150006,
    "AC_S_pow": 0.01650066,
    "AC_T_Pow": 0.0181516,
    "AC_R_output": 0.315,
    "AC_S_output": 0.3465,
    "AC_T_output": 0.38115,
    "dc_current": 0.00036765,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00005,
    "carbon offset": 0.0625,
    "trees planted": 1.388888889,
    "DC_R_output": 0.10395,
    "DC_S_output": 0.114345,
    "DC_T_output": 0.1257795
  },
  {
    "datetime": "10/12/2024 15:15",
    "plant_id": "Green-tek-A26358",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "3:15:00 PM",
    "power": 0.15,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "10/12/2024 15:30",
    "plant_id": "Green-tek-A26359",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "3:30:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "10/12/2024 15:45",
    "plant_id": "Green-tek-A26360",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "3:45:00 PM",
    "power": 0.082,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "10/12/2024 16:00",
    "plant_id": "Green-tek-A26361",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/12/2024 16:15",
    "plant_id": "Green-tek-A26362",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "4:15:00 PM",
    "power": 0.041,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/12/2024 16:30",
    "plant_id": "Green-tek-A26363",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/12/2024 16:45",
    "plant_id": "Green-tek-A26364",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/12/2024 17:00",
    "plant_id": "Green-tek-A26365",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/12/2024 17:15",
    "plant_id": "Green-tek-A26366",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/12/2024 17:30",
    "plant_id": "Green-tek-A26367",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/12/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/13/2024 6:00",
    "plant_id": "Green-tek-A26368",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/13/2024 6:15",
    "plant_id": "Green-tek-A26369",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/13/2024 6:30",
    "plant_id": "Green-tek-A26370",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/13/2024 6:45",
    "plant_id": "Green-tek-A26371",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/13/2024 7:00",
    "plant_id": "Green-tek-A26372",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/13/2024 7:15",
    "plant_id": "Green-tek-A26373",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/13/2024 7:30",
    "plant_id": "Green-tek-A26374",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/13/2024 7:45",
    "plant_id": "Green-tek-A26375",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "7:45:00 AM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/13/2024 8:00",
    "plant_id": "Green-tek-A26376",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "8:00:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "10/13/2024 8:15",
    "plant_id": "Green-tek-A26377",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "8:15:00 AM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "10/13/2024 8:30",
    "plant_id": "Green-tek-A26378",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "8:30:00 AM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "10/13/2024 8:45",
    "plant_id": "Green-tek-A26379",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "8:45:00 AM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "10/13/2024 9:00",
    "plant_id": "Green-tek-A26380",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "9:00:00 AM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "10/13/2024 9:15",
    "plant_id": "Green-tek-A26381",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "9:15:00 AM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "10/13/2024 9:30",
    "plant_id": "Green-tek-A26382",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "9:30:00 AM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "10/13/2024 9:45",
    "plant_id": "Green-tek-A26383",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "9:45:00 AM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "10/13/2024 10:00",
    "plant_id": "Green-tek-A26384",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "10:00:00 AM",
    "power": 0.78,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "10/13/2024 10:15",
    "plant_id": "Green-tek-A26385",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "10:15:00 AM",
    "power": 0.89,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "10/13/2024 10:30",
    "plant_id": "Green-tek-A26386",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "10:30:00 AM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "10/13/2024 10:45",
    "plant_id": "Green-tek-A26387",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "10:45:00 AM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "10/13/2024 11:00",
    "plant_id": "Green-tek-A26388",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "11:00:00 AM",
    "power": 1.173,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "10/13/2024 11:15",
    "plant_id": "Green-tek-A26389",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "11:15:00 AM",
    "power": 1.234,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "10/13/2024 11:30",
    "plant_id": "Green-tek-A26390",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "11:30:00 AM",
    "power": 1.272,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.32742,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "10/13/2024 11:45",
    "plant_id": "Green-tek-A26391",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "11:45:00 AM",
    "power": 1.285,
    "Energy": 0.321,
    "output Energy": 0.321,
    "Ac output": 1.321,
    "dc output": 1.34742,
    "AC_R_i": 0.0004187,
    "AC_S_i": 0.00046057,
    "AC_T_i": 0.00050663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.096301,
    "AC_S_pow": 0.1059311,
    "AC_T_Pow": 0.1165249,
    "AC_R_output": 0.3963,
    "AC_S_output": 0.43593,
    "AC_T_output": 0.479523,
    "dc_current": 0.00236029,
    "dc_voltage": 136,
    "dc_power": 0.32436,
    "dc_output": 0.32742,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000321,
    "carbon offset": 0.40125,
    "trees planted": 8.916666667,
    "DC_R_output": 0.130779,
    "DC_S_output": 0.1438569,
    "DC_T_output": 0.15824259
  },
  {
    "datetime": "10/13/2024 12:00",
    "plant_id": "Green-tek-A26392",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "12:00:00 PM",
    "power": 1.272,
    "Energy": 0.318,
    "output Energy": 0.318,
    "Ac output": 1.318,
    "dc output": 1.34436,
    "AC_R_i": 0.00041478,
    "AC_S_i": 0.000456258,
    "AC_T_i": 0.00050188,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0953994,
    "AC_S_pow": 0.10493934,
    "AC_T_Pow": 0.1154324,
    "AC_R_output": 0.3954,
    "AC_S_output": 0.43494,
    "AC_T_output": 0.478434,
    "dc_current": 0.00233824,
    "dc_voltage": 136,
    "dc_power": 0.31518,
    "dc_output": 0.32436,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000318,
    "carbon offset": 0.3975,
    "trees planted": 8.833333333,
    "DC_R_output": 0.130482,
    "DC_S_output": 0.1435302,
    "DC_T_output": 0.15788322
  },
  {
    "datetime": "10/13/2024 12:15",
    "plant_id": "Green-tek-A26393",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "12:15:00 PM",
    "power": 1.234,
    "Energy": 0.309,
    "output Energy": 0.309,
    "Ac output": 1.309,
    "dc output": 1.33518,
    "AC_R_i": 0.00040304,
    "AC_S_i": 0.000443344,
    "AC_T_i": 0.00048768,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0926992,
    "AC_S_pow": 0.10196912,
    "AC_T_Pow": 0.1121664,
    "AC_R_output": 0.3927,
    "AC_S_output": 0.43197,
    "AC_T_output": 0.475167,
    "dc_current": 0.00227206,
    "dc_voltage": 136,
    "dc_power": 0.29886,
    "dc_output": 0.31518,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000309,
    "carbon offset": 0.38625,
    "trees planted": 8.583333333,
    "DC_R_output": 0.129591,
    "DC_S_output": 0.1425501,
    "DC_T_output": 0.15680511
  },
  {
    "datetime": "10/13/2024 12:30",
    "plant_id": "Green-tek-A26394",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "12:30:00 PM",
    "power": 1.173,
    "Energy": 0.293,
    "output Energy": 0.293,
    "Ac output": 1.293,
    "dc output": 1.31886,
    "AC_R_i": 0.00038217,
    "AC_S_i": 0.000420387,
    "AC_T_i": 0.00046243,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0878991,
    "AC_S_pow": 0.09668901,
    "AC_T_Pow": 0.1063589,
    "AC_R_output": 0.3879,
    "AC_S_output": 0.42669,
    "AC_T_output": 0.469359,
    "dc_current": 0.00215441,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.29886,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000293,
    "carbon offset": 0.36625,
    "trees planted": 8.138888889,
    "DC_R_output": 0.128007,
    "DC_S_output": 0.1408077,
    "DC_T_output": 0.15488847
  },
  {
    "datetime": "10/13/2024 12:45",
    "plant_id": "Green-tek-A26395",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "12:45:00 PM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.25398,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "10/13/2024 13:00",
    "plant_id": "Green-tek-A26396",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "1:00:00 PM",
    "power": 0.996,
    "Energy": 0.249,
    "output Energy": 0.249,
    "Ac output": 1.249,
    "dc output": 1.27398,
    "AC_R_i": 0.00032478,
    "AC_S_i": 0.000357258,
    "AC_T_i": 0.00039298,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0746994,
    "AC_S_pow": 0.08216934,
    "AC_T_Pow": 0.0903854,
    "AC_R_output": 0.3747,
    "AC_S_output": 0.41217,
    "AC_T_output": 0.453387,
    "dc_current": 0.00183088,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.25398,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000249,
    "carbon offset": 0.31125,
    "trees planted": 6.916666667,
    "DC_R_output": 0.123651,
    "DC_S_output": 0.1360161,
    "DC_T_output": 0.14961771
  },
  {
    "datetime": "10/13/2024 13:15",
    "plant_id": "Green-tek-A26397",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "1:15:00 PM",
    "power": 0.89,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.1989,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "10/13/2024 13:30",
    "plant_id": "Green-tek-A26398",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "1:30:00 PM",
    "power": 0.78,
    "Energy": 0.195,
    "output Energy": 0.195,
    "Ac output": 1.195,
    "dc output": 1.2189,
    "AC_R_i": 0.00025435,
    "AC_S_i": 0.000279785,
    "AC_T_i": 0.00030776,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0585005,
    "AC_S_pow": 0.06435055,
    "AC_T_Pow": 0.0707848,
    "AC_R_output": 0.3585,
    "AC_S_output": 0.39435,
    "AC_T_output": 0.433785,
    "dc_current": 0.00143382,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1989,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000195,
    "carbon offset": 0.24375,
    "trees planted": 5.416666667,
    "DC_R_output": 0.118305,
    "DC_S_output": 0.1301355,
    "DC_T_output": 0.14314905
  },
  {
    "datetime": "10/13/2024 13:45",
    "plant_id": "Green-tek-A26399",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "1:45:00 PM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "10/13/2024 14:00",
    "plant_id": "Green-tek-A26400",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "2:00:00 PM",
    "power": 0.562,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "10/13/2024 14:15",
    "plant_id": "Green-tek-A26401",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "2:15:00 PM",
    "power": 0.463,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "10/13/2024 14:30",
    "plant_id": "Green-tek-A26402",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "2:30:00 PM",
    "power": 0.374,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "10/13/2024 14:45",
    "plant_id": "Green-tek-A26403",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "2:45:00 PM",
    "power": 0.296,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "10/13/2024 15:00",
    "plant_id": "Green-tek-A26404",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "3:00:00 PM",
    "power": 0.229,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "10/13/2024 15:15",
    "plant_id": "Green-tek-A26405",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "3:15:00 PM",
    "power": 0.174,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "10/13/2024 15:30",
    "plant_id": "Green-tek-A26406",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "3:30:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "10/13/2024 15:45",
    "plant_id": "Green-tek-A26407",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "3:45:00 PM",
    "power": 0.094,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/13/2024 16:00",
    "plant_id": "Green-tek-A26408",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/13/2024 16:15",
    "plant_id": "Green-tek-A26409",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/13/2024 16:30",
    "plant_id": "Green-tek-A26410",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/13/2024 16:45",
    "plant_id": "Green-tek-A26411",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/13/2024 17:00",
    "plant_id": "Green-tek-A26412",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/13/2024 17:15",
    "plant_id": "Green-tek-A26413",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/13/2024 17:30",
    "plant_id": "Green-tek-A26414",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/13/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/14/2024 6:00",
    "plant_id": "Green-tek-A26415",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/14/2024 6:15",
    "plant_id": "Green-tek-A26416",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/14/2024 6:30",
    "plant_id": "Green-tek-A26417",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/14/2024 6:45",
    "plant_id": "Green-tek-A26418",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "6:45:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/14/2024 7:00",
    "plant_id": "Green-tek-A26419",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "7:00:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/14/2024 7:15",
    "plant_id": "Green-tek-A26420",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "7:15:00 AM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/14/2024 7:30",
    "plant_id": "Green-tek-A26421",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "7:30:00 AM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/14/2024 7:45",
    "plant_id": "Green-tek-A26422",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "7:45:00 AM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/14/2024 8:00",
    "plant_id": "Green-tek-A26423",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "8:00:00 AM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "10/14/2024 8:15",
    "plant_id": "Green-tek-A26424",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "8:15:00 AM",
    "power": 0.166,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "10/14/2024 8:30",
    "plant_id": "Green-tek-A26425",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "8:30:00 AM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "10/14/2024 8:45",
    "plant_id": "Green-tek-A26426",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "8:45:00 AM",
    "power": 0.282,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "10/14/2024 9:00",
    "plant_id": "Green-tek-A26427",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "9:00:00 AM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "10/14/2024 9:15",
    "plant_id": "Green-tek-A26428",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "9:15:00 AM",
    "power": 0.442,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "10/14/2024 9:30",
    "plant_id": "Green-tek-A26429",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "9:30:00 AM",
    "power": 0.537,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "10/14/2024 9:45",
    "plant_id": "Green-tek-A26430",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "9:45:00 AM",
    "power": 0.639,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "10/14/2024 10:00",
    "plant_id": "Green-tek-A26431",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "10:00:00 AM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "10/14/2024 10:15",
    "plant_id": "Green-tek-A26432",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "10:15:00 AM",
    "power": 0.85,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "10/14/2024 10:30",
    "plant_id": "Green-tek-A26433",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "10:30:00 AM",
    "power": 0.951,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "10/14/2024 10:45",
    "plant_id": "Green-tek-A26434",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "10:45:00 AM",
    "power": 1.042,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "10/14/2024 11:00",
    "plant_id": "Green-tek-A26435",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "11:00:00 AM",
    "power": 1.119,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "10/14/2024 11:15",
    "plant_id": "Green-tek-A26436",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "11:15:00 AM",
    "power": 1.178,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "10/14/2024 11:30",
    "plant_id": "Green-tek-A26437",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "11:30:00 AM",
    "power": 1.214,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "10/14/2024 11:45",
    "plant_id": "Green-tek-A26438",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "11:45:00 AM",
    "power": 1.227,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.31008,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "10/14/2024 12:00",
    "plant_id": "Green-tek-A26439",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "12:00:00 PM",
    "power": 1.214,
    "Energy": 0.304,
    "output Energy": 0.304,
    "Ac output": 1.304,
    "dc output": 1.33008,
    "AC_R_i": 0.00039652,
    "AC_S_i": 0.000436172,
    "AC_T_i": 0.00047979,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0911996,
    "AC_S_pow": 0.10031956,
    "AC_T_Pow": 0.1103517,
    "AC_R_output": 0.3912,
    "AC_S_output": 0.43032,
    "AC_T_output": 0.473352,
    "dc_current": 0.00223529,
    "dc_voltage": 136,
    "dc_power": 0.29988,
    "dc_output": 0.31008,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000304,
    "carbon offset": 0.38,
    "trees planted": 8.444444444,
    "DC_R_output": 0.129096,
    "DC_S_output": 0.1420056,
    "DC_T_output": 0.15620616
  },
  {
    "datetime": "10/14/2024 12:15",
    "plant_id": "Green-tek-A26440",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "12:15:00 PM",
    "power": 1.178,
    "Energy": 0.294,
    "output Energy": 0.294,
    "Ac output": 1.294,
    "dc output": 1.31988,
    "AC_R_i": 0.00038348,
    "AC_S_i": 0.000421828,
    "AC_T_i": 0.00046401,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0882004,
    "AC_S_pow": 0.09702044,
    "AC_T_Pow": 0.1067223,
    "AC_R_output": 0.3882,
    "AC_S_output": 0.42702,
    "AC_T_output": 0.469722,
    "dc_current": 0.00216176,
    "dc_voltage": 136,
    "dc_power": 0.2856,
    "dc_output": 0.29988,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000294,
    "carbon offset": 0.3675,
    "trees planted": 8.166666667,
    "DC_R_output": 0.128106,
    "DC_S_output": 0.1409166,
    "DC_T_output": 0.15500826
  },
  {
    "datetime": "10/14/2024 12:30",
    "plant_id": "Green-tek-A26441",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "12:30:00 PM",
    "power": 1.119,
    "Energy": 0.28,
    "output Energy": 0.28,
    "Ac output": 1.28,
    "dc output": 1.3056,
    "AC_R_i": 0.00036522,
    "AC_S_i": 0.000401742,
    "AC_T_i": 0.00044192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0840006,
    "AC_S_pow": 0.09240066,
    "AC_T_Pow": 0.1016416,
    "AC_R_output": 0.384,
    "AC_S_output": 0.4224,
    "AC_T_output": 0.46464,
    "dc_current": 0.00205882,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.2856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00028,
    "carbon offset": 0.35,
    "trees planted": 7.777777778,
    "DC_R_output": 0.12672,
    "DC_S_output": 0.139392,
    "DC_T_output": 0.1533312
  },
  {
    "datetime": "10/14/2024 12:45",
    "plant_id": "Green-tek-A26442",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "12:45:00 PM",
    "power": 1.042,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.24276,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "10/14/2024 13:00",
    "plant_id": "Green-tek-A26443",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "1:00:00 PM",
    "power": 0.951,
    "Energy": 0.238,
    "output Energy": 0.238,
    "Ac output": 1.238,
    "dc output": 1.26276,
    "AC_R_i": 0.00031043,
    "AC_S_i": 0.000341473,
    "AC_T_i": 0.00037562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0713989,
    "AC_S_pow": 0.07853879,
    "AC_T_Pow": 0.0863926,
    "AC_R_output": 0.3714,
    "AC_S_output": 0.40854,
    "AC_T_output": 0.449394,
    "dc_current": 0.00175,
    "dc_voltage": 136,
    "dc_power": 0.21624,
    "dc_output": 0.24276,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000238,
    "carbon offset": 0.2975,
    "trees planted": 6.611111111,
    "DC_R_output": 0.122562,
    "DC_S_output": 0.1348182,
    "DC_T_output": 0.14830002
  },
  {
    "datetime": "10/14/2024 13:15",
    "plant_id": "Green-tek-A26444",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "1:15:00 PM",
    "power": 0.85,
    "Energy": 0.212,
    "output Energy": 0.212,
    "Ac output": 1.212,
    "dc output": 1.23624,
    "AC_R_i": 0.00027652,
    "AC_S_i": 0.000304172,
    "AC_T_i": 0.00033459,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0635996,
    "AC_S_pow": 0.06995956,
    "AC_T_Pow": 0.0769557,
    "AC_R_output": 0.3636,
    "AC_S_output": 0.39996,
    "AC_T_output": 0.439956,
    "dc_current": 0.00155882,
    "dc_voltage": 136,
    "dc_power": 0.18972,
    "dc_output": 0.21624,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000212,
    "carbon offset": 0.265,
    "trees planted": 5.888888889,
    "DC_R_output": 0.119988,
    "DC_S_output": 0.1319868,
    "DC_T_output": 0.14518548
  },
  {
    "datetime": "10/14/2024 13:30",
    "plant_id": "Green-tek-A26445",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "1:30:00 PM",
    "power": 0.744,
    "Energy": 0.186,
    "output Energy": 0.186,
    "Ac output": 1.186,
    "dc output": 1.20972,
    "AC_R_i": 0.00024261,
    "AC_S_i": 0.000266871,
    "AC_T_i": 0.00029356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0558003,
    "AC_S_pow": 0.06138033,
    "AC_T_Pow": 0.0675188,
    "AC_R_output": 0.3558,
    "AC_S_output": 0.39138,
    "AC_T_output": 0.430518,
    "dc_current": 0.00136765,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.18972,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000186,
    "carbon offset": 0.2325,
    "trees planted": 5.166666667,
    "DC_R_output": 0.117414,
    "DC_S_output": 0.1291554,
    "DC_T_output": 0.14207094
  },
  {
    "datetime": "10/14/2024 13:45",
    "plant_id": "Green-tek-A26446",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "1:45:00 PM",
    "power": 0.639,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.13668,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "10/14/2024 14:00",
    "plant_id": "Green-tek-A26447",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "2:00:00 PM",
    "power": 0.537,
    "Energy": 0.134,
    "output Energy": 0.134,
    "Ac output": 1.134,
    "dc output": 1.15668,
    "AC_R_i": 0.00017478,
    "AC_S_i": 0.000192258,
    "AC_T_i": 0.00021148,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0401994,
    "AC_S_pow": 0.04421934,
    "AC_T_Pow": 0.0486404,
    "AC_R_output": 0.3402,
    "AC_S_output": 0.37422,
    "AC_T_output": 0.411642,
    "dc_current": 0.00098529,
    "dc_voltage": 136,
    "dc_power": 0.11322,
    "dc_output": 0.13668,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000134,
    "carbon offset": 0.1675,
    "trees planted": 3.722222222,
    "DC_R_output": 0.112266,
    "DC_S_output": 0.1234926,
    "DC_T_output": 0.13584186
  },
  {
    "datetime": "10/14/2024 14:15",
    "plant_id": "Green-tek-A26448",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "2:15:00 PM",
    "power": 0.442,
    "Energy": 0.111,
    "output Energy": 0.111,
    "Ac output": 1.111,
    "dc output": 1.13322,
    "AC_R_i": 0.00014478,
    "AC_S_i": 0.000159258,
    "AC_T_i": 0.00017518,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0332994,
    "AC_S_pow": 0.03662934,
    "AC_T_Pow": 0.0402914,
    "AC_R_output": 0.3333,
    "AC_S_output": 0.36663,
    "AC_T_output": 0.403293,
    "dc_current": 0.00081618,
    "dc_voltage": 136,
    "dc_power": 0.09078,
    "dc_output": 0.11322,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000111,
    "carbon offset": 0.13875,
    "trees planted": 3.083333333,
    "DC_R_output": 0.109989,
    "DC_S_output": 0.1209879,
    "DC_T_output": 0.13308669
  },
  {
    "datetime": "10/14/2024 14:30",
    "plant_id": "Green-tek-A26449",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "2:30:00 PM",
    "power": 0.357,
    "Energy": 0.089,
    "output Energy": 0.089,
    "Ac output": 1.089,
    "dc output": 1.11078,
    "AC_R_i": 0.00011609,
    "AC_S_i": 0.000127699,
    "AC_T_i": 0.00014047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0267007,
    "AC_S_pow": 0.02937077,
    "AC_T_Pow": 0.0323081,
    "AC_R_output": 0.3267,
    "AC_S_output": 0.35937,
    "AC_T_output": 0.395307,
    "dc_current": 0.00065441,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.09078,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000089,
    "carbon offset": 0.11125,
    "trees planted": 2.472222222,
    "DC_R_output": 0.107811,
    "DC_S_output": 0.1185921,
    "DC_T_output": 0.13045131
  },
  {
    "datetime": "10/14/2024 14:45",
    "plant_id": "Green-tek-A26450",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "2:45:00 PM",
    "power": 0.282,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.0561,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "10/14/2024 15:00",
    "plant_id": "Green-tek-A26451",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "3:00:00 PM",
    "power": 0.219,
    "Energy": 0.055,
    "output Energy": 0.055,
    "Ac output": 1.055,
    "dc output": 1.0761,
    "AC_R_i": 0.00007174,
    "AC_S_i": 0.000078914,
    "AC_T_i": 0.00008681,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0165002,
    "AC_S_pow": 0.01815022,
    "AC_T_Pow": 0.0199663,
    "AC_R_output": 0.3165,
    "AC_S_output": 0.34815,
    "AC_T_output": 0.382965,
    "dc_current": 0.00040441,
    "dc_voltage": 136,
    "dc_power": 0.04284,
    "dc_output": 0.0561,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000055,
    "carbon offset": 0.06875,
    "trees planted": 1.527777778,
    "DC_R_output": 0.104445,
    "DC_S_output": 0.1148895,
    "DC_T_output": 0.12637845
  },
  {
    "datetime": "10/14/2024 15:15",
    "plant_id": "Green-tek-A26452",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "3:15:00 PM",
    "power": 0.166,
    "Energy": 0.042,
    "output Energy": 0.042,
    "Ac output": 1.042,
    "dc output": 1.06284,
    "AC_R_i": 0.00005478,
    "AC_S_i": 0.000060258,
    "AC_T_i": 0.00006628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0125994,
    "AC_S_pow": 0.01385934,
    "AC_T_Pow": 0.0152444,
    "AC_R_output": 0.3126,
    "AC_S_output": 0.34386,
    "AC_T_output": 0.378246,
    "dc_current": 0.00030882,
    "dc_voltage": 136,
    "dc_power": 0.03162,
    "dc_output": 0.04284,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000042,
    "carbon offset": 0.0525,
    "trees planted": 1.166666667,
    "DC_R_output": 0.103158,
    "DC_S_output": 0.1134738,
    "DC_T_output": 0.12482118
  },
  {
    "datetime": "10/14/2024 15:30",
    "plant_id": "Green-tek-A26453",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "3:30:00 PM",
    "power": 0.124,
    "Energy": 0.031,
    "output Energy": 0.031,
    "Ac output": 1.031,
    "dc output": 1.05162,
    "AC_R_i": 0.00004043,
    "AC_S_i": 0.000044473,
    "AC_T_i": 0.00004892,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0092989,
    "AC_S_pow": 0.01022879,
    "AC_T_Pow": 0.0112516,
    "AC_R_output": 0.3093,
    "AC_S_output": 0.34023,
    "AC_T_output": 0.374253,
    "dc_current": 0.00022794,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03162,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000031,
    "carbon offset": 0.03875,
    "trees planted": 0.861111111,
    "DC_R_output": 0.102069,
    "DC_S_output": 0.1122759,
    "DC_T_output": 0.12350349
  },
  {
    "datetime": "10/14/2024 15:45",
    "plant_id": "Green-tek-A26454",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "3:45:00 PM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/14/2024 16:00",
    "plant_id": "Green-tek-A26455",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "4:00:00 PM",
    "power": 0.064,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/14/2024 16:15",
    "plant_id": "Green-tek-A26456",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "4:15:00 PM",
    "power": 0.045,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/14/2024 16:30",
    "plant_id": "Green-tek-A26457",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "4:30:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/14/2024 16:45",
    "plant_id": "Green-tek-A26458",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "4:45:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/14/2024 17:00",
    "plant_id": "Green-tek-A26459",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/14/2024 17:15",
    "plant_id": "Green-tek-A26460",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/14/2024 17:30",
    "plant_id": "Green-tek-A26461",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/14/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/15/2024 6:00",
    "plant_id": "Green-tek-A26462",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/15/2024 6:15",
    "plant_id": "Green-tek-A26463",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/15/2024 6:30",
    "plant_id": "Green-tek-A26464",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/15/2024 6:45",
    "plant_id": "Green-tek-A26465",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/15/2024 7:00",
    "plant_id": "Green-tek-A26466",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "7:00:00 AM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/15/2024 7:15",
    "plant_id": "Green-tek-A26467",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/15/2024 7:30",
    "plant_id": "Green-tek-A26468",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "7:30:00 AM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/15/2024 7:45",
    "plant_id": "Green-tek-A26469",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "7:45:00 AM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "10/15/2024 8:00",
    "plant_id": "Green-tek-A26470",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "8:00:00 AM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "10/15/2024 8:15",
    "plant_id": "Green-tek-A26471",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "8:15:00 AM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "10/15/2024 8:30",
    "plant_id": "Green-tek-A26472",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "8:30:00 AM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "10/15/2024 8:45",
    "plant_id": "Green-tek-A26473",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "8:45:00 AM",
    "power": 0.254,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "10/15/2024 9:00",
    "plant_id": "Green-tek-A26474",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "9:00:00 AM",
    "power": 0.321,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "10/15/2024 9:15",
    "plant_id": "Green-tek-A26475",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "9:15:00 AM",
    "power": 0.398,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "10/15/2024 9:30",
    "plant_id": "Green-tek-A26476",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "9:30:00 AM",
    "power": 0.483,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "10/15/2024 9:45",
    "plant_id": "Green-tek-A26477",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "9:45:00 AM",
    "power": 0.574,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "10/15/2024 10:00",
    "plant_id": "Green-tek-A26478",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "10:00:00 AM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "10/15/2024 10:15",
    "plant_id": "Green-tek-A26479",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "10:15:00 AM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/15/2024 10:30",
    "plant_id": "Green-tek-A26480",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "10:30:00 AM",
    "power": 0.855,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "10/15/2024 10:45",
    "plant_id": "Green-tek-A26481",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "10:45:00 AM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "10/15/2024 11:00",
    "plant_id": "Green-tek-A26482",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "11:00:00 AM",
    "power": 1.007,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "10/15/2024 11:15",
    "plant_id": "Green-tek-A26483",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "11:15:00 AM",
    "power": 1.059,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "10/15/2024 11:30",
    "plant_id": "Green-tek-A26484",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "11:30:00 AM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "10/15/2024 11:45",
    "plant_id": "Green-tek-A26485",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "11:45:00 AM",
    "power": 1.104,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.27846,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "10/15/2024 12:00",
    "plant_id": "Green-tek-A26486",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "12:00:00 PM",
    "power": 1.092,
    "Energy": 0.273,
    "output Energy": 0.273,
    "Ac output": 1.273,
    "dc output": 1.29846,
    "AC_R_i": 0.00035609,
    "AC_S_i": 0.000391699,
    "AC_T_i": 0.00043087,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0819007,
    "AC_S_pow": 0.09009077,
    "AC_T_Pow": 0.0991001,
    "AC_R_output": 0.3819,
    "AC_S_output": 0.42009,
    "AC_T_output": 0.462099,
    "dc_current": 0.00200735,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.27846,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000273,
    "carbon offset": 0.34125,
    "trees planted": 7.583333333,
    "DC_R_output": 0.126027,
    "DC_S_output": 0.1386297,
    "DC_T_output": 0.15249267
  },
  {
    "datetime": "10/15/2024 12:15",
    "plant_id": "Green-tek-A26487",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "12:15:00 PM",
    "power": 1.059,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "10/15/2024 12:30",
    "plant_id": "Green-tek-A26488",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "12:30:00 PM",
    "power": 1.007,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "10/15/2024 12:45",
    "plant_id": "Green-tek-A26489",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "12:45:00 PM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21828,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "10/15/2024 13:00",
    "plant_id": "Green-tek-A26490",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "1:00:00 PM",
    "power": 0.855,
    "Energy": 0.214,
    "output Energy": 0.214,
    "Ac output": 1.214,
    "dc output": 1.23828,
    "AC_R_i": 0.00027913,
    "AC_S_i": 0.000307043,
    "AC_T_i": 0.00033775,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0641999,
    "AC_S_pow": 0.07061989,
    "AC_T_Pow": 0.0776825,
    "AC_R_output": 0.3642,
    "AC_S_output": 0.40062,
    "AC_T_output": 0.440682,
    "dc_current": 0.00157353,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.21828,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000214,
    "carbon offset": 0.2675,
    "trees planted": 5.944444444,
    "DC_R_output": 0.120186,
    "DC_S_output": 0.1322046,
    "DC_T_output": 0.14542506
  },
  {
    "datetime": "10/15/2024 13:15",
    "plant_id": "Green-tek-A26491",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "1:15:00 PM",
    "power": 0.764,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/15/2024 13:30",
    "plant_id": "Green-tek-A26492",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "1:30:00 PM",
    "power": 0.669,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.14688,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "10/15/2024 13:45",
    "plant_id": "Green-tek-A26493",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "1:45:00 PM",
    "power": 0.574,
    "Energy": 0.144,
    "output Energy": 0.144,
    "Ac output": 1.144,
    "dc output": 1.16688,
    "AC_R_i": 0.00018783,
    "AC_S_i": 0.000206613,
    "AC_T_i": 0.00022727,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0432009,
    "AC_S_pow": 0.04752099,
    "AC_T_Pow": 0.0522721,
    "AC_R_output": 0.3432,
    "AC_S_output": 0.37752,
    "AC_T_output": 0.415272,
    "dc_current": 0.00105882,
    "dc_voltage": 136,
    "dc_power": 0.12342,
    "dc_output": 0.14688,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000144,
    "carbon offset": 0.18,
    "trees planted": 4,
    "DC_R_output": 0.113256,
    "DC_S_output": 0.1245816,
    "DC_T_output": 0.13703976
  },
  {
    "datetime": "10/15/2024 14:00",
    "plant_id": "Green-tek-A26494",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "2:00:00 PM",
    "power": 0.483,
    "Energy": 0.121,
    "output Energy": 0.121,
    "Ac output": 1.121,
    "dc output": 1.14342,
    "AC_R_i": 0.00015783,
    "AC_S_i": 0.000173613,
    "AC_T_i": 0.00019097,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0363009,
    "AC_S_pow": 0.03993099,
    "AC_T_Pow": 0.0439231,
    "AC_R_output": 0.3363,
    "AC_S_output": 0.36993,
    "AC_T_output": 0.406923,
    "dc_current": 0.00088971,
    "dc_voltage": 136,
    "dc_power": 0.10098,
    "dc_output": 0.12342,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000121,
    "carbon offset": 0.15125,
    "trees planted": 3.361111111,
    "DC_R_output": 0.110979,
    "DC_S_output": 0.1220769,
    "DC_T_output": 0.13428459
  },
  {
    "datetime": "10/15/2024 14:15",
    "plant_id": "Green-tek-A26495",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "2:15:00 PM",
    "power": 0.398,
    "Energy": 0.099,
    "output Energy": 0.099,
    "Ac output": 1.099,
    "dc output": 1.12098,
    "AC_R_i": 0.00012913,
    "AC_S_i": 0.000142043,
    "AC_T_i": 0.00015625,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0296999,
    "AC_S_pow": 0.03266989,
    "AC_T_Pow": 0.0359375,
    "AC_R_output": 0.3297,
    "AC_S_output": 0.36267,
    "AC_T_output": 0.398937,
    "dc_current": 0.00072794,
    "dc_voltage": 136,
    "dc_power": 0.0816,
    "dc_output": 0.10098,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000099,
    "carbon offset": 0.12375,
    "trees planted": 2.75,
    "DC_R_output": 0.108801,
    "DC_S_output": 0.1196811,
    "DC_T_output": 0.13164921
  },
  {
    "datetime": "10/15/2024 14:30",
    "plant_id": "Green-tek-A26496",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "2:30:00 PM",
    "power": 0.321,
    "Energy": 0.08,
    "output Energy": 0.08,
    "Ac output": 1.08,
    "dc output": 1.1016,
    "AC_R_i": 0.00010435,
    "AC_S_i": 0.000114785,
    "AC_T_i": 0.00012626,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0240005,
    "AC_S_pow": 0.02640055,
    "AC_T_Pow": 0.0290398,
    "AC_R_output": 0.324,
    "AC_S_output": 0.3564,
    "AC_T_output": 0.39204,
    "dc_current": 0.00058824,
    "dc_voltage": 136,
    "dc_power": 0.06426,
    "dc_output": 0.0816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00008,
    "carbon offset": 0.1,
    "trees planted": 2.222222222,
    "DC_R_output": 0.10692,
    "DC_S_output": 0.117612,
    "DC_T_output": 0.1293732
  },
  {
    "datetime": "10/15/2024 14:45",
    "plant_id": "Green-tek-A26497",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "2:45:00 PM",
    "power": 0.254,
    "Energy": 0.063,
    "output Energy": 0.063,
    "Ac output": 1.063,
    "dc output": 1.08426,
    "AC_R_i": 0.00008217,
    "AC_S_i": 0.000090387,
    "AC_T_i": 0.00009943,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0188991,
    "AC_S_pow": 0.02078901,
    "AC_T_Pow": 0.0228689,
    "AC_R_output": 0.3189,
    "AC_S_output": 0.35079,
    "AC_T_output": 0.385869,
    "dc_current": 0.00046324,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06426,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000063,
    "carbon offset": 0.07875,
    "trees planted": 1.75,
    "DC_R_output": 0.105237,
    "DC_S_output": 0.1157607,
    "DC_T_output": 0.12733677
  },
  {
    "datetime": "10/15/2024 15:00",
    "plant_id": "Green-tek-A26498",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "3:00:00 PM",
    "power": 0.197,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "10/15/2024 15:15",
    "plant_id": "Green-tek-A26499",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "3:15:00 PM",
    "power": 0.149,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "10/15/2024 15:30",
    "plant_id": "Green-tek-A26500",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "3:30:00 PM",
    "power": 0.111,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "10/15/2024 15:45",
    "plant_id": "Green-tek-A26501",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "3:45:00 PM",
    "power": 0.081,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "10/15/2024 16:00",
    "plant_id": "Green-tek-A26502",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "4:00:00 PM",
    "power": 0.058,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/15/2024 16:15",
    "plant_id": "Green-tek-A26503",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/15/2024 16:30",
    "plant_id": "Green-tek-A26504",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "4:30:00 PM",
    "power": 0.028,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/15/2024 16:45",
    "plant_id": "Green-tek-A26505",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/15/2024 17:00",
    "plant_id": "Green-tek-A26506",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/15/2024 17:15",
    "plant_id": "Green-tek-A26507",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/15/2024 17:30",
    "plant_id": "Green-tek-A26508",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/15/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/16/2024 6:00",
    "plant_id": "Green-tek-A26509",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/16/2024 6:15",
    "plant_id": "Green-tek-A26510",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/16/2024 6:30",
    "plant_id": "Green-tek-A26511",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/16/2024 6:45",
    "plant_id": "Green-tek-A26512",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/16/2024 7:00",
    "plant_id": "Green-tek-A26513",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/16/2024 7:15",
    "plant_id": "Green-tek-A26514",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "7:15:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/16/2024 7:30",
    "plant_id": "Green-tek-A26515",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "7:30:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/16/2024 7:45",
    "plant_id": "Green-tek-A26516",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "7:45:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/16/2024 8:00",
    "plant_id": "Green-tek-A26517",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "8:00:00 AM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/16/2024 8:15",
    "plant_id": "Green-tek-A26518",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "8:15:00 AM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "10/16/2024 8:30",
    "plant_id": "Green-tek-A26519",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "8:30:00 AM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "10/16/2024 8:45",
    "plant_id": "Green-tek-A26520",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "8:45:00 AM",
    "power": 0.19,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "10/16/2024 9:00",
    "plant_id": "Green-tek-A26521",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "9:00:00 AM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "10/16/2024 9:15",
    "plant_id": "Green-tek-A26522",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "9:15:00 AM",
    "power": 0.298,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "10/16/2024 9:30",
    "plant_id": "Green-tek-A26523",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "9:30:00 AM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "10/16/2024 9:45",
    "plant_id": "Green-tek-A26524",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "9:45:00 AM",
    "power": 0.43,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "10/16/2024 10:00",
    "plant_id": "Green-tek-A26525",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "10:00:00 AM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "10/16/2024 10:15",
    "plant_id": "Green-tek-A26526",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "10:15:00 AM",
    "power": 0.573,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "10/16/2024 10:30",
    "plant_id": "Green-tek-A26527",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "10:30:00 AM",
    "power": 0.641,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "10/16/2024 10:45",
    "plant_id": "Green-tek-A26528",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "10:45:00 AM",
    "power": 0.702,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "10/16/2024 11:00",
    "plant_id": "Green-tek-A26529",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "11:00:00 AM",
    "power": 0.754,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "10/16/2024 11:15",
    "plant_id": "Green-tek-A26530",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "11:15:00 AM",
    "power": 0.794,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "10/16/2024 11:30",
    "plant_id": "Green-tek-A26531",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "11:30:00 AM",
    "power": 0.818,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "10/16/2024 11:45",
    "plant_id": "Green-tek-A26532",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "11:45:00 AM",
    "power": 0.827,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "10/16/2024 12:00",
    "plant_id": "Green-tek-A26533",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "12:00:00 PM",
    "power": 0.818,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "10/16/2024 12:15",
    "plant_id": "Green-tek-A26534",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "12:15:00 PM",
    "power": 0.794,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "10/16/2024 12:30",
    "plant_id": "Green-tek-A26535",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "12:30:00 PM",
    "power": 0.754,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "10/16/2024 12:45",
    "plant_id": "Green-tek-A26536",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "12:45:00 PM",
    "power": 0.702,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "10/16/2024 13:00",
    "plant_id": "Green-tek-A26537",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "1:00:00 PM",
    "power": 0.641,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "10/16/2024 13:15",
    "plant_id": "Green-tek-A26538",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "1:15:00 PM",
    "power": 0.573,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1275,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "10/16/2024 13:30",
    "plant_id": "Green-tek-A26539",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "1:30:00 PM",
    "power": 0.501,
    "Energy": 0.125,
    "output Energy": 0.125,
    "Ac output": 1.125,
    "dc output": 1.1475,
    "AC_R_i": 0.00016304,
    "AC_S_i": 0.000179344,
    "AC_T_i": 0.00019728,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0374992,
    "AC_S_pow": 0.04124912,
    "AC_T_Pow": 0.0453744,
    "AC_R_output": 0.3375,
    "AC_S_output": 0.37125,
    "AC_T_output": 0.408375,
    "dc_current": 0.00091912,
    "dc_voltage": 136,
    "dc_power": 0.11016,
    "dc_output": 0.1275,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000125,
    "carbon offset": 0.15625,
    "trees planted": 3.472222222,
    "DC_R_output": 0.111375,
    "DC_S_output": 0.1225125,
    "DC_T_output": 0.13476375
  },
  {
    "datetime": "10/16/2024 13:45",
    "plant_id": "Green-tek-A26540",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "1:45:00 PM",
    "power": 0.43,
    "Energy": 0.108,
    "output Energy": 0.108,
    "Ac output": 1.108,
    "dc output": 1.13016,
    "AC_R_i": 0.00014087,
    "AC_S_i": 0.000154957,
    "AC_T_i": 0.00017045,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0324001,
    "AC_S_pow": 0.03564011,
    "AC_T_Pow": 0.0392035,
    "AC_R_output": 0.3324,
    "AC_S_output": 0.36564,
    "AC_T_output": 0.402204,
    "dc_current": 0.00079412,
    "dc_voltage": 136,
    "dc_power": 0.0918,
    "dc_output": 0.11016,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000108,
    "carbon offset": 0.135,
    "trees planted": 3,
    "DC_R_output": 0.109692,
    "DC_S_output": 0.1206612,
    "DC_T_output": 0.13272732
  },
  {
    "datetime": "10/16/2024 14:00",
    "plant_id": "Green-tek-A26541",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "2:00:00 PM",
    "power": 0.362,
    "Energy": 0.09,
    "output Energy": 0.09,
    "Ac output": 1.09,
    "dc output": 1.1118,
    "AC_R_i": 0.00011739,
    "AC_S_i": 0.000129129,
    "AC_T_i": 0.00014204,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0269997,
    "AC_S_pow": 0.02969967,
    "AC_T_Pow": 0.0326692,
    "AC_R_output": 0.327,
    "AC_S_output": 0.3597,
    "AC_T_output": 0.39567,
    "dc_current": 0.00066176,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00009,
    "carbon offset": 0.1125,
    "trees planted": 2.5,
    "DC_R_output": 0.10791,
    "DC_S_output": 0.118701,
    "DC_T_output": 0.1305711
  },
  {
    "datetime": "10/16/2024 14:15",
    "plant_id": "Green-tek-A26542",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "2:15:00 PM",
    "power": 0.298,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0612,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "10/16/2024 14:30",
    "plant_id": "Green-tek-A26543",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "2:30:00 PM",
    "power": 0.241,
    "Energy": 0.06,
    "output Energy": 0.06,
    "Ac output": 1.06,
    "dc output": 1.0812,
    "AC_R_i": 0.00007826,
    "AC_S_i": 0.000086086,
    "AC_T_i": 0.00009469,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0179998,
    "AC_S_pow": 0.01979978,
    "AC_T_Pow": 0.0217787,
    "AC_R_output": 0.318,
    "AC_S_output": 0.3498,
    "AC_T_output": 0.38478,
    "dc_current": 0.00044118,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.0612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00006,
    "carbon offset": 0.075,
    "trees planted": 1.666666667,
    "DC_R_output": 0.10494,
    "DC_S_output": 0.115434,
    "DC_T_output": 0.1269774
  },
  {
    "datetime": "10/16/2024 14:45",
    "plant_id": "Green-tek-A26544",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "2:45:00 PM",
    "power": 0.19,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "10/16/2024 15:00",
    "plant_id": "Green-tek-A26545",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "3:00:00 PM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02856,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "10/16/2024 15:15",
    "plant_id": "Green-tek-A26546",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "3:15:00 PM",
    "power": 0.112,
    "Energy": 0.028,
    "output Energy": 0.028,
    "Ac output": 1.028,
    "dc output": 1.04856,
    "AC_R_i": 0.00003652,
    "AC_S_i": 0.000040172,
    "AC_T_i": 0.00004419,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0083996,
    "AC_S_pow": 0.00923956,
    "AC_T_Pow": 0.0101637,
    "AC_R_output": 0.3084,
    "AC_S_output": 0.33924,
    "AC_T_output": 0.373164,
    "dc_current": 0.00020588,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02856,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000028,
    "carbon offset": 0.035,
    "trees planted": 0.777777778,
    "DC_R_output": 0.101772,
    "DC_S_output": 0.1119492,
    "DC_T_output": 0.12314412
  },
  {
    "datetime": "10/16/2024 15:30",
    "plant_id": "Green-tek-A26547",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "3:30:00 PM",
    "power": 0.083,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/16/2024 15:45",
    "plant_id": "Green-tek-A26548",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "3:45:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/16/2024 16:00",
    "plant_id": "Green-tek-A26549",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "4:00:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/16/2024 16:15",
    "plant_id": "Green-tek-A26550",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "4:15:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/16/2024 16:30",
    "plant_id": "Green-tek-A26551",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/16/2024 16:45",
    "plant_id": "Green-tek-A26552",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/16/2024 17:00",
    "plant_id": "Green-tek-A26553",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/16/2024 17:15",
    "plant_id": "Green-tek-A26554",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/16/2024 17:30",
    "plant_id": "Green-tek-A26555",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/16/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/17/2024 6:00",
    "plant_id": "Green-tek-A26556",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/17/2024 6:15",
    "plant_id": "Green-tek-A26557",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/17/2024 6:30",
    "plant_id": "Green-tek-A26558",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/17/2024 6:45",
    "plant_id": "Green-tek-A26559",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/17/2024 7:00",
    "plant_id": "Green-tek-A26560",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "7:00:00 AM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/17/2024 7:15",
    "plant_id": "Green-tek-A26561",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/17/2024 7:30",
    "plant_id": "Green-tek-A26562",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/17/2024 7:45",
    "plant_id": "Green-tek-A26563",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "7:45:00 AM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/17/2024 8:00",
    "plant_id": "Green-tek-A26564",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "8:00:00 AM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/17/2024 8:15",
    "plant_id": "Green-tek-A26565",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "8:15:00 AM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/17/2024 8:30",
    "plant_id": "Green-tek-A26566",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "8:30:00 AM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "10/17/2024 8:45",
    "plant_id": "Green-tek-A26567",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "8:45:00 AM",
    "power": 0.206,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/17/2024 9:00",
    "plant_id": "Green-tek-A26568",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "9:00:00 AM",
    "power": 0.26,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "10/17/2024 9:15",
    "plant_id": "Green-tek-A26569",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "9:15:00 AM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "10/17/2024 9:30",
    "plant_id": "Green-tek-A26570",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "9:30:00 AM",
    "power": 0.392,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "10/17/2024 9:45",
    "plant_id": "Green-tek-A26571",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "9:45:00 AM",
    "power": 0.466,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "10/17/2024 10:00",
    "plant_id": "Green-tek-A26572",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "10:00:00 AM",
    "power": 0.543,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "10/17/2024 10:15",
    "plant_id": "Green-tek-A26573",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "10:15:00 AM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "10/17/2024 10:30",
    "plant_id": "Green-tek-A26574",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "10:30:00 AM",
    "power": 0.694,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "10/17/2024 10:45",
    "plant_id": "Green-tek-A26575",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "10:45:00 AM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "10/17/2024 11:00",
    "plant_id": "Green-tek-A26576",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "11:00:00 AM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "10/17/2024 11:15",
    "plant_id": "Green-tek-A26577",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "11:15:00 AM",
    "power": 0.859,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "10/17/2024 11:30",
    "plant_id": "Green-tek-A26578",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "11:30:00 AM",
    "power": 0.886,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "10/17/2024 11:45",
    "plant_id": "Green-tek-A26579",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "11:45:00 AM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.22542,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "10/17/2024 12:00",
    "plant_id": "Green-tek-A26580",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "12:00:00 PM",
    "power": 0.886,
    "Energy": 0.221,
    "output Energy": 0.221,
    "Ac output": 1.221,
    "dc output": 1.24542,
    "AC_R_i": 0.00028826,
    "AC_S_i": 0.000317086,
    "AC_T_i": 0.00034879,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0662998,
    "AC_S_pow": 0.07292978,
    "AC_T_Pow": 0.0802217,
    "AC_R_output": 0.3663,
    "AC_S_output": 0.40293,
    "AC_T_output": 0.443223,
    "dc_current": 0.001625,
    "dc_voltage": 136,
    "dc_power": 0.2193,
    "dc_output": 0.22542,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000221,
    "carbon offset": 0.27625,
    "trees planted": 6.138888889,
    "DC_R_output": 0.120879,
    "DC_S_output": 0.1329669,
    "DC_T_output": 0.14626359
  },
  {
    "datetime": "10/17/2024 12:15",
    "plant_id": "Green-tek-A26581",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "12:15:00 PM",
    "power": 0.859,
    "Energy": 0.215,
    "output Energy": 0.215,
    "Ac output": 1.215,
    "dc output": 1.2393,
    "AC_R_i": 0.00028043,
    "AC_S_i": 0.000308473,
    "AC_T_i": 0.00033932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0644989,
    "AC_S_pow": 0.07094879,
    "AC_T_Pow": 0.0780436,
    "AC_R_output": 0.3645,
    "AC_S_output": 0.40095,
    "AC_T_output": 0.441045,
    "dc_current": 0.00158088,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.2193,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000215,
    "carbon offset": 0.26875,
    "trees planted": 5.972222222,
    "DC_R_output": 0.120285,
    "DC_S_output": 0.1323135,
    "DC_T_output": 0.14554485
  },
  {
    "datetime": "10/17/2024 12:30",
    "plant_id": "Green-tek-A26582",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "12:30:00 PM",
    "power": 0.817,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.1938,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "10/17/2024 12:45",
    "plant_id": "Green-tek-A26583",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "12:45:00 PM",
    "power": 0.76,
    "Energy": 0.19,
    "output Energy": 0.19,
    "Ac output": 1.19,
    "dc output": 1.2138,
    "AC_R_i": 0.00024783,
    "AC_S_i": 0.000272613,
    "AC_T_i": 0.00029987,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0570009,
    "AC_S_pow": 0.06270099,
    "AC_T_Pow": 0.0689701,
    "AC_R_output": 0.357,
    "AC_S_output": 0.3927,
    "AC_T_output": 0.43197,
    "dc_current": 0.00139706,
    "dc_voltage": 136,
    "dc_power": 0.17646,
    "dc_output": 0.1938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00019,
    "carbon offset": 0.2375,
    "trees planted": 5.277777778,
    "DC_R_output": 0.11781,
    "DC_S_output": 0.129591,
    "DC_T_output": 0.1425501
  },
  {
    "datetime": "10/17/2024 13:00",
    "plant_id": "Green-tek-A26584",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "1:00:00 PM",
    "power": 0.694,
    "Energy": 0.173,
    "output Energy": 0.173,
    "Ac output": 1.173,
    "dc output": 1.19646,
    "AC_R_i": 0.00022565,
    "AC_S_i": 0.000248215,
    "AC_T_i": 0.00027304,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0518995,
    "AC_S_pow": 0.05708945,
    "AC_T_Pow": 0.0627992,
    "AC_R_output": 0.3519,
    "AC_S_output": 0.38709,
    "AC_T_output": 0.425799,
    "dc_current": 0.00127206,
    "dc_voltage": 136,
    "dc_power": 0.1581,
    "dc_output": 0.17646,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000173,
    "carbon offset": 0.21625,
    "trees planted": 4.805555556,
    "DC_R_output": 0.116127,
    "DC_S_output": 0.1277397,
    "DC_T_output": 0.14051367
  },
  {
    "datetime": "10/17/2024 13:15",
    "plant_id": "Green-tek-A26585",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "1:15:00 PM",
    "power": 0.62,
    "Energy": 0.155,
    "output Energy": 0.155,
    "Ac output": 1.155,
    "dc output": 1.1781,
    "AC_R_i": 0.00020217,
    "AC_S_i": 0.000222387,
    "AC_T_i": 0.00024463,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0464991,
    "AC_S_pow": 0.05114901,
    "AC_T_Pow": 0.0562649,
    "AC_R_output": 0.3465,
    "AC_S_output": 0.38115,
    "AC_T_output": 0.419265,
    "dc_current": 0.00113971,
    "dc_voltage": 136,
    "dc_power": 0.13872,
    "dc_output": 0.1581,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000155,
    "carbon offset": 0.19375,
    "trees planted": 4.305555556,
    "DC_R_output": 0.114345,
    "DC_S_output": 0.1257795,
    "DC_T_output": 0.13835745
  },
  {
    "datetime": "10/17/2024 13:30",
    "plant_id": "Green-tek-A26586",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "1:30:00 PM",
    "power": 0.543,
    "Energy": 0.136,
    "output Energy": 0.136,
    "Ac output": 1.136,
    "dc output": 1.15872,
    "AC_R_i": 0.00017739,
    "AC_S_i": 0.000195129,
    "AC_T_i": 0.00021464,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0407997,
    "AC_S_pow": 0.04487967,
    "AC_T_Pow": 0.0493672,
    "AC_R_output": 0.3408,
    "AC_S_output": 0.37488,
    "AC_T_output": 0.412368,
    "dc_current": 0.001,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.13872,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000136,
    "carbon offset": 0.17,
    "trees planted": 3.777777778,
    "DC_R_output": 0.112464,
    "DC_S_output": 0.1237104,
    "DC_T_output": 0.13608144
  },
  {
    "datetime": "10/17/2024 13:45",
    "plant_id": "Green-tek-A26587",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "1:45:00 PM",
    "power": 0.466,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "10/17/2024 14:00",
    "plant_id": "Green-tek-A26588",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "2:00:00 PM",
    "power": 0.392,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "10/17/2024 14:15",
    "plant_id": "Green-tek-A26589",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "2:15:00 PM",
    "power": 0.323,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "10/17/2024 14:30",
    "plant_id": "Green-tek-A26590",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "2:30:00 PM",
    "power": 0.26,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "10/17/2024 14:45",
    "plant_id": "Green-tek-A26591",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "2:45:00 PM",
    "power": 0.206,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/17/2024 15:00",
    "plant_id": "Green-tek-A26592",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "3:00:00 PM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "10/17/2024 15:15",
    "plant_id": "Green-tek-A26593",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "3:15:00 PM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/17/2024 15:30",
    "plant_id": "Green-tek-A26594",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "3:30:00 PM",
    "power": 0.09,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/17/2024 15:45",
    "plant_id": "Green-tek-A26595",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "3:45:00 PM",
    "power": 0.066,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/17/2024 16:00",
    "plant_id": "Green-tek-A26596",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/17/2024 16:15",
    "plant_id": "Green-tek-A26597",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/17/2024 16:30",
    "plant_id": "Green-tek-A26598",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "4:30:00 PM",
    "power": 0.022,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/17/2024 16:45",
    "plant_id": "Green-tek-A26599",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/17/2024 17:00",
    "plant_id": "Green-tek-A26600",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/17/2024 17:15",
    "plant_id": "Green-tek-A26601",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/17/2024 17:30",
    "plant_id": "Green-tek-A26602",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/17/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/18/2024 6:00",
    "plant_id": "Green-tek-A26603",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/18/2024 6:15",
    "plant_id": "Green-tek-A26604",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/18/2024 6:30",
    "plant_id": "Green-tek-A26605",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/18/2024 6:45",
    "plant_id": "Green-tek-A26606",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/18/2024 7:00",
    "plant_id": "Green-tek-A26607",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/18/2024 7:15",
    "plant_id": "Green-tek-A26608",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/18/2024 7:30",
    "plant_id": "Green-tek-A26609",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/18/2024 7:45",
    "plant_id": "Green-tek-A26610",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "7:45:00 AM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/18/2024 8:00",
    "plant_id": "Green-tek-A26611",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/18/2024 8:15",
    "plant_id": "Green-tek-A26612",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "8:15:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "10/18/2024 8:30",
    "plant_id": "Green-tek-A26613",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "8:30:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "10/18/2024 8:45",
    "plant_id": "Green-tek-A26614",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "8:45:00 AM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "10/18/2024 9:00",
    "plant_id": "Green-tek-A26615",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "9:00:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "10/18/2024 9:15",
    "plant_id": "Green-tek-A26616",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "9:15:00 AM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "10/18/2024 9:30",
    "plant_id": "Green-tek-A26617",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "9:30:00 AM",
    "power": 0.57,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "10/18/2024 9:45",
    "plant_id": "Green-tek-A26618",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "9:45:00 AM",
    "power": 0.678,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "10/18/2024 10:00",
    "plant_id": "Green-tek-A26619",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "10:00:00 AM",
    "power": 0.79,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "10/18/2024 10:15",
    "plant_id": "Green-tek-A26620",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "10:15:00 AM",
    "power": 0.902,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "10/18/2024 10:30",
    "plant_id": "Green-tek-A26621",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "10:30:00 AM",
    "power": 1.01,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "10/18/2024 10:45",
    "plant_id": "Green-tek-A26622",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "10:45:00 AM",
    "power": 1.107,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "10/18/2024 11:00",
    "plant_id": "Green-tek-A26623",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "11:00:00 AM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "10/18/2024 11:15",
    "plant_id": "Green-tek-A26624",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "11:15:00 AM",
    "power": 1.251,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "10/18/2024 11:30",
    "plant_id": "Green-tek-A26625",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "11:30:00 AM",
    "power": 1.29,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.33252,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "10/18/2024 11:45",
    "plant_id": "Green-tek-A26626",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "11:45:00 AM",
    "power": 1.303,
    "Energy": 0.326,
    "output Energy": 0.326,
    "Ac output": 1.326,
    "dc output": 1.35252,
    "AC_R_i": 0.00042522,
    "AC_S_i": 0.000467742,
    "AC_T_i": 0.00051452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0978006,
    "AC_S_pow": 0.10758066,
    "AC_T_Pow": 0.1183396,
    "AC_R_output": 0.3978,
    "AC_S_output": 0.43758,
    "AC_T_output": 0.481338,
    "dc_current": 0.00239706,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.33252,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000326,
    "carbon offset": 0.4075,
    "trees planted": 9.055555556,
    "DC_R_output": 0.131274,
    "DC_S_output": 0.1444014,
    "DC_T_output": 0.15884154
  },
  {
    "datetime": "10/18/2024 12:00",
    "plant_id": "Green-tek-A26627",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "12:00:00 PM",
    "power": 1.29,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.31926,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "10/18/2024 12:15",
    "plant_id": "Green-tek-A26628",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "12:15:00 PM",
    "power": 1.251,
    "Energy": 0.313,
    "output Energy": 0.313,
    "Ac output": 1.313,
    "dc output": 1.33926,
    "AC_R_i": 0.00040826,
    "AC_S_i": 0.000449086,
    "AC_T_i": 0.00049399,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0938998,
    "AC_S_pow": 0.10328978,
    "AC_T_Pow": 0.1136177,
    "AC_R_output": 0.3939,
    "AC_S_output": 0.43329,
    "AC_T_output": 0.476619,
    "dc_current": 0.00230147,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31926,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000313,
    "carbon offset": 0.39125,
    "trees planted": 8.694444444,
    "DC_R_output": 0.129987,
    "DC_S_output": 0.1429857,
    "DC_T_output": 0.15728427
  },
  {
    "datetime": "10/18/2024 12:30",
    "plant_id": "Green-tek-A26629",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "12:30:00 PM",
    "power": 1.189,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28254,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "10/18/2024 12:45",
    "plant_id": "Green-tek-A26630",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "12:45:00 PM",
    "power": 1.107,
    "Energy": 0.277,
    "output Energy": 0.277,
    "Ac output": 1.277,
    "dc output": 1.30254,
    "AC_R_i": 0.0003613,
    "AC_S_i": 0.00039743,
    "AC_T_i": 0.00043717,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.083099,
    "AC_S_pow": 0.0914089,
    "AC_T_Pow": 0.1005491,
    "AC_R_output": 0.3831,
    "AC_S_output": 0.42141,
    "AC_T_output": 0.463551,
    "dc_current": 0.00203676,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.28254,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000277,
    "carbon offset": 0.34625,
    "trees planted": 7.694444444,
    "DC_R_output": 0.126423,
    "DC_S_output": 0.1390653,
    "DC_T_output": 0.15297183
  },
  {
    "datetime": "10/18/2024 13:00",
    "plant_id": "Green-tek-A26631",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "1:00:00 PM",
    "power": 1.01,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "10/18/2024 13:15",
    "plant_id": "Green-tek-A26632",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "1:15:00 PM",
    "power": 0.902,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.20196,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "10/18/2024 13:30",
    "plant_id": "Green-tek-A26633",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "1:30:00 PM",
    "power": 0.79,
    "Energy": 0.198,
    "output Energy": 0.198,
    "Ac output": 1.198,
    "dc output": 1.22196,
    "AC_R_i": 0.00025826,
    "AC_S_i": 0.000284086,
    "AC_T_i": 0.00031249,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0593998,
    "AC_S_pow": 0.06533978,
    "AC_T_Pow": 0.0718727,
    "AC_R_output": 0.3594,
    "AC_S_output": 0.39534,
    "AC_T_output": 0.434874,
    "dc_current": 0.00145588,
    "dc_voltage": 136,
    "dc_power": 0.1734,
    "dc_output": 0.20196,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000198,
    "carbon offset": 0.2475,
    "trees planted": 5.5,
    "DC_R_output": 0.118602,
    "DC_S_output": 0.1304622,
    "DC_T_output": 0.14350842
  },
  {
    "datetime": "10/18/2024 13:45",
    "plant_id": "Green-tek-A26634",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "1:45:00 PM",
    "power": 0.678,
    "Energy": 0.17,
    "output Energy": 0.17,
    "Ac output": 1.17,
    "dc output": 1.1934,
    "AC_R_i": 0.00022174,
    "AC_S_i": 0.000243914,
    "AC_T_i": 0.00026831,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0510002,
    "AC_S_pow": 0.05610022,
    "AC_T_Pow": 0.0617113,
    "AC_R_output": 0.351,
    "AC_S_output": 0.3861,
    "AC_T_output": 0.42471,
    "dc_current": 0.00125,
    "dc_voltage": 136,
    "dc_power": 0.14586,
    "dc_output": 0.1734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00017,
    "carbon offset": 0.2125,
    "trees planted": 4.722222222,
    "DC_R_output": 0.11583,
    "DC_S_output": 0.127413,
    "DC_T_output": 0.1401543
  },
  {
    "datetime": "10/18/2024 14:00",
    "plant_id": "Green-tek-A26635",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "2:00:00 PM",
    "power": 0.57,
    "Energy": 0.143,
    "output Energy": 0.143,
    "Ac output": 1.143,
    "dc output": 1.16586,
    "AC_R_i": 0.00018652,
    "AC_S_i": 0.000205172,
    "AC_T_i": 0.00022569,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0428996,
    "AC_S_pow": 0.04718956,
    "AC_T_Pow": 0.0519087,
    "AC_R_output": 0.3429,
    "AC_S_output": 0.37719,
    "AC_T_output": 0.414909,
    "dc_current": 0.00105147,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14586,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000143,
    "carbon offset": 0.17875,
    "trees planted": 3.972222222,
    "DC_R_output": 0.113157,
    "DC_S_output": 0.1244727,
    "DC_T_output": 0.13691997
  },
  {
    "datetime": "10/18/2024 14:15",
    "plant_id": "Green-tek-A26636",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "2:15:00 PM",
    "power": 0.47,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "10/18/2024 14:30",
    "plant_id": "Green-tek-A26637",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "2:30:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "10/18/2024 14:45",
    "plant_id": "Green-tek-A26638",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "2:45:00 PM",
    "power": 0.3,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "10/18/2024 15:00",
    "plant_id": "Green-tek-A26639",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "3:00:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "10/18/2024 15:15",
    "plant_id": "Green-tek-A26640",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "3:15:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "10/18/2024 15:30",
    "plant_id": "Green-tek-A26641",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/18/2024 15:45",
    "plant_id": "Green-tek-A26642",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "3:45:00 PM",
    "power": 0.096,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/18/2024 16:00",
    "plant_id": "Green-tek-A26643",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/18/2024 16:15",
    "plant_id": "Green-tek-A26644",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/18/2024 16:30",
    "plant_id": "Green-tek-A26645",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/18/2024 16:45",
    "plant_id": "Green-tek-A26646",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/18/2024 17:00",
    "plant_id": "Green-tek-A26647",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/18/2024 17:15",
    "plant_id": "Green-tek-A26648",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/18/2024 17:30",
    "plant_id": "Green-tek-A26649",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/18/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/19/2024 6:00",
    "plant_id": "Green-tek-A26650",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/19/2024 6:15",
    "plant_id": "Green-tek-A26651",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/19/2024 6:30",
    "plant_id": "Green-tek-A26652",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/19/2024 6:45",
    "plant_id": "Green-tek-A26653",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/19/2024 7:00",
    "plant_id": "Green-tek-A26654",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/19/2024 7:15",
    "plant_id": "Green-tek-A26655",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/19/2024 7:30",
    "plant_id": "Green-tek-A26656",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/19/2024 7:45",
    "plant_id": "Green-tek-A26657",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/19/2024 8:00",
    "plant_id": "Green-tek-A26658",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "8:00:00 AM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/19/2024 8:15",
    "plant_id": "Green-tek-A26659",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "8:15:00 AM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "10/19/2024 8:30",
    "plant_id": "Green-tek-A26660",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "8:30:00 AM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/19/2024 8:45",
    "plant_id": "Green-tek-A26661",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "8:45:00 AM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "10/19/2024 9:00",
    "plant_id": "Green-tek-A26662",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "9:00:00 AM",
    "power": 0.334,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "10/19/2024 9:15",
    "plant_id": "Green-tek-A26663",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "9:15:00 AM",
    "power": 0.414,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "10/19/2024 9:30",
    "plant_id": "Green-tek-A26664",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "9:30:00 AM",
    "power": 0.502,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "10/19/2024 9:45",
    "plant_id": "Green-tek-A26665",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "9:45:00 AM",
    "power": 0.598,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "10/19/2024 10:00",
    "plant_id": "Green-tek-A26666",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "10:00:00 AM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "10/19/2024 10:15",
    "plant_id": "Green-tek-A26667",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "10:15:00 AM",
    "power": 0.795,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "10/19/2024 10:30",
    "plant_id": "Green-tek-A26668",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "10:30:00 AM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "10/19/2024 10:45",
    "plant_id": "Green-tek-A26669",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "10:45:00 AM",
    "power": 0.975,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "10/19/2024 11:00",
    "plant_id": "Green-tek-A26670",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "11:00:00 AM",
    "power": 1.047,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "10/19/2024 11:15",
    "plant_id": "Green-tek-A26671",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "11:15:00 AM",
    "power": 1.102,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "10/19/2024 11:30",
    "plant_id": "Green-tek-A26672",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "11:30:00 AM",
    "power": 1.136,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "10/19/2024 11:45",
    "plant_id": "Green-tek-A26673",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "11:45:00 AM",
    "power": 1.148,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.28968,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "10/19/2024 12:00",
    "plant_id": "Green-tek-A26674",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "12:00:00 PM",
    "power": 1.136,
    "Energy": 0.284,
    "output Energy": 0.284,
    "Ac output": 1.284,
    "dc output": 1.30968,
    "AC_R_i": 0.00037043,
    "AC_S_i": 0.000407473,
    "AC_T_i": 0.00044822,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0851989,
    "AC_S_pow": 0.09371879,
    "AC_T_Pow": 0.1030906,
    "AC_R_output": 0.3852,
    "AC_S_output": 0.42372,
    "AC_T_output": 0.466092,
    "dc_current": 0.00208824,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.28968,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000284,
    "carbon offset": 0.355,
    "trees planted": 7.888888889,
    "DC_R_output": 0.127116,
    "DC_S_output": 0.1398276,
    "DC_T_output": 0.15381036
  },
  {
    "datetime": "10/19/2024 12:15",
    "plant_id": "Green-tek-A26675",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "12:15:00 PM",
    "power": 1.102,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "10/19/2024 12:30",
    "plant_id": "Green-tek-A26676",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "12:30:00 PM",
    "power": 1.047,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "10/19/2024 12:45",
    "plant_id": "Green-tek-A26677",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "12:45:00 PM",
    "power": 0.975,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "10/19/2024 13:00",
    "plant_id": "Green-tek-A26678",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "1:00:00 PM",
    "power": 0.89,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "10/19/2024 13:15",
    "plant_id": "Green-tek-A26679",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "1:15:00 PM",
    "power": 0.795,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "10/19/2024 13:30",
    "plant_id": "Green-tek-A26680",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "1:30:00 PM",
    "power": 0.696,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15198,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "10/19/2024 13:45",
    "plant_id": "Green-tek-A26681",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "1:45:00 PM",
    "power": 0.598,
    "Energy": 0.149,
    "output Energy": 0.149,
    "Ac output": 1.149,
    "dc output": 1.17198,
    "AC_R_i": 0.00019435,
    "AC_S_i": 0.000213785,
    "AC_T_i": 0.00023516,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0447005,
    "AC_S_pow": 0.04917055,
    "AC_T_Pow": 0.0540868,
    "AC_R_output": 0.3447,
    "AC_S_output": 0.37917,
    "AC_T_output": 0.417087,
    "dc_current": 0.00109559,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.15198,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000149,
    "carbon offset": 0.18625,
    "trees planted": 4.138888889,
    "DC_R_output": 0.113751,
    "DC_S_output": 0.1251261,
    "DC_T_output": 0.13763871
  },
  {
    "datetime": "10/19/2024 14:00",
    "plant_id": "Green-tek-A26682",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "2:00:00 PM",
    "power": 0.502,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.10506,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "10/19/2024 14:15",
    "plant_id": "Green-tek-A26683",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "2:15:00 PM",
    "power": 0.414,
    "Energy": 0.103,
    "output Energy": 0.103,
    "Ac output": 1.103,
    "dc output": 1.12506,
    "AC_R_i": 0.00013435,
    "AC_S_i": 0.000147785,
    "AC_T_i": 0.00016256,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0309005,
    "AC_S_pow": 0.03399055,
    "AC_T_Pow": 0.0373888,
    "AC_R_output": 0.3309,
    "AC_S_output": 0.36399,
    "AC_T_output": 0.400389,
    "dc_current": 0.00075735,
    "dc_voltage": 136,
    "dc_power": 0.08466,
    "dc_output": 0.10506,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000103,
    "carbon offset": 0.12875,
    "trees planted": 2.861111111,
    "DC_R_output": 0.109197,
    "DC_S_output": 0.1201167,
    "DC_T_output": 0.13212837
  },
  {
    "datetime": "10/19/2024 14:30",
    "plant_id": "Green-tek-A26684",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "2:30:00 PM",
    "power": 0.334,
    "Energy": 0.083,
    "output Energy": 0.083,
    "Ac output": 1.083,
    "dc output": 1.10466,
    "AC_R_i": 0.00010826,
    "AC_S_i": 0.000119086,
    "AC_T_i": 0.00013099,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0248998,
    "AC_S_pow": 0.02738978,
    "AC_T_Pow": 0.0301277,
    "AC_R_output": 0.3249,
    "AC_S_output": 0.35739,
    "AC_T_output": 0.393129,
    "dc_current": 0.00061029,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08466,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000083,
    "carbon offset": 0.10375,
    "trees planted": 2.305555556,
    "DC_R_output": 0.107217,
    "DC_S_output": 0.1179387,
    "DC_T_output": 0.12973257
  },
  {
    "datetime": "10/19/2024 14:45",
    "plant_id": "Green-tek-A26685",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "2:45:00 PM",
    "power": 0.264,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "10/19/2024 15:00",
    "plant_id": "Green-tek-A26686",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "3:00:00 PM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/19/2024 15:15",
    "plant_id": "Green-tek-A26687",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "3:15:00 PM",
    "power": 0.155,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "10/19/2024 15:30",
    "plant_id": "Green-tek-A26688",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "3:30:00 PM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/19/2024 15:45",
    "plant_id": "Green-tek-A26689",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/19/2024 16:00",
    "plant_id": "Green-tek-A26690",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/19/2024 16:15",
    "plant_id": "Green-tek-A26691",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/19/2024 16:30",
    "plant_id": "Green-tek-A26692",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/19/2024 16:45",
    "plant_id": "Green-tek-A26693",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/19/2024 17:00",
    "plant_id": "Green-tek-A26694",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/19/2024 17:15",
    "plant_id": "Green-tek-A26695",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/19/2024 17:30",
    "plant_id": "Green-tek-A26696",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/19/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/20/2024 6:00",
    "plant_id": "Green-tek-A26697",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/20/2024 6:15",
    "plant_id": "Green-tek-A26698",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/20/2024 6:30",
    "plant_id": "Green-tek-A26699",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/20/2024 6:45",
    "plant_id": "Green-tek-A26700",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/20/2024 7:00",
    "plant_id": "Green-tek-A26701",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/20/2024 7:15",
    "plant_id": "Green-tek-A26702",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/20/2024 7:30",
    "plant_id": "Green-tek-A26703",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/20/2024 7:45",
    "plant_id": "Green-tek-A26704",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "7:45:00 AM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "10/20/2024 8:00",
    "plant_id": "Green-tek-A26705",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "8:00:00 AM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/20/2024 8:15",
    "plant_id": "Green-tek-A26706",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "8:15:00 AM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "10/20/2024 8:30",
    "plant_id": "Green-tek-A26707",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "8:30:00 AM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "10/20/2024 8:45",
    "plant_id": "Green-tek-A26708",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "8:45:00 AM",
    "power": 0.277,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "10/20/2024 9:00",
    "plant_id": "Green-tek-A26709",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "9:00:00 AM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "10/20/2024 9:15",
    "plant_id": "Green-tek-A26710",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "9:15:00 AM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "10/20/2024 9:30",
    "plant_id": "Green-tek-A26711",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "9:30:00 AM",
    "power": 0.528,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "10/20/2024 9:45",
    "plant_id": "Green-tek-A26712",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "9:45:00 AM",
    "power": 0.627,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "10/20/2024 10:00",
    "plant_id": "Green-tek-A26713",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "10:00:00 AM",
    "power": 0.731,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "10/20/2024 10:15",
    "plant_id": "Green-tek-A26714",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "10:15:00 AM",
    "power": 0.835,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "10/20/2024 10:30",
    "plant_id": "Green-tek-A26715",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "10:30:00 AM",
    "power": 0.934,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "10/20/2024 10:45",
    "plant_id": "Green-tek-A26716",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "10:45:00 AM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "10/20/2024 11:00",
    "plant_id": "Green-tek-A26717",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "11:00:00 AM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.29478,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "10/20/2024 11:15",
    "plant_id": "Green-tek-A26718",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "11:15:00 AM",
    "power": 1.157,
    "Energy": 0.289,
    "output Energy": 0.289,
    "Ac output": 1.289,
    "dc output": 1.31478,
    "AC_R_i": 0.00037696,
    "AC_S_i": 0.000414656,
    "AC_T_i": 0.00045612,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0867008,
    "AC_S_pow": 0.09537088,
    "AC_T_Pow": 0.1049076,
    "AC_R_output": 0.3867,
    "AC_S_output": 0.42537,
    "AC_T_output": 0.467907,
    "dc_current": 0.002125,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.29478,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000289,
    "carbon offset": 0.36125,
    "trees planted": 8.027777778,
    "DC_R_output": 0.127611,
    "DC_S_output": 0.1403721,
    "DC_T_output": 0.15440931
  },
  {
    "datetime": "10/20/2024 11:30",
    "plant_id": "Green-tek-A26719",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "11:30:00 AM",
    "power": 1.193,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.30702,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "10/20/2024 11:45",
    "plant_id": "Green-tek-A26720",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "11:45:00 AM",
    "power": 1.206,
    "Energy": 0.301,
    "output Energy": 0.301,
    "Ac output": 1.301,
    "dc output": 1.32702,
    "AC_R_i": 0.00039261,
    "AC_S_i": 0.000431871,
    "AC_T_i": 0.00047506,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0903003,
    "AC_S_pow": 0.09933033,
    "AC_T_Pow": 0.1092638,
    "AC_R_output": 0.3903,
    "AC_S_output": 0.42933,
    "AC_T_output": 0.472263,
    "dc_current": 0.00221324,
    "dc_voltage": 136,
    "dc_power": 0.30396,
    "dc_output": 0.30702,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000301,
    "carbon offset": 0.37625,
    "trees planted": 8.361111111,
    "DC_R_output": 0.128799,
    "DC_S_output": 0.1416789,
    "DC_T_output": 0.15584679
  },
  {
    "datetime": "10/20/2024 12:00",
    "plant_id": "Green-tek-A26721",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "12:00:00 PM",
    "power": 1.193,
    "Energy": 0.298,
    "output Energy": 0.298,
    "Ac output": 1.298,
    "dc output": 1.32396,
    "AC_R_i": 0.0003887,
    "AC_S_i": 0.00042757,
    "AC_T_i": 0.00047033,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.089401,
    "AC_S_pow": 0.0983411,
    "AC_T_Pow": 0.1081759,
    "AC_R_output": 0.3894,
    "AC_S_output": 0.42834,
    "AC_T_output": 0.471174,
    "dc_current": 0.00219118,
    "dc_voltage": 136,
    "dc_power": 0.29478,
    "dc_output": 0.30396,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000298,
    "carbon offset": 0.3725,
    "trees planted": 8.277777778,
    "DC_R_output": 0.128502,
    "DC_S_output": 0.1413522,
    "DC_T_output": 0.15548742
  },
  {
    "datetime": "10/20/2024 12:15",
    "plant_id": "Green-tek-A26722",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "12:15:00 PM",
    "power": 1.157,
    "Energy": 0.289,
    "output Energy": 0.289,
    "Ac output": 1.289,
    "dc output": 1.31478,
    "AC_R_i": 0.00037696,
    "AC_S_i": 0.000414656,
    "AC_T_i": 0.00045612,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0867008,
    "AC_S_pow": 0.09537088,
    "AC_T_Pow": 0.1049076,
    "AC_R_output": 0.3867,
    "AC_S_output": 0.42537,
    "AC_T_output": 0.467907,
    "dc_current": 0.002125,
    "dc_voltage": 136,
    "dc_power": 0.2805,
    "dc_output": 0.29478,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000289,
    "carbon offset": 0.36125,
    "trees planted": 8.027777778,
    "DC_R_output": 0.127611,
    "DC_S_output": 0.1403721,
    "DC_T_output": 0.15440931
  },
  {
    "datetime": "10/20/2024 12:30",
    "plant_id": "Green-tek-A26723",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "12:30:00 PM",
    "power": 1.1,
    "Energy": 0.275,
    "output Energy": 0.275,
    "Ac output": 1.275,
    "dc output": 1.3005,
    "AC_R_i": 0.0003587,
    "AC_S_i": 0.00039457,
    "AC_T_i": 0.00043403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.082501,
    "AC_S_pow": 0.0907511,
    "AC_T_Pow": 0.0998269,
    "AC_R_output": 0.3825,
    "AC_S_output": 0.42075,
    "AC_T_output": 0.462825,
    "dc_current": 0.00202206,
    "dc_voltage": 136,
    "dc_power": 0.26112,
    "dc_output": 0.2805,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000275,
    "carbon offset": 0.34375,
    "trees planted": 7.638888889,
    "DC_R_output": 0.126225,
    "DC_S_output": 0.1388475,
    "DC_T_output": 0.15273225
  },
  {
    "datetime": "10/20/2024 12:45",
    "plant_id": "Green-tek-A26724",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "12:45:00 PM",
    "power": 1.024,
    "Energy": 0.256,
    "output Energy": 0.256,
    "Ac output": 1.256,
    "dc output": 1.28112,
    "AC_R_i": 0.00033391,
    "AC_S_i": 0.000367301,
    "AC_T_i": 0.00040403,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0767993,
    "AC_S_pow": 0.08447923,
    "AC_T_Pow": 0.0929269,
    "AC_R_output": 0.3768,
    "AC_S_output": 0.41448,
    "AC_T_output": 0.455928,
    "dc_current": 0.00188235,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.26112,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000256,
    "carbon offset": 0.32,
    "trees planted": 7.111111111,
    "DC_R_output": 0.124344,
    "DC_S_output": 0.1367784,
    "DC_T_output": 0.15045624
  },
  {
    "datetime": "10/20/2024 13:00",
    "plant_id": "Green-tek-A26725",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "1:00:00 PM",
    "power": 0.934,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "10/20/2024 13:15",
    "plant_id": "Green-tek-A26726",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "1:15:00 PM",
    "power": 0.835,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "10/20/2024 13:30",
    "plant_id": "Green-tek-A26727",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "1:30:00 PM",
    "power": 0.731,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "10/20/2024 13:45",
    "plant_id": "Green-tek-A26728",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "1:45:00 PM",
    "power": 0.627,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "10/20/2024 14:00",
    "plant_id": "Green-tek-A26729",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "2:00:00 PM",
    "power": 0.528,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "10/20/2024 14:15",
    "plant_id": "Green-tek-A26730",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "2:15:00 PM",
    "power": 0.435,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "10/20/2024 14:30",
    "plant_id": "Green-tek-A26731",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "2:30:00 PM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07038,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "10/20/2024 14:45",
    "plant_id": "Green-tek-A26732",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "2:45:00 PM",
    "power": 0.277,
    "Energy": 0.069,
    "output Energy": 0.069,
    "Ac output": 1.069,
    "dc output": 1.09038,
    "AC_R_i": 0.00009,
    "AC_S_i": 0.000099,
    "AC_T_i": 0.0001089,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0207,
    "AC_S_pow": 0.02277,
    "AC_T_Pow": 0.025047,
    "AC_R_output": 0.3207,
    "AC_S_output": 0.35277,
    "AC_T_output": 0.388047,
    "dc_current": 0.00050735,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.07038,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000069,
    "carbon offset": 0.08625,
    "trees planted": 1.916666667,
    "DC_R_output": 0.105831,
    "DC_S_output": 0.1164141,
    "DC_T_output": 0.12805551
  },
  {
    "datetime": "10/20/2024 15:00",
    "plant_id": "Green-tek-A26733",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "3:00:00 PM",
    "power": 0.215,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "10/20/2024 15:15",
    "plant_id": "Green-tek-A26734",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "3:15:00 PM",
    "power": 0.163,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "10/20/2024 15:30",
    "plant_id": "Green-tek-A26735",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "3:30:00 PM",
    "power": 0.121,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/20/2024 15:45",
    "plant_id": "Green-tek-A26736",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "3:45:00 PM",
    "power": 0.088,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "10/20/2024 16:00",
    "plant_id": "Green-tek-A26737",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/20/2024 16:15",
    "plant_id": "Green-tek-A26738",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/20/2024 16:30",
    "plant_id": "Green-tek-A26739",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/20/2024 16:45",
    "plant_id": "Green-tek-A26740",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/20/2024 17:00",
    "plant_id": "Green-tek-A26741",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/20/2024 17:15",
    "plant_id": "Green-tek-A26742",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/20/2024 17:30",
    "plant_id": "Green-tek-A26743",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/20/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/21/2024 6:00",
    "plant_id": "Green-tek-A26744",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/21/2024 6:15",
    "plant_id": "Green-tek-A26745",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/21/2024 6:30",
    "plant_id": "Green-tek-A26746",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "6:30:00 AM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/21/2024 6:45",
    "plant_id": "Green-tek-A26747",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "6:45:00 AM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/21/2024 7:00",
    "plant_id": "Green-tek-A26748",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "7:00:00 AM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/21/2024 7:15",
    "plant_id": "Green-tek-A26749",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "7:15:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/21/2024 7:30",
    "plant_id": "Green-tek-A26750",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "7:30:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/21/2024 7:45",
    "plant_id": "Green-tek-A26751",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "7:45:00 AM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/21/2024 8:00",
    "plant_id": "Green-tek-A26752",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "8:00:00 AM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/21/2024 8:15",
    "plant_id": "Green-tek-A26753",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "8:15:00 AM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/21/2024 8:30",
    "plant_id": "Green-tek-A26754",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "8:30:00 AM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "10/21/2024 8:45",
    "plant_id": "Green-tek-A26755",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "8:45:00 AM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "10/21/2024 9:00",
    "plant_id": "Green-tek-A26756",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "9:00:00 AM",
    "power": 0.262,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "10/21/2024 9:15",
    "plant_id": "Green-tek-A26757",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "9:15:00 AM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "10/21/2024 9:30",
    "plant_id": "Green-tek-A26758",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "9:30:00 AM",
    "power": 0.394,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "10/21/2024 9:45",
    "plant_id": "Green-tek-A26759",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "9:45:00 AM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "10/21/2024 10:00",
    "plant_id": "Green-tek-A26760",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "10:00:00 AM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "10/21/2024 10:15",
    "plant_id": "Green-tek-A26761",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "10:15:00 AM",
    "power": 0.623,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "10/21/2024 10:30",
    "plant_id": "Green-tek-A26762",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "10:30:00 AM",
    "power": 0.698,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "10/21/2024 10:45",
    "plant_id": "Green-tek-A26763",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "10:45:00 AM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/21/2024 11:00",
    "plant_id": "Green-tek-A26764",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "11:00:00 AM",
    "power": 0.821,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "10/21/2024 11:15",
    "plant_id": "Green-tek-A26765",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "11:15:00 AM",
    "power": 0.864,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "10/21/2024 11:30",
    "plant_id": "Green-tek-A26766",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "11:30:00 AM",
    "power": 0.891,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "10/21/2024 11:45",
    "plant_id": "Green-tek-A26767",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "11:45:00 AM",
    "power": 0.9,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "10/21/2024 12:00",
    "plant_id": "Green-tek-A26768",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "12:00:00 PM",
    "power": 0.891,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.22032,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "10/21/2024 12:15",
    "plant_id": "Green-tek-A26769",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "12:15:00 PM",
    "power": 0.864,
    "Energy": 0.216,
    "output Energy": 0.216,
    "Ac output": 1.216,
    "dc output": 1.24032,
    "AC_R_i": 0.00028174,
    "AC_S_i": 0.000309914,
    "AC_T_i": 0.00034091,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0648002,
    "AC_S_pow": 0.07128022,
    "AC_T_Pow": 0.0784093,
    "AC_R_output": 0.3648,
    "AC_S_output": 0.40128,
    "AC_T_output": 0.441408,
    "dc_current": 0.00158824,
    "dc_voltage": 136,
    "dc_power": 0.2091,
    "dc_output": 0.22032,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000216,
    "carbon offset": 0.27,
    "trees planted": 6,
    "DC_R_output": 0.120384,
    "DC_S_output": 0.1324224,
    "DC_T_output": 0.14566464
  },
  {
    "datetime": "10/21/2024 12:30",
    "plant_id": "Green-tek-A26770",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "12:30:00 PM",
    "power": 0.821,
    "Energy": 0.205,
    "output Energy": 0.205,
    "Ac output": 1.205,
    "dc output": 1.2291,
    "AC_R_i": 0.00026739,
    "AC_S_i": 0.000294129,
    "AC_T_i": 0.00032354,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0614997,
    "AC_S_pow": 0.06764967,
    "AC_T_Pow": 0.0744142,
    "AC_R_output": 0.3615,
    "AC_S_output": 0.39765,
    "AC_T_output": 0.437415,
    "dc_current": 0.00150735,
    "dc_voltage": 136,
    "dc_power": 0.19482,
    "dc_output": 0.2091,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000205,
    "carbon offset": 0.25625,
    "trees planted": 5.694444444,
    "DC_R_output": 0.119295,
    "DC_S_output": 0.1312245,
    "DC_T_output": 0.14434695
  },
  {
    "datetime": "10/21/2024 12:45",
    "plant_id": "Green-tek-A26771",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "12:45:00 PM",
    "power": 0.765,
    "Energy": 0.191,
    "output Energy": 0.191,
    "Ac output": 1.191,
    "dc output": 1.21482,
    "AC_R_i": 0.00024913,
    "AC_S_i": 0.000274043,
    "AC_T_i": 0.00030145,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0572999,
    "AC_S_pow": 0.06302989,
    "AC_T_Pow": 0.0693335,
    "AC_R_output": 0.3573,
    "AC_S_output": 0.39303,
    "AC_T_output": 0.432333,
    "dc_current": 0.00140441,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.19482,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000191,
    "carbon offset": 0.23875,
    "trees planted": 5.305555556,
    "DC_R_output": 0.117909,
    "DC_S_output": 0.1296999,
    "DC_T_output": 0.14266989
  },
  {
    "datetime": "10/21/2024 13:00",
    "plant_id": "Green-tek-A26772",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "1:00:00 PM",
    "power": 0.698,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.15912,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "10/21/2024 13:15",
    "plant_id": "Green-tek-A26773",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "1:15:00 PM",
    "power": 0.623,
    "Energy": 0.156,
    "output Energy": 0.156,
    "Ac output": 1.156,
    "dc output": 1.17912,
    "AC_R_i": 0.00020348,
    "AC_S_i": 0.000223828,
    "AC_T_i": 0.00024621,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0468004,
    "AC_S_pow": 0.05148044,
    "AC_T_Pow": 0.0566283,
    "AC_R_output": 0.3468,
    "AC_S_output": 0.38148,
    "AC_T_output": 0.419628,
    "dc_current": 0.00114706,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.15912,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000156,
    "carbon offset": 0.195,
    "trees planted": 4.333333333,
    "DC_R_output": 0.114444,
    "DC_S_output": 0.1258884,
    "DC_T_output": 0.13847724
  },
  {
    "datetime": "10/21/2024 13:30",
    "plant_id": "Green-tek-A26774",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "1:30:00 PM",
    "power": 0.546,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "10/21/2024 13:45",
    "plant_id": "Green-tek-A26775",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "1:45:00 PM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "10/21/2024 14:00",
    "plant_id": "Green-tek-A26776",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "2:00:00 PM",
    "power": 0.394,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08262,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "10/21/2024 14:15",
    "plant_id": "Green-tek-A26777",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "2:15:00 PM",
    "power": 0.324,
    "Energy": 0.081,
    "output Energy": 0.081,
    "Ac output": 1.081,
    "dc output": 1.10262,
    "AC_R_i": 0.00010565,
    "AC_S_i": 0.000116215,
    "AC_T_i": 0.00012784,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0242995,
    "AC_S_pow": 0.02672945,
    "AC_T_Pow": 0.0294032,
    "AC_R_output": 0.3243,
    "AC_S_output": 0.35673,
    "AC_T_output": 0.392403,
    "dc_current": 0.00059559,
    "dc_voltage": 136,
    "dc_power": 0.0663,
    "dc_output": 0.08262,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000081,
    "carbon offset": 0.10125,
    "trees planted": 2.25,
    "DC_R_output": 0.107019,
    "DC_S_output": 0.1177209,
    "DC_T_output": 0.12949299
  },
  {
    "datetime": "10/21/2024 14:30",
    "plant_id": "Green-tek-A26778",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "2:30:00 PM",
    "power": 0.262,
    "Energy": 0.065,
    "output Energy": 0.065,
    "Ac output": 1.065,
    "dc output": 1.0863,
    "AC_R_i": 0.00008478,
    "AC_S_i": 0.000093258,
    "AC_T_i": 0.00010258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0194994,
    "AC_S_pow": 0.02144934,
    "AC_T_Pow": 0.0235934,
    "AC_R_output": 0.3195,
    "AC_S_output": 0.35145,
    "AC_T_output": 0.386595,
    "dc_current": 0.00047794,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.0663,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000065,
    "carbon offset": 0.08125,
    "trees planted": 1.805555556,
    "DC_R_output": 0.105435,
    "DC_S_output": 0.1159785,
    "DC_T_output": 0.12757635
  },
  {
    "datetime": "10/21/2024 14:45",
    "plant_id": "Green-tek-A26779",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "2:45:00 PM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.0408,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "10/21/2024 15:00",
    "plant_id": "Green-tek-A26780",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "3:00:00 PM",
    "power": 0.16,
    "Energy": 0.04,
    "output Energy": 0.04,
    "Ac output": 1.04,
    "dc output": 1.0608,
    "AC_R_i": 0.00005217,
    "AC_S_i": 0.000057387,
    "AC_T_i": 0.00006313,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0119991,
    "AC_S_pow": 0.01319901,
    "AC_T_Pow": 0.0145199,
    "AC_R_output": 0.312,
    "AC_S_output": 0.3432,
    "AC_T_output": 0.37752,
    "dc_current": 0.00029412,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.0408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00004,
    "carbon offset": 0.05,
    "trees planted": 1.111111111,
    "DC_R_output": 0.10296,
    "DC_S_output": 0.113256,
    "DC_T_output": 0.1245816
  },
  {
    "datetime": "10/21/2024 15:15",
    "plant_id": "Green-tek-A26781",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "3:15:00 PM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/21/2024 15:30",
    "plant_id": "Green-tek-A26782",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "3:30:00 PM",
    "power": 0.091,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/21/2024 15:45",
    "plant_id": "Green-tek-A26783",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "3:45:00 PM",
    "power": 0.066,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/21/2024 16:00",
    "plant_id": "Green-tek-A26784",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "4:00:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/21/2024 16:15",
    "plant_id": "Green-tek-A26785",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "4:15:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/21/2024 16:30",
    "plant_id": "Green-tek-A26786",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "4:30:00 PM",
    "power": 0.023,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/21/2024 16:45",
    "plant_id": "Green-tek-A26787",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "4:45:00 PM",
    "power": 0.015,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/21/2024 17:00",
    "plant_id": "Green-tek-A26788",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "5:00:00 PM",
    "power": 0.01,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/21/2024 17:15",
    "plant_id": "Green-tek-A26789",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/21/2024 17:30",
    "plant_id": "Green-tek-A26790",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/21/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/22/2024 6:00",
    "plant_id": "Green-tek-A26791",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/22/2024 6:15",
    "plant_id": "Green-tek-A26792",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/22/2024 6:30",
    "plant_id": "Green-tek-A26793",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/22/2024 6:45",
    "plant_id": "Green-tek-A26794",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/22/2024 7:00",
    "plant_id": "Green-tek-A26795",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/22/2024 7:15",
    "plant_id": "Green-tek-A26796",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/22/2024 7:30",
    "plant_id": "Green-tek-A26797",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/22/2024 7:45",
    "plant_id": "Green-tek-A26798",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "10/22/2024 8:00",
    "plant_id": "Green-tek-A26799",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "8:00:00 AM",
    "power": 0.106,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "10/22/2024 8:15",
    "plant_id": "Green-tek-A26800",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "8:15:00 AM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "10/22/2024 8:30",
    "plant_id": "Green-tek-A26801",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "8:30:00 AM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "10/22/2024 8:45",
    "plant_id": "Green-tek-A26802",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "8:45:00 AM",
    "power": 0.242,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "10/22/2024 9:00",
    "plant_id": "Green-tek-A26803",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "9:00:00 AM",
    "power": 0.306,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "10/22/2024 9:15",
    "plant_id": "Green-tek-A26804",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "9:15:00 AM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "10/22/2024 9:30",
    "plant_id": "Green-tek-A26805",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "9:30:00 AM",
    "power": 0.461,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "10/22/2024 9:45",
    "plant_id": "Green-tek-A26806",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "9:45:00 AM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "10/22/2024 10:00",
    "plant_id": "Green-tek-A26807",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "10:00:00 AM",
    "power": 0.639,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "10/22/2024 10:15",
    "plant_id": "Green-tek-A26808",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "10:15:00 AM",
    "power": 0.729,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "10/22/2024 10:30",
    "plant_id": "Green-tek-A26809",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "10:30:00 AM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "10/22/2024 10:45",
    "plant_id": "Green-tek-A26810",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "10:45:00 AM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "10/22/2024 11:00",
    "plant_id": "Green-tek-A26811",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "11:00:00 AM",
    "power": 0.961,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "10/22/2024 11:15",
    "plant_id": "Green-tek-A26812",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "11:15:00 AM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "10/22/2024 11:30",
    "plant_id": "Green-tek-A26813",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "11:30:00 AM",
    "power": 1.042,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "10/22/2024 11:45",
    "plant_id": "Green-tek-A26814",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "11:45:00 AM",
    "power": 1.053,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "10/22/2024 12:00",
    "plant_id": "Green-tek-A26815",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "12:00:00 PM",
    "power": 1.042,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "10/22/2024 12:15",
    "plant_id": "Green-tek-A26816",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "12:15:00 PM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "10/22/2024 12:30",
    "plant_id": "Green-tek-A26817",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "12:30:00 PM",
    "power": 0.961,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "10/22/2024 12:45",
    "plant_id": "Green-tek-A26818",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "12:45:00 PM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "10/22/2024 13:00",
    "plant_id": "Green-tek-A26819",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "1:00:00 PM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "10/22/2024 13:15",
    "plant_id": "Green-tek-A26820",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "1:15:00 PM",
    "power": 0.729,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "10/22/2024 13:30",
    "plant_id": "Green-tek-A26821",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "1:30:00 PM",
    "power": 0.639,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "10/22/2024 13:45",
    "plant_id": "Green-tek-A26822",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "1:45:00 PM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "10/22/2024 14:00",
    "plant_id": "Green-tek-A26823",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "2:00:00 PM",
    "power": 0.461,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "10/22/2024 14:15",
    "plant_id": "Green-tek-A26824",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "2:15:00 PM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "10/22/2024 14:30",
    "plant_id": "Green-tek-A26825",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "2:30:00 PM",
    "power": 0.306,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "10/22/2024 14:45",
    "plant_id": "Green-tek-A26826",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "2:45:00 PM",
    "power": 0.242,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "10/22/2024 15:00",
    "plant_id": "Green-tek-A26827",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "3:00:00 PM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "10/22/2024 15:15",
    "plant_id": "Green-tek-A26828",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "3:15:00 PM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "10/22/2024 15:30",
    "plant_id": "Green-tek-A26829",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "3:30:00 PM",
    "power": 0.106,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "10/22/2024 15:45",
    "plant_id": "Green-tek-A26830",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "10/22/2024 16:00",
    "plant_id": "Green-tek-A26831",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/22/2024 16:15",
    "plant_id": "Green-tek-A26832",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/22/2024 16:30",
    "plant_id": "Green-tek-A26833",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/22/2024 16:45",
    "plant_id": "Green-tek-A26834",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/22/2024 17:00",
    "plant_id": "Green-tek-A26835",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/22/2024 17:15",
    "plant_id": "Green-tek-A26836",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/22/2024 17:30",
    "plant_id": "Green-tek-A26837",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/22/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/23/2024 6:00",
    "plant_id": "Green-tek-A26838",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/23/2024 6:15",
    "plant_id": "Green-tek-A26839",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "6:15:00 AM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/23/2024 6:30",
    "plant_id": "Green-tek-A26840",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "6:30:00 AM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/23/2024 6:45",
    "plant_id": "Green-tek-A26841",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "6:45:00 AM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/23/2024 7:00",
    "plant_id": "Green-tek-A26842",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "7:00:00 AM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/23/2024 7:15",
    "plant_id": "Green-tek-A26843",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "7:15:00 AM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "10/23/2024 7:30",
    "plant_id": "Green-tek-A26844",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "7:30:00 AM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "10/23/2024 7:45",
    "plant_id": "Green-tek-A26845",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "7:45:00 AM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "10/23/2024 8:00",
    "plant_id": "Green-tek-A26846",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "8:00:00 AM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/23/2024 8:15",
    "plant_id": "Green-tek-A26847",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "8:15:00 AM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/23/2024 8:30",
    "plant_id": "Green-tek-A26848",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "8:30:00 AM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "10/23/2024 8:45",
    "plant_id": "Green-tek-A26849",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "8:45:00 AM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "10/23/2024 9:00",
    "plant_id": "Green-tek-A26850",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "9:00:00 AM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "10/23/2024 9:15",
    "plant_id": "Green-tek-A26851",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "9:15:00 AM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "10/23/2024 9:30",
    "plant_id": "Green-tek-A26852",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "9:30:00 AM",
    "power": 0.426,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "10/23/2024 9:45",
    "plant_id": "Green-tek-A26853",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "9:45:00 AM",
    "power": 0.506,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "10/23/2024 10:00",
    "plant_id": "Green-tek-A26854",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "10:00:00 AM",
    "power": 0.59,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "10/23/2024 10:15",
    "plant_id": "Green-tek-A26855",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "10:15:00 AM",
    "power": 0.674,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "10/23/2024 10:30",
    "plant_id": "Green-tek-A26856",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "10:30:00 AM",
    "power": 0.754,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "10/23/2024 10:45",
    "plant_id": "Green-tek-A26857",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "10:45:00 AM",
    "power": 0.827,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "10/23/2024 11:00",
    "plant_id": "Green-tek-A26858",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "11:00:00 AM",
    "power": 0.888,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "10/23/2024 11:15",
    "plant_id": "Green-tek-A26859",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "11:15:00 AM",
    "power": 0.934,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "10/23/2024 11:30",
    "plant_id": "Green-tek-A26860",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "11:30:00 AM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "10/23/2024 11:45",
    "plant_id": "Green-tek-A26861",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "11:45:00 AM",
    "power": 0.973,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.24582,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "10/23/2024 12:00",
    "plant_id": "Green-tek-A26862",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "12:00:00 PM",
    "power": 0.963,
    "Energy": 0.241,
    "output Energy": 0.241,
    "Ac output": 1.241,
    "dc output": 1.26582,
    "AC_R_i": 0.00031435,
    "AC_S_i": 0.000345785,
    "AC_T_i": 0.00038036,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0723005,
    "AC_S_pow": 0.07953055,
    "AC_T_Pow": 0.0874828,
    "AC_R_output": 0.3723,
    "AC_S_output": 0.40953,
    "AC_T_output": 0.450483,
    "dc_current": 0.00177206,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.24582,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000241,
    "carbon offset": 0.30125,
    "trees planted": 6.694444444,
    "DC_R_output": 0.122859,
    "DC_S_output": 0.1351449,
    "DC_T_output": 0.14865939
  },
  {
    "datetime": "10/23/2024 12:15",
    "plant_id": "Green-tek-A26863",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "12:15:00 PM",
    "power": 0.934,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "10/23/2024 12:30",
    "plant_id": "Green-tek-A26864",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "12:30:00 PM",
    "power": 0.888,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.21114,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "10/23/2024 12:45",
    "plant_id": "Green-tek-A26865",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "12:45:00 PM",
    "power": 0.827,
    "Energy": 0.207,
    "output Energy": 0.207,
    "Ac output": 1.207,
    "dc output": 1.23114,
    "AC_R_i": 0.00027,
    "AC_S_i": 0.000297,
    "AC_T_i": 0.0003267,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0621,
    "AC_S_pow": 0.06831,
    "AC_T_Pow": 0.075141,
    "AC_R_output": 0.3621,
    "AC_S_output": 0.39831,
    "AC_T_output": 0.438141,
    "dc_current": 0.00152206,
    "dc_voltage": 136,
    "dc_power": 0.19278,
    "dc_output": 0.21114,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000207,
    "carbon offset": 0.25875,
    "trees planted": 5.75,
    "DC_R_output": 0.119493,
    "DC_S_output": 0.1314423,
    "DC_T_output": 0.14458653
  },
  {
    "datetime": "10/23/2024 13:00",
    "plant_id": "Green-tek-A26866",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "1:00:00 PM",
    "power": 0.754,
    "Energy": 0.189,
    "output Energy": 0.189,
    "Ac output": 1.189,
    "dc output": 1.21278,
    "AC_R_i": 0.00024652,
    "AC_S_i": 0.000271172,
    "AC_T_i": 0.00029829,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0566996,
    "AC_S_pow": 0.06236956,
    "AC_T_Pow": 0.0686067,
    "AC_R_output": 0.3567,
    "AC_S_output": 0.39237,
    "AC_T_output": 0.431607,
    "dc_current": 0.00138971,
    "dc_voltage": 136,
    "dc_power": 0.17136,
    "dc_output": 0.19278,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000189,
    "carbon offset": 0.23625,
    "trees planted": 5.25,
    "DC_R_output": 0.117711,
    "DC_S_output": 0.1294821,
    "DC_T_output": 0.14243031
  },
  {
    "datetime": "10/23/2024 13:15",
    "plant_id": "Green-tek-A26867",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "1:15:00 PM",
    "power": 0.674,
    "Energy": 0.168,
    "output Energy": 0.168,
    "Ac output": 1.168,
    "dc output": 1.19136,
    "AC_R_i": 0.00021913,
    "AC_S_i": 0.000241043,
    "AC_T_i": 0.00026515,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0503999,
    "AC_S_pow": 0.05543989,
    "AC_T_Pow": 0.0609845,
    "AC_R_output": 0.3504,
    "AC_S_output": 0.38544,
    "AC_T_output": 0.423984,
    "dc_current": 0.00123529,
    "dc_voltage": 136,
    "dc_power": 0.15096,
    "dc_output": 0.17136,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000168,
    "carbon offset": 0.21,
    "trees planted": 4.666666667,
    "DC_R_output": 0.115632,
    "DC_S_output": 0.1271952,
    "DC_T_output": 0.13991472
  },
  {
    "datetime": "10/23/2024 13:30",
    "plant_id": "Green-tek-A26868",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "1:30:00 PM",
    "power": 0.59,
    "Energy": 0.148,
    "output Energy": 0.148,
    "Ac output": 1.148,
    "dc output": 1.17096,
    "AC_R_i": 0.00019304,
    "AC_S_i": 0.000212344,
    "AC_T_i": 0.00023358,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0443992,
    "AC_S_pow": 0.04883912,
    "AC_T_Pow": 0.0537234,
    "AC_R_output": 0.3444,
    "AC_S_output": 0.37884,
    "AC_T_output": 0.416724,
    "dc_current": 0.00108824,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.15096,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000148,
    "carbon offset": 0.185,
    "trees planted": 4.111111111,
    "DC_R_output": 0.113652,
    "DC_S_output": 0.1250172,
    "DC_T_output": 0.13751892
  },
  {
    "datetime": "10/23/2024 13:45",
    "plant_id": "Green-tek-A26869",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "1:45:00 PM",
    "power": 0.506,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.10812,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "10/23/2024 14:00",
    "plant_id": "Green-tek-A26870",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "2:00:00 PM",
    "power": 0.426,
    "Energy": 0.106,
    "output Energy": 0.106,
    "Ac output": 1.106,
    "dc output": 1.12812,
    "AC_R_i": 0.00013826,
    "AC_S_i": 0.000152086,
    "AC_T_i": 0.00016729,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0317998,
    "AC_S_pow": 0.03497978,
    "AC_T_Pow": 0.0384767,
    "AC_R_output": 0.3318,
    "AC_S_output": 0.36498,
    "AC_T_output": 0.401478,
    "dc_current": 0.00077941,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.10812,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000106,
    "carbon offset": 0.1325,
    "trees planted": 2.944444444,
    "DC_R_output": 0.109494,
    "DC_S_output": 0.1204434,
    "DC_T_output": 0.13248774
  },
  {
    "datetime": "10/23/2024 14:15",
    "plant_id": "Green-tek-A26871",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "2:15:00 PM",
    "power": 0.351,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.07242,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "10/23/2024 14:30",
    "plant_id": "Green-tek-A26872",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "2:30:00 PM",
    "power": 0.283,
    "Energy": 0.071,
    "output Energy": 0.071,
    "Ac output": 1.071,
    "dc output": 1.09242,
    "AC_R_i": 0.00009261,
    "AC_S_i": 0.000101871,
    "AC_T_i": 0.00011206,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0213003,
    "AC_S_pow": 0.02343033,
    "AC_T_Pow": 0.0257738,
    "AC_R_output": 0.3213,
    "AC_S_output": 0.35343,
    "AC_T_output": 0.388773,
    "dc_current": 0.00052206,
    "dc_voltage": 136,
    "dc_power": 0.05712,
    "dc_output": 0.07242,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000071,
    "carbon offset": 0.08875,
    "trees planted": 1.972222222,
    "DC_R_output": 0.106029,
    "DC_S_output": 0.1166319,
    "DC_T_output": 0.12829509
  },
  {
    "datetime": "10/23/2024 14:45",
    "plant_id": "Green-tek-A26873",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "2:45:00 PM",
    "power": 0.224,
    "Energy": 0.056,
    "output Energy": 0.056,
    "Ac output": 1.056,
    "dc output": 1.07712,
    "AC_R_i": 0.00007304,
    "AC_S_i": 0.000080344,
    "AC_T_i": 0.00008838,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0167992,
    "AC_S_pow": 0.01847912,
    "AC_T_Pow": 0.0203274,
    "AC_R_output": 0.3168,
    "AC_S_output": 0.34848,
    "AC_T_output": 0.383328,
    "dc_current": 0.00041176,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05712,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000056,
    "carbon offset": 0.07,
    "trees planted": 1.555555556,
    "DC_R_output": 0.104544,
    "DC_S_output": 0.1149984,
    "DC_T_output": 0.12649824
  },
  {
    "datetime": "10/23/2024 15:00",
    "plant_id": "Green-tek-A26874",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "3:00:00 PM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "10/23/2024 15:15",
    "plant_id": "Green-tek-A26875",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "3:15:00 PM",
    "power": 0.132,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/23/2024 15:30",
    "plant_id": "Green-tek-A26876",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "3:30:00 PM",
    "power": 0.098,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01836,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/23/2024 15:45",
    "plant_id": "Green-tek-A26877",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "3:45:00 PM",
    "power": 0.071,
    "Energy": 0.018,
    "output Energy": 0.018,
    "Ac output": 1.018,
    "dc output": 1.03836,
    "AC_R_i": 0.00002348,
    "AC_S_i": 0.000025828,
    "AC_T_i": 0.00002841,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0054004,
    "AC_S_pow": 0.00594044,
    "AC_T_Pow": 0.0065343,
    "AC_R_output": 0.3054,
    "AC_S_output": 0.33594,
    "AC_T_output": 0.369534,
    "dc_current": 0.00013235,
    "dc_voltage": 136,
    "dc_power": 0.01326,
    "dc_output": 0.01836,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000018,
    "carbon offset": 0.0225,
    "trees planted": 0.5,
    "DC_R_output": 0.100782,
    "DC_S_output": 0.1108602,
    "DC_T_output": 0.12194622
  },
  {
    "datetime": "10/23/2024 16:00",
    "plant_id": "Green-tek-A26878",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "4:00:00 PM",
    "power": 0.051,
    "Energy": 0.013,
    "output Energy": 0.013,
    "Ac output": 1.013,
    "dc output": 1.03326,
    "AC_R_i": 0.00001696,
    "AC_S_i": 0.000018656,
    "AC_T_i": 0.00002052,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0039008,
    "AC_S_pow": 0.00429088,
    "AC_T_Pow": 0.0047196,
    "AC_R_output": 0.3039,
    "AC_S_output": 0.33429,
    "AC_T_output": 0.367719,
    "dc_current": 0.00009559,
    "dc_voltage": 136,
    "dc_power": 0.00918,
    "dc_output": 0.01326,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000013,
    "carbon offset": 0.01625,
    "trees planted": 0.361111111,
    "DC_R_output": 0.100287,
    "DC_S_output": 0.1103157,
    "DC_T_output": 0.12134727
  },
  {
    "datetime": "10/23/2024 16:15",
    "plant_id": "Green-tek-A26879",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "4:15:00 PM",
    "power": 0.036,
    "Energy": 0.009,
    "output Energy": 0.009,
    "Ac output": 1.009,
    "dc output": 1.02918,
    "AC_R_i": 0.00001174,
    "AC_S_i": 0.000012914,
    "AC_T_i": 0.00001421,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0027002,
    "AC_S_pow": 0.00297022,
    "AC_T_Pow": 0.0032683,
    "AC_R_output": 0.3027,
    "AC_S_output": 0.33297,
    "AC_T_output": 0.366267,
    "dc_current": 0.00006618,
    "dc_voltage": 136,
    "dc_power": 0.00612,
    "dc_output": 0.00918,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000009,
    "carbon offset": 0.01125,
    "trees planted": 0.25,
    "DC_R_output": 0.099891,
    "DC_S_output": 0.1098801,
    "DC_T_output": 0.12086811
  },
  {
    "datetime": "10/23/2024 16:30",
    "plant_id": "Green-tek-A26880",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "4:30:00 PM",
    "power": 0.024,
    "Energy": 0.006,
    "output Energy": 0.006,
    "Ac output": 1.006,
    "dc output": 1.02612,
    "AC_R_i": 0.00000783,
    "AC_S_i": 0.000008613,
    "AC_T_i": 0.00000947,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0018009,
    "AC_S_pow": 0.00198099,
    "AC_T_Pow": 0.0021781,
    "AC_R_output": 0.3018,
    "AC_S_output": 0.33198,
    "AC_T_output": 0.365178,
    "dc_current": 0.00004412,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00612,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000006,
    "carbon offset": 0.0075,
    "trees planted": 0.166666667,
    "DC_R_output": 0.099594,
    "DC_S_output": 0.1095534,
    "DC_T_output": 0.12050874
  },
  {
    "datetime": "10/23/2024 16:45",
    "plant_id": "Green-tek-A26881",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "4:45:00 PM",
    "power": 0.016,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/23/2024 17:00",
    "plant_id": "Green-tek-A26882",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "5:00:00 PM",
    "power": 0.011,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/23/2024 17:15",
    "plant_id": "Green-tek-A26883",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "5:15:00 PM",
    "power": 0.007,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/23/2024 17:30",
    "plant_id": "Green-tek-A26884",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/23/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/24/2024 6:00",
    "plant_id": "Green-tek-A26885",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/24/2024 6:15",
    "plant_id": "Green-tek-A26886",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/24/2024 6:30",
    "plant_id": "Green-tek-A26887",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/24/2024 6:45",
    "plant_id": "Green-tek-A26888",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/24/2024 7:00",
    "plant_id": "Green-tek-A26889",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "7:00:00 AM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/24/2024 7:15",
    "plant_id": "Green-tek-A26890",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "7:15:00 AM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/24/2024 7:30",
    "plant_id": "Green-tek-A26891",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "7:30:00 AM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/24/2024 7:45",
    "plant_id": "Green-tek-A26892",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "7:45:00 AM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/24/2024 8:00",
    "plant_id": "Green-tek-A26893",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "8:00:00 AM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "10/24/2024 8:15",
    "plant_id": "Green-tek-A26894",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "8:15:00 AM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "10/24/2024 8:30",
    "plant_id": "Green-tek-A26895",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "8:30:00 AM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "10/24/2024 8:45",
    "plant_id": "Green-tek-A26896",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "8:45:00 AM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "10/24/2024 9:00",
    "plant_id": "Green-tek-A26897",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "9:00:00 AM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "10/24/2024 9:15",
    "plant_id": "Green-tek-A26898",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "9:15:00 AM",
    "power": 0.462,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "10/24/2024 9:30",
    "plant_id": "Green-tek-A26899",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "9:30:00 AM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "10/24/2024 9:45",
    "plant_id": "Green-tek-A26900",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "9:45:00 AM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "10/24/2024 10:00",
    "plant_id": "Green-tek-A26901",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "10:00:00 AM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "10/24/2024 10:15",
    "plant_id": "Green-tek-A26902",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "10:15:00 AM",
    "power": 0.887,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "10/24/2024 10:30",
    "plant_id": "Green-tek-A26903",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "10:30:00 AM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "10/24/2024 10:45",
    "plant_id": "Green-tek-A26904",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "10:45:00 AM",
    "power": 1.088,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "10/24/2024 11:00",
    "plant_id": "Green-tek-A26905",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "11:00:00 AM",
    "power": 1.169,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "10/24/2024 11:15",
    "plant_id": "Green-tek-A26906",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "11:15:00 AM",
    "power": 1.23,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "10/24/2024 11:30",
    "plant_id": "Green-tek-A26907",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "11:30:00 AM",
    "power": 1.268,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.3264,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "10/24/2024 11:45",
    "plant_id": "Green-tek-A26908",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "11:45:00 AM",
    "power": 1.281,
    "Energy": 0.32,
    "output Energy": 0.32,
    "Ac output": 1.32,
    "dc output": 1.3464,
    "AC_R_i": 0.00041739,
    "AC_S_i": 0.000459129,
    "AC_T_i": 0.00050504,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0959997,
    "AC_S_pow": 0.10559967,
    "AC_T_Pow": 0.1161592,
    "AC_R_output": 0.396,
    "AC_S_output": 0.4356,
    "AC_T_output": 0.47916,
    "dc_current": 0.00235294,
    "dc_voltage": 136,
    "dc_power": 0.32334,
    "dc_output": 0.3264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00032,
    "carbon offset": 0.4,
    "trees planted": 8.888888889,
    "DC_R_output": 0.13068,
    "DC_S_output": 0.143748,
    "DC_T_output": 0.1581228
  },
  {
    "datetime": "10/24/2024 12:00",
    "plant_id": "Green-tek-A26909",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "12:00:00 PM",
    "power": 1.268,
    "Energy": 0.317,
    "output Energy": 0.317,
    "Ac output": 1.317,
    "dc output": 1.34334,
    "AC_R_i": 0.00041348,
    "AC_S_i": 0.000454828,
    "AC_T_i": 0.00050031,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0951004,
    "AC_S_pow": 0.10461044,
    "AC_T_Pow": 0.1150713,
    "AC_R_output": 0.3951,
    "AC_S_output": 0.43461,
    "AC_T_output": 0.478071,
    "dc_current": 0.00233088,
    "dc_voltage": 136,
    "dc_power": 0.31314,
    "dc_output": 0.32334,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000317,
    "carbon offset": 0.39625,
    "trees planted": 8.805555556,
    "DC_R_output": 0.130383,
    "DC_S_output": 0.1434213,
    "DC_T_output": 0.15776343
  },
  {
    "datetime": "10/24/2024 12:15",
    "plant_id": "Green-tek-A26910",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "12:15:00 PM",
    "power": 1.23,
    "Energy": 0.307,
    "output Energy": 0.307,
    "Ac output": 1.307,
    "dc output": 1.33314,
    "AC_R_i": 0.00040043,
    "AC_S_i": 0.000440473,
    "AC_T_i": 0.00048452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0920989,
    "AC_S_pow": 0.10130879,
    "AC_T_Pow": 0.1114396,
    "AC_R_output": 0.3921,
    "AC_S_output": 0.43131,
    "AC_T_output": 0.474441,
    "dc_current": 0.00225735,
    "dc_voltage": 136,
    "dc_power": 0.29784,
    "dc_output": 0.31314,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000307,
    "carbon offset": 0.38375,
    "trees planted": 8.527777778,
    "DC_R_output": 0.129393,
    "DC_S_output": 0.1423323,
    "DC_T_output": 0.15656553
  },
  {
    "datetime": "10/24/2024 12:30",
    "plant_id": "Green-tek-A26911",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "12:30:00 PM",
    "power": 1.169,
    "Energy": 0.292,
    "output Energy": 0.292,
    "Ac output": 1.292,
    "dc output": 1.31784,
    "AC_R_i": 0.00038087,
    "AC_S_i": 0.000418957,
    "AC_T_i": 0.00046085,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0876001,
    "AC_S_pow": 0.09636011,
    "AC_T_Pow": 0.1059955,
    "AC_R_output": 0.3876,
    "AC_S_output": 0.42636,
    "AC_T_output": 0.468996,
    "dc_current": 0.00214706,
    "dc_voltage": 136,
    "dc_power": 0.27744,
    "dc_output": 0.29784,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000292,
    "carbon offset": 0.365,
    "trees planted": 8.111111111,
    "DC_R_output": 0.127908,
    "DC_S_output": 0.1406988,
    "DC_T_output": 0.15476868
  },
  {
    "datetime": "10/24/2024 12:45",
    "plant_id": "Green-tek-A26912",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "12:45:00 PM",
    "power": 1.088,
    "Energy": 0.272,
    "output Energy": 0.272,
    "Ac output": 1.272,
    "dc output": 1.29744,
    "AC_R_i": 0.00035478,
    "AC_S_i": 0.000390258,
    "AC_T_i": 0.00042928,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0815994,
    "AC_S_pow": 0.08975934,
    "AC_T_Pow": 0.0987344,
    "AC_R_output": 0.3816,
    "AC_S_output": 0.41976,
    "AC_T_output": 0.461736,
    "dc_current": 0.002,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.27744,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000272,
    "carbon offset": 0.34,
    "trees planted": 7.555555556,
    "DC_R_output": 0.125928,
    "DC_S_output": 0.1385208,
    "DC_T_output": 0.15237288
  },
  {
    "datetime": "10/24/2024 13:00",
    "plant_id": "Green-tek-A26913",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "1:00:00 PM",
    "power": 0.993,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.22644,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "10/24/2024 13:15",
    "plant_id": "Green-tek-A26914",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "1:15:00 PM",
    "power": 0.887,
    "Energy": 0.222,
    "output Energy": 0.222,
    "Ac output": 1.222,
    "dc output": 1.24644,
    "AC_R_i": 0.00028957,
    "AC_S_i": 0.000318527,
    "AC_T_i": 0.00035038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0666011,
    "AC_S_pow": 0.07326121,
    "AC_T_Pow": 0.0805874,
    "AC_R_output": 0.3666,
    "AC_S_output": 0.40326,
    "AC_T_output": 0.443586,
    "dc_current": 0.00163235,
    "dc_voltage": 136,
    "dc_power": 0.19788,
    "dc_output": 0.22644,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000222,
    "carbon offset": 0.2775,
    "trees planted": 6.166666667,
    "DC_R_output": 0.120978,
    "DC_S_output": 0.1330758,
    "DC_T_output": 0.14638338
  },
  {
    "datetime": "10/24/2024 13:30",
    "plant_id": "Green-tek-A26915",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "1:30:00 PM",
    "power": 0.777,
    "Energy": 0.194,
    "output Energy": 0.194,
    "Ac output": 1.194,
    "dc output": 1.21788,
    "AC_R_i": 0.00025304,
    "AC_S_i": 0.000278344,
    "AC_T_i": 0.00030618,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0581992,
    "AC_S_pow": 0.06401912,
    "AC_T_Pow": 0.0704214,
    "AC_R_output": 0.3582,
    "AC_S_output": 0.39402,
    "AC_T_output": 0.433422,
    "dc_current": 0.00142647,
    "dc_voltage": 136,
    "dc_power": 0.17034,
    "dc_output": 0.19788,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000194,
    "carbon offset": 0.2425,
    "trees planted": 5.388888889,
    "DC_R_output": 0.118206,
    "DC_S_output": 0.1300266,
    "DC_T_output": 0.14302926
  },
  {
    "datetime": "10/24/2024 13:45",
    "plant_id": "Green-tek-A26916",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "1:45:00 PM",
    "power": 0.667,
    "Energy": 0.167,
    "output Energy": 0.167,
    "Ac output": 1.167,
    "dc output": 1.19034,
    "AC_R_i": 0.00021783,
    "AC_S_i": 0.000239613,
    "AC_T_i": 0.00026357,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0501009,
    "AC_S_pow": 0.05511099,
    "AC_T_Pow": 0.0606211,
    "AC_R_output": 0.3501,
    "AC_S_output": 0.38511,
    "AC_T_output": 0.423621,
    "dc_current": 0.00122794,
    "dc_voltage": 136,
    "dc_power": 0.1428,
    "dc_output": 0.17034,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000167,
    "carbon offset": 0.20875,
    "trees planted": 4.638888889,
    "DC_R_output": 0.115533,
    "DC_S_output": 0.1270863,
    "DC_T_output": 0.13979493
  },
  {
    "datetime": "10/24/2024 14:00",
    "plant_id": "Green-tek-A26917",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "2:00:00 PM",
    "power": 0.561,
    "Energy": 0.14,
    "output Energy": 0.14,
    "Ac output": 1.14,
    "dc output": 1.1628,
    "AC_R_i": 0.00018261,
    "AC_S_i": 0.000200871,
    "AC_T_i": 0.00022096,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0420003,
    "AC_S_pow": 0.04620033,
    "AC_T_Pow": 0.0508208,
    "AC_R_output": 0.342,
    "AC_S_output": 0.3762,
    "AC_T_output": 0.41382,
    "dc_current": 0.00102941,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.1428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00014,
    "carbon offset": 0.175,
    "trees planted": 3.888888889,
    "DC_R_output": 0.11286,
    "DC_S_output": 0.124146,
    "DC_T_output": 0.1365606
  },
  {
    "datetime": "10/24/2024 14:15",
    "plant_id": "Green-tek-A26918",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "2:15:00 PM",
    "power": 0.462,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.09486,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "10/24/2024 14:30",
    "plant_id": "Green-tek-A26919",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "2:30:00 PM",
    "power": 0.373,
    "Energy": 0.093,
    "output Energy": 0.093,
    "Ac output": 1.093,
    "dc output": 1.11486,
    "AC_R_i": 0.0001213,
    "AC_S_i": 0.00013343,
    "AC_T_i": 0.00014677,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.027899,
    "AC_S_pow": 0.0306889,
    "AC_T_Pow": 0.0337571,
    "AC_R_output": 0.3279,
    "AC_S_output": 0.36069,
    "AC_T_output": 0.396759,
    "dc_current": 0.00068382,
    "dc_voltage": 136,
    "dc_power": 0.07548,
    "dc_output": 0.09486,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000093,
    "carbon offset": 0.11625,
    "trees planted": 2.583333333,
    "DC_R_output": 0.108207,
    "DC_S_output": 0.1190277,
    "DC_T_output": 0.13093047
  },
  {
    "datetime": "10/24/2024 14:45",
    "plant_id": "Green-tek-A26920",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "2:45:00 PM",
    "power": 0.295,
    "Energy": 0.074,
    "output Energy": 0.074,
    "Ac output": 1.074,
    "dc output": 1.09548,
    "AC_R_i": 0.00009652,
    "AC_S_i": 0.000106172,
    "AC_T_i": 0.00011679,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0221996,
    "AC_S_pow": 0.02441956,
    "AC_T_Pow": 0.0268617,
    "AC_R_output": 0.3222,
    "AC_S_output": 0.35442,
    "AC_T_output": 0.389862,
    "dc_current": 0.00054412,
    "dc_voltage": 136,
    "dc_power": 0.05814,
    "dc_output": 0.07548,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000074,
    "carbon offset": 0.0925,
    "trees planted": 2.055555556,
    "DC_R_output": 0.106326,
    "DC_S_output": 0.1169586,
    "DC_T_output": 0.12865446
  },
  {
    "datetime": "10/24/2024 15:00",
    "plant_id": "Green-tek-A26921",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "3:00:00 PM",
    "power": 0.228,
    "Energy": 0.057,
    "output Energy": 0.057,
    "Ac output": 1.057,
    "dc output": 1.07814,
    "AC_R_i": 0.00007435,
    "AC_S_i": 0.000081785,
    "AC_T_i": 0.00008996,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0171005,
    "AC_S_pow": 0.01881055,
    "AC_T_Pow": 0.0206908,
    "AC_R_output": 0.3171,
    "AC_S_output": 0.34881,
    "AC_T_output": 0.383691,
    "dc_current": 0.00041912,
    "dc_voltage": 136,
    "dc_power": 0.04386,
    "dc_output": 0.05814,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000057,
    "carbon offset": 0.07125,
    "trees planted": 1.583333333,
    "DC_R_output": 0.104643,
    "DC_S_output": 0.1151073,
    "DC_T_output": 0.12661803
  },
  {
    "datetime": "10/24/2024 15:15",
    "plant_id": "Green-tek-A26922",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "3:15:00 PM",
    "power": 0.173,
    "Energy": 0.043,
    "output Energy": 0.043,
    "Ac output": 1.043,
    "dc output": 1.06386,
    "AC_R_i": 0.00005609,
    "AC_S_i": 0.000061699,
    "AC_T_i": 0.00006787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0129007,
    "AC_S_pow": 0.01419077,
    "AC_T_Pow": 0.0156101,
    "AC_R_output": 0.3129,
    "AC_S_output": 0.34419,
    "AC_T_output": 0.378609,
    "dc_current": 0.00031618,
    "dc_voltage": 136,
    "dc_power": 0.03264,
    "dc_output": 0.04386,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000043,
    "carbon offset": 0.05375,
    "trees planted": 1.194444444,
    "DC_R_output": 0.103257,
    "DC_S_output": 0.1135827,
    "DC_T_output": 0.12494097
  },
  {
    "datetime": "10/24/2024 15:30",
    "plant_id": "Green-tek-A26923",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "3:30:00 PM",
    "power": 0.129,
    "Energy": 0.032,
    "output Energy": 0.032,
    "Ac output": 1.032,
    "dc output": 1.05264,
    "AC_R_i": 0.00004174,
    "AC_S_i": 0.000045914,
    "AC_T_i": 0.00005051,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0096002,
    "AC_S_pow": 0.01056022,
    "AC_T_Pow": 0.0116173,
    "AC_R_output": 0.3096,
    "AC_S_output": 0.34056,
    "AC_T_output": 0.374616,
    "dc_current": 0.00023529,
    "dc_voltage": 136,
    "dc_power": 0.02346,
    "dc_output": 0.03264,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000032,
    "carbon offset": 0.04,
    "trees planted": 0.888888889,
    "DC_R_output": 0.102168,
    "DC_S_output": 0.1123848,
    "DC_T_output": 0.12362328
  },
  {
    "datetime": "10/24/2024 15:45",
    "plant_id": "Green-tek-A26924",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "3:45:00 PM",
    "power": 0.094,
    "Energy": 0.023,
    "output Energy": 0.023,
    "Ac output": 1.023,
    "dc output": 1.04346,
    "AC_R_i": 0.00003,
    "AC_S_i": 0.000033,
    "AC_T_i": 0.0000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0069,
    "AC_S_pow": 0.00759,
    "AC_T_Pow": 0.008349,
    "AC_R_output": 0.3069,
    "AC_S_output": 0.33759,
    "AC_T_output": 0.371349,
    "dc_current": 0.00016912,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000023,
    "carbon offset": 0.02875,
    "trees planted": 0.638888889,
    "DC_R_output": 0.101277,
    "DC_S_output": 0.1114047,
    "DC_T_output": 0.12254517
  },
  {
    "datetime": "10/24/2024 16:00",
    "plant_id": "Green-tek-A26925",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "4:00:00 PM",
    "power": 0.067,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/24/2024 16:15",
    "plant_id": "Green-tek-A26926",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "4:15:00 PM",
    "power": 0.047,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/24/2024 16:30",
    "plant_id": "Green-tek-A26927",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "4:30:00 PM",
    "power": 0.032,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/24/2024 16:45",
    "plant_id": "Green-tek-A26928",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/24/2024 17:00",
    "plant_id": "Green-tek-A26929",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/24/2024 17:15",
    "plant_id": "Green-tek-A26930",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/24/2024 17:30",
    "plant_id": "Green-tek-A26931",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/24/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/25/2024 6:00",
    "plant_id": "Green-tek-A26932",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/25/2024 6:15",
    "plant_id": "Green-tek-A26933",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/25/2024 6:30",
    "plant_id": "Green-tek-A26934",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/25/2024 6:45",
    "plant_id": "Green-tek-A26935",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/25/2024 7:00",
    "plant_id": "Green-tek-A26936",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/25/2024 7:15",
    "plant_id": "Green-tek-A26937",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "7:15:00 AM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/25/2024 7:30",
    "plant_id": "Green-tek-A26938",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "7:30:00 AM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/25/2024 7:45",
    "plant_id": "Green-tek-A26939",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "7:45:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/25/2024 8:00",
    "plant_id": "Green-tek-A26940",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "8:00:00 AM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/25/2024 8:15",
    "plant_id": "Green-tek-A26941",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "8:15:00 AM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "10/25/2024 8:30",
    "plant_id": "Green-tek-A26942",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "8:30:00 AM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "10/25/2024 8:45",
    "plant_id": "Green-tek-A26943",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "8:45:00 AM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "10/25/2024 9:00",
    "plant_id": "Green-tek-A26944",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "9:00:00 AM",
    "power": 0.338,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "10/25/2024 9:15",
    "plant_id": "Green-tek-A26945",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "9:15:00 AM",
    "power": 0.418,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "10/25/2024 9:30",
    "plant_id": "Green-tek-A26946",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "9:30:00 AM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "10/25/2024 9:45",
    "plant_id": "Green-tek-A26947",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "9:45:00 AM",
    "power": 0.604,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "10/25/2024 10:00",
    "plant_id": "Green-tek-A26948",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "10:00:00 AM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "10/25/2024 10:15",
    "plant_id": "Green-tek-A26949",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "10:15:00 AM",
    "power": 0.804,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "10/25/2024 10:30",
    "plant_id": "Green-tek-A26950",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "10:30:00 AM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "10/25/2024 10:45",
    "plant_id": "Green-tek-A26951",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "10:45:00 AM",
    "power": 0.986,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "10/25/2024 11:00",
    "plant_id": "Green-tek-A26952",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "11:00:00 AM",
    "power": 1.059,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "10/25/2024 11:15",
    "plant_id": "Green-tek-A26953",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "11:15:00 AM",
    "power": 1.114,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "10/25/2024 11:30",
    "plant_id": "Green-tek-A26954",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "11:30:00 AM",
    "power": 1.149,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "10/25/2024 11:45",
    "plant_id": "Green-tek-A26955",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "11:45:00 AM",
    "power": 1.16,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.29274,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "10/25/2024 12:00",
    "plant_id": "Green-tek-A26956",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "12:00:00 PM",
    "power": 1.149,
    "Energy": 0.287,
    "output Energy": 0.287,
    "Ac output": 1.287,
    "dc output": 1.31274,
    "AC_R_i": 0.00037435,
    "AC_S_i": 0.000411785,
    "AC_T_i": 0.00045296,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0861005,
    "AC_S_pow": 0.09471055,
    "AC_T_Pow": 0.1041808,
    "AC_R_output": 0.3861,
    "AC_S_output": 0.42471,
    "AC_T_output": 0.467181,
    "dc_current": 0.00211029,
    "dc_voltage": 136,
    "dc_power": 0.28356,
    "dc_output": 0.29274,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000287,
    "carbon offset": 0.35875,
    "trees planted": 7.972222222,
    "DC_R_output": 0.127413,
    "DC_S_output": 0.1401543,
    "DC_T_output": 0.15416973
  },
  {
    "datetime": "10/25/2024 12:15",
    "plant_id": "Green-tek-A26957",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "12:15:00 PM",
    "power": 1.114,
    "Energy": 0.278,
    "output Energy": 0.278,
    "Ac output": 1.278,
    "dc output": 1.30356,
    "AC_R_i": 0.00036261,
    "AC_S_i": 0.000398871,
    "AC_T_i": 0.00043876,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0834003,
    "AC_S_pow": 0.09174033,
    "AC_T_Pow": 0.1009148,
    "AC_R_output": 0.3834,
    "AC_S_output": 0.42174,
    "AC_T_output": 0.463914,
    "dc_current": 0.00204412,
    "dc_voltage": 136,
    "dc_power": 0.2703,
    "dc_output": 0.28356,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000278,
    "carbon offset": 0.3475,
    "trees planted": 7.722222222,
    "DC_R_output": 0.126522,
    "DC_S_output": 0.1391742,
    "DC_T_output": 0.15309162
  },
  {
    "datetime": "10/25/2024 12:30",
    "plant_id": "Green-tek-A26958",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "12:30:00 PM",
    "power": 1.059,
    "Energy": 0.265,
    "output Energy": 0.265,
    "Ac output": 1.265,
    "dc output": 1.2903,
    "AC_R_i": 0.00034565,
    "AC_S_i": 0.000380215,
    "AC_T_i": 0.00041824,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0794995,
    "AC_S_pow": 0.08744945,
    "AC_T_Pow": 0.0961952,
    "AC_R_output": 0.3795,
    "AC_S_output": 0.41745,
    "AC_T_output": 0.459195,
    "dc_current": 0.00194853,
    "dc_voltage": 136,
    "dc_power": 0.25092,
    "dc_output": 0.2703,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000265,
    "carbon offset": 0.33125,
    "trees planted": 7.361111111,
    "DC_R_output": 0.125235,
    "DC_S_output": 0.1377585,
    "DC_T_output": 0.15153435
  },
  {
    "datetime": "10/25/2024 12:45",
    "plant_id": "Green-tek-A26959",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "12:45:00 PM",
    "power": 0.986,
    "Energy": 0.246,
    "output Energy": 0.246,
    "Ac output": 1.246,
    "dc output": 1.27092,
    "AC_R_i": 0.00032087,
    "AC_S_i": 0.000352957,
    "AC_T_i": 0.00038825,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0738001,
    "AC_S_pow": 0.08118011,
    "AC_T_Pow": 0.0892975,
    "AC_R_output": 0.3738,
    "AC_S_output": 0.41118,
    "AC_T_output": 0.452298,
    "dc_current": 0.00180882,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25092,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000246,
    "carbon offset": 0.3075,
    "trees planted": 6.833333333,
    "DC_R_output": 0.123354,
    "DC_S_output": 0.1356894,
    "DC_T_output": 0.14925834
  },
  {
    "datetime": "10/25/2024 13:00",
    "plant_id": "Green-tek-A26960",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "1:00:00 PM",
    "power": 0.899,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20502,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "10/25/2024 13:15",
    "plant_id": "Green-tek-A26961",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "1:15:00 PM",
    "power": 0.804,
    "Energy": 0.201,
    "output Energy": 0.201,
    "Ac output": 1.201,
    "dc output": 1.22502,
    "AC_R_i": 0.00026217,
    "AC_S_i": 0.000288387,
    "AC_T_i": 0.00031723,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0602991,
    "AC_S_pow": 0.06632901,
    "AC_T_Pow": 0.0729629,
    "AC_R_output": 0.3603,
    "AC_S_output": 0.39633,
    "AC_T_output": 0.435963,
    "dc_current": 0.00147794,
    "dc_voltage": 136,
    "dc_power": 0.17952,
    "dc_output": 0.20502,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000201,
    "carbon offset": 0.25125,
    "trees planted": 5.583333333,
    "DC_R_output": 0.118899,
    "DC_S_output": 0.1307889,
    "DC_T_output": 0.14386779
  },
  {
    "datetime": "10/25/2024 13:30",
    "plant_id": "Green-tek-A26962",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "1:30:00 PM",
    "power": 0.704,
    "Energy": 0.176,
    "output Energy": 0.176,
    "Ac output": 1.176,
    "dc output": 1.19952,
    "AC_R_i": 0.00022957,
    "AC_S_i": 0.000252527,
    "AC_T_i": 0.00027778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0528011,
    "AC_S_pow": 0.05808121,
    "AC_T_Pow": 0.0638894,
    "AC_R_output": 0.3528,
    "AC_S_output": 0.38808,
    "AC_T_output": 0.426888,
    "dc_current": 0.00129412,
    "dc_voltage": 136,
    "dc_power": 0.15402,
    "dc_output": 0.17952,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000176,
    "carbon offset": 0.22,
    "trees planted": 4.888888889,
    "DC_R_output": 0.116424,
    "DC_S_output": 0.1280664,
    "DC_T_output": 0.14087304
  },
  {
    "datetime": "10/25/2024 13:45",
    "plant_id": "Green-tek-A26963",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "1:45:00 PM",
    "power": 0.604,
    "Energy": 0.151,
    "output Energy": 0.151,
    "Ac output": 1.151,
    "dc output": 1.17402,
    "AC_R_i": 0.00019696,
    "AC_S_i": 0.000216656,
    "AC_T_i": 0.00023832,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0453008,
    "AC_S_pow": 0.04983088,
    "AC_T_Pow": 0.0548136,
    "AC_R_output": 0.3453,
    "AC_S_output": 0.37983,
    "AC_T_output": 0.417813,
    "dc_current": 0.00111029,
    "dc_voltage": 136,
    "dc_power": 0.12954,
    "dc_output": 0.15402,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000151,
    "carbon offset": 0.18875,
    "trees planted": 4.194444444,
    "DC_R_output": 0.113949,
    "DC_S_output": 0.1253439,
    "DC_T_output": 0.13787829
  },
  {
    "datetime": "10/25/2024 14:00",
    "plant_id": "Green-tek-A26964",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "2:00:00 PM",
    "power": 0.508,
    "Energy": 0.127,
    "output Energy": 0.127,
    "Ac output": 1.127,
    "dc output": 1.14954,
    "AC_R_i": 0.00016565,
    "AC_S_i": 0.000182215,
    "AC_T_i": 0.00020044,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0380995,
    "AC_S_pow": 0.04190945,
    "AC_T_Pow": 0.0461012,
    "AC_R_output": 0.3381,
    "AC_S_output": 0.37191,
    "AC_T_output": 0.409101,
    "dc_current": 0.00093382,
    "dc_voltage": 136,
    "dc_power": 0.1071,
    "dc_output": 0.12954,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000127,
    "carbon offset": 0.15875,
    "trees planted": 3.527777778,
    "DC_R_output": 0.111573,
    "DC_S_output": 0.1227303,
    "DC_T_output": 0.13500333
  },
  {
    "datetime": "10/25/2024 14:15",
    "plant_id": "Green-tek-A26965",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "2:15:00 PM",
    "power": 0.418,
    "Energy": 0.105,
    "output Energy": 0.105,
    "Ac output": 1.105,
    "dc output": 1.1271,
    "AC_R_i": 0.00013696,
    "AC_S_i": 0.000150656,
    "AC_T_i": 0.00016572,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0315008,
    "AC_S_pow": 0.03465088,
    "AC_T_Pow": 0.0381156,
    "AC_R_output": 0.3315,
    "AC_S_output": 0.36465,
    "AC_T_output": 0.401115,
    "dc_current": 0.00077206,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.1071,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000105,
    "carbon offset": 0.13125,
    "trees planted": 2.916666667,
    "DC_R_output": 0.109395,
    "DC_S_output": 0.1203345,
    "DC_T_output": 0.13236795
  },
  {
    "datetime": "10/25/2024 14:30",
    "plant_id": "Green-tek-A26966",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "2:30:00 PM",
    "power": 0.338,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06834,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "10/25/2024 14:45",
    "plant_id": "Green-tek-A26967",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "2:45:00 PM",
    "power": 0.267,
    "Energy": 0.067,
    "output Energy": 0.067,
    "Ac output": 1.067,
    "dc output": 1.08834,
    "AC_R_i": 0.00008739,
    "AC_S_i": 0.000096129,
    "AC_T_i": 0.00010574,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0200997,
    "AC_S_pow": 0.02210967,
    "AC_T_Pow": 0.0243202,
    "AC_R_output": 0.3201,
    "AC_S_output": 0.35211,
    "AC_T_output": 0.387321,
    "dc_current": 0.00049265,
    "dc_voltage": 136,
    "dc_power": 0.05304,
    "dc_output": 0.06834,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000067,
    "carbon offset": 0.08375,
    "trees planted": 1.861111111,
    "DC_R_output": 0.105633,
    "DC_S_output": 0.1161963,
    "DC_T_output": 0.12781593
  },
  {
    "datetime": "10/25/2024 15:00",
    "plant_id": "Green-tek-A26968",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "3:00:00 PM",
    "power": 0.207,
    "Energy": 0.052,
    "output Energy": 0.052,
    "Ac output": 1.052,
    "dc output": 1.07304,
    "AC_R_i": 0.00006783,
    "AC_S_i": 0.000074613,
    "AC_T_i": 0.00008207,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0156009,
    "AC_S_pow": 0.01716099,
    "AC_T_Pow": 0.0188761,
    "AC_R_output": 0.3156,
    "AC_S_output": 0.34716,
    "AC_T_output": 0.381876,
    "dc_current": 0.00038235,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05304,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000052,
    "carbon offset": 0.065,
    "trees planted": 1.444444444,
    "DC_R_output": 0.104148,
    "DC_S_output": 0.1145628,
    "DC_T_output": 0.12601908
  },
  {
    "datetime": "10/25/2024 15:15",
    "plant_id": "Green-tek-A26969",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "3:15:00 PM",
    "power": 0.157,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "10/25/2024 15:30",
    "plant_id": "Green-tek-A26970",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "3:30:00 PM",
    "power": 0.117,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/25/2024 15:45",
    "plant_id": "Green-tek-A26971",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "3:45:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/25/2024 16:00",
    "plant_id": "Green-tek-A26972",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "4:00:00 PM",
    "power": 0.061,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/25/2024 16:15",
    "plant_id": "Green-tek-A26973",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "4:15:00 PM",
    "power": 0.043,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/25/2024 16:30",
    "plant_id": "Green-tek-A26974",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/25/2024 16:45",
    "plant_id": "Green-tek-A26975",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/25/2024 17:00",
    "plant_id": "Green-tek-A26976",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/25/2024 17:15",
    "plant_id": "Green-tek-A26977",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/25/2024 17:30",
    "plant_id": "Green-tek-A26978",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/25/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/26/2024 6:00",
    "plant_id": "Green-tek-A26979",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/26/2024 6:15",
    "plant_id": "Green-tek-A26980",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/26/2024 6:30",
    "plant_id": "Green-tek-A26981",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/26/2024 6:45",
    "plant_id": "Green-tek-A26982",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "6:45:00 AM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/26/2024 7:00",
    "plant_id": "Green-tek-A26983",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "7:00:00 AM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/26/2024 7:15",
    "plant_id": "Green-tek-A26984",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "7:15:00 AM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/26/2024 7:30",
    "plant_id": "Green-tek-A26985",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "7:30:00 AM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/26/2024 7:45",
    "plant_id": "Green-tek-A26986",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "7:45:00 AM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/26/2024 8:00",
    "plant_id": "Green-tek-A26987",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "8:00:00 AM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/26/2024 8:15",
    "plant_id": "Green-tek-A26988",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "8:15:00 AM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "10/26/2024 8:30",
    "plant_id": "Green-tek-A26989",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "8:30:00 AM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/26/2024 8:45",
    "plant_id": "Green-tek-A26990",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "8:45:00 AM",
    "power": 0.265,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "10/26/2024 9:00",
    "plant_id": "Green-tek-A26991",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "9:00:00 AM",
    "power": 0.335,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "10/26/2024 9:15",
    "plant_id": "Green-tek-A26992",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "9:15:00 AM",
    "power": 0.415,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "10/26/2024 9:30",
    "plant_id": "Green-tek-A26993",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "9:30:00 AM",
    "power": 0.503,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "10/26/2024 9:45",
    "plant_id": "Green-tek-A26994",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "9:45:00 AM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "10/26/2024 10:00",
    "plant_id": "Green-tek-A26995",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "10:00:00 AM",
    "power": 0.698,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "10/26/2024 10:15",
    "plant_id": "Green-tek-A26996",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "10:15:00 AM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "10/26/2024 10:30",
    "plant_id": "Green-tek-A26997",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "10:30:00 AM",
    "power": 0.892,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "10/26/2024 10:45",
    "plant_id": "Green-tek-A26998",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "10:45:00 AM",
    "power": 0.977,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "10/26/2024 11:00",
    "plant_id": "Green-tek-A26999",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "11:00:00 AM",
    "power": 1.05,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "10/26/2024 11:15",
    "plant_id": "Green-tek-A27000",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "11:15:00 AM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "10/26/2024 11:30",
    "plant_id": "Green-tek-A27001",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "11:30:00 AM",
    "power": 1.139,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.29376,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "10/26/2024 11:45",
    "plant_id": "Green-tek-A27002",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "11:45:00 AM",
    "power": 1.151,
    "Energy": 0.288,
    "output Energy": 0.288,
    "Ac output": 1.288,
    "dc output": 1.31376,
    "AC_R_i": 0.00037565,
    "AC_S_i": 0.000413215,
    "AC_T_i": 0.00045454,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0863995,
    "AC_S_pow": 0.09503945,
    "AC_T_Pow": 0.1045442,
    "AC_R_output": 0.3864,
    "AC_S_output": 0.42504,
    "AC_T_output": 0.467544,
    "dc_current": 0.00211765,
    "dc_voltage": 136,
    "dc_power": 0.2907,
    "dc_output": 0.29376,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000288,
    "carbon offset": 0.36,
    "trees planted": 8,
    "DC_R_output": 0.127512,
    "DC_S_output": 0.1402632,
    "DC_T_output": 0.15428952
  },
  {
    "datetime": "10/26/2024 12:00",
    "plant_id": "Green-tek-A27003",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "12:00:00 PM",
    "power": 1.139,
    "Energy": 0.285,
    "output Energy": 0.285,
    "Ac output": 1.285,
    "dc output": 1.3107,
    "AC_R_i": 0.00037174,
    "AC_S_i": 0.000408914,
    "AC_T_i": 0.00044981,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0855002,
    "AC_S_pow": 0.09405022,
    "AC_T_Pow": 0.1034563,
    "AC_R_output": 0.3855,
    "AC_S_output": 0.42405,
    "AC_T_output": 0.466455,
    "dc_current": 0.00209559,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.2907,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000285,
    "carbon offset": 0.35625,
    "trees planted": 7.916666667,
    "DC_R_output": 0.127215,
    "DC_S_output": 0.1399365,
    "DC_T_output": 0.15393015
  },
  {
    "datetime": "10/26/2024 12:15",
    "plant_id": "Green-tek-A27004",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "12:15:00 PM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.26724,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "10/26/2024 12:30",
    "plant_id": "Green-tek-A27005",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "12:30:00 PM",
    "power": 1.05,
    "Energy": 0.262,
    "output Energy": 0.262,
    "Ac output": 1.262,
    "dc output": 1.28724,
    "AC_R_i": 0.00034174,
    "AC_S_i": 0.000375914,
    "AC_T_i": 0.00041351,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0786002,
    "AC_S_pow": 0.08646022,
    "AC_T_Pow": 0.0951073,
    "AC_R_output": 0.3786,
    "AC_S_output": 0.41646,
    "AC_T_output": 0.458106,
    "dc_current": 0.00192647,
    "dc_voltage": 136,
    "dc_power": 0.24888,
    "dc_output": 0.26724,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000262,
    "carbon offset": 0.3275,
    "trees planted": 7.277777778,
    "DC_R_output": 0.124938,
    "DC_S_output": 0.1374318,
    "DC_T_output": 0.15117498
  },
  {
    "datetime": "10/26/2024 12:45",
    "plant_id": "Green-tek-A27006",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "12:45:00 PM",
    "power": 0.977,
    "Energy": 0.244,
    "output Energy": 0.244,
    "Ac output": 1.244,
    "dc output": 1.26888,
    "AC_R_i": 0.00031826,
    "AC_S_i": 0.000350086,
    "AC_T_i": 0.00038509,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0731998,
    "AC_S_pow": 0.08051978,
    "AC_T_Pow": 0.0885707,
    "AC_R_output": 0.3732,
    "AC_S_output": 0.41052,
    "AC_T_output": 0.451572,
    "dc_current": 0.00179412,
    "dc_voltage": 136,
    "dc_power": 0.22746,
    "dc_output": 0.24888,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000244,
    "carbon offset": 0.305,
    "trees planted": 6.777777778,
    "DC_R_output": 0.123156,
    "DC_S_output": 0.1354716,
    "DC_T_output": 0.14901876
  },
  {
    "datetime": "10/26/2024 13:00",
    "plant_id": "Green-tek-A27007",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "1:00:00 PM",
    "power": 0.892,
    "Energy": 0.223,
    "output Energy": 0.223,
    "Ac output": 1.223,
    "dc output": 1.24746,
    "AC_R_i": 0.00029087,
    "AC_S_i": 0.000319957,
    "AC_T_i": 0.00035195,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0669001,
    "AC_S_pow": 0.07359011,
    "AC_T_Pow": 0.0809485,
    "AC_R_output": 0.3669,
    "AC_S_output": 0.40359,
    "AC_T_output": 0.443949,
    "dc_current": 0.00163971,
    "dc_voltage": 136,
    "dc_power": 0.20298,
    "dc_output": 0.22746,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000223,
    "carbon offset": 0.27875,
    "trees planted": 6.194444444,
    "DC_R_output": 0.121077,
    "DC_S_output": 0.1331847,
    "DC_T_output": 0.14650317
  },
  {
    "datetime": "10/26/2024 13:15",
    "plant_id": "Green-tek-A27008",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "1:15:00 PM",
    "power": 0.797,
    "Energy": 0.199,
    "output Energy": 0.199,
    "Ac output": 1.199,
    "dc output": 1.22298,
    "AC_R_i": 0.00025957,
    "AC_S_i": 0.000285527,
    "AC_T_i": 0.00031408,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0597011,
    "AC_S_pow": 0.06567121,
    "AC_T_Pow": 0.0722384,
    "AC_R_output": 0.3597,
    "AC_S_output": 0.39567,
    "AC_T_output": 0.435237,
    "dc_current": 0.00146324,
    "dc_voltage": 136,
    "dc_power": 0.17748,
    "dc_output": 0.20298,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000199,
    "carbon offset": 0.24875,
    "trees planted": 5.527777778,
    "DC_R_output": 0.118701,
    "DC_S_output": 0.1305711,
    "DC_T_output": 0.14362821
  },
  {
    "datetime": "10/26/2024 13:30",
    "plant_id": "Green-tek-A27009",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "1:30:00 PM",
    "power": 0.698,
    "Energy": 0.174,
    "output Energy": 0.174,
    "Ac output": 1.174,
    "dc output": 1.19748,
    "AC_R_i": 0.00022696,
    "AC_S_i": 0.000249656,
    "AC_T_i": 0.00027462,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0522008,
    "AC_S_pow": 0.05742088,
    "AC_T_Pow": 0.0631626,
    "AC_R_output": 0.3522,
    "AC_S_output": 0.38742,
    "AC_T_output": 0.426162,
    "dc_current": 0.00127941,
    "dc_voltage": 136,
    "dc_power": 0.153,
    "dc_output": 0.17748,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000174,
    "carbon offset": 0.2175,
    "trees planted": 4.833333333,
    "DC_R_output": 0.116226,
    "DC_S_output": 0.1278486,
    "DC_T_output": 0.14063346
  },
  {
    "datetime": "10/26/2024 13:45",
    "plant_id": "Green-tek-A27010",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "1:45:00 PM",
    "power": 0.599,
    "Energy": 0.15,
    "output Energy": 0.15,
    "Ac output": 1.15,
    "dc output": 1.173,
    "AC_R_i": 0.00019565,
    "AC_S_i": 0.000215215,
    "AC_T_i": 0.00023674,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0449995,
    "AC_S_pow": 0.04949945,
    "AC_T_Pow": 0.0544502,
    "AC_R_output": 0.345,
    "AC_S_output": 0.3795,
    "AC_T_output": 0.41745,
    "dc_current": 0.00110294,
    "dc_voltage": 136,
    "dc_power": 0.12852,
    "dc_output": 0.153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00015,
    "carbon offset": 0.1875,
    "trees planted": 4.166666667,
    "DC_R_output": 0.11385,
    "DC_S_output": 0.125235,
    "DC_T_output": 0.1377585
  },
  {
    "datetime": "10/26/2024 14:00",
    "plant_id": "Green-tek-A27011",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "2:00:00 PM",
    "power": 0.503,
    "Energy": 0.126,
    "output Energy": 0.126,
    "Ac output": 1.126,
    "dc output": 1.14852,
    "AC_R_i": 0.00016435,
    "AC_S_i": 0.000180785,
    "AC_T_i": 0.00019886,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0378005,
    "AC_S_pow": 0.04158055,
    "AC_T_Pow": 0.0457378,
    "AC_R_output": 0.3378,
    "AC_S_output": 0.37158,
    "AC_T_output": 0.408738,
    "dc_current": 0.00092647,
    "dc_voltage": 136,
    "dc_power": 0.10608,
    "dc_output": 0.12852,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000126,
    "carbon offset": 0.1575,
    "trees planted": 3.5,
    "DC_R_output": 0.111474,
    "DC_S_output": 0.1226214,
    "DC_T_output": 0.13488354
  },
  {
    "datetime": "10/26/2024 14:15",
    "plant_id": "Green-tek-A27012",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "2:15:00 PM",
    "power": 0.415,
    "Energy": 0.104,
    "output Energy": 0.104,
    "Ac output": 1.104,
    "dc output": 1.12608,
    "AC_R_i": 0.00013565,
    "AC_S_i": 0.000149215,
    "AC_T_i": 0.00016414,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0311995,
    "AC_S_pow": 0.03431945,
    "AC_T_Pow": 0.0377522,
    "AC_R_output": 0.3312,
    "AC_S_output": 0.36432,
    "AC_T_output": 0.400752,
    "dc_current": 0.00076471,
    "dc_voltage": 136,
    "dc_power": 0.08568,
    "dc_output": 0.10608,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000104,
    "carbon offset": 0.13,
    "trees planted": 2.888888889,
    "DC_R_output": 0.109296,
    "DC_S_output": 0.1202256,
    "DC_T_output": 0.13224816
  },
  {
    "datetime": "10/26/2024 14:30",
    "plant_id": "Green-tek-A27013",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "2:30:00 PM",
    "power": 0.335,
    "Energy": 0.084,
    "output Energy": 0.084,
    "Ac output": 1.084,
    "dc output": 1.10568,
    "AC_R_i": 0.00010957,
    "AC_S_i": 0.000120527,
    "AC_T_i": 0.00013258,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0252011,
    "AC_S_pow": 0.02772121,
    "AC_T_Pow": 0.0304934,
    "AC_R_output": 0.3252,
    "AC_S_output": 0.35772,
    "AC_T_output": 0.393492,
    "dc_current": 0.00061765,
    "dc_voltage": 136,
    "dc_power": 0.06732,
    "dc_output": 0.08568,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000084,
    "carbon offset": 0.105,
    "trees planted": 2.333333333,
    "DC_R_output": 0.107316,
    "DC_S_output": 0.1180476,
    "DC_T_output": 0.12985236
  },
  {
    "datetime": "10/26/2024 14:45",
    "plant_id": "Green-tek-A27014",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "2:45:00 PM",
    "power": 0.265,
    "Energy": 0.066,
    "output Energy": 0.066,
    "Ac output": 1.066,
    "dc output": 1.08732,
    "AC_R_i": 0.00008609,
    "AC_S_i": 0.000094699,
    "AC_T_i": 0.00010417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0198007,
    "AC_S_pow": 0.02178077,
    "AC_T_Pow": 0.0239591,
    "AC_R_output": 0.3198,
    "AC_S_output": 0.35178,
    "AC_T_output": 0.386958,
    "dc_current": 0.00048529,
    "dc_voltage": 136,
    "dc_power": 0.05202,
    "dc_output": 0.06732,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000066,
    "carbon offset": 0.0825,
    "trees planted": 1.833333333,
    "DC_R_output": 0.105534,
    "DC_S_output": 0.1160874,
    "DC_T_output": 0.12769614
  },
  {
    "datetime": "10/26/2024 15:00",
    "plant_id": "Green-tek-A27015",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "3:00:00 PM",
    "power": 0.205,
    "Energy": 0.051,
    "output Energy": 0.051,
    "Ac output": 1.051,
    "dc output": 1.07202,
    "AC_R_i": 0.00006652,
    "AC_S_i": 0.000073172,
    "AC_T_i": 0.00008049,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0152996,
    "AC_S_pow": 0.01682956,
    "AC_T_Pow": 0.0185127,
    "AC_R_output": 0.3153,
    "AC_S_output": 0.34683,
    "AC_T_output": 0.381513,
    "dc_current": 0.000375,
    "dc_voltage": 136,
    "dc_power": 0.03978,
    "dc_output": 0.05202,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000051,
    "carbon offset": 0.06375,
    "trees planted": 1.416666667,
    "DC_R_output": 0.104049,
    "DC_S_output": 0.1144539,
    "DC_T_output": 0.12589929
  },
  {
    "datetime": "10/26/2024 15:15",
    "plant_id": "Green-tek-A27016",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "3:15:00 PM",
    "power": 0.156,
    "Energy": 0.039,
    "output Energy": 0.039,
    "Ac output": 1.039,
    "dc output": 1.05978,
    "AC_R_i": 0.00005087,
    "AC_S_i": 0.000055957,
    "AC_T_i": 0.00006155,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0117001,
    "AC_S_pow": 0.01287011,
    "AC_T_Pow": 0.0141565,
    "AC_R_output": 0.3117,
    "AC_S_output": 0.34287,
    "AC_T_output": 0.377157,
    "dc_current": 0.00028676,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03978,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000039,
    "carbon offset": 0.04875,
    "trees planted": 1.083333333,
    "DC_R_output": 0.102861,
    "DC_S_output": 0.1131471,
    "DC_T_output": 0.12446181
  },
  {
    "datetime": "10/26/2024 15:30",
    "plant_id": "Green-tek-A27017",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "3:30:00 PM",
    "power": 0.116,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/26/2024 15:45",
    "plant_id": "Green-tek-A27018",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "3:45:00 PM",
    "power": 0.084,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/26/2024 16:00",
    "plant_id": "Green-tek-A27019",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "4:00:00 PM",
    "power": 0.06,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/26/2024 16:15",
    "plant_id": "Green-tek-A27020",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "4:15:00 PM",
    "power": 0.042,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/26/2024 16:30",
    "plant_id": "Green-tek-A27021",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "4:30:00 PM",
    "power": 0.029,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/26/2024 16:45",
    "plant_id": "Green-tek-A27022",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "4:45:00 PM",
    "power": 0.019,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/26/2024 17:00",
    "plant_id": "Green-tek-A27023",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/26/2024 17:15",
    "plant_id": "Green-tek-A27024",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/26/2024 17:30",
    "plant_id": "Green-tek-A27025",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/26/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/27/2024 6:00",
    "plant_id": "Green-tek-A27026",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/27/2024 6:15",
    "plant_id": "Green-tek-A27027",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/27/2024 6:30",
    "plant_id": "Green-tek-A27028",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/27/2024 6:45",
    "plant_id": "Green-tek-A27029",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/27/2024 7:00",
    "plant_id": "Green-tek-A27030",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/27/2024 7:15",
    "plant_id": "Green-tek-A27031",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/27/2024 7:30",
    "plant_id": "Green-tek-A27032",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "7:30:00 AM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/27/2024 7:45",
    "plant_id": "Green-tek-A27033",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "7:45:00 AM",
    "power": 0.078,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "10/27/2024 8:00",
    "plant_id": "Green-tek-A27034",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "8:00:00 AM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "10/27/2024 8:15",
    "plant_id": "Green-tek-A27035",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "8:15:00 AM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "10/27/2024 8:30",
    "plant_id": "Green-tek-A27036",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "8:30:00 AM",
    "power": 0.19,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "10/27/2024 8:45",
    "plant_id": "Green-tek-A27037",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "8:45:00 AM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "10/27/2024 9:00",
    "plant_id": "Green-tek-A27038",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "9:00:00 AM",
    "power": 0.31,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "10/27/2024 9:15",
    "plant_id": "Green-tek-A27039",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "9:15:00 AM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "10/27/2024 9:30",
    "plant_id": "Green-tek-A27040",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "9:30:00 AM",
    "power": 0.466,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "10/27/2024 9:45",
    "plant_id": "Green-tek-A27041",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "9:45:00 AM",
    "power": 0.554,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "10/27/2024 10:00",
    "plant_id": "Green-tek-A27042",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "10:00:00 AM",
    "power": 0.645,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "10/27/2024 10:15",
    "plant_id": "Green-tek-A27043",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "10:15:00 AM",
    "power": 0.737,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "10/27/2024 10:30",
    "plant_id": "Green-tek-A27044",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "10:30:00 AM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "10/27/2024 10:45",
    "plant_id": "Green-tek-A27045",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "10:45:00 AM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "10/27/2024 11:00",
    "plant_id": "Green-tek-A27046",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "11:00:00 AM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "10/27/2024 11:15",
    "plant_id": "Green-tek-A27047",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "11:15:00 AM",
    "power": 1.022,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "10/27/2024 11:30",
    "plant_id": "Green-tek-A27048",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "11:30:00 AM",
    "power": 1.053,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.27132,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "10/27/2024 11:45",
    "plant_id": "Green-tek-A27049",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "11:45:00 AM",
    "power": 1.064,
    "Energy": 0.266,
    "output Energy": 0.266,
    "Ac output": 1.266,
    "dc output": 1.29132,
    "AC_R_i": 0.00034696,
    "AC_S_i": 0.000381656,
    "AC_T_i": 0.00041982,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0798008,
    "AC_S_pow": 0.08778088,
    "AC_T_Pow": 0.0965586,
    "AC_R_output": 0.3798,
    "AC_S_output": 0.41778,
    "AC_T_output": 0.459558,
    "dc_current": 0.00195588,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.27132,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000266,
    "carbon offset": 0.3325,
    "trees planted": 7.388888889,
    "DC_R_output": 0.125334,
    "DC_S_output": 0.1378674,
    "DC_T_output": 0.15165414
  },
  {
    "datetime": "10/27/2024 12:00",
    "plant_id": "Green-tek-A27050",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "12:00:00 PM",
    "power": 1.053,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.2601,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "10/27/2024 12:15",
    "plant_id": "Green-tek-A27051",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "12:15:00 PM",
    "power": 1.022,
    "Energy": 0.255,
    "output Energy": 0.255,
    "Ac output": 1.255,
    "dc output": 1.2801,
    "AC_R_i": 0.00033261,
    "AC_S_i": 0.000365871,
    "AC_T_i": 0.00040246,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0765003,
    "AC_S_pow": 0.08415033,
    "AC_T_Pow": 0.0925658,
    "AC_R_output": 0.3765,
    "AC_S_output": 0.41415,
    "AC_T_output": 0.455565,
    "dc_current": 0.001875,
    "dc_voltage": 136,
    "dc_power": 0.24786,
    "dc_output": 0.2601,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000255,
    "carbon offset": 0.31875,
    "trees planted": 7.083333333,
    "DC_R_output": 0.124245,
    "DC_S_output": 0.1366695,
    "DC_T_output": 0.15033645
  },
  {
    "datetime": "10/27/2024 12:30",
    "plant_id": "Green-tek-A27052",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "12:30:00 PM",
    "power": 0.971,
    "Energy": 0.243,
    "output Energy": 0.243,
    "Ac output": 1.243,
    "dc output": 1.26786,
    "AC_R_i": 0.00031696,
    "AC_S_i": 0.000348656,
    "AC_T_i": 0.00038352,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0729008,
    "AC_S_pow": 0.08019088,
    "AC_T_Pow": 0.0882096,
    "AC_R_output": 0.3729,
    "AC_S_output": 0.41019,
    "AC_T_output": 0.451209,
    "dc_current": 0.00178676,
    "dc_voltage": 136,
    "dc_power": 0.23052,
    "dc_output": 0.24786,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000243,
    "carbon offset": 0.30375,
    "trees planted": 6.75,
    "DC_R_output": 0.123057,
    "DC_S_output": 0.1353627,
    "DC_T_output": 0.14889897
  },
  {
    "datetime": "10/27/2024 12:45",
    "plant_id": "Green-tek-A27053",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "12:45:00 PM",
    "power": 0.904,
    "Energy": 0.226,
    "output Energy": 0.226,
    "Ac output": 1.226,
    "dc output": 1.25052,
    "AC_R_i": 0.00029478,
    "AC_S_i": 0.000324258,
    "AC_T_i": 0.00035668,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0677994,
    "AC_S_pow": 0.07457934,
    "AC_T_Pow": 0.0820364,
    "AC_R_output": 0.3678,
    "AC_S_output": 0.40458,
    "AC_T_output": 0.445038,
    "dc_current": 0.00166176,
    "dc_voltage": 136,
    "dc_power": 0.21012,
    "dc_output": 0.23052,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000226,
    "carbon offset": 0.2825,
    "trees planted": 6.277777778,
    "DC_R_output": 0.121374,
    "DC_S_output": 0.1335114,
    "DC_T_output": 0.14686254
  },
  {
    "datetime": "10/27/2024 13:00",
    "plant_id": "Green-tek-A27054",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "1:00:00 PM",
    "power": 0.825,
    "Energy": 0.206,
    "output Energy": 0.206,
    "Ac output": 1.206,
    "dc output": 1.23012,
    "AC_R_i": 0.0002687,
    "AC_S_i": 0.00029557,
    "AC_T_i": 0.00032513,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.061801,
    "AC_S_pow": 0.0679811,
    "AC_T_Pow": 0.0747799,
    "AC_R_output": 0.3618,
    "AC_S_output": 0.39798,
    "AC_T_output": 0.437778,
    "dc_current": 0.00151471,
    "dc_voltage": 136,
    "dc_power": 0.18768,
    "dc_output": 0.21012,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000206,
    "carbon offset": 0.2575,
    "trees planted": 5.722222222,
    "DC_R_output": 0.119394,
    "DC_S_output": 0.1313334,
    "DC_T_output": 0.14446674
  },
  {
    "datetime": "10/27/2024 13:15",
    "plant_id": "Green-tek-A27055",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "1:15:00 PM",
    "power": 0.737,
    "Energy": 0.184,
    "output Energy": 0.184,
    "Ac output": 1.184,
    "dc output": 1.20768,
    "AC_R_i": 0.00024,
    "AC_S_i": 0.000264,
    "AC_T_i": 0.0002904,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0552,
    "AC_S_pow": 0.06072,
    "AC_T_Pow": 0.066792,
    "AC_R_output": 0.3552,
    "AC_S_output": 0.39072,
    "AC_T_output": 0.429792,
    "dc_current": 0.00135294,
    "dc_voltage": 136,
    "dc_power": 0.16422,
    "dc_output": 0.18768,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000184,
    "carbon offset": 0.23,
    "trees planted": 5.111111111,
    "DC_R_output": 0.117216,
    "DC_S_output": 0.1289376,
    "DC_T_output": 0.14183136
  },
  {
    "datetime": "10/27/2024 13:30",
    "plant_id": "Green-tek-A27056",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "1:30:00 PM",
    "power": 0.645,
    "Energy": 0.161,
    "output Energy": 0.161,
    "Ac output": 1.161,
    "dc output": 1.18422,
    "AC_R_i": 0.00021,
    "AC_S_i": 0.000231,
    "AC_T_i": 0.0002541,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0483,
    "AC_S_pow": 0.05313,
    "AC_T_Pow": 0.058443,
    "AC_R_output": 0.3483,
    "AC_S_output": 0.38313,
    "AC_T_output": 0.421443,
    "dc_current": 0.00118382,
    "dc_voltage": 136,
    "dc_power": 0.14076,
    "dc_output": 0.16422,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000161,
    "carbon offset": 0.20125,
    "trees planted": 4.472222222,
    "DC_R_output": 0.114939,
    "DC_S_output": 0.1264329,
    "DC_T_output": 0.13907619
  },
  {
    "datetime": "10/27/2024 13:45",
    "plant_id": "Green-tek-A27057",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "1:45:00 PM",
    "power": 0.554,
    "Energy": 0.138,
    "output Energy": 0.138,
    "Ac output": 1.138,
    "dc output": 1.16076,
    "AC_R_i": 0.00018,
    "AC_S_i": 0.000198,
    "AC_T_i": 0.0002178,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0414,
    "AC_S_pow": 0.04554,
    "AC_T_Pow": 0.050094,
    "AC_R_output": 0.3414,
    "AC_S_output": 0.37554,
    "AC_T_output": 0.413094,
    "dc_current": 0.00101471,
    "dc_voltage": 136,
    "dc_power": 0.11832,
    "dc_output": 0.14076,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000138,
    "carbon offset": 0.1725,
    "trees planted": 3.833333333,
    "DC_R_output": 0.112662,
    "DC_S_output": 0.1239282,
    "DC_T_output": 0.13632102
  },
  {
    "datetime": "10/27/2024 14:00",
    "plant_id": "Green-tek-A27058",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "2:00:00 PM",
    "power": 0.466,
    "Energy": 0.116,
    "output Energy": 0.116,
    "Ac output": 1.116,
    "dc output": 1.13832,
    "AC_R_i": 0.0001513,
    "AC_S_i": 0.00016643,
    "AC_T_i": 0.00018307,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.034799,
    "AC_S_pow": 0.0382789,
    "AC_T_Pow": 0.0421061,
    "AC_R_output": 0.3348,
    "AC_S_output": 0.36828,
    "AC_T_output": 0.405108,
    "dc_current": 0.00085294,
    "dc_voltage": 136,
    "dc_power": 0.09792,
    "dc_output": 0.11832,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000116,
    "carbon offset": 0.145,
    "trees planted": 3.222222222,
    "DC_R_output": 0.110484,
    "DC_S_output": 0.1215324,
    "DC_T_output": 0.13368564
  },
  {
    "datetime": "10/27/2024 14:15",
    "plant_id": "Green-tek-A27059",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "2:15:00 PM",
    "power": 0.384,
    "Energy": 0.096,
    "output Energy": 0.096,
    "Ac output": 1.096,
    "dc output": 1.11792,
    "AC_R_i": 0.00012522,
    "AC_S_i": 0.000137742,
    "AC_T_i": 0.00015152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0288006,
    "AC_S_pow": 0.03168066,
    "AC_T_Pow": 0.0348496,
    "AC_R_output": 0.3288,
    "AC_S_output": 0.36168,
    "AC_T_output": 0.397848,
    "dc_current": 0.00070588,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.09792,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000096,
    "carbon offset": 0.12,
    "trees planted": 2.666666667,
    "DC_R_output": 0.108504,
    "DC_S_output": 0.1193544,
    "DC_T_output": 0.13128984
  },
  {
    "datetime": "10/27/2024 14:30",
    "plant_id": "Green-tek-A27060",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "2:30:00 PM",
    "power": 0.31,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "10/27/2024 14:45",
    "plant_id": "Green-tek-A27061",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "2:45:00 PM",
    "power": 0.245,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "10/27/2024 15:00",
    "plant_id": "Green-tek-A27062",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "3:00:00 PM",
    "power": 0.19,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "10/27/2024 15:15",
    "plant_id": "Green-tek-A27063",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "3:15:00 PM",
    "power": 0.144,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "10/27/2024 15:30",
    "plant_id": "Green-tek-A27064",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "3:30:00 PM",
    "power": 0.107,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "10/27/2024 15:45",
    "plant_id": "Green-tek-A27065",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "3:45:00 PM",
    "power": 0.078,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "10/27/2024 16:00",
    "plant_id": "Green-tek-A27066",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "4:00:00 PM",
    "power": 0.056,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/27/2024 16:15",
    "plant_id": "Green-tek-A27067",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/27/2024 16:30",
    "plant_id": "Green-tek-A27068",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/27/2024 16:45",
    "plant_id": "Green-tek-A27069",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/27/2024 17:00",
    "plant_id": "Green-tek-A27070",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/27/2024 17:15",
    "plant_id": "Green-tek-A27071",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/27/2024 17:30",
    "plant_id": "Green-tek-A27072",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/27/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/28/2024 6:00",
    "plant_id": "Green-tek-A27073",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/28/2024 6:15",
    "plant_id": "Green-tek-A27074",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/28/2024 6:30",
    "plant_id": "Green-tek-A27075",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/28/2024 6:45",
    "plant_id": "Green-tek-A27076",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/28/2024 7:00",
    "plant_id": "Green-tek-A27077",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "7:00:00 AM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/28/2024 7:15",
    "plant_id": "Green-tek-A27078",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "7:15:00 AM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/28/2024 7:30",
    "plant_id": "Green-tek-A27079",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "7:30:00 AM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/28/2024 7:45",
    "plant_id": "Green-tek-A27080",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "7:45:00 AM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "10/28/2024 8:00",
    "plant_id": "Green-tek-A27081",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "8:00:00 AM",
    "power": 0.106,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "10/28/2024 8:15",
    "plant_id": "Green-tek-A27082",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "8:15:00 AM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "10/28/2024 8:30",
    "plant_id": "Green-tek-A27083",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "8:30:00 AM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "10/28/2024 8:45",
    "plant_id": "Green-tek-A27084",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "8:45:00 AM",
    "power": 0.242,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "10/28/2024 9:00",
    "plant_id": "Green-tek-A27085",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "9:00:00 AM",
    "power": 0.306,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "10/28/2024 9:15",
    "plant_id": "Green-tek-A27086",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "9:15:00 AM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "10/28/2024 9:30",
    "plant_id": "Green-tek-A27087",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "9:30:00 AM",
    "power": 0.461,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "10/28/2024 9:45",
    "plant_id": "Green-tek-A27088",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "9:45:00 AM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "10/28/2024 10:00",
    "plant_id": "Green-tek-A27089",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "10:00:00 AM",
    "power": 0.639,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "10/28/2024 10:15",
    "plant_id": "Green-tek-A27090",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "10:15:00 AM",
    "power": 0.73,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "10/28/2024 10:30",
    "plant_id": "Green-tek-A27091",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "10:30:00 AM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "10/28/2024 10:45",
    "plant_id": "Green-tek-A27092",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "10:45:00 AM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "10/28/2024 11:00",
    "plant_id": "Green-tek-A27093",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "11:00:00 AM",
    "power": 0.961,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "10/28/2024 11:15",
    "plant_id": "Green-tek-A27094",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "11:15:00 AM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "10/28/2024 11:30",
    "plant_id": "Green-tek-A27095",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "11:30:00 AM",
    "power": 1.043,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.26826,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "10/28/2024 11:45",
    "plant_id": "Green-tek-A27096",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "11:45:00 AM",
    "power": 1.053,
    "Energy": 0.263,
    "output Energy": 0.263,
    "Ac output": 1.263,
    "dc output": 1.28826,
    "AC_R_i": 0.00034304,
    "AC_S_i": 0.000377344,
    "AC_T_i": 0.00041508,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0788992,
    "AC_S_pow": 0.08678912,
    "AC_T_Pow": 0.0954684,
    "AC_R_output": 0.3789,
    "AC_S_output": 0.41679,
    "AC_T_output": 0.458469,
    "dc_current": 0.00193382,
    "dc_voltage": 136,
    "dc_power": 0.26622,
    "dc_output": 0.26826,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000263,
    "carbon offset": 0.32875,
    "trees planted": 7.305555556,
    "DC_R_output": 0.125037,
    "DC_S_output": 0.1375407,
    "DC_T_output": 0.15129477
  },
  {
    "datetime": "10/28/2024 12:00",
    "plant_id": "Green-tek-A27097",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "12:00:00 PM",
    "power": 1.043,
    "Energy": 0.261,
    "output Energy": 0.261,
    "Ac output": 1.261,
    "dc output": 1.28622,
    "AC_R_i": 0.00034043,
    "AC_S_i": 0.000374473,
    "AC_T_i": 0.00041192,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0782989,
    "AC_S_pow": 0.08612879,
    "AC_T_Pow": 0.0947416,
    "AC_R_output": 0.3783,
    "AC_S_output": 0.41613,
    "AC_T_output": 0.457743,
    "dc_current": 0.00191912,
    "dc_voltage": 136,
    "dc_power": 0.25806,
    "dc_output": 0.26622,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000261,
    "carbon offset": 0.32625,
    "trees planted": 7.25,
    "DC_R_output": 0.124839,
    "DC_S_output": 0.1373229,
    "DC_T_output": 0.15105519
  },
  {
    "datetime": "10/28/2024 12:15",
    "plant_id": "Green-tek-A27098",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "12:15:00 PM",
    "power": 1.011,
    "Energy": 0.253,
    "output Energy": 0.253,
    "Ac output": 1.253,
    "dc output": 1.27806,
    "AC_R_i": 0.00033,
    "AC_S_i": 0.000363,
    "AC_T_i": 0.0003993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0759,
    "AC_S_pow": 0.08349,
    "AC_T_Pow": 0.091839,
    "AC_R_output": 0.3759,
    "AC_S_output": 0.41349,
    "AC_T_output": 0.454839,
    "dc_current": 0.00186029,
    "dc_voltage": 136,
    "dc_power": 0.2448,
    "dc_output": 0.25806,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000253,
    "carbon offset": 0.31625,
    "trees planted": 7.027777778,
    "DC_R_output": 0.124047,
    "DC_S_output": 0.1364517,
    "DC_T_output": 0.15009687
  },
  {
    "datetime": "10/28/2024 12:30",
    "plant_id": "Green-tek-A27099",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "12:30:00 PM",
    "power": 0.961,
    "Energy": 0.24,
    "output Energy": 0.24,
    "Ac output": 1.24,
    "dc output": 1.2648,
    "AC_R_i": 0.00031304,
    "AC_S_i": 0.000344344,
    "AC_T_i": 0.00037878,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0719992,
    "AC_S_pow": 0.07919912,
    "AC_T_Pow": 0.0871194,
    "AC_R_output": 0.372,
    "AC_S_output": 0.4092,
    "AC_T_output": 0.45012,
    "dc_current": 0.00176471,
    "dc_voltage": 136,
    "dc_power": 0.22848,
    "dc_output": 0.2448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00024,
    "carbon offset": 0.3,
    "trees planted": 6.666666667,
    "DC_R_output": 0.12276,
    "DC_S_output": 0.135036,
    "DC_T_output": 0.1485396
  },
  {
    "datetime": "10/28/2024 12:45",
    "plant_id": "Green-tek-A27100",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "12:45:00 PM",
    "power": 0.895,
    "Energy": 0.224,
    "output Energy": 0.224,
    "Ac output": 1.224,
    "dc output": 1.24848,
    "AC_R_i": 0.00029217,
    "AC_S_i": 0.000321387,
    "AC_T_i": 0.00035353,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0671991,
    "AC_S_pow": 0.07391901,
    "AC_T_Pow": 0.0813119,
    "AC_R_output": 0.3672,
    "AC_S_output": 0.40392,
    "AC_T_output": 0.444312,
    "dc_current": 0.00164706,
    "dc_voltage": 136,
    "dc_power": 0.20808,
    "dc_output": 0.22848,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000224,
    "carbon offset": 0.28,
    "trees planted": 6.222222222,
    "DC_R_output": 0.121176,
    "DC_S_output": 0.1332936,
    "DC_T_output": 0.14662296
  },
  {
    "datetime": "10/28/2024 13:00",
    "plant_id": "Green-tek-A27101",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "1:00:00 PM",
    "power": 0.816,
    "Energy": 0.204,
    "output Energy": 0.204,
    "Ac output": 1.204,
    "dc output": 1.22808,
    "AC_R_i": 0.00026609,
    "AC_S_i": 0.000292699,
    "AC_T_i": 0.00032197,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0612007,
    "AC_S_pow": 0.06732077,
    "AC_T_Pow": 0.0740531,
    "AC_R_output": 0.3612,
    "AC_S_output": 0.39732,
    "AC_T_output": 0.437052,
    "dc_current": 0.0015,
    "dc_voltage": 136,
    "dc_power": 0.18564,
    "dc_output": 0.20808,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000204,
    "carbon offset": 0.255,
    "trees planted": 5.666666667,
    "DC_R_output": 0.119196,
    "DC_S_output": 0.1311156,
    "DC_T_output": 0.14422716
  },
  {
    "datetime": "10/28/2024 13:15",
    "plant_id": "Green-tek-A27102",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "1:15:00 PM",
    "power": 0.73,
    "Energy": 0.182,
    "output Energy": 0.182,
    "Ac output": 1.182,
    "dc output": 1.20564,
    "AC_R_i": 0.00023739,
    "AC_S_i": 0.000261129,
    "AC_T_i": 0.00028724,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0545997,
    "AC_S_pow": 0.06005967,
    "AC_T_Pow": 0.0660652,
    "AC_R_output": 0.3546,
    "AC_S_output": 0.39006,
    "AC_T_output": 0.429066,
    "dc_current": 0.00133824,
    "dc_voltage": 136,
    "dc_power": 0.1632,
    "dc_output": 0.18564,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000182,
    "carbon offset": 0.2275,
    "trees planted": 5.055555556,
    "DC_R_output": 0.117018,
    "DC_S_output": 0.1287198,
    "DC_T_output": 0.14159178
  },
  {
    "datetime": "10/28/2024 13:30",
    "plant_id": "Green-tek-A27103",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "1:30:00 PM",
    "power": 0.639,
    "Energy": 0.16,
    "output Energy": 0.16,
    "Ac output": 1.16,
    "dc output": 1.1832,
    "AC_R_i": 0.0002087,
    "AC_S_i": 0.00022957,
    "AC_T_i": 0.00025253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.048001,
    "AC_S_pow": 0.0528011,
    "AC_T_Pow": 0.0580819,
    "AC_R_output": 0.348,
    "AC_S_output": 0.3828,
    "AC_T_output": 0.42108,
    "dc_current": 0.00117647,
    "dc_voltage": 136,
    "dc_power": 0.13974,
    "dc_output": 0.1632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00016,
    "carbon offset": 0.2,
    "trees planted": 4.444444444,
    "DC_R_output": 0.11484,
    "DC_S_output": 0.126324,
    "DC_T_output": 0.1389564
  },
  {
    "datetime": "10/28/2024 13:45",
    "plant_id": "Green-tek-A27104",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "1:45:00 PM",
    "power": 0.548,
    "Energy": 0.137,
    "output Energy": 0.137,
    "Ac output": 1.137,
    "dc output": 1.15974,
    "AC_R_i": 0.0001787,
    "AC_S_i": 0.00019657,
    "AC_T_i": 0.00021623,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.041101,
    "AC_S_pow": 0.0452111,
    "AC_T_Pow": 0.0497329,
    "AC_R_output": 0.3411,
    "AC_S_output": 0.37521,
    "AC_T_output": 0.412731,
    "dc_current": 0.00100735,
    "dc_voltage": 136,
    "dc_power": 0.1173,
    "dc_output": 0.13974,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000137,
    "carbon offset": 0.17125,
    "trees planted": 3.805555556,
    "DC_R_output": 0.112563,
    "DC_S_output": 0.1238193,
    "DC_T_output": 0.13620123
  },
  {
    "datetime": "10/28/2024 14:00",
    "plant_id": "Green-tek-A27105",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "2:00:00 PM",
    "power": 0.461,
    "Energy": 0.115,
    "output Energy": 0.115,
    "Ac output": 1.115,
    "dc output": 1.1373,
    "AC_R_i": 0.00015,
    "AC_S_i": 0.000165,
    "AC_T_i": 0.0001815,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0345,
    "AC_S_pow": 0.03795,
    "AC_T_Pow": 0.041745,
    "AC_R_output": 0.3345,
    "AC_S_output": 0.36795,
    "AC_T_output": 0.404745,
    "dc_current": 0.00084559,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.1173,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000115,
    "carbon offset": 0.14375,
    "trees planted": 3.194444444,
    "DC_R_output": 0.110385,
    "DC_S_output": 0.1214235,
    "DC_T_output": 0.13356585
  },
  {
    "datetime": "10/28/2024 14:15",
    "plant_id": "Green-tek-A27106",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "2:15:00 PM",
    "power": 0.38,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.07854,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "10/28/2024 14:30",
    "plant_id": "Green-tek-A27107",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "2:30:00 PM",
    "power": 0.306,
    "Energy": 0.077,
    "output Energy": 0.077,
    "Ac output": 1.077,
    "dc output": 1.09854,
    "AC_R_i": 0.00010043,
    "AC_S_i": 0.000110473,
    "AC_T_i": 0.00012152,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0230989,
    "AC_S_pow": 0.02540879,
    "AC_T_Pow": 0.0279496,
    "AC_R_output": 0.3231,
    "AC_S_output": 0.35541,
    "AC_T_output": 0.390951,
    "dc_current": 0.00056618,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07854,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000077,
    "carbon offset": 0.09625,
    "trees planted": 2.138888889,
    "DC_R_output": 0.106623,
    "DC_S_output": 0.1172853,
    "DC_T_output": 0.12901383
  },
  {
    "datetime": "10/28/2024 14:45",
    "plant_id": "Green-tek-A27108",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "2:45:00 PM",
    "power": 0.242,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04794,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "10/28/2024 15:00",
    "plant_id": "Green-tek-A27109",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "3:00:00 PM",
    "power": 0.188,
    "Energy": 0.047,
    "output Energy": 0.047,
    "Ac output": 1.047,
    "dc output": 1.06794,
    "AC_R_i": 0.0000613,
    "AC_S_i": 0.00006743,
    "AC_T_i": 0.00007417,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.014099,
    "AC_S_pow": 0.0155089,
    "AC_T_Pow": 0.0170591,
    "AC_R_output": 0.3141,
    "AC_S_output": 0.34551,
    "AC_T_output": 0.380061,
    "dc_current": 0.00034559,
    "dc_voltage": 136,
    "dc_power": 0.03672,
    "dc_output": 0.04794,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000047,
    "carbon offset": 0.05875,
    "trees planted": 1.305555556,
    "DC_R_output": 0.103653,
    "DC_S_output": 0.1140183,
    "DC_T_output": 0.12542013
  },
  {
    "datetime": "10/28/2024 15:15",
    "plant_id": "Green-tek-A27110",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "3:15:00 PM",
    "power": 0.143,
    "Energy": 0.036,
    "output Energy": 0.036,
    "Ac output": 1.036,
    "dc output": 1.05672,
    "AC_R_i": 0.00004696,
    "AC_S_i": 0.000051656,
    "AC_T_i": 0.00005682,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0108008,
    "AC_S_pow": 0.01188088,
    "AC_T_Pow": 0.0130686,
    "AC_R_output": 0.3108,
    "AC_S_output": 0.34188,
    "AC_T_output": 0.376068,
    "dc_current": 0.00026471,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03672,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000036,
    "carbon offset": 0.045,
    "trees planted": 1,
    "DC_R_output": 0.102564,
    "DC_S_output": 0.1128204,
    "DC_T_output": 0.12410244
  },
  {
    "datetime": "10/28/2024 15:30",
    "plant_id": "Green-tek-A27111",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "3:30:00 PM",
    "power": 0.106,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.01938,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "10/28/2024 15:45",
    "plant_id": "Green-tek-A27112",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "3:45:00 PM",
    "power": 0.077,
    "Energy": 0.019,
    "output Energy": 0.019,
    "Ac output": 1.019,
    "dc output": 1.03938,
    "AC_R_i": 0.00002478,
    "AC_S_i": 0.000027258,
    "AC_T_i": 0.00002998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0056994,
    "AC_S_pow": 0.00626934,
    "AC_T_Pow": 0.0068954,
    "AC_R_output": 0.3057,
    "AC_S_output": 0.33627,
    "AC_T_output": 0.369897,
    "dc_current": 0.00013971,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.01938,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000019,
    "carbon offset": 0.02375,
    "trees planted": 0.527777778,
    "DC_R_output": 0.100881,
    "DC_S_output": 0.1109691,
    "DC_T_output": 0.12206601
  },
  {
    "datetime": "10/28/2024 16:00",
    "plant_id": "Green-tek-A27113",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "4:00:00 PM",
    "power": 0.055,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "10/28/2024 16:15",
    "plant_id": "Green-tek-A27114",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "4:15:00 PM",
    "power": 0.039,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "10/28/2024 16:30",
    "plant_id": "Green-tek-A27115",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "4:30:00 PM",
    "power": 0.026,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "10/28/2024 16:45",
    "plant_id": "Green-tek-A27116",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/28/2024 17:00",
    "plant_id": "Green-tek-A27117",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/28/2024 17:15",
    "plant_id": "Green-tek-A27118",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/28/2024 17:30",
    "plant_id": "Green-tek-A27119",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/28/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/29/2024 6:00",
    "plant_id": "Green-tek-A27120",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "6:00:00 AM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/29/2024 6:15",
    "plant_id": "Green-tek-A27121",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/29/2024 6:30",
    "plant_id": "Green-tek-A27122",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "6:30:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/29/2024 6:45",
    "plant_id": "Green-tek-A27123",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "6:45:00 AM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/29/2024 7:00",
    "plant_id": "Green-tek-A27124",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "7:00:00 AM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/29/2024 7:15",
    "plant_id": "Green-tek-A27125",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "7:15:00 AM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/29/2024 7:30",
    "plant_id": "Green-tek-A27126",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "7:30:00 AM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/29/2024 7:45",
    "plant_id": "Green-tek-A27127",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "7:45:00 AM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/29/2024 8:00",
    "plant_id": "Green-tek-A27128",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "8:00:00 AM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/29/2024 8:15",
    "plant_id": "Green-tek-A27129",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "8:15:00 AM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "10/29/2024 8:30",
    "plant_id": "Green-tek-A27130",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "8:30:00 AM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "10/29/2024 8:45",
    "plant_id": "Green-tek-A27131",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "8:45:00 AM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "10/29/2024 9:00",
    "plant_id": "Green-tek-A27132",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "9:00:00 AM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "10/29/2024 9:15",
    "plant_id": "Green-tek-A27133",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "9:15:00 AM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "10/29/2024 9:30",
    "plant_id": "Green-tek-A27134",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "9:30:00 AM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "10/29/2024 9:45",
    "plant_id": "Green-tek-A27135",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "9:45:00 AM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "10/29/2024 10:00",
    "plant_id": "Green-tek-A27136",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "10:00:00 AM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "10/29/2024 10:15",
    "plant_id": "Green-tek-A27137",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "10:15:00 AM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "10/29/2024 10:30",
    "plant_id": "Green-tek-A27138",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "10:30:00 AM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "10/29/2024 10:45",
    "plant_id": "Green-tek-A27139",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "10:45:00 AM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "10/29/2024 11:00",
    "plant_id": "Green-tek-A27140",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "11:00:00 AM",
    "power": 1.187,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "10/29/2024 11:15",
    "plant_id": "Green-tek-A27141",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "11:15:00 AM",
    "power": 1.249,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "10/29/2024 11:30",
    "plant_id": "Green-tek-A27142",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "11:30:00 AM",
    "power": 1.288,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.3315,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "10/29/2024 11:45",
    "plant_id": "Green-tek-A27143",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "11:45:00 AM",
    "power": 1.301,
    "Energy": 0.325,
    "output Energy": 0.325,
    "Ac output": 1.325,
    "dc output": 1.3515,
    "AC_R_i": 0.00042391,
    "AC_S_i": 0.000466301,
    "AC_T_i": 0.00051293,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0974993,
    "AC_S_pow": 0.10724923,
    "AC_T_Pow": 0.1179739,
    "AC_R_output": 0.3975,
    "AC_S_output": 0.43725,
    "AC_T_output": 0.480975,
    "dc_current": 0.00238971,
    "dc_voltage": 136,
    "dc_power": 0.32844,
    "dc_output": 0.3315,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000325,
    "carbon offset": 0.40625,
    "trees planted": 9.027777778,
    "DC_R_output": 0.131175,
    "DC_S_output": 0.1442925,
    "DC_T_output": 0.15872175
  },
  {
    "datetime": "10/29/2024 12:00",
    "plant_id": "Green-tek-A27144",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "12:00:00 PM",
    "power": 1.288,
    "Energy": 0.322,
    "output Energy": 0.322,
    "Ac output": 1.322,
    "dc output": 1.34844,
    "AC_R_i": 0.00042,
    "AC_S_i": 0.000462,
    "AC_T_i": 0.0005082,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0966,
    "AC_S_pow": 0.10626,
    "AC_T_Pow": 0.116886,
    "AC_R_output": 0.3966,
    "AC_S_output": 0.43626,
    "AC_T_output": 0.479886,
    "dc_current": 0.00236765,
    "dc_voltage": 136,
    "dc_power": 0.31824,
    "dc_output": 0.32844,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000322,
    "carbon offset": 0.4025,
    "trees planted": 8.944444444,
    "DC_R_output": 0.130878,
    "DC_S_output": 0.1439658,
    "DC_T_output": 0.15836238
  },
  {
    "datetime": "10/29/2024 12:15",
    "plant_id": "Green-tek-A27145",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "12:15:00 PM",
    "power": 1.249,
    "Energy": 0.312,
    "output Energy": 0.312,
    "Ac output": 1.312,
    "dc output": 1.33824,
    "AC_R_i": 0.00040696,
    "AC_S_i": 0.000447656,
    "AC_T_i": 0.00049242,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0936008,
    "AC_S_pow": 0.10296088,
    "AC_T_Pow": 0.1132566,
    "AC_R_output": 0.3936,
    "AC_S_output": 0.43296,
    "AC_T_output": 0.476256,
    "dc_current": 0.00229412,
    "dc_voltage": 136,
    "dc_power": 0.30294,
    "dc_output": 0.31824,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000312,
    "carbon offset": 0.39,
    "trees planted": 8.666666667,
    "DC_R_output": 0.129888,
    "DC_S_output": 0.1428768,
    "DC_T_output": 0.15716448
  },
  {
    "datetime": "10/29/2024 12:30",
    "plant_id": "Green-tek-A27146",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "12:30:00 PM",
    "power": 1.187,
    "Energy": 0.297,
    "output Energy": 0.297,
    "Ac output": 1.297,
    "dc output": 1.32294,
    "AC_R_i": 0.00038739,
    "AC_S_i": 0.000426129,
    "AC_T_i": 0.00046874,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0890997,
    "AC_S_pow": 0.09800967,
    "AC_T_Pow": 0.1078102,
    "AC_R_output": 0.3891,
    "AC_S_output": 0.42801,
    "AC_T_output": 0.470811,
    "dc_current": 0.00218382,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.30294,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000297,
    "carbon offset": 0.37125,
    "trees planted": 8.25,
    "DC_R_output": 0.128403,
    "DC_S_output": 0.1412433,
    "DC_T_output": 0.15536763
  },
  {
    "datetime": "10/29/2024 12:45",
    "plant_id": "Green-tek-A27147",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "12:45:00 PM",
    "power": 1.105,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.25704,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "10/29/2024 13:00",
    "plant_id": "Green-tek-A27148",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "1:00:00 PM",
    "power": 1.008,
    "Energy": 0.252,
    "output Energy": 0.252,
    "Ac output": 1.252,
    "dc output": 1.27704,
    "AC_R_i": 0.0003287,
    "AC_S_i": 0.00036157,
    "AC_T_i": 0.00039773,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.075601,
    "AC_S_pow": 0.0831611,
    "AC_T_Pow": 0.0914779,
    "AC_R_output": 0.3756,
    "AC_S_output": 0.41316,
    "AC_T_output": 0.454476,
    "dc_current": 0.00185294,
    "dc_voltage": 136,
    "dc_power": 0.2295,
    "dc_output": 0.25704,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000252,
    "carbon offset": 0.315,
    "trees planted": 7,
    "DC_R_output": 0.123948,
    "DC_S_output": 0.1363428,
    "DC_T_output": 0.14997708
  },
  {
    "datetime": "10/29/2024 13:15",
    "plant_id": "Green-tek-A27149",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "1:15:00 PM",
    "power": 0.901,
    "Energy": 0.225,
    "output Energy": 0.225,
    "Ac output": 1.225,
    "dc output": 1.2495,
    "AC_R_i": 0.00029348,
    "AC_S_i": 0.000322828,
    "AC_T_i": 0.00035511,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0675004,
    "AC_S_pow": 0.07425044,
    "AC_T_Pow": 0.0816753,
    "AC_R_output": 0.3675,
    "AC_S_output": 0.40425,
    "AC_T_output": 0.444675,
    "dc_current": 0.00165441,
    "dc_voltage": 136,
    "dc_power": 0.20094,
    "dc_output": 0.2295,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000225,
    "carbon offset": 0.28125,
    "trees planted": 6.25,
    "DC_R_output": 0.121275,
    "DC_S_output": 0.1334025,
    "DC_T_output": 0.14674275
  },
  {
    "datetime": "10/29/2024 13:30",
    "plant_id": "Green-tek-A27150",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "1:30:00 PM",
    "power": 0.789,
    "Energy": 0.197,
    "output Energy": 0.197,
    "Ac output": 1.197,
    "dc output": 1.22094,
    "AC_R_i": 0.00025696,
    "AC_S_i": 0.000282656,
    "AC_T_i": 0.00031092,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0591008,
    "AC_S_pow": 0.06501088,
    "AC_T_Pow": 0.0715116,
    "AC_R_output": 0.3591,
    "AC_S_output": 0.39501,
    "AC_T_output": 0.434511,
    "dc_current": 0.00144853,
    "dc_voltage": 136,
    "dc_power": 0.17238,
    "dc_output": 0.20094,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000197,
    "carbon offset": 0.24625,
    "trees planted": 5.472222222,
    "DC_R_output": 0.118503,
    "DC_S_output": 0.1303533,
    "DC_T_output": 0.14338863
  },
  {
    "datetime": "10/29/2024 13:45",
    "plant_id": "Green-tek-A27151",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "1:45:00 PM",
    "power": 0.677,
    "Energy": 0.169,
    "output Energy": 0.169,
    "Ac output": 1.169,
    "dc output": 1.19238,
    "AC_R_i": 0.00022043,
    "AC_S_i": 0.000242473,
    "AC_T_i": 0.00026672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0506989,
    "AC_S_pow": 0.05576879,
    "AC_T_Pow": 0.0613456,
    "AC_R_output": 0.3507,
    "AC_S_output": 0.38577,
    "AC_T_output": 0.424347,
    "dc_current": 0.00124265,
    "dc_voltage": 136,
    "dc_power": 0.14484,
    "dc_output": 0.17238,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000169,
    "carbon offset": 0.21125,
    "trees planted": 4.694444444,
    "DC_R_output": 0.115731,
    "DC_S_output": 0.1273041,
    "DC_T_output": 0.14003451
  },
  {
    "datetime": "10/29/2024 14:00",
    "plant_id": "Green-tek-A27152",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "2:00:00 PM",
    "power": 0.569,
    "Energy": 0.142,
    "output Energy": 0.142,
    "Ac output": 1.142,
    "dc output": 1.16484,
    "AC_R_i": 0.00018522,
    "AC_S_i": 0.000203742,
    "AC_T_i": 0.00022412,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0426006,
    "AC_S_pow": 0.04686066,
    "AC_T_Pow": 0.0515476,
    "AC_R_output": 0.3426,
    "AC_S_output": 0.37686,
    "AC_T_output": 0.414546,
    "dc_current": 0.00104412,
    "dc_voltage": 136,
    "dc_power": 0.11934,
    "dc_output": 0.14484,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000142,
    "carbon offset": 0.1775,
    "trees planted": 3.944444444,
    "DC_R_output": 0.113058,
    "DC_S_output": 0.1243638,
    "DC_T_output": 0.13680018
  },
  {
    "datetime": "10/29/2024 14:15",
    "plant_id": "Green-tek-A27153",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "2:15:00 PM",
    "power": 0.469,
    "Energy": 0.117,
    "output Energy": 0.117,
    "Ac output": 1.117,
    "dc output": 1.13934,
    "AC_R_i": 0.00015261,
    "AC_S_i": 0.000167871,
    "AC_T_i": 0.00018466,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0351003,
    "AC_S_pow": 0.03861033,
    "AC_T_Pow": 0.0424718,
    "AC_R_output": 0.3351,
    "AC_S_output": 0.36861,
    "AC_T_output": 0.405471,
    "dc_current": 0.00086029,
    "dc_voltage": 136,
    "dc_power": 0.0969,
    "dc_output": 0.11934,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000117,
    "carbon offset": 0.14625,
    "trees planted": 3.25,
    "DC_R_output": 0.110583,
    "DC_S_output": 0.1216413,
    "DC_T_output": 0.13380543
  },
  {
    "datetime": "10/29/2024 14:30",
    "plant_id": "Green-tek-A27154",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "2:30:00 PM",
    "power": 0.379,
    "Energy": 0.095,
    "output Energy": 0.095,
    "Ac output": 1.095,
    "dc output": 1.1169,
    "AC_R_i": 0.00012391,
    "AC_S_i": 0.000136301,
    "AC_T_i": 0.00014993,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0284993,
    "AC_S_pow": 0.03134923,
    "AC_T_Pow": 0.0344839,
    "AC_R_output": 0.3285,
    "AC_S_output": 0.36135,
    "AC_T_output": 0.397485,
    "dc_current": 0.00069853,
    "dc_voltage": 136,
    "dc_power": 0.0765,
    "dc_output": 0.0969,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000095,
    "carbon offset": 0.11875,
    "trees planted": 2.638888889,
    "DC_R_output": 0.108405,
    "DC_S_output": 0.1192455,
    "DC_T_output": 0.13117005
  },
  {
    "datetime": "10/29/2024 14:45",
    "plant_id": "Green-tek-A27155",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "2:45:00 PM",
    "power": 0.299,
    "Energy": 0.075,
    "output Energy": 0.075,
    "Ac output": 1.075,
    "dc output": 1.0965,
    "AC_R_i": 0.00009783,
    "AC_S_i": 0.000107613,
    "AC_T_i": 0.00011837,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0225009,
    "AC_S_pow": 0.02475099,
    "AC_T_Pow": 0.0272251,
    "AC_R_output": 0.3225,
    "AC_S_output": 0.35475,
    "AC_T_output": 0.390225,
    "dc_current": 0.00055147,
    "dc_voltage": 136,
    "dc_power": 0.05916,
    "dc_output": 0.0765,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000075,
    "carbon offset": 0.09375,
    "trees planted": 2.083333333,
    "DC_R_output": 0.106425,
    "DC_S_output": 0.1170675,
    "DC_T_output": 0.12877425
  },
  {
    "datetime": "10/29/2024 15:00",
    "plant_id": "Green-tek-A27156",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "3:00:00 PM",
    "power": 0.232,
    "Energy": 0.058,
    "output Energy": 0.058,
    "Ac output": 1.058,
    "dc output": 1.07916,
    "AC_R_i": 0.00007565,
    "AC_S_i": 0.000083215,
    "AC_T_i": 0.00009154,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0173995,
    "AC_S_pow": 0.01913945,
    "AC_T_Pow": 0.0210542,
    "AC_R_output": 0.3174,
    "AC_S_output": 0.34914,
    "AC_T_output": 0.384054,
    "dc_current": 0.00042647,
    "dc_voltage": 136,
    "dc_power": 0.04488,
    "dc_output": 0.05916,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000058,
    "carbon offset": 0.0725,
    "trees planted": 1.611111111,
    "DC_R_output": 0.104742,
    "DC_S_output": 0.1152162,
    "DC_T_output": 0.12673782
  },
  {
    "datetime": "10/29/2024 15:15",
    "plant_id": "Green-tek-A27157",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "3:15:00 PM",
    "power": 0.176,
    "Energy": 0.044,
    "output Energy": 0.044,
    "Ac output": 1.044,
    "dc output": 1.06488,
    "AC_R_i": 0.00005739,
    "AC_S_i": 0.000063129,
    "AC_T_i": 0.00006944,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0131997,
    "AC_S_pow": 0.01451967,
    "AC_T_Pow": 0.0159712,
    "AC_R_output": 0.3132,
    "AC_S_output": 0.34452,
    "AC_T_output": 0.378972,
    "dc_current": 0.00032353,
    "dc_voltage": 136,
    "dc_power": 0.03366,
    "dc_output": 0.04488,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000044,
    "carbon offset": 0.055,
    "trees planted": 1.222222222,
    "DC_R_output": 0.103356,
    "DC_S_output": 0.1136916,
    "DC_T_output": 0.12506076
  },
  {
    "datetime": "10/29/2024 15:30",
    "plant_id": "Green-tek-A27158",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "3:30:00 PM",
    "power": 0.131,
    "Energy": 0.033,
    "output Energy": 0.033,
    "Ac output": 1.033,
    "dc output": 1.05366,
    "AC_R_i": 0.00004304,
    "AC_S_i": 0.000047344,
    "AC_T_i": 0.00005208,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0098992,
    "AC_S_pow": 0.01088912,
    "AC_T_Pow": 0.0119784,
    "AC_R_output": 0.3099,
    "AC_S_output": 0.34089,
    "AC_T_output": 0.374979,
    "dc_current": 0.00024265,
    "dc_voltage": 136,
    "dc_power": 0.02448,
    "dc_output": 0.03366,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000033,
    "carbon offset": 0.04125,
    "trees planted": 0.916666667,
    "DC_R_output": 0.102267,
    "DC_S_output": 0.1124937,
    "DC_T_output": 0.12374307
  },
  {
    "datetime": "10/29/2024 15:45",
    "plant_id": "Green-tek-A27159",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "3:45:00 PM",
    "power": 0.095,
    "Energy": 0.024,
    "output Energy": 0.024,
    "Ac output": 1.024,
    "dc output": 1.04448,
    "AC_R_i": 0.0000313,
    "AC_S_i": 0.00003443,
    "AC_T_i": 0.00003787,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.007199,
    "AC_S_pow": 0.0079189,
    "AC_T_Pow": 0.0087101,
    "AC_R_output": 0.3072,
    "AC_S_output": 0.33792,
    "AC_T_output": 0.371712,
    "dc_current": 0.00017647,
    "dc_voltage": 136,
    "dc_power": 0.01734,
    "dc_output": 0.02448,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000024,
    "carbon offset": 0.03,
    "trees planted": 0.666666667,
    "DC_R_output": 0.101376,
    "DC_S_output": 0.1115136,
    "DC_T_output": 0.12266496
  },
  {
    "datetime": "10/29/2024 16:00",
    "plant_id": "Green-tek-A27160",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "4:00:00 PM",
    "power": 0.068,
    "Energy": 0.017,
    "output Energy": 0.017,
    "Ac output": 1.017,
    "dc output": 1.03734,
    "AC_R_i": 0.00002217,
    "AC_S_i": 0.000024387,
    "AC_T_i": 0.00002683,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0050991,
    "AC_S_pow": 0.00560901,
    "AC_T_Pow": 0.0061709,
    "AC_R_output": 0.3051,
    "AC_S_output": 0.33561,
    "AC_T_output": 0.369171,
    "dc_current": 0.000125,
    "dc_voltage": 136,
    "dc_power": 0.01224,
    "dc_output": 0.01734,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000017,
    "carbon offset": 0.02125,
    "trees planted": 0.472222222,
    "DC_R_output": 0.100683,
    "DC_S_output": 0.1107513,
    "DC_T_output": 0.12182643
  },
  {
    "datetime": "10/29/2024 16:15",
    "plant_id": "Green-tek-A27161",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "4:15:00 PM",
    "power": 0.048,
    "Energy": 0.012,
    "output Energy": 0.012,
    "Ac output": 1.012,
    "dc output": 1.03224,
    "AC_R_i": 0.00001565,
    "AC_S_i": 0.000017215,
    "AC_T_i": 0.00001894,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0035995,
    "AC_S_pow": 0.00395945,
    "AC_T_Pow": 0.0043562,
    "AC_R_output": 0.3036,
    "AC_S_output": 0.33396,
    "AC_T_output": 0.367356,
    "dc_current": 0.00008824,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01224,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000012,
    "carbon offset": 0.015,
    "trees planted": 0.333333333,
    "DC_R_output": 0.100188,
    "DC_S_output": 0.1102068,
    "DC_T_output": 0.12122748
  },
  {
    "datetime": "10/29/2024 16:30",
    "plant_id": "Green-tek-A27162",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "4:30:00 PM",
    "power": 0.033,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/29/2024 16:45",
    "plant_id": "Green-tek-A27163",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "4:45:00 PM",
    "power": 0.022,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/29/2024 17:00",
    "plant_id": "Green-tek-A27164",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "5:00:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/29/2024 17:15",
    "plant_id": "Green-tek-A27165",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/29/2024 17:30",
    "plant_id": "Green-tek-A27166",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/29/2024",
    "time": "5:30:00 PM",
    "power": 0.006,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/30/2024 6:00",
    "plant_id": "Green-tek-A27167",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "6:00:00 AM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/30/2024 6:15",
    "plant_id": "Green-tek-A27168",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "6:15:00 AM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/30/2024 6:30",
    "plant_id": "Green-tek-A27169",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "6:30:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/30/2024 6:45",
    "plant_id": "Green-tek-A27170",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "6:45:00 AM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/30/2024 7:00",
    "plant_id": "Green-tek-A27171",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "7:00:00 AM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/30/2024 7:15",
    "plant_id": "Green-tek-A27172",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "7:15:00 AM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/30/2024 7:30",
    "plant_id": "Green-tek-A27173",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "7:30:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/30/2024 7:45",
    "plant_id": "Green-tek-A27174",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "7:45:00 AM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/30/2024 8:00",
    "plant_id": "Green-tek-A27175",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "8:00:00 AM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/30/2024 8:15",
    "plant_id": "Green-tek-A27176",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "8:15:00 AM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/30/2024 8:30",
    "plant_id": "Green-tek-A27177",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "8:30:00 AM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "10/30/2024 8:45",
    "plant_id": "Green-tek-A27178",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "8:45:00 AM",
    "power": 0.194,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "10/30/2024 9:00",
    "plant_id": "Green-tek-A27179",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "9:00:00 AM",
    "power": 0.246,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "10/30/2024 9:15",
    "plant_id": "Green-tek-A27180",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "9:15:00 AM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "10/30/2024 9:30",
    "plant_id": "Green-tek-A27181",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "9:30:00 AM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "10/30/2024 9:45",
    "plant_id": "Green-tek-A27182",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "9:45:00 AM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "10/30/2024 10:00",
    "plant_id": "Green-tek-A27183",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "10:00:00 AM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "10/30/2024 10:15",
    "plant_id": "Green-tek-A27184",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "10:15:00 AM",
    "power": 0.585,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "10/30/2024 10:30",
    "plant_id": "Green-tek-A27185",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "10:30:00 AM",
    "power": 0.654,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "10/30/2024 10:45",
    "plant_id": "Green-tek-A27186",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "10:45:00 AM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "10/30/2024 11:00",
    "plant_id": "Green-tek-A27187",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "11:00:00 AM",
    "power": 0.77,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "10/30/2024 11:15",
    "plant_id": "Green-tek-A27188",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "11:15:00 AM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "10/30/2024 11:30",
    "plant_id": "Green-tek-A27189",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "11:30:00 AM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.21522,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "10/30/2024 11:45",
    "plant_id": "Green-tek-A27190",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "11:45:00 AM",
    "power": 0.844,
    "Energy": 0.211,
    "output Energy": 0.211,
    "Ac output": 1.211,
    "dc output": 1.23522,
    "AC_R_i": 0.00027522,
    "AC_S_i": 0.000302742,
    "AC_T_i": 0.00033302,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0633006,
    "AC_S_pow": 0.06963066,
    "AC_T_Pow": 0.0765946,
    "AC_R_output": 0.3633,
    "AC_S_output": 0.39963,
    "AC_T_output": 0.439593,
    "dc_current": 0.00155147,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.21522,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000211,
    "carbon offset": 0.26375,
    "trees planted": 5.861111111,
    "DC_R_output": 0.119889,
    "DC_S_output": 0.1318779,
    "DC_T_output": 0.14506569
  },
  {
    "datetime": "10/30/2024 12:00",
    "plant_id": "Green-tek-A27191",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "12:00:00 PM",
    "power": 0.836,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.20706,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "10/30/2024 12:15",
    "plant_id": "Green-tek-A27192",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "12:15:00 PM",
    "power": 0.811,
    "Energy": 0.203,
    "output Energy": 0.203,
    "Ac output": 1.203,
    "dc output": 1.22706,
    "AC_R_i": 0.00026478,
    "AC_S_i": 0.000291258,
    "AC_T_i": 0.00032038,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0608994,
    "AC_S_pow": 0.06698934,
    "AC_T_Pow": 0.0736874,
    "AC_R_output": 0.3609,
    "AC_S_output": 0.39699,
    "AC_T_output": 0.436689,
    "dc_current": 0.00149265,
    "dc_voltage": 136,
    "dc_power": 0.19686,
    "dc_output": 0.20706,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000203,
    "carbon offset": 0.25375,
    "trees planted": 5.638888889,
    "DC_R_output": 0.119097,
    "DC_S_output": 0.1310067,
    "DC_T_output": 0.14410737
  },
  {
    "datetime": "10/30/2024 12:30",
    "plant_id": "Green-tek-A27193",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "12:30:00 PM",
    "power": 0.77,
    "Energy": 0.193,
    "output Energy": 0.193,
    "Ac output": 1.193,
    "dc output": 1.21686,
    "AC_R_i": 0.00025174,
    "AC_S_i": 0.000276914,
    "AC_T_i": 0.00030461,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0579002,
    "AC_S_pow": 0.06369022,
    "AC_T_Pow": 0.0700603,
    "AC_R_output": 0.3579,
    "AC_S_output": 0.39369,
    "AC_T_output": 0.433059,
    "dc_current": 0.00141912,
    "dc_voltage": 136,
    "dc_power": 0.18258,
    "dc_output": 0.19686,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000193,
    "carbon offset": 0.24125,
    "trees planted": 5.361111111,
    "DC_R_output": 0.118107,
    "DC_S_output": 0.1299177,
    "DC_T_output": 0.14290947
  },
  {
    "datetime": "10/30/2024 12:45",
    "plant_id": "Green-tek-A27194",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "12:45:00 PM",
    "power": 0.717,
    "Energy": 0.179,
    "output Energy": 0.179,
    "Ac output": 1.179,
    "dc output": 1.20258,
    "AC_R_i": 0.00023348,
    "AC_S_i": 0.000256828,
    "AC_T_i": 0.00028251,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0537004,
    "AC_S_pow": 0.05907044,
    "AC_T_Pow": 0.0649773,
    "AC_R_output": 0.3537,
    "AC_S_output": 0.38907,
    "AC_T_output": 0.427977,
    "dc_current": 0.00131618,
    "dc_voltage": 136,
    "dc_power": 0.16728,
    "dc_output": 0.18258,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000179,
    "carbon offset": 0.22375,
    "trees planted": 4.972222222,
    "DC_R_output": 0.116721,
    "DC_S_output": 0.1283931,
    "DC_T_output": 0.14123241
  },
  {
    "datetime": "10/30/2024 13:00",
    "plant_id": "Green-tek-A27195",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "1:00:00 PM",
    "power": 0.654,
    "Energy": 0.164,
    "output Energy": 0.164,
    "Ac output": 1.164,
    "dc output": 1.18728,
    "AC_R_i": 0.00021391,
    "AC_S_i": 0.000235301,
    "AC_T_i": 0.00025883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0491993,
    "AC_S_pow": 0.05411923,
    "AC_T_Pow": 0.0595309,
    "AC_R_output": 0.3492,
    "AC_S_output": 0.38412,
    "AC_T_output": 0.422532,
    "dc_current": 0.00120588,
    "dc_voltage": 136,
    "dc_power": 0.14892,
    "dc_output": 0.16728,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000164,
    "carbon offset": 0.205,
    "trees planted": 4.555555556,
    "DC_R_output": 0.115236,
    "DC_S_output": 0.1267596,
    "DC_T_output": 0.13943556
  },
  {
    "datetime": "10/30/2024 13:15",
    "plant_id": "Green-tek-A27196",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "1:15:00 PM",
    "power": 0.585,
    "Energy": 0.146,
    "output Energy": 0.146,
    "Ac output": 1.146,
    "dc output": 1.16892,
    "AC_R_i": 0.00019043,
    "AC_S_i": 0.000209473,
    "AC_T_i": 0.00023042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0437989,
    "AC_S_pow": 0.04817879,
    "AC_T_Pow": 0.0529966,
    "AC_R_output": 0.3438,
    "AC_S_output": 0.37818,
    "AC_T_output": 0.415998,
    "dc_current": 0.00107353,
    "dc_voltage": 136,
    "dc_power": 0.13056,
    "dc_output": 0.14892,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000146,
    "carbon offset": 0.1825,
    "trees planted": 4.055555556,
    "DC_R_output": 0.113454,
    "DC_S_output": 0.1247994,
    "DC_T_output": 0.13727934
  },
  {
    "datetime": "10/30/2024 13:30",
    "plant_id": "Green-tek-A27197",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "1:30:00 PM",
    "power": 0.512,
    "Energy": 0.128,
    "output Energy": 0.128,
    "Ac output": 1.128,
    "dc output": 1.15056,
    "AC_R_i": 0.00016696,
    "AC_S_i": 0.000183656,
    "AC_T_i": 0.00020202,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0384008,
    "AC_S_pow": 0.04224088,
    "AC_T_Pow": 0.0464646,
    "AC_R_output": 0.3384,
    "AC_S_output": 0.37224,
    "AC_T_output": 0.409464,
    "dc_current": 0.00094118,
    "dc_voltage": 136,
    "dc_power": 0.1122,
    "dc_output": 0.13056,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000128,
    "carbon offset": 0.16,
    "trees planted": 3.555555556,
    "DC_R_output": 0.111672,
    "DC_S_output": 0.1228392,
    "DC_T_output": 0.13512312
  },
  {
    "datetime": "10/30/2024 13:45",
    "plant_id": "Green-tek-A27198",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "1:45:00 PM",
    "power": 0.439,
    "Energy": 0.11,
    "output Energy": 0.11,
    "Ac output": 1.11,
    "dc output": 1.1322,
    "AC_R_i": 0.00014348,
    "AC_S_i": 0.000157828,
    "AC_T_i": 0.00017361,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0330004,
    "AC_S_pow": 0.03630044,
    "AC_T_Pow": 0.0399303,
    "AC_R_output": 0.333,
    "AC_S_output": 0.3663,
    "AC_T_output": 0.40293,
    "dc_current": 0.00080882,
    "dc_voltage": 136,
    "dc_power": 0.09384,
    "dc_output": 0.1122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00011,
    "carbon offset": 0.1375,
    "trees planted": 3.055555556,
    "DC_R_output": 0.10989,
    "DC_S_output": 0.120879,
    "DC_T_output": 0.1329669
  },
  {
    "datetime": "10/30/2024 14:00",
    "plant_id": "Green-tek-A27199",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "2:00:00 PM",
    "power": 0.369,
    "Energy": 0.092,
    "output Energy": 0.092,
    "Ac output": 1.092,
    "dc output": 1.11384,
    "AC_R_i": 0.00012,
    "AC_S_i": 0.000132,
    "AC_T_i": 0.0001452,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0276,
    "AC_S_pow": 0.03036,
    "AC_T_Pow": 0.033396,
    "AC_R_output": 0.3276,
    "AC_S_output": 0.36036,
    "AC_T_output": 0.396396,
    "dc_current": 0.00067647,
    "dc_voltage": 136,
    "dc_power": 0.07752,
    "dc_output": 0.09384,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000092,
    "carbon offset": 0.115,
    "trees planted": 2.555555556,
    "DC_R_output": 0.108108,
    "DC_S_output": 0.1189188,
    "DC_T_output": 0.13081068
  },
  {
    "datetime": "10/30/2024 14:15",
    "plant_id": "Green-tek-A27200",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "2:15:00 PM",
    "power": 0.304,
    "Energy": 0.076,
    "output Energy": 0.076,
    "Ac output": 1.076,
    "dc output": 1.09752,
    "AC_R_i": 0.00009913,
    "AC_S_i": 0.000109043,
    "AC_T_i": 0.00011995,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0227999,
    "AC_S_pow": 0.02507989,
    "AC_T_Pow": 0.0275885,
    "AC_R_output": 0.3228,
    "AC_S_output": 0.35508,
    "AC_T_output": 0.390588,
    "dc_current": 0.00055882,
    "dc_voltage": 136,
    "dc_power": 0.06222,
    "dc_output": 0.07752,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000076,
    "carbon offset": 0.095,
    "trees planted": 2.111111111,
    "DC_R_output": 0.106524,
    "DC_S_output": 0.1171764,
    "DC_T_output": 0.12889404
  },
  {
    "datetime": "10/30/2024 14:30",
    "plant_id": "Green-tek-A27201",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "2:30:00 PM",
    "power": 0.246,
    "Energy": 0.061,
    "output Energy": 0.061,
    "Ac output": 1.061,
    "dc output": 1.08222,
    "AC_R_i": 0.00007957,
    "AC_S_i": 0.000087527,
    "AC_T_i": 0.00009628,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0183011,
    "AC_S_pow": 0.02013121,
    "AC_T_Pow": 0.0221444,
    "AC_R_output": 0.3183,
    "AC_S_output": 0.35013,
    "AC_T_output": 0.385143,
    "dc_current": 0.00044853,
    "dc_voltage": 136,
    "dc_power": 0.04998,
    "dc_output": 0.06222,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000061,
    "carbon offset": 0.07625,
    "trees planted": 1.694444444,
    "DC_R_output": 0.105039,
    "DC_S_output": 0.1155429,
    "DC_T_output": 0.12709719
  },
  {
    "datetime": "10/30/2024 14:45",
    "plant_id": "Green-tek-A27202",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "2:45:00 PM",
    "power": 0.194,
    "Energy": 0.049,
    "output Energy": 0.049,
    "Ac output": 1.049,
    "dc output": 1.06998,
    "AC_R_i": 0.00006391,
    "AC_S_i": 0.000070301,
    "AC_T_i": 0.00007733,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0146993,
    "AC_S_pow": 0.01616923,
    "AC_T_Pow": 0.0177859,
    "AC_R_output": 0.3147,
    "AC_S_output": 0.34617,
    "AC_T_output": 0.380787,
    "dc_current": 0.00036029,
    "dc_voltage": 136,
    "dc_power": 0.03876,
    "dc_output": 0.04998,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000049,
    "carbon offset": 0.06125,
    "trees planted": 1.361111111,
    "DC_R_output": 0.103851,
    "DC_S_output": 0.1142361,
    "DC_T_output": 0.12565971
  },
  {
    "datetime": "10/30/2024 15:00",
    "plant_id": "Green-tek-A27203",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "3:00:00 PM",
    "power": 0.151,
    "Energy": 0.038,
    "output Energy": 0.038,
    "Ac output": 1.038,
    "dc output": 1.05876,
    "AC_R_i": 0.00004957,
    "AC_S_i": 0.000054527,
    "AC_T_i": 0.00005998,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0114011,
    "AC_S_pow": 0.01254121,
    "AC_T_Pow": 0.0137954,
    "AC_R_output": 0.3114,
    "AC_S_output": 0.34254,
    "AC_T_output": 0.376794,
    "dc_current": 0.00027941,
    "dc_voltage": 136,
    "dc_power": 0.02958,
    "dc_output": 0.03876,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000038,
    "carbon offset": 0.0475,
    "trees planted": 1.055555556,
    "DC_R_output": 0.102762,
    "DC_S_output": 0.1130382,
    "DC_T_output": 0.12434202
  },
  {
    "datetime": "10/30/2024 15:15",
    "plant_id": "Green-tek-A27204",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "3:15:00 PM",
    "power": 0.114,
    "Energy": 0.029,
    "output Energy": 0.029,
    "Ac output": 1.029,
    "dc output": 1.04958,
    "AC_R_i": 0.00003783,
    "AC_S_i": 0.000041613,
    "AC_T_i": 0.00004577,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0087009,
    "AC_S_pow": 0.00957099,
    "AC_T_Pow": 0.0105271,
    "AC_R_output": 0.3087,
    "AC_S_output": 0.33957,
    "AC_T_output": 0.373527,
    "dc_current": 0.00021324,
    "dc_voltage": 136,
    "dc_power": 0.02142,
    "dc_output": 0.02958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000029,
    "carbon offset": 0.03625,
    "trees planted": 0.805555556,
    "DC_R_output": 0.101871,
    "DC_S_output": 0.1120581,
    "DC_T_output": 0.12326391
  },
  {
    "datetime": "10/30/2024 15:30",
    "plant_id": "Green-tek-A27205",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "3:30:00 PM",
    "power": 0.085,
    "Energy": 0.021,
    "output Energy": 0.021,
    "Ac output": 1.021,
    "dc output": 1.04142,
    "AC_R_i": 0.00002739,
    "AC_S_i": 0.000030129,
    "AC_T_i": 0.00003314,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0062997,
    "AC_S_pow": 0.00692967,
    "AC_T_Pow": 0.0076222,
    "AC_R_output": 0.3063,
    "AC_S_output": 0.33693,
    "AC_T_output": 0.370623,
    "dc_current": 0.00015441,
    "dc_voltage": 136,
    "dc_power": 0.0153,
    "dc_output": 0.02142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000021,
    "carbon offset": 0.02625,
    "trees planted": 0.583333333,
    "DC_R_output": 0.101079,
    "DC_S_output": 0.1111869,
    "DC_T_output": 0.12230559
  },
  {
    "datetime": "10/30/2024 15:45",
    "plant_id": "Green-tek-A27206",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "3:45:00 PM",
    "power": 0.062,
    "Energy": 0.015,
    "output Energy": 0.015,
    "Ac output": 1.015,
    "dc output": 1.0353,
    "AC_R_i": 0.00001957,
    "AC_S_i": 0.000021527,
    "AC_T_i": 0.00002368,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0045011,
    "AC_S_pow": 0.00495121,
    "AC_T_Pow": 0.0054464,
    "AC_R_output": 0.3045,
    "AC_S_output": 0.33495,
    "AC_T_output": 0.368445,
    "dc_current": 0.00011029,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.0153,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000015,
    "carbon offset": 0.01875,
    "trees planted": 0.416666667,
    "DC_R_output": 0.100485,
    "DC_S_output": 0.1105335,
    "DC_T_output": 0.12158685
  },
  {
    "datetime": "10/30/2024 16:00",
    "plant_id": "Green-tek-A27207",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "4:00:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/30/2024 16:15",
    "plant_id": "Green-tek-A27208",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "4:15:00 PM",
    "power": 0.031,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/30/2024 16:30",
    "plant_id": "Green-tek-A27209",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "4:30:00 PM",
    "power": 0.021,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00408,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/30/2024 16:45",
    "plant_id": "Green-tek-A27210",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "4:45:00 PM",
    "power": 0.014,
    "Energy": 0.004,
    "output Energy": 0.004,
    "Ac output": 1.004,
    "dc output": 1.02408,
    "AC_R_i": 0.00000522,
    "AC_S_i": 0.000005742,
    "AC_T_i": 0.00000632,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0012006,
    "AC_S_pow": 0.00132066,
    "AC_T_Pow": 0.0014536,
    "AC_R_output": 0.3012,
    "AC_S_output": 0.33132,
    "AC_T_output": 0.364452,
    "dc_current": 0.00002941,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00408,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000004,
    "carbon offset": 0.005,
    "trees planted": 0.111111111,
    "DC_R_output": 0.099396,
    "DC_S_output": 0.1093356,
    "DC_T_output": 0.12026916
  },
  {
    "datetime": "10/30/2024 17:00",
    "plant_id": "Green-tek-A27211",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "5:00:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/30/2024 17:15",
    "plant_id": "Green-tek-A27212",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "5:15:00 PM",
    "power": 0.006,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/30/2024 17:30",
    "plant_id": "Green-tek-A27213",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/30/2024",
    "time": "5:30:00 PM",
    "power": 0.004,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/31/2024 6:00",
    "plant_id": "Green-tek-A27214",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "10/31/2024 6:15",
    "plant_id": "Green-tek-A27215",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "6:15:00 AM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/31/2024 6:30",
    "plant_id": "Green-tek-A27216",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "6:30:00 AM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/31/2024 6:45",
    "plant_id": "Green-tek-A27217",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "6:45:00 AM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/31/2024 7:00",
    "plant_id": "Green-tek-A27218",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "7:00:00 AM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/31/2024 7:15",
    "plant_id": "Green-tek-A27219",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "7:15:00 AM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/31/2024 7:30",
    "plant_id": "Green-tek-A27220",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "7:30:00 AM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/31/2024 7:45",
    "plant_id": "Green-tek-A27221",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "7:45:00 AM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "10/31/2024 8:00",
    "plant_id": "Green-tek-A27222",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "8:00:00 AM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/31/2024 8:15",
    "plant_id": "Green-tek-A27223",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "8:15:00 AM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "10/31/2024 8:30",
    "plant_id": "Green-tek-A27224",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "8:30:00 AM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "10/31/2024 8:45",
    "plant_id": "Green-tek-A27225",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "8:45:00 AM",
    "power": 0.278,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "10/31/2024 9:00",
    "plant_id": "Green-tek-A27226",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "9:00:00 AM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "10/31/2024 9:15",
    "plant_id": "Green-tek-A27227",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "9:15:00 AM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "10/31/2024 9:30",
    "plant_id": "Green-tek-A27228",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "9:30:00 AM",
    "power": 0.529,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "10/31/2024 9:45",
    "plant_id": "Green-tek-A27229",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "9:45:00 AM",
    "power": 0.629,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "10/31/2024 10:00",
    "plant_id": "Green-tek-A27230",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "10:00:00 AM",
    "power": 0.733,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "10/31/2024 10:15",
    "plant_id": "Green-tek-A27231",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "10:15:00 AM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "10/31/2024 10:30",
    "plant_id": "Green-tek-A27232",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "10:30:00 AM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "10/31/2024 10:45",
    "plant_id": "Green-tek-A27233",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "10:45:00 AM",
    "power": 1.027,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "10/31/2024 11:00",
    "plant_id": "Green-tek-A27234",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "11:00:00 AM",
    "power": 1.103,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "10/31/2024 11:15",
    "plant_id": "Green-tek-A27235",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "11:15:00 AM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "10/31/2024 11:30",
    "plant_id": "Green-tek-A27236",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "11:30:00 AM",
    "power": 1.197,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.30804,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "10/31/2024 11:45",
    "plant_id": "Green-tek-A27237",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "11:45:00 AM",
    "power": 1.209,
    "Energy": 0.302,
    "output Energy": 0.302,
    "Ac output": 1.302,
    "dc output": 1.32804,
    "AC_R_i": 0.00039391,
    "AC_S_i": 0.000433301,
    "AC_T_i": 0.00047663,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0905993,
    "AC_S_pow": 0.09965923,
    "AC_T_Pow": 0.1096249,
    "AC_R_output": 0.3906,
    "AC_S_output": 0.42966,
    "AC_T_output": 0.472626,
    "dc_current": 0.00222059,
    "dc_voltage": 136,
    "dc_power": 0.30498,
    "dc_output": 0.30804,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000302,
    "carbon offset": 0.3775,
    "trees planted": 8.388888889,
    "DC_R_output": 0.128898,
    "DC_S_output": 0.1417878,
    "DC_T_output": 0.15596658
  },
  {
    "datetime": "10/31/2024 12:00",
    "plant_id": "Green-tek-A27238",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "12:00:00 PM",
    "power": 1.197,
    "Energy": 0.299,
    "output Energy": 0.299,
    "Ac output": 1.299,
    "dc output": 1.32498,
    "AC_R_i": 0.00039,
    "AC_S_i": 0.000429,
    "AC_T_i": 0.0004719,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0897,
    "AC_S_pow": 0.09867,
    "AC_T_Pow": 0.108537,
    "AC_R_output": 0.3897,
    "AC_S_output": 0.42867,
    "AC_T_output": 0.471537,
    "dc_current": 0.00219853,
    "dc_voltage": 136,
    "dc_power": 0.2958,
    "dc_output": 0.30498,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000299,
    "carbon offset": 0.37375,
    "trees planted": 8.305555556,
    "DC_R_output": 0.128601,
    "DC_S_output": 0.1414611,
    "DC_T_output": 0.15560721
  },
  {
    "datetime": "10/31/2024 12:15",
    "plant_id": "Green-tek-A27239",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "12:15:00 PM",
    "power": 1.161,
    "Energy": 0.29,
    "output Energy": 0.29,
    "Ac output": 1.29,
    "dc output": 1.3158,
    "AC_R_i": 0.00037826,
    "AC_S_i": 0.000416086,
    "AC_T_i": 0.00045769,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0869998,
    "AC_S_pow": 0.09569978,
    "AC_T_Pow": 0.1052687,
    "AC_R_output": 0.387,
    "AC_S_output": 0.4257,
    "AC_T_output": 0.46827,
    "dc_current": 0.00213235,
    "dc_voltage": 136,
    "dc_power": 0.28152,
    "dc_output": 0.2958,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00029,
    "carbon offset": 0.3625,
    "trees planted": 8.055555556,
    "DC_R_output": 0.12771,
    "DC_S_output": 0.140481,
    "DC_T_output": 0.1545291
  },
  {
    "datetime": "10/31/2024 12:30",
    "plant_id": "Green-tek-A27240",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "12:30:00 PM",
    "power": 1.103,
    "Energy": 0.276,
    "output Energy": 0.276,
    "Ac output": 1.276,
    "dc output": 1.30152,
    "AC_R_i": 0.00036,
    "AC_S_i": 0.000396,
    "AC_T_i": 0.0004356,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0828,
    "AC_S_pow": 0.09108,
    "AC_T_Pow": 0.100188,
    "AC_R_output": 0.3828,
    "AC_S_output": 0.42108,
    "AC_T_output": 0.463188,
    "dc_current": 0.00202941,
    "dc_voltage": 136,
    "dc_power": 0.26214,
    "dc_output": 0.28152,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000276,
    "carbon offset": 0.345,
    "trees planted": 7.666666667,
    "DC_R_output": 0.126324,
    "DC_S_output": 0.1389564,
    "DC_T_output": 0.15285204
  },
  {
    "datetime": "10/31/2024 12:45",
    "plant_id": "Green-tek-A27241",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "12:45:00 PM",
    "power": 1.027,
    "Energy": 0.257,
    "output Energy": 0.257,
    "Ac output": 1.257,
    "dc output": 1.28214,
    "AC_R_i": 0.00033522,
    "AC_S_i": 0.000368742,
    "AC_T_i": 0.00040562,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0771006,
    "AC_S_pow": 0.08481066,
    "AC_T_Pow": 0.0932926,
    "AC_R_output": 0.3771,
    "AC_S_output": 0.41481,
    "AC_T_output": 0.456291,
    "dc_current": 0.00188971,
    "dc_voltage": 136,
    "dc_power": 0.23868,
    "dc_output": 0.26214,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000257,
    "carbon offset": 0.32125,
    "trees planted": 7.138888889,
    "DC_R_output": 0.124443,
    "DC_S_output": 0.1368873,
    "DC_T_output": 0.15057603
  },
  {
    "datetime": "10/31/2024 13:00",
    "plant_id": "Green-tek-A27242",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "1:00:00 PM",
    "power": 0.937,
    "Energy": 0.234,
    "output Energy": 0.234,
    "Ac output": 1.234,
    "dc output": 1.25868,
    "AC_R_i": 0.00030522,
    "AC_S_i": 0.000335742,
    "AC_T_i": 0.00036932,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0702006,
    "AC_S_pow": 0.07722066,
    "AC_T_Pow": 0.0849436,
    "AC_R_output": 0.3702,
    "AC_S_output": 0.40722,
    "AC_T_output": 0.447942,
    "dc_current": 0.00172059,
    "dc_voltage": 136,
    "dc_power": 0.21318,
    "dc_output": 0.23868,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000234,
    "carbon offset": 0.2925,
    "trees planted": 6.5,
    "DC_R_output": 0.122166,
    "DC_S_output": 0.1343826,
    "DC_T_output": 0.14782086
  },
  {
    "datetime": "10/31/2024 13:15",
    "plant_id": "Green-tek-A27243",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "1:15:00 PM",
    "power": 0.837,
    "Energy": 0.209,
    "output Energy": 0.209,
    "Ac output": 1.209,
    "dc output": 1.23318,
    "AC_R_i": 0.00027261,
    "AC_S_i": 0.000299871,
    "AC_T_i": 0.00032986,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0627003,
    "AC_S_pow": 0.06897033,
    "AC_T_Pow": 0.0758678,
    "AC_R_output": 0.3627,
    "AC_S_output": 0.39897,
    "AC_T_output": 0.438867,
    "dc_current": 0.00153676,
    "dc_voltage": 136,
    "dc_power": 0.18666,
    "dc_output": 0.21318,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000209,
    "carbon offset": 0.26125,
    "trees planted": 5.805555556,
    "DC_R_output": 0.119691,
    "DC_S_output": 0.1316601,
    "DC_T_output": 0.14482611
  },
  {
    "datetime": "10/31/2024 13:30",
    "plant_id": "Green-tek-A27244",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "1:30:00 PM",
    "power": 0.733,
    "Energy": 0.183,
    "output Energy": 0.183,
    "Ac output": 1.183,
    "dc output": 1.20666,
    "AC_R_i": 0.0002387,
    "AC_S_i": 0.00026257,
    "AC_T_i": 0.00028883,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.054901,
    "AC_S_pow": 0.0603911,
    "AC_T_Pow": 0.0664309,
    "AC_R_output": 0.3549,
    "AC_S_output": 0.39039,
    "AC_T_output": 0.429429,
    "dc_current": 0.00134559,
    "dc_voltage": 136,
    "dc_power": 0.16014,
    "dc_output": 0.18666,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000183,
    "carbon offset": 0.22875,
    "trees planted": 5.083333333,
    "DC_R_output": 0.117117,
    "DC_S_output": 0.1288287,
    "DC_T_output": 0.14171157
  },
  {
    "datetime": "10/31/2024 13:45",
    "plant_id": "Green-tek-A27245",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "1:45:00 PM",
    "power": 0.629,
    "Energy": 0.157,
    "output Energy": 0.157,
    "Ac output": 1.157,
    "dc output": 1.18014,
    "AC_R_i": 0.00020478,
    "AC_S_i": 0.000225258,
    "AC_T_i": 0.00024778,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0470994,
    "AC_S_pow": 0.05180934,
    "AC_T_Pow": 0.0569894,
    "AC_R_output": 0.3471,
    "AC_S_output": 0.38181,
    "AC_T_output": 0.419991,
    "dc_current": 0.00115441,
    "dc_voltage": 136,
    "dc_power": 0.13464,
    "dc_output": 0.16014,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000157,
    "carbon offset": 0.19625,
    "trees planted": 4.361111111,
    "DC_R_output": 0.114543,
    "DC_S_output": 0.1259973,
    "DC_T_output": 0.13859703
  },
  {
    "datetime": "10/31/2024 14:00",
    "plant_id": "Green-tek-A27246",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "2:00:00 PM",
    "power": 0.529,
    "Energy": 0.132,
    "output Energy": 0.132,
    "Ac output": 1.132,
    "dc output": 1.15464,
    "AC_R_i": 0.00017217,
    "AC_S_i": 0.000189387,
    "AC_T_i": 0.00020833,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0395991,
    "AC_S_pow": 0.04355901,
    "AC_T_Pow": 0.0479159,
    "AC_R_output": 0.3396,
    "AC_S_output": 0.37356,
    "AC_T_output": 0.410916,
    "dc_current": 0.00097059,
    "dc_voltage": 136,
    "dc_power": 0.11118,
    "dc_output": 0.13464,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000132,
    "carbon offset": 0.165,
    "trees planted": 3.666666667,
    "DC_R_output": 0.112068,
    "DC_S_output": 0.1232748,
    "DC_T_output": 0.13560228
  },
  {
    "datetime": "10/31/2024 14:15",
    "plant_id": "Green-tek-A27247",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "2:15:00 PM",
    "power": 0.436,
    "Energy": 0.109,
    "output Energy": 0.109,
    "Ac output": 1.109,
    "dc output": 1.13118,
    "AC_R_i": 0.00014217,
    "AC_S_i": 0.000156387,
    "AC_T_i": 0.00017203,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0326991,
    "AC_S_pow": 0.03596901,
    "AC_T_Pow": 0.0395669,
    "AC_R_output": 0.3327,
    "AC_S_output": 0.36597,
    "AC_T_output": 0.402567,
    "dc_current": 0.00080147,
    "dc_voltage": 136,
    "dc_power": 0.08976,
    "dc_output": 0.11118,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000109,
    "carbon offset": 0.13625,
    "trees planted": 3.027777778,
    "DC_R_output": 0.109791,
    "DC_S_output": 0.1207701,
    "DC_T_output": 0.13284711
  },
  {
    "datetime": "10/31/2024 14:30",
    "plant_id": "Green-tek-A27248",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "2:30:00 PM",
    "power": 0.352,
    "Energy": 0.088,
    "output Energy": 0.088,
    "Ac output": 1.088,
    "dc output": 1.10976,
    "AC_R_i": 0.00011478,
    "AC_S_i": 0.000126258,
    "AC_T_i": 0.00013888,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0263994,
    "AC_S_pow": 0.02903934,
    "AC_T_Pow": 0.0319424,
    "AC_R_output": 0.3264,
    "AC_S_output": 0.35904,
    "AC_T_output": 0.394944,
    "dc_current": 0.00064706,
    "dc_voltage": 136,
    "dc_power": 0.0714,
    "dc_output": 0.08976,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000088,
    "carbon offset": 0.11,
    "trees planted": 2.444444444,
    "DC_R_output": 0.107712,
    "DC_S_output": 0.1184832,
    "DC_T_output": 0.13033152
  },
  {
    "datetime": "10/31/2024 14:45",
    "plant_id": "Green-tek-A27249",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "2:45:00 PM",
    "power": 0.278,
    "Energy": 0.07,
    "output Energy": 0.07,
    "Ac output": 1.07,
    "dc output": 1.0914,
    "AC_R_i": 0.0000913,
    "AC_S_i": 0.00010043,
    "AC_T_i": 0.00011047,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.020999,
    "AC_S_pow": 0.0230989,
    "AC_T_Pow": 0.0254081,
    "AC_R_output": 0.321,
    "AC_S_output": 0.3531,
    "AC_T_output": 0.38841,
    "dc_current": 0.00051471,
    "dc_voltage": 136,
    "dc_power": 0.05508,
    "dc_output": 0.0714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00007,
    "carbon offset": 0.0875,
    "trees planted": 1.944444444,
    "DC_R_output": 0.10593,
    "DC_S_output": 0.116523,
    "DC_T_output": 0.1281753
  },
  {
    "datetime": "10/31/2024 15:00",
    "plant_id": "Green-tek-A27250",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "3:00:00 PM",
    "power": 0.216,
    "Energy": 0.054,
    "output Energy": 0.054,
    "Ac output": 1.054,
    "dc output": 1.07508,
    "AC_R_i": 0.00007043,
    "AC_S_i": 0.000077473,
    "AC_T_i": 0.00008522,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0161989,
    "AC_S_pow": 0.01781879,
    "AC_T_Pow": 0.0196006,
    "AC_R_output": 0.3162,
    "AC_S_output": 0.34782,
    "AC_T_output": 0.382602,
    "dc_current": 0.00039706,
    "dc_voltage": 136,
    "dc_power": 0.04182,
    "dc_output": 0.05508,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000054,
    "carbon offset": 0.0675,
    "trees planted": 1.5,
    "DC_R_output": 0.104346,
    "DC_S_output": 0.1147806,
    "DC_T_output": 0.12625866
  },
  {
    "datetime": "10/31/2024 15:15",
    "plant_id": "Green-tek-A27251",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "3:15:00 PM",
    "power": 0.164,
    "Energy": 0.041,
    "output Energy": 0.041,
    "Ac output": 1.041,
    "dc output": 1.06182,
    "AC_R_i": 0.00005348,
    "AC_S_i": 0.000058828,
    "AC_T_i": 0.00006471,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0123004,
    "AC_S_pow": 0.01353044,
    "AC_T_Pow": 0.0148833,
    "AC_R_output": 0.3123,
    "AC_S_output": 0.34353,
    "AC_T_output": 0.377883,
    "dc_current": 0.00030147,
    "dc_voltage": 136,
    "dc_power": 0.0306,
    "dc_output": 0.04182,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000041,
    "carbon offset": 0.05125,
    "trees planted": 1.138888889,
    "DC_R_output": 0.103059,
    "DC_S_output": 0.1133649,
    "DC_T_output": 0.12470139
  },
  {
    "datetime": "10/31/2024 15:30",
    "plant_id": "Green-tek-A27252",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "3:30:00 PM",
    "power": 0.122,
    "Energy": 0.03,
    "output Energy": 0.03,
    "Ac output": 1.03,
    "dc output": 1.0506,
    "AC_R_i": 0.00003913,
    "AC_S_i": 0.000043043,
    "AC_T_i": 0.00004735,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0089999,
    "AC_S_pow": 0.00989989,
    "AC_T_Pow": 0.0108905,
    "AC_R_output": 0.309,
    "AC_S_output": 0.3399,
    "AC_T_output": 0.37389,
    "dc_current": 0.00022059,
    "dc_voltage": 136,
    "dc_power": 0.02244,
    "dc_output": 0.0306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00003,
    "carbon offset": 0.0375,
    "trees planted": 0.833333333,
    "DC_R_output": 0.10197,
    "DC_S_output": 0.112167,
    "DC_T_output": 0.1233837
  },
  {
    "datetime": "10/31/2024 15:45",
    "plant_id": "Green-tek-A27253",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "3:45:00 PM",
    "power": 0.089,
    "Energy": 0.022,
    "output Energy": 0.022,
    "Ac output": 1.022,
    "dc output": 1.04244,
    "AC_R_i": 0.0000287,
    "AC_S_i": 0.00003157,
    "AC_T_i": 0.00003473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.006601,
    "AC_S_pow": 0.0072611,
    "AC_T_Pow": 0.0079879,
    "AC_R_output": 0.3066,
    "AC_S_output": 0.33726,
    "AC_T_output": 0.370986,
    "dc_current": 0.00016176,
    "dc_voltage": 136,
    "dc_power": 0.01632,
    "dc_output": 0.02244,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000022,
    "carbon offset": 0.0275,
    "trees planted": 0.611111111,
    "DC_R_output": 0.101178,
    "DC_S_output": 0.1112958,
    "DC_T_output": 0.12242538
  },
  {
    "datetime": "10/31/2024 16:00",
    "plant_id": "Green-tek-A27254",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "4:00:00 PM",
    "power": 0.063,
    "Energy": 0.016,
    "output Energy": 0.016,
    "Ac output": 1.016,
    "dc output": 1.03632,
    "AC_R_i": 0.00002087,
    "AC_S_i": 0.000022957,
    "AC_T_i": 0.00002525,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0048001,
    "AC_S_pow": 0.00528011,
    "AC_T_Pow": 0.0058075,
    "AC_R_output": 0.3048,
    "AC_S_output": 0.33528,
    "AC_T_output": 0.368808,
    "dc_current": 0.00011765,
    "dc_voltage": 136,
    "dc_power": 0.01122,
    "dc_output": 0.01632,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000016,
    "carbon offset": 0.02,
    "trees planted": 0.444444444,
    "DC_R_output": 0.100584,
    "DC_S_output": 0.1106424,
    "DC_T_output": 0.12170664
  },
  {
    "datetime": "10/31/2024 16:15",
    "plant_id": "Green-tek-A27255",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "4:15:00 PM",
    "power": 0.044,
    "Energy": 0.011,
    "output Energy": 0.011,
    "Ac output": 1.011,
    "dc output": 1.03122,
    "AC_R_i": 0.00001435,
    "AC_S_i": 0.000015785,
    "AC_T_i": 0.00001736,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0033005,
    "AC_S_pow": 0.00363055,
    "AC_T_Pow": 0.0039928,
    "AC_R_output": 0.3033,
    "AC_S_output": 0.33363,
    "AC_T_output": 0.366993,
    "dc_current": 0.00008088,
    "dc_voltage": 136,
    "dc_power": 0.00816,
    "dc_output": 0.01122,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000011,
    "carbon offset": 0.01375,
    "trees planted": 0.305555556,
    "DC_R_output": 0.100089,
    "DC_S_output": 0.1100979,
    "DC_T_output": 0.12110769
  },
  {
    "datetime": "10/31/2024 16:30",
    "plant_id": "Green-tek-A27256",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "4:30:00 PM",
    "power": 0.03,
    "Energy": 0.008,
    "output Energy": 0.008,
    "Ac output": 1.008,
    "dc output": 1.02816,
    "AC_R_i": 0.00001043,
    "AC_S_i": 0.000011473,
    "AC_T_i": 0.00001262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0023989,
    "AC_S_pow": 0.00263879,
    "AC_T_Pow": 0.0029026,
    "AC_R_output": 0.3024,
    "AC_S_output": 0.33264,
    "AC_T_output": 0.365904,
    "dc_current": 0.00005882,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00816,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000008,
    "carbon offset": 0.01,
    "trees planted": 0.222222222,
    "DC_R_output": 0.099792,
    "DC_S_output": 0.1097712,
    "DC_T_output": 0.12074832
  },
  {
    "datetime": "10/31/2024 16:45",
    "plant_id": "Green-tek-A27257",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "4:45:00 PM",
    "power": 0.02,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "10/31/2024 17:00",
    "plant_id": "Green-tek-A27258",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "5:00:00 PM",
    "power": 0.013,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "10/31/2024 17:15",
    "plant_id": "Green-tek-A27259",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "5:15:00 PM",
    "power": 0.009,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "10/31/2024 17:30",
    "plant_id": "Green-tek-A27260",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "10/31/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "11/1/2024 6:00",
    "plant_id": "Green-tek-A27261",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "6:00:00 AM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  },
  {
    "datetime": "11/1/2024 6:15",
    "plant_id": "Green-tek-A27262",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "6:15:00 AM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "11/1/2024 6:30",
    "plant_id": "Green-tek-A27263",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "6:30:00 AM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "11/1/2024 6:45",
    "plant_id": "Green-tek-A27264",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "6:45:00 AM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "11/1/2024 7:00",
    "plant_id": "Green-tek-A27265",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "7:00:00 AM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "11/1/2024 7:15",
    "plant_id": "Green-tek-A27266",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "7:15:00 AM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "11/1/2024 7:30",
    "plant_id": "Green-tek-A27267",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "7:30:00 AM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "11/1/2024 7:45",
    "plant_id": "Green-tek-A27268",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "7:45:00 AM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "11/1/2024 8:00",
    "plant_id": "Green-tek-A27269",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "8:00:00 AM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "11/1/2024 8:15",
    "plant_id": "Green-tek-A27270",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "8:15:00 AM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "11/1/2024 8:30",
    "plant_id": "Green-tek-A27271",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "8:30:00 AM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "11/1/2024 8:45",
    "plant_id": "Green-tek-A27272",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "8:45:00 AM",
    "power": 0.25,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "11/1/2024 9:00",
    "plant_id": "Green-tek-A27273",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "9:00:00 AM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "11/1/2024 9:15",
    "plant_id": "Green-tek-A27274",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "9:15:00 AM",
    "power": 0.391,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "11/1/2024 9:30",
    "plant_id": "Green-tek-A27275",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "9:30:00 AM",
    "power": 0.475,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "11/1/2024 9:45",
    "plant_id": "Green-tek-A27276",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "9:45:00 AM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "11/1/2024 10:00",
    "plant_id": "Green-tek-A27277",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "10:00:00 AM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "11/1/2024 10:15",
    "plant_id": "Green-tek-A27278",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "10:15:00 AM",
    "power": 0.752,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "11/1/2024 10:30",
    "plant_id": "Green-tek-A27279",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "10:30:00 AM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "11/1/2024 10:45",
    "plant_id": "Green-tek-A27280",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "10:45:00 AM",
    "power": 0.922,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "11/1/2024 11:00",
    "plant_id": "Green-tek-A27281",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "11:00:00 AM",
    "power": 0.99,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "11/1/2024 11:15",
    "plant_id": "Green-tek-A27282",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "11:15:00 AM",
    "power": 1.042,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "11/1/2024 11:30",
    "plant_id": "Green-tek-A27283",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "11:30:00 AM",
    "power": 1.074,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.27642,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "11/1/2024 11:45",
    "plant_id": "Green-tek-A27284",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "11:45:00 AM",
    "power": 1.085,
    "Energy": 0.271,
    "output Energy": 0.271,
    "Ac output": 1.271,
    "dc output": 1.29642,
    "AC_R_i": 0.00035348,
    "AC_S_i": 0.000388828,
    "AC_T_i": 0.00042771,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0813004,
    "AC_S_pow": 0.08943044,
    "AC_T_Pow": 0.0983733,
    "AC_R_output": 0.3813,
    "AC_S_output": 0.41943,
    "AC_T_output": 0.461373,
    "dc_current": 0.00199265,
    "dc_voltage": 136,
    "dc_power": 0.27438,
    "dc_output": 0.27642,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000271,
    "carbon offset": 0.33875,
    "trees planted": 7.527777778,
    "DC_R_output": 0.125829,
    "DC_S_output": 0.1384119,
    "DC_T_output": 0.15225309
  },
  {
    "datetime": "11/1/2024 12:00",
    "plant_id": "Green-tek-A27285",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "12:00:00 PM",
    "power": 1.074,
    "Energy": 0.269,
    "output Energy": 0.269,
    "Ac output": 1.269,
    "dc output": 1.29438,
    "AC_R_i": 0.00035087,
    "AC_S_i": 0.000385957,
    "AC_T_i": 0.00042455,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0807001,
    "AC_S_pow": 0.08877011,
    "AC_T_Pow": 0.0976465,
    "AC_R_output": 0.3807,
    "AC_S_output": 0.41877,
    "AC_T_output": 0.460647,
    "dc_current": 0.00197794,
    "dc_voltage": 136,
    "dc_power": 0.2652,
    "dc_output": 0.27438,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000269,
    "carbon offset": 0.33625,
    "trees planted": 7.472222222,
    "DC_R_output": 0.125631,
    "DC_S_output": 0.1381941,
    "DC_T_output": 0.15201351
  },
  {
    "datetime": "11/1/2024 12:15",
    "plant_id": "Green-tek-A27286",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "12:15:00 PM",
    "power": 1.042,
    "Energy": 0.26,
    "output Energy": 0.26,
    "Ac output": 1.26,
    "dc output": 1.2852,
    "AC_R_i": 0.00033913,
    "AC_S_i": 0.000373043,
    "AC_T_i": 0.00041035,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0779999,
    "AC_S_pow": 0.08579989,
    "AC_T_Pow": 0.0943805,
    "AC_R_output": 0.378,
    "AC_S_output": 0.4158,
    "AC_T_output": 0.45738,
    "dc_current": 0.00191176,
    "dc_voltage": 136,
    "dc_power": 0.25296,
    "dc_output": 0.2652,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00026,
    "carbon offset": 0.325,
    "trees planted": 7.222222222,
    "DC_R_output": 0.12474,
    "DC_S_output": 0.137214,
    "DC_T_output": 0.1509354
  },
  {
    "datetime": "11/1/2024 12:30",
    "plant_id": "Green-tek-A27287",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "12:30:00 PM",
    "power": 0.99,
    "Energy": 0.248,
    "output Energy": 0.248,
    "Ac output": 1.248,
    "dc output": 1.27296,
    "AC_R_i": 0.00032348,
    "AC_S_i": 0.000355828,
    "AC_T_i": 0.00039141,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0744004,
    "AC_S_pow": 0.08184044,
    "AC_T_Pow": 0.0900243,
    "AC_R_output": 0.3744,
    "AC_S_output": 0.41184,
    "AC_T_output": 0.453024,
    "dc_current": 0.00182353,
    "dc_voltage": 136,
    "dc_power": 0.2346,
    "dc_output": 0.25296,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000248,
    "carbon offset": 0.31,
    "trees planted": 6.888888889,
    "DC_R_output": 0.123552,
    "DC_S_output": 0.1359072,
    "DC_T_output": 0.14949792
  },
  {
    "datetime": "11/1/2024 12:45",
    "plant_id": "Green-tek-A27288",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "12:45:00 PM",
    "power": 0.922,
    "Energy": 0.23,
    "output Energy": 0.23,
    "Ac output": 1.23,
    "dc output": 1.2546,
    "AC_R_i": 0.0003,
    "AC_S_i": 0.00033,
    "AC_T_i": 0.000363,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.069,
    "AC_S_pow": 0.0759,
    "AC_T_Pow": 0.08349,
    "AC_R_output": 0.369,
    "AC_S_output": 0.4059,
    "AC_T_output": 0.44649,
    "dc_current": 0.00169118,
    "dc_voltage": 136,
    "dc_power": 0.2142,
    "dc_output": 0.2346,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00023,
    "carbon offset": 0.2875,
    "trees planted": 6.388888889,
    "DC_R_output": 0.12177,
    "DC_S_output": 0.133947,
    "DC_T_output": 0.1473417
  },
  {
    "datetime": "11/1/2024 13:00",
    "plant_id": "Green-tek-A27289",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "1:00:00 PM",
    "power": 0.841,
    "Energy": 0.21,
    "output Energy": 0.21,
    "Ac output": 1.21,
    "dc output": 1.2342,
    "AC_R_i": 0.00027391,
    "AC_S_i": 0.000301301,
    "AC_T_i": 0.00033143,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0629993,
    "AC_S_pow": 0.06929923,
    "AC_T_Pow": 0.0762289,
    "AC_R_output": 0.363,
    "AC_S_output": 0.3993,
    "AC_T_output": 0.43923,
    "dc_current": 0.00154412,
    "dc_voltage": 136,
    "dc_power": 0.19176,
    "dc_output": 0.2142,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00021,
    "carbon offset": 0.2625,
    "trees planted": 5.833333333,
    "DC_R_output": 0.11979,
    "DC_S_output": 0.131769,
    "DC_T_output": 0.1449459
  },
  {
    "datetime": "11/1/2024 13:15",
    "plant_id": "Green-tek-A27290",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "1:15:00 PM",
    "power": 0.752,
    "Energy": 0.188,
    "output Energy": 0.188,
    "Ac output": 1.188,
    "dc output": 1.21176,
    "AC_R_i": 0.00024522,
    "AC_S_i": 0.000269742,
    "AC_T_i": 0.00029672,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0564006,
    "AC_S_pow": 0.06204066,
    "AC_T_Pow": 0.0682456,
    "AC_R_output": 0.3564,
    "AC_S_output": 0.39204,
    "AC_T_output": 0.431244,
    "dc_current": 0.00138235,
    "dc_voltage": 136,
    "dc_power": 0.1683,
    "dc_output": 0.19176,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000188,
    "carbon offset": 0.235,
    "trees planted": 5.222222222,
    "DC_R_output": 0.117612,
    "DC_S_output": 0.1293732,
    "DC_T_output": 0.14231052
  },
  {
    "datetime": "11/1/2024 13:30",
    "plant_id": "Green-tek-A27291",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "1:30:00 PM",
    "power": 0.658,
    "Energy": 0.165,
    "output Energy": 0.165,
    "Ac output": 1.165,
    "dc output": 1.1883,
    "AC_R_i": 0.00021522,
    "AC_S_i": 0.000236742,
    "AC_T_i": 0.00026042,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0495006,
    "AC_S_pow": 0.05445066,
    "AC_T_Pow": 0.0598966,
    "AC_R_output": 0.3495,
    "AC_S_output": 0.38445,
    "AC_T_output": 0.422895,
    "dc_current": 0.00121324,
    "dc_voltage": 136,
    "dc_power": 0.14382,
    "dc_output": 0.1683,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000165,
    "carbon offset": 0.20625,
    "trees planted": 4.583333333,
    "DC_R_output": 0.115335,
    "DC_S_output": 0.1268685,
    "DC_T_output": 0.13955535
  },
  {
    "datetime": "11/1/2024 13:45",
    "plant_id": "Green-tek-A27292",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "1:45:00 PM",
    "power": 0.565,
    "Energy": 0.141,
    "output Energy": 0.141,
    "Ac output": 1.141,
    "dc output": 1.16382,
    "AC_R_i": 0.00018391,
    "AC_S_i": 0.000202301,
    "AC_T_i": 0.00022253,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0422993,
    "AC_S_pow": 0.04652923,
    "AC_T_Pow": 0.0511819,
    "AC_R_output": 0.3423,
    "AC_S_output": 0.37653,
    "AC_T_output": 0.414183,
    "dc_current": 0.00103676,
    "dc_voltage": 136,
    "dc_power": 0.12138,
    "dc_output": 0.14382,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000141,
    "carbon offset": 0.17625,
    "trees planted": 3.916666667,
    "DC_R_output": 0.112959,
    "DC_S_output": 0.1242549,
    "DC_T_output": 0.13668039
  },
  {
    "datetime": "11/1/2024 14:00",
    "plant_id": "Green-tek-A27293",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "2:00:00 PM",
    "power": 0.475,
    "Energy": 0.119,
    "output Energy": 0.119,
    "Ac output": 1.119,
    "dc output": 1.14138,
    "AC_R_i": 0.00015522,
    "AC_S_i": 0.000170742,
    "AC_T_i": 0.00018782,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0357006,
    "AC_S_pow": 0.03927066,
    "AC_T_Pow": 0.0431986,
    "AC_R_output": 0.3357,
    "AC_S_output": 0.36927,
    "AC_T_output": 0.406197,
    "dc_current": 0.000875,
    "dc_voltage": 136,
    "dc_power": 0.09996,
    "dc_output": 0.12138,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000119,
    "carbon offset": 0.14875,
    "trees planted": 3.305555556,
    "DC_R_output": 0.110781,
    "DC_S_output": 0.1218591,
    "DC_T_output": 0.13404501
  },
  {
    "datetime": "11/1/2024 14:15",
    "plant_id": "Green-tek-A27294",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "2:15:00 PM",
    "power": 0.391,
    "Energy": 0.098,
    "output Energy": 0.098,
    "Ac output": 1.098,
    "dc output": 1.11996,
    "AC_R_i": 0.00012783,
    "AC_S_i": 0.000140613,
    "AC_T_i": 0.00015467,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0294009,
    "AC_S_pow": 0.03234099,
    "AC_T_Pow": 0.0355741,
    "AC_R_output": 0.3294,
    "AC_S_output": 0.36234,
    "AC_T_output": 0.398574,
    "dc_current": 0.00072059,
    "dc_voltage": 136,
    "dc_power": 0.08058,
    "dc_output": 0.09996,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000098,
    "carbon offset": 0.1225,
    "trees planted": 2.722222222,
    "DC_R_output": 0.108702,
    "DC_S_output": 0.1195722,
    "DC_T_output": 0.13152942
  },
  {
    "datetime": "11/1/2024 14:30",
    "plant_id": "Green-tek-A27295",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "2:30:00 PM",
    "power": 0.316,
    "Energy": 0.079,
    "output Energy": 0.079,
    "Ac output": 1.079,
    "dc output": 1.10058,
    "AC_R_i": 0.00010304,
    "AC_S_i": 0.000113344,
    "AC_T_i": 0.00012468,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0236992,
    "AC_S_pow": 0.02606912,
    "AC_T_Pow": 0.0286764,
    "AC_R_output": 0.3237,
    "AC_S_output": 0.35607,
    "AC_T_output": 0.391677,
    "dc_current": 0.00058088,
    "dc_voltage": 136,
    "dc_power": 0.06324,
    "dc_output": 0.08058,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000079,
    "carbon offset": 0.09875,
    "trees planted": 2.194444444,
    "DC_R_output": 0.106821,
    "DC_S_output": 0.1175031,
    "DC_T_output": 0.12925341
  },
  {
    "datetime": "11/1/2024 14:45",
    "plant_id": "Green-tek-A27296",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "2:45:00 PM",
    "power": 0.25,
    "Energy": 0.062,
    "output Energy": 0.062,
    "Ac output": 1.062,
    "dc output": 1.08324,
    "AC_R_i": 0.00008087,
    "AC_S_i": 0.000088957,
    "AC_T_i": 0.00009785,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0186001,
    "AC_S_pow": 0.02046011,
    "AC_T_Pow": 0.0225055,
    "AC_R_output": 0.3186,
    "AC_S_output": 0.35046,
    "AC_T_output": 0.385506,
    "dc_current": 0.00045588,
    "dc_voltage": 136,
    "dc_power": 0.04896,
    "dc_output": 0.06324,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000062,
    "carbon offset": 0.0775,
    "trees planted": 1.722222222,
    "DC_R_output": 0.105138,
    "DC_S_output": 0.1156518,
    "DC_T_output": 0.12721698
  },
  {
    "datetime": "11/1/2024 15:00",
    "plant_id": "Green-tek-A27297",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "3:00:00 PM",
    "power": 0.193,
    "Energy": 0.048,
    "output Energy": 0.048,
    "Ac output": 1.048,
    "dc output": 1.06896,
    "AC_R_i": 0.00006261,
    "AC_S_i": 0.000068871,
    "AC_T_i": 0.00007576,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0144003,
    "AC_S_pow": 0.01584033,
    "AC_T_Pow": 0.0174248,
    "AC_R_output": 0.3144,
    "AC_S_output": 0.34584,
    "AC_T_output": 0.380424,
    "dc_current": 0.00035294,
    "dc_voltage": 136,
    "dc_power": 0.03774,
    "dc_output": 0.04896,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000048,
    "carbon offset": 0.06,
    "trees planted": 1.333333333,
    "DC_R_output": 0.103752,
    "DC_S_output": 0.1141272,
    "DC_T_output": 0.12553992
  },
  {
    "datetime": "11/1/2024 15:15",
    "plant_id": "Green-tek-A27298",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "3:15:00 PM",
    "power": 0.147,
    "Energy": 0.037,
    "output Energy": 0.037,
    "Ac output": 1.037,
    "dc output": 1.05774,
    "AC_R_i": 0.00004826,
    "AC_S_i": 0.000053086,
    "AC_T_i": 0.00005839,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0110998,
    "AC_S_pow": 0.01220978,
    "AC_T_Pow": 0.0134297,
    "AC_R_output": 0.3111,
    "AC_S_output": 0.34221,
    "AC_T_output": 0.376431,
    "dc_current": 0.00027206,
    "dc_voltage": 136,
    "dc_power": 0.02754,
    "dc_output": 0.03774,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000037,
    "carbon offset": 0.04625,
    "trees planted": 1.027777778,
    "DC_R_output": 0.102663,
    "DC_S_output": 0.1129293,
    "DC_T_output": 0.12422223
  },
  {
    "datetime": "11/1/2024 15:30",
    "plant_id": "Green-tek-A27299",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "3:30:00 PM",
    "power": 0.109,
    "Energy": 0.027,
    "output Energy": 0.027,
    "Ac output": 1.027,
    "dc output": 1.04754,
    "AC_R_i": 0.00003522,
    "AC_S_i": 0.000038742,
    "AC_T_i": 0.00004262,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0081006,
    "AC_S_pow": 0.00891066,
    "AC_T_Pow": 0.0098026,
    "AC_R_output": 0.3081,
    "AC_S_output": 0.33891,
    "AC_T_output": 0.372801,
    "dc_current": 0.00019853,
    "dc_voltage": 136,
    "dc_power": 0.0204,
    "dc_output": 0.02754,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000027,
    "carbon offset": 0.03375,
    "trees planted": 0.75,
    "DC_R_output": 0.101673,
    "DC_S_output": 0.1118403,
    "DC_T_output": 0.12302433
  },
  {
    "datetime": "11/1/2024 15:45",
    "plant_id": "Green-tek-A27300",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "3:45:00 PM",
    "power": 0.08,
    "Energy": 0.02,
    "output Energy": 0.02,
    "Ac output": 1.02,
    "dc output": 1.0404,
    "AC_R_i": 0.00002609,
    "AC_S_i": 0.000028699,
    "AC_T_i": 0.00003157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0060007,
    "AC_S_pow": 0.00660077,
    "AC_T_Pow": 0.0072611,
    "AC_R_output": 0.306,
    "AC_S_output": 0.3366,
    "AC_T_output": 0.37026,
    "dc_current": 0.00014706,
    "dc_voltage": 136,
    "dc_power": 0.01428,
    "dc_output": 0.0204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00002,
    "carbon offset": 0.025,
    "trees planted": 0.555555556,
    "DC_R_output": 0.10098,
    "DC_S_output": 0.111078,
    "DC_T_output": 0.1221858
  },
  {
    "datetime": "11/1/2024 16:00",
    "plant_id": "Green-tek-A27301",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "4:00:00 PM",
    "power": 0.057,
    "Energy": 0.014,
    "output Energy": 0.014,
    "Ac output": 1.014,
    "dc output": 1.03428,
    "AC_R_i": 0.00001826,
    "AC_S_i": 0.000020086,
    "AC_T_i": 0.00002209,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0041998,
    "AC_S_pow": 0.00461978,
    "AC_T_Pow": 0.0050807,
    "AC_R_output": 0.3042,
    "AC_S_output": 0.33462,
    "AC_T_output": 0.368082,
    "dc_current": 0.00010294,
    "dc_voltage": 136,
    "dc_power": 0.0102,
    "dc_output": 0.01428,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000014,
    "carbon offset": 0.0175,
    "trees planted": 0.388888889,
    "DC_R_output": 0.100386,
    "DC_S_output": 0.1104246,
    "DC_T_output": 0.12146706
  },
  {
    "datetime": "11/1/2024 16:15",
    "plant_id": "Green-tek-A27302",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "4:15:00 PM",
    "power": 0.04,
    "Energy": 0.01,
    "output Energy": 0.01,
    "Ac output": 1.01,
    "dc output": 1.0302,
    "AC_R_i": 0.00001304,
    "AC_S_i": 0.000014344,
    "AC_T_i": 0.00001578,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0029992,
    "AC_S_pow": 0.00329912,
    "AC_T_Pow": 0.0036294,
    "AC_R_output": 0.303,
    "AC_S_output": 0.3333,
    "AC_T_output": 0.36663,
    "dc_current": 0.00007353,
    "dc_voltage": 136,
    "dc_power": 0.00714,
    "dc_output": 0.0102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.00001,
    "carbon offset": 0.0125,
    "trees planted": 0.277777778,
    "DC_R_output": 0.09999,
    "DC_S_output": 0.109989,
    "DC_T_output": 0.1209879
  },
  {
    "datetime": "11/1/2024 16:30",
    "plant_id": "Green-tek-A27303",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "4:30:00 PM",
    "power": 0.027,
    "Energy": 0.007,
    "output Energy": 0.007,
    "Ac output": 1.007,
    "dc output": 1.02714,
    "AC_R_i": 0.00000913,
    "AC_S_i": 0.000010043,
    "AC_T_i": 0.00001105,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0020999,
    "AC_S_pow": 0.00230989,
    "AC_T_Pow": 0.0025415,
    "AC_R_output": 0.3021,
    "AC_S_output": 0.33231,
    "AC_T_output": 0.365541,
    "dc_current": 0.00005147,
    "dc_voltage": 136,
    "dc_power": 0.0051,
    "dc_output": 0.00714,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000007,
    "carbon offset": 0.00875,
    "trees planted": 0.194444444,
    "DC_R_output": 0.099693,
    "DC_S_output": 0.1096623,
    "DC_T_output": 0.12062853
  },
  {
    "datetime": "11/1/2024 16:45",
    "plant_id": "Green-tek-A27304",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "4:45:00 PM",
    "power": 0.018,
    "Energy": 0.005,
    "output Energy": 0.005,
    "Ac output": 1.005,
    "dc output": 1.0251,
    "AC_R_i": 0.00000652,
    "AC_S_i": 0.000007172,
    "AC_T_i": 0.00000789,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0014996,
    "AC_S_pow": 0.00164956,
    "AC_T_Pow": 0.0018147,
    "AC_R_output": 0.3015,
    "AC_S_output": 0.33165,
    "AC_T_output": 0.364815,
    "dc_current": 0.00003676,
    "dc_voltage": 136,
    "dc_power": 0.00306,
    "dc_output": 0.0051,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000005,
    "carbon offset": 0.00625,
    "trees planted": 0.138888889,
    "DC_R_output": 0.099495,
    "DC_S_output": 0.1094445,
    "DC_T_output": 0.12038895
  },
  {
    "datetime": "11/1/2024 17:00",
    "plant_id": "Green-tek-A27305",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "5:00:00 PM",
    "power": 0.012,
    "Energy": 0.003,
    "output Energy": 0.003,
    "Ac output": 1.003,
    "dc output": 1.02306,
    "AC_R_i": 0.00000391,
    "AC_S_i": 0.000004301,
    "AC_T_i": 0.00000473,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0008993,
    "AC_S_pow": 0.00098923,
    "AC_T_Pow": 0.0010879,
    "AC_R_output": 0.3009,
    "AC_S_output": 0.33099,
    "AC_T_output": 0.364089,
    "dc_current": 0.00002206,
    "dc_voltage": 136,
    "dc_power": 0.00204,
    "dc_output": 0.00306,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000003,
    "carbon offset": 0.00375,
    "trees planted": 0.083333333,
    "DC_R_output": 0.099297,
    "DC_S_output": 0.1092267,
    "DC_T_output": 0.12014937
  },
  {
    "datetime": "11/1/2024 17:15",
    "plant_id": "Green-tek-A27306",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "5:15:00 PM",
    "power": 0.008,
    "Energy": 0.002,
    "output Energy": 0.002,
    "Ac output": 1.002,
    "dc output": 1.02204,
    "AC_R_i": 0.00000261,
    "AC_S_i": 0.000002871,
    "AC_T_i": 0.00000316,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.0006003,
    "AC_S_pow": 0.00066033,
    "AC_T_Pow": 0.0007268,
    "AC_R_output": 0.3006,
    "AC_S_output": 0.33066,
    "AC_T_output": 0.363726,
    "dc_current": 0.00001471,
    "dc_voltage": 136,
    "dc_power": 0.00102,
    "dc_output": 0.00204,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000002,
    "carbon offset": 0.0025,
    "trees planted": 0.055555556,
    "DC_R_output": 0.099198,
    "DC_S_output": 0.1091178,
    "DC_T_output": 0.12002958
  },
  {
    "datetime": "11/1/2024 17:30",
    "plant_id": "Green-tek-A27307",
    "plant_name": "Gayathri",
    "country": "India",
    "state": "Telangana",
    "district": "Hyderabad",
    "Date": "11/1/2024",
    "time": "5:30:00 PM",
    "power": 0.005,
    "Energy": 0.001,
    "output Energy": 0.001,
    "Ac output": 1.001,
    "dc output": 1.02102,
    "AC_R_i": 0.0000013,
    "AC_S_i": 0.00000143,
    "AC_T_i": 0.00000157,
    "AC_R_V": 230,
    "AC_S_V": 230,
    "AC_T_V": 230,
    "AC_R_pow": 0.000299,
    "AC_S_pow": 0.0003289,
    "AC_T_Pow": 0.0003611,
    "AC_R_output": 0.3003,
    "AC_S_output": 0.33033,
    "AC_T_output": 0.363363,
    "dc_current": 0.00000735,
    "dc_voltage": 136,
    "dc_power": 0,
    "dc_output": 0.00102,
    "irradiance": 800,
    "max temp": 28,
    "rec count": 0.000001,
    "carbon offset": 0.00125,
    "trees planted": 0.027777778,
    "DC_R_output": 0.099099,
    "DC_S_output": 0.1090089,
    "DC_T_output": 0.11990979
  }
 ]


 export default PlantDailyData;